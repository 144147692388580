import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoPoleInspectionItemIdentifier } from '../business-objects/dto-pole-inspection-item-identifier.bo';

@Injectable()
export class dtoPoleInspectionItemIdentifierService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoPoleInspectionItemIdentifier(dtoPoleInspectionItemIdentifierId: string) {
		return this.http.get<dtoPoleInspectionItemIdentifier>(this._baseUrl + 'api/dtoPoleInspectionItemIdentifier/' + dtoPoleInspectionItemIdentifierId);
	}

	getdtoPoleInspectionItemIdentifierParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoPoleInspectionItemIdentifier[]>(this._baseUrl + 'api/dtoPoleInspectionItemIdentifier/GetdtoPoleInspectionItemIdentifiersParamArray/', aParam);
	}

  getdtoPoleInspectionItemIdentifierLastNumber() {
    return this.http.get<dtoPoleInspectionItemIdentifier>(this._baseUrl + 'api/dtoPoleInspectionItemIdentifier/GetLastNumber/');
  }

	getAlldtoPoleInspectionItemIdentifiers() {
		return this.http.get<dtoPoleInspectionItemIdentifier[]>(this._baseUrl + 'api/dtoPoleInspectionItemIdentifier/GetAlldtoPoleInspectionItemIdentifiers/');
	}

	//getAlldtodtoPoleInspectionItemIdentifiers() {
	//	return this.http.get<dtodtoPoleInspectionItemIdentifier[]>(this._baseUrl + 'api/dtoPoleInspectionItemIdentifier/GetAlldtoPoleInspectionItemIdentifiers/');
	//}

	createdtoPoleInspectionItemIdentifier(dtoPoleInspectionItemIdentifier: dtoPoleInspectionItemIdentifier) {
		return this.http.post<dtoPoleInspectionItemIdentifier>(this._baseUrl + 'api/dtoPoleInspectionItemIdentifier/CreatedtoPoleInspectionItemIdentifier/', dtoPoleInspectionItemIdentifier);
	}

	updatedtoPoleInspectionItemIdentifier(dtoPoleInspectionItemIdentifier: dtoPoleInspectionItemIdentifier) {
		return this.http.put<dtoPoleInspectionItemIdentifier>(this._baseUrl + 'api/dtoPoleInspectionItemIdentifier/UpdatedtoPoleInspectionItemIdentifier/', dtoPoleInspectionItemIdentifier);
	}

	deletedtoPoleInspectionItemIdentifier(dtoPoleInspectionItemIdentifierId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoPoleInspectionItemIdentifier/DeletedtoPoleInspectionItemIdentifier/' + dtoPoleInspectionItemIdentifierId);
	}

	async getdtoPoleInspectionItemIdentifierPromise(dtoPoleInspectionItemIdentifierId: string) {
		return new Promise<dtoPoleInspectionItemIdentifier>(resolve => {
			this.getdtoPoleInspectionItemIdentifier(dtoPoleInspectionItemIdentifierId).subscribe(dtoPoleInspectionItemIdentifier => {
				resolve(dtoPoleInspectionItemIdentifier);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoPoleInspectionItemIdentifier records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoPoleInspectionItemIdentifierLastNumberPromise() {
    return new Promise<dtoPoleInspectionItemIdentifier>(resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      

      this.getdtoPoleInspectionItemIdentifierLastNumber().subscribe(dtoPoleInspectionItemIdentifier => {
        resolve(dtoPoleInspectionItemIdentifier);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoPoleInspectionItemIdentifier records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getdtoPoleInspectionItemIdentifierParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoPoleInspectionItemIdentifier[]>(resolve => {
			this.getdtoPoleInspectionItemIdentifierParamArray(aParam).subscribe(dtoPoleInspectionItemIdentifiers => {
				resolve(dtoPoleInspectionItemIdentifiers);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoPoleInspectionItemIdentifier records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoPoleInspectionItemIdentifierPromise(dtoPoleInspectionItemIdentifier: dtoPoleInspectionItemIdentifier) {
		return new Promise<dtoPoleInspectionItemIdentifier>(resolve => {
			this.updatedtoPoleInspectionItemIdentifier(dtoPoleInspectionItemIdentifier).subscribe(dtoPoleInspectionItemIdentifier => {
				resolve(dtoPoleInspectionItemIdentifier);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoPoleInspectionItemIdentifier record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoPoleInspectionItemIdentifierPromise(dtoPoleInspectionItemIdentifier: dtoPoleInspectionItemIdentifier) {
		return new Promise<dtoPoleInspectionItemIdentifier>(resolve => {
			this.createdtoPoleInspectionItemIdentifier(dtoPoleInspectionItemIdentifier).subscribe(dtoPoleInspectionItemIdentifier => {
				resolve(dtoPoleInspectionItemIdentifier);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoPoleInspectionItemIdentifier record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}


	async deletedtoPoleInspectionItemIdentifierPromise(dtoPoleInspectionItemIdentifierId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoPoleInspectionItemIdentifier(dtoPoleInspectionItemIdentifierId).subscribe(dtoPoleInspectionItemIdentifier => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoPoleInspectionItemIdentifier record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
