import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { dtoTPPoleInspectionItemSummary } from '../../_shared/business-objects/dto-tppole-inspection-item-summary.bo';

@Component({
  selector: 'app-final-inspection-item-summary',
  templateUrl: './final-inspection-item-summary.component.html',
  styleUrls: ['./final-inspection-item-summary.component.css']
})
export class FinalInspectionItemSummaryComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoTPPoleInspectionItemSummary>;
  @Input() finalInspectionItemSummaryS1QtyTotal: number;
  @Input() finalInspectionItemSummaryS2QtyTotal: number;
  @Input() finalInspectionItemSummaryS3QtyTotal: number;
  @Input() finalInspectionItemSummaryBBTQtyTotal: number;
  @Input() finalInspectionItemSummaryQtyTotal: number;
  @Input() finalInspectionItemSummaryM3Total: number;
  @Input() finalInspectionItemSummaryTonneTotal: number;
  @Input() mode: string;

  @ViewChild(MatSort) sort: MatSort;

  displayedfinalInspectionItemSummary: string[] = ['txtOrderNo', 'txtCustomerName', 'txtDestination', 'txtProductName', 'txtFinalPole', 'intS1', 'intS2', 'intS3', 'intBBT', 'intTotalQuantity', 'fltActualM3', 'fltActualTonne'];


  constructor() { }

  ngOnInit(): void {

  }


  ngAfterViewInit() {
    //this.datasource.sort = this.sort;
  }
}
