import { query } from "@angular/animations";

export interface SQLParamArray {
  constructor(name: string, value: string, operator: SQLParamArray.enSQLOperator, logicalOperator: SQLParamArray.enSQLLogicalOperator, dbFieldFunctionArray: DBFieldFunction, tablename: string, sqlStatmentType: SQLParamArray.enSQLStatementType, queryNo: number, subQueryClass: string);
  ParameterTableName: string;
  ParameterName: string;
  ParameterValue: string;
  ParameterOperator: SQLParamArray.enSQLOperator;
  ParameterLogicalOperator: SQLParamArray.enSQLLogicalOperator;
  ParameterDBFieldFunction: DBFieldFunction;
  SQLStatementType: SQLParamArray.enSQLStatementType;
  QueryNo: number;
  SubQueryClass: string;
}

export class SQLParamArray {
  constructor(name: string, value: string, operator: SQLParamArray.enSQLOperator = SQLParamArray.enSQLOperator.Equals, logicalOperator: SQLParamArray.enSQLLogicalOperator = SQLParamArray.enSQLLogicalOperator.AND, dbFieldFunctionArray: DBFieldFunction = null, tablename: string = "", sqlStatmentType: SQLParamArray.enSQLStatementType = SQLParamArray.enSQLStatementType.SelectSQL, queryNo: number = 1, subQueryClass: string = "") {
    this.ParameterTableName = tablename;
    this.ParameterName = name;
    this.ParameterValue = value;
    this.ParameterOperator = operator;
    this.ParameterLogicalOperator = logicalOperator;
    this.ParameterDBFieldFunction = dbFieldFunctionArray;
    this.SQLStatementType = sqlStatmentType;
    this.QueryNo = queryNo;
    this.SubQueryClass = subQueryClass;
  }

  ParameterTableName: string;
  ParameterName: string;
  ParameterValue: string;
  ParameterOperator: SQLParamArray.enSQLOperator;
  ParameterLogicalOperator: SQLParamArray.enSQLLogicalOperator;
  ParameterDBFieldFunction: DBFieldFunction;
  SQLStatementType: SQLParamArray.enSQLStatementType;
  QueryNo: number;
  SubQueryClass: string;
}

export namespace SQLParamArray {
  export enum enSQLOperator {
    Equals,
    NotEqualTo,
    Like,
    LikeAny,
    LessThan,
    LessThanOrEqualTo,
    GreaterThan,
    GreaterThanOrEqualTo,
    IsNull,
    IsNotNull
  }

  export enum enSQLLogicalOperator {
    AND,
    OR
  }

  export enum enDBFieldFunction {
    None,
    Replace,
    Len,
    Min,
    Max,
    CastDate
  }

  export enum enSQLStatementType {
    SelectSQL,
    UpdateSQL,
    DeleteSQL,
    SubQuerySQL
  }
}


export interface DBFieldFunction {
  constructor(dbFieldFunction: SQLParamArray.enDBFieldFunction, Value1: string, Value2: string);
  DBFieldFunction: SQLParamArray.enDBFieldFunction;
  Value1: string;
  Value2: string;
}

export class DBFieldFunction {
  constructor(dbFieldFunction: SQLParamArray.enDBFieldFunction, Value1: string, Value2: string) {
    this.DBFieldFunction = dbFieldFunction;
    this.Value1 = Value1;
    this.Value2 = Value2;
  }

  DBFieldFunction: SQLParamArray.enDBFieldFunction;
  Value1: string;
  Value2: string;
}
