<div class="app-page-column-set-create-container">
  <div class="app-page-column-set-create-form-container">
    <form class="app-page-column-set-create-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created</mat-label>
              <input id="dteDateCreated" class="date-input created-from-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteDateCreated">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>App User</mat-label>
              <mat-select formControlName="guAppUserId">
                <mat-option *ngFor="let appUser of appUsers" [value]="appUser.rowguid">{{appUser.txtFirstName}} {{appUser.txtLastName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>App Page</mat-label>
              <mat-select formControlName="guAppPageId">
                <mat-option *ngFor="let appPage of appPages" [value]="appPage.rowguid">{{appPage.txtPageName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Column Set Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. txtName" value="" type="text" id="txtColumnSetName" formControlName="txtColumnSetName">
              </mat-form-field>
            </div>
          </div>

          <div class="main-row-3">
            <mat-slide-toggle formControlName="blnCurrentView">Current View</mat-slide-toggle>
          </div>

          <button class="stocktakeButton" mat-flat-button color="primary" (click)="create('Create')">Create</button>
          <button class="stocktakeButton" mat-flat-button color="primary" (click)="create('CreateAndAdd')">Create and Add New</button>
          <button class="stocktakeButton" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
        </div>
      </div>

    </form>
  </div>
</div>
