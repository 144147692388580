import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dtoTPChargeItem } from '../../_shared/business-objects/dto-tpcharge-item.bo';
import { dtoTPChargeItemService } from '../../_shared/services/dto-tpcharge-item.service';

@Component({
  selector: 'app-cca-charge-item-view',
  templateUrl: './cca-charge-item-view.component.html',
  styleUrl: './cca-charge-item-view.component.scss'
})
export class CcaChargeItemViewComponent implements OnInit {

  @Input() tpChargIdInput: string;

  tpChargeItems: dtoTPChargeItem[] = [];
  tpChargId: string;
  screenView: boolean = false;
  samples: boolean = false;

  totalM3: number = 0;
  totalTonne: number = 0;

  constructor(private dtoTPChargeItemService: dtoTPChargeItemService, private route: ActivatedRoute) {


  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(async params => {

      if (params != null) {

        // ==========================================================================================
        // The charge id can be supplied from the @Input so it will override the param value.
        // ==========================================================================================
        if (this.tpChargIdInput == null || this.tpChargIdInput == "") {
          this.tpChargId = params["chargeid"];
        }

        let screenView: string = String(params["screenview"]);
        this.screenView = screenView.toLocaleLowerCase() == "yes";
        this.samples = (params["samples"] == "yes");

      }
    });

    //console.log("app view - after: " + this.deliveryDocketId);

    if (this.tpChargId == null) {
      return;
    }

    this.loadData();

  }

  async loadData() {

    this.tpChargeItems = await this.dtoTPChargeItemService.getdtoTPChargeItems(this.tpChargId);
    if (this.samples == true) {
      this.tpChargeItems = this.tpChargeItems.filter((a) => { return a.blnSampleTaken == true });
    }
    this.tpChargeItems.sort((a, b) => { return a.intConsecutiveNo - b.intConsecutiveNo });

    console.log("this.tpChargeItems", this.tpChargeItems);

    for (let i = 0; i <= this.tpChargeItems.length - 1; i++) {
      this.totalM3 += this.tpChargeItems[i].fltM3;
    }

    this.totalM3 = Number(this.totalM3.toFixed(3))
    this.totalTonne = Number((this.totalM3 * 1.2).toFixed(3))
    console.log("totalM3: ", this.totalM3);


  }
}
