<div class="disc-template-config-create-container">
  <div class="disc-template-config-create-form-container">
    <form class="disc-template-config-create-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="header-row-4">
            <div class="electrical-authority-spec">
              <mat-form-field>
                <div class="electrical-authority-spec-input-container">
                  <input matInput class="electrical-authority-spec-input" placeholder="Filter by name" placeholderLabel="i.e. Essential Energy....." value="" type="text" #electricalAuthoritySpecFilter (keyup)="filterElectricalAuthoritySpec(electricalAuthoritySpecFilter.value)" (focus)="electricalAuthoritySpecFilter.select()">
                  <br /><br />
                </div>
              </mat-form-field>
              <mat-selection-list class="electrical-authority-spec-select" #guElectricalAuthoritySpecId [multiple]="false" formControlName="guElectricalAuthoritySpecId" (selectionChange)="setNextColumnNumber(guElectricalAuthoritySpecId._value[0])">
                <mat-list-option *ngFor="let electricalAuthority of electricalAuthoritys" [value]="electricalAuthority.rowguid">
                  {{electricalAuthority.txtAuthorityName}}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>

          <div class="csv-column-number-container">
            <div class="csv-column-number-form-field-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>CSV Column Number</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 1" value="" type="number" #txtCSVColumnNumber id="txtCSVColumnNumber" formControlName="txtCSVColumnNumber">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="input-type-container">
              <mat-selection-list class="input-type-select" #intInputType [multiple]="false" formControlName="intInputType" (selectionChange)="disableInputField($event)">
                <mat-list-option *ngFor="let inputType of inputTypes" [value]="inputType.intValue">
                  {{inputType.txtValue}}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>

          <div class="user-input-container">
            <div class="user-input-form-field-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>User Input</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. H5" value="" type="text" #txtUserInput id="txtUserInput" formControlName="txtUserInput">
              </mat-form-field>
            </div>
          </div>

          <div class="computer-input-field-container">
            <div class="computer-input-field-form-field-container">
              <mat-selection-list class="computer-input-field-select" #intInputType [multiple]="false" formControlName="intComputerInputField">
                <mat-list-option *ngFor="let computerInputField of computerInputFields" [value]="computerInputField.intValue">
                  {{computerInputField.txtValue}}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>

          <div class="comments-container">
            <div class="comments-form-field-container">
              <mat-form-field class="example-full-width comments-form-field" appearance="fill">
                <mat-label>Comments</mat-label>
                <textarea class="comments" matInput rows="10" value="" id="txtComments" formControlName="txtComments"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <span><mat-slide-toggle formControlName="blnActive">Active</mat-slide-toggle></span>
            </div>
          </div>

      
          <div class="header-row-4">
            <div class="header-row-3-container">
            </div>
          </div>

          <div class="action-button-container">
            <button class="action-button" mat-flat-button color="primary" (click)="createDiscTemplateConfig('create')">Create Disc Template Config</button>
            <button class="action-button" mat-flat-button color="primary" (click)="cancelDiscTemplateConfig()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="spinner-container">
  <mat-card [@displaySpinner]="transitionProducts ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showSpinner">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
