import { AfterViewInit, Component, EventEmitter, InjectionToken, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../account/auth.service';
import { EmailMessage } from '../_shared/app-objects/email-message';
import { BoardTallyRecordDeliveryTransferred } from '../_shared/business-objects/board-tally-record-delivery-transferred.bo';
import { BoardTallyRecordHistory } from '../_shared/business-objects/board-tally-record-history.bo';
import { BoardTallyRecord } from '../_shared/business-objects/board-tally-record.bo';
import { dtoDeliveryDocketStockTransfer } from '../_shared/business-objects/dto-delivery-docket-stock-transfer.bo';
import { dtoStockItem } from '../_shared/business-objects/dto-stock-item.bo';
import { GeoStockLocation } from '../_shared/business-objects/geo-stock-location.bo';
import { SQLParamArray } from '../_shared/business-objects/sql-param-array';
import { StockContainer } from '../_shared/business-objects/stock-container.bo';
import { AlertService } from '../_shared/services/alert.service';
import { BoardTallyRecordDeliveryTransferredService } from '../_shared/services/board-tally-record-delivery-transferred.service';
import { BoardTallyRecordService } from '../_shared/services/board-tally-record.service';
import { SharedService } from '../_shared/services/shared.service';
import { StockContainerService } from '../_shared/services/stock-container.service';

enum enStockItemColumns { 'txtProductName', 'txtProfileName', 'intWidth', 'intThickness', 'txtSpeciesName', 'txtGradeName', 'fltQuantity', 'txtQuantityUoM', 'fltLength', 'fltDia', 'txtLocation', 'intIdentifier', 'dteDateCreated', 'txtStockKeepingUnit', 'chkReceiveStock' };

@Component({
  selector: 'app-stock-item',
  templateUrl: './stock-item.component.html',
  styleUrls: ['./stock-item.component.css']
})
export class StockItemComponent implements OnInit {

  @Input() stockItemFrom: dtoDeliveryDocketStockTransfer;
  @Input() stockItemFromIndex: number;
  @Input() geoStockLocation: GeoStockLocation;
  @Input() data: dtoStockItem[];
  @Output() transferToStockItemEvent = new EventEmitter<[dtoStockItem, number, number]>();

  //displayedStockItems: string[] = ['txtIdentifier', 'txtProductName', 'txtProfileName', 'intWidth', 'chkReceiveStock'];
  displayedStockItems: string[] = ['txtProductName', 'txtProfileName', 'intWidth', 'intThickness', 'txtSpeciesName', 'txtGradeName', 'fltQuantity', 'txtQuantityUoM', 'fltLength', 'fltDia', 'txtLocation', 'intIdentifier', 'dteDateCreated', 'txtStockKeepingUnit', 'chkReceiveStock'];

  stockItems: MatTableDataSource<dtoStockItem>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  //private inject: Injector

  constructor(private alertService: AlertService, private authService: AuthService, private stockContainerService: StockContainerService
    , private boardTallyRecordService: BoardTallyRecordService, private sharedService: SharedService
    , private boardTallyRecordDeliveryTransferred: BoardTallyRecordDeliveryTransferredService
  ) { }

  ngOnInit(): void {

    // Take out stock keeping unit for now.
    this.displayedStockItems.splice(enStockItemColumns.txtStockKeepingUnit, 1);
    this.displayedStockItems.splice(enStockItemColumns.dteDateCreated, 1);  

  }

  async getStockContainer(stockContainerId: string) {

    return new Promise<StockContainer>(resolve => {
      this.stockContainerService.getStockContainer(stockContainerId).subscribe(stockContainer => {

        resolve(stockContainer);

      }, err => {
        console.log(err);
        resolve(null);
      })
    });
  }

  transferToStockItem(boardTallyRecordHistory: dtoStockItem, stockToIndex: number, stockFromIndex: number) {
    let snack = this.alertService.openSnackBarCustom("Transfer Stock Alert", "Are you sure you want to transfer the stock item " + this.stockItemFrom.intIdentifier.toString() + " into your stock item " + boardTallyRecordHistory.intIdentifier.toString() + "?", "Yes","No", "center", "bottom", "", 5000);

    snack.onAction().subscribe(async () => {
      if (snack.instance.actionResponse == "Yes") {
        let stockContainerFrom: StockContainer = await this.getStockContainer(this.stockItemFrom.guBoardTallyStockContainerId);
        let stockContainerTo: StockContainer = await this.getStockContainer(boardTallyRecordHistory.guStockContainerId);
        let boardTallyRecordTo: BoardTallyRecord = await this.boardTallyRecordService.getBoardTallyRecordPromise(boardTallyRecordHistory.rowguid);
        let boardTallyRecordFrom: BoardTallyRecord = await this.boardTallyRecordService.getBoardTallyRecordPromise(this.stockItemFrom.guBoardTallyRecordId);



      //=========================================================================================================
      // ADD BoardTallyRecordDeliveryTransferred RECORD FOR EACH STOCK ITEM.
      //=========================================================================================================
        let boardTallyRecordDelvieryTransferred = new BoardTallyRecordDeliveryTransferred;
        boardTallyRecordDelvieryTransferred.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
        boardTallyRecordDelvieryTransferred.guBoardTallyRecordId = this.stockItemFrom.guBoardTallyRecordId;
        boardTallyRecordDelvieryTransferred.guDeliveryDocketStockTransferId = this.stockItemFrom.rowguid;
        await this.boardTallyRecordDeliveryTransferred.createBoardTallyRecordDeliveryTransferredParamArrayPromise(boardTallyRecordDelvieryTransferred);

      //=========================================================================================================

        let aParamList: SQLParamArray[][] = [];
        let aParam: SQLParamArray[] = [];

        aParam.push(new SQLParamArray("guDeliveryDocketStockTransferId", this.stockItemFrom.rowguid));
        aParamList.push(aParam);
        let transferredStock: BoardTallyRecordDeliveryTransferred[] = await this.boardTallyRecordDeliveryTransferred.getBoardTallyRecordDeliveryTransferredParamArrayPromise(aParamList);

        aParamList = [];
        aParam = [];
        aParam.push(new SQLParamArray("guStockContainerId", this.stockItemFrom.guBoardTallyStockContainerId));
        aParamList.push(aParam);
        let boardTallyRecords: BoardTallyRecord[] = await this.boardTallyRecordService.getBoardTallyRecordParamArrayPromise(aParamList);
        
        let archive: boolean = true;
        if (transferredStock != null && boardTallyRecords != null) {
          let boardTallyRecordIds: string[] = boardTallyRecords.map(bt => bt.rowguid);
          let transferredStockIds: string[] = transferredStock.map(ts => ts.guBoardTallyRecordId);

          console.log(boardTallyRecordIds);

          for (let btId of boardTallyRecordIds) {

            // If the board tally record Id does not exist in the stock transfer array then exit loop.
            // We need all board tally record Id's to be transferred then we can archive the Stock Container

            archive = transferredStockIds.includes(btId);
          
            console.log("archive: " + archive);
           if (archive == false) {
             console.log("break");
            break;
            }

          };
        }


        boardTallyRecordTo.fltQuantity += boardTallyRecordFrom.fltQuantity;
        await this.boardTallyRecordService.updateBoardTallyRecordPromise(this.authService.getAppUserEmployeeId(), true, BoardTallyRecordHistory.enChangeReason.StockTransferAndQuantityIncrease, boardTallyRecordTo);


        boardTallyRecordFrom.fltQuantity = 0;
        await this.boardTallyRecordService.updateBoardTallyRecordPromise(this.authService.getAppUserEmployeeId(), true, BoardTallyRecordHistory.enChangeReason.StockTransferAndQuantityDecrease, boardTallyRecordFrom);


        if (archive == true) {
          stockContainerFrom.blnArchived = archive;
          stockContainerFrom.dteArchivedDate = this.sharedService.currentDatePlusTZOffset();
          stockContainerFrom.txtArchiveComments = "Archiving stock after transfer at " + this.geoStockLocation.txtLocationName;
          await this.stockContainerService.updateStockContainerPromise(this.authService.getAppUserEmployeeId(), true, BoardTallyRecordHistory.enChangeReason.StockTransferAndArchived, stockContainerFrom);
        }


        this.alertService.openSnackBar("Stock has been transferred", "Close", "center", "bottom", "", 2000);

        this.transferToStockItemEvent.emit([boardTallyRecordHistory, stockToIndex, stockFromIndex]);

      }


    }, err => {
      console.log(err);
    });


    //this.data = this.data.splice(index, 1);

  }

  async testArchive() {

    console.log("from guBoardTallyRecordId: " + this.stockItemFrom.guBoardTallyRecordId);

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guDeliveryDocketStockTransferId", this.stockItemFrom.rowguid));
    aParamList.push(aParam);
    let transferredStock: BoardTallyRecordDeliveryTransferred[] = await this.boardTallyRecordDeliveryTransferred.getBoardTallyRecordDeliveryTransferredParamArrayPromise(aParamList);

    aParamList = [];
    aParam = [];
    aParam.push(new SQLParamArray("guStockContainerId", this.stockItemFrom.guBoardTallyStockContainerId));
    aParamList.push(aParam);
    let boardTallyRecords: BoardTallyRecord[] = await this.boardTallyRecordService.getBoardTallyRecordParamArrayPromise(aParamList);

    let archive: boolean = true;
    if (transferredStock != null && boardTallyRecords != null) {
      let boardTallyRecordIds: string[] = boardTallyRecords.map(bt => bt.rowguid);
      let transferredStockIds: string[] = transferredStock.map(ts => ts.guBoardTallyRecordId);

      console.log(boardTallyRecordIds);
      console.log(transferredStockIds);

      for (let btId of boardTallyRecordIds) {

        // If the board tally record Id does not exist in the stock transfer array then exit loop.
        // We need all board tally record Id's to be transferred then we can archive the Stock Container
        console.log("btId: " + btId);

        archive = transferredStockIds.includes(btId);

        console.log("archive: " + archive);
        if (archive == false) {

          break;
        }

      };
    }


  }



}
