import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';
import { dtoStockItemDisplay } from '../../_shared/business-objects/dto-stock-item-display.bo';

@Component({
  selector: 'app-photo-viewer-dialog',
  templateUrl: './photo-viewer-dialog.component.html',
  styleUrl: './photo-viewer-dialog.component.scss'
})
export class PhotoViewerDialogComponent {

  path: string;
  folderName: string;

  constructor(private dialogRef: MatDialogRef<PhotoViewerDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { path: string, folderName: string }) {

    this.path = data.path;
    this.folderName = data.folderName;

  }

  emailPhotos(fileAttachments: FileAttachment[]) {

    this.dialogRef.close({ status: "emailphotos", fileAttachments: fileAttachments });

  }

  photoViewerClose(val) {

    this.dialogRef.close();

  }

}
