import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { SharedService } from '../../_shared/services/shared.service';
import { AppPageService } from '../../_shared/services/app-page.service';
import { AppService } from '../../_shared/services/app-services/app.service';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AppPageColumn } from '../../_shared/business-objects/app-page-column.bo';
import { AppPageColumnService } from '../../_shared/services/app-page-column.service';
import { MatSelect } from '@angular/material/select';
import { ColumnService } from '../../_shared/services/column.service';
import { CodeBuilderTable } from '../../_shared/business-objects/code-builder-table.bo';
import { TableService } from '../../_shared/services/table.service';
import { CodeBuilderColumn } from '../../_shared/business-objects/code-builder-column.bo';


@Component({
  selector: 'app-app-page-column-create',
  templateUrl: './app-page-column-create.component.html',
  styleUrls: ['./app-page-column-create.component.css']
})
export class AppPageColumnCreateComponent implements OnInit {

  appPages: AppPage[];
  appPagesCopy: AppPage[];
  codeBuilderColumns: CodeBuilderColumn[];
  codeBuilderColumnsAll: CodeBuilderColumn[];
  codeBuilderTables: CodeBuilderTable[];
  codeBuilderTablesAll: CodeBuilderTable[];

  form: FormGroup;

  @Output() appPageColumnCreated = new EventEmitter<AppPage>();
  @Output() appPageColumnCancelled = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private appService: AppService, private sharedService: SharedService, private alertService: AlertService
    , private appPageService: AppPageService, private appPageColumnService: AppPageColumnService, private columnService: ColumnService
    , private tableService: TableService) {

    const datepipe: DatePipe = new DatePipe('en-AU');

    this.form = this.fb.group({
      dteDateCreated: [''],
      guCopyFromAppPageId: [''],
      guAppPageId: [''],
      txtColumnName: [''],
      txtColumnNameAlias: [''],
      intDefaultColumnOrder: [''],
      guCodeBuilderColumnId: [''],
      guTableId: ['']
    });


    this.form.controls['dteDateCreated'].enable();
    this.form.controls['dteDateCreated'].setValue(new Date(Date.now()));
    this.form.controls['dteDateCreated'].disable();

    console.log(this.sharedService.currentDatePlusTZOffset());
    console.log(new Date(Date.now()));

  }

  ngOnInit(): void {

    this.loadData();

  }

  async loadData() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParamList.push(aParam);

    this.appPages = await this.appPageService.getAppPageParamArrayPromise(aParamList);
    this.appPagesCopy = await this.appPageService.getAppPageParamArrayPromise(aParamList);

    this.codeBuilderTablesAll = await this.tableService.getAllTablesPromise();

    this.codeBuilderTables = this.codeBuilderTablesAll;


  }

  async setNextColumnNumber(appPageId: string) {
    //console.log(appPage);

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guAppPageId", appPageId));
    aParamList.push(aParam);

    let appPageColumns: AppPageColumn[] = await this.appPageColumnService.getAppPageColumnParamArrayPromise(aParamList);
    this.form.controls['intDefaultColumnOrder'].setValue((appPageColumns.length + 1).toString());

  }

  async getTableColumns(tableId: string) {

    console.log(tableId);
    this.codeBuilderColumns = await this.columnService.getAllColumnsPromise(tableId);

    this.codeBuilderColumnsAll = this.codeBuilderColumns;
  }

  filterTables(tableFilter: string) {
    this.codeBuilderTables = this.codeBuilderTablesAll;

    this.codeBuilderTables = this.codeBuilderTables.filter(a => a.txtTableName.toLowerCase().startsWith(tableFilter.toLowerCase()) );
    //console.log("tableFilter: " + this.codeBuilderTables);

  }

  filterColumns(columnFilter: string) {
    this.codeBuilderColumns = this.codeBuilderColumnsAll;

    this.codeBuilderColumns = this.codeBuilderColumns.filter(a => a.txtColumnName.toLowerCase().indexOf(columnFilter.toLowerCase()) > -1);
    //console.log("tableFilter: " + this.codeBuilderTables);

  }

  setColumnFromCodeBuilder(codeBuilderColumn: CodeBuilderColumn) {
    console.log(codeBuilderColumn)

    this.form.controls['txtColumnName'].setValue(codeBuilderColumn.txtColumnName);

    let columnName: string = codeBuilderColumn.txtColumnName.substr(3, codeBuilderColumn.txtColumnName.length - 3);
    columnName = this.sharedService.spaceBeforeCapital(columnName);

    this.form.controls['txtColumnNameAlias'].setValue(columnName);

  }

  async copyFromAppPage(appPage: MatSelect, appPageTo: MatSelect) {


    if (appPage.value == null || appPage.value == "" || appPageTo.value == null || appPageTo.value == "") {
      return;
    }

    const datepipe: DatePipe = new DatePipe('en-AU');
    let dateCreated: Date = new Date(this.form.controls['dteDateCreated'].value.toString());

    if (dateCreated == null || dateCreated.toString() == "") {
      this.alertService.openSnackBar("Please select an created date.", "Close", "center", "bottom", "", 3000);
      return;
    }



    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guAppPageId", appPage.value));
    aParamList.push(aParam);
    let appPageColumns: AppPageColumn[] = await this.appPageColumnService.getAppPageColumnParamArrayPromise(aParamList);

    if (appPageColumns != null) {

      let appPageColumn: AppPageColumn;

      for (let i = 0; i <= appPageColumns.length - 1; i++) {

        appPageColumn = new AppPageColumn;


        delete appPageColumn.rowguid;  //remove rowguid property so DB will create one.

        appPageColumn.dteDateCreated = new Date(datepipe.transform(dateCreated, 'yyyy-MM-dd'));
        appPageColumn.txtColumnName = appPageColumns[i].txtColumnName;
        appPageColumn.txtColumnNameAlias = appPageColumns[i].txtColumnNameAlias;
        appPageColumn.guAppPageId = appPageTo.value;
        appPageColumn.intDefaultColumnOrder = appPageColumns[i].intDefaultColumnOrder;

        appPageColumn = await this.appPageColumnService.createAppPageColumnPromise(appPageColumn);

        console.log("creating appPageColumn: ", appPageColumn);

      }

      if (appPageColumn != null) {
        let appPage: AppPage = await this.appPageService.getAppPagePromise(appPageColumn.rowguid);
        this.appPageColumnCreated.emit(appPage);
        console.log("fired appPageColumnCreated: ");
      }

    }


    console.log("appPageColumns: ", appPageColumns);


  }



  async create(mode: string) {

    let saveAppPageColumn: boolean = true;
    let dateCreated: Date = new Date(this.form.controls['dteDateCreated'].value.toString());
    let columnName: string = this.form.controls['txtColumnName'].value;
    let columnNameAlias: string = this.form.controls['txtColumnNameAlias'].value;
    let appPageId: string = this.form.controls['guAppPageId'].value;
    let defaultColumnOrder: number = parseInt(this.form.controls['intDefaultColumnOrder'].value.toString());
    const datepipe: DatePipe = new DatePipe('en-AU');

    if (dateCreated == null || dateCreated.toString() == "") {
      this.alertService.openSnackBar("Please select an created date.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (columnName == null || columnName.toString() == "") {
      this.alertService.openSnackBar("Please enter a column name.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (columnNameAlias == null || columnNameAlias.toString() == "") {
      this.alertService.openSnackBar("Please enter a column name alias.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (appPageId == null || appPageId.toString() == "") {
      this.alertService.openSnackBar("Please select an app page.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (defaultColumnOrder == null || defaultColumnOrder == 0) {
      this.alertService.openSnackBar("Please enter a default column order.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (saveAppPageColumn) {
      let appPageColumn: AppPageColumn = new AppPageColumn;


      delete appPageColumn.rowguid;  //remove rowguid property so DB will create one.

      appPageColumn.dteDateCreated = new Date(datepipe.transform(dateCreated, 'yyyy-MM-dd'));
      appPageColumn.txtColumnName = columnName;
      appPageColumn.txtColumnNameAlias = columnNameAlias;
      appPageColumn.guAppPageId = appPageId;
      appPageColumn.intDefaultColumnOrder = defaultColumnOrder;

      console.log(appPageColumn);

      appPageColumn = await this.appPageColumnService.createAppPageColumnPromise(appPageColumn);

      if (mode == "Create") {
        //appPageColumn = await this.appPageColumnService.getAppPageColumnPromise(appPageColumn.rowguid);
        //this.appPageColumnCreated.emit(appPageColumn);

        if (appPageColumn != null) {
          let appPage: AppPage = await this.appPageService.getAppPagePromise(appPageColumn.rowguid);
          this.appPageColumnCreated.emit(appPage);
        }
      }

      if (mode == "CreateAndAdd") {
        this.resetFields(mode);
      }
    }


  }

  cancel() {
    this.resetFields('');
    this.appPageColumnCancelled.emit(true);
  }

  resetFields(mode: string) {

    if (mode != "CreateAndAdd") {
      this.form.controls['dteDateCreated'].setValue(new Date(Date.now()));
      this.form.controls['guAppPageId'].setValue('');
    }
    this.form.controls['txtColumnName'].setValue('');
    this.form.controls['txtColumnNameAlias'].setValue('');
    this.form.controls['intDefaultColumnOrder'].setValue(parseInt(this.form.controls['intDefaultColumnOrder'].value.toString()) + 1);

  }


}
