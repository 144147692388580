export interface LoadingDocketDelivery
{
	dteLastUpdated: Date;
	guDeliveryDocketId: string;
	guLoadingDocketId: string;
	intOrder: number;
	rowguid: string;
	txtDestination: string;
}

export class LoadingDocketDelivery
{
	dteLastUpdated: Date;
	guDeliveryDocketId: string;
	guLoadingDocketId: string;
	intOrder: number;
	rowguid: string;
	txtDestination: string;
}

