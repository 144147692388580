import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AppUser } from '../app-user-update/appuser.module';

@Component({
  selector: 'app-app-user-list',
  templateUrl: './app-user-list.component.html',
  styleUrl: './app-user-list.component.scss'
})
export class AppUserListComponent implements OnInit {

  @ViewChild('tblAppUser', { static: false }) tblAppUser: MatTable<AppUser>;

  @Input() datasource: MatTableDataSource<AppUser>;
  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() delOrderItemColumnBarContainer: string;
  @Input() columnListId: string;
  @Output() editAppUserEvent = new EventEmitter<AppUser>();
  @Output() addAppUserEvent = new EventEmitter<AppUser>();

  loadedComponent: boolean;

  displayedColumns: string[] = ['txtFirstName', 'txtLastName', 'txtUserName', 'txtFromAddresses', 'btnEditAppUser', 'btnRemoveAppUser'];

  constructor() {

  }


  ngOnInit(){

  }


  async editAppUser(appUser: AppUser) {
    this.editAppUserEvent.emit(appUser);


  }

  removeAppUser(appUserId: string) {


  }



}
