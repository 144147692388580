import { Component } from '@angular/core';

@Component({
  selector: 'app-dropbox-emails',
  templateUrl: './dropbox-emails.component.html',
  styleUrl: './dropbox-emails.component.scss'
})
export class DropboxEmailsComponent {

  onDropZone: boolean = false;
  readerData: any;
  addresses: string = "";

  readImage = (
    file
  ) => {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.readerData = e.target?.result;
        resolve(e.target?.result);
      };

      reader.onerror = (e) => {
        reject(`couldn't read image`);
      };

      reader.readAsDataURL(file);
    });
  };

  readAsText = (
    file
  ) => {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.readerData = e.target?.result;
      //let fileContents = document.getElementById("fileContents");
        //let emailFromAddresses = document.getElementById("emailFromAddresses");
        //let fileContentsDiv = document.getElementById("fileContentsDiv");


        
        //fileContentsDiv.innerHTML = e.target?.result.toString();
        //let innerHTML: string = e.target?.result.toString().replace(/ /g, "");

        //let fromIndex: number = fileContentsDiv.innerHTML.toString().indexOf("From: \"");
        ////console.log("fromIndex: " + fromIndex);
        ////console.log("less then arrow: " + fileContentsDiv.innerHTML.indexOf("&lt", fromIndex));
        ////console.log("innerHTML dot index: ", fileContentsDiv.innerHTML.substring(fileContentsDiv.innerHTML.indexOf("&lt", fromIndex), fromIndex + 5));

        //if (fromIndex == -1) {
        //  fromIndex = fileContentsDiv.innerHTML.toString().indexOf("From: ");
        //}

        //if (fromIndex > -1) {
        //  let leftArrowIndex: number = fileContentsDiv.innerHTML.indexOf('&lt', fromIndex);

        //  //console.log("leftArrowIndex: " + leftArrowIndex);

        //  if (leftArrowIndex > -1) {
        //    let rightArrowIndex: number = fileContentsDiv.innerHTML.indexOf("&gt", leftArrowIndex);

        //    //console.log("rightArrowIndex: " + rightArrowIndex);

        //    if (rightArrowIndex > -1) {
        //      emailFromAddresses.innerText += fileContentsDiv.innerHTML.substring(leftArrowIndex + 5, rightArrowIndex) + ",";
        //    }

        //  }
          
        //}


        resolve(e.target?.result);
      };

      reader.onerror = (e) => {
        reject(`couldn't read image`);
      };

      reader.readAsText(file);
    });

  };
  async uploadFile(e) {
    let fileByteArray: String | ArrayBuffer;
    let file = e.target.files[0];
    fileByteArray = await this.readImage(file);

    if (fileByteArray != null) {
      //this.fileAttachments.push(new FileAttachment(file.name.toString(), "", file.type, fileByteArray, null, file.size, new Date(Date.now())));
    }

    //let testImage: HTMLImageElement = document.getElementById("testImage") as HTMLImageElement;
    //testImage.src = this.fileByteArray[0].toString();
    //testImage.height = 100;
    //console.log("image", this.fileByteArray);

  }

  clearAddresses() {
    let emailFromAddresses = document.getElementById("emailFromAddresses");
    emailFromAddresses.innerHTML = "";
    this.addresses = "";
  }

  async dropHandler(ev) {

    //await this.getAddresses(ev);
    let emailFromAddresses = document.getElementById("emailFromAddresses");
    //emailFromAddresses.innerHTML = "";

    if (this.addresses != "") {
      this.addresses += "\n";
    }


    await this.loopAddress(ev);



    //console.log("this.addresses.substring(this.addresses.length - 1, 1): ", this.addresses.substring(this.addresses.length - 1, this.addresses.length));

    if (this.addresses.substring(this.addresses.length - 1, this.addresses.length) == "\n") {
      this.addresses = (this.addresses.substring(0, this.addresses.length - 1));
    }

    if (emailFromAddresses.innerHTML != "") {
      this.addresses = this.addresses;
    }
    emailFromAddresses.innerHTML = this.addresses;

  }

  async getAddresses(ev) {

    //console.log("this.onDropZone: " + this.onDropZone);

    if (this.onDropZone == false) {
      ev.preventDefault();
      ev.stopPropagation();
      //console.log("outside of drop zone");
      return
    }

    let addresses: string = "";

    //console.log("File(s) dropped");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    let fileByteArray: String | ArrayBuffer;
    //let emailFromAddresses = document.getElementById("emailFromAddresses");

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      
      [...ev.dataTransfer.items].forEach(async (item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {


          this.readerData = "";


          const file = item.getAsFile();
          fileByteArray = await this.readAsText(file)


          addresses += this.getSenderAddress(this.readerData) + ",";
          //emailFromAddresses.innerHTML = addresses;


          //console.log("addresses: ", addresses);
          //console.log("file.type.toString(): ", file.type.toString());

          //this.fileAttachments.push(new FileAttachment(file.name.toString(), "", file.type, fileByteArray, null, file.size, new Date(Date.now())));

        }
      });
      
      //await this.loopAddress(ev);


      //console.log("addresses: ", this.addresses);

    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach(async (file, i) => {

        fileByteArray = await this.readAsText(file)

        //this.fileAttachments.push(new FileAttachment(file.name.toString(), "", file.type, fileByteArray, null, file.size, new Date(Date.now())));
      });
    }


  }

  async loopAddress(ev) {
    return new Promise<string>(async resolve => {
      let fileByteArray: String | ArrayBuffer;
      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        let fileItems: DataTransferItemList = ev.dataTransfer.items;
        let itemLength: number = fileItems.length - 1;
        //console.log("fileItems.length: ", fileItems.length - 1);
        [...ev.dataTransfer.items].forEach(async (item, i) => {

          //console.log("fileItems", item);

          if (item.kind === "file") {


            this.readerData = "";

            //console.log("item.getAsFile() - before: ");

            const file = item.getAsFile();


            fileByteArray = await this.readAsText(file)

            //console.log("item.getAsFile() - after: ");

            //console.log("fileByteArray:", fileByteArray);
            this.addresses += this.getSenderAddress(this.readerData) + "\n";


            //console.log("file.type.toString(): ", file.type.toString());

            //this.fileAttachments.push(new FileAttachment(file.name.toString(), "", file.type, fileByteArray, null, file.size, new Date(Date.now())));

          }

          if (itemLength == i) {
            resolve(this.addresses);
          }

        });


      }
    });
  }

  saveFile() {


    var file = new Blob([this.addresses], { type: "text/html" });
    //if (window.navigator.msSaveOrOpenBlob) // IE10+
    //  window.navigator.msSaveOrOpenBlob(file, "BlockedSendersList.txt");
    //else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = "BlockedSendersList.txt";
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    //}


  }


  getSenderAddress(data: string) {
    let fileContentsDiv = document.createElement("Div");  // document.getElementById("fileContentsDiv");
    fileContentsDiv.innerHTML = data;

    let fromIndex: number = fileContentsDiv.innerHTML.toString().indexOf("From: \"");
    //console.log("fromIndex: " + fromIndex);
    //console.log("less then arrow: " + fileContentsDiv.innerHTML.indexOf("&lt", fromIndex));
    //console.log("innerHTML dot index: ", fileContentsDiv.innerHTML.substring(fileContentsDiv.innerHTML.indexOf("&lt", fromIndex), fromIndex + 5));

    if (fromIndex == -1) {
      fromIndex = fileContentsDiv.innerHTML.toString().indexOf("From: ");
    }

    if (fromIndex > -1) {
      let leftArrowIndex: number = fileContentsDiv.innerHTML.indexOf('&lt', fromIndex);

      //console.log("leftArrowIndex: " + leftArrowIndex);

      if (leftArrowIndex > -1) {
        let rightArrowIndex: number = fileContentsDiv.innerHTML.indexOf("&gt", leftArrowIndex);

        //console.log("rightArrowIndex: " + rightArrowIndex);

        if (rightArrowIndex > -1) {
          return fileContentsDiv.innerHTML.substring(leftArrowIndex + 5, rightArrowIndex);
        }

      }

      return "";
    }

  }


  dragOverHandler(ev) {

    this.onDropZone = true;

    console.log("File(s) in drop zone");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  dragOutHandler(ev) {

    this.onDropZone = false;

    console.log("File(s) NOT in drop zone");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    ev.stopPropagation();
  }


  async readFile(evt) {
    const [file] = evt.target.files
    if (!file) return
    const data = await file.text()
    //return this.processFileContent(data)
  }


}
