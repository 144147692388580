<form class="example-form" [formGroup]="form">

  <div class="stock-items-container">
    <h1>Stock Items</h1>

    <mat-tab-group class="chh-mat-tab-group stock-items-tab-group" color="accent" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabStockItems>
      <mat-tab label="Stock Items" class="tab-label">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Identifier</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="text" id="txtSearchIdentifier" formControlName="txtSearchIdentifier" (keyup.enter)="searchStockItems()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 55670,28631..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-2">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>SKU</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="text" id="txtSearchSKU" formControlName="txtSearchSKU" (keyup.enter)="searchStockItems()">
            </mat-form-field>
            <mat-icon matTooltip="click to paste clipboard content" class="paste_icon" (click)="pasteFromClipboard()">content_paste</mat-icon>&nbsp;
            <mat-icon matTooltip="click to clear SKU" class="backspace_icon" (click)="clearProductCode()">backspace</mat-icon>&nbsp;
          </div>
          <div class="search-column-3">
            <div class="header-row-2">
              <span>Geo Location</span>
              <mat-button-toggle-group #locationToggle name="fontStyle" aria-label="Font Style" formControlName="guGeoStockLocationId" color="accent">
                <mat-button-toggle *ngFor="let geoStockLocation of geoStockLocations" [value]="geoStockLocation.rowguid">{{geoStockLocation.txtShortName}}</mat-button-toggle>
              </mat-button-toggle-group>
              <br />
              <br />
            </div>
          </div>
          <div class="search-column-3">
            <button class="search-stock-items-button" type="button" mat-flat-button color="primary" id="btnSearchStockItemsMain" (click)="searchStockItems()">Search</button>
          </div>
          <div class="console-log-message" id="consoleLogMessage"></div>
        </div>

        <div class="search-totals-container">
          <div class="search-total-rows">Total Rows: {{totalRows}}</div><div class="search-total-lm">Total LM: {{totalLM | number:'1.0':'en-AU'}}</div><div class="search-total-lm">Total M2: {{totalM2 | number:'1.0':'en-AU'}}</div>
          <div class="search-total-lm">Total M3: {{totalM3 | number:'1.0':'en-AU'}}</div><div class="search-total-pieces">Total Pieces: {{totalPieces}}</div><div class="search-total-pieces">Results Time: {{resultTime}} s</div>
        </div>
        <mat-divider></mat-divider>

        <div class="stock-item-list-container">

          <app-stock-item-list [datasource]="stockItems" (editStockItemEvent)="editStockItem($event)" (addStockItemEvent)="addStockItem($event)"></app-stock-item-list>
        </div>

      </mat-tab>
      <mat-tab label="Search Options" class="tab-label">
        <div class="search-options-fields-container" id="searchFieldsContainer">

          <div class="search-option-row-1">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Product</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Solid Flooring" value="" type="text" id="txtProductName" formControlName="txtProductName" (keyup.enter)="searchStockItems()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full product name i.e. Sl*d Flooring" class="help-icon">help_outline</mat-icon>
            <span class="search-options-search-button">
              <button class="search-stock-items-button" type="button" mat-flat-button color="primary" id="btnSearchStockItemsProduct" (click)="searchStockItems()">Search</button>
            </span>
          </div>

          <div class="search-option-row-2">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Profile</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Tongue and Groove" value="" type="text" id="txtProfileName" formControlName="txtProfileName" (keyup.enter)="searchStockItems()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full profile name i.e. To*ue and Groove" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-3">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Width</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 80" value="" type="text" formControlName="txtWidth" (keyup.enter)="searchStockItems()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text..." class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-4">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Thickness</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 19" value="" type="text" formControlName="txtThickness" (keyup.enter)="searchStockItems()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text..." class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-4">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Length</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 2.4" value="" type="text" formControlName="txtLength" (keyup.enter)="searchStockItems()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text..." class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-5">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Species</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Blackbutt" value="" type="text" formControlName="txtSpeciesName" (keyup.enter)="searchStockItems()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full species name i.e. Bl*butt" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-6">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Grade</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Select" value="" type="text" formControlName="txtGradeName" (keyup.enter)="searchStockItems()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full grade name i.e. Standard*" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-3">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created From</mat-label>
              <input id="dteSearchDateCreatedFrom" class="date-input receipt-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteSearchDateCreatedFrom">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-4">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created To</mat-label>
              <input id="dteSearchDateCreatedTo" class="date-input receipt-date-search" #searchReceiptDateTo matInput [matDatepicker]="pickercreatedto" formControlName="dteSearchDateCreatedTo">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedto"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedto></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-6">
            <div class="delivery-status-container">
              <label id="example-radio-group-label">Delivery Status</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radDeliveryStatus">
                <mat-radio-button class="example-radio-button" *ngFor="let deliveryStatus of deliveryStatuses" [value]="deliveryStatus.intValue">
                  {{deliveryStatus.txtValue}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="search-option-row-6">
            <div class="archived-container">
              <label id="example-radio-group-label">Container Archived</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radContainerArchived">
                <mat-radio-button class="example-radio-button" [value]="0">Active</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">Archived</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="-1">Both</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="search-option-row-6">
            <div class="item-archived-container">
              <label id="example-radio-group-label">Item Archived</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radItemArchived">
                <mat-radio-button class="example-radio-button" [value]="0">Active</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">Archived</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="-1">Both</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="search-option-row-6">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Retail Value</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Select" value="" type="text" formControlName="txtRetailValue" (keyup.enter)="searchStockItems()">
            </mat-form-field>
          </div>

          <div class="search-option-row-6">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Trade Value</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Select" value="" type="text" formControlName="txtTradeValue" (keyup.enter)="searchStockItems()">
            </mat-form-field>
          </div>

          <div class="search-option-row-6">
            <div class="approved-pricing-container">
              <label id="example-radio-group-label">Approved Pricing</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radApprovedPricing">
                <mat-radio-button class="example-radio-button" [value]="0">Approved Pricing</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">Non Approved Pricing</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="-1">Both</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="search-option-row-6">
            <div class="on-order-container">
              <label id="example-radio-group-label">On Order</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radOnOrder">
                <mat-radio-button class="example-radio-button" [value]="0" [checked]="true">No</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">Yes</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="search-option-row-6">
            <div class="on-order-container">
              <label id="example-radio-group-label">SLOB Stock</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radSlobStock">
                <mat-radio-button class="example-radio-button" [value]="0">No</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">Yes</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="-1" [checked]="true">Either</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="search-option-row-6">
            <div class="on-order-container">
              <label id="example-radio-group-label">Clearance Stock</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radClearanceStock">
                <mat-radio-button class="example-radio-button" [value]="0">No</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">Yes</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="-1" [checked]="true">Either</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="search-option-row-7">
            <button class="search-option-delivery-docket-button" type="button" mat-flat-button color="primary" id="btnSearchStockItems" (click)="searchStockItems()">Search</button>
          </div>

        </div>
      </mat-tab>

      <mat-tab label="Create Stock Item" class="tab-label" (click)="createStockItem()">
        <div class="create-stock-item-container">
          <app-stock-item-create-by-product-code [mode]="createStockItemMode" [boardTallyRecordHistory]="addToStockItem" (stockCreated)="stockCreated($event)" *ngIf="selectedTab == eStockItemsTabs.CreateStockItem"></app-stock-item-create-by-product-code>
        </div>
      </mat-tab>

      <mat-tab label="Update Stock Item" class="tab-label">
        <div class="update-stock-item-container">
          <app-stock-item-update [boardTallyRecordHistory]="boardTallyRecordHistory" (stockUpdated)="stockUpdated($event)" *ngIf="selectedTab == eStockItemsTabs.UpdateStockItem"></app-stock-item-update>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showProductsSpinner">
  <div [@displaySpinner]="showProductsSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showProductsSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
