import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FieldQueryParamMap } from '../../../_shared/app-objects/field-query-param-map.ao';
import { dtoRoyaltyRate } from '../../../_shared/business-objects/dto-royalty-rate.bo';
import { RoyaltyRate } from '../../../_shared/business-objects/royalty-rate.bo';
import { AlertService } from '../../../_shared/services/alert.service';
import { FormService } from '../../../_shared/services/form.service';
import { SharedService } from '../../../_shared/services/shared.service';
import { RoyaltyRateListComponent } from '../royalty-rate-list/royalty-rate-list.component';
import { dtoRoyaltyRateService } from '../../../_shared/services/dto-royalty-rate.service';
import { RoyaltyRateService } from '../../../_shared/services/royalty-rate.service';
import { DatePipe } from '@angular/common';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { SQLParamArray } from '../../../_shared/business-objects/sql-param-array';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

export enum enRoyaltyRateTabs { RoyaltyRates = 0, SearchOptions = 1, CreateRoyaltyRate = 2, UpdateRoyaltyRate = 3, DocketAddItems = 4 }


@Component({
  selector: 'app-royalty-rate-search',
  templateUrl: './royalty-rate-search.component.html',
  styleUrls: ['./royalty-rate-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ], encapsulation: ViewEncapsulation.None
})
export class RoyaltyRateSearchComponent implements OnInit {

  @ViewChild('appRoyaltyRateList', { static: false }) royaltyRateList: RoyaltyRateListComponent;
  @ViewChild('tabRoyaltyRates', { static: false }) tabRoyaltyRates: MatTabGroup;

  form: FormGroup;
  public eRoyaltyRateTabs = enRoyaltyRateTabs;

  selectedTab: number;
  showTable: boolean = false;
  showSpinner: boolean = false;
  royaltyRates: dtoRoyaltyRate[] = [];
  royaltyRate: RoyaltyRate;

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  search: boolean = false;
  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private formService: FormService
    , private dtoRoyaltyRateService: dtoRoyaltyRateService, private royaltyRateService: RoyaltyRateService) {

    this.form = this.fb.group({
      txtSearchDurabilityClass: [''],
      txtSearchLength: [''],
      txtSearchKN: [''],
      dteSearchDateCreatedFrom: [''],
      dteSearchDateCreatedTo: [''],
      dteSearchExpiryFrom: [''],
      dteSearchExpiryTo: [''],
      radCurrent: ['']
    });

    //this.filterToQueryParamMap.push({ txtField: "txtSearchRoyaltyRateNo", txtQueryParam: "docketno", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });


  }

  ngOnInit(): void {

    this.selectedTab = this.eRoyaltyRateTabs.RoyaltyRates;


  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    console.log(e.index == this.eRoyaltyRateTabs.SearchOptions);

    if (e.index == this.eRoyaltyRateTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchLoadingDocketNo");
    }

  }


  async searchRoyaltyRates() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let durabilityClass: string = this.form.controls["txtSearchDurabilityClass"].value;
    let length: string = this.form.controls["txtSearchLength"].value;
    let kn: string = this.form.controls["txtSearchKN"].value;
    let createdFrom: string = this.form.controls["dteSearchDateCreatedFrom"].value;
    let createdTo: string = this.form.controls["dteSearchDateCreatedTo"].value;
    let expiryFrom: string = this.form.controls["dteSearchExpiryFrom"].value;
    let expiryTo: string = this.form.controls["dteSearchExpiryTo"].value;
    let current: number = this.form.controls["radCurrent"].value;
    const datepipe: DatePipe = new DatePipe('en-AU');


    if (durabilityClass != null && durabilityClass != "") {
      aParam = [];
      if (durabilityClass.toString().indexOf(",")) {
        let aDurabilityClasses: string[] = durabilityClass.toString().split(",");
        for (let i = 0; i <= aDurabilityClasses.length - 1; i++) {
          aParam.push(new SQLParamArray("txtDurabilityClass", aDurabilityClasses[i]))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtDurabilityClass", durabilityClass))
      }
      aParamList.push(aParam);
    }

    if (length != null && length != "") {
      aParam = [];
      if (length.indexOf(",")) {
        let aLengths: string[] = length.split(",");
        for (let i = 0; i <= aLengths.length - 1; i++) {
          aParam.push(new SQLParamArray("intLength", aLengths[i]))
        }
      }
      else {
        aParam.push(new SQLParamArray("intLength", length))
      }
      aParamList.push(aParam);
    }

    if (kn != null && kn != "") {
      aParam = [];
      if (length.indexOf(",")) {
        let aKNs: string[] = kn.split(",");
        for (let i = 0; i <= aKNs.length - 1; i++) {
          aParam.push(new SQLParamArray("intKN", aKNs[i]))
        }
      }
      else {
        aParam.push(new SQLParamArray("intKN", kn))
      }
      aParamList.push(aParam);
    }

    if (createdFrom != null && createdFrom != "") {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }

    if (createdTo != null && createdTo != "") {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND));
      aParamList.push(aParam);
    }


    if (expiryFrom != null && expiryFrom != "") {
      aParam = [];
      aParam.push(new SQLParamArray("dteExpiry", datepipe.transform(expiryFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND));
      aParamList.push(aParam);
    }

    if (expiryTo != null && expiryTo != "") {
      aParam = [];
      aParam.push(new SQLParamArray("dteExpiry", datepipe.transform(expiryTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND));
      aParamList.push(aParam);
    }

    if (current != null && current < 2) {
      aParam = [];
      aParam.push(new SQLParamArray("blnCurrent", current == 0 ? "1" : "0"));
      aParamList.push(aParam);
    }


    this.showTable = false;

    if (aParamList.length == 0 && current < 2) {
      this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "centerSnackbar", 4000);
    }

    this.showSpinner = true;

    this.tabRoyaltyRates.selectedIndex = 0;
    
    this.royaltyRates = await this.dtoRoyaltyRateService.getdtoRoyaltyRateParamArrayPromise(aParamList);
    this.royaltyRates.sort((a, b) => {
      return a.intLength - b.intLength || a.intKn - b.intKn || (a.txtDurabilityClass > b.txtDurabilityClass ? 1 : -1)
    });

    this.showTable = true
    this.showSpinner = false;

  }


  async editRoyaltyRate(royaltyRate: dtoRoyaltyRate) {

    this.royaltyRate = await this.royaltyRateService.getRoyaltyRatePromise(royaltyRate.rowguid);

    this.tabRoyaltyRates.selectedIndex = this.eRoyaltyRateTabs.UpdateRoyaltyRate;

  }

  async royaltyRateCreated(royaltyRateCreatedObj: [RoyaltyRate, string]) {
    if (royaltyRateCreatedObj == null) {
      return;
    }

    if (royaltyRateCreatedObj[1] == "create") {
      this.tabRoyaltyRates.selectedIndex = this.eRoyaltyRateTabs.RoyaltyRates;
      //this.form.controls['txtSearchDurabilityClass'].setValue(royaltyRateCreatedObj[0].intDurabilityClass.toString());
      this.searchRoyaltyRates();
    }

  }

  async royaltyRateUpdated(royaltyRate: RoyaltyRate) {

    if (royaltyRate == null) {
      return;
    }

    this.tabRoyaltyRates.selectedIndex = this.eRoyaltyRateTabs.RoyaltyRates;

    //this.form.controls['txtSearchDurabilityClass'].setValue(royaltyRate.intDurabilityClass);

    this.searchRoyaltyRates();

  }

  royaltyRateCancelled(cancelled: boolean) {
    this.tabRoyaltyRates.selectedIndex = this.eRoyaltyRateTabs.RoyaltyRates;
  }


  royaltyRatesUpdated(updated: boolean) {

  }


  royaltyRatesCancelled(cancelled: boolean) {

  }

}
