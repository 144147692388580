import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PoleTrimmingItem } from '../../_shared/business-objects/pole-trimming-item.bo';


@Injectable()
export class PoleTrimmingItemService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getPoleTrimmingItemsTodaysList() {
    return this.http.get(this._baseUrl + 'api/poletrimmingitem/GetTodaysList'); 
  }

  getPoleTrimmingItemsLast3DaysList() {
    return this.http.get(this._baseUrl + 'api/poletrimmingitem/GetLast3DaysList');
  }

  getPoleTrimmingItemsListByDates(dateFrom, dateTo) {
    return this.http.get(this._baseUrl + 'api/poletrimmingitem/GetListByDate/'+dateFrom+'/'+dateTo);
  }

  getPoleTrimmingItemByPoleNumber(poleNumber) {
    return this.http.get<PoleTrimmingItem>(this._baseUrl + 'api/poletrimmingitem/GetItemByPoleNumber/' + poleNumber);
  }

  getPoleTrimmingItemByRowguid(gPoleTrimmingItemId) {
    return this.http.get<PoleTrimmingItem>(this._baseUrl + 'api/poletrimmingitem/GetItemByRowguid/' + gPoleTrimmingItemId);
  }

  getTrimmingItem(polenumber) {
    return this.http.get(this._baseUrl + 'api/poletrimmingitem/' + polenumber);
  }


  setPoleTrimmingItem(poleTrimmingItem: PoleTrimmingItem) {
    return this.http.put<any>(this._baseUrl + 'api/poletrimmingitem/AddPoleTrimmingItemByInspectionItem/', poleTrimmingItem);
  }


  addPoleTrimmingItem(poleTrimmingItem: PoleTrimmingItem) {
    return this.http.post<any>(this._baseUrl + 'api/poletrimmingitem/AddPoleTrimming/', poleTrimmingItem);
  }

  updatePoleTrimmingItem(poleTrimmingItem: PoleTrimmingItem) {
    console.log(JSON.stringify(poleTrimmingItem));
    return this.http.put<any>(this._baseUrl + 'api/poletrimmingitem/UpdatePoleTrimming/', poleTrimmingItem);
  }
  

}  
