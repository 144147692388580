export interface DiscTemplateConfig
{
	blnActive: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guElectricalAuthoritySpecId: string;
	intComputerInputField: number;
	intCSVColumnNumber: number;
	intInputType: number;
	rowguid: string;
	txtComments: string;
	txtUserInputValue: string;
}

export class DiscTemplateConfig
{
	blnActive: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guElectricalAuthoritySpecId: string;
	intComputerInputField: number;
	intCSVColumnNumber: number;
	intInputType: number;
	rowguid: string;
	txtComments: string;
	txtUserInputValue: string;
}

export namespace DiscTemplateConfig
{
	export enum enComputerInputField
	{
		DateCreated,
		ElectricalAuthorityDiscCode,
		HazardLevel,
		TreatmentChargeNo,
		TreatmentDay,
		TreatmentMonth,
		TreatmentYear,
		PoleLength,
		PoleKN,
		PoleUltimateKN,
		Species,
		PoleNumber,
		DayCreated,
		MonthCreated,
		YearCreated,
		StrengthGroup
	}
}

export namespace DiscTemplateConfig
{
	export enum enInputType
	{
		User,
		Computer
	}
}

