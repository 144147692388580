import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { share } from 'rxjs/operators';
import { AuthService } from '../../account/auth.service';
import { dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { dtoLoadingDocket } from '../../_shared/business-objects/dto-loading-docket.bo';
import { LoadingDocket } from '../../_shared/business-objects/loading-docket.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoLoadingDocketService } from '../../_shared/services/dto-loading-docket.service';
import { SharedService } from '../../_shared/services/shared.service';
import { UserPositionService } from '../../_shared/services/user-position.service';

@Component({
  selector: 'app-link-existing-loading-docket',
  templateUrl: './link-existing-loading-docket.component.html',
  styleUrls: ['./link-existing-loading-docket.component.css']
})
export class LinkExistingLoadingDocketComponent {

  @Input() selectedLoadingDocket: dtoLoadingDocket;
  @Output() loadingDocketSelectionEvent = new EventEmitter<dtoLoadingDocket>();
  @Output() linkLoadingDocketCancelled = new EventEmitter<boolean>();

  @ViewChild('guLoadingDocketId') guLoadingDocketId: MatSelectionList;
  form: FormGroup;

  public loadingDockets: dtoLoadingDocket[] = [];
  yardSections: dtoKeyValue[] = [];
  treatmentPlantYardSection: boolean = false;
  boardPlantYardSection: boolean = false;
  crossarmYardSection: boolean = false;
  millYardSection: boolean = false;

  yardSectionLoadFilter: string;
  yardSection: SharedService.enYardSection;
  yardSectionsSelected: SharedService.enYardSection[] = [];

  constructor(private dtoLoadingDocketService: dtoLoadingDocketService, private alertService: AlertService, private formBuilder: FormBuilder
    , private sharedService: SharedService, private cdref: ChangeDetectorRef, private userPositionService: UserPositionService, private authService: AuthService) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      guLoadingDocketId: [''],
      intYardSection: [''],
      txtSearchLoadingDocketNo:['']
    });

    this.yardSections = this.sharedService.getEnumObject2(SharedService.enYardSection);
    this.yardSections.sort((a, b) => { return a.txtValue > b.txtValue ? 1 : -1 });
    let ys: dtoKeyValue = new dtoKeyValue();
    ys.intValue = -1;
    ys.txtValue = "None";
    this.yardSections.splice(0, 0, ys);

    this.loadData();




  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  public async loadData() {

    let yardSection: number = this.form.controls['intYardSection'].value;
    if (yardSection == null) {
      yardSection = -1;
    }

    // get last 10 on first load
    this.loadingDockets = await this.dtoLoadingDocketService.getLastdtoLoadingDocketsPromise(10, yardSection);

    this.addExistingLoadingDocket();
    this.addBlankLoadingDocket();

    //console.log("selectedLoadingDocket: ", this.selectedLoadingDocket);

    // Only one of these should exist other than office staff.
    this.treatmentPlantYardSection = await this.userPositionService.isInPosition("tp supervisor", this.authService.getAppUserEmployeeId());
    this.boardPlantYardSection = await this.userPositionService.isInPosition("board plant sales staff", this.authService.getAppUserEmployeeId());
    this.crossarmYardSection = await this.userPositionService.isInPosition("crossarms", this.authService.getAppUserEmployeeId());
    this.millYardSection = await this.userPositionService.isInPosition("mill", this.authService.getAppUserEmployeeId());

    this.yardSection = SharedService.enYardSection.All;
    this.yardSectionLoadFilter = "All";

    if (this.treatmentPlantYardSection == true) {
      this.yardSection = SharedService.enYardSection.TreatmentPlant;
      this.yardSectionLoadFilter = "Treatment Plant";
      this.yardSectionsSelected.push(this.yardSection);

      this.form.controls['intYardSection'].setValue(this.yardSection);

      console.log("YardSection: ", this.yardSection);

      //console.log(this.yardSectionsSelected);
    }

    if (this.boardPlantYardSection == true) {
      this.yardSection = SharedService.enYardSection.BoardPlant;
      this.yardSectionLoadFilter = "Board Plant";
      this.yardSectionsSelected.push(this.yardSection);
    }

    if (this.crossarmYardSection == true) {
      this.yardSection = SharedService.enYardSection.XArms;
      this.yardSectionLoadFilter = "Crossarms";
      this.yardSectionsSelected.push(this.yardSection);
    }

    if (this.millYardSection == true) {
      this.yardSection = SharedService.enYardSection.Mill;
      this.yardSectionLoadFilter = "Mill";
      this.yardSectionsSelected.push(this.yardSection);
    }


  }


  loadingDocketSelect(loadingDocket: dtoLoadingDocket[]) {
    //console.log("loadingDocket: ", loadingDocket[0]);
    this.selectedLoadingDocket = loadingDocket[0];

  }

  async searchLoadingDocket() {
    let loadingDocketNo: number = this.form.controls['txtSearchLoadingDocketNo'].value;

    if (loadingDocketNo == null || Number(loadingDocketNo) == 0) {
      this.alertService.openSnackBarDefault("Please enter a loading docket number");
      return;
    }

    if (loadingDocketNo != null) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("intDocketNo", loadingDocketNo.toString()));
      aParamList.push(aParam);

      this.loadingDockets = await this.dtoLoadingDocketService.getdtoLoadingDocketParamArrayPromise(aParamList);

    }


  }


  async searchLast10LoadingDockets() {

    let yardSection: number = this.form.controls['intYardSection'].value;

    if (yardSection == null) {
      yardSection = -1;
    }



    // get last 10 on first load
    this.loadingDockets = await this.dtoLoadingDocketService.getLastdtoLoadingDocketsPromise(10, yardSection);

    this.addExistingLoadingDocket();
    this.addBlankLoadingDocket();

  }

  async searchReadyForLoading() {

    this.seachLoadingDockets(LoadingDocket.enLoadingDocketStatus.ReadyForLoading);
    
  }

  async searchScheduled() {

    this.seachLoadingDockets(LoadingDocket.enLoadingDocketStatus.Scheduled);

  }

  async seachLoadingDockets(status: LoadingDocket.enLoadingDocketStatus) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (status != null) {
      aParam.push(new SQLParamArray("intStatus", status.toString()));
      aParamList.push(aParam);
    }

    let yardSection: number = this.form.controls['intYardSection'].value;
    if (yardSection != null && Number(yardSection) > -1) {

      aParam = [];
      aParam.push(new SQLParamArray("intYardSection", yardSection.toString()));
      aParamList.push(aParam);

    }

    this.loadingDockets = await this.dtoLoadingDocketService.getdtoLoadingDocketParamArrayPromise(aParamList);

    this.addExistingLoadingDocket();
    this.addBlankLoadingDocket();

  }

  selectLoadingDocket() {

    //console.log("selectLoadingDocket", this.selectedLoadingDocket);

    this.loadingDocketSelectionEvent.emit(this.selectedLoadingDocket);

  }

  cancel() {
    this.linkLoadingDocketCancelled.emit(true);
  }

  addBlankLoadingDocket() {
    let blankLoadingDocket: dtoLoadingDocket = new dtoLoadingDocket();
    blankLoadingDocket.rowguid = null;
    blankLoadingDocket.intDocketNo = 0;
    blankLoadingDocket.txtCustomerLocation = "None";

    this.loadingDockets.splice(0, 0, blankLoadingDocket);
  }

  addExistingLoadingDocket() {

    let exists: boolean = false;
    if (this.selectedLoadingDocket != null) {
      for (let i = 0; i <= this.loadingDockets.length - 1; i++) {
        if (this.selectedLoadingDocket.rowguid == this.loadingDockets[i].rowguid) {
          exists = true;
          break;
        }
      }
    }

    if (this.selectedLoadingDocket != null && exists == false) {
      this.loadingDockets.splice(0, 0, this.selectedLoadingDocket);
    }
  }

}
