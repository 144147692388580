import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Employee } from '../business-objects/employee.bo';
import { AlertService } from './alert.service';
import { SQLParamArray } from '../business-objects/sql-param-array';

@Injectable()
export class EmployeeService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getEmployee(username: string) {
    return this.http.get<Employee>(this._baseUrl + 'api/employee/GetEmployee/' + username);
  }

  getEmployeeByRowguid(employeeId: string) {
    return this.http.get<Employee>(this._baseUrl + 'api/employee/GetEmployeeByRowguid/' + employeeId);
  }

  getEmployeeList() {
    return this.http.get(this._baseUrl + 'api/employee/GetList/');
  }

  getEmployeesInPosition(position: string) {
    return this.http.get<Employee[]>(this._baseUrl + 'api/employee/GetEmployeesInPosition/' + position);
  }

  getCurrentUserLoggedIn() {
  }

  getEmployeeParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<Employee[]>(this._baseUrl + 'api/Employee/GetEmployeesParamArray/', aParam);
  }

  async getEmployeeParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<Employee[]>(resolve => {
      this.getEmployeeParamArray(aParam).subscribe(employees => {
        resolve(employees);
      }, err => {
        this.alertService.openSnackBarError("Error getting Employee records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async getEmployeePromise(employeeId: string) {
    return new Promise<Employee>(resolve => {
      this.getEmployeeByRowguid(employeeId).subscribe(employee => {
        resolve(employee);
      }, err => {
        this.alertService.openSnackBarError("Error getting Employee record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

}  
