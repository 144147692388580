<div class="delivery-docket-rate-container">
  <div class="delivery-docket-rate-form-container">
    <div class="delivery-docket-rate-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="header-row-4">
            <div class="species">
              <mat-selection-list class="delivery-rate-select" #guOrdersDeliveryRateId [multiple]="false" formControlName="guOrdersDeliveryRateId">
                <mat-list-option *ngFor="let ordersDeliveryRate of deliveryRates" [value]="ordersDeliveryRate.rowguid" [disabled]="ordersDeliveryRate.guDeliveryDocketId != null" (click)="selectDeliveryRate(ordersDeliveryRate.rowguid)">
                  {{ordersDeliveryRate.txtName}} - {{ordersDeliveryRate.txtRateType}}  ${{ordersDeliveryRate.fltPrice}}  {{ordersDeliveryRate.fltEscort > 0 ? 'Escort: $' + ordersDeliveryRate.fltEscort : '' }}  {{ordersDeliveryRate.fltAdditionalKM > 0 ? 'Additional KM: $' + ordersDeliveryRate.fltAdditionalKM : '' }}
                </mat-list-option>
              </mat-selection-list>
              <br />
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>

<div class="spinner-container">
  <mat-card [@displaySpinner]="transitionProducts ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showSpinner">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
