import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeliveryDocketOrderItem } from '../business-objects/delivery-docket-order-item.bo';
import { SharedService } from './shared.service';
import { AlertService } from './alert.service';


@Injectable()
export class DeliveryDocketOrderItemService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getDeliveryDocketOrderItems() {
    return this.http.get<DeliveryDocketOrderItem[]>(this._baseUrl + 'api/deliverydocketorderitem');
  }

  getDeliveryDocketOrderItem(deliveryDocketOrderItemId: string) {
    return this.http.get<DeliveryDocketOrderItem>(this._baseUrl + 'api/deliverydocketorderitem/' + deliveryDocketOrderItemId);
  }

  getBlankDeliveryDocketOrderItem() {
    return this.http.get<DeliveryDocketOrderItem>(this._baseUrl + 'api/deliverydocketorderitem/GetBlankDeliveryDocketOrderItem/');
  }

  getDeliveryDocketOrderItemByDocketId(docketId: string) {
    return this.http.get<DeliveryDocketOrderItem[]>(this._baseUrl + 'api/Deliverydocketorderitem/GetDeliveryDocketOrderItemByDocketId/' + docketId);
  }

  getDeliveryDocketOrderItemById(deliveryDocketId: string) {
    return this.http.get<DeliveryDocketOrderItem>(this._baseUrl + 'api/deliverydocketorderitem/GetDeliveryDocketOrderItemById/' + deliveryDocketId);
  }

  //getDeliveryDocketOrderItemById(docketId: string) {
  //  return this.http.get<DeliveryDocketOrderItem>(this._baseUrl + 'api/deliverydocketorderitem/GetDeliveryDocketOrderItemById/' + docketId);
  //}

  createDeliveryDocektOrderItem(deliveryDocketOrderItem: DeliveryDocketOrderItem) {
    console.log(this._baseUrl + 'api/deliverydocketorderitem/', JSON.stringify(deliveryDocketOrderItem));
    return this.http.post<DeliveryDocketOrderItem>(this._baseUrl + 'api/deliverydocketorderitem/', deliveryDocketOrderItem);
  }

  updateDeliveryDocektOrderItem(deliveryDocketOrderItem: DeliveryDocketOrderItem) {
    return this.http.put<DeliveryDocketOrderItem>(this._baseUrl + 'api/deliverydocketorderitem/', deliveryDocketOrderItem);
  }

  removeDeliveryDocektOrderItem(deliveryDocketOrderItemId: string) {
    return this.http.delete(this._baseUrl + 'api/deliverydocketorderitem/DeleteDeliveryDocketOrderItem/' + deliveryDocketOrderItemId);
  }

  async createDeliveryDocektOrderItemPromise(deliveryDocketOrderItem: DeliveryDocketOrderItem) {
    return new Promise<DeliveryDocketOrderItem>(resolve => {
      this.createDeliveryDocektOrderItem(deliveryDocketOrderItem).subscribe(deliveryDocketOrderItem => {
        resolve(deliveryDocketOrderItem);
      }, err => {
        this.alertService.openSnackBarError("Error creating DeliveryDocketOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async updateDeliveryDocektOrderItemPromise(deliveryDocketOrderItem: DeliveryDocketOrderItem) {
    return new Promise<DeliveryDocketOrderItem>(resolve => {
      this.updateDeliveryDocektOrderItem(deliveryDocketOrderItem).subscribe(deliveryDocketOrderItem => {
        resolve(deliveryDocketOrderItem);
      }, err => {
        this.alertService.openSnackBarError("Error updating DeliveryDocketOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getDeliveryDocketOrderItemPromise(deliveryDocketOrderItemId: string) {
    return new Promise<DeliveryDocketOrderItem>(resolve => {
      this.getDeliveryDocketOrderItem(deliveryDocketOrderItemId).subscribe(deliveryDocketOrderItem => {
        resolve(deliveryDocketOrderItem);
      }, err => {
        this.alertService.openSnackBarError("Error getting DeliveryDocketOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async removeDeliveryDocektOrderItemPromise(deliveryDocketOrderItemId: string) {
    return new Promise<boolean>(resolve => {
      this.removeDeliveryDocektOrderItem(deliveryDocketOrderItemId).subscribe(deliveryDocketOrderItem => {
        resolve(true);
      }, err => {
        this.alertService.openSnackBarError("Error removing DeliveryDocketOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getBlankDeliveryDocketOrderItemPromise() {
    return new Promise<DeliveryDocketOrderItem>(resolve => {
      this.getBlankDeliveryDocketOrderItem().subscribe(deliveryDocketOrderItem => {
        resolve(deliveryDocketOrderItem);
      }, err => {
        this.alertService.openSnackBarError("Error getting DeliveryDocketOrderItem record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async reSetItemNumbers(deliveryDocketOrderItemId: string) {
    return new Promise<DeliveryDocketOrderItem[]>(resolve => {
      this.http.get<DeliveryDocketOrderItem[]>(this._baseUrl + 'api/DeliveryDocketOrderItem/ReSetItemNumbers/' + deliveryDocketOrderItemId).subscribe(deliveryDocketOrderItems => {
        resolve(deliveryDocketOrderItems);
      }, err => {
        this.alertService.openSnackBarError("Error resetting DeliveryDocketOrderItem item numbers", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getDeliveryDocketOrderItemByDocketIdPromise(docketId: string) {
    return new Promise<DeliveryDocketOrderItem[]>(resolve => {
      this.getDeliveryDocketOrderItemByDocketId(docketId).subscribe(dtoDeliveryDocketOrderItems => {
        resolve(dtoDeliveryDocketOrderItems);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoDeliveryDocketOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  //async getDeliveryDocketOrderItemByDocketIdPromise(docketId: string) {
  //  return new Promise<DeliveryDocketOrderItem>(resolve => {
  //    this.getDeliveryDocketOrderItemById(docketId).subscribe(deliveryDocketOrderItem => {
  //      resolve(deliveryDocketOrderItem);
  //    }, err => {
  //      this.alertService.openSnackBarError("Error getting DeliveryDocketOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
  //      resolve(null);
  //    });
  //  });
  //}

  //mapDeliveryDocketFromDTO(stockItem: dtoStockItem, excludeProperties: Array<string>): DeliveryDocket {
  //  var deliveryDocket: DeliveryDocket = new DeliveryDocket();

  //  for (let [key, value] of Object.entries(stockItem)) {
  //    if (excludeProperties.indexOf(key) == -1) {
  //      console.log("Property " + key + ",  val: " + value);

  //      deliveryDocket[key] = value;

  //      if (value == this.sharedService.EmptyGuid) {
  //        deliveryDocket[key] = null;
  //      }

  //      if (value == this.sharedService.MinDate) {
  //        console.log("date min value");
  //        deliveryDocket[key] = null;
  //      }

  //    }
  //  }
  //  return deliveryDocket;
  //}


}  
