import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoAppUserPrinterDefault } from '../business-objects/dto-app-user-printer-default.bo';

@Injectable()
export class dtoAppUserPrinterDefaultService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoAppUserPrinterDefault(dtoAppUserPrinterDefaultId: string) {
		return this.http.get<dtoAppUserPrinterDefault>(this._baseUrl + 'api/dtoAppUserPrinterDefault/' + dtoAppUserPrinterDefaultId);
	}

	getdtoAppUserPrinterDefaultParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoAppUserPrinterDefault[]>(this._baseUrl + 'api/dtoAppUserPrinterDefault/GetdtoAppUserPrinterDefaultsParamArray/', aParam);
	}

	getAlldtoAppUserPrinterDefaults() {
		return this.http.get<dtoAppUserPrinterDefault[]>(this._baseUrl + 'api/dtoAppUserPrinterDefault/GetAlldtoAppUserPrinterDefaults/');
	}

	//getAlldtodtoAppUserPrinterDefaults() {
	//	return this.http.get<dtodtoAppUserPrinterDefault[]>(this._baseUrl + 'api/dtoAppUserPrinterDefault/GetAlldtoAppUserPrinterDefaults/');
	//}

	createdtoAppUserPrinterDefault(dtoAppUserPrinterDefault: dtoAppUserPrinterDefault) {
		return this.http.post<dtoAppUserPrinterDefault>(this._baseUrl + 'api/dtoAppUserPrinterDefault/CreatedtoAppUserPrinterDefault/', dtoAppUserPrinterDefault);
	}

	updatedtoAppUserPrinterDefault(dtoAppUserPrinterDefault: dtoAppUserPrinterDefault) {
		return this.http.put<dtoAppUserPrinterDefault>(this._baseUrl + 'api/dtoAppUserPrinterDefault/UpdatedtoAppUserPrinterDefault/', dtoAppUserPrinterDefault);
	}

	deletedtoAppUserPrinterDefault(dtoAppUserPrinterDefaultId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoAppUserPrinterDefault/DeletedtoAppUserPrinterDefault/' + dtoAppUserPrinterDefaultId);
	}

	async getdtoAppUserPrinterDefaultPromise(dtoAppUserPrinterDefaultId: string) {
		return new Promise<dtoAppUserPrinterDefault>(resolve => {
			this.getdtoAppUserPrinterDefault(dtoAppUserPrinterDefaultId).subscribe(dtoAppUserPrinterDefault => {
				resolve(dtoAppUserPrinterDefault);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppUserPrinterDefault records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoAppUserPrinterDefaultParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoAppUserPrinterDefault[]>(resolve => {
			this.getdtoAppUserPrinterDefaultParamArray(aParam).subscribe(dtoAppUserPrinterDefaults => {
				resolve(dtoAppUserPrinterDefaults);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppUserPrinterDefault records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoAppUserDefaultPrinter(appUserId: string, printerType: number) {
    return new Promise<dtoAppUserPrinterDefault>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guAppUserId", appUserId));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("intType", printerType.toString()));
      aParamList.push(aParam);

      this.getdtoAppUserPrinterDefaultParamArray(aParamList).subscribe(dtoAppUserPrinterDefaults => {
        if (dtoAppUserPrinterDefaults != null && dtoAppUserPrinterDefaults.length > 0) {
          resolve(dtoAppUserPrinterDefaults[0]);
        }
        resolve(null);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoAppUserPrinterDefault records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async updatedtoAppUserPrinterDefaultPromise(dtoAppUserPrinterDefault: dtoAppUserPrinterDefault) {
		return new Promise<dtoAppUserPrinterDefault>(resolve => {
			this.updatedtoAppUserPrinterDefault(dtoAppUserPrinterDefault).subscribe(dtoAppUserPrinterDefault => {
				resolve(dtoAppUserPrinterDefault);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoAppUserPrinterDefault record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoAppUserPrinterDefaultPromise(dtoAppUserPrinterDefault: dtoAppUserPrinterDefault) {
		return new Promise<dtoAppUserPrinterDefault>(resolve => {
			this.createdtoAppUserPrinterDefault(dtoAppUserPrinterDefault).subscribe(dtoAppUserPrinterDefault => {
				resolve(dtoAppUserPrinterDefault);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoAppUserPrinterDefault record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoAppUserPrinterDefaultPromise(dtoAppUserPrinterDefaultId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoAppUserPrinterDefault(dtoAppUserPrinterDefaultId).subscribe(dtoAppUserPrinterDefault => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoAppUserPrinterDefault record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
