import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoDeliveredSalesBySection } from '../business-objects/dto-delivered-sales-by-section.bo';

@Injectable()
export class dtoDeliveredSalesBySectionService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoDeliveredSalesBySection(dtoDeliveredSalesBySectionId: string) {
		return this.http.get<dtoDeliveredSalesBySection>(this._baseUrl + 'api/dtoDeliveredSalesBySection/' + dtoDeliveredSalesBySectionId);
	}

  getdtoDeliveredSalesBySections(dateFrom: string, dateTo: string) {
    console.log(this._baseUrl + 'api/dtoDeliveredSalesBySection/' + dateFrom + '/' + dateTo);
    return this.http.get<dtoDeliveredSalesBySection[]>(this._baseUrl + 'api/dtoDeliveredSalesBySection/GetdtoDeliveredSalesBySections/' + dateFrom + '/' + dateTo);
  }

	getdtoDeliveredSalesBySectionParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoDeliveredSalesBySection[]>(this._baseUrl + 'api/dtoDeliveredSalesBySection/GetdtoDeliveredSalesBySectionsParamArray/', aParam);
	}

	getAlldtoDeliveredSalesBySections() {
		return this.http.get<dtoDeliveredSalesBySection[]>(this._baseUrl + 'api/dtoDeliveredSalesBySection/GetAlldtoDeliveredSalesBySections/');
	}

	//getAlldtodtoDeliveredSalesBySections() {
	//	return this.http.get<dtodtoDeliveredSalesBySection[]>(this._baseUrl + 'api/dtoDeliveredSalesBySection/GetAlldtoDeliveredSalesBySections/');
	//}

	createdtoDeliveredSalesBySection(dtoDeliveredSalesBySection: dtoDeliveredSalesBySection) {
		return this.http.post<dtoDeliveredSalesBySection>(this._baseUrl + 'api/dtoDeliveredSalesBySection/CreatedtoDeliveredSalesBySection/', dtoDeliveredSalesBySection);
	}

	updatedtoDeliveredSalesBySection(dtoDeliveredSalesBySection: dtoDeliveredSalesBySection) {
		return this.http.put<dtoDeliveredSalesBySection>(this._baseUrl + 'api/dtoDeliveredSalesBySection/UpdatedtoDeliveredSalesBySection/', dtoDeliveredSalesBySection);
	}

	deletedtoDeliveredSalesBySection(dtoDeliveredSalesBySectionId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoDeliveredSalesBySection/DeletedtoDeliveredSalesBySection/' + dtoDeliveredSalesBySectionId);
	}

	async getdtoDeliveredSalesBySectionPromise(dtoDeliveredSalesBySectionId: string) {
		return new Promise<dtoDeliveredSalesBySection>(resolve => {
			this.getdtoDeliveredSalesBySection(dtoDeliveredSalesBySectionId).subscribe(dtoDeliveredSalesBySection => {
				resolve(dtoDeliveredSalesBySection);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDeliveredSalesBySection records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoDeliveredSalesBySectionParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoDeliveredSalesBySection[]>(resolve => {
			this.getdtoDeliveredSalesBySectionParamArray(aParam).subscribe(dtoDeliveredSalesBySections => {
				resolve(dtoDeliveredSalesBySections);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDeliveredSalesBySection records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoDeliveredSalesBySectionsPromise(dateFrom: string, dateTo: string) {
    return new Promise<dtoDeliveredSalesBySection[]>(resolve => {
      this.getdtoDeliveredSalesBySections(dateFrom, dateTo).subscribe(dtoDeliveredSalesBySections => {
        resolve(dtoDeliveredSalesBySections);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoDeliveredSalesBySection records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async updatedtoDeliveredSalesBySectionPromise(dtoDeliveredSalesBySection: dtoDeliveredSalesBySection) {
		return new Promise<dtoDeliveredSalesBySection>(resolve => {
			this.updatedtoDeliveredSalesBySection(dtoDeliveredSalesBySection).subscribe(dtoDeliveredSalesBySection => {
				resolve(dtoDeliveredSalesBySection);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoDeliveredSalesBySection record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoDeliveredSalesBySectionPromise(dtoDeliveredSalesBySection: dtoDeliveredSalesBySection) {
		return new Promise<dtoDeliveredSalesBySection>(resolve => {
			this.createdtoDeliveredSalesBySection(dtoDeliveredSalesBySection).subscribe(dtoDeliveredSalesBySection => {
				resolve(dtoDeliveredSalesBySection);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoDeliveredSalesBySection record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoDeliveredSalesBySectionPromise(dtoDeliveredSalesBySectionId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoDeliveredSalesBySection(dtoDeliveredSalesBySectionId).subscribe(dtoDeliveredSalesBySection => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoDeliveredSalesBySection record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
