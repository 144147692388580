import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { dtoAppUserColumnSet } from '../../_shared/business-objects/dto-app-user-column-set.bo';

@Component({
  selector: 'app-app-user-column-set-list',
  templateUrl: './app-user-column-set-list.component.html',
  styleUrls: ['./app-user-column-set-list.component.css']
})
export class AppUserColumnSetListComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoAppUserColumnSet>;
  @Output() editAppUserColumnSetEvent = new EventEmitter<dtoAppUserColumnSet>();

  @ViewChild(MatSort) sort: MatSort;

  displayedUserColumnSets: string[] = ['txtUserName', 'txtPageName', 'txtColumnSetName', 'blnCurrentView', 'dteDateCreated', 'btnEditUserColumnSet'];

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
  }

  editAppUserColumnSet(appUserColumnSet: dtoAppUserColumnSet) {

    console.log("", appUserColumnSet);

    this.editAppUserColumnSetEvent.emit(appUserColumnSet);

  }


}
