<form class="example-form bp-floor-order-search-form" [formGroup]="form">

  <div class="bp-floor-order-search-container" id="floorOrderContainer" #finalInspectionsElement>
    <h1>Customer Orders</h1>

    <mat-tab-group class="chh-mat-tab-group floor-order-tab-group" color="accent" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabFinalInspections>
      <mat-tab label="Orders" class="tab-label">
        <div class="search-fields-container">
          <div class="customer-filter-container">
            <mat-form-field>
              <div class="customer-input-container">
                <input matInput class="customer-input" style="width:30rem;" placeholder="Filter Customer" placeholderLabel="i.e. Joe Smith ....." value="" type="text" #customerFilter (keyup)="getCustomerSearchByName(customerFilter.value)" (focus)="customerFilter.select()">
                <br /><br />
              </div>
            </mat-form-field>
          </div>
          <div></div>
          <div></div>
          <div class="search-customer-column">
            <div class="customers">
              <mat-selection-list class="customer-select" #guCustomerId [multiple]="false" formControlName="guCustomerId" (click)="getActiveAndPartialCompleteOrders(guCustomerId._value)">
                <mat-list-option *ngFor="let Customer of customers" [value]="Customer.rowguid">
                  {{Customer.txtName}}
                </mat-list-option>
              </mat-selection-list>
              <app-spinner *ngIf="showSpinner" [showSpinner]="showSpinner" class="customer-spinner"></app-spinner>

            </div>
          </div>
          <div class="search-orders-column">
            <div class="order-numbers">
              <mat-selection-list class="order-select" #guOrderId [multiple]="false" formControlName="guOrderId" (click)="getOrderItems(guOrderId._value)">
                <mat-list-option *ngFor="let Order of orders" [value]="Order.rowguid">
                  {{Order.txtOrderNo}}
                </mat-list-option>
              </mat-selection-list>
              <app-spinner *ngIf="showOrderSpinner" [showSpinner]="showOrderSpinner" class="order-spinner"></app-spinner>
            </div>
            <div class="view-all-orders">
              <mat-checkbox class="example-margin" #chkViewAllOrders id="chkViewAllOrders" (change)="viewAllOrders(guCustomerId._value);">View All Customer Orders</mat-checkbox>
            </div>
          </div>

          <div class="order-items-sticker">
            <app-order-item-barcode-sticker #orderItemBarcodeSticker (removeStockItemEvent)="removeStockItem($event)" [stockItems]="stockItems" #barcodeSticker></app-order-item-barcode-sticker>
          </div>
          <div></div>
          <div></div>
          <div class="create-and-print-sticker">
            <button class="view-pdf-button" type="button" mat-flat-button color="primary" (click)="createPackAndPrint()" [disabled]="stockItems == null || stockItems.length == 0">Create Pack and Print Sticker</button>
            <button class="clear-pack-button" type="button" mat-flat-button color="primary" (click)="clearPack()" [disabled]="stockItems == null || stockItems.length == 0">Clear Pack</button>
          </div>
        </div>


        <div class="search-totals-container" *ngIf="false">
          <div class="search-total-rows">Total Rows: {{totalRows}}</div>
          <div class="search-total-pieces">Total Qty: {{totalQuantity}}</div>
          <div class="search-total-lm">Total M3: {{totalM3 | number:'1.0':'en-AU'}}</div>
          <div class="search-total-lm">Total Ton: {{totalTon | number:'1.0':'en-AU'}}</div>
          <div class="search-total-pieces">Results Time: {{resultTime}} s</div>
        </div>
        <mat-divider></mat-divider>

        <div class="order-item-list-container">
          <app-bp-floor-order-item-list #appOrderItemList [datasource]="orderItems" (selectedOrderItemsEvent)="selectedOrderItems($event)" (addStockItemEvent)="addStockItem($event)" [columnListId]="'columnListId'" [columnBarContainer]="'floorOrderContainer'"></app-bp-floor-order-item-list>
          <app-spinner *ngIf="showOrderItemSpinner" [showSpinner]="showOrderItemSpinner" class="order-item-spinner"></app-spinner>
        </div>

      </mat-tab>
      <mat-tab label="Search Options" class="tab-label">
        <div class="search-options-fields-container" id="searchFieldsContainer">
          <div class="search-field-wrapper">
            <div class="search-option-row-final-status">
              <label id="example-radio-group-label">Order Item Status</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" #finalStatus formControlName="radOrderItemStatus">
                <mat-radio-button class="example-radio-button" [value]="-1" [checked]="true">All</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="0">Active</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">Partial Complete</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="2">Complete</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="3">Pending</mat-radio-button>
              </mat-radio-group>
            </div>

          </div>

        </div>
      </mat-tab>

      <mat-tab label="Linked Stock" class="tab-label">
        <div class="stock-items-bp-floor-order-search-container">
          Linked Stock
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</form>


