import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoTPPoleInspectionLoadingDocket } from '../business-objects/dto-tppole-inspection-loading-docket.bo';

@Injectable()
export class dtoTPPoleInspectionLoadingDocketService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoTPPoleInspectionLoadingDocket(dtoTPPoleInspectionLoadingDocketId: string) {
		return this.http.get<dtoTPPoleInspectionLoadingDocket>(this._baseUrl + 'api/dtoTPPoleInspectionLoadingDocket/' + dtoTPPoleInspectionLoadingDocketId);
	}

	getdtoTPPoleInspectionLoadingDocketParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoTPPoleInspectionLoadingDocket[]>(this._baseUrl + 'api/dtoTPPoleInspectionLoadingDocket/GetdtoTPPoleInspectionLoadingDocketsParamArray/', aParam);
	}

	getAlldtoTPPoleInspectionLoadingDockets() {
		return this.http.get<dtoTPPoleInspectionLoadingDocket[]>(this._baseUrl + 'api/dtoTPPoleInspectionLoadingDocket/GetAlldtoTPPoleInspectionLoadingDockets/');
	}

	//getAlldtodtoTPPoleInspectionLoadingDockets() {
	//	return this.http.get<dtodtoTPPoleInspectionLoadingDocket[]>(this._baseUrl + 'api/dtoTPPoleInspectionLoadingDocket/GetAlldtoTPPoleInspectionLoadingDockets/');
	//}

	createdtoTPPoleInspectionLoadingDocket(dtoTPPoleInspectionLoadingDocket: dtoTPPoleInspectionLoadingDocket) {
		return this.http.post<dtoTPPoleInspectionLoadingDocket>(this._baseUrl + 'api/dtoTPPoleInspectionLoadingDocket/CreatedtoTPPoleInspectionLoadingDocket/', dtoTPPoleInspectionLoadingDocket);
	}

	updatedtoTPPoleInspectionLoadingDocket(dtoTPPoleInspectionLoadingDocket: dtoTPPoleInspectionLoadingDocket) {
		return this.http.put<dtoTPPoleInspectionLoadingDocket>(this._baseUrl + 'api/dtoTPPoleInspectionLoadingDocket/UpdatedtoTPPoleInspectionLoadingDocket/', dtoTPPoleInspectionLoadingDocket);
	}

	async getdtoTPPoleInspectionLoadingDocketPromise(dtoTPPoleInspectionLoadingDocketId: string) {
		return new Promise<dtoTPPoleInspectionLoadingDocket>(resolve => {
			this.getdtoTPPoleInspectionLoadingDocket(dtoTPPoleInspectionLoadingDocketId).subscribe(dtoTPPoleInspectionLoadingDocket => {
				resolve(dtoTPPoleInspectionLoadingDocket);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTPPoleInspectionLoadingDocket records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getdtoTPPoleInspectionLoadingDocketParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoTPPoleInspectionLoadingDocket[]>(resolve => {
			this.getdtoTPPoleInspectionLoadingDocketParamArray(aParam).subscribe(dtoTPPoleInspectionLoadingDockets => {
				resolve(dtoTPPoleInspectionLoadingDockets);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTPPoleInspectionLoadingDocket records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async updatedtoTPPoleInspectionLoadingDocketPromise(dtoTPPoleInspectionLoadingDocket: dtoTPPoleInspectionLoadingDocket) {
		return new Promise<dtoTPPoleInspectionLoadingDocket>(resolve => {
			this.updatedtoTPPoleInspectionLoadingDocket(dtoTPPoleInspectionLoadingDocket).subscribe(dtoTPPoleInspectionLoadingDocket => {
				resolve(dtoTPPoleInspectionLoadingDocket);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoTPPoleInspectionLoadingDocket record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createdtoTPPoleInspectionLoadingDocketPromise(dtoTPPoleInspectionLoadingDocket: dtoTPPoleInspectionLoadingDocket) {
		return new Promise<dtoTPPoleInspectionLoadingDocket>(resolve => {
			this.createdtoTPPoleInspectionLoadingDocket(dtoTPPoleInspectionLoadingDocket).subscribe(dtoTPPoleInspectionLoadingDocket => {
				resolve(dtoTPPoleInspectionLoadingDocket);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoTPPoleInspectionLoadingDocket record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

}