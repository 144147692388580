<div class="final-inspection-create-container">
  <div class="final-inspection-create-form-container">
    <form class="final-inspection-create-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Inspected</mat-label>
              <input id="dteDateInspected" class="date-input date-inspected" #dateInspected matInput [matDatepicker]="pickerinspected" formControlName="dteDateInspected">
              <mat-datepicker-toggle matSuffix [for]="pickerinspected"></mat-datepicker-toggle>
              <mat-datepicker #pickerinspected></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-column-2">
            <mat-form-field appearance="fill">
              <mat-label>Inspected By</mat-label>
              <mat-select formControlName="guInspectedById">
                <mat-option *ngFor="let employee of employees" [value]="employee.rowguid">{{employee.txtFirstName + ' ' + employee.txtLastName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <div class="use-auto-number-checkbox"><mat-checkbox class="example-margin" #chhCHHBrandApplied id="chkCHHBrandApplied" formControlName="chkCHHBrandApplied" [value]="true">CHH Brand Applied</mat-checkbox></div>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <div class="use-auto-number-checkbox"><mat-checkbox class="example-margin" #inspectorsStampApplied id="chkInspectorsStampApplied" formControlName="chkInspectorsStampApplied" [value]="true">Inspectors Stamp Brand Applied</mat-checkbox></div>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <div class="use-auto-number-checkbox"><mat-checkbox class="example-margin" #CCAAndHazardLevelApplied id="chkCCAAndHazardLevelApplied" formControlName="chkCCAAndHazardLevelApplied" [value]="true">CCA and Hazard Level Brand Applied</mat-checkbox></div>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <div class="use-auto-number-checkbox"><mat-checkbox class="example-margin" #surfaceClean id="chkSurfaceClean" formControlName="chkSurfaceClean" [value]="true">Pole Surface Clean and No Residue</mat-checkbox></div>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <div class="use-auto-number-checkbox"><mat-checkbox class="example-margin" #buttsMarkedCorrectly id="chkButtsMarkedCorrectly" formControlName="chkButtsMarkedCorrectly" [value]="true">Butts Marked Correctly</mat-checkbox></div>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <label id="example-radio-group-label">Hazard Level</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radHazardLevel">
                <mat-radio-button class="example-radio-button" *ngFor="let hazardLevel of hazardLevels" [value]="hazardLevel.intValue">
                  {{hazardLevel.txtValue}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 100px !important" appearance="fill">
                <mat-label>Load</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 1" value="" type="number" formControlName="intLoadNo">
              </mat-form-field>
              Of
              <mat-form-field class="example-full-width header-button" style="width: 100px !important" appearance="fill">
                <mat-label>Loads</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 3" value="" type="number" formControlName="intMaxLoads">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <div class="use-auto-number-checkbox"><mat-checkbox class="example-margin" #parkedFinal id="blnParkedFinal" formControlName="blnParkedFinal">Parked Final</mat-checkbox></div>
            </div>
          </div>

          <div class="header-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 500px !important" appearance="fill">
              <mat-label>Park Final Spec</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Load 1" value="" type="text" id="txtParkedFinalComments" formControlName="txtParkedFinalComments">
            </mat-form-field>
          </div>

          <div class="header-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 300px !important" appearance="fill">
              <mat-label>Pole Specification</mat-label>
              <mat-select class="final-item-order-item" formControlName="guElectricalAuthoritySpecId" placeholder="Pole Specification" #guElectricalAuthoritySpecId>
                <mat-option class="electrical-authority-spec" *ngFor="let elecAuthSpec of elecAuthSpecs" [value]="elecAuthSpec.rowguid">
                  {{elecAuthSpec.txtAuthorityName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="header-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 500px !important" appearance="fill">
              <mat-label>Comments</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Load 1" value="" type="text" id="txtComments" formControlName="txtComments">
            </mat-form-field>
          </div>

          <button class="stocktakeButton" mat-flat-button color="primary" (click)="createFinalInspection('create')">Create Final Inspection</button>
          <button class="stocktakeButton" mat-flat-button color="primary" (click)="createFinalInspection('createanditem')">Create Final Inspection and Add Item</button>
        </div>
       </div>
    </form>
  </div>
</div>
