import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { dtoDeliveryDocket } from '../../_shared/business-objects/dto-delivery-docket.bo';
import { dtoIntKeyValue, dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { DBFieldFunction, SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { dtoDeliveryDocketService } from '../../_shared/services/dto-delivery-docket.service';
import { SharedService } from '../../_shared/services/shared.service';
import { AlertService } from '../../_shared/services/alert.service';
import { ActivatedRoute, Params } from '@angular/router';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { FormService } from '../../_shared/services/form.service';
import { DeliveryDocketService } from '../../_shared/services/delivery-docket.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { dtoDeliveryDocketOrderItem } from '../../_shared/business-objects/dto-delivery-docket-order-item.bo';
import { dtoDeliveryDocketOrderItemService } from '../../_shared/services/dto-delivery-docket-order-item.service';
import { dtoDeliveryDocketItemService } from '../../_shared/services/dto-delivery-docket-item.service';
import { trigger, state, style, transition, animate, query, animateChild } from '@angular/animations';
import { DeliveryDocketOrderItemListComponent } from '../delivery-docket-order-item-list/delivery-docket-order-item-list.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeliveryDocketDeliveryRateComponent } from '../delivery-docket-delivery-rate/delivery-docket-delivery-rate.component';
import { TPPoleInspectionItem } from '../../_shared/business-objects/tppole-inspection-item.bo';
import { TPPoleInspectionItemService } from '../../_shared/services/tppole-inspection-item.service';
import { DeliveryDocketOrderItem } from '../../_shared/business-objects/delivery-docket-order-item.bo';
import { DeliveryDocketOrderItemService } from '../../_shared/services/delivery-docket-order-item.service';
import { dtoTPPoleInspectionItemService } from '../../_shared/services/dto-tppole-inspection-item.service';
import jsPDF from 'jspdf';
import { HttpHeaders } from '@angular/common/http';
import { LoadingLocation } from '../../_shared/business-objects/loading-location.bo';
import { LoadingDocket } from '../../_shared/business-objects/loading-docket.bo';
import { dtoTPPoleInspectionItem } from '../../_shared/business-objects/dto-tppole-inspection-item.bo';
import { DeliveryDocketFinalItemsListComponent } from '../delivery-docket-final-items-list/delivery-docket-final-items-list.component';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { LinkExistingLoadingDocketDialogComponent } from '../link-existing-loading-docket-dialog/link-existing-loading-docket-dialog.component';
import { dtoLoadingDocket } from '../../_shared/business-objects/dto-loading-docket.bo';
import { dtoLoadingDocketService } from '../../_shared/services/dto-loading-docket.service';
import { LoadingDocketDeliveryService } from '../../_shared/services/loading-docket-delivery.service';
import { LoadingDocketDelivery } from '../../_shared/business-objects/loading-docket-delivery.bo';
import { LoadingDocketCreateDialogComponent } from '../../loading-docket/loading-docket-create-dialog/loading-docket-create-dialog.component';
import { LoadingDocketService } from '../../_shared/services/loading-docket.service';
import { DeliveryDocketPrintComponent } from '../delivery-docket-print/delivery-docket-print.component';
import { CustomerDeliveryAddressDialogComponent } from '../../customer-delivery-address/customer-delivery-address-dialog/customer-delivery-address-dialog.component';
import { dtoCustomerDeliveryAddress } from '../../_shared/business-objects/dto-customer-delivery-address.bo';
import { dtoCustomerDeliveryAddressService } from '../../_shared/services/dto-customer-delivery-address.service';
import { ClientDeliveryAddress } from '../../_shared/business-objects/client-delivery-address.bo';
import { SendMailDialogComponent } from '../../email/send-mail-dialog/send-mail-dialog.component';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';
import { resolve } from 'dns';
import { AuthService } from '../../account/auth.service';
import { User } from '../../account/user';
import { distinct } from 'rxjs';
import { FileService } from '../../_shared/services/app-services/file.service';
import { PDFDocument } from 'pdf-lib';
import { TPPoleTrimmingItem } from '../../_shared/business-objects/tppole-trimming-item.bo';
import { DeliveryDocketCustomerDeliveryContact } from '../../_shared/business-objects/delivery-docket-customer-delivery-contact.bo';
import { DeliveryDocketCustomerDeliveryContactService } from '../../_shared/services/delivery-docket-customer-delivery-contact.service';
import { OrdersDeliveryRate } from '../../_shared/business-objects/orders-delivery-rate.bo';
import { OrdersDeliveryRateService } from '../../_shared/services/orders-delivery-rate.service';
import { dtoBoardTallyRecordHistoryService } from '../../_shared/services/dto-board-tally-record-history.service';
import { FinalInspectionItemPrintComponent } from '../../final-inspection-item/final-inspection-item-print/final-inspection-item-print.component';
import { dtoTPPoleInspection } from '../../_shared/business-objects/dto-tppole-inspection.bo';
import { dtoTPPoleInspectionService } from '../../_shared/services/dto-tppole-inspection.service';
import { TPPoleInspection } from '../../_shared/business-objects/tppole-inspection.bo';

export enum enDeliveryDocketOrderItemsTabs { DeliveryDocketOrderItems = 0, LinkedFinalItems = 1, LinkedStockItems = 2 }

@Component({
  selector: 'app-delivery-docket-list',
  templateUrl: './delivery-docket-list.component.html',
  styleUrls: ['./delivery-docket-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', [
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),  //225
        query('@*', animateChild(), { optional: true })
      ]),
    ]),
  ]
})
export class DeliveryDocketListComponent implements OnInit {

  //@ViewChild('tabDeliveryDockets', { static: false }) tabDeliveryDockets: MatTabGroup;
  @ViewChild('tblDeliveryDocket', { static: false }) tblDeliveryDocket: MatTable<dtoDeliveryDocket>;
  @ViewChild('appdeliveryDocketOrderItemlist') deliveryDocketOrderItemlist: DeliveryDocketOrderItemListComponent
  @ViewChild('appLinkedInspecionList') appLinkedInspecionList: DeliveryDocketFinalItemsListComponent
  @ViewChild('tabDeliveryDocketsOrderItems', { static: false }) tabDeliveryDocketsOrderItems: MatTabGroup;
  @ViewChildren('nextDelivery') nextDelivery: QueryList<MatInput>;

  @ViewChild('deliveryDocketPrintApp') deliveryDocketPrintApp: DeliveryDocketPrintComponent
  @ViewChild('finalInspectionItemPrintApp') finalInspectionItemPrintApp: FinalInspectionItemPrintComponent;


  @Input() datasource: MatTableDataSource<dtoDeliveryDocket>;
  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() delOrderItemColumnBarContainer: string;
  @Input() columnListId: string;
  @Output() editDeliveryDocketEvent = new EventEmitter<DeliveryDocket>();
  @Output() addDeliveryDocketEvent = new EventEmitter<DeliveryDocket>();
  @Output() addDeliveryDocketItemsEvent = new EventEmitter<dtoDeliveryDocket>();

  deliveryRateUpdateDialogRef: MatDialogRef<DeliveryDocketDeliveryRateComponent>
  linkExistingLoadingDocketDialogRef: MatDialogRef<LinkExistingLoadingDocketDialogComponent>
  addToNewLoadingDocketCreateDialogRef: MatDialogRef<LoadingDocketCreateDialogComponent>
  customerDeliveryAddressDialogRef: MatDialogRef<CustomerDeliveryAddressDialogComponent>
  sendMailDialogRef: MatDialogRef<SendMailDialogComponent>

  form: FormGroup;
  public eDeliveryDocketOrderItemTabs = enDeliveryDocketOrderItemsTabs;

  //deliveryDocket: DeliveryDocket;
  deliveryDocketExpanded: dtoDeliveryDocket;
  deliveryDocketOrderItems: MatTableDataSource<dtoDeliveryDocketOrderItem>;
  linkedFinalInspectionItems: MatTableDataSource<dtoTPPoleInspectionItem>;

  loadedComponent: boolean = false;

  deliveryDocketOrderItemQtyTotalInput: number;
  deliveryDocketOrderItemM3TotalInput: number;
  deliveryDocketOrderItemActualM3TotalInput: number;
  deliveryDocketOrderItemActualTonneTotalInput: number;

  finalInspectionItemM3TotalInput: number;
  finalInspectionItemWeightTotalInput: number;

  selectedRow: string = "";

  deliveryDocketPDFId: string = "";

  showInlineSpinner: boolean = true;
  showTick: boolean = false;
  showTOCs: boolean;

  ms_authenticated: boolean = false;

  finalInspectionPDFId: string = "";

  //selectedTab: number;
  //showTable: boolean = false;
  //showProductsSpinner: boolean = false;
  //deliveryDockets: dtoDeliveryDocket[] = [];
  //deliveryStatuses: dtoKeyValue[] = [];
  //selectedStatuses: number[] = [];

  //filterToQueryParamMap: FieldQueryParamMap[] = [];

  displayedColumns: string[] = ['intDocketNo', 'intLoadingDocketNo', 'txtStatus', 'dteStatusDate', 'txtHaulingCompanyName', 'txtCustomerLocation', 'txtOrderNo', 'dteCreatedDate', 'fltDeliveryCharge', 'fltEscort', 'fltAdditionalKM', 'intLoadNo', 'loadNoSeparator', 'intMaxLoads', 'fltActualM3', 'fltActualTonne', 'btnEditDeliveryDocket', 'btnRemoveDeliveryDocket', 'btnPrintDeliveryDocket', 'btnSetAsDeliveredDeliveryDocket', 'btnSetAsPickedUpDeliveryDocket', 'btnSetAsPickedUpAndEmailDeliveryDocket', 'btnSetAsReadyForDispatchDeliveryDocket', 'btnAddDeliveryDocketItems', 'btnUpdateDeliveryRate', 'btnViewDeliveryDocketOrderItems', 'btnDownloadDeliveryDocket', 'btnOpenLoadingDocket', 'btnAddToNewLoadingDocket', 'blnNextDelivery', 'intInvoiceStatus', 'intInvoicePortalUploaded'];

  constructor(private dtoDeliveryDocketService: dtoDeliveryDocketService, private deliveryDocketService: DeliveryDocketService, private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private route: ActivatedRoute, private formService: FormService, private dtoDeliveryDocketOrderItemService: dtoDeliveryDocketOrderItemService
    , private dtoDeliveryDocketItemService: dtoDeliveryDocketItemService, private dialog: MatDialog, private tpPoleInspectionItemService: TPPoleInspectionItemService
    , private deliveryDocketOrderItemService: DeliveryDocketOrderItemService, private dtoTPPoleInspectionService: dtoTPPoleInspectionService, private dtoTPPoleInspectionItemService: dtoTPPoleInspectionItemService
    , private dtoLoadingDocketService: dtoLoadingDocketService, private loadingDocketDeliveryService: LoadingDocketDeliveryService, private loadingDocketService: LoadingDocketService
    , private dtoCustomerDeliveryAddressService: dtoCustomerDeliveryAddressService, private authService: AuthService, private fileService: FileService
    , private deliveryDocketCustomerDeliveryContactService: DeliveryDocketCustomerDeliveryContactService, private ordersDeliveryRateService: OrdersDeliveryRateService
      ) {

    if (this.columnBarPage == null || this.columnBarPage == "") {
      this.columnBarPage = "Delivery Docket";
    }

    if (this.columnBarContainer == null || this.columnBarContainer == "") {
      this.columnBarContainer = "deliveryDocketContainer";
    }


    //this.form = this.fb.group({
    //  txtSearchDeliveryDocketNo: [''],
    //  txtSearchLoadingDocketNo: [''],
    //  dteSearchStatusDateFrom: [''],
    //  dteSearchStatusDateTo: [''],
    //  txtSearchHaulingCompanyName: [''],
    //  intStatus: [''],
    //  blnSearchUploadInvoice: ['']
    //});

    //this.filterToQueryParamMap.push({ txtField: "intStatus", txtQueryParam: "status", intFieldType: FieldQueryParamMap.enFieldType.SelectboxMultiple, intValueType: FieldQueryParamMap.enValueType.Int });
    //this.filterToQueryParamMap.push({ txtField: "txtSearchHaulingCompanyName", txtQueryParam: "hauler", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    //this.filterToQueryParamMap.push({ txtField: "dteSearchStatusDateFrom", txtQueryParam: "datefrom", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.Date });
    //this.filterToQueryParamMap.push({ txtField: "dteSearchStatusDateTo", txtQueryParam: "dateto", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.Date });
    //this.filterToQueryParamMap.push({ txtField: "blnSearchUploadInvoice", txtQueryParam: "uploadinvoice", intFieldType: FieldQueryParamMap.enFieldType.SlideToogle, intValueType: FieldQueryParamMap.enValueType.Boolean });

    //this.deliveryStatuses = this.sharedService.getEnumObject2(DeliveryDocket.enDeliveryStatus);

  }

  ngOnInit(): void {

    //this.showTable = false;

    //this.route.queryParams.subscribe(params => {

    //  this.formService.setFieldsFromQueryParams(this.filterToQueryParamMap, params, this.form);

    //});

    //this.selectedStatuses.length = 0;

    //if (Array.isArray(this.form.controls['intStatus'].value)) {
    //  for (let i = 0; i <= this.form.controls['intStatus'].value.length - 1; i++) {
    //    this.selectedStatuses.push(this.form.controls['intStatus'].value[i]);
    //    console.log("status: " + this.form.controls['intStatus'].value[i]);
    //  }

    //}

    if (this.columnListId == null) {
      this.columnListId = "columnListId";
    }


    this.loadedComponent = true;


    this.getUser();


  }

  async getUser() {

    console.log("ms_authenticated - before", this.authService.ms_authenticated);

    this.ms_authenticated = (await this.authService.getUser() != null);

    console.log("ms_authenticated - after", this.authService.ms_authenticated);

  }

  async createFinalPDFAttachement(finalInspection: dtoTPPoleInspection): Promise<FileAttachment> {

    return new Promise<FileAttachment>(async resolve=> {
      this.finalInspectionPDFId = finalInspection.rowguid;

      this.finalInspectionItemPrintApp.finalInspectionId = finalInspection.rowguid;
      //this.finalInspectionPrintApp.pdfPDFMode = true;

      await this.finalInspectionItemPrintApp.loadData();

      setTimeout(() => {
      //  this.finalInspectionPrintApp.appLoadingDocketView.deliveryDocketId = finalInspection.rowguid;
      //  this.finalInspectionPrintApp.appLoadingDocketView.deliveryDocketIdInput = finalInspection.rowguid;
      //  this.finalInspectionPrintApp.appLoadingDocketView.loadData();
    }, 300);

    setTimeout(async () => {

      let htmlElement: HTMLDivElement = document.getElementById("finalInspectionItemPrintContainer") as HTMLDivElement;
      htmlElement.classList.toggle("final-inspection-print-container-visible");
      let htmlSummaryElement: HTMLDivElement = document.getElementById("finalInspectionSummaryId") as HTMLDivElement;
      let divHeight: number = htmlElement.clientHeight;

      let finalInspectionArrayBuffer: ArrayBuffer;
      let finalInspectionSummaryArrayBuffer: ArrayBuffer;
      finalInspectionArrayBuffer = await this.fileService.HTMLToPdfArrayBuffer(htmlElement, "l");
      finalInspectionSummaryArrayBuffer = await this.fileService.HTMLToPdfArrayBuffer(htmlSummaryElement, "l");
      let arrBuffer: ArrayBuffer[] = [];
      arrBuffer.push(finalInspectionArrayBuffer);
      arrBuffer.push(finalInspectionSummaryArrayBuffer);

      if (finalInspectionArrayBuffer == null) {
        await this.alertService.openSnackBarCustomPromise("Final Inspection PDF", "There was a problem creating the final inspection pdf", "OK", "", "center", "bottom", "", 0, false);
        resolve(null);
      }


      //await this.fileService.createPDF("finalInspectionItemPrintContainer", "FinalInspection_" + finalInspection.intFinalNo.toString(), "l");

      setTimeout(() => {
        htmlElement.classList.toggle("final-inspection-print-container-visible");
      }, 800);

      let fileAttachment: FileAttachment = await this.fileService.createPDFFileAttachment("FinalInspection_" + finalInspection.intFinalNo.toString() + ".pdf", arrBuffer);
      if (fileAttachment != null) {
        resolve(fileAttachment);
      }

      resolve(null);

    }, 1000);

    
  });

  }


  async createDeliveryDocketPDF(deliveryDocket: dtoDeliveryDocket) {

    this.deliveryDocketPDFId = deliveryDocket.rowguid;

    this.deliveryDocketPrintApp.deliveryDocketId = deliveryDocket.rowguid;
    this.deliveryDocketPrintApp.pdfMode = true;

    await this.deliveryDocketPrintApp.loadData();

    setTimeout(() => {
      this.deliveryDocketPrintApp.appLoadingDocketView.deliveryDocketId = deliveryDocket.rowguid;
      this.deliveryDocketPrintApp.appLoadingDocketView.deliveryDocketIdInput = deliveryDocket.rowguid;
      this.deliveryDocketPrintApp.appLoadingDocketView.loadData();
    }, 300);

    setTimeout(async () => {

      let htmlElement: HTMLDivElement = document.getElementById("deliveryDocketPDF") as HTMLDivElement;
      htmlElement.classList.toggle("delivery-docket-print-container-visible");
      let divHeight: number = htmlElement.clientHeight;
      let deliveryDocketBottom: HTMLDivElement = document.getElementById("deliveryDocketBottom") as HTMLDivElement;


      deliveryDocketBottom.style.marginTop = "10px";

      if (divHeight > 1700 && divHeight < 1960) {
        //console.log("deliveryDocketBottom: ", deliveryDocketBottom);
        deliveryDocketBottom.style.marginTop = (1960 - divHeight) + "px";
      }

      console.log("height: ", htmlElement.clientHeight);
      //==============================================================================================
      // TO DO:
      // If height is greater than 1700px and less than 1960px (htmlElement.clientHeight) then set a
      // margin-top: 260px on the Proof of delivery box.
      // If its greatert than 1960px it means the POD box is on the next page.
      // We could cater for the delivery docket going over two pages but is very unlikely.
      //==============================================================================================

      await this.createPDF(deliveryDocket)

      setTimeout(() => {
        htmlElement.classList.toggle("delivery-docket-print-container-visible");
      }, 500);

    }, 1000);

  }


  async createPDF(deliveryDocket: dtoDeliveryDocket) {
    //var pdf = new jsPDF('p', 'px', 'A4');
    var pdf = new jsPDF('p', 'pt', 'A4');

    //var pdf = new jsPDF('p', 'px', [297, 210]);
    //
    let htmlElement: HTMLElement = document.getElementById("deliveryDocketPDF");
    //htmlElement.innerHTML = "<div style='width:500px;height500px;background-color: #92a8d1;'>Test Div</div>";

    if (htmlElement == null) {
      console.log("null element");
      return;
    }

    console.log("htmlElement", htmlElement);

    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/octet-stream'
    });

    pdf.html(htmlElement,
      {
        html2canvas: {
          // insert html2canvas options here, e.g.
          scale: 0.5
        },
        callback: (pdf) => {
          //let arrBuffer: ArrayBuffer = pdf.output('arraybuffer');
          //console.log(arrBuffer);
          //this.dtoDeliveryDocketService.printDeliveryDocket(arrBuffer);


          //this.http.post<Blob>("https://localhost:44358/api/Print/SaveFile/", arrBuffer, { headers: headers, responseType: 'blob' as 'json' }).subscribe(data => {
          //console.log("data: " + data);
          //});
          pdf.save('DeliveryDocket_' + deliveryDocket.intDocketNo.toString() + '.pdf');
        }
      });


  }

  async loadDeliveryDocketView(deliveryDocket: dtoDeliveryDocket) {
    return new Promise<boolean>(async resolve => {
      this.deliveryDocketPrintApp.appLoadingDocketView.deliveryDocketId = deliveryDocket.rowguid;
      this.deliveryDocketPrintApp.appLoadingDocketView.deliveryDocketIdInput = deliveryDocket.rowguid;
      await this.deliveryDocketPrintApp.appLoadingDocketView.loadData();
      console.log("resolve loadDeliveryDocketView");
      resolve(true);
    });
  }

  getPDFBase64(deliveryDocket: dtoDeliveryDocket) {
    return new Promise<FileAttachment>(async resolve => {


      this.deliveryDocketPDFId = deliveryDocket.rowguid;

      this.deliveryDocketPrintApp.deliveryDocketId = deliveryDocket.rowguid;
      this.deliveryDocketPrintApp.pdfMode = true;

      await this.deliveryDocketPrintApp.loadData();

      //setTimeout(() => {
      //  this.deliveryDocketPrintApp.appLoadingDocketView.deliveryDocketId = deliveryDocket.rowguid;
      //  this.deliveryDocketPrintApp.appLoadingDocketView.deliveryDocketIdInput = deliveryDocket.rowguid;
      //  this.deliveryDocketPrintApp.appLoadingDocketView.loadData();
      //}, 300);

      await this.loadDeliveryDocketView(deliveryDocket);

      setTimeout(async () => {

        let htmlElement: HTMLDivElement = document.getElementById("deliveryDocketPDF") as HTMLDivElement;
        htmlElement.classList.toggle("delivery-docket-print-container-visible");
        let divHeight: number = htmlElement.clientHeight;

        let deliveryDocketPrintScreenContainer: HTMLDivElement = document.getElementById("deliveryDocketPrintScreenContainer") as HTMLDivElement;
        let deliveryDocketPrintContainer: HTMLDivElement = document.getElementById("deliveryDocketPrintContainer") as HTMLDivElement;
        let deliveryDocketBottom: HTMLDivElement = document.getElementById("deliveryDocketBottom") as HTMLDivElement;
        let termsAndConditions: HTMLDivElement = document.getElementById("termsAndConditions") as HTMLDivElement;

        
        let printScreenContainerHeight: number = deliveryDocketPrintScreenContainer.clientHeight;
        let printContainerHeight: number = deliveryDocketPrintContainer.clientHeight;

        deliveryDocketBottom.style.marginTop = "10px";

        //console.log("divHeight: ", divHeight);
        //console.log("printScreenContainerHeight: ", printScreenContainerHeight);
        //console.log("printContainerHeight: ", printContainerHeight);

        if (printScreenContainerHeight > 1700 && printScreenContainerHeight < 1960) {
          deliveryDocketBottom.style.marginTop = (1700 - printContainerHeight) + "px";
          //console.log("(1830 - printContainerHeight): ", (1700 - printContainerHeight));
        }

        //==============================================================================================
        // TO DO:
        // If height is greater than 1700px and less than 1960px (htmlElement.clientHeight) then set a
        // margin-top: 260px on the Proof of delivery box.
        // If its greatert than 1960px it means the POD box is on the next page.
        // We could cater for the delivery docket going over two pages but is very unlikely.
        //==============================================================================================


        let deliveryArrayBuffer: ArrayBuffer;
        let tocArrayBuffer: ArrayBuffer;

        if (htmlElement == null) {
          console.log("null element");
          return;
        }

        if (termsAndConditions == null) {
          console.log("termsAndConditions null element");
          return;
        }

        tocArrayBuffer = await this.fileService.HTMLToPdfArrayBuffer(termsAndConditions);
        deliveryArrayBuffer = await this.fileService.HTMLToPdfArrayBuffer(htmlElement);
        let arrBuffer: ArrayBuffer[] = [];
        arrBuffer.push(deliveryArrayBuffer);
        arrBuffer.push(tocArrayBuffer);

        if (deliveryArrayBuffer == null) {
          await this.alertService.openSnackBarCustomPromise("Delivery Docket PDF", "There was a problem creating the delivery docket pdf", "OK", "", "center", "bottom", "", 0, false);
          resolve(null);
        }

        resolve(this.fileService.createPDFFileAttachment("DeliveryDocket_" + deliveryDocket.intDocketNo.toString() + ".pdf", arrBuffer));

        setTimeout(() => {
          htmlElement.classList.toggle("delivery-docket-print-container-visible");
        }, 500);

      }, 500);

    });



  }

  async loadLinkedFinalItems(index: number) {

    //console.log("delivery docket: " + this.deliveryDocketExpanded);

    if (this.deliveryDocketExpanded != null) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocketExpanded.rowguid));
      aParamList.push(aParam);
      this.linkedFinalInspectionItems = new MatTableDataSource();
      this.linkedFinalInspectionItems.data = await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemParamArrayPromise(aParamList);

      //this.finalItemsLinkedComponent

      this.appLinkedInspecionList.loadedComponent = false;

      this.appLinkedInspecionList.setTotals(index);

      //console.log("this.appLinkedInspecionList.finalInspectionItemM3TotalInput: " + this.appLinkedInspecionList.finalInspectionItemM3TotalInput);
      //console.log("this.appLinkedInspecionList.finalInspectionItemWeightTotalInput: " + this.appLinkedInspecionList.finalInspectionItemWeightTotalInput);
      //this.finalInspectionItemM3TotalInput = this.appLinkedInspecionList.finalInspectionItemM3TotalInput;
      //this.finalInspectionItemWeightTotalInput = this.appLinkedInspecionList.finalInspectionItemWeightTotalInput;

      //this.appLinkedInspecionList.tblfinalInspectionItems.removeFooterRowDef(null);
      //this.appLinkedInspecionList.tblfinalInspectionItems.renderRows();

    }
  }

  expandElementAndSetColor(deliveryDocket: dtoDeliveryDocket, idx: number) {

    let div: HTMLDivElement = document.getElementById("locationrow" + idx.toString()) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(() => {
        if (div.getAttribute("data-dblclick") == "1") {
          this.setRowColor(deliveryDocket.rowguid);
          //window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 200);
    } else {
      div.removeAttribute("data-dblclick");
      this.expandElement(deliveryDocket, idx);
      this.selectedRow = deliveryDocket.rowguid;  //Always highlight row when double clicking.

      //window.alert('ondouble');
    }

  }


  async expandElement(deliveryDocket: dtoDeliveryDocket, index: number) {

    this.deliveryDocketExpanded = this.deliveryDocketExpanded === deliveryDocket ? null : deliveryDocket;

    await this.loadDeliveryDocketOrderItems(deliveryDocket);

    await this.loadLinkedFinalItems(index)



  }

  getElementDetailClass() {
    setTimeout(() => {

      return 'example-element-detail';

    }, 100);


  }


  async loadDeliveryDocketOrderItems(deliveryDocket: dtoDeliveryDocket) {

    if (this.deliveryDocketExpanded == deliveryDocket) {
      this.deliveryDocketOrderItems = new MatTableDataSource<dtoDeliveryDocketOrderItem>();
      this.deliveryDocketOrderItems.data = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemByDocketIdPromise(deliveryDocket.rowguid);
      setTimeout(() => { this.deliveryDocketOrderItemlist.setTotals() }, 500);

      //console.log("deliveryDocketOrderItemQtyTotal: ", this.deliveryDocketOrderItems.data);
      this.deliveryDocketOrderItemQtyTotalInput = this.deliveryDocketOrderItemlist.deliveryDocketOrderItemQtyTotal;
      this.deliveryDocketOrderItemM3TotalInput = this.deliveryDocketOrderItemlist.deliveryDocketOrderItemM3Total;
      this.deliveryDocketOrderItemActualM3TotalInput = this.deliveryDocketOrderItemlist.deliveryDocketOrderItemActualM3Total;
      this.deliveryDocketOrderItemActualTonneTotalInput = this.deliveryDocketOrderItemlist.deliveryDocketOrderItemActualTonneTotal;
      //this.deliveryDocketOrderItemlist.loadedComponent = true;
    }

  }

  updatedComponentColumnsEvent(columns: string[]) {

    this.displayedColumns = columns;

  }


  async onTabChange(e: MatTabChangeEvent, index: number) {

    console.log(e);

    if (e.index == this.eDeliveryDocketOrderItemTabs.LinkedFinalItems) {
      this.appLinkedInspecionList.setTotals(index);


    }


  }


  async editDeliveryDocket(deliveryDocket: dtoDeliveryDocket) {
    this.editDeliveryDocketEvent.emit(deliveryDocket);

    this.rowColorPropagation(deliveryDocket.rowguid);

  }

  async removeDeliveryDocket(deliveryDocketId: string) {

    let msg: string = await this.alertService.openSnackBarCustomDefaultPromise("Remove Delivery Docket", "Are you sure you want to remove this delivery docket", "Yes", "No", "center", "bottom", "", 0, true);
    if (msg != "Yes") {
      return;
    }

    let delOrderItems: DeliveryDocketOrderItem[] = await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemByDocketIdPromise(deliveryDocketId);

    console.log("del order items: ", delOrderItems);

    if (delOrderItems != null && delOrderItems.length > 0) {
      let msg: string = await this.alertService.openSnackBarCustomDefaultPromise("", "There are Order Items linked to this delivery docket.\nPlease remove all order items from the docket before removing.");
      return;
    }

    let finalItems: dtoTPPoleInspectionItem[] = await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemByDeliveryIdPromise(deliveryDocketId);

    console.log("del final items: ", finalItems);

    if (finalItems != null && finalItems.length > 0) {
      let msg: string = await this.alertService.openSnackBarCustomDefaultPromise("", "There are Final Items linked to this delivery docket.\nPlease remove all final items from the docket before removing.");
      return;
    }

    let delLoadingDockets: LoadingDocketDelivery[] = await this.loadingDocketDeliveryService.getLoadingDocketDeliveryByDeliveryIdPromise(deliveryDocketId);
    if (delLoadingDockets != null && delLoadingDockets.length > 0) {
      let msg: string = await this.alertService.openSnackBarCustomDefaultPromise("", "This delivery docket is linked to a Loading Docket.\nPlease unlink the delivery docket from the loading docket before removing.");
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocketId));
    aParamList.push(aParam);

    let delContacts: DeliveryDocketCustomerDeliveryContact[] = await this.deliveryDocketCustomerDeliveryContactService.getDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParamList);
    for (let i = 0; i <= delContacts.length - 1; i++) {
      await this.deliveryDocketCustomerDeliveryContactService.deleteDeliveryDocketCustomerDeliveryContactPromise(delContacts[i].rowguid);
    }


    aParamList = [];
    aParam = [];
    aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocketId));
    aParamList.push(aParam);
    let delOrdersDelRate: OrdersDeliveryRate[] = await this.ordersDeliveryRateService.getOrdersDeliveryRateParamArrayPromise(aParamList);
    for (let i = 0; i <= delOrdersDelRate.length - 1; i++) {
      delOrdersDelRate[i].guDeliveryDocketId = null;
      await this.ordersDeliveryRateService.updateOrdersDeliveryRatePromise(delOrdersDelRate[i]);
      //console.log("update OrdersDeliveryRate " + delOrdersDelRate[i].rowguid);
    }


    let removed: boolean = await this.deliveryDocketService.removeDeliveryDocketPromise(deliveryDocketId);

    if (removed == true) {
      for (let i = 0; i <= this.datasource.data.length - 1; i++) {
        if (this.datasource.data[i].rowguid == deliveryDocketId) {
          this.datasource.data.splice(i, 1);
          this.tblDeliveryDocket.renderRows();
          break;
        }
      }
    }

    this.rowColorPropagation(deliveryDocketId);

  }


  async setAsDeliveredDeliveryDocket(deliveryDocket: dtoDeliveryDocket, index: number) {

    await this.updateDeliveryDocket(deliveryDocket, DeliveryDocket.enDeliveryStatus.Delivered, index);
    this.alertService.openSnackBarDefault("The delivery docket has been set as delivered.");

    this.rowColorPropagation(deliveryDocket.rowguid);

  }


  async setAsPickedUpDeliveryDocket(deliveryDocket: dtoDeliveryDocket, index: number) {

    await this.updateDeliveryDocket(deliveryDocket, DeliveryDocket.enDeliveryStatus.PickedUp, index);
    this.alertService.openSnackBarDefault("The delivery docket has been set as picked up.");

    this.rowColorPropagation(deliveryDocket.rowguid);

  }

  async setAsPickedUpAndEmailDeliveryDocket(deliveryDocket: dtoDeliveryDocket, index: number, pickedupAndEmailButtonTD: HTMLTableDataCellElement) {

    await this.updateDeliveryDocket(deliveryDocket, DeliveryDocket.enDeliveryStatus.PickedUp, index);
    this.alertService.openSnackBarDefault("The delivery docket has been set as picked up.");

    this.rowColorPropagation(deliveryDocket.rowguid);

    let username: string = (await this.authService.getUser()).displayName;
    let toAddresses: string = "loadingdockets@coffshardwoods.com.au";
    let subject: string = "Delivery Docket " + deliveryDocket.intDocketNo.toString() + " For " + deliveryDocket.txtCustomerName + " Has Been Picked Up";
    let message: string = "Hi\n\nThe delivery docket " + deliveryDocket.intDocketNo.toString() + " for " + deliveryDocket.txtCustomerName + " has been picked up." + "\n\nRegards\n\n" + username
    this.emailDeliveryDocket(deliveryDocket, pickedupAndEmailButtonTD, toAddresses, subject, message);

  }

  async setAsReadyForDispatchDeliveryDocket(deliveryDocket: dtoDeliveryDocket, index: number) {

    await this.updateDeliveryDocket(deliveryDocket, DeliveryDocket.enDeliveryStatus.ReadyForDispatch, index);
    this.alertService.openSnackBarDefault("The delivery docket has been set as read for dispatch.");

    this.rowColorPropagation(deliveryDocket.rowguid);

  }

  updateDeliveryDocket(deliveryDocket: dtoDeliveryDocket, eStatus: DeliveryDocket.enDeliveryStatus, index: number) {

    //console.log(deliveryDocket);


    this.deliveryDocketService.getDeliveryDocket(deliveryDocket.intDocketNo).subscribe(dd => {


      dd.intStatus = eStatus;
      dd.dteStatusDate = this.sharedService.currentDatePlusTZOffset();

      this.deliveryDocketService.updateDeliveryDocket(dd).subscribe(ddUpdated => {

        //this.datasource.data.splice(index, 1);
        deliveryDocket.intStatus = eStatus;
        deliveryDocket.dteStatusDate = this.sharedService.currentDatePlusTZOffset();

        this.tblDeliveryDocket.renderRows();

      }, err => {
        console.log(err);

      });

    }, err => {
      console.log(err);
    });

    this.rowColorPropagation(deliveryDocket.rowguid);

  }


  addDeliveryDocketItems(deliveryDocket: dtoDeliveryDocket, rowIdx: number) {
    this.addDeliveryDocketItemsEvent.emit(deliveryDocket);

    this.rowColorPropagation(deliveryDocket.rowguid);


  }

  async removeDeliveryDocketOrderItem(deliveryDocketOrderItem: dtoDeliveryDocketOrderItem) {
    //console.log("removeDeliveryDocketOrderItem");

    if (deliveryDocketOrderItem == null) {
      this.alertService.openSnackBar("There is no delivery docket order item to remove", "Close", "center", "bottom", "", 4000);
      return;
    }

    let alertMsg: string = "Are you sure you want to remove this deliery docket order item and unlink all linked items?";
    let msg: string = await this.alertService.openSnackBarCustomPromise("Remove Delivery Docket Order Item", alertMsg, "Yes", "No");

    if (msg != "Yes") {
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];


    aParam.push(new SQLParamArray("guDeliveryDocketOrderItemId", deliveryDocketOrderItem.rowguid));
    aParamList.push(aParam);
    let finalItems: TPPoleInspectionItem[] = await this.tpPoleInspectionItemService.getTPPoleInspectionItemParamArrayPromise(aParamList);
    for (let i = 0; i <= finalItems.length - 1; i++) {
      finalItems[i].guDeliveryDocketOrderItemId = null;
    }

    await this.tpPoleInspectionItemService.updateTPPoleInspectionItemsPromise(finalItems);

    //console.log("updating delivery docket order item.");
    let delDocketOrderItem: DeliveryDocketOrderItem = await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemPromise(deliveryDocketOrderItem.rowguid);
    await this.deliveryDocketOrderItemService.removeDeliveryDocektOrderItemPromise(deliveryDocketOrderItem.rowguid);

    await this.deliveryDocketOrderItemService.reSetItemNumbers(deliveryDocketOrderItem.guDeliveryDocketId);

    this.loadDeliveryDocketOrderItems(this.deliveryDocketExpanded);


  }

  printDeliveryDocket(deliveryDocketId: string) {
    let path: string = this.sharedService.LocalServerURI;
    if (location.hostname.indexOf('localhost') == -1) {
      path = 'https://' + location.hostname
    }

    var windowUrl = path + '/delivery-docket-print?deliverydocketid=' + deliveryDocketId + '&hidenav=yes&openprintdialog=yes';
    var uniqueName = new Date();
    var windowName = "_blank";
    var printWindow = window.open(windowUrl, windowName).focus();

    this.rowColorPropagation(deliveryDocketId);

  }

  async openLoadingDocket(deliveryDocket: dtoDeliveryDocket, rowIdx: number) {

    let path: string = this.sharedService.LocalServerURI;
    if (location.hostname.indexOf('localhost') == -1) {
      path = 'https://' + location.hostname
    }

    var windowUrl = path + '/loading-docket-search?docketno=' + deliveryDocket.intLoadingDocketNo + '&search=true';
    var uniqueName = new Date();
    var windowName = "_blank";
    var printWindow = window.open(windowUrl, windowName).focus();

  }

  async downloadDeliveryDocket(deliveryDocket: dtoDeliveryDocket, rowIdx: number) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocket.rowguid));
    aParamList.push(aParam);

    let docketItems = await this.dtoDeliveryDocketItemService.getdtoDeliveryDocketItemParamArrayPromise(aParamList);

    if (docketItems == null) {
      this.alertService.openSnackBarError("There was a problem getting the delivery docket items", "Close", "center", "bottom", 4000, true, "There was a problem getting the delivery docket items");
      return;
    }

    console.log(docketItems);

    this.dtoDeliveryDocketService.downloadDeliveryDockect(docketItems);

    this.rowColorPropagation(deliveryDocket.rowguid);

  }

  async updateDeliveryRate(dtoDeliveryDocket: dtoDeliveryDocket, rowIdx: number) {

    if (this.deliveryRateUpdateDialogRef != null) {
      this.deliveryRateUpdateDialogRef.close();
    }

    this.rowColorPropagation(dtoDeliveryDocket.rowguid);

    let deliveryDocket: DeliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(dtoDeliveryDocket.rowguid);
    let deliveryDocketOrderItems: dtoDeliveryDocketOrderItem[] = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemByDocketIdPromise(deliveryDocket.rowguid);

    if (deliveryDocket == null) {
      this.alertService.openSnackBarDefault("There is no delivery docket to update");
      return;
    }

    if (deliveryDocketOrderItems == null || deliveryDocketOrderItems.length == 0) {
      this.alertService.openSnackBarDefault("There is no delivery docket order items on this delivery docket to update");
      return;
    }


    this.deliveryRateUpdateDialogRef = this.dialog.open(DeliveryDocketDeliveryRateComponent, {
      hasBackdrop: false,
      height: 'auto',
      maxWidth: '90vw',
      data: { orderId: deliveryDocketOrderItems[0].guOrderId, deliveryDocket: deliveryDocket }
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.deliveryRateUpdateDialogRef.backdropClick().subscribe(() => {
      this.deliveryRateUpdateDialogRef.close();
    });


    this.deliveryRateUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, deliveryDocket: DeliveryDocket }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

          //let deliveryDocket: dtoDeliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(data.deliveryDocket.rowguid);

          this.alertService.openSnackBarDefault("The delivery rate has been updated");

        }
        else {
          // We have cancellled

        }

      });


  }

  async addToExistingLoadingDocket(deliveryDocket: dtoDeliveryDocket, index: number) {
    if (this.linkExistingLoadingDocketDialogRef != null) {
      this.linkExistingLoadingDocketDialogRef.close();
    }

    if (deliveryDocket == null) {
      this.alertService.openSnackBarDefault("There is no delivery docket selected.");
      return;
    }

    this.rowColorPropagation(deliveryDocket.rowguid);

    let existingLoadingDockets: dtoLoadingDocket[] = null;
    let existingLoadingDocket: dtoLoadingDocket = null;
    if (deliveryDocket.intLoadingDocketNo != null && deliveryDocket.intLoadingDocketNo > 0) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("intDocketNo", deliveryDocket.intLoadingDocketNo.toString()));
      aParamList.push(aParam);

      existingLoadingDockets = await this.dtoLoadingDocketService.getdtoLoadingDocketParamArrayPromise(aParamList)
      if (existingLoadingDockets != null) {
        existingLoadingDocket = existingLoadingDockets[0];
      }
    }

    this.linkExistingLoadingDocketDialogRef = this.dialog.open(LinkExistingLoadingDocketDialogComponent, {
      hasBackdrop: false,
      height: 'auto',
      maxWidth: '90vw',
      data: { existingLoadingDocket: existingLoadingDocket }
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.linkExistingLoadingDocketDialogRef.backdropClick().subscribe(() => {
      this.linkExistingLoadingDocketDialogRef.close();
    });



    this.linkExistingLoadingDocketDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, selectedLoadingDocket: dtoLoadingDocket }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nNull data returned in link existing loading docket\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the LinkExistingLoadingDocket dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

          console.log("data.selectedLoadingDocket", data.selectedLoadingDocket);
          if (data.selectedLoadingDocket != null) {
            // Remove any existing links to other loading dockets.
            //let aParamList: SQLParamArray[][] = [];
            let aParamList: SQLParamArray[][] = [];
            let aParam: SQLParamArray[] = [];

            aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocket.rowguid));
            aParamList.push(aParam);

            let loadingDocketDeliveries: LoadingDocketDelivery[] = await this.loadingDocketDeliveryService.getLoadingDocketDeliveryParamArrayPromise(aParamList);
            for (let i = 0; i <= loadingDocketDeliveries.length - 1; i++) {
              console.log("deleting loading docket delivery");
              await this.loadingDocketDeliveryService.deleteLoadingDocketDeliveryPromise(loadingDocketDeliveries[i].rowguid);
            }

            // We've selected 'None' so don't link it to any loading docket.
            if (data.selectedLoadingDocket.rowguid != null) {
              console.log("creating loading docket delivery");
              let loadingDocketDelivery: LoadingDocketDelivery = new LoadingDocketDelivery();
              loadingDocketDelivery.guDeliveryDocketId = deliveryDocket.rowguid;
              loadingDocketDelivery.guLoadingDocketId = data.selectedLoadingDocket.rowguid;
              await this.loadingDocketDeliveryService.createLoadingDocketDeliveryPromise(loadingDocketDelivery);

              // Update HaulingCompany and Driver on delivery docket that is already set on Loading Docket.
              let delDocket: DeliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(deliveryDocket.rowguid);
              if (delDocket != null) {
                delDocket.guHaulingId = data.selectedLoadingDocket.guHaulerId;
                delDocket.guDriverId = data.selectedLoadingDocket.guDriverId;
                delDocket.dteExpectedDeliveryDate = data.selectedLoadingDocket.dteExpectedDeliveryDate;
                delDocket.dteExpectedLoadingDate = data.selectedLoadingDocket.dteExpectedLoadingDate;
                await this.deliveryDocketService.updateDeliveryDocketPromise(delDocket);
              }
            }

            // Refresh delivery docket with newly linked loading docket.
            deliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(deliveryDocket.rowguid);

            console.log("this.datasource.data", this.datasource.data);
            if (this.datasource.data != null) {
              for (let i = 0; i <= this.datasource.data.length - 1; i++) {
                console.log(this.datasource.data[i].rowguid + " == " + deliveryDocket.rowguid);
                if (this.datasource.data[i].rowguid == deliveryDocket.rowguid) {
                  console.log("this.datasource.data[i] = deliveryDocket");
                  this.datasource.data[i] = deliveryDocket;
                  this.tblDeliveryDocket.renderRows();
                  break;
                }
              }
            }

            if (data.selectedLoadingDocket.rowguid != null) {
              this.alertService.openSnackBarDefault("The loading docket " + data.selectedLoadingDocket.intDocketNo.toString() + " has been linked to delivery docket " + deliveryDocket.intDocketNo.toString());
            }

            if (data.selectedLoadingDocket.rowguid == null) {
              this.alertService.openSnackBarDefault("The delivery docket is no longer linked to a loading docket.");
            }

          }

        }
        else {
          // We have cancellled

        }

      });


  }

  async addToNewLoadingDocket(deliveryDocket: dtoDeliveryDocket, index: number) {

    // CREATE LOADING DOCKET.
    if (this.addToNewLoadingDocketCreateDialogRef != null) {
      this.addToNewLoadingDocketCreateDialogRef.close();
    }

    if (deliveryDocket == null) {
      this.alertService.openSnackBarDefault("There is no delivery docket selected.");
      return;
    }

    this.rowColorPropagation(deliveryDocket.rowguid);

    this.addToNewLoadingDocketCreateDialogRef = this.dialog.open(LoadingDocketCreateDialogComponent, {
      hasBackdrop: false,
      height: 'auto',
      maxWidth: '90vw'
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.addToNewLoadingDocketCreateDialogRef.backdropClick().subscribe(() => {
      this.addToNewLoadingDocketCreateDialogRef.close();
    });



    this.addToNewLoadingDocketCreateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, loadingDocket: LoadingDocket }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nNull data returned in link existing loading docket\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the LinkExistingLoadingDocket dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

          console.log("data.selectedLoadingDocket", data.loadingDocket);
          if (data.loadingDocket != null) {


            // Remove any existing links to other loading dockets if they exist.
            //let aParamList: SQLParamArray[][] = [];
            let aParamList: SQLParamArray[][] = [];
            let aParam: SQLParamArray[] = [];

            aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocket.rowguid));
            aParamList.push(aParam);

            let loadingDocketDeliveries: LoadingDocketDelivery[] = await this.loadingDocketDeliveryService.getLoadingDocketDeliveryParamArrayPromise(aParamList);
            if (loadingDocketDeliveries != null && loadingDocketDeliveries.length > 0) {
              let existingLoadingDocket: LoadingDocket = await this.loadingDocketService.getLoadingDocketPromise(loadingDocketDeliveries[0].guLoadingDocketId);
              let msg: string = await this.alertService.openSnackBarCustomPromise(" ", "The delivery docket is already linked to a loading docket number " + existingLoadingDocket.intDocketNo + ".\nDo you want to remove this delivery docket from loading docket number " + existingLoadingDocket.intDocketNo + " and add it to a new loading docket?", "Yes", "No", "center", "bottom");

              if (msg == "No") {
                return;
              }

              for (let i = 0; i <= loadingDocketDeliveries.length - 1; i++) {
                //console.log("deleting loading docket delivery");
                await this.loadingDocketDeliveryService.deleteLoadingDocketDeliveryPromise(loadingDocketDeliveries[i].rowguid);
              }
            }


            console.log("creating loading docket delivery");
            let loadingDocketDelivery: LoadingDocketDelivery = new LoadingDocketDelivery();
            loadingDocketDelivery.guDeliveryDocketId = deliveryDocket.rowguid;
            loadingDocketDelivery.guLoadingDocketId = data.loadingDocket.rowguid;
            await this.loadingDocketDeliveryService.createLoadingDocketDeliveryPromise(loadingDocketDelivery);

            // Refresh delivery docket with newly linked loading docket.
            deliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(deliveryDocket.rowguid);

            console.log("this.datasource.data", this.datasource.data);
            if (this.datasource.data != null) {
              for (let i = 0; i <= this.datasource.data.length - 1; i++) {
                console.log(this.datasource.data[i].rowguid + " == " + deliveryDocket.rowguid);
                if (this.datasource.data[i].rowguid == deliveryDocket.rowguid) {
                  console.log("this.datasource.data[i] = deliveryDocket");
                  this.datasource.data[i] = deliveryDocket;
                  this.tblDeliveryDocket.renderRows();
                  break;
                }
              }
            }

            this.alertService.openSnackBarDefault("The loading docket " + data.loadingDocket.intDocketNo.toString() + " has been created and linked to delivery docket " + deliveryDocket.intDocketNo.toString());

          }




        }
        else {
          // We have cancellled

        }

      });

    this.rowColorPropagation(deliveryDocket.rowguid);

  }

  async openDeliveryAddressDialog(deliveryDocket: dtoDeliveryDocket) {

    if (this.customerDeliveryAddressDialogRef != null) {
      this.customerDeliveryAddressDialogRef.close();
    }

    if (deliveryDocket == null) {
      this.alertService.openSnackBarDefault("There is no delivery docket selected.");
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParamList = [];
    aParam = [];
    aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocket.rowguid));
    aParamList.push(aParam);

    let deliverDocketOrderItems: dtoDeliveryDocketOrderItem[] = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemParamArrayPromise(aParamList);
    if (deliverDocketOrderItems == null || deliverDocketOrderItems.length == 0) {
      this.alertService.openSnackBarDefault("There are no order items on this delivery docket");
      return;
    }

    let deliveryDocketOrderItem: dtoDeliveryDocketOrderItem = deliverDocketOrderItems[0];
    let dtoDelAddress: dtoCustomerDeliveryAddress = null;
    dtoDelAddress = await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressPromise(deliveryDocketOrderItem.guCustomerDeliveryAddressId);


    this.customerDeliveryAddressDialogRef = this.dialog.open(CustomerDeliveryAddressDialogComponent, {
      hasBackdrop: false,
      height: 'auto',
      maxWidth: '90vw',
      data: { customerId: deliveryDocketOrderItem.guDeliveryDocketCustomerId, customerName: deliveryDocketOrderItem.txtCustomerName, selectedCustomerDeliveryAddress: dtoDelAddress }
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.customerDeliveryAddressDialogRef.backdropClick().subscribe(() => {
      this.customerDeliveryAddressDialogRef.close();
    });


    this.customerDeliveryAddressDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, customerDeliveryAddress: ClientDeliveryAddress }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);


          if (data.customerDeliveryAddress == null) {
            this.alertService.openSnackBar("There is no delivery address selected.", "Close", "center", "bottom", "", 5000);
            return;
          }

          if (data.customerDeliveryAddress != null) {

            let delDocketOrderItem: DeliveryDocketOrderItem[] = await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemByDocketIdPromise(deliveryDocketOrderItem.guDeliveryDocketId);

            for (let i = 0; i <= delDocketOrderItem.length - 1; i++) {
              delDocketOrderItem[i].guCustomerDeliveryAddressId = data.customerDeliveryAddress.rowguid;
              await this.deliveryDocketOrderItemService.updateDeliveryDocektOrderItemPromise(delDocketOrderItem[i]);

              //console.log("updateDeliveryDocektOrderItemPromise:", deliveryDocketOrderItem[i]);

            }

            // Get updated delivery docket.
            let delDocket: dtoDeliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(deliveryDocket.rowguid);
            if (delDocket != null) {
              for (let i = 0; i <= this.datasource.data.length - 1; i++) {
                //console.log("update del docket: ");
                if (this.datasource.data[i].rowguid == deliveryDocket.rowguid) {
                  this.datasource.data[i] = delDocket;
                  this.tblDeliveryDocket.renderRows();
                  break;
                }
              }
            }

            //for (let i = 0; i <= this.deliveryDocketOrderItems.length - 1; i++) {

            //}

            this.alertService.openSnackBarDefault("Delivery address has been updated.");

          }

        }
        else {
          // We have cancellled

        }

      });

  }

  customerLocation(deliveryDocket: dtoDeliveryDocket) {
    if (deliveryDocket.txtCustomer == null || deliveryDocket.txtLocation == null) {
      return;
    }

    return (deliveryDocket.txtCustomer.indexOf(',') > -1 && deliveryDocket.txtLocation.indexOf(',') > -1) ? deliveryDocket.txtCustomerLocation.replace("&amp;", "&") : deliveryDocket.txtCustomer + ' - ' + deliveryDocket.txtLocation.replace("&amp;", "&");

  }

  copyToClipboard(value: string) {
    this.formService.copyToClipboard(value);
    this.alertService.openSnackBar("The value " + value + " has been copied to the clipboard", "Close", "center", "bottom", "", 3000);
  }

  async setNextDelivery(val: boolean, deliveryDocket: dtoDeliveryDocket) {

    if (val == null) {
      this.alertService.openSnackBarError("There was a problem reading the tick box value", "Close", "center", "bottom", 3000, false, "");
      return;
    }

    let deliveryDoc: DeliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(deliveryDocket.rowguid);
    deliveryDoc.blnNextDelivery = val;

    await this.deliveryDocketService.updateDeliveryDocketPromise(deliveryDoc);

    this.alertService.openSnackBarDefault("The delivery docket next delivery has been updated");

  }

  async setInvoiceStatus(invoiceStatus: number, deliveryDocket: dtoDeliveryDocket) {
    console.log(invoiceStatus);
    let deliveryDoc: DeliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(deliveryDocket.rowguid);
    deliveryDoc.intInvoiceStatus = invoiceStatus;

    await this.deliveryDocketService.updateDeliveryDocketPromise(deliveryDoc);

    console.log(deliveryDoc);

    this.alertService.openSnackBarDefault("The delivery docket invoice status has been updated");

  }

  async setInvoiceUploaded(intInvoicePortalUploaded: number, deliveryDocket: dtoDeliveryDocket) {
    console.log(intInvoicePortalUploaded);
    let deliveryDoc: DeliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(deliveryDocket.rowguid);
    deliveryDoc.intInvoicePortalUploaded = intInvoicePortalUploaded;

    await this.deliveryDocketService.updateDeliveryDocketPromise(deliveryDoc);

    this.alertService.openSnackBarDefault("The delivery docket invoice portal upload has been updated");

  }

  setRowColor(deliveryDocketId: string) {

    if (this.selectedRow == "" || deliveryDocketId != this.selectedRow) {
      this.selectedRow = deliveryDocketId;
      return;
    }

    if (deliveryDocketId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }

  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      if (window.event != null) {
        window.event.stopPropagation();
      }
    }
  }

  doubleclick(idx: number) {
    let div: HTMLDivElement = document.getElementById("locationrow" + idx.toString()) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(function () {
        if (div.getAttribute("data-dblclick") == "1") {
          window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 300);
    } else {
      div.removeAttribute("data-dblclick");
      window.alert('ondouble');
    }
  }

  positionSpinner(top: number, left: number) {

    //setTimeout(() => {
    //}, 500);

    let spinnerInlineContent: HTMLCollectionOf<HTMLDivElement> = document.getElementsByClassName("spinner-inline-tick-content") as HTMLCollectionOf<HTMLDivElement>;
    let inlineSpinner = document.getElementById("inlineSpinnerId");
    inlineSpinner.style.position = "absolute";
    inlineSpinner.style.top = top.toString() + 'px';
    inlineSpinner.style.left = left.toString() + 'px';
    inlineSpinner.style.padding = "0";

    //inlineSpinner.style.opacity = "0.1"
    inlineSpinner.style.backgroundColor = "#868686"

    if (spinnerInlineContent[0] != null) {
      spinnerInlineContent[0].style.backgroundColor = "#868686"
    }

    //console.log("inlineSpinner", inlineSpinner);
    //console.log("spinnerInlineContent", spinnerInlineContent[0]);

  }

  showAndPositionInlineSpinner(top: number, left: number) {
    this.showInlineSpinner = true;
    setTimeout(() => { this.positionSpinner(top, left) }, 200);
  }

  hideInlineSpinner() {
    this.showInlineSpinner = false;

    setTimeout(() => { this.showTick = true }, 400);
    setTimeout(() => { this.showTick = false }, 600);
  }

  async emailDeliveryDocket(deliveryDocket: dtoDeliveryDocket, emailButtonTD: HTMLTableDataCellElement, toAddresses: string, subject: string, message: string) {

    console.log("emailButtonTD", emailButtonTD);

    var offsets = emailButtonTD.getBoundingClientRect();
    var top = offsets.top + window.scrollY - 175;
    var left = offsets.left + window.scrollX - 25;

    //console.log("top" + top + " - left: " + left);

    this.showAndPositionInlineSpinner(top, left);

    this.rowColorPropagation(deliveryDocket.rowguid);

    if (this.customerDeliveryAddressDialogRef != null) {
      this.customerDeliveryAddressDialogRef.close();
    }

    if (deliveryDocket == null || deliveryDocket.rowguid == "") {
      this.alertService.openSnackBarDefault("There is no delivery docket selected.");
      return;
    }

    this.showTOCs = true;

    let fileAttachments: FileAttachment[] = [];
    let fileAttachment: FileAttachment = await this.getPDFBase64(deliveryDocket);
    if (fileAttachment != null) {
      fileAttachments.push(fileAttachment);
    }

    this.showTOCs = false;

    this.hideInlineSpinner();

    //console.log(fileAttachments);
    let username: string = (await this.authService.getUser()).displayName;
    //let toAddresses: string = "";
    let ccAddresses: string = "";
    let customerDeliveryAddress: dtoCustomerDeliveryAddress[] = await this.dtoCustomerDeliveryAddressService.getdtoDeliveryDocketOrderItemAddresses(deliveryDocket.rowguid);

    if (toAddresses == "" && customerDeliveryAddress != null && customerDeliveryAddress.length > 0) {
      let customerDeliveryToAddressEmails = customerDeliveryAddress.filter(a => a.txtDeliveryEmailAddressTo > "").map(a => a.txtDeliveryEmailAddressTo);
      let customerDeliveryCCAddressEmails = customerDeliveryAddress.filter(a => a.txtDeliveryEmailAddressCC > "").map(a => a.txtDeliveryEmailAddressCC);

      //console.log("customerDeliveryCCAddressEmails", customerDeliveryCCAddressEmails);

      let distinctToEmails: string[] = [... new Set(customerDeliveryToAddressEmails)];
      let distinctCCEmails: string[] = [... new Set(customerDeliveryCCAddressEmails)];
      toAddresses = (distinctToEmails.toString()).replace(",", ";\n");
      ccAddresses = (distinctCCEmails.toString()).replace(",", ";\n");
      //console.log("distinctCCEmails", distinctCCEmails);
    }

    if (subject == null || subject == "") {
      subject = "Coffs Harbour Hardwoods Delivery Docket";
    }

    if (message == null || message == "") {
      message = "Hi\n\nPlease find attached delivery docket " + deliveryDocket.intDocketNo.toString() + "\n\nRegards\n\n" + username
      if (deliveryDocket.txtCustomerName == "Essential Energy") {
        subject = deliveryDocket.txtOrderNo + " - " + deliveryDocket.txtLocation
        message = "Hi\n\nPlease find attached delivery docket for order " + deliveryDocket.txtOrderNo + " which will be loading on ## and expected delivery on ##." + "\n\nRegards\n\n" + username
      }
    }


    this.sendMailDialogRef = this.dialog.open(SendMailDialogComponent, {
      hasBackdrop: false,
      height: 'auto',
      maxWidth: '90vw',
      data: { toAddresses: toAddresses, ccAddresses: ccAddresses, bccAddresses: "", emailSubject: subject, emailMessage: message, fileAttachements: fileAttachments }
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.sendMailDialogRef.backdropClick().subscribe(() => {
      this.sendMailDialogRef.close();
    });


    this.sendMailDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);


          //if (data.customerDeliveryAddress == null) {
          //  this.alertService.openSnackBar("There is no delivery address selected.", "Close", "center", "bottom", "", 5000);
          //  return;
          //}

          //if (data.customerDeliveryAddress != null) {

          //  this.alertService.openSnackBarDefault("Delivery address has been updated.");

          //}

        }
        else {
          // We have cancellled

        }

      });

  }

  async emailAusgridCSV(deliveryDocket: dtoDeliveryDocket, rowIndex: number, ausgridEmailButtonTD: HTMLTableDataCellElement) {

    var offsets = ausgridEmailButtonTD.getBoundingClientRect();
    var top = offsets.top + window.scrollY - 175;
    var left = offsets.left + window.scrollX - 25;

    //console.log("top" + top + " - left: " + left);

    this.showAndPositionInlineSpinner(top, left);

    this.rowColorPropagation(deliveryDocket.rowguid);

    let csvString: string = "Shipment No,Shipment Date,Customer Order No,Deliver To Address,Customer Item No,Item Description,Item Barcode No,Quantity,Supplier,Hazard Class,Preservative,Disc Date,Species,Registered Plant\n";


    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    //=======================================================================================
    //====================== TO DO ==========================================================
    // WE NEED TO SEND THE FINAL INSPECTION PDF AS WELL, SO FIND OUT HOW MANY FINALS MAY BE
    // LINKED TO THIS DELVIERY DOCKET. THEN GET ALL FINAL INSPECTIONS AS PDF's AND ATTACH
    // THEM TO THE EMAIL. NORMALLY THERE IS ONLY ONE FINAL PER DELIVERY DOCKET BUT THERE
    // SOMETIMES BE TWO.
    //=======================================================================================


    aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocket.rowguid));
    aParamList.push(aParam);
    let finalItems: dtoTPPoleInspectionItem[] = await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemParamArrayPromise(aParamList);
    let finalIds: string[] = [];
    const datepipe: DatePipe = new DatePipe('en-AU');

    for (let i = 0; i <= finalItems.length - 1; i++) {
      let itemNo: string = String(finalItems[i].txtPoleNumber);
      if (String(finalItems[i].txtPoleNumber) == "" && String(finalItems[i].txtConsecutiveNumber) != "") {
        itemNo = String(finalItems[i].txtConsecutiveNumber);
      }
      let statusDate: Date = new Date(deliveryDocket.dteStatusDate);
      let hazardLevel: string = "";
      let perservativeType: string = "";
      if (finalItems[i].intCCAHazardLevel > 0) {
        hazardLevel = "H" + finalItems[i].intCCAHazardLevel.toString();
        perservativeType = "01";
      }

      csvString += deliveryDocket.intDocketNo.toString() + ", " + statusDate.getDay() + "/" + statusDate.getMonth() + "/" + statusDate.getFullYear() + ", " + finalItems[i].txtOrderNo + ", " + deliveryDocket.txtDelOrderItemAddressTownStatePostcode.replace(/,/g, " ") + ", " + finalItems[i].txtStockCode + ", " + finalItems[i].txtFinalPole + " kN  " + finalItems[i].txtSpeciesCode + ", " + itemNo + ", 1, Coffs Harbour Hardwoods Trading Pty Ltd," + hazardLevel + "," + perservativeType + "," + new Date(datepipe.transform(finalItems[i].dteDateInspected, 'yyyy')).getFullYear() + "," + finalItems[i].txtSpeciesCode + ",126\n";

      if (finalIds.indexOf(finalItems[i].guTPPoleInspectionId, 0) == -1) {
        finalIds.push(finalItems[i].guTPPoleInspectionId);
      }
   }



    //const blob = new Blob([csvString], { type: 'text/csv' });
    //const url = window.URL.createObjectURL(blob);
    //const a = document.createElement('a');
    //a.setAttribute('href', url);
    //a.setAttribute('download', 'download.csv');
    //a.click();


    if (this.customerDeliveryAddressDialogRef != null) {
      this.customerDeliveryAddressDialogRef.close();
    }

    if (deliveryDocket == null || deliveryDocket.rowguid == "") {
      this.alertService.openSnackBarDefault("There is no delivery docket selected.");
      return;
    }

    let csvBlob: Blob = new Blob([csvString], { type: 'text/csv' });
    let csvBase64 = await this.fileService.fileToBase64(csvBlob);

    //console.log("blob size", csvBlob.size);

    let fileAttachments: FileAttachment[] = [];
    let fileAttachment: FileAttachment = new FileAttachment("Poles Delivery Report " + deliveryDocket.intDocketNo.toString() + ".csv", "", "text/csv", csvBase64, null, csvBlob.size, new Date(Date.now()));
    if (fileAttachment != null) {
      fileAttachments.push(fileAttachment);
    }

    if (finalIds.length > 0) {
      //console.log("finalIds", finalIds);
      aParamList = [];
      aParam = [];

      for (let f = 0; f <= finalIds.length - 1; f++) {
        aParam.push(new SQLParamArray("rowguid", finalIds[f]));
      }

      aParamList.push(aParam);

      let finalInspections: dtoTPPoleInspection[] = await this.dtoTPPoleInspectionService.getdtoTPPoleInspectionParamArrayPromise(aParamList, TPPoleInspection.enOnDeliveryDocket.Either);
      //console.log(" ***** finalInspections *****", finalInspections);

      //let fileAttachmentArray: FileAttachment[] = [];
      if (finalInspections != null) {
        for (let f = 0; f <= finalInspections.length - 1; f++) {
          let attachment: FileAttachment = await this.createFinalPDFAttachement(finalInspections[f]);

          if (attachment != null) {
            fileAttachments.push(attachment);
          }

        }
      }
    }

    this.showTOCs = true;

    let deliveryDocketFileAttachment: FileAttachment = await this.getPDFBase64(deliveryDocket);
    if (deliveryDocketFileAttachment != null) {
      fileAttachments.push(deliveryDocketFileAttachment);
    }

    this.showTOCs = false;

    this.hideInlineSpinner();

    //console.log(fileAttachments);
    let username: string = (await this.authService.getUser()).displayName;
    let toAddresses: string = "";
    let ccAddresses: string = "";
    let customerFirstName: string = "";
    let customerDeliveryAddress: dtoCustomerDeliveryAddress[] = await this.dtoCustomerDeliveryAddressService.getdtoDeliveryDocketOrderItemAddresses(deliveryDocket.rowguid);

    if (customerDeliveryAddress != null && customerDeliveryAddress.length > 0) {
      let customerDeliveryToAddressEmails = customerDeliveryAddress.filter(a => a.txtDeliveryEmailAddressTo > "").map(a => a.txtDeliveryEmailAddressTo);
      let customerDeliveryCCAddressEmails = customerDeliveryAddress.filter(a => a.txtDeliveryEmailAddressCC > "").map(a => a.txtDeliveryEmailAddressCC);
      let customerContactFirstNames = customerDeliveryAddress.filter(a => a.txtContactFirstName > "").map(a => a.txtContactFirstName);

      //console.log("customerDeliveryCCAddressEmails", customerDeliveryCCAddressEmails);

      let distinctToEmails: string[] = [... new Set(customerDeliveryToAddressEmails)];
      let distinctCCEmails: string[] = [... new Set(customerDeliveryCCAddressEmails)];
      let distinctCustomerContactFirstName: string[] = [... new Set(customerContactFirstNames)];

      toAddresses = (distinctToEmails.toString()).replace(",", ";\n");
      ccAddresses = (distinctCCEmails.toString()).replace(",", ";\n");
      customerFirstName = String(distinctCustomerContactFirstName[0]);

      //console.log("customerFirstName", customerFirstName);

    }

    let subject: string = deliveryDocket.txtLocation + " Poles Delivery Report " + deliveryDocket.intDocketNo.toString() + " - " + deliveryDocket.txtOrderNo;
    let message: string = "Hi " + customerFirstName + "\n\nPlease find attached pole delivery report \n\nRegards\n\n" + username


    this.sendMailDialogRef = this.dialog.open(SendMailDialogComponent, {
      hasBackdrop: false,
      height: 'auto',
      maxWidth: '90vw',
      data: { toAddresses: toAddresses, ccAddresses: ccAddresses, bccAddresses: "", emailSubject: subject, emailMessage: message, fileAttachements: fileAttachments }
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.sendMailDialogRef.backdropClick().subscribe(() => {
      this.sendMailDialogRef.close();
    });


    this.sendMailDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {

        }
        else {
          // We have cancellled

        }

      });
  }

}
