<div class="product-type-select-container" #productTypeContainer>
  <mat-form-field class="chh-form-field example-full-width">
    <mat-label>Search Product Type</mat-label>
    <input matInput data-product-type-input="productTypeInput" class="product-type-input" placeholder="Filter by name" placeholderLabel="i.e. Essential Energy....." value="" type="text" #productTypeFilter (keyup)="filterProductTypes(productTypeFilter.value, $event)" (focus)="productTypeFilter.select()" (click)="checkProductTypeListIsOpen(productTypeFilter.value)">
  </mat-form-field>

  <mat-icon matTooltip="Click to add a Product Type" class="clickable-icon person-add" (click)="addProductType()">person_add</mat-icon>
  <span><mat-slide-toggle #multipleProductTypes (click)="checkMultipleProductTypes(multipleProductTypes.checked)">Multiple Product Types</mat-slide-toggle></span>
  <div class="selected-product-types-container">
    <div class="selected-product-types" id="selectedProductTypes" *ngIf="selectedProductTypes != null && selectedProductTypes.length > 0">
      <div class="chip-column">
        <mat-chip-listbox #chipGrid aria-label="ProductType selection">
          @for (selectProduct of selectedProductTypes; track selectProduct; let selProdIdx = $index; let lastItem = $last) {
          <mat-chip-option (removed)="removeProductType(selectProduct)">
            {{selectProduct.txtName}}
            <button matChipRemove [attr.aria-label]="'remove ' + selectProduct.txtName">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
          }
        </mat-chip-listbox>
      </div>
      <div class="see-more-chips">
        <button mat-icon-button aria-label="expand row" (click)="expandSelectedProductTypes($event)">
          <mat-icon class="expand-icons product-type-selection-expand-icon material-icons-outlined" *ngIf="productTypeSelectionExpanded == false ">keyboard_arrow_down</mat-icon>
          <mat-icon class="expand-icons product-type-selection-expand-icon material-icons-outlined" *ngIf="productTypeSelectionExpanded == true">keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div data-product-type-select="productTypeSelect" class="product-type-container" *ngIf="showProductTypeList" #productTypeListContainer>
    <div data-product-type-select="productTypeSelect" id="productTypeSelect" class="product-type-select-list">
      <div class="product-type-pin-row">
        <div class="button" data-push-pin="pushPin" id="btnPushPin" (click)="clickPin($event)" [ngClass]="productTypeListPinned == true ? 'button-pressed' : 'button'" matTooltip="{{toolTipText}}">
          <mat-icon data-push-pin="pushPin" class="pin-icon">push_pin</mat-icon> 
        </div> 
      </div>
      <div class="product-type-heading-row product-type-name-heading">Name</div>
      <div class="product-type-heading-row product-type-update-heading"></div>
      @for(productType of productTypesFiltered; track productType; let idx = $index){
      <div data-product-type-select="productTypeSelect" tabindex="{{idx}}" id="productTypeName{{idx}}" (keyup)="nextProductType($event, productType)" class="chh-list-item-row chh-list-item-first-column product-type-name" [ngClass]="productType.guSelectedProductTypeId != '' ? 'chh-list-item-row chh-list-item-row-selected product-type-first-name' : addressHoverId == productType.rowguid ? 'chh-list-item-row chh-list-item-row-hover product-type-first-name' : 'chh-list-item-row product-type-first-name'" (mouseenter)="rowEnter(productType.rowguid)" (mouseleave)="rowLeave()" (click)="selectProductType(productType, !(productType.guSelectedProductTypeId != ''))"><div data-product-type-select="productTypeSelect" class="product-type-text">{{productType.txtName}}</div></div>
      <div data-product-type-select="productTypeSelect" id="productTypeUpdateDiv{{idx}}" class="chh-list-item-row product-type-radio product-type-update-div" [ngClass]="productType.guSelectedProductTypeId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == productType.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(productType.rowguid)" (mouseleave)="rowLeave()" (click)="selectProductType(productType, !(productType.guSelectedProductTypeId != ''))">
        <div data-product-type-select="productTypeSelect" id="productTypeUpdateButtonDiv{{idx}}" class="update-product-type-button">
          <button mat-button (click)="updateProductType(productType)" matTooltip="Click to edit ProductType">
            <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
          </button>
        </div>
      </div>

      }
    </div>

  </div>

</div>
