export interface MobilePlantEquipment
{
	blnActive: boolean;
	dteDateOfManufacture: Date;
	dteLastUpdated: Date;
	fltEquipmentLength: number;
	guMobilePlantCategoryId: string;
	intCategory: number;
	intCHHReferenceNo: number;
	intEquipmentLengthUoM: number;
	intEquipmentType: number;
	intFuelType: number;
	intIdentifier: number;
	intMeterType: number;
	intSection: number;
	memComments: string;
	rowguid: string;
	txtAbbreviation: string;
	txtIdentifierCode: string;
	txtModel: string;
	txtName: string;
}

export class MobilePlantEquipment
{
	blnActive: boolean;
	dteDateOfManufacture: Date;
	dteLastUpdated: Date;
	fltEquipmentLength: number;
	guMobilePlantCategoryId: string;
	intCategory: number;
	intCHHReferenceNo: number;
	intEquipmentLengthUoM: number;
	intEquipmentType: number;
	intFuelType: number;
	intIdentifier: number;
	intMeterType: number;
	intSection: number;
	memComments: string;
	rowguid: string;
	txtAbbreviation: string;
	txtIdentifierCode: string;
	txtModel: string;
	txtName: string;
}

