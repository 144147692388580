import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StockContainerType } from '../business-objects/stock-container-type.bo';
import { SharedService } from './shared.service';

@Injectable()
export class StockContainerTypeService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService) {
    this._baseUrl = baseUrl;
  }

  getStockContainerTypes() {
    return this.http.get<StockContainerType[]>(this._baseUrl + 'api/stockcontainertype');
  }

  getStockContainerType(stockContainerTypeId: string) {
    return this.http.get<StockContainerType>(this._baseUrl + 'api/stockcontainertype/' + stockContainerTypeId);
  }

  getStockContainerByName(name: string) {
    return this.http.get<StockContainerType>(this._baseUrl + 'api/stockcontainertype/GetStockContainerTypeByName/' + name);
  }

  updateStockContainerType(stockContainerType: StockContainerType) {
    console.log(this._baseUrl + 'api/stockcontainer/');
    return this.http.put<StockContainerType>(this._baseUrl + 'api/stockcontainertype/', stockContainerType);
  }

  createStockContainerType(stockContainerType: StockContainerType) {
    return this.http.post<StockContainerType>(this._baseUrl + 'api/stockcontainertype/', stockContainerType);
  }


  getStockContainerByNamePromise(name: string) {
    return new Promise<StockContainerType>(resolve => {
      this.getStockContainerByName(name).subscribe(stockContainerType => {

        resolve(stockContainerType);

      }, err => {
        console.log(err);
      });
    });
  }

}  
