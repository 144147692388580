import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';
import { OnChanges } from '@angular/core';
import { EventEmitter, Output, ViewChild } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../account/auth.service';
import { BoardTallyRecordDeliveryTransferred } from '../_shared/business-objects/board-tally-record-delivery-transferred.bo';
import { BoardTallyRecordHistory } from '../_shared/business-objects/board-tally-record-history.bo';
import { BoardTallyRecord } from '../_shared/business-objects/board-tally-record.bo';
import { dtoDeliveryDocketStockTransfer } from '../_shared/business-objects/dto-delivery-docket-stock-transfer.bo';
import { dtoStockItem } from '../_shared/business-objects/dto-stock-item.bo';
import { GeoStockLocation } from '../_shared/business-objects/geo-stock-location.bo';
import { DBFieldFunction, SQLParamArray } from '../_shared/business-objects/sql-param-array';
import { StockContainerType } from '../_shared/business-objects/stock-container-type.bo';
import { StockContainer } from '../_shared/business-objects/stock-container.bo';
import { AlertService } from '../_shared/services/alert.service';
import { BoardTallyRecordDeliveryTransferredService } from '../_shared/services/board-tally-record-delivery-transferred.service';
import { BoardTallyRecordService } from '../_shared/services/board-tally-record.service';
import { SharedService } from '../_shared/services/shared.service';
import { StockContainerTypeService } from '../_shared/services/stock-container-type.service';
import { StockContainerService } from '../_shared/services/stock-container.service';

enum enStockItemColumns { 'txtProductName', 'txtProfileName', 'intWidth', 'intThickness', 'txtSpeciesName', 'txtGradeName', 'fltQuantity', 'txtQuantityUoM', 'fltLength', 'fltDia', 'txtLocation', 'intIdentifier', 'dteDateCreated', 'txtStockKeepingUnit', 'txtNewLocation', 'chkFixedStockType', 'btnReceiveStock', 'chkReceiveStock' };

@Component({
  selector: 'app-stock-transfer-stock-item',
  templateUrl: './stock-transfer-stock-item.component.html',
  styleUrls: ['./stock-transfer-stock-item.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', [
        animate('325ms cubic-bezier(0.4, 0.0, 0.2, 1)'),  //
        query('@*', animateChild(), { optional: true })
      ]),
    ]),
  ]

})
export class StockTransferStockItemComponent implements OnInit, OnChanges {

  form: FormGroup;

  @Input() stockContainerRackType: StockContainerType;
  @Input() geoStockLocation: GeoStockLocation;
  @Input() data: dtoDeliveryDocketStockTransfer[];
  @Output() modifiedData: dtoStockItem[];

  @Output() transferToStockItemEvent = new EventEmitter<[dtoStockItem, dtoDeliveryDocketStockTransfer]>();
  @Output() transferWholeStockItemEvent = new EventEmitter<[dtoDeliveryDocketStockTransfer, number]>();


  @ViewChild("tblTransferStockItems") tblTransferStockItems: MatTable<dtoStockItem>;

  displayedStockItems: string[] = ['txtProductName', 'txtProfileName', 'intWidth', 'intThickness', 'txtSpeciesName', 'txtGradeName', 'fltQuantity', 'txtQuantityUoM', 'fltLength', 'fltDia', 'txtLocation', 'intIdentifier', 'dteDateCreated', 'txtStockKeepingUnit', 'txtNewLocation', 'chkFixedStockType', 'btnReceiveStock', 'chkReceiveStock'];

  stockItems: dtoStockItem[];

  stockItemExpanded: dtoDeliveryDocketStockTransfer | null;

  showNoStock: boolean = false;

  constructor(private fb: FormBuilder, private boardTallyRecordService: BoardTallyRecordService, private alertService: AlertService, private authService: AuthService
    , private stockContainerService: StockContainerService, private stockContainerTypeService: StockContainerTypeService
    , private boardTallyRecordDeliveryTransferredService: BoardTallyRecordDeliveryTransferredService, private sharedService: SharedService  ) { }

  ngOnInit(): void {

    this.showNoStock = this.data == null || this.data.length == 0;

    this.form = this.fb.group({
      txtStocktakeLocation: ['']
    });

    // Take out stock keeping unit for now.
    this.displayedStockItems.splice(enStockItemColumns.txtStockKeepingUnit,1);
    this.displayedStockItems.splice(enStockItemColumns.dteDateCreated, 1);  // as you remove them you'll need to minus 1 (-1) since the array is now shorter.

  }


  ngOnChanges() {

    //console.log("On Changes");

  }

  async expandElement(boardTallyRecordHistory: dtoDeliveryDocketStockTransfer, index) {
    let txtLocation: HTMLInputElement = document.getElementById("location" + index) as HTMLInputElement;

    this.showNoStock = false;


    this.stockItemExpanded = this.stockItemExpanded === boardTallyRecordHistory ? null : boardTallyRecordHistory;


    if (this.stockItemExpanded === boardTallyRecordHistory) {
      txtLocation.value = "";

    console.log("boardTallyRecordHistory", boardTallyRecordHistory);

      this.stockItems = await this.getFixedStockItems(boardTallyRecordHistory.guBoardTallyProductCodeId);

      this.showNoStock = this.stockItems.length == 0;
    }



  }

  isExpanded(boardTallyRecordHistory: dtoDeliveryDocketStockTransfer): boolean {
    return (this.stockItemExpanded === boardTallyRecordHistory);

  }


  async getFixedStockItems(productCodeId: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guProductCodeId", productCodeId))
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("blnFixedLocation", "1"))
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("guGeoStockLocationId", this.geoStockLocation.rowguid))
    aParamList.push(aParam);

    return new Promise<dtoStockItem[]>(resolve => {
      this.boardTallyRecordService.getNonDeliveredStockItemParamArray(aParamList).subscribe(stockItems => {

        resolve(stockItems);

        console.log(stockItems);

      }, err => {
        console.log(err);
        resolve(null);
      })
    });
  }

  async getStockContainer(stockContainerId: string) {

    return new Promise<StockContainer>(resolve => {
      this.stockContainerService.getStockContainer(stockContainerId).subscribe(stockContainer => {

        resolve(stockContainer);

      }, err => {
        console.log(err);
        resolve(null);
      })
    });
  }


  transferToStockItem(stockItemToArray: [dtoStockItem, number, number], stockItemFrom: dtoDeliveryDocketStockTransfer) {

    this.stockItems.splice(stockItemToArray[1], 1);

    //if (this.stockItems.length == 0) {
    //  this.deliveryDockets.splice(deliveryDocketIndex, 1);
    //  this.tblDeliveryDockets.renderRows();
    //};

    //console.log("Stock Transfer - TransferToStockItem");
    //console.log(stockItemToArray);

    this.stockItemExpanded = null;

    this.transferToStockItemEvent.emit([stockItemToArray[0], stockItemFrom]);

    this.transferWholeStockItemEvent.emit([null, stockItemToArray[2]]);

    this.tblTransferStockItems.renderRows();

  }

  transferWholeStock(boardTallyRecordHistory: dtoDeliveryDocketStockTransfer, index: number) {

    let snack = this.alertService.openSnackBarCustom("Transfer Stock Alert", "Are you sure you want to transfer the whole of this stock into your location?", "Yes", "No", "center", "bottom", "", 5000);

    snack.onAction().subscribe(async () => {
      if (snack.instance.actionResponse == "Yes") {
        //console.log('The snackbar action was triggered!');
        let stockContainer: StockContainer = await this.getStockContainer(boardTallyRecordHistory.guBoardTallyStockContainerId);

        stockContainer.guGeoStockLocationId = this.geoStockLocation.rowguid;
        stockContainer.txtLocation = boardTallyRecordHistory.txtContainerLocation.toUpperCase();
        if (boardTallyRecordHistory.blnFixedLocation) {
          stockContainer.guStockContainerTypeId = this.stockContainerRackType.rowguid;
        }

        //=========================================================================================================
        // ADD BoardTallyRecordDeliveryTransferred RECORD FOR EACH STOCK ITEM.
        //=========================================================================================================
        let boardTallyRecordDelvieryTransferred = new BoardTallyRecordDeliveryTransferred;
        boardTallyRecordDelvieryTransferred.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
        boardTallyRecordDelvieryTransferred.guBoardTallyRecordId = boardTallyRecordHistory.guBoardTallyRecordId;
        boardTallyRecordDelvieryTransferred.guDeliveryDocketStockTransferId = boardTallyRecordHistory.rowguid;
        await this.boardTallyRecordDeliveryTransferredService.createBoardTallyRecordDeliveryTransferredParamArrayPromise(boardTallyRecordDelvieryTransferred);

      //=========================================================================================================


        this.stockContainerService.updateStockContainer(this.authService.getAppUserEmployeeId(), true, BoardTallyRecordHistory.enChangeReason.StockTransfer, stockContainer).subscribe(stockContainer => {

          this.alertService.openSnackBar("Stock has been transferred", "Close", "center", "bottom", "", 2000);

          this.stockItems = [];
          this.stockItemExpanded = null;
          this.transferWholeStockItemEvent.emit([boardTallyRecordHistory, index]);

          this.tblTransferStockItems.renderRows();


        }, err => {
          console.log(err);
        });
      }



    }, err => {
      console.log(err);
    });

  }

  fixedLocation(e, val) {
    console.log(val);
  }

}
