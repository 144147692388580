export interface Orders
{
	blnAddGST: boolean;
	blnAskForSale: boolean;
	blnBPToProcess: boolean;
	blnCalled: boolean;
	blnConsignment: boolean;
	blnContactedSubContractor: boolean;
	blnDelivered: boolean;
	blnExcludeGST: boolean;
	blnFreightQuoteReceived: boolean;
	blnFreightQuoteRequested: boolean;
	blnJoinery: boolean;
	blnMillToCut: boolean;
	blnMinimumDepositPaid: boolean;
	blnOptionalQuote: boolean;
	blnOrderAmended: boolean;
	blnPaid: boolean;
	blnRemittance: boolean;
	blnSheltered: boolean;
	blnStock: boolean;
	blnWaitingOnAmendments: boolean;
	dteAskForSaleReminder: Date;
	dteCompleted: Date;
	dteCreated: Date;
	dteDeliveryDate: Date;
	dteDueDate: Date;
	dteEntered: Date;
	dteLastEmailed: Date;
	dteLastUpdated: Date;
	fltAdditionalKM: number;
	fltAskForReasonPercentage: number;
	fltDeliveryRate: number;
	fltPreslungCharge: number;
	fltSubContractedFreightQuote: number;
	fltUnloadingCharge: number;
	guClientDelAddressId: string;
	guClientDelAddressId2: string;
	guClientDelAddressId3: string;
	guClientDelAddressId4: string;
	guCustomerContactId: string;
	guCustomerDeliveryContactId: string;
	guCustomerId: string;
	guDeliveryRateId: string;
	guDuplicatedQuoteId: string;
	guEmployeeId: string;
	guOrderTakenById: string;
	guQuotedById: string;
	guQuoteId: string;
	intAskForSaleReason: number;
	intAvailabilityNumber: number;
	intAvailabilityUnit: number;
	intBPReferenceNo: number;
	intDeliveryRateUnit: number;
	intEscortRateUnit: number;
	intEstimateNo: number;
	intInvoiceTransmission: number;
	intLoadOrder: number;
	intOrderId: number;
	intPaymentType: number;
	intProformaNo: number;
	intStatus: number;
	intSystem: number;
	intType: number;
	rowguid: string;
	txtAdminComments: string;
	txtAskForReasonComments: string;
	txtAvailablity: string;
	txtComments: string;
	txtContact: string;
	txtEscort: number;
	txtFreightSubContractor: string;
	txtFreightSubContractorComments: string;
	txtInternalComments: string;
	txtOrderNo: string;
	txtOverDueComments: string;
}

export class Orders
{
	blnAddGST: boolean;
	blnAskForSale: boolean;
	blnBPToProcess: boolean;
	blnCalled: boolean;
	blnConsignment: boolean;
	blnContactedSubContractor: boolean;
	blnDelivered: boolean;
	blnExcludeGST: boolean;
	blnFreightQuoteReceived: boolean;
	blnFreightQuoteRequested: boolean;
	blnJoinery: boolean;
	blnMillToCut: boolean;
	blnMinimumDepositPaid: boolean;
	blnOptionalQuote: boolean;
	blnOrderAmended: boolean;
	blnPaid: boolean;
	blnRemittance: boolean;
	blnSheltered: boolean;
	blnStock: boolean;
	blnWaitingOnAmendments: boolean;
	dteAskForSaleReminder: Date;
	dteCompleted: Date;
	dteCreated: Date;
	dteDeliveryDate: Date;
	dteDueDate: Date;
	dteEntered: Date;
	dteLastEmailed: Date;
	dteLastUpdated: Date;
	fltAdditionalKM: number;
	fltAskForReasonPercentage: number;
	fltDeliveryRate: number;
	fltPreslungCharge: number;
	fltSubContractedFreightQuote: number;
	fltUnloadingCharge: number;
	guClientDelAddressId: string;
	guClientDelAddressId2: string;
	guClientDelAddressId3: string;
	guClientDelAddressId4: string;
	guCustomerContactId: string;
	guCustomerDeliveryContactId: string;
	guCustomerId: string;
	guDeliveryRateId: string;
	guDuplicatedQuoteId: string;
	guEmployeeId: string;
	guOrderTakenById: string;
	guQuotedById: string;
	guQuoteId: string;
	intAskForSaleReason: number;
	intAvailabilityNumber: number;
	intAvailabilityUnit: number;
	intBPReferenceNo: number;
	intDeliveryRateUnit: number;
	intEscortRateUnit: number;
	intEstimateNo: number;
	intInvoiceTransmission: number;
	intLoadOrder: number;
	intOrderId: number;
	intPaymentType: number;
	intProformaNo: number;
	intStatus: number;
	intSystem: number;
	intType: number;
	rowguid: string;
	txtAdminComments: string;
	txtAskForReasonComments: string;
	txtAvailablity: string;
	txtComments: string;
	txtContact: string;
	txtEscort: number;
	txtFreightSubContractor: string;
	txtFreightSubContractorComments: string;
	txtInternalComments: string;
	txtOrderNo: string;
	txtOverDueComments: string;
}

export namespace Orders
{
	export enum enAuctionPacks
	{
		Sold,
		NotSold,
		Both
	}
}

export namespace Orders
{
	export enum enAvailabilityUnit
	{
		None,
		Days,
		Weeks,
		Months
	}
}

export namespace Orders
{
	export enum enDelivered
	{
		Delivered,
		NonDelivered,
		Both
	}
}

export namespace Orders
{
	export enum enDeliveryRateUnit
	{
		None,
		Load,
		M3,
		Tonne
	}
}

export namespace Orders
{
	export enum enEscortRateUnit
	{
		None,
		Load,
		M3,
		Tonne
	}
}

export namespace Orders
{
	export enum enInvoiceTransmission
	{
		None,
		Email,
		Fax,
		Post,
		PickUp
	}
}

export namespace Orders
{
	export enum enPaymentType
	{
		None,
		Account,
		ElectronicFundsTransfer,
		DebitCard,
		DebitCardOverPhone,
		CreditCard,
		PaymentOnDelivery,
		PaymentOnPickup
	}
}

export namespace Orders
{
	export enum enStatus
	{
		Active,
		Complete,
		PartialComplete,
		Cancelled,
		Pending,
		System,
		Inactive,
		Delivered
	}
}

export namespace Orders
{
	export enum enType
	{
		Order,
		Quote
	}
}

