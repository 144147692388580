import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';

@Injectable()
export class CustomerContactService {

  public _baseUrl: string;

  public readonly CoffsHarbourStoreCustomerContactGuid: string = "22222222-2222-2222-2222-222222222222";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getCustomerContactByEmail(email: string) {
    return this.http.get<CustomerContact[]>(this._baseUrl + 'api/Customer/GetCustomerContactsByEmail/' + email);
  }

  getCustomerContact(customerContactId: string) {
    return this.http.get<CustomerContact>(this._baseUrl + 'api/CustomerContact/' + customerContactId);
  }

  getCustomerContactParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<CustomerContact[]>(this._baseUrl + 'api/CustomerContact/GetCustomerContactsParamArray/', aParam);
  }

  getAllCustomerContacts() {
    return this.http.get<CustomerContact[]>(this._baseUrl + 'api/CustomerContact/GetAllCustomerContacts/');
  }

  //getAlldtoCustomerContacts() {
  //	return this.http.get<dtoCustomerContact[]>(this._baseUrl + 'api/CustomerContact/GetAllCustomerContacts/');
  //}

  createCustomerContact(customerContact: CustomerContact) {
    return this.http.post<CustomerContact>(this._baseUrl + 'api/CustomerContact/CreateCustomerContact/', customerContact);
  }

  updateCustomerContact(customerContact: CustomerContact) {
    return this.http.put<CustomerContact>(this._baseUrl + 'api/CustomerContact/UpdateCustomerContact/', customerContact);
  }

  async getCustomerContactPromise(customerContactId: string) {
    return new Promise<CustomerContact>(resolve => {
      this.getCustomerContact(customerContactId).subscribe(customerContact => {
        resolve(customerContact);
      }, err => {
        this.alertService.openSnackBarError("Error getting CustomerContact records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async getCustomerContactParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<CustomerContact[]>(resolve => {
      this.getCustomerContactParamArray(aParam).subscribe(customerContacts => {
        resolve(customerContacts);
      }, err => {
        this.alertService.openSnackBarError("Error getting CustomerContact records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async getCustomerContactsOnDeliveryDocket(customerId: string, deliveryDocketId: string) {
    return new Promise<CustomerContact[]>(resolve => {
      this.http.get<CustomerContact[]>(this._baseUrl + 'api/CustomerContact/GetCustomerContactsOnDeliveryDocket/' + customerId + '/' + deliveryDocketId).subscribe(customerContacts => {
        resolve(customerContacts);
      }, err => {
        this.alertService.openSnackBarError("Error getting CustomerContact records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async updateCustomerContactPromise(customerContact: CustomerContact) {
    return new Promise<CustomerContact>(resolve => {
      this.updateCustomerContact(customerContact).subscribe(customerContact => {
        resolve(customerContact);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating CustomerContact record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async createCustomerContactPromise(customerContact: CustomerContact) {
    return new Promise<CustomerContact>(resolve => {
      this.createCustomerContact(customerContact).subscribe(customerContact => {
        resolve(customerContact);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating CustomerContact record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

}  
