import { Component, Inject, Input } from '@angular/core';
import { BoardTallyRecordService } from '../_shared/services/board-tally-record.service';
import { NavigationService } from '../_shared/services/navigation.service';
import { Router } from '@angular/router';
import { BoardTallyRecord } from '../_shared/business-objects/board-tally-record.bo';
import { dtoStockItemDisplay } from '../_shared/business-objects/dto-stock-item-display.bo';

@Component({
  selector: 'app-boardtallyrecord',
  templateUrl: './boardtallyrecord.component.html'
})
export class BoardTallyRecordComponent {
  //public boardTallyRecords: any;

  @Input() boardTallyRecords: dtoStockItemDisplay[]

  constructor(private router: Router, private boardtallyrecordService: BoardTallyRecordService, private navigationService: NavigationService) {


    //this.boardtallyrecordService.getBoardTallyRecords(this.navigationService.getLocationGuid("BP")).subscribe(result => {
    //  console.log(result);
    //  this.boardTallyRecords = result;
    //}, error => console.error(error));


  }


}  
