import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ProductCode } from '../business-objects/product-code.bo';
import { PricingCategory } from '../business-objects/pricing-category.bo';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';

@Injectable()
export class PricingCategoryService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getPricingCategoryByRowguid(gPricingCategoryId: string) {
    return this.http.get<PricingCategory>(this._baseUrl + 'api/PricingCategory/' + gPricingCategoryId);
  }


  getPricingCategoryByName(name: string) {
    return this.http.get<PricingCategory>(this._baseUrl + 'api/PricingCategory/GetPricingCategoryByName/' + name);
  }

  getPricingCategory(pricingCategoryId: string) {
    return this.http.get<PricingCategory>(this._baseUrl + 'api/PricingCategory/' + pricingCategoryId);
  }

  getPricingCategoryParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<PricingCategory[]>(this._baseUrl + 'api/PricingCategory/GetPricingCategoryParamArray/', aParam);
  }

  getAllPricingCategorys() {
    return this.http.get<PricingCategory[]>(this._baseUrl + 'api/PricingCategory/GetAllPricingCategory/');
  }

  //getAlldtoPricingCategorys() {
  //	return this.http.get<dtoPricingCategory[]>(this._baseUrl + 'api/PricingCategory/GetAllPricingCategory/');
  //}

  createPricingCategory(pricingCategory: PricingCategory) {
    return this.http.post<PricingCategory>(this._baseUrl + 'api/PricingCategory/CreatePricingCategory/', pricingCategory);
  }

  updatePricingCategory(pricingCategory: PricingCategory) {
    return this.http.put<PricingCategory>(this._baseUrl + 'api/PricingCategory/UpdatePricingCategory/', pricingCategory);
  }

  deletePricingCategory(pricingCategoryId: string) {
    return this.http.delete(this._baseUrl + 'api/PricingCategory/DeletePricingCategory/' + pricingCategoryId);
  }

  async getAllPricingCategoryPromise() {
    return new Promise<PricingCategory[]>(resolve => {
      this.getAllPricingCategorys().subscribe(pricingCategory => {
        resolve(pricingCategory);
      }, err => {
        this.alertService.openSnackBarError("Error getting PricingCategory records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getPricingCategoryPromise(pricingCategoryId: string) {
    return new Promise<PricingCategory>(resolve => {
      this.getPricingCategory(pricingCategoryId).subscribe(pricingCategory => {
        resolve(pricingCategory);
      }, err => {
        this.alertService.openSnackBarError("Error getting PricingCategory records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getPricingCategoryParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<PricingCategory[]>(resolve => {
      this.getPricingCategoryParamArray(aParam).subscribe(pricingCategory => {
        resolve(pricingCategory);
      }, err => {
        this.alertService.openSnackBarError("Error getting PricingCategory records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async updatePricingCategoryPromise(pricingCategory: PricingCategory) {
    return new Promise<PricingCategory>(resolve => {
      this.updatePricingCategory(pricingCategory).subscribe(pricingCategory => {
        resolve(pricingCategory);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating PricingCategory record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async createPricingCategoryPromise(pricingCategory: PricingCategory) {
    return new Promise<PricingCategory>(resolve => {
      this.createPricingCategory(pricingCategory).subscribe(pricingCategory => {
        resolve(pricingCategory);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating PricingCategory record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async deletePricingCategoryPromise(pricingCategoryId: string) {
    return new Promise<boolean>(resolve => {
      this.deletePricingCategory(pricingCategoryId).subscribe(pricingCategory => {
        resolve(true);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error removing PricingCategory record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(false);
      });
    });
  }

}  
