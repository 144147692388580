import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dtoLoadingDocket } from '../../_shared/business-objects/dto-loading-docket.bo';
import { DeliveryDocketListComponent } from '../delivery-docket-list/delivery-docket-list.component';

@Component({
  selector: 'app-link-existing-loading-docket-dialog',
  templateUrl: './link-existing-loading-docket-dialog.component.html',
  styleUrls: ['./link-existing-loading-docket-dialog.component.css']
})
export class LinkExistingLoadingDocketDialogComponent {

  existingLoadingDocket: dtoLoadingDocket;

  constructor(private dialogRef: MatDialogRef<DeliveryDocketListComponent>, @Inject(MAT_DIALOG_DATA) private data: { existingLoadingDocket: dtoLoadingDocket }) {

    this.existingLoadingDocket = data.existingLoadingDocket;
    console.log("data.existingLoadingDocket", data.existingLoadingDocket);
  }

  linkLoadingDocket(loadingDocket: dtoLoadingDocket) {

    //console.log("linkLoadingDocket", loadingDocket);

    this.dialogRef.close({ status: "Saved", selectedLoadingDocket: loadingDocket });

  }

  linkLoadingDocketCancelled(cancelled: boolean) {

    this.dialogRef.close({ status: "Cancel", selectedLoadingDocket: null });


  }


}
