import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { PickupLocation } from '../business-objects/pickup-location.bo';

@Injectable()
export class PickupLocationService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getPickupLocation(pickupLocationId: string) {
		return this.http.get<PickupLocation>(this._baseUrl + 'api/PickupLocation/' + pickupLocationId);
	}

	getPickupLocationParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<PickupLocation[]>(this._baseUrl + 'api/PickupLocation/GetPickupLocationsParamArray/', aParam);
	}

	getAllPickupLocations() {
		return this.http.get<PickupLocation[]>(this._baseUrl + 'api/PickupLocation/GetAllPickupLocations/');
	}

	//getAlldtoPickupLocations() {
	//	return this.http.get<dtoPickupLocation[]>(this._baseUrl + 'api/PickupLocation/GetAllPickupLocations/');
	//}

	createPickupLocation(pickupLocation: PickupLocation) {
		return this.http.post<PickupLocation>(this._baseUrl + 'api/PickupLocation/CreatePickupLocation/', pickupLocation);
	}

	updatePickupLocation(pickupLocation: PickupLocation) {
		return this.http.put<PickupLocation>(this._baseUrl + 'api/PickupLocation/UpdatePickupLocation/', pickupLocation);
	}

	deletePickupLocation(pickupLocationId: string) {
		return this.http.delete(this._baseUrl + 'api/PickupLocation/DeletePickupLocation/' + pickupLocationId);
	}

	async getPickupLocationPromise(pickupLocationId: string) {
		return new Promise<PickupLocation>(resolve => {
			this.getPickupLocation(pickupLocationId).subscribe(pickupLocation => {
				resolve(pickupLocation);
			}, err => {
				this.alertService.openSnackBarError("Error getting PickupLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getPickupLocationParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<PickupLocation[]>(resolve => {
			this.getPickupLocationParamArray(aParam).subscribe(pickupLocations => {
				resolve(pickupLocations);
			}, err => {
				this.alertService.openSnackBarError("Error getting PickupLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatePickupLocationPromise(pickupLocation: PickupLocation) {
		return new Promise<PickupLocation>(resolve => {
			this.updatePickupLocation(pickupLocation).subscribe(pickupLocation => {
				resolve(pickupLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating PickupLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createPickupLocationPromise(pickupLocation: PickupLocation) {
		return new Promise<PickupLocation>(resolve => {
			this.createPickupLocation(pickupLocation).subscribe(pickupLocation => {
				resolve(pickupLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating PickupLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletePickupLocationPromise(pickupLocationId: string) {
		return new Promise<boolean>(resolve => {
			this.deletePickupLocation(pickupLocationId).subscribe(pickupLocation => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing PickupLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}