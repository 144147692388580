import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { dtoMachineRun } from '../../_shared/business-objects/dto-machine-run.bo';
import { MachineRun } from '../../_shared/business-objects/machine-run.bo';
import { Species } from '../../_shared/business-objects/species.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { MachineRunListComponent } from '../machine-run-list/machine-run-list.component';


export enum enMachineRunTabs { MachineRuns = 0, SearchOptions = 1, CreateMachineRun = 2, UpdateMachineRun = 3, AddMachineRecords = 4 }


@Component({
  selector: 'app-machine-run-search',
  templateUrl: './machine-run-search.component.html',
  styleUrls: ['./machine-run-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class MachineRunSearchComponent implements OnInit {

  @ViewChild('tabMachineRuns', { static: false }) tabMachineRuns: MatTabGroup;
  @ViewChild('appMachineRunList', { static: false }) machineRunList: MachineRunListComponent;


  form: FormGroup;
  public eMachineRunTabs = enMachineRunTabs;

  selectedTab: number;
  showTable: boolean = false;
  showSpinner: boolean = false;
  machineRuns: dtoMachineRun[] = [];
  machineRun: MachineRun;
  openMachineRun: dtoMachineRun;
  machineRunAddMachineRecord: dtoMachineRun;
  species: Species[] = [];

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  search: boolean = false;

  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private route: ActivatedRoute, private formService: FormService) {


  }

  ngOnInit(): void {

    this.form = this.fb.group({
      txtSearchMachineRunNo: [''],
      txtSearchMoulder: [''],
      guSpeciesId: [0],
      dteSearchDateFrom: [1],
      dteSearchDateTo: ['']
    });

  }

  onTabChange(index: number) {

  }

  searchMachineRuns(type: string) {

  }


}
