import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Input } from '@angular/core';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatSelectionListChange, MatListOption } from '@angular/material/list';
import { AuthService } from '../../account/auth.service';
import { DiscTemplateConfig } from '../../_shared/business-objects/disc-template-config.bo';
import { dtoKeyValue, dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { ElectricalAuthoritySpec } from '../../_shared/business-objects/electrical-authority-spec.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { DiscTemplateConfigService } from '../../_shared/services/disc-template-config.service';
import { ElectricalAuthoritySpecService } from '../../_shared/services/electrical-authority-spec.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-disc-template-config-update',
  templateUrl: './disc-template-config-update.component.html',
  styleUrls: ['./disc-template-config-update.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class DiscTemplateConfigUpdateComponent {
  @ViewChild('txtFirstName') txtFirstName: ElementRef;
  @ViewChild('txtLastName') txtLastName: ElementRef;

  form: FormGroup;

  @Input() discTemplateConfig: DiscTemplateConfig;
  @Output() discTemplateConfigUpdated = new EventEmitter<DiscTemplateConfig>();
  @Output() discTemplateConfigCancelled = new EventEmitter<boolean>();


  public customersFilterCtrl: FormControl = new FormControl();
  public customerContactsFilterCtrl: FormControl = new FormControl();
  public haulersFilterCtrl: FormControl = new FormControl();

  public electricalAuthoritys: ElectricalAuthoritySpec[];
  public electricalAuthoritysAll: ElectricalAuthoritySpec[];

  inputTypes: dtoKeyValue[] = [];
  computerInputFields: dtoIntKeyValue[] = [];

  showSpinner: boolean

  constructor(private formBuilder: FormBuilder, private formService: FormService, private electricalAuthoritySpecService: ElectricalAuthoritySpecService, private sharedService: SharedService
    , private alertService: AlertService, private authService: AuthService
    , private discTemplateConfigService: DiscTemplateConfigService) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      guElectricalAuthoritySpecId: [''],
      txtCSVColumnNumber: [''],
      intInputType: [''],
      txtUserInput: [''],
      intComputerInputField: [''],
      blnActive: [''],
      txtComments: ['']
    });

    this.showSpinner = false;

    this.loadData();

    this.inputTypes = this.sharedService.getEnumObject2(DiscTemplateConfig.enInputType);
    this.computerInputFields = this.sharedService.getEnumObjectKeyValue(DiscTemplateConfig.enComputerInputField);

    this.computerInputFields.splice(0, 0, new dtoIntKeyValue(-1, "None"));

    if (this.discTemplateConfig != null) {
      this.form.controls['guElectricalAuthoritySpecId'].setValue([this.discTemplateConfig.guElectricalAuthoritySpecId]);
      this.form.controls['txtCSVColumnNumber'].setValue(this.discTemplateConfig.intCSVColumnNumber.toString());
      this.form.controls['intInputType'].setValue([this.discTemplateConfig.intInputType]);
      this.form.controls['txtUserInput'].setValue(this.discTemplateConfig.txtUserInputValue);
      this.form.controls['intComputerInputField'].setValue([this.discTemplateConfig.intComputerInputField]);
      this.form.controls['blnActive'].setValue(this.discTemplateConfig.blnActive);
      this.form.controls['txtComments'].setValue(this.discTemplateConfig.txtComments);
    }

    if (this.discTemplateConfig.intInputType == DiscTemplateConfig.enInputType.User) {
      this.form.controls['txtUserInput'].enable();
      this.form.controls['intComputerInputField'].disable();
    }

    if (this.discTemplateConfig.intComputerInputField == DiscTemplateConfig.enInputType.Computer) {
      this.form.controls['intComputerInputField'].enable();
      this.form.controls['txtUserInput'].disable();
    }


  }

  async loadData() {

    await this.getElectricalAuthoritys();
  }


  async getElectricalAuthoritys() {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];
    let electricalAuthoritys = await this.electricalAuthoritySpecService.getElectricalAuthoritySpecParamArrayPromise(paramArrayList);
    if (electricalAuthoritys == null) {
      return;
    }

    this.electricalAuthoritys = electricalAuthoritys;
    this.electricalAuthoritysAll = this.electricalAuthoritys;
  }

  async filterElectricalAuthoritySpec(name: string) {
    this.electricalAuthoritys = this.electricalAuthoritysAll;

    this.electricalAuthoritys = this.electricalAuthoritys.filter(a => ((a.txtAuthorityName.toLowerCase().indexOf(name.toLowerCase()) > -1)) || a.txtAuthorityName == "None");

  }

  disableInputField(val: MatSelectionListChange) {

    if (val == null) {
      return null;
    }

    let option: MatListOption = val.options[0];

    if (option == null) {
      return null;
    }

    if (option.value == DiscTemplateConfig.enInputType.User) {
      this.form.controls['txtUserInput'].enable();
      this.form.controls['intComputerInputField'].setValue('');
      this.form.controls['intComputerInputField'].disable();
    }

    if (option.value == DiscTemplateConfig.enInputType.Computer) {
      this.form.controls['intComputerInputField'].enable();
      this.form.controls['txtUserInput'].setValue('');
      this.form.controls['txtUserInput'].disable();
    }

    console.log(option.value);

  }


  async updateDiscTemplateConfig() {

    let electricalAuthoritySpecId: string = this.form.controls['guElectricalAuthoritySpecId'].value[0];
    let csvColumnNumber: number = Number(this.form.controls['txtCSVColumnNumber'].value);
    let inputType: number = this.form.controls['intInputType'].value[0];
    let userInput: string = this.form.controls['txtUserInput'].value;
    let computerInputField: number = this.form.controls['intComputerInputField'].value[0];
    let active: boolean = this.form.controls['blnActive'].value == true;
    let comments: string = this.form.controls['txtComments'].value;

    console.log("electricalAuthoritySpecId: ", electricalAuthoritySpecId);


    if (electricalAuthoritySpecId == null || electricalAuthoritySpecId == "") {
      this.alertService.openSnackBar("Please select an Electrical Authority Spec.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (csvColumnNumber == null || csvColumnNumber == 0) {
      this.alertService.openSnackBar("Please enter a CSV column number.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (inputType == null) {
      this.alertService.openSnackBar("Please select an input type.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if ((computerInputField == null || computerInputField == 0) && inputType == DiscTemplateConfig.enInputType.Computer) {
      this.alertService.openSnackBar("Please select a computer input field.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if ((userInput == null || userInput == "") && inputType == DiscTemplateConfig.enInputType.User) {
      this.alertService.openSnackBar("Please select an Electrical Authority Spec.", "Close", "center", "bottom", "", 3000);
      return;
    }


    this.discTemplateConfig.intCSVColumnNumber = csvColumnNumber;
    this.discTemplateConfig.intInputType = inputType;
    this.discTemplateConfig.txtUserInputValue = userInput;

    if (computerInputField == null) {
      computerInputField = -1
    }
    this.discTemplateConfig.intComputerInputField = computerInputField;
    this.discTemplateConfig.txtComments = comments;
    this.discTemplateConfig.blnActive = active;

    this.discTemplateConfig.guElectricalAuthoritySpecId = electricalAuthoritySpecId;
    if (Array.isArray(electricalAuthoritySpecId) == true) {
      this.discTemplateConfig.guElectricalAuthoritySpecId = electricalAuthoritySpecId[0];
    }

    this.showSpinner = true;

    console.log("discTemplateConfig: ", this.discTemplateConfig);
    this.discTemplateConfig = await this.discTemplateConfigService.updateDiscTemplateConfigPromise(this.discTemplateConfig);

    if (this.discTemplateConfig != null) {
      console.log(this.discTemplateConfig.rowguid);
      this.discTemplateConfig = await this.discTemplateConfigService.getDiscTemplateConfigPromise(this.discTemplateConfig.rowguid);

      this.alertService.openSnackBar("The disc template has been updated", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;
      this.discTemplateConfigUpdated.emit(this.discTemplateConfig);
    }


    if (this.discTemplateConfig == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem updating the disc template", "Close", "center", "bottom", 3000, true, "There was a problem creating the driver");
    }



  }

  cancelDiscTemplateConfig() {
    this.discTemplateConfigCancelled.emit(true);
  }
}
