export interface BoardTallyRecordOrderItem
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	fltOrderItemQuantity: number;
	guBoardTallyRecordId: string;
	guOrderItemId: string;
	intOrderItemQuantityUoM: number;
	rowguid: string;
}

export class BoardTallyRecordOrderItem
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	fltOrderItemQuantity: number;
	guBoardTallyRecordId: string;
	guOrderItemId: string;
	intOrderItemQuantityUoM: number;
	rowguid: string;
}

