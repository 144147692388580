import { Component, OnInit, ViewChild } from '@angular/core';
import { dtoTable } from '../_shared/business-objects/dto-table.bo';
import { TableService } from '../_shared/services/table.service';
import { ColumnService } from '../_shared/services/column.service';

import { AlertService } from '../_shared/services/alert.service';
import { IOService } from '../_shared/services/io.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { dtoColumn } from '../_shared/business-objects/dto-column.bo';
import { MatTabGroup } from '@angular/material/tabs';
import { CodeBuilderTable } from '../_shared/business-objects/code-builder-table.bo';
import { CodeBuilderColumn } from '../_shared/business-objects/code-builder-column.bo';
import { SharedService } from '../_shared/services/shared.service';
import { dtoKeyValue } from '../_shared/business-objects/dto-int-key-value.bo';
import { FormService } from '../_shared/services/form.service';

@Component({
  selector: 'app-code-builder',
  templateUrl: './code-builder.component.html',
  styleUrls: ['./code-builder.component.css']
})
export class CodeBuilderComponent implements OnInit {

  tables: CodeBuilderTable[];
  tablesTemp: CodeBuilderTable[];
  tableFields: CodeBuilderColumn[];
  selectedOrdersTab: number;
  selectedTable: CodeBuilderTable;
  dbFilters: dtoKeyValue[];
  dbSQLFilterOperators: dtoKeyValue[];
  saveFile: boolean = false;

  form: FormGroup;

  @ViewChild('tabCodeBuilder', { static: false }) tabCodeBuilderItems: MatTabGroup;

  tableColumns: string[] = ['column_name', 'fk_column', 'fk_referenced_table', 'fk_referenced_column', 'chkFilter', 'chkSQLFilterOperator', 'is_nullable_filter'];


  constructor(private tableService: TableService, private columnService: ColumnService, private alertService: AlertService, private fb: FormBuilder, private ioService: IOService, private sharedService: SharedService
            , private formService: FormService  ) {

    this.form = this.fb.group({
      txtHTMLCode: [''],
      txtCSSCode: [''],
      txtControllerCode: [''],
      txtServiceClassCode: [''],
      txtServiceInterfaceCode: [''],
      txtRepositoryClassCode: [''],
      txtRepositoryInterfaceCode: [''],
      txtServiceCode: ['']
      //guCodeBuilderColumnId: ['']
    });

    this.dbFilters = this.sharedService.getEnumObject2(CodeBuilderColumn.enColumnFilterType);
    this.dbSQLFilterOperators = this.sharedService.getEnumObject2(CodeBuilderColumn.enColumnSQLFilterOperator);
    
  }

  ngOnInit(): void {

    this.tableService.getAllTables().subscribe(tables => {

      this.tables = tables;
      this.tablesTemp = tables;

    }, err => {
      console.log(err);
    });

    this.formService.setAutoFocusTimeout("txtCustomer");

  }

  testNullableParameter() {
    this.ioService.getNullableParameter().subscribe(res => {

      console.log(res);

    }, err => {
      console.log(err);
    });
  }



  viewColumns(table: CodeBuilderTable) {

    this.selectedTable = table;

    this.columnService.getAllColumns(table.rowguid).subscribe(columns => {

      this.tableFields = columns;


    }, err => {

      console.log(err);

    });

  }

  onSaveChange(val) {
    this.saveFile = val;

    console.log(val);
  }

  viewCode() {


    if (this.selectedTable == null) {
      this.alertService.openSnackBarError("Please select a table", "Close", "center", "bottom", 2000, false, "");
      return false
    }

    this.ioService.getSearchComponentHTML(this.selectedTable.txtTableName, "", this.saveFile).subscribe(controller => {

      this.form.controls["txtHTMLCode"].setValue(controller);

    }, err => {
      console.log(err);
    });

    this.ioService.getWebAPIControllerWithColumns(this.selectedTable.txtTableName, this.tableFields, this.saveFile).subscribe(controller => {

      this.form.controls["txtControllerCode"].setValue(controller);

    }, err => {
      console.log(err);
    });

    let writeFile = { content: "Type Script Services" };

    this.ioService.getTypeScriptService(this.selectedTable.txtTableName, this.tableFields, this.saveFile).subscribe(controller => {

      console.log(this.selectedTable.txtTableName);

      this.form.controls["txtServiceCode"].setValue(controller);

    }, err => {
      console.log(err);
    });

    this.ioService.getCSServiceClass(this.selectedTable.txtTableName, this.tableFields, this.saveFile).subscribe(serviceClass => {

      this.form.controls["txtServiceClassCode"].setValue(serviceClass);

    }, err => {
      console.log(err);
    });

    this.ioService.getCSServiceInterface(this.selectedTable.txtTableName, this.tableFields, this.saveFile).subscribe(serviceInterface => {

      this.form.controls["txtServiceInterfaceCode"].setValue(serviceInterface);

    }, err => {
      console.log(err);
    });


    this.ioService.getCSRepositoryClass(this.selectedTable.txtTableName, this.tableFields, this.saveFile).subscribe(repositoryClass => {

      this.form.controls["txtRepositoryClassCode"].setValue(repositoryClass);

    }, err => {
      console.log(err);
    });

    this.ioService.getCSRepositoryInterface(this.selectedTable.txtTableName, this.tableFields, this.saveFile).subscribe(repositoryInterface => {

      this.form.controls["txtRepositoryInterfaceCode"].setValue(repositoryInterface);

    }, err => {
      console.log(err);
    });

     this.tabCodeBuilderItems.selectedIndex = 1

    //this.alertService.openSnackBar("tableName: " + tableName, "Close", "center", "bottom", "addToOrderSnackBar", 2000);

  }

  onColumFilterSelect(val: number, column: CodeBuilderColumn) {
    column.intColumnFilterType = val;
  }

  onColumSQLFilterOperatorSelect(val: number, column: CodeBuilderColumn) {
    console.log(val);
    column.intSQLFilterOperator = val;
  }

  onIsNullableFilterChange(val, column: CodeBuilderColumn) {
    column.blnNullableFilter = val
  }


  getDBFilters(column: CodeBuilderColumn) {

    let aFilter: dtoKeyValue[] = [];

    aFilter.push(this.dbFilters[0])
    aFilter.push(this.dbFilters[1]);
    aFilter.push(this.dbFilters[2]);
    aFilter.push(this.dbFilters[3]);
    aFilter.push(this.dbFilters[4]);
    //console.log(column);
    //if (column.txtFKColumnName != null) {

    //  aFilter.push(this.dbFilters[3]);
    //  aFilter.push(this.dbFilters[4]);
    //}

    //if (column.txtFKColumnName == null) {
    //  aFilter.push(this.dbFilters[1]);
    //  aFilter.push(this.dbFilters[2]);
    //}
    return aFilter;
  }


  async saveColumn() {

    for (let i = 0; i <= this.tableFields.length - 1; i++) {
      await new Promise<CodeBuilderColumn>(resolve => {
        this.columnService.updateColumn(this.tableFields[i]).subscribe(column => {

          console.log(column);
          resolve(column);

        }, err => {

          console.log(err);
          resolve(null);

        });
      });

      }
  }


  onTabChange(e) {

  }

  filterTables(val: string) {
    this.tables = this.tablesTemp.filter(table => { return table.txtTableName.toLowerCase().includes(val.toLowerCase()) });
  }

}
