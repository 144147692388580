import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PrinterLocation } from '../../_shared/business-objects/printer-location.bo';
import { dtoPrinterLocation } from '../../_shared/business-objects/dto-printer-location.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { SQLParamService } from '../../_shared/services/app-services/sql-param.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { PrinterLocationListComponent } from '../printer-location-list/printer-location-list.component';
import { PrinterLocationService } from '../../_shared/services/printer-location.service';
import { dtoPrinterLocationService } from '../../_shared/services/dto-printer-location.service';

export enum enPrinterLocationTabs { PrinterLocations = 0, SearchOptions = 1, CreatePrinterLocation = 2, UpdatePrinterLocation = 3 }

@Component({
  selector: 'app-printer-location-search',
  templateUrl: './printer-location-search.component.html',
  styleUrls: ['./printer-location-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class PrinterLocationSearchComponent implements OnInit {

  @ViewChild("appPrinterLocationList") appPrinterLocationList: PrinterLocationListComponent;

  showSpinner: boolean;
  printerLocations: MatTableDataSource<dtoPrinterLocation>;
  public ePrinterLocationTabs = enPrinterLocationTabs;


  aPrinterParamList: SQLParamArray[][];

  selectedTab: number;

  form: FormGroup;


  constructor(private formService: FormService, private fb: FormBuilder, private sharedService: SharedService, private printerLocationService: PrinterLocationService, private dtoPrinterLocationService: dtoPrinterLocationService
    , private alertService: AlertService, private cdref: ChangeDetectorRef, private sqlParamService: SQLParamService) {

    this.form = this.fb.group({
      txtSearchPrinterLocationName: [''],
      dteDateCreatedFrom: [''],
      dteDateCreatedTo: ['']
    });

  }


  ngOnInit() {

    document.title = "Printer Locations";

    this.printerLocations = new MatTableDataSource<dtoPrinterLocation>();

    this.loadData();

    this.cdref.detectChanges();

  }

  ngAfterContentInit() {

    this.cdref.detectChanges();

  }

  ngAfterViewInit() {

    this.cdref.detectChanges();

  }

  async loadData() {

  }

  enterKeySearch(type: string, e: KeyboardEvent) {

    if (this.selectedTab == enPrinterLocationTabs.SearchOptions) {

      //WE NEED BLUR THE CURRENT TEXTBOX BECAUSE WE GET AN REF DETECTION CHANGE ERROR
      //WHEN GOING BACK TO THE PRINTERS TAB WHICH IS DONE PROGRAMATICALLY IN this.searchPrinters.

      if (e.target != null) {
        let input: HTMLInputElement = e.target as HTMLInputElement;
        if (input != null) {
          input.blur()
        }
      }
    }

    this.searchPrinterLocations(type, 0, e);

  }

  async searchPrinterLocations(type: string, lastPrinters: number, e) {

    this.aPrinterParamList = [];
    let criteriaSelected: boolean = false;
    let aMandatoryParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let printerName: string = this.form.controls["txtSearchPrinterLocationName"].value;
    let printerCreatedFrom: string = this.form.controls["dteDateCreatedFrom"].value;
    let printerCreatedTo: string = this.form.controls["dteDateCreatedTo"].value;


    const datepipe: DatePipe = new DatePipe('en-AU');

    if (type == "searchoptions") {

    }

    this.sqlParamService.addParameter(this.aPrinterParamList, printerName, "txtName");
    this.sqlParamService.addParameter(this.aPrinterParamList, datepipe.transform(printerCreatedFrom, 'yyyy-MM-dd'), "dteDateCreated", false, SQLParamArray.enSQLOperator.GreaterThanOrEqualTo);
    this.sqlParamService.addParameter(this.aPrinterParamList, datepipe.transform(printerCreatedTo, 'yyyy-MM-dd'), "dteDateCreated", false, SQLParamArray.enSQLOperator.LessThanOrEqualTo);

    //if (this.aPrinterParamList.length == 0) {
    //  let msg: string = await this.alertService.openSnackBarCustomPromise("No Search Criteria", "Please select some search criteria before searching", "Ok", "", "center", "bottom", "", 0, false);
    //  return;
    //}

    //this.sqlParamService.addParameter(this.aPrinterParamList, "1", "intType");


    console.log("aParamList", this.aPrinterParamList);

    this.printerLocations = new MatTableDataSource<dtoPrinterLocation>();
    this.showSpinner = true;


    if (this.selectedTab != enPrinterLocationTabs.PrinterLocations) {
      this.selectedTab = enPrinterLocationTabs.PrinterLocations;

    }


    let printerLocations: dtoPrinterLocation[] = await this.dtoPrinterLocationService.getdtoPrinterLocationParamArrayPromise(this.aPrinterParamList);



    if (printerLocations != null && printerLocations.length > 0) {

      //printers.sort((a, b) => { return a.txtOrderNo > b.txtOrderNo ? 1 : -1 });

      printerLocations.sort((a, b) => { return a.txtName > b.txtName || a.txtName > b.txtName ? 1 : -1 });

      this.printerLocations.data = printerLocations;
      console.log("printerLocations: ", printerLocations);
    }



    this.showSpinner = false;



  }


  printerLocationCancelled(val: boolean) {

  }


  updatedPrinterLocation(printerLocation: PrinterLocation) {

  }

  removePrinterLocation(printerLocation: PrinterLocation) {

  }

  editPrinterLocation($event) {

  }

  addPrinterLocationItems(printerLocation: PrinterLocation) {

  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    console.log(e.index == this.ePrinterLocationTabs.SearchOptions);

    if (e.index == this.ePrinterLocationTabs.SearchOptions) {
      //this.formService.setAutoFocusTimeout("txtSearchPrinter");
    }

    if (e.index == this.ePrinterLocationTabs.PrinterLocations) {
      this.formService.setAutoFocusTimeout("txtSearchPrinterLocation");

    }

  }

}
