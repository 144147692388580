<div class="consecutive-number-list-container" [formGroup]="form">
  <div class="consecutive-number-heading"><h1>Pick A Consecutive Number</h1></div>

  <div class="consecutive-number-fields-grid">
    <div class="charge-number-container">
      <div class="charge-number-label">Charge Number:</div>
      <div class="charge-number">{{chargeNo}}</div>
    </div>

    <div class="charge-month-container">
      <div class="charge-month-label">Treatment Month:</div>
      <div class="charge-month">{{chargeMonth}}</div>
    </div>

    <div class="charge-year-container">
      <div class="charge-year-label">Treatment Year:</div>
      <div class="charge-year">{{chargeYear}}</div>
    </div>
  </div>

  <div class="consecutive-number-container" #consecutiveNumberSelectContainer>
    <div id="consecutiveNumberSelect" class="consecutive-number-select-list">
      <div class="consecutive-number-heading-row consecutive-number-name-heading">Consecutive Number</div>
      <div class="consecutive-number-heading-row consecutive-number-default-order-heading">Pole Number</div>
      <div class="consecutive-number-heading-row consecutive-number-field-type-heading">Description</div>
      <div class="consecutive-number-heading-row consecutive-number-field-width-heading">Species</div>
      <div class="consecutive-number-heading-row consecutive-number-field-width-heading">Centre Girth</div>
      <div class="consecutive-number-heading-row consecutive-number-unlink-heading"></div>
      @for(tpChargeItem of tpChargeItems; track tpChargeItem; let idx = $index){
      <div tabindex="{{idx}}" id="consecutiveNumber{{idx}}" class="chh-list-item-row chh-list-item-first-column consecutive-number" [ngClass]="tpChargeItem.guTPPoleInspectionItemId != null ? 'disabled-row' : tpChargeItem.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected consecutive-number' : columnHoverId == tpChargeItem.rowguid ? 'chh-list-item-row chh-list-item-row-hover consecutive-number' : 'chh-list-item-row consecutive-number'" (mouseenter)="rowEnter(tpChargeItem.rowguid)" (mouseleave)="rowLeave()" (click)="selectConsecutiveNumber(tpChargeItem)"><div class="tp-charge-item-text">{{tpChargeItem.intConsecutiveNo}}</div></div>
      <div id="poleNumber{{idx}}" class="chh-list-item-row pole-number" [ngClass]="tpChargeItem.guTPPoleInspectionItemId != null ? 'disabled-row' : tpChargeItem.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected' : columnHoverId == tpChargeItem.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(tpChargeItem.rowguid)" (mouseleave)="rowLeave()" (click)="selectConsecutiveNumber(tpChargeItem)">{{tpChargeItem.txtPoleNumber}}</div>
      <div id="description{{idx}}" class="chh-list-item-row description" [ngClass]="tpChargeItem.guTPPoleInspectionItemId != null ? 'disabled-row' : tpChargeItem.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected' : columnHoverId == tpChargeItem.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(tpChargeItem.rowguid)" (mouseleave)="rowLeave()" (click)="selectConsecutiveNumber(tpChargeItem)">{{tpChargeItem.fltPoleLength + ' / ' + tpChargeItem.intPoleKN}}</div>
      <div id="species{{idx}}" class="chh-list-item-row species" [ngClass]="tpChargeItem.guTPPoleInspectionItemId != null ? 'disabled-row' : tpChargeItem.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected' : columnHoverId == tpChargeItem.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(tpChargeItem.rowguid)" (mouseleave)="rowLeave()" (click)="selectConsecutiveNumber(tpChargeItem)">{{tpChargeItem.txtSpeciesName}}</div>
      <div id="centreGirthDia{{idx}}" class="chh-list-item-row cetre-girth-diameter" [ngClass]="tpChargeItem.guTPPoleInspectionItemId != null ? 'disabled-row' : tpChargeItem.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected' : columnHoverId == tpChargeItem.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(tpChargeItem.rowguid)" (mouseleave)="rowLeave()" (click)="selectConsecutiveNumber(tpChargeItem)">{{tpChargeItem.intGirth}}</div>
      <div id="unlinkFinal{{idx}}" class="chh-list-item-row chh-list-item-last-column ulink-final-button" [ngClass]="tpChargeItem.guTPPoleInspectionItemId != null ? 'disabled-row' : tpChargeItem.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected' : columnHoverId == tpChargeItem.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(tpChargeItem.rowguid)" (mouseleave)="rowLeave()" (click)="selectConsecutiveNumber(tpChargeItem)">
        <button class="stocktakeButton" mat-flat-button color="primary" (click)="unlinkFromFinal(tpChargeItem)" *ngIf="tpChargeItem.guTPPoleInspectionItemId != null">Unlink</button>
      </div>
      }
    </div>

  </div>

  <div class="button-container">
    <button class="stocktakeButton" mat-flat-button color="primary" (click)="save()" id="btnSaveChargeItem" [disabled]="selectedTPChargeItem == null">Save</button>
    <button class="stocktakeButton" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
  </div>

</div>
