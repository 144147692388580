export interface dtoLoadingDocket
{
	blnCHHVehicle: boolean;
	dteDateCreated: Date;
	dteDateDispatched: Date;
	dteDateLoaded: Date;
	dteExpectedDeliveryDate: Date;
	dteExpectedLoadingDate: Date;
	dteLastUpdated: Date;
	fltActualM3: number;
	fltActualTonne: number;
	fltFinalM3: number;
	fltFinalTonne: number;
	fltM3: number;
	fltTonne: number;
	guDriverId: string;
	guHaulerId: string;
	guLoadingLocationId: string;
	guMobilePlantEquipmentId: string;
	guTrailerId: string;
	intDocketNo: number;
	intLoadNo: number;
	intMaxLoads: number;
	intMaxPoleLength: number;
	intMinPoleLength: number;
	intStatus: number;
	intYardSection: number;
	rowguid: string;
	txtComments: string;
	txtCustomerLocation: string;
	txtHaulingCompanyDriverFirstName: string;
	txtHaulingCompanyDriverLastName: string;
	txtHaulingCompanyDriverNickName: string;
	txtHaulingCompanyName: string;
	txtLocation: string;
	txtMobilePlantEquipmentName: string;
	txtOrderNo: string;
	txtStatus: string;
	txtTrailer: string;
	txtTrailerAbbreviation: string;
}

export class dtoLoadingDocket
{
	blnCHHVehicle: boolean;
	dteDateCreated: Date;
	dteDateDispatched: Date;
	dteDateLoaded: Date;
	dteExpectedDeliveryDate: Date;
	dteExpectedLoadingDate: Date;
	dteLastUpdated: Date;
	fltActualM3: number;
	fltActualTonne: number;
	fltFinalM3: number;
	fltFinalTonne: number;
	fltM3: number;
	fltTonne: number;
	guDriverId: string;
	guHaulerId: string;
	guLoadingLocationId: string;
	guMobilePlantEquipmentId: string;
	guTrailerId: string;
	intDocketNo: number;
	intLoadNo: number;
	intMaxLoads: number;
	intMaxPoleLength: number;
	intMinPoleLength: number;
	intStatus: number;
	intYardSection: number;
	rowguid: string;
	txtComments: string;
	txtCustomerLocation: string;
	txtHaulingCompanyDriverFirstName: string;
	txtHaulingCompanyDriverLastName: string;
	txtHaulingCompanyDriverNickName: string;
	txtHaulingCompanyName: string;
	txtLocation: string;
	txtMobilePlantEquipmentName: string;
	txtOrderNo: string;
	txtStatus: string;
	txtTrailer: string;
	txtTrailerAbbreviation: string;
}

