<div class="email-container">
  <div class="email-content-container">
    <div class="email-top-container">
      <div class="email-addresses">
        <mat-form-field class="from-address-form-field">
          <mat-label>From Address</mat-label>
          <mat-select #selFromAddress class="from-address">
            @for (fromAddress of fromAddresses; track fromAddress) {
            <mat-option [value]="fromAddress">{{fromAddress}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <textarea #toAddress class="email-to-address" placeholder="To Address" autosize rows="1" [(ngModel)]="emailToAddresses" id="txtToAddresses"></textarea>
        <textarea #ccAddress class="email-cc-address" placeholder="CC Address" autosize rows="1" [(ngModel)]="emailCCAddresses" id="txtCCAddresses"></textarea>
        <textarea #bccAddress class="email-bcc-address" placeholder="BCC Address" autosize rows="1" [(ngModel)]="emailBCCAddresses" id="txtBCCAddresses"></textarea>
      </div>
      <div class="drop-zone-area">

        <div class="drop-zone-container" (drop)="dropHandler($event);" (dragover)="dragOverHandler($event);" (dragleave)="dragOutHandler($event);" [ngClass]="onDropZone ? 'drop_zone drop_zone_hover' : 'drop_zone'">
          <div class="drop-zone-water-mark" *ngIf="fileAttachments == null || fileAttachments.length == 0">Drag one or more files to this <i>drop zone</i></div>
          <div id="imagelist" class="attachment-list">
            @for(fileAttachment of fileAttachments; track fileAttachment){

            <div class="attachment-container">
              <div class="attachment-remove"><mat-icon [ngClass]="attachmentHoverName == fileAttachment.fileName ? '' : 'material-symbols-outlined'" (mouseover)="attachmentHoverName = fileAttachment.fileName" (mouseleave)="attachmentHoverName = ''" (click)="removeAttachement($index)">cancel</mat-icon></div>
              <div class="attachment-image-container" (click)="downloadAttachment(fileAttachment)">
                <div class="attachment-icon">
                  <img *ngIf="fileAttachment.fileName.indexOf('.msg') == -1 && fileAttachment.fileName.indexOf('.csv') == -1" src="{{fileAttachment.contentType == 'application/pdf' ? './assets/img/pdf_icon.png' : fileAttachment.dataURI()}}" height="30" />
                  <mat-icon *ngIf="fileAttachment.fileName.indexOf('.msg') > -1" class="material-symbols-outlined email-icon">email</mat-icon>
                  <mat-icon *ngIf="fileAttachment.fileName.indexOf('.csv') > -1" class="material-symbols-outlined email-icon">csv</mat-icon>
                </div>
                <div class="attachment-name">{{fileAttachment.truncatedName(30)}}</div>
                <div class="attachment-file-size">{{fileAttachment.sizeMB() == 0 ? fileAttachment.sizeKB() + ' KB' : fileAttachment.sizeMB() + ' MB'}} </div>
              </div>
            </div>

            }
          </div>
        </div>

        <div class="image-upload">
          <label for="myInput">
            <mat-icon matTooltip="Add Attachment" class="material-symbols-outlined open-file">attach_email</mat-icon>
          </label>
          <input #myInput id="myInput" type="file" (change)="uploadFile($event)">
        </div>


      </div>
    </div>
    <input matInput placeholder="Subject" value="" type="text" #txtEmailSubject class="email-subject">
    <quill-editor #emailContent id="emailContent" class="quill-email-editor" (onEditorChanged)="onEditorChanged($event)"></quill-editor>
  </div>

  <div class="buttons-container">
    <button type="submit" class="btn btn-primary mr-2" (click)="sendEmail()">
      Send Email
    </button>
    <button type="button" class="btn btn-primary mr-2" (click)="cancel()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary mr-2" (click)="clearForm()">
      Clear Form
    </button>
  </div>
</div>
