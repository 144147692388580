import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';
import { dtoCustomerContact } from '../../_shared/business-objects/dto-customer-contact.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { CustomerContactService } from '../../_shared/services/customer-contact.service';
import { dtoCustomerContactService } from '../../_shared/services/dto-customer-contact.service';
import { CustomerContactUpdateDialogComponent } from '../customer-contact-update-dialog/customer-contact-update-dialog.component';

@Component({
  selector: 'app-customer-contact-select',
  templateUrl: './customer-contact-select.component.html',
  styleUrl: './customer-contact-select.component.scss'
})
export class CustomerContactSelectComponent {
  @ViewChild('customerContactFilter') customerContactFilter: ElementRef;
  @ViewChild('customerContactListContainer') customerContactListContainer: ElementRef;
  @ViewChild('multipleCustomerContacts') multipleCustomerContacts: MatSlideToggle;


  @Input() customerContacts: dtoCustomerContact[];
  @Input() showMultiple: boolean;
  @Input() showAll: boolean;
  @Output() selectedCustomerContactsEvent = new EventEmitter<dtoCustomerContact[]>();

  showSpinner: boolean
  contactHoverId: string;

  customerContactUpdateDialogRef: MatDialogRef<CustomerContactUpdateDialogComponent>;
  selectedCustomerContacts: dtoCustomerContact[];
  customerContactsFiltered: dtoCustomerContact[];
  customerContactExpanded: dtoCustomerContact;

  customerContactFilteredItems: HTMLCollectionOf<HTMLDivElement>;

  showCustomerContactList: boolean = false;
  showCustomerContactListTransition: boolean = false;

  customerContactSelectionExpanded: boolean = false;
  customerContactListPinned: boolean = false;

  toolTipText: string = "pin";

  constructor(private customerContactService: CustomerContactService, private dtoCustomerContactService: dtoCustomerContactService
    , private alertService: AlertService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.selectedCustomerContacts = [];

    document.addEventListener("click", this.openCloseCustomerContactList.bind(this));

  }

  openCloseCustomerContactList(e) {


    if (this.customerContactListPinned == true) {
      return;
    }

    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.pushPin != null) {
      return;
    }

    console.log("customer contact dataset.customerContactInput", e.target.dataset.customerContactInput);

    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.customerContactInput != null) {   // && this.customerContactFilter.nativeElement.value != null && this.customerContactFilter.nativeElement.value.toString() != ""
      this.showCustomerContactList = true;
      console.log("this.showCustomerContactList = true", this.showCustomerContactList);
      //console.log("customerListContainer", this.customerFilter.nativeElement.value);

      return;
    }

    //if (e == null || e.target == null || e.target.dataset == null || e.target.dataset.customerContactSelect == null) {

    // ALWAYS CLOSE THE SEARCH LIST UNLESS I'M PINNED
    if (this.customerContactListPinned == false) {
      this.showCustomerContactList = false;

      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      //console.log("this.showCustomerList = false", this.showCustomerList);
      return;
    }

    //let domStringMap: DOMStringMap = e.target.dataset;

    //console.log(domStringMap.customerSelect.toString() == "customerSelect");
    //console.log(domStringMap.customerSelect.toString());
  }

  checkCustomerContactListIsOpen(inputValue: string) {

    //console.log("inputValue", inputValue);

    if (this.showAll == false && (inputValue == null || inputValue == "")) {
      this.showCustomerContactList = false;
      //console.log("inputValue empty", inputValue);
      return;
    }

    this.showCustomerContactList = true;


  }


  rowEnter(customerContactId: string) {
    this.contactHoverId = customerContactId;
    //console.log("row enter");
  }

  rowLeave() {
    this.contactHoverId = "";
    //console.log("row leave");
  }

  async selectCustomerContact(customerContact: dtoCustomerContact, selected: boolean) {

    if (customerContact == null) {
      this.alertService.openSnackBarDefault("There was a problem selecting the customerContact - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    // Remove All CustomerContact Contact
    if (this.multipleCustomerContacts != null && this.multipleCustomerContacts.checked == false) {

      let customerContactSelected: dtoCustomerContact[] = this.customerContacts.filter((a) => { return a.guSelectedCustomerContactId != null && a.guSelectedCustomerContactId != "" });
      for (let i = 0; i <= customerContactSelected.length - 1; i++) {
        customerContactSelected[i].guSelectedCustomerContactId = "";
      }

      for (let i = this.selectedCustomerContacts.length - 1; i >= 0; i--) {
        this.selectedCustomerContacts.splice(i, 1);
      }

      for (let i = this.customerContactsFiltered.length - 1; i >= 0; i--) {
        this.customerContactsFiltered[i].guSelectedCustomerContactId = "";
      }

    }

    // Remove CustomerContact Contact
    if (selected == false) {
      for (let i = this.selectedCustomerContacts.length - 1; i >= 0; i--) {
        if (this.selectedCustomerContacts[i].rowguid == customerContact.rowguid) {
          this.selectedCustomerContacts.splice(i, 1);
          customerContact.guSelectedCustomerContactId = "";
          //console.log("unselected customerContact");
          this.selectedCustomerContactsEvent.emit(this.selectedCustomerContacts);
          break;
        }
      }
    }

    // Add CustomerContact Contact
    if (selected == true) {

      customerContact.guSelectedCustomerContactId = "tempvalue";

      this.selectedCustomerContacts.push(customerContact);
      this.selectedCustomerContactsEvent.emit(this.selectedCustomerContacts);

      if (this.multipleCustomerContacts.checked == false && this.customerContactListPinned == false) {
        this.showCustomerContactList = false;
      }

      //console.log("added customerContact");

    }


  }

  async updateCustomerContact(customerContact: dtoCustomerContact) {

    console.log(customerContact);
    if (customerContact == null) {
      this.alertService.openSnackBarDefault("There is no customerContact to edit");
      return;
    }

    if (this.customerContactUpdateDialogRef != null) {
      this.customerContactUpdateDialogRef.close();
    }

    this.customerContactUpdateDialogRef = this.dialog.open(CustomerContactUpdateDialogComponent, {
      hasBackdrop: false,
      height: '95vh',
      maxWidth: '90vw',
      data: { selectedCustomerContact: customerContact }
    });

    this.customerContactUpdateDialogRef.backdropClick().subscribe(() => {
      this.customerContactUpdateDialogRef.close();
    });


    this.customerContactUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, customerContact: CustomerContact }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          let cust: dtoCustomerContact = await this.dtoCustomerContactService.getdtoCustomerContactPromise(data.customerContact.rowguid);
          this.selectedCustomerContacts.push(cust);

          for (let i = 0; i <= this.customerContacts.length - 1; i++) {
            if (this.customerContacts[i].rowguid == data.customerContact.rowguid) {
              this.customerContacts[i] = cust;
              this.customerContacts[i].guSelectedCustomerContactId = "tempvalue";
              break;
            }
          }

          this.customerContacts.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

          this.alertService.openSnackBarDefault("The CustomerContact has been updated");

        }
        else {
          // We have cancellled

        }

      });

  }

  addCustomerContact() {

  }

  nextCustomerContact(e, customerContact: dtoCustomerContact) {

    //console.log("e ", e);

    if (e.code.toLowerCase() == "escape") {
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      this.customerContactFilter.nativeElement.focus();
      this.customerContactFilter.nativeElement.setSelectionRange(this.customerContactFilter.nativeElement.value.length, this.customerContactFilter.nativeElement.value.length);

      this.showCustomerContactList = false;
      return;
    }

    if ((e.code.toLowerCase() == "arrowdown" || e.code.toLowerCase() == "arrowup") && this.showCustomerContactList == true) {
      //console.log("e ", e);

      if (this.customerContactFilteredItems != null) {

        let tabIndex: number = e.target.tabIndex;

        let customerContactUpdateDiv = document.getElementById("customerContactUpdateDiv" + tabIndex);

        if (tabIndex < this.customerContactFilteredItems.length - 1 && e.code.toLowerCase() == "arrowdown") {

          let customerContactUpdateDivPlus = document.getElementById("customerContactUpdateDiv" + (tabIndex + 1));

          //console.log("e down ", tabIndex);
          //TODO
          // Set this.customerContactContainer.style overflow-y to hidden here
          let docBody = document.getElementById("rootBody");
          docBody.style.overflowY = "hidden";

          this.customerContactListContainer.nativeElement.classList.add("customerContact-container-no-scroll");

          this.customerContactFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          customerContactUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.customerContactFilteredItems[tabIndex + 1].focus();
          this.customerContactFilteredItems[tabIndex + 1].classList.add("chh-list-item-row-selected");
          customerContactUpdateDivPlus.classList.add("chh-list-item-row-selected");

          // Set this.customerContactContainer.style overflow-y to scroll here
          this.customerContactListContainer.nativeElement.classList.remove("customerContact-container-no-scroll");

        }

        if (tabIndex >= 0 && e.code.toLowerCase() == "arrowup") {

          if (tabIndex == 0) {
            //console.log("up tabIndex ", tabIndex);

            this.customerContactFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
            customerContactUpdateDiv.classList.remove("chh-list-item-row-selected");

            let docBody = document.getElementById("rootBody");
            docBody.style.overflowY = "scroll";

            this.customerContactFilter.nativeElement.focus();
            this.customerContactFilter.nativeElement.setSelectionRange(this.customerContactFilter.nativeElement.value.length, this.customerContactFilter.nativeElement.value.length);
            return;
          }


          let customerContactUpdateDivMinus = document.getElementById("customerContactUpdateDiv" + (tabIndex - 1));

          //console.log("e up ", tabIndex);

          this.customerContactListContainer.nativeElement.classList.add("customerContact-container-no-scroll");

          this.customerContactFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          customerContactUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.customerContactFilteredItems[tabIndex - 1].focus();
          this.customerContactFilteredItems[tabIndex - 1].classList.add("chh-list-item-row-selected");
          customerContactUpdateDivMinus.classList.add("chh-list-item-row-selected");

          this.customerContactListContainer.nativeElement.classList.remove("customerContact-container-no-scroll");


        }

      }
      //console.log("nextCustomerContact");

    }

    if (e.code.toLowerCase() == "enter") {
      this.selectCustomerContact(customerContact, !(customerContact.guSelectedCustomerContactId != ''));
    }

  }

  filterCustomerContacts(customerContactFilter: string, e) {


    if (e.code.toLowerCase() == "arrowdown" && this.showCustomerContactList == true) {
      //console.log("e ", e);
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "hidden";

      this.customerContactFilteredItems = document.getElementsByClassName("product-type-name") as HTMLCollectionOf<HTMLDivElement>;
      if (this.customerContactFilteredItems != null && this.customerContactFilteredItems.length > 0) {


        this.customerContactFilteredItems[0].classList.add("chh-list-item-row-selected");
        let customerContactUpdateDiv = document.getElementById("customerContactUpdateDiv0");

        customerContactUpdateDiv.classList.add("chh-list-item-row-selected");

        this.customerContactFilteredItems[0].focus();
        console.log("filterCustomerContact TabIndex: ", this.customerContactFilteredItems[0].tabIndex);

      }

      //console.log("customerContactElements", this.customerContactFilteredItems);

    }

    this.getCustomerContacts(customerContactFilter);



  }

  getCustomerContacts(customerContactFilter: string) {

    console.log("customerContactFilter", customerContactFilter);

    if (this.customerContacts == null) {
      return;
    }

    this.customerContactsFiltered = this.customerContacts;

    if (this.showAll == false && (customerContactFilter == null || customerContactFilter == "")) {
      this.customerContactsFiltered = [];
      this.showCustomerContactList = false;
      return;
    }



    let customerContacts: dtoCustomerContact[] = this.customerContacts;

    customerContacts = customerContacts.filter(a => ((a.txtName.toLowerCase().indexOf(customerContactFilter.toLowerCase()) > -1)));

    customerContacts = customerContacts.slice(0, 50);

    customerContacts.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

    this.customerContactsFiltered = customerContacts;

    this.showCustomerContactList = true;

    this.setCustomerContactListTranition(1000);

  }

  setCustomerContactListTranition(delay: number) {

    setTimeout(() => {
      this.showCustomerContactListTransition = true;
    }, delay);

  }

  checkMultipleCustomerContacts(multipleCustomerContacts: boolean) {

    if (multipleCustomerContacts == false) {
      //Remove multiple selected customerContacts so only one remains.
      let multipleCust: dtoCustomerContact[] = this.customerContacts.filter((a) => { return a.guSelectedCustomerContactId != null && a.guSelectedCustomerContactId != "" });
      if (multipleCust != null && multipleCust.length > 0) {

        multipleCust.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

        for (let i = multipleCust.length - 1; i >= 1; i--) {
          multipleCust.pop();
        }

        for (let i = 0; i <= this.customerContacts.length - 1; i++) {
          if (multipleCust[0].rowguid != this.customerContacts[i].rowguid) {
            this.customerContacts[i].guSelectedCustomerContactId = "";
          }
        }

        this.selectedCustomerContacts.length = 0;
        this.selectedCustomerContacts.push(multipleCust[0]);
        this.selectedCustomerContactsEvent.emit(this.selectedCustomerContacts);

      }

    }
  }

  removeCustomerContact(customerContact: dtoCustomerContact) {

    if (customerContact == null) {
      return;
    }

    for (let i = this.selectedCustomerContacts.length - 1; i >= 0; i--) {
      if (this.selectedCustomerContacts[i].rowguid == customerContact.rowguid) {
        this.selectedCustomerContacts.splice(i, 1);
        customerContact.guSelectedCustomerContactId = "";
        //console.log("unselected customerContact");
        this.selectedCustomerContactsEvent.emit(this.selectedCustomerContacts);
        break;
      }
    }


  }

  expandSelectedCustomerContacts(e: PointerEvent) {

    if (e.pointerType == null || e.pointerType == "") {
      return;
    }

    this.customerContactSelectionExpanded = !this.customerContactSelectionExpanded;

    let selectedCustomerContacts: HTMLDivElement = document.getElementById("selectedCustomerContacts") as HTMLDivElement;
    selectedCustomerContacts.classList.toggle("selected-product-types-expanded");

    if (this.customerContactSelectionExpanded == true) {

    }

  }

  clickPin(e) {

    this.customerContactListPinned = !this.customerContactListPinned;

    if (this.customerContactListPinned == false) {
      this.showCustomerContactList = false;
    }

    this.toolTipText = this.customerContactListPinned == true ? 'unpin' : 'pin';


  }


  setCustomerContacts(customerContacts: dtoCustomerContact[]) {

    this.customerContacts = customerContacts;

    console.log("setCustomerContacts", this.customerContacts)
  }

}
