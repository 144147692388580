import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { SaleLocation } from '../business-objects/sale-location.bo';

@Injectable()
export class SaleLocationService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getSaleLocation(saleLocationId: string) {
		return this.http.get<SaleLocation>(this._baseUrl + 'api/SaleLocation/' + saleLocationId);
	}

	getSaleLocationParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<SaleLocation[]>(this._baseUrl + 'api/SaleLocation/GetSaleLocationsParamArray/', aParam);
	}

	getAllSaleLocations() {
		return this.http.get<SaleLocation[]>(this._baseUrl + 'api/SaleLocation/GetAllSaleLocations/');
	}

	//getAlldtoSaleLocations() {
	//	return this.http.get<dtoSaleLocation[]>(this._baseUrl + 'api/SaleLocation/GetAllSaleLocations/');
	//}

	createSaleLocation(saleLocation: SaleLocation) {
		return this.http.post<SaleLocation>(this._baseUrl + 'api/SaleLocation/CreateSaleLocation/', saleLocation);
	}

	updateSaleLocation(saleLocation: SaleLocation) {
		return this.http.put<SaleLocation>(this._baseUrl + 'api/SaleLocation/UpdateSaleLocation/', saleLocation);
	}

	deleteSaleLocation(saleLocationId: string) {
		return this.http.delete(this._baseUrl + 'api/SaleLocation/DeleteSaleLocation/' + saleLocationId);
	}

	async getSaleLocationPromise(saleLocationId: string) {
		return new Promise<SaleLocation>(resolve => {
			this.getSaleLocation(saleLocationId).subscribe(saleLocation => {
				resolve(saleLocation);
			}, err => {
				this.alertService.openSnackBarError("Error getting SaleLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getSaleLocationParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<SaleLocation[]>(resolve => {
			this.getSaleLocationParamArray(aParam).subscribe(saleLocations => {
				resolve(saleLocations);
			}, err => {
				this.alertService.openSnackBarError("Error getting SaleLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateSaleLocationPromise(saleLocation: SaleLocation) {
		return new Promise<SaleLocation>(resolve => {
			this.updateSaleLocation(saleLocation).subscribe(saleLocation => {
				resolve(saleLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating SaleLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createSaleLocationPromise(saleLocation: SaleLocation) {
		return new Promise<SaleLocation>(resolve => {
			this.createSaleLocation(saleLocation).subscribe(saleLocation => {
				resolve(saleLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating SaleLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deleteSaleLocationPromise(saleLocationId: string) {
		return new Promise<boolean>(resolve => {
			this.deleteSaleLocation(saleLocationId).subscribe(saleLocation => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing SaleLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}