import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoMachineRun } from '../business-objects/dto-machine-run.bo';

@Injectable()
export class dtoMachineRunService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoMachineRun(dtoMachineRunId: string) {
		return this.http.get<dtoMachineRun>(this._baseUrl + 'api/dtoMachineRun/' + dtoMachineRunId);
	}

	getdtoMachineRunParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoMachineRun[]>(this._baseUrl + 'api/dtoMachineRun/GetdtoMachineRunsParamArray/', aParam);
	}

  getdtoMachineRunParamArrayTop(aParam: SQLParamArray[][], top: number) {
    return this.http.post<dtoMachineRun[]>(this._baseUrl + 'api/dtoMachineRun/GetdtoMachineRunsParamArrayTop/' + top, aParam);
  }

	getAlldtoMachineRuns() {
		return this.http.get<dtoMachineRun[]>(this._baseUrl + 'api/dtoMachineRun/GetAlldtoMachineRuns/');
	}

	//getAlldtodtoMachineRuns() {
	//	return this.http.get<dtodtoMachineRun[]>(this._baseUrl + 'api/dtoMachineRun/GetAlldtoMachineRuns/');
	//}

	createdtoMachineRun(dtoMachineRun: dtoMachineRun) {
		return this.http.post<dtoMachineRun>(this._baseUrl + 'api/dtoMachineRun/CreatedtoMachineRun/', dtoMachineRun);
	}

	updatedtoMachineRun(dtoMachineRun: dtoMachineRun) {
		return this.http.put<dtoMachineRun>(this._baseUrl + 'api/dtoMachineRun/UpdatedtoMachineRun/', dtoMachineRun);
	}

	async getdtoMachineRunPromise(dtoMachineRunId: string) {
		return new Promise<dtoMachineRun>(resolve => {
			this.getdtoMachineRun(dtoMachineRunId).subscribe(dtoMachineRun => {
				resolve(dtoMachineRun);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoMachineRun records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoMachineRunParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoMachineRun[]>(resolve => {
			this.getdtoMachineRunParamArray(aParam).subscribe(dtoMachineRuns => {
				resolve(dtoMachineRuns);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoMachineRun records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoMachineRunParamArrayTopPromise(aParam: SQLParamArray[][], top: number) {
    return new Promise<dtoMachineRun[]>(resolve => {
      this.getdtoMachineRunParamArrayTop(aParam, top).subscribe(dtoMachineRuns => {
        resolve(dtoMachineRuns);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoMachineRun records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }


	async updatedtoMachineRunPromise(dtoMachineRun: dtoMachineRun) {
		return new Promise<dtoMachineRun>(resolve => {
			this.updatedtoMachineRun(dtoMachineRun).subscribe(dtoMachineRun => {
				resolve(dtoMachineRun);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoMachineRun record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoMachineRunPromise(dtoMachineRun: dtoMachineRun) {
		return new Promise<dtoMachineRun>(resolve => {
			this.createdtoMachineRun(dtoMachineRun).subscribe(dtoMachineRun => {
				resolve(dtoMachineRun);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoMachineRun record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}
