<div class="quote-create-container">
  <div class="quote-create-form" [formGroup]="form">
    <div class="quote-create-quote-no field-container">
      <mat-form-field class="chh-form-field chh-textbox-400 quote-no-form-field" appearance="fill">
        <mat-label>Quote No</mat-label>
        <input class="textbox-input" matInput value="" type="text" id="txtOrderNo" formControlName="txtOrderNo" maxlength="255">
      </mat-form-field>
    </div>

    <div class="quote-create-customer">
      <app-customer-select [customers]="customers" [showMultiple]="false" (selectedCustomersEvent)="selectedCustomers($event)" (selectedCustomerContactsEvent)="selectedCustomerAndContacts($event)"></app-customer-select>
    </div>

    <div class="quote-create-customer-contact field-container">
      <app-customer-contact-select #appCustomerContact [customerContacts]="customerContacts" [showMultiple]="false" [showAll]="true" (selectedCustomerContactsEvent)="selectedCustomerContacts($event)"></app-customer-contact-select>

      <div class="customer-contact-label container-label">Customer Contact</div>
      <mat-selection-list class="chh-mat-selection-list customer-contact-select" #guCustomerContactId [multiple]="false" id="guCustomerContactId" formControlName="guCustomerContactId">
        <mat-list-option *ngFor="let customerContact of customerContacts" [value]="customerContact.rowguid" (click)="customerContactClick(customerContact)">
          {{customerContact.txtName}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="quote-create-customer-delivery-contact field-container">
      <div class="customer-delivery-contact-label container-label">Delivery Contact</div>
      <mat-selection-list class="chh-mat-selection-list customer-delivery-contact-select" #guCustomerDeliveryContactId [multiple]="false" id="guCustomerDeliveryContactId" formControlName="guCustomerDeliveryContactId">
        <mat-list-option *ngFor="let customerDeliveryContact of customerDeliveryContacts" [value]="customerDeliveryContact.rowguid" (click)="customerDeliveryContactClick(customerDeliveryContact)">
          {{customerDeliveryContact.txtName}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="quote-create-client-delivery-address field-container">
      <div class="client-delivery-address-label container-label">Customer Delivery Address</div>
      <mat-selection-list class="chh-mat-selection-list client-delivery-address-select" #guClientDelAddressId [multiple]="false" id="guClientDelAddressId" formControlName="guClientDelAddressId">
        <mat-list-option *ngFor="let customerDeliveryAddress of customerDeliveryAddresses" [value]="customerDeliveryAddress.rowguid" (click)="customerDeliveryAddressClick(customerDeliveryAddress)">
          {{customerDeliveryAddress.txtAddress}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="quote-create-client-delivery-address-2 field-container">
      <div class="client-delivery-address-2-label container-label">Customer Delivery Address 2</div>
      <mat-selection-list class="chh-mat-selection-list client-delivery-address-2-select" #guClientDelAddressId2 [multiple]="false" id="guClientDelAddressId2" formControlName="guClientDelAddressId2">
        <mat-list-option *ngFor="let customerDeliveryAddress2 of customerDeliveryAddress2s" [value]="customerDeliveryAddress2.rowguid" (click)="customerDeliveryAddress2Click(customerDeliveryAddress2)">
          {{customerDeliveryAddress.txtAddress}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="quote-create-client-delivery-address-3 field-container">
      <div class="client-delivery-address-3-label container-label">Customer Delivery Address 3</div>
      <mat-selection-list class="chh-mat-selection-list client-delivery-address-3-select" #guClientDelAddressId3 [multiple]="false" id="guClientDelAddressId3" formControlName="guClientDelAddressId3">
        <mat-list-option *ngFor="let customerDeliveryAddress3 of customerDeliveryAddress3s" [value]="customerDeliveryAddress3.rowguid" (click)="customerDeliveryAddress3Click(customerDeliveryAddress3)">
          {{clientDeliveryAddress3.txtAddress}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="quote-create-client-delivery-address-4 field-container">
      <div class="client-delivery-address-4-label container-label">Customer Delivery Address 4</div>
      <mat-selection-list class="chh-mat-selection-list client-delivery-address-4-select" #guClientDelAddressId4 [multiple]="false" id="guClientDelAddressId4" formControlName="guClientDelAddressId4">
        <mat-list-option *ngFor="let customerDeliveryAddress4 of customerDeliveryAddress4s" [value]="customerDeliveryAddress4.rowguid" (click)="customerDeliveryAddress4Click(customerDeliveryAddress4)">
          {{clientDeliveryAddress4.txtAddress}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="quote-create-payment-type field-container">
    </div>

    <div class="quote-create-invoice-transmission field-container">
    </div>

    <div class="quote-create-delivery-rate field-container">
    </div>

    <div class="quote-create-escort field-container">
      <mat-form-field class="chh-form-field chh-textbox-100 escort-form-field" appearance="fill">
        <mat-label>Escort</mat-label>
        <input class="textbox-input" matInput value="" type="text" id="fltEscort" formControlName="fltEscort">
      </mat-form-field>
    </div>

    <div class="quote-create-unloading-charge">
    </div>

    <div class="quote-create-quoted-by field-container">
      <div class="quoted-by-label container-label">Quoted By</div>
      <mat-selection-list class="chh-mat-selection-list quoted-by-select" #guEmployeeId [multiple]="false" id="guEmployeeId" formControlName="guEmployeeId">
        <mat-list-option *ngFor="let quotedByEmployee of quotedByEmployees" [value]="quotedByEmployee.rowguid" (click)="quotedByClick(quotedByEmployee)">
          {{quotedBy.txtFirstName}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="quote-create-comments field-container">
      <mat-form-field class="chh-form-field example-full-width comments-form-field" appearance="fill">
        <mat-label>Comments</mat-label>
        <textarea class="comments" matInput value="" id="txtComments" formControlName="txtComments"></textarea>
      </mat-form-field>
    </div>

    <div class="quote-create-internal-comments field-container">
      <mat-form-field class="chh-form-field example-full-width internal-comments-form-field" appearance="fill">
        <mat-label>Comments</mat-label>
        <textarea class="comments" matInput value="" id="txtInternalComments" formControlName="txtInternalComments"></textarea>
      </mat-form-field>
    </div>

    <div class="quote-create-freight-sub-contractor field-container">
      <mat-form-field class="chh-form-field chh-textbox-500 freight-sub-contractor-form-field" appearance="fill">
        <mat-label>Freight Sub Contractor</mat-label>
        <input class="textbox-input" matInput value="" type="text" id="txtFreightSubContractor" formControlName="txtFreightSubContractor" maxlength="100">
      </mat-form-field>
    </div>

    <div class="quote-create-sub-contracted-freight-quote field-container">
    </div>

    <div class="quote-create-freight-sub-contractor-comments field-container">
      <mat-form-field class="chh-form-field example-full-width freight-sub-contractor-comments-form-field" appearance="fill">
        <mat-label>Comments</mat-label>
        <textarea class="comments" matInput value="" id="txtFreightSubContractorComments" formControlName="txtFreightSubContractorComments"></textarea>
      </mat-form-field>
    </div>

    <div class="quote-create-contacted-sub-contractor field-container">
      <mat-form-field class="chh-form-field chh-textbox-100 contacted-sub-contractor-form-field" appearance="fill">
        <mat-label>Contacted Sub Contractor</mat-label>
        <input class="textbox-input" matInput value="" type="text" id="blnContactedSubContractor" formControlName="blnContactedSubContractor">
      </mat-form-field>
    </div>

    <div class="quote-create-admin-comments field-container">
      <mat-form-field class="chh-form-field example-full-width admin-comments-form-field" appearance="fill">
        <mat-label>Admin Comments</mat-label>
        <textarea class="comments" matInput value="" id="txtAdminComments" formControlName="txtAdminComments"></textarea>
      </mat-form-field>
    </div>

    <div class="quote-create-availability-number field-container">
    </div>

    <div class="quote-create-availability-unit field-container">
    </div>

    <div class="quote-create-availablity field-container">
      <mat-form-field class="chh-form-field example-full-width availablity-form-field" appearance="fill">
        <mat-label>Availability</mat-label>
        <textarea class="comments" matInput value="" id="txtAvailablity" formControlName="txtAvailablity"></textarea>
      </mat-form-field>
    </div>

    <div class="quote-create-optional-quote field-container">
      <mat-form-field class="chh-form-field chh-textbox-100 optional-quote-form-field" appearance="fill">
        <mat-label>Optional Quote</mat-label>
        <input class="textbox-input" matInput value="" type="text" id="blnOptionalQuote" formControlName="blnOptionalQuote">
      </mat-form-field>
    </div>

    <div class="quote-create-ask-for-sale field-container">
      <mat-form-field class="chh-form-field chh-textbox-100 ask-for-sale-form-field" appearance="fill">
        <mat-label>Ask For Sale</mat-label>
        <input class="textbox-input" matInput value="" type="text" id="blnAskForSale" formControlName="blnAskForSale">
      </mat-form-field>
    </div>

    <div class="quote-create-ask-for-sale-reminder field-container">
      <mat-form-field class="chh-form-field chh-textbox-100 ask-for-sale-reminder-form-field" appearance="fill">
        <mat-label>Ask For Sale Reminder</mat-label>
        <input class="textbox-input" matInput value="" type="text" id="dteAskForSaleReminder" formControlName="dteAskForSaleReminder">
      </mat-form-field>
    </div>

    <div class="quote-create-created field-container">
      <mat-form-field class="chh-form-field chh-textbox-100 created-form-field" appearance="fill">
        <mat-label>Created</mat-label>
        <input class="textbox-input" matInput value="" type="text" id="dteCreated" formControlName="dteCreated">
      </mat-form-field>
    </div>
    <div class="action-button-container">
      <button class="action-button" mat-flat-button color="primary" (click)="createQuote('')">Create Quote</button>
      <button class="action-button" mat-flat-button color="primary" (click)="createQuote('CreateQuoteAddNew')">Create Quote And Add New</button>
      <button class="action-button" mat-flat-button color="primary" (click)="cancelQuote()">Cancel</button>
    </div>
  </div>


</div>

<app-spinner *ngIf="showSpinner" [showSpinner]="showSpinner" [spinnerId]="'quoteSpinner'" class="quote-spinner"></app-spinner>
