import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';

@Component({
  selector: 'app-send-mail-dialog',
  templateUrl: './send-mail-dialog.component.html',
  styleUrl: './send-mail-dialog.component.scss'
})
export class SendMailDialogComponent {

  fileAttachments: FileAttachment[];
  toAddresses: string;
  ccAddresses: string;
  bccAddresses: string;
  emailSubject: string;
  emailMessage: string

  constructor(private dialogRef: MatDialogRef<SendMailDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {toAddresses: string, ccAddresses: string, bccAddresses: string, emailSubject: string, emailMessage: string, fileAttachements: FileAttachment[] }) {

    this.toAddresses = data.toAddresses;
    this.ccAddresses = data.ccAddresses;
    this.bccAddresses = data.bccAddresses;
    this.emailSubject = data.emailSubject;
    this.emailMessage = data.emailMessage;
    this.fileAttachments = data.fileAttachements;

    //console.log("data.fileAttachements", data.fileAttachements);

    
  }

  emailSent(ev) {
    this.closeDialog();
  }

  emailCancelled(ev) {
    //console.log("in closeDialog");
    this.closeDialog();
  }

  closeDialog() {

    this.dialogRef.close();

  }
}
