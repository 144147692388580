import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { AppPreference } from '../business-objects/app-preference.bo';
import { SharedService } from './shared.service';

@Injectable()
export class AppPreferenceService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private sharedService: SharedService) {
		this._baseUrl = baseUrl;
	}

	getAppPreference(appPreferenceId: string) {
		return this.http.get<AppPreference>(this._baseUrl + 'api/AppPreference/' + appPreferenceId);
	}

	getAppPreferenceParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<AppPreference[]>(this._baseUrl + 'api/AppPreference/GetAppPreferencesParamArray/', aParam);
	}

	getAllAppPreferences() {
		return this.http.get<AppPreference[]>(this._baseUrl + 'api/AppPreference/GetAllAppPreferences/');
	}

	//getAlldtoAppPreferences() {
	//	return this.http.get<dtoAppPreference[]>(this._baseUrl + 'api/AppPreference/GetAllAppPreferences/');
	//}

	createAppPreference(appPreference: AppPreference) {
		return this.http.post<AppPreference>(this._baseUrl + 'api/AppPreference/CreateAppPreference/', appPreference);
	}

	updateAppPreference(appPreference: AppPreference) {
		return this.http.put<AppPreference>(this._baseUrl + 'api/AppPreference/UpdateAppPreference/', appPreference);
	}

	deleteAppPreference(appPreferenceId: string) {
		return this.http.delete(this._baseUrl + 'api/AppPreference/DeleteAppPreference/' + appPreferenceId);
	}

	async getAppPreferencePromise(appPreferenceId: string) {
		return new Promise<AppPreference>(resolve => {
			this.getAppPreference(appPreferenceId).subscribe(appPreference => {
				resolve(appPreference);
			}, err => {
				this.alertService.openSnackBarError("Error getting AppPreference records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getAppPreferenceByName(name: string) {
    return new Promise<AppPreference>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("txtName", name));
      aParamList.push(aParam);

      this.getAppPreferenceParamArray(aParamList).subscribe({
        next: (appPreferences) => {
          if (appPreferences != null && appPreferences.length > 0) {
            resolve(appPreferences[0]);
          } else {
            resolve(null);
          }
        }, error: (err: HttpErrorResponse) => {
          this.alertService.openSnackBarError("Error getting AppPreferenceByName records", "Close", "center", "bottom", 4000, true, err.message);
          resolve(null);
        }
      });
    });
  }

  async createAppPreferenceByName(name: string) {
    return new Promise<AppPreference>(resolve => {

      let appPreference: AppPreference = new AppPreference();
      appPreference.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
      appPreference.intApp = SharedService.enAppType.MTECH_Web_App;
      appPreference.txtName = name;


      this.createAppPreference(appPreference).subscribe({
        next: (appPreferences) => {
          resolve(appPreferences);
        }, error: (err: HttpErrorResponse) => {
          this.alertService.openSnackBarError("Error creating AppPreference records", "Close", "center", "bottom", 4000, true, err.message);
          resolve(null);
        }
      });
    });
  }

	async getAppPreferenceParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<AppPreference[]>(resolve => {
			this.getAppPreferenceParamArray(aParam).subscribe(appPreferences => {
				resolve(appPreferences);
			}, err => {
				this.alertService.openSnackBarError("Error getting AppPreference records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateAppPreferencePromise(appPreference: AppPreference) {
		return new Promise<AppPreference>(resolve => {
			this.updateAppPreference(appPreference).subscribe(appPreference => {
				resolve(appPreference);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating AppPreference record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createAppPreferencePromise(appPreference: AppPreference) {
		return new Promise<AppPreference>(resolve => {
			this.createAppPreference(appPreference).subscribe(appPreference => {
				resolve(appPreference);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating AppPreference record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deleteAppPreferencePromise(appPreferenceId: string) {
		return new Promise<boolean>(resolve => {
			this.deleteAppPreference(appPreferenceId).subscribe(appPreference => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing AppPreference record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
