<div class="moulder-dialog-container">
  <div class="moulder-dialog-form-container">
    <div class="moulder-dialog-form">
      
      <div class="header-container">
        <div class="header-container-row-container">
          <div class="header-column-1"></div>
          <div class="header-column-2"><h1>Moulders</h1></div>
          <div class="header-column-3">
            <div class="selected-moulders-container"><div class="selected-moulders-label" *ngIf="selectedMoulders.length > 0">Selected Moulders:</div>
              <span class="moulder-name" *ngFor="let moulder of selectedMoulders; let i = index">{{i < selectedMoulders.length - 1 ? moulder.txtName + ', ' : moulder.txtName}}</span>
            </div>
          </div>
        </div>

        <div class="fields-container">

            <div class="fields-row-1">

              <div class="header-row-4">
                <div class="moulders">
                  <mat-selection-list class="moulder-select" #guMoulderId [multiple]="true">
                    <mat-list-option *ngFor="let moulder of moulders" [value]="moulder" (click)="moulderSelected(moulder)">
                      <div class="options-container"><div class="option-name">{{moulder.txtName}}</div></div>
                    </mat-list-option>
                  </mat-selection-list>
                  <br />
                </div>
              </div>
            </div>
        </div>
        <div class="action-button-container">
          <button class="action-button" mat-flat-button color="primary" (click)="selectMoulders()" [disabled]="this.selectedMoulders == null || this.selectedMoulders.length == 0">Select</button>
          <button class="action-button" mat-flat-button color="primary" (click)="moulderCancelled(true)">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="spinner-container">
  <mat-card [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showSpinner">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
