import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { dtoPrinterLocation } from '../../_shared/business-objects/dto-printer-location.bo';

@Component({
  selector: 'app-printer-location-list',
  templateUrl: './printer-location-list.component.html',
  styleUrls: ['./printer-location-list.component.css']
})
export class PrinterLocationListComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoPrinterLocation>;
  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() columnListId: string;

  loadedComponent: boolean = false;

  displayedColumns: string[] = ['txtName', 'txtAliasName', 'txtPrinterLocation', 'txtPrinterIPAddress', 'txtPaperKind', 'txtPrinterType', 'fltWidth', 'fltHeight', 'txtSizeUnit', 'blnSensativeData', 'blnActive', 'dteDateCreated', 'btnEditPrinter', 'btnRemovePrinter'];


  constructor() { }

  ngOnInit(): void {
  }

}
