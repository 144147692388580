import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../account/auth.service';
import { dtoKeyValue } from '../../../_shared/business-objects/dto-int-key-value.bo';
import { Pole } from '../../../_shared/business-objects/pole.bo';
import { RoyaltyRate } from '../../../_shared/business-objects/royalty-rate.bo';
import { SQLParamArray } from '../../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../../_shared/services/alert.service';
import { FormService } from '../../../_shared/services/form.service';
import { PoleService } from '../../../_shared/services/pole.service';
import { RoyaltyRateService } from '../../../_shared/services/royalty-rate.service';
import { SharedService } from '../../../_shared/services/shared.service';

@Component({
  selector: 'app-royalty-rate-create',
  templateUrl: './royalty-rate-create.component.html',
  styleUrls: ['./royalty-rate-create.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class RoyaltyRateCreateComponent implements OnInit {

  form: FormGroup;

  @Output() royaltyRateCreated = new EventEmitter<[RoyaltyRate, string]>();
  @Output() royaltyRateCancelled = new EventEmitter<boolean>();

  public polesFilterCtrl: FormControl = new FormControl();
  public poleContactsFilterCtrl: FormControl = new FormControl();
  public haulersFilterCtrl: FormControl = new FormControl();

  public poles: Pole[];


  public filteredPoles: ReplaySubject<Pole[]> = new ReplaySubject<Pole[]>(1);

  private _onDestroy = new Subject<void>();

  durabilityClasses: dtoKeyValue[] = [];

  public firstNameMaxWidth: number;

  showSpinner: boolean

  constructor(private formBuilder: FormBuilder, private formService: FormService, private alertService: AlertService, private authService: AuthService, private sharedService: SharedService
              , private poleService: PoleService, private royaltyRateService: RoyaltyRateService  ) {

    this.durabilityClasses = this.sharedService.getEnumObject2(RoyaltyRate.enDurabilityClass, true);

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      dteDateCreated: ['', Validators.required],
      guPoleId: ['', Validators.required],
      dteExpiry: [''],
      intDurabilityClass: ['', Validators.required],
      fltRoyaltyRate: [''],
      fltCHHRoyaltyRate: [''],
      txtComments: [''],
      chkCurrent: ['']
    });

    this.showSpinner = false;

    this.loadData();

  }

  async loadData() {

    const datepipe: DatePipe = new DatePipe('en-AU');

    this.form.controls['dteDateCreated'].setValue(datepipe.transform(new Date(Date.now()), 'yyyy-MM-dd'));
    this.form.controls['dteExpiry'].setValue('2023-10-01');


    this.getPoles();

  }

  async getPoles() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("blnActive", "1"));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("blnForestryRoyalty", "1"));
    aParamList.push(aParam);

    let poles = await this.poleService.getPoleParamArrayPromise(aParamList);
    if (poles == null) {
      return;
    }

    this.poles = poles;
    this.filteredPoles.next(this.poles);

    this.polesFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPoles();
      });
  }

  private filterPoles() {

    if (!this.poles) {
      return;
    }

    // get the search keyword
    let search = this.polesFilterCtrl.value;
    if (!search) {
      this.filteredPoles.next(this.poles);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the Poles
    this.filteredPoles.next(
      this.poles.filter(c => (c.intLength.toString() + '/' + c.intKn.toString()).startsWith(search, 0) == true)
    );
  }



  async createRoyaltyRate(mode: string) {

    let dateCreated: Date = this.form.controls['dteDateCreated'].value;
    let pole: string = this.form.controls['guPoleId'].value;
    let expiry: Date = this.form.controls['dteExpiry'].value;
    let durabilityClass: number = this.form.controls['intDurabilityClass'].value;
    let royaltyrate: number = Number(this.form.controls['fltRoyaltyRate'].value);
    let chhRoyaltyRate: number = Number(this.form.controls['fltCHHRoyaltyRate'].value);
    let comments: string = this.form.controls['txtComments'].value;
    let current: boolean = this.form.controls['chkCurrent'].value == true;
    const datepipe: DatePipe = new DatePipe('en-AU');

    if (pole == null || pole.toString() == "") {
      this.alertService.openSnackBar("Please select a pole.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (durabilityClass == null || durabilityClass.toString() == "") {
      this.alertService.openSnackBar("Please select a durability class.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (dateCreated == null || dateCreated.toString() == "") {
      this.alertService.openSnackBar("Please select a date created.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (expiry == null || expiry.toString() == "") {
      this.alertService.openSnackBar("Please select an expiry date.", "Close", "center", "bottom", "", 3000);
      return;
    }


    let royaltyRate: RoyaltyRate = new RoyaltyRate;
    royaltyRate.dteDateCreated = new Date(datepipe.transform(dateCreated, 'yyyy-MM-dd'));
    royaltyRate.dteDateExpires = new Date(datepipe.transform(expiry, 'yyyy-MM-dd'));
    royaltyRate.guPoleId = pole;
    royaltyRate.intDurabilityClass = durabilityClass;
    royaltyRate.blnCurrent = current;
    royaltyRate.fltForestryRoyaltyRate = royaltyrate;
    royaltyRate.fltCHHRoyaltyRate = chhRoyaltyRate;
    royaltyRate.txtComments = comments;

    this.showSpinner = true;

    console.log(royaltyRate);

    royaltyRate = await this.royaltyRateService.createRoyaltyRatePromise(royaltyRate);

    if (royaltyRate != null) {
      console.log(royaltyRate.rowguid);
      royaltyRate = await this.royaltyRateService.getRoyaltyRatePromise(royaltyRate.rowguid);

      this.alertService.openSnackBar("The royalty rate has been created", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;

      if (mode == "create") {
        this.royaltyRateCreated.emit([royaltyRate, mode]);
      }

      if (mode == "createandadd") {
        this.reset(true);
        for (let i = 0; i <= this.poles.length - 1; i++) {
          if (i < this.poles.length -1) {
            //console.log(this.form.controls["guPoleId"].value + "  -  " + this.poles[i].rowguid);
            if (this.form.controls["guPoleId"].value == this.poles[i].rowguid) {
              this.form.controls["guPoleId"].setValue(this.poles[i + 1].rowguid);
              //console.log("got match");
              break;
            }
          }
        }
      //  let pole: HTMLInputElement = document.getElementById("guPoleId") as HTMLInputElement;
      //  pole.scrollIntoView({ behavior: 'smooth' });
      //  pole.focus();
      }


    }


    if (royaltyRate == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem creating the royalty rate", "Close", "center", "bottom", 3000, true, "There was a problem creating the royalty rate");
    }



  }

  cancelRoyaltyRate() {
    this.royaltyRateCancelled.emit(true);
  }


  reset(cache: boolean) {

    const datepipe: DatePipe = new DatePipe('en-AU');

    if (cache == false) {
      this.form.controls['dteDateCreated'].setValue(datepipe.transform(new Date(Date.now()), 'yyyy-MM-dd'));
      this.form.controls['guPoleId'].setValue('');
      this.form.controls['dteExpiry'].setValue('');
      this.form.controls['intDurabilityClass'].setValue(0);
      this.form.controls['chkCurrent'].setValue(1);
    }

    this.form.controls['fltRoyaltyRate'].setValue('');
    this.form.controls['fltCHHRoyaltyRate'].setValue('');
    this.form.controls['txtComments'].setValue('');


  }



}
