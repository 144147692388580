<h1 class="boardtallyrecord-title">Stock Items</h1>
<br>

<table class="table">
  <tr bgcolor="#f9f9f9">
    <th>Pack No</th>
    <th>Width</th>
    <th>Thickness</th>
  </tr>

  <tr *ngFor="let boardtallyrecord of boardTallyRecords">
    <td>
      {{ boardtallyrecord.intPackNo }}
    </td>
    <td>
      {{ boardtallyrecord.intWidth }}
    </td>
    <td>
      {{ boardtallyrecord.intThickness }}
    </td>
  </tr>
</table>  
