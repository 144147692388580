import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoPoleInspectionItem } from '../business-objects/dto-pole-inspection-item.bo';

@Injectable()
export class dtoPoleInspectionItemService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoPoleInspectionItem(dtoPoleInspectionItemId: string) {
		return this.http.get<dtoPoleInspectionItem>(this._baseUrl + 'api/dtoPoleInspectionItem/' + dtoPoleInspectionItemId);
	}

	getdtoPoleInspectionItemParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoPoleInspectionItem[]>(this._baseUrl + 'api/dtoPoleInspectionItem/GetdtoPoleInspectionItemsParamArray/', aParam);
	}

	getAlldtoPoleInspectionItems() {
		return this.http.get<dtoPoleInspectionItem[]>(this._baseUrl + 'api/dtoPoleInspectionItem/GetAlldtoPoleInspectionItems/');
	}

	//getAlldtodtoPoleInspectionItems() {
	//	return this.http.get<dtodtoPoleInspectionItem[]>(this._baseUrl + 'api/dtoPoleInspectionItem/GetAlldtoPoleInspectionItems/');
	//}

	createdtoPoleInspectionItem(dtoPoleInspectionItem: dtoPoleInspectionItem) {
		return this.http.post<dtoPoleInspectionItem>(this._baseUrl + 'api/dtoPoleInspectionItem/CreatedtoPoleInspectionItem/', dtoPoleInspectionItem);
	}

	updatedtoPoleInspectionItem(dtoPoleInspectionItem: dtoPoleInspectionItem) {
		return this.http.put<dtoPoleInspectionItem>(this._baseUrl + 'api/dtoPoleInspectionItem/UpdatedtoPoleInspectionItem/', dtoPoleInspectionItem);
	}

	deletedtoPoleInspectionItem(dtoPoleInspectionItemId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoPoleInspectionItem/DeletedtoPoleInspectionItem/' + dtoPoleInspectionItemId);
	}

	async getdtoPoleInspectionItemPromise(dtoPoleInspectionItemId: string) {
		return new Promise<dtoPoleInspectionItem>(resolve => {
			this.getdtoPoleInspectionItem(dtoPoleInspectionItemId).subscribe(dtoPoleInspectionItem => {
				resolve(dtoPoleInspectionItem);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoPoleInspectionItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoPoleInspectionItemParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoPoleInspectionItem[]>(resolve => {
			this.getdtoPoleInspectionItemParamArray(aParam).subscribe(dtoPoleInspectionItems => {
				resolve(dtoPoleInspectionItems);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoPoleInspectionItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoPoleInspectionItemPromise(dtoPoleInspectionItem: dtoPoleInspectionItem) {
		return new Promise<dtoPoleInspectionItem>(resolve => {
			this.updatedtoPoleInspectionItem(dtoPoleInspectionItem).subscribe(dtoPoleInspectionItem => {
				resolve(dtoPoleInspectionItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoPoleInspectionItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoPoleInspectionItemPromise(dtoPoleInspectionItem: dtoPoleInspectionItem) {
		return new Promise<dtoPoleInspectionItem>(resolve => {
			this.createdtoPoleInspectionItem(dtoPoleInspectionItem).subscribe(dtoPoleInspectionItem => {
				resolve(dtoPoleInspectionItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoPoleInspectionItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoPoleInspectionItemPromise(dtoPoleInspectionItemId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoPoleInspectionItem(dtoPoleInspectionItemId).subscribe(dtoPoleInspectionItem => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoPoleInspectionItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}