import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoOrders } from '../business-objects/dto-orders.bo';

@Injectable()
export class dtoOrdersService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoOrders(dtoOrdersId: string) {
		return this.http.get<dtoOrders>(this._baseUrl + 'api/dtoOrders/' + dtoOrdersId);
	}

  getdtoOrdersParamArray(aParam: SQLParamArray[][], top: number = 0) {
    console.log("GetdtoOrdersParamArray", new Date);

		return this.http.post<dtoOrders[]>(this._baseUrl + 'api/dtoOrders/GetdtoOrdersParamArray/' + top, aParam);
	}

	getAlldtoOrderss() {
		return this.http.get<dtoOrders[]>(this._baseUrl + 'api/dtoOrders/GetAlldtoOrders/');
	}

	//getAlldtodtoOrderss() {
	//	return this.http.get<dtodtoOrders[]>(this._baseUrl + 'api/dtoOrders/GetAlldtoOrders/');
	//}

	createdtoOrders(dtoOrders: dtoOrders) {
		return this.http.post<dtoOrders>(this._baseUrl + 'api/dtoOrders/CreatedtoOrders/', dtoOrders);
	}

	updatedtoOrders(dtoOrders: dtoOrders) {
		return this.http.put<dtoOrders>(this._baseUrl + 'api/dtoOrders/UpdatedtoOrders/', dtoOrders);
	}

	async getdtoOrdersPromise(dtoOrdersId: string) {
		return new Promise<dtoOrders>(resolve => {
			this.getdtoOrders(dtoOrdersId).subscribe(dtoOrders => {
				resolve(dtoOrders);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoOrders records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoOrdersParamArrayPromise(aParam: SQLParamArray[][], top: number = 0) {
		return new Promise<dtoOrders[]>(resolve => {
      this.getdtoOrdersParamArray(aParam, top).subscribe(dtoOrders => {
				resolve(dtoOrders);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoOrders records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoOrdersPromise(dtoOrders: dtoOrders) {
		return new Promise<dtoOrders>(resolve => {
			this.updatedtoOrders(dtoOrders).subscribe(dtoOrders => {
				resolve(dtoOrders);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoOrders record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoOrdersPromise(dtoOrders: dtoOrders) {
		return new Promise<dtoOrders>(resolve => {
			this.createdtoOrders(dtoOrders).subscribe(dtoOrders => {
				resolve(dtoOrders);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoOrders record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}
