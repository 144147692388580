<div class="customer-select-container" #customerContainer>
  <mat-form-field class="chh-form-field example-full-width">
    <mat-label>Search Customer</mat-label>
    <input matInput data-customer-input="customerInput" class="customer-input" placeholder="Filter by name" placeholderLabel="i.e. Essential Energy....." value="" type="text" #customerFilter (keyup)="filterCustomers(customerFilter.value, $event)" (focus)="customerFilter.select()" (click)="checkCustomerListIsOpen(customerFilter.value)">
  </mat-form-field>

  <mat-icon matTooltip="Click to add a Customer" class="clickable-icon person-add" (click)="addCustomer()">person_add</mat-icon>
  <span><mat-slide-toggle #multipleCustomers (click)="checkMultipleCustomers(multipleCustomers.checked)" *ngIf="showMultiple">Multiple Customers</mat-slide-toggle></span>
  <div class="selected-customers-container">
    <div class="selected-customers" id="selectedCustomers" *ngIf="selectedCustomers != null && selectedCustomers.length > 0">
      <div class="chip-column">
        <mat-chip-grid #chipGrid aria-label="Customer selection">
          @for (selectCust of selectedCustomers; track selectCust; let selCusIdx = $index; let lastItem = $last) {
          <mat-chip-row data-customer-input="customerInput" (removed)="removeCustomer(selectCust)" (click)="selectChipCustomer($event, selectCust);customerFilter.select()">
            {{selectCust.txtName}}
            <button matChipRemove [attr.aria-label]="'remove ' + selectCust.txtName">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          }
        </mat-chip-grid>
        <input #customerChipInput [matChipInputFor]="chipGrid"/>
      </div>
      <div class="see-more-chips">
        <button mat-icon-button aria-label="expand row" (click)="expandSelectedCustomers($event)" *ngIf="selectedCustomers != null && selectedCustomers.length > 1">
          <mat-icon class="expand-icons customer-selection-expand-icon material-icons-outlined" *ngIf="customerSelectionExpanded == false ">keyboard_arrow_down</mat-icon>
          <mat-icon class="expand-icons customer-selection-expand-icon material-icons-outlined" *ngIf="customerSelectionExpanded == true">keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div data-customer-select="customerSelect" class="customer-container" *ngIf="showCustomerList" #customerListContainer>
    <div data-customer-select="customerSelect" id="customerSelect" class="customer-select-list">
      <div class="customer-pin-row">
        <div class="button" data-push-pin="pushPin" id="btnPushPin" (click)="clickPin($event)" [ngClass]="customerListPinned == true ? 'button-pressed' : 'button'" matTooltip="{{toolTipText}}">
          <mat-icon data-push-pin="pushPin" class="pin-icon">push_pin</mat-icon>
        </div>
      </div>
      <div class="customer-heading-row customer-name-heading">Name</div>
      <div class="customer-heading-row customer-pricing-category-heading">Pricing Category</div>
      <div class="customer-heading-row customer-update-heading"></div>
      <div class="customer-heading-row customer-view-contacts-heading"></div>
      @for(customer of customersFiltered; track customer; let idx = $index){
      <div data-customer-select="customerSelect" tabindex="{{idx}}" id="customerName{{idx}}" (keyup)="nextCustomer($event, customer)" class="chh-list-item-row chh-list-item-first-column customer-name" [ngClass]="customer.guSelectedCustomerId != '' ? 'chh-list-item-row chh-list-item-row-selected customer-first-name' : addressHoverId == customer.rowguid ? 'chh-list-item-row chh-list-item-row-hover customer-first-name' : 'chh-list-item-row customer-first-name'" (mouseenter)="rowEnter(customer.rowguid)" (mouseleave)="rowLeave()" (click)="selectCustomer(customer, !(customer.guSelectedCustomerId != ''))"><div data-customer-select="customerSelect" class="customer-text">{{customer.txtName}}</div></div>
      <div data-customer-select="customerSelect" id="customerTown{{idx}}" class="chh-list-item-row customer-pricing-category customer-town" [ngClass]="customer.guSelectedCustomerId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customer.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customer.rowguid)" (mouseleave)="rowLeave()" (click)="selectCustomer(customer, !(customer.guSelectedCustomerId != ''))"><div data-customer-select="customerSelect" class="customer-text">{{customer.txtPricingCategoryName}}</div></div>
      <div data-customer-select="customerSelect" id="customerUpdateDiv{{idx}}" class="chh-list-item-row customer-radio customer-update-div" [ngClass]="customer.guSelectedCustomerId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customer.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customer.rowguid)" (mouseleave)="rowLeave()">
        <div data-customer-select="customerSelect" id="customerUpdateButtonDiv{{idx}}" class="update-customer-button">
          <button mat-button (click)="updateCustomer(customer)" matTooltip="Click to edit Customer">
            <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
          </button>
        </div>
      </div>
      <div data-customer-expand="customerExpand" id="customerContactList{{idx}}" class="chh-list-item-row chh-list-item-last-column customer-pricing-category customer-contact-list" [ngClass]="customer.guSelectedCustomerId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customer.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customer.rowguid)" (mouseleave)="rowLeave()" (click)="selectCustomer(customer, !(customer.guSelectedCustomerId != ''))">
        <div data-customer-expand="customerExpand" class="customer-text customer-expand-div">
          <button mat-icon-button aria-label="expand row" (click)="expandElement(customer,$event, customerExpanded === customer);$event.stopPropagation()">
            <mat-icon class="expand-icons" *ngIf="customerExpanded !== customer">keyboard_arrow_down</mat-icon>
            <mat-icon class="expand-icons" *ngIf="customerExpanded === customer">keyboard_arrow_up</mat-icon>
          </button>
        </div>
      </div>

      <div class="customer-contacts-row" *ngIf="customerExpanded === customer">
        <div class="customer-contact-grid">
          <div data-customer-contact-list="customerContactList" class="customer-contact-row-heading customer-contact-first-name-heading">First Name</div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row-heading customer-contact-last-name-heading">Last Name</div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row-heading customer-contact-phone-heading">Phone</div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row-heading customer-contact-mobile-heading">Mobile</div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row-heading customer-contact-mobile-heading">Email</div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row-heading customer-contact-town-heading-container">
            <div data-customer-contact-list="customerContactList" class="customer-contact-town-heading">Town</div>
            <div data-customer-contact-list="customerContactList" class="customer-contact-add-contact">
            </div>
          </div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row-heading customer-contact-update-heading">
            <mat-icon data-customer-contact-add="customerContactAdd" matTooltip="Click to add a Customer Contact" class="clickable-icon customer-contact-add-icon" (click)="addCustomerContact(customer)">person_add</mat-icon>

          </div>
          @for(customerContact of customerContacts; track customerContact; let custContactIdx = $index){
          <div data-customer-contact-list="customerContactList" class="customer-contact-row customer-contact-first-name" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="contactRowEnter(customerContact.rowguid)" (mouseleave)="contactRowLeave()" (click)="selectContact(customerContact, customer)">{{customerContact.txtName}}</div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row customer-contact-last-name" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="contactRowEnter(customerContact.rowguid)" (mouseleave)="contactRowLeave()" (click)="selectContact(customerContact, customer)">{{customerContact.txtLastName}}</div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row customer-contact-phone1" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="contactRowEnter(customerContact.rowguid)" (mouseleave)="contactRowLeave()" (click)="selectContact(customerContact, customer)">{{customerContact.txtPhone1}}</div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row customer-contact-mobile" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="contactRowEnter(customerContact.rowguid)" (mouseleave)="contactRowLeave()" (click)="selectContact(customerContact, customer)">{{customerContact.txtMobile}}</div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row customer-contact-email" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="contactRowEnter(customerContact.rowguid)" (mouseleave)="contactRowLeave()" (click)="selectContact(customerContact, customer)" #emailDiv>
            <mat-icon data-customer-contact-add="customerCopyEmail" matTooltip="{{(customerContact.txtEmail == null || customerContact.txtEmail == '') ? 'There is no email set' : 'Click to Copy Email Address\n' + customerContact.txtEmail}}" matTooltipClass="chh-tooltip-max-width" class="clickable-icon email-customer-icon" (click)="copyToClipboard(customerContact.txtEmail)">content_copy</mat-icon>
            <mat-icon data-customer-contact-add="sendEmail" matTooltip="{{(customerContact.txtEmail == null || customerContact.txtEmail == '') ? 'There is no email set' : 'Click to Send The Contact An Email'}}" matTooltipClass="chh-tooltip-max-width" class="clickable-icon send-email-customer-icon material-icons-outlined" #emailIcon (click)="sendEmail(customerContact.txtEmail, emailDiv)">mail</mat-icon>
          </div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row customer-contact-town" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="contactRowEnter(customerContact.rowguid)" (mouseleave)="contactRowLeave()" (click)="selectContact(customerContact, customer)">{{customerContact.txtTown}}</div>
          <div data-customer-contact-list="customerContactList" class="customer-contact-row customer-contact-update" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="contactRowEnter(customerContact.rowguid)" (mouseleave)="contactRowLeave()">
            <div data-customer-contact-list="customerContactList" class="customer-contact-update-contact">
              <mat-icon data-customer-contact-update="customerContactUpdate" matTooltip="Click to update a Customer Contact" class="clickable-icon edit-customer-contact-icon material-icons-outlined" (click)="updateCustomerContact(customerContact)">edit_document</mat-icon>
            </div>
          </div>
          }
        </div>
      </div>

        }
    </div>

    </div>

</div>
