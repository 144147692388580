


<p>class-builder</p>
<button class="stocktakeButton" mat-flat-button color="primary" id="btnOpenFile" (click)="onCreateClasses()">Create Type Script Classes</button>
<br />
<br />
<button class="stocktakeButton" mat-flat-button color="primary" id="btnOpenFile" (click)="onCreateDTOClasses()">Create DTO CS Classes</button>
<br />
<br />
<button class="stocktakeButton" mat-flat-button color="primary" id="btnOpenFile" (click)="onCreateDTOTSClasses()">Create DTO Type Script Classes</button>
<br />
<br />
<button class="stocktakeButton" mat-flat-button color="primary" id="btnOpenFile" (click)="onCreateDTORepoClasses()">Create DTO Repository Classes</button>
<br />
<br />
<button class="stocktakeButton" mat-flat-button color="primary" id="btnOpenFile" (click)="onCreateDTOServiceClasses()">Create DTO Service Classes</button>
<br />
<br />
<button class="stocktakeButton" mat-flat-button color="primary" id="btnOpenFile" (click)="onCreateTypeScriptServices()">Create Type Script Services</button>
<br />
<br />
<button class="stocktakeButton" mat-flat-button color="primary" id="btnOpenFile" (click)="onCreateWebApiControllers()">Create Web API Controllers</button>
<br />
<br />
<button class="stocktakeButton" mat-flat-button color="primary" id="btnOpenFile" (click)="download('TestWritingFile.txt')">Download Text File</button>
<br />
<br />

<button class="stocktakeButton" mat-flat-button color="primary" id="btnOpenFile" (click)="download('powersupply.jpg')">Download JPG</button>
<br />
<br />

<button class="stocktakeButton" mat-flat-button color="primary" id="btnOpenFile" (click)="download('661321.pdf')">Download PDF</button>
