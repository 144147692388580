import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { TPChargeItem } from '../business-objects/tpcharge-item.bo';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { dtoKeyValue } from '../business-objects/dto-int-key-value.bo';

@Injectable()
export class TPChargeItemService
{

  public _baseUrl: string;
  private today = new Date();
  public year: number = this.today.getFullYear();
  public decadeDigit: string = this.year.toString().substring(this.year.toString().length - 2, this.year.toString().length - 1);
  public yearDigit: string = this.year.toString().substring(this.year.toString().length - 1, this.year.toString().length);

  //public treatment_date_pattern = "(((^[0])[1-9]||^[1][0-2])([0-" + this.decadeDigit + "][0-" + this.yearDigit +"])$)";
  public treatment_month_pattern = "(([1-9]||^[1][0-2]))";
  public treatment_year_pattern = "(([0-9][0-9]))";

  //public treatment_date_Validator: ValidatorFn = Validators.pattern(this.treatment_date_pattern);
  public treatment_month_Validator: ValidatorFn = Validators.pattern(this.treatment_month_pattern);
  public treatment_month_required_Validator: ValidatorFn = Validators.required;
  public treatment_year_Validator: ValidatorFn = Validators.pattern(this.treatment_year_pattern);
  public treatment_year_required_Validator: ValidatorFn = Validators.required;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
	  this._baseUrl = baseUrl;
  }

  getTPChargeItem(tPChargeItemId: string) {
	  return this.http.get<TPChargeItem>(this._baseUrl + 'api/TPChargeItem/' + tPChargeItemId);
  }

  getTPChargeItemParamArray(aParam: SQLParamArray[][]) {
	  return this.http.post<TPChargeItem[]>(this._baseUrl + 'api/TPChargeItem/GetTPChargeItemsParamArray/', aParam);
  }

  getAllTPChargeItems() {
	  return this.http.get<TPChargeItem[]>(this._baseUrl + 'api/TPChargeItem/GetAllTPChargeItems/');
  }

  //getAlldtoTPChargeItems() {
  //	return this.http.get<dtoTPChargeItem[]>(this._baseUrl + 'api/TPChargeItem/GetAllTPChargeItems/');
  //}

  createTPChargeItem(tPChargeItem: TPChargeItem) {
	  return this.http.post<TPChargeItem>(this._baseUrl + 'api/TPChargeItem/CreateTPChargeItem/', tPChargeItem);
  }

  updateTPChargeItem(tPChargeItem: TPChargeItem) {
	  return this.http.put<TPChargeItem>(this._baseUrl + 'api/TPChargeItem/UpdateTPChargeItem/', tPChargeItem);
  }

  async getTPChargeItemPromise(tPChargeItem) {
	  return new Promise<TPChargeItem>(resolve => {
		  this.getTPChargeItem(tPChargeItem).subscribe(tPChargeItem => {
			  resolve(tPChargeItem);
		  }, err => {
			  this.alertService.openSnackBarError("Error getting TPChargeItem records", "Close", "center", "bottom", 4000, true, err.error);
			  resolve(null);
		  });
	  });
  }

  async getTPChargeItemParamArrayPromise(aParam: SQLParamArray[][]) {
	  return new Promise<TPChargeItem[]>(resolve => {
		  this.getTPChargeItemParamArray(aParam).subscribe(tPChargeItems => {
			  resolve(tPChargeItems);
		  }, err => {
			  this.alertService.openSnackBarError("Error getting TPChargeItem records", "Close", "center", "bottom", 4000, true, err.error);
			  resolve(null);
		  });
	  });
  }

  async updateTPChargeItemPromise(tPChargeItem: TPChargeItem) {
	  return new Promise<TPChargeItem>(resolve => {
		  this.updateTPChargeItem(tPChargeItem).subscribe(tPChargeItem => {
			  resolve(tPChargeItem);
		  }, (err: HttpErrorResponse) => {
			  this.alertService.openSnackBarError("Error updating TPChargeItem record", "Close", "center", "bottom", 4000, true, err.error);
			  resolve(null);
		  });
	  });
  }

  async createTPChargeItemPromise(tPChargeItem) {
	  return new Promise<TPChargeItem>(resolve => {
		  this.createTPChargeItem(tPChargeItem).subscribe(tPChargeItem => {
			  resolve(tPChargeItem);
		  }, (err: HttpErrorResponse) => {
			  this.alertService.openSnackBarError("Error creating TPChargeItem record", "Close", "center", "bottom", 4000, true, err.error);
			  resolve(null);
		  });
	  });
  }


  public validateTreatmentMonth(treatmentMonth: AbstractControl) {
    if (treatmentMonth.hasError('pattern')) {
      return 'The Treatment Month is not valid';
    }
  }

  public validateTreatmentYear(treatmentYear: AbstractControl) {
    if (treatmentYear.hasError('pattern')) {
      return 'The Treatment Year is not valid';
    }

    let today = new Date();
    let year: number = today.getFullYear();
    //let decadeDigit: string = year.toString().substring(year.toString().length - 2, year.toString().length - 1);
    let yearDigits: number = parseInt(year.toString().substring(year.toString().length - 2, year.toString().length));

    if (parseInt(treatmentYear.value) > yearDigits) {
      return 'The Treatment Year is not valid';
    }
  }

}
