import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { dtoAppUserGeoStockLocation } from '../_shared/business-objects/dto-app-user-geo-stock-location.bo';
import { AlertService } from '../_shared/services/alert.service';
import { AppUserGeoStockLocationService } from '../_shared/services/appuser-geostocklocation.service';
import { SnackBarCustomComponent } from '../snack-bar-custom/snack-bar-custom.component';

@Component({
  selector: 'app-appuser-geostocklocation-list',
  templateUrl: './appuser-geostocklocation-list.component.html',
  styleUrls: ['./appuser-geostocklocation-list.component.css']
})
export class AppuserGeostocklocationListComponent implements OnInit {

  @ViewChild('tblAppUserGeoStockLocation') tblRefundTaxInvoiceItems: MatTable<dtoAppUserGeoStockLocation>;

  dtoAppUserGeoStockLocations: dtoAppUserGeoStockLocation[] = [];

  appUserGeoStockLocationsColumns: string[] = ['txtFullName', 'txtLocationName', 'blnDefaultLocation', 'btnEdit', 'btnRemove'];


  constructor(private appUserGeoStockLocationService: AppUserGeoStockLocationService, private alertService: AlertService) {

  }

  ngOnInit(): void {

    this.getAppUserGeoStockLocations()

  }

  getAppUserGeoStockLocations() {
    this.appUserGeoStockLocationService.getAlldtoAppUserGeoStockLocations().subscribe(appUserLocactions => {

      this.dtoAppUserGeoStockLocations = appUserLocactions;
      console.log(appUserLocactions);

    }, err => {
      console.log(err);
    });
  }

  addAppUserLocation() {

  }

  editAppUserLocation() {

  }

  removeAppUserLocation(appUserGeoStockLocationId: string) {

    //let snack = this.alertService.openSnackBar("Are you sure you want to remove this user stock location?", "Close", "center", "bottom", "", 5000);

    let snack = this.alertService.openSnackBarCustom("App User Location Alert", "Are you sure you want to remove this app user location", "Yes", "No", "center", "bottom", "", 5000);

    snack.onAction().subscribe(() => {
      console.log('The snackbar action was triggered!');
      if (snack.instance.actionResponse == "Yes") {
        this.appUserGeoStockLocationService.removeAppUserGeoStockLocation(appUserGeoStockLocationId).subscribe(data => {

          this.getAppUserGeoStockLocations()

        }, err => {
          console.log(err);
        })
      }

    });

  }

}
