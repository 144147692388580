export interface CodeBuilderColumn
{
	blnIsComputed: boolean;
	blnIsIdentity: boolean;
	blnNullableFilter: boolean;
	dteLastUpdated: Date;
	guCodeBuilderTableId: string;
	intColumnFilterType: number;
	intMaxCharacterLength: number;
	intSQLFilterOperator: number;
	rowguid: string;
	txtColumnDefault: string;
	txtColumnName: string;
	txtComments: string;
	txtDataType: string;
	txtFKColumnDataType: string;
	txtFKColumnIsNullable: string;
	txtFKColumnName: string;
	txtFKReferencedColumnName: string;
	txtFKSchemaName: string;
	txtFKTableName: string;
	txtIsNullable: string;
}

export class CodeBuilderColumn
{
	blnIsComputed: boolean;
	blnIsIdentity: boolean;
	blnNullableFilter: boolean;
	dteLastUpdated: Date;
	guCodeBuilderTableId: string;
	intColumnFilterType: number;
	intMaxCharacterLength: number;
	intSQLFilterOperator: number;
	rowguid: string;
	txtColumnDefault: string;
	txtColumnName: string;
	txtComments: string;
	txtDataType: string;
	txtFKColumnDataType: string;
	txtFKColumnIsNullable: string;
	txtFKColumnName: string;
	txtFKReferencedColumnName: string;
	txtFKSchemaName: string;
	txtFKTableName: string;
	txtIsNullable: string;
}

export namespace CodeBuilderColumn
{
	export enum enColumnFilterType
	{
		None,
		TableFilterSingle,
		TableFilterMultiple,
		ForeignKeyFilterSingle,
		ForeignKeyFilterMultiple
	}
}

export namespace CodeBuilderColumn
{
	export enum enColumnSQLFilterOperator
	{
		None,
		Equals,
		NotEqualTo,
		Like,
		LessThan,
		GreaterThan
	}
}

