<div class="app-user-column-set-list-container">
  <table mat-table #tblAppPageColumns [dataSource]="datasource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="txtUserName">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnSetTH" mat-sort-header> App User </th>
      <td mat-cell *matCellDef="let appUserColumnSet" class="appUserColumnSetTD appUserColumnSetUserName"> {{appUserColumnSet.txtFirstName}} {{appUserColumnSet.txtLastName}}</td>
    </ng-container>

    <ng-container matColumnDef="txtPageName">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnSetTH" mat-sort-header> Page Name </th>
      <td mat-cell *matCellDef="let appUserColumnSet" class="appUserColumnSetTD appUserColumnSetName"> {{appUserColumnSet.txtPageName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtColumnSetName">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnSetTH" mat-sort-header> Column Set Name </th>
      <td mat-cell *matCellDef="let appUserColumnSet" class="appUserColumnSetTD appUserColumnSetName"> {{appUserColumnSet.txtName}} </td>
    </ng-container>

    <ng-container matColumnDef="blnCurrentView">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnSetTH" mat-sort-header> Current View </th>
      <td mat-cell *matCellDef="let appUserColumnSet" class="appUserColumnSetTD appUserColumnSetCurrentView"> {{appUserColumnSet.blnCurrentView}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnSetTH" mat-sort-header> Date Created </th>
      <td mat-cell *matCellDef="let appUserColumnSet" class="appUserColumnSetTD appPageDateCreated"> {{appUserColumnSet.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditUserColumnSet">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnSetTH appUserColumnSetEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let appUserColumnSet" class="appUserColumnSetTD appUserColumnSetEdit">
        <button class="edit-final-inspection-item-button" type="button" mat-flat-button color="primary" (click)="editAppUserColumnSet(appUserColumnSet)">Edit</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedUserColumnSets"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedUserColumnSets;">
    </tr>

  </table>
  <div class="no-stock-items" *ngIf="datasource == null || datasource.data.length == 0"><h7>There are no app pages</h7></div>
</div>


