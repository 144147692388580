import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { dtoHaulingCompanyDriver } from '../../_shared/business-objects/dto-hauling-company-driver.bo';
import { HaulingCompanyDriver } from '../../_shared/business-objects/hauling-company-driver.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoHaulingCompanyDriverService } from '../../_shared/services/dto-hauling-company-driver.service';
import { FormService } from '../../_shared/services/form.service';
import { HaulingCompanyDriverService } from '../../_shared/services/hauling-company-driver.service';
import { HaulingCompanyService } from '../../_shared/services/hauling-company.service';
import { MobilePlantCategoryService } from '../../_shared/services/mobile-plant-category.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-hauling-company-driver-list',
  templateUrl: './hauling-company-driver-list.component.html',
  styleUrls: ['./hauling-company-driver-list.component.css']
})
export class HaulingCompanyDriverListComponent implements OnInit {

  @ViewChild('tblHaulingCompanyDriver', { static: false }) tblHaulingCompanyDriver: MatTable<dtoHaulingCompanyDriver>;

  @Input() datasource: MatTableDataSource<dtoHaulingCompanyDriver>;
  @Output() editHaulingCompanyDriverEvent = new EventEmitter<dtoHaulingCompanyDriver>();
  @Output() addHaulingCompanyDriverEvent = new EventEmitter<HaulingCompanyDriver>();
  @Output() addHaulingCompanyDriverItemsEvent = new EventEmitter<dtoHaulingCompanyDriver>();
  @Output() updatedHaulingCompanyDriverEvent = new EventEmitter <dtoHaulingCompanyDriver>();

  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() columnListId: string;

  form: FormGroup;

  loadedComponent: boolean = false;

  haulingCompanyDriver: HaulingCompanyDriver;
  haulingCompanyDriverExpanded: dtoHaulingCompanyDriver;

  selectedRow: string = "";


  displayedColumns: string[] = ['dteDateCreated', 'txtHaulingCompanyName', 'txtFirstName', 'txtLastName', 'txtNickName', 'txtComments', 'blnActive', 'blnDefaultDriver', 'btnSetAsDefaultDriver', 'btnEditHaulingCompanyDriver', 'btnRemoveHaulingCompanyDriver'];


  constructor(private dtoHaulingCompanyDriverService: dtoHaulingCompanyDriverService, private haulingCompanyDriverService: HaulingCompanyDriverService, private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private route: ActivatedRoute, private formService: FormService
    , private dialog: MatDialog, private mobilePlantCategoryService: MobilePlantCategoryService, private haulingCompanyService: HaulingCompanyService
    ) {

    if (this.columnBarPage == null || this.columnBarPage == "") {
      this.columnBarPage = "Loading Dockets";
    }

    if (this.columnBarContainer == null || this.columnBarContainer == "") {
      this.columnBarContainer = "haulingCompanyDriverContainer";
    }


  }

  ngOnInit(): void {

    if (this.columnListId == null) {
      this.columnListId = "columnListId";
    }

    this.loadedComponent = true;


  }


  updatedComponentColumnsEvent(columns: string[]) {

    this.displayedColumns = columns;

  }

  onTabChange(e) {

  }

  async setAsDefaultDriver(haulingCompanyDriver: dtoHaulingCompanyDriver, index: number) {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guHaulingCompanyId", haulingCompanyDriver.guHaulingCompanyId));
    aParamList.push(aParam);

    let haulingDrivers: HaulingCompanyDriver[] = await this.haulingCompanyDriverService.getHaulingCompanyDriverParamArrayPromise(aParamList);
    haulingDrivers.forEach(async hd => {
      hd.blnDefaultDriver = false;
      await this.haulingCompanyDriverService.updateHaulingCompanyDriverPromise(hd);

    });

    let haulingDriver: HaulingCompanyDriver = await this.haulingCompanyDriverService.getHaulingCompanyDriverPromise(haulingCompanyDriver.rowguid);
    haulingDriver.blnDefaultDriver = true;

    haulingDriver = await this.haulingCompanyDriverService.updateHaulingCompanyDriverPromise(haulingDriver);

    let driver: dtoHaulingCompanyDriver = await this.dtoHaulingCompanyDriverService.getdtoHaulingCompanyDriverPromise(haulingCompanyDriver.rowguid);

    this.datasource.data.splice(index, 1, driver);

    this.tblHaulingCompanyDriver.renderRows();

    this.alertService.openSnackBarDefault("Driver " + haulingCompanyDriver.txtFirstName + ' ' + haulingCompanyDriver.txtLastName + " is set as the default driver");

    this.rowColorPropagation(haulingCompanyDriver.rowguid);

  }



  async editHaulingCompanyDriver(haulingCompanyDriver: dtoHaulingCompanyDriver) {
    this.editHaulingCompanyDriverEvent.emit(haulingCompanyDriver);

    this.rowColorPropagation(haulingCompanyDriver.rowguid);

  }

  async removeHaulingCompanyDriver(haulingCompanyDriver: dtoHaulingCompanyDriver, index: number) {
    let removed: boolean = await this.haulingCompanyDriverService.deleteHaulingCompanyDriverPromise(haulingCompanyDriver.rowguid);
    if (removed == true) {
      this.datasource.data.splice(index, 1);
      this.tblHaulingCompanyDriver.renderRows();

      this.alertService.openSnackBarDefault("Driver " + haulingCompanyDriver.txtFirstName + ' ' + haulingCompanyDriver.txtLastName + " has been removed");
    }

  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      window.event.stopPropagation();
    }
  }

  setRowColor(haulingCompanyDriverId: string) {

    //console.log("in set row color: " + haulingCompanyDriverId);

    if (this.selectedRow == "" || haulingCompanyDriverId != this.selectedRow) {
      this.selectedRow = haulingCompanyDriverId;
      return;
    }

    if (haulingCompanyDriverId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }

  }



}
