<div class="royalty-rate-create-container">
  <div class="royalty-rate-create-form-container">
    <form class="royalty-rate-create-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="header-row-1">
            <div class="search-option-row-1">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
                <mat-label>Date Created</mat-label>
                <input id="dteDateCreated" class="date-input date-created" #dateCreated matInput [matDatepicker]="pickerdatecreated" formControlName="dteDateCreated">
                <mat-datepicker-toggle matSuffix [for]="pickerdatecreated"></mat-datepicker-toggle>
                <mat-datepicker #pickerdatecreated></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="search-column-2">
              <mat-form-field class="example-full-width header-button" style="width: 500px !important" appearance="fill">
                <mat-select class="royalty-rate-poles" formControlName="guPoleId" id="guPoleId" placeholder="Pole" #pole>
                  <mat-option class="pole">
                    <ngx-mat-select-search placeholderLabel="i.e. 12.5 ....." [formControl]="polesFilterCtrl" noEntriesFoundLabel="There are no poles" ngDefaultControl></ngx-mat-select-search>
                  </mat-option>
                  <mat-option class="poles" *ngFor="let pole of filteredPoles | async" [value]="pole.rowguid">
                    {{pole.intLength}} / {{pole.intKn}} {{pole.blnQV ? 'Q/V' : ''}} {{pole.blnSubPole ? 'Sub' : ''}} {{pole.blnPlus ? '+' : ''}} {{pole.blnRegal ? 'Regal' : ''}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>


          <div class="header-row-4">
            <div class="search-option-row-4">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
                <mat-label>Expiry Date</mat-label>
                <input id="dteExpiry" class="date-input expiry-date" #dateInspected matInput [matDatepicker]="pickerexpiry" formControlName="dteExpiry">
                <mat-datepicker-toggle matSuffix [for]="pickerexpiry"></mat-datepicker-toggle>
                <mat-datepicker #pickerexpiry></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field appearance="fill">
                <mat-label>Durability Class</mat-label>
                <mat-select formControlName="intDurabilityClass">
                  <mat-option *ngFor="let durabilityClass of durabilityClasses" [value]="durabilityClass.intValue">{{durabilityClass.txtValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 100px !important" appearance="fill">
                <mat-label>Royalty Rate</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 785" value="" type="number" formControlName="fltRoyaltyRate" (keyup.enter)="createRoyaltyRate('createandadd')">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 100px !important" appearance="fill">
                <mat-label>CHH Royalty Rate</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 785" value="" type="number" formControlName="fltCHHRoyaltyRate" (keyup.enter)="createRoyaltyRate('createandadd')">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 500px !important" appearance="fill">
              <mat-label>Comments</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Load 1" value="" type="text" id="txtComments" formControlName="txtComments">
            </mat-form-field>
          </div>


          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-checkbox class="example-margin" formControlName="chkCurrent" [value]="true">Current</mat-checkbox>
            </div>
          </div>


          <div class="action-button-container">
            <button class="action-button" type="button" mat-flat-button color="primary" (click)="createRoyaltyRate('create')">Create Royalty Rate</button>
            <button class="action-button" type="button" mat-flat-button color="primary" (click)="createRoyaltyRate('createandadd')">Create and Add Royalty Rate</button>
            <button class="action-button" type="button" mat-flat-button color="primary" (click)="cancelRoyaltyRate()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="spinner-container">
  <mat-card [@displaySpinner]="transitionProducts ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showSpinner">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
