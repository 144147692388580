import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { dtoStockItemDisplay } from '../business-objects/dto-stock-item-display.bo';
import { SQLParamArray } from '../business-objects/sql-param-array';

@Injectable()
export class dtoStockItemDisplayService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		this._baseUrl = baseUrl;
	}

	getdtoStockItemDisplay(dtoStockItemDisplayId: string) {
		return this.http.get<dtoStockItemDisplay>(this._baseUrl + 'api/dtoStockItemDisplay/' + dtoStockItemDisplayId);
	}

	getAlldtoStockItemDisplays() {
		return this.http.get<dtoStockItemDisplay[]>(this._baseUrl + 'api/dtoStockItemDisplay/GetAlldtoStockItemDisplays/');
	}

  getAlldtoStockItemDisplayParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoStockItemDisplay[]>(this._baseUrl + 'api/dtoStockItemDisplay/GetBoardTallyRecordParamArray/', aParam);
  }

	//getAlldtodtoStockItemDisplays() {
	//	return this.http.get<dtodtoStockItemDisplay[]>(this._baseUrl + 'api/dtoStockItemDisplay/GetAlldtoStockItemDisplays/');
	//}

	createdtoStockItemDisplay(dtoStockItemDisplay: dtoStockItemDisplay) {
		return this.http.post<dtoStockItemDisplay>(this._baseUrl + 'api/dtoStockItemDisplay/CreatedtoStockItemDisplay/', dtoStockItemDisplay);
	}

	updatedtoStockItemDisplay(dtoStockItemDisplay: dtoStockItemDisplay) {
		return this.http.post<dtoStockItemDisplay>(this._baseUrl + 'api/dtoStockItemDisplay/UpdatedtoStockItemDisplay/', dtoStockItemDisplay);
  }

  async getdtoStockItemDisplayParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<dtoStockItemDisplay[]>(resolve => {
      this.getAlldtoStockItemDisplayParamArray(aParam).subscribe(boardTallyRecords => {
        resolve(boardTallyRecords);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

}
