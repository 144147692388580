import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { LoadingDocket } from '../business-objects/loading-docket.bo';
import { SharedService } from './shared.service';
import { dtoLoadingDocket } from '../business-objects/dto-loading-docket.bo';
import { dtoTPPoleInspectionLoadingDocket } from '../business-objects/dto-tppole-inspection-loading-docket.bo';

@Injectable()
export class LoadingDocketService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private sharedService: SharedService) {
		this._baseUrl = baseUrl;
	}

	getLoadingDocket(loadingDocketId: string) {
		return this.http.get<LoadingDocket>(this._baseUrl + 'api/LoadingDocket/' + loadingDocketId);
	}

	getLoadingDocketParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<LoadingDocket[]>(this._baseUrl + 'api/LoadingDocket/GetLoadingDocketsParamArray/', aParam);
	}

	getAllLoadingDockets() {
		return this.http.get<LoadingDocket[]>(this._baseUrl + 'api/LoadingDocket/GetAllLoadingDockets/');
	}

	//getAlldtoLoadingDockets() {
	//	return this.http.get<dtoLoadingDocket[]>(this._baseUrl + 'api/LoadingDocket/GetAllLoadingDockets/');
	//}

	createLoadingDocket(loadingDocket: LoadingDocket) {
		return this.http.post<LoadingDocket>(this._baseUrl + 'api/LoadingDocket/CreateLoadingDocket/', loadingDocket);
	}

	updateLoadingDocket(loadingDocket: LoadingDocket) {
		return this.http.put<LoadingDocket>(this._baseUrl + 'api/LoadingDocket/UpdateLoadingDocket/', loadingDocket);
	}

  deleteLoadingDocket(loadingDocketId: string) {
    return this.http.delete(this._baseUrl + 'api/LoadingDocket/DeleteLoadingDocket/' + loadingDocketId);
  }

	async getLoadingDocketPromise(loadingDocketId: string) {
		return new Promise<LoadingDocket>(resolve => {
			this.getLoadingDocket(loadingDocketId).subscribe(loadingDocket => {
				resolve(loadingDocket);
			}, err => {
				this.alertService.openSnackBarError("Error getting LoadingDocket records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getLoadingDocketParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<LoadingDocket[]>(resolve => {
			this.getLoadingDocketParamArray(aParam).subscribe(loadingDockets => {
				resolve(loadingDockets);
			}, err => {
				this.alertService.openSnackBarError("Error getting LoadingDocket records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async updateLoadingDocketPromise(loadingDocket: LoadingDocket) {
		return new Promise<LoadingDocket>(resolve => {
			this.updateLoadingDocket(loadingDocket).subscribe(loadingDocket => {
				resolve(loadingDocket);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating LoadingDocket record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

  async deleteLoadingDocketPromise(loadingDocketId: string) {
    return new Promise<boolean>(resolve => {
      this.deleteLoadingDocket(loadingDocketId).subscribe(loadingDocket => {
        resolve(true);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error deleting LoadingDocket record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(false);
      });
    });
  }

	async createLoadingDocketPromise(loadingDocket: LoadingDocket) {
		return new Promise<LoadingDocket>(resolve => {
			this.createLoadingDocket(loadingDocket).subscribe(loadingDocket => {
				resolve(loadingDocket);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating LoadingDocket record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
  }

  async downloadLoadingDockect(loadingDockets: dtoTPPoleInspectionLoadingDocket[]) {

    return new Promise(resolve => {
      let path: string = this.sharedService.PrintServerURI;
      path = "https://localhost:44358/";
      console.log("in downloadLoadingDockect", loadingDockets);
      this.http.post(path + 'api/Print/DownloadLoadingDocket/', loadingDockets, { responseType: 'blob' }).subscribe(pdf => {
        let mimeType: string = 'application/pdf';
        let blob: any = new Blob([pdf], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        //console.log("opening widow");
        window.open(url);
        resolve(pdf);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

}
