import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { Settings } from '../business-objects/settings.bo';

@Injectable()
export class SettingsService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getSettings(settingsId: string) {
		return this.http.get<Settings>(this._baseUrl + 'api/Settings/' + settingsId);
	}

	getSettingsParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<Settings[]>(this._baseUrl + 'api/Settings/GetSettingsParamArray/', aParam);
	}

	getAllSettingss() {
		return this.http.get<Settings[]>(this._baseUrl + 'api/Settings/GetAllSettings/');
	}

	//getAlldtoSettingss() {
	//	return this.http.get<dtoSettings[]>(this._baseUrl + 'api/Settings/GetAllSettings/');
	//}

	createSettings(settings: Settings) {
		return this.http.post<Settings>(this._baseUrl + 'api/Settings/CreateSettings/', settings);
	}

	updateSettings(settings: Settings) {
		return this.http.put<Settings>(this._baseUrl + 'api/Settings/UpdateSettings/', settings);
	}

	deleteSettings(settingsId: string) {
		return this.http.delete(this._baseUrl + 'api/Settings/DeleteSettings/' + settingsId);
	}

	async getSettingsPromise(settingsId: string) {
		return new Promise<Settings>(resolve => {
			this.getSettings(settingsId).subscribe(settings => {
				resolve(settings);
			}, err => {
				this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getSettingsByName(settingsName: string) {
    return new Promise<Settings>(resolve => {
      this.http.get<Settings>(this._baseUrl + 'api/Settings/BySettingName/' + settingsName).subscribe(settings => {
        resolve(settings);
      }, err => {
        this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }


	async getSettingsParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<Settings[]>(resolve => {
			this.getSettingsParamArray(aParam).subscribe(settings => {
				resolve(settings);
			}, err => {
				this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateSettingsPromise(settings: Settings) {
		return new Promise<Settings>(resolve => {
			this.updateSettings(settings).subscribe(settings => {
				resolve(settings);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating Settings record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createSettingsPromise(settings: Settings) {
		return new Promise<Settings>(resolve => {
			this.createSettings(settings).subscribe(settings => {
				resolve(settings);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating Settings record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deleteSettingsPromise(settingsId: string) {
		return new Promise<boolean>(resolve => {
			this.deleteSettings(settingsId).subscribe(settings => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing Settings record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
