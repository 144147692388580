import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BoardTallyRecord } from '../business-objects/board-tally-record.bo';
import { dtoStockItem } from '../business-objects/dto-stock-item.bo';
import { SharedService } from './shared.service';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { BoardTallyRecordHistory } from '../business-objects/board-tally-record-history.bo';

@Injectable()
export class BoardTallyRecordService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService) {
    this._baseUrl = baseUrl;
  }

  getBoardTallyRecords(gGeoStockLocationId: string) {
    return this.http.get(this._baseUrl + 'api/boardtallyrecord/GetTop50ByGeoLocation/' + gGeoStockLocationId);
  }

  getBoardTallyRecord(rowguid: string) {
    return this.http.get<BoardTallyRecord>(this._baseUrl + 'api/boardtallyrecord/' + rowguid);
  }

  getdtoStockItem(boardTallyRecordId: string) {
    return this.http.get<dtoStockItem>(this._baseUrl + 'api/boardtallyrecord/GetdtoStockItem/' + boardTallyRecordId);
  }

  getBoardTallyRecordParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<BoardTallyRecord[]>(this._baseUrl + 'api/boardtallyrecord/GetBoardTallyRecordParamArray/', aParam);
  }

  getStockItem(identifier, sGeoStockLocationId: string) {
    return this.http.get<dtoStockItem[]>(this._baseUrl + 'api/boardtallyrecord/GetStockItem/' + identifier + '/' + sGeoStockLocationId);
  }

  getStockItemParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoStockItem[]>(this._baseUrl + 'api/boardtallyrecord/GetStockItemParamArray/', aParam);
  }

  getNonDeliveredStockItemParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoStockItem[]>(this._baseUrl + 'api/boardtallyrecord/GetNonDeliveredStockItemParamArray/', aParam);
  }

  getStockItemsByProductCode(gProductCodeId, gGeoStockLocationId: string) {
    return this.http.get<dtoStockItem[]>(this._baseUrl + 'api/boardtallyrecord/GetStockItemsByProductCode/' + gProductCodeId + '/' + gGeoStockLocationId);
  }

  createBoardTallyRecord(employeeid: string, createHistory: boolean, eChangeReason: BoardTallyRecordHistory.enChangeReason, stockitem: BoardTallyRecord) {
    return this.http.post<BoardTallyRecord>(this._baseUrl + 'api/boardtallyrecord/' + employeeid + '/' + createHistory + '/' + eChangeReason, stockitem);
  }

  updateBoardTallyRecord(employeeid: string, createHistory: boolean, eChangeReason: BoardTallyRecordHistory.enChangeReason, stockitem: BoardTallyRecord) {
    return this.http.put<BoardTallyRecord>(this._baseUrl + 'api/boardtallyrecord/' + employeeid + '/' + createHistory + '/' + eChangeReason, stockitem);
  }

  async getBoardTallyRecordPromise(rowguid: string) {
    return new Promise<BoardTallyRecord>(resolve => {
      this.getBoardTallyRecord(rowguid).subscribe(boardTallyRecord => {
        resolve(boardTallyRecord);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  async getBoardTallyRecordParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<BoardTallyRecord[]>(resolve => {
      this.getBoardTallyRecordParamArray(aParam).subscribe(boardTallyRecords => {
        resolve(boardTallyRecords);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  async getdtoStockItemPromise(stockItemId: string) {
    return new Promise<dtoStockItem>(resolve => {
      this.getdtoStockItem(stockItemId).subscribe(stockItem => {
        resolve(stockItem);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  async getStockItemParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<dtoStockItem[]>(resolve => {
      this.getStockItemParamArray(aParam).subscribe(boardTallyRecord => {
        resolve(boardTallyRecord);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  async updateBoardTallyRecordPromise(employeeid: string, createHistory: boolean, eChangeReason: BoardTallyRecordHistory.enChangeReason, stockitem: BoardTallyRecord) {
    return new Promise<BoardTallyRecord>(resolve => {
      this.updateBoardTallyRecord(employeeid, createHistory, eChangeReason, stockitem).subscribe(boardTallyRecord => {
        resolve(boardTallyRecord);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  async createBoardTallyRecordPromise(employeeid: string, createHistory: boolean, eChangeReason: BoardTallyRecordHistory.enChangeReason, stockitem: BoardTallyRecord) {
    return new Promise<BoardTallyRecord>(resolve => {
      this.createBoardTallyRecord(employeeid, createHistory, eChangeReason, stockitem).subscribe(boardTallyRecord => {
        resolve(boardTallyRecord);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }


  mapBoardTallyRecordFromDTO(stockItem: dtoStockItem, excludeProperties: Array<string>): BoardTallyRecord {
    var boardTallyRecord: BoardTallyRecord = new BoardTallyRecord();

    for (let [key, value] of Object.entries(stockItem)) {
      if (excludeProperties.indexOf(key) == -1) {
        console.log("Property " + key + ",  val: " + value);

        boardTallyRecord[key] = value;

        if (value == this.sharedService.EmptyGuid) {
          boardTallyRecord[key] = null;
        }

        if (value == this.sharedService.MinDate) {
          console.log("date min value");
          boardTallyRecord[key] = null;
        }

      }
    }
    return boardTallyRecord;
  }


  //getBoardTallyRecords() {
  //  return this.http.get(this._baseUrl + 'api/boardtallyrecord');
  //}

}  
