<form class="appuser-geostocklocation-create-form" [formGroup]="form">
  <div class="create-appuser-geostocklocation-container">
    <div class="header-container">
      <div class="header-row-1">
        <h1>Create App User Geo Stock Location</h1>
      </div>
    </div>
    <div class="main-container">
      <div class="main-row-1">
        <mat-form-field appearance="fill">
          <mat-label>App User</mat-label>
          <mat-select [formControl]="guAppUserId" [(ngModel)]="selectedAppUsers" (selectionChange)="getRemainingLocations(selectedAppUsers)">
            <mat-option *ngFor="let appUser of appUsers" [value]="appUser.rowguid">{{appUser.txtFirstName}} {{appUser.txtLastName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="main-row-2">
        <mat-form-field appearance="fill">
          <mat-label>Geo Location</mat-label>
          <mat-select [formControl]="guGeoStockLocationId" multiple [(ngModel)]="selectedGeoStockLocations">
            <mat-option *ngFor="let geoStockLocation of geoStockLocations" [value]="geoStockLocation.rowguid">{{geoStockLocation.txtLocationName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="main-row-3">
          <mat-slide-toggle [formControl]="blnDefaultLocation">Default Location</mat-slide-toggle>
      </div>
    </div>

    <div class="footer-container">
      <button class="stocktakeButton" mat-flat-button color="primary" (click)="addAppUserToLoctions();">Add App User to Locations</button>&nbsp;
    </div>

  </div>
</form>
