import { ViewChild } from '@angular/core';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HaulingCompanyDriverSelectionComponent } from '../../hauling/hauling-company-driver-selection/hauling-company-driver-selection.component';
import { MobilePlantEquipmentSelectionComponent } from '../../mobile-plant/mobile-plant-equipment-selection/mobile-plant-equipment-selection.component';
import { HaulingCompanyDriver } from '../../_shared/business-objects/hauling-company-driver.bo';
import { HaulingCompany } from '../../_shared/business-objects/hauling-company.bo';
import { MobilePlantEquipment } from '../../_shared/business-objects/mobile-plant-equipment.bo';
import { SharedService } from '../../_shared/services/shared.service';
import { LoadingDocketListComponent } from '../loading-docket-list/loading-docket-list.component';

@Component({
  selector: 'app-truck-trailer-selection-dialog',
  templateUrl: './truck-trailer-selection-dialog.component.html',
  styleUrls: ['./truck-trailer-selection-dialog.component.css']
})
export class TruckTrailerSelectionDialogComponent implements OnInit {

  @ViewChild('appHaulingCompanyDriverSelection') appHaulingCompanyDriverSelection: HaulingCompanyDriverSelectionComponent
  @ViewChild('appMobilePlantEquipmentSelection') appMobilePlantEquipmentSelection: MobilePlantEquipmentSelectionComponent
  equipmentCategoryId: string = "";
  haulingCompany: HaulingCompany;
  haulingCompanyDriver: HaulingCompanyDriver;

  selectedHaulingCompany: HaulingCompany;
  selectedHaulingCompanyDriver: HaulingCompanyDriver;
  trailerEquipment: MobilePlantEquipment;
  haulingCompanyId: string;
  chhVehicle: boolean = true;
  independentSelection: boolean = false;


  constructor(private dialogRef: MatDialogRef<LoadingDocketListComponent>, private sharedService: SharedService, @Inject(MAT_DIALOG_DATA) private data: { equipmentCategoryId: string, haulingCompany: HaulingCompany, haulingCompanyDriver: HaulingCompanyDriver, trailerEquipment: MobilePlantEquipment, independentSelection: boolean }) {

  }

  ngOnInit(): void {

    this.equipmentCategoryId = this.data.equipmentCategoryId;
    this.haulingCompany = this.data.haulingCompany;
    this.selectedHaulingCompany = this.data.haulingCompany;

    this.haulingCompanyDriver = this.data.haulingCompanyDriver;
    this.selectedHaulingCompanyDriver = this.data.haulingCompanyDriver;
    this.trailerEquipment = this.data.trailerEquipment;
    this.independentSelection = this.data.independentSelection;

    if (this.data.haulingCompany != null) {
      this.haulingCompanyId = this.data.haulingCompany.rowguid;
      this.chhVehicle = this.data.haulingCompany.blnCHHVehicle;
    }


  }

  haulingCompanySelection(haulingCompany: HaulingCompany[]) {


    if (haulingCompany != null && haulingCompany.length > 0) {
      this.haulingCompany = haulingCompany[0];
      this.haulingCompanyId = this.haulingCompany.rowguid;
      this.appHaulingCompanyDriverSelection.haulingCompanyId = this.haulingCompanyId;
      this.appHaulingCompanyDriverSelection.selectedHaulingCompanyDriver = null;
      this.appHaulingCompanyDriverSelection.loadData();
    }

    if (haulingCompany == null) {
      this.haulingCompanyDriver = null;
    }

  }

  haulingCompanyDriverSelection(haulingCompanyDriver: HaulingCompanyDriver[]) {


    //console.log("haulingCompanyDriverSelection: ", haulingCompanyDriver);

    if (haulingCompanyDriver != null && haulingCompanyDriver.length > 0) {
      this.haulingCompanyDriver = haulingCompanyDriver[0];
      //console.log("this.haulingCompanyDriver: ", this.haulingCompanyDriver);
    }

  }

  equipmentSelection(equipment: MobilePlantEquipment[]) {

    //console.log("equipment: ", equipment);
    if (equipment != null && equipment.length > 0) {
      if (equipment[0].rowguid == this.sharedService.EmptyGuid) {
        this.trailerEquipment = null;
      } else {
        this.trailerEquipment = equipment[0];
      }
    }

  }

  chhVehicleSelection(val: boolean) {
    if (val == true) {
      this.appMobilePlantEquipmentSelection.chhVehicles = true;
      this.appMobilePlantEquipmentSelection.loadData();
    }

    if (val == false) {
      this.appMobilePlantEquipmentSelection.chhVehicles = false;
      this.appMobilePlantEquipmentSelection.loadData();
    }

  }


  save() {

    if (this.trailerEquipment != null && (this.trailerEquipment.rowguid == this.sharedService.EmptyGuid || this.trailerEquipment.rowguid == null)) {
      this.trailerEquipment = null;
    }

    if (this.haulingCompanyDriver != null && (this.haulingCompanyDriver.rowguid == this.sharedService.EmptyGuid || this.haulingCompanyDriver.rowguid == null)) {
      this.haulingCompanyDriver = null;
    }

    if (this.haulingCompany != null && (this.haulingCompany.rowguid == this.sharedService.EmptyGuid || this.haulingCompany.rowguid == null)) {
      this.haulingCompany = null;
      this.haulingCompanyDriver = null;
    }

    this.dialogRef.close({ status: "Saved", haulingCompany: this.haulingCompany, haulingCompanyDriver: this.haulingCompanyDriver, trailerEquipment: this.trailerEquipment });

  }

  cancel() {

    this.dialogRef.close({ status: "Cancel", haulingCompany: null, haulingCompanyDriver: null, trailerEquipment: null });

  }

}
