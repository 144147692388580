import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dtoDeliveredSalesBySection } from '../../../../_shared/business-objects/dto-delivered-sales-by-section.bo';
import { dtoDeliveredSalesBySectionService } from '../../../../_shared/services/dto-delivered-sales-by-section.service';
import { SharedService } from '../../../../_shared/services/shared.service';

export enum enSummaryReportType { LastWeek, LastMonth, LastQuarter, LastYear }


@Component({
  selector: 'app-delivered-sales-summary',
  templateUrl: './delivered-sales-summary.component.html',
  styleUrl: './delivered-sales-summary.component.scss'
})
export class DeliveredSalesSummaryComponent {



  @Input() deliveredSalesSummary: dtoDeliveredSalesBySection[];

  printMode: boolean;

  enSummaryType = enSummaryReportType;

  reportType: number[] = [];
  reportTypeSummary: number[] = [];

  salesSummaryFromDate: string;
  salesSummaryToDate: string;
  salesSummaryYear: number;
  salesSummaryMonth: number;
  salesSummaryWeek: number;


  salesSummaryTotal: number;

  currentMonth: number;
  currentYear: number;
  currentDay: string;

  constructor(private dtoDeliveredSalesBySectionService: dtoDeliveredSalesBySectionService, private route: ActivatedRoute, private sharedService: SharedService) {

  }

  ngOnInit(): void {

    this.currentMonth = new Date().getMonth() + 1;
    this.currentYear = new Date().getFullYear();
    let day = Number(new Date().toLocaleDateString('en-AU', { day: 'numeric' }));
    this.currentDay = day.toString() + this.sharedService.nth(day);

  }

  async runTasks(email: boolean) {



  }

  createPDF() {

  }


}
