import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { BoardTallyRecordMachineRun } from '../business-objects/board-tally-record-machine-run.bo';

@Injectable()
export class BoardTallyRecordMachineRunService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getBoardTallyRecordMachineRun(boardTallyRecordMachineRunId: string) {
    return this.http.get<BoardTallyRecordMachineRun>(this._baseUrl + 'api/BoardTallyRecordMachineRun/' + boardTallyRecordMachineRunId);
  }

  getBoardTallyRecordMachineRunParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<BoardTallyRecordMachineRun[]>(this._baseUrl + 'api/BoardTallyRecordMachineRun/GetBoardTallyRecordMachineRunsParamArray/', aParam);
  }

  getAllBoardTallyRecordMachineRuns() {
    return this.http.get<BoardTallyRecordMachineRun[]>(this._baseUrl + 'api/BoardTallyRecordMachineRun/GetAllBoardTallyRecordMachineRuns/');
  }

  //getAlldtoBoardTallyRecordMachineRuns() {
  //	return this.http.get<dtoBoardTallyRecordMachineRun[]>(this._baseUrl + 'api/BoardTallyRecordMachineRun/GetAllBoardTallyRecordMachineRuns/');
  //}

  createBoardTallyRecordMachineRun(boardTallyRecordMachineRun: BoardTallyRecordMachineRun) {
    return this.http.post<BoardTallyRecordMachineRun>(this._baseUrl + 'api/BoardTallyRecordMachineRun/CreateBoardTallyRecordMachineRun/', boardTallyRecordMachineRun);
  }

  updateBoardTallyRecordMachineRun(boardTallyRecordMachineRun: BoardTallyRecordMachineRun) {
    return this.http.put<BoardTallyRecordMachineRun>(this._baseUrl + 'api/BoardTallyRecordMachineRun/UpdateBoardTallyRecordMachineRun/', boardTallyRecordMachineRun);
  }

  async getBoardTallyRecordMachineRunPromise(boardTallyRecordMachineRunId: string) {
    return new Promise<BoardTallyRecordMachineRun>(resolve => {
      this.getBoardTallyRecordMachineRun(boardTallyRecordMachineRunId).subscribe(boardTallyRecordMachineRun => {
        resolve(boardTallyRecordMachineRun);
      }, err => {
        this.alertService.openSnackBarError("Error getting BoardTallyRecordMachineRun records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getBoardTallyRecordMachineRunParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<BoardTallyRecordMachineRun[]>(resolve => {
      this.getBoardTallyRecordMachineRunParamArray(aParam).subscribe(boardTallyRecordMachineRuns => {
        resolve(boardTallyRecordMachineRuns);
      }, err => {
        this.alertService.openSnackBarError("Error getting BoardTallyRecordMachineRun records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async updateBoardTallyRecordMachineRunPromise(boardTallyRecordMachineRun: BoardTallyRecordMachineRun) {
    return new Promise<BoardTallyRecordMachineRun>(resolve => {
      this.updateBoardTallyRecordMachineRun(boardTallyRecordMachineRun).subscribe(boardTallyRecordMachineRun => {
        resolve(boardTallyRecordMachineRun);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating BoardTallyRecordMachineRun record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async createBoardTallyRecordMachineRunPromise(boardTallyRecordMachineRun: BoardTallyRecordMachineRun) {
    return new Promise<BoardTallyRecordMachineRun>(resolve => {
      this.createBoardTallyRecordMachineRun(boardTallyRecordMachineRun).subscribe(boardTallyRecordMachineRun => {
        resolve(boardTallyRecordMachineRun);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating BoardTallyRecordMachineRun record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

}
