import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AuthService } from './account/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';

  //lastKnownScrollPosition = 0;
  //ticking = false;

  constructor(private authService: AuthService, private router: Router) {

    //this.authService.isAuthenticated
    //  .pipe(
    //    take(1),
    //    map((isAuthenticated: boolean) => {
    //      if (!isAuthenticated) {
    //        this.router.navigate(['/login']);
    //        return false;
    //      }
    //      return true;
    //    }));

    //document.addEventListener("scroll", (event) => {
    //  this.lastKnownScrollPosition = window.scrollY;

    //  if (!this.ticking) {
    //    window.requestAnimationFrame(() => {
    //      this.doSomething(this.lastKnownScrollPosition);
    //      this.ticking = false;
    //    });

    //    this.ticking = true;
    //  }
    //});


  }

  //doSomething(scrollPos) {
  //  // Do something with the scroll position
  //  console.log("scrollPos: " + scrollPos);
  //}



}
