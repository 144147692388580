
export interface FileAttachment {
  constructor(fileName: string, filePath: string, contentType: string, bytes: (String | ArrayBuffer), byteArr: Blob, size: number, fileDate: Date)
  fileName: string;
  filePath: string;
  contentType: string;
  bytes: (String | ArrayBuffer);
  byteArr: Blob;
  size: number;
  fileDate: Date;
}

export class FileAttachment {
  constructor(fileName: string, filePath: string, contentType: string, bytes: (String | ArrayBuffer), byteArr: Blob, size: number, fileDate: Date) {
    this.fileName = fileName;
    this.filePath = filePath;
    this.contentType = contentType;
    this.bytes = bytes;
    this.byteArr = byteArr;
    this.size = size;
    this.fileDate = fileDate;
  }

  fileName: string;
  filePath: string;
  contentType: string;
  bytes: (String | ArrayBuffer);
  byteArr: Blob;
 size: number;
  fileDate: Date;

  sizeMB(): number {
    return Number((this.size / 1048576).toFixed(2));
  }

  sizeKB(): number {
    return Number((this.size / 1024).toFixed(2));
  }

  base64WithoutPrefix() {
    let base64Index: number = this.bytes.toString().indexOf("base64,");
    let base64WithoutPrefix: string;
    if (base64Index > -1) {
      base64WithoutPrefix = this.bytes.toString().substring(base64Index + 7, this.bytes.toString().length);
    }
    if (base64Index == -1) {
      base64WithoutPrefix = this.bytes.toString();
    }
    return base64WithoutPrefix;
  }

  dataPrefix() {
    let prefix: string = "data:" + this.contentType + ";base64,";
    return prefix;
  }

  dataURI() {
    return this.dataPrefix() + this.base64WithoutPrefix()
  }


  truncatedName(maxCharacters: number): string {

    if (this.fileName.length <= maxCharacters) {
      return this.fileName;
    }

    let extIndex: number = this.fileName.indexOf(".");
    if (extIndex == -1) {
      return this.fileName;
    }

    let extension: string = this.fileName.substring(extIndex, this.fileName.length);
    return this.fileName.substring(0, maxCharacters - extension.length) + extension;

  }
}
