import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { AppPage } from '../business-objects/app-page.bo';

@Injectable()
export class AppPageService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getAppPage(appPageId: string) {
    return this.http.get<AppPage>(this._baseUrl + 'api/AppPage/' + appPageId);
  }

  getAppPageParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<AppPage[]>(this._baseUrl + 'api/AppPage/GetAppPagesParamArray/', aParam);
  }

  getAllAppPages() {
    return this.http.get<AppPage[]>(this._baseUrl + 'api/AppPage/GetAllAppPages/');
  }

  //getAlldtoAppPages() {
  //	return this.http.get<dtoAppPage[]>(this._baseUrl + 'api/AppPage/GetAllAppPages/');
  //}

  createAppPage(appPage: AppPage) {
    return this.http.post<AppPage>(this._baseUrl + 'api/AppPage/CreateAppPage/', appPage);
  }

  updateAppPage(appPage: AppPage) {
    return this.http.put<AppPage>(this._baseUrl + 'api/AppPage/UpdateAppPage/', appPage);
  }

  async getAppPageAllPromise() {
    return new Promise<AppPage[]>(resolve => {
      this.getAllAppPages().subscribe(appPage => {
        resolve(appPage);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error getting AppPage records", "Close", "center", "bottom", 4000, true, err.error.message);
        resolve(null);
      });
    });
  }

  async getAppPagePromise(appPageId: string) {
    return new Promise<AppPage>(resolve => {
      this.getAppPage(appPageId).subscribe(appPage => {
        resolve(appPage);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error getting AppPage record", "Close", "center", "bottom", 4000, true, err.error.message);
        resolve(null);
      });
    });
  }

  async getAppPageParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<AppPage[]>(resolve => {
      this.getAppPageParamArray(aParam).subscribe(appPages => {
        resolve(appPages);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async updateAppPagePromise(appPage: AppPage) {
    return new Promise<AppPage>(resolve => {
      this.updateAppPage(appPage).subscribe(appPage => {
        resolve(appPage);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating AppPage record", "Close", "center", "bottom", 4000, true, err.error.message);
        resolve(null);
      });
    });
  }

  async createAppPagePromise(appPage) {
    return new Promise<AppPage>(resolve => {
      this.createAppPage(appPage).subscribe(appPage => {
        resolve(appPage);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating AppPage record", "Close", "center", "bottom", 4000, true, err.error.message);
        resolve(null);
      });
    });
  }

}
