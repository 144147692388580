import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { DeliveryDocketCustomerDeliveryContact } from '../business-objects/delivery-docket-customer-delivery-contact.bo';

@Injectable()
export class DeliveryDocketCustomerDeliveryContactService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getDeliveryDocketCustomerDeliveryContact(deliveryDocketCustomerDeliveryContactId: string) {
		return this.http.get<DeliveryDocketCustomerDeliveryContact>(this._baseUrl + 'api/DeliveryDocketCustomerDeliveryContact/' + deliveryDocketCustomerDeliveryContactId);
	}

	getDeliveryDocketCustomerDeliveryContactParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<DeliveryDocketCustomerDeliveryContact[]>(this._baseUrl + 'api/DeliveryDocketCustomerDeliveryContact/GetDeliveryDocketCustomerDeliveryContactsParamArray/', aParam);
	}

	getAllDeliveryDocketCustomerDeliveryContacts() {
		return this.http.get<DeliveryDocketCustomerDeliveryContact[]>(this._baseUrl + 'api/DeliveryDocketCustomerDeliveryContact/GetAllDeliveryDocketCustomerDeliveryContacts/');
	}

	//getAlldtoDeliveryDocketCustomerDeliveryContacts() {
	//	return this.http.get<dtoDeliveryDocketCustomerDeliveryContact[]>(this._baseUrl + 'api/DeliveryDocketCustomerDeliveryContact/GetAllDeliveryDocketCustomerDeliveryContacts/');
	//}

	createDeliveryDocketCustomerDeliveryContact(deliveryDocketCustomerDeliveryContact: DeliveryDocketCustomerDeliveryContact) {
		return this.http.post<DeliveryDocketCustomerDeliveryContact>(this._baseUrl + 'api/DeliveryDocketCustomerDeliveryContact/CreateDeliveryDocketCustomerDeliveryContact/', deliveryDocketCustomerDeliveryContact);
	}

	updateDeliveryDocketCustomerDeliveryContact(deliveryDocketCustomerDeliveryContact: DeliveryDocketCustomerDeliveryContact) {
		return this.http.put<DeliveryDocketCustomerDeliveryContact>(this._baseUrl + 'api/DeliveryDocketCustomerDeliveryContact/UpdateDeliveryDocketCustomerDeliveryContact/', deliveryDocketCustomerDeliveryContact);
	}

	deleteDeliveryDocketCustomerDeliveryContact(deliveryDocketCustomerDeliveryContactId: string) {
		return this.http.delete(this._baseUrl + 'api/DeliveryDocketCustomerDeliveryContact/DeleteDeliveryDocketCustomerDeliveryContact/' + deliveryDocketCustomerDeliveryContactId);
	}

	async getDeliveryDocketCustomerDeliveryContactPromise(deliveryDocketCustomerDeliveryContactId: string) {
		return new Promise<DeliveryDocketCustomerDeliveryContact>(resolve => {
			this.getDeliveryDocketCustomerDeliveryContact(deliveryDocketCustomerDeliveryContactId).subscribe(deliveryDocketCustomerDeliveryContact => {
				resolve(deliveryDocketCustomerDeliveryContact);
			}, err => {
				this.alertService.openSnackBarError("Error getting DeliveryDocketCustomerDeliveryContact records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<DeliveryDocketCustomerDeliveryContact[]>(resolve => {
			this.getDeliveryDocketCustomerDeliveryContactParamArray(aParam).subscribe(deliveryDocketCustomerDeliveryContacts => {
				resolve(deliveryDocketCustomerDeliveryContacts);
			}, err => {
				this.alertService.openSnackBarError("Error getting DeliveryDocketCustomerDeliveryContact records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateDeliveryDocketCustomerDeliveryContactPromise(deliveryDocketCustomerDeliveryContact: DeliveryDocketCustomerDeliveryContact) {
		return new Promise<DeliveryDocketCustomerDeliveryContact>(resolve => {
			this.updateDeliveryDocketCustomerDeliveryContact(deliveryDocketCustomerDeliveryContact).subscribe(deliveryDocketCustomerDeliveryContact => {
				resolve(deliveryDocketCustomerDeliveryContact);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating DeliveryDocketCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createDeliveryDocketCustomerDeliveryContactPromise(deliveryDocketCustomerDeliveryContact: DeliveryDocketCustomerDeliveryContact) {
		return new Promise<DeliveryDocketCustomerDeliveryContact>(resolve => {
			this.createDeliveryDocketCustomerDeliveryContact(deliveryDocketCustomerDeliveryContact).subscribe(deliveryDocketCustomerDeliveryContact => {
				resolve(deliveryDocketCustomerDeliveryContact);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating DeliveryDocketCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deleteDeliveryDocketCustomerDeliveryContactPromise(deliveryDocketCustomerDeliveryContactId: string) {
		return new Promise<boolean>(resolve => {
			this.deleteDeliveryDocketCustomerDeliveryContact(deliveryDocketCustomerDeliveryContactId).subscribe(deliveryDocketCustomerDeliveryContact => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing DeliveryDocketCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}