import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../account/auth.service';
import { AlertService } from '../../_shared/services/alert.service';
import { ReckonSettingsService } from '../../_shared/services/reckon.service';

@Component({
  selector: 'app-user-files-list',
  templateUrl: './user-files-list.component.html',
  styleUrl: './user-files-list.component.scss'
})
export class UserFilesListComponent implements OnInit {

  userFiles: string = "";

  accessToken: string = "";
  subKey: string = "";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private reckonSettingsService: ReckonSettingsService
    , private route: ActivatedRoute, private authService: AuthService) {

  }

ngOnInit(): void {


  this.loadData();

  }

  async loadData() {
    this.accessToken = localStorage.getItem("rkAccessToken");
    this.subKey = await this.reckonSettingsService.getReckonPrimarySubscriptionKeyPromise();
    //this.subKey = await this.reckonSettingsService.getReckonSecondarySubscriptionKeyPromise()

    let gotFiles: boolean = await this.getUserFiles();

    console.log("gotFiles: ", gotFiles);

  }


  getUserFiles() {
    return new Promise<boolean>(resolve => {
      this.http.get('https://api.reckon.com/RAH/v2/userfiles', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken,
          'Ocp-Apim-Subscription-Key': this.subKey
        },
        responseType: 'json'
      }).subscribe(res => {
        console.log("res", res);
        resolve(true);
      }, err => {
        console.log("err", err);
        resolve(false);
      });
    });
  }

}
