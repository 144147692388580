import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoLoadingDocket } from '../business-objects/dto-loading-docket.bo';
import { dtoLoadingDocketItem } from '../business-objects/dto-loading-docket-item.bo';
import { SharedService } from './shared.service';

@Injectable()
export class dtoLoadingDocketService
{

	public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private sharedService: SharedService) {
		this._baseUrl = baseUrl;
	}

	getdtoLoadingDocket(dtoLoadingDocketId: string) {
		return this.http.get<dtoLoadingDocket>(this._baseUrl + 'api/dtoLoadingDocket/' + dtoLoadingDocketId);
	}

	getdtoLoadingDocketParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoLoadingDocket[]>(this._baseUrl + 'api/dtoLoadingDocket/GetdtoLoadingDocketsParamArray/', aParam);
	}

  getLastdtoLoadingDockets(top: number, yardSection: number = -1) {
    return this.http.get<dtoLoadingDocket[]>(this._baseUrl + 'api/dtoLoadingDocket/GetLastdtoLoadingDockets/' + top + '/' + yardSection);
  }

	getAlldtoLoadingDockets() {
		return this.http.get<dtoLoadingDocket[]>(this._baseUrl + 'api/dtoLoadingDocket/GetAlldtoLoadingDockets/');
	}

	//getAlldtodtoLoadingDockets() {
	//	return this.http.get<dtodtoLoadingDocket[]>(this._baseUrl + 'api/dtoLoadingDocket/GetAlldtoLoadingDockets/');
	//}

	createdtoLoadingDocket(dtoLoadingDocket: dtoLoadingDocket) {
		return this.http.post<dtoLoadingDocket>(this._baseUrl + 'api/dtoLoadingDocket/CreatedtoLoadingDocket/', dtoLoadingDocket);
	}

	updatedtoLoadingDocket(dtoLoadingDocket: dtoLoadingDocket) {
		return this.http.put<dtoLoadingDocket>(this._baseUrl + 'api/dtoLoadingDocket/UpdatedtoLoadingDocket/', dtoLoadingDocket);
	}

	async getdtoLoadingDocketPromise(dtoLoadingDocketId: string) {
		return new Promise<dtoLoadingDocket>(resolve => {
			this.getdtoLoadingDocket(dtoLoadingDocketId).subscribe(dtoLoadingDocket => {
				resolve(dtoLoadingDocket);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoLoadingDocket records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getdtoLoadingDocketParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoLoadingDocket[]>(resolve => {
			this.getdtoLoadingDocketParamArray(aParam).subscribe(dtoLoadingDockets => {
				resolve(dtoLoadingDockets);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoLoadingDocket records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

    async getdtoLoadingDocketPromiseById(loadingDocketId: string) {
      return new Promise<dtoLoadingDocket>(resolve => {
        resolve(this.getdtoLoadingDocketByField("rowguid", loadingDocketId));
      });
    }

    async getdtoLoadingDocketPromiseByDocketNo(loadingDocketNo: string) {
      return new Promise<dtoLoadingDocket>(resolve => {
        resolve(this.getdtoLoadingDocketByField("intDocketNo", loadingDocketNo));
      });
    }
  
    private async getdtoLoadingDocketByField(field: string, value: string) {
      return new Promise<dtoLoadingDocket>(resolve => {
        let aParamList: SQLParamArray[][] = [];
        let aParam: SQLParamArray[] = [];

        aParam.push(new SQLParamArray(field, value));
        aParamList.push(aParam);

        this.getdtoLoadingDocketParamArray(aParamList).subscribe(dtoLoadingDockets => {
          if (dtoLoadingDockets != null && dtoLoadingDockets.length > 0) {
            resolve(dtoLoadingDockets[0]);
          }
          resolve(null);
        }, err => {
          this.alertService.openSnackBarError("Error getting dtoLoadingDocket records", "Close", "center", "bottom", 4000, true, err.error);
          resolve(null);
        });
      });
    }

  async getLastdtoLoadingDocketsPromise(top: number, yardSection: number = -1) {
    return new Promise<dtoLoadingDocket[]>(resolve => {
      this.getLastdtoLoadingDockets(top, yardSection).subscribe(dtoLoadingDockets => {
        resolve(dtoLoadingDockets);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoLoadingDocket records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

	async updatedtoLoadingDocketPromise(dtoLoadingDocket: dtoLoadingDocket) {
		return new Promise<dtoLoadingDocket>(resolve => {
			this.updatedtoLoadingDocket(dtoLoadingDocket).subscribe(dtoLoadingDocket => {
				resolve(dtoLoadingDocket);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoLoadingDocket record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createdtoLoadingDocketPromise(dtoLoadingDocket: dtoLoadingDocket) {
		return new Promise<dtoLoadingDocket>(resolve => {
			this.createdtoLoadingDocket(dtoLoadingDocket).subscribe(dtoLoadingDocket => {
				resolve(dtoLoadingDocket);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoLoadingDocket record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
  }

  async downloadLoadingDockect(loadingDocketItems: dtoLoadingDocketItem[], type: string) {

    return new Promise(resolve => {
      let path: string = this.sharedService.PrintServerURI;
      //path = "https://localhost:44358/";
      //console.log("in downloadLoadingDockect", loadingDocketItems);
      this.http.post(path + 'api/PrintLoadingDocket/DownloadLoadingDocket/' + type, loadingDocketItems, { responseType: 'blob' }).subscribe(pdf => {
        let mimeType: string = 'application/pdf';
        let blob: any = new Blob([pdf], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        //console.log("opening widow");
        window.open(url);
        resolve(pdf);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

}
