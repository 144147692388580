import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { Orders } from '../business-objects/orders.bo';

@Injectable()
export class OrdersService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getOrders(ordersId: string) {
		return this.http.get<Orders>(this._baseUrl + 'api/Orders/' + ordersId);
	}

	getOrdersParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<Orders[]>(this._baseUrl + 'api/Orders/GetOrdersParamArray/', aParam);
	}

	getAllOrderss() {
		return this.http.get<Orders[]>(this._baseUrl + 'api/Orders/GetAllOrders/');
	}

	//getAlldtoOrderss() {
	//	return this.http.get<dtoOrders[]>(this._baseUrl + 'api/Orders/GetAllOrders/');
	//}

	createOrders(orders: Orders) {
		return this.http.post<Orders>(this._baseUrl + 'api/Orders/CreateOrders/', orders);
	}

	updateOrders(orders: Orders) {
		return this.http.put<Orders>(this._baseUrl + 'api/Orders/UpdateOrders/', orders);
	}

  async getOrdersPromise(ordersId: string) {
		return new Promise<Orders>(resolve => {
      this.getOrders(ordersId).subscribe(orders => {
				resolve(orders);
			}, err => {
				this.alertService.openSnackBarError("Error getting Orders records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getOrdersParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<Orders[]>(resolve => {
			this.getOrdersParamArray(aParam).subscribe(orders => {
				resolve(orders);
			}, err => {
				this.alertService.openSnackBarError("Error getting Orders records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async updateOrdersPromise(orders: Orders) {
		return new Promise<Orders>(resolve => {
			this.updateOrders(orders).subscribe(orders => {
				resolve(orders);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating Orders record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createOrdersPromise(orders) {
		return new Promise<Orders>(resolve => {
			this.createOrders(orders).subscribe(orders => {
				resolve(orders);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating Orders record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
  }


  async getCustomerOrders(customerId: string, status: Orders.enStatus[]) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (status != null) {
      aParam = [];
        for (let i = 0; i <= status.length - 1; i++) {
          aParam.push(new SQLParamArray("intStatus", status[i].toString()))
        }
      aParamList.push(aParam);
    }

    aParam = [];
    aParam.push(new SQLParamArray("guCustomerId", customerId))
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("intType", "0"))
    aParamList.push(aParam);

    return this.getOrdersParamArrayPromise(aParamList);
  }


  async getCustomerOrdersActiveAndPartial(customerId: string) {
    let statuses: Orders.enStatus[] = [];
    statuses.push(Orders.enStatus.Active);
    statuses.push(Orders.enStatus.PartialComplete);

    return this.getCustomerOrders(customerId, statuses);

  }


}
