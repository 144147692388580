import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TrimmingItemReasonForChange } from '../../_shared/business-objects/trimming-item-reason-for-change.bo';

import { PoleTrimmingItemReasonForChange } from '../../_shared/business-objects/pole-trimming-item-reason-for-change.bo';

@Injectable()
export class PoleTrimingItemReasonForChangeService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getSetTrimmingItemReasonForChanges(gPoleTrimmingItemId) {
    return this.http.get <PoleTrimmingItemReasonForChange[]>(this._baseUrl + 'api/PoleTrimingItemReasonForChange/GetSetTrimmingItemReasonForChanges/' + gPoleTrimmingItemId);
  }

  setTrimmingItemReasonForChanges(aTrimmingItemReasonForChange: PoleTrimmingItemReasonForChange[]) {

    return this.http.post(this._baseUrl + 'api/PoleTrimingItemReasonForChange/AddMultipleItems/', aTrimmingItemReasonForChange);

  }

  updateTrimmingItemReasonForChanges(gPoleTrimmingItemId, aTrimmingItemReasonForChange: PoleTrimmingItemReasonForChange[]) {
    return this.http.post(this._baseUrl + 'api/PoleTrimingItemReasonForChange/UpdateMultipleItems/' + gPoleTrimmingItemId, aTrimmingItemReasonForChange);

  }
}  
