<div class="app-page-list-container">
  <table mat-table #tblAppPages [dataSource]="datasource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="intApp">
      <th mat-header-cell *matHeaderCellDef class="appPageTH" mat-sort-header> App </th>
      <td mat-cell *matCellDef="let appPage" class="appPageTD appPageApp"> {{appName(appPage.intApp)}} </td>
    </ng-container>

    <ng-container matColumnDef="txtPageName">
      <th mat-header-cell *matHeaderCellDef class="appPageTH" mat-sort-header> Page Name </th>
      <td mat-cell *matCellDef="let appPage" class="appPageTD appPageName"> {{appPage.txtPageName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtPageURL">
      <th mat-header-cell *matHeaderCellDef class="appPageTH" mat-sort-header> Page URL </th>
      <td mat-cell *matCellDef="let appPage" class="appPageTD appPageURL"> {{appPage.txtPageURL}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="appPageTH" mat-sort-header> Date Created </th>
      <td mat-cell *matCellDef="let appPage" class="appPageTD appPageDateCreated"> {{appPage.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditappPage">
      <th mat-header-cell *matHeaderCellDef class="appPageTH appPageEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let appPage" class="appPageTD appPageEdit">
        <button class="edit-final-inspection-item-button" type="button" mat-flat-button color="primary" (click)="editAppPage(appPage)">Edit</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedAppPages"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedAppPages;">
    </tr>

  </table>
  <div class="no-stock-items" *ngIf="datasource == null || datasource.data.length == 0"><h7>There are no app pages</h7></div>
</div>


