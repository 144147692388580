import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { BrowserModule } from '@angular/platform-browser';


//import { , MatIconModule, MatDialogModule, MatButtonModule, MatTableModule, MatPaginatorModule, MatSortModule, MatTabsModule, MatCheckboxModule, MatToolbarModule, MatCard, MatCardModule, MatFormField, MatFormFieldModule, MatInputModule } from '@angular/material';


@NgModule({
  imports: [
    //MatTabsModule, MatNativeDateModule, MatSnackBarModule, MatIconModule, MatDialogModule, MatSortModule, MatToolbarModule, MatPaginatorModule
    MatDividerModule, MatSliderModule, MatSelectModule, MatRadioModule, MatGridListModule, MatMenuModule, MatTabsModule, MatListModule, MatButtonToggleModule, 
    MatDatepickerModule, MatProgressBarModule, MatRippleModule, MatChipsModule,
    MatProgressSpinnerModule, MatButtonModule,
    MatTableModule, MatDialogModule,
    MatCheckboxModule,
    MatCardModule, MatFormFieldModule, MatProgressSpinnerModule, MatInputModule, MatSnackBarModule,
    MatNativeDateModule, MatExpansionModule, MatIconModule, MatBadgeModule, MatSlideToggleModule, MatTooltipModule,
    MatPaginatorModule, MatSortModule, BrowserModule
  ],
  //declarations: [
  //  MatRipple
  //  ],
  exports: [
    //MatTabsModule, MatNativeDateModule, MatSnackBarModule, MatIconModule, MatDialogModule, MatSortModule, MatToolbarModule, MatPaginatorModule
    MatDividerModule, MatSliderModule, MatSelectModule, MatRadioModule, MatGridListModule, MatMenuModule, MatTabsModule, MatListModule, MatButtonToggleModule, 
    MatDatepickerModule, MatButtonToggleModule, MatProgressBarModule, MatRippleModule, MatChipsModule,
    MatProgressSpinnerModule, MatButtonModule,
    MatTableModule, MatDialogModule,
    MatCheckboxModule,
    MatCardModule, MatFormFieldModule, MatProgressSpinnerModule, MatInputModule, MatSnackBarModule,
    MatNativeDateModule, MatExpansionModule, MatIconModule, MatBadgeModule, MatSlideToggleModule, MatTooltipModule,
    MatPaginatorModule, MatSortModule, BrowserModule
 ],

})

export class CHHMaterialModule { }
