<div class="delivery-docket-list-container" id="deliveryDocketListContainer">

  <button class="" type="button" mat-flat-button color="primary" (click)="testPDF()" *ngIf="false">Test PDF</button>


  <app-app-user-column-set-bar [componentColumns]="displayedColumns" [pageName]="columnBarPage" [container]="columnBarContainer" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)" *ngIf="loadedComponent"></app-app-user-column-set-bar>

  <table mat-table #tblDeliveryDocket [dataSource]="datasource" multiTemplateDataRows class="mat-elevation-z8 delivery-docket-table" *ngIf="datasource != null">

    <ng-container matColumnDef="intDocketNo">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Docket No </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="docketNo deliveryDocketTD alignRight"> {{deliveryDocket.intDocketNo}} </td>
    </ng-container>

    <ng-container matColumnDef="intLoadingDocketNo">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Loading<br />Docket No </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD loadingDocketNo alignRight"> {{deliveryDocket.intLoadingDocketNo}} </td>
    </ng-container>

    <ng-container matColumnDef="dteExpectedLoadingDate">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Expected Loading Date </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD dateExpectedLoading alignRight"> {{deliveryDocket.dteExpectedLoadingDate | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dteExpectedDeliveryDate">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Expected Delivery Date </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD dateExpectedDelivery alignRight"> {{deliveryDocket.dteExpectedDeliveryDate | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtStatus">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"> Status </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD status"> {{deliveryDocket.txtStatus}} </td>
    </ng-container>


    <ng-container matColumnDef="dteStatusDate">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Status Date </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD statusDate alignRight"> {{deliveryDocket.dteStatusDate | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtHaulingCompanyName">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"> Hauling Company </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD haulingCompanyName"> {{deliveryDocket.txtHaulingCompanyName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtHaulingCompanyDriverName">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"> Driver </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD haulingCompanyDriverName">{{deliveryDocket.txtHaulingCompanyDriverFirstName}} {{deliveryDocket.txtHaulingCompanyDriverLastName}}</td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerLocation">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH deliveryDocketCustomerLocationTH"> Customer - Location </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD deliveryDocketCustomerLocation" [innerHtml]="deliveryDocket.txtCustomerLocation | safeHtml"></td>
    </ng-container>

    <ng-container matColumnDef="btnEditDeliveryAddress">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"> </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD editDeliveryAddress">
        <mat-icon matTooltip="Edit Delivery Address" class="edit-note-icon mouse-pointer" (click)="openDeliveryAddressDialog(deliveryDocket)">edit_note</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="txtOrderNo">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"> Order No </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD deliveryDocketOrderNo">
        <div class="delivery-docket-order-number">
          <div>{{deliveryDocket.txtOrderNo}}</div>
          <div><mat-icon matTooltip="Copy Order Number To Clipboard" class="help-icon small-icon" (click)="copyToClipboard(deliveryDocket.txtOrderNo.toString())">content_copy</mat-icon></div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dteCreatedDate">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Date Created </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD createdDate alignRight"> {{deliveryDocket.dteCreatedDate | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="fltDeliveryCharge">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"> Delivery Charge </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD deliveryCharge"> {{deliveryDocket.fltDeliveryCharge}} </td>
    </ng-container>

    <ng-container matColumnDef="fltEscort">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"> Escort </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD escort"> {{deliveryDocket.fltEscort}} </td>
    </ng-container>

    <ng-container matColumnDef="fltAdditionalKM">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"> Additional KM </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD additionalKM"> {{deliveryDocket.fltAdditionalKM}} </td>
    </ng-container>

    <ng-container matColumnDef="intLoadNo">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Load </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD loadNo alignRight"> {{deliveryDocket.intLoadNo}} </td>
    </ng-container>

    <ng-container matColumnDef="loadNoSeparator">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD loadNoSeparator"> {{deliveryDocket.intLoadNo != null ? 'of' : ''}} </td>
    </ng-container>

    <ng-container matColumnDef="intMaxLoads">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Loads </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD maxLoads"> {{deliveryDocket.intMaxLoads}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualM3">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Actual M3 </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD actualM3 alignRight"> {{deliveryDocket.fltActualM3 | number : '1.2-2' }} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualTonne">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Actual Tonne </th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD actualTonne alignRight"> {{deliveryDocket.fltActualTonne | number : '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD"><button class="edit-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="editDeliveryDocket(deliveryDocket)">Edit</button></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD"><button class="remove-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="removeDeliveryDocket(deliveryDocket.rowguid)">Remove</button></td>
    </ng-container>

    <ng-container matColumnDef="btnPrintDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD">
        <button class="print-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="printDeliveryDocket(deliveryDocket.rowguid)">Print</button>&nbsp;
        <button class="print-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="createDeliveryDocketPDF(deliveryDocket)">Download Delivery Docket</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnEmailDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD" #emailButtonTD>
        <div matTooltip="To enable the Eamil button you must be logged on to Office 365" [matTooltipDisabled]="ms_authenticated">
          <button class="email-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="emailDeliveryDocket(deliveryDocket, emailButtonTD, '', '', '')" [disabled]="!ms_authenticated">Email</button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsDeliveredDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD"><button class="set-as-delivered-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="setAsDeliveredDeliveryDocket(deliveryDocket, rowIdx)" [disabled]="deliveryDocket.intStatus == 2">Set as Delivered</button></td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsPickedUpDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD"><button class="set-as-picked-up-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="setAsPickedUpDeliveryDocket(deliveryDocket, rowIdx)" [disabled]="deliveryDocket.intStatus == 3">Set as Picked Up</button></td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsPickedUpAndEmailDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD" #pickedupAndEmailButtonTD><button class="set-as-picked-up-and-email-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="setAsPickedUpAndEmailDeliveryDocket(deliveryDocket, rowIdx, pickedupAndEmailButtonTD)" [disabled]="deliveryDocket.intStatus == 3">Set as Picked Up And Email</button></td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsReadyForDispatchDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD"><button class="set-as-ready-for-dispatch-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="setAsReadyForDispatchDeliveryDocket(deliveryDocket, rowIdx)" [disabled]="deliveryDocket.intStatus == 0">Set as Ready For Dispatch</button></td>
    </ng-container>

    <ng-container matColumnDef="btnAddDeliveryDocketItems">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD"><button class="add-delivery-docket-items-button" type="button" mat-flat-button color="primary" (click)="addDeliveryDocketItems(deliveryDocket, rowIdx)">Add / View Docket Items</button></td>
    </ng-container>

    <ng-container matColumnDef="btnDownloadDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD"><button class="add-download-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="downloadDeliveryDocket(deliveryDocket, rowIdx)">Download Delivery Docket</button></td>
    </ng-container>

    <ng-container matColumnDef="btnUpdateDeliveryRate">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD"><button class="update-delivery-rate-button" type="button" mat-flat-button color="primary" (click)="updateDeliveryRate(deliveryDocket, rowIdx)">Update Delivery Rate</button></td>
    </ng-container>

    <ng-container matColumnDef="btnOpenLoadingDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD"><button class="open-loading-docket-button" type="button" mat-flat-button color="primary" (click)="openLoadingDocket(deliveryDocket, rowIdx)" [disabled]="deliveryDocket.intLoadingDocketNo == null || deliveryDocket.intLoadingDocketNo == 0">Open Loading Docket</button></td>
    </ng-container>

    <ng-container matColumnDef="btnAddToNewLoadingDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD"><button class="add-to-new-loading-docket-button" type="button" mat-flat-button color="primary" (click)="addToNewLoadingDocket(deliveryDocket, rowIdx)">Add To New Loading Docket</button></td>
    </ng-container>

    <ng-container matColumnDef="btnAddToExistingLoadingDocket">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD"><button class="add-to-existing-loading-docket-button" type="button" mat-flat-button color="primary" (click)="addToExistingLoadingDocket(deliveryDocket, rowIdx)">Add To Existing Loading Docket</button></td>
    </ng-container>

    <ng-container matColumnDef="btnEmailAusgridCSV">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD" #ausgridEmailButtonTD><button class="email-ausgrid-csv-button" type="button" mat-flat-button color="primary" (click)="emailAusgridCSV(deliveryDocket, rowIdx, ausgridEmailButtonTD)">Email Ausgrid CSV</button></td>
    </ng-container>

    <ng-container matColumnDef="blnNextDelivery">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH">Next Delivery</th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD"><mat-checkbox class="example-margin" #nextDelivery id="blnNextDelivery{{rowIdx}}" (change)="setNextDelivery($event.checked, deliveryDocket)" [checked]="deliveryDocket.blnNextDelivery"></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="intInvoiceStatus">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH">Invoice Emailed</th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD">
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" #invoiceStatus (change)="setInvoiceStatus($event.value, deliveryDocket)">
          <mat-radio-button class="example-radio-button" [value]="0" [checked]="deliveryDocket.intInvoiceStatus == 0">N/A</mat-radio-button>
          <mat-radio-button class="example-radio-button" [value]="1" [checked]="deliveryDocket.intInvoiceStatus == 1">To Be Emailed</mat-radio-button>
          <mat-radio-button class="example-radio-button" [value]="2" [checked]="deliveryDocket.intInvoiceStatus == 2">Emailed</mat-radio-button>
        </mat-radio-group>
      </td>
    </ng-container>

    <ng-container matColumnDef="intInvoicePortalUploaded">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH">Invoice Uploaded</th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex;" class="deliveryDocketTD">
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" #invoicePortalUploaded (change)="setInvoiceUploaded($event.value, deliveryDocket)">
          <mat-radio-button class="example-radio-button" [value]="0" [checked]="deliveryDocket.intInvoicePortalUploaded == 0">N/A</mat-radio-button>
          <mat-radio-button class="example-radio-button" [value]="1" [checked]="deliveryDocket.intInvoicePortalUploaded == 1">To Be Uploaded</mat-radio-button>
          <mat-radio-button class="example-radio-button" [value]="2" [checked]="deliveryDocket.intInvoicePortalUploaded == 2">Uploaded</mat-radio-button>
        </mat-radio-group>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnViewDeliveryDocketOrderItems">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH" aria-label="row actions"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex" class="deliveryDocketTD">
        <button mat-icon-button aria-label="expand row" (click)="expandElement(deliveryDocket, rowIdx);$event.stopPropagation()">
          <mat-icon *ngIf="deliveryDocketExpanded !== deliveryDocket">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="deliveryDocketExpanded === deliveryDocket">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH" [attr.colspan]="displayedColumns.length"></th>
      <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex" class="deliveryDocketTD deliveryDocketExpandTD" [attr.colspan]="displayedColumns.length">
        <div [ngClass]="deliveryDocketExpanded == deliveryDocket ? 'example-element-detail example-element-detail-overflow' : 'example-element-detail'" [@detailExpand]="deliveryDocketExpanded == deliveryDocket ? 'expanded' : 'collapsed'">
          <div class="detail-view-container">
            <div class="final-view">
              <mat-tab-group color="accent" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event, rowIdx)" #tabDeliveryDocketsOrderItems>
                <mat-tab label="Order Items" class="tab-label order-item-list-tab">
                  <div class="delivery-docket-order-items-container">
                    <app-delivery-docket-order-item-list *ngIf="deliveryDocketExpanded == deliveryDocket" class="delivery-docket-order-item-list-app" #appdeliveryDocketOrderItemlist (removeDeliveryDocketOrderItemEvent)="removeDeliveryDocketOrderItem($event, deliveryDocket)" [datasource]="deliveryDocketOrderItems" [columnBarPage]="'Delivery Docket Order Items List'" [columnBarContainer]="columnBarContainer" [columnListId]="'columnList'+rowIdx" [appIndex]="rowIdx"
                                                         [deliveryDocketOrderItemQtyTotalInput]="deliveryDocketOrderItemQtyTotalInput"
                                                         [deliveryDocketOrderItemM3TotalInput]="deliveryDocketOrderItemM3TotalInput"
                                                         [deliveryDocketOrderItemActualM3TotalInput]="deliveryDocketOrderItemActualM3TotalInput"
                                                         [deliveryDocketOrderItemActualTonneTotalInput]="deliveryDocketOrderItemActualTonneTotalInput"></app-delivery-docket-order-item-list>
                  </div>
                </mat-tab>

                <mat-tab label="Linked Final Items" class="tab-label">
                  <div id="finalInspectionsContainer">
                    <app-delivery-docket-final-items-list *ngIf="deliveryDocketExpanded == deliveryDocket" #appLinkedInspecionList [datasource]="linkedFinalInspectionItems" [columnBarContainer]="columnBarContainer" [columnListId]="'columnList'+rowIdx" [appIndex]="rowIdx"
                                                          [finalInspectionItemActualM3TotalInput]="finalInspectionItemM3TotalInput"
                                                          [finalInspectionItemActualTonneTotalInput]="finalInspectionItemWeightTotalInput"></app-delivery-docket-final-items-list>
                  </div>
                </mat-tab>

                <mat-tab label="Linked Stock Items" class="tab-label">
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let deliveryDocket; let idx = dataIndex;" id="locationrow{{idx}}" (click)="expandElementAndSetColor(deliveryDocket,idx)" [ngClass]="deliveryDocket.rowguid == selectedRow ? 'activeTR' : ''"></tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" id="expandedRow" class="example-detail-row"></tr>

  </table>

</div>

<div class="delivery-docket-print-wrapper">
  <div id="deliveryDocketPDF" class="delivery-docket-print-container">
    <app-delivery-docket-print #deliveryDocketPrintApp [deliveryDocketIdInput]="deliveryDocketPDFId" [showTOCs]="true" [hideSignaturePad]="true"></app-delivery-docket-print>
  </div>
</div>
<app-terms-and-conditions class="terms-and-conditions-app" id="termsAndConditions" *ngIf="showTOCs"></app-terms-and-conditions>
<app-spinner-inline-tick #spinnerInlineTick [showSpinner]="showInlineSpinner" [showTick]="showTick" [diameter]="20" [inlineSpinnerId]="'inlineSpinnerId'" class="inline-spinner"></app-spinner-inline-tick>

<div class="final-inspection-print-wrapper">
  <div id="finalInspectionPDF" class="final-inspection-print-container">
    <app-final-inspection-item-print #finalInspectionItemPrintApp [finalInspectionIdInput]="finalInspectionPDFId" [printPDFMode]="true"></app-final-inspection-item-print>
  </div>
</div>
