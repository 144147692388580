import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoSlobStockSummary } from '../business-objects/dto-slob-stock-summary.bo';

@Injectable()
export class dtoSlobStockSummaryService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoSlobStockSummary(dtoSlobStockSummaryId: string) {
		return this.http.get<dtoSlobStockSummary>(this._baseUrl + 'api/dtoSlobStockSummary/' + dtoSlobStockSummaryId);
	}

	getdtoSlobStockSummaryParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoSlobStockSummary[]>(this._baseUrl + 'api/dtoSlobStockSummary/GetdtoSlobStockSummaryParamArray/', aParam);
	}

	getAlldtoSlobStockSummarys() {
		return this.http.get<dtoSlobStockSummary[]>(this._baseUrl + 'api/dtoSlobStockSummary/GetAlldtoSlobStockSummary/');
	}

	//getAlldtodtoSlobStockSummarys() {
	//	return this.http.get<dtodtoSlobStockSummary[]>(this._baseUrl + 'api/dtoSlobStockSummary/GetAlldtoSlobStockSummary/');
	//}

	createdtoSlobStockSummary(dtoSlobStockSummary: dtoSlobStockSummary) {
		return this.http.post<dtoSlobStockSummary>(this._baseUrl + 'api/dtoSlobStockSummary/CreatedtoSlobStockSummary/', dtoSlobStockSummary);
	}

	updatedtoSlobStockSummary(dtoSlobStockSummary: dtoSlobStockSummary) {
		return this.http.put<dtoSlobStockSummary>(this._baseUrl + 'api/dtoSlobStockSummary/UpdatedtoSlobStockSummary/', dtoSlobStockSummary);
	}

	deletedtoSlobStockSummary(dtoSlobStockSummaryId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoSlobStockSummary/DeletedtoSlobStockSummary/' + dtoSlobStockSummaryId);
	}

	async getdtoSlobStockSummaryPromise(dtoSlobStockSummaryId: string) {
		return new Promise<dtoSlobStockSummary>(resolve => {
			this.getdtoSlobStockSummary(dtoSlobStockSummaryId).subscribe(dtoSlobStockSummary => {
				resolve(dtoSlobStockSummary);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoSlobStockSummary records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoSlobStockSummaryParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoSlobStockSummary[]>(resolve => {
			this.getdtoSlobStockSummaryParamArray(aParam).subscribe(dtoSlobStockSummary => {
				resolve(dtoSlobStockSummary);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoSlobStockSummary records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoSlobStockSummaryPromise(dtoSlobStockSummary: dtoSlobStockSummary) {
		return new Promise<dtoSlobStockSummary>(resolve => {
			this.updatedtoSlobStockSummary(dtoSlobStockSummary).subscribe(dtoSlobStockSummary => {
				resolve(dtoSlobStockSummary);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoSlobStockSummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoSlobStockSummaryPromise(dtoSlobStockSummary: dtoSlobStockSummary) {
		return new Promise<dtoSlobStockSummary>(resolve => {
			this.createdtoSlobStockSummary(dtoSlobStockSummary).subscribe(dtoSlobStockSummary => {
				resolve(dtoSlobStockSummary);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoSlobStockSummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoSlobStockSummaryPromise(dtoSlobStockSummaryId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoSlobStockSummary(dtoSlobStockSummaryId).subscribe(dtoSlobStockSummary => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoSlobStockSummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}