import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { dtoTPChargeItem } from '../../_shared/business-objects/dto-tpcharge-item.bo';
import { dtoTPPoleInspectionItem } from '../../_shared/business-objects/dto-tppole-inspection-item.bo';
import { TPChargeItem } from '../../_shared/business-objects/tpcharge-item.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoTPChargeItemService } from '../../_shared/services/dto-tpcharge-item.service';
import { dtoTPPoleInspectionItemService } from '../../_shared/services/dto-tppole-inspection-item.service';
import { TPChargeItemService } from '../../_shared/services/tpcharge-item.service';

@Component({
  selector: 'app-consecutive-number-list',
  templateUrl: './consecutive-number-list.component.html',
  styleUrl: './consecutive-number-list.component.scss'
})
export class ConsecutiveNumberListComponent {

  @ViewChild('chargeNumber') chargeNumber: MatInput;
  @ViewChild('treatmentYear') treatmentYear: MatInput;

  form: FormGroup;

  chargeNo: number;
  chargeYear: number;
  chargeMonth: number;

  tpChargeItems: dtoTPChargeItem[];
  selectedTPChargeItem: dtoTPChargeItem;

  columnHoverId: string = "";

  constructor(private dialogRef: MatDialogRef<ConsecutiveNumberListComponent>, @Inject(MAT_DIALOG_DATA) private data: { chargeNo: number, chargeYear: number }
    , private formBuilder: FormBuilder, private tpChargeItemService: dtoTPChargeItemService, private chargeItemService: TPChargeItemService
    , private alertService: AlertService, private dtoTPPoleInspectionItemService: dtoTPPoleInspectionItemService) {

    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close();
    });

    dialogRef.disableClose = true;

    this.chargeNo = data.chargeNo;
    this.chargeYear = data.chargeYear;

    console.log("this.chargeNo", this.chargeNo);

  }


  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close({ status: "Cancel" });
  }

  ngOnInit(): void {
      
    this.form = this.formBuilder.group({
      txtChargeNumber: [''],
      txtTreatmentYear: ['']
    });

    //this.chargeNumber.value = this.chargeNo;
    //this.treatmentYear.value = this.chargeYear;
    console.log("this.chargeNumber", this.chargeNumber);

    this.loadData();

  }

  async loadData() {
    //this.form.controls['txtChargeNumber'].setValue(this.chargeNo);
    //this.form.controls['txtTreatmentYear'].setValue(this.chargeYear);

    this.tpChargeItems = await this.tpChargeItemService.getdtoTPChargeItemsPromise(this.chargeNo, this.chargeYear);

    if (this.tpChargeItems != null && this.tpChargeItems.length > 0) {
      this.chargeMonth = this.tpChargeItems[0].intChargeMonth;
      this.tpChargeItems = this.tpChargeItems.sort((a, b) => { return a.intConsecutiveNo - b.intConsecutiveNo });
    }
    console.log("tpChargeItems", this.tpChargeItems);

  }

  finalInspectionItemTransferred(val: boolean) {
    this.dialogRef.close({ status: "Updated", chargeItem: this.selectedTPChargeItem });
  }

  finalInspectionItemTransferCancelled(val: boolean) {
    this.dialogRef.close({ status: "Cancel", chargeItem: null });
  }

  selectConsecutiveNumber(tpChargeItem: dtoTPChargeItem) {
    if (tpChargeItem.guTPPoleInspectionItemId != null && tpChargeItem.guTPPoleInspectionItemId != "") {
      return;
    }

    for (let i = 0; i <= this.tpChargeItems.length - 1; i++) {
      this.tpChargeItems[i].guSelectedColumnId = "";
    }

    this.selectedTPChargeItem = tpChargeItem;
    tpChargeItem.guSelectedColumnId = "tempvalue";
  }

  async unlinkFromFinal(tpChargeItem: dtoTPChargeItem) {
    if (tpChargeItem == null) {
      return;
    }

    //Check Delivery Docket.
    let finalItem: dtoTPPoleInspectionItem = await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemPromise(tpChargeItem.guTPPoleInspectionItemId);
    if (finalItem.guDeliveryDocketOrderItemId != null) {
      await this.alertService.openSnackBarCustomDefaultPromise("Unlink Final Item", "This Charge Item is already on a Delivery Docket and cannot be unlinked.", "OK");
      return;
    }

    let msg: string = await this.alertService.openSnackBarCustomDefaultShowCancel("Unlink Final Item", "Are you sure you want to unlink this Charge Item?", "Yes", "No");
    if (msg != "Yes") {
      return;
    }

    let chargeItem: TPChargeItem = await this.chargeItemService.getTPChargeItemPromise(tpChargeItem.rowguid);
    chargeItem.guTPPoleInspectionItemId = null;
    await this.chargeItemService.updateTPChargeItemPromise(chargeItem);
    //console.log("chargeItem: ", chargeItem);

    this.loadData();
  }


  rowEnter(columnId: string) {
    this.columnHoverId = columnId;
    //console.log("row enter");
  }

  rowLeave() {
    this.columnHoverId = "";
    //console.log("row leave");
  }

  save() {
    this.dialogRef.close({ status: "Save", chargeItem: this.selectedTPChargeItem });

  }

  cancel() {
    this.selectedTPChargeItem = null;
    this.dialogRef.close({ status: "Cancel", chargeItem: null });

  }

}
