import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { NavigationService } from '../_shared/services/navigation.service';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router, private navigationService: NavigationService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticated
      .pipe(
        take(1),                              
        map((isAuthenticated: boolean) => {
          if (!isAuthenticated) {
            //console.log("getReturnURL: " + this.navigationService.getReturnURL())
            this.router.navigate(['login'], { queryParams: { returnUrl: this.navigationService.getReturnURL() } });
            //this.router.navigate(['/login']);
            return false;
          }
          return true;
        }));
      
  }
}
