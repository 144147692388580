<div class="date-selector-container">
  <mat-card class="demo-inline-calendar-card">
    <mat-calendar #datePicker (selectedChange)="setDate($event)"></mat-calendar>
  </mat-card>
  <div class="button-container">
    <div class="no-date">
      <button class="save-button" type="button" mat-flat-button color="primary" (click)="saveNoDate()">No Date</button>
    </div>
    <div class="cancel">
      <button class="cancel-button" type="button" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
