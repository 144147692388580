import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { LoadingLocation } from '../business-objects/loading-location.bo';

@Injectable()
export class LoadingLocationService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getLoadingLocation(loadingLocationId: string) {
		return this.http.get<LoadingLocation>(this._baseUrl + 'api/LoadingLocation/' + loadingLocationId);
	}

	getLoadingLocationParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<LoadingLocation[]>(this._baseUrl + 'api/LoadingLocation/GetLoadingLocationsParamArray/', aParam);
	}

	getAllLoadingLocations() {
		return this.http.get<LoadingLocation[]>(this._baseUrl + 'api/LoadingLocation/GetAllLoadingLocations/');
	}

	//getAlldtoLoadingLocations() {
	//	return this.http.get<dtoLoadingLocation[]>(this._baseUrl + 'api/LoadingLocation/GetAllLoadingLocations/');
	//}

	createLoadingLocation(loadingLocation: LoadingLocation) {
		return this.http.post<LoadingLocation>(this._baseUrl + 'api/LoadingLocation/CreateLoadingLocation/', loadingLocation);
	}

	updateLoadingLocation(loadingLocation: LoadingLocation) {
		return this.http.put<LoadingLocation>(this._baseUrl + 'api/LoadingLocation/UpdateLoadingLocation/', loadingLocation);
	}

	async getLoadingLocationPromise(loadingLocationId: string) {
		return new Promise<LoadingLocation>(resolve => {
			this.getLoadingLocation(loadingLocationId).subscribe(loadingLocation => {
				resolve(loadingLocation);
			}, err => {
				this.alertService.openSnackBarError("Error getting LoadingLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getLoadingLocationParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<LoadingLocation[]>(resolve => {
			this.getLoadingLocationParamArray(aParam).subscribe(loadingLocations => {
				resolve(loadingLocations);
			}, err => {
				this.alertService.openSnackBarError("Error getting LoadingLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateLoadingLocationPromise(loadingLocation: LoadingLocation) {
		return new Promise<LoadingLocation>(resolve => {
			this.updateLoadingLocation(loadingLocation).subscribe(loadingLocation => {
				resolve(loadingLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating LoadingLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createLoadingLocationPromise(loadingLocation: LoadingLocation) {
		return new Promise<LoadingLocation>(resolve => {
			this.createLoadingLocation(loadingLocation).subscribe(loadingLocation => {
				resolve(loadingLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating LoadingLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}