import { Component } from '@angular/core';

@Component({
  selector: 'app-species-update-dialog',
  templateUrl: './species-update-dialog.component.html',
  styleUrl: './species-update-dialog.component.scss'
})
export class SpeciesUpdateDialogComponent {

}
