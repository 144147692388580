import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PoleInspectionItem } from '../business-objects/pole-inspection-item.bo';

@Injectable()
export class PoleInspectionItemService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		this._baseUrl = baseUrl;
	}

	getPoleInspectionItem(poleInspectionItemId: string) {
		return this.http.get<PoleInspectionItem>(this._baseUrl + 'api/PoleInspectionItem/' + poleInspectionItemId);
	}

	getAllPoleInspectionItems() {
		return this.http.get<PoleInspectionItem[]>(this._baseUrl + 'api/PoleInspectionItem/GetAllPoleInspectionItem/');
	}

	//getAlldtoPoleInspectionItems() {
	//	return this.http.get<dtoPoleInspectionItem[]>(this._baseUrl + 'api/PoleInspectionItem/GetAllPoleInspectionItem/');
	//}

	createPoleInspectionItem(poleInspectionItem: PoleInspectionItem) {
		return this.http.post<PoleInspectionItem>(this._baseUrl + 'api/PoleInspectionItem/CreatePoleInspectionItem/', poleInspectionItem);
	}

	updatePoleInspectionItem(poleInspectionItem: PoleInspectionItem) {
		return this.http.post<PoleInspectionItem>(this._baseUrl + 'api/PoleInspectionItem/UpdatePoleInspectionItem/', poleInspectionItem);
	}

}
