import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-printer-create',
  templateUrl: './printer-create.component.html',
  styleUrls: ['./printer-create.component.css']
})
export class PrinterCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
