import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { dtoOrderItem } from '../../../_shared/business-objects/dto-order-item.bo';

@Component({
  selector: 'app-quote-item-list',
  templateUrl: './quote-item-list.component.html',
  styleUrl: './quote-item-list.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', [
        animate('425ms cubic-bezier(0.4, 0.0, 0.2, 1)'),  //225
        query('@*', animateChild(), { optional: true })
      ]),
    ]),
  ]
})
export class QuoteItemListComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoOrderItem>;
  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() columnListId: string;

  quoteItemExpanded: dtoOrderItem;

  showTick: boolean;
  showInlineSpinner: boolean;
  selectedRow: string = "";


  displayedColumns: string[] = ['intItemNo', 'txtStockKeepingUnit', 'txtProductName', 'txtProfileName', 'fltWidth', 'fltThickness', 'txtSizeType', 'fltNominalWidth', 'fltNominalThickness', 'fltExWidth', 'fltExThickness', 'blnNonStandardNominal', 'intRippedBoards', 'intLaminatedBoards', 'txtPoleLengthKN', 'blnDrilled', 'fltLength', 'blnSetLength', 'blnPrecoated', 'fltDiameter', 'txtDiameterType', 'fltWeight', 'txtSpeciesName', 'txtInGroundDurabilityClass', 'txtAboveGroundDurabilityClass', 'txtStrengthGroup', 'txtGradeName', 'txtStructureGrade', 'txtAuthorityName', 'dteDateCreated', 'dteDueDate', 'btnEditDueDate', 'txtAdminNotes', 'fltM3', 'fltTonne', 'btnSetAsComplete', 'btnEditQuoteItem', 'btnRemoveQuoteItem', 'btnDropbox', 'btnViewLinkedQuoteItems'];

  ngOnInit(): void {

    if (this.columnBarPage == null || this.columnBarPage == "") {
      this.columnBarPage = "QuoteItems";
    }

    if (this.columnBarContainer == null || this.columnBarContainer == "") {
      this.columnBarContainer = "quoteContainer";
    }

  }



  updatedComponentColumnsEvent(columns: string[]) {
    console.log("columns........:", columns);

    this.displayedColumns = columns;

  }


  async expandElement(quoteItem: dtoOrderItem, index: number) {

    this.quoteItemExpanded = this.quoteItemExpanded === quoteItem ? null : quoteItem;

    await this.showQuoteItemLinkedData(quoteItem.rowguid);


  }

  expandElementAndSetColor(quoteItem: dtoOrderItem, idx: number) {

    let div: HTMLDivElement = document.getElementById("locationrow" + idx.toString()) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(() => {
        if (div.getAttribute("data-dblclick") == "1") {
          this.setRowColor(quoteItem.rowguid);
          //window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 200);
    } else {
      div.removeAttribute("data-dblclick");
      this.expandElement(quoteItem, idx);
      this.selectedRow = quoteItem.rowguid;  //Always highlight row when double clicking.

      //window.alert('ondouble');
    }

  }

  setRowColor(quoteItemId: string) {

    // No dates to select in Quote Items but there will be in Order Items.
    //if (String(this.selectedDueDate) != "" || String(this.selectedAskForSaleReminderDate) != "") {

    //  return;
    //}


    if (this.selectedRow == "" || quoteItemId != this.selectedRow) {
      this.selectedRow = quoteItemId;
      return;
    }

    if (quoteItemId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }

  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      window.event.stopPropagation();
    }
  }


  showQuoteItemLinkedData(quoteItemId: string) {
    
  }

}
