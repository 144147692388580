import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { dtoDeliveryDocketOrderItem } from '../../_shared/business-objects/dto-delivery-docket-order-item.bo';
import { DeliveryDocketAddItemsComponent } from '../delivery-docket-add-items/delivery-docket-add-items.component';
import { DeliveryDocketDeliveryRateComponent } from '../delivery-docket-delivery-rate/delivery-docket-delivery-rate.component';
import { AlertService } from '../../_shared/services/alert.service';
import { LoadingDocket } from '../../_shared/business-objects/loading-docket.bo';

@Component({
  selector: 'app-delivery-docket-update-dialog',
  templateUrl: './delivery-docket-update-dialog.component.html',
  styleUrls: ['./delivery-docket-update-dialog.component.css']
})
export class DeliveryDocketUpdateDialogComponent implements OnInit {



  deliveryDocket: DeliveryDocket;
  loadingDocket: LoadingDocket;
  orderId: string;

  constructor(private dialogRef: MatDialogRef<DeliveryDocketAddItemsComponent>, @Inject(MAT_DIALOG_DATA) private data: { deliveryDocket: DeliveryDocket, loadingDocket: LoadingDocket, orderId: string }
      ) {

    this.deliveryDocket = data.deliveryDocket;
    this.loadingDocket = data.loadingDocket;
    this.orderId = data.orderId;

    //console.log("DeliveryDocketUpdateDialogComponent - Order Id: " + data.orderId);

  }

  ngOnInit(): void {


  }


  deliveryDocketUpdated(deliveryDocketUpdated: DeliveryDocket) {

    this.dialogRef.close({ status: "Saved", deliveryDocket: deliveryDocketUpdated });


  }

  deliveryDocketCancelled(cancelled: boolean) {

    this.dialogRef.close({ status: "Cancel", finalInspectionItem: null });


  }




}
