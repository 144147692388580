import { Injectable } from "@angular/core";


@Injectable()
export class UtilityService {

  async timeWait(time: number) {
    return new Promise<boolean>(resolve => {
      setTimeout(() => {
        //console.log("in timeWait")
        resolve(true);
      }, time);
    });
  }

  CString(value) {
    return value == null || undefined ? "" : value.toString();
  }

}
