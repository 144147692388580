
export class AppUser {
  constructor() {
    this.guEmployeeId = "00000000-0000-0000-0000-000000000000";
  }
  rowguid: string;
  txtFirstName: string;
  txtLastName: string;
  txtUserName: string;
  txtPassword: string;
  txtSalt: string;
  txtToken: string;
  blnIsAdmin: boolean;
  blnIsPowerUser: boolean;
  blnHas365Account: boolean;
  blnHasReckonAccount: boolean;
  guEmployeeId: string;
  guAppUserId: string;
  txtFromAddresses: string;
}
