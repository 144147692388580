<div class="app-page-development-container" [formGroup]="form">

  <h1>App Page Development</h1>
  <div class="app-page-development-grid">
    <div class="app-page-table-container">
      <div class="app-page-tables">

        <div class="code-builder-table-container">
          <mat-form-field>
            <div class="table-filter">
              <input matInput class="table-filter-input" placeholder="Filter Tables" placeholderLabel="i.e. Delivery Dockets ....." value="" type="text" #tableFilter (keyup)="filterAppPages(tableFilter.value)">
              <br /><br />
            </div>
          </mat-form-field>
          <mat-selection-list class="table-select" formControlName="guAppPageId" #guAppPageId [multiple]="false" (selectionChange)="getAppPageColumns(guAppPageId._value[0])">
            <mat-list-option *ngFor="let appPage of appPages" [value]="appPage">{{appPage.txtPageName}}</mat-list-option>
          </mat-selection-list>
          <br /><br />
        </div>
      </div>
    </div>

    <div class="app-page-columns">
      <div class="code-builder-columns">
        <mat-form-field>
          <div class="table-filter">
            <input matInput class="table-filter-input" placeholder="Filter Columns" placeholderLabel="i.e. txtUserName ....." value="" type="text" #columnFilter (keyup)="filterAppPageColumns(columnFilter.value)">
            <br /><br />
          </div>
        </mat-form-field>
        <mat-selection-list *ngIf="false" class="columns-select" #codeBuilderColumn [multiple]="true" formControlName="guCodeBuilderColumnId" (selectionChange)="setColumnFromCodeBuilder(codeBuilderColumn._value[0])">
          <mat-list-option *ngFor="let appPageColumn of appPageColumns" [value]="appPageColumn">
            {{appPageColumn.txtColumnName}}
          </mat-list-option>
        </mat-selection-list>

        <div data-code-builder-column-select="appPageColumnSelect" class="code-builder-column-container" #appPageColumnListContainer>
          <div data-code-builder-column-select="appPageColumnSelect" id="appPageColumnSelect" class="code-builder-column-select-list">
            <div class="code-builder-column-heading-row code-builder-column-name-heading">Name</div>
            <div class="code-builder-column-heading-row code-builder-column-default-order-heading">Default Order</div>
            <div class="code-builder-column-heading-row code-builder-column-field-type-heading">Field Type</div>
            <div class="code-builder-column-heading-row code-builder-column-field-width-heading">Field Width</div>
            <div class="code-builder-column-heading-row code-builder-column-field-width-heading">Select Display Field</div>
            <div class="code-builder-column-heading-row code-builder-column-field-width-heading">Select Value Field</div>
            @for(appPageColumn of appPageColumns; track appPageColumn; let idx = $index){
            <div data-code-builder-column-select="appPageColumnSelect" tabindex="{{idx}}" id="appPageColumnName{{idx}}" class="chh-list-item-row chh-list-item-first-column code-builder-column-name" [ngClass]="appPageColumn.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected code-builder-column-first-name' : columnHoverId == appPageColumn.rowguid ? 'chh-list-item-row chh-list-item-row-hover code-builder-column-first-name' : 'chh-list-item-row code-builder-column-first-name'" (mouseenter)="rowEnter(appPageColumn.rowguid)" (mouseleave)="rowLeave()" (click)="selectAppPageColumns(appPageColumn, !(appPageColumn.guSelectedColumnId != ''))"><div data-code-builder-column-select="appPageColumnSelect" class="code-builder-column-text">{{appPageColumn.txtColumnName}}</div></div>
            <div data-code-builder-column-select="appPageColumnSelect" id="appPageColumnUpdateDiv{{idx}}" class="chh-list-item-row code-builder-column-radio code-builder-column-update-div" [ngClass]="appPageColumn.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected' : columnHoverId == appPageColumn.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(appPageColumn.rowguid)" (mouseleave)="rowLeave()" (click)="selectAppPageColumns(appPageColumn, !(appPageColumn.guSelectedColumnId != ''))">
              {{appPageColumn.intDefaultColumnOrder}}
            </div>
            <div data-code-builder-column-select="appPageColumnSelect" id="appPageColumnUpdateDiv{{idx}}" class="chh-list-item-row code-builder-column-field-type-div" [ngClass]="appPageColumn.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected' : columnHoverId == appPageColumn.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(appPageColumn.rowguid)" (mouseleave)="rowLeave()">
              <mat-form-field data-code-builder-column-select="appPageColumnSelect" appearance="fill">
                <mat-label data-code-builder-column-select="appPageColumnSelect">Field Type</mat-label>
                <mat-select data-code-builder-column-select="appPageColumnSelect" formControlName="intAppPageColumnFieldType" #appPageColumnFieldType (click)="clickedColumnFieldType()" (selectionChange)="fieldTypeChange(appPageColumn, appPageColumnFieldType.value)">
                  <mat-option data-code-builder-column-select="appPageColumnSelect" *ngFor="let appPageColumnFieldType of appPageColumnFieldTypes" [value]="appPageColumnFieldType.intValue">{{appPageColumnFieldType.txtValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div data-code-builder-column-select="appPageColumnSelect" id="appPageColumnUpdateDiv{{idx}}" class="chh-list-item-row code-builder-column-field-width-div" [ngClass]="appPageColumn.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected' : columnHoverId == appPageColumn.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(appPageColumn.rowguid)" (mouseleave)="rowLeave()">
              <mat-form-field data-code-builder-column-select="appPageColumnSelect" appearance="fill">
                <mat-label data-code-builder-column-select="appPageColumnSelect">Field Width</mat-label>
                <mat-select data-code-builder-column-select="appPageColumnSelect" formControlName="intAppPageColumnFieldWidth" #appPageColumnFieldWidth (selectionChange)="fieldWidthChange(appPageColumn, appPageColumnFieldWidth.value)">
                  <mat-option data-code-builder-column-select="appPageColumnSelect" *ngFor="let appPageColumnFieldWidth of appPageColumnFieldWidths" [value]="appPageColumnFieldWidth.intValue">{{appPageColumnFieldWidth.txtValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div data-code-builder-column-select="appPageColumnSelect" id="appPageColumnUpdateDiv{{idx}}" class="chh-list-item-row code-builder-column-field-width-div" [ngClass]="appPageColumn.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected' : columnHoverId == appPageColumn.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(appPageColumn.rowguid)" (mouseleave)="rowLeave()">
              <mat-form-field data-code-builder-column-select="appPageColumnSelect" appearance="fill">
                <mat-label data-code-builder-column-select="appPageColumnSelect">Select Display Field</mat-label>
                <mat-select data-code-builder-column-select="appPageColumnSelect" formControlName="intAppPageColumnSelectDisplayField" #appPageColumnSelectDisplayField (selectionChange)="selectDisplayFieldChange(appPageColumn, appPageColumnSelectDisplayField.value)">
                  <mat-option data-code-builder-column-select="appPageColumnSelect" *ngFor="let appPageColumnSelectDisplayField of appPageColumn.arFKColumns" [value]="appPageColumnSelectDisplayField.txtValue">{{appPageColumnSelectDisplayField.txtValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div data-code-builder-column-select="appPageColumnSelect" id="appPageColumnUpdateDiv{{idx}}" class="chh-list-item-row code-builder-column-field-width-div" [ngClass]="appPageColumn.guSelectedColumnId != '' ? 'chh-list-item-row chh-list-item-row-selected' : columnHoverId == appPageColumn.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(appPageColumn.rowguid)" (mouseleave)="rowLeave()">
              <mat-form-field data-code-builder-column-select="appPageColumnSelect" appearance="fill">
                <mat-label data-code-builder-column-select="appPageColumnSelect">Select Value Field</mat-label>
                <mat-select data-code-builder-column-select="appPageColumnSelect" formControlName="intAppPageColumnSelectValueField" #appPageColumnSelectValueField (selectionChange)="selectValueFieldChange(appPageColumn, appPageColumnSelectValueField.value)">
                  <mat-option data-code-builder-column-select="appPageColumnSelect" *ngFor="let appPageColumnSelectValueField of appPageColumn.arFKColumns" [value]="appPageColumnSelectValueField.txtValue">{{appPageColumnSelectValueField.txtValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            }
          </div>

        </div>

        <br />
      </div>
    </div>
    <div class="action-button-container">
      <button class="action-button" mat-flat-button color="primary" (click)="createCode()">Create Code</button>
    </div>

  </div>

  <div class="html-page">
    <div class="html-page-column">
      <textarea class="html-page-textarea" #htmlPage id="htmlPage">
      </textarea>
    </div>
    <div class="html-page-copy-column">
      <mat-icon matTooltip="Copy To Clipboard" class="help-icon small-icon" (click)="copyToClipboard(htmlPage.value)">content_copy</mat-icon>
    </div>

  </div>

  <div class="javascript-page">

    <div class="javascript-page-column">
      <textarea class="javascript-page-textarea" #javascriptPage id="javascriptPage">
      </textarea>
    </div>
    <div class="javascript-page-copy-column">
      <mat-icon matTooltip="Copy To Clipboard" class="help-icon small-icon" (click)="copyToClipboard(javascriptPage.value)">content_copy</mat-icon>
    </div>
  </div>
  </div>
