import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { AfterViewInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatRadioGroup } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { AuthService } from '../../account/auth.service';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { dtoTPPoleInspectionItem } from '../../_shared/business-objects/dto-tppole-inspection-item.bo';
import { dtoTPPoleInspection } from '../../_shared/business-objects/dto-tppole-inspection.bo';
import { DBFieldFunction, SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { TPPoleInspection } from '../../_shared/business-objects/tppole-inspection.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoTPPoleInspectionItemService } from '../../_shared/services/dto-tppole-inspection-item.service';
import { dtoTPPoleInspectionService } from '../../_shared/services/dto-tppole-inspection.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { TPPoleInspectionService } from '../../_shared/services/tppole-inspection.service';
import { UserPositionService } from '../../_shared/services/user-position.service';
import { FinalInspectionListComponent } from '../final-inspection-list/final-inspection-list.component';


export enum enFinalInspectionTabs { FinalInspections = 0, SearchOptions = 1, CreateFinalInspection = 2, UpdateFinalInspection = 3 }


@Component({
  selector: 'app-final-inspection-search',
  templateUrl: './final-inspection-search.component.html',
  styleUrls: ['./final-inspection-search.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class FinalInspectionSearchComponent implements OnInit, AfterViewInit {

  @ViewChild('tabFinalInspections', { static: false }) tabFinalInspections: MatTabGroup;
  @ViewChild("appFinalInspectionList") appFinalInspectionList: FinalInspectionListComponent;

  @ViewChild("finalInspectionsElement") elementRef: ElementRef;

  form: FormGroup;
  selectedTab: number;
  public eFinalInspectionTabs = enFinalInspectionTabs;

  tpPoleInspections: MatTableDataSource<dtoTPPoleInspection>;
  tpPoleInspection: TPPoleInspection;
  isSupervisor: boolean = false;

  deliveryStatuses: dtoIntKeyValue[] = [];

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  totalTon: number = 0;
  totalM3: number = 0;
  totalPoleQuantity: number = 0;
  totalRows: number = 0;

  resultTime: number;
  searchTime: number;

  showSpinner: boolean = false;
  autoAdd: boolean = false;
  finalInspectionAdd: dtoTPPoleInspection;

  refreshTimeout: any;
  timeLeftTimeout: any;
  timeLeft: number;
  timeLeftMinutes: number;
  timeLeftSeconds: number;

  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private route: ActivatedRoute, private formService: FormService
    , private dtoTPPoleInspectionService: dtoTPPoleInspectionService, private tpPoleInsectionService: TPPoleInspectionService
    , private userPositionService: UserPositionService, private authService: AuthService, private dtoTPPoleInspectionItemService: dtoTPPoleInspectionItemService  ) {

    this.filterToQueryParamMap.push({ txtField: "radOnDeliveryDocket", txtQueryParam: "ondeliverydocket", intFieldType: FieldQueryParamMap.enFieldType.Radiobox, intValueType: FieldQueryParamMap.enValueType.Int });
    this.filterToQueryParamMap.push({ txtField: "radFinalStatus", txtQueryParam: "finalstatus", intFieldType: FieldQueryParamMap.enFieldType.Radiobox, intValueType: FieldQueryParamMap.enValueType.Int });
    this.filterToQueryParamMap.push({ txtField: "radSearchDeliveryStatus", txtQueryParam: "deliverydocketstatus", intFieldType: FieldQueryParamMap.enFieldType.Radiobox, intValueType: FieldQueryParamMap.enValueType.Int });

    this.form = this.fb.group({
      txtSearchFinalNumber: [''],
      txtSearchCustomerName: [''],
      txtSearchLocation: [''],
      dteSearchDateCreatedFrom: [''],
      dteSearchDateCreatedTo: [''],
      txtPoleNumber: [''],
      txtConsecutiveNumber: [''],
      radSearchDeliveryStatus: [''],
      radOnDeliveryDocket: ['2'],
      radFinalStatus: ['-1'],
      chkRefreshReadyForLoading: [''],
      txtTimeLeft: [''],
      txtOrderNumber: [''],
      blnParkedFinal: [''],
      txtProductType: [''],
      txtLength: [''],
      txtKN: [''],
    });

    this.tpPoleInspections = new MatTableDataSource();


    this.deliveryStatuses = this.sharedService.getEnumObjectKeyValue(DeliveryDocket.enDeliveryStatus);
    this.deliveryStatuses.splice(0, 0, new dtoIntKeyValue(-1, "None"));

    this.form.controls['radSearchDeliveryStatus'].setValue(-1);



  }


  ngOnInit(): void {

    this.selectedTab = this.eFinalInspectionTabs.FinalInspections;



    this.loadData();

    setTimeout(()=>this.loadQueryParameters(),500);

    this.route.queryParams.subscribe(data => {

      if (data.search != null && data.search == 1) {
        console.log(data.search);
        let top: number = 0;
        let searchtype: string = '';
        if (data.top != null && data.top != "") {
          top = Number(data.top);
        }

        if (data.searchtype != null && data.searchtype != "") {
          searchtype = data.searchtype;
        }

        setTimeout(() => { this.searchFinalInspections(searchtype, top) }, 500);
      }

      if (data.searchreadyforloading != null && data.searchreadyforloading == 1) {
        
        setTimeout(() => { this.searchFinalInspections('readyforloading', 0) }, 500);
        this.form.controls['chkRefreshReadyForLoading'].setValue(1);
        setTimeout(() => { this.refreshReadyForLoading(true) }, 500);

      }


    });

  }

  ngAfterViewInit(): void {



  }

  loadQueryParameters() {
    this.route.queryParams.subscribe(params => {

      this.formService.setFieldsFromQueryParams(this.filterToQueryParamMap, params, this.form);
      console.log("set field");

    })


  }


  async loadData() {


    this.isSupervisor = await this.userPositionService.isInPosition("tp supervisor", this.authService.getAppUserEmployeeId());
    this.appFinalInspectionList.setIsSupervisor(this.isSupervisor);
    

  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    console.log(this.selectedTab == this.eFinalInspectionTabs.CreateFinalInspection);

    if (e.index == this.eFinalInspectionTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchCustomerName");
    }

  }

  createFinalInspection() {

  }

  async searchFinalInspections(searchType: string, lastNoOfInspections: number) {

    let btnSeachFinalInspections = document.getElementById("btnSearchFinalInspectionsCustomer");
    if (btnSeachFinalInspections != null) {
      btnSeachFinalInspections.focus();
    }

    this.tabFinalInspections.selectedIndex = this.eFinalInspectionTabs.FinalInspections
    this.showSpinner = true;

    this.resultTime = 0;
    this.searchTime = Date.now();

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let finalNumber: string = this.form.controls["txtSearchFinalNumber"].value;
    let customerName: string = this.form.controls["txtSearchCustomerName"].value;
    let location: string = this.form.controls["txtSearchLocation"].value;
    let createdDateFrom: string = this.form.controls["dteSearchDateCreatedFrom"].value;
    let createdDateTo: string = this.form.controls["dteSearchDateCreatedTo"].value;
    let orderNumber: string = this.form.controls["txtOrderNumber"].value;
    let poleNumber: string = this.form.controls["txtPoleNumber"].value;
    let consecutiveNumber: string = this.form.controls["txtConsecutiveNumber"].value;
    let deliveryStatus: string = this.form.controls["radSearchDeliveryStatus"].value;
    let onDeliveryDocket: number = this.form.controls["radOnDeliveryDocket"].value;
    let finalStatus: number = this.form.controls["radFinalStatus"].value;
    let parkedFinal: boolean = this.form.controls["blnParkedFinal"].value == true;
    let productName: string = this.form.controls["txtProductType"].value;
    let length: string = this.form.controls["txtLength"].value;
    let kn: string = this.form.controls["txtKN"].value;
    let eOnDeliveryDocket: TPPoleInspection.enOnDeliveryDocket = TPPoleInspection.enOnDeliveryDocket.Either;
    const datepipe: DatePipe = new DatePipe('en-AU');


    this.totalRows = 0;
    this.totalTon = 0;
    this.totalM3 = 0;
    this.totalPoleQuantity = 0;

    this.tpPoleInspections.data = [];

    if (finalNumber != null && finalNumber != "") {
      aParam = [];
      if (finalNumber.toString().indexOf(",")) {
        let aFinalNumbers: string[] = finalNumber.toString().split(",");
        for (let i = 0; i <= aFinalNumbers.length - 1; i++) {
          aParam.push(new SQLParamArray("intFinalNo", aFinalNumbers[i].trim()))
        }
      }
      else {
        aParam.push(new SQLParamArray("intFinalNo", finalNumber))
      }
      aParamList.push(aParam);
    }


    if (customerName != "" && customerName != null) {
      aParam = [];
      if (customerName.indexOf(",")) {
        let aCustomerNames: string[] = customerName.split(",");
        for (let i = 0; i <= aCustomerNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtCustomer", aCustomerNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtCustomer", customerName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (location != "" && location != null) {
      aParam = [];
      if (location.indexOf(",")) {
        let aLocations: string[] = location.split(",");
        for (let i = 0; i <= aLocations.length - 1; i++) {
          aParam.push(new SQLParamArray("txtLocation", aLocations[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtLocation", location, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }


    if (createdDateFrom != "" && createdDateFrom != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateInspected", datepipe.transform(createdDateFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }

    if (createdDateTo != "" && createdDateTo != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateInspected", datepipe.transform(createdDateTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }


    if (orderNumber != "" && orderNumber != null) {
      aParam = [];
      if (orderNumber.indexOf(",")) {
        let aOrderNumbers: string[] = orderNumber.split(",");
        for (let i = 0; i <= aOrderNumbers.length - 1; i++) {
          aParam.push(new SQLParamArray("txtOrderNo", aOrderNumbers[i].trim(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtOrderNo", orderNumber, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL))
      }
      aParamList.push(aParam);
    }

    if (poleNumber != "" && poleNumber != null) {
      aParam = [];
      if (poleNumber.indexOf(",")) {
        let aPoleNumbers: string[] = poleNumber.split(",");
        for (let i = 0; i <= aPoleNumbers.length - 1; i++) {
          aParam.push(new SQLParamArray("txtPoleNumber", aPoleNumbers[i].trim(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtPoleNumber", poleNumber, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL))
      }
      aParamList.push(aParam);
      console.log(aParam);
    }


    if (consecutiveNumber != "" && consecutiveNumber != null) {
      aParam = [];
      if (consecutiveNumber.indexOf(",")) {
        let aConsecutiveNumbers: string[] = consecutiveNumber.split(",");
        for (let i = 0; i <= aConsecutiveNumbers.length - 1; i++) {
          aParam.push(new SQLParamArray("txtConsecutiveNumber", aConsecutiveNumbers[i].trim(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtConsecutiveNumber", consecutiveNumber, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL))
      }
      aParamList.push(aParam);
    }

    if (productName != null && productName != "") {
      aParam = [];
      if (productName.indexOf(",")) {
        let aProductName: string[] = productName.split(",");
        for (let i = 0; i <= aProductName.length - 1; i++) {
          aParam.push(new SQLParamArray("txtProductName", aProductName[i].trim(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtProductName", productName, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1))
      }
      aParamList.push(aParam);
    }

    if (length != null && length.toString() != "") {
      aParam = [];
      console.log("length: ", length);
      if (length.toString().indexOf(",")) {
        let aLength: string[] = length.toString().split(",");
        for (let i = 0; i <= aLength.length - 1; i++) {
          aParam.push(new SQLParamArray("fltLength", aLength[i].trim(), SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1));
          aParam.push(new SQLParamArray("fltPoleFinalLength", aLength[i].trim(), SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1));
        }
      }
      else {
        aParam.push(new SQLParamArray("fltLength", length, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1))
        aParam.push(new SQLParamArray("fltPoleFinalLength", length.trim(), SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1));
      }
      aParamList.push(aParam);
    }

    if (kn != null && kn != "") {
      aParam = [];
      if (kn.toString().indexOf(",")) {
        let aKN: string[] = kn.toString().split(",");
        for (let i = 0; i <= aKN.length - 1; i++) {
          aParam.push(new SQLParamArray("intPoleFinalKN", aKN[i].trim(), SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1))
        }
      }
      else {
        aParam.push(new SQLParamArray("intPoleFinalKN", kn, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1))
      }
      aParamList.push(aParam);
    }

    if (deliveryStatus != "" && deliveryStatus != null && parseInt(deliveryStatus) > -1) {
      aParam = [];
      aParam.push(new SQLParamArray("intDeliveryStatus", deliveryStatus))
      aParamList.push(aParam);
    }

    if (finalStatus != null && finalStatus > -1) {

      aParam = [];
      aParam.push(new SQLParamArray("intStatus", finalStatus.toString()))
      aParamList.push(aParam);
    }

    if (parkedFinal != null && parkedFinal == true) {

      aParam = [];
      aParam.push(new SQLParamArray("blnParkedFinal", parkedFinal.toString()))
      aParamList.push(aParam);
    }

    if (aParamList.length == 0 && searchType == "") {
      this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "", 4000);
      this.showSpinner = false;
      return;
    }


    //console.log("onDeliveryDocket: " + onDeliveryDocket);
    if (onDeliveryDocket != null && onDeliveryDocket == 0) {
      eOnDeliveryDocket = TPPoleInspection.enOnDeliveryDocket.No;
    }

    if (onDeliveryDocket != null && onDeliveryDocket == 1) {
      eOnDeliveryDocket = TPPoleInspection.enOnDeliveryDocket.Yes;
    }
    
    let finalInspections: dtoTPPoleInspection[];
    if (searchType == "") {
      finalInspections = await this.dtoTPPoleInspectionService.getdtoTPPoleInspectionParamArrayPromise(aParamList, eOnDeliveryDocket);
    }
     
    if (searchType == "lastinspections") {
      finalInspections = await this.dtoTPPoleInspectionService.getLastdtoTPPoleInspections(lastNoOfInspections, eOnDeliveryDocket, aParamList);
    }

    if (searchType == "readyforloading" || searchType == "parkedpoles") {
      aParamList = [];
      aParam = [];

      let status: TPPoleInspection.enStatus = TPPoleInspection.enStatus.ReadyForLoading;

      //console.log("searchType: " + searchType);

      if (searchType == "parkedpoles") {
        //status = TPPoleInspection.enStatus.PolesParked;
        aParam = [];
        aParam.push(new SQLParamArray("blnParkedFinal", "true"))
        aParamList.push(aParam);
      }

      if (searchType != "parkedpoles") {
        aParam = [];
        aParam.push(new SQLParamArray("intStatus", status.toString()));
        aParamList.push(aParam);
      }

      if (searchType == "readyforloading") {
        aParam = [];
        aParam.push(new SQLParamArray("guDeliveryDocketOrderItemId", "", SQLParamArray.enSQLOperator.IsNull, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL));
        aParamList.push(aParam);
      }

      //console.log(aParamList);

      finalInspections = await this.dtoTPPoleInspectionService.getdtoTPPoleInspectionParamArrayPromise(aParamList, eOnDeliveryDocket);
    }

    if (finalInspections != null) {
      this.tpPoleInspections.data = finalInspections;

      this.totalRows = finalInspections.length;
      finalInspections.forEach(finalInspection => {

        this.totalM3 += finalInspection.fltTotalActualM3;
        this.totalTon += finalInspection.fltTotalActualTonne;
        this.totalPoleQuantity += finalInspection.intTotalPoleQuantity;
      });

      this.totalTon = parseFloat(this.totalTon.toFixed(2));
      this.totalM3 = parseFloat(this.totalM3.toFixed(2));
      this.totalPoleQuantity = parseFloat(this.totalPoleQuantity.toFixed(2));


      this.resultTime = ((Date.now() - this.searchTime) / 1000);

      this.showSpinner = false;
    }

    //this.dtoTPPoleInspectionService.getdtoTPPoleInspectionParamArray(aParamList).subscribe(finalInspections => {



    //}, err => {
    //  console.log(err);
    //});


  }


  async searchLastThreeFinalInspections(lastNoOfInspections: number) {

    //if (isNaN(lastNoOfInspections)) {
    //  return false;
    //}

    //this.resultTime = 0;
    //this.searchTime = Date.now();


    //this.totalRows = 0;
    //this.totalTon = 0;
    //this.totalM3 = 0;
    //this.totalPoleQuantity = 0;

    //this.tpPoleInspections.data = [];

    //this.showSpinner = true;

    //let finalInspections = await this.dtoTPPoleInspectionService.getLastdtoTPPoleInspections(lastNoOfInspections, aParamList);
    
    //this.tpPoleInspections.data = finalInspections;

    //this.totalRows = finalInspections.length;
    //finalInspections.forEach(finalInspection => {

    //  this.totalM3 += finalInspection.fltTotalActualM3;
    //  this.totalTon += finalInspection.fltTotalActualTonne;
    //  this.totalPoleQuantity += finalInspection.intTotalPoleQuantity;
    //});

    //this.totalTon = parseFloat(this.totalTon.toFixed(2));
    //this.totalM3 = parseFloat(this.totalM3.toFixed(2));
    //this.totalPoleQuantity = parseFloat(this.totalPoleQuantity.toFixed(2));


    //this.resultTime = ((Date.now() - this.searchTime) / 1000);

    //this.showSpinner = false;

  }

  searchParameters() {

  }


  async editFinalInspection(finalInspection: dtoTPPoleInspection) {

    this.tpPoleInspection = await this.tpPoleInsectionService.getTPPoleInspectionPromise(finalInspection.rowguid);

    this.tabFinalInspections.selectedIndex = this.eFinalInspectionTabs.UpdateFinalInspection;

  }

  async removeFinalInspection(finalInspection: dtoTPPoleInspection) {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guTPPoleInspectionId", finalInspection.rowguid));
    aParamList.push(aParam);

    let finalItems: dtoTPPoleInspectionItem[] = await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemParamArrayPromise(aParamList);

    if (finalItems != null && finalItems.length > 0) {
      this.alertService.openSnackBarDefault("There are final items still linked to this final.\nYou must remove the items first.");
      return;
    }

    let msg:string = await this.alertService.openSnackBarCustomPromise("Remove Final", "Are you sure you want to remove this final", "Yes", "No", "center", "bottom");
    if (msg != "Yes") {
      return;
    }

    let removed: boolean = await this.dtoTPPoleInspectionService.removeTPPoleInspectionPromise(finalInspection.rowguid);


    if (removed == true) {
    console.log("removed: " + removed);

      for (let i = 0; i <= this.tpPoleInspections.data.length - 1; i++) {
        if (this.tpPoleInspections.data[i].rowguid == finalInspection.rowguid) {
          
          this.tpPoleInspections.data.splice(i, 1);
          this.tpPoleInspections.data = this.tpPoleInspections.data;
          break;
        }

      }

      this.alertService.openSnackBarDefault("The final has been removed.");
    }

  }

  async finalInspectionCreated(finalInspectionCreatedObj: [ TPPoleInspection, string ]) {
    if (finalInspectionCreatedObj == null) {
      return;
    }

    if (finalInspectionCreatedObj[1] == "create") {
      this.tabFinalInspections.selectedIndex = this.eFinalInspectionTabs.FinalInspections;
      this.form.controls['txtSearchFinalNumber'].setValue(finalInspectionCreatedObj[0].intFinalNo);
      this.searchFinalInspections('', 0);
    }

    if (finalInspectionCreatedObj[1] == "createanditem") {
      this.autoAdd = true;
      this.finalInspectionAdd = await this.dtoTPPoleInspectionService.getdtoTPPoleInspectionPromise(finalInspectionCreatedObj[0].rowguid);
      this.tabFinalInspections.selectedIndex = this.eFinalInspectionTabs.FinalInspections;
      this.form.controls['txtSearchFinalNumber'].setValue(finalInspectionCreatedObj[0].intFinalNo);
      console.log("before - this.autoAdd: " + this.autoAdd);
      await this.searchFinalInspections('', 0);
      console.log("after - this.autoAdd: " + this.autoAdd);

      this.appFinalInspectionList.addFinalInspectionItem(this.finalInspectionAdd, 0);

    }

  }

  async finalInspectionUpdated(finalInspection: TPPoleInspection) {

    if (finalInspection == null) {
      return;
    }

    this.tabFinalInspections.selectedIndex = this.eFinalInspectionTabs.FinalInspections;

    this.form.controls['txtSearchFinalNumber'].setValue(finalInspection.intFinalNo);

    this.searchFinalInspections('', 0);

  }

  finalInspectionCancelled() {
    this.tabFinalInspections.selectedIndex = this.eFinalInspectionTabs.FinalInspections;
  }

  stockUpdated(e) {
    this.tabFinalInspections.selectedIndex = this.eFinalInspectionTabs.FinalInspections;
    this.searchFinalInspections('', 0);
  }

  copyToClipboard(value) {
    this.formService.copyToClipboard(value);
    this.alertService.openSnackBar("The value " + value + " has been copied to the clipboard", "Close", "center", "bottom", "", 3000);
  }

  getFinalStatusValue() {
    return this.form.controls['radFinalStatus'].value
  }

  getOnDeliveryStatus() {
    return this.form.controls['radOnDeliveryDocket'].value
  }

  getDeliveryStatus() {
    return this.form.controls['radSearchDeliveryStatus'].value
  }

  refreshReadyForLoading(val) {

    if (val == true) {
      this.refreshTimeout = setInterval(() => { this.searchFinalInspections('readyforloading', 0); }, 300000);
      this.timeLeft = 300000;
      this.timeLeftMinutes = Math.floor((this.timeLeft / 1000) / 60);
      this.timeLeftSeconds = (this.timeLeft / 1000) - this.timeLeftMinutes * 60;

      this.form.controls["txtTimeLeft"].setValue((this.timeLeftMinutes + ':' + this.timeLeftSeconds).toString());
      this.timeLeftTimeout = setInterval(() => {
        this.timeLeft -= 1000;
        this.timeLeftMinutes = Math.floor((this.timeLeft / 1000) / 60);
        this.timeLeftSeconds = (this.timeLeft / 1000) - this.timeLeftMinutes * 60;
        if (this.timeLeft <= 0) {
          this.timeLeft = 300000;
        }
        this.form.controls["txtTimeLeft"].setValue((this.timeLeftMinutes + ':' + this.timeLeftSeconds).toString());
        
        //console.log("time left: " + this.form.controls["txtTimeLeft"].value);
      }, 1000);

    }

    if (val == false) {
      clearInterval(this.refreshTimeout);
      this.timeLeft = 0;
      clearInterval(this.timeLeftTimeout);
    }


  }
}
