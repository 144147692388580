<div class="printer-list-container" id="printerListContainer">

  <app-app-user-column-set-bar [componentColumns]="displayedColumns" [pageName]="columnBarPage" [container]="columnBarContainer" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)" *ngIf="loadedComponent"></app-app-user-column-set-bar>

  <table mat-table #tblPrinterList [dataSource]="datasource" multiTemplateDataRows class="mat-elevation-z8 printer-list-table" *ngIf="datasource != null">

    <ng-container matColumnDef="txtName">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Name </th>
      <td mat-cell *matCellDef="let printer" class="name printerTD alignRight"> {{printer.txtName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtAliasName">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Alias Name </th>
      <td mat-cell *matCellDef="let printer" class="alias-name printerTD alignRight"> {{printer.txtAliasName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtPrinterLocation">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Priner Location </th>
      <td mat-cell *matCellDef="let printer" class="printerTD printer-location alignRight"> {{printer.txtPrinterLocation}} </td>
    </ng-container>

    <ng-container matColumnDef="txtPrinterIPAddress">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Printer IP Address </th>
      <td mat-cell *matCellDef="let printer" class="printerTD printer-ip-Address-type alignRight"> {{printer.txtPrinterIPAddress}} </td>
    </ng-container>

    <ng-container matColumnDef="txtPaperKind">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Paper Kind </th>
      <td mat-cell *matCellDef="let printer" class="printerTD paper-kind alignRight"> {{printer.txtPaperKind}} </td>
    </ng-container>

    <ng-container matColumnDef="txtPrinterType">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Printer Type </th>
      <td mat-cell *matCellDef="let printer" class="printerTD printer-type alignRight"> {{printer.txtPrinterType}} </td>
    </ng-container>

    <ng-container matColumnDef="fltWidth">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Width </th>
      <td mat-cell *matCellDef="let printer" class="printerTD width alignRight"> {{printer.fltWidth}} </td>
    </ng-container>

    <ng-container matColumnDef="fltHeight">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Height </th>
      <td mat-cell *matCellDef="let printer" class="printerTD height alignRight"> {{printer.fltHeight}} </td>
    </ng-container>

    <ng-container matColumnDef="txtSizeUnit">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Size Unit </th>
      <td mat-cell *matCellDef="let printer" class="printerTD size-unit alignRight"> {{printer.txtSizeUnit}} </td>
    </ng-container>

    <ng-container matColumnDef="blnSensativeData">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Sensative Data </th>
      <td mat-cell *matCellDef="let printer" class="printerTD sensative-data alignRight"> {{printer.blnSensativeData | yesNo }} </td>
    </ng-container>

    <ng-container matColumnDef="blnActive">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Active </th>
      <td mat-cell *matCellDef="let printer" class="printerTD active alignRight"> {{printer.blnActive | yesNo}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="printerTH alignRight"> Date Created </th>
      <td mat-cell *matCellDef="let printer" class="printerTD date-created alignRight"> {{printer.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditPrinter">
      <th mat-header-cell *matHeaderCellDef class="printerTH"></th>
      <td mat-cell *matCellDef="let printer" class="printerTD"><button class="edit-printer-list-button" type="button" mat-flat-button color="primary" (click)="editPrinter(printer)">Edit</button></td>
    </ng-container>

    <ng-container matColumnDef="btnRemovePrinter">
      <th mat-header-cell *matHeaderCellDef class="printerTH"></th>
      <td mat-cell *matCellDef="let printer" class="printerTD"><button class="remove-printer-list-button" type="button" mat-flat-button color="primary" (click)="removePrinter(printer.rowguid)">Remove</button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let printer; let idx = dataIndex;" id="locationrow{{idx}}"></tr>

  </table>

</div>
