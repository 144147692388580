import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppPageColumn } from '../../_shared/business-objects/app-page-column.bo';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AppUserColumnSet } from '../../_shared/business-objects/app-user-column-set.bo';
import { AppUserColumn } from '../../_shared/business-objects/app-user-column.bo';
import { AppUser } from '../../_shared/business-objects/app-user.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { AppPageColumnService } from '../../_shared/services/app-page-column.service';
import { AppPageService } from '../../_shared/services/app-page.service';
import { AppService } from '../../_shared/services/app-services/app.service';
import { AppUserColumnSetService } from '../../_shared/services/app-user-column-set.service';
import { AppUserColumnService } from '../../_shared/services/app-user-column.service';
import { AppUserService } from '../../_shared/services/app-user.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-app-user-column-update',
  templateUrl: './app-user-column-update.component.html',
  styleUrls: ['./app-user-column-update.component.css']
})
export class AppUserColumnUpdateComponent implements OnInit {

  appUsers: AppUser[];
  appUserColumnSets: AppUserColumnSet[];
  appUserColumns: AppUserColumn[];
  appPageColumns: AppPageColumn[];
  appPages: AppPage[];


  form: FormGroup;

  @Output() appUserColumnUpdated = new EventEmitter<AppUserColumn>();
  @Output() appUserColumnCancelled = new EventEmitter<boolean>();
  @Input() appUserColumn: AppUserColumn;

  constructor(private fb: FormBuilder, private appService: AppService, private sharedService: SharedService, private alertService: AlertService
    , private appPageService: AppPageService, private appPageColumnService: AppPageColumnService, private appUserColumnService: AppUserColumnService
    , private appUserColumnSetService: AppUserColumnSetService, private appUserService: AppUserService) {

    this.form = this.fb.group({
      dteDateCreated: [''],
      guAppUserId: [''],
      guAppPageId: [''],
      guAppUserColumnSetId: [''],
      guAppPageColumnId: [''],
      blnVisible: [''],
      txtColumnName: [''],
      intColumnOrder: [''],
      blnAddAllColumns: ['']
    });

    this.form.controls['dteDateCreated'].enable();
    this.form.controls['dteDateCreated'].setValue(this.sharedService.currentDatePlusTZOffset());
    this.form.controls['dteDateCreated'].disable();
  }

  ngOnInit(): void {


    if (this.appUserColumn == null) {
      this.alertService.openSnackBar("There is no app user column to update", "Close", "center", "bottom", "", 3000);
      return;
    }

    this.loadData();

  }


  async loadData() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];


    aParamList.push(aParam);
    this.appUsers = await this.appUserService.getAppUserParamArrayPromise(aParamList);

    let appPageColumn: AppPageColumn = await this.appPageColumnService.getAppPageColumnPromise(this.appUserColumn.guAppPageColumnId);
    if (appPageColumn != null) {
      this.form.controls['guAppPageId'].setValue(appPageColumn.guAppPageId);

      aParamList = [];
      aParam = [];
      aParam.push(new SQLParamArray("guAppPageId", appPageColumn.guAppPageId));
      aParamList.push(aParam);
      this.appUserColumnSets = await this.appUserColumnSetService.getAppUserColumnSetParamArrayPromise(aParamList);
    }

    aParamList = [];
    aParam = [];
   aParamList.push(aParam);
    this.appPageColumns = await this.appPageColumnService.getAppPageColumnParamArrayPromise(aParamList);

    aParamList = [];
    aParam = [];
    aParamList.push(aParam);
    this.appPages = await this.appPageService.getAppPageParamArrayPromise(aParamList);



    this.form.controls['dteDateCreated'].setValue(this.appUserColumn.dteDateCreated);
    this.form.controls['guAppUserId'].setValue(this.appUserColumn.guAppUserId);
    this.form.controls['guAppPageColumnId'].setValue(this.appUserColumn.guAppPageColumnId);
    this.form.controls['guAppUserColumnSetId'].setValue(this.appUserColumn.guAppUserColumnSetId);
    this.form.controls['blnVisible'].setValue(this.appUserColumn.blnVisible);
    this.form.controls['intColumnOrder'].setValue(this.appUserColumn.intColumnOrder);

  }


  async update() {

    let saveAppUserColumn: boolean = true;
    let dateCreated: Date = new Date(this.form.controls['dteDateCreated'].value.toString());
    let appPageId: string = this.form.controls['guAppPageId'].value;
    let appUserId: string = this.form.controls['guAppUserId'].value;
    let appUserColumnSetId: string = this.form.controls['guAppUserColumnSetId'].value;
    let appPageColumnId: string = this.form.controls['guAppPageColumnId'].value;
    let visible: boolean = (this.form.controls['blnVisible'].value == true);
    console.log("appUserId: " + appUserId);
    let defaultColumnOrder: number = this.form.controls['intColumnOrder'].value;
    const datepipe: DatePipe = new DatePipe('en-AU');

    console.log("appUserColumnSetId: " + appUserColumnSetId);
    console.log("appPageColumnId: " + appPageColumnId);
    console.log("visible: " + visible);
    console.log("defaultColumnOrder: " + defaultColumnOrder);


    if (dateCreated == null || dateCreated.toString() == "") {
      this.alertService.openSnackBar("Please select an created date.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (appUserId == null || appUserId.toString() == "") {
      this.alertService.openSnackBar("Please select an app user.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (appUserColumnSetId == null || appUserColumnSetId.toString() == "") {
      this.alertService.openSnackBar("Please select an app column set.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (appPageColumnId == null || appPageColumnId.toString() == "") {
      this.alertService.openSnackBar("Please select an app page column.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (defaultColumnOrder == null || defaultColumnOrder == 0) {
      this.alertService.openSnackBar("Please enter a default column order.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (saveAppUserColumn) {

      let appUserColumnExists: AppUserColumn[] = [];
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guAppUserId", appUserId));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("guAppUserColumnSetId", appUserColumnSetId));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("guAppPageColumnId", appPageColumnId));
      aParamList.push(aParam);

      aParam = [];
      aParamList.push(aParam);
      appUserColumnExists = await this.appUserColumnService.getAppUserColumnParamArrayPromise(aParamList);

      if (appUserColumnExists != null && appUserColumnExists.length > 0 && appUserColumnExists[0].rowguid != this.appUserColumn.rowguid) {
        console.log("breaking out of for loop");
        this.alertService.openSnackBar("This column already exists for this user, page and column set", "Close", "center", "bottom", "", 3000);
        return;
      }

      this.appUserColumn.dteDateCreated = new Date(datepipe.transform(dateCreated, 'yyyy-MM-dd'));
      this.appUserColumn.guAppUserId = appUserId;
      this.appUserColumn.guAppUserColumnSetId = appUserColumnSetId;
      this.appUserColumn.guAppPageColumnId = appPageColumnId;
      this.appUserColumn.intColumnOrder = defaultColumnOrder;
      this.appUserColumn.blnVisible = visible;

      this.appUserColumn = await this.appUserColumnService.updateAppUserColumnPromise(this.appUserColumn);

      this.appUserColumnUpdated.emit(this.appUserColumn);
      console.log(this.appUserColumn);

    }

  }

  cancel() {
    this.resetFields('');
    this.appUserColumnCancelled.emit(true);

  }

  resetFields(mode: string) {

    this.form.controls['dteDateCreated'].setValue('');
    this.form.controls['guAppUserId'].setValue('');
    this.form.controls['guAppUserColumnSetId'].setValue('');
    this.form.controls['blnVisible'].setValue('1');
    this.form.controls['guAppPageId'].setValue('');
    this.form.controls['guAppPageColumnId'].setValue('');
    this.form.controls['intColumnOrder'].setValue('');

  }

  onUserChange(userId: string) {


  }

  async onPageChange(pageId: string) {

    console.log("pageId: " + pageId);

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guAppPageId", pageId));
    aParamList.push(aParam);
    this.appPageColumns = await this.appPageColumnService.getAppPageColumnParamArrayPromise(aParamList);

  }

  onAddAllColumnsChange(val: boolean) {

    console.log(val);

    if (val == true) {
      this.form.controls['blnVisible'].setValue(1);
      this.form.controls['blnVisible'].disable();
      this.form.controls['intColumnOrder'].disable();
    }

    if (val == false) {
      this.form.controls['blnVisible'].setValue(0);
      this.form.controls['blnVisible'].enable();
      this.form.controls['intColumnOrder'].enable();
    }
  }
}
