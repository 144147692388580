import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { AppService } from '../../_shared/services/app-services/app.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { AppPageService } from '../../_shared/services/app-page.service';


export enum enAppPageTabs { AppPages = 0, SearchOptions = 1, CreateAppPage = 2, UpdateAppPage = 3 }

@Component({
  selector: 'app-app-page-search',
  templateUrl: './app-page-search.component.html',
  styleUrls: ['./app-page-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class AppPageSearchComponent implements OnInit {

  @ViewChild('tabAppPages', { static: false }) tabAppPages: MatTabGroup;


  form: FormGroup;
  selectedTab: number;
  public enAppPageTabs = enAppPageTabs;

  appPages: MatTableDataSource<AppPage>;
  appPage: AppPage;

  apps: dtoIntKeyValue[] = [];

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  totalRows: number = 0;

  resultTime: number;
  searchTime: number;

  showSpinner: boolean = false;

  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private route: ActivatedRoute, private formService: FormService
    , private appPageService: AppPageService, private appService: AppService  ) {

    this.form = this.fb.group({
      txtSearchAppPageName: [''],
      radSearchApps: [''],
      txtSearchAppPageURL: [''],
      dteSearchDateCreatedFrom: [''],
      dteSearchDateCreatedTo: ['']
    });

    this.appPages = new MatTableDataSource();


    this.apps = this.sharedService.getEnumObjectKeyValue(appService.enApp);
    this.apps.splice(0, 0, new dtoIntKeyValue(-1, "None"));

    this.form.controls['radSearchApps'].setValue(-1);

  }

  ngOnInit(): void {

    this.selectedTab = this.enAppPageTabs.AppPages;

    this.route.queryParams.subscribe(params => {

      this.formService.setFieldsFromQueryParams(this.filterToQueryParamMap, params, this.form);

    });

    this.loadData();

  }

  async loadData() {

    //let aParamList: SQLParamArray[][] = [];
    //let aParam: SQLParamArray[] = [];

    //aParam.push(new SQLParamArray("dteDateCreated", '2022-01-01', SQLParamArray.enSQLOperator.GreaterThanOrEqualTo));
    //aParamList.push(aParam);

    //this.appPages.data = await this.appPageService.getAppPageParamArrayPromise(aParamList);

  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    if (e.index == this.enAppPageTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchAppName");
    }

  }


  searchAppPages() {

    let btnSearchAppPages = document.getElementById("btnSearchPageURL");
    if (btnSearchAppPages != null) {
      btnSearchAppPages.focus();
    }

    this.tabAppPages.selectedIndex = this.enAppPageTabs.AppPages;
    this.showSpinner = true;

    this.resultTime = 0;
    this.searchTime = Date.now();

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let appPageName: string = this.form.controls["txtSearchAppPageName"].value.toString();
    let app: string = this.form.controls["radSearchApps"].value.toString();
    let appPageURL: string = this.form.controls["txtSearchAppPageURL"].value;
    let createdDateFrom: string = this.form.controls["dteSearchDateCreatedFrom"].value;
    let createdDateTo: string = this.form.controls["dteSearchDateCreatedTo"].value;
    const datepipe: DatePipe = new DatePipe('en-AU');


    this.totalRows = 0;

    this.appPages.data = [];

    if (appPageName != "" && appPageName != null) {
      aParam = [];
      if (appPageName.indexOf(",")) {
        let aAppPageNames: string[] = appPageName.split(",");
        for (let i = 0; i <= aAppPageNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtPageName", aAppPageNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtPageName", appPageName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (app != "" && app != null && parseInt(app) > -1) {
      aParam = [];
      aParam.push(new SQLParamArray("intApp", app))
      aParamList.push(aParam);
    }


    if (appPageURL != "" && appPageURL != null) {
      aParam = [];
      if (appPageURL.indexOf(",")) {
        let aAppPageURLs: string[] = appPageURL.split(",");
        for (let i = 0; i <= aAppPageURLs.length - 1; i++) {
          aParam.push(new SQLParamArray("txtPageURL", aAppPageURLs[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtPageURL", appPageURL, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }


    if (createdDateFrom != "" && createdDateFrom != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdDateFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }

    if (createdDateTo != "" && createdDateTo != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdDateTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }


    if (aParamList.length == 0) {
      //this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "", 4000);
    }


    this.appPageService.getAppPageParamArray(aParamList).subscribe(appPages => {

      this.appPages.data = appPages;

      this.totalRows = appPages.length;

      this.resultTime = ((Date.now() - this.searchTime) / 1000);

      this.showSpinner = false;

    }, err => {
      console.log(err);
    });


  }


  async editAppPage(appPage: AppPage) {

    this.appPage = await this.appPageService.getAppPagePromise(appPage.rowguid);

    console.log(this.appPage);

    this.tabAppPages.selectedIndex = this.enAppPageTabs.UpdateAppPage;

  }

  async appPageCreated(appPage: AppPage) {
    if (appPage == null) {
      return;
    }

    this.tabAppPages.selectedIndex = this.enAppPageTabs.AppPages;

    this.form.controls['txtSearchAppPageName'].setValue(appPage.txtPageName);

    this.searchAppPages();

  }

  async appPageUpdated(appPage: AppPage) {

    if (appPage == null) {
      return;
    }

    this.tabAppPages.selectedIndex = this.enAppPageTabs.AppPages;

    this.form.controls['txtSearchAppPageName'].setValue(appPage.txtPageName);

    this.searchAppPages();

  }

  appPageCancelled() {

    this.tabAppPages.selectedIndex = this.enAppPageTabs.AppPages;

  }


}
