<h2>App User</h2>
<form [formGroup]="form">
  <div class="form-group">
    <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
      <mat-label>First Name</mat-label>
      <input class="textbox-input" matInput placeholder="i.e. John" value="" type="text" id="txtFirstName" formControlName="txtFirstName">
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
      <mat-label>Last Name</mat-label>
      <input class="textbox-input" matInput placeholder="i.e. Smith" value="" type="text" id="txtLastName" formControlName="txtLastName">
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
      <mat-label>User Name</mat-label>
      <input class="textbox-input" matInput placeholder="i.e. quotes@coffshardwoods.com.au;orders@coffshardwoods.com.au" value="" type="text" id="txtUserName" formControlName="txtUserName">
    </mat-form-field>

  </div>

  <div class="form-group">
    <mat-form-field class="example-full-width header-button from-address-form-field" appearance="fill">
      <mat-label>From Addresses</mat-label>
      <input class="textbox-input" matInput placeholder="i.e. quotes@coffshardwoods.com.au;orders@coffshardwoods.com.au" value="" type="text" id="txtFromAddresses" formControlName="txtFromAddresses">
    </mat-form-field>
  </div>

  <div class="search-option-row-1">
    <mat-form-field appearance="fill" class="chh-textbox-400">
      <mat-label>Employee Account</mat-label>
      <mat-select formControlName="guEmployeeId">
        <mat-option *ngFor="let employee of employees" [value]="employee.rowguid"><div class="first-name">{{employee.txtFirstName}}</div><div class="last-name">{{employee.txtLastName}}</div></mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-group" *ngIf="false">
    <label for="password">Password</label>
    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div *ngIf="f.password.errors.required">Password is required</div>
      <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
    </div>
  </div>

  <div class="form-group">
    <mat-checkbox class="example-margin" id="blnHas365Account" formControlName="blnHas365Account">Has 365 Account</mat-checkbox>
  </div>

  <div class="form-group">
    <mat-checkbox class="example-margin" id="blnHasReckonAccount" formControlName="blnHasReckonAccount">Has Reckon Account</mat-checkbox>
  </div>

  <div class="action-button-container">
    <button class="action-button" mat-flat-button color="primary" (click)="updateAppUser()">Update App User</button>
    <button class="action-button" mat-flat-button color="primary" (click)="cancelAppUser()">Cancel</button>
  </div>

</form>
