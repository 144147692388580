import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { AppUserPrinterDefault } from '../business-objects/app-user-printer-default.bo';

@Injectable()
export class AppUserPrinterDefaultService
{
  @Output() setDefaultPrinter = new EventEmitter<AppUserPrinterDefault>();

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getAppUserPrinterDefault(appUserPrinterDefaultId: string) {
		return this.http.get<AppUserPrinterDefault>(this._baseUrl + 'api/AppUserPrinterDefault/' + appUserPrinterDefaultId);
	}

	getAppUserPrinterDefaultParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<AppUserPrinterDefault[]>(this._baseUrl + 'api/AppUserPrinterDefault/GetAppUserPrinterDefaultsParamArray/', aParam);
	}

	getAllAppUserPrinterDefaults() {
		return this.http.get<AppUserPrinterDefault[]>(this._baseUrl + 'api/AppUserPrinterDefault/GetAllAppUserPrinterDefaults/');
	}

	//getAlldtoAppUserPrinterDefaults() {
	//	return this.http.get<dtoAppUserPrinterDefault[]>(this._baseUrl + 'api/AppUserPrinterDefault/GetAllAppUserPrinterDefaults/');
	//}

	createAppUserPrinterDefault(appUserPrinterDefault: AppUserPrinterDefault) {
		return this.http.post<AppUserPrinterDefault>(this._baseUrl + 'api/AppUserPrinterDefault/CreateAppUserPrinterDefault/', appUserPrinterDefault);
	}

	updateAppUserPrinterDefault(appUserPrinterDefault: AppUserPrinterDefault) {
		return this.http.put<AppUserPrinterDefault>(this._baseUrl + 'api/AppUserPrinterDefault/UpdateAppUserPrinterDefault/', appUserPrinterDefault);
	}

	deleteAppUserPrinterDefault(appUserPrinterDefaultId: string) {
		return this.http.delete(this._baseUrl + 'api/AppUserPrinterDefault/DeleteAppUserPrinterDefault/' + appUserPrinterDefaultId);
	}

	async getAppUserPrinterDefaultPromise(appUserPrinterDefaultId: string) {
		return new Promise<AppUserPrinterDefault>(resolve => {
			this.getAppUserPrinterDefault(appUserPrinterDefaultId).subscribe(appUserPrinterDefault => {
				resolve(appUserPrinterDefault);
			}, err => {
				this.alertService.openSnackBarError("Error getting AppUserPrinterDefault records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getAppUserDefaultPrinter(appUserId: string, printerType: number) {
    return new Promise<AppUserPrinterDefault>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guAppUserId", appUserId));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("intPrinterType", printerType.toString()));
      aParamList.push(aParam);

      this.getAppUserPrinterDefaultParamArray(aParamList).subscribe(appUserPrinterDefaults => {
        if (appUserPrinterDefaults != null && appUserPrinterDefaults.length > 0) {
          resolve(appUserPrinterDefaults[0]);
        }
        resolve(null);
      }, err => {
        this.alertService.openSnackBarError("Error getting AppUserPrinterDefault records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }


	async getAppUserPrinterDefaultParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<AppUserPrinterDefault[]>(resolve => {
			this.getAppUserPrinterDefaultParamArray(aParam).subscribe(appUserPrinterDefaults => {
				resolve(appUserPrinterDefaults);
			}, err => {
				this.alertService.openSnackBarError("Error getting AppUserPrinterDefault records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateAppUserPrinterDefaultPromise(appUserPrinterDefault: AppUserPrinterDefault) {
		return new Promise<AppUserPrinterDefault>(resolve => {
			this.updateAppUserPrinterDefault(appUserPrinterDefault).subscribe(appUserPrinterDefault => {
				resolve(appUserPrinterDefault);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating AppUserPrinterDefault record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createAppUserPrinterDefaultPromise(appUserPrinterDefault: AppUserPrinterDefault) {
		return new Promise<AppUserPrinterDefault>(resolve => {
      this.createAppUserPrinterDefault(appUserPrinterDefault).subscribe(appUserPrinterDefault => {
        this.setDefaultPrinter.emit(appUserPrinterDefault);

				resolve(appUserPrinterDefault);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating AppUserPrinterDefault record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deleteAppUserPrinterDefaultPromise(appUserPrinterDefaultId: string) {
		return new Promise<boolean>(resolve => {
			this.deleteAppUserPrinterDefault(appUserPrinterDefaultId).subscribe(appUserPrinterDefault => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing AppUserPrinterDefault record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
