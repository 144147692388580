import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dtoWebPublishStockSummary } from '../../_shared/business-objects/dto-web-publish-stock-summary.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { UtilityService } from '../../_shared/services/app-services/utility.service';
import { GuestService } from '../../_shared/services/guest.service';

@Component({
  selector: 'app-stock-for-sale',
  templateUrl: './stock-for-sale.component.html',
  styleUrl: './stock-for-sale.component.scss'
})
export class StockForSaleComponent implements OnInit {

  @Output() hideNavEvent = new EventEmitter<boolean>();

  stockItems: dtoWebPublishStockSummary[]
  viewDetail: boolean;
  selectedStockSummary: dtoWebPublishStockSummary;

  categoryParam: string;
  productParam: string;
  profileParam: string;
  widthParam: number;
  thicknessParam: number;
  speciesParam: string;
  visGradeParam: string;
  structGradeParam: string;


  constructor(private guestService: GuestService, private route: ActivatedRoute, private utilitityService: UtilityService) {
    this.viewDetail = false;
  }


  ngOnInit(): void {

    this.loadData()

  }

  async loadData() {

    await this.getParameters();

    console.log("about to get stock for sale");
    this.getStockForSale(this.categoryParam, this.productParam, this.profileParam, this.widthParam, this.thicknessParam, this.speciesParam, this.visGradeParam, this.structGradeParam)


  }

  async getParameters(): Promise<boolean> {

    
    this.route.queryParams.subscribe(async params => {

      if (params != null) {
        this.categoryParam = this.utilitityService.CString(params["category"]);
        this.productParam = this.utilitityService.CString(params["product"]);
        this.profileParam = this.utilitityService.CString(params["profile"]);
        this.widthParam = this.utilitityService.CNumber(params["width"]);
        this.thicknessParam = this.utilitityService.CNumber(params["thickness"]);
        this.speciesParam = this.utilitityService.CString(params["species"]);
        this.visGradeParam = this.utilitityService.CString(params["visgrade"]);
        this.structGradeParam = this.utilitityService.CString(params["structgrade"]);
        console.log("product param: ", this.productParam);
        return true;
      }

    });

    return false;

  }


  async getStockForSale(category: string, product: string, profile: string, width: number, thickness: number, species: string, visGrade: string, structGrade: string) {



    this.stockItems = await this.guestService.getSlobStockSummaryPromise(category, product, profile, width, thickness, species, visGrade, structGrade);

    if (this.stockItems == null) {
      return;
    }


    this.stockItems.sort(

      (a, b) => {
        // Sort by txtProductName (ascending)
        if (a.txtProductName < b.txtProductName) return -1;
        if (a.txtProductName > b.txtProductName) return 1;
        // Sort by intWidth (descending)
        if (a.intWidth < b.intWidth) return -1;
        if (a.intWidth > b.intWidth) return 1;
        // Sort by intThickness (ascending)
        if (a.intThickness < b.intThickness) return -1;
        if (a.intThickness > b.intThickness) return 1;
        // Sort by txtSpecies (ascending)
        if (a.txtSpeciesName < b.txtSpeciesName) return -1;
        if (a.txtSpeciesName > b.txtSpeciesName) return 1;
        // Sort by txtGradeName (ascending)
        if (a.txtGradeName < b.txtGradeName) return -1;
        if (a.txtGradeName > b.txtGradeName) return 1;
      }

    );
    console.log(this.stockItems);
  }

  viewProductDetail(slobStockSummaryItem: dtoWebPublishStockSummary) {

    this.selectedStockSummary = slobStockSummaryItem;

    console.log("slob stock summary", slobStockSummaryItem);


  }

  closeProductDetail(val) {

    this.selectedStockSummary = null;

  }

}
