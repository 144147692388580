<div class="customer-contact-select-container" #customerContactContainer>
  <mat-form-field class="chh-form-field example-full-width">
    <mat-label>Search Customer Contact</mat-label>
    <input matInput data-customer-contact-input="customerContactInput" class="customer-contact-input" placeholder="Filter by name" placeholderLabel="i.e. John Smith....." value="" type="text" #customerContactFilter (keyup)="filterCustomerContacts(customerContactFilter.value, $event)" (focus)="customerContactFilter.select()" (click)="checkCustomerContactListIsOpen(customerContactFilter.value)">
  </mat-form-field>

  <mat-icon matTooltip="Click to add a Customer Contact" class="clickable-icon person-add" (click)="addCustomerContact()">person_add</mat-icon>
  <span><mat-slide-toggle #multipleCustomerContacts (click)="checkMultipleCustomerContacts(multipleCustomerContacts.checked)" *ngIf="showMultiple">Multiple Customer Contacts</mat-slide-toggle></span>
  <div class="selected-customers-container">
    <div class="selected-customers" id="selectedCustomerContacts" *ngIf="selectedCustomerContacts != null && selectedCustomerContacts.length > 0">
      <div class="chip-column">
        <mat-chip-grid #chipGrid aria-label="Customer selection">
          @for (customerContact of customerContacts; track customerContact; let customerContactIdx = $index; let lastItem = $last) {
          <mat-chip-row data-customer-input="customerContactInput" (removed)="removeCustomerContact(customerContact)" (click)="selectChipCustomerContact($event, customerContact);customerContactFilter.select()">
            {{customerContact.txtName + ' ' + customerContact.txtLastName}}
            <button matChipRemove [attr.aria-label]="'remove ' + customerContact.txtName">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          }
        </mat-chip-grid>
        <input #customerContactChipInput [matChipInputFor]="chipGrid" />
      </div>
      <div class="see-more-chips">
        <button mat-icon-button aria-label="expand row" (click)="expandSelectedCustomerContacts($event)" *ngIf="selectedCustomerContacts != null && selectedCustomerContacts.length > 1">
          <mat-icon class="expand-icons customer-contact-selection-expand-icon material-icons-outlined" *ngIf="customerContactSelectionExpanded == false ">keyboard_arrow_down</mat-icon>
          <mat-icon class="expand-icons customer-contact-selection-expand-icon material-icons-outlined" *ngIf="customerContactSelectionExpanded == true">keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div data-customer-select="customerContactSelect2" class="customer-container" *ngIf="showCustomerContactList" #customerContactListContainer>
    <div data-customer-select="customerContactSelect2" id="customerContactSelect2" class="customer-contact-select-list">
      <div class="customer-contact-pin-row">
        <div class="button" data-push-pin="pushPin" id="btnPushPin" (click)="clickPin($event)" [ngClass]="customerContactListPinned == true ? 'button-pressed' : 'button'" matTooltip="{{toolTipText}}">
          <mat-icon data-push-pin="pushPin" class="pin-icon">push_pin</mat-icon>
        </div>
      </div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row-heading customer-contact-first-name-heading">First Name</div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row-heading customer-contact-last-name-heading">Last Name</div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row-heading customer-contact-phone-heading">Phone</div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row-heading customer-contact-mobile-heading">Mobile</div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row-heading customer-contact-mobile-heading">Email</div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row-heading customer-contact-town-heading-container">
        <div data-customer-contact-list="customerContactSelect2" class="customer-contact-town-heading">Town</div>
        <div data-customer-contact-list="customerContactSelect2" class="customer-contact-add-contact">
          <mat-icon data-customer-contact-add="customerContactAdd" matTooltip="Click to add a Customer Contact" class="clickable-icon customer-contact-add-icon" (click)="addCustomerContact(customer)">person_add</mat-icon>
        </div>
      </div>
      @for(customerContact of customerContactsFiltered; track customerContact; let idx = $index){
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row customer-contact-first-name" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()">{{customerContact.txtName}}</div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row customer-contact-last-name" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()">{{customerContact.txtLastName}}</div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row customer-contact-phone1" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()">{{customerContact.txtPhone1}}</div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row customer-contact-mobile" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()">{{customerContact.txtMobile}}</div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row customer-contact-email" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()" #emailDiv>
        <mat-icon data-customer-contact-add="customerCopyEmail" matTooltip="{{(customerContact.txtEmail == null || customerContact.txtEmail == '') ? 'There is no email set' : 'Click to Copy Email Address\n' + customerContact.txtEmail}}" matTooltipClass="chh-tooltip-max-width" class="clickable-icon email-customer-icon" (click)="copyToClipboard(customerContact.txtEmail)">content_copy</mat-icon>
        <mat-icon data-customer-contact-add="sendEmail" matTooltip="{{(customerContact.txtEmail == null || customerContact.txtEmail == '') ? 'There is no email set' : 'Click to Send The Contact An Email'}}" matTooltipClass="chh-tooltip-max-width" class="clickable-icon send-email-customer-icon material-icons-outlined" #emailIcon (click)="sendEmail(customerContact.txtEmail, emailDiv)">mail</mat-icon>
      </div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row customer-contact-town" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()">{{customerContact.txtTown}}</div>
      <div data-customer-contact-list="customerContactSelect2" class="customer-contact-row customer-contact-update" [ngClass]="contactHoverId == customerContact.rowguid ? 'customer-contact-row-hover' : 'customer-contact-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()">
        <div data-customer-contact-list="customerContactSelect2" class="customer-contact-update-contact">
          <mat-icon data-customer-contact-update="customerContactUpdate" matTooltip="Click to update a Customer Contact" class="clickable-icon edit-customer-contact-icon material-icons-outlined" (click)="updateCustomerContact(customerContact)">edit_document</mat-icon>
        </div>
      </div>

      }
    </div>

  </div>

</div>
