<div class="toc-container">
  <div class="toc-heading">Coffs Harbour Hardwoods <span class="toc-heading-dash">-</span> Terms & Conditions of Trade</div>
  <div class="toc-grid">
    <div class="toc-column-1">
      <div class="column-1">
        <div class="toc-row">
          <div class="number-column-span">1. </div>
          <div class="text-column-span bold-text">Definitions</div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            1.1
          </div>

          <div class="text-column-span">
            “Contract” means the terms and conditions contained herein,
            together with any Quotation, order, invoice or other document or
            amendments expressed to be supplemental to this Contract.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            1.2
          </div>

          <div class="text-column-span">
            “Supplier” means Coffs Harbour Hardwoods (Trading) Pty Ltd T/A
            Coffs Harbour Hardwoods, its successors and assigns or any person
            acting on behalf of and with the authority of Coffs Harbour
            Hardwoods (Trading) Pty Ltd T/A Coffs Harbour Hardwoods.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            1.3
          </div>

          <div class="text-column-span">
            “Customer” means the person/s, entities or any person acting on
            behalf of and with the authority of the Customer requesting the
            Supplier to provide the Services as specified in any proposal,
            quotation, order, invoice or other documentation, and:
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (a)
          </div><div class="text-column-span">
            if there is more than one Customer, is a reference to each Customer
            jointly and severally; and
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (b)
          </div><div class="text-column-span">
            if the Customer is a partnership, it shall bind each partner jointly and
            severally; and
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (c)
          </div><div class="text-column-span">
            if the Customer is a part of a Trust, shall be bound in their capacity
            as a trustee; and
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (d)
          </div><div class="text-column-span">
            includes the Customer’s executors, administrators, successors and
            permitted assigns.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            1.4
          </div>

          <div class="text-column-span">
            “Goods” means all Goods or Services supplied by the Supplier to the
            Customer at the Customer’s request from time to time (where the
            context so permits the terms ‘Goods’ or ‘Services’ shall be
            interchangeable for the other).
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            1.5
          </div>

          <div class="text-column-span">
            "Confidential Information” means information of a confidential nature
            whether oral, written or in electronic form including, but not limited to,
            this Contract, either party’s intellectual property, operational
            information, know-how, trade secrets, financial and commercial
            affairs, contracts, client information (including but not limited to,
            <span class=" bold-text">“Personal Information”</span> such as: name, address, D.O.B,
            occupation, driver’s license details, electronic contact (email,
            Facebook or Twitter details), medical insurance details or next of kin
            and other contact information (where applicable), previous credit
            applications, credit history) and pricing details.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            1.6
          </div>

          <div class="text-column-span">
            “Cookies” means small files which are stored on a user’s computer.
            They are designed to hold a modest amount of data (including
            Personal Information) specific to a particular client and website, and
            can be accessed either by the web server or the client’s computer.
            <span class="bold-text">
              If
              the Customer does not wish to allow Cookies to operate in the
              background when ordering from the website, then the
              Customer shall have the right to enable / disable the Cookies
              first by selecting the option to enable / disable provided on the
              website, prior to ordering Goods via the website.
            </span>
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            1.7
          </div>

          <div class="text-column-span">
            “Price” means the Price payable (plus any GST where applicable) for
            the Goods as agreed between the Supplier and the Customer in
            accordance with clause 5 below.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            1.8
          </div>
          <div class="text-column-span">
            “GST” means Goods and Services Tax as defined within the “A New
            Tax System (Goods and Services Tax) Act 1999” (Cth).
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span bold-text">
            2.
          </div>
          <div class="text-column-span bold-text">
            Acceptance
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            2.1
          </div>

          <div class="text-column-span">
            The Customer is taken to have exclusively accepted and is
            immediately bound, jointly and severally, by these terms and
            conditions if the Customer places an order for or accepts Delivery of
            the Goods.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            2.2
          </div>

          <div class="text-column-span">
            In the event of any inconsistency between the terms and conditions
            of this Contract and any other prior document or schedule that the
            parties have entered into, the terms of this Contract shall prevail.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            2.3
          </div>

          <div class="text-column-span">
            Any amendment to the terms and conditions contained in this
            Contract may only be amended in writing by the consent of both
            parties.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            2.4
          </div>

          <div class="text-column-span">
            The Customer acknowledges and accepts that:
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (a)
          </div>
          <div class="text-column-span">
            the supply of Goods on credit shall not take effect until the Customer
            has completed a credit application with the Supplier and it has been
            approved with a credit limit established for the account; and
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (b)
          </div>
          <div class="text-column-span">
            in the event that the supply of Goods request exceeds the
            Customers credit limit and/or the account exceeds the payment
            terms, the Supplier reserves the right to refuse Delivery; and
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (c)
          </div>
          <div class="text-column-span">
            the supply of Goods for accepted orders may be subject to
            availability and if, for any reason, Goods are not or cease to be
            available, the Supplier reserves the right to vary the Price with
            alternative Goods as per clause 5.2(c), subject to prior confirmation
            and agreement of both parties. The Supplier also reserves the right
            to halt all Services until such time as the Supplier and the Customer
            agree to such changes. The Supplier shall not be liable to the
            Customer for any loss or damage the Customer suffers due to the
            Suppllier exercising its rights under this clause.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            2.5
          </div>

          <div class="text-column-span">
            Any advice, recommendation, information, assistance or service
            provided by the Supplier in relation to Goods or Services supplied is
            given in good faith, is based on the Supplier’s own knowledge and
            experience and shall be accepted without liability on the part of the
            Supplier and it shall be the responsibility of the Customer to confirm
            the accuracy and reliability of the same in light of the use to which
            the Customer makes or intends to make of the Goods or Services.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            2.6
          </div>

          <div class="text-column-span">
            In the event that the Supplier is required to provide the Services
            outside of the normal business hours of between 8.30am to 5.00pm
            Monday to Friday, (including but not limited to Public Holidays), then
            the Supplier reserves the right to charge the Customer additional
            labour costs (penalty rates will apply), unless otherwise agreed
            between the Supplier and the Customer.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            2.7
          </div>

          <div class="text-column-span">
            Electronic signatures shall be deemed to be accepted by either party
            providing that the parties have complied with Section 9 of the
            Electronic Transactions Act 2000 or any other applicable provisions
            of that Act or any Regulations referred to in that Act.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span bold-text">
            3.
          </div>

          <div class="text-column-span bold-text">Errors and Omissions</div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            3.1
          </div>
          <div class="text-column-span">
            The Customer acknowledges and accepts that the Supplier shall,
            without prejudice, accept no liability in respect of any alleged or
            actual error(s) and/or omission(s):
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (a)
          </div>
          <div class="text-column-span">
            resulting from an inadvertent mistake made by the Supplier in the
            formation and/or administration of this Contract; and/or
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (b)
          </div>
          <div class="text-column-span">
            contained in/omitted from any literature (hard copy and/or electronic)
            supplied by the Supplier in respect of the Services.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            3.2
          </div>

          <div class="text-column-span">
            In the event such an error and/or omission occurs in accordance
            with clause 3.1, and is not attributable to the negligence and/or wilful
            misconduct of the Supplier; the Customer shall not be entitled to
            treat this Contract as repudiated nor render it invalid.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span bold-text">
            4.
          </div>

          <div class="text-column-span bold-text">
            Change in Control
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            4.1
          </div>

          <div class="text-column-span">
            The Customer shall give the Supplier not less than fourteen (14)
            days prior written notice of any proposed change of ownership of the
            Customer and/or any other change in the Customer’s details
            (including but not limited to, changes in the Customer’s name,
            address, contact phone or fax number/s, change of trustees, or
            business practice). The Customer shall be liable for any loss
            incurred by the Supplier as a result of the Customer’s failure to
            comply with this clause.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span bold-text">
            5.
          </div>

          <div class="text-column-span bold-text">
            Price and Payment
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            5.1
          </div>
          <div class="text-column-span">
            At the Supplier’s sole discretion, the Price shall be either:
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (a)
          </div>
          <div class="text-column-span">
            as indicated on any invoice provided by the Supplier to the
            Customer; or
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (b)
          </div>
          <div class="text-column-span">
            the Price as at the date of Delivery of the Goods according to the
            Supplier’s current price list; or
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (c)
          </div>
          <div class="text-column-span">
            the Supplier’s quoted price (subject to clause 5.2) which will be valid
            for the period stated in the quotation or otherwise for a period of
            thirty (30) days.
          </div>
        </div>

        <div class="toc-row">
          <div class="number-column-span">
            5.2
          </div>
          <div class="text-column-span">
            The Supplier reserves the right to change the Price:
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">(a) </div>
          <div class="text-column-span">
            if a variation to the Goods which are to be supplied is requested; or
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (b)
          </div>
          <div class="text-column-span">
            if a variation to the Services originally scheduled (including any
            applicable plans or specifications) is requested; or
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (c)
          </div>
          <div class="text-column-span">
            if during the course of the Services, the Goods are not or cease to
            be available from the Supplier’s third party suppliers, then the
            Supplier reserves the right to provide alternative Goods; or
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (d)
          </div>
          <div class="text-column-span">
            in the event of increases to the Supplier in the cost of labour or
            materials (including, but not limited to, any variation as a result of
            fluctuations in currency exchange rates or increases to the Supplier
            in the cost of taxes, levies, freight and insurance charges, or delays
            in shipment etc.) which are beyond the Supplier’s control.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            5.3
          </div>

          <div class="text-column-span">
            Variations will be charged for on the basis of the Supplier’s
            quotation, and will be detailed in writing, and shown as variations on
            the Supplier’s invoice. The Customer shall be required to respond to
            any variation submitted by the Supplier within ten (10) working days.
            Failure to do so will entitle the Supplier to add the cost of the
            variation to the Price. Payment for all variations must be made in full
            at the time of their completion.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            5.4
          </div>

          <div class="text-column-span">
            At the Supplier’s sole discretion, a non-refundable deposit may be
            required.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            5.5
          </div>
          <div class="text-column-span">
            Time for payment for the Goods being of the essence, the Price will
            be payable by the Customer on the date/s determined by the
            Supplier, which may be:
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (a)
          </div>
          <div class="text-column-span">
            on Delivery of the Goods;
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (b)
          </div>
          <div class="text-column-span">
            before Delivery of the Goods;
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (c)
          </div>
          <div class="text-column-span">
            by way of instalments/progress payments in accordance with the
            Supplier’s payment schedule;
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (d)
          </div>
          <div class="text-column-span">
            thirty (30) days following the end of the month in which a statement
            is posted to the Customer’s address or address for notices;
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (e)
          </div>
          <div class="text-column-span">
            the date specified on any invoice or other form as being the date for
            payment; or
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            (f)
          </div>
          <div class="text-column-span">
            failing any notice to the contrary, the date which is seven (7) days
            following the date of any invoice given to the Customer by the
            Supplier.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            5.6
          </div>

          <div class="text-column-span">
            The Supplier may, at the Supplier’s discretion, charge additional
            Delivery costs for any excessive weight of the Goods.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            5.7
          </div>

          <div class="text-column-span">
            Payment may be made by cash, cheque, bank cheque,
            electronic/on-line banking, credit card (a surcharge may apply per
            transaction), or by any other method as agreed to between the
            Customer and the Supplier.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            5.8
          </div>

          <div class="text-column-span">
            The Supplier may in its discretion allocate any payment received
            from the Customer towards any invoice that the Supplier determines
            and may do so at the time of receipt or at any time afterwards. On
            any default by the Customer the Supplier may re-allocate any
            payments previously received and allocated. In the absence of any
            payment allocation by the Supplier, payment will be deemed to be
            allocated in such manner as preserves the maximum value of the
            Supplier’s Purchase Money Security Interest (as defined in the
            PPSA) in the Goods.
          </div>
        </div>
        <div class="toc-row">
          <div class="number-column-span">
            5.9
          </div>

          <div class="text-column-span">
            The Customer shall not be entitled to set off against, or deduct from
            the Price, any sums owed or claimed to be owed to the Customer by
          </div>
        </div>

      </div>
    </div>

    <div class="toc-column-2">
      <div class="toc-row">
        <div class="number-column-span">
        </div>
        <div class="text-column-span">
          the Supplier nor to withhold payment of any invoice because part of
          that invoice is in dispute.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          5.10
        </div>
        <div class="text-column-span">
          Unless otherwise stated the Price does not include GST. In addition
          to the Price, the Customer must pay to the Supplier an amount equal
          to any GST the Supplier must pay for any supply by the Supplier
          under this or any other agreement for the sale of the Goods. The
          Customer must pay GST, without deduction or set off of any other
          amounts, at the same time and on the same basis as the Customer
          pays the Price. In addition, the Customer must pay any other taxes
          and duties that may be applicable in addition to the Price except
          where they are expressly included in the Price.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          6.
        </div>
        <div class="text-column-span bold-text">
          Delivery of Goods
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          6.1
        </div>
        <div class="text-column-span">
          Delivery (“<span class="bold-text">Delivery </span>”)of the Goods is taken to occur at the time that:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          the Customer or the Customer’s nominated carrier takes possession
          of the Goods at the Supplier’s address; or
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          the Supplier (or the Supplier’s nominated carrier) delivers the Goods
          to the Customer’s nominated address even if the Customer is not
          present at the address.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          6.2
        </div>
        <div class="text-column-span">
          At the Supplier’s sole discretion, the cost of Delivery is either
          included in the Price or is in addition to the Price.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          6.3
        </div>
        <div class="text-column-span">
          Any time specified by the Supplier for Delivery of the Goods is an
          estimate only. The Customer must take Delivery by receipt or
          collection of the Goods whenever they are tendered for Delivery.
          The Supplier will not be liable for any loss or damage incurred by the
          Customer as a result of Delivery being late. In the event that the
          Customer is unable to take Delivery of the Goods as arranged then
          the Supplier shall be entitled to charge a reasonable fee for
          redelivery and/or storage.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          6.4
        </div>
        <div class="text-column-span">
          The Supplier may deliver the Goods in separate instalments. Each
          separate instalment shall be invoiced and paid in accordance with
          the provisions in these terms and conditions.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          6.5
        </div>
        <div class="text-column-span">
          The Supplier’s crane trucks shall only deliver the Goods to the
          kerbside. Placement at the kerbside shall be at the Supplier’s
          discretion.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          6.6
        </div>
        <div class="text-column-span">
          The Supplier shall not be liable for any damage to property or
          persons where the Customer has requested the Supplier to place
          the Goods at an alternative site.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          6.7
        </div>
        <div class="text-column-span">
          For Delivery by a third party contractor, the Customer, or the
          Customer’s authorised representative shall be on site to receive the
          Goods. Unloading of the Goods shall be at the Customer ‘s
          expense. Where no assistance is provided by the Customer, the
          Supplier reserves the right to charge additional labour costs for the
          unloading of the vehicle, in accordance with clause 5.2.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          6.8
        </div>
        <div class="text-column-span">
          The Customer accepts and acknowledges that the Supplier will not
          deliver the Goods in inclement weather to prevent damage to the
          Goods. The Supplier will not be liable for any damage to the Goods
          where the Customer has requested, in writing, for the Goods to be
          delivered in such weather conditions.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          7.
        </div>
        <div class="text-column-span bold-text">
          Risk
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          7.1
        </div>
        <div class="text-column-span">
          Risk of damage to or loss of the Goods passes to the Customer on
          Delivery and the Customer must insure the Goods on or before
          Delivery.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          7.2
        </div>
        <div class="text-column-span">
          If any of the Goods are damaged or destroyed following Delivery but
          prior to ownership passing to the Customer, the Supplier is entitled
          to receive all insurance proceeds payable for the Goods. The
          production of these terms and conditions by the Supplier is sufficient
          evidence of the Supplier’s rights to receive the insurance proceeds
          without the need for any person dealing with the Supplier to make
          further enquiries.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          7.3
        </div>
        <div class="text-column-span">
          If the Customer requests the Supplier to leave Goods outside the
          Supplier’s premises for collection or to deliver the Goods to an
          unattended location, then such Goods shall be left at the Customer’s
          sole risk.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          7.4
        </div>
        <div class="text-column-span">
          The Supplier shall be entitled to rely on the accuracy of any plans,
          specifications and other information provided by the Customer. The
          Customer acknowledges and agrees that in the event that any of this
          information provided by the Customer is inaccurate, the Supplier
          accepts no responsibility for any loss, damages, or costs however
          resulting from these inaccurate plans, specifications or other
          information.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          7.5
        </div>
        <div class="text-column-span">
          Timber is a natural product and as such may exhibit variations in
          texture, shade, colour, surface, finish, markings, veining, and contain
          natural fissures, occlusions, and indentations.. Whilst every effort will
          be taken by the Supplier to match colour, texture and marking of
          such natural products, the Supplier shall not be liable for any loss,
          damages or costs (howsoever arising), resulting from any variation
          in colour, texture and marking between batches, or sale samples,
          and the final Goods provided.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          7.6
        </div>
        <div class="text-column-span">
          The Customer acknowledges and accepts that Goods supplied may
          also:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          expand, contract or distort as a result of exposure to heat, cold,
          weather;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          mark or stain if exposed to certain substances; and
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          be damaged or disfigured by impact or scratching.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          7.7
        </div>
        <div class="text-column-span">
          Timber is a hydroscopic material subject to expansion and
          contraction, therefore the Supplier will accept no responsibility for
          gaps that may appear during prolonged dry periods.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          8.
        </div>
        <div class="text-column-span bold-text">
          Access
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          8.1
        </div>
        <div class="text-column-span">
          The Customer shall ensure that the Supplier has clear and free
          access to the site at all times to enable them to undertake the
          Services. The Supplier shall not be liable for any loss or damage to
          the site (including, without limitation, damage to pathways,
          driveways and concreted or paved or grassed areas) unless due to
          the negligence of the Supplier.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          9.
        </div>
        <div class="text-column-span bold-text">
          Title
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          9.1
        </div>
        <div class="text-column-span">
          The Supplier and the Customer agree that ownership of the Goods
          shall not pass until:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          the Customer has paid the Supplier all amounts owing to the
          Supplier; and
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          the Customer has met all of its other obligations to the Supplier.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          9.2
        </div>
        <div class="text-column-span">
          Receipt by the Supplier of any form of payment other than cash shall
          not be deemed to be payment until that form of payment has been
          honoured, cleared or recognised.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          9.3
        </div>
        <div class="text-column-span">
          It is further agreed that, until ownership of the Goods passes to the
          Customer in accordance with clause 9.1:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          the Customer is only a bailee of the Goods and must return the
          Goods to the Supplier on request;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          the Customer holds the benefit of the Customer’s insurance of the
          Goods on trust for the Supplier and must pay to the Supplier the
          proceeds of any insurance in the event of the Goods being lost,
          damaged or destroyed;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          the Customer must not sell, dispose, or otherwise part with
          possession of the Goods other than in the ordinary course of
          business and for market value. If the Customer sells, disposes or
          parts with possession of the Goods then the Customer must hold the
          proceeds of any such act on trust for the Supplier and must pay or
          deliver the proceeds to the Supplier on demand;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (d)
        </div>
        <div class="text-column-span">
          the Customer should not convert or process the Goods or intermix
          them with other goods but if the Customer does so then the
          Customer holds the resulting product on trust for the benefit of the
          Supplier and must sell, dispose of or return the resulting product to
          the Supplier as it so directs;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (e)
        </div>
        <div class="text-column-span">
          the Customer irrevocably authorises the Supplier to enter any
          premises where the Supplier believes the Goods are kept and
          recover possession of the Goods;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (f)
        </div>
        <div class="text-column-span">
          the Supplier may recover possession of any Goods in transit
          whether or not Delivery has occurred;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (g)
        </div>
        <div class="text-column-span">
          the Customer shall not charge or grant an encumbrance over the
          Goods nor grant nor otherwise give away any interest in the Goods
          while they remain the property of the Supplier;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (h)
        </div>
        <div class="text-column-span">
          the Supplier may commence proceedings to recover the Price of the
          Goods sold notwithstanding that ownership of the Goods has not
          passed to the Customer.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          10.
        </div>
        <div class="text-column-span bold-text">
          Personal Property Securities Act 2009 (“PPSA”)
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          10.1
        </div>
        <div class="text-column-span">
          In this clause financing statement, financing change statement,
          security agreement, and security interest has the meaning given to it
          by the PPSA.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          10.2
        </div>
        <div class="text-column-span">
          Upon assenting to these terms and conditions in writing the
          Customer acknowledges and agrees that these terms and conditions
          constitute a security agreement for the purposes of the PPSA and
          creates a security interest in all Goods and/or collateral (account) –
          being a monetary obligation of the Customer to the Supplier for
          Services – that have previously been supplied and that will be
          supplied in the future by the Supplier to the Customer.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          10.3
        </div>
        <div class="text-column-span">
          The Customer undertakes to:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          promptly sign any further documents and/or provide any further
          information (such information to be complete, accurate and up-todate
          in all respects) which the Supplier may reasonably require to;
          <div class="toc-inner-row">
            <div class="number-inner-column-span">(i) </div>
            <div class="text-inner-column-span">
              register a financing statement or financing change statement in
              relation to a security interest on the Personal Property Securities
              Register;
            </div>
            <div class="number-inner-column-span">(ii) </div>
            <div class="text-inner-column-span">
              register any other document required to be registered by the
              PPSA; or
            </div>
            <div class="number-inner-column-span">(iii) </div>
            <div class="text-inner-column-span">
              correct a defect in a statement referred to in clause 10.3(a)(i) or
              10.3(a)(ii);
            </div>
          </div>
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          indemnify, and upon demand reimburse, the Supplier for all
          expenses incurred in registering a financing statement or financing
          change statement on the Personal Property Securities Register
          established by the PPSA or releasing any Goods charged thereby;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          not register a financing change statement in respect of a security
          interest without the prior written consent of the Supplier;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (d)
        </div>
        <div class="text-column-span">
          not register, or permit to be registered, a financing statement or a
          financing change statement in relation to the Goods and/or collateral
          (account) in favour of a third party without the prior written consent of
          the Supplier;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (e)
        </div>
        <div class="text-column-span">
          immediately advise the Supplier of any material change in its
          business practices of selling the Goods which would result in a
          change in the nature of proceeds derived from such sales.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          10.4
        </div>
        <div class="text-column-span">
          The Supplier and the Customer agree that sections 96, 115 and 125
          of the PPSA do not apply to the security agreement created by these
          terms and conditions.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          10.5
        </div>
        <div class="text-column-span">
          The Customer waives their rights to receive notices under sections
          95, 118, 121(4), 130, 132(3)(d) and 132(4) of the PPSA.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          10.6
        </div>
        <div class="text-column-span">
          The Customer waives their rights as a grantor and/or a debtor under
          sections 142 and 143 of the PPSA.
        </div>
      </div>
    </div>

    <div class="toc-column-3" *ngIf="true">
      <div class="toc-row">
        <div class="number-column-span">
          10.7
        </div>
        <div class="text-column-span">
          Unless otherwise agreed to in writing by the Supplier, the Customer waives their right to receive a verification statement in accordance with section 157 of the PPSA.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          10.8
        </div>
        <div class="text-column-span">
          The Customer must unconditionally ratify any actions taken by the Supplier under clauses 10.3 to 10.5.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          10.9
        </div>
        <div class="text-column-span">
          Subject to any express provisions to the contrary (including those contained in this clause 10), nothing in these terms and conditions is intended to have the effect of contracting out of any of the provisions of the PPSA.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          11.
        </div>
        <div class="text-column-span bold-text">
          Security and Charge
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          11.1
        </div>
        <div class="text-column-span">
          In consideration of the Supplier agreeing to supply the Goods, the Customer charges all of its rights, title and interest (whether joint or several) in any land, realty or other assets capable of being charged, owned by the Customer either now or in the future, to secure the performance by the Customer of its obligations under these terms and conditions (including, but not limited to, the payment of any money).
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          11.2
        </div>
        <div class="text-column-span">
          The Customer indemnifies the Supplier from and against all the Supplier’s costs and disbursements including legal costs on a solicitor and own client basis incurred in exercising the Supplier’s rights under this clause.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          11.3
        </div>
        <div class="text-column-span">
          The Customer irrevocably appoints the Supplier and each director of the Supplier as the Customer’s true and lawful attorney/s to perform all necessary acts to give effect to the provisions of this clause 11 including, but not limited to, signing any document on the Customer’s behalf.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          12.
        </div>
        <div class="text-column-span bold-text">
          Defects, Warranties and Returns, Competition and Consumer Act 2010 (CCA)
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.1
        </div>
        <div class="text-column-span">
          The Customer must inspect the Goods on Delivery and must within seven (7) days of Delivery notify the Supplier in writing of any evident defect/damage, shortage in quantity, or failure to comply with the description or quote. The Customer must notify any other alleged defect in the Goods as soon as reasonably possible after any such defect becomes evident. Upon such notification the Customer must allow the Supplier to inspect the Goods.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.2
        </div>
        <div class="text-column-span">
          Under applicable State, Territory and Commonwealth Law (including, without limitation the CCA), certain statutory implied guarantees and warranties (including, without limitation the statutory guarantees under the CCA) may be implied into these terms and conditions (Non-Excluded Guarantees).
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.3
        </div>
        <div class="text-column-span">
          The Supplier acknowledges that nothing in these terms and conditions purports to modify or exclude the Non-Excluded Guarantees.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.4
        </div>
        <div class="text-column-span">
          Except as expressly set out in these terms and conditions or in respect of the Non-Excluded Guarantees, the Supplier makes no warranties or other representations under these terms and conditions including but not limited to the quality or suitability of the Goods. The Supplier’s liability in respect of these warranties is limited to the fullest extent permitted by law.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.5
        </div>
        <div class="text-column-span">
          If the Customer is a consumer within the meaning of the CCA, the Supplier’s liability is limited to the extent permitted by section 64A of Schedule 2.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.6
        </div>
        <div class="text-column-span">
          If the Supplier is required to replace the Goods under this clause or the CCA, but is unable to do so, the Supplier may refund any money the Customer has paid for the Goods.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.7
        </div>
        <div class="text-column-span">
          If the Customer is not a consumer within the meaning of the CCA, the Supplier’s liability for any defect or damage in the Goods is:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          limited to the value of any express warranty or warranty card provided to the Customer by the Supplier at the Supplier’s sole discretion;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          limited to any warranty to which the Supplier is entitled, if the Supplier did not manufacture the Goods;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          otherwise negated absolutely.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.8
        </div>
        <div class="text-column-span">
          Subject to this clause 12, returns will only be accepted provided that:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          the Customer has complied with the provisions of clause 12.1; and
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          the Supplier has agreed that the Goods are defective; and
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          the Goods are returned within a reasonable time at the Customer’s cost (if that cost is not significant); and
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (d)
        </div>
        <div class="text-column-span">
          the Goods are returned in as close a condition to that in which they were delivered as is possible.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.9
        </div>
        <div class="text-column-span">
          Notwithstanding clauses 12.1 to 12.8 but subject to the CCA, the Supplier shall not be liable for any defect or damage which may be caused or partly caused by or arise as a result of:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          the Customer failing to properly maintain or store any Goods;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          the Customer using the Goods for any purpose other than that for which they were designed;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          the Customer continuing the use of any Goods after any defect became apparent or should have become apparent to a reasonably prudent operator or user;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (d)
        </div>
        <div class="text-column-span">
          the Customer failing to follow any instructions or guidelines provided by the Supplier;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (e)
        </div>
        <div class="text-column-span">
          fair wear and tear, any accident, or act of God.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.10
        </div>
        <div class="text-column-span">
          The Supplier may in its absolute discretion accept non-defective Goods for return in which case the Supplier may require the Customer to pay handling fees of up to twenty-five percent (25%) of the value of the returned Goods plus any freight costs.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.11
        </div>
        <div class="text-column-span">
          Notwithstanding anything contained in this clause if the Supplier is required by a law to accept a return then the Supplier will only accept a return on the conditions imposed by that law.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          12.12
        </div>
        <div class="text-column-span">
          Subject to clause 12.1, customised, or non-stocklist items or Goods made or ordered to the Customer’s specifications are not acceptable for credit or return.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          13.
        </div>
        <div class="text-column-span bold-text">
          Intellectual Property
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          13.1
        </div>
        <div class="text-column-span">
          Where the Supplier has designed, drawn or developed Goods for the Customer, then the copyright in any designs and drawings and documents shall remain the property of the Supplier. Under no circumstances may such designs, drawings and documents be used without the express written approval of the Supplier.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          13.2
        </div>
        <div class="text-column-span">
          The Customer warrants that all designs, specifications or instructions given to the Supplier will not cause the Supplier to infringe any patent, registered design or trademark in the execution of the Customer’s order and the Customer agrees to indemnify the Supplier against any action taken by a third party against the Supplier in respect of any such infringement.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          13.3
        </div>
        <div class="text-column-span">
          The Customer agrees that the Supplier may (at no cost) use for the purposes of marketing or entry into any competition, any documents, designs, drawings or Goods which the Supplier has created for the Customer.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          14.
        </div>
        <div class="text-column-span bold-text">
          Default and Consequences of Default
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          14.1
        </div>
        <div class="text-column-span">
          Interest on overdue invoices shall accrue daily from the date when payment becomes due, until the date of payment, at a rate of two and a half percent (2.5%) per calendar month (and at the Supplier’s sole discretion such interest shall compound monthly at such a rate) after as well as before any judgment.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          14.2
        </div>
        <div class="text-column-span">
          If the Customer owes the Supplier any money the Customer shall indemnify the Supplier from and against all costs and disbursements incurred by the Supplier in recovering the debt (including but not limited to internal administration fees, legal costs on a solicitor and own client basis, the Supplier’s contract default fee, and bank dishonour fees).
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          14.3
        </div>
        <div class="text-column-span">
          Further to any other rights or remedies the Supplier may have under this Contract, if a Customer has made payment to the Supplier, and the transaction is subsequently reversed, the Customer shall be liable for the amount of the reversed transaction, in addition to any further costs incurred by the Supplier under this clause 14 where it can be proven that such reversal is found to be illegal, fraudulent or in contravention to the Customer’s obligations under this Contract.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          14.4
        </div>
        <div class="text-column-span">
          Without prejudice to the Supplier’s other remedies at law the Supplier shall be entitled to cancel all or any part of any order of the Customer which remains unfulfilled and all amounts owing to the Supplier shall, whether or not due for payment, become immediately payable if:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          any money payable to the Supplier becomes overdue, or in the Supplier’s opinion the Customer will be unable to make a payment when it falls due;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          the Customer has exceeded any applicable credit limit provided by the Supplier;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          the Customer becomes insolvent, convenes a meeting with its creditors or proposes or enters into an arrangement with creditors, or makes an assignment for the benefit of its creditors; or
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (d)
        </div>
        <div class="text-column-span">
          a receiver, manager, liquidator (provisional or otherwise) or similar person is appointed in respect of the Customer or any asset of the Customer.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          15.
        </div>
        <div class="text-column-span bold-text">
          Cancellation
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          15.1
        </div>
        <div class="text-column-span">
          Without prejudice to any other remedies the Supplier may have, if at any time the Customer is in breach of any obligation (including those relating to payment) under these terms and conditions the Supplier may suspend or terminate the supply of Goods to the Customer. The Supplier will not be liable to the Customer for any loss or damage the Customer suffers because the Supplier has exercised its rights under this clause.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          15.2
        </div>
        <div class="text-column-span">
          The Supplier may cancel any contract to which these terms and conditions apply or cancel Delivery of Goods at any time before the Goods are delivered by giving written notice to the Customer. On giving such notice the Supplier shall repay to the Customer any money paid by the Customer for the Goods. The Supplier shall not be liable for any loss or damage whatsoever arising from such cancellation.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          15.3
        </div>
        <div class="text-column-span">
          In the event that the Customer cancels Delivery of Goods the Customer shall be liable for any and all loss incurred (whether direct or indirect) by the Supplier as a direct result of the cancellation (including, but not limited to, any loss of profits).
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          15.4
        </div>
        <div class="text-column-span">
          Cancellation of orders for Goods made to the Customer’s specifications, or for non-stocklist items, will definitely not be accepted once production has commenced, or an order has been placed.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          16.
        </div>
        <div class="text-column-span bold-text">
          Privacy Policy
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.1
        </div>
        <div class="text-column-span">
          All emails, documents, images or other recorded information held or used by the Supplier is Personal Information, as defined and referred to in clause 16.3, and therefore considered Confidential Information. The Supplier acknowledges its obligation in relation to the handling, use, disclosure and processing of Personal Information pursuant to the Privacy Act 1988 (“the Act”) including the Part IIIC of the Act being Privacy Amendment (Notifiable Data Breaches) Act 2017 (NDB) and any statutory requirements, where relevant in a
        </div>
      </div>
    </div>

    <div class="toc-column-4" *ngIf="true">
      <div class="toc-row">
        <div class="number-column-span">
        </div>
        <div class="text-column-span">
          European Economic Area (“EEA”), under the EU Data Privacy Laws (including the General Data Protection Regulation “GDPR”) (collectively, “EU Data Privacy Laws”). The Supplier acknowledges that in the event it becomes aware of any data breaches and/or disclosure of the Customers Personal Information, held by the Supplier that may result in serious harm to the Customer, the Supplier will notify the Customer in accordance with the Act and/or the GDPR. Any release of such Personal Information must be in accordance with the Act and the GDPR (where relevant) and must be approved by the Customer by written consent, unless subject to an operation of law.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.2
        </div>
        <div class="text-column-span">
          Notwithstanding clause 16.1, privacy limitations will extend to the Supplier in respect of Cookies where transactions for purchases/orders transpire directly from the Supplier’s website. The Supplier agrees to display reference to such Cookies and/or similar tracking technologies, such as pixels and web beacons (if applicable), such technology allows the collection of Personal Information such as the Customer’s:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          IP address, browser, email client type and other similar details;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          tracking website usage and traffic; and
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          reports are available to the Supplier when the Supplier sends an email to the Customer, so the Supplier may collect and review that information (“collectively Personal Information”)
          In order to enable / disable the collection of Personal Information by way of Cookies, the Customer shall have the right to enable / disable the Cookies first by selecting the option to enable / disable, provided on the website prior to proceeding with a purchase/order via the Supplier’s website.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.3
        </div>
        <div class="text-column-span">
          The Customer agrees for the Supplier to obtain from a credit reporting body (CRB) a credit report containing personal credit information (e.g. name, address, D.O.B, occupation, driver’s license details, electronic contact (email, Facebook or Twitter details), medical insurance details or next of kin and other contact information (where applicable), previous credit applications, credit history) about the Customer in relation to credit provided by the Supplier.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.4
        </div>
        <div class="text-column-span">
          The Customer agrees that the Supplier may exchange information about the Customer with those credit providers and with related body corporates for the following purposes:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          to assess an application by the Customer; and/or
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          to notify other credit providers of a default by the Customer; and/or
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          to exchange information with other credit providers as to the status of this credit account, where the Customer is in default with other credit providers; and/or
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (d)
        </div>
        <div class="text-column-span">
          to assess the creditworthiness of the Customer including the Customer’s repayment history in the preceding two (2) years.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.5
        </div>
        <div class="text-column-span">
          The Customer consents to the Supplier being given a consumer credit report to collect overdue payment on commercial credit.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.6
        </div>
        <div class="text-column-span">
          The Customer agrees that personal credit information provided may be used and retained by the Supplier for the following purposes (and for other agreed purposes or required by):
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          the provision of Goods; and/or
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          analysing, verifying and/or checking the Customer’s credit, payment and/or status in relation to the provision of Goods; and/or
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          processing of any payment instructions, direct debit facilities and/or credit facilities requested by the Customer; and/or
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (d)
        </div>
        <div class="text-column-span">
          enabling the collection of amounts outstanding in relation to the Goods.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.7
        </div>
        <div class="text-column-span">
          The Supplier may give information about the Customer to a CRB for the following purposes:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          to obtain a consumer credit report;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          allow the CRB to create or maintain a credit information file about the Customer including credit history.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.8
        </div>
        <div class="text-column-span">
          The information given to the CRB may include:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          Personal Information as outlined in 16.3 above;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          name of the credit provider and that the Supplier is a current credit provider to the Customer;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          whether the credit provider is a licensee;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (d)
        </div>
        <div class="text-column-span">
          type of consumer credit;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (e)
        </div>
        <div class="text-column-span">
          details concerning the Customer’s application for credit or commercial credit (e.g. date of commencement/termination of the credit account and the amount requested);
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (f)
        </div>
        <div class="text-column-span">
          advice of consumer credit defaults, overdue accounts, loan repayments or outstanding monies which are overdue by more than sixty (60) days and for which written notice for request of payment has been made and debt recovery action commenced or alternatively that the Customer no longer has any overdue accounts and the Supplier has been paid or otherwise discharged and all details surrounding that discharge (e.g. dates of payments);
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (g)
        </div>
        <div class="text-column-span">
          information that, in the opinion of the Supplier, the Customer has committed a serious credit infringement;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (h)
        </div>
        <div class="text-column-span">
          advice that the amount of the Customer’s overdue payment is equal to or more than one hundred and fifty dollars ($150).
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.9
        </div>
        <div class="text-column-span">
          The Customer shall have the right to request (by e-mail) from the Supplier:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          a copy of the Personal Information about the Customer retained by the Supplier and the right to request that the Supplier correct any incorrect Personal Information; and
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          that the Supplier does not disclose any Personal Information about the Customer for the purpose of direct marketing.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.10
        </div>
        <div class="text-column-span">
          The Supplier will destroy Personal Information upon the Customer’s request (by e-mail) or if it is no longer required unless it is required in order to fulfil the obligations of this Contract or is required to be maintained and/or stored in accordance with the law.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          16.11
        </div>
        <div class="text-column-span">
          The Customer can make a privacy complaint by contacting the Supplier via e-mail. The Supplier will respond to that complaint within seven (7) days of receipt and will take all reasonable steps to make a decision as to the complaint within thirty (30) days of receipt of the complaint. In the event that the Customer is not satisfied with the resolution provided, the Customer can make a complaint to the Information Commissioner at www.oaic.gov.au.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          17.
        </div>
        <div class="text-column-span bold-text">
          Compliance with Laws
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          17.1
        </div>
        <div class="text-column-span">
          The Customer and the Supplier shall comply with the provisions of all statutes, regulations and bylaws of government, local and other public authorities that may be applicable to the Services.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          17.2
        </div>
        <div class="text-column-span">
          The Customer shall obtain (at the expense of the Customer) all licenses and approvals that may be required for the Services.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          17.3
        </div>
        <div class="text-column-span">
          The Customer agrees that the site will comply with any work health and safety (WHS) laws relating to building/construction sites and any other relevant safety standards or legislation.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          18.
        </div>
        <div class="text-column-span bold-text">
          Trusts
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          18.1
        </div>
        <div class="text-column-span">
          If the Customer at any time upon or subsequent to entering in to the Contract is acting in the capacity of trustee of any trust (“Trust”) then whether or not the Supplier may have notice of the Trust, the Customer covenants with the Supplier as follows:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (a)
        </div>
        <div class="text-column-span">
          the Contract extends to all rights of indemnity which the Customer now or subsequently may have against the Trust and the trust fund;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (b)
        </div>
        <div class="text-column-span">
          the Customer has full and complete power and authority under the Trust to enter into the Contract and the provisions of the Trust do not purport to exclude or take away the right of indemnity of the Customer against the Trust or the trust fund. The Customer will not release the right of indemnity or commit any breach of trust or be a party to any other action which might prejudice that right of indemnity;
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          (c)
        </div>
        <div class="text-column-span">
          the Customer will not without consent in writing of the Supplier (the Supplier will not unreasonably withhold consent), cause, permit, or suffer to happen any of the following events:
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
        </div>
        <div class="text-column-span">
          <div class="toc-inner-row">
            <div class="number-inner-column-span">
              (i)
            </div>
            <div class="text-inner-column-span">
              the removal, replacement or retirement of the Customer as trustee of the Trust;
            </div>
            <div class="number-inner-column-span">
              (ii)
            </div>
            <div class="text-inner-column-span">
              any alteration to or variation of the terms of the Trust;
            </div>
            <div class="number-inner-column-span">
              (iii)
            </div>
            <div class="text-inner-column-span">
              any advancement or distribution of capital of the Trust; or
            </div>
            <div class="number-inner-column-span">
              (iv)
            </div>
            <div class="text-inner-column-span">
              any resettlement of the trust property.
            </div>
          </div>
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span bold-text">
          19.
        </div>
        <div class="text-column-span bold-text">
          General
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          19.1
        </div>
        <div class="text-column-span">
          The failure by either party to enforce any provision of these terms and conditions shall not be treated as a waiver of that provision, nor shall it affect that party’s right to subsequently enforce that provision. If any provision of these terms and conditions shall be invalid, void, illegal or unenforceable the validity, existence, legality and enforceability of the remaining provisions shall not be affected, prejudiced or impaired.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          19.2
        </div>
        <div class="text-column-span">
          These terms and conditions and any contract to which they apply shall be governed by the laws of the state in which the Supplier has its principal place of business, and are subject to the jurisdiction of the courts in that state.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          19.3
        </div>
        <div class="text-column-span">
          Subject to clause 12, the Supplier shall be under no liability whatsoever to the Customer for any indirect and/or consequential loss and/or expense (including loss of profit) suffered by the Customer arising out of a breach by the Supplier of these terms and conditions (alternatively the Supplier’s liability shall be limited to damages which under no circumstances shall exceed the Price of the Goods).
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          19.4
        </div>
        <div class="text-column-span">
          The Supplier may licence and/or assign all or any part of its rights and/or obligations under this Contract without the Customer’s consent.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          19.5
        </div>
        <div class="text-column-span">
          The Customer cannot licence or assign without the written approval of the Supplier.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          19.6
        </div>
        <div class="text-column-span">
          The Supplier may elect to subcontract out any part of the Services but shall not be relieved from any liability or obligation under this Contract by so doing. Furthermore, the Customer agrees and understands that they have no authority to give any instruction to any of the Supplier’s sub-contractors without the authority of the Supplier.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          19.7
        </div>
        <div class="text-column-span">
          The Customer agrees that the Supplier may amend their general terms and conditions for subsequent future contracts with the Customer by disclosing such to the Customer in writing. These changes shall be deemed to take effect from the date on which the Customer accepts such changes, or otherwise at such time as the Customer makes a further request for the Supplier to provide Goods to the Customer.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          19.8
        </div>
        <div class="text-column-span">
          Neither party shall be liable for any default due to any act of God, war, terrorism, strike, lock-out, industrial action, fire, flood, storm or other event beyond the reasonable control of either party.
        </div>
      </div>
      <div class="toc-row">
        <div class="number-column-span">
          19.9
        </div>
        <div class="text-column-span">
          Both parties warrant that they have the power to enter into this Contract and have obtained all necessary authorisations to allow them to do so, they are not insolvent and that this Contract creates binding and valid legal obligations on them.
        </div>
      </div>
    </div>

  </div>

  <div class="toc-footer-container">
    <div class="toc-footer-note">Please note that a larger print version of these terms and conditions is available from the Supplier on request.</div>
    <div class="toc-footer-copy-right">#38036 © Copyright – EC Credit Control 1999 - 2019</div>
  </div>
</div>
