import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { SharedService } from '../../_shared/services/shared.service';
import { AppPageService } from '../../_shared/services/app-page.service';
import { AppService } from '../../_shared/services/app-services/app.service';


@Component({
  selector: 'app-app-page-update',
  templateUrl: './app-page-update.component.html',
  styleUrls: ['./app-page-update.component.css']
})
export class AppPageUpdateComponent implements OnInit {

  form: FormGroup;
  apps: dtoIntKeyValue[] = [];

  @Output() appPageUpdated = new EventEmitter<AppPage>();
  @Output() appPageCancelled = new EventEmitter<boolean>();
 @Input() appPage: AppPage;


  constructor(private fb: FormBuilder, private appService: AppService, private sharedService: SharedService, private alertService: AlertService, private appPageService: AppPageService) {

    const datepipe: DatePipe = new DatePipe('en-AU');

    this.form = this.fb.group({
      dteDateCreated: [''],
      txtAppPageName: [''],
      txtAppPageURL: [''],
      txtAppPageObjectName: [''],
      txtAppPageObjectAliasName: [''],
      radApp: ['']
    });


    this.apps = this.sharedService.getEnumObjectKeyValue(appService.enApp);


  }

  ngOnInit(): void {

    if (this.appPage == null) {
      this.alertService.openSnackBar("There is no app page to update.", "Close", "center", "bottom", "", 3000);
      return;
    }

    this.form.controls['dteDateCreated'].enable();
    this.form.controls['dteDateCreated'].setValue(this.appPage.dteDateCreated);
    this.form.controls['dteDateCreated'].disable();
    this.form.controls['txtAppPageName'].setValue(this.appPage.txtPageName);
    this.form.controls['txtAppPageURL'].setValue(this.appPage.txtPageURL);
    this.form.controls['txtAppPageObjectName'].setValue(this.appPage.txtAppPageObjectName);
    this.form.controls['txtAppPageObjectAliasName'].setValue(this.appPage.txtAppPageObjectAliasName);
    this.form.controls['radApp'].setValue(this.appPage.intApp);


  }


  async updateAppPage() {

    let saveAppPage: boolean = true;
    let dateCreated: Date = new Date(this.form.controls['dteDateCreated'].value.toString());
    let appPageName: string = this.form.controls['txtAppPageName'].value;
    let appPageURL: string = this.form.controls['txtAppPageURL'].value;
    let appPageObjectName: string = this.form.controls['txtAppPageObjectName'].value;
    let appPageObjectAliasName: string = this.form.controls['txtAppPageObjectAliasName'].value;
    let app: number = this.form.controls['radApp'].value;
    const datepipe: DatePipe = new DatePipe('en-AU');

    if (dateCreated == null || dateCreated.toString() == "") {
      this.alertService.openSnackBar("Please select an created date.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (appPageName == null || appPageName.toString() == "") {
      this.alertService.openSnackBar("Please enter a name.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (appPageURL == null || appPageURL.toString() == "") {
      this.alertService.openSnackBar("Please enter a url.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (app == null || app == -1) {
      this.alertService.openSnackBar("Please select an app.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (saveAppPage) {
      let appPage: AppPage = this.appPage;

      appPage.dteDateCreated = new Date(datepipe.transform(dateCreated, 'yyyy-MM-dd'));
      appPage.txtPageName = appPageName;
      appPage.txtPageURL = appPageURL;
      appPage.intApp = app;
      appPage.txtAppPageObjectName = appPageObjectName;
      appPage.txtAppPageObjectAliasName = appPageObjectAliasName;

      appPage = await this.appPageService.updateAppPagePromise(appPage);

      appPage = await this.appPageService.getAppPagePromise(appPage.rowguid);
      this.appPageUpdated.emit(appPage);

    }


  }

  cancel() {
    this.resetFields();
    this.appPageCancelled.emit(true);
  }

  resetFields() {

    this.form.controls['dteDateCreated'].setValue(this.sharedService.currentDatePlusTZOffset.toString());
    this.form.controls['txtAppPageName'].setValue('');
    this.form.controls['txtAppPageURL'].setValue('');
    this.form.controls['txtAppPageObjectName'].setValue('');
    this.form.controls['txtAppPageObjectAliasName'].setValue('');
    this.form.controls['radApp'].setValue('');

  }

}
