import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoTPPoleInspectionItemSummary } from '../business-objects/dto-tppole-inspection-item-summary.bo';
import { AppUserInfoService } from './app-user-info.service';

@Injectable()
export class dtoTPPoleInspectionItemSummaryService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private appUserInfoService: AppUserInfoService) {
		this._baseUrl = baseUrl;
	}

	getdtoTPPoleInspectionItemSummary(dtoTPPoleInspectionItemSummaryId: string) {
		return this.http.get<dtoTPPoleInspectionItemSummary>(this._baseUrl + 'api/dtoTPPoleInspectionItemSummary/' + dtoTPPoleInspectionItemSummaryId);
	}

	getdtoTPPoleInspectionItemSummaryParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoTPPoleInspectionItemSummary[]>(this._baseUrl + 'api/dtoTPPoleInspectionItemSummary/GetdtoTPPoleInspectionItemSummarysParamArray/', aParam);
	}

	getAlldtoTPPoleInspectionItemSummarys() {
		return this.http.get<dtoTPPoleInspectionItemSummary[]>(this._baseUrl + 'api/dtoTPPoleInspectionItemSummary/GetAlldtoTPPoleInspectionItemSummarys/');
	}

	//getAlldtodtoTPPoleInspectionItemSummarys() {
	//	return this.http.get<dtodtoTPPoleInspectionItemSummary[]>(this._baseUrl + 'api/dtoTPPoleInspectionItemSummary/GetAlldtoTPPoleInspectionItemSummarys/');
	//}

	createdtoTPPoleInspectionItemSummary(dtoTPPoleInspectionItemSummary: dtoTPPoleInspectionItemSummary) {
		return this.http.post<dtoTPPoleInspectionItemSummary>(this._baseUrl + 'api/dtoTPPoleInspectionItemSummary/CreatedtoTPPoleInspectionItemSummary/', dtoTPPoleInspectionItemSummary);
	}

	updatedtoTPPoleInspectionItemSummary(dtoTPPoleInspectionItemSummary: dtoTPPoleInspectionItemSummary) {
		return this.http.put<dtoTPPoleInspectionItemSummary>(this._baseUrl + 'api/dtoTPPoleInspectionItemSummary/UpdatedtoTPPoleInspectionItemSummary/', dtoTPPoleInspectionItemSummary);
	}

	async getdtoTPPoleInspectionItemSummaryPromise(dtoTPPoleInspectionItemSummaryId: string) {
		return new Promise<dtoTPPoleInspectionItemSummary>(resolve => {
			this.getdtoTPPoleInspectionItemSummary(dtoTPPoleInspectionItemSummaryId).subscribe(dtoTPPoleInspectionItemSummary => {
				resolve(dtoTPPoleInspectionItemSummary);
			}, err => {
        this.alertService.openSnackBarError("Error getting dtoTPPoleInspectionItemSummary records", "Close", "center", "bottom", 4000, true, err.error + "<br>" + this.appUserInfoService.getAppUserUserName());
				resolve(null);
			});
		});
	}

	async getdtoTPPoleInspectionItemSummaryParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoTPPoleInspectionItemSummary[]>(resolve => {
			this.getdtoTPPoleInspectionItemSummaryParamArray(aParam).subscribe(dtoTPPoleInspectionItemSummarys => {
				resolve(dtoTPPoleInspectionItemSummarys);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTPPoleInspectionItemSummary records", "Close", "center", "bottom", 4000, true, err.error + "<br>" + this.appUserInfoService.getAppUserUserName());
				resolve(null);
			});
		});
	}

	async updatedtoTPPoleInspectionItemSummaryPromise(dtoTPPoleInspectionItemSummary: dtoTPPoleInspectionItemSummary) {
		return new Promise<dtoTPPoleInspectionItemSummary>(resolve => {
			this.updatedtoTPPoleInspectionItemSummary(dtoTPPoleInspectionItemSummary).subscribe(dtoTPPoleInspectionItemSummary => {
				resolve(dtoTPPoleInspectionItemSummary);
			}, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating dtoTPPoleInspectionItemSummary record", "Close", "center", "bottom", 4000, true, err.error + "<br>" + this.appUserInfoService.getAppUserUserName());
				resolve(null);
			});
		});
	}

	async createdtoTPPoleInspectionItemSummaryPromise(dtoTPPoleInspectionItemSummary: dtoTPPoleInspectionItemSummary) {
		return new Promise<dtoTPPoleInspectionItemSummary>(resolve => {
			this.createdtoTPPoleInspectionItemSummary(dtoTPPoleInspectionItemSummary).subscribe(dtoTPPoleInspectionItemSummary => {
				resolve(dtoTPPoleInspectionItemSummary);
			}, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating dtoTPPoleInspectionItemSummary record", "Close", "center", "bottom", 4000, true, err.error + "<br>" + this.appUserInfoService.getAppUserUserName());
				resolve(null);
			});
		});
	}

}
