<div class="delivery-docket-create-container">
  <div class="delivery-docket-create-form-container">
    <form class="delivery-docket-create-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="header-row-4">
            <div class="customer-list">
              <mat-form-field class="chh-form-field example-full-width">
                <mat-label>Search Customer</mat-label>
                <input matInput class="customer-input" placeholder="Filter by name" placeholderLabel="i.e. Essential....." value="" type="text" #customerFilter (keyup)="filterCustomers(customerFilter.value)" (focus)="customerFilter.select()">
              </mat-form-field>
              <mat-selection-list class="customer-select" #guCustomerId [multiple]="false" id="guCustomerId" formControlName="guCustomerId" (selectionChange)="customerChanged(guCustomerId._value[0])">
                <mat-list-option *ngFor="let customer of customers" [value]="customer.rowguid">
                  {{customer.txtName}}
                </mat-list-option>
              </mat-selection-list>
              <br />
            </div>
          </div>

          <div class="header-row-4">
            <div class="delivery-docket-customer-contacts-container">
              <mat-form-field class="chh-form-field example-full-width">
                <mat-label>Search Customer Contact</mat-label>
                <input matInput class="customer-contact-input" placeholder="Filter by name" placeholderLabel="i.e. John....." value="" type="text" #customerContactFilter (keyup)="filterCustomerContacts(customerContactFilter.value)" (focus)="customerContactFilter.select()">
              </mat-form-field>

              <mat-icon matTooltip="Click to add a Customer Contact" class="clickable-icon person-add" (click)="addCustomerContact(guCustomerId)">person_add</mat-icon>
              <mat-icon matTooltip="Click to update selected Customer Contact" class="clickable-icon person-add material-icons-outlined" (click)="showEditButton()">edit_note</mat-icon>

              <div class="customer-contact-select-container">
                <div id="customerDeliveryAddressSelect" class="customer-contact-select-list">
                  @for(customerContact of customerContacts; track customerContact){
                  <div class="chh-list-item-row chh-list-item-first-column customer-contact-first-name" [ngClass]="customerContact.guCustomerDeliveryContactId != '' ? 'chh-list-item-row chh-list-item-row-selected customer-contact-first-name' : addressHoverId == customerContact.rowguid ? 'chh-list-item-row chh-list-item-row-hover customer-contact-first-name' : 'chh-list-item-row customer-contact-first-name'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()" (click)="updateDeliveryContact2(customerContact, !(customerContact.guCustomerDeliveryContactId != ''))"><div class="contact-text">{{customerContact.txtName}}</div></div>
                  <div class="chh-list-item-row customer-contact-last-name" [ngClass]="customerContact.guCustomerDeliveryContactId != '' ? 'chh-list-item-row chh-list-item-row-selected customer-contact-last-name' : addressHoverId == customerContact.rowguid ? 'chh-list-item-row chh-list-item-row-hover customer-contact-last-name' : 'chh-list-item-row customer-contact-last-name'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()" (click)="updateDeliveryContact2(customerContact, !(customerContact.guCustomerDeliveryContactId != ''))"><div class="contact-text">{{customerContact.txtLastName}}</div></div>
                  <div class="chh-list-item-row customer-contact-town" [ngClass]="customerContact.guCustomerDeliveryContactId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customerContact.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()" (click)="updateDeliveryContact2(customerContact, !(customerContact.guCustomerDeliveryContactId != ''))"><div class="contact-text">{{customerContact.txtTown}}</div></div>
                  <div class="chh-list-item-row customer-contact-phone1" [ngClass]="customerContact.guCustomerDeliveryContactId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customerContact.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()" (click)="updateDeliveryContact2(customerContact, !(customerContact.guCustomerDeliveryContactId != ''))"><div class="contact-text">{{customerContact.txtPhone1}}</div></div>
                  <div class="chh-list-item-row customer-contact-mobile" [ngClass]="customerContact.guCustomerDeliveryContactId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customerContact.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()" (click)="updateDeliveryContact2(customerContact, !(customerContact.guCustomerDeliveryContactId != ''))"><div class="contact-text">{{customerContact.txtMobile}}</div></div>
                  <div class="chh-list-item-row chh-list-item-last-column customer-contact-radio" [ngClass]="customerContact.guCustomerDeliveryContactId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customerContact.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customerContact.rowguid)" (mouseleave)="rowLeave()" (click)="updateDeliveryContact2(customerContact, !(customerContact.guCustomerDeliveryContactId != ''))">
                    <div class="contact-text" *ngIf="!showUpdateButton">
                      <mat-checkbox class="example-margin" #chkCustomerContact{{customerContact.rowguid}} id="chkCustomerContact{{customerContact.rowguid}}" [value]="true" [checked]="customerContact.guCustomerDeliveryContactId != ''" (change)="updateDeliveryContact2(customerContact, !(customerContact.guCustomerDeliveryContactId != ''))"></mat-checkbox>
                    </div>
                    <div class="update-address-button" *ngIf="showUpdateButton">
                      <button mat-button (click)="updateCustomerContact(customerContact.rowguid)" matTooltip="Click to edit Customer Delivery Address">
                        <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
                      </button>
                    </div>
                  </div>
                  }
                </div>

              </div>

            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="chh-form-field example-full-width">
                <mat-label>Search Hauler</mat-label>
                <input matInput class="hauling-company-input" placeholder="Filter by name" placeholderLabel="i.e. Blanchards....." value="" type="text" #haulingCompanyFilter (keyup)="filterHaulers(haulingCompanyFilter.value, chkCCHVehicles.checked)" (focus)="haulingCompanyFilter.select()">
              </mat-form-field>
              <div class="chh-vehicle-checkbox"><mat-checkbox class="example-margin" #chkCCHVehicles id="chkCCHVehicles" [value]="true" [checked]="true" (change)="filterHaulers(haulingCompanyFilter.value, chkCCHVehicles.checked)">CHH Vehicles</mat-checkbox></div>
              <mat-selection-list class="hauler-select" #guHaulerId [multiple]="false" id="guHaulerId" formControlName="guHaulerId" (selectionChange)="getHaulingCompanyDrivers(guHaulerId._value[0])">
                <mat-list-option *ngFor="let hauler of haulers" [value]="hauler.rowguid">
                  {{hauler.txtName}}
                </mat-list-option>
              </mat-selection-list>
              <br />
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-label>Hauling Driver</mat-label>
              <mat-selection-list class="hauling-company-driver-select" #guHaulingCompanyDriverId [multiple]="false" formControlName="guHaulingCompanyDriverId">
                <mat-list-option *ngFor="let haulingCompanyDriver of haulingCompanyDrivers" [value]="haulingCompanyDriver.rowguid">
                  {{haulingCompanyDriver.txtFirstName}} {{haulingCompanyDriver.txtLastName}} {{haulingCompanyDriver.blnDefaultDriver == true ? ' - (Default Driver)' : ''}}
                </mat-list-option>
              </mat-selection-list>
              <br />
            </div>
          </div>


          <div class="header-row-4">
            <div class="search-option-row-4">
              <mat-form-field class="chh-form-field example-full-width header-button" appearance="fill">
                <mat-label>Expected Loading Date</mat-label>
                <input id="dteExpectedLoadingDate" class="date-input expected-loading-date" #expectedLoadingDate matInput [matDatepicker]="pickerexpectedloadingdate" formControlName="dteExpectedLoadingDate">
                <mat-datepicker-toggle matSuffix [for]="pickerexpectedloadingdate"></mat-datepicker-toggle>
                <mat-datepicker #pickerexpectedloadingdate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="search-option-row-4">
              <mat-form-field class="chh-form-field example-full-width header-button" appearance="fill">
                <mat-label>Expected Delivery Date</mat-label>
                <input id="dteExpectedDeliveryDate" class="date-input expected-delivery-date" #expectedDeliveryDate matInput [matDatepicker]="pickerexpecteddeliverydate" formControlName="dteExpectedDeliveryDate">
                <mat-datepicker-toggle matSuffix [for]="pickerexpecteddeliverydate"></mat-datepicker-toggle>
                <mat-datepicker #pickerexpecteddeliverydate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="search-option-row-4">
              <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important;" appearance="fill">
                <mat-label>Status Date</mat-label>
                <input id="dteStatusDate" class="date-input status-date" #dateInspected matInput [matDatepicker]="pickerinspected" formControlName="dteStatusDate">
                <mat-datepicker-toggle matSuffix [for]="pickerinspected"></mat-datepicker-toggle>
                <mat-datepicker #pickerinspected></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field appearance="fill" class="chh-form-field">
                <mat-label>Status</mat-label>
                <mat-select formControlName="intStatus">
                  <mat-option *ngFor="let deliveryStatus of deliveryStatuses" [value]="deliveryStatus.intValue">{{deliveryStatus.txtValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="comments-form-field-container">
              <mat-form-field class="chh-form-field example-full-width comments-form-field" appearance="fill">
                <mat-label>Comments</mat-label>
                <textarea class="comments" matInput rows="10" value="" id="txtComments" formControlName="txtComments"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
            </div>
          </div>

          <div class="action-button-container">
            <button class="action-button" mat-flat-button color="primary" (click)="createDeliveryDocket('create')">Create Delivery Docket</button>
            <button class="action-button" mat-flat-button color="primary" (click)="cancelDeliveryDocket()">Cancel</button>
          </div>
        </div>
        </div>
    </form>
  </div>
</div>

<div class="spinner-container">
  <mat-card [@displaySpinner]="transitionProducts ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showSpinner">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
