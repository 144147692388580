import { ViewChild } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { dtoHaulingCompany } from '../../_shared/business-objects/dto-hauling-company.bo';
import { HaulingCompany } from '../../_shared/business-objects/hauling-company.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoHaulingCompanyService } from '../../_shared/services/dto-hauling-company.service';

@Component({
  selector: 'app-hauling-company-selection',
  templateUrl: './hauling-company-selection.component.html',
  styleUrls: ['./hauling-company-selection.component.css']
})
export class HaulingCompanySelectionComponent implements OnInit {

  @Input() EquipmentCategory: string;
  @Input() selectedHaulingCompany: HaulingCompany;
  @Output() haulingCompanySelectionEvent = new EventEmitter<HaulingCompany[]>();
  @Output() chhVehicleSelectionEvent = new EventEmitter<boolean>();

  @ViewChild('guHaulingCompanyId') haulingCompany: MatSelectionList;
  @ViewChild('chkCCHVehicles') chkCCHVehicles: MatCheckbox;

  form: FormGroup;

  public haulingCompanies: dtoHaulingCompany[] = [];
  public haulingCompaniesAll: dtoHaulingCompany[] = [];


  constructor(private dtoHaulingCompanyService: dtoHaulingCompanyService, private alertService: AlertService, private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      guHaulingCompanyId: [''],
    });

    console.log("inside hauling company selection: ");

    this.loadData();

  }

  async loadData() {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];


    //if (this.EquipmentCategory != "") {
    //  aParam.push(new SQLParamArray("guMobilePlantCategoryId", this.EquipmentCategory));
    //  aParamList.push(aParam);

    //}


    this.haulingCompaniesAll = await this.dtoHaulingCompanyService.getdtoHaulingCompanyParamArrayPromise(aParamList);
    this.haulingCompaniesAll.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

    let blankHaulingCompany: dtoHaulingCompany = new dtoHaulingCompany();
    blankHaulingCompany.rowguid = null;
    blankHaulingCompany.txtName = "None";

    this.haulingCompaniesAll.splice(0, 0, blankHaulingCompany);

    this.haulingCompanies = this.haulingCompaniesAll;

    if (this.selectedHaulingCompany == null) {
      //On first load show CHH Vehicles.
      //this.haulingCompanies = this.haulingCompanies.filter(a => (a.blnCHHVehicle == true && a.guMobilePlantEquipmentId != null && a.txtMobilePlantCategoryName.toLowerCase() == "truck") || a.txtName == "None");
      this.filterHaulingCompany("", true);
    }

    if (this.selectedHaulingCompany != null) {

      this.chkCCHVehicles.checked = this.selectedHaulingCompany.blnCHHVehicle;
      this.filterHaulingCompany("", this.selectedHaulingCompany.blnCHHVehicle);

      //if (this.selectedHaulingCompany.blnCHHVehicle == false) {
      //  this.chhVehicleSelectionEvent.emit(this.selectedHaulingCompany.blnCHHVehicle);
      //}

    }

  }

  filterHaulingCompany(haulingCompanyFilter: string, chhVehicles: boolean) {
    //console.log("selectedHaulingCompany", this.selectedHaulingCompany);

    this.haulingCompanies = this.haulingCompaniesAll;

    //console.log("chh vehicles: ", chhVehicles);
    this.haulingCompanies = this.haulingCompanies.filter(a => ((a.txtName.toLowerCase().indexOf(haulingCompanyFilter.toLowerCase()) > -1)) || a.txtName == "None");

    if (chhVehicles == true) {
      this.haulingCompanies = this.haulingCompanies.filter(a => (a.blnCHHVehicle == chhVehicles && a.guMobilePlantEquipmentId != null && a.txtMobilePlantCategoryName.toLowerCase() == "truck") || a.txtName == "None");
    }

    if (this.selectedHaulingCompany != null) {
      setTimeout(() => { this.scrollToHauler(),500 });
    }

    this.chhVehicleSelectionEvent.emit(chhVehicles);

  }

  haulingCompanySelect(haulingCompany: HaulingCompany[]) {
    //console.log("selectedEquipment: ", haulingCompany);

    this.haulingCompanySelectionEvent.emit(haulingCompany);
  }

  scrollToHauler() {
    let haulerList = document.getElementById("guHaulingCompanyId");

    //console.log("speciesList", haulerList);
    let selectedHaulerValue: string = this.selectedHaulingCompany.rowguid;  // this.speciesMatList.selectedOptions.selected[0].value;
    let selectedHaulerHeight: number = 0;


    let haulerListOptions: MatListOption[] = this.haulingCompany.options.toArray();

    //console.log("haulingCompany.options.toArray()", this.haulingCompany.options.toArray());

    for (let i = 0; i <= haulerListOptions.length - 1; i++) {
      let element: HTMLElement = haulerListOptions[i]._hostElement;
      selectedHaulerHeight += element.clientHeight;
      //console.log("element.nodeValue: " + element.innerText + ",   value: " + haulerListOptions[i].value + ",   selectedHaulerValue: " + haulerListOptions);
      //console.log("haulerListOptions[i].value == selectedHaulerValue: " + haulerListOptions[i].value.rowguid + " == " + selectedHaulerValue);
      if (haulerListOptions[i].value.rowguid == selectedHaulerValue) {
        break;
      }
      //console.log("selectedHaulerHeight: " + selectedHaulerHeight + ",   clientHeight: " + element.clientHeight + ",   clientLeft: " + element.clientLeft + ",   clientTop: " + element.clientTop);
    }

    if (selectedHaulerHeight > 500) {
      haulerList.scrollTo({ top: selectedHaulerHeight - 75, left: 0, behavior: 'smooth' });
    }
  }

}
