<h1>Pole Barcodes</h1>
<form class="stock-transfer-create-form" [formGroup]="form">
  <div class="header-row-4">
    <mat-form-field class="example-full-width example-container" appearance="fill">
      <mat-label>Identifier</mat-label>
      <input class="stock-identifier-input" matInput placeholder="" value="" formControlName="txtIdentifier">
      <mat-error *ngIf="false">
      </mat-error>
    </mat-form-field>
    <button class="stocktakeButton" mat-flat-button color="primary" (click)="printPoleBarcode()">Print Pole Barcode</button>&nbsp;
  </div>
</form>

<div class="error-console" id="errorConsole"></div>
<div class="error-console-message" id="errorConsoleMessage"></div>
