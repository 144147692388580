import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { dtoPickupLocation } from '../../_shared/business-objects/dto-pickup-location.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoPickupLocationService } from '../../_shared/services/dto-pickup-location.service';

@Component({
  selector: 'app-pickup-location-select',
  templateUrl: './pickup-location-select.component.html',
  styleUrl: './pickup-location-select.component.scss'
})
export class PickupLocationSelectComponent {
  @ViewChild('pickupLocationFilter') pickupLocationFilter: ElementRef;
  @ViewChild('pickupLocationListContainer') pickupLocationListContainer: ElementRef;
  @ViewChild('multiplePickupLocations') multiplePickupLocations: MatSlideToggle;


  @Input() pickupLocations: dtoPickupLocation[];
  @Output() selectedPickupLocationsEvent = new EventEmitter<dtoPickupLocation[]>();

  showSpinner: boolean
  addressHoverId: string;

  selectedPickupLocations: dtoPickupLocation[];
  pickupLocationsFiltered: dtoPickupLocation[];
  pickupLocationExpanded: dtoPickupLocation;

  pickupLocationFilteredItems: HTMLCollectionOf<HTMLDivElement>;

  showPickupLocationList: boolean = false;
  showPickupLocationListTransition: boolean = false;

  pickupLocationSelectionExpanded: boolean = false;
  pickupLocationListPinned: boolean = false;

  toolTipText: string = "pin";

  constructor(private pickupLocationService: dtoPickupLocationService, private PickupLocationService: dtoPickupLocationService
    , private alertService: AlertService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.selectedPickupLocations = [];

    document.addEventListener("click", this.openClosePickupLocationList.bind(this));

  }

  openClosePickupLocationList(e) {

    if (this.pickupLocationListPinned == true) {
      return;
    }

    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.pushPin != null) {
      return;
    }


    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.pickupLocationInput != null && this.pickupLocationFilter.nativeElement.value != null && this.pickupLocationFilter.nativeElement.value.toString() != "") {
      this.showPickupLocationList = true;
      //console.log("this.showCustomerList = true", this.showCustomerList);
      //console.log("customerListContainer", this.customerFilter.nativeElement.value);

      return;
    }

    //if (e == null || e.target == null || e.target.dataset == null || e.target.dataset.pickupLocationSelect == null) {

    // ALWAYS CLOSE THE SEARCH LIST UNLESS I'M PINNED
    if (this.pickupLocationListPinned == false) {
      this.showPickupLocationList = false;

      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      //console.log("this.showCustomerList = false", this.showCustomerList);
      return;
    }

    //let domStringMap: DOMStringMap = e.target.dataset;

    //console.log(domStringMap.customerSelect.toString() == "customerSelect");
    //console.log(domStringMap.customerSelect.toString());
  }

  checkPickupLocationListIsOpen(inputValue: string) {

    //console.log("inputValue", inputValue);

    if (inputValue == null || inputValue == "") {
      this.showPickupLocationList = false;
      //console.log("inputValue empty", inputValue);
      return;
    }

    this.showPickupLocationList = true;


  }


  rowEnter(pickupLocationId: string) {
    this.addressHoverId = pickupLocationId;
    //console.log("row enter");
  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");
  }

  setSelectedPickupLocations(pickupLocations: dtoPickupLocation[]) {
    this.selectedPickupLocations = pickupLocations;
  }


  async selectPickupLocation(pickupLocation: dtoPickupLocation, selected: boolean) {

    if (pickupLocation == null) {
      this.alertService.openSnackBarDefault("There was a problem selecting the pickupLocation - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    // Remove All dtoPickupLocation Contact
    if (this.multiplePickupLocations != null && this.multiplePickupLocations.checked == false) {

      let pickupLocationSelected: dtoPickupLocation[] = this.pickupLocations.filter((a) => { return a.guSelectedPickupLocationId != null && a.guSelectedPickupLocationId != "" });
      for (let i = 0; i <= pickupLocationSelected.length - 1; i++) {
        pickupLocationSelected[i].guSelectedPickupLocationId = "";
      }

      for (let i = this.selectedPickupLocations.length - 1; i >= 0; i--) {
        this.selectedPickupLocations.splice(i, 1);
      }

      for (let i = this.pickupLocationsFiltered.length - 1; i >= 0; i--) {
        this.pickupLocationsFiltered[i].guSelectedPickupLocationId = "";
      }

    }

    // Remove dtoPickupLocation Contact
    if (selected == false) {
      for (let i = this.selectedPickupLocations.length - 1; i >= 0; i--) {
        if (this.selectedPickupLocations[i].rowguid == pickupLocation.rowguid) {
          this.selectedPickupLocations.splice(i, 1);
          pickupLocation.guSelectedPickupLocationId = "";
          //console.log("unselected pickupLocation");
          this.selectedPickupLocationsEvent.emit(this.selectedPickupLocations);
          break;
        }
      }
    }

    // Add dtoPickupLocation Contact
    if (selected == true) {

      pickupLocation.guSelectedPickupLocationId = "tempvalue";

      this.selectedPickupLocations.push(pickupLocation);
      this.selectedPickupLocationsEvent.emit(this.selectedPickupLocations);

      if (this.multiplePickupLocations.checked == false && this.pickupLocationListPinned == false) {
        this.showPickupLocationList = false;
      }

      //console.log("added pickupLocation");

    }


  }

  //async updatePickupLocation(pickupLocation: dtoPickupLocation) {

  //  console.log(pickupLocation);
  //  if (pickupLocation == null) {
  //    this.alertService.openSnackBarDefault("There is no pickupLocation to edit");
  //    return;
  //  }

  //  if (this.pickupLocationUpdateDialogRef != null) {
  //    this.pickupLocationUpdateDialogRef.close();
  //  }

  //  this.pickupLocationUpdateDialogRef = this.dialog.open(PickupLocationUpdateDialogComponent, {
  //    hasBackdrop: false,
  //    height: '95vh',
  //    maxWidth: '90vw',
  //    data: { selectedPickupLocation: pickupLocation }
  //  });

  //  this.pickupLocationUpdateDialogRef.backdropClick().subscribe(() => {
  //    this.pickupLocationUpdateDialogRef.close();
  //  });


  //  this.pickupLocationUpdateDialogRef
  //    .afterClosed()
  //    .subscribe(async (
  //      data: { status: string, pickupLocation: dtoPickupLocation }) => {
  //      if (data == null || data == undefined) {
  //        //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
  //        return;
  //      }

  //      //console.log(data);
  //      if (data.status != "Cancel") {
  //        let cust: dtoPickupLocation = await this.PickupLocationService.getPickupLocationPromise(data.pickupLocation.rowguid);
  //        this.selectedPickupLocations.push(cust);

  //        for (let i = 0; i <= this.pickupLocations.length - 1; i++) {
  //          if (this.pickupLocations[i].rowguid == data.pickupLocation.rowguid) {
  //            this.pickupLocations[i] = cust;
  //            this.pickupLocations[i].guSelectedPickupLocationId = "tempvalue";
  //            break;
  //          }
  //        }

  //        this.pickupLocations.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

  //        this.alertService.openSnackBarDefault("The dtoPickupLocation has been updated");

  //      }
  //      else {
  //        // We have cancellled

  //      }

  //    });

  //}

  addPickupLocation() {

  }

  nextPickupLocation(e, pickupLocation: dtoPickupLocation) {

    //console.log("e ", e);

    if (e.code.toLowerCase() == "escape") {
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      this.pickupLocationFilter.nativeElement.focus();
      this.pickupLocationFilter.nativeElement.setSelectionRange(this.pickupLocationFilter.nativeElement.value.length, this.pickupLocationFilter.nativeElement.value.length);

      this.showPickupLocationList = false;
      return;
    }

    if ((e.code.toLowerCase() == "arrowdown" || e.code.toLowerCase() == "arrowup") && this.showPickupLocationList == true) {
      //console.log("e ", e);

      if (this.pickupLocationFilteredItems != null) {

        let tabIndex: number = e.target.tabIndex;

        let pickupLocationUpdateDiv = document.getElementById("pickupLocationUpdateDiv" + tabIndex);

        if (tabIndex < this.pickupLocationFilteredItems.length - 1 && e.code.toLowerCase() == "arrowdown") {

          let pickupLocationUpdateDivPlus = document.getElementById("pickupLocationUpdateDiv" + (tabIndex + 1));

          //console.log("e down ", tabIndex);
          //TODO
          // Set this.pickupLocationContainer.style overflow-y to hidden here
          let docBody = document.getElementById("rootBody");
          docBody.style.overflowY = "hidden";

          this.pickupLocationListContainer.nativeElement.classList.add("pickupLocation-container-no-scroll");

          this.pickupLocationFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          pickupLocationUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.pickupLocationFilteredItems[tabIndex + 1].focus();
          this.pickupLocationFilteredItems[tabIndex + 1].classList.add("chh-list-item-row-selected");
          pickupLocationUpdateDivPlus.classList.add("chh-list-item-row-selected");

          // Set this.pickupLocationContainer.style overflow-y to scroll here
          this.pickupLocationListContainer.nativeElement.classList.remove("pickupLocation-container-no-scroll");

        }

        if (tabIndex >= 0 && e.code.toLowerCase() == "arrowup") {

          if (tabIndex == 0) {
            //console.log("up tabIndex ", tabIndex);

            this.pickupLocationFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
            pickupLocationUpdateDiv.classList.remove("chh-list-item-row-selected");

            let docBody = document.getElementById("rootBody");
            docBody.style.overflowY = "scroll";

            this.pickupLocationFilter.nativeElement.focus();
            this.pickupLocationFilter.nativeElement.setSelectionRange(this.pickupLocationFilter.nativeElement.value.length, this.pickupLocationFilter.nativeElement.value.length);
            return;
          }


          let pickupLocationUpdateDivMinus = document.getElementById("pickupLocationUpdateDiv" + (tabIndex - 1));

          //console.log("e up ", tabIndex);

          this.pickupLocationListContainer.nativeElement.classList.add("pickupLocation-container-no-scroll");

          this.pickupLocationFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          pickupLocationUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.pickupLocationFilteredItems[tabIndex - 1].focus();
          this.pickupLocationFilteredItems[tabIndex - 1].classList.add("chh-list-item-row-selected");
          pickupLocationUpdateDivMinus.classList.add("chh-list-item-row-selected");

          this.pickupLocationListContainer.nativeElement.classList.remove("pickupLocation-container-no-scroll");


        }

      }
      //console.log("nextPickupLocation");

    }

    if (e.code.toLowerCase() == "enter") {
      this.selectPickupLocation(pickupLocation, !(pickupLocation.guSelectedPickupLocationId != ''));
    }

  }

  filterPickupLocations(pickupLocationFilter: string, e) {


    if (e.code.toLowerCase() == "arrowdown" && this.showPickupLocationList == true) {
      //console.log("e ", e);
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "hidden";

      this.pickupLocationFilteredItems = document.getElementsByClassName("pickup-location-type-name") as HTMLCollectionOf<HTMLDivElement>;
      if (this.pickupLocationFilteredItems != null && this.pickupLocationFilteredItems.length > 0) {


        this.pickupLocationFilteredItems[0].classList.add("chh-list-item-row-selected");
        let pickupLocationUpdateDiv = document.getElementById("pickupLocationUpdateDiv0");

        pickupLocationUpdateDiv.classList.add("chh-list-item-row-selected");

        this.pickupLocationFilteredItems[0].focus();
        console.log("filterPickupLocation TabIndex: ", this.pickupLocationFilteredItems[0].tabIndex);

      }

      //console.log("pickupLocationElements", this.pickupLocationFilteredItems);

    }

    this.getPickupLocations(pickupLocationFilter);



  }

  getPickupLocations(pickupLocationFilter: string) {

    this.pickupLocationsFiltered = this.pickupLocations;

    if (pickupLocationFilter == null || pickupLocationFilter == "") {
      this.pickupLocationsFiltered = [];
      this.showPickupLocationList = false;
      return;
    }

    console.log("this.pickupLocations", this.pickupLocations);

    let pickupLocations: dtoPickupLocation[] = this.pickupLocations;

    pickupLocations = pickupLocations.filter(a => ((a.txtLocationName.toLowerCase().indexOf(pickupLocationFilter.toLowerCase()) > -1)));

    pickupLocations = pickupLocations.slice(0, 50);

    pickupLocations.sort((a, b) => { return a.txtLocationName > b.txtLocationName ? 1 : -1 });

    this.pickupLocationsFiltered = pickupLocations;

    this.showPickupLocationList = true;

    this.setPickupLocationListTranition(1000);

  }

  setPickupLocationListTranition(delay: number) {

    setTimeout(() => {
      this.showPickupLocationListTransition = true;
    }, delay);

  }

  checkMultiplePickupLocations(multiplePickupLocations: boolean) {

    if (multiplePickupLocations == false) {
      //Remove multiple selected pickupLocations so only one remains.
      let multipleCust: dtoPickupLocation[] = this.pickupLocations.filter((a) => { return a.guSelectedPickupLocationId != null && a.guSelectedPickupLocationId != "" });
      if (multipleCust != null && multipleCust.length > 0) {

        multipleCust.sort((a, b) => { return a.txtLocationName > b.txtLocationName ? 1 : -1 });

        for (let i = multipleCust.length - 1; i >= 1; i--) {
          multipleCust.pop();
        }

        for (let i = 0; i <= this.pickupLocations.length - 1; i++) {
          if (multipleCust[0].rowguid != this.pickupLocations[i].rowguid) {
            this.pickupLocations[i].guSelectedPickupLocationId = "";
          }
        }

        this.selectedPickupLocations.length = 0;
        this.selectedPickupLocations.push(multipleCust[0]);
        this.selectedPickupLocationsEvent.emit(this.selectedPickupLocations);

      }

    }
  }

  removePickupLocation(pickupLocation: dtoPickupLocation) {

    if (pickupLocation == null) {
      return;
    }

    for (let i = this.selectedPickupLocations.length - 1; i >= 0; i--) {
      if (this.selectedPickupLocations[i].rowguid == pickupLocation.rowguid) {
        this.selectedPickupLocations.splice(i, 1);
        pickupLocation.guSelectedPickupLocationId = "";
        //console.log("unselected pickupLocation");
        this.selectedPickupLocationsEvent.emit(this.selectedPickupLocations);
        break;
      }
    }


  }

  expandSelectedPickupLocations(e: PointerEvent) {

    if (e.pointerType == null || e.pointerType == "") {
      return;
    }

    this.pickupLocationSelectionExpanded = !this.pickupLocationSelectionExpanded;

    let selectedPickupLocations: HTMLDivElement = document.getElementById("selectedPickupLocations") as HTMLDivElement;
    selectedPickupLocations.classList.toggle("selected-pickup-locations-expanded");

    if (this.pickupLocationSelectionExpanded == true) {

    }

  }

  clickPin(e) {

    this.pickupLocationListPinned = !this.pickupLocationListPinned;

    if (this.pickupLocationListPinned == false) {
      this.showPickupLocationList = false;
    }

    this.toolTipText = this.pickupLocationListPinned == true ? 'unpin' : 'pin';


  }
}
