//Imports
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap, RouteConfigLoadEnd } from '@angular/router';

import { PoleTrimmingItem } from '../_shared/business-objects/pole-trimming-item.bo';

import { ProductType } from '../_shared/business-objects/product-type.bo';

import { Species } from '../_shared/business-objects/species.bo';

import { Pole } from '../_shared/business-objects/pole.bo';

import { TrimmingItemReasonForChange } from '../_shared/business-objects/trimming-item-reason-for-change.bo';

import { ProductTypeEnum } from '../_shared/enums/product-type.enum';

import { ErrorMessage } from '../_shared/ErrorMessage.class';

import { PoleTrimmingItemReasonForChange } from '../_shared/business-objects/pole-trimming-item-reason-for-change.bo';



import { PoleTrimmingItemService } from '../_shared/services/pole-trimming-item.service';
import { ProductTypeService } from '../_shared/services/product-type.service';
import { SpeciesService } from '../_shared/services/species.service';
import { TrimingItemReasonForChangeService } from '../_shared/services/trimming-item-reason-for-change.service';
import { PoleTrimingItemReasonForChangeService } from '../_shared/services/pole-trimming-item-reason-for-change.service';
import { PoleService } from '../_shared/services/pole.service';
import { AuthService } from '../account/auth.service';

import { HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { MatSelectionListChange } from '@angular/material/list';

import { fromEvent } from 'rxjs';
import { scan } from 'rxjs/operators';
import { DatePipe } from '@angular/common'
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';


@Component({
  selector: 'app-pole-trimming-item-update',
  templateUrl: './pole-trimming-item-update.component.html',
  styleUrls: ['./pole-trimming-item-update.component.css']
})
export class PoleTrimmingItemUpdateComponent implements OnInit {
  form: FormGroup;

  public productTypes: ProductType[];
  public species: any;
  public trimmingItemReasonForChangeData: any;
  private reasonForChangeSelected: string[];
  //private reasonForChangeSelectedValues: any;
  showErrorMessage: boolean;
  errorMessage: ErrorMessage;

  //guProductTypeId = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  //guSpeciesId = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  public productTypeFilterCtrl: FormControl = new FormControl();
  public speciesFilterCtrl: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  public filteredProductTypes: ReplaySubject<ProductType[]> = new ReplaySubject<ProductType[]>(1);
  public filteredSpecies: ReplaySubject<ProductType[]> = new ReplaySubject<ProductType[]>(1);
  public reasonForChangeSelectedValues: any;
  public eOriginalProductMeasurementType: ProductType.enMeasurementType;
  public eFinishedProductMeasurementType: ProductType.enMeasurementType;
  public products$ = this.productTypeService.getAllTrimmingProductTypes();
  public originalDiamHint: string = '';
  public finishedDiamHint: string = '';
  public originalKNHint: string = '';
  public finishedKNHint: string = '';
  public originalLengthHint: string = '';
  public finishedLengthHint: string = '';
  public originalDiaPlaceHolder: string = '';
  public finishedDiaPlaceHolder: string = '';

  public nsw_kNRange = "(^[2]$|^[4]$|^[6]$|^[8]$)|(^[1][2]$|^[1][6]$|^[1][8]$|^[2][0]$|^[3][0]$)";
  public qv_kNRange = "(^[5]$|^[8]$)|(^[1][2]$|^[1][6]$|^[1][8]$|^[2][0]$)";

  public nsw_LengthRange = "((^[8]$|^[9].[5]$|^[1][1]$|^[1][2].[5]$|^[1][4]$|^[1][5].[5]$|^[1][7]$|^[1][8].[5]$|^[2][0]$|^[2][1].[5]$|^[2][3]$|^[2][4].[5]$|^[2][6]$))";
  public qv_LengthRange = "((^[9].[5]$|^[1][1]$|^[1][2].[5]$|^[1][4]$|^[1][5].[5]$|^[1][7]$|^[1][8].[5]$|^[2][0]$|^[2][1].[5]$|^[2][3]$))";

  public original_nsw_LengthValidator: ValidatorFn = Validators.pattern(this.nsw_LengthRange);
  public finished_nsw_LengthValidator: ValidatorFn = Validators.pattern(this.nsw_LengthRange);

  public original_qv_LengthValidator: ValidatorFn = Validators.pattern(this.nsw_LengthRange);
  public finished_qv_LengthValidator: ValidatorFn = Validators.pattern(this.nsw_LengthRange);

  public poleTrimmingItemGuid: string = '';
  public poleTrimmingItem: PoleTrimmingItem;

  public guPoleTrimmingItemReasonForChange: PoleTrimmingItemReasonForChange[];

  get dteDateCreated() {
    return this.form.get('dteDateCreated');
  }

  get guOriginalProductTypeId() {
    return this.form.get('guOriginalProductTypeId');
  }

  get guFinishedProductTypeId() {
    return this.form.get('guFinishedProductTypeId');
  }

  get txtPoleNumber() {
    return this.form.get('txtPoleNumber');
  }

  get fltOriginalDiameter() {
    return this.form.get('fltOriginalDiameter');
  }

  get fltFinishedDiameter() {
    return this.form.get('fltFinishedDiameter');
  }

  get intOriginalDiameterType() {
    return this.form.get('intOriginalDiameterType');
  }

  get intFinishedDiameterType() {
    return this.form.get('intFinishedDiameterType');
  }

  get chkOriginalQV() {
    return this.form.get('chkOriginalQV');
  }

  get chkFinishedQV() {
    return this.form.get('chkFinishedQV');
  }

  get txtOriginalKN() {
    return this.form.get('txtOriginalKN');
  }

  get txtFinishedKN() {
    return this.form.get('txtFinishedKN');
  }

  get txtOriginalLength() {
    return this.form.get('txtOriginalLength');
  }

  get txtFinishedLength() {
    return this.form.get('txtFinishedLength');
  }

  get guSpeciesId() {
    return this.form.get('guSpeciesId');
  }

  get guOriginalPoleId() {
    return this.form.get('guOriginalPoleId');
  }

  get guFinalPoleId() {
    return this.form.get('guFinalPoleId');
  }

  get txtComments() {
    return this.form.get('txtComments');
  }


  constructor(private fb: FormBuilder, private poleTrimmingItemService: PoleTrimmingItemService, private productTypeService: ProductTypeService, private speciesService: SpeciesService, private trimingItemReasonForChangeService: TrimingItemReasonForChangeService, private poleTrimmingItemReasonForChangeService: PoleTrimingItemReasonForChangeService, private poleService: PoleService, private authService: AuthService, private router: Router, private route: ActivatedRoute) {
    //['dteDateCreated', 'dteTimeCreated', 'txtPoleNumber', 'txtProductType', 'fltOriginalLength', 'origspace', 'intOriginalKN', 'txtOriginalSpec', 'fltFinishedLength', 'finspace', 'intFinishedKN', 'txtFinishedSpec', 'fltDiameter', 'txtDiameterType', 'txtReasonForChange', 'btnShowDetail']

    this.form = this.fb.group({
      dteDateCreated: ['', Validators.required],
      txtPoleNumber: ['', Validators.required],
      guOriginalProductTypeId: ['', Validators.required],
      guFinishedProductTypeId: ['', Validators.required],
      productTypeFilterCtrl: ['', Validators.required],
      txtOriginalLength: ['', [Validators.required, Validators.min(1), Validators.max(26), this.original_nsw_LengthValidator]],
      txtOriginalKN: ['', [Validators.required, Validators.pattern(this.nsw_kNRange)]],
      txtFinishedLength: ['', [Validators.required, Validators.min(1), Validators.max(26), this.finished_nsw_LengthValidator]],
      txtFinishedKN: ['', [Validators.required, Validators.pattern(this.nsw_kNRange)]],
      fltLength: [0, Validators.required],
      fltOriginalDiameter: ['', [Validators.required, Validators.maxLength(4), Validators.min(150), Validators.max(1000)]],
      intOriginalDiameterType: [0, Validators.required],
      fltFinishedDiameter: ['', [Validators.required, Validators.maxLength(4), Validators.min(150), Validators.max(1000)]],
      intFinishedDiameterType: [0, Validators.required],
      guSpeciesId: ['', Validators.required],
      guOriginalPoleId: ['', Validators.required],
      guFinalPoleId: ['', Validators.required],
      txtComments: [''],
      chkOriginalQV: [false, Validators.required],
      chkFinishedQV: [false, Validators.required],
      guPoleTrimmingItemReasonForChange: ['']
    });

    const datepipe: DatePipe = new DatePipe('en-AU');

    this.form.controls['dteDateCreated'].setValue(datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss'));  // HH:mm:ss:ss a
    this.originalDiamHint = "min: 150mm,  max: 1000mm";
    this.finishedDiamHint = "min: 150mm,  max: 1000mm";

    this.originalKNHint = '2, 4, 6, 8, 12, 16, 18, 20, 30';
    this.finishedKNHint = '2, 4, 6, 8, 12, 16, 18, 20, 30';

    this.originalLengthHint = '8m to 26m';
    this.finishedLengthHint = '8m to 26m';


    this.originalDiaPlaceHolder = "Diameter mm";
    this.finishedDiaPlaceHolder = "Diameter mm";

    this.getProductTypes();
    this.getSpecies();

    this.showErrorMessage = false;
    this.errorMessage = new ErrorMessage;

    this.eOriginalProductMeasurementType = ProductType.enMeasurementType.LengthKN;
    this.eFinishedProductMeasurementType = ProductType.enMeasurementType.LengthKN;


    //this.poleTrimmingItemGuid = this.route.snapshot.paramMap.get("poleTrimmingItemGuid");
    //console.log("poleTrimmingItemGuid: " + this.poleTrimmingItemGuid);

    //this.loadPoleTrimmingItem();


    //this.form.value.dteDateCreated.setValue(Date.now);  //['dteDateCreated']

  }

  loadPoleTrimmingItem() {
    this.poleTrimmingItemService.getPoleTrimmingItemByRowguid(this.poleTrimmingItemGuid).subscribe(data => {

      this.poleTrimmingItem = data;

      let originalProductMeasurementType: ProductType.enMeasurementType;
      let finishedProductMeasurementType: ProductType.enMeasurementType;
      let originalPT: ProductType;
      let finishedPT: ProductType;



      
      this.dteDateCreated.setValue(this.poleTrimmingItem.dteDateCreated);
      this.guOriginalProductTypeId.setValue(this.poleTrimmingItem.guOriginalProductTypeId);
      this.guFinishedProductTypeId.setValue(this.poleTrimmingItem.guFinishedProductTypeId);
      this.txtPoleNumber.setValue(this.poleTrimmingItem.txtPoleNumber);
      this.txtOriginalLength.setValue(this.poleTrimmingItem.fltOriginalLength);
      this.txtFinishedLength.setValue(this.poleTrimmingItem.fltFinishedLength);
      this.fltOriginalDiameter.setValue(this.poleTrimmingItem.fltOriginalDiameter);
      this.intOriginalDiameterType.setValue(this.poleTrimmingItem.intOriginalDiameterType);
      this.fltFinishedDiameter.setValue(this.poleTrimmingItem.fltFinishedDiameter);
      this.intFinishedDiameterType.setValue(this.poleTrimmingItem.intFinishedDiameterType);
      this.guSpeciesId.setValue(this.poleTrimmingItem.guSpeciesId);
      this.guOriginalPoleId.setValue(this.poleTrimmingItem.guOriginalPoleId);
      this.guFinalPoleId.setValue(this.poleTrimmingItem.guFinalPoleId);

      if (this.poleTrimmingItem.guOriginalPoleId != null && this.poleTrimmingItem.guOriginalPoleId != "") {
        this.poleService.getPoleByRowguid(this.poleTrimmingItem.guOriginalPoleId).subscribe(poleData => {
          this.txtOriginalLength.setValue(poleData.intLength);
          this.txtOriginalKN.setValue(poleData.intKn);
          this.chkOriginalQV.setValue(poleData.blnQV);
        },
          err => {
            this.errorMessage.message = "There was an error getting the pole";
            this.showErrorMessage = true;
          });
      }


      if (this.poleTrimmingItem.guFinalPoleId != null && this.poleTrimmingItem.guFinalPoleId != "") {
        this.poleService.getPoleByRowguid(this.poleTrimmingItem.guFinalPoleId).subscribe(poleData => {
          this.txtFinishedLength.setValue(poleData.intLength);
          this.txtFinishedKN.setValue(poleData.intKn);
          this.chkFinishedQV.setValue(poleData.blnQV);
        },
          err => {
            this.errorMessage.message = "There was an error getting the pole";
            this.showErrorMessage = true;
          });
      }

      this.txtComments.setValue(this.poleTrimmingItem.txtComments);
     
      this.loadPoleTrimmingItemReasonForChange(this.poleTrimmingItem.rowguid);

      for (let i = 0; i < this.productTypes.length; i++) {
        if (this.guOriginalProductTypeId.value == this.productTypes[i].rowguid && this.productTypes[i].intMeasurementType != ProductType.enMeasurementType.LengthKN) {
          this.onOriginalProductSelect(this.productTypes[i]);
        }

        if (this.guFinishedProductTypeId.value == this.productTypes[i].rowguid && this.productTypes[i].intMeasurementType != ProductType.enMeasurementType.LengthKN) {
          this.onFinishedProductSelect(this.productTypes[i]);
        }
      }

    },
      err => {

      });

  }

  loadPoleTrimmingItemReasonForChange(gPoleTrimmingItemId) {
    this.poleTrimmingItemReasonForChangeService.getSetTrimmingItemReasonForChanges(gPoleTrimmingItemId).subscribe(data => {
      this.guPoleTrimmingItemReasonForChange = data;
      this.getTrimmingItemReasonForChange();
    }, err => {
        console.log(err);
    });


  }


  isSelected(gTrimmingItemReasonForChangeId) {
    if (this.guPoleTrimmingItemReasonForChange != null) {
      for (let i = 0; i < this.guPoleTrimmingItemReasonForChange.length; i++) {
        if (this.guPoleTrimmingItemReasonForChange[i].guTrimmingItemReasonForChangeId == gTrimmingItemReasonForChangeId) {
          return true;
        }
      }
    }
    return false;
  }


  ngOnInit(): void {

    //this.route.queryParams.subscribe(params => {
    //  this.poleTrimmingItemGuid = params['poleTrimmingItemGuid'];
    //});

    this.poleTrimmingItemGuid = this.route.snapshot.paramMap.get("poleTrimmingItemGuid");

    this.loadPoleTrimmingItem();
    //this.getTrimmingItemReasonForChange();  // do this after we get selected items from DB so we can set as "Selected"

  }

  validateAddTrimmingItem() {
    var pole1$ = this.getOriginalPole(this.form.value.txtOriginalLength, parseInt(this.form.value.txtOriginalKN), Boolean(this.form.value.chkOriginalQV));
    var pole2$ = this.getOriginalPole(this.form.value.txtFinishedLength, parseInt(this.form.value.txtFinishedKN), Boolean(this.form.value.chkFinishedQV));

  }

  async updatePoleTrimmingItem() {


    this.showErrorMessage = false;

    this.poleTrimmingItem.dteDateCreated = this.dteDateCreated.value;
    this.poleTrimmingItem.guOriginalProductTypeId = this.guOriginalProductTypeId.value;
    this.poleTrimmingItem.guFinishedProductTypeId = this.guFinishedProductTypeId.value;
    this.poleTrimmingItem.txtPoleNumber = this.txtPoleNumber.value;
    this.poleTrimmingItem.fltOriginalDiameter = this.fltOriginalDiameter.value;
    this.poleTrimmingItem.intOriginalDiameterType = parseInt(this.intOriginalDiameterType.value);
    this.poleTrimmingItem.fltFinishedDiameter = parseInt(this.fltFinishedDiameter.value);
    this.poleTrimmingItem.intFinishedDiameterType = this.intFinishedDiameterType.value;
    this.poleTrimmingItem.guSpeciesId = this.guSpeciesId.value;
    this.poleTrimmingItem.txtComments = this.txtComments.value;
    this.poleTrimmingItem.guEmployeeEnteredId = this.authService.getAppUserEmployeeId();


    //var aPoleTrimItem: PoleTrimmingItem;
    //let poleTrim: PoleTrimmingItem;
    //poleTrim = new PoleTrimmingItem();
    //poleTrim.dteDateCreated = this.form.value.dteDateCreated;
    //poleTrim.txtPoleNumber = this.form.value.txtPoleNumber;
    //poleTrim.guOriginalProductTypeId = this.form.value.guOriginalProductTypeId;
    //poleTrim.guFinishedProductTypeId = this.form.value.guFinishedProductTypeId;
    //poleTrim.guSpeciesId = this.form.value.guSpeciesId;
    //poleTrim.txtComments = this.form.value.txtComments;
    //poleTrim.guEmployeeEnteredId = this.authService.getAppUserEmployeeId();
    //poleTrim.fltOriginalDiameter = parseInt(this.form.value.fltOriginalDiameter);
    //poleTrim.intOriginalDiameterType = this.form.value.intOriginalDiameterType;
    //poleTrim.fltFinishedDiameter = parseInt(this.form.value.fltFinishedDiameter);
    //poleTrim.intFinishedDiameterType = this.form.value.intFinishedDiameterType;



    if (this.eOriginalProductMeasurementType != ProductType.enMeasurementType.LengthKN) {
      // DO FINISH LENGTH ONLY WITH DIAMETER.... THIS WILL BE PILE OR GIRDER ETC....


      if (this.form.value.txtOriginalLength == '') {
        this.errorMessage.message = "The original length is empty.<br>Please add an original length before updating."
        this.showErrorMessage = true;
        return false;
      }

      if (this.form.value.fltOriginalDiameter == '') {
        this.errorMessage.message = "The original diameter is empty.<br>Please add an original diameter before updating."
        this.showErrorMessage = true;
        return false;
      }

      this.poleTrimmingItem.guOriginalPoleId = null;
      this.poleTrimmingItem.fltOriginalLength = Number(this.txtOriginalLength.value);

    }

    if (this.eFinishedProductMeasurementType != ProductType.enMeasurementType.LengthKN) {

      if (this.form.value.txtFinishedLength == '') {
        this.errorMessage.message = "The finished length is empty.<br>Please add a finished length before updating."
        this.showErrorMessage = true;
        return false;
      }

      if (this.form.value.fltFinishedDiameter == '') {
        this.errorMessage.message = "The finished diameter is empty.<br>Please add a finished diameter before updating."
        this.showErrorMessage = true;
        return false;
      }

      this.poleTrimmingItem.guFinalPoleId = null;
      this.poleTrimmingItem.fltFinishedLength = Number(this.txtFinishedLength.value);

    }

    if (this.eOriginalProductMeasurementType == ProductType.enMeasurementType.LengthKN) {

      if (this.txtOriginalLength.value == '') {
        this.errorMessage.message = "The product is a Pole but you do not have an original length entered.<br>Please add an original length before updating."
        this.showErrorMessage = true;
        return false;
      }

      if (this.txtOriginalKN.value == '') {
        this.errorMessage.message = "The product is a Pole but you do not have an Original kN entered.<br>Please enter a Original kN or change the Product.<br>Is it meant to be a Pile?"
        this.showErrorMessage = true;
        return false;
      }

      const pole1: Pole = await this.getPole(this.form.value.txtOriginalLength, parseInt(this.form.value.txtOriginalKN), Boolean(this.form.value.chkOriginalQV)) // <- async operation

      if (pole1 == null) {
        return false;
      }
      //poleTrim.guOriginalPoleId = pole1.rowguid;
      this.poleTrimmingItem.guOriginalPoleId = pole1.rowguid;
      this.poleTrimmingItem.fltOriginalLength = null;

    }

    if (this.eFinishedProductMeasurementType == ProductType.enMeasurementType.LengthKN) {

      if (this.txtFinishedLength.value == '') {
        this.errorMessage.message = "The product is a Pole but you do not have a finished length entered.<br>Please add a finished length before updating."
        this.showErrorMessage = true;
        return false;
      }

      if (this.txtFinishedKN.value == '') {
        this.errorMessage.message = "The product is a Pole but you do not have a Finshed kN entered.<br>Please enter a Finished kN or change the Product.<br>Is it meant to be a Pile?"
        this.showErrorMessage = true;
        return false;
      }

      const pole2: Pole = await this.getPole(this.form.value.txtFinishedLength, parseInt(this.form.value.txtFinishedKN), Boolean(this.form.value.chkFinishedQV)) // <- async operation

      if (pole2 == null) {
        return false;
      }
      //poleTrim.guFinalPoleId = pole2.rowguid;
      this.poleTrimmingItem.guFinalPoleId = pole2.rowguid;
      this.poleTrimmingItem.fltFinishedLength = null;

    }


    var addpoletrimming$ = new Promise<PoleTrimmingItem>(resolve => {
      this.poleTrimmingItemService.updatePoleTrimmingItem(this.poleTrimmingItem).subscribe(data => {
        resolve(data);
      },
        err => {
          console.log(err);
          this.errorMessage.message = "There was a problem updating the pole trimming item";
          this.showErrorMessage = true;
          resolve(null);
        });
    });
    addpoletrimming$.then(poleTrimming => {
      if (poleTrimming != null) {
        this.updatePoleTrimmingItemReasonForChange(poleTrimming);
        this.router.navigate(['pole-trimming-item']);
      }
      //console.log(poleTrimming)
    });

  }


  updatePoleTrimmingItemReasonForChange(poleTrimItem) {
    // Look for PoleTrimmingItemReasonForChange and Add
    if (poleTrimItem != null && this.reasonForChangeSelected != null) {
      var aPoleTrimItems: PoleTrimmingItemReasonForChange[] = [];
      for (let i = 0; i < this.reasonForChangeSelected.length; i++) {

       let poleT: PoleTrimmingItemReasonForChange = new PoleTrimmingItemReasonForChange();
        
        poleT.guPoleTrimmingItemId = poleTrimItem.rowguid;
        poleT.guTrimmingItemReasonForChangeId = this.reasonForChangeSelected[i];
        aPoleTrimItems.push(poleT);

        //console.log("selected: " + aPoleTrimItems[i].guPoleTrimmingItemId + "\n\n");
      }

      this.poleTrimmingItemReasonForChangeService.updateTrimmingItemReasonForChanges(poleTrimItem.rowguid, aPoleTrimItems).subscribe(data => {
        this.showErrorMessage = false;
        //console.log("data: " + data);

      },
        (errResponse: HttpErrorResponse) => {
          this.errorMessage = new ErrorMessage();
          this.errorMessage.message = errResponse.error.message;
          this.showErrorMessage = true;
        }
      );
    }
  }

  async getPole(length: number, kn: number, qv: boolean) {
    return new Promise<Pole>(resolve => {
      this.poleService.GetPoleInStock(length, kn, qv, false, false, false).subscribe(data => {
        //console.log("inside observable: " + data);
        resolve(data);
      },
        err => {
          this.errorMessage.message = "There was a problem retreiving pole " + length + " / " + kn + " " + (qv ? "Q/V" : "") + "<br>Is this correct?";
          this.showErrorMessage = true;
          resolve(null);
        });
    });

  }


  onOriginalProductSelect(pt: ProductType) {

    this.eOriginalProductMeasurementType = pt.intMeasurementType;

    if (pt.intMeasurementType == ProductType.enMeasurementType.LengthKN) {
      //console.log("measureType - Disable: " + pt.intMeasurementType)
      this.txtOriginalKN.enable()
      this.chkOriginalQV.enable()

      this.txtOriginalLength.setValidators(this.original_nsw_LengthValidator);
      this.txtOriginalLength.updateValueAndValidity();
      this.originalKNHint = '2, 4, 6, 8, 12, 16, 18, 20';
      this.originalLengthHint = '8m to 26m';
    }
    else {
      //console.log("measureType - Enable: " + pt.intMeasurementType)
      this.txtOriginalKN.setValue("");
      this.chkOriginalQV.setValue(false);

      this.fltOriginalDiameter.setValidators([Validators.maxLength(4)]);
      this.fltOriginalDiameter.setValidators([Validators.min(150), Validators.max(1000)]);
      this.txtOriginalKN.setValidators([Validators.pattern(this.nsw_kNRange)]);

      //console.log("removed validators");

      this.txtOriginalLength.removeValidators(this.original_nsw_LengthValidator);
      this.txtOriginalLength.removeValidators(this.finished_qv_LengthValidator);
      this.txtOriginalLength.updateValueAndValidity();

      this.originalDiamHint = "min: 150mm,  max: 1000mm";
      this.originalDiaPlaceHolder = "Diameter mm";

      this.originalLengthHint = '1m to 26m';

      this.txtOriginalKN.disable()
      this.chkOriginalQV.disable()
    }

  }


  onFinishedProductSelect(pt: ProductType) {

    this.eFinishedProductMeasurementType = pt.intMeasurementType;

    if (pt.intMeasurementType == ProductType.enMeasurementType.LengthKN) {
      //console.log("measureType - Disable: " + pt.intMeasurementType)
      this.txtFinishedKN.enable()
      this.chkFinishedQV.enable()

      this.txtFinishedLength.setValidators(this.finished_nsw_LengthValidator);
      this.txtFinishedLength.updateValueAndValidity();
      this.finishedKNHint = '2, 4, 6, 8, 12, 16, 18, 20';
      this.finishedLengthHint = '8m to 26m';
    }
    else {
      //console.log("measureType - Enable: " + pt.intMeasurementType)
      this.txtFinishedKN.setValue("");
      this.chkFinishedQV.setValue(false);

      this.fltFinishedDiameter.setValidators([Validators.maxLength(4)]);
      this.fltFinishedDiameter.setValidators([Validators.min(150), Validators.max(1000)]);
      this.txtFinishedKN.setValidators([Validators.pattern(this.nsw_kNRange)]);

      this.txtFinishedLength.removeValidators(this.finished_nsw_LengthValidator);
      this.txtFinishedLength.removeValidators(this.finished_qv_LengthValidator);
      this.txtFinishedLength.updateValueAndValidity();


      this.finishedDiamHint = "min: 150mm,  max: 1000mm";
      this.finishedDiaPlaceHolder = "Diameter mm";
      this.finishedLengthHint = '1m to 26m';

      this.txtFinishedKN.disable()
      this.chkFinishedQV.disable()
    }
  }

  getOriginalPole(length, kn, qv) {
    return this.poleService.getPole(length, kn, qv, false, false, false)
  }


  checkDiameter(e: MatSelectChange) {
    const selectedData = {
      text: (e.source.selected as MatOption).viewValue,
      value: e.source.value
    };
    //console.log("product-text: " + selectedData.text);
  }



  getTrimmingItemReasonForChange() {
    this.trimingItemReasonForChangeService.getAllTrimingItemReasonForChangeService().subscribe(data => {
      this.trimmingItemReasonForChangeData = data;
    },
      (errResponse: HttpErrorResponse) => {
        this.errorMessage = new ErrorMessage();
        this.errorMessage.message = errResponse.error.message;
        this.showErrorMessage = true;
      });
  }

  getProductTypes() {
    this.productTypeService.getAllTrimmingProductTypes().subscribe(data => {
      this.productTypes = data;

      //Only do this i Add pole trimming Item
      //let polePT: ProductType
    //  polePT = this.productTypes.find(pt => pt.txtName == 'Pole');
    //  this.form.controls['guOriginalProductTypeId'].setValue(polePT.rowguid);
    //  this.form.controls['guFinishedProductTypeId'].setValue(polePT.rowguid);
    });
  }

  getProductTypesOLD() {
    this.productTypeService.getAllTrimmingProductTypes().subscribe(data => {
      this.productTypes = data;
      this.filteredProductTypes.next(this.productTypes);
      let polePT: ProductType
      polePT = this.productTypes.find(pt => pt.txtName == 'Pole');
      //console.log("guProductTypeId-: " + this.form.value.guProductTypeId);

      this.form.controls['guOriginalProductTypeId'].setValue(polePT.rowguid);
      this.form.controls['guFinishedProductTypeId'].setValue(polePT.rowguid);

      this.productTypeFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterProductTypes();
        });

    },
      (errResponse: HttpErrorResponse) => {
        this.errorMessage = new ErrorMessage();
        this.errorMessage.message = errResponse.error.message;
        this.showErrorMessage = true;
      });

  }

  public getAllRoundProductTypes() {
    return this.productTypeService.getAllRoundProductTypes();
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  private filterProductTypes() {

    if (!this.productTypes) {
      return;
    }

    // get the search keyword
    let search = this.productTypeFilterCtrl.value;
    if (!search) {
      this.filteredProductTypes.next(this.productTypes);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredProductTypes.next(
      this.productTypes.filter(pt => pt.txtName.toLowerCase().indexOf(search) > -1)
    );
  }


  getSpecies() {
    this.speciesService.GetTrimmingSpecies().subscribe(data => {
      this.species = data;
      this.filteredSpecies.next(this.species);

      this.speciesFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterSpecies();
        });
      //console.log("data: " + JSON.stringify(data));
    },
      (errResponse: HttpErrorResponse) => {
        this.errorMessage = new ErrorMessage();
        this.errorMessage.message = errResponse.error.message;
        this.showErrorMessage = true;
      });

  }

  public getAllSpecies() {
    return this.speciesService.getAllSpecies();
  }


  private filterSpecies() {

    if (!this.species) {
      return;
    }

    // get the search keyword
    let search = this.speciesFilterCtrl.value;
    if (!search) {
      this.filteredSpecies.next(this.species);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the Species
    this.filteredSpecies.next(
      this.species.filter(sp => sp.txtCode.toLowerCase().indexOf(search) > -1)
    );
  }


  public selChange(e: MatSelectionListChange) {


    this.reasonForChangeSelected = [];

    for (let i = 0; i < e.source.selectedOptions.selected.length; i++) {
      //console.log("selected: " + e.source.selectedOptions.selected[i].value);
      this.reasonForChangeSelected.push(e.source.selectedOptions.selected[i].value);
    }

  }

  public validateDiameter(diameterControl: AbstractControl, qv: boolean) {
    let measureDef: string = 'Diameter'

    if (qv) {
      measureDef = 'Circumference';
    }

    if (diameterControl.hasError('required')) {
      return 'You must enter a value';
    }

    if (diameterControl.hasError('min')) {
      return 'The ' + measureDef.toLowerCase() + ' is too small';
    }

    if (diameterControl.hasError('max')) {
      return 'The ' + measureDef.toLowerCase() + ' is too big';
    }

    return diameterControl.hasError('maxlength') ? measureDef + ' is too big.' : '';

  }

  public validateKN(knControl: AbstractControl) {
    if (knControl.hasError('pattern')) {
      return 'The KN is not valid';
    }
  }

  public validateLength(lengthControl: AbstractControl) {
    if (lengthControl.hasError('min')) {
      return 'The length is too short';
    }

    if (lengthControl.hasError('max')) {
      return 'The length is too long';
    }

    if (lengthControl.hasError('pattern')) {
      return 'The Length is not valid for a Pole';
    }
  }

  public onOriginalQVClick() {
    if (this.chkOriginalQV.value == true) {
      this.fltOriginalDiameter.setValidators([Validators.maxLength(5)]);
      this.fltOriginalDiameter.setValidators([Validators.min(470), Validators.max(3100)]);
      this.txtOriginalKN.setValidators([Validators.pattern(this.qv_kNRange)]);
      if (this.eOriginalProductMeasurementType == ProductType.enMeasurementType.LengthKN) {
        this.txtOriginalLength.setValidators(this.finished_qv_LengthValidator);
      }
      else {
        this.txtOriginalLength.removeValidators(this.finished_qv_LengthValidator);
      }

      this.originalDiamHint = "min: 470mm,  max: 3100mm";
      this.originalDiaPlaceHolder = "Circumference mm";
      this.originalKNHint = '5, 8, 12, 20';

      this.originalLengthHint = '9.5m to 26m';
    }
    else {
      this.fltOriginalDiameter.setValidators([Validators.maxLength(4)]);
      this.fltOriginalDiameter.setValidators([Validators.min(150), Validators.max(1000)]);
      this.txtOriginalKN.setValidators([Validators.pattern(this.nsw_kNRange)]);
      if (this.eOriginalProductMeasurementType == ProductType.enMeasurementType.LengthKN) {
        this.txtOriginalLength.setValidators(this.finished_nsw_LengthValidator);
      }
      else {
        this.txtOriginalLength.removeValidators(this.finished_nsw_LengthValidator);
      }
      this.originalDiamHint = "min: 150mm,  max: 1000mm";
      this.originalDiaPlaceHolder = "Diameter mm";
      this.originalKNHint = '2, 4, 6, 8, 12, 16, 18, 20';

      this.originalLengthHint = '8m to 26m';
    }
    this.fltOriginalDiameter.updateValueAndValidity();
    this.txtOriginalKN.updateValueAndValidity();
  }


  public onFinishedQVClick() {

    // Remove both length validators.
    this.txtFinishedLength.removeValidators(this.finished_qv_LengthValidator);
    this.txtFinishedLength.removeValidators(this.finished_nsw_LengthValidator);

    if (this.chkFinishedQV.value == true) {
      this.fltFinishedDiameter.setValidators([Validators.maxLength(5)]);
      this.fltFinishedDiameter.setValidators([Validators.min(470), Validators.max(3100)]);
      this.txtFinishedKN.setValidators([Validators.pattern(this.qv_kNRange)]);
      this.txtFinishedLength.setValidators(this.finished_qv_LengthValidator);

      this.finishedDiamHint = "min: 470mm,  max: 3100mm";
      this.finishedDiaPlaceHolder = "Circumference mm";
      this.finishedKNHint = '5, 8, 12, 20';

      this.finishedLengthHint = '9.5m to 26m';

    }
    else {
      this.fltFinishedDiameter.setValidators([Validators.maxLength(4)]);
      this.fltFinishedDiameter.setValidators([Validators.min(150), Validators.max(1000)]);
      this.txtFinishedKN.setValidators([Validators.pattern(this.nsw_kNRange)]);
      this.txtFinishedLength.setValidators(this.finished_nsw_LengthValidator);

      this.finishedDiamHint = "min: 150mm,  max: 1000mm";
      this.finishedDiaPlaceHolder = "Diameter mm";
      this.finishedKNHint = '2, 4, 6, 8, 12, 16, 18, 20';

      this.finishedLengthHint = '8m to 26m';
    }
    this.fltFinishedDiameter.updateValueAndValidity();
    this.txtFinishedKN.updateValueAndValidity();
  }
}
