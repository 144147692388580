export interface dtoHaulingCompanyDriver
{
	blnActive: boolean;
	blnDefaultDriver: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guEmployeeId: string;
	guHaulingCompanyId: string;
	rowguid: string;
	txtAbbreviation: string;
	txtComments: string;
	txtFirstName: string;
	txtHaulingCompanyAddress: string;
	txtHaulingCompanyAdminEmail: string;
	txtHaulingCompanyCorrespondenceEmail: string;
	txtHaulingCompanyMobile: string;
	txtHaulingCompanyName: string;
	txtHaulingCompanyPhone1: string;
	txtHaulingCompanyTown: string;
	txtLastName: string;
	txtMobilePlantAbbreviation: string;
	txtMobilePlantCategoryName: string;
	txtMobilePlantEquipmentName: string;
	txtNickName: string;
}

export class dtoHaulingCompanyDriver
{
	blnActive: boolean;
	blnDefaultDriver: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guEmployeeId: string;
	guHaulingCompanyId: string;
	rowguid: string;
	txtAbbreviation: string;
	txtComments: string;
	txtFirstName: string;
	txtHaulingCompanyAddress: string;
	txtHaulingCompanyAdminEmail: string;
	txtHaulingCompanyCorrespondenceEmail: string;
	txtHaulingCompanyMobile: string;
	txtHaulingCompanyName: string;
	txtHaulingCompanyPhone1: string;
	txtHaulingCompanyTown: string;
	txtLastName: string;
	txtMobilePlantAbbreviation: string;
	txtMobilePlantCategoryName: string;
	txtMobilePlantEquipmentName: string;
	txtNickName: string;
}

