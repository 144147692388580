import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthService } from '../account/auth.service';
import { AppUser } from '../app-users/app-user-update/appuser.module';
import { AppUserService } from '../_shared/services/app-user.service';
import { AppUser as AppUser2 } from '../_shared/business-objects/app-user.bo';
import { SQLParamArray } from '../_shared/business-objects/sql-param-array';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  isAdmin: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private appUserService: AppUserService
    
  ) {
    // redirect to home if already logged in
  //  if (!this.authService.isAuthenticated()) {
  //    this.router.navigate(['/login']);
  //  }
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      token: ['']
    });

    this.registerForm.value.token = localStorage.getItem("txtToken");

    let appUser: AppUser = this.authService.getAppUser();
    if (appUser != null && appUser.blnIsAdmin == true) {
      this.isAdmin = true;
    }

    this.authService.isAdmin.subscribe(isAdmin => {

      this.isAdmin = isAdmin;

      console.log("isAdmin: ", isAdmin);

    }, error => {

      console.log("error: ", error);

    });

    console.log("appUser oninit: ", appUser);

  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    //this.alertService.clear();

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;

    var user = new AppUser()
    user.txtUserName = this.registerForm.value.username;
    user.txtPassword = this.registerForm.value.password;
    user.txtFirstName = this.registerForm.value.firstName
    user.txtLastName = this.registerForm.value.lastName

    this.authService.registerUser(user)
      .subscribe(
        data => {
          //this.alertService.success('Registration successful', true);
          this.router.navigate(['/login']);
        },
        error => {
          //this.alertService.error(error);
          console.log("error: " + error.message);
          this.loading = false;
        });
  }

  async updateUser() {

    if (this.registerForm.invalid) {
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("txtUserName", this.registerForm.value.username));
    aParamList.push(aParam);

    let appUser: AppUser2[] = await this.appUserService.getAppUserParamArrayPromise(aParamList);


    if (appUser != null && appUser.length == 1) {

      let user: AppUser2 = appUser[0];
      user.txtPassword = this.registerForm.value.password;

      console.log("appuer ", appUser);

      this.authService.updateUser(user)
        .subscribe(
          data => {
            //this.alertService.success('Registration successful', true);
            this.router.navigate(['/login']);
          },
          error => {
            //this.alertService.error(error);
            console.log("error: " + error.message);
            this.loading = false;
          });
      }

  }

}
