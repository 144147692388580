<button class="action-button" mat-flat-button color="primary" (click)="getToken()" *ngIf="false">Get Token</button>
<button class="action-button" mat-flat-button color="primary" (click)="getRefreshToken()" *ngIf="false">Get Refresh Token</button>
<button class="action-button" mat-flat-button color="primary" (click)="getAuthCode()" *ngIf="false">Get Code</button>
<button class="action-button" mat-flat-button color="primary" (click)="getTokenWithxmlReq()" *ngIf="false">Get With XMLReq</button>
<button class="action-button" mat-flat-button color="primary" (click)="getUserFiles()" *ngIf="false">Get User Files</button>
<button class="action-button" mat-flat-button color="primary" (click)="getUserFilesWithxmlReq()" *ngIf="false">Get User Files With xml Req</button>

<div id="resDiv" *ngIf="false">

</div>
