<div class="drop-zone-area">

  <div class="drop-zone-container" (drop)="dropHandler($event);" (dragover)="dragOverHandler($event);" (dragleave)="dragOutHandler($event);" [ngClass]="onDropZone ? 'drop_zone drop_zone_hover' : 'drop_zone'">


  </div>

  <div class="image-upload">
    <label for="myInput">
      <mat-icon matTooltip="Add Attachment" class="material-symbols-outlined open-file">attach_email</mat-icon>
    </label>
    <input #myInput id="myInput" type="file" (change)="uploadFile($event)">
  </div>
  <div>
    <button type="button" name="btnClear" (click)="clearAddresses()">Clear Addresses</button>
    <button type="button" name="btnClear" (click)="saveFile()">Save File</button>

  </div>
  <textarea id="emailFromAddresses" cols="120" rows="5"></textarea>

  <textarea id="fileContents" cols="120" rows="50"></textarea>
  <div id="fileContentsDiv"></div>

</div>
