import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { dtoDeliveryDocketOrderItem } from '../../_shared/business-objects/dto-delivery-docket-order-item.bo';
import { dtoTPPoleInspectionItem } from '../../_shared/business-objects/dto-tppole-inspection-item.bo';
import { dtoTPPoleInspection } from '../../_shared/business-objects/dto-tppole-inspection.bo';
import { TPPoleInspectionItem } from '../../_shared/business-objects/tppole-inspection-item.bo';

@Component({
  selector: 'app-delivery-docket-final-items-add',
  templateUrl: './delivery-docket-final-items-add.component.html',
  styleUrls: ['./delivery-docket-final-items-add.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class DeliveryDocketFinalItemsAddComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoTPPoleInspectionItem>;
  @Input() deliveryOrderItemsDatasource: dtoDeliveryDocketOrderItem[];
  @Output() updateFinalInspectionItemEvent = new EventEmitter<dtoTPPoleInspectionItem[]>();
  @Output() unlinkFinalInspectionItemEvent = new EventEmitter<dtoTPPoleInspectionItem>();
 

  @ViewChild('tblfinalInspectionItems') tblfinalInspectionItems: MatTable<dtoTPPoleInspectionItem>; 
  @ViewChild(MatSort) sort: MatSort;

  form: FormGroup;

  displayedfinalInspectionItems: string[] = ['intItemNo', 'txtCustomerName', 'txtOrderNo', 'txtPoleNumber', 'txtConsecutiveNumber', 'txtChargeId', 'txtChargeDate', 'txtOriginalPole', 'txtFinalPole', 'txtDrilled', 'txtSpec', 'fltLength', 'fltGLDia', 'txtProductName', 'txtSpeciesName', 'fltGirth', 'fltActualM3', 'fltActualTonne', 'chkAddItem', 'btnUnlink'];

  deliveryDocketFinalItems: dtoTPPoleInspectionItem[];

  constructor(private fb: FormBuilder) {

    this.form = this.fb.group({
      //chkAddItem:[true]
    });


  }

  ngOnInit(): void {


  }


  ngAfterViewInit() {

    //this.datasource.sort = this.sort;
  }


  addItemChange(finalItem: dtoTPPoleInspectionItem, val: boolean, index: number) {

    if (val == true) {
      finalItem.blnAddToDelivery = true;
      //this.deliveryDocketFinalItems.push(finalItem);
      this.updateFinalInspectionItemEvent.emit(this.deliveryDocketFinalItems);
      console.log("added: " + index + ", " + finalItem.txtPoleNumber, this.deliveryDocketFinalItems);
     //console.log("added: " + finalItem.txtPoleNumber);
    }

    if (val == false) {
      finalItem.blnAddToDelivery = false;
      //this.deliveryDocketFinalItems.splice(index, 1);
      this.updateFinalInspectionItemEvent.emit(this.deliveryDocketFinalItems);
      console.log("removed: " + index + ", " + finalItem.txtPoleNumber, this.deliveryDocketFinalItems);
    }


  }

  setDeliveryDocketFinalItems(finalItems: dtoTPPoleInspectionItem[]) {

    this.deliveryDocketFinalItems = finalItems;

  }

  setDeliveryDocketOrderItems(finalItems: dtoTPPoleInspectionItem[]) {

    

  }


  unlinkFinalItem(finalItem: dtoTPPoleInspectionItem) {
    this.unlinkFinalInspectionItemEvent.emit(finalItem);
  }
}
