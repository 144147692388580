<div class="delivery-docket-view-container">
  <div class="delivery-docket-view-table">
    <div class="delivery-docket-item-no-header item-row-header"></div>
    <div class="delivery-docket-po-number-header item-row-header">P.O #</div>
    <div class="delivery-docket-description-header item-row-header">Description</div>
    <div class="delivery-docket-qty-header item-row-header alignRight">Qty</div>
    <div class="delivery-docket-qty-uom-header item-row-header">Qty UoM</div>
    <div class="delivery-docket-total-m3-header item-row-header alignRight">Total M3</div>
    <div class="delivery-docket-weight-header item-row-header alignRight">Weight (T)</div>

    <ng-container *ngFor="let deliveryDocketOrderItem of deliveryDocketOrderItems; let idx = index">

      <div class="delivery-docket-item-no item-row alignRight">{{idx + 1}}</div>
      <div class="order-number item-row">{{deliveryDocketOrderItem.txtOrderNo}}</div>
      <div class="delivery-docket-description item-row">
        {{deliveryDocketOrderItem.txtDescription}}
        <div *ngIf="deliveryDocketOrderItem.txtComments != null" [innerHTML]="deliveryDocketOrderItem.txtComments | safeHtml"></div>
      </div>
      <div class="item-row alignRight delivery-docket-qty">{{deliveryDocketOrderItem.fltBoardTallyRecordQuantity != null && deliveryDocketOrderItem.fltBoardTallyRecordQuantity > 0 ? deliveryDocketOrderItem.fltBoardTallyRecordQuantity : deliveryDocketOrderItem.fltQuantity}}</div>
      <div class="delivery-docket-qty-uom item-row">{{deliveryDocketOrderItem.txtOrderItemQuantityUom}}</div>
      <div class="delivery-docket-total-m3 item-row alignRight">{{deliveryDocketOrderItem.fltActualM3 | number : '1.2-2' }}</div>
      <div class="delivery-docket-weight item-row alignRight">{{deliveryDocketOrderItem.fltActualTonne | number : '1.2-2'}}</div>

      <div class="delivery-docket-item-no-footer item-footer alignRight" *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)">Totals</div>
      <div class="delivery-docket-description-footer item-footer alignRight" *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)">
        <span *ngIf="deliveryDocketOrderItem.totalQtyBoxes > 0" [ngClass]="deliveryDocketOrderItem.totalQtyLM > 0 || deliveryDocketOrderItem.totalQtyEach > 0 || deliveryDocketOrderItem.totalQtyM2 > 0 ? 'item-footer-boxes' : ''">{{deliveryDocketOrderItem.totalQtyBoxes + (deliveryDocketOrderItem.uomMultiples == true ? ' Boxes' : '')}}</span>
        <span *ngIf="deliveryDocketOrderItem.totalQtyM2 > 0" [ngClass]="deliveryDocketOrderItem.totalQtyLM > 0 || deliveryDocketOrderItem.totalQtyEach > 0 ? 'item-footer-m2' : ''">{{deliveryDocketOrderItem.totalQtyM2 + (deliveryDocketOrderItem.uomMultiples == true ? ' M2' : '')}}</span>
        <span *ngIf="deliveryDocketOrderItem.totalQtyLM > 0" [ngClass]="deliveryDocketOrderItem.totalQtyEach > 0 ? 'item-footer-lm' : ''">{{deliveryDocketOrderItem.totalQtyLM + (deliveryDocketOrderItem.uomMultiples == true ? ' LM' : '')}}</span>
        <span *ngIf="deliveryDocketOrderItem.totalQtyEach > 0">{{deliveryDocketOrderItem.totalQtyEach}}</span>
      </div>

      <div class="delivery-docket-qty-uom-footer item-footer" *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)"></div>
      <div class="delivery-docket-total-m3-footer item-footer alignRight" *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)">
        {{deliveryDocketOrderItem.totalM3}}
      </div>
      <div class="delivery-docket-weight-footer item-footer item-footer-weight alignRight" *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)">
        {{deliveryDocketOrderItem.totalTonne}}
      </div>


 




    </ng-container>
  </div>

</div>
