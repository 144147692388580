import { AfterViewInit, Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DeliveryDocketOrderItem } from '../../_shared/business-objects/delivery-docket-order-item.bo';
import { dtoDeliveryDocketOrderItem } from '../../_shared/business-objects/dto-delivery-docket-order-item.bo';
import { dtoDeliveryDocket } from '../../_shared/business-objects/dto-delivery-docket.bo';
import { dtoTPPoleInspectionItem } from '../../_shared/business-objects/dto-tppole-inspection-item.bo';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-delivery-docket-final-items-preview',
  templateUrl: './delivery-docket-final-items-preview.component.html',
  styleUrls: ['./delivery-docket-final-items-preview.component.css']
})
export class DeliveryDocketFinalItemsPreviewComponent implements OnInit, AfterViewInit {

  @Input() deliveryOrderItemsDatasource: MatTableDataSource<dtoDeliveryDocketOrderItem>;
  @Input() finalItemsDatasource: dtoTPPoleInspectionItem[];
  @Input() deliveryDocket: dtoDeliveryDocket[];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tblDeliveryDocketOrderItem') tblDeliveryDocketOrderItem: MatTable<dtoDeliveryDocketOrderItem>;

  //deliveryDocketOrderItemQtyOnOrderTotal: number;
  deliveryDocketOrderItemQtyTotal: number;
  deliveryDocketOrderItemM3Total: number;
  deliveryDocketOrderItemTonneTotal: number;
  deliveryDocketOrderItemFinalQtyTotal: number;
  deliveryDocketOrderItemFinalItemsToAddQtyTotal: number;
  deliveryDocketOrderItemFinalItemsToAddQtyTotalTotal: number;

  deliveryDocketOrderItemFinalItemsToAddM3Total: number;
  deliveryDocketOrderItemFinalItemsToAddTonneTotal: number;

  intQuantityToAdd: number;
  intQuantityToAddTotal: number;

  displayedDeliveryDocketPreview: string[] = ['txtOrderNo', 'txtCustomerName', 'txtProductTypeName', 'txtPoleLengthKN', 'fltQuantityOnOrder', 'fltQtyBal', 'fltQuantity', 'intFinalQuantity', 'fltActualM3', 'fltActualTonne', 'intFinalItemsToAdd', 'intFinalItemsToAddTotal', 'fltFinalItemsToAddTotalActualM3', 'fltFinalItemsToAddTotalActualTonne'];


  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    //this.deliveryDocketOrderItemQtyOnOrderTotal = 0;
    this.deliveryDocketOrderItemQtyTotal = 0;
    this.deliveryDocketOrderItemM3Total = 0;
    this.deliveryDocketOrderItemTonneTotal = 0;
    this.deliveryDocketOrderItemFinalQtyTotal = 0;
    this.deliveryDocketOrderItemFinalItemsToAddQtyTotal = 0;
    this.deliveryDocketOrderItemFinalItemsToAddQtyTotalTotal = 0;
    this.deliveryDocketOrderItemFinalItemsToAddM3Total = 0;
    this.deliveryDocketOrderItemFinalItemsToAddTonneTotal = 0;

  }

  ngAfterViewInit() {
    if (this.deliveryOrderItemsDatasource != null) {
      setTimeout(() => { this.deliveryOrderItemsDatasource.sort = this.sort },200);
    }
  }

  setTotals() {
    
    this.deliveryDocketOrderItemQtyTotal = 0;
    this.deliveryDocketOrderItemM3Total = 0;
    this.deliveryDocketOrderItemTonneTotal = 0;
    this.deliveryDocketOrderItemFinalQtyTotal = 0;
    this.deliveryDocketOrderItemFinalItemsToAddQtyTotal = 0;
    this.deliveryDocketOrderItemFinalItemsToAddQtyTotalTotal = 0;
    this.deliveryDocketOrderItemFinalItemsToAddM3Total = 0;
    this.deliveryDocketOrderItemFinalItemsToAddTonneTotal = 0;

    if (this.deliveryOrderItemsDatasource.data != null) {
      this.deliveryOrderItemsDatasource.data.forEach((a)=> {
        //this.deliveryDocketOrderItemQtyOnOrderTotal += a.fltQuantityTotal;
        this.deliveryDocketOrderItemQtyTotal += a.fltQuantity;
        this.deliveryDocketOrderItemM3Total += Number(Number(a.fltActualM3).toFixed(3));
        this.deliveryDocketOrderItemTonneTotal += Number(Number(a.fltActualTonne).toFixed(3));
        this.deliveryDocketOrderItemFinalQtyTotal += a.intFinalQuantity;
        this.deliveryDocketOrderItemFinalItemsToAddQtyTotal += a.intFinalItemsToAdd;
        this.deliveryDocketOrderItemFinalItemsToAddQtyTotalTotal += a.intFinalItemsToAddTotal;
        this.deliveryDocketOrderItemFinalItemsToAddM3Total += a.fltFinalItemsToAddTotalActualM3;
        this.deliveryDocketOrderItemFinalItemsToAddTonneTotal += a.fltFinalItemsToAddTotalActualTonne;

      });
      this.deliveryDocketOrderItemM3Total = Number(this.deliveryDocketOrderItemM3Total.toFixed(3));
      this.deliveryDocketOrderItemTonneTotal = Number(this.deliveryDocketOrderItemTonneTotal.toFixed(3));
      this.deliveryDocketOrderItemFinalItemsToAddM3Total = Number(this.deliveryDocketOrderItemFinalItemsToAddM3Total.toFixed(3));
      this.deliveryDocketOrderItemFinalItemsToAddTonneTotal = Number(this.deliveryDocketOrderItemFinalItemsToAddTonneTotal.toFixed(3));

    }
  }


  updatePreview(deliveryDocketFinalItems: dtoTPPoleInspectionItem[]) {
    let i: number = 0;
    deliveryDocketFinalItems.forEach((a) => {
      i++;
      //this.deliveryDocketOrderItemQtyTotal = a.;
      this.deliveryDocketOrderItemM3Total += a.fltActualM3;
      this.deliveryDocketOrderItemTonneTotal += a.fltActualTonne;
    });


    //console.log("deliveryOrderItemsDatasource", this.deliveryOrderItemsDatasource);

  }

  RoundNumber(val, decimal) {
    return this.sharedService.RoundNumber(val, decimal);
  }

}
