<div class="delivery-docket-order-item-add-container" id="orderItemContainer">

  <app-app-user-column-set-bar [componentColumns]="displayedDeliveryDocketOrderItemsAdd" [pageName]="'Delivery Docket Order Items Add'" [container]="columnBarContainer" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)" *ngIf="loadedComponent"></app-app-user-column-set-bar>

  <table mat-table #tblOrderItems id="tblOrderItems{{appIndex}}" [dataSource]="datasource" class="mat-elevation-z8" matSort *ngIf="datasource != null">

    <ng-container matColumnDef="intItemNo">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD finalItemNumber"> {{orderItem.intItemNo}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD footerTD">  </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerName">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Customer </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD customerName"> {{orderItem.txtCustomerName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtOrderNo">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Order No </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD orderNo"> {{orderItem.txtOrderNo}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtProductTypeName">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Product Name </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD productName"> {{orderItem.txtProductName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtProfileName">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" mat-sort-header> Profile </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD profileName"> {{orderItem.txtProfileName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltWidth">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" mat-sort-header> Width </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD width"> {{orderItem.fltWidth}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltThickness">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" mat-sort-header> Thickness </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD thickness"> {{orderItem.fltThickness}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtLengthKN">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" mat-sort-header> Length / KN </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD lengthKN"> {{orderItem.intLength}} {{orderItem.intLength != null ? '/' : '' }} {{orderItem.intKN}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltLength">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" mat-sort-header> Length </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD length"> {{orderItem.fltLength}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltDiameter">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" mat-sort-header> Diameter </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD diameter"> {{orderItem.fltDiameter == 0 ? '' : orderItem.fltDiameter}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtSpecies">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Species </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD species"> {{orderItem.txtSpecies}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtGradeName">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Grade </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD grade"> {{orderItem.txtGradeName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtTreatmentType">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Treatment </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD treatment"> {{orderItem.txtTreatmentType}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtTreatmentHazardLevel">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Hazard Level </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD hazardLevel"> {{orderItem.txtTreatmentHazardLevel}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtStockCode">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Stock Code </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD stockCode"> {{orderItem.txtStockCode}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltQtyBal">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" arrowPosition="before" mat-sort-header> Quantity Bal </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD finalQuantityOnOrder alignRightTD"> {{orderItem.fltQtyBal}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRight footerTD"> </td>
    </ng-container>

    <ng-container matColumnDef="fltQuantityOnOrder">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" arrowPosition="before" mat-sort-header> Quantity On Order</th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD alignRightTD quantity">
        {{orderItem.fltQuantityTotal}}
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD"> {{deliveryDocketOrderItemQtyTotalInput}} </td>
    </ng-container>

    <ng-container matColumnDef="fltQuantity">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" arrowPosition="before" mat-sort-header> Quantity To Add </th>
      <td mat-cell *matCellDef="let orderItem; let rowIdx = index;" class="orderItemTD alignRightTD quantity">
        <mat-form-field class="example-full-width header-button" style="width: 75px !important" appearance="fill">
          <mat-label>Quantity</mat-label>
          <input class="textbox-input" matInput type="number" value="{{orderItem.blnExistsOnDocket == false ? orderItem.fltQtyBal : ''}}" id="txtQuantity{{rowIdx}}" #txtQuantity [disabled]="(orderItem.fltQtyBal == 0 || orderItem.blnExistsOnDocket == true) ? true : false" (change)="updateOrderItem(orderItem, txtQuantity.value)">
        </mat-form-field>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD"> {{deliveryDocketOrderItemQtyTotalInput}} </td>
    </ng-container>

    <ng-container matColumnDef="txtQuantityUOM">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Qty UOM </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD quantityUOM"> {{orderItem.txtOrderItemQuantityUom}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtAddress">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH addressTH" mat-sort-header> Address </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD address"> {{orderItem.txtAddress}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtComments">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Comments </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD comments"> {{orderItem.txtComments}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="fltM3">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" align="right" arrowPosition="before" mat-sort-header> M3 </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD M3 alignRightTD"> {{orderItem.fltM3}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD"> {{deliveryDocketOrderItemM3TotalInput}} </td>
    </ng-container>

    <ng-container matColumnDef="fltTonne">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" arrowPosition="before" mat-sort-header> Tonne </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD actualTonne alignRightTD"> {{RoundNumber(orderItem.fltTonne,3)}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerTonne"> {{deliveryDocketOrderItemTonneTotalInput}} </td>
    </ng-container>

    <ng-container matColumnDef="chkAddItem">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH orderItemAddItemTH" mat-sort-header>
      <mat-checkbox class="example-margin" #addAllItem id="chkAddAllItem" [checked]="true" (change)="addAllItemsChange(addAllItem)">Add Items</mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let orderItem; let rowIdx = index;" class="orderItemTD orderItemAddItem">
        <mat-checkbox class="example-margin" #addItem id="chkAddItem{{rowIdx}}" [checked]="(orderItem.blnExistsOnDocket == false && orderItem.fltQtyBal > 0) ? true : false" [disabled]="(orderItem.blnExistsOnDocket == true || orderItem.fltQtyBal == 0) ? true : false" (change)="addItemChange(orderItem, addItem.checked, rowIdx)">Add Item</mat-checkbox>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedDeliveryDocketOrderItemsAdd"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedDeliveryDocketOrderItemsAdd;">
    </tr>
    <tr mat-footer-row *matFooterRowDef="displayedDeliveryDocketOrderItemsAdd"></tr>
  </table>
  <div class="no-delivery-docket-orderitems-items-add" *ngIf="datasource == null"><h7>There are no order items</h7></div>
</div>

