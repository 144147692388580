<p>report-centre works!</p>
<div>
  <div style="display: block; width: 1400px;">
    <canvas *ngIf="lineChart2 != null" baseChart
            [datasets]="lineChart2.chartData"
            [labels]="lineChart2.chartLabels"
            [options]="lineChart2.chartOptions"
            [legend]="lineChart2.chartLegend"
            [type]="lineChart2.chartType"
            [plugins]="chartPlugins">
    </canvas>
    <br /><br /><br />

    <canvas baseChart
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [legend]="lineChartLegend"
            [type]="lineChartType">
    </canvas>
    <br /><br /><br />
    <canvas baseChart
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [type]="barChartType"
            [plugins]="chartPlugins">
    </canvas>
  </div>
</div>

