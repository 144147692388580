import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '../alert.service';

export enum enApp { WebApp, DesktopApp, MobileApp };

@Injectable()
export class AppService {

  enApp = enApp;
  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {

    this._baseUrl = baseUrl;

  }

  appText(app: number) {
    if (app == enApp.WebApp) {
      return "Web App";
    }

    if (app == enApp.DesktopApp) {
      return "Desktop App";
    }

    if (app == enApp.MobileApp) {
      return "Mobile App";
    }

    return "";

  }

  isTestDatabase() {
    return new Promise<boolean>(resolve => {
      this.http.get<boolean>(this._baseUrl + 'api/Utilities/IsTestDatabase').subscribe({
        next: isTestDB => {
          resolve(isTestDB);
        }
      });
    });
  }

}
