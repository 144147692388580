import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';
import { dtoStockItemDisplay } from '../../_shared/business-objects/dto-stock-item-display.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { FileService } from '../../_shared/services/app-services/file.service';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrl: './photo-viewer.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PhotoViewerComponent implements OnInit {

  @Input() path: string;
  @Input() folderName: string;
  @Input() fileNameOverride: string;
  @Output() photoViewerClose = new EventEmitter<boolean>();
  @Output() emailPhotosEvent = new EventEmitter<FileAttachment[]>();
  @Output() deletedAllPhotosEvent = new EventEmitter<boolean>();

  fileAttachments: FileAttachment[];
  selectedFileAttachments: FileAttachment[] = [];
  loadFileAttachments: FileAttachment[];
  loadNumber: number;
  totalPhotos: number;

  showSpinner: boolean = false;

  constructor(private alertService: AlertService, private fileService: FileService, protected sanitizer: DomSanitizer) {

  }


  ngOnInit(): void {

    this.getPhotos();

    console.log("path : ", this.path);

  }

  async getPhotos() {
    return new Promise<boolean>(async resolve => {
      if (this.path == null || this.path == "") {
        await this.alertService.openSnackBarCustomDefaultPromise("No Path", "No photo path was supplied");
        resolve(false);
      }

      this.showSpinner = true;

      //let spinner = document.getElementById("photoViewerSpinner");

      //console.log(spinner);
      let allFileNames: string[] = await this.fileService.getFileNames(this.path + this.folderName);
      if (allFileNames == null) {
        this.showSpinner = false;
        resolve(false);
      }

      let fileNames: string[] = await allFileNames.filter(a => { return a.toLowerCase().indexOf("thumbs") == -1 });
      this.totalPhotos = fileNames.length;
      this.loadNumber = 0;

      //console.log("fileNames: ", fileNames);
      this.fileAttachments = [];
      this.loadFileAttachments = [];
      for (let i = 0; i <= fileNames.length - 1; i++) {
        if (fileNames[i].toLowerCase().indexOf("thumbs") == -1) {
          this.loadFileAttachments = await this.fileService.getFile(this.path + this.folderName, fileNames[i]);
          this.loadNumber = i + 1;
          this.showSpinner = false;
          this.fileAttachments.push(this.loadFileAttachments[0]);
        }
      }

      this.showSpinner = false;

      resolve(true);

      //this.fileAttachments = await this.fileService.getFiles(this.path + this.folderName);
    });

  }

  dataURI(fileAttachment: FileAttachment) {

    return this.sanitizer.bypassSecurityTrustUrl(fileAttachment.dataURI());
  }


  async emailPhotos() {
    if (this.selectedFileAttachments.length == 0) {
      await this.alertService.openSnackBarCustomDefaultPromise("No Selected Photos", "There are no selected photos to email.\nPlease tick the tickbox to select photos to email");
      return;
    }

    this.emailPhotosEvent.emit(this.selectedFileAttachments);

  }

  closePhotos() {
    this.photoViewerClose.emit(true);
  }

  selectedPhoto(chkSelected: MatCheckbox, fileAttachment: FileAttachment) {

    if (chkSelected.checked == true) {
      this.selectedFileAttachments.push(fileAttachment);
    }

    if (chkSelected.checked == false) {
      for (let i = this.selectedFileAttachments.length - 1; i > -1; i--) {
        if (fileAttachment.fileName == this.selectedFileAttachments[i].fileName) {
          this.selectedFileAttachments.splice(i, 1);
        }
      }

    }

  }

  async deletePhotos() {

    let msg: string = await this.alertService.openSnackBarCustomPromise("Delete Photos", "Are you sure you want to delete the selected photos", "Yes", "No", "center", "bottom", "", 0, true);
    if (msg != "Yes") {
      return;
    }

    console.log("this.selectedFileAttachments:", this.selectedFileAttachments);


    let deleted: boolean = await this.fileService.deleteFiles(this.selectedFileAttachments)
    if (deleted == true) {

      await await this.getPhotos();
      this.alertService.openSnackBarDefault("The photos have been removed");

      console.log("deletedPhotos - photo-viewer");

      console.log("this.fileAttachments", this.fileAttachments);

      if (this.fileAttachments == null || this.fileAttachments.length == 0) {
        console.log("about to trigger deletedAllPhotosEvent");
       this.deletedAllPhotosEvent.emit(true);
        console.log("about to trigged deletedAllPhotosEvent");
      }


    }

  }

  async savePhoto(ev) {
    console.log("saving photo");

    let saved: boolean = await this.fileService.savePhoto(ev, this.path + this.folderName, this.fileNameOverride);

    if (saved == true) {
      console.log("getPhotos");
      await this.getPhotos();
    }

  }

  openPhoto(fileAttachment: FileAttachment) {

    this.fileService.downloadAttachment(fileAttachment);

  }

}
