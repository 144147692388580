import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { OrdersCustomerDeliveryContact } from '../business-objects/orders-customer-delivery-contact.bo';

@Injectable()
export class OrdersCustomerDeliveryContactService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getOrdersCustomerDeliveryContact(ordersCustomerDeliveryContactId: string) {
		return this.http.get<OrdersCustomerDeliveryContact>(this._baseUrl + 'api/OrdersCustomerDeliveryContact/' + ordersCustomerDeliveryContactId);
	}

	getOrdersCustomerDeliveryContactParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<OrdersCustomerDeliveryContact[]>(this._baseUrl + 'api/OrdersCustomerDeliveryContact/GetOrdersCustomerDeliveryContactsParamArray/', aParam);
	}

	getAllOrdersCustomerDeliveryContacts() {
		return this.http.get<OrdersCustomerDeliveryContact[]>(this._baseUrl + 'api/OrdersCustomerDeliveryContact/GetAllOrdersCustomerDeliveryContacts/');
	}

	//getAlldtoOrdersCustomerDeliveryContacts() {
	//	return this.http.get<dtoOrdersCustomerDeliveryContact[]>(this._baseUrl + 'api/OrdersCustomerDeliveryContact/GetAllOrdersCustomerDeliveryContacts/');
	//}

	createOrdersCustomerDeliveryContact(ordersCustomerDeliveryContact: OrdersCustomerDeliveryContact) {
		return this.http.post<OrdersCustomerDeliveryContact>(this._baseUrl + 'api/OrdersCustomerDeliveryContact/CreateOrdersCustomerDeliveryContact/', ordersCustomerDeliveryContact);
	}

	updateOrdersCustomerDeliveryContact(ordersCustomerDeliveryContact: OrdersCustomerDeliveryContact) {
		return this.http.put<OrdersCustomerDeliveryContact>(this._baseUrl + 'api/OrdersCustomerDeliveryContact/UpdateOrdersCustomerDeliveryContact/', ordersCustomerDeliveryContact);
	}

	deleteOrdersCustomerDeliveryContact(ordersCustomerDeliveryContactId: string) {
		return this.http.delete(this._baseUrl + 'api/OrdersCustomerDeliveryContact/DeleteOrdersCustomerDeliveryContact/' + ordersCustomerDeliveryContactId);
	}

	async getOrdersCustomerDeliveryContactPromise(ordersCustomerDeliveryContactId: string) {
		return new Promise<OrdersCustomerDeliveryContact>(resolve => {
			this.getOrdersCustomerDeliveryContact(ordersCustomerDeliveryContactId).subscribe(ordersCustomerDeliveryContact => {
				resolve(ordersCustomerDeliveryContact);
			}, err => {
				this.alertService.openSnackBarError("Error getting OrdersCustomerDeliveryContact records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getOrdersCustomerDeliveryContactParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<OrdersCustomerDeliveryContact[]>(resolve => {
			this.getOrdersCustomerDeliveryContactParamArray(aParam).subscribe(ordersCustomerDeliveryContacts => {
				resolve(ordersCustomerDeliveryContacts);
			}, err => {
				this.alertService.openSnackBarError("Error getting OrdersCustomerDeliveryContact records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateOrdersCustomerDeliveryContactPromise(ordersCustomerDeliveryContact: OrdersCustomerDeliveryContact) {
		return new Promise<OrdersCustomerDeliveryContact>(resolve => {
			this.updateOrdersCustomerDeliveryContact(ordersCustomerDeliveryContact).subscribe(ordersCustomerDeliveryContact => {
				resolve(ordersCustomerDeliveryContact);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating OrdersCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createOrdersCustomerDeliveryContactPromise(ordersCustomerDeliveryContact: OrdersCustomerDeliveryContact) {
		return new Promise<OrdersCustomerDeliveryContact>(resolve => {
			this.createOrdersCustomerDeliveryContact(ordersCustomerDeliveryContact).subscribe(ordersCustomerDeliveryContact => {
				resolve(ordersCustomerDeliveryContact);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating OrdersCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deleteOrdersCustomerDeliveryContactPromise(ordersCustomerDeliveryContactId: string) {
		return new Promise<boolean>(resolve => {
			this.deleteOrdersCustomerDeliveryContact(ordersCustomerDeliveryContactId).subscribe(ordersCustomerDeliveryContact => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing OrdersCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}