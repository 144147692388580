import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { dtoSlobStockSummary } from '../../_shared/business-objects/dto-slob-stock-summary.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { GuestService } from '../../_shared/services/guest.service';

@Component({
  selector: 'app-stock-for-sale',
  templateUrl: './stock-for-sale.component.html',
  styleUrl: './stock-for-sale.component.scss'
})
export class StockForSaleComponent implements OnInit {

  @Output() hideNavEvent = new EventEmitter<boolean>();

  stockItems: dtoSlobStockSummary[]

  constructor(private guestService: GuestService) {

  }


  ngOnInit(): void {

    this.getStockForSale()

  }

  async getStockForSale() {



    this.stockItems = await this.guestService.getSlobStockSummaryPromise();

    if (this.stockItems == null) {
      return;
    }


    this.stockItems.sort(

      (a, b) => {
        // Sort by txtProductName (ascending)
        if (a.txtProductName < b.txtProductName) return -1;
        if (a.txtProductName > b.txtProductName) return 1;
        // Sort by intWidth (descending)
        if (a.intWidth < b.intWidth) return -1;
        if (a.intWidth > b.intWidth) return 1;
        // Sort by intThickness (ascending)
        if (a.intThickness < b.intThickness) return -1;
        if (a.intThickness > b.intThickness) return 1;
        // Sort by txtSpecies (ascending)
        if (a.txtSpeciesName < b.txtSpeciesName) return -1;
        if (a.txtSpeciesName > b.txtSpeciesName) return 1;
        // Sort by txtGradeName (ascending)
        if (a.txtGradeName < b.txtGradeName) return -1;
        if (a.txtGradeName > b.txtGradeName) return 1;
      }

    );
    console.log(this.stockItems);
  }

}
