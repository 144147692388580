import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TaxInvoiceItem } from '../business-objects/tax-invoice-item.bo';
import { dtoTaxInvoiceItem } from '../business-objects/dto-tax-invoice-item.bo';


@Injectable()
export class TaxInvoiceItemService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getTaxInvoiceItems(invoiceId: string) {
    return this.http.get<TaxInvoiceItem[]>(this._baseUrl + 'api/TaxInvoiceItem/GetTaxInvoiceItems/' + invoiceId);
  }

  getDTOTaxInvoiceItems(invoiceId: string) {
    return this.http.get<dtoTaxInvoiceItem[]>(this._baseUrl + 'api/TaxInvoiceItem/GetDTOTaxInvoiceItems/' + invoiceId);
  }

  getDTOTaxInvoiceItemsByInvoiceNo(invoiceNo: string) {
    return this.http.get<dtoTaxInvoiceItem[]>(this._baseUrl + 'api/TaxInvoiceItem/GetDTOTaxInvoiceItemsByInvoiceNo/' + invoiceNo);
  }

  getTaxInvoicesByRowguid(taxInvoiceItemId: string) {
    return this.http.get<TaxInvoiceItem>(this._baseUrl + 'api/TaxInvoiceItem/' + taxInvoiceItemId);
  }


  createTaxInvoiceItem(taxInvoice: TaxInvoiceItem) {
    return this.http.post<TaxInvoiceItem>(this._baseUrl + 'api/TaxInvoiceItem/CreateTaxInvoiceItem/', taxInvoice);
  }

}
