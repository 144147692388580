<form class="example-form royalty-rate-container-form" [formGroup]="form">

  <div class="royalty-rates-container" id="royaltyRatesContainer" #royaltyRatesElement>
    <h1>Royalty Rates</h1>

    <mat-tab-group class="royalty-rate-mat-tab-group" color="accent" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabRoyaltyRates>
      <mat-tab label="Royalty Rates" class="tab-label">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Durability Class</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Blackbutt" value="" type="text" id="txtSearchFinalNumber" formControlName="txtSearchDurabilityClass" (keyup.enter)="searchRoyaltyRates()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. Blackbutt,Spotted Gum..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-3">
            <button class="search-royalty-rate-button" type="button" mat-flat-button color="primary" id="btnSearchRoyaltyRatesMain" (click)="searchRoyaltyRates()">Search</button>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="royalty-rate-list-container">
          <app-royalty-rate-list #royaltyRateList [datasource]="royaltyRates" [columnBarContainer]="'royaltyRatesContainer'" [columnListId]="'columnListId'" [columnBarPage]="'Royalty Rates'" (editRoyaltyRateEvent)="editRoyaltyRate($event)" *ngIf="royaltyRates != null"></app-royalty-rate-list>
        </div>

      </mat-tab>
      <mat-tab label="Search Options" class="tab-label">
        <div class="search-options-fields-container" id="searchFieldsContainer">

          <div class="search-option-row-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Length</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 12.5" value="" type="text" id="txtLength" formControlName="txtSearchLength" (keyup.enter)="searchRoyaltyRates()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full description i.e. 12* " class="help-icon">help_outline</mat-icon>
            <span class="search-options-search-button">
              <button class="search-royalty-rate-button" type="button" mat-flat-button color="primary" id="btnSearchRoyaltyRate" (click)="searchRoyaltyRates()">Search</button>
            </span>
          </div>

          <div class="search-option-row-3">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>KN</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 6" value="" type="text" id="txtKN" formControlName="txtSearchKN" (keyup.enter)="searchRoyaltyRates()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full description i.e. 6* " class="help-icon">help_outline</mat-icon>
          </div>


          <div class="search-option-row-current">
            <label id="example-radio-group-label">Current Rate</label>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radCurrent">
              <mat-radio-button class="example-radio-button" [value]="0" [checked]="true">Yes</mat-radio-button>
              <mat-radio-button class="example-radio-button" [value]="1">No</mat-radio-button>
              <mat-radio-button class="example-radio-button" [value]="2">Either</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="search-option-row-3">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Created date from</mat-label>
              <input id="dteSearchDateCreatedFrom" class="date-input date-created-search" #searchDateCreatedFrom matInput [matDatepicker]="pickerdatecreatedfrom" formControlName="dteSearchDateCreatedFrom">
              <mat-datepicker-toggle matSuffix [for]="pickerdatecreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerdatecreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>


          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Created date to</mat-label>
              <input id="dteSearchDateCreatedTo" class="date-input date-created-search" #searchDateCreatedTo matInput [matDatepicker]="pickerdatecreatedto" formControlName="dteSearchDateCreatedTo">
              <mat-datepicker-toggle matSuffix [for]="pickerdatecreatedto"></mat-datepicker-toggle>
              <mat-datepicker #pickerdatecreatedto></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-3">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Expiry date from</mat-label>
              <input id="dteSearchDateExpiredFrom" class="date-input expiry-search" #searchExpiryFrom matInput [matDatepicker]="pickerexpiryfrom" formControlName="dteSearchExpiryFrom">
              <mat-datepicker-toggle matSuffix [for]="pickerexpiryfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerexpiryfrom></mat-datepicker>
            </mat-form-field>
          </div>


          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Expiry date to</mat-label>
              <input id="dteSearchExpiryTo" class="date-input expiry-search" #searchExpiryTo matInput [matDatepicker]="pickerexpiryto" formControlName="dteSearchExpiryTo">
              <mat-datepicker-toggle matSuffix [for]="pickerexpiryto"></mat-datepicker-toggle>
              <mat-datepicker #pickerexpiryto></mat-datepicker>
            </mat-form-field>
          </div>

        </div>
      </mat-tab>

      <mat-tab label="Create Royalty Rate" class="tab-label">
        <div class="create-royalty-rate-container">
          <app-royalty-rate-create (royaltyRateCreated)="royaltyRateCreated($event)" *ngIf="selectedTab == eRoyaltyRateTabs.CreateRoyaltyRate"></app-royalty-rate-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Royalty Rate" class="tab-label">
        <div class="update-royalty-rate-container">
          <app-royalty-rate-update [royaltyRate]="royaltyRate" (royaltyRateUpdated)="royaltyRateUpdated($event)" (royaltyRateCancelled)="royaltyRateCancelled($event)" *ngIf="selectedTab == eRoyaltyRateTabs.UpdateRoyaltyRate"></app-royalty-rate-update>
        </div>
      </mat-tab>

      <mat-tab label="Utilities" class="tab-label">
        <div class="update-royalty-rate-container">
          <app-royalty-rate-utility (royaltyRatesUpdated)="royaltyRatesUpdated($event)" (royaltyRatesCancelled)="royaltyRatesCancelled($event)"></app-royalty-rate-utility>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
