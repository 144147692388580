import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StockContainer } from '../business-objects/stock-container.bo';
import { SharedService } from './shared.service';
import { BoardTallyRecordHistory } from '../business-objects/board-tally-record-history.bo';
import { AlertService } from './alert.service';



@Injectable()
export class StockContainerService {

  public _baseUrl: string;
  
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getStockContainers() {
    return this.http.get(this._baseUrl + 'api/stockcontainer');
  }

  getStockContainer(stockContainerId: string) {
    return this.http.get<StockContainer>(this._baseUrl + 'api/stockcontainer/' + stockContainerId);
  }

  getStockContainerByID(identifier: number) {
    return this.http.get<StockContainer[]>(this._baseUrl + 'api/stockcontainer/' + identifier);
  }

  updateStockContainer(employeeid: string, createHistory: boolean, eChangeReason: BoardTallyRecordHistory.enChangeReason, stockitem: StockContainer) {
    console.log(this._baseUrl + 'api/stockcontainer/');
    return this.http.put<StockContainer>(this._baseUrl + 'api/stockcontainer/' + employeeid + '/' + createHistory + '/' + eChangeReason, stockitem);
  }

  createStockContainer(employeeid: string, createHistory: boolean, eChangeReason: BoardTallyRecordHistory.enChangeReason, useAutoNumbers: boolean, stockitem: StockContainer) {
    return this.http.post<StockContainer>(this._baseUrl + 'api/stockcontainer/' + employeeid + '/' + createHistory + '/' + eChangeReason + '/' + useAutoNumbers, stockitem);
  }


  public getStockContainerLocations() {

    return this.http.get(this._baseUrl + 'api/stockcontainerlocation');
  }

  getStockContainerPromise(stockContainerId: string) {
    return new Promise<StockContainer>(resolve => {
      this.getStockContainer(stockContainerId).subscribe(stockContainer => {

        resolve(stockContainer);

      }, err => {
        this.alertService.openSnackBarError("Error getting Stock Container", "Close", "center", "bottom", 4000, true, err.error.message);
        console.log(err);
        resolve(err);
      });
    });
  }

  createStockContainerPromise(employeeid: string, createHistory: boolean, eChangeReason: BoardTallyRecordHistory.enChangeReason, useAutoNumbers: boolean, stockContainer: StockContainer) {
    return new Promise<StockContainer | HttpErrorResponse>(resolve => {
      this.createStockContainer(employeeid, createHistory, eChangeReason, useAutoNumbers, stockContainer).subscribe(stockContainer => {

        console.log(stockContainer);
        resolve(stockContainer);

      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating Stock Container", "Close", "center", "bottom", 4000, true, err.error);
        console.log(err);
        resolve(err);
      });
    });
  }


  updateStockContainerPromise(employeeid: string, createHistory: boolean, eChangeReason: BoardTallyRecordHistory.enChangeReason, stockContainer: StockContainer) {
    return new Promise<StockContainer>(resolve => {
      this.updateStockContainer(employeeid, createHistory, eChangeReason, stockContainer).subscribe(stockContainer => {

        resolve(stockContainer);

      }, err => {
        this.alertService.openSnackBarError("Error updating Stock Container", "Close", "center", "bottom", 4000, true, err.error.message);
        console.log(err);
        resolve(err);
      });
    });
  }

}  
