import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CodeBuilderTable } from '../business-objects/code-builder-table.bo';

@Injectable()
export class TableService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getTable(dtoTableName: string) {
    return this.http.get<CodeBuilderTable>(this._baseUrl + 'api/CodeBuilderTable/GetCodeBuilderTable/' + dtoTableName);
  }

  getAllTables() {
    return this.http.get<CodeBuilderTable[]>(this._baseUrl + 'api/CodeBuilderTable/GetCodeBuilderTables/');
  }

  async getTablePromise(tableId: string) {
    return new Promise<CodeBuilderTable>(resolve => {
      this.getTable(tableId).subscribe(table => {
        resolve(table);
      }, err => {
        console.log(err);
      });
    })
  }

  async getAllTablesPromise() {
    return new Promise<CodeBuilderTable[]>(resolve => {
      this.getAllTables().subscribe(tables => {
        resolve(tables);
      }, err => {
        console.log(err);
      });
    })
  }

}
