import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../account/auth.service';
import { DeliveryDocketOrderItem } from '../../_shared/business-objects/delivery-docket-order-item.bo';
import { dtoDeliveryDocketOrderItem } from '../../_shared/business-objects/dto-delivery-docket-order-item.bo';
import { dtoDeliveryDocket } from '../../_shared/business-objects/dto-delivery-docket.bo';
import { dtoOrderItem } from '../../_shared/business-objects/dto-order-item.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { AppUserColumnSetService } from '../../_shared/services/app-user-column-set.service';
import { AppUserColumnService } from '../../_shared/services/app-user-column.service';
import { DeliveryDocketOrderItemService } from '../../_shared/services/delivery-docket-order-item.service';
import { dtoDeliveryDocketOrderItemService } from '../../_shared/services/dto-delivery-docket-order-item.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-delivery-docket-order-items-add',
  templateUrl: './delivery-docket-order-items-add.component.html',
  styleUrls: ['./delivery-docket-order-items-add.component.css']
})
export class DeliveryDocketOrderItemsAddComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoOrderItem>;

  @ViewChild('tblOrderItems') tblOrderItems: MatTable<dtoOrderItem>;
  @ViewChild('addAllItem') addAllItem: MatCheckbox;
  @ViewChildren('addItem') addItems: QueryList<MatCheckbox>;

  @Input() columnListId: string;
  @Input() columnBarContainer: string;
  @Input() appIndex: number;
  @Input() deliveryDocket: dtoDeliveryDocket;
  @Input() existingDeliveryDocketOrderItems: dtoDeliveryDocketOrderItem[];
  @Input() deliveryDocketOrderItemAddQtyTotalInput: number;
  @Input() deliveryDocketOrderItemAddM3TotalInput: number;
  @Input() deliveryDocketOrderItemAddTonneTotalInput: number;
  @Output() editDeliveryDocketOrderItemEvent = new EventEmitter<dtoOrderItem>();
  @Output() addDeliveryDocketOrderItemEvent = new EventEmitter<dtoOrderItem>();
  @Output() removeDeliveryDocketOrderItemEvent = new EventEmitter<dtoOrderItem>();
  @Output() updateOrderItemsToAdd = new EventEmitter<DeliveryDocketOrderItem[]>();

  @ViewChild(MatSort) sort: MatSort;

  deliveryDocketOrderItemsToAdd: DeliveryDocketOrderItem[];

  selectedColumns = new FormControl('');

  deliveryDocketOrderItemAddQtyTotal: number = 0;
  deliveryDocketOrderItemAddM3Total: number = 0;
  deliveryDocketOrderItemAddTonneTotal: number = 0;

  //loadedComponent: boolean = false;

  displayedDeliveryDocketOrderItemsAdd: string[] = ['intItemNo', 'txtCustomerName', 'txtOrderNo', 'txtProductTypeName', 'txtProfileName', 'fltWidth', 'fltThickness', 'txtLengthKN', 'fltLength', 'fltDiameter', 'txtSpecies', 'txtGradeName', 'txtTreatmentType', 'txtTreatmentHazardLevel', 'txtStockCode', 'fltQuantityOnOrder', 'fltQtyBal', 'fltQuantity', 'txtQuantityUOM', 'txtAddress', 'txtComments', 'fltM3', 'fltTonne', 'chkAddItem'];  //

  loadedComponent: boolean = false;

  windowMouseX: number;
  windowMouseY: number;
  listMouseX: number;
  listMouseY: number;

  constructor(private appUserColumnService: AppUserColumnService, private authService: AuthService, private appUserColumnSetService: AppUserColumnSetService
    , private ref: ChangeDetectorRef, private alertService: AlertService, private formService: FormService
    , private sharedService: SharedService, private deliveryDocketOrderItemService: DeliveryDocketOrderItemService, private dtoDeliveryDocketOrderItemService: dtoDeliveryDocketOrderItemService) {

    if (this.columnBarContainer == null || this.columnBarContainer == "") {
      this.columnBarContainer = "deliveryDocketContainer";
    }
  }

  ngOnInit(): void {

    this.deliveryDocketOrderItemAddQtyTotal = 0;
    this.deliveryDocketOrderItemAddM3Total = 0;
    this.deliveryDocketOrderItemAddM3Total = 0;
    this.deliveryDocketOrderItemAddTonneTotal = 0;

    if (this.columnListId == null) {
      this.columnListId = "columnListId";
    }


  }


  updatedComponentColumnsEvent(columns: string[]) {

    this.displayedDeliveryDocketOrderItemsAdd = columns;

  }


  setTotals() {


    this.deliveryDocketOrderItemAddQtyTotal = 0;
    this.deliveryDocketOrderItemAddM3Total = 0;
    this.deliveryDocketOrderItemAddTonneTotal = 0;

    if (this.datasource.data != null) {
      this.datasource.data.forEach((a) => {
        this.deliveryDocketOrderItemAddQtyTotal += a.fltQuantityTotal;
        console.log("deliveryDocketOrderItemAddQtyTotal: " + this.deliveryDocketOrderItemAddQtyTotal);
        this.deliveryDocketOrderItemAddM3Total += Number(Number(a.fltM3).toFixed(3));
        this.deliveryDocketOrderItemAddTonneTotal += Number(Number(a.fltM3 * this.sharedService.M3ToWeightConversion).toFixed(3));

      });

      this.deliveryDocketOrderItemAddM3Total = Number(this.deliveryDocketOrderItemAddM3Total.toFixed(3));
      this.deliveryDocketOrderItemAddTonneTotal = Number(this.deliveryDocketOrderItemAddTonneTotal.toFixed(3));
      console.log("deliveryDocketOrderItemAddActualTonneTotal: " + this.deliveryDocketOrderItemAddTonneTotal);



      this.deliveryDocketOrderItemAddM3TotalInput = this.deliveryDocketOrderItemAddM3Total;
      this.deliveryDocketOrderItemAddTonneTotalInput = this.deliveryDocketOrderItemAddTonneTotal;
      this.deliveryDocketOrderItemAddQtyTotalInput = this.deliveryDocketOrderItemAddQtyTotal;

      console.log("deliveryDocketOrderItemAddM3TotalInput: " + this.deliveryDocketOrderItemAddM3TotalInput);
      console.log("deliveryDocketOrderItemAddTonneTotalInput: " + this.deliveryDocketOrderItemAddTonneTotalInput);
      console.log("deliveryDocketOrderItemAddQtyTotalInput: " + this.deliveryDocketOrderItemAddQtyTotalInput);

    }
  }


  ngAfterViewInit() {
    if (this.datasource != null) {
      this.datasource.sort = this.sort;
    }
  }

  editDeliveryDocketOrderItem(deliveryDocketOrderItemAdd: dtoOrderItem) {

    this.editDeliveryDocketOrderItemEvent.emit(deliveryDocketOrderItemAdd);

  }

  removeDeliveryDocketOrderItem(deliveryDocketOrderItemAdd: dtoOrderItem) {

    this.removeDeliveryDocketOrderItemEvent.emit(deliveryDocketOrderItemAdd);

  }

  RoundNumber(val, decimal) {
    return this.sharedService.RoundNumber(val, decimal);
  }

  unlinkOrderItem(orderItem: dtoOrderItem) {

  }

  addAllItemsChange(chkAddAllItem: MatCheckbox) {

    //console.log("chkAllItems: ", chkAddAllItem);
    let i = 0;
    this.addItems.forEach(c => {
      if (c.disabled == false) {
        c.checked = chkAddAllItem.checked
        this.addItemChange(this.datasource.data[i], c.checked, i);
      }
      i += 1;

    });

  }


  async addItemChange(orderItem: dtoOrderItem, checked: boolean, index: number) {

    let txtQuantity: HTMLInputElement = document.getElementById("txtQuantity" + index.toString()) as HTMLInputElement;
    if (txtQuantity != null) {
      orderItem.fltQtyBal = Number(txtQuantity.value);
    }

    //console.log("textbox: ", txtQuantity);
    //console.log("iQuantity: ", iQuantity);

    let reOrder: boolean = false;

    //  REMOVE ITEM
    if (checked == false) {
      for (let i = 0; i <= this.deliveryDocketOrderItemsToAdd.length - 1; i++) {
        if (this.deliveryDocketOrderItemsToAdd[i].guOrderItemId == orderItem.rowguid) {
          this.deliveryDocketOrderItemsToAdd.splice(i, 1);
          break;
        }
      }
    }


    //  ADD ITEM
    if (checked == true) {
      let delItemBlank: DeliveryDocketOrderItem = await this.deliveryDocketOrderItemService.getBlankDeliveryDocketOrderItemPromise();
      //console.log("fltQuantity = : ", orderItem.fltQtyBal);
      this.addNewDeliveryDocketOrderItem(this.deliveryDocket, orderItem, delItemBlank, orderItem.fltQtyBal, this.deliveryDocketOrderItemsToAdd.length);

    }

    // RE ORDER DELVIERY DOCKET ITEMS SO THEY ARE IN ORDER OF THE ORDER ITEMS.
    // USER SHOULD HAVE THE OPTION TO RE ORDER VIA INTERFACE LATER.
    let iReOrderItemNo: number = 0;
    for (let i = 0; i <= this.datasource.data.length - 1; i++) {
      for (let doi = 0; doi <= this.deliveryDocketOrderItemsToAdd.length - 1; doi++) {

        if (this.deliveryDocketOrderItemsToAdd[doi].guOrderItemId == this.datasource.data[i].rowguid) {
          iReOrderItemNo += 1;
          this.deliveryDocketOrderItemsToAdd[doi].intItemNo = iReOrderItemNo;
          break;
        }

      }

    }

    this.updateOrderItemsToAdd.emit(this.deliveryDocketOrderItemsToAdd);


  }

  updateOrderItem(orderItem: dtoOrderItem, fltQuantity: number) {

    for (let i = 0; i <= this.deliveryDocketOrderItemsToAdd.length - 1; i++) {
      if (this.deliveryDocketOrderItemsToAdd[i].guOrderItemId == orderItem.rowguid) {
        this.deliveryDocketOrderItemsToAdd[i].fltQuantity = parseFloat(fltQuantity.toString());
        break;
      }
    }

    this.updateOrderItemsToAdd.emit(this.deliveryDocketOrderItemsToAdd);

  }

  async createDeliveryDocketOrderItemsToAdd() {

    if (this.datasource == null || this.datasource.data == null) {
      this.alertService.openSnackBarDefault("There are no order items to create delivery docket order items");
      return;
    }

    this.deliveryDocketOrderItemsToAdd = [];



    //const datepipe: DatePipe = new DatePipe('en-AU');

    let delItemBlank: DeliveryDocketOrderItem = await this.deliveryDocketOrderItemService.getBlankDeliveryDocketOrderItemPromise();


    for (let i = 0; i <= this.datasource.data.length - 1; i++) {

      // --------------------------------------------------------------------
      // ONLY ADD ITEMS THAT HAVE A QUANTITY BALANCE GREATER THAN ZERO.
      // AND ARE NOT ALREADY EXISTING ON THE DELIVERY DOCKET.
      // --------------------------------------------------------------------
      let exists: boolean = false;
      if (this.existingDeliveryDocketOrderItems != null && this.existingDeliveryDocketOrderItems.length > 0) {
        for (let e = 0; e <= this.existingDeliveryDocketOrderItems.length - 1; e++) {
          if (this.existingDeliveryDocketOrderItems[e].guOrderItemId == this.datasource.data[i].rowguid) {
            exists = true;
            break;
          }
        }
      }

      if (exists == false && this.datasource.data[i].fltQtyBal < this.datasource.data[i].fltQuantityTotal) {
        this.addNewDeliveryDocketOrderItem(this.deliveryDocket, this.datasource.data[i], delItemBlank, this.datasource.data[i].fltQtyBal, i);

      }

    }

  }

  addNewDeliveryDocketOrderItem(deliveryDocket: dtoDeliveryDocket, orderItem: dtoOrderItem, delItemBlank: DeliveryDocketOrderItem, fltQuantity: number, itemNo: number) {
    let delItem: DeliveryDocketOrderItem = { ...delItemBlank };  // Clone blank object
    delete delItem.rowguid
    delItem.dteDateCreated = new Date(Date.now());
    delItem.fltQuantity = fltQuantity;
    delItem.intQuantityUoM = orderItem.intQuantityUoM;
    delItem.intItemNo = itemNo;
    delItem.guDeliveryDocketId = deliveryDocket.rowguid;
    delItem.guOrderItemId = orderItem.rowguid;
    delItem.guCustomerDeliveryAddressId = orderItem.guClientDelAddressId;

    this.deliveryDocketOrderItemsToAdd.push(delItem);
  }

}
