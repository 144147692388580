import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { RoyaltyRate } from '../business-objects/royalty-rate.bo';

@Injectable()
export class RoyaltyRateService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getRoyaltyRate(royaltyRateId: string) {
		return this.http.get<RoyaltyRate>(this._baseUrl + 'api/RoyaltyRate/' + royaltyRateId);
	}

	getRoyaltyRateParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<RoyaltyRate[]>(this._baseUrl + 'api/RoyaltyRate/GetRoyaltyRatesParamArray/', aParam);
	}

	getAllRoyaltyRates() {
		return this.http.get<RoyaltyRate[]>(this._baseUrl + 'api/RoyaltyRate/GetAllRoyaltyRates/');
	}

	//getAlldtoRoyaltyRates() {
	//	return this.http.get<dtoRoyaltyRate[]>(this._baseUrl + 'api/RoyaltyRate/GetAllRoyaltyRates/');
	//}

	createRoyaltyRate(royaltyRate: RoyaltyRate) {
		return this.http.post<RoyaltyRate>(this._baseUrl + 'api/RoyaltyRate/CreateRoyaltyRate/', royaltyRate);
	}

	updateRoyaltyRate(royaltyRate: RoyaltyRate) {
		return this.http.put<RoyaltyRate>(this._baseUrl + 'api/RoyaltyRate/UpdateRoyaltyRate/', royaltyRate);
	}

	async getRoyaltyRatePromise(royaltyRateId: string) {
		return new Promise<RoyaltyRate>(resolve => {
			this.getRoyaltyRate(royaltyRateId).subscribe(royaltyRate => {
				resolve(royaltyRate);
			}, err => {
				this.alertService.openSnackBarError("Error getting RoyaltyRate records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getRoyaltyRateParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<RoyaltyRate[]>(resolve => {
			this.getRoyaltyRateParamArray(aParam).subscribe(royaltyRates => {
				resolve(royaltyRates);
			}, err => {
				this.alertService.openSnackBarError("Error getting RoyaltyRate records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateRoyaltyRatePromise(royaltyRate: RoyaltyRate) {
		return new Promise<RoyaltyRate>(resolve => {
			this.updateRoyaltyRate(royaltyRate).subscribe(royaltyRate => {
				resolve(royaltyRate);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating RoyaltyRate record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createRoyaltyRatePromise(royaltyRate: RoyaltyRate) {
		return new Promise<RoyaltyRate>(resolve => {
			this.createRoyaltyRate(royaltyRate).subscribe(royaltyRate => {
				resolve(royaltyRate);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating RoyaltyRate record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}