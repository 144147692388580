import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { StockItemMultiplesComponent } from '../../board-plant-floor/stock-item-multiples/stock-item-multiples.component';
import { Moulder } from '../../_shared/business-objects/moulder.bo';
import { MoulderService } from '../../_shared/services/moulder.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-moulder-dialog',
  templateUrl: './moulder-dialog.component.html',
  styleUrls: ['./moulder-dialog.component.css']
})
export class MoulderDialogComponent implements OnInit {

  @ViewChild('guMoulderId') moulderSelect: MatSelectionList;

  moulders: Moulder[];
  selectedMoulders: Moulder[] = [];
  showSpinner: boolean;


  constructor(private dialogRef: MatDialogRef<StockItemMultiplesComponent>, @Inject(MAT_DIALOG_DATA) private data: { selectedMoulders: Moulder[] }
    , private moulderService: MoulderService, private sharedService: SharedService) {


    if (data.selectedMoulders != null) {
      this.selectedMoulders = data.selectedMoulders;
      console.log("selected moulders: ", this.selectedMoulders);
    }


  }

  ngOnInit(): void {

    this.getMoulders();



  }


  async getMoulders() {
    this.moulders = await this.moulderService.getAllMouldersPromise();
    this.moulders.sort((a, b) => { return a.txtName >= b.txtName ? 1 : -1 });

    await this.setMoulders();
  }

  setMoulders() {
    setTimeout(function (oThis) {
      if (oThis.moulderSelect != null) {
        oThis.moulderSelect.options.forEach(m => {

          oThis.selectedMoulders.forEach(mld => {
            if (mld.rowguid == m.value.rowguid) {
              m.selected = true;
            }
          });

        });
      }


    }

      , 100, this);
  }

  moulderSelected(moulder: Moulder) {

    this.selectedMoulders.length = 0;

    this.moulderSelect.selectedOptions.selected.forEach(m => {

      this.moulders.forEach(mld => {
        if (mld.rowguid == m.value.rowguid) {
          this.selectedMoulders.push(mld);
        }
      });

    });


   console.log("selectedMoulders: ", this.selectedMoulders);

  }


  selectMoulders() {

    this.dialogRef.close({ status: "Selected", moulders: this.selectedMoulders });

  }

  moulderCancelled(cancelled: boolean) {

    this.dialogRef.close({ status: "Cancel", machineRun: null });


  }

}
