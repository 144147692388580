<div class="loading-docket-check-list-container">
  <div class="header-wrapper">
    <div class="header-container">
      <div class="header-logo">
        <img src="../../../../assets/img/BlackLogo.bmp" width="40" height="40" />
      </div>
      <div class="divider-wrapper">
        <div class="logo-divider"></div>
      </div>

      <div class="company-heading-container">
        <div class="company-heading">
          <div><h3>Coffs Harbour Hardwoods</h3></div>
        </div>
      </div>

    </div>
  </div>
  <div class="loading-check-list-heading">Loading Checklist</div>
  <br />
  <div class="vehicle-type-info-heading">VEHICLE TYPE</div>
  <div class="vehicle-type-info">
    <div class="vehicle-type-header vehicle-type">TYPE</div>
    <div class="vehicle-type-header vehicle-steer">Steer</div>
    <div class="vehicle-type-header vehicle-steer-actual">ACTUAL</div>
    <div class="vehicle-type-header vehicle-drive">Drive</div>
    <div class="vehicle-type-header vehicle-drive-actual">ACTUAL</div>
    <div class="vehicle-type-header vehicle-tri">Tri</div>
    <div class="vehicle-type-header vehicle-tri-actual">ACTUAL</div>
    <div class="vehicle-type-header vehicle-gross">Gross</div>
    <div class="vehicle-type-header vehicle-gross-actual">ACTUAL</div>

    <div class="vehicle-type-row vehicle-2-axel-blank">2 axle rigid</div>
    <div class="vehicle-type-row vehicle-2-axel-steer-blank alignRight">6.0 T</div>
    <div class="vehicle-type-row vehicle-2-axel-steer-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-2-axel-drive-blank alignRight">9.0 T</div>
    <div class="vehicle-type-row vehicle-2-axel-drive-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-2-axel-tri-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-2-axel-tri-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-2-axel-gross-blank alignRight">15.0 T</div>
    <div class="vehicle-type-row vehicle-2-axel-gross-actual-blank alignRight"></div>

    <div class="vehicle-type-row vehicle-3-axel-blank">3 axle rigid</div>
    <div class="vehicle-type-row vehicle-3-axel-steer-blank alignRight">6.0 T</div>
    <div class="vehicle-type-row vehicle-3-axel-steer-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-3-axel-drive-blank alignRight">16.5 T</div>
    <div class="vehicle-type-row vehicle-3-axel-drive-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-3-axel-tri-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-3-axel-tri-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-3-axel-gross-blank alignRight">22.5 T</div>
    <div class="vehicle-type-row vehicle-3-axel-gross-actual-blank alignRight"></div>

    <div class="vehicle-type-row vehicle-4-axel-blank">4 axle rigid</div>
    <div class="vehicle-type-row vehicle-4-axel-steer-blank alignRight">11.0 T</div>
    <div class="vehicle-type-row vehicle-4-axel-steer-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-4-axel-drive-blank alignRight">16.5 T</div>
    <div class="vehicle-type-row vehicle-4-axel-drive-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-4-axel-tri-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-4-axel-tri-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-4-axel-gross-blank alignRight">27.5 T</div>
    <div class="vehicle-type-row vehicle-4-axel-gross-actual-blank alignRight"></div>

    <div class="vehicle-type-row vehicle-6-axel-blank">6 axle semi</div>
    <div class="vehicle-type-row vehicle-6-axel-steer-blank alignRight">6.0 T</div>
    <div class="vehicle-type-row vehicle-6-axel-steer-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-6-axel-drive-blank alignRight">16.5 T</div>
    <div class="vehicle-type-row vehicle-6-axel-drive-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-6-axel-tri-blank alignRight">20.0 T</div>
    <div class="vehicle-type-row vehicle-6-axel-tri-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-6-axel-gross-blank alignRight">42.5 T</div>
    <div class="vehicle-type-row vehicle-6-axel-gross-actual-blank alignRight"></div>

    <div class="vehicle-type-row vehicle-bogie-jinker-blank">Bogie Jinker</div>
    <div class="vehicle-type-row vehicle-bogie-jinker-steer-blank alignRight">6.0 T</div>
    <div class="vehicle-type-row vehicle-bogie-jinker-steer-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-bogie-jinker-drive-blank alignRight">16.5 T</div>
    <div class="vehicle-type-row vehicle-bogie-jinker-drive-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-bogie-jinker-tri-blank alignRight">16.5 T</div>
    <div class="vehicle-type-row vehicle-bogie-jinker-tri-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-bogie-jinker-gross-blank alignRight">39.0 T</div>
    <div class="vehicle-type-row vehicle-bogie-jinker-gross-actual-blank alignRight"></div>

    <div class="vehicle-type-row vehicle-tri-jinker-blank">Tri Jinker</div>
    <div class="vehicle-type-row vehicle-tri-jinker-steer-blank alignRight">6.0 T</div>
    <div class="vehicle-type-row vehicle-tri-jinker-steer-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-tri-jinker-drive-blank alignRight">16.5 T</div>
    <div class="vehicle-type-row vehicle-tri-jinker-drive-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-tri-jinker-tri-blank alignRight">20.0 T</div>
    <div class="vehicle-type-row vehicle-tri-jinker-tri-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-tri-jinker-gross-blank alignRight">42.5 T</div>
    <div class="vehicle-type-row vehicle-tri-jinker-gross-actual-blank alignRight"></div>

    <div class="vehicle-type-row vehicle-mass-management-blank">Mass Management</div>
    <div class="vehicle-type-row vehicle-mass-management-steer-blank alignRight">6.0 T</div>
    <div class="vehicle-type-row vehicle-mass-management-steer-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-mass-management-drive-blank alignRight">17.0 T</div>
    <div class="vehicle-type-row vehicle-mass-management-drive-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-mass-management-tri-blank alignRight">21.0 T</div>
    <div class="vehicle-type-row vehicle-mass-management-tri-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-mass-management-gross-blank alignRight">44.0 T</div>
    <div class="vehicle-type-row vehicle-mass-management-gross-actual-blank alignRight"></div>

    <div class="vehicle-type-row vehicle-twin-steer-blank">Twin Steer</div>
    <div class="vehicle-type-row vehicle-twin-steer-steer-blank alignRight">11.0 T</div>
    <div class="vehicle-type-row vehicle-twin-steer-steer-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-twin-steer-drive-blank alignRight">16.5 T</div>
    <div class="vehicle-type-row vehicle-twin-steer-drive-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-twin-steer-tri-blank alignRight">20.0 T</div>
    <div class="vehicle-type-row vehicle-twin-steer-tri-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-twin-steer-gross-blank alignRight">47.5 T</div>
    <div class="vehicle-type-row vehicle-twin-steer-gross-actual-blank alignRight"></div>

    <div class="vehicle-type-row vehicle-mass-management-twin-steer-blank">Mass Management Twin Steer</div>
    <div class="vehicle-type-row vehicle-mass-management-twin-steer-steer-blank alignRight">11.0 T</div>
    <div class="vehicle-type-row vehicle-mass-management-twin-steer-steer-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-mass-management-twin-steer-drive-blank alignRight">17.0 T</div>
    <div class="vehicle-type-row vehicle-mass-management-twin-steer-drive-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-mass-management-twin-steer-tri-blank alignRight">21.0 T</div>
    <div class="vehicle-type-row vehicle-mass-management-twin-steer-tri-actual-blank alignRight"></div>
    <div class="vehicle-type-row vehicle-mass-management-twin-steer-gross-blank alignRight">Max<br />48.5 T</div>
    <div class="vehicle-type-row vehicle-mass-management-twin-steer-gross-actual-blank alignRight"></div>
  </div>

  <br />
  <div class="vehicle-type-restraints-heading">RESTRAINTS</div>
  <div class="loading-check-list-restraints">
    <div class="restraints-label poles-label">Poles</div>
    <div class="restraints-cell poles">Front 2 chains, back 3 chains, 2 belly chains</div>
    <div class="restraints-label packs-label">Packs</div>
    <div class="restraints-cell packs">Bottom 2 tie down straps, top rows 4 tie down straps and 2 belly straps</div>
    <div class="restraints-label xarm-label">X Arms</div>
    <div class="restraints-cell xarm">Bottom 1 tie down straps, top rows 2 tie down straps and 1 belly straps</div>
    <div class="restraints-label rough-sawn-label">Rough sawn</div>
    <div class="restraints-cell rough-sawn">Bottom 2 belly chains/straps, top rows 4 tie down chains/strap</div>
  </div>

  <br />
  <div class="over-all-length"><b>OVER LENGTH AND LOADS REQUIRING A PERMIT</b> - Refer to Transport Manager.</div>

  <br />
  <div class="rear-over-hang">
    <div class="rear-over-hang-label">Rear over hang correct?</div>
    <div class="rear-over-hang-answer">YES&nbsp;<div class="rear-over-hang-yes-tickbox">&nbsp;</div>NO&nbsp;<div class="rear-over-hang-no-tickbox"></div></div>
  </div>

  <br />
  <div class="loader-details-heading">LOADED and CHECKED BY:</div>
  <div class="loader-details-container">
    <div class="loader-person-loading-truck">Person Loading Truck:</div>
    <div class="loader-signature">Signature:</div>
    <div class="loader-time">Time:</div>
    <div class="loader-date">Date:</div>
  </div>
</div>
