import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoTPChargeItem } from '../business-objects/dto-tpcharge-item.bo';

@Injectable()
export class dtoTPChargeItemService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoTPChargeItem(dtoTPChargeItemId: string) {
		return this.http.get<dtoTPChargeItem>(this._baseUrl + 'api/dtoTPChargeItem/' + dtoTPChargeItemId);
	}

	getdtoTPChargeItemParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoTPChargeItem[]>(this._baseUrl + 'api/dtoTPChargeItem/GetdtoTPChargeItemsParamArray/', aParam);
	}

	getAlldtoTPChargeItems() {
		return this.http.get<dtoTPChargeItem[]>(this._baseUrl + 'api/dtoTPChargeItem/GetAlldtoTPChargeItems/');
	}

	//getAlldtodtoTPChargeItems() {
	//	return this.http.get<dtodtoTPChargeItem[]>(this._baseUrl + 'api/dtoTPChargeItem/GetAlldtoTPChargeItems/');
	//}

	createdtoTPChargeItem(dtoTPChargeItem: dtoTPChargeItem) {
		return this.http.post<dtoTPChargeItem>(this._baseUrl + 'api/dtoTPChargeItem/CreatedtoTPChargeItem/', dtoTPChargeItem);
	}

	updatedtoTPChargeItem(dtoTPChargeItem: dtoTPChargeItem) {
		return this.http.put<dtoTPChargeItem>(this._baseUrl + 'api/dtoTPChargeItem/UpdatedtoTPChargeItem/', dtoTPChargeItem);
	}

	deletedtoTPChargeItem(dtoTPChargeItemId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoTPChargeItem/DeletedtoTPChargeItem/' + dtoTPChargeItemId);
	}

	async getdtoTPChargeItemPromise(dtoTPChargeItemId: string) {
		return new Promise<dtoTPChargeItem>(resolve => {
			this.getdtoTPChargeItem(dtoTPChargeItemId).subscribe(dtoTPChargeItem => {
				resolve(dtoTPChargeItem);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTPChargeItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoTPChargeItems(chargeId: string) {
    return new Promise<dtoTPChargeItem[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guTPChargeId", chargeId));
      aParamList.push(aParam);

      this.getdtoTPChargeItemParamArray(aParamList).subscribe(dtoTPChargeItems => {
        resolve(dtoTPChargeItems);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoTPChargeItem records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getdtoTPChargeItemByFinalItemId(finalInspectionItemId: string) {
    return new Promise<dtoTPChargeItem>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guTPPoleInspectionItemId", finalInspectionItemId));
      aParamList.push(aParam);

      this.getdtoTPChargeItemParamArray(aParamList).subscribe(dtoTPChargeItems => {
        if (dtoTPChargeItems != null && dtoTPChargeItems.length > 0) {
          resolve(dtoTPChargeItems[0])
        }
        resolve(null);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoTPChargeItem records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getdtoTPChargeItemParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoTPChargeItem[]>(resolve => {
			this.getdtoTPChargeItemParamArray(aParam).subscribe(dtoTPChargeItems => {
				resolve(dtoTPChargeItems);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTPChargeItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoTPChargeItemsPromise(chargeNo: number, chargeYear: number) {
    return new Promise<dtoTPChargeItem[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("intChargeNo", chargeNo.toString()));
      aParamList.push(aParam);
      aParam = [];
      aParam.push(new SQLParamArray("intChargeShortYear", chargeYear.toString()));
      aParamList.push(aParam);

      this.getdtoTPChargeItemParamArray(aParamList).subscribe(dtoTPChargeItems => {
        resolve(dtoTPChargeItems);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoTPChargeItem records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async updatedtoTPChargeItemPromise(dtoTPChargeItem: dtoTPChargeItem) {
		return new Promise<dtoTPChargeItem>(resolve => {
			this.updatedtoTPChargeItem(dtoTPChargeItem).subscribe(dtoTPChargeItem => {
				resolve(dtoTPChargeItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoTPChargeItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoTPChargeItemPromise(dtoTPChargeItem: dtoTPChargeItem) {
		return new Promise<dtoTPChargeItem>(resolve => {
			this.createdtoTPChargeItem(dtoTPChargeItem).subscribe(dtoTPChargeItem => {
				resolve(dtoTPChargeItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoTPChargeItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoTPChargeItemPromise(dtoTPChargeItemId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoTPChargeItem(dtoTPChargeItemId).subscribe(dtoTPChargeItem => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoTPChargeItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
