import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../account/auth.service';
import { ReckonCompanyFile, ReckonCompanyFiles } from '../../_shared/reckon-objects/reckon-company-files.ro';
import { ReckonResponseObject } from '../../_shared/reckon-objects/reckon-response.ro';
import { ReckonSettingsService } from '../../_shared/services/reckon.service';
import { NgxXml2jsonService } from 'ngx-xml2json';

@Component({
  selector: 'app-reckon-invoice-list',
  templateUrl: './reckon-invoice-list.component.html',
  styleUrl: './reckon-invoice-list.component.scss'
})
export class ReckonInvoiceListComponent implements OnInit {

  companyFile: string = "";
  documentData: string;
  xmlDocument: XMLDocument;

  constructor(private reckonSettingService: ReckonSettingsService, private authService: AuthService, private ngxXml2jsonService: NgxXml2jsonService) {

  }

  ngOnInit(): void{

    setTimeout(() => { this.getReckonAccessToken() }, 4000 );

  }

  async getReckonAccessToken() {


    //let companyFiles: ReckonCompanyFiles = await this.reckonSettingService.getUserFiles();


    let courgarCompanyFile: ReckonCompanyFile = await this.reckonSettingService.getCougarEnterprisesCompanyFile();

    console.log("courgarCompanyFile:", courgarCompanyFile);

    this.companyFile = courgarCompanyFile.filePath;

    //await this.getInvoiceRequest(this.companyFile);


  }

  async getInvoiceRequest(fileName: string) {

    //await this.reckonSettingService.getInvoicesWithXMLReq(fileName);

    let res: ReckonResponseObject = await this.reckonSettingService.getReckonInvoiceQuery(fileName, 2083);

    this.documentData = res.Data;


  }


  loadXMLData() {

    let resDiv: HTMLElement = document.getElementById("resDiv");
    let parser
    var text;


    parser = new DOMParser();

    this.xmlDocument = parser.parseFromString(this.documentData, "text/xml");

    let xmlDoc: XMLDocument = parser.parseFromString(this.documentData, "text/xml");
    xmlDoc.getElementsByTagName("QBXML");
    console.log(xmlDoc);

    for (let i = 0; i <= xmlDoc.childNodes.length - 1; i++) {
      console.log(xmlDoc.nodeName + " - " + xmlDoc.nodeValue);
    }


    //resDiv.innerHTML = xmlDoc;


  }


  getDataByTagName(tagName: string) {

    let htmlCol = this.xmlDocument.getElementsByTagName(tagName);
    console.log(htmlCol);

    const parser = new DOMParser();
    const xml = parser.parseFromString(this.documentData, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    console.log(obj);


    //for (let i = 0; i <= htmlCol.length - 1; i++) {
    //  console.log(htmlCol[i]);
    //}

  }


}
