import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { HaulingCompany } from '../business-objects/hauling-company.bo';

@Injectable()
export class HaulingCompanyService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getHaulingCompany(haulingCompanyId: string) {
		return this.http.get<HaulingCompany>(this._baseUrl + 'api/HaulingCompany/' + haulingCompanyId);
	}

	getHaulingCompanyParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<HaulingCompany[]>(this._baseUrl + 'api/HaulingCompany/GetHaulingCompanysParamArray/', aParam);
	}

	getAllHaulingCompanys() {
		return this.http.get<HaulingCompany[]>(this._baseUrl + 'api/HaulingCompany/GetAllHaulingCompanys/');
	}

	//getAlldtoHaulingCompanys() {
	//	return this.http.get<dtoHaulingCompany[]>(this._baseUrl + 'api/HaulingCompany/GetAllHaulingCompanys/');
	//}

	createHaulingCompany(haulingCompany: HaulingCompany) {
		return this.http.post<HaulingCompany>(this._baseUrl + 'api/HaulingCompany/CreateHaulingCompany/', haulingCompany);
	}

	updateHaulingCompany(haulingCompany: HaulingCompany) {
		return this.http.put<HaulingCompany>(this._baseUrl + 'api/HaulingCompany/UpdateHaulingCompany/', haulingCompany);
	}

	async getHaulingCompanyPromise(haulingCompanyId: string) {
		return new Promise<HaulingCompany>(resolve => {
			this.getHaulingCompany(haulingCompanyId).subscribe(haulingCompany => {
				resolve(haulingCompany);
			}, err => {
				this.alertService.openSnackBarError("Error getting HaulingCompany records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getHaulingCompanyParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<HaulingCompany[]>(resolve => {
			this.getHaulingCompanyParamArray(aParam).subscribe(haulingCompanys => {
				resolve(haulingCompanys);
			}, err => {
				this.alertService.openSnackBarError("Error getting HaulingCompany records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async updateHaulingCompanyPromise(haulingCompany: HaulingCompany) {
		return new Promise<HaulingCompany>(resolve => {
			this.updateHaulingCompany(haulingCompany).subscribe(haulingCompany => {
				resolve(haulingCompany);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating HaulingCompany record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createHaulingCompanyPromise(haulingCompany: HaulingCompany) {
		return new Promise<HaulingCompany>(resolve => {
			this.createHaulingCompany(haulingCompany).subscribe(haulingCompany => {
				resolve(haulingCompany);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating HaulingCompany record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

}