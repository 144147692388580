import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { dtoRoyaltyRate } from '../../../_shared/business-objects/dto-royalty-rate.bo';
import { RoyaltRate } from '../../../_shared/business-objects/royalt-rate.bo';
import { RoyaltyRateService } from '../../../_shared/services/royalty-rate.service';

@Component({
  selector: 'app-royalty-rate-list',
  templateUrl: './royalty-rate-list.component.html',
  styleUrls: ['./royalty-rate-list.component.css']
})
export class RoyaltyRateListComponent implements OnInit {

  @ViewChild('tblRoyaltyRate', { static: false }) tblRoyaltyRate: MatTable<dtoRoyaltyRate>;

  @Input() datasource: MatTableDataSource<dtoRoyaltyRate>;
  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() delOrderItemColumnBarContainer: string;
  @Input() columnListId: string;
  @Output() editRoyaltyRateEvent = new EventEmitter<dtoRoyaltyRate>();


  loadedComponent: boolean = false;

  displayedColumns: string[] = ['dteDateCreate', 'intLength', 'intKN', 'txtPoleType', 'txtDurabilityClass', 'fltForestryRoyaltyRate', 'fltCHHRoyaltyRate', 'blnCurrent', 'dteDateExpires', 'txtComments', 'btnEditRoyaltyRate', 'btnRemoveRoyaltyRate'];


  constructor(private royaltyRateService: RoyaltyRateService) { }

  ngOnInit(): void {

    this.columnBarPage = "Royalty Rates";

    this.loadedComponent = true;

    console.log("columnBarPage: " + this.columnBarPage);
    console.log("columnBarContainer: " + this.columnBarContainer);
    console.log("columnListId: " + this.columnListId);

  }


  updatedComponentColumnsEvent(columns: string[]) {

    this.displayedColumns = columns;

  }


  async editRoyaltyRate(royaltyRate: dtoRoyaltyRate) {
    this.editRoyaltyRateEvent.emit(royaltyRate);
  }

  removeRoyaltyRate(royaltyRateId: string) {

  }

}
