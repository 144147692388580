<div class="disc-template-config-search-container" id="discTemplateConfigContainer">
  <form class="example-form disc-template-config-search-container-form" [formGroup]="form">

    <h1>Disc Template Config</h1>

    <mat-tab-group color="accent" class="disc-template-config-search-mat-tab-group" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabDiscTemplateConfig>
      <mat-tab label="Disc Template Config" class="tab-label disc-template-config-search-search-tab">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Electrical Authority</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Essential Energy" value="" type="text" id="txtSearchElectricalAuthority" formControlName="txtSearchElectricalAuthority" (keydown.enter)="searchDiscTemplateConfig('')">
            </mat-form-field>
            <mat-icon matTooltip="separate text by a comma i.e. Essential Energy,Ausgrid..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-3">
            <div class="button-container">
              <button class="search-electrical-authority-button search-button" type="button" mat-flat-button color="primary" (click)="searchDiscTemplateConfig('')">Search</button>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="disc-template-config-search-list-app-container">
          <app-disc-template-config-list #appDiscTemplateConfigList [datasource]="discTemplateConfigs" (editDiscTemplateConfigEvent)="editDiscTemplateConfig($event)" (addDiscTemplateConfigEvent)="addDiscTemplateConfig($event)" [columnBarPage]="'Disc Template Config'" *ngIf="discTemplateConfigs != null"></app-disc-template-config-list>
        </div>

      </mat-tab>



      <mat-tab label="Search Options" class="tab-label search-option-tab">


        <div class="search-options">
          <div class="search-option-row-1">
          </div>

          <div class="search-option-row-6">
            <span><mat-slide-toggle formControlName="blnSearchActive">Active</mat-slide-toggle></span>
          </div>

          <div class="search-option-row-7">
            <button class="search-option-disc-template-config-search-button" type="button" mat-flat-button color="primary" (click)="searchDiscTemplateConfig('')">Search</button>
          </div>

          <mat-divider></mat-divider>
        </div>
      </mat-tab>

      <mat-tab label="Create Disc Template Config" class="tab-label">
        <div class="create-disc-template-config-search-container">
          <app-disc-template-config-create (discTemplateConfigCreated)="discTemplateConfigCreated($event)" (discTemplateConfigCancelled)="discTemplateConfigCancelled($event)" *ngIf="selectedTab == eDiscTemplateConfigTabs.CreateDiscTemplateConfig"></app-disc-template-config-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Disc Template Config" class="tab-label">
        <div class="update-disc-template-config-search-container">
          <app-disc-template-config-update [discTemplateConfig]="discTemplateConfig" [selectedHaulingCompany]="haulingCompany" [selectedEmployee]="haulingCompanyEmployee" (discTemplateConfigUpdated)="discTemplateConfigUpdated($event)" (discTemplateConfigCancelled)="discTemplateConfigCancelled($event)" *ngIf="selectedTab == eDiscTemplateConfigTabs.UpdateDiscTemplateConfig"></app-disc-template-config-update>
        </div>
      </mat-tab>

    </mat-tab-group>
  </form>
</div>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
