import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GeoStockLocation } from '../_shared/business-objects/geo-stock-location.bo';

import { GeoStockLocationService } from '../_shared/services/geo-stock-location.service';
import { SharedService } from '../_shared/services/shared.service';
import { AuthService } from '../account/auth.service';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { dtoDeliveryDocketStockTransfer } from '../_shared/business-objects/dto-delivery-docket-stock-transfer.bo';
import { dtoStockItem } from '../_shared/business-objects/dto-stock-item.bo';
import { BoardTallyRecordService } from '../_shared/services/board-tally-record.service';
import { AlertService } from '../_shared/services/alert.service';
import { AppUserGeoStockLocationService } from '../_shared/services/appuser-geostocklocation.service';
import { MatTable } from '@angular/material/table';
import { DeliveryDocketStockTransferService } from '../_shared/services/delivery-docket-stock-transfer.service';
import { DeliveryDocketStockTransfer } from '../_shared/business-objects/delivery-docket-stock-transfer.bo';
import { DeliveryDocketService } from '../_shared/services/delivery-docket.service';
import { DeliveryDocket } from '../_shared/business-objects/delivery-docket.bo';
import { DBFieldFunction, SQLParamArray } from '../_shared/business-objects/sql-param-array';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';


@Component({
  selector: 'app-stock-transfer-create',
  templateUrl: './stock-transfer-create.component.html',
  styleUrls: ['./stock-transfer-create.component.css']
})
export class StockTransferCreateComponent implements OnInit {

  @ViewChild('tblStockItems') tblStockItems: MatTable<dtoStockItem>;


  form: FormGroup;
  geoStockLocations: GeoStockLocation[];
  myGeoStockLocations: GeoStockLocation[];
  deliveryDocketStockTransfer: dtoDeliveryDocketStockTransfer[] = [];
  stockItems: dtoStockItem[] = [];

  selectedFromGeoStockLocation: GeoStockLocation;
  selectedToGeoStockLocation: GeoStockLocation;

  showFromLocations: boolean = false;

  displayedStockItems: string[] = ['txtIdentifier', 'txtProductName', 'txtProfileName', 'intWidth', 'sizeSeparator', 'intThickness', 'txtSpeciesName', 'txtGradeName', 'fltQuantity', 'txtQuantityUoM', 'intTotalLM', 'txtContainerLocation', 'txtStockType', 'btnRemove'];


  constructor(private fb: FormBuilder, private geoStockLocationService: GeoStockLocationService, private sharedService: SharedService, private authService: AuthService,
    private boardTallyRecordService: BoardTallyRecordService, private alertService: AlertService, private appUserGeoStockLocaton: AppUserGeoStockLocationService,
    private deliveryDocketStockTransferService: DeliveryDocketStockTransferService, private deliveryDocketService: DeliveryDocketService
  ) {
    //, private navigationService: NavigationService
    //

    this.form = this.fb.group({
      txtIdentifier: [''],
      guGeoStockLocationFromId: [''],
      guGeoStockLocationTooId: ['']
    });

  }

  ngOnInit(): void {

    this.loadData();


  }


  async loadData() {
    this.geoStockLocationService.getAllGeoStockLocations().subscribe(geoLocations => {
      this.geoStockLocations = geoLocations;
    }, err => {
      console.log(err);
    });

    this.myGeoStockLocations = await this.getMyGeoStockLocations();

    this.appUserGeoStockLocaton.getAppUserGeoStockLocationDefault(this.authService.getAppUserId()).subscribe(loc => {

      if (loc != null) {
        this.form.controls['guGeoStockLocationFromId'].setValue(loc.guGeoStockLocationId);

        for (let i = 0; i <= this.myGeoStockLocations.length - 1; i++) {
          if (this.myGeoStockLocations[i].rowguid == loc.guGeoStockLocationId) {
            this.selectedFromGeoStockLocation = this.myGeoStockLocations[i];
            break;
          }
        }
      }

    }, err => {
      console.log(err);
    });

    setTimeout(this.setAutoFocus, 1000, "txtIdentifier");

  }


  async getMyGeoStockLocations() {
    return new Promise<GeoStockLocation[]>(resolve => {
      this.geoStockLocationService.getMyGeoStockLocations(this.authService.getAppUserId()).subscribe(myLocations => {
        this.showFromLocations = true;
        resolve(myLocations);

      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  attachStock() {
    //this.selectedValue = this.myGeoStockLocations[0].rowguid;
    //console.log("selected Value: " + this.selectedValue);

    let identifier: string = this.form.controls["txtIdentifier"].value;
    let fromLocation: string = this.form.controls["guGeoStockLocationFromId"].value;
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (identifier == null || identifier == "") {
      this.alertService.openSnackBar("Please enter an identifer", "Close", "center", "bottom", "centerSnackbar", 3000);
    }

    if (fromLocation == null || fromLocation == "") {
      this.alertService.openSnackBar("Please select a from stock location", "Close", "center", "bottom", "centerSnackbar", 3000);
    }

    aParam.push(new SQLParamArray("intIdentifier", identifier))
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("guGeoStockLocationId", fromLocation))
    aParamList.push(aParam);

    //aParam = [];
    //aParam.push(new SQLParamArray("intTransferDeliveryStatus", DeliveryDocket.enDeliveryStatus.ReadyForDispatch.toString(), SQLParamArray.enSQLOperator.NotEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
    //aParam.push(new SQLParamArray("intTransferDeliveryStatus", null, SQLParamArray.enSQLOperator.IsNull, SQLParamArray.enSQLLogicalOperator.AND))
    //aParamList.push(aParam);

    this.boardTallyRecordService.getNonDeliveredStockItemParamArray(aParamList).subscribe(stockItems => {

      if (stockItems.length == 0) {
        this.alertService.openSnackBar("The identifier number does not exist, please check the number", "Close", "center", "bottom", "", 5000);
        return;
      }


      let exists: boolean = false;
      //stockItems.forEach(el => {
      //  exists = this.stockItems.some(stock => stock.guStockContainerId == el.guStockContainerId);
      //});

      for (let i = 0; i <= stockItems.length - 1; i++) {
        exists = this.stockItems.some(stock => stock.guStockContainerId == stockItems[i].guStockContainerId);
      }


      if (exists == true) {
        this.alertService.openSnackBar("The stock has already been added to the stock transfer", "Close","center","bottom","centerSnackbar", 3000);
      };

      if (exists == false) {
        this.stockItems = this.stockItems.concat(stockItems);
        this.form.controls["txtIdentifier"].setValue("");
        setTimeout(this.setAutoFocus, 1000, "txtIdentifier");
      };


    }, err => {
      console.log(err);
    });


  }

  setAutoFocus(id) {
    let txtInput = document.getElementById(id) as HTMLInputElement;
    if (txtInput != null) {
      txtInput.focus();
    }

  }

  onFromGeoStockLocationSelect(geoStockLocation: GeoStockLocation) {
    this.selectedFromGeoStockLocation = geoStockLocation;
  }

  onToGeoStockLocationSelect(geoStockLocation: GeoStockLocation) {
    this.selectedToGeoStockLocation = geoStockLocation;
  }


  showMyLocations(val) {

    if (this.myGeoStockLocations == null) {
      return true;
    }

    for (let i = 0; i <= this.myGeoStockLocations.length - 1; i++) {
      //console.log("this.myGeoStockLocations[i].rowguid: " + this.myGeoStockLocations[i].rowguid + "  :   val- " + val);

      if (val == this.myGeoStockLocations[i].rowguid) {
        return false;
      }
    }
    return true;
  }

  showMyLocationsTo(val) {

    if (this.myGeoStockLocations == null) {
      return true;
    }

    if (this.form.controls["guGeoStockLocationTooId"].value == this.form.controls["guGeoStockLocationFromId"].value) {
      this.form.controls["guGeoStockLocationTooId"].setValue("");
    }

    for (let i = 0; i <= this.myGeoStockLocations.length - 1; i++) {
      if (val == this.myGeoStockLocations[i].rowguid && this.form.controls["guGeoStockLocationFromId"].value != val) {
        return false;
      }
    }
    return true;
  }

  removeFromStockTransfer(boardTallyRecordHistory: dtoStockItem, rowIdx: number) {
    this.stockItems.splice(0, 1);
    this.tblStockItems.renderRows();
    setTimeout(this.setAutoFocus, 1000, "txtIdentifier");
  }

  async createStockTransfer() {

    if (this.stockItems.length == 0) {
      this.alertService.openSnackBar("There are no stock items to create a stock transfer deliver docket.", "Close", "center", "bottom", "", 5000);
      return;
    }

    if (this.selectedFromGeoStockLocation == null) {
      this.alertService.openSnackBar("Please select a From Geo Stock Location", "Close", "center", "bottom", "", 5000);
      return;
    }

    if (this.selectedToGeoStockLocation == null) {
      this.alertService.openSnackBar("Please select a Too Geo Stock Location", "Close", "center", "bottom", "", 5000);
      return;
    }

    let deliveryDocket: DeliveryDocket = await new Promise<DeliveryDocket>(resolve => {

      let deliveryDocket: DeliveryDocket = new DeliveryDocket()
      deliveryDocket.dteCreatedDate = this.sharedService.currentDatePlusTZOffset();
      deliveryDocket.dteLoaded = null;
      deliveryDocket.dteStatusDate = this.sharedService.currentDatePlusTZOffset();
      deliveryDocket.guCustomerId = this.selectedToGeoStockLocation.guCustomerId;
      //deliveryDocket.guCustomerContactId = this.selectedToGeoStockLocation.
      deliveryDocket.guStockTransferDelAddressId = this.selectedToGeoStockLocation.guCustomerDeliveryAddressId
      deliveryDocket.guGeoStockLocationId = this.selectedToGeoStockLocation.rowguid;
      deliveryDocket.guEmployeeCreatedId = this.authService.getAppUserEmployeeId();
      deliveryDocket.guHaulingId = null;
      deliveryDocket.guTPPoleInspectionId = null;
      deliveryDocket.intStatus = DeliveryDocket.enDeliveryStatus.ReadyForDispatch;
      deliveryDocket.intDeliveryType = DeliveryDocket.enDeliveryType.StockTransfer;

      this.deliveryDocketService.createDeliveryDocket(deliveryDocket).subscribe(dd => {
        resolve(dd);
      }, err => {
        console.log(err);
        this.alertService.openSnackBarError("There was a problem creating the delivery docket.", "Close", "center", "bottom", 3000, false, "");
        resolve(null);
      })
    });

    let tempStockContainerId: string[] = [];  // We only add the stock containter to the transfer so filter out multiple items in pack.

    if (deliveryDocket != null) {

      for (let i = 0; i <= this.stockItems.length - 1; i++) {

        if (tempStockContainerId.includes(this.stockItems[i].guStockContainerId) == false) {
          let stockTransferDocket: DeliveryDocketStockTransfer = new DeliveryDocketStockTransfer()
          stockTransferDocket.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
          stockTransferDocket.guDeliveryDocketId = deliveryDocket.rowguid;
          stockTransferDocket.guStockContainerId = this.stockItems[i].guStockContainerId;
          stockTransferDocket.intItemNo = i;

          tempStockContainerId.push(this.stockItems[i].guStockContainerId);

          this.deliveryDocketStockTransferService.createDeliveryDocketStockTransfer(stockTransferDocket).subscribe(ddStockTransfer => {
            //console.log(ddStockTransfer);

          }, err => {
            console.log(err);
          });
        }
      }
    }
  
      this.stockItems.length = 0;
      this.tblStockItems.renderRows();

      this.alertService.openSnackBar("The stock transfer delivery docket has been created.", "Close", "center", "bottom", "", 5000);

  }

}
