<div class="pole-barcode-heading"><h1>Print Pole Tags</h1></div>
<form class="pole-barcode-form" [formGroup]="form">
  <div class="pole-barcode-container-grid">
    <div class="pole-barcode-container-column1">
      <div class="create-pole-inspection-item-identifier">

        <div class="last-number-label" *ngIf="false">Last Number Created</div><div class="last-number" *ngIf="false">{{lastNumber}}</div>
        <div class="last-number-error" *ngIf="showLastNumberError == true">Unable to retrieve the last number.</div>
        <div class="number-range-grid">
          <div class="create-numbers-grid">
            <div class="number-next-and-to">
              <div class="next-numbers-grid">
                <div class="from-number-label">Number From:</div><div class="from-number">{{nextNumber}}</div>
                <div class="to-number-label">Number To:</div><div class="to-number">{{toNumber}}</div>
                <div class="tags-to-create-label"># of tags:</div><div class="tags-to-create">{{tagsToCreate}}</div>
              </div>
            </div>
            <div class="to-number-grid">
              <div class="to-number-button-heading">Number of pole tags to create</div>
              <div class="to-number-one">
                <button class="create-one-number" mat-flat-button color="primary" (click)="createNumbers(1)">1</button>
              </div>
              <div class="to-number-five">
                <button class="create-one-number" mat-flat-button color="primary" (click)="createNumbers(5)">5</button>
              </div>
              <div class="to-number-ten">
                <button class="create-one-number" mat-flat-button color="primary" (click)="createNumbers(10)">10</button>
              </div>
              <div class="to-number-fifteen">
                <button class="create-one-number" mat-flat-button color="primary" (click)="createNumbers(15)">15</button>
              </div>
              <div class="to-number-twenty">
                <button class="create-one-number" mat-flat-button color="primary" (click)="createNumbers(20)">20</button>
              </div>
              <div class="to-number-fifty">
                <button class="create-one-number" mat-flat-button color="primary" (click)="createNumbers(50)">50</button>
              </div>
              <div class="to-number-onehundred">
                <button class="create-one-number" mat-flat-button color="primary" (click)="createNumbers(100)">100</button>
              </div>
            </div>
            <div class="create-pole-numbers-button-container">
              <div class="create-pole-numbers-reprint">
                <button class="clear-pole-numbers-button" mat-flat-button color="primary" (click)="clearSelectedNumbers()" [disabled]="selectedTagsToPrint == null || selectedTagsToPrint.length == 0">Clear Selected Pole Numbers</button>
              </div>
              <div class="create-pole-numbers-reprint">
                <button class="print-pole-numbers-button" mat-flat-button color="primary" (click)="printNumbers()" [disabled]="selectedTagsToPrint == null || selectedTagsToPrint.length == 0">Print Selected Pole Numbers</button>
              </div>
              <div class="create-pole-numbers-button-div">
                <button class="create-pole-numbers-button" mat-flat-button color="primary" (click)="createNextNumbers(tagsToCreate)">Create &amp; Print Pole Numbers</button>
              </div>
            </div>
          </div>
          <div class="search-numbers-header">
            <div class="search-numbers-label">Search Existing Numbers</div>
            <div class="search-numbers-icon">
              <mat-icon class="arrow-icon" (click)="showSearchNumbers()" *ngIf="searchNumbersExpanded == false">keyboard_arrow_down</mat-icon>
              <mat-icon class="arrow-icon" (click)="showSearchNumbers()" *ngIf="searchNumbersExpanded == true">keyboard_arrow_up</mat-icon>
            </div>
          </div>
          <div class="search-numbers-container" *ngIf="searchNumbersExpanded == true">
            <div class="number-from">
              <mat-form-field class="example-full-width example-container number-input" appearance="fill">
                <mat-label>Search Number From</mat-label>
                <input class="next-number-from-input" matInput type="number" max="6" maxlength="6" placeholder="" value="" formControlName="txtNextNumberFrom" #txtNextNumberFrom (input)="validateFromNumber(txtNextNumberFrom)">
              </mat-form-field>
              <div class="number-from-error" *ngIf="numberFromError == true">{{numberFromErrorText}}</div>
            </div>
            <div class="number-to">
              <mat-form-field class="example-full-width example-container number-input" appearance="fill">
                <mat-label>Search Number To</mat-label>
                <input class="next-number-to-input" matInput type="number" max="999999" placeholder="" value="" formControlName="txtNextNumberTo" #txtNextNumberTo (input)="validateToNumber(txtNextNumberTo)">
              </mat-form-field>
              <div class="number-to-error" *ngIf="numberToError">{{numberToErrorText}}</div>
            </div>

            <div class="search-button">
              <button class="searchButton" mat-flat-button color="primary" (click)="searchNumbers()">Search Existing Numbers</button>
            </div>

            <div class="search-last-buttons-container">
              <div class="search-last-buttons-label">Search Last Numbers:</div>
              <div class="search-last-buttons-five">
                <button class="searchButton" mat-flat-button color="primary" (click)="searchLastNumbers(5)">5</button>
              </div>
              <div class="search-last-buttons-five">
                <button class="search-last-button" mat-flat-button color="primary" (click)="searchLastNumbers(10)">10</button>
              </div>
              <div class="search-last-buttons-ten">
                <button class="search-last-button" mat-flat-button color="primary" (click)="searchLastNumbers(15)">15</button>
              </div>
              <div class="search-last-buttons-twenty">
                <button class="search-last-button" mat-flat-button color="primary" (click)="searchLastNumbers(20)">20</button>
              </div>
              <div class="search-last-buttons-fifty">
                <button class="search-last-button" mat-flat-button color="primary" (click)="searchLastNumbers(50)">50</button>
              </div>
              <div class="search-last-buttons-onehundred">
                <button class="search-last-button" mat-flat-button color="primary" (click)="searchLastNumbers(100)">100</button>
              </div>
            </div>
          </div>
          <div class="search-numbers">
            <div class="row-number-header"></div><div class="identifier-header">Pole Number</div><div class="date-created-header">Date Created</div>
            <div class="search-numbers-list">
              <mat-selection-list *ngIf="poleInspectionItemIdentifiers != null" #numbersList>
                @for (pii of poleInspectionItemIdentifiers; track pii; let idx = $index) {
                <mat-list-option class="identifier-list-option" #listOptionIdentifer (click)="setSelectedIdentifier(pii, listOptionIdentifer.selected)"><div class="row-number">{{idx + 1}}</div><div class="identifier">{{pii.intIdentifier}}</div><div class="date-created">{{pii.dteDateCreated | date: 'dd/MM/yyyy hh:mm a'}}</div></mat-list-option>
                }
              </mat-selection-list>
            </div>
          </div>
        </div>


      </div>
    </div>

    <div class="pole-barcode-container-column2">

      <div class="header-row-4">
        <mat-form-field class="example-full-width example-container" appearance="fill">
          <mat-label>Identifier</mat-label>
          <input class="pole-number-identifier-input" matInput placeholder="" value="" formControlName="txtIdentifier">
          <mat-error *ngIf="false">
          </mat-error>
        </mat-form-field>
        <button class="stocktakeButton" mat-flat-button color="primary" (click)="printPoleBarcode()">Print Manual Pole Tag</button>&nbsp;
      </div>
    </div>

  </div>
</form>

<div class="error-console" id="errorConsole"></div>
<div class="error-console-message" id="errorConsoleMessage"></div>
