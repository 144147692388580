import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartDataset, ChartItem, ChartOptions, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FileAttachment } from '../../../_shared/app-objects/file-attachment';
import { NGChart } from '../../../_shared/app-objects/ng-chart';
import { FileService } from '../../../_shared/services/app-services/file.service';

@Component({
  selector: 'app-chh-chart',
  templateUrl: './chh-chart.component.html',
  styleUrl: './chh-chart.component.scss'
})
export class ChhChartComponent implements OnInit {
  @Input() chartDataSet: ChartDataset[];
  @Input() chartLabel: string[];
  @Input() chartType: ChartType;
  @Input() chartLegend: boolean;
  @Input() chartOptions: ChartOptions;
  @Input() canvasWidth: number;
  @Input() canvasHeight: number;
  @Input() chartContainerId: string;
  @Input() hideChart: boolean;

  @ViewChild('chhChart') chhChart: Chart;

  public lineChart2: NGChart;

  public chartPlugins = [ChartDataLabels];

  chartItem: ChartItem;
  chart: Chart;
  chartContainerStyle: string;

  constructor(private fileService: FileService) {

  }


  ngOnInit(): void {

    
    this.chartContainerStyle = "width: " + this.canvasWidth + "px; height: " + this.canvasHeight + "px";

    //console.log("this.chartContainerStyle", this.chartContainerStyle);

    this.chartItem = document.getElementById('myChart') as ChartItem;

    this.chart = new Chart(this.chartItem, {
      type: this.chartType,
      data: {
        labels: this.chartLabel,
        datasets: this.chartDataSet
      },
      options: this.chartOptions,
      plugins: [{
        id: 'custom_canvas_background_color',
        beforeDraw: (chart) => {
          const ctx = chart.canvas.getContext('2d');
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
          ctx.restore();
        }
      }]
    });
    //this.chart.canvas.style.backgroundColor = 'rgba(255,255,255,255)'; //white background
    //this.chart.canvas.style.backgroundColor = 'rgba(255,0,0,255)'; //white background
    //this.chart.canvas.fillStyle = 'white';
  }

  loadChart() {
    //this.lineChart2 = new NGChart(this.chartOptions, this.chartLabel, this.chartType, this.chartLegend, this.chartDataSet);


    console.log("in load chart");
    console.log("chartDataSet", this.chartDataSet);
  }

  async downloadChart() {
    let base64 = this.chart.toBase64Image();
    let blob: Blob = this.fileService.base64toBlob(base64, "image/png");
    let arrBuffer: ArrayBuffer = await blob.arrayBuffer();
    let arrBuffer2: ArrayBuffer[] = [];
    arrBuffer2.push(arrBuffer);

    let fileAttachment: FileAttachment = new FileAttachment("testchart.png", "c:\temp", "image/png", base64, null, blob.size, new Date(Date.now()));
    console.log("this.chart", this.chart);

    //this.fileService.createPDFFileAttachment("c:\temp\testchart.jpg", arrBuffer2)
    this.fileService.downloadAttachment(fileAttachment);

    console.log(base64);

  }

  async chartBase64() {
    return this.chart.toBase64Image();
    //chartBase64
  }

}
