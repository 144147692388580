<div class="header-spacer" *ngIf="isAuthenticated() && hideNav == false"></div>
<header style="text-align:left;">

  <nav *ngIf="isAuthenticated() && hideNav == false" #navMenu id="chhNav" class="system-nav navbar navbar-expand-lg navbar-toggleable-sm navbar-dark bg-dark border-bottom box-shadow mb-3">
    <div class="d-flex flex-row w-100" id="navContainer">
      <div class="p-1">
        <a class="navbar-brand " [routerLink]="['/']"><img width="160" height="48" src="../../assets/img/chhlogo.png" /></a>
        <!--
            <div class="navbar-toggler w-100 flex-column-reverse" style="text-align:right;border:0;">
              <button class="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target=".navbar-collapse"
                      aria-label="Toggle navigation"
                      [attr.aria-expanded]="isExpanded">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
      -->
      </div>
      <div class="w-100">
        <table class="menu-table w-100">
          <tr>
            <td align="right" style="width:80%">
              <div class="menu-container">
                <div class="test-database-heading" *ngIf="testDB"><h1>Test Database</h1></div>
                <button mat-button [matMenuTriggerFor]="test" class="mat-menu-item menu-heading"><span class="navbar-toggler-icon"></span></button>
                <mat-menu #test="matMenu" class="chh-menu first-menu">
                  <button mat-menu-item [matMenuTriggerFor]="boardplant">Board Plant</button>
                  <button mat-menu-item [matMenuTriggerFor]="treatmentplant">Treatment Plant</button>
                  <button mat-menu-item [matMenuTriggerFor]="mill">Mill</button>
                  <button mat-menu-item [matMenuTriggerFor]="logyard">Log Yard</button>
                  <button mat-menu-item [matMenuTriggerFor]="orders">Quotes and Orders</button>
                  <button mat-menu-item [matMenuTriggerFor]="stockforsale">Stock For Sale</button>
                  <button mat-menu-item [routerLink]="['/delivery-docket-search']">Delivery Dockets</button>
                  <button mat-menu-item [routerLink]="['/loading-docket-search']">Loading Dockets</button>
                  <button mat-menu-item [matMenuTriggerFor]="hauling">Hauling</button>
                  <button mat-menu-item [matMenuTriggerFor]="system" *ngIf="isAdmin()">System</button>
                  <button mat-menu-item [routerLink]="['/report-centre']" *ngIf="isAdmin()">Test Report Graph</button>
                  <button mat-menu-item [routerLink]="['/downloads']">Downloads</button>
                  <button mat-menu-item [routerLink]="['/delivery-docket-print']">Delivery Docket Print</button>
                </mat-menu>


                <mat-menu #system="matMenu" class="chh-menu">
                  <button mat-menu-item [matMenuTriggerFor]="globalsetup">Global</button>
                  <button mat-menu-item [matMenuTriggerFor]="boardplantsetup">Board Plant</button>
                  <button mat-menu-item [matMenuTriggerFor]="millsetup">Mill</button>
                  <button mat-menu-item [matMenuTriggerFor]="ordersetup">Orders</button>
                  <button mat-menu-item [matMenuTriggerFor]="productsetup">Products</button>
                  <button mat-menu-item [matMenuTriggerFor]="treatmentplantsetup">Treatment Plant</button>
                  <button mat-menu-item [matMenuTriggerFor]="costings">Costings</button>
                  <button mat-menu-item [matMenuTriggerFor]="reckon">Reckon</button>
                </mat-menu>
                <mat-menu #globalsetup="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/appuser-geostocklocation-list']" *ngIf="isAdmin()">App User Geo Locations</button>
                  <button mat-menu-item [routerLink]="['/app-page-search']" *ngIf="isAdmin()">App Pages</button>
                  <button mat-menu-item [routerLink]="['/app-user-column-set-search']" *ngIf="isAdmin()">App User Column Sets</button>
                  <button mat-menu-item [routerLink]="['/app-page-column-search']" *ngIf="isAdmin()">App Page Columns</button>
                  <button mat-menu-item [routerLink]="['/app-user-column-search']" *ngIf="isAdmin()">App User Columns</button>
                  <button mat-menu-item [routerLink]="['/app-user-search']" *ngIf="isAdmin()">App User Search</button>
                  <button mat-menu-item [routerLink]="['/app-page-development']" *ngIf="isAdmin()">App Page Development</button>
                  <button mat-menu-item [routerLink]="['/']" *ngIf="isAdmin()">Positions</button>
                  <button mat-menu-item [routerLink]="['/']" *ngIf="isAdmin()">Employee Positions</button>
                  <button mat-menu-item [routerLink]="['/printer-search']" *ngIf="isAdmin()">Printers</button>
                  <button mat-menu-item [routerLink]="['/printer-location-search']" *ngIf="isAdmin()">Printer Locations</button>
                  <button mat-menu-item [routerLink]="['/org-chart']" *ngIf="isAdmin()">Org Chart</button>
                  <button mat-menu-item [routerLink]="['/send-mail']" *ngIf="isAdmin()">Send Email</button>
                  <button mat-menu-item [routerLink]="['/dropbox-email']" *ngIf="isAdmin()">Dropbox Email</button>
                  <button mat-menu-item [routerLink]="['/terms-and-conditions']" *ngIf="isAdmin()">T &amp; C's</button>
                </mat-menu>
                <mat-menu #boardplantsetup="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/']" *ngIf="isAdmin()">Grades</button>
                  <button mat-menu-item [routerLink]="['/']" *ngIf="isAdmin()">Profiles</button>
                  <button mat-menu-item [routerLink]="['/board-tally-record-not-in-stock']" *ngIf="isAdmin()">Board Tally Not In Stock</button>
                </mat-menu>
                <mat-menu #millsetup="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/']">Girder Stock</button>
                </mat-menu>
                <mat-menu #ordersetup="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/']">Quote Reference No.</button>
                </mat-menu>
                <mat-menu #productsetup="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/']">Product Codes</button>
                </mat-menu>
                <mat-menu #treatmentplantsetup="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/disc-template-config-search']">Disc Template Config</button>
                  <button mat-menu-item [routerLink]="['/']">Poles</button>
                  <button mat-menu-item [routerLink]="['/']">Pole M3</button>
                </mat-menu>
                <mat-menu #costings="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/royalty-rate-search']" *ngIf="isAdmin()">Royalty Rates</button>
                  <button mat-menu-item [routerLink]="['/']" *ngIf="isAdmin()">Pole Costing</button>
                </mat-menu>
                <mat-menu #reckon="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/reckon-invoice-list']" *ngIf="isAdmin()">Reckon Invoice List</button>
                  <button mat-menu-item [routerLink]="['/']" *ngIf="isAdmin()">Reckon Proforma List</button>
                </mat-menu>

                <mat-menu #boardplant="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/stock-items']">Stock Items</button>
                  <button mat-menu-item [routerLink]="['/bpstocktake']">Stocktake</button>
                  <button mat-menu-item [routerLink]="['/stock-transfer-create']">Stock Transfer</button>
                  <button mat-menu-item [routerLink]="['/stock-transfer-receive']" [queryParams]="{status: [0,1]}">Stock Transfer Deliveries</button>
                  <button mat-menu-item [routerLink]="['/stock-item']" *ngIf="isAdmin()">Stock Items</button>
                  <button mat-menu-item [routerLink]="['/product-code-barcode-print']">Product Code Barcode</button>
                  <button mat-menu-item [routerLink]="['/barcode-sticker']">Print Barcode</button>
                  <button mat-menu-item [routerLink]="['/barcode-sticker-large']">Print Barcode Large</button>
                  <button mat-menu-item [routerLink]="['/bp-floor-order-search']">Floor Orders</button>
                  <button mat-menu-item [routerLink]="['/machine-run-search']">Machine Run</button>
                  <button mat-menu-item [matMenuTriggerFor]="bpreports">Reports</button>
                </mat-menu>
                <mat-menu #bpreports="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/pos-sales']">POS Sales</button>
                  <button mat-menu-item [routerLink]="['/monthly-reports']">Monthly Reports</button>
                  <button mat-menu-item [routerLink]="['/']">Production Charts</button>
                  <button mat-menu-item [routerLink]="['/']">Sales By State</button>
                </mat-menu>

                <mat-menu #treatmentplant="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/final-inspection-search']">Final Inspections</button>
                  <button mat-menu-item [routerLink]="['/']">Asoma Results</button>
                  <button mat-menu-item [routerLink]="['/cca-charge-search']">Charge Sheets</button>
                  <button mat-menu-item [routerLink]="['/pole-barcode']">Pole Barcode</button>
                  <button mat-menu-item [routerLink]="['/pole-stock-take']">Pole Stocktake</button>
                  <button mat-menu-item [routerLink]="['/pole-trimming-item']">Pole Trimming</button>
                  <button mat-menu-item [routerLink]="['/']">Pole Stock</button>
                  <button mat-menu-item [matMenuTriggerFor]="tpreports">Reports</button>
                </mat-menu>

                <mat-menu #logyard="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/inspection-search']">Inspections</button>
                  <button mat-menu-item [routerLink]="['/inspection-pole-barcode']">Pole Barcode</button>
                  <button mat-menu-item [matMenuTriggerFor]="lgreports">Reports</button>
                </mat-menu>

                <mat-menu #tpreports="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/report-centre']">Production Charts</button>
                  <button mat-menu-item [routerLink]="['/']">Sales By State</button>
                </mat-menu>

                <mat-menu #mill="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/']">Edger</button>
                  <button mat-menu-item [routerLink]="['/']">Bench</button>
                </mat-menu>
                <mat-menu #millreports="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/']">Production Charts</button>
                  <button mat-menu-item [routerLink]="['/']">Sales By State</button>
                </mat-menu>


                <mat-menu #orders="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/quote-search']">Quotes</button>
                  <button mat-menu-item [routerLink]="['/order-search']">Orders</button>
                  <button mat-menu-item [matMenuTriggerFor]="deliveries">Deliveries</button>
                  <button mat-menu-item [routerLink]="['/loading-docket-search']">Loading Dockets</button>
                  <button mat-menu-item [routerLink]="['/loading-docket-view']">Loading Docket View</button>
                  <button mat-menu-item [routerLink]="['/loading-docket-print']">Loading Docket Print</button>
                  <button mat-menu-item [matMenuTriggerFor]="bpreports">Reports</button>
                </mat-menu>

                <mat-menu #stockforsale="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/stock-for-sale']" [queryParams]="{mode: 'all'}">All Stock</button>
                  <button mat-menu-item [routerLink]="['/stock-for-sale']" [queryParams]="{mode: 'clearance'}">Clearance</button>
                  <button mat-menu-item [routerLink]="['/stock-for-sale']" [queryParams]="{mode: 'specials'}">Specials</button>
                </mat-menu>

                <mat-menu #deliveries="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/delivery-docket-search']">All Deliveries</button>
                  <button mat-menu-item [routerLink]="['/delivery-docket-search']" [queryParams]="{status: 0}">Deliveries Ready For Dispatch</button>
                  <button mat-menu-item [routerLink]="['/delivery-docket-search']" [queryParams]="{status: 1}">Deliveries Dispatched</button>
                  <button mat-menu-item [routerLink]="['/delivery-docket-search']" [queryParams]="{status: [2,3]}">Deliveries Delivered and Picked Up</button>
                  <button mat-menu-item [routerLink]="['/delivery-docket-search']" [queryParams]="{status: 0, hauler: 'CHH-XN55MZ (Scania - Local)'}">Local Deliveries Ready For Dispatch (CHH-XN55MZ (Scania - Local))</button>
                </mat-menu>

                <mat-menu #hauling="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/']">Hauling Companies</button>
                  <button mat-menu-item [routerLink]="['/hauling-company-driver-search']">Hauling Company Drivers</button>
                </mat-menu>

                <mat-menu #bpreports="matMenu" class="chh-menu">
                  <button mat-menu-item [routerLink]="['/']">Sales Charts</button>
                  <button mat-menu-item [routerLink]="['/']">Sales By State</button>
                </mat-menu>
              </div>
            </td>
            <td align="right" style="width:10%">
              <div class="icon-container" data-icon-container="iconContainer">
                <span class="mobile-printers-icon" *ngIf="user != null && printers != null" (click)="togglePrintersList()">
                  <mat-icon matTooltip="Click to set your default mobile printer" class="print-icon material-symbols-outlined">print</mat-icon>
                </span>
                <div class="mobile-printers" *ngIf="showPrinters">
                  @for (printer of printers; track printer; let  i = $index) {
                  <div class="printer-row" (click)="setDefaultMobilePrinter(printer, printerRow)">
                    <div class="printer-default" #printerRow>
                      <mat-icon matTooltip="Default Printer" class="check-icon material-symbols-outlined" *ngIf="defaultPrinter != null && printer.rowguid == defaultPrinter.rowguid">check_circle</mat-icon>
                    </div>
                    <div class="printer-alias-name">{{printer.txtAliasName}}</div>
                  </div>
                  }
                </div>
                <span class="reckon-logged-out" *ngIf="user != null && user.blnHasReckonAccount && !reckonAuthenticated">
                  <a (click)="reckonSignIn()"><img src="../../assets/img/reckon-logo-logged-out.png" width="24" height="24" title="Click to log into Reckon" /></a>
                </span>
                <span class="reckon-logged-in" *ngIf="user != null && user.blnHasReckonAccount && reckonAuthenticated">
                  <a (click)="reckonSignOut()"><img src="../../assets/img/reckon-logo-logged-in.png" width="24" height="24" title="Click to logout" /></a>
                </span>

                <span class="office-365-login" *ngIf="!authService.ms_authenticated">
                  <a (click)="office365SignIn()"><img src="../../assets/img/office_365_login_white.png" width="24" height="24" title="Click to log into Office 365" /></a>
                </span>
                <span class="office-365-logged-in" *ngIf="authService.ms_authenticated">
                  <a (click)="office365SignOut()"><img src="../../assets/img/office_365_logged_in_faint.png" width="24" height="24" title="{{msUser != null ? 'Logged into Office 365\n' + msUser.displayName + ' \nClick to logout' : '' }}" /></a>
                </span>
                <span style="display:inline-block;" *ngIf="isAuthenticated">
                  <img src="../../assets/img/sm_account_circle_white_24dp.png" title="{{ user != null ? user.txtFirstName + ' ' + user.txtLastName : '' }}" />
                </span>
              </div>
            </td>
            <td align="right" style="width:5%">
              <span style="display:inline-block;" *ngIf="isAuthenticated" (click)="onLogout()">
                <a class="nav-link" [routerLink]="['']"><img src="../../assets/img/logout_white_24dp.png" title="Logout" /></a>
              </span>
            </td>
            <td align="right" style="width:5%">
              <div><p style="color:white;">{{time | date:'HH:mm' }}</p></div>
              <div><p style="font-size:0.7em;color:white;display:inline;text-wrap:none;">Token&nbsp;Expires:&nbsp;&nbsp;</p><p style="color: white;display: inline;text-wrap:none;">{{timeRefreshTime}}&nbsp;m</p></div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <app-spinner-inline-tick id="navSpinner" #spinnerInlineTick [showSpinner]="showInlineSpinner" [showTick]="showTick" [diameter]="20" [inlineSpinnerId]="'inlineSpinnerId'" [stokeColor]="ffffff" class="inline-spinner nav-spinner"></app-spinner-inline-tick>

    <!--

  <div #navCollapseMenu class="navbar-collapse collapse flex-sm-row-reverse w-100" id="navbarSupportedContent" [ngClass]="{ show: isExpanded }">
  <ul class="navbar-nav flex-grow">
    <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" [routerLink]="['/']">Home</a>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Board Plant
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()"><a class="dropdown-item" [routerLink]="['/boardtallyrecord']">&nbsp;Stock Items</a></span>
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/bpstocktake']">&nbsp;Stocktake</a></span>
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/getpack']">&nbsp;BP Get Pack</a></span>
        <div class="dropdown-divider"></div>
        <div class="dropdown-item dropdown-heading">Reports</div>
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/bpstocktake']">&nbsp;Sales By State</a></span>
      </div>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Mill</a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/boardtallyrecord']">&nbsp;Edger</a></span>
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/bpstocktake']">&nbsp;Bench</a></span>
        <div class="dropdown-divider"></div>
        <div class="dropdown-item dropdown-heading">Reports</div>
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/bpstocktake']">&nbsp;Sales By State</a></span>
      </div>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Treatment Plant</a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/boardtallyrecord']">&nbsp;Charge Sheets</a></span>
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/bpstocktake']">&nbsp;Pole Stock</a></span>
        <div class="dropdown-divider"></div>
        <div class="dropdown-item dropdown-heading">Reports</div>
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/bpstocktake']">&nbsp;Sales By State</a></span>
      </div>
    </li>
    <li class="nav-item dropdown" [hidden]="!isAdmin()">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Reports</a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/boardtallyrecord']">&nbsp;Production Graphs</a></span>
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/bpstocktake']">&nbsp;Costings</a></span>
        <div class="dropdown-divider"></div>
        <div class="dropdown-item dropdown-heading">Reports</div>
        <span data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded"><a class="dropdown-item" [routerLink]="['/bpstocktake']">&nbsp;Sales By State</a></span>
      </div>
    </li>
    <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="!isAuthenticated()">
      <a class="nav-link" [routerLink]="['/login']">Login</a>
    </li>
    <li class="nav-item" [routerLinkActive]="['link-active']" (click)="onLogout()" *ngIf="isAuthenticated()">
      <a class="nav-link" [routerLink]="['']">Logout</a>
    </li>
    <li class="nav-item" [hidden]="!isAdmin()" [routerLinkActive]="['link-active']">
      <a class="nav-link" [routerLink]="['/register']">Register</a>
    </li>
  </ul>
    </div>
  -->

  </nav>
</header>
