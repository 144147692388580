import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoOrderItem } from '../business-objects/dto-order-item.bo';
import { Orders } from '../business-objects/orders.bo';

@Injectable()
export class dtoOrderItemService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoOrderItem(dtoOrderItemId: string) {
		return this.http.get<dtoOrderItem>(this._baseUrl + 'api/dtoOrderItem/' + dtoOrderItemId);
	}

  getdtoOrderItemParamArray(aParam: SQLParamArray[][]) {
    console.log(this._baseUrl + 'api/dtoOrderItem/GetdtoOrderItemsParamArray/');
		return this.http.post<dtoOrderItem[]>(this._baseUrl + 'api/dtoOrderItem/GetdtoOrderItemsParamArray/', aParam);
	}

	getAlldtoOrderItems() {
		return this.http.get<dtoOrderItem[]>(this._baseUrl + 'api/dtoOrderItem/GetAlldtoOrderItems/');
	}

	//getAlldtodtoOrderItems() {
	//	return this.http.get<dtodtoOrderItem[]>(this._baseUrl + 'api/dtoOrderItem/GetAlldtoOrderItems/');
	//}

	createdtoOrderItem(dtoOrderItem: dtoOrderItem) {
		return this.http.post<dtoOrderItem>(this._baseUrl + 'api/dtoOrderItem/CreatedtoOrderItem/', dtoOrderItem);
	}

	updatedtoOrderItem(dtoOrderItem: dtoOrderItem) {
		return this.http.put<dtoOrderItem>(this._baseUrl + 'api/dtoOrderItem/UpdatedtoOrderItem/', dtoOrderItem);
	}

	async getdtoOrderItemPromise(dtoOrderItemId: string) {
		return new Promise<dtoOrderItem>(resolve => {
			this.getdtoOrderItem(dtoOrderItemId).subscribe(dtoOrderItem => {
				resolve(dtoOrderItem);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoOrderItemParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoOrderItem[]>(resolve => {
			this.getdtoOrderItemParamArray(aParam).subscribe(dtoOrderItems => {
				resolve(dtoOrderItems);
      }, err => {
        console.log(err);
				this.alertService.openSnackBarError("Error getting dtoOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoOrderItemPromise(dtoOrderItem: dtoOrderItem) {
		return new Promise<dtoOrderItem>(resolve => {
			this.updatedtoOrderItem(dtoOrderItem).subscribe(dtoOrderItem => {
				resolve(dtoOrderItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoOrderItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoOrderItemPromise(dtoOrderItem: dtoOrderItem) {
		return new Promise<dtoOrderItem>(resolve => {
			this.createdtoOrderItem(dtoOrderItem).subscribe(dtoOrderItem => {
				resolve(dtoOrderItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoOrderItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
  }


  async getCustomerOrderItems(customerId: string[], status: Orders.enStatus[], guPoleId: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (status != null) {
      aParam = [];
      for (let i = 0; i <= status.length - 1; i++) {
        aParam.push(new SQLParamArray("intOrderStatus", status[i].toString()))
      }
      aParamList.push(aParam);
    }

    aParam = [];
    for (let i = 0; i <= customerId.length - 1; i++) {
      aParam.push(new SQLParamArray("guCustomerId", customerId[i]))
    }
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("intOrderType", "0"))
    aParamList.push(aParam);

    if (guPoleId != null && guPoleId != "") {
      aParam = [];
      aParam.push(new SQLParamArray("guPoleId", guPoleId))
      aParamList.push(aParam);
    }


    return this.getdtoOrderItemParamArrayPromise(aParamList);
  }


 }
