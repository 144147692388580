import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { take } from 'rxjs/operators';


@Injectable()
export class NavigationService {

  public _baseUrl: string;
  public returnURL: string;

  private readonly BPGeoLocationGuid: string = "11111111-1111-1111-1111-111111111111";
  private readonly OfficeGeoLocationGuid: string = "22222222-2222-2222-2222-222222222222";
  private readonly CoffsGeoLocationGuid: string = "33333333-3333-3333-3333-333333333333";
  private readonly GlenreaghYardGeoLocationGuid: string = "44444444-4444-4444-4444-444444444444";

  constructor(private http: HttpClient, private router: Router, @Inject('BASE_URL') baseUrl: string, private route: ActivatedRoute, private serializer: UrlSerializer) {
    this._baseUrl = baseUrl;
  }

  //setNavParams() {
  //  this.route.queryParams.pipe(take(1)).subscribe(params => {
  //    if (params != null) {
  //      //const tree = this.router.createUrlTree([], { queryParams: params });
  //      this.returnURL = params["returnUrl"];
  //    }
  //  });
  //}

  getReturnURL() {
    let query: string = '';
    let host: string = "https://" + window.location.host;

    //console.log("href: " + window.location.href);
    //console.log("host: " + window.location.host);

    query = window.location.href.substring(host.length, window.location.href.length);

    //console.log("query: " + query);

    //if (window.location.href.indexOf('?') > -1) {
    //  query += window.location.href.substring(window.location.href.indexOf('?') + 1, window.location.href.length);
    //  console.log("return url: " + query);
    //}
    return query;
  }

  getLocationGuid(location: string) {

    if (location == "Coffs") {
      return this.CoffsGeoLocationGuid;
    }

    if (location == "BP") {
      return this.BPGeoLocationGuid;
    }

    if (location == "HQ") {
      return this.OfficeGeoLocationGuid;
    }

    if (location == "GY") {
      return this.GlenreaghYardGeoLocationGuid;
    }

    // Just in case location is not sent for whatever reason
    // We will default to Coffs location
    return this.CoffsGeoLocationGuid;
  }


}  
