import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import { dtoOrderItem } from '../../../_shared/business-objects/dto-order-item.bo';
import { Printer } from '../../../_shared/business-objects/printer.bo';
import { PrintService } from '../../../_shared/services/app-services/print.service';
import { dtoOrderItemService } from '../../../_shared/services/dto-order-item.service';
import { PrinterService } from '../../../_shared/services/printer.service';
import { PrintByteArray } from '../../../_shared/app-objects/PrintByteArray';
import { ActivatedRoute } from '@angular/router';
import { dtoStockItem } from '../../../_shared/business-objects/dto-stock-item.bo';
import { MatTableDataSource } from '@angular/material/table';
import { StockContainer } from '../../../_shared/business-objects/stock-container.bo';
import { dtoKeyValueBarcode } from '../../../_shared/business-objects/dto-int-key-value.bo';
import { dtoStockItemService } from '../../../_shared/services/dto-stock-item.service';
import { SQLParamArray } from '../../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../../_shared/services/alert.service';
import { StockContainerService } from '../../../_shared/services/stock-container.service';
import { StockItemContainer } from '../../../_shared/business-objects/stock-item-container';

@Component({
  selector: 'app-order-item-barcode-sticker',
  templateUrl: './order-item-barcode-sticker.component.html',
  styleUrls: ['./order-item-barcode-sticker.component.css']
})
export class OrderItemBarcodeStickerComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoStockItem>;
  @Input() orderItem: dtoOrderItem;
  @Input() stockItems: dtoStockItem[];
  @Input() stockItemContainer: StockItemContainer[];
  @Output() removeStockItemEvent = new EventEmitter<dtoStockItem>();

  stockContainer: StockContainer;
  keyValueBarcode: dtoKeyValueBarcode;

  showSpinner: boolean = false;
  multiItem: boolean = true;
  stockContainerIdentifier: string = "Auto Generated";
  stockContainerId: string = "";
  customerName: string = "";
  orderNo: string = "";

  displayedStockItems: string[] = ['intItemNo', 'txtProductName', 'txtProfileName', 'txtSpeciesName', 'txtGradeName', 'intSize', 'fltQuantityLength', 'fltOrderLength', 'btnRemoveItem'];

  constructor(private dtoOrderItemService: dtoOrderItemService, private printService: PrintService, private printerService: PrinterService, private route: ActivatedRoute
              , private dtoStockItemService: dtoStockItemService, private alertService: AlertService, private stockContainerService: StockContainerService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(async params => {

      //let boardTallyRecordHistory: dtoStockItem = new dtoStockItem();
      //this.stockItems = [];
      //this.stockItems.push(boardTallyRecordHistory);


      this.stockContainerId = "";

      if (params != null) {
        this.stockContainerId = params["stockcontainerid"];
        if (this.stockContainerId != null && this.stockContainerId != "") {
          let aParamList: SQLParamArray[][] = [];
          let aParam: SQLParamArray[] = [];

          this.stockContainer = await this.stockContainerService.getStockContainerPromise(this.stockContainerId);


          this.stockContainerIdentifier = this.stockContainer.intIdentifier.toString();

          aParam.push(new SQLParamArray("guStockContainerId", this.stockContainerId));
          aParamList.push(aParam);


          this.stockItems = await this.dtoStockItemService.getdtoStockItemParamArrayPromise(aParamList);
          this.stockItems.sort((a, b) => { return a.intItemNo - b.intItemNo });

          this.datasource = new MatTableDataSource<dtoStockItem>();
          this.datasource.data = this.stockItems;

          if (this.stockItems != null && this.stockItems.length > 0) {
            this.customerName = this.stockItems[0].txtCustomerName;
            this.orderNo = this.stockItems[0].txtOrderNo;
          }

          console.log("containerId: ", this.stockItems);
          //window.resizeTo(900, 800);
          //window.print();
        }


      }

    });



  }

  getHTMLElement() {
    let htmlElement: HTMLElement = document.getElementById("orderItemBarcodeSticker");

    if (htmlElement == null) {
      return null;
    }

    return htmlElement;

  }

  setOrderItem(orderItem: dtoOrderItem) {
    this.orderItem = orderItem;
  }

  async getOrdrItem() {
    this.orderItem = await this.dtoOrderItemService.getdtoOrderItemPromise("077b6204-92e8-4d8a-a3e2-4a079dc15ed6");
    console.log("setting order item: ", this.orderItem);

  }

  async viewPDF() {



    this.showSpinner = true;

    let htmlElement: HTMLElement = this.getHTMLElement();

    //let iFrame: HTMLIFrameElement = document.createElement("iframe");
    //iFrame.contentWindow.document.body.appendChild(htmlElement);
    //var x = document.createElement("iframe");
    ////x.setAttribute("src", "https://www.w3schools.com");
    //let newWin = x.contentWindow;

    //newWin.document.body.appendChild(htmlElement);
    //let newHTML: string = "<html><body>" + newWin.document.documentElement.outerHTML + "</body></html>";
    ////var iframeWindow = x.contentWindow;
    //var printWindow = window.open('', '_blank', 'height=600,width=800');
    //printWindow.document.write(newWin.document.documentElement.outerHTML);
    //printWindow.document.close();
    //printWindow.focus();
    //printWindow.print();
    //printWindow.close();


    //var printContent = document.getElementById('ctl00_DefaultContent_divShippingRate');

    var windowUrl = 'https://' + location.hostname + ':44356/order-item-barcode-sticker?orderitemid=763544e4-ecbb-4537-85ab-e2c3c60a09d9&hidenav=yes';
    var uniqueName = new Date();
    var windowName = "_blank";
    var printWindow = window.open(windowUrl, windowName, 'left=0,top=0,width=1000,height=0');

    //window.open("https://www.w3schools.com", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");

    console.log("location name: " + location.hostname);

    return;

    //var head = document.getElementsByTagName("head");
    //let newWinHTML = "<html>" + head[0].innerHTML + "<body>" + htmlElement.innerHTML + "</body></html>";
    //printWindow.document.write(newWinHTML);
    //printWindow.document.close();
    //printWindow.focus();
    //printWindow.print();

    //printWindow.close();


    //htmlToImage.toPng(htmlElement, { width: htmlElement.clientWidth, height: htmlElement.clientHeight, backgroundColor: 'white', canvasWidth: htmlElement.clientWidth, canvasHeight: htmlElement.clientHeight, quality: 1})
    //  .then(function (dataUrl) {
    //    var img = new Image();
    //    img.src = dataUrl;

    //    window.saveAs(img.src, 'test.png');

    //    //document.body.appendChild(img);
    //  })
    //  .catch(function (error) {
    //    console.error('oops, something went wrong!', error);
    //  });


    //htmlToImage.toBlob(htmlElement, { quality: 0.95 })
    //  .then(function (blob) {
    //    if (window.saveAs) {
    //      window.saveAs(blob, 'my-node.png');
    //    } else {
    //      //FileSaver.saveAs(blob, 'my-node.png');
    //    }
    //  });


    //var doc = new jsPDF('p', 'px', 'a4')
    //let htmlE: HTMLElement = document.createElement('div');
    //htmlE.innerHTML = "Test dynamic text";


    //html2canvas(htmlElement).then(async canvas => {
      
    //    var img = canvas.toDataURL("image/png");
    //    //alert('This will currently open image in a new window called "data:". Instead I want to save to users local computer. Ideally as a jpg instead of png.');

    //  doc.addImage(img, 'png', 15, 15, 400, 600);
    //  doc.save('testPDF.pdf');

      // string generated by canvas.toDataURL()

      //var img = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0"
      //  + "NAAAAKElEQVQ4jWNgYGD4Twzu6FhFFGYYNXDUwGFpIAk2E4dHDRw1cDgaCAASFOffhEIO"
      //  + "3gAAAABJRU5ErkJggg==";
      // strip off the data: url prefix to get just the base64-encoded bytes
      //var data = img.replace(/^data:image\/\w+;base64,/, "");

      //const base64String = btoa(img);

      ////window.saveAs(img, 'test.png');

      //let printer: Printer = await this.printerService.getPrintersByAppNameAndTypePromise("WEBAPP-HOME", Printer.enType.Normal);

      //let printByteArray: PrintByteArray = new PrintByteArray();
      //printByteArray.byteArray = img;
      //printByteArray.printer = printer;

      //console.log(printByteArray);

      //this.printService.printByteArray(printByteArray);


      //console.log(img);
      //window.open(img);
      
        //document.body.appendChild(canvas)
    //});

    //doc.html(htmlE,
    //  {
    //    html2canvas: {
    //      // insert html2canvas options here, e.g.
    //      //width: width,
    //      //scale: scale
    //    },
    //    callback: (pdf) => {
    //      console.log("got pdf about to open");
    //      window.open(pdf.output('bloburl').toString());
    //      //pdf.save('save.pdf');
    //      console.log("pdf opened");
          
    //    }
    //  }
    //);

    console.log("show spinner");

    //let htmlElement: HTMLElement = this.getHTMLElement();
    //await this.printService.viewPDF(htmlElement, 200, 0.5);

    //printJS('orderItemBarcodeSticker', 'html');

    this.showSpinner = false;
    console.log("hide spinner");
  }

  async print() {

    console.log("stock items", this.stockItems);

    //if (this.stockItems == null || this.stockItems.length == 0) {
    //  this.alertService.openSnackBarDefault("There are no stock items to add");
    //  return;
    //}



    let promptuser = window.print
    window.print();
    return;

    this.showSpinner = true;

    let htmlElement: HTMLElement = this.getHTMLElement();
    let printer: Printer = await this.printerService.getPrintersByAppNameAndTypePromise("WEBAPP-HOME", Printer.enType.Normal);

    await this.printService.printPDF(htmlElement, printer, 200, 0.5);

    this.showSpinner = false;

  }

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
   }

  downloadBase64File(contentBase64, fileName) {
    const linkSource = `data:application/pdf;base64,${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
  }


  loadStockItems(stockItems: dtoStockItem[]) {

    console.log("stock items - before", stockItems);

    if (stockItems == null) {
      return;
    }

    console.log("stock items - after", stockItems);

    //let stockItems: dtoStockItem[] = [];
    //stockItemContainer.forEach(sc => {
    //  stockItems.push(sc.boardTallyRecordHistory);
    //});


    //this.multiItem = (stockItems.length > 1);
    this.multiItem = true;

    this.datasource = new MatTableDataSource<dtoStockItem>();
    this.datasource.data = stockItems;



  }

  removeItem(boardTallyRecordHistory: dtoStockItem) {

    this.removeStockItemEvent.emit(boardTallyRecordHistory);



  }

}
