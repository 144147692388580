<form class="example-form" [formGroup]="form">
  <div class="final-inspection-item-list-container">
    <table mat-table #tblfinalInspectionItems [dataSource]="datasource" class="mat-elevation-z8" matSort>

      <ng-container matColumnDef="intItemNo">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalItemNumber"> {{finalInspectionItem.intItemNo}} </td>
      </ng-container>

      <ng-container matColumnDef="txtCustomerName">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Customer </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalInspectionItemtxtCustomerName"> {{finalInspectionItem.txtCustomerName}} </td>
      </ng-container>

      <ng-container matColumnDef="txtOrderNo">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Order No </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalInspectionItemtxtOrderNo"> {{finalInspectionItem.txtOrderNo}} </td>
      </ng-container>

      <ng-container matColumnDef="txtPoleNumber">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Pole Number </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD poleNumber"> {{finalInspectionItem.txtPoleNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="txtConsecutiveNumber">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Consec Number </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalConsecutiveNumber"> {{finalInspectionItem.txtConsecutiveNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="txtChargeId">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Charge Number </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalChargeNo"> {{finalInspectionItem.txtChargeId}} </td>
      </ng-container>

      <ng-container matColumnDef="txtChargeDate">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Treat Month / Year</th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalTreatmentMonthYear"> {{finalInspectionItem.intTreatmentMonth}} {{finalInspectionItem.intTreatmentYear != null ? '/' : ''}} {{finalInspectionItem.intTreatmentYear}}</td>
      </ng-container>

      <ng-container matColumnDef="txtOriginalPole">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Original Pole </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalOriginalPole"> {{finalInspectionItem.txtOriginalPole}} </td>
      </ng-container>

      <ng-container matColumnDef="txtFinalPole">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Final Pole </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalFinalPole"> {{finalInspectionItem.txtFinalPole}} </td>
      </ng-container>

      <ng-container matColumnDef="txtDrilled">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Drilled </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalDrilled"> {{finalInspectionItem.txtDrilled}} </td>
      </ng-container>

      <ng-container matColumnDef="txtSpec">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Spec </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalSpec"> {{finalInspectionItem.txtSpec}} </td>
      </ng-container>

      <ng-container matColumnDef="fltLength">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Length </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalLength"> {{finalInspectionItem.fltLength == 0 ? '' : finalInspectionItem.fltLength}} </td>
      </ng-container>

      <ng-container matColumnDef="fltGLDia">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Dia </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalGLDia"> {{finalInspectionItem.fltDiameter}} </td>
      </ng-container>

      <ng-container matColumnDef="txtProductName">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Product Name </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalProductName"> {{finalInspectionItem.txtProductName}} </td>
      </ng-container>

      <ng-container matColumnDef="txtSpeciesName">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Species Name </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalSpeciesName"> {{finalInspectionItem.txtSpeciesCode}} </td>
      </ng-container>

      <ng-container matColumnDef="fltGirth">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Girth </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalGirth"> {{finalInspectionItem.fltGirth}} </td>
      </ng-container>

      <ng-container matColumnDef="fltActualM3">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Actual M3 </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalActualM3 alignRight"> {{finalInspectionItem.fltActualM3}} </td>
      </ng-container>

      <ng-container matColumnDef="fltActualTonne">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Actual Tonne </th>
        <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalActualTonne alignRight"> {{finalInspectionItem.fltActualTonne}} </td>
      </ng-container>


      <ng-container matColumnDef="chkAddItem">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH finalInspectionItemEditTH" mat-sort-header>  </th>
        <td mat-cell *matCellDef="let finalInspectionItem; let rowIdx = index;" class="finalInspectionItemTD finalInspectionItemEdit">
          <mat-checkbox class="example-margin" #addItem id="chkAddItem{{rowIdx}}" [checked]="(finalInspectionItem.blnRejected == false || finalInspectionItem.blnRejected == null) ? true : fasle" [disabled]="(finalInspectionItem.guDeliveryDocketOrderItemId != null || finalInspectionItem.blnRejected) ? true : false" (change)="addItemChange(finalInspectionItem, addItem.checked, rowIdx)" *ngIf="(finalInspectionItem.blnRejected == false || finalInspectionItem.blnRejected == null) ? true : fasle">Add Item</mat-checkbox>
          <div *ngIf="finalInspectionItem.blnRejected == true">Rejected</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="btnUnlink">
        <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH finalInspectionItemUnlinkTH" mat-sort-header>  </th>
        <td mat-cell *matCellDef="let finalInspectionItem; let rowIdx = index;" class="finalInspectionItemTD finalInspectionItemUnlink">
          <button class="remove-delivery-docket-order-item-button" type="button" mat-flat-button color="primary" [disabled]="finalInspectionItem.guDeliveryDocketOrderItemId != null ? false : true" (click)="unlinkFinalItem(finalInspectionItem)">Unlink</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedfinalInspectionItems"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedfinalInspectionItems;">
      </tr>
    </table>
    <div class="no-stock-items" *ngIf="datasource == null"><h7>There are no final inspections</h7></div>
  </div>
</form>
