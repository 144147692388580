<h1 id="tableLabel">Get Woolworths Products</h1>


<input type="text" name="productCode" id="productCode" />

<button value="Get Wow Data" (click)="getWOWProducts()">Get WOW Data</button>
<button value="Send Email" (click)="sendEmail()">Send Email</button>
<br />
<br />

<div id="productsContainer">
  <h1 id="tableLabel">Woolworths Products</h1>

  <table mat-table id="stockItems" #tblStockItems [dataSource]="wowStockItems" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="StockItem NameHeader" style="text-align: left;padding-right: 30px;"> Name </th>
      <td mat-cell *matCellDef="let StockItem" class="StockItem" style="text-align:left;padding-right: 30px;"> {{StockItem.Name}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef class="StockItem" style="text-align:right;padding-right: 30px;"> Price </th>
      <td mat-cell *matCellDef="let StockItem" class="StockItem" style="text-align:right;padding-right: 30px;"> {{StockItem.Price | currency}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="special">
      <th mat-header-cell *matHeaderCellDef class="StockItem" style="text-align:left;padding-right: 30px;"> Special </th>
      <td mat-cell *matCellDef="let StockItem" class="StockItem" style="text-align:left;padding-right: 30px;"> {{StockItem.Special ? "Yes" : "No"}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="wasprice">
      <th mat-header-cell *matHeaderCellDef class="StockItem" style="text-align:right;padding-right: 30px;"> Was Price </th>
      <td mat-cell *matCellDef="let StockItem" class="StockItem" style="text-align:right;padding-right: 30px;"> {{StockItem.WasPrice | currency}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="discount">
      <th mat-header-cell *matHeaderCellDef class="StockItem" style="text-align:right;padding-right: 30px;"> Discount </th>
      <td mat-cell *matCellDef="let StockItem" class="StockItem" style="text-align:right;padding-right: 30px;"> {{StockItem.Discount}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
  </table>

</div>
