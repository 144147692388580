import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeliveryDocketOrderItem } from '../../_shared/business-objects/delivery-docket-order-item.bo';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { DeliveryDocketAddItemsComponent } from '../delivery-docket-add-items/delivery-docket-add-items.component';

@Component({
  selector: 'app-delivery-docket-order-item-update-dialog',
  templateUrl: './delivery-docket-order-item-update-dialog.component.html',
  styleUrls: ['./delivery-docket-order-item-update-dialog.component.css'], encapsulation: ViewEncapsulation.None
})
export class DeliveryDocketOrderItemUpdateDialogComponent implements OnInit {

  deliveryDocketOrderItem: DeliveryDocketOrderItem;
  deliveryDocket: DeliveryDocket;

  constructor(private dialogRef: MatDialogRef<DeliveryDocketAddItemsComponent>, @Inject(MAT_DIALOG_DATA) private data: { deliveryDocketOrderItem: DeliveryDocketOrderItem, deliveryDocket: DeliveryDocket }) {

    this.deliveryDocketOrderItem = data.deliveryDocketOrderItem;
    this.deliveryDocket = data.deliveryDocket;

  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close({ status: "Cancel", finalInspectionItem: null });
  }

  ngOnInit(): void {


  }


  deliveryDocketOrderItemUpdated(deliveryDocketOrderItemUpdated: DeliveryDocketOrderItem) {

    this.dialogRef.close({ status: "Saved", deliveryDocketOrderItem: deliveryDocketOrderItemUpdated });


  }

  deliveryDocketOrderItemCancelled(cancelled: boolean) {

    this.dialogRef.close({ status: "Cancel", finalInspectionItem: null });


  }

}
