<div class="chh-dialog-container truck-trailer-selection-container">
  <div class="truck-trailer-heading"><h1>Truck / Trailer Selection</h1></div>
  <div class="truck-trailer-grid-container">
    <div class="hauling-company-container">
      <app-hauling-company-selection [selectedHaulingCompany]="selectedHaulingCompany" (haulingCompanySelectionEvent)="haulingCompanySelection($event)" (chhVehicleSelectionEvent)="chhVehicleSelection($event)"></app-hauling-company-selection>
    </div>
    <div class="driver-selection-container">
      <app-hauling-company-driver-selection #appHaulingCompanyDriverSelection [selectedHaulingCompanyDriver]="selectedHaulingCompanyDriver" [haulingCompanyId]="haulingCompanyId" (haulingCompanyDriverSelectionEvent)="haulingCompanyDriverSelection($event)"></app-hauling-company-driver-selection>
    </div>
    <div class="equipment-selection-container">
      <app-mobile-plant-equipment-selection #appMobilePlantEquipmentSelection [mobilePlantEquipmentObj]="trailerEquipment" [EquipmentCategory]="equipmentCategoryId" [chhVehicles]="chhVehicle" (equipmentSelectionEvent)="equipmentSelection($event)"></app-mobile-plant-equipment-selection>
    </div>
  </div>
  <div class="button-container">
    <button class="save-button" type="button" mat-flat-button color="primary" (click)="save()">Save</button>
    <button class="cancel-button" type="button" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
  </div>

</div>
