import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { ElectricalAuthoritySpec } from '../../_shared/business-objects/electrical-authority-spec.bo';
import { Employee } from '../../_shared/business-objects/employee.bo';
import { TPPoleInspection } from '../../_shared/business-objects/tppole-inspection.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { ElectricalAuthoritySpecService } from '../../_shared/services/electrical-authority-spec.service';
import { EmployeeService } from '../../_shared/services/employee.service';
import { SharedService } from '../../_shared/services/shared.service';
import { TPPoleInspectionService } from '../../_shared/services/tppole-inspection.service';

@Component({
  selector: 'app-final-inspection-update',
  templateUrl: './final-inspection-update.component.html',
  styleUrls: ['./final-inspection-update.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class FinalInspectionUpdateComponent implements OnInit {

  form: FormGroup;
  hazardLevels: dtoIntKeyValue[] = [];
  employees: Employee[] = [];
  finalStatuses: dtoIntKeyValue[] = []
  public elecAuthSpecs: ElectricalAuthoritySpec[];

  @Input() tpPoleInspection: TPPoleInspection;
  @Output() finalInspectionUpdated = new EventEmitter<TPPoleInspection>();
  @Output() finalInspectionCancelled = new EventEmitter<boolean>();

  public saving: boolean = false;
  public showSpinner: boolean = false;

  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private tpPoleInspectionService: TPPoleInspectionService
    , private employeeService: EmployeeService, private electricalAuthoritySpec: ElectricalAuthoritySpecService) {

    this.form = this.fb.group({
      dteDateInspected: [''],
      guInspectedById: [''],
      chkCHHBrandApplied: [''],
      chkInspectorsStampApplied: [''],
      chkCCAAndHazardLevelApplied: [''],
      chkSurfaceClean: [''],
      chkButtsMarkedCorrectly: [''],
      radHazardLevel: [''],
      intStatus: [''],
      intLoadNo: [''],
      intMaxLoads: [''],
      txtComments: [''],
      blnParkedFinal: [''],
      txtParkedFinalComments: [''],
      guElectricalAuthoritySpecId: ['']
    });


    this.hazardLevels = this.sharedService.getEnumObjectKeyValue(SharedService.enHazardTreatment);
    this.finalStatuses = this.sharedService.getEnumObjectKeyValue(TPPoleInspection.enStatus);

  }

  ngOnInit(): void {

    if (this.tpPoleInspection == null) {
      this.alertService.openSnackBar("There is no pole inspection to update.", "Close", "center", "bottom", "", 3000);
      return;
    }

    this.getElectricalAuthoritySpecs();

    this.employeeService.getEmployeesInPosition("Pole Final Inspector").subscribe(employees => {
      console.log(employees);

      this.employees = employees


      let inList: boolean = false;


      this.employees.forEach(employee => {
        if (employee.rowguid == this.tpPoleInspection.guInspectedById) {
          inList = true;
        }
      });

      if (inList == false) {
        // Add Employee to list and sort.
        this.employeeService.getEmployeeByRowguid(this.tpPoleInspection.guInspectedById).subscribe(e => {
          this.employees.push(e);
          this.employees.sort((a, b) => a.txtLastName.localeCompare(b.txtLastName) || a.txtFirstName.localeCompare(b.txtFirstName));
        }, err => {
          console.log(err);
        })
      }

    }, err => {
      console.log(err);
    });





    this.form.controls['dteDateInspected'].setValue(this.tpPoleInspection.dteDateInspected);
    this.form.controls['guInspectedById'].setValue(this.tpPoleInspection.guInspectedById);
    this.form.controls['chkCHHBrandApplied'].setValue(this.tpPoleInspection.blnCHHBranded);
    this.form.controls['chkInspectorsStampApplied'].setValue(this.tpPoleInspection.blnInspectedStamp);
    this.form.controls['chkCCAAndHazardLevelApplied'].setValue(this.tpPoleInspection.blnCCAHazardStamp);
    this.form.controls['chkSurfaceClean'].setValue(this.tpPoleInspection.blnSurfaceClean);
    this.form.controls['chkButtsMarkedCorrectly'].setValue(this.tpPoleInspection.blnButtsMarkedCorrectly);
    this.form.controls['radHazardLevel'].setValue(this.tpPoleInspection.intCCAHazardLevel);
    this.form.controls['intLoadNo'].setValue(this.tpPoleInspection.intLoadNo);
    this.form.controls['intMaxLoads'].setValue(this.tpPoleInspection.intMaxLoads);
    this.form.controls['intStatus'].setValue(this.tpPoleInspection.intStatus);
    this.form.controls['txtComments'].setValue(this.tpPoleInspection.txtComments);
    this.form.controls['blnParkedFinal'].setValue(this.tpPoleInspection.blnParkedFinal);
    this.form.controls['txtParkedFinalComments'].setValue(this.tpPoleInspection.txtParkedSpec);

    this.form.controls['guElectricalAuthoritySpecId'].setValue(this.sharedService.EmptyGuid);

    if (this.tpPoleInspection.guElectricalAuthoritySpecId != null) {
      this.form.controls['guElectricalAuthoritySpecId'].setValue(this.tpPoleInspection.guElectricalAuthoritySpecId);
    }

  }


  async getElectricalAuthoritySpecs() {
    await this.electricalAuthoritySpec.getAllElectricalAuthoritySpecs().subscribe(data => {
      this.elecAuthSpecs = data;
      let elecAuthSpec: ElectricalAuthoritySpec = new ElectricalAuthoritySpec;
      elecAuthSpec.rowguid = this.sharedService.EmptyGuid;
      elecAuthSpec.txtAuthorityName = "None";
      this.elecAuthSpecs.splice(0, 0, elecAuthSpec);
    });
  }

  async updateFinalInspection() {

    let saveFinalInspection: boolean = true;
    let dateInspected: string = this.form.controls['dteDateInspected'].value;
    let inspectedById: string = this.form.controls['guInspectedById'].value;
    let chhBrandApplied: boolean = this.form.controls['chkCHHBrandApplied'].value == true;
    let inspectorsStamp: boolean = this.form.controls['chkInspectorsStampApplied'].value == true;
    let ccaHazardLevel: boolean = this.form.controls['chkCCAAndHazardLevelApplied'].value == true;
    let surfaceClean: boolean = this.form.controls['chkSurfaceClean'].value == true;
    let buttsMarkedCorrectly: boolean = this.form.controls['chkButtsMarkedCorrectly'].value == true;
    let hazardLevel: number = this.form.controls['radHazardLevel'].value;
    let loadNo: number = this.form.controls['intLoadNo'].value;
    let maxLoads: number = this.form.controls['intMaxLoads'].value;
    let status: number = this.form.controls['intStatus'].value;
    let comments: string = this.form.controls['txtComments'].value;
    let parkedFinal: boolean = this.form.controls['blnParkedFinal'].value == true;
    let parkedFinalComments: string = this.form.controls['txtParkedFinalComments'].value;
    let electricalAuthoritySpec: string = this.form.controls['guElectricalAuthoritySpecId'].value;
    const datepipe: DatePipe = new DatePipe('en-AU');

    console.log("dateInspected: " + dateInspected);
    console.log("ccaHazardLevel: " + ccaHazardLevel);
    console.log("surfaceClean: " + surfaceClean);
    console.log("buttsMarkedCorrectly: " + buttsMarkedCorrectly);
    console.log("hazardLevel: " + hazardLevel);

    if (dateInspected == null || dateInspected.toString() == "") {
      this.alertService.openSnackBar("Please select an inspection date.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (inspectedById == null || inspectedById.toString() == "") {
      this.alertService.openSnackBar("Please select an inspector.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (chhBrandApplied == null || chhBrandApplied == false) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("CHH Brand", "Is the CHH Brand applied?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = (alertResponse == "Yes");
      chhBrandApplied = (alertResponse == "Yes");
      this.form.controls['chkCHHBrandApplied'].setValue((alertResponse == "Yes"));
    }

    if (saveFinalInspection && (inspectorsStamp == null || inspectorsStamp == false)) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("Inspectors Stamp", "Is the inspectors stamp suppose to be applied?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = true;  // We are going to save whether they say yes or no, just set checkbox.
      inspectorsStamp = (alertResponse == "Yes");
      this.form.controls['chkInspectorsStampApplied'].setValue((alertResponse == "Yes"));
    }

    if (saveFinalInspection && (ccaHazardLevel == null || ccaHazardLevel == false)) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("CCA and Hazard Level Stamp", "Is the CCA and Hazard Level stamp suppose to be applied?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = (alertResponse == "Yes");
      ccaHazardLevel = (alertResponse == "Yes");
      this.form.controls['chkCCAAndHazardLevelApplied'].setValue((alertResponse == "Yes"));
    }

    if (saveFinalInspection && (surfaceClean == null || surfaceClean == false)) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("Surface Clean No Residure", "Is the surface clean with no residue?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = (alertResponse == "Yes");
      surfaceClean = (alertResponse == "Yes");
      this.form.controls['chkSurfaceClean'].setValue((alertResponse == "Yes"));
    }

    if (saveFinalInspection && (buttsMarkedCorrectly == null || buttsMarkedCorrectly == false)) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("Butts Marked Correctly", "Are the butts marked correctly?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = (alertResponse == "Yes");
      buttsMarkedCorrectly = (alertResponse == "Yes");
      this.form.controls['chkButtsMarkedCorrectly'].setValue((alertResponse == "Yes"));
    }

    if (saveFinalInspection && (hazardLevel == null || hazardLevel == 0)) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("Hazard Level", "Is there a Hazard Level?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = (alertResponse != "Yes");  // If we've canceled the snack bar it means we want to stop and select a Hazard Level
    }


    if (saveFinalInspection) {
      this.saving = true;
      this.showSpinner = true;

      let tpPoleInspection: TPPoleInspection = this.tpPoleInspection;


      tpPoleInspection.dteDateInspected = new Date(datepipe.transform(dateInspected, 'yyyy-MM-dd'));
      tpPoleInspection.guInspectedById = inspectedById;
      tpPoleInspection.blnCHHBranded = chhBrandApplied;
      tpPoleInspection.blnInspectedStamp = inspectorsStamp;
      tpPoleInspection.blnCCAHazardStamp = ccaHazardLevel;
      tpPoleInspection.blnSurfaceClean = surfaceClean;
      tpPoleInspection.blnButtsMarkedCorrectly = buttsMarkedCorrectly;
      tpPoleInspection.intCCAHazardLevel = hazardLevel;
      tpPoleInspection.intLoadNo = loadNo;
      tpPoleInspection.intMaxLoads = maxLoads;
      tpPoleInspection.intStatus = status;
      tpPoleInspection.txtComments = comments;
      tpPoleInspection.blnParkedFinal = parkedFinal;
      tpPoleInspection.txtParkedSpec = parkedFinalComments;
      tpPoleInspection.guElectricalAuthoritySpecId = null;

      if (electricalAuthoritySpec != null && electricalAuthoritySpec != this.sharedService.EmptyGuid) {
        tpPoleInspection.guElectricalAuthoritySpecId = this.form.controls['guElectricalAuthoritySpecId'].value;
      }

      tpPoleInspection = await this.tpPoleInspectionService.updateTPPoleInspectionPromise(tpPoleInspection);

      tpPoleInspection = await this.tpPoleInspectionService.getTPPoleInspectionPromise(this.tpPoleInspection.rowguid);

      this.saving = false;
      this.showSpinner = false;

      this.finalInspectionUpdated.emit(tpPoleInspection);

    }

  }

  cancelFinalInspection() {
    this.finalInspectionCancelled.emit(true);
  }



}
