import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoAppUserPrinter } from '../business-objects/dto-app-user-printer.bo';

@Injectable()
export class dtoAppUserPrinterService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoAppUserPrinter(dtoAppUserPrinterId: string) {
		return this.http.get<dtoAppUserPrinter>(this._baseUrl + 'api/dtoAppUserPrinter/' + dtoAppUserPrinterId);
	}

	getdtoAppUserPrinterParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoAppUserPrinter[]>(this._baseUrl + 'api/dtoAppUserPrinter/GetdtoAppUserPrintersParamArray/', aParam);
	}

	getAlldtoAppUserPrinters() {
		return this.http.get<dtoAppUserPrinter[]>(this._baseUrl + 'api/dtoAppUserPrinter/GetAlldtoAppUserPrinters/');
	}

	//getAlldtodtoAppUserPrinters() {
	//	return this.http.get<dtodtoAppUserPrinter[]>(this._baseUrl + 'api/dtoAppUserPrinter/GetAlldtoAppUserPrinters/');
	//}

	createdtoAppUserPrinter(dtoAppUserPrinter: dtoAppUserPrinter) {
		return this.http.post<dtoAppUserPrinter>(this._baseUrl + 'api/dtoAppUserPrinter/CreatedtoAppUserPrinter/', dtoAppUserPrinter);
	}

	updatedtoAppUserPrinter(dtoAppUserPrinter: dtoAppUserPrinter) {
		return this.http.put<dtoAppUserPrinter>(this._baseUrl + 'api/dtoAppUserPrinter/UpdatedtoAppUserPrinter/', dtoAppUserPrinter);
	}

	deletedtoAppUserPrinter(dtoAppUserPrinterId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoAppUserPrinter/DeletedtoAppUserPrinter/' + dtoAppUserPrinterId);
	}

	async getdtoAppUserPrinterPromise(dtoAppUserPrinterId: string) {
		return new Promise<dtoAppUserPrinter>(resolve => {
			this.getdtoAppUserPrinter(dtoAppUserPrinterId).subscribe(dtoAppUserPrinter => {
				resolve(dtoAppUserPrinter);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppUserPrinter records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoAppUserPrinterParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoAppUserPrinter[]>(resolve => {
			this.getdtoAppUserPrinterParamArray(aParam).subscribe(dtoAppUserPrinters => {
				resolve(dtoAppUserPrinters);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppUserPrinter records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoAppUserPrinterPromise(dtoAppUserPrinter: dtoAppUserPrinter) {
		return new Promise<dtoAppUserPrinter>(resolve => {
			this.updatedtoAppUserPrinter(dtoAppUserPrinter).subscribe(dtoAppUserPrinter => {
				resolve(dtoAppUserPrinter);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoAppUserPrinter record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoAppUserPrinterPromise(dtoAppUserPrinter: dtoAppUserPrinter) {
		return new Promise<dtoAppUserPrinter>(resolve => {
			this.createdtoAppUserPrinter(dtoAppUserPrinter).subscribe(dtoAppUserPrinter => {
				resolve(dtoAppUserPrinter);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoAppUserPrinter record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoAppUserPrinterPromise(dtoAppUserPrinterId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoAppUserPrinter(dtoAppUserPrinterId).subscribe(dtoAppUserPrinter => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoAppUserPrinter record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}