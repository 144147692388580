import { Component, OnInit } from '@angular/core';
import { IOService } from '../_shared/services/io.service';
import { HttpClient } from '@angular/common/http';
import * as saveAs from 'file-saver';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
//import * as mime from 'mime-types';


@Component({
  selector: 'app-class-builder',
  templateUrl: './class-builder.component.html',
  styleUrls: ['./class-builder.component.css']
})
export class ClassBuilderComponent implements OnInit {

  constructor(private http: HttpClient, private ioService: IOService, private _snackBar: MatSnackBar) {


  }

  ngOnInit(): void {
  }


  onCreateClass() {
    console.log("before: in onCreateClass");
    let writeFile = { content: "Hello World" };

    console.log(JSON.stringify(writeFile));

    this.ioService.writeFile(writeFile).subscribe(data => {
      console.log("before: writeFile Call");
      //window.open("/temp-files/TestWritingFile.txt", "_blank");
      console.log("after: writeFile Call");
      this.openSnackBar("Created Classes", "Close", "right", "bottom", "productCodeSnackBar");
    }, err => {
      console.log(err);
      this.openSnackBarError("Error Creating Classes", "Close", "right", "bottom");
    });


    console.log("after: in onCreateClass");
  }

  onCreateTypeScriptServices() {
    let writeFile = { content: "Type Script Services" };
    this.ioService.createTypeScriptServices(writeFile).subscribe(data => {
      this.openSnackBar("Created Type Script Services", "Close", "right", "bottom", "productCodeSnackBar");
    }, err => {
      console.log(err);
      this.openSnackBarError("Error Type Script Services", "Close", "right", "bottom");
    });

  }

  onCreateWebApiControllers() {
    let writeFile = { content: "Web API Controllers" };
    this.ioService.createWebAPIControllers(writeFile).subscribe(data => {
      this.openSnackBar("Created Web API Controllers", "Close", "right", "bottom", "productCodeSnackBar");
    }, err => {
      console.log(err);
      this.openSnackBarError("Error Type Script Services", "Close", "right", "bottom");
    });

  }



  fileCreationCallBack() {
    console.log("in call back");

  }

  download(fileName) {

    let mimeTypeCheck = false  //mime.lookup(fileName);
    if (mimeTypeCheck == false) {
      console.log("invalid mimeType");
      return;
    }

    let mimeType: string = mimeTypeCheck;
    console.log("mimeType: " + mimeType);

    this.ioService.downloadFile(fileName).subscribe((response: any) => {
      //let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      //let blob: any = new Blob([response], { type: 'image/jpeg' });
      let blob: any = new Blob([response], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      //window.open(url);
      //saveAs(blob, 'testFile.txt');
      //saveAs(blob, 'testFile.jpg');
      saveAs(blob, fileName);
      this.openSnackBar("Saved File", "Close", "right", "bottom", "productCodeSnackBar");
      console.log("saved file");
    }), (error: any) => {
      console.log('Error downloading the file')
      this.openSnackBarError("Error downloading file", "Close", "right", "bottom");
    },
      () => console.info('File downloaded successfully');
  }

  onCreateClasses() {
    console.log("before: in onCreateClasses");
    let writeFile = { content: "Create Classes" };
    console.log(JSON.stringify(writeFile));

    this.ioService.createClasses(writeFile).subscribe(data => {
      console.log("created Classes");
      this.openSnackBar("Created Classes", "Close", "right", "bottom", "productCodeSnackBar");

    }, err => {
      this.openSnackBarError("Error Creating Classes", "Close", "right", "bottom");
      console.log(err);
    });

    console.log("after: in onCreateClasses");
  }

  onCreateDTOClasses() {
    console.log("before: in onCreateDTOClasses");
    let writeFile = { content: "Create DTO Classes" };
    console.log(JSON.stringify(writeFile));

    this.ioService.createDTOClasses(writeFile).subscribe(data => {
      console.log("created DTO Classes");
      this.openSnackBar("Created DTO CS Classes", "Close", "right", "bottom", "productCodeSnackBar");

    }, err => {
      this.openSnackBarError("Error Creating DTO Classes", "Close", "right", "bottom");
      console.log(err);
    });

    console.log("after: in onCreateDTOClasses");
  }


  onCreateDTOTSClasses() {
    console.log("before: in onCreateDTOTSClasses");
    let writeFile = { content: "Create DTO TS Classes" };
    console.log(JSON.stringify(writeFile));

    this.ioService.createDTOTSClasses(writeFile).subscribe(data => {
      console.log("created DTO TS Classes");
      this.openSnackBar("Created DTO TS Classes", "Close", "right", "bottom", "productCodeSnackBar");

    }, err => {
      this.openSnackBarError("Error Creating DTO TS Classes", "Close", "right", "bottom");
      console.log(err);
    });

    console.log("after: in onCreateDTOTSClasses");
  }


  onCreateDTORepoClasses() {
    console.log("before: in onCreateDTORepoClasses");
    let writeFile = { content: "Create DTO Repo Classes" };
    console.log(JSON.stringify(writeFile));

    this.ioService.createDTORepoClasses(writeFile).subscribe(data => {
      console.log("created DTO Repo Classes");
      this.openSnackBar("Created DTO Repo Classes", "Close", "right", "bottom", "productCodeSnackBar");

    }, err => {
      this.openSnackBarError("Error Creating DTO Repo Classes", "Close", "right", "bottom");
      console.log(err);
    });

    console.log("after: in onCreateDTORepoClasses");
  }

  onCreateDTOServiceClasses() {
    console.log("before: in onCreateDTOServiceClasses");
    let writeFile = { content: "Create DTO Service Classes" };
    console.log(JSON.stringify(writeFile));

    this.ioService.createDTOServiceClasses(writeFile).subscribe(data => {
      console.log("created DTO Service Classes");
      this.openSnackBar("Created DTO Service Classes", "Close", "right", "bottom", "productCodeSnackBar");

    }, err => {
      this.openSnackBarError("Error Creating DTO Service Classes", "Close", "right", "bottom");
      console.log(err);
    });

    console.log("after: in onCreateDTOServiceClasses");
  }


  openSnackBar(message: string, action: string, hPosition: MatSnackBarHorizontalPosition, vPosition: MatSnackBarVerticalPosition, panelclass: string) {
    this._snackBar.open(message, action, {
      horizontalPosition: hPosition,
      verticalPosition: vPosition,
      duration: 4000,
      panelClass: [panelclass]
    });
  }

  openSnackBarError(message: string, action: string, hPosition: MatSnackBarHorizontalPosition, vPosition: MatSnackBarVerticalPosition) {
    this._snackBar.open(message, action, {
      horizontalPosition: hPosition,
      verticalPosition: vPosition,
      duration: 4000,
      panelClass: ["snack-bar-error"]
    });
  }

}
