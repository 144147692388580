import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'textarea[autosize]'
})
export class TextAreaAutoSizeDirective {
  private _ngModel: any;

  public get ngModel(): any {
    return this._ngModel;
  }

  @Input()
  public set ngModel(value: any) {
    if (this._ngModel !== value) {
      this._ngModel = value;
      this.resize();
    }
  }

  @HostBinding('rows')
  public rows: number;

  @Input()
  public minRows: number = 1;

  constructor() { }

  private resize() {
    this.rows = !this._ngModel ? this.minRows : this.ngModel.split('\n').length;
  }

}
