import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { dtoHaulingCompanyDriver } from '../../_shared/business-objects/dto-hauling-company-driver.bo';
import { HaulingCompanyDriver } from '../../_shared/business-objects/hauling-company-driver.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoHaulingCompanyDriverService } from '../../_shared/services/dto-hauling-company-driver.service';

@Component({
  selector: 'app-hauling-company-driver-selection',
  templateUrl: './hauling-company-driver-selection.component.html',
  styleUrls: ['./hauling-company-driver-selection.component.css']
})
export class HaulingCompanyDriverSelectionComponent implements OnInit {
  @Input() haulingCompanyId: string;
  @Input() selectedHaulingCompanyDriver: HaulingCompanyDriver;
  @Output() haulingCompanyDriverSelectionEvent = new EventEmitter<HaulingCompanyDriver[]>();

  @ViewChild('guHaulingCompanyDriverId') haulingCompanyDriver: MatSelectionList;
  form: FormGroup;

  public haulingCompanyDrivers: dtoHaulingCompanyDriver[] = [];
  public haulingCompanyDriversAll: dtoHaulingCompanyDriver[] = [];


  constructor(private dtoHaulingCompanyDriverService: dtoHaulingCompanyDriverService, private alertService: AlertService, private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      guHaulingCompanyDriverId: [''],
    });


    this.loadData();

  }

  public async loadData() {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];


    if (this.haulingCompanyId != "") {
      aParam.push(new SQLParamArray("guHaulingCompanyId", this.haulingCompanyId));
      aParamList.push(aParam);

    }

    this.haulingCompanyDriversAll = await this.dtoHaulingCompanyDriverService.getdtoHaulingCompanyDriverParamArrayPromise(aParamList);
    this.haulingCompanyDriversAll.sort((a, b) => { return (a.txtFirstName > b.txtFirstName ? 1 : -1) });

    let blankHaulingCompany: dtoHaulingCompanyDriver = new dtoHaulingCompanyDriver();
    blankHaulingCompany.rowguid = null;
    blankHaulingCompany.txtFirstName = "None";

    this.haulingCompanyDriversAll.splice(0, 0, blankHaulingCompany);

    this.haulingCompanyDrivers = this.haulingCompanyDriversAll;


    //On first load show CHH Vehicles.
    //this.haulingCompanyDrivers = this.haulingCompanyDrivers.filter(a => (a.blnCHHVehicle == true && a.guMobilePlantEquipmentId != null && a.txtMobilePlantCategoryName.toLowerCase() == "truck") || a.txtName == "None");

    //console.log("haulingCompanies: ", this.haulingCompanyDrivers);

    console.log("selectedHaulingCompanyDriver: ", this.selectedHaulingCompanyDriver);

    if (this.selectedHaulingCompanyDriver == null) {
      for (let i = 0; i <= this.haulingCompanyDrivers.length - 1; i++) {
        console.log("this.haulingCompanyDrivers[i].blnDefaultDriver: " + this.haulingCompanyDrivers[i].blnDefaultDriver);
        if (this.haulingCompanyDrivers[i].blnDefaultDriver == true) {
          this.selectedHaulingCompanyDriver = this.haulingCompanyDrivers[i];
          this.haulingCompanyDriverSelectionEvent.emit([this.selectedHaulingCompanyDriver]);
          break;
        }
      }
    }

  }

  //filterHaulingCompany(haulingCompanyFilter: string, chhVehicles: boolean) {
  //  this.haulingCompanyDrivers = this.haulingCompanyDriversAll;

  //  console.log("chh vehicles: ", chhVehicles);
  //  this.haulingCompanyDrivers = this.haulingCompanyDrivers.filter(a => ((a.txtName.toLowerCase().indexOf(haulingCompanyFilter.toLowerCase()) > -1)) || a.txtName == "None");

  //  if (chhVehicles == true) {
  //    this.haulingCompanyDrivers = this.haulingCompanyDrivers.filter(a => (a.blnCHHVehicle == chhVehicles && a.guMobilePlantEquipmentId != null && a.txtMobilePlantCategoryName.toLowerCase() == "truck") || a.txtName == "None");
  //  }

  //}

  haulingCompanyDriverSelect(haulingCompanyDriver: HaulingCompanyDriver[]) {
    //console.log("haulingCompanyDriver: ", haulingCompanyDriver);
    this.selectedHaulingCompanyDriver = haulingCompanyDriver[0];

    this.haulingCompanyDriverSelectionEvent.emit(haulingCompanyDriver);
  }

}
