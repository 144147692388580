import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';
import { dtoCustomer } from '../../_shared/business-objects/dto-customer.bo';
import { CustomerSelectComponent } from '../customer-select/customer-select.component';

@Component({
  selector: 'app-customer-update-dialog',
  templateUrl: './customer-update-dialog.component.html',
  styleUrl: './customer-update-dialog.component.scss'
})
export class CustomerUpdateDialogComponent {
  customerId: string;
  customerName: string;
  customerObj: dtoCustomer;

  showCustomerContact: boolean = false;
  showCustomer: boolean = true;

  customerUpdateHeading: string = "Update Customer";

  customer: dtoCustomer;

  constructor(private dialogRef: MatDialogRef<CustomerSelectComponent>, @Inject(MAT_DIALOG_DATA) private data: { selectedCustomer: dtoCustomer }) {

    this.customer = data.selectedCustomer;

  }

  ngOnInit(): void {
  }

  customerUpdated(obJ: { customer: dtoCustomer, type: string }) {

    console.log("customer: ", obJ.customer, "type: ", obJ.type);

    this.customerObj = obJ.customer;

    console.log("this.customerObj: ", this.customerObj);

    if (obJ.type == "UpdateCustomerContact") {
      this.customerId = obJ.customer.rowguid;
      this.customerName = obJ.customer.txtName;
      this.showCustomer = false;
      this.showCustomerContact = true;
      this.customerUpdateHeading = "Update Customer Contact";
    } else {
      this.dialogRef.close({ status: "Saved", customer: obJ.customer });
    }

  }

  customerCancelled(cancelled: boolean) {

    this.dialogRef.close({ status: "Cancel", customer: null });

  }

  customerContactCreated(obJ: [CustomerContact, string]) {

    console.log("obJ: ", obJ);
    console.log("customer: ", obJ[0], "type: ", obJ[1]);

    if (obJ[1] == "createaddnew") {
      this.showCustomerContact = false;
      setTimeout(() => { this.showCustomerContact = true }, 500);
      this.customerUpdateHeading = "Update Customer Contact";
    } else {
      this.dialogRef.close({ status: "Saved", customer: this.customerObj });
    }

  }

  customerContactCancelled(cancelled: boolean) {
    console.log("customerContactCancelled: ", this.customerObj);

    this.dialogRef.close({ status: "CancelContact", customer: this.customerObj });
  }

  setShowCustomer() {
    this.showCustomer = true;
    this.showCustomerContact = false;
  }

  setShowContact() {
    this.showCustomerContact = true;
    this.showCustomer = false;
  }

}
