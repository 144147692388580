export interface BoardTallyRecordMachineRun
{
	dteLastUpdated: Date;
	guBoardTallyRecordId: string;
	guMachineRunId: string;
	rowguid: string;
}

export class BoardTallyRecordMachineRun
{
	dteLastUpdated: Date;
	guBoardTallyRecordId: string;
	guMachineRunId: string;
	rowguid: string;
}

