import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TruncateStringPipe } from '../../../pipes/truncateString.pipe';

@Component({
  selector: 'app-truncate-string',
  templateUrl: './truncate-string.component.html',
  styleUrl: './truncate-string.component.scss'
})
export class TruncateStringComponent implements OnInit {

  @Input() truncateString: string;
  @Input() truncateLength: number;

  constructor(private sanitizer: DomSanitizer) {

  }

  ngOnInit() {

    this.truncateString = String(this.truncateString);

  }


  setTruncateStringAttribute(div: HTMLDivElement, truncateString: string) {

    if (truncateString == null || truncateString.length < this.truncateLength) {
      return;
    }

    let ariaExpanded = div.getAttribute('aria-expanded');

    if (ariaExpanded == 'true' || ariaExpanded == null) {
      div.setAttribute('aria-expanded', 'false');
    }

    if (ariaExpanded == 'false') {
      div.setAttribute('aria-expanded', 'true');
    }

    window.event.stopPropagation();


  }

  getTruncateStringAttribute(div: HTMLDivElement, comments: string) {

    let ariaExpanded = div.getAttribute('aria-expanded');

    if (ariaExpanded == 'true' || ariaExpanded == null) {
      return comments;
    }

    if (ariaExpanded == 'false') {
      div.setAttribute('aria-expanded', 'true');
      //let domSan: DomSanitizer;
      let trunPipe: TruncateStringPipe = new TruncateStringPipe(this.sanitizer);
      return trunPipe.transform(comments, 50);
    }



  }


}
