import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Orders } from '../business-objects/orders.bo';
import { OrderItemProcess } from '../business-objects/order-item-process.bo';
import { SharedService } from './shared.service';

@Injectable()
export class OrderItemProcessService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService) {
    this._baseUrl = baseUrl;
  }

  getOrderItem(orderItemId: string) {
    return this.http.get<OrderItemProcess>(this._baseUrl + 'api/orderitem/' + orderItemId);
  }

  getOrderItemProcessesByProductCode(gProductCodeId: string) {
    return this.http.get<OrderItemProcess[]>(this._baseUrl + 'api/OrderItemProcess/GetOrderItemProcessesByProductCode/' + gProductCodeId);
  }



}
