import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoProductProfile } from '../business-objects/dto-product-profile.bo';

@Injectable()
export class dtoProductProfileService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoProductProfile(dtoProductProfileId: string) {
		return this.http.get<dtoProductProfile>(this._baseUrl + 'api/dtoProductProfile/' + dtoProductProfileId);
	}

	getdtoProductProfileParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoProductProfile[]>(this._baseUrl + 'api/dtoProductProfile/GetdtoProductProfilesParamArray/', aParam);
	}

	getAlldtoProductProfiles() {
		return this.http.get<dtoProductProfile[]>(this._baseUrl + 'api/dtoProductProfile/GetAlldtoProductProfiles/');
	}

	//getAlldtodtoProductProfiles() {
	//	return this.http.get<dtodtoProductProfile[]>(this._baseUrl + 'api/dtoProductProfile/GetAlldtoProductProfiles/');
	//}

	createdtoProductProfile(dtoProductProfile: dtoProductProfile) {
		return this.http.post<dtoProductProfile>(this._baseUrl + 'api/dtoProductProfile/CreatedtoProductProfile/', dtoProductProfile);
	}

	updatedtoProductProfile(dtoProductProfile: dtoProductProfile) {
		return this.http.put<dtoProductProfile>(this._baseUrl + 'api/dtoProductProfile/UpdatedtoProductProfile/', dtoProductProfile);
	}

	deletedtoProductProfile(dtoProductProfileId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoProductProfile/DeletedtoProductProfile/' + dtoProductProfileId);
	}

  async getdtoAllProductProfile() {
    return new Promise<dtoProductProfile[]>(resolve => {
      this.getAlldtoProductProfiles().subscribe(dtoProductProfile => {
        resolve(dtoProductProfile);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoProductProfile records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getdtoProductProfilePromise(dtoProductProfileId: string) {
		return new Promise<dtoProductProfile>(resolve => {
			this.getdtoProductProfile(dtoProductProfileId).subscribe(dtoProductProfile => {
				resolve(dtoProductProfile);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoProductProfile records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoProductProfileParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoProductProfile[]>(resolve => {
			this.getdtoProductProfileParamArray(aParam).subscribe(dtoProductProfiles => {
				resolve(dtoProductProfiles);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoProductProfile records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoProductProfilePromise(dtoProductProfile: dtoProductProfile) {
		return new Promise<dtoProductProfile>(resolve => {
			this.updatedtoProductProfile(dtoProductProfile).subscribe(dtoProductProfile => {
				resolve(dtoProductProfile);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoProductProfile record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoProductProfilePromise(dtoProductProfile: dtoProductProfile) {
		return new Promise<dtoProductProfile>(resolve => {
			this.createdtoProductProfile(dtoProductProfile).subscribe(dtoProductProfile => {
				resolve(dtoProductProfile);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoProductProfile record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoProductProfilePromise(dtoProductProfileId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoProductProfile(dtoProductProfileId).subscribe(dtoProductProfile => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoProductProfile record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
