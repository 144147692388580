import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { dtoBoardTallyRecordHistory } from '../../_shared/business-objects/dto-board-tally-record-history.bo';

@Component({
  selector: 'app-board-tally-record-history-list',
  templateUrl: './board-tally-record-history-list.component.html',
  styleUrl: './board-tally-record-history-list.component.scss'
})
export class BoardTallyRecordHistoryListComponent implements OnInit {
  @ViewChild('tblBoardTallyRecordHistory', { static: false }) tblDeliveryDocket: MatTable<dtoBoardTallyRecordHistory>;


  @Input() datasource: MatTableDataSource<dtoBoardTallyRecordHistory>;
  @Input() showSpinner: boolean;
  @Output() nextPage = new EventEmitter<{nextPage: number, pageSize: number}>();

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  totalRecords = 0;
  pageSize = 10;
  pageIndex = 0;

  displayedColumns: string[] = ['dteDateCreated', 'fltQuantity', 'intQuantityUoM', 'fltTotalLM', 'intPieces', 'intNoOfLayers', 'txtLocation', 'blnArchived', 'dteArchivedDate', 'txtArchiveComments', 'txtDescription', 'txtEmployeeName', 'dteCreated', 'txtChangedData', 'txtBoardTallyRecordComments', 'txtStockContainerComments', 'dteDateReturned'];

  constructor() {

  }

  ngOnInit() {

    this.pageIndex = 1;
    this.pageSize = 5;

  }

  setPaginator(length: number) {
    //console.log("about to set paginator");

    if (this.datasource == null) {
      this.datasource = new MatTableDataSource<dtoBoardTallyRecordHistory>();
    }

    //console.log("this.datasource", this.datasource);
    //console.log("this.datasource.paginator", this.datasource.paginator);
    //console.log("this.paginator", this.paginator);


    if (this.datasource != null && this.paginator != null) {
      //this.paginator.pageSizeOptions.length = length;
      //this.paginator.pageSizeOptions = [6, 11, 51, 101, length]
      this.totalRecords = length;
      this.datasource.paginator = this.paginator;
      //console.log("this.totalRecords", this.totalRecords);
      //console.log("this.paginator.pageSizeOptions.length", this.paginator.length);


    }
    //this.dataSource.sort = this.sort;
  }


  getPagedData() {
    const search = {
      // ... set filters here
    };

    //this.searching = true;
    //this.service.search(search).subscribe({
    //  next: ((results) => {
    //    this.totalRecords = results?.length ? results[0].totalRecords : 0;
    //    this.dataSource.data = results || [];
    //  }),
    //  complete: () => this.searching = false,
    //  error: () => this.searching = false,
    //});
  }


  pageChangeEvent(event: PageEvent) {

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    //this.nextPage.emit({ nextPage: this.pageIndex, pageSize: this.pageSize });

    //this.getPagedData();
  }

}
