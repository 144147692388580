<div class="app-user-container" id="appUserContainer">
  <form class="example-form app-user-container-form" [formGroup]="form">

    <h1>App Users</h1>

    <mat-tab-group color="accent" class="chh-mat-tab-group app-user-mat-tab-group" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabAppUsers>
      <mat-tab label="App Users" class="tab-label app-user-search-tab">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>User Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. john@coffshardwoods.com.au..." value="" type="text" formControlName="txtSearchUserName" (keydown.enter)="searchAppUsers()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-3">
            <div class="button-container">
              <button class="search-app-user-docket-button search-button" type="button" mat-flat-button color="primary" (click)="searchAppUsers()">Search</button>
            </div>
          </div>

        </div>

        <mat-divider></mat-divider>

        <div class="app-user-list-app-container">
          <app-app-user-list #appAppUserList [datasource]="appUsers" [totalTonne]="totalTonne" (editAppUserEvent)="editAppUser($event)" (addAppUserItemsEvent)="addAppUserItems($event)" (updatedAppUserEvent)="updatedAppUser($event)" (removeAppUserEvent)="removeAppUser($event)" (searchAppUsers)="searchAppUsers()" [columnBarPage]="'App Users'" *ngIf="appUsers != null"></app-app-user-list>
        </div>

      </mat-tab>



      <mat-tab label="Search Options" class="tab-label search-option-tab">


        <div class="search-options">

          <div class="search-option-row-1">
            <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>First Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. John" value="" type="text" id="txtSearchFirstName" formControlName="txtSearchFirstName" (keydown.enter)="searchAppUsers()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-2">
            <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>Last Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Smith" value="" type="text" id="txtSearchLastName" formControlName="txtSearchLastName" (keydown.enter)="searchAppUsers()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-3">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Created date from</mat-label>
              <input id="dteSearchCreatedDateFrom" class="date-input app-user-date-search" #searchCreatedDateFrom matInput [matDatepicker]="statuspickerfrom" formControlName="dteSearchCreatedDateFrom">
              <mat-datepicker-toggle matSuffix [for]="statuspickerfrom"></mat-datepicker-toggle>
              <mat-datepicker #statuspickerfrom></mat-datepicker>
            </mat-form-field>
          </div>


          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Created date to</mat-label>
              <input id="dteSearchCreatedDateTo" class="date-input app-user-date-search" #searchCreatedDateTo matInput [matDatepicker]="statuspickerto" formControlName="dteSearchCreatedDateTo">
              <mat-datepicker-toggle matSuffix [for]="statuspickerto"></mat-datepicker-toggle>
              <mat-datepicker #statuspickerto></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-7">
            <button class="search-option-app-user-button" type="button" mat-flat-button color="primary" (click)="searchAppUsers()">Search</button>
          </div>

          <mat-divider></mat-divider>
        </div>
      </mat-tab>

      <mat-tab label="Create App User" class="tab-label">
        <div class="create-app-user-container">
          <app-app-user-create [inDialog]="false" (appUserCreated)="appUserCreated($event)" (appUserCancelled)="appUserCancelled($event)" *ngIf="selectedTab == eAppUserTabs.CreateAppUser"></app-app-user-create>
        </div>
      </mat-tab>

      <mat-tab label="Update App User" class="tab-label">
        <div class="update-app-user-container">
          <app-app-user-update [appUser]="appUser" (appUserUpdated)="appUserUpdated($event)" (appUserCancelled)="appUserCancelled($event)" *ngIf="selectedTab == eAppUserTabs.UpdateAppUser"></app-app-user-update>
        </div>
      </mat-tab>

    </mat-tab-group>
  </form>
</div>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
