<div class="hauling-company-selection-container">
  <div class="hauling-company-selection-form" [formGroup]="form">
    <div class="hauling-company">
      <mat-form-field class="chh-form-field">
        <div class="hauling-company-input-container">
          <input matInput class="hauling-company-input" placeholder="Filter hauler by name" placeholderLabel="i.e. XN54....." value="" type="text" #haulingCompanyFilter (keyup)="filterHaulingCompany(haulingCompanyFilter.value, chkCCHVehicles.checked)" (focus)="haulingCompanyFilter.select()">
          <br /><br />
        </div>
      </mat-form-field>
      <div class="chh-vehicle-checkbox"><mat-checkbox class="example-margin" #chkCCHVehicles id="chkCCHVehicles" [value]="true" [checked]="true" (change)="filterHaulingCompany(haulingCompanyFilter.value, chkCCHVehicles.checked)">CHH Vehicles</mat-checkbox></div>
      <mat-selection-list class="hauling-company-select" #guHaulingCompanyId [multiple]="false" id="guHaulingCompanyId" formControlName="guHaulingCompanyId" (selectionChange)="haulingCompanySelect(guHaulingCompanyId._value)">
        <mat-list-option *ngFor="let haulingCompany of haulingCompanies" [value]="haulingCompany" [selected]="selectedHaulingCompany == null ? haulingCompany.rowguid == null : haulingCompany.rowguid == selectedHaulingCompany.rowguid">
          {{haulingCompany.txtName}}
        </mat-list-option>
      </mat-selection-list>
      <br />
    </div>
  </div>
</div>
