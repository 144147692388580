import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { AfterContentInit, AfterViewInit } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { AuthService } from '../../account/auth.service';
import { dtoHaulingCompany } from '../../_shared/business-objects/dto-hauling-company.bo';
import { Employee } from '../../_shared/business-objects/employee.bo';
import { HaulingCompanyDriver } from '../../_shared/business-objects/hauling-company-driver.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoHaulingCompanyService } from '../../_shared/services/dto-hauling-company.service';
import { EmployeeService } from '../../_shared/services/employee.service';
import { FormService } from '../../_shared/services/form.service';
import { HaulingCompanyDriverService } from '../../_shared/services/hauling-company-driver.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-hauling-company-driver-update',
  templateUrl: './hauling-company-driver-update.component.html',
  styleUrls: ['./hauling-company-driver-update.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class HaulingCompanyDriverUpdateComponent implements OnInit, AfterContentInit {

  @ViewChild('txtFirstName') txtFirstName: ElementRef;
  @ViewChild('txtLastName') txtLastName: ElementRef;
  @ViewChild('guHaulingCompanyId') guHaulingCompanyId: MatSelectionList;

  form: FormGroup;

  @Input() haulingCompanyDriver: HaulingCompanyDriver;
  @Input() selectedHaulingCompany: dtoHaulingCompany;
  @Input() selectedEmployee: Employee;
  @Output() haulingCompanyDriverUpdated = new EventEmitter<HaulingCompanyDriver>();
  @Output() haulingCompanyDriverCancelled = new EventEmitter<boolean>();


  public customersFilterCtrl: FormControl = new FormControl();
  public customerContactsFilterCtrl: FormControl = new FormControl();
  public haulersFilterCtrl: FormControl = new FormControl();

  public employees: Employee[];
  public haulers: dtoHaulingCompany[];

  public employeesAll: Employee[];
  public haulersAll: dtoHaulingCompany[];

  showSpinner: boolean

  constructor(private formBuilder: FormBuilder, private formService: FormService, private dtoHaulerService: dtoHaulingCompanyService, private sharedService: SharedService
    , private alertService: AlertService, private authService: AuthService
    , private haulingCompanyDriverService: HaulingCompanyDriverService, private employeeService: EmployeeService) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      guHaulingCompanyId: [''],
      guEmployeeId: [''],
      txtFirstName: [''],
      txtLastName: [''],
      txtNickName: [''],
      blnActive: [''],
      blnDefaultDriver: [''],
      txtComments: ['']
    });

    this.showSpinner = false;

    this.loadData();

    if (this.haulingCompanyDriver != null) {
      this.form.controls['guHaulingCompanyId'].setValue([this.haulingCompanyDriver.guHaulingCompanyId]);
      this.form.controls['guEmployeeId'].setValue([this.haulingCompanyDriver.guEmployeeId]);
      this.form.controls['txtFirstName'].setValue(this.haulingCompanyDriver.txtFirstName);
      this.form.controls['txtLastName'].setValue(this.haulingCompanyDriver.txtLastName);
      this.form.controls['txtNickName'].setValue(this.haulingCompanyDriver.txtNickName);
      this.form.controls['blnActive'].setValue(this.haulingCompanyDriver.blnActive);
      this.form.controls['blnDefaultDriver'].setValue(this.haulingCompanyDriver.blnDefaultDriver);
      this.form.controls['txtComments'].setValue(this.haulingCompanyDriver.txtComments);
    }

  }

  ngAfterContentInit() {
      //this.guHaulingCompanyId._value = [this.haulingCompanyDriver.guHaulingCompanyId];

    setTimeout(() => {
      //this.form.controls['guHaulingCompanyId'].setValue(this.haulingCompanyDriver.guHaulingCompanyId);
    }, 1000);

  }


  async loadData() {

    this.getEmployees();
    await this.getHaulers();

    console.log("selected hauling company: ", this.selectedHaulingCompany);
    console.log("selected Employee: ", this.selectedEmployee);

    this.filterHaulingCompany("", this.selectedHaulingCompany.blnCHHVehicle);
  }

  setFromEmployee(employee: MatSelectionListChange) {

    //let emp: Employee = employee.options[0].value

    console.log(employee.options[0].value);
    let emp: Employee = this.employeesAll.find((e) => {
      return e.rowguid == employee.options[0].value
    });

    if (emp != null) {
      this.form.controls['txtFirstName'].setValue(emp.txtFirstName);
      this.form.controls['txtLastName'].setValue(emp.txtLastName);
    }


    console.log("emp: ", emp);

  }


  async getHaulers() {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];
    let haulers = await this.dtoHaulerService.getdtoHaulingCompanyParamArrayPromise(paramArrayList);
    if (haulers == null) {
      return;
    }

    haulers.sort((a,b) => { return a.txtName > b.txtName ? 1 : -1});

    this.haulers = haulers;
    this.haulersAll = this.haulers;
  }

  async getEmployees() {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];
    //Filter on Position Name
    paramArray.push(new SQLParamArray("txtName", "Drivers", SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 2));  // Sub Query 2 is UserPositon > Positon
    paramArrayList.push(paramArray);

    let employees = await this.employeeService.getEmployeeParamArrayPromise(paramArrayList);
    if (employees == null) {
      return;
    }

    this.employees = employees;
    this.employeesAll = this.employees;
  }

  async filterHaulingCompany(name: string, chhVehicles: boolean) {
    this.haulers = this.haulersAll;

    console.log("chh vehicles: ", chhVehicles);
    this.haulers = this.haulers.filter(a => ((a.txtName.toLowerCase().indexOf(name.toLowerCase()) > -1)) || a.txtName == "None");

    if (chhVehicles == true) {
      this.haulers = this.haulers.filter(a => (a.blnCHHVehicle == chhVehicles && a.guMobilePlantEquipmentId != null && a.txtMobilePlantCategoryName.toLowerCase() == "truck") || a.txtName == "None");
    }

  }

  async filterEmployee(name: string) {

    this.employees = this.employeesAll;

    this.employees = this.employees.filter(a => ((a.txtFirstName.toLowerCase().indexOf(name.toLowerCase()) > -1)) || ((a.txtLastName.toLowerCase().indexOf(name.toLowerCase()) > -1)));


  }


  async updateHaulingCompanyDriver() {

    let haulerId: string = this.form.controls['guHaulingCompanyId'].value;
    let employeeId: string = this.form.controls['guEmployeeId'].value;
    let firstName: string = this.form.controls['txtFirstName'].value;
    let lastName: string = this.form.controls['txtLastName'].value;
    let active: boolean = this.form.controls['blnActive'].value;
    let defaultDriver: boolean = this.form.controls['blnDefaultDriver'].value;
    let comments: string = this.form.controls['txtComments'].value;

    console.log("first Name: ", firstName);
    console.log("employeeId: ", this.form.controls['guEmployeeId'].value);

    if (haulerId == null || haulerId == "") {
      this.alertService.openSnackBar("Please select a hauling company.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (firstName == null || firstName == "") {
      this.alertService.openSnackBar("Please enter at least a first name.", "Close", "center", "bottom", "", 3000);
      return;
    }


    this.haulingCompanyDriver.txtFirstName = firstName;
    this.haulingCompanyDriver.txtLastName = lastName;
    this.haulingCompanyDriver.txtComments = comments;
    this.haulingCompanyDriver.blnActive = active;
    this.haulingCompanyDriver.blnDefaultDriver = defaultDriver;
    this.haulingCompanyDriver.guHaulingCompanyId = haulerId[0];
    this.haulingCompanyDriver.guEmployeeId = "";
    if (employeeId != null) {
      this.haulingCompanyDriver.guEmployeeId = employeeId[0];
    }

    this.showSpinner = true;

    this.haulingCompanyDriver = await this.haulingCompanyDriverService.updateHaulingCompanyDriverPromise(this.haulingCompanyDriver);

    if (this.haulingCompanyDriver != null) {
      console.log(this.haulingCompanyDriver.rowguid);
      this.haulingCompanyDriver = await this.haulingCompanyDriverService.getHaulingCompanyDriverPromise(this.haulingCompanyDriver.rowguid);

      this.alertService.openSnackBar("The driver has been updated", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;
      this.haulingCompanyDriverUpdated.emit(this.haulingCompanyDriver);
    }


    if (this.haulingCompanyDriver == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem updating the driver", "Close", "center", "bottom", 3000, true, "There was a problem creating the driver");
    }



  }

  cancelHaulingCompanyDriver() {
    this.haulingCompanyDriverCancelled.emit(true);
  }
}
