import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoBoardTallyRecordHistory } from '../business-objects/dto-board-tally-record-history.bo';

@Injectable()
export class dtoBoardTallyRecordHistoryService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoBoardTallyRecordHistory(dtoBoardTallyRecordHistoryId: string) {
		return this.http.get<dtoBoardTallyRecordHistory>(this._baseUrl + 'api/dtoBoardTallyRecordHistory/' + dtoBoardTallyRecordHistoryId);
	}

	getdtoBoardTallyRecordHistoryParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoBoardTallyRecordHistory[]>(this._baseUrl + 'api/dtoBoardTallyRecordHistory/GetdtoBoardTallyRecordHistoryParamArray/', aParam);
	}

	getAlldtoBoardTallyRecordHistorys() {
		return this.http.get<dtoBoardTallyRecordHistory[]>(this._baseUrl + 'api/dtoBoardTallyRecordHistory/GetAlldtoBoardTallyRecordHistory/');
	}

	//getAlldtodtoBoardTallyRecordHistorys() {
	//	return this.http.get<dtodtoBoardTallyRecordHistory[]>(this._baseUrl + 'api/dtoBoardTallyRecordHistory/GetAlldtoBoardTallyRecordHistory/');
	//}

	createdtoBoardTallyRecordHistory(dtoBoardTallyRecordHistory: dtoBoardTallyRecordHistory) {
		return this.http.post<dtoBoardTallyRecordHistory>(this._baseUrl + 'api/dtoBoardTallyRecordHistory/CreatedtoBoardTallyRecordHistory/', dtoBoardTallyRecordHistory);
	}

	updatedtoBoardTallyRecordHistory(dtoBoardTallyRecordHistory: dtoBoardTallyRecordHistory) {
		return this.http.put<dtoBoardTallyRecordHistory>(this._baseUrl + 'api/dtoBoardTallyRecordHistory/UpdatedtoBoardTallyRecordHistory/', dtoBoardTallyRecordHistory);
	}

	deletedtoBoardTallyRecordHistory(dtoBoardTallyRecordHistoryId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoBoardTallyRecordHistory/DeletedtoBoardTallyRecordHistory/' + dtoBoardTallyRecordHistoryId);
	}

	async getdtoBoardTallyRecordHistoryPromise(dtoBoardTallyRecordHistoryId: string) {
		return new Promise<dtoBoardTallyRecordHistory>(resolve => {
			this.getdtoBoardTallyRecordHistory(dtoBoardTallyRecordHistoryId).subscribe(dtoBoardTallyRecordHistory => {
				resolve(dtoBoardTallyRecordHistory);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoBoardTallyRecordHistory records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoBoardTallyRecordHistoryByBoardTallyIdPromise(boardTallyRecordId: string) {

    if (boardTallyRecordId ==  null || boardTallyRecordId == "") {
      this.alertService.openSnackBarDefault("There was no board tally record ID supplied");
      return;
    }

    return new Promise<dtoBoardTallyRecordHistory[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guBoardTallyRecordId", boardTallyRecordId));
      aParamList.push(aParam);

      this.getdtoBoardTallyRecordHistoryParamArray(aParamList).subscribe({
        next: (dtoBoardTallyRecordHistory: dtoBoardTallyRecordHistory[]) => {
          resolve(dtoBoardTallyRecordHistory);
        },
        error: (err) => {
          this.alertService.openSnackBarError("Error getting dtoBoardTallyRecordHistory records", "Close", "center", "bottom", 4000, true, err.message);
          resolve(null);
        }
      });
    });
  }

	async getdtoBoardTallyRecordHistoryParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoBoardTallyRecordHistory[]>(resolve => {
			this.getdtoBoardTallyRecordHistoryParamArray(aParam).subscribe(dtoBoardTallyRecordHistory => {
				resolve(dtoBoardTallyRecordHistory);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoBoardTallyRecordHistory records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoBoardTallyRecordHistoryPromise(dtoBoardTallyRecordHistory: dtoBoardTallyRecordHistory) {
		return new Promise<dtoBoardTallyRecordHistory>(resolve => {
			this.updatedtoBoardTallyRecordHistory(dtoBoardTallyRecordHistory).subscribe(dtoBoardTallyRecordHistory => {
				resolve(dtoBoardTallyRecordHistory);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoBoardTallyRecordHistory record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoBoardTallyRecordHistoryPromise(dtoBoardTallyRecordHistory: dtoBoardTallyRecordHistory) {
		return new Promise<dtoBoardTallyRecordHistory>(resolve => {
			this.createdtoBoardTallyRecordHistory(dtoBoardTallyRecordHistory).subscribe(dtoBoardTallyRecordHistory => {
				resolve(dtoBoardTallyRecordHistory);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoBoardTallyRecordHistory record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoBoardTallyRecordHistoryPromise(dtoBoardTallyRecordHistoryId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoBoardTallyRecordHistory(dtoBoardTallyRecordHistoryId).subscribe(dtoBoardTallyRecordHistory => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoBoardTallyRecordHistory record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
