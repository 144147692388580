import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Component, Input, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { OrdersDeliveryRate } from '../../_shared/business-objects/orders-delivery-rate.bo';
import { Orders } from '../../_shared/business-objects/orders.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { OrdersDeliveryRateService } from '../../_shared/services/orders-delivery-rate.service';
import { dtoOrdersDeliveryRateService } from '../../_shared/services/dto-orders-delivery-rate.service';
import { DeliveryDocketListComponent } from '../delivery-docket-list/delivery-docket-list.component';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { dtoOrdersDeliveryRate } from '../../_shared/business-objects/dto-orders-delivery-rate.bo';
import { DeliveryDocketService } from '../../_shared/services/delivery-docket.service';
import { AlertService } from '../../_shared/services/alert.service';

@Component({
  selector: 'app-delivery-docket-delivery-rate',
  templateUrl: './delivery-docket-delivery-rate.component.html',
  styleUrls: ['./delivery-docket-delivery-rate.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class DeliveryDocketDeliveryRateComponent implements OnInit {

  form: FormGroup;

  @Output() ordersDeliveryRateSelected = new EventEmitter<OrdersDeliveryRate>();

  @Input() deliveryDocket: DeliveryDocket;
  @Input() orderId: string;

  deliveryRates: OrdersDeliveryRate[];
  deliveryRateOriginal: OrdersDeliveryRate[];
  showSpinner: boolean = false;

  public filtereddeliveryRates: ReplaySubject<OrdersDeliveryRate[]> = new ReplaySubject<OrdersDeliveryRate[]>(1);

  public deliveryRatesFilterCtrl: FormControl = new FormControl();

  constructor(private formBuilder: FormBuilder, private ordersDeliveryRateService: OrdersDeliveryRateService
    , private dtoOrdersDeliveryRateService: dtoOrdersDeliveryRateService
    , private deliveryDocketService: DeliveryDocketService, private alertService: AlertService  ) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      guOrdersDeliveryRateId: [''],
    });

    this.loadData()

  }


  async loadData() {

    this.getDeliveryRates();

  }


  async getDeliveryRates() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guOrderId", this.orderId));
    aParamList.push(aParam);

    this.deliveryRates = await this.dtoOrdersDeliveryRateService.getdtoOrdersDeliveryRateParamArrayPromise(aParamList);


  }


  async selectDeliveryRate(orderDeliveryRateId: string) {


    
  }

}
