import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { withRequestsMadeViaParent } from '@angular/common/http';
import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ProductCodeBuilderDialogComponent } from '../../../product-codes/product-code-builder-dialog/product-code-builder-dialog.component';
import { dtoCustomer } from '../../../_shared/business-objects/dto-customer.bo';
import { dtoGrade } from '../../../_shared/business-objects/dto-grade.bo';
import { dtoKeyValue } from '../../../_shared/business-objects/dto-int-key-value.bo';
import { dtoOrders } from '../../../_shared/business-objects/dto-orders.bo';
import { dtoProductCode } from '../../../_shared/business-objects/dto-product-code.bo';
import { dtoProductProfile } from '../../../_shared/business-objects/dto-product-profile.bo';
import { dtoProductType } from '../../../_shared/business-objects/dto-product-type.bo';
import { dtoSpecies } from '../../../_shared/business-objects/dto-species.bo';
import { OrderItem } from '../../../_shared/business-objects/order-item.bo';
import { Orders } from '../../../_shared/business-objects/orders.bo';
import { DBFieldFunction, SQLParamArray } from '../../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../../_shared/services/alert.service';
import { SQLParamService } from '../../../_shared/services/app-services/sql-param.service';
import { dtoCustomerService } from '../../../_shared/services/dto-customer.service';
import { dtoGradeService } from '../../../_shared/services/dto-grade.service';
import { dtoOrdersService } from '../../../_shared/services/dto-orders.service';
import { dtoProductProfileService } from '../../../_shared/services/dto-product-profile.service';
import { dtoProductTypeService } from '../../../_shared/services/dto-product-type.service';
import { dtoSpeciesService } from '../../../_shared/services/dto-species.service';
import { FormService } from '../../../_shared/services/form.service';
import { OrdersService } from '../../../_shared/services/orders.service';
import { SharedService } from '../../../_shared/services/shared.service';
import { QuoteListComponent } from '../quote-list/quote-list.component';


export enum enQuoteTabs { Quotes = 0, SearchOptions = 1, CreateQuote = 2, UpdateQuote = 3 }


@Component({
  selector: 'app-quote-search',
  templateUrl: './quote-search.component.html',
  styleUrl: './quote-search.component.scss',
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class QuoteSearchComponent implements OnInit, AfterContentInit, AfterViewInit {

  @ViewChild("appQuoteList") appQuoteList: QuoteListComponent;

  showSpinner: boolean;
  quotes: MatTableDataSource<dtoOrders>;
  public eQuoteTabs = enQuoteTabs;
  quoteStatuses: dtoKeyValue[] = [];
  treatmentTypes: dtoKeyValue[] = [];
  treatmentHazardLevels: dtoKeyValue[] = [];

  aQuoteParamList: SQLParamArray[][];

  selectedTab: number;

  form: FormGroup;

  customers: dtoCustomer[];
  selectedCust: dtoCustomer[];

  productTypes: dtoProductType[];
  selectedProd: dtoProductType[];

  productProfiles: dtoProductProfile[];
  selectedProdProfile: dtoProductProfile[];

  species: dtoSpecies[];
  selectedSpec: dtoSpecies[];

  grades: dtoGrade[];
  selectedGrade: dtoGrade[];

  selectedProductCode: dtoProductCode[];

  productCodeSelectDialogRef: MatDialogRef<ProductCodeBuilderDialogComponent>

  constructor(private formService: FormService, private fb: FormBuilder, private sharedService: SharedService, private quoteService: OrdersService
    , private dtoQuoteService: dtoOrdersService, private alertService: AlertService, private customerService: dtoCustomerService, private productTypeService: dtoProductTypeService
    , private cdref: ChangeDetectorRef, private sqlParamService: SQLParamService, private dtoSpeciesService: dtoSpeciesService, private dtoGradeService: dtoGradeService
    , private dtoProductProfileService: dtoProductProfileService, private dialog: MatDialog) {

    this.form = this.fb.group({
      txtSearchQuoteNo: [''],
      txtCustomer: [''],
      intStatus: [''],
      txtSearchDeliveryAddress: [''],
      txtSearchOrderNumber: [''],
      txtSearchHaulingCompanyName: [''],
      txtSearchWidth: [''],
      txtSearchThickness: [''],
      txtSearchLength: [''],
      txtSearchSpecies: [''],
      txtSearchGrade: [''],
      txtSearchPoleKN: [''],
      txtSearchDiameter: [''],
      dteSearchQuotedDateFrom: [''],
      dteSearchQuotedDateTo: [''],
      blnSearchMinimumDeposit: [''],
      txtSearchQuotedByFirstName: [''],
      txtSearchQuotedByLastName: [''],
      txtSearchQuoteComments: [''],
      txtSearchFreightSubContractor: [''],
      txtSearchSubContractorFreightValueFrom: [''],
      txtSearchSubContractorFreightValueTo: [''],
      txtSearchSubContractorFreightComments: [''],
      txtSearchQuoteItemComments: [''],
      blnShowSearchItemsOnly: [false],
      radOnOrder: [2],
      txtSearchNominalWidth: [''],
      txtSearchNominalThickness: [''],
      txtSearchExWidth: [''],
      txtSearchExThickness: [''],
      intSearchTreatment: [''],
      intSearchTreatmentHazardLevel: [''],
      txtSearchContactFirstName: [''],
      txtSearchContactLastName: [''],
      txtSearchContactEmail: [''],
      txtSearchContactMobile: [''],
      txtSearchContactPhone: ['']
    });

  }


  ngOnInit() {

    document.title = "Quotes";

    this.quotes = new MatTableDataSource<dtoOrders>();

    this.loadData();

    this.quoteStatuses = this.sharedService.getEnumObject2(Orders.enStatus, true);
    this.treatmentTypes = this.sharedService.getEnumObject2(SharedService.enTreatmentType, true).filter((a) => { return a.txtValue != "None" });
    this.treatmentTypes.sort((a, b) => { return a.txtValue > b.txtValue ? 1 : -1 });

    this.treatmentHazardLevels = this.sharedService.getEnumObject2(SharedService.enHazardTreatment, true).filter((a) => { return a.txtValue != "None" });;
    this.treatmentHazardLevels.sort((a, b) => { return a.txtValue > b.txtValue ? 1 : -1 });

    //this.form.controls['txtCustomer'].setValue("Floormania");
    this.form.controls['intStatus'].setValue([0, 2]);

    //this.searchQuotes("");

    this.cdref.detectChanges();

    console.log("ngOnInit");


  }

  ngAfterContentInit() {

    console.log("ngAfterContentInit");
    this.cdref.detectChanges();

  }

  ngAfterViewInit() {

    console.log("ngAfterViewInit");
    this.cdref.detectChanges();

  }

  async loadData() {


    this.customerService.getAlldtoCustomer().then(customers => {
      this.customers = customers;
    });

    this.productTypeService.getdtoProductTypes().then(productTypes => {
      this.productTypes = productTypes;
    });

    this.dtoSpeciesService.getdtoAllSpecies().then(species => {
      this.species = species;
      console.log("got species", this.species.length);
    });

    this.dtoGradeService.getdtoAllGrades().then(grades => {
      this.grades = grades;
      console.log("got grades", this.grades.length);
    });

    this.dtoProductProfileService.getdtoAllProductProfile().then(profiles => {
      this.productProfiles = profiles;
      console.log("got productProfiles", this.productProfiles.length);
    });

  }

  enterKeySearch(type: string, e: KeyboardEvent) {

    if (this.selectedTab == enQuoteTabs.SearchOptions) {

      //WE NEED BLUR THE CURRENT TEXTBOX BECAUSE WE GET AN REF DETECTION CHANGE ERROR
      //WHEN GOING BACK TO THE QUOTES TAB WHICH IS DONE PROGRAMATICALLY IN this.searchQuotes.

      if (e.target != null) {
        let input: HTMLInputElement = e.target as HTMLInputElement;
        if (input != null) {
          input.blur()
        }
      }
    }

    this.searchQuotes(type, 0, e);

  }

  async searchQuotes(type: string, lastQuotes: number, e) {

    this.aQuoteParamList = [];
    let criteriaSelected: boolean = false;
    let aMandatoryParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let quoteNo: string = this.form.controls["txtSearchQuoteNo"].value;
    let status: string = this.form.controls["intStatus"].value;
    let deliveryAddress: string = this.form.controls["txtSearchDeliveryAddress"].value;
    let orderNumber: string = this.form.controls["txtSearchOrderNumber"].value;
    let width: string = this.form.controls["txtSearchWidth"].value;
    let thickness: string = this.form.controls["txtSearchThickness"].value;
    let length: string = this.form.controls["txtSearchLength"].value;
    let species: string = this.form.controls["txtSearchSpecies"].value;
    let grade: string = this.form.controls["txtSearchGrade"].value;
    let diameter: string = this.form.controls["txtSearchDiameter"].value;
    let poleKN: string = this.form.controls["txtSearchPoleKN"].value;
    let quoteDateFrom: string = this.form.controls["dteSearchQuotedDateFrom"].value;
    let quoteDateTo: string = this.form.controls["dteSearchQuotedDateTo"].value;
    let showSearchItemsOnly: boolean = this.form.controls["blnShowSearchItemsOnly"].value;
    let quotedByFirstName: string = this.form.controls["txtSearchQuotedByFirstName"].value;
    let quotedByLastName: string = this.form.controls["txtSearchQuotedByLastName"].value;
    let onOrder: number = this.form.controls["radOnOrder"].value;

    let quoteComments: string = this.form.controls["txtSearchQuoteComments"].value;
    let freightSubContractor: string = this.form.controls["txtSearchFreightSubContractor"].value;
    let subContractorFreightValueFrom: number = this.form.controls["txtSearchSubContractorFreightValueFrom"].value;
    let subContractorFreightValueTo: number = this.form.controls["txtSearchSubContractorFreightValueTo"].value;
    let subContractorFreightComments: string = this.form.controls["txtSearchSubContractorFreightComments"].value;
    let quoteItemComments: string = this.form.controls["txtSearchQuoteItemComments"].value;


    let nominalWidth: string = this.form.controls["txtSearchNominalWidth"].value;
    let nominalThickness: string = this.form.controls["txtSearchNominalThickness"].value;
    let exWidth: string = this.form.controls["txtSearchExWidth"].value;
    let exThickness: string = this.form.controls["txtSearchExThickness"].value;
    let treatment: string = this.form.controls["intSearchTreatment"].value;
    let treatmentHazardLevel: string = this.form.controls["intSearchTreatmentHazardLevel"].value;
    let contactFirstName: string = this.form.controls["txtSearchContactFirstName"].value;
    let contactLastName: string = this.form.controls["txtSearchContactLastName"].value;
    let contactEmail: string = this.form.controls["txtSearchContactEmail"].value;
    let contactMobile: string = this.form.controls["txtSearchContactMobile"].value;
    let contactPhone: string = this.form.controls["txtSearchContactPhone"].value;

    const datepipe: DatePipe = new DatePipe('en-AU');

    if (type == "searchoptions") {

    }

    this.sqlParamService.addParameter(this.aQuoteParamList, quoteNo, "txtOrderNo");

    let customerIds: string[] = this.selectedCust != null ? this.selectedCust.map((a) => { return a.rowguid }) : [];
    this.sqlParamService.addParameter(this.aQuoteParamList, customerIds, "guClientId");

    let productTypeIds: string[] = this.selectedProd != null ? this.selectedProd.map((a) => { return a.rowguid }) : [];
    this.sqlParamService.addParameter(this.aQuoteParamList, productTypeIds, "guProductTypeId", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");

    let productProfileIds: string[] = this.selectedProdProfile != null ? this.selectedProdProfile.map((a) => { return a.rowguid }) : [];
    this.sqlParamService.addParameter(this.aQuoteParamList, productProfileIds, "guProductProfileId", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");

    let speciesIds: string[] = this.selectedSpec != null ? this.selectedSpec.map((a) => { return a.rowguid }) : [];
    this.sqlParamService.addParameter(this.aQuoteParamList, speciesIds, "guSpeciesId", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");

    let gradeIds: string[] = this.selectedGrade != null ? this.selectedGrade.map((a) => { return a.rowguid }) : [];
    this.sqlParamService.addParameter(this.aQuoteParamList, gradeIds, "guBoardGradeId", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");

    let productCodeIds: string[] = this.selectedProductCode != null ? this.selectedProductCode.map((a) => { return a.rowguid }) : [];
    this.sqlParamService.addParameter(this.aQuoteParamList, productCodeIds, "guProductCodeGuidId", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");

    this.sqlParamService.addParameter(this.aQuoteParamList, datepipe.transform(quoteDateFrom, 'yyyy-MM-dd'), "dteEntered", false, SQLParamArray.enSQLOperator.GreaterThanOrEqualTo);
    this.sqlParamService.addParameter(this.aQuoteParamList, datepipe.transform(quoteDateTo, 'yyyy-MM-dd'), "dteEntered", false, SQLParamArray.enSQLOperator.LessThanOrEqualTo);
    this.sqlParamService.addParameter(this.aQuoteParamList, deliveryAddress, "txtDeliveryAddress", false, SQLParamArray.enSQLOperator.LikeAny);
    this.sqlParamService.addParameter(this.aQuoteParamList, orderNumber, "txtOrderNo", false, SQLParamArray.enSQLOperator.LikeAny, SQLParamArray.enSQLLogicalOperator.AND, null, "Orders", SQLParamArray.enSQLStatementType.SubQuerySQL, 2, "Orders");
    this.sqlParamService.addParameter(this.aQuoteParamList, width, "fltWidth", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addParameter(this.aQuoteParamList, thickness, "fltThickness", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    //this.sqlParamService.addMultiFieldParameter(this.aQuoteParamList, species, ['txtSpeciesName', 'txtSpeciesCode'], false, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    //this.sqlParamService.addMultiFieldParameter(this.aQuoteParamList, grade, ['txtGradeAbbreviation', 'txtGradeName'], false, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addMultiFieldParameter(this.aQuoteParamList, length, ["fltLength", 'intLength'], false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addParameter(this.aQuoteParamList, poleKN, "intKN", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addParameter(this.aQuoteParamList, diameter, "fltDiameter", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    //this.sqlParamService.addParameter(this.aQuoteParamList, productCode, "txtStockKeepingUnit", false, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");


    if (onOrder != null && onOrder == 0) {
      this.sqlParamService.addParameter(this.aQuoteParamList, "", "txtQuotedOrderNo", true, SQLParamArray.enSQLOperator.IsNull);
    }

    if (onOrder != null && onOrder == 1) {
      this.sqlParamService.addParameter(this.aQuoteParamList, "", "txtQuotedOrderNo", true, SQLParamArray.enSQLOperator.IsNotNull);
    }

    this.sqlParamService.addParameter(this.aQuoteParamList, quotedByFirstName, "txtQuotedByFirstName", false, SQLParamArray.enSQLOperator.Like);
    this.sqlParamService.addParameter(this.aQuoteParamList, quotedByLastName, "txtQuotedByLastName", false, SQLParamArray.enSQLOperator.Like);


    this.sqlParamService.addParameter(this.aQuoteParamList, quoteComments, "txtComments", false, SQLParamArray.enSQLOperator.Like);
    this.sqlParamService.addParameter(this.aQuoteParamList, freightSubContractor, "txtFreightSubContractor", false, SQLParamArray.enSQLOperator.Like);
    this.sqlParamService.addParameter(this.aQuoteParamList, String(subContractorFreightValueFrom), "fltSubContractedFreightQuote", false, SQLParamArray.enSQLOperator.GreaterThanOrEqualTo);
    this.sqlParamService.addParameter(this.aQuoteParamList, String(subContractorFreightValueTo), "fltSubContractedFreightQuote", false, SQLParamArray.enSQLOperator.LessThanOrEqualTo);
    this.sqlParamService.addParameter(this.aQuoteParamList, subContractorFreightComments, "txtFreightSubContractorComments", false, SQLParamArray.enSQLOperator.Like);
    this.sqlParamService.addParameter(this.aQuoteParamList, quoteItemComments, "txtComments", false, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addParameter(this.aQuoteParamList, nominalWidth, "fltNominalWidth", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addParameter(this.aQuoteParamList, nominalThickness, "fltNominalThickness", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addParameter(this.aQuoteParamList, exWidth, "fltExWidth", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addParameter(this.aQuoteParamList, exThickness, "fltExThickness", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addParameter(this.aQuoteParamList, treatment, "intTreatmentType", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addParameter(this.aQuoteParamList, treatmentHazardLevel, "intHazardLevel", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    this.sqlParamService.addParameter(this.aQuoteParamList, contactFirstName, "txtCustomerContactFirstName", false, SQLParamArray.enSQLOperator.Like);
    this.sqlParamService.addParameter(this.aQuoteParamList, contactLastName, "txtCustomerContactLastName", false, SQLParamArray.enSQLOperator.Like);
    this.sqlParamService.addParameter(this.aQuoteParamList, contactEmail, "txtCustomerContactEmail", false, SQLParamArray.enSQLOperator.Like);
    this.sqlParamService.addParameter(this.aQuoteParamList, contactMobile, "txtCustomerContactMobile", false, SQLParamArray.enSQLOperator.Like);
    this.sqlParamService.addParameter(this.aQuoteParamList, contactPhone, "txtCustomerContactPhone", false, SQLParamArray.enSQLOperator.Like);


    if (this.aQuoteParamList.length == 0 && status != null && status.length > 0) {
      let msg: string = await this.alertService.openSnackBarCustomDefaultShowCancel("Search Parameters", "You have selected only the status as search parameters\nThis may bring back alot of data and take a while\nDo you want to continue?", "Yes", "No");
      if (msg != "Yes") {
        return;
      }
    }

    this.sqlParamService.addParameter(this.aQuoteParamList, status, "intStatus");

    if (this.aQuoteParamList.length == 0) {
      let msg: string = await this.alertService.openSnackBarCustomPromise("No Search Criteria", "Please select some search criteria before searching", "Ok", "", "center", "bottom", "", 0, false);
      return;
    }

    //Search on Quote Type: intType == 1
    //aParam.push(new SQLParamArray("intType", "1"))
    //this.aQuoteParamList.push(aParam);
    this.sqlParamService.addParameter(this.aQuoteParamList, "1", "intType");


    console.log("aParamList", this.aQuoteParamList);

    this.quotes = new MatTableDataSource<dtoOrders>();
    this.showSpinner = true;


    if (this.selectedTab != enQuoteTabs.Quotes) {
      this.selectedTab = enQuoteTabs.Quotes;

    }

    console.log("last quotes: ", lastQuotes);

    let quotes: dtoOrders[] = await this.dtoQuoteService.getdtoOrdersParamArrayPromise(this.aQuoteParamList, lastQuotes);



    if (quotes != null && quotes.length > 0) {

      //quotes.sort((a, b) => { return a.txtOrderNo > b.txtOrderNo ? 1 : -1 });

      quotes.sort((a, b) => { return a.dteCreated < b.dteCreated ? 1 : -1 });

      this.quotes.data = quotes;
    }



    this.showSpinner = false;



  }

  //addToParameterList(value: string, field: string, sqlOperator: SQLParamArray.enSQLOperator) {

  //  let aParam: SQLParamArray[] = [];
  //  console.log("returnParameter - value: ", value);
  //  console.log("returnParameter - field: ", field);

  //  if (value == null || value == "null" || value == "" || field == null || field == "") {
  //    console.log("returnParameter return empty: ");
  //    return aParam;
  //  }

  //  if (value.indexOf(",") > -1) {
  //    let aArray: string[] = value.split(",");
  //    for (let i = 0; i <= aArray.length - 1; i++) {
  //      aParam.push(new SQLParamArray(field, aArray[i].trimStart(), sqlOperator));
  //    }
  //  }
  //  else {
  //    aParam.push(new SQLParamArray(field, value, sqlOperator));
  //  }

  //  console.log("returnParameter - aParam: ", aParam);

  //  return aParam;

  //}

  addToParameterList(value: string, field: string, sqlOperator: SQLParamArray.enSQLOperator = SQLParamArray.enSQLOperator.Equals, logicalOperator: SQLParamArray.enSQLLogicalOperator = SQLParamArray.enSQLLogicalOperator.AND, dbFieldFunctionArray: DBFieldFunction = null, tablename: string = "", sqlStatmentType: SQLParamArray.enSQLStatementType = SQLParamArray.enSQLStatementType.SelectSQL, queryNo: number = 1, subQueryClass: string = "") {

    let aParam: SQLParamArray[] = [];
    console.log("returnParameter - value: ", value);
    console.log("returnParameter - field: ", field);

    if (value == null || value == "null" || value == "" || field == null || field == "") {
      console.log("returnParameter return empty: ");
      return aParam;
    }

    if (value.indexOf(",") > -1) {
      let aArray: string[] = value.split(",");
      for (let i = 0; i <= aArray.length - 1; i++) {
        aParam.push(new SQLParamArray(field, aArray[i].trimStart(), sqlOperator, logicalOperator, dbFieldFunctionArray, tablename, sqlStatmentType, queryNo, subQueryClass));
      }
    }
    else {
      aParam.push(new SQLParamArray(field, value, sqlOperator, logicalOperator, dbFieldFunctionArray, tablename, sqlStatmentType, queryNo, subQueryClass));
    }

    console.log("returnParameter - aParam: ", aParam);

    return aParam;

  }

  quoteCancelled(val: boolean) {

  }


  quoteItemsAdded(quoteItem: OrderItem) {

  }


  updatedQuote(quote: Orders) {

  }

  removeQuote(quote: Orders) {

  }

  editQuote($event) {

  }

  addQuoteItems(quote: Orders) {

  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    console.log(e.index == this.eQuoteTabs.SearchOptions);

    if (e.index == this.eQuoteTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchCustomer");
    }

    if (e.index == this.eQuoteTabs.Quotes) {
      this.formService.setAutoFocusTimeout("txtSearchQuoteNo");

    }

  }

  selectedCustomers(customers: dtoCustomer[]) {

    this.selectedCust = customers;

  }

  selectedProductTypes(productTypes: dtoProductType[]) {

    this.selectedProd = productTypes;

  }

  selectedProductProfiles(productProfiles: dtoProductProfile[]) {

    this.selectedProdProfile = productProfiles;

  }

  selectedSpecies(species: dtoSpecies[]) {

    this.selectedSpec = species;

  }

  selectedGrades(grades: dtoGrade[]) {

    this.selectedGrade = grades;

  }

  selectedProductCodes(productCodes: dtoProductCode[]) {
    this.selectedProductCode = productCodes;

  }



}
