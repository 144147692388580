import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeliveryDocketCreateComponent } from '../../delivery-docket/delivery-docket-create/delivery-docket-create.component';
import { DeliveryDocketUpdateComponent } from '../../delivery-docket/delivery-docket-update/delivery-docket-update.component';
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';

@Component({
  selector: 'app-customer-contact-update-dialog',
  templateUrl: './customer-contact-update-dialog.component.html',
  styleUrl: './customer-contact-update-dialog.component.scss'
})
export class CustomerContactUpdateDialogComponent {
  customerName: string;
  customerId: string;
  selectedCustomerContact: CustomerContact;

  constructor(private deliveryUpdateRef: MatDialogRef<DeliveryDocketUpdateComponent>, private deliveryCreateRef: MatDialogRef<DeliveryDocketCreateComponent>, @Inject(MAT_DIALOG_DATA) private data: { customerId: string, customerName: string, selectedCustomerContact: CustomerContact }) {

    this.customerId = data.customerId;
    this.customerName = data.customerName;
    this.selectedCustomerContact = data.selectedCustomerContact;

  }


  customerContactUpdated(customerContactCreatedObj: CustomerContact) {

    if (this.deliveryUpdateRef != null) {
      this.deliveryUpdateRef.close({ status: "Saved", customerContact: customerContactCreatedObj });
    }

    if (this.deliveryCreateRef != null) {
      this.deliveryCreateRef.close({ status: "Saved", customerContact: customerContactCreatedObj });
    }

  }

  customerContactCancelled(cancelled: boolean) {

    if (this.deliveryUpdateRef != null) {
      this.deliveryUpdateRef.close({ status: "Cancel", customerContact: null });
    }

    if (this.deliveryCreateRef != null) {
      this.deliveryCreateRef.close({ status: "Cancel", customerContact: null });
    }


  }
}
