<div class="pickup-location-select-container" #pickupLocationContainer>
  <mat-form-field class="chh-form-field example-full-width">
    <mat-label>Search Pickup Location</mat-label>
    <input matInput data-pickup-location-input="pickupLocationInput" class="pickup-location-input" placeholder="Filter by name" placeholderLabel="i.e. Essential Energy....." value="" type="text" #pickupLocationFilter (keyup)="filterPickupLocations(pickupLocationFilter.value, $event)" (focus)="pickupLocationFilter.select()" (click)="checkPickupLocationListIsOpen(pickupLocationFilter.value)">
  </mat-form-field>

  <span><mat-slide-toggle #multiplePickupLocations (click)="checkMultiplePickupLocations(multiplePickupLocations.checked)">Multiple Pickup Locations</mat-slide-toggle></span>
  <div class="selected-pickup-locations-container">
    <div class="selected-pickup-locations" id="selectedPickupLocations" *ngIf="selectedPickupLocations != null && selectedPickupLocations.length > 0">
      <div class="chip-column">
        <mat-chip-listbox #chipGrid aria-label="PickupLocation selection">
          @for (selectPickupLocation of selectedPickupLocations; track selectPickupLocation; let selProdIdx = $index; let lastItem = $last) {
          <mat-chip-option (removed)="removePickupLocation(selectPickupLocation)">
            {{selectPickupLocation.txtLocationName}}
            <button matChipRemove [attr.aria-label]="'remove ' + selectPickupLocation.txtName">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
          }
        </mat-chip-listbox>
      </div>
      <div class="see-more-chips">
        <button mat-icon-button aria-label="expand row" (click)="expandSelectedPickupLocations($event)">
          <mat-icon class="expand-icons pickup-location-selection-expand-icon material-icons-outlined" *ngIf="pickupLocationSelectionExpanded == false ">keyboard_arrow_down</mat-icon>
          <mat-icon class="expand-icons pickup-location-selection-expand-icon material-icons-outlined" *ngIf="pickupLocationSelectionExpanded == true">keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div data-pickup-location-select="pickupLocationSelect" class="pickup-location-container" *ngIf="showPickupLocationList" #pickupLocationListContainer>
    <div data-pickup-location-select="pickupLocationSelect" id="pickupLocationSelect" class="pickup-location-select-list">
      <div class="pickup-location-pin-row">
        <div class="button" data-push-pin="pushPin" id="btnPushPin" (click)="clickPin($event)" [ngClass]="pickupLocationListPinned == true ? 'button-pressed' : 'button'" matTooltip="{{toolTipText}}">
          <mat-icon data-push-pin="pushPin" class="pin-icon">push_pin</mat-icon>
        </div>
      </div>
      <div class="pickup-location-heading-row pickup-location-name-heading">Name</div>
      <div class="pickup-location-heading-row pickup-location-update-heading"></div>
      @for(pickupLocation of pickupLocationsFiltered; track pickupLocation; let idx = $index){
      <div data-pickup-location-select="pickupLocationSelect" tabindex="{{idx}}" id="pickupLocationName{{idx}}" (keyup)="nextPickupLocation($event, pickupLocation)" class="chh-list-item-row chh-list-item-first-column pickup-location-name" [ngClass]="pickupLocation.guSelectedPickupLocationId != '' ? 'chh-list-item-row chh-list-item-row-selected pickup-location-first-name' : addressHoverId == pickupLocation.rowguid ? 'chh-list-item-row chh-list-item-row-hover pickup-location-first-name' : 'chh-list-item-row pickup-location-first-name'" (mouseenter)="rowEnter(pickupLocation.rowguid)" (mouseleave)="rowLeave()" (click)="selectPickupLocation(pickupLocation, !(pickupLocation.guSelectedPickupLocationId != ''))"><div data-pickup-location-select="pickupLocationSelect" class="pickup-location-text">{{pickupLocation.txtLocationName}}</div></div>
      <div data-pickup-location-select="pickupLocationSelect" id="pickupLocationUpdateDiv{{idx}}" class="chh-list-item-row pickup-location-radio pickup-location-update-div" [ngClass]="pickupLocation.guSelectedPickupLocationId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == pickupLocation.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(pickupLocation.rowguid)" (mouseleave)="rowLeave()" (click)="selectPickupLocation(pickupLocation, !(pickupLocation.guSelectedPickupLocationId != ''))">
        <div data-pickup-location-select="pickupLocationSelect" id="pickupLocationUpdateButtonDiv{{idx}}" class="update-pickup-location-button">
          <button mat-button (click)="updatePickupLocation(pickupLocation)" matTooltip="Click to edit PickupLocation">
            <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
          </button>
        </div>
      </div>

      }
    </div>

  </div>

</div>
