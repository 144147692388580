import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Species } from '../../_shared/business-objects/species.bo';

@Injectable()
export class SpeciesService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getAllSpecies() {
    return this.http.get<Species[]>(this._baseUrl + 'api/Species/GetAllSpecies');
  }

  GetTrimmingSpecies() {
    return this.http.get<Species[]>(this._baseUrl + 'api/Species/GetTrimmingSpecies');
  }  

  getProductCodeSpecies(gProductCategoryId: string, gProductTypeId: string, iLogSection, iWidth: number, iThickness: number, gProductProfileId: string) {
    return this.http.get<Species[]>(this._baseUrl + 'api/Species/GetProductCodeSpecies/' + gProductCategoryId + '/' + gProductTypeId + '/' + iLogSection + '/' + iWidth + '/' + iThickness + '/' + gProductProfileId);
  }

  getSpecies(gSpeciesId: string) {
    return this.http.get<Species>(this._baseUrl + 'api/Species/' + gSpeciesId);
  }
}
