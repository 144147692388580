import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { AppUserPreference } from '../business-objects/app-user-preference.bo';
import { SharedService } from './shared.service';
import { AuthService } from '../../account/auth.service';

@Injectable()
export class AppUserPreferenceService
{

	public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService
            , private sharedService: SharedService, private authService: AuthService) {
		this._baseUrl = baseUrl;
	}

	getAppUserPreference(appUserPreferenceId: string) {
		return this.http.get<AppUserPreference>(this._baseUrl + 'api/AppUserPreference/' + appUserPreferenceId);
	}

	getAppUserPreferenceParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<AppUserPreference[]>(this._baseUrl + 'api/AppUserPreference/GetAppUserPreferencesParamArray/', aParam);
	}

	getAllAppUserPreferences() {
		return this.http.get<AppUserPreference[]>(this._baseUrl + 'api/AppUserPreference/GetAllAppUserPreferences/');
	}

	//getAlldtoAppUserPreferences() {
	//	return this.http.get<dtoAppUserPreference[]>(this._baseUrl + 'api/AppUserPreference/GetAllAppUserPreferences/');
	//}

	createAppUserPreference(appUserPreference: AppUserPreference) {
		return this.http.post<AppUserPreference>(this._baseUrl + 'api/AppUserPreference/CreateAppUserPreference/', appUserPreference);
	}

	updateAppUserPreference(appUserPreference: AppUserPreference) {
		return this.http.put<AppUserPreference>(this._baseUrl + 'api/AppUserPreference/UpdateAppUserPreference/', appUserPreference);
	}

	deleteAppUserPreference(appUserPreferenceId: string) {
		return this.http.delete(this._baseUrl + 'api/AppUserPreference/DeleteAppUserPreference/' + appUserPreferenceId);
	}

	async getAppUserPreferencePromise(appUserPreferenceId: string) {
		return new Promise<AppUserPreference>(resolve => {
			this.getAppUserPreference(appUserPreferenceId).subscribe(appUserPreference => {
				resolve(appUserPreference);
			}, err => {
				this.alertService.openSnackBarError("Error getting AppUserPreference records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getAppUserPreferenceByPreferenceId(appPreferenceId: string) {
    return new Promise<AppUserPreference>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guAppPreferenceId", appPreferenceId));
      aParamList.push(aParam);

      this.getAppUserPreferenceParamArray(aParamList).subscribe({
        next: (appUserPreference) => {
          if (appUserPreference != null && appUserPreference.length > 0) {
            resolve(appUserPreference[0]);
          } else {
            resolve(null);
          }
        }, error: (err: HttpErrorResponse) => {
          this.alertService.openSnackBarError("Error getting AppUserPreference records", "Close", "center", "bottom", 4000, true, err.message);
          resolve(null);
        }
      });
    });
  }

  async createAppUserPreferenceByPreferenceId(appPreferenceId: string) {
    return new Promise<AppUserPreference>(resolve => {

      let appUserPreference: AppUserPreference = new AppUserPreference();
      appUserPreference.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
      appUserPreference.guAppPreferenceId = appPreferenceId;
      appUserPreference.guAppUserId = this.authService.getAppUserId();
      appUserPreference.blnActive = true;
      
      this.createAppUserPreference(appUserPreference).subscribe({
        next: (appPreferences) => {
          resolve(appPreferences);
        }, error: (err: HttpErrorResponse) => {
          this.alertService.openSnackBarError("Error creating AppUserPreference records", "Close", "center", "bottom", 4000, true, err.message);
          resolve(null);
        }
      });
    });
  }

	async getAppUserPreferenceParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<AppUserPreference[]>(resolve => {
			this.getAppUserPreferenceParamArray(aParam).subscribe(appUserPreferences => {
				resolve(appUserPreferences);
			}, err => {
				this.alertService.openSnackBarError("Error getting AppUserPreference records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateAppUserPreferencePromise(appUserPreference: AppUserPreference) {
		return new Promise<AppUserPreference>(resolve => {
			this.updateAppUserPreference(appUserPreference).subscribe(appUserPreference => {
				resolve(appUserPreference);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating AppUserPreference record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createAppUserPreferencePromise(appUserPreference: AppUserPreference) {
		return new Promise<AppUserPreference>(resolve => {
			this.createAppUserPreference(appUserPreference).subscribe(appUserPreference => {
				resolve(appUserPreference);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating AppUserPreference record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deleteAppUserPreferencePromise(appUserPreferenceId: string) {
		return new Promise<boolean>(resolve => {
			this.deleteAppUserPreference(appUserPreferenceId).subscribe(appUserPreference => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing AppUserPreference record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
