import { DatePipe } from '@angular/common';
import { Component, InjectionToken, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { DeliveryDocket } from '../_shared/business-objects/delivery-docket.bo';
import { dtoDeliveryDocket } from '../_shared/business-objects/dto-delivery-docket.bo';
import { dtoKeyValue } from '../_shared/business-objects/dto-int-key-value.bo';
import { DBFieldFunction, SQLParamArray } from '../_shared/business-objects/sql-param-array';
import { dtoDeliveryDocketService } from '../_shared/services/dto-delivery-docket.service';
import { SharedService } from '../_shared/services/shared.service';
import { AlertService } from '../_shared/services/alert.service';
import { ActivatedRoute, Params } from '@angular/router';
import { FieldQueryParamMap } from '../_shared/app-objects/field-query-param-map.ao';
import { FormService } from '../_shared/services/form.service';
import { BoardTallyRecordService } from '../_shared/services/board-tally-record.service';
import { dtoStockItem } from '../_shared/business-objects/dto-stock-item.bo';
import { AppUserGeoStockLocationService } from '../_shared/services/appuser-geostocklocation.service';
import { AuthService } from '../account/auth.service';
import { GeoStockLocation } from '../_shared/business-objects/geo-stock-location.bo';
import { GeoStockLocationService } from '../_shared/services/geo-stock-location.service';
import { AppUserGeoStockLocation } from '../_shared/business-objects/app-user-geo-stock-location.bo';
import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';
import { StockItemComponent } from '../stock-item/stock-item.component';
import { StockTransferStockItemComponent } from '../stock-transfer-stock-item/stock-transfer-stock-item.component';
import { MatTable } from '@angular/material/table';
import { promise } from 'protractor';
import { StockContainerTypeService } from '../_shared/services/stock-container-type.service';
import { async } from 'rxjs';
import { StockContainerType } from '../_shared/business-objects/stock-container-type.bo';
import { DeliveryDocketService } from '../_shared/services/delivery-docket.service';
import { DeliveryDocketStockTransferService } from '../_shared/services/delivery-docket-stock-transfer.service';
import { dtoDeliveryDocketStockTransfer } from '../_shared/business-objects/dto-delivery-docket-stock-transfer.bo';
import { BoardTallyRecordDeliveryTransferred } from '../_shared/business-objects/board-tally-record-delivery-transferred.bo';
import { BoardTallyRecordDeliveryTransferredService } from '../_shared/services/board-tally-record-delivery-transferred.service';

enum enStockTransferTabs { Search, SearchOptions, StockItems }
export const token = new InjectionToken<string>("");

@Component({
  selector: 'app-stock-transfer-receive',
  templateUrl: './stock-transfer-receive.component.html',
  styleUrls: ['./stock-transfer-receive.component.css'],
  animations: [
      trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', [
          animate('325ms cubic-bezier(0.4, 0.0, 0.2, 1)'),  //225
          query('@*', animateChild(), { optional: true })
        ]),
      ]),
  ]
})
export class StockTransferReceiveComponent implements OnInit {

  @ViewChild('tabDeliveryDockets', { static: false }) tabDeliveryDockets: MatTabGroup;
  @ViewChild('tblDeliveryDocket', { static: false }) tblDeliveryDockets: MatTable<dtoDeliveryDocket>;

  //
  @ViewChild('stockItemApp', { static: false }) stockItemComp: StockItemComponent;
  @ViewChild('appstocktransfer')
  private stockTransferComponent!: StockTransferStockItemComponent;


  form: FormGroup;
  selectedTab: number;
  showTable: boolean = false;
  showProductsSpinner: boolean = false;
  deliveryDockets: dtoDeliveryDocket[] = [];
  deliveryStatuses: dtoKeyValue[] = [];
  selectedStatuses: number[] = [];
  stockItems: dtoDeliveryDocketStockTransfer[];
  transferredStock: BoardTallyRecordDeliveryTransferred[]

  //nonDeliveredStockItems: dtoStockItem[];

  geoStockLocations: GeoStockLocation[];

  defaultGeoStockLocation: string;
  defaultGeoLocation: GeoStockLocation;
  defaultGeoLocationName: string;
  stockContainerRackType: StockContainerType;

  transferToStockItems: dtoStockItem[] = [];

  deliveryDocketExpanded: dtoDeliveryDocket | null;
  stockItemExpanded: dtoStockItem | null;

  expandCell: boolean = false;

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  displayedColumns: string[] = ['intDocketNo', 'intLoadingDocketNo', 'txtStatus', 'dteStatusDate', 'txtHaulingCompanyName', 'dteCreatedDate', 'btnSetAsDelivered', 'btnReceiveStock'];
  displayedStockItems: string[] = ['txtIdentifier', 'txtProductName', 'txtProfileName', 'intWidth', 'sizeSeparator', 'intThickness', 'txtSpeciesName', 'txtGradeName', 'fltBoardTallyQuantity', 'txtBoardTallyQuantityUoM', 'intTotalLM', 'txtContainerLocation', 'txtStockType', 'txtLocation', 'txtTransferToPack', 'btnTransferPack'];
  
  displayedStockItems2: string[] = ['txtIdentifier', 'txtProductName', 'txtProfileName', 'intWidth', 'btnViewStock'];

  displayedStockItems3: string[] = ['txtIdentifier', 'txtProductName', 'txtProfileName', 'intWidth'];

  displayedCurrentStockItems: string[] = ['txtIdentifier', 'txtProductName', 'txtProfileName', 'btnViewCurrentStock'];

  public myInjector: Injector;
  public activeComponent: StockItemComponent;

  constructor(private dtoDeliveryDocketService: dtoDeliveryDocketService, private deliveryDocketService: DeliveryDocketService, private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private route: ActivatedRoute, private formService: FormService, private boardTallyRecordService: BoardTallyRecordService, private appUserGeoStockLocationService: AppUserGeoStockLocationService
    , private authService: AuthService, private geoStockLocationService: GeoStockLocationService, private viewContainerRef: ViewContainerRef, private injector: Injector
    , private stockContainerTypeService: StockContainerTypeService, private deliveryDocketStockTransferService: DeliveryDocketStockTransferService
    , private boardTallyRecordDeliveryTransferred: BoardTallyRecordDeliveryTransferredService
  ) {

    this.form = this.fb.group({
      txtSearchDeliveryDocketNo: [''],
      txtSearchLoadingDocketNo: [''],
      dteSearchStatusDateFrom: [''],
      dteSearchStatusDateTo: [''],
      txtSearchHaulingCompanyName: [''],
      intStatus: [''],
      guGeoStockLocationId: [''],
    });


    this.deliveryStatuses = this.sharedService.getEnumObject2(DeliveryDocket.enDeliveryStatus);

    this.filterToQueryParamMap.push({ txtField: "intStatus", txtQueryParam: "status", intFieldType: FieldQueryParamMap.enFieldType.SelectboxMultiple, intValueType: FieldQueryParamMap.enValueType.Int });
    this.filterToQueryParamMap.push({ txtField: "txtSearchHaulingCompanyName", txtQueryParam: "hauler", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    this.filterToQueryParamMap.push({ txtField: "dteSearchStatusDateFrom", txtQueryParam: "datefrom", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.Date });
    this.filterToQueryParamMap.push({ txtField: "dteSearchStatusDateTo", txtQueryParam: "dateto", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.Date });

    //console.log("status: ", this.filterToQueryParamMap);


  }

  ngOnInit(): void {

    this.showTable = false;

    //this.selectedStatuses.length = 0;

    this.loadData();


    //if (Array.isArray(this.form.controls['intStatus'].value)) {
    //  for (let i = 0; i <= this.form.controls['intStatus'].value.length - 1; i++) {
    //    this.selectedStatuses.push(this.form.controls['intStatus'].value[i]);
    //    //console.log("status: " + this.form.controls['intStatus'].value[i]);
    //  }

    //}


  }


  //createInjector() {
  //  this.myInjector = Injector.create({
  //    providers: [{ provide: token, useValue: this.stockItems }],
  //    parent: this.injector,
  //  });
  //}

  async expandElement(deliveryDocket: dtoDeliveryDocket) {

    console.log(deliveryDocket.rowguid);

    this.deliveryDocketExpanded = this.deliveryDocketExpanded === deliveryDocket ? null : deliveryDocket;
    this.loadStockToReceive(deliveryDocket.rowguid);


  }

  expandStockElement(boardTallyRecordHistory: dtoStockItem, rowIdx: number) {

    let div: HTMLDivElement = document.getElementById("currentStock" + rowIdx) as HTMLDivElement;

    console.log(boardTallyRecordHistory.guProductCodeId);

    this.stockItemExpanded = this.stockItemExpanded === boardTallyRecordHistory ? null : boardTallyRecordHistory;

    this.showCurrentStock(boardTallyRecordHistory.guProductCodeId);

  }

  async loadData() {
    this.route.queryParams.subscribe(params => {

      this.formService.setFieldsFromQueryParams(this.filterToQueryParamMap, params, this.form);

    });

    await new Promise<GeoStockLocation[]>(resolve => {
      this.geoStockLocationService.getAllGeoStockLocations().subscribe(geoLocations => {

        this.geoStockLocations = geoLocations;

        resolve(geoLocations);

      }, err => {
        console.log(err);
        resolve(null);
      });
    });



    await new Promise<AppUserGeoStockLocation>(resolve => {

      this.appUserGeoStockLocationService.getAppUserGeoStockLocationDefault(this.authService.getAppUserId()).subscribe(loc => {

        if (loc != null) {
          this.defaultGeoStockLocation = loc.guGeoStockLocationId;
          this.form.controls['guGeoStockLocationId'].setValue([loc.guGeoStockLocationId]);
        }
        resolve(loc);
      }, err => {
        resolve(null);
        console.log(err);
      });
    })

    this.defaultGeoLocation = await this.getMyDefaultGeoLocation();

    this.defaultGeoLocationName = this.defaultGeoLocation.txtLocationName;

    this.stockContainerRackType = await this.getStockContainerRackType();

  }

  async getMyDefaultGeoLocation() {

    return new Promise<GeoStockLocation>(resolve => {
      this.geoStockLocationService.getMyDefaultGeoStockLocation(this.authService.getAppUserId()).subscribe(stockLocation => {

        resolve(stockLocation);

      }, err => {
        console.log(err);
      });
    });


  }

  async getStockContainerRackType() {

    return new Promise<StockContainerType>(resolve => {
      this.stockContainerTypeService.getStockContainerByName("Rack").subscribe(containerType => {

        resolve(containerType);

      }, err => {
        console.log(err);
      });
    });


  }

  onTabChange(e) {

  }

  searchDeliveryDockets() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let docketNo: string = this.form.controls["txtSearchDeliveryDocketNo"].value;
    let loadingDocketNo: string = this.form.controls["txtSearchLoadingDocketNo"].value;
    let statusDateFrom: string = this.form.controls["dteSearchStatusDateFrom"].value;
    let statusDateTo: string = this.form.controls["dteSearchStatusDateTo"].value;
    let haulingCompanyName: string = this.form.controls["txtSearchHaulingCompanyName"].value;
    let status: number[] = this.form.controls["intStatus"].value
    const datepipe: DatePipe = new DatePipe('en-AU');


    //if (this.selectedStatuses.length > 0) {
    //  for (let i = 0; i <= this.selectedStatuses.length - 1; i++) {
    //    aParam.push(new SQLParamArray("intStatus", this.selectedStatuses[i].toString()))
    //    console.log("this.selectedStatuses.length: " + this.selectedStatuses.length);
    //  }
    //  aParamList.push(aParam);
    //}

    if (status.length > 0 || (status != null && status.toString() != "")) {
      if (Array.isArray(status) == true) {
        for (let i = 0; i <= status.length - 1; i++) {
          aParam.push(new SQLParamArray("intStatus", status[i].toString()));
        }
        aParamList.push(aParam);
        //console.log("intStatus - parameter Array", aParam);
      }

      if (Array.isArray(status) == false) {
        aParam.push(new SQLParamArray("intStatus", status.toString()));
        aParamList.push(aParam);
        //console.log("intStatus - parameter", aParam);
      }

    }

    aParam = [];

    if (docketNo != "" && docketNo != null) {
      aParam = [];
      if (docketNo.indexOf(",")) {
        let aDocketNos: string[] = docketNo.split(",");
        for (let i = 0; i <= aDocketNos.length - 1; i++) {
          aParam.push(new SQLParamArray("intDocketNo", aDocketNos[i]))
        }
      }
      else {
        aParam.push(new SQLParamArray("intDocketNo", docketNo))
      }
      aParamList.push(aParam);
    }

    if (loadingDocketNo != "" && loadingDocketNo != null) {
      aParam = [];
      if (loadingDocketNo.indexOf(",")) {
        let aLoadingDocketNos: string[] = loadingDocketNo.split(",");
        for (let i = 0; i <= aLoadingDocketNos.length - 1; i++) {
          aParam.push(new SQLParamArray("intLoadingDocketNo", aLoadingDocketNos[i]))
        }
      }
      else {
        aParam.push(new SQLParamArray("intLoadingDocketNo", loadingDocketNo))
      }
      aParamList.push(aParam);
    }

    if (statusDateFrom != "" && statusDateFrom != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteStatusDate", datepipe.transform(statusDateFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }

    if (statusDateTo != "" && statusDateTo != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteStatusDate", datepipe.transform(statusDateTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }

    if (haulingCompanyName != "" && haulingCompanyName != null) {
      aParam = [];
      if (haulingCompanyName.indexOf(",")) {
        let aHaulingCompanyNames: string[] = haulingCompanyName.split(",");
        for (let i = 0; i <= aHaulingCompanyNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtHaulingCompanyName", aHaulingCompanyNames[i], SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtHaulingCompanyName", haulingCompanyName, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }

    if (this.form.controls['guGeoStockLocationId'].value != null) {
      aParam = [];
     for (let i = 0; i <= this.form.controls['guGeoStockLocationId'].value.length - 1; i++) {
       aParam.push(new SQLParamArray("guGeoStockLocationId", this.form.controls['guGeoStockLocationId'].value[i].toString()))
      }
      aParamList.push(aParam);
    }

    aParam = [];
    aParam.push(new SQLParamArray("intDeliveryType", DeliveryDocket.enDeliveryType.StockTransfer.toString()))
    aParamList.push(aParam);

    this.showTable = false;

    if (aParamList.length == 0) {
      this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "centerSnackbar", 4000);
    }

    this.dtoDeliveryDocketService.getdtoDeliveryDocketsParamArray(aParamList).subscribe(delDockets => {

      this.deliveryDockets = delDockets;
      this.showTable = true
      this.tabDeliveryDockets.selectedIndex = enStockTransferTabs.Search;

    }, err => {
      console.log(err);
    });

  }

  receiveStock(deliveryDocket: DeliveryDocket) {

    this.loadStockToReceive(deliveryDocket.rowguid);
    //this.tabDeliveryDockets.selectedIndex = enStockTransferTabs.StockItems;


    if (deliveryDocket.intStatus == DeliveryDocket.enDeliveryStatus.ReadyForDispatch || deliveryDocket.intStatus == DeliveryDocket.enDeliveryStatus.Dispatched) {

    }

    if (deliveryDocket.intStatus == DeliveryDocket.enDeliveryStatus.Delivered) {

    }


  }

  async loadStockToReceive(deliveryDocketId: string) {

    //this.stockItems = [];
    this.stockItems = await this.getStockItems(deliveryDocketId);
    //this.nonDeliveredStockItems = this.stockItems;


    //=======================================================================================================
    // Get ALL Board Tally Record Delviery Transferred records for delivery docket.
    //=======================================================================================================
    //let aParamList: SQLParamArray[][] = [];
    //let aParam: SQLParamArray[] = [];

    //aParam.push(new SQLParamArray("guDeliveryDocketStockTransferId", this.deliveryDocketExpanded.rowguid));
    //aParamList.push(aParam);
    //this.transferredStock = await this.boardTallyRecordDeliveryTransferred.getBoardTallyRecordDeliveryTransferredParamArrayPromise(aParamList);

    
  }

  async getStockItems(deliveryDocketId: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocketId, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "vw_DeliveryDocketStockTransfer"))
    aParamList.push(aParam);

    //aParam = [];
    //aParam.push(new SQLParamArray("guGeoStockLocationId", this.defaultGeoLocation.rowguid, SQLParamArray.enSQLOperator.NotEqualTo, SQLParamArray.enSQLLogicalOperator.AND, null, "vw_DeliveryDocketStockTransfer"))
    //aParamList.push(aParam);

    console.log("this.deliveryDocketExpanded", this.deliveryDocketExpanded);

    //aParam = [];
    //aParam.push(new SQLParamArray("blnStockContainerArchived", "0", SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "vw_DeliveryDocketStockTransfer"))
    //aParamList.push(aParam);

    return new Promise<dtoDeliveryDocketStockTransfer[]>(resolve => {
      this.deliveryDocketStockTransferService.getdtoDeliveryDocketStockTransfersParamArray(aParamList).subscribe(ddStockTransfers => {

        resolve(ddStockTransfers);

        //console.log(ddStockTransfers[0].fltBoardTallyQuantity);

      }, err => {
        console.log(err);
        resolve(null);
      })
    });
  }


  viewReceiveStock(deliveryDocketId: string) {

  }

  filterTransferToStock(productCodeId: string): dtoStockItem[] {
    let stockItems: dtoStockItem[] = this.transferToStockItems.filter(stock => stock.guProductCodeId == productCodeId);

    console.log("stock Items array: " + stockItems);

    return stockItems;
  }

  showCurrentStock(productCodeId: string) {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guGeoStockLocationId", this.form.controls['guGeoStockLocationId'].value[0]))
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("guProductCodeId", productCodeId))
    aParamList.push(aParam);

    this.transferToStockItems = [];

    this.boardTallyRecordService.getNonDeliveredStockItemParamArray(aParamList).subscribe(stockItems => {

      this.transferToStockItems = stockItems;

      console.log("transferStockItems.count: " + this.transferToStockItems.length);


    }, err => {
      console.log(err);
    });

  }


  async transferToStockItem(stockItemArray: [dtoStockItem, dtoDeliveryDocketStockTransfer]) {
    // HERE WE SAVE THE STOCK FROM INTO STOCK TOO.

    await this.loadStockToReceive(stockItemArray[1].guDeliveryDocketId);

  }


  async transferWholeStockItem(wholeStockArray: [dtoDeliveryDocketStockTransfer, number], deliveryDocketIndex: number) {

    // HERE WE SAVE THIS PACK AS TO DESTINATIONS GEO STOCK LOCATION AND SET ITS IN HOUSE LOCATION.
    console.log(this.deliveryDockets[deliveryDocketIndex]);


    //=========================================================================================================
    // ADD BoardTallyRecordDeliveryTransferred RECORD FOR EACH STOCK ITEM.
    //=========================================================================================================


    //=========================================================================================================


    //this.stockItems.splice(wholeStockArray[1], 1);


    //====================================================================================================
    // Compare if all transferredStock is transferred for this delivery docket.
    // If so then we can set delivery docket as Delivered.
    // Create code similar to the archive stock code in stock-item-component.
    //====================================================================================================
    // -- TO DO.

    let boardTallyTransferredStock = await this.boardTallyRecordDeliveryTransferred.getAllBoardTallyRecordDeliveryTransferredByDelDocketPromise(this.deliveryDocketExpanded.rowguid);
    let setDelivery: boolean = true;

    if (boardTallyTransferredStock != null) {
      //this.stockItems

      let boardTallyRecordIds: string[] = this.stockItems.map(bt => bt.guBoardTallyRecordId);
      let transferredStockIds: string[] = boardTallyTransferredStock.map(ts => ts.guBoardTallyRecordId);

      console.log(boardTallyRecordIds);
      console.log(transferredStockIds);

      for (let btId of boardTallyRecordIds) {

        // If the board tally record Id does not exist in the stock transfer array then exit loop.
        // We need all board tally record Id's to be transferred then we can archive the Stock Container

        setDelivery = transferredStockIds.includes(btId);

        if (setDelivery == false) {

          break;
        }

      };



      //====================================================================================================

      if (setDelivery == true) {
        //console.log("about to set delivery: " + setDelivery);
        let deliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(this.deliveryDockets[deliveryDocketIndex].rowguid);

        deliveryDocket.intStatus = DeliveryDocket.enDeliveryStatus.Delivered;
        deliveryDocket.dteStatusDate = this.sharedService.currentDatePlusTZOffset();

        this.deliveryDocketService.updateDeliveryDocket(deliveryDocket).subscribe(dd => {

        }, err => {
          console.log(err);
        });

        //this.deliveryDockets.splice(deliveryDocketIndex, 1);

        this.searchDeliveryDockets();

        //this.tblDeliveryDockets.renderRows();
      };

      this.expandStockElement = null;
   }

    this.stockTransferComponent.tblTransferStockItems.renderRows();

    if (wholeStockArray[0] != null) {
      this.loadStockToReceive(wholeStockArray[0].guDeliveryDocketId);
    }



  }

  async setDeliveryDocketDelivered(deliveryDocket: dtoDeliveryDocket) {

    if (deliveryDocket == null) {
      return;
    }

    let msg: string = await this.alertService.openSnackBarCustomPromise("Set As Delivered", "Are you sure you want to set this Stock Transfer as Delivered?", "Yes", "No", "center");

    if (msg != "Yes") {
      return;
    }

    let delDocket: DeliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(deliveryDocket.rowguid);

    if (delDocket != null) {
      delDocket.intStatus = DeliveryDocket.enDeliveryStatus.Delivered;
    }

    await this.deliveryDocketService.updateDeliveryDocketPromise(delDocket);

    this.searchDeliveryDockets()

  }



}
