import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';


@Injectable()
export class AddressService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getPostCode(townName: string, state: string) {
    let path: string = "https://v0.postcodeapi.com.au/suburbs/?name=" + townName.toLowerCase() + "&state=" + state.toLowerCase()

    //let headers;

    //headers.append('Content-Type', 'application/json');
    //headers.append('Accept', 'application/json');

    //headers.append('Access-Control-Allow-Origin', 'https://localhost:44356');
    //headers.append('Access-Control-Allow-Credentials', 'true');    

    //return this.http.get(path, { headers: { 'Access-Control-Allow-Origin': 'https://localhost:44356', 'referer': 'origin' } });

    //return this.http.get(path);

    return this.http.get<Object[]>(this._baseUrl + 'api/Address/GetPostcode/' + townName + '/' + state);


  }


  async getPostCodePromise(townName: string, state: string) {
    if ((townName == null || townName == "") || (state == null || state == "")) {
      await this.alertService.openSnackBarCustomPromise("Postcode Error", "Please supply a State and a Town name", "Ok", "", "center", "bottom", "", 0, false);
      return;
    }

    return new Promise<Object[]>(resolve => {
      this.getPostCode(townName, state).subscribe(postCode => {
        resolve(postCode);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error getting postcode", "Close", "center", "bottom", 4000, true, err.error.message);
        resolve(null);
      });
    });
  }


}
