import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { Client } from '../business-objects/client.bo';

@Injectable()
export class ClientService
{
  private _customers: Client[];
	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getClient(clientId: string) {
    return this.http.get<Client>(this._baseUrl + 'api/Client/' + clientId);
	}

	getClientParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<Client[]>(this._baseUrl + 'api/Client/GetClientsParamArray/', aParam);
	}

	getAllClients() {
		return this.http.get<Client[]>(this._baseUrl + 'api/Client/GetAllClients/');
	}

	//getAlldtoClients() {
	//	return this.http.get<dtoClient[]>(this._baseUrl + 'api/Client/GetAllClients/');
	//}

	createClient(client: Client) {
		return this.http.post<Client>(this._baseUrl + 'api/Client/CreateClient/', client);
	}

	updateClient(client: Client) {
		return this.http.put<Client>(this._baseUrl + 'api/Client/UpdateClient/', client);
	}

  async getClientPromise(clientId) {
		return new Promise<Client>(resolve => {
      this.getClient(clientId).subscribe(client => {
				resolve(client);
			}, err => {
				this.alertService.openSnackBarError("Error getting Client records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

  async getClientParamArrayPromise(aParam: SQLParamArray[][], reset: boolean) {

    return new Promise<Client[]>(resolve => {
      if (this._customers != null && reset == false) {
        resolve(this._customers);
        return;
      }
			this.getClientParamArray(aParam).subscribe(clients => {
        this._customers = clients;
        resolve(clients);

			}, err => {
				this.alertService.openSnackBarError("Error getting Client records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async updateClientPromise(client: Client) {
		return new Promise<Client>(resolve => {
			this.updateClient(client).subscribe(client => {
				resolve(client);
      }, (err: HttpErrorResponse) => {
        console.log(err);
				this.alertService.openSnackBarError("Error updating Client record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createClientPromise(client) {
		return new Promise<Client>(resolve => {
			this.createClient(client).subscribe(client => {
				resolve(client);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating Client record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
  }


  async getTreatmentPlantCustomers(active: boolean, reset: boolean) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (active == true) {
      aParam.push(new SQLParamArray("blnActive", "1"));
      aParamList.push(aParam);
    }

    aParam = [];
    aParam.push(new SQLParamArray("blnTreatmentPlant", "1"));
    aParamList.push(aParam);
    return await this.getClientParamArrayPromise(aParamList, reset);
  }

  async getActiveCustomers(active: boolean, reset: boolean) {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (active == true) {
      aParam.push(new SQLParamArray("blnActive", "1"));
      aParamList.push(aParam);
    }

    return await this.getClientParamArrayPromise(aParamList, reset);
  }


}
