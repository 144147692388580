import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoCodeBuilderColumn } from '../business-objects/dto-code-builder-column.bo';

@Injectable()
export class dtoCodeBuilderColumnService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoCodeBuilderColumn(dtoCodeBuilderColumnId: string) {
		return this.http.get<dtoCodeBuilderColumn>(this._baseUrl + 'api/dtoCodeBuilderColumn/' + dtoCodeBuilderColumnId);
	}

	getdtoCodeBuilderColumnParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoCodeBuilderColumn[]>(this._baseUrl + 'api/dtoCodeBuilderColumn/GetdtoCodeBuilderColumnsParamArray/', aParam);
	}

	getAlldtoCodeBuilderColumns() {
		return this.http.get<dtoCodeBuilderColumn[]>(this._baseUrl + 'api/dtoCodeBuilderColumn/GetAlldtoCodeBuilderColumns/');
	}

	//getAlldtodtoCodeBuilderColumns() {
	//	return this.http.get<dtodtoCodeBuilderColumn[]>(this._baseUrl + 'api/dtoCodeBuilderColumn/GetAlldtoCodeBuilderColumns/');
	//}

	createdtoCodeBuilderColumn(dtoCodeBuilderColumn: dtoCodeBuilderColumn) {
		return this.http.post<dtoCodeBuilderColumn>(this._baseUrl + 'api/dtoCodeBuilderColumn/CreatedtoCodeBuilderColumn/', dtoCodeBuilderColumn);
	}

	updatedtoCodeBuilderColumn(dtoCodeBuilderColumn: dtoCodeBuilderColumn) {
		return this.http.put<dtoCodeBuilderColumn>(this._baseUrl + 'api/dtoCodeBuilderColumn/UpdatedtoCodeBuilderColumn/', dtoCodeBuilderColumn);
	}

	deletedtoCodeBuilderColumn(dtoCodeBuilderColumnId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoCodeBuilderColumn/DeletedtoCodeBuilderColumn/' + dtoCodeBuilderColumnId);
	}

	async getdtoCodeBuilderColumnPromise(dtoCodeBuilderColumnId: string) {
		return new Promise<dtoCodeBuilderColumn>(resolve => {
			this.getdtoCodeBuilderColumn(dtoCodeBuilderColumnId).subscribe(dtoCodeBuilderColumn => {
				resolve(dtoCodeBuilderColumn);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoCodeBuilderColumn records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoCodeBuilderColumnParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoCodeBuilderColumn[]>(resolve => {
			this.getdtoCodeBuilderColumnParamArray(aParam).subscribe(dtoCodeBuilderColumns => {
				resolve(dtoCodeBuilderColumns);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoCodeBuilderColumn records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoCodeBuilderColumnPromise(dtoCodeBuilderColumn: dtoCodeBuilderColumn) {
		return new Promise<dtoCodeBuilderColumn>(resolve => {
			this.updatedtoCodeBuilderColumn(dtoCodeBuilderColumn).subscribe(dtoCodeBuilderColumn => {
				resolve(dtoCodeBuilderColumn);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoCodeBuilderColumn record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoCodeBuilderColumnPromise(dtoCodeBuilderColumn: dtoCodeBuilderColumn) {
		return new Promise<dtoCodeBuilderColumn>(resolve => {
			this.createdtoCodeBuilderColumn(dtoCodeBuilderColumn).subscribe(dtoCodeBuilderColumn => {
				resolve(dtoCodeBuilderColumn);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoCodeBuilderColumn record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoCodeBuilderColumnPromise(dtoCodeBuilderColumnId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoCodeBuilderColumn(dtoCodeBuilderColumnId).subscribe(dtoCodeBuilderColumn => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoCodeBuilderColumn record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
