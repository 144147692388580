export interface AppPage
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	intApp: number;
	rowguid: string;
	txtAppPageObjectAliasName: string;
	txtAppPageObjectName: string;
	txtPageName: string;
	txtPageURL: string;
}

export class AppPage
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	intApp: number;
	rowguid: string;
	txtAppPageObjectAliasName: string;
	txtAppPageObjectName: string;
	txtPageName: string;
	txtPageURL: string;
}

