import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../account/auth.service';
import { dtoStockItem } from '../../../_shared/business-objects/dto-stock-item.bo';
import { Printer } from '../../../_shared/business-objects/printer.bo';
import { SQLParamArray } from '../../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../../_shared/services/alert.service';
import { dtoStockItemService } from '../../../_shared/services/dto-stock-item.service';
import { SharedService } from '../../../_shared/services/shared.service';

@Component({
  selector: 'app-product-code-barcode-print',
  templateUrl: './product-code-barcode-print.component.html',
  styleUrls: ['./product-code-barcode-print.component.css']
})
export class ProductCodeBarcodePrintComponent implements OnInit {

  public _baseUrl: string;
  boardPlantMobilePrinter: Printer;
  form: FormGroup;


  constructor(private fb: FormBuilder, private _snackBar: MatSnackBar, private http: HttpClient, private authService: AuthService, private sharedService: SharedService,
    @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private dtoStockItemService: dtoStockItemService) {

    this._baseUrl = baseUrl;

    this.form = this.fb.group({
      txtIdentifier: ['']
    });

  }


  ngOnInit(): void {


    // 2 is mobile printer.
    //this.sharedService.PrintServerURI
    //this.http.get<Printer>(this._baseUrl + 'api/AppPrinter/GetPrintersByAppNameAndType/WEB-APP-TP/2').subscribe(printer => {
    this.http.get<Printer>(this._baseUrl + 'api/AppPrinter/GetPrintersByAppNameAndType/WEB-APP-BP/2').subscribe(printer => {
      //console.log("treatment plant printer");
      console.log(printer[0]);

      this.boardPlantMobilePrinter = printer[0];
      console.log(JSON.stringify(printer[0]));

    }, err => {
      console.log(err);

    });



  }



  async printProductCodeBarcode() {
    //console.log(this.form.controls["txtIdentifier"].value);
    if (this.form.controls["txtIdentifier"].value == "") {
      this.alertService.openSnackBarError("You must enter a pack number", "Close", "center", "bottom", 2000, false, "");
      return false;
    }

    let identifier: number = this.form.controls["txtIdentifier"].value;

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("intIdentifier", identifier.toString()));
    aParamList.push(aParam);

    let boardTallyRecordHistory: dtoStockItem[] = await this.dtoStockItemService.getdtoStockItemParamArrayPromise(aParamList);

    if (boardTallyRecordHistory == null || boardTallyRecordHistory.length == 0) {
      this.alertService.openSnackBarCustom("Stock Item", "The stock item does not exist", "OK", "", "center", "bottom", "", 0, false);
      return false;
    }


    let path: string = this.sharedService.PrintServerURI;
    path = "https://localhost:44358/";

    //https://localhost:44358
    //this.sharedService.PrintServerURI
    this.http.post(path + 'api/PrintProductCode/PrintProductCode/' + boardTallyRecordHistory[0].rowguid.toString(), this.boardPlantMobilePrinter, { responseType: 'blob' }).subscribe(pdf => {
      //let mimeType: string = 'application/pdf';
      //let blob: any = new Blob([pdf], { type: mimeType });
      //const url = window.URL.createObjectURL(blob);
      //console.log(blob);
      //window.open(url);


      //saveAs(blob, 'testFile.txt');
      //saveAs(blob, 'testFile.jpg');
      //saveAs(blob, 'testPDF.pdf');

    }, err => {
      console.log(err);
    });;

  }

}
