import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ProductType } from '../../_shared/business-objects/product-type.bo';
import { AlertService } from './alert.service';
import { resolve } from 'path';

@Injectable()
export class ProductTypeService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getAllRoundProductTypes() {
    return this.http.get<ProductType[]>(this._baseUrl + 'api/ProductType/GetAllRoundProducts');
  }

  getAllTrimmingProductTypes() {
    return this.http.get<ProductType[]>(this._baseUrl + 'api/ProductType/GetAllTrimmingProducts');
  }
  
  getAllPoleFinalProductTypes() {
    return this.http.get<ProductType[]>(this._baseUrl + 'api/ProductType/GetAllPoleFinalProducts');
  }

  getAllProductTypes() {
    return this.http.get(this._baseUrl + 'api/ProductType/GetAllProducts');
  }
 
  getProductCodeProductTypes(gProductTypeCategoryId) {
    return this.http.get<ProductType[]>(this._baseUrl + 'api/ProductType/GetProductCodeProductTypes/' + gProductTypeCategoryId);
  }

  getProductType(gProductTypeId: string) {

    //console.log("gProductTypeId: " + gProductTypeId);

    return this.http.get<ProductType>(this._baseUrl + 'api/ProductType/' + gProductTypeId);
  }

  getProductTypePromise(gProductTypeId: string) {
    return new Promise<ProductType>(resolve => {
      this.getProductType(gProductTypeId).subscribe(pt => {
        resolve(pt);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("There was an error creating getting the product type. An email has been sent to the IT department.", "Close", "center", "bottom", 3000, true, err.message);
      });
    });
  }

}  
