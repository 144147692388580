import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { ClientDeliveryAddress } from '../business-objects/client-delivery-address.bo';

@Injectable()
export class ClientDeliveryAddressService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getClientDeliveryAddress(clientDeliveryAddressId: string) {
		return this.http.get<ClientDeliveryAddress>(this._baseUrl + 'api/ClientDeliveryAddress/' + clientDeliveryAddressId);
	}

	getClientDeliveryAddressParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<ClientDeliveryAddress[]>(this._baseUrl + 'api/ClientDeliveryAddress/GetClientDeliveryAddressParamArray/', aParam);
	}

	getAllClientDeliveryAddresss() {
		return this.http.get<ClientDeliveryAddress[]>(this._baseUrl + 'api/ClientDeliveryAddress/GetAllClientDeliveryAddress/');
	}

	//getAlldtoClientDeliveryAddresss() {
	//	return this.http.get<dtoClientDeliveryAddress[]>(this._baseUrl + 'api/ClientDeliveryAddress/GetAllClientDeliveryAddress/');
	//}

	createClientDeliveryAddress(clientDeliveryAddress: ClientDeliveryAddress) {
		return this.http.post<ClientDeliveryAddress>(this._baseUrl + 'api/ClientDeliveryAddress/CreateClientDeliveryAddress/', clientDeliveryAddress);
	}

	updateClientDeliveryAddress(clientDeliveryAddress: ClientDeliveryAddress) {
		return this.http.put<ClientDeliveryAddress>(this._baseUrl + 'api/ClientDeliveryAddress/UpdateClientDeliveryAddress/', clientDeliveryAddress);
	}

	async getClientDeliveryAddressPromise(clientDeliveryAddressId: string) {
		return new Promise<ClientDeliveryAddress>(resolve => {
			this.getClientDeliveryAddress(clientDeliveryAddressId).subscribe(clientDeliveryAddress => {
				resolve(clientDeliveryAddress);
			}, err => {
				this.alertService.openSnackBarError("Error getting ClientDeliveryAddress records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getClientDeliveryAddressParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<ClientDeliveryAddress[]>(resolve => {
			this.getClientDeliveryAddressParamArray(aParam).subscribe(clientDeliveryAddress => {
				resolve(clientDeliveryAddress);
			}, err => {
				this.alertService.openSnackBarError("Error getting ClientDeliveryAddress records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateClientDeliveryAddressPromise(clientDeliveryAddress: ClientDeliveryAddress) {
		return new Promise<ClientDeliveryAddress>(resolve => {
			this.updateClientDeliveryAddress(clientDeliveryAddress).subscribe(clientDeliveryAddress => {
				resolve(clientDeliveryAddress);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating ClientDeliveryAddress record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createClientDeliveryAddressPromise(clientDeliveryAddress: ClientDeliveryAddress) {
		return new Promise<ClientDeliveryAddress>(resolve => {
			this.createClientDeliveryAddress(clientDeliveryAddress).subscribe(clientDeliveryAddress => {
				resolve(clientDeliveryAddress);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating ClientDeliveryAddress record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}