import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoDeliveryDocketCustomerDeliveryContact } from '../business-objects/dto-delivery-docket-customer-delivery-contact.bo';

@Injectable()
export class dtoDeliveryDocketCustomerDeliveryContactService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoDeliveryDocketCustomerDeliveryContact(dtoDeliveryDocketCustomerDeliveryContactId: string) {
		return this.http.get<dtoDeliveryDocketCustomerDeliveryContact>(this._baseUrl + 'api/dtoDeliveryDocketCustomerDeliveryContact/' + dtoDeliveryDocketCustomerDeliveryContactId);
	}

	getdtoDeliveryDocketCustomerDeliveryContactParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoDeliveryDocketCustomerDeliveryContact[]>(this._baseUrl + 'api/dtoDeliveryDocketCustomerDeliveryContact/GetdtoDeliveryDocketCustomerDeliveryContactsParamArray/', aParam);
	}

	getAlldtoDeliveryDocketCustomerDeliveryContacts() {
		return this.http.get<dtoDeliveryDocketCustomerDeliveryContact[]>(this._baseUrl + 'api/dtoDeliveryDocketCustomerDeliveryContact/GetAlldtoDeliveryDocketCustomerDeliveryContacts/');
	}

	//getAlldtodtoDeliveryDocketCustomerDeliveryContacts() {
	//	return this.http.get<dtodtoDeliveryDocketCustomerDeliveryContact[]>(this._baseUrl + 'api/dtoDeliveryDocketCustomerDeliveryContact/GetAlldtoDeliveryDocketCustomerDeliveryContacts/');
	//}

	createdtoDeliveryDocketCustomerDeliveryContact(dtoDeliveryDocketCustomerDeliveryContact: dtoDeliveryDocketCustomerDeliveryContact) {
		return this.http.post<dtoDeliveryDocketCustomerDeliveryContact>(this._baseUrl + 'api/dtoDeliveryDocketCustomerDeliveryContact/CreatedtoDeliveryDocketCustomerDeliveryContact/', dtoDeliveryDocketCustomerDeliveryContact);
	}

	updatedtoDeliveryDocketCustomerDeliveryContact(dtoDeliveryDocketCustomerDeliveryContact: dtoDeliveryDocketCustomerDeliveryContact) {
		return this.http.put<dtoDeliveryDocketCustomerDeliveryContact>(this._baseUrl + 'api/dtoDeliveryDocketCustomerDeliveryContact/UpdatedtoDeliveryDocketCustomerDeliveryContact/', dtoDeliveryDocketCustomerDeliveryContact);
	}

	deletedtoDeliveryDocketCustomerDeliveryContact(dtoDeliveryDocketCustomerDeliveryContactId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoDeliveryDocketCustomerDeliveryContact/DeletedtoDeliveryDocketCustomerDeliveryContact/' + dtoDeliveryDocketCustomerDeliveryContactId);
	}

	async getdtoDeliveryDocketCustomerDeliveryContactPromise(dtoDeliveryDocketCustomerDeliveryContactId: string) {
		return new Promise<dtoDeliveryDocketCustomerDeliveryContact>(resolve => {
			this.getdtoDeliveryDocketCustomerDeliveryContact(dtoDeliveryDocketCustomerDeliveryContactId).subscribe(dtoDeliveryDocketCustomerDeliveryContact => {
				resolve(dtoDeliveryDocketCustomerDeliveryContact);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDeliveryDocketCustomerDeliveryContact records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoDeliveryDocketCustomerDeliveryContact[]>(resolve => {
			this.getdtoDeliveryDocketCustomerDeliveryContactParamArray(aParam).subscribe(dtoDeliveryDocketCustomerDeliveryContacts => {
				resolve(dtoDeliveryDocketCustomerDeliveryContacts);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDeliveryDocketCustomerDeliveryContact records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoDeliveryDocketCustomerDeliveryContactPromise(dtoDeliveryDocketCustomerDeliveryContact: dtoDeliveryDocketCustomerDeliveryContact) {
		return new Promise<dtoDeliveryDocketCustomerDeliveryContact>(resolve => {
			this.updatedtoDeliveryDocketCustomerDeliveryContact(dtoDeliveryDocketCustomerDeliveryContact).subscribe(dtoDeliveryDocketCustomerDeliveryContact => {
				resolve(dtoDeliveryDocketCustomerDeliveryContact);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoDeliveryDocketCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoDeliveryDocketCustomerDeliveryContactPromise(dtoDeliveryDocketCustomerDeliveryContact: dtoDeliveryDocketCustomerDeliveryContact) {
		return new Promise<dtoDeliveryDocketCustomerDeliveryContact>(resolve => {
			this.createdtoDeliveryDocketCustomerDeliveryContact(dtoDeliveryDocketCustomerDeliveryContact).subscribe(dtoDeliveryDocketCustomerDeliveryContact => {
				resolve(dtoDeliveryDocketCustomerDeliveryContact);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoDeliveryDocketCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoDeliveryDocketCustomerDeliveryContactPromise(dtoDeliveryDocketCustomerDeliveryContactId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoDeliveryDocketCustomerDeliveryContact(dtoDeliveryDocketCustomerDeliveryContactId).subscribe(dtoDeliveryDocketCustomerDeliveryContact => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoDeliveryDocketCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}