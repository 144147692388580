import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../account/auth.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
//  template: `
//<form [formGroup]="form">
//    <fieldset>
//        <legend>Login</legend>
//        <label *ngIf="show" class="authFail">Incorrect username or password</label>
//        <div class="form-field">
//            <label>Email:</label>
//            <input name="email" formControlName="email">
//        </div>
//        <div class="form-field">
//            <label>Password:</label>
//            <input name="password" formControlName="password" 
//                   type="password">
//        </div>
//    </fieldset>
//    <div class="form-buttons">
//        <button class="button button-primary" 
//                (click)="login()">Login</button>
//    </div>
//</form>`,
  styleUrls: ['./login.component.css']

})

export class LoginComponent {
  form: FormGroup;
  public show: boolean = false;
  returnUrl: string;

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private router: Router, private route: ActivatedRoute) {

    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.show = false;

    this.returnUrl = "";

    if (this.route.snapshot.queryParams['returnUrl'] != null) {
      console.log("login page: " + this.route.snapshot.queryParams['returnUrl']);
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    }

  }

  login() {
    const val = this.form.value;
    this.show = false;

    if (val.email && val.password) {
      this.authService.login(val.email, val.password)
        .subscribe(authUser => {
          //console.log("authUser: ", authUser);
          this.authService.setSession(authUser);
          //console.log("setSession: " + this.authService.hasTotkenExpired());

          //this.router.navigateByUrl('/?' + this.returnUrl);

          // use full URL not just querystring paramerts.
          //console.log("login return url: " + this.returnUrl);

          //window.location.href = this.returnUrl;

          console.log("login method: " + this.returnUrl);

          this.router.navigateByUrl(this.returnUrl);

       }

          , (errResponse: HttpErrorResponse) => {

            console.log("type: " + errResponse.error.errors);
            console.log("err: " + JSON.stringify(errResponse));
            this.show = true;

          //  console.log("Message: " + err.message);
          //  console.log("Error: " + err.error);
          //  console.log("content-type : " + err.headers.get("content-type"));
          //  console.log("date: " + err.headers.get("date"));
          //  console.log("server : " + err.headers.get("server"));
          //  console.log("x-powered-by : " + err.headers.get("x-powered-by"));
          //  console.log("Cache-Control : " + err.headers.get("Cache-Control"));
          //  err.headers.keys().forEach(key => {
          //    console.log("Header Keys: " + key);

          //  })
          //  console.log("Status Code: " + err.status);
          //  console.log("Status Text: " + err.statusText);
          });


    }
  }
}
