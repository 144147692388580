import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoOrdersCustomerDeliveryContact } from '../business-objects/dto-orders-customer-delivery-contact.bo';

@Injectable()
export class dtoOrdersCustomerDeliveryContactService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoOrdersCustomerDeliveryContact(dtoOrdersCustomerDeliveryContactId: string) {
		return this.http.get<dtoOrdersCustomerDeliveryContact>(this._baseUrl + 'api/dtoOrdersCustomerDeliveryContact/' + dtoOrdersCustomerDeliveryContactId);
	}

	getdtoOrdersCustomerDeliveryContactParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoOrdersCustomerDeliveryContact[]>(this._baseUrl + 'api/dtoOrdersCustomerDeliveryContact/GetdtoOrdersCustomerDeliveryContactsParamArray/', aParam);
	}

	getAlldtoOrdersCustomerDeliveryContacts() {
		return this.http.get<dtoOrdersCustomerDeliveryContact[]>(this._baseUrl + 'api/dtoOrdersCustomerDeliveryContact/GetAlldtoOrdersCustomerDeliveryContacts/');
	}

	//getAlldtodtoOrdersCustomerDeliveryContacts() {
	//	return this.http.get<dtodtoOrdersCustomerDeliveryContact[]>(this._baseUrl + 'api/dtoOrdersCustomerDeliveryContact/GetAlldtoOrdersCustomerDeliveryContacts/');
	//}

	createdtoOrdersCustomerDeliveryContact(dtoOrdersCustomerDeliveryContact: dtoOrdersCustomerDeliveryContact) {
		return this.http.post<dtoOrdersCustomerDeliveryContact>(this._baseUrl + 'api/dtoOrdersCustomerDeliveryContact/CreatedtoOrdersCustomerDeliveryContact/', dtoOrdersCustomerDeliveryContact);
	}

	updatedtoOrdersCustomerDeliveryContact(dtoOrdersCustomerDeliveryContact: dtoOrdersCustomerDeliveryContact) {
		return this.http.put<dtoOrdersCustomerDeliveryContact>(this._baseUrl + 'api/dtoOrdersCustomerDeliveryContact/UpdatedtoOrdersCustomerDeliveryContact/', dtoOrdersCustomerDeliveryContact);
	}

	deletedtoOrdersCustomerDeliveryContact(dtoOrdersCustomerDeliveryContactId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoOrdersCustomerDeliveryContact/DeletedtoOrdersCustomerDeliveryContact/' + dtoOrdersCustomerDeliveryContactId);
	}

	async getdtoOrdersCustomerDeliveryContactPromise(dtoOrdersCustomerDeliveryContactId: string) {
		return new Promise<dtoOrdersCustomerDeliveryContact>(resolve => {
			this.getdtoOrdersCustomerDeliveryContact(dtoOrdersCustomerDeliveryContactId).subscribe(dtoOrdersCustomerDeliveryContact => {
				resolve(dtoOrdersCustomerDeliveryContact);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoOrdersCustomerDeliveryContact records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoOrdersCustomerDeliveryContactParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoOrdersCustomerDeliveryContact[]>(resolve => {
			this.getdtoOrdersCustomerDeliveryContactParamArray(aParam).subscribe(dtoOrdersCustomerDeliveryContacts => {
				resolve(dtoOrdersCustomerDeliveryContacts);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoOrdersCustomerDeliveryContact records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoOrdersCustomerDeliveryContactPromise(dtoOrdersCustomerDeliveryContact: dtoOrdersCustomerDeliveryContact) {
		return new Promise<dtoOrdersCustomerDeliveryContact>(resolve => {
			this.updatedtoOrdersCustomerDeliveryContact(dtoOrdersCustomerDeliveryContact).subscribe(dtoOrdersCustomerDeliveryContact => {
				resolve(dtoOrdersCustomerDeliveryContact);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoOrdersCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoOrdersCustomerDeliveryContactPromise(dtoOrdersCustomerDeliveryContact: dtoOrdersCustomerDeliveryContact) {
		return new Promise<dtoOrdersCustomerDeliveryContact>(resolve => {
			this.createdtoOrdersCustomerDeliveryContact(dtoOrdersCustomerDeliveryContact).subscribe(dtoOrdersCustomerDeliveryContact => {
				resolve(dtoOrdersCustomerDeliveryContact);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoOrdersCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoOrdersCustomerDeliveryContactPromise(dtoOrdersCustomerDeliveryContactId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoOrdersCustomerDeliveryContact(dtoOrdersCustomerDeliveryContactId).subscribe(dtoOrdersCustomerDeliveryContact => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoOrdersCustomerDeliveryContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}