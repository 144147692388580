import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoAppPageColumn } from '../business-objects/dto-app-page-column.bo';
import { dtoAppPageColumnExt } from '../../app-page-development/app-page-development.component';

@Injectable()
export class dtoAppPageColumnService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoAppPageColumn(dtoAppPageColumnId: string) {
		return this.http.get<dtoAppPageColumn>(this._baseUrl + 'api/dtoAppPageColumn/' + dtoAppPageColumnId);
	}

	getdtoAppPageColumnParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoAppPageColumn[]>(this._baseUrl + 'api/dtoAppPageColumn/GetdtoAppPageColumnsParamArray/', aParam);
	}

  getdtoAppPageColumnExtParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoAppPageColumnExt[]>(this._baseUrl + 'api/dtoAppPageColumn/GetdtoAppPageColumnsParamArray/', aParam);
  }

	getAlldtoAppPageColumns() {
		return this.http.get<dtoAppPageColumn[]>(this._baseUrl + 'api/dtoAppPageColumn/GetAlldtoAppPageColumns/');
	}

	//getAlldtodtoAppPageColumns() {
	//	return this.http.get<dtodtoAppPageColumn[]>(this._baseUrl + 'api/dtoAppPageColumn/GetAlldtoAppPageColumns/');
	//}

	createdtoAppPageColumn(dtoAppPageColumn: dtoAppPageColumn) {
		return this.http.post<dtoAppPageColumn>(this._baseUrl + 'api/dtoAppPageColumn/CreatedtoAppPageColumn/', dtoAppPageColumn);
	}

	updatedtoAppPageColumn(dtoAppPageColumn: dtoAppPageColumn) {
		return this.http.put<dtoAppPageColumn>(this._baseUrl + 'api/dtoAppPageColumn/UpdatedtoAppPageColumn/', dtoAppPageColumn);
	}

	async getdtoAppPageColumnPromise(dtoAppPageColumn) {
		return new Promise<dtoAppPageColumn>(resolve => {
			this.getdtoAppPageColumn(dtoAppPageColumn).subscribe(dtoAppPageColumn => {
				resolve(dtoAppPageColumn);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppPageColumn records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getdtoAppPageColumnParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoAppPageColumn[]>(resolve => {
			this.getdtoAppPageColumnParamArray(aParam).subscribe(dtoAppPageColumns => {
				resolve(dtoAppPageColumns);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppPageColumn records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

  async getdtoAppPageColumnExtParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<dtoAppPageColumnExt[]>(resolve => {
      this.getdtoAppPageColumnExtParamArray(aParam).subscribe(dtoAppPageColumns => {
        resolve(dtoAppPageColumns);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoAppPageColumn records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

	async updatedtoAppPageColumnPromise(dtoAppPageColumn: dtoAppPageColumn) {
		return new Promise<dtoAppPageColumn>(resolve => {
			this.updatedtoAppPageColumn(dtoAppPageColumn).subscribe(dtoAppPageColumn => {
				resolve(dtoAppPageColumn);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoAppPageColumn record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createdtoAppPageColumnPromise(dtoAppPageColumn) {
		return new Promise<dtoAppPageColumn>(resolve => {
			this.createdtoAppPageColumn(dtoAppPageColumn).subscribe(dtoAppPageColumn => {
				resolve(dtoAppPageColumn);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoAppPageColumn record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

}
