<div class="product-code-select-container" #productCodeContainer>
  <div class="search-field-grid">
    <div class="search-input-column">
      <mat-form-field class="chh-form-field example-full-width">
        <mat-label>Search SKU</mat-label>
        <input matInput data-product-code-input="productCodeInput" class="product-code-input" placeholder="Filter by SKU" placeholderLabel="i.e. SF-TGSE....." value="" type="text" #productCodeFilter (input)="productCodeFilter.value = productCodeFilter.value.toString().toUpperCase()" (keyup)="filterProductCodes(productCodeFilter.value, $event)" (keyup.enter)="searchProdctCodes(productCodeFilter.value);" (focus)="productCodeFilter.select()" (click)="checkProductCodeListIsOpen(productCodeFilter.value)">
      </mat-form-field>
    </div>
    <div class="search-icon-column">
      <mat-icon class="clickable-icon material-icons-outlined search-icon" (click)="searchProdctCodes(productCodeFilter.value)">search</mat-icon>
    </div>
    <div class="multiple-icon-column">
      <mat-slide-toggle #multipleProductCodes (click)="checkMultipleProductCodes(multipleProductCodes.checked)" *ngIf="multipleCodes">Multiple Product Codes</mat-slide-toggle>
      <br />
      <button class="product-code-select-button" type="button" mat-flat-button color="primary" id="btnProductCodeSelect" (click)="openProductCodeSelect()">SKU Builder</button>
    </div>
  </div>


  <div class="selected-product-codes-container">
    <div class="selected-product-codes" id="selectedProductCodes" *ngIf="selectedProductCodes != null && selectedProductCodes.length > 0">
      <div class="chip-column">
        <mat-chip-listbox #chipGrid aria-label="ProductCode selection">
          @for (selectProduct of selectedProductCodes; track selectProduct; let selProdIdx = $index; let lastItem = $last) {
          <mat-chip-option (removed)="removeProductCode(selectProduct)">
            {{selectProduct.txtStockKeepingUnit}}
            <button matChipRemove [attr.aria-label]="'remove ' + selectProduct.txtName">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
          }
        </mat-chip-listbox>
      </div>
      <div class="see-more-chips">
        <button mat-icon-button aria-label="expand row" (click)="expandSelectedProductCodes($event)">
          <mat-icon class="expand-icons product-code-selection-expand-icon material-icons-outlined" *ngIf="productCodeSelectionExpanded == false ">keyboard_arrow_down</mat-icon>
          <mat-icon class="expand-icons product-code-selection-expand-icon material-icons-outlined" *ngIf="productCodeSelectionExpanded == true">keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div data-product-code-select="productCodeSelect" class="product-code-container" *ngIf="showProductCodeList" #productCodeListContainer>
    <div data-product-code-select="productCodeSelect" id="productCodeSelect" class="product-code-select-list">
      <div class="product-code-pin-row">
        <div class="button" data-push-pin="pushPin" id="btnPushPin" (click)="clickPin($event)" [ngClass]="productCodeListPinned == true ? 'button-pressed' : 'button'" matTooltip="{{toolTipText}}">
          <mat-icon data-push-pin="pushPin" class="pin-icon">push_pin</mat-icon>
        </div>
      </div>
      <div class="product-code-heading-row product-code-name-heading">Name</div>
      <div class="product-code-heading-row product-code-update-heading" *ngIf="false"></div>
      @for(productCode of productCodes; track productCode; let idx = $index){
      <div data-product-code-select="productCodeSelect" tabindex="{{idx}}" id="productCodeName{{idx}}" (keyup)="nextProductCode($event, productCode)" class="chh-list-item-row chh-list-item-first-column product-code-name" [ngClass]="productCode.guSelectedProductCodeId != '' ? 'chh-list-item-row chh-list-item-row-selected product-code-first-name' : addressHoverId == productCode.rowguid ? 'chh-list-item-row chh-list-item-row-hover product-code-first-name' : 'chh-list-item-row product-code-first-name'" (mouseenter)="rowEnter(productCode.rowguid)" (mouseleave)="rowLeave()" (click)="selectProductCode(productCode, !(productCode.guSelectedProductCodeId != ''))"><div data-product-code-select="productCodeSelect" class="product-code-text">{{productCode.txtStockKeepingUnit}}</div></div>
      <div data-product-code-select="productCodeSelect" *ngIf="false" id="productCodeUpdateDiv{{idx}}" class="chh-list-item-row product-code-radio product-code-update-div" [ngClass]="productCode.guSelectedProductCodeId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == productCode.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(productCode.rowguid)" (mouseleave)="rowLeave()" (click)="selectProductCode(productCode, !(productCode.guSelectedProductCodeId != ''))">
        <div data-product-code-select="productCodeSelect" id="productCodeUpdateButtonDiv{{idx}}" class="update-product-code-button">
          <button mat-button (click)="updateProductCode(productCode)" matTooltip="Click to edit ProductCode">
            <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
          </button>
        </div>
      </div>

      }
    </div>

  </div>
  <app-spinner *ngIf="showSpinner" [showSpinner]="showSpinner" [spinnerId]="'productCodeSpinner'" class="product-code-spinner"></app-spinner>

</div>
