<div class="hauling-company-driver-search-container" id="haulingCompanyDriverContainer">
  <form class="example-form hauling-company-driver-search-container-form" [formGroup]="form">

    <h1>Hauling Company Driver</h1>

    <mat-tab-group color="accent" class="hauling-company-driver-search-mat-tab-group" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabHaulingCompanyDrivers>
      <mat-tab label="Hauling Company Drivers" class="tab-label hauling-company-driver-search-search-tab">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Hauling Company</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. CHH-XN54MZ" value="" type="text" id="txtSearchHaulingCompany" formControlName="txtSearchHaulingCompany" (keydown.enter)="searchHaulingCompanyDrivers('')">
            </mat-form-field>
            <mat-icon matTooltip="separate text by a comma i.e. CHH-XN55MZ,Blanchards..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-3">
            <div class="button-container">
              <button class="search-hauling-company-button search-button" type="button" mat-flat-button color="primary" (click)="searchHaulingCompanyDrivers('')">Search</button>
              <button class="search-chh-drivers-button search-button" type="button" mat-flat-button color="primary" (click)="searchHaulingCompanyDrivers('chhdrivers')">CHH Drivers</button>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="hauling-company-driver-search-list-app-container">
          <app-hauling-company-driver-list #appHaulingCompanyList [datasource]="haulingCompanyDrivers" (editHaulingCompanyDriverEvent)="editHaulingCompanyDriver($event)" (addHaulingCompanyDriverEvent)="addHaulingCompanyDriver($event)" [columnBarPage]="'Hauling Company Driver'" *ngIf="haulingCompanyDrivers != null"></app-hauling-company-driver-list>
        </div>

      </mat-tab>



      <mat-tab label="Search Options" class="tab-label search-option-tab">


        <div class="search-options">
          <div class="search-option-row-1">
            <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>Driver Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Greg,James" value="" type="text" id="txtSearchDriverName" formControlName="txtSearchDriverName" (keydown.enter)="searchHaulingCompanyDrivers('')">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-6">
            <span><mat-slide-toggle formControlName="blnSearchActive">Active</mat-slide-toggle></span>
          </div>

          <div class="search-option-row-7">
            <button class="search-option-hauling-company-driver-search-button" type="button" mat-flat-button color="primary" (click)="searchHaulingCompanyDrivers('')">Search</button>
          </div>

          <mat-divider></mat-divider>
        </div>
      </mat-tab>

      <mat-tab label="Create Driver" class="tab-label">
        <div class="create-hauling-company-driver-search-container">
          <app-hauling-company-driver-create (haulingCompanyDriverCreated)="haulingCompanyDriverCreated($event)" (haulingCompanyDriverCancelled)="haulingCompanyDriverCancelled($event)" *ngIf="selectedTab == eHaulingCompanyDriverTabs.CreateHaulingCompanyDriver"></app-hauling-company-driver-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Driver" class="tab-label">
        <div class="update-hauling-company-driver-search-container">
          <app-hauling-company-driver-update [haulingCompanyDriver]="haulingCompanyDriver" [selectedHaulingCompany]="haulingCompany" [selectedEmployee]="haulingCompanyEmployee" (haulingCompanyDriverUpdated)="haulingCompanyDriverUpdated($event)" (haulingCompanyDriverCancelled)="haulingCompanyDriverCancelled($event)" *ngIf="selectedTab == eHaulingCompanyDriverTabs.UpdateHaulingCompanyDriver"></app-hauling-company-driver-update>
        </div>
      </mat-tab>

    </mat-tab-group>
  </form>
</div>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
