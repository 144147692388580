import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoPickupLocation } from '../business-objects/dto-pickup-location.bo';

@Injectable()
export class dtoPickupLocationService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoPickupLocation(dtoPickupLocationId: string) {
		return this.http.get<dtoPickupLocation>(this._baseUrl + 'api/dtoPickupLocation/' + dtoPickupLocationId);
	}

	getdtoPickupLocationParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoPickupLocation[]>(this._baseUrl + 'api/dtoPickupLocation/GetdtoPickupLocationsParamArray/', aParam);
	}

	getAlldtoPickupLocations() {
		return this.http.get<dtoPickupLocation[]>(this._baseUrl + 'api/dtoPickupLocation/GetAlldtoPickupLocations/');
	}

	//getAlldtodtoPickupLocations() {
	//	return this.http.get<dtodtoPickupLocation[]>(this._baseUrl + 'api/dtoPickupLocation/GetAlldtoPickupLocations/');
	//}

	createdtoPickupLocation(dtoPickupLocation: dtoPickupLocation) {
		return this.http.post<dtoPickupLocation>(this._baseUrl + 'api/dtoPickupLocation/CreatedtoPickupLocation/', dtoPickupLocation);
	}

	updatedtoPickupLocation(dtoPickupLocation: dtoPickupLocation) {
		return this.http.put<dtoPickupLocation>(this._baseUrl + 'api/dtoPickupLocation/UpdatedtoPickupLocation/', dtoPickupLocation);
	}

	deletedtoPickupLocation(dtoPickupLocationId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoPickupLocation/DeletedtoPickupLocation/' + dtoPickupLocationId);
	}

  async getAlldtoPickupLocationsPromise() {
    return new Promise<dtoPickupLocation[]>(resolve => {
      this.getAlldtoPickupLocations().subscribe(dtoPickupLocations => {
        resolve(dtoPickupLocations);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoPickupLocation records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getdtoPickupLocationPromise(dtoPickupLocationId: string) {
		return new Promise<dtoPickupLocation>(resolve => {
			this.getdtoPickupLocation(dtoPickupLocationId).subscribe(dtoPickupLocation => {
				resolve(dtoPickupLocation);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoPickupLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoPickupLocationParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoPickupLocation[]>(resolve => {
			this.getdtoPickupLocationParamArray(aParam).subscribe(dtoPickupLocations => {
				resolve(dtoPickupLocations);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoPickupLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoPickupLocationPromise(dtoPickupLocation: dtoPickupLocation) {
		return new Promise<dtoPickupLocation>(resolve => {
			this.updatedtoPickupLocation(dtoPickupLocation).subscribe(dtoPickupLocation => {
				resolve(dtoPickupLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoPickupLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoPickupLocationPromise(dtoPickupLocation: dtoPickupLocation) {
		return new Promise<dtoPickupLocation>(resolve => {
			this.createdtoPickupLocation(dtoPickupLocation).subscribe(dtoPickupLocation => {
				resolve(dtoPickupLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoPickupLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoPickupLocationPromise(dtoPickupLocationId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoPickupLocation(dtoPickupLocationId).subscribe(dtoPickupLocation => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoPickupLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
