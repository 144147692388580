import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';
import { BoardTallyRecord } from '../../_shared/business-objects/board-tally-record.bo';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { dtoStockItemQuantitySummary } from '../../_shared/business-objects/dto-stock-item-quantity-summary.bo';
import { dtoStockItem } from '../../_shared/business-objects/dto-stock-item.bo';
import { dtoWebPublishStockSummary } from '../../_shared/business-objects/dto-web-publish-stock-summary.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { FileService } from '../../_shared/services/app-services/file.service';
import { UtilityService } from '../../_shared/services/app-services/utility.service';
import { dtoStockItemQuantitySummaryService } from '../../_shared/services/dto-stock-item-quantity-summary.service';
import { dtoStockItemService } from '../../_shared/services/dto-stock-item.service';
import { GuestService } from '../../_shared/services/guest.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-product-detail-view',
  templateUrl: './product-detail-view.component.html',
  styleUrl: './product-detail-view.component.scss'
})
export class ProductDetailViewComponent implements OnInit {

  @Input() selectedStockSummary: dtoWebPublishStockSummary;
  @Output() closeProductDetailEvent = new EventEmitter<boolean>();

  stockItems: dtoStockItem[];
  stockItemUoM: BoardTallyRecord.enQuantityUoM;
  stockItemUoMText: string;
  totalQuantity: number;
  stockItemSetLengths: dtoIntKeyValue[];
  stockItemQtySummary: dtoStockItemQuantitySummary[];
  showSetLengths: boolean = false;
  showRandomLengths: boolean = false;

  fileAttachments: FileAttachment[];
  selectedFileAttachments: FileAttachment[] = [];
  loadFileAttachments: FileAttachment[];
  loadNumber: number;
  totalPhotos: number;

  showSpinner: boolean = false;

  paths: string[];
  photoIndex: number = 0;

  photoTouchStart: number;
  photoTouchMove: number;
  photoTouchEnd: number;

  horizontalSwipeDirection: SharedService.enHorizontalSwipeDirection = SharedService.enHorizontalSwipeDirection.None;
  horizontalTouchDiff: number;
  isSwiping: boolean = false;

  clickedNextImage: boolean = false;

  constructor(private guestService: GuestService, private utilityService: UtilityService, private dtoStockItemQuantitySummaryService: dtoStockItemQuantitySummaryService
              , private fileService: FileService, private sharedService: SharedService) {

  }

  ngOnInit() {

    console.log("webPublishStockSummary", this.selectedStockSummary);

    this.getStockItemDetail();


  }

  async getStockItemDetail() {

    if (this.selectedStockSummary == null) {
      return;
    }

    this.stockItems = await this.guestService.getWebPublishStockDetailPromise(this.selectedStockSummary);

    console.log("stock item", this.stockItems);

    if (this.stockItems == null || this.stockItems.length == 0) {
      return;
    }

    this.paths = [];
    this.stockItems.forEach(s => {
      this.paths.push(this.sharedService.StockItemPhotosURI + s.rowguid);
    });

    this.getPhotos();


    this.stockItemUoM = this.stockItems[0].intQuantityUoM;
    this.stockItemUoMText = this.stockItems[0].txtQuantityUoM.trim();
    this.totalQuantity = 0;
    this.stockItemQtySummary = [];
    let stockItemQtySum: dtoStockItemQuantitySummary[];

    //console.log("stock item stockItemUoMText", this.stockItemUoMText);
    //console.log("stock item stockItemUoMText = Each", this.stockItemUoMText == "Each");
    //console.log("this.stockItemUoM", this.stockItemUoM);
    //console.log("BoardTallyRecord.enQuantityUoM.Each", BoardTallyRecord.enQuantityUoM.Each);

    const identifiers = [...new Set(this.stockItems.map(item => item.intIdentifier))]; 

    for (let i = 0; i <= identifiers.length - 1; i++) {
      stockItemQtySum = await this.guestService.getdtoStockItemQuantitySummaryPromise(identifiers[i]);
      if (stockItemQtySum != null && stockItemQtySum.length > 0) {
        stockItemQtySum.forEach(s => { this.stockItemQtySummary.push(s) });
      }

      console.log("stockItemQtySum -- : ", stockItemQtySum);
    }

    if (this.stockItemUoM != BoardTallyRecord.enQuantityUoM.Each) {

      //const identifiers = [...new Set(this.stockItems.map(item => item.intIdentifier))]; 

      //for (let i = 0; i <= this.stockItems.length - 1; i++) {
      //  this.totalQuantity += this.stockItems[i].fltQuantity;

      //  console.log("stock item qty Non Each", this.stockItems[i].fltQuantity);

      //}

      console.log("this.totalQuantity Non Each", this.totalQuantity);

      if (this.stockItemQtySummary != null && this.stockItemQtySummary.length > 0) {
        this.showRandomLengths = true;
      }

    }

    //console.log("this.stockItemQtySummary", this.stockItemQtySummary);

    if (this.stockItemUoM == BoardTallyRecord.enQuantityUoM.Each) {

      if (this.stockItemQtySummary != null && this.stockItemQtySummary.length > 0) {
        this.showSetLengths = true;
      }

      //console.log("this.totalQuantity Each", this.totalQuantity);

    }



  }


  closeStockDetail() {
    this.closeProductDetailEvent.emit(true);

  }

  formatDollars(val: number) {
    return this.utilityService.formatDollars(val);
  }


  formatCents(val: number) {
    return this.utilityService.formatCents(val);
  }

  formatPriceType(val: string, price: number) {

    return this.utilityService.formatPriceType(val, price);
  }

  async getPhotos() {
    return new Promise<boolean>(async resolve => {
      if (this.paths == null || this.paths.length == 0) {
        //await this.alertService.openSnackBarCustomDefaultPromise("No Path", "No photo path was supplied");
        resolve(false);
      }

      this.showSpinner = true;

      //let spinner = document.getElementById("photoViewerSpinner");

      this.photoIndex = 0;
      this.totalPhotos = 0;
      this.loadNumber = 0;

      //console.log("fileNames: ", fileNames);
      this.fileAttachments = [];
      this.loadFileAttachments = [];
      
      this.loadFileAttachments = await this.guestService.getFiles(this.paths);
      this.totalPhotos = this.loadFileAttachments != null && this.loadFileAttachments.length > 0 ? this.loadFileAttachments.length : 0;

      //this.loadNumber = i + 1;
      this.showSpinner = false;
      this.fileAttachments.push(this.loadFileAttachments[this.photoIndex]);

      this.showSpinner = false;

      console.log("attachements: ", this.fileAttachments);

      setTimeout(() => {
        this.scrollListen = this.scrollListen.bind(this);
        let slider: HTMLElement = document.getElementById("slidesContainer");
        slider.addEventListener("scroll", this.scrollListen);
        console.log("slider", slider);
      }, 1000);


      resolve(true);

      //this.fileAttachments = await this.fileService.getFiles(this.path + this.folderName);
    });

  }

  scrollListen(e) {

    let imageCalcWidth: number = (e.target.scrollWidth / this.totalPhotos);
    let imgSegments: number = (1 / this.totalPhotos);
    let scrollPercentage: number = ((imageCalcWidth + e.target.scrollLeft) / e.target.scrollWidth);
    let imgWidthPlusLeft: number = (imageCalcWidth + e.target.scrollLeft);
    let calcIndex: number = Number((scrollPercentage / imgSegments).toFixed(0)) - 1;



    if (this.clickedNextImage == false) {
      this.photoIndex = calcIndex;
      //console.log("clickedNextImage: " + this.clickedNextImage);
      //console.log("this.photoIndex: " + this.photoIndex);
    }

    //this.clickedNextImage = false;

    //console.log("left: " + e.target.scrollLeft);
    //console.log(imgSegments + " : " + scrollPercentage + " : mod- " + calcIndex);

  }

  slideToNext(id) {
    let el: HTMLElement = document.getElementById("slide-image-" + id.toString());
    this.clickedNextImage = true;
    this.photoIndex = id;
    el.scrollIntoView(true);

    this.clearClickedNextImage();

  }

  clearClickedNextImage() {
    setTimeout(() => {
      this.clickedNextImage = false;
    }, 2000);
  }


  backPhoto() {
    //console.log("backPhoto Photo Index: " + this.photoIndex);
    //console.log("backPhoto this.totalPhotos: " + this.totalPhotos);

    if (this.photoIndex - 1 == -1) {
      return;
    }

    this.clickedNextImage = true;

    this.photoIndex -= 1;

    let el: HTMLElement = document.getElementById("slide-image-" + this.photoIndex.toString());
    el.scrollIntoView(true);
    console.log("backPhoto el: ", el);

    this.clearClickedNextImage();

    //this.fileAttachments.length = 0;
    //this.photoIndex -= 1;
    //this.fileAttachments.push(this.loadFileAttachments[this.photoIndex]);

    //console.log("backPhoto Photo Index - 1: " + this.photoIndex);

  }

  forwardPhoto(img: HTMLImageElement) {

    //this.swipeLeft();

    //console.log("forwardPhoto Photo Index: " + this.photoIndex);
    //console.log("forwardPhoto this.totalPhotos: " + this.totalPhotos);

    if (this.photoIndex + 1 == this.totalPhotos) {
      return;
    }

    this.clickedNextImage = true;

    this.photoIndex += 1;

    let el: HTMLElement = document.getElementById("slide-image-" + this.photoIndex.toString());
    el.scrollIntoView(true);
    //console.log("forwardPhoto el: ", el);

    this.clearClickedNextImage();

    //this.fileAttachments.length = 0;
    //this.photoIndex += 1;
    //this.fileAttachments.push(this.loadFileAttachments[this.photoIndex]);

    //console.log("forwardPhoto Photo Index + 1: " + this.photoIndex);

  }

  openPhoto(fileAttachment) {


    this.fileService.viewPhotoZoom(fileAttachment);

  }


  touchimage(e) {
    //this.photoIndex = this.photoIndex;

    //console.log("e: ", e);

    console.log("photoIndex: ", this.photoIndex);

  }


  swipeLeft() {
    let img2: HTMLImageElement = document.getElementById("productImage") as HTMLImageElement;

    console.log("img: ", img2);

    img2.classList.toggle("product-image-swipe-left");

    setTimeout(() => {
      img2.classList.toggle("product-image-swipe-left");
    }, 500);


  }


  logTouchstart(e: TouchEvent, img: HTMLImageElement) {

    img.style.top = e.touches[0].clientX.toString();
    img.style.position = 'absolute';
    this.photoTouchStart = e.touches[0].clientX;

    console.log("touch start top: ", img.style.top);
    console.log("touch start: ", e.touches[0].clientX);
  }

  logTouchmove(e: TouchEvent, img: HTMLImageElement) {
    this.photoTouchMove = e.targetTouches[0].clientX;
    //console.log("photoTouch Diff: ", this.photoTouchMove - this.photoTouchStart);

    //console.log("this.photoTouchStart: ", this.photoTouchStart);
    //console.log("img: ", img.clientLeft);
    //console.log("touch move: ", e.targetTouches[0].clientX);

    //img.style.top = e.targetTouches[0]. "0px";
    this.horizontalTouchDiff = this.photoTouchMove - this.photoTouchStart;

    img.style.left = this.horizontalTouchDiff.toString() + "px";
    this.horizontalSwipeDirection = this.horizontalTouchDiff < 0 ? SharedService.enHorizontalSwipeDirection.Left : SharedService.enHorizontalSwipeDirection.Right;

    // If we have swiped more than 20% of image width then slide out image and slide in next image
    if (this.isSwiping == false && (Math.abs(this.horizontalTouchDiff / img.width) > 0.2)) {
      //img.classList.toggle("product-image-swipe-left");
      this.swipeLeft();

      this.isSwiping = true;
    }

    console.log("horizontalTouchDiff ", Math.abs(this.horizontalTouchDiff / img.width));

    //console.log("img.style.width: ", this.horizontalTouchDiff / img.width);

    //console.log("img.style.position: ", img.style.position);
    //console.log("img.style.left: ", img.style.left);

  }

  logTouchend(e: TouchEvent, img: HTMLImageElement) {
    img.style.position = 'relative';
    if (this.isSwiping == false) {
      img.style.left = "0px";
    }

    if (this.isSwiping == true) {
      setTimeout(()=>{
        img.style.left = "0px";
        if (this.horizontalSwipeDirection == SharedService.enHorizontalSwipeDirection.Left) {
          this.forwardPhoto(img);
        }

        if (this.horizontalSwipeDirection == SharedService.enHorizontalSwipeDirection.Right) {
          this.backPhoto();
        }

        this.isSwiping = false;

      }, 300);
    }

    //img.style.top = "0px";

    console.log("touch end: ", e.changedTouches[0].clientX);
  }


}
