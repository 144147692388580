import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { OrdersDeliveryRate } from '../business-objects/orders-delivery-rate.bo';

@Injectable()
export class OrdersDeliveryRateService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getOrdersDeliveryRate(ordersDeliveryRateId: string) {
		return this.http.get<OrdersDeliveryRate>(this._baseUrl + 'api/OrdersDeliveryRate/' + ordersDeliveryRateId);
	}

	getOrdersDeliveryRateParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<OrdersDeliveryRate[]>(this._baseUrl + 'api/OrdersDeliveryRate/GetOrdersDeliveryRatesParamArray/', aParam);
	}

	getAllOrdersDeliveryRates() {
		return this.http.get<OrdersDeliveryRate[]>(this._baseUrl + 'api/OrdersDeliveryRate/GetAllOrdersDeliveryRates/');
	}

	//getAlldtoOrdersDeliveryRates() {
	//	return this.http.get<dtoOrdersDeliveryRate[]>(this._baseUrl + 'api/OrdersDeliveryRate/GetAllOrdersDeliveryRates/');
	//}

	createOrdersDeliveryRate(ordersDeliveryRate: OrdersDeliveryRate) {
		return this.http.post<OrdersDeliveryRate>(this._baseUrl + 'api/OrdersDeliveryRate/CreateOrdersDeliveryRate/', ordersDeliveryRate);
	}

	updateOrdersDeliveryRate(ordersDeliveryRate: OrdersDeliveryRate) {
		return this.http.put<OrdersDeliveryRate>(this._baseUrl + 'api/OrdersDeliveryRate/UpdateOrdersDeliveryRate/', ordersDeliveryRate);
	}

  deleteOrdersDeliveryRate(ordersDeliveryRateId: string) {
    return this.http.delete(this._baseUrl + 'api/OrdersDeliveryRate/DeleteOrdersDeliveryRate/' + ordersDeliveryRateId);
  }

	async getOrdersDeliveryRatePromise(ordersDeliveryRateId: string) {
		return new Promise<OrdersDeliveryRate>(resolve => {
			this.getOrdersDeliveryRate(ordersDeliveryRateId).subscribe(ordersDeliveryRate => {
				resolve(ordersDeliveryRate);
			}, err => {
				this.alertService.openSnackBarError("Error getting OrdersDeliveryRate records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getOrdersDeliveryRateParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<OrdersDeliveryRate[]>(resolve => {
			this.getOrdersDeliveryRateParamArray(aParam).subscribe(ordersDeliveryRates => {
				resolve(ordersDeliveryRates);
			}, err => {
				this.alertService.openSnackBarError("Error getting OrdersDeliveryRate records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateOrdersDeliveryRatePromise(ordersDeliveryRate: OrdersDeliveryRate) {
		return new Promise<OrdersDeliveryRate>(resolve => {
			this.updateOrdersDeliveryRate(ordersDeliveryRate).subscribe(ordersDeliveryRate => {
				resolve(ordersDeliveryRate);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating OrdersDeliveryRate record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async deleteOrdersDeliveryRatePromise(ordersDeliveryRateId: string) {
    return new Promise<boolean>(resolve => {
      this.deleteOrdersDeliveryRate(ordersDeliveryRateId).subscribe(ordersDeliveryRate => {
        resolve(true);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error deleting OrdersDeliveryRate record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(false);
      });
    });
  }

	async createOrdersDeliveryRatePromise(ordersDeliveryRate: OrdersDeliveryRate) {
		return new Promise<OrdersDeliveryRate>(resolve => {
			this.createOrdersDeliveryRate(ordersDeliveryRate).subscribe(ordersDeliveryRate => {
				resolve(ordersDeliveryRate);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating OrdersDeliveryRate record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}
