import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
export interface NGChart {
  constructor(chartOptions: ChartOptions, chartLabels: string[], chartType: ChartType, chartLegend: boolean, chartData: ChartDataset[])
  chartOptions: ChartOptions
  chartLabels: string[];
  chartType: ChartType;
  chartLegend: boolean;
  chartData: ChartDataset[];
}

export class NGChart {
  constructor(chartOptions: ChartOptions, chartLabels: string[], chartType: ChartType, chartLegend: boolean, chartData: ChartDataset[]) {
    this.chartOptions = chartOptions;
    this.chartLabels = chartLabels;
    this.chartType = chartType;
    this.chartLegend = chartLegend;
    this.chartData = chartData;
  }

  chartOptions: ChartOptions;
  chartLabels: string[];
  chartType: ChartType;
  chartLegend: boolean;
  chartData: ChartDataset[];
}
