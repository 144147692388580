import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoGrade } from '../business-objects/dto-grade.bo';

@Injectable()
export class dtoGradeService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoGrade(dtoGradeId: string) {
		return this.http.get<dtoGrade>(this._baseUrl + 'api/dtoGrade/' + dtoGradeId);
	}

	getdtoGradeParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoGrade[]>(this._baseUrl + 'api/dtoGrade/GetdtoGradesParamArray/', aParam);
	}

	getAlldtoGrades() {
		return this.http.get<dtoGrade[]>(this._baseUrl + 'api/dtoGrade/GetAlldtoGrades/');
	}

	//getAlldtodtoGrades() {
	//	return this.http.get<dtodtoGrade[]>(this._baseUrl + 'api/dtoGrade/GetAlldtoGrades/');
	//}

	createdtoGrade(dtoGrade: dtoGrade) {
		return this.http.post<dtoGrade>(this._baseUrl + 'api/dtoGrade/CreatedtoGrade/', dtoGrade);
	}

	updatedtoGrade(dtoGrade: dtoGrade) {
		return this.http.put<dtoGrade>(this._baseUrl + 'api/dtoGrade/UpdatedtoGrade/', dtoGrade);
	}

	deletedtoGrade(dtoGradeId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoGrade/DeletedtoGrade/' + dtoGradeId);
	}

  async getdtoAllGrades() {
    return new Promise<dtoGrade[]>(resolve => {
      this.getAlldtoGrades().subscribe(dtoGrade => {
        resolve(dtoGrade);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoGrade records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getdtoGradePromise(dtoGradeId: string) {
		return new Promise<dtoGrade>(resolve => {
			this.getdtoGrade(dtoGradeId).subscribe(dtoGrade => {
				resolve(dtoGrade);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoGrade records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoGradeParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoGrade[]>(resolve => {
			this.getdtoGradeParamArray(aParam).subscribe(dtoGrades => {
				resolve(dtoGrades);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoGrade records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoGradePromise(dtoGrade: dtoGrade) {
		return new Promise<dtoGrade>(resolve => {
			this.updatedtoGrade(dtoGrade).subscribe(dtoGrade => {
				resolve(dtoGrade);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoGrade record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoGradePromise(dtoGrade: dtoGrade) {
		return new Promise<dtoGrade>(resolve => {
			this.createdtoGrade(dtoGrade).subscribe(dtoGrade => {
				resolve(dtoGrade);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoGrade record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoGradePromise(dtoGradeId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoGrade(dtoGradeId).subscribe(dtoGrade => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoGrade record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
