import { Component, Input, OnInit } from '@angular/core';
import { NGChart } from '../_shared/app-objects/ng-chart';
import { dtoDeliveredSalesBySectionDetailAll } from '../_shared/business-objects/dto-delivered-sales-by-section-detail-all.bo';
import { dtoTaxInvoiceDailyTotals } from '../_shared/business-objects/dto-tax-invoice-daily-totals.bo';
import { dtoTaxInvoiceDailyTotalsService } from '../_shared/business-objects/dto-tax-invoice-daily-totals.service';

import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { dtoDeliveredSalesBySectionDetailAllService } from '../_shared/services/dto-delivered-sales-by-section-detail-all.service';
import { SharedService } from '../_shared/services/shared.service';
import { NavigationService } from '../_shared/services/navigation.service';
import { borderBottomLeftRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';



@Component({
  selector: 'app-report-centre',
  templateUrl: './report-centre.component.html',
  styleUrls: ['./report-centre.component.css']
})
export class ReportCentreComponent implements OnInit {

  @Input() chartDataSet: ChartDataset[];
  @Input() chartLabel: string[];
  @Input() chartType: ChartType;
  @Input() chartLegend: boolean;
  @Input() chartOptions: ChartOptions;


  public lineChart2: NGChart;

  deliveredSales: dtoDeliveredSalesBySectionDetailAll[];

  constructor(private deliveredSalesBySectionDetailAllService: dtoDeliveredSalesBySectionDetailAllService, private dtoTaxInvoiceDailyTotalsService: dtoTaxInvoiceDailyTotalsService
      , private navigationService: NavigationService) {

  }

  public chartPlugins = [ChartDataLabels];

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: 10,
        font: {
          size: 20,
        }
      }
    }
    //legend: {
    //  display: true,
    //  labels: {
    //    fontColor: 'rgb(255, 99, 132)'
    //  }
    //}
  };

  public lineChartLabels = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public lineChartType = 'line';
  public lineChartLegend = true;
  //public lineChartData = [
  //  { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  //  { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  //];

  public lineChartData = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' }
   ];


  public barChartLabels = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];


  ngOnInit(): void {

    //let lc: ChartDataset;

    //let sectionName: string[] = [];
    //sectionName.push("Board Plant");
    //sectionName.push("Treatment");

    //this.deliveredSalesBySectionDetailAllService.getAlldtoDeliveredSalesBySectionDetailAlls(sectionName).subscribe(sales => {

    //  console.log("sales",sales);

    //}, err => {
    //  console.log(err);
    //});


    this.getTaxInvoiceDailyTotals();

  }

  async getTaxInvoiceDailyTotals() {

    let saleLocationId: string = await this.navigationService.getLocationGuid("Coffs");
    saleLocationId = await this.navigationService.getLocationGuid("HQ");

    let taxInvoiceDailyTotals: dtoTaxInvoiceDailyTotals[] = await this.dtoTaxInvoiceDailyTotalsService.getdtoTaxInvoiceDailyTotalsByDates("2024-01-01", "2024-06-30", saleLocationId);

    console.log("taxInvoiceDailyTotals", taxInvoiceDailyTotals);
    let chartTitle: string = "Lawson Cresent POS Sales";

    let chartOptions: ChartOptions = {
      responsive: true,
      layout: {
        padding: 50
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 1,
          font: {
            size: 15,
            weight: 'bold'
          },
          formatter: function (value, context) {
            return '$' + value;
          }
        },
        title: {
          display: true,
          text: chartTitle,
          font: {
            size: 40,
            weight: 'bold'
          }
        }
      },
      scales: {
        y: {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return '$' + value;
            },
            font: {
              size: 15
            }
          }
        }
      }
    }

    let chartDataSet: ChartDataset[];
    chartDataSet = [{ data: taxInvoiceDailyTotals.map((a) => { return Math.round(a.fltTotalPriceExGST) }), label: 'POS Daily Totals', tension: 0.2 }];

    let chartLabel: string[] = taxInvoiceDailyTotals.map((a) => { return new Date(a.dteDateCreated).toLocaleDateString('en-AU', { weekday: 'long' }) + '\n' + new Date(a.dteDateCreated).toLocaleDateString() });
    let chartType: ChartType = "line";
    let chartLegend: boolean = true;
    
    this.lineChart2 = new NGChart(chartOptions, chartLabel, chartType, chartLegend, chartDataSet);


    //this.lineChartData[0].data = taxInvoiceDailyTotals.map((a) => { return a.fltTotalPriceExGST });
    //this.lineChartData[0].label = "Daily Totals";

    //console.log("this.lineChartData[0].data", this.lineChartData[0].data);

    //this.lineChartLabels = taxInvoiceDailyTotals.map((a) => { return new Date(a.dteDateCreated).toLocaleDateString('en-AU', { weekday: 'long' }) + '\n\n' + new Date(a.dteDateCreated).toLocaleDateString() });

    //console.log("this.lineChartLabels", this.lineChartLabels);

  }


  createGraph() {
    


  }

}

  
