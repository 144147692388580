export const OAuthSettings = {
  appId: '7f254664-037c-4e43-b74d-d799b185d124',
  redirectUri: '',
  scopes: [
    "user.read",
    "mail.send",
    "mailboxsettings.read",
    "calendars.readwrite",
    "Notes.Read",
    "Notes.Create",
    "Notes.ReadWrite",
    "Notes.Read.All",
    "Files.ReadWrite.All",
    "Sites.ReadWrite.All"
  ]
};

