export interface AppPageColumn
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guAppPageId: string;
	intDefaultColumnOrder: number;
	rowguid: string;
	txtColumnName: string;
	txtColumnNameAlias: string;
}

export class AppPageColumn
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guAppPageId: string;
	intDefaultColumnOrder: number;
	rowguid: string;
	txtColumnName: string;
	txtColumnNameAlias: string;
}

