<div class="final-inspection-item-update-form" [formGroup]="form">
  <h1>Update Inspection Item</h1>
  <div class="final-inspection-item-update-container">
    <div class="final-inspection-item-update-form-container">
      <div class="header-container">
        <div class="fields-container">
          <!--Outside div-->
          <div class="spinner-container" *ngIf="showSpinner">
            <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
              <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
            </div>
          </div>

          <div class="search-option-row-4" #dateinspected>
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created From</mat-label>
              <input id="dteDateCreated" class="date-input created-from-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteDateCreated">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>
          <br />

          <div class="header-row-4" #customer>
            <div class="header-row-3-container">

              <mat-form-field class="chh-form-field">
                <div class="customer-input-container">
                  <input matInput class="textbox-input" placeholder="Filter Customers" placeholderLabel="i.e. Essential ....." value="" type="text" #customerFilter (keyup)="searchCustomers(customerFilter.value)" (focus)="customerFilter.select()">
                </div>
              </mat-form-field>
              <mat-selection-list class="customer-select" #guCustomerId id="guCustomerId" [multiple]="false" formControlName="guCustomerId" (selectionChange)="customerChanged($event)">
                <mat-list-option *ngFor="let customer of customers" [value]="customer.rowguid">
                  {{customer.txtName}}
                </mat-list-option>
              </mat-selection-list>

              <mat-form-field class="chh-form-field example-full-width header-button" style="width: 600px !important" appearance="fill" *ngIf="false">
                <mat-select class="final-item-customers" formControlName="guCustomerId" id="guCustomerId" placeholder="Customer" #singleSelect (selectionChange)="customerChanged($event)">
                  <mat-option class="final-item-customer">
                    <ngx-mat-select-search placeholderLabel="i.e. Essential ....." [formControl]="customersFilterCtrl" noEntriesFoundLabel="There are no customers" ngDefaultControl></ngx-mat-select-search>
                  </mat-option>
                  <mat-option class="customers" *ngFor="let customer of filteredCustomers | async" [value]="customer.rowguid">
                    {{customer.txtName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <br />

          <div class="header-row-4" #order>
            <div class="header-row-3-container">

              <mat-form-field class="chh-form-field">
                <div class="orders-input-container">
                  <input matInput class="orders-input" placeholder="Filter Orders" placeholderLabel="i.e. EE000 ....." value="" type="text" #ordersFilter (keyup)="searchOrders(ordersFilter.value)" (focus)="ordersFilter.select()">
                </div>
              </mat-form-field>
              <mat-selection-list class="orders-select" #guOrderId id="guOrderId" [multiple]="false" formControlName="guOrderId" (selectionChange)="orderChanged($event.options[0].value)">
                <mat-list-option *ngFor="let order of orders" [value]="order">
                  {{order.txtOrderNo}}
                </mat-list-option>
              </mat-selection-list>

              <mat-form-field class="chh-form-field example-full-width header-button" style="width: 600px !important" appearance="fill" *ngIf="false">
                <mat-select class="final-item-orders" formControlName="guOrderId" placeholder="Order Number" #singleSelect (selectionChange)="orderChanged($event.value)">
                  <mat-option class="final-item-order">
                    <ngx-mat-select-search placeholderLabel="i.e. 4500056 ...." [formControl]="ordersFilterCtrl" noEntriesFoundLabel="There are no orders" ngDefaultControl></ngx-mat-select-search>
                  </mat-option>
                  <mat-option class="orders" *ngFor="let order of filteredOrders | async" [value]="order">
                    {{order.txtOrderNo}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <br />

          <div class="header-row-4" #orderitem>
            <div class="header-row-3-container">

              <mat-form-field class="chh-form-field">
                <div class="order-item-input-container">
                  <input matInput class="order-item-input" placeholder="Filter Order Items" placeholderLabel="i.e. Item 1 - Pole, 12.5/8 ....." value="" type="text" #orderItemFilter (keyup)="searchOrderItems(orderItemFilter.value)" (focus)="orderItemFilter.select()">
                </div>
              </mat-form-field>
              <mat-selection-list class="chh-form-field order-item-select" #guOrderItemId id="guOrderItemId" [multiple]="false" formControlName="guOrderItemId" (selectionChange)="orderItemChanged($event.options[0].value)">
                <mat-list-option *ngFor="let orderItem of orderItems" [value]="orderItem">
                  <div class="order-item-description">{{orderItem.txtDescription}}{{orderItem.txtAuthorityCode != null && orderItem.txtAuthorityCode != '' ? ' - ' + orderItem.txtAuthorityCode : ''}}</div><div class="order-item-on-order">({{orderItem.blnStock == false ? 'On Order:' : 'In Stock:'}} {{orderItem.fltQuantityTotal}})</div><div class="order-item-balance">{{orderItem.blnStock == false ? '(Bal: ' : ''}} {{orderItem.blnStock == false ? orderItem.fltQuantityTotal - orderItem.intFinalCount + ')': ''}}</div>
                </mat-list-option>
              </mat-selection-list>

              <mat-form-field class="chh-form-field example-full-width header-button" style="width: 600px !important" appearance="fill" *ngIf="false">
                <mat-select class="final-item-order-item" formControlName="guOrderItemId" placeholder="Order Item" #singleSelect (selectionChange)="orderItemChanged($event.value)">
                  <mat-option class="final-item-order">
                    <ngx-mat-select-search placeholderLabel="i.e. Item 1 - Pole, 12.5/8 ...." [formControl]="ordersFilterCtrl" noEntriesFoundLabel="There are no orders" ngDefaultControl></ngx-mat-select-search>
                  </mat-option>
                  <mat-option class="orderitems" *ngFor="let orderItem of filteredOrderItems | async" [value]="orderItem">
                    <div class="order-item-description">{{orderItem.txtDescription}}</div><div class="order-item-on-order">({{orderItem.blnStock == false ? 'On Order:' : 'In Stock:'}} {{orderItem.fltQuantityTotal}})</div><div class="order-item-balance">{{orderItem.blnStock == false ? '(Bal: ' : ''}} {{orderItem.blnStock == false ? orderItem.fltQuantityTotal - orderItem.intFinalCount + ')': ''}}</div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <br />

          <div class="header-row-4" #polenumber>
            <div class="header-row-3-container">
              <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Pole Number</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 459980" value="" type="number" id="txtPoleNumber" formControlName="txtPoleNumber" #polenumbertextbox>
              </mat-form-field>
            </div>
          </div>
          <br />

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Consecutive Number</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 25416" value="" type="text" id="txtConsecutiveNumber" formControlName="txtConsecutiveNumber">
              </mat-form-field>
              <button class="stocktakeButton" mat-flat-button color="primary" (click)="selectConsecutiveNumber()" [disabled]="chargeNumber.value == '' || treatmentYear.value == ''">Select Consecutive Number</button>
            </div>
          </div>
          <br />

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Charge Number</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 215" value="" type="number" id="txtChargeNumber" formControlName="txtChargeNumber" #chargeNumber onKeyPress="if(this.value.length == 3) return false;">
                <mat-error *ngIf="txtChargeNumber.invalid">
                  The Charge Number is invalid.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <br />

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="chh-form-field example-full-width header-button" style="width: 110px !important" appearance="fill">
                <mat-label>Treat. Month</mat-label>
                <input matInput placeholder="i.e  6" type="number" value="" id="txtTreatmentMonth" formControlName="txtTreatmentMonth" class="toUpperCase" #treatmentMonth (keyup)="txtTreatmentMonth.invalid == false ? testTreatmentMonth(treatmentMonth) : null">
                <mat-hint align="end">{{treatmentMonthHint}}</mat-hint>
                <mat-error *ngIf="txtTreatmentMonth.invalid">
                  {{
                  validateTreatmentMonth(txtTreatmentMonth)
                  }}
                </mat-error>
              </mat-form-field><span> / </span>
              <mat-form-field class="chh-form-field example-full-width header-button" style="width: 110px !important" appearance="fill">
                <mat-label>Treat. Year</mat-label>
                <input matInput placeholder="i.e  22" type="tel" value="" id="txtTreatmentYear" formControlName="txtTreatmentYear" class="toUpperCase" #treatmentYear>
                <mat-hint align="end">{{treatmentYearHint}}</mat-hint>
                <mat-error *ngIf="txtTreatmentYear.invalid">
                  {{
                  validateTreatmentYear(txtTreatmentYear)
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <br />

          <div class="original-section">
            <h6>Original Item</h6>
            <mat-selection-list class="product-type-select" #guOriginalProductTypeId [multiple]="false" formControlName="guOriginalProductTypeId" (selectionChange)="onOriginalProductSelect(guOriginalProductTypeId._value[0])">
              <mat-list-option *ngFor="let ProductType of productTypes" [value]="ProductType.rowguid" color="accent">
                {{ProductType.txtName}}
              </mat-list-option>
            </mat-selection-list>
            <br />
            <br />


            <mat-form-field class="chh-form-field example-full-width example-container" appearance="fill">
              <mat-label>Original Length</mat-label>
              <input matInput placeholder="" value="" type="number" formControlName="txtOriginalLength">
              <mat-hint align="end">{{originalLengthHint}}</mat-hint>
              <mat-error *ngIf="txtOriginalLength.invalid">
                {{
                  validateLength(txtOriginalLength)
                }}
              </mat-error>
            </mat-form-field>
            &nbsp;
            <mat-form-field class="chh-form-field example-full-width example-container" appearance="fill">
              <mat-label>Original KN</mat-label>
              <input matInput placeholder="" value="" type="number" formControlName="txtOriginalKN">
              <mat-hint align="end">{{originalKNHint}}</mat-hint>
              <mat-error *ngIf="txtOriginalKN.invalid">
                {{
                  validateKN(txtOriginalKN)
                }}
              </mat-error>
            </mat-form-field>&nbsp;

            <mat-checkbox class="example-margin" formControlName="chkOriginalQV" (change)="onOriginalQVClick()">QV</mat-checkbox>
            <mat-checkbox class="example-margin" formControlName="chkOriginalSub">Sub</mat-checkbox>
            <mat-checkbox class="example-margin" formControlName="chkOriginalRegal">Regal</mat-checkbox>
            <br />

          </div>
          <br />

          <div class="header-row-4">
            <div class="header-row-3-container">
              <label id="example-rejected-label"></label>
              <mat-checkbox class="example-margin" formControlName="chkRejected">Rejected</mat-checkbox>
            </div>
          </div>
          <br />

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="chh-form-field example-full-width header-button" style="width: 400px !important" appearance="fill">
                <mat-label>Rejected Comments</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Punk" value="" type="text" id="txtRejectedComments" formControlName="txtRejectedComments">
              </mat-form-field>
            </div>
          </div>
          <br />

          <br />

          <div class="finished-section">
            <h6>Finished Item</h6>
            <mat-selection-list class="product-type-select" #guFinishedProductTypeId [multiple]="false" formControlName="guFinishedProductTypeId" (selectionChange)="onFinishedProductSelect(guFinishedProductTypeId._value[0])">
              <mat-list-option *ngFor="let ProductType of productTypes" [value]="ProductType.rowguid" color="accent">
                {{ProductType.txtName}}
              </mat-list-option>
            </mat-selection-list>
            <br />
            <br />

            <mat-form-field class="chh-form-field example-full-width example-container" appearance="fill">
              <mat-label>Finished Lenth</mat-label>
              <input matInput placeholder="" value="" type="number" formControlName="txtFinishedLength">
              <mat-hint align="end">{{finishedLengthHint}}</mat-hint>
              <mat-error *ngIf="txtFinishedLength.invalid">
                {{
        validateLength(txtFinishedLength)
                }}
              </mat-error>
            </mat-form-field>
            &nbsp;
            <mat-form-field class="chh-form-field example-full-width example-container" appearance="fill">
              <mat-label>Finished KN</mat-label>
              <input matInput placeholder="" value="" type="number" formControlName="txtFinishedKN">
              <mat-hint align="end">{{finishedKNHint}}</mat-hint>
              <mat-error *ngIf="txtFinishedKN.invalid">
                {{
        validateKN(txtFinishedKN)
                }}
              </mat-error>
            </mat-form-field>&nbsp;
            <mat-checkbox class="example-margin" formControlName="chkFinishedQV" (change)="onFinishedQVClick()">QV</mat-checkbox>
            <mat-checkbox class="example-margin" formControlName="chkFinishedSub">Sub</mat-checkbox>
            <mat-checkbox class="example-margin" formControlName="chkFinishedRegal">Regal</mat-checkbox>
            <br />
            <br />

            <div class="header-row-4">
              <div class="header-row-3-container">
                <mat-form-field class="chh-form-field example-full-width header-button" style="width: 300px !important" appearance="fill">
                  <mat-label>Pole Specification</mat-label>
                  <mat-select class="final-item-order-item" formControlName="guElectricalAuthoritySpecId" placeholder="Pole Specification" #singleSelect>
                    <mat-option class="electrical-authority-spec" *ngFor="let elecAuthSpec of elecAuthSpecs" [value]="elecAuthSpec.rowguid">
                      {{elecAuthSpec.txtAuthorityName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <br />
            <br />


            <mat-form-field class="chh-form-field example-full-width example-container" appearance="fill">
              <mat-label>{{finishedDiaPlaceHolder}}</mat-label>
              <input matInput placeholder="" value="" type="number" formControlName="fltFinishedDiameter">
              <mat-hint align="end">{{finishedDiamHint}}</mat-hint>
              <mat-error *ngIf="fltFinishedDiameter.invalid">
                {{
        validateDiameter(fltFinishedDiameter, chkFinishedQV.value)
                }}
              </mat-error>
            </mat-form-field>
            &nbsp;
            <mat-form-field appearance="fill" class="chh-form-field">
              <mat-label>Diameter Type</mat-label>
              <mat-select formControlName="intFinishedDiameterType" (selectionChange)="finishedDiameterTypeChange($event.value)">
                <mat-option class="diameter-type" *ngFor="let diameterType of eDiameterTypes" [value]="diameterType.intValue">
                  {{diameterType.txtValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br />
            <br />

            <mat-form-field class="chh-form-field example-full-width example-container" appearance="fill">
              <mat-label>{{finishedGirthPlaceHolder}}</mat-label>
              <input matInput placeholder="" value="" type="number" formControlName="fltFinishedGirth">
              <mat-hint align="end">{{finishedGirthHint}}</mat-hint>
              <mat-error *ngIf="fltFinishedGirth.invalid">
                {{
        validateDiameter(fltFinishedDiameter, chkFinishedQV.value)
                }}
              </mat-error>
            </mat-form-field>
            <br />
            <br />
          </div>


          <div class="header-row-4">
            <div class="header-row-3-container">
              <label id="example-drilled-label"></label>
              <mat-checkbox class="example-margin" formControlName="chkDrilled">Drilled</mat-checkbox>
            </div>
          </div>
          <br />
          <br />

          <div class="species">
            <div class="species">
              <mat-form-field class="chh-form-field">
                <div class="species-input-container">
                  <input matInput class="species-input" placeholder="Filter Species" placeholderLabel="i.e. BBT ....." value="" type="text" #speciesFilter (keyup)="searchSpecies(speciesFilter.value)" (focus)="speciesFilter.select()">
                </div>
              </mat-form-field>
              <mat-selection-list class="speices-select" #speciesListBox [multiple]="false" id="guSpeciesId" formControlName="guSpeciesId">
                <mat-list-option *ngFor="let Species of species" [value]="Species.rowguid">
                  {{Species.txtCodeAlias != null && Species.txtCodeAlias != "" ? Species.txtCodeAlias : Species.txtCode}} - {{Species.txtName}}
                </mat-list-option>
              </mat-selection-list>
              <br />
            </div>
            <br />


          </div> <!--Outside Div -->


        </div>
      </div>

    </div>
  </div>
  <div class="button-container">
    <button class="stocktakeButton" mat-flat-button color="primary" (click)="save('Update')" [disabled]="saving">Update Inspection Item</button>
    <button class="stocktakeButton" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
  </div>
</div>
