import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoHaulingCompany } from '../business-objects/dto-hauling-company.bo';

@Injectable()
export class dtoHaulingCompanyService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoHaulingCompany(dtoHaulingCompanyId: string) {
		return this.http.get<dtoHaulingCompany>(this._baseUrl + 'api/dtoHaulingCompany/' + dtoHaulingCompanyId);
	}

	getdtoHaulingCompanyParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoHaulingCompany[]>(this._baseUrl + 'api/dtoHaulingCompany/GetdtoHaulingCompanyParamArray/', aParam);
	}

	getAlldtoHaulingCompanys() {
		return this.http.get<dtoHaulingCompany[]>(this._baseUrl + 'api/dtoHaulingCompany/GetAlldtoHaulingCompany/');
	}

	//getAlldtodtoHaulingCompanys() {
	//	return this.http.get<dtodtoHaulingCompany[]>(this._baseUrl + 'api/dtoHaulingCompany/GetAlldtoHaulingCompany/');
	//}

	createdtoHaulingCompany(dtoHaulingCompany: dtoHaulingCompany) {
		return this.http.post<dtoHaulingCompany>(this._baseUrl + 'api/dtoHaulingCompany/CreatedtoHaulingCompany/', dtoHaulingCompany);
	}

	updatedtoHaulingCompany(dtoHaulingCompany: dtoHaulingCompany) {
		return this.http.put<dtoHaulingCompany>(this._baseUrl + 'api/dtoHaulingCompany/UpdatedtoHaulingCompany/', dtoHaulingCompany);
	}

	async getdtoHaulingCompanyPromise(dtoHaulingCompanyId: string) {
		return new Promise<dtoHaulingCompany>(resolve => {
			this.getdtoHaulingCompany(dtoHaulingCompanyId).subscribe(dtoHaulingCompany => {
				resolve(dtoHaulingCompany);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoHaulingCompany records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoHaulingCompanyParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoHaulingCompany[]>(resolve => {
			this.getdtoHaulingCompanyParamArray(aParam).subscribe(dtoHaulingCompany => {
				resolve(dtoHaulingCompany);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoHaulingCompany records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoHaulingCompanyPromise(dtoHaulingCompany: dtoHaulingCompany) {
		return new Promise<dtoHaulingCompany>(resolve => {
			this.updatedtoHaulingCompany(dtoHaulingCompany).subscribe(dtoHaulingCompany => {
				resolve(dtoHaulingCompany);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoHaulingCompany record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoHaulingCompanyPromise(dtoHaulingCompany: dtoHaulingCompany) {
		return new Promise<dtoHaulingCompany>(resolve => {
			this.createdtoHaulingCompany(dtoHaulingCompany).subscribe(dtoHaulingCompany => {
				resolve(dtoHaulingCompany);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoHaulingCompany record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}