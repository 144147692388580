import { Component, Inject } from '@angular/core';
import { BPStocktakeService } from '../bpstocktake/bpstocktake.service';
import { StockContainerService } from '../_shared/services/stock-container.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../account/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorMessage } from '../_shared/ErrorMessage.class';
import { dtoStockItem } from '../_shared/business-objects/dto-stock-item.bo';
import { NavigationService } from '../_shared/services/navigation.service';
import { SharedService } from '../_shared/services/shared.service';
import { FormService } from '../_shared/services/form.service';
import { GeoStockLocation } from '../_shared/business-objects/geo-stock-location.bo';
import { GeoStockLocationService } from '../_shared/services/geo-stock-location.service';
import { SQLParamArray } from '../_shared/business-objects/sql-param-array';
import { dtoStockItemService } from '../_shared/services/dto-stock-item.service';
import { AlertService } from '../_shared/services/alert.service';


//export interface StockItem {
//  txtIdentifier: string;
//  productName: string;
//  intWidth: number;
//  intThickness: number;
//  speciesName: string;
//  gradeName: string;
//  fltQuantity: number;
//  txtQuantityUoM: string;
//  intTotalLM: number;
//  txtLocation: string;
//  blnStockTake: boolean;
//  txtStockTake: string;
//  dteStockTake: Date;
//}

export interface StockContainerLocation {
  txtLocation: string;
}

export class StockItemCol {
  intTotalStockItems: number;
}

@Component({
  selector: 'app-bpstocktake',
  templateUrl: './bpstocktake.component.html',
  styleUrls: ['./bpstocktake.component.css']
})
export class BPStocktakeComponent {
  form: FormGroup;
  public bpStocktake: any;
  public stockContainerLocations: any;
  stockDoesntExist: boolean;
  stockItemsCol: StockItemCol;  
  formMode: string;
  errorMessage: ErrorMessage;

  geoStockLocations: GeoStockLocation[];
  myGeoStockLocations: GeoStockLocation[];
  defaultGeoLocation: GeoStockLocation;

  displayedColumns: string[] = ['txtIdentifier', 'productName', 'intWidth', 'intThickness', 'speciesName', 'gradeName', 'fltQuantity', 'txtQuantityUoM', 'intTotalLM', 'txtLocation', 'txtStockTake', 'dteStockTake'];

  constructor(private fb: FormBuilder, private router: Router, private bpstocktakeService: BPStocktakeService, private stockContainerService: StockContainerService, private authService: AuthService, private navigationService: NavigationService, private sharedService: SharedService
    , private formService: FormService, private geoStockLocationService: GeoStockLocationService, private dtoStockItemService: dtoStockItemService, private alertService: AlertService  ) {
    this.stockDoesntExist = false;
    this.formMode = 'search';

    this.form = this.fb.group({
      txtStocktakeIdentifier: ['', Validators.required],
      txtStocktakeLocation: ['', Validators.required],
      radIncludeNonStocktake: [0],
      radMode: [1],
      guGeoStockLocationId: [''],
      blnArchived: [0]
    });

    this.stockItemsCol = new StockItemCol;
    this.stockItemsCol.intTotalStockItems = 0;

    this.loadLocations();
    this.loadData();

  //  this.bpstocktakeService.getStocktake().subscribe(result => {
  //    console.log(result);
  //    this.bpStocktake = result;
  //  }, error => console.error(error));
  }

  async loadData() {

    this.defaultGeoLocation = await this.geoStockLocationService.getMyDefaultGeoLocation(this.authService.getAppUserId());
    this.form.controls['guGeoStockLocationId'].setValue(this.defaultGeoLocation.rowguid);

    this.geoStockLocations = await this.geoStockLocationService.getMyGeoStockLocationsPromise(this.authService.getAppUserId());

  }

  loadLocations() {
    this.stockContainerService.getStockContainerLocations().subscribe(result => {
      //console.log(result);
      this.stockContainerLocations = result;
    }, error => console.error(error));

  }

  onSearch() {
    if (this.form.value.txtStocktakeIdentifier == '') {
      window.alert('Please enter an identifier number to search.');
    }
    this.searchStock(this.form.value.txtStocktakeIdentifier);
  }

  onSubmit(sType) {
    if ((sType == 'stocktake') || (this.formMode == 'stocktake')) {
      console.log('on submit: ' + sType);
      this.onSetStocktake()
    }
    else {
      this.searchTo20Stocktake()
    }
    this.formService.setAutoFocusTimeout("txtStocktakeIdentifier", 300);
  }

  onSetStocktake() {
    this.stockDoesntExist = false;
    let bContinue: boolean = true;
    //console.log("onSetStocktake");
    if (this.form.value.txtStocktakeIdentifier == '') {
      window.alert('Please enter an identifier number to set stocktake.');
      bContinue = false;
    }

    if (this.form.value.txtStocktakeLocation == '') {
      window.alert('Please enter a location to set stocktake.');
      bContinue = false;
    }

    if (bContinue) {
      console.log("set stocktake");
      this.setStocktake(this.form.value.txtStocktakeIdentifier, this.locationWithMaskSeperator(), this.authService.getAppUserEmployeeId());
    }
  }

  onModeSelect() {
    //console.log("onModeSelect: " + this.form.value.radMode);
    if (this.form.value.radMode == 'stocktake') {
      this.formMode = 'stocktake';
      //console.log("onModeSelect-stocktake: " + this.form.value.radMode);
    }
    if (this.form.value.radMode == 'search') {
      this.formMode = 'search';
      //console.log("onModeSelect-search: " + this.form.value.radMode);
    }
  }

  searchStock(identifier) {

    let geoLocation: string = this.form.controls["guGeoStockLocationId"].value;

    this.bpstocktakeService.getStocktakePack(identifier, geoLocation).subscribe(result => {  // this.navigationService.getLocationGuid("BP")
      console.log(result);
      this.bpStocktake = result;
    }, error => console.error(error));

  }

  async setStocktake(identifier, location, employeeId) {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("intIdentifier", identifier));
    aParamList.push(aParam);

    let stockItem: dtoStockItem = await this.dtoStockItemService.getdtoStockItemByIdentifer(identifier);

    if (stockItem != null && stockItem.blnStockContainerArchived == true) {
      let msg: string = await this.alertService.openSnackBarCustomDefaultShowCancel("Archived Stock", "This stock item (" + identifier + ") is Archived!!!\nDo you want to Unarchived this stock item???", "Yes", "No");
      if (msg != "Yes") {
        return;
      }

      if (msg == "Yes") {
        let msg2: string = await this.alertService.openSnackBarCustomDefaultShowCancel("Archived Stock", "Are you really sure you want to Unarchive this stock item????", "Yes", "No");
        if (msg2 != "Yes") {
          return;
        }
        
      }

    }

    this.bpstocktakeService.setStocktake(identifier, location, employeeId).subscribe(result => {
      //console.log("setStocktake: " + identifier);
      this.form.patchValue({ 'txtStocktakeIdentifier': '' });
      this.searchTo20Stocktake();
    }, (error: HttpErrorResponse) => {
        this.stockDoesntExist = true;
        this.errorMessage = new ErrorMessage();
        this.errorMessage.message = error.message;
        console.log(error)
        }
      );

  }

  searchTo20Stocktake() {
    let bContinue: boolean = true;
    //console.log("searchTo20Stocktake: ");
    if ((this.form.value.txtStocktakeLocation == '') && (this.identifierNumber() == 0)) {
      window.alert('Please enter either a location or an identifier to search stocktake.');
      bContinue = false;
    }

    if (bContinue) {
      let geoLocation: string = this.form.controls["guGeoStockLocationId"].value;
      let archived: boolean = this.form.controls["blnArchived"].value == true;

      if (this.form.value.radMode == 'stocktake') {  //Gets top 20
        this.bpstocktakeService.getTop20Stocktake(this.identifierNumber(), this.locationWithMaskSeperator(), this.form.value.radIncludeNonStocktake, geoLocation).subscribe(result => {
          let array = JSON.parse(JSON.stringify(result));
          this.stockItemsCol.intTotalStockItems = array.length;
          this.bpStocktake = result;
        }, error => console.error(error));
      }
      else {     //Gets all stock in location

        let aParamList: SQLParamArray[][] = [];
        let aParam: SQLParamArray[] = [];

        if (this.identifierNumber() > 0) {
          aParam.push(new SQLParamArray("intIdentifier", this.identifierNumber().toString()));
          aParamList.push(aParam);
        }

        aParam = [];
        aParam.push(new SQLParamArray("guGeoStockLocationId", geoLocation));
        aParamList.push(aParam);

        if (this.locationWithMaskSeperator() != '') {
          aParam = [];
          aParam.push(new SQLParamArray("txtContainerLocation", this.locationWithMaskSeperator()));
          aParamList.push(aParam);
        }

        if (this.form.value.radIncludeNonStocktake ==  false) {
          aParam = [];
          aParam.push(new SQLParamArray("blnContainerStockTake", "1"));
          aParamList.push(aParam);
        }

        if (this.form.value.radIncludeNonStocktake == true) {
          aParam = [];
          aParam.push(new SQLParamArray("blnContainerStockTake", "0"));
          aParam.push(new SQLParamArray("blnContainerStockTake", "", SQLParamArray.enSQLOperator.IsNull));
          aParamList.push(aParam);
        }

        if (archived == false) {
          aParam = [];
          aParam.push(new SQLParamArray("blnStockContainerArchived", "0"));
          aParam.push(new SQLParamArray("blnStockContainerArchived", "", SQLParamArray.enSQLOperator.IsNull));
          aParamList.push(aParam);
        }


        //console.log("aparamlist", aParamList);

        this.bpstocktakeService.getStockParamArray(aParamList).subscribe(result => {
        //this.bpstocktakeService.getStock(this.identifierNumber(), this.locationWithMaskSeperator(), this.form.value.radIncludeNonStocktake, geoLocation).subscribe(result => {
          let array = JSON.parse(JSON.stringify(result));
          this.stockItemsCol.intTotalStockItems = array.length;
          this.bpStocktake = result;
          console.log(result);
      }, error => console.error(error));
      }
    }
  }

  private identifierNumber(): number {
    return Number(this.form.value.txtStocktakeIdentifier)
  }

  locationWithMaskSeperator() {
    let loc: string = this.form.value.txtStocktakeLocation;
    if (loc == '') {
      return '';
    }
    else {
      //console.log("checkLocation: " + loc.substr(0, 1).toUpperCase() + "-" + loc.substr(1, 1) + "-" + loc.substr(2, loc.length - 1));
      return loc.substr(0, 1).toUpperCase() + "-" + loc.substr(1, 1) + "-" + loc.substr(2, loc.length - 1)
    }
  }

}  



//<table align="right" >
//  <tr>
//  <td></td>
//  < td >
//  <div id="divIdentifier" > Identifier: <span id="spIdentifier" > <input id="txtIdentifier" type = "text" name = "txtIdentifier" value = ""[(ngModel)] = "txtIdentifier" /> </span></div >
//    </td>
//    < td >
//    <span id="spSearchButton" > <input id="btnSearch" type = "button" name = "btnSearchButton" value = "Search"(click) = "onSearch()" /> </span>
//      < /td>
//      < /tr>
//      < tr >
//      <td></td>
//      < td >
//      <div id="divStocktakeIdentifier" > Identifier: <span id="spStocktakeIdentifier" > <input id="txtStocktakeIdentifier" type = "text" name = "txtStocktakeIdentifier" value = ""[(ngModel)] = "txtStocktakeIdentifier" /> </span></div >
//        </td>
//        < td >
//        <span id="spSetStocktakeButton" > <input id="btnSetStocktakeButton" type = "button" name = "btnSetStocktakeButton" value = "Set Stock Take"(click) = "onSetStocktake()" /> </span>
//          < /td>
//          < /tr>
//          < /table>
//          < br />

//          <table class="table" >
//            <tr bgcolor="#f9f9f9" >
//              <th>Pack No < /th>
//                < th > Width < /th>
//                < th > Thickness < /th>
//                < th > Location < /th>
//                < th > Stocktake < /th>
//                < th > Stocktake Date < /th>
//                  < /tr>

//                  < tr * ngFor="let stocktake of bpStocktake" >
//                    <td>
//                    {{ stocktake.intIdentifier }}
//</td>
//  < td >
//  {{ stocktake.intWidth }}
//</td>
//  < td >
//  {{ stocktake.intThickness }}
//</td>
//  < td >
//  {{ stocktake.txtLocation }}
//</td>
//  < td >
//  {{ stocktake.txtStockTake }}
//</td>
//  < td >
//  {{ stocktake.dteStockTake | date: 'dd/MM/yyyy' }}
//</td>
//  < td >
//  {{ stocktake.dteStockTake | date: 'dd/MM/yyyy' }}
//</td>
//  < /tr>
//  < /table>
