import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-custom',
  templateUrl: './snack-bar-custom.component.html',
  styleUrls: ['./snack-bar-custom.component.css']
})
export class SnackBarCustomComponent implements OnInit {

  title: string;
  message: string;
  actionText: string;
  action2Text: string;
  actionResponse: string;
  showCancel: boolean;

  constructor(public sbRef: MatSnackBarRef<SnackBarCustomComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: { title: string, message: string, actionText: string, action2Text: string, showCancel: boolean}) {

    this.title = data.title;
    this.message = data.message;
    this.actionText = data.actionText;
    this.action2Text = data.action2Text;
    this.showCancel = data.showCancel == null ? true : data.showCancel == true;
  }

  ngOnInit(): void {
  }

  

  doAction(actionResponse: string) {
    // you can do some action here
    this.actionResponse = actionResponse;

    this.sbRef.closeWithAction();
  }

  cancel() {
    // close the snackbar or wirte another action

    //this.sbRef.dismissWithAction()
    this.sbRef.dismiss();

  }

}
