import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-machine-run-list',
  templateUrl: './machine-run-list.component.html',
  styleUrls: ['./machine-run-list.component.css']
})
export class MachineRunListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
