import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { dtoPrinter } from '../../_shared/business-objects/dto-printer.bo';

@Component({
  selector: 'app-printer-list',
  templateUrl: './printer-list.component.html',
  styleUrls: ['./printer-list.component.css']
})
export class PrinterListComponent implements OnInit {
  @Input() datasource: MatTableDataSource<dtoPrinter>;
  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() columnListId: string;

  loadedComponent: boolean = false;

  displayedColumns: string[] = ['txtName', 'txtAliasName', 'txtPrinterLocation', 'txtPrinterIPAddress', 'txtPaperKind', 'txtPrinterType', 'fltWidth', 'fltHeight', 'txtSizeUnit', 'blnSensativeData', 'blnActive', 'dteDateCreated', 'btnEditPrinter', 'btnRemovePrinter'];


  constructor() { }

  ngOnInit(): void {
  }


  removePrinter(printer: dtoPrinter) {

  }

  editPrinter(printer: dtoPrinter) {

  }

}
