import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { dtoDeliveredSalesBySection } from '../../../_shared/business-objects/dto-delivered-sales-by-section.bo';
import { dtoDeliveredSalesBySectionService } from '../../../_shared/services/dto-delivered-sales-by-section.service';

@Component({
  selector: 'app-monthly-reports',
  templateUrl: './monthly-reports.component.html',
  styleUrl: './monthly-reports.component.scss'
})
export class MonthlyReportsComponent implements OnInit {

  showDeliveredSalesChart: boolean;
  showDeliveredSalesSummary: boolean;
  deliveredSalesSummary: dtoDeliveredSalesBySection[];

  constructor(private dtoDeliveredSalesBySectionService: dtoDeliveredSalesBySectionService) {

  }


  ngOnInit(): void {

    this.showDeliveredSalesChart = true;
    this.showDeliveredSalesSummary = true;

    this.deliveredSalesSummary = [];

    this.getDeliveredSales("2023-07-01", "2024-08-27");
  }


  async getDeliveredSales(dateFrom: string, dateTo: string) {


    const datepipe: DatePipe = new DatePipe('en-AU');

    //console.log("about to get deliveredSalesAll: ", this.deliveredSalesAll);
    dateFrom = datepipe.transform(new Date(dateFrom), 'yyyy-MM-dd');
    dateTo = datepipe.transform(new Date(dateTo), 'yyyy-MM-dd');
    this.deliveredSalesSummary = await this.dtoDeliveredSalesBySectionService.getdtoDeliveredSalesBySectionsPromise(dateFrom, dateTo);
    this.deliveredSalesSummary.sort((a, b) => { return a.intYear > b.intYear || a.intMonth > b.intMonth || a.txtOrderItemProcesses > b.txtOrderItemProcesses ? 1 : -1 });
    console.log("deliveredSalesSummary: ", this.deliveredSalesSummary);

  }


}
