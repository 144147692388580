import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeliveryDocketItem } from '../business-objects/delivery-docket-item.bo';
import { SharedService } from './shared.service';


@Injectable()
export class DeliveryDocketItemService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService) {
    this._baseUrl = baseUrl;
  }

  getDeliveryDocketItems() {
    return this.http.get<DeliveryDocketItem[]>(this._baseUrl + 'api/deliverydocketitem');
  }

  getDeliveryDocketItem(docketId: string) {
    return this.http.get<DeliveryDocketItem>(this._baseUrl + 'api/deliverydocketitem/' + docketId);
  }

  createDeliveryDocektItem(deliveryDocketItem: DeliveryDocketItem) {
    return this.http.post<DeliveryDocketItem>(this._baseUrl + 'api/deliverydocketitem/', deliveryDocketItem);
  }

  updateDeliveryDocektItem(deliveryDocketItem: DeliveryDocketItem) {
    return this.http.put<DeliveryDocketItem>(this._baseUrl + 'api/deliverydocketitem/', deliveryDocketItem);
  }

  //mapDeliveryDocketFromDTO(stockItem: dtoStockItem, excludeProperties: Array<string>): DeliveryDocket {
  //  var deliveryDocket: DeliveryDocket = new DeliveryDocket();

  //  for (let [key, value] of Object.entries(stockItem)) {
  //    if (excludeProperties.indexOf(key) == -1) {
  //      console.log("Property " + key + ",  val: " + value);

  //      deliveryDocket[key] = value;

  //      if (value == this.sharedService.EmptyGuid) {
  //        deliveryDocket[key] = null;
  //      }

  //      if (value == this.sharedService.MinDate) {
  //        console.log("date min value");
  //        deliveryDocket[key] = null;
  //      }

  //    }
  //  }
  //  return deliveryDocket;
  //}


}  
