<div class="product-code-text">
  <div class="sku-grid">
    <div class="sku-text-heading"></div>
    <div class="sku-text-heading">Product Type</div>
    <div class="sku-text-heading"></div>
    <div class="sku-text-heading">Profile</div>
    <div class="sku-text-heading"></div>
    <div class="sku-text-heading">Width</div>
    <div class="sku-text-heading"></div>
    <div class="sku-text-heading">Thickness</div>
    <div class="sku-text-heading"></div>
    <div class="sku-text-heading">Length</div>
    <div class="sku-text-heading"></div>
    <div class="sku-text-heading">Species</div>
    <div class="sku-text-heading"></div>
    <div class="sku-text-heading">Grade</div>

    <div class="sku-text">SKU:</div>
    <div class="product-type-sku-row">
      <input class="textbox-input product-type-sku" type="text" #productTypeSKU (input)="productTypeSKU.value = productTypeSKU.value.toString().toUpperCase()">
    </div>
    <div class="sku-divider">-</div>
    <div class="product-profile-sku-row">
      <input class="textbox-input product-profile-sku" type="text" #productProfileSKU (input)="productProfileSKU.value = productProfileSKU.value.toString().toUpperCase()">
    </div>
    <div class="sku-divider">-</div>
    <div class="width-sku-row">
      <input class="textbox-input width-sku" type="text" #widthSKU (input)="widthSKU.value = widthSKU.value.toString().toUpperCase()">
    </div>
    <div class="sku-divider">-</div>
    <div class="thickness-sku-row">
      <input class="textbox-input thickness-sku" type="text" #thicknessSKU (input)="thicknessSKU.value = thicknessSKU.value.toString().toUpperCase()">
    </div>
    <div class="sku-divider">-</div>
    <div class="length-sku-row">
      <input class="textbox-input length-sku" type="text" #lengthSKU (input)="lengthSKU.value = lengthSKU.value.toString().toUpperCase()">
    </div>
    <div class="sku-divider">-</div>
    <div class="species-sku-row">
      <input class="textbox-input species-sku" type="text" #speciesSKU (input)="speciesSKU.value = speciesSKU.value.toString().toUpperCase()">
    </div>
    <div class="sku-divider">-</div>
    <div class="grade-sku-row">
      <input class="textbox-input grade-sku" type="text" #gradeSKU (input)="gradeSKU.value = gradeSKU.value.toString().toUpperCase()">
    </div>
  </div>

  <div class="sku-finished-text-grid">
    <div class="sku-finished-text">{{skuText}}</div>
    <div class="sku-finished-text-wild-card">
      <mat-checkbox class="example-margin" #skuFinishWildCard (click)="buildSKUText()">End With Wildcard (*)</mat-checkbox>
    </div>
    <div class="sku-finished-select">
      <button class="sku-finished-select-button" type="button" mat-flat-button color="primary" id="btnSKUSelect" (click)="selectSKU()">Select SKU</button>
    </div>
    <div class="sku-finished-cancel">
      <button class="sku-finished-cancel-button" type="button" mat-flat-button color="primary" id="btnSKUCancel" (click)="cancelSKU()">Cancel</button>
    </div>
  </div>
</div>

<div class="product-code-select-container">
  <div class="product-code-select-grid">
    <div class="product-code-selections">
      <div class="product-type">
        <div class="wild-card-grid">
          <div>
            <h3>Product Type</h3>
          </div>
          <div class="wild-card-textbox">
            <mat-checkbox class="example-margin" #productTypeWildCard (click)="useWildCard(productTypeSKU, productTypeWildCard.checked);selectedProd = null;">Use Wildcard (*)</mat-checkbox>
          </div>
        </div>
        <div class="product-type-search">
          <mat-form-field class="chh-form-field example-full-width chh-textbox-200 search-product-type-textbox" appearance="fill">
            <mat-label>Search Product Type</mat-label>
            <input class="textbox-search" matInput placeholder="i.e. Solid Flooring" value="" type="text" #productTypeSearch (input)="filterProductType(productTypeSearch.value)">
          </mat-form-field>
        </div>

        <div class="product-type-grid">
          <div class="grid-header">Code</div>
          <div class="grid-header">Name</div>
          @for(productType of productTypes; track productType; let idx = $index){
          <div class="rowWrapper" [ngClass]="productType == selectedProd ? 'rowWrapperSelected' : 'rowWrapper'">
            <div class="chh-item-row product-type-code" (click)="selectProductType(productType, productTypeSKU);productTypeWildCard.checked = false">{{productType.txtProductCode}}</div>
            <div class="chh-item-row product-type-name" (click)="selectProductType(productType, productTypeSKU);productTypeWildCard.checked = false">{{productType.txtName.substring(0,75) + (productType.txtName.length > 75 ? '...' : '')}}</div>
          </div>
          }
        </div>
      </div>
      <div class="product-profile">
        <div class="wild-card-grid">
          <div>
            <h3>Product Profile</h3>
          </div>
          <div class="wild-card-textbox">
            <mat-checkbox class="example-margin" #productProfileWildCard (click)="useWildCard(productProfileSKU, productProfileWildCard.checked);selectedProdProfile = null;">Use Wildcard (*)</mat-checkbox>
          </div>
        </div>
        <div class="product-profile-search">
          <mat-form-field class="chh-form-field example-full-width chh-textbox-200 search-product-profile-textbox" appearance="fill">
            <mat-label>Search Product Profile</mat-label>
            <input class="textbox-search" matInput placeholder="i.e. DARPR" value="" type="text" #productProfileSearch (input)="filterProductProfile(productProfileSearch.value)">
          </mat-form-field>
        </div>
        <div class="product-profile-grid">
          <div class="grid-header">Code</div>
          <div class="grid-header">Name</div>
          @for(productProfile of productProfiles; track productProfile; let idx = $index){
          <div class="rowWrapper" [ngClass]="productProfile == selectedProdProfile ? 'rowWrapperSelected' : 'rowWrapper'">
            <div class="chh-item-row product-profile-code" (click)="selectProductProfile(productProfile, productProfileSKU);productProfileWildCard.checked = false;">{{productProfile.txtProductCode}}</div>
            <div class="chh-item-row product-profile-name" (click)="selectProductProfile(productProfile, productProfileSKU);productProfileWildCard.checked = false;">{{productProfile.txtName.substring(0,75) + (productProfile.txtName.length > 75 ? '...' : '')}}</div>
          </div>
          }
        </div>
      </div>
      <div class="width">
        <h3>Size</h3>
        <div class="size-grid">
          <div class="width-column-header">
            <mat-checkbox class="example-margin" #widthWildCard (click)="useWildCard(widthSKU, widthWildCard.checked);width.value = '';">Use Wildcard (*)</mat-checkbox>
          </div>
          <div class="thickness-column-header">
            <mat-checkbox class="example-margin" #thicknessWildCard (click)="useWildCard(thicknessSKU, thicknessWildCard.checked);thickness.value = '';">Use Wildcard (*)</mat-checkbox>
          </div>
          <div class="length-column-header">
            <mat-checkbox class="example-margin" #lengthWildCard (click)="useWildCard(lengthSKU, lengthWildCard.checked);length.value = '';">Use Wildcard (*)</mat-checkbox>
          </div>
          <div class="width-column">
            <mat-form-field class="chh-form-field example-full-width chh-textbox-150 width" appearance="fill">
              <mat-label>Width</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 80" value="" type="text" #width (input)="setWidthProductCode(width.value, widthSKU);widthWildCard.checked = false;">
            </mat-form-field>
          </div>
          <div class="thickness-column">
            <mat-form-field class="chh-form-field example-full-width chh-textbox-150 thickness" appearance="fill">
              <mat-label>Thickness</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 19" value="" type="text" #thickness (input)="setThicknessProductCode(thickness.value, thicknessSKU);thicknessWildCard.checked = false;">
            </mat-form-field>
          </div>
          <div class="length-column">
            <mat-form-field class="chh-form-field example-full-width chh-textbox-150 length" appearance="fill">
              <mat-label>Length</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 2.4" value="" type="text" #length (input)="setLengthProductCode(length.value, lengthSKU);lengthWildCard.checked = false;">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="species">
        <div class="wild-card-grid">
          <div>
            <h3>Species</h3>
          </div>
          <div class="wild-card-textbox">
            <mat-checkbox class="example-margin" #speciesWildCard (click)="useWildCard(speciesSKU, speciesWildCard.checked);selectedSpec = null;">Use Wildcard (*)</mat-checkbox>
          </div>
        </div>
        <div class="species-search">
          <mat-form-field class="chh-form-field example-full-width chh-textbox-200 search-species-textbox" appearance="fill">
            <mat-label>Search Species</mat-label>
            <input class="textbox-search" matInput placeholder="i.e. Blackbutt" value="" type="text" #speciesSearch (input)="filterSpecies(speciesSearch.value)">
          </mat-form-field>
        </div>
        <div class="species-grid">
          <div class="grid-header">Code</div>
          <div class="grid-header">Name</div>
          @for(specie of species; track specie; let idx = $index){
          <div class="rowWrapper" [ngClass]="specie == selectedSpec ? 'rowWrapperSelected' : 'rowWrapper'">
            <div class="chh-item-row species-code" (click)="selectSpecies(specie, speciesSKU);speciesWildCard.checked = false;">{{specie.txtProductCode}}</div>
            <div class="chh-item-row species-name" (click)="selectSpecies(specie, speciesSKU);speciesWildCard.checked = false;">{{specie.txtName.substring(0,75) + (specie.txtName.length > 75 ? '...' : '')}}</div>
          </div>
          }
        </div>
      </div>
      <div class="grade">
        <div class="wild-card-grid">
          <div>
            <h3>Grade</h3>
          </div>
          <div class="wild-card-textbox">
            <mat-checkbox class="example-margin" #gradeWildCard (click)="useWildCard(gradeSKU, gradeWildCard.checked);selectedGrade = null;">Use Wildcard (*)</mat-checkbox>
          </div>
        </div>
        <div class="grade-search">
          <mat-form-field class="chh-form-field example-full-width chh-textbox-200 search-grade-textbox" appearance="fill">
            <mat-label>Search Grade</mat-label>
            <input class="textbox-search" matInput placeholder="i.e. Select" value="" type="text" #gradeSearch (input)="filterGrade(gradeSearch.value)">
          </mat-form-field>
        </div>
        <div class="grade-grid">
          <div class="grid-header">Code</div>
          <div class="grid-header">Name</div>
          @for(grade of grades; track grade; let idx = $index){
          <div class="rowWrapper" [ngClass]="grade == selectedGrade ? 'rowWrapperSelected' : 'rowWrapper'">
            <div class="chh-item-row grade-code" (click)="selectGrade(grade, gradeSKU);gradeWildCard.checked = false;">{{grade.txtProductCode}}</div>
            <div class="chh-item-row grade-name" (click)="selectGrade(grade, gradeSKU);gradeWildCard.checked = false;">{{grade.txtName.substring(0,75) + (grade.txtName.length > 75 ? '...' : '')}}</div>
          </div>
          }
        </div>
      </div>

    </div>

  </div>
</div>
