<div class="final-inspection-transfer-container" [formGroup]="form">
  <br /><br />
  <h4>Transfer From</h4>
  <div class="transfer-from">
    <div class="final-inspection-number-from">
      <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
        <mat-label>Final No. From</mat-label>
        <input class="textbox-input" matInput type="number" value="{{finalInspection.intFinalNo}}" #txtFinalNoFrom [disabled]="true">
      </mat-form-field>
    </div>

    <div class="final-inspection-items">
      <mat-accordion>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{customerLocation(finalInspection)}}
            </mat-panel-title>
            <mat-panel-description>
              ....
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let finalItem of finalInspectionItems"><div class="pole-number">{{finalItem.txtIdentifier}}</div><div class="pole-separator">-</div><div class="pole-description">{{finalItem.txtDescription}}</div><div class="pole-order-no">PO - {{finalItem.txtOrderNo}}</div></mat-list-item>
          </mat-list>

        </mat-expansion-panel>
      </mat-accordion>


    </div>
  </div>

  <div class="divder"></div>

  <h4>Transfer Too</h4>
  <div class="transfer-too">
    <div class="parked-finals">
      <mat-accordion>
        <mat-expansion-panel class="parked-finals-expansion" hideToggle [disabled]="finalInspectionsParked == null" (click)="setDefaultParkedFinal();clearOrderLists();">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Parked Finals
            </mat-panel-title>
            <mat-panel-description>
              ....
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-selection-list [multiple]="false" formControlName="guElectricalAuthoritySpecId">
            <mat-list-option *ngFor="let finalInspection of finalInspectionsParked" (click)="txtFinalNoToo.value = finalInspection.intFinalNo; getFinalInspectionAfterClick(txtFinalNoToo);clearOrderLists();" [value]="finalInspection.guElectricalAuthoritySpecId">{{finalInspection.intFinalNo}} - {{finalInspection.txtParkedSpec}}</mat-list-option>
          </mat-selection-list>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="in-progress-finals">
      <mat-accordion>
        <mat-expansion-panel class="in-progress-finals-expansion" hideToggle [disabled]="finalInspectionsInProgress == null">
          <mat-expansion-panel-header>
            <mat-panel-title>
              In Progress Finals
            </mat-panel-title>
            <mat-panel-description>
              ....
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-selection-list [multiple]="false">
            <mat-list-option *ngFor="let finalInspection of finalInspectionsInProgress" (click)="txtFinalNoToo.value = finalInspection.intFinalNo; getFinalInspectionAfterClick(txtFinalNoToo)">{{finalInspection.intFinalNo}} - {{finalInspection.txtCustomerLocation}}</mat-list-option>
          </mat-selection-list>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="final-inspection-number-too">
      <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
        <mat-label>Final No. Too</mat-label>
        <input class="textbox-input" matInput type="number" value=""  formControlName="txtFinalNoToo" #txtFinalNoToo (keyup.enter)="getFinalInspection(txtFinalNoToo.value);getFinalInspectionAfterClick(txtFinalNoToo);" (keyup)="checkCharLength(txtFinalNoToo.value)">
      </mat-form-field>
      <mat-icon matTooltip="click to paste clipboard content" class="paste_icon" (click)="pasteFromClipboard(txtFinalNoToo);getFinalInspectionAfterClick(txtFinalNoToo);">content_paste</mat-icon>&nbsp;
      <mat-icon matTooltip="click to clear final number" class="backspace_icon" (click)="clearTextbox(txtFinalNoToo)">backspace</mat-icon>&nbsp;
      <span style="display: inline-block; padding-left: 10px; border: 0px solid green; visibility: hidden; width: 0px; vertical-align: middle; padding-bottom: 25px;" id="spanSpinnerContainer" [@displaySpinner]="showSpinner ? 'open' : 'closed'">
        <mat-progress-spinner class="example-margin products-spinner inline-spinner" color="primary" mode="indeterminate" [diameter]="30" value="50"></mat-progress-spinner>
      </span>
      <span style="display: inline-block; padding-left: 10px; border: 0px solid green; visibility: hidden; width: 0px; vertical-align:bottom; padding-bottom:25px;" id="iconTickContainer" [@displayTick]="showTick ? 'open' : 'closed'">
        <mat-icon class="done-icon">done</mat-icon>
      </span>
      <span style="display: inline-block; padding-left: 10px; border: 0px solid green; visibility: hidden; width: 0px; vertical-align:bottom; padding-bottom:25px;" id="iconCancelContainer" [@displayCancel]="showCancel ? 'open' : 'closed'">
        <mat-icon class="cancel-icon" matTooltip="Cancel update" (click)="clearFinalNo(txtFinalNoToo)">cancel</mat-icon>
      </span>

      <mat-checkbox class="example-margin" #sameOrder id="blnSameOrder" formControlName="blnSameOrder" (click)="setSameOrder(sameOrder.checked)">Same Order</mat-checkbox>

      <mat-accordion>
        <mat-expansion-panel hideToggle [disabled]="finalInspectionItemsToo == null">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{customerLocation(finalInspectionToo)}}
            </mat-panel-title>
            <mat-panel-description>
              ....
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let finalItem of finalInspectionItemsToo"><div class="pole-number">{{finalItem.txtIdentifier}}</div><div class="pole-separator">-</div><div class="pole-description">{{finalItem.txtDescription}}</div></mat-list-item>
          </mat-list>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="order-item-summary">
      <mat-accordion>
        <mat-expansion-panel class="order-item-summary-expansion" hideToggle="true" [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Pick an Order Item to Transfer Too
            </mat-panel-title>
            <mat-panel-description>
              ....
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-selection-list [multiple]="false" *ngIf="orderItemSummary != null" #orderItemSummaryList formControlName="orderItemSummaryList">
            <mat-list-option *ngFor="let orderItem of orderItemSummary" (click)="setOrderItem(orderItem)" [value]="orderItem.rowguid">{{orderItem.txtCustomerName}} - {{orderItem.txtOrderNo}} - {{orderItem.txtDescription}}</mat-list-option>
          </mat-selection-list>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="customer-order-orderitem">
      <div class="customer">
        <mat-form-field class="chh-form-field">
          <div class="customer-input-container">
            <input matInput class="textbox-input" placeholder="Filter Customers" placeholderLabel="i.e. Essential ....." value="" type="text" #customerFilter (keyup)="searchCustomers(customerFilter.value)" (focus)="customerFilter.select()">
          </div>
        </mat-form-field>

        <mat-selection-list class="customer-select" #guCustomerId id="guCustomerId" [multiple]="false" formControlName="guCustomerId" (selectionChange)="customerChanged($event)">
          <mat-list-option *ngFor="let customer of customers" [value]="customer.rowguid">
            {{customer.txtName}}
          </mat-list-option>
        </mat-selection-list>

        <mat-form-field class="example-full-width header-button" style="width: 600px !important" appearance="fill" *ngIf="false">
          <mat-select class="final-item-customers" formControlName="guCustomerId" id="guCustomerId" placeholder="Customer" #guCustomerId (selectionChange)="customerChanged($event)">
            <mat-option class="final-item-customer">
              <ngx-mat-select-search placeholderLabel="i.e. Essential ....." [formControl]="customersFilterCtrl" noEntriesFoundLabel="There are no customers" ngDefaultControl></ngx-mat-select-search>
            </mat-option>
            <mat-option class="customers" *ngFor="let customer of filteredCustomers | async" [value]="customer.rowguid">
              {{customer.txtName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="order">
        <mat-form-field class="chh-form-field">
          <div class="orders-input-container">
            <input matInput class="orders-input" placeholder="Filter Orders" placeholderLabel="i.e. EE000 ....." value="" type="text" #ordersFilter (keyup)="searchOrders(ordersFilter.value)" (focus)="ordersFilter.select()">
          </div>
        </mat-form-field>
        <mat-selection-list class="orders-select" #guOrderId id="guOrderId" [multiple]="false" formControlName="guOrderId" (selectionChange)="orderChanged($event.options[0].value)">
          <mat-list-option *ngFor="let order of orders" [value]="order">
            {{order.txtOrderNo}}
          </mat-list-option>
        </mat-selection-list>


        <mat-form-field class="example-full-width header-button" style="width: 600px !important" appearance="fill" *ngIf="false">
          <mat-select class="final-item-orders" formControlName="guOrderId" placeholder="Order Number" #guOrderId (selectionChange)="orderChanged($event.value)">
            <mat-option class="final-item-order">
              <ngx-mat-select-search placeholderLabel="i.e. 4500056 ...." [formControl]="ordersFilterCtrl" noEntriesFoundLabel="There are no orders" ngDefaultControl></ngx-mat-select-search>
            </mat-option>
            <mat-option class="orders" *ngFor="let order of filteredOrders | async" [value]="order">
              {{order.txtOrderNo}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div class="order-item">
        <mat-form-field class="chh-form-field">
          <div class="order-item-input-container">
            <input matInput class="order-item-input" placeholder="Filter Order Items" placeholderLabel="i.e. Item 1 - Pole, 12.5/8 ....." value="" type="text" #orderItemFilter (keyup)="searchOrderItems(orderItemFilter.value)" (focus)="orderItemFilter.select()">
          </div>
        </mat-form-field>
        <mat-selection-list class="chh-form-field order-item-select" #guOrderItemId id="guOrderItemId" [multiple]="false" formControlName="guOrderItemId">
          <mat-list-option *ngFor="let orderItem of orderItems" [value]="orderItem">
            <div class="order-item-description">{{orderItem.txtDescription}}</div><div class="order-item-on-order">({{orderItem.blnStock == false ? 'On Order:' : 'In Stock:'}} {{orderItem.fltQuantityTotal}})</div><div class="order-item-balance">{{orderItem.blnStock == false ? '(Bal: ' : ''}} {{orderItem.blnStock == false ? orderItem.fltQuantityTotal - orderItem.intFinalCount + ')': ''}}</div>
          </mat-list-option>
        </mat-selection-list>

        <mat-form-field class="example-full-width header-button" style="width: 600px !important" appearance="fill" *ngIf="false">
          <mat-select class="final-item-order-item" formControlName="guOrderItemId" placeholder="Order Item" #guOrderItemId>
            <mat-option class="final-item-order">
              <ngx-mat-select-search placeholderLabel="i.e. Item 1 - Pole, 12.5/8 ...." [formControl]="ordersFilterCtrl" noEntriesFoundLabel="There are no orders" ngDefaultControl></ngx-mat-select-search>
            </mat-option>
            <mat-option class="orderitems" *ngFor="let orderItem of filteredOrderItems | async" [value]="orderItem">
              <div class="order-item-description">{{orderItem.txtDescription}}</div><div class="order-item-on-order">({{orderItem.blnStock == false ? 'On Order:' : 'In Stock:'}} {{orderItem.fltQuantityTotal}})</div><div class="order-item-balance">{{orderItem.blnStock == false ? '(Bal: ' : ''}} {{orderItem.blnStock == false ? orderItem.fltQuantityTotal - orderItem.intFinalCount + ')': ''}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
  </div>
</div>

<div class="buttons-container">
  <button class="transferButton" mat-flat-button color="primary" (click)="transferfinalInspectionItem()">Transfer Final Inspection Item</button>
  <button class="cancelButton" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
</div>

