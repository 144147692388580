import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.css']
})
export class DateSelectorComponent implements OnInit {

  @Input() inputDate: Date;
  @Output() outputDateEvent = new EventEmitter<Date>();;
  @Output() cancelEvent = new EventEmitter<boolean>();;

  selected: Date | null;

  constructor() { }

  ngOnInit(): void {
  }

  setDate(date: Date) {

    console.log("in set date: ", date);
    //Sets time to 10am so when sent to server it
    //will store with no time because javascript
    //converts the time to UTC.
    //date.setHours(10, 0, 0)

    this.outputDateEvent.emit(date);

  }

  saveNoDate() {
    this.outputDateEvent.emit(null);
  }

  cancel() {
    this.cancelEvent.emit(true);
  }

}
