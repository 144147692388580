import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartDataset, ChartType, ChartOptions } from 'chart.js';
import { CHHChart } from '../../../../_shared/app-objects/chh-chart';
import { EmailMessage } from '../../../../_shared/app-objects/email-message';
import { FileAttachment } from '../../../../_shared/app-objects/file-attachment';
import { MicrosoftGraphFileAttachment } from '../../../../_shared/app-objects/microsoft-graph-file-attachment';
import { Body, MSGraphEmailMessage } from '../../../../_shared/app-objects/msgraph-email-message';
import { dtoDeliveredSalesBySection } from '../../../../_shared/business-objects/dto-delivered-sales-by-section.bo';
import { SQLParamArray } from '../../../../_shared/business-objects/sql-param-array';
import { FileService } from '../../../../_shared/services/app-services/file.service';
import { UtilityService } from '../../../../_shared/services/app-services/utility.service';
import { dtoDeliveredSalesBySectionService } from '../../../../_shared/services/dto-delivered-sales-by-section.service';
import { EmailService } from '../../../../_shared/services/email.service';
import { SharedService } from '../../../../_shared/services/shared.service';
import { ChhChartComponent } from '../../chh-chart/chh-chart.component';

export enum enChartReportType { WeeklySales, MonthlySales, QuarterlySales, YearlySales }

@Component({
  selector: 'app-delivered-sales-chart',
  templateUrl: './delivered-sales-chart.component.html',
  styleUrl: './delivered-sales-chart.component.scss'
})
export class DeliveredSalesChartComponent implements OnInit {

  chartYardSections: string[][];
  @ViewChild("deliveredSalesChart") deliveredSalesChart: ChhChartComponent;

  chart: CHHChart;

  constructor(private dtoDeliveredSalesBySectionService: dtoDeliveredSalesBySectionService, private route: ActivatedRoute, private sharedService: SharedService,
    private utilService: UtilityService, private fileService: FileService, private emailService: EmailService) {

  }


  deliveredSalesAll: dtoDeliveredSalesBySection[];
  deliveredSales: dtoDeliveredSalesBySection[];


  chartTitle: string;
  chartDataSet: ChartDataset[];
  chartLabel: string[];
  chartType: ChartType;
  chartLegend: boolean;
  chartOptions: ChartOptions;
  chartBase64Array: string[] = [];


  enChartType = enChartReportType;

  reportType: number[] = [];


  ngOnInit(): void {

    this.route.queryParams.subscribe(async params => {

      if (params != null) {
        let displayMode: string = params["displayMode"];
        let email: string = params["email"];
        let chartReportType: number = params["chartreporttype"];
        let chartYardSections: string[] = params["chartyardsections"];

       // console.log("chartYardSections: ", chartYardSections);
        //console.log("Array.isArray(params[chartreporttype]): ", Array.isArray(chartYardSections));
        if (chartYardSections != null && Array.isArray(chartYardSections)) {
          this.chartYardSections = [];
          for (let i = 0; i <= chartYardSections.length - 1; i++) {
            //console.log("chartYardSections[i]: ", chartYardSections[i]);
            if (chartYardSections[i].indexOf(",") > -1) {
              this.chartYardSections.push(chartYardSections[i].split(","));
            }
          }
        }
       //console.log("chartreporttypeArr: ", Array.isArray(params["chartreporttype"]));


        if (params["txtToken"] == null || params["txtToken"] == "") {
          // No token no go.
          return;
        }

        console.log("txtToken: ", params["txtToken"]);

        let emailReports: boolean = (email != null && email.toLowerCase() == "yes");

        await this.runTasks(emailReports);


      }

    });

  }

  async runTasks(email: boolean) {

    console.log("email: ", email);

    await this.getFinancialYearDeliveredSales("2023-07-01", "2024-08-27");
    for (let s = 0; s <= this.chartYardSections.length - 1; s++) {

      this.chart = null;
      await this.utilService.timeWait(500);

      //console.log("this.chartYardSections[s]", this.chartYardSections[s]);


      await this.loadChart(this.chartYardSections[s]);

      await this.utilService.timeWait(2000);

      let base64: string = await this.getChartBase64();
      this.chartBase64Array.push(base64);
      //console.log(base64);

    }

    //console.log(this.chartBase64Array);

    if (email == true) {
      this.emailGraphs()
    }
  }

  async getFinancialYearDeliveredSales(dateFrom: string, dateTo: string) {


    const datepipe: DatePipe = new DatePipe('en-AU');

    //console.log("about to get deliveredSalesAll: ", this.deliveredSalesAll);
    dateFrom = datepipe.transform(new Date(dateFrom), 'yyyy-MM-dd');
    dateTo = datepipe.transform(new Date(dateTo), 'yyyy-MM-dd');
    this.deliveredSalesAll = await this.dtoDeliveredSalesBySectionService.getdtoDeliveredSalesBySectionsPromise(dateFrom, dateTo);
    this.deliveredSalesAll.sort((a, b) => { return a.intYear > b.intYear || a.intMonth > b.intMonth || a.txtOrderItemProcesses > b.txtOrderItemProcesses ? 1 : -1 });
    //console.log("deliveredSalesAll: ", this.deliveredSalesAll);

  }


  async loadChart(chartYardSections: string[]) {
    return new Promise<boolean>(async resolve => {
      this.chart = new CHHChart()
      this.chart.setChartOption("Section Sales", "$", "$");


      let sections: string[] = this.deliveredSalesAll.map((a) => {
        return a.txtOrderItemProcesses
      });

      let setSections = new Set(sections);
      let sectionsArr: string[];
      if (setSections == null) {
        return;
      }

      sectionsArr = Array.from(setSections);
      let results3: { intYear, intMonth }[] = [];

      //var arr = [{ class: "second", fare: "a" }, { class: "second", fare: "b" }, { class: "first", fare: "a" }, { class: "first", fare: "a" }, { class: "second", fare: "a" }, { class: "first", fare: "c" }],
      var arr = this.deliveredSalesAll.map(item => ({ intYear: item.intYear, intMonth: item.intMonth }));
      results3 = arr.filter(function (a) {
          var key = a.intYear + '|' + a.intMonth;
          if (!this[key]) {
            this[key] = true;
            return true;
          }
        }, Object.create(null));

      console.log("results3", results3);


      //console.log("this.chartYardSections: ", this.chartYardSections);

      //console.log("sectionsArr: ", sectionsArr);

      let sectionTotalPrice: number[];
      for (let i = 0; i <= sectionsArr.length - 1; i++) {
        for (let s = 0; s <= chartYardSections.length - 1; s++) {

          if (chartYardSections[s].indexOf(sectionsArr[i]) > -1) {

            sectionTotalPrice = [];

            //FOR EACH MONTH OF FINANCIAL YEAR FOR SECTION PULL DATA OUT OF ARRAY.
            //let fyMonths: number[] = this.sharedService.FYMonths();
            let tempPriceArr: number[] = [];
            for (let m = 0; m <= results3.length - 1; m++) {

              tempPriceArr = this.deliveredSalesAll.filter((f) => { return f.txtOrderItemProcesses == sectionsArr[i] && f.intMonth == results3[m].intMonth && f.intYear == results3[m].intYear }).map((a) => {
                return Math.round(a.fltTotalPrice)
              });

              if (tempPriceArr.length == 0) {
                sectionTotalPrice.push(0)
              }

              if (tempPriceArr.length > 0) {
                sectionTotalPrice.push(tempPriceArr[0])
              }

            }


            this.chart.addChartData(sectionTotalPrice, sectionsArr[i], 0.2);
            //console.log(sectionsArr[i], sectionTotalPrice);
          }
        }


      }

      //let chartLabel: string[] = ['July', 'August', 'September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June']

      let chartLabel: string[] = results3.map((a) => {

        var date = new Date();
        var lastDay = new Date(a.intYear, a.intMonth, 0);
        let label: string = "";
        let day: number;
        if (lastDay > date) {
          day = Number(new Date().toLocaleDateString('en-AU', { day: 'numeric' }));
          label = day.toString() + this.sharedService.nth(day) + " ";
        }
        label += new Date(a.intYear + '-' + a.intMonth + '-01').toLocaleDateString('en-AU', { month: 'long', year: 'numeric' });
        return label
      });


      this.chartOptions = this.chart.chartOptions;
      this.chartDataSet = this.chart.chartData;
      this.chartLabel = chartLabel;
      this.chartType = "line";
      this.chartLegend = true;

      if (this.chartBase64Array == null) {
        this.chartBase64Array = [];
      }


      resolve(true);
    });
  }

  
  async getChartBase64() {
    return await this.deliveredSalesChart.chartBase64();
  }

  async emailGraphs() {

    let emailMessage2: MSGraphEmailMessage = new MSGraphEmailMessage("chhservice@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "Section Sales Graphs", "", []);

    if (this.chartBase64Array.length > 0) {

      let emailBody: string = "<h1>Section Sales Graphs</h1></br><br><br><br>";
      let emailMessage: EmailMessage = new EmailMessage("chhservice@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "Section Sales Graphs", "", null, null, true);

      //emailBody += "<p><img src=\"" + await this.getChartBase64() + "\"></p><br><br>";
      emailMessage.DataURLAttachments = [];
      let fileExt: string = "";
      let fileAttachments: FileAttachment[] = [];

      for (let i = 0; i <= this.chartBase64Array.length - 1; i++) {
        ////emailBody += "<p><img src=" + this.chartBase64Array[i].toString() + "></p><br><br>";
        fileExt = this.fileService.getBase64ContentExtensionFileType(this.chartBase64Array[i])
        emailBody += "<p><img width=\"100%\" height=\"100%\"  src=cid:File" + (i + 1).toString() + "." + fileExt + "></p><br><br>";
        emailMessage2.message.attachments.push(new MicrosoftGraphFileAttachment("File" + (i + 1).toString() + "." + fileExt, this.fileService.getBase64ContentType(this.chartBase64Array[i]), this.fileService.base64WithoutPrefix(this.chartBase64Array[i])));
      }

      emailMessage2.message.body = new Body(emailBody);
      
      console.log("emailMessage2", emailMessage2);
      //console.log(this.posSalesChart.chartBase64());

      //this.emailService.sendEmail2(emailMessage);

      let sent = this.emailService.sendEmailOnBehalfOf(emailMessage2, "9702481a-0b9b-44ff-9cce-e3ef0d7bb844");


    }

  }


}
