<div class="app-user-column-list-container">
  <table mat-table #tblAppUserColumns [dataSource]="datasource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="txtUserName">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnTH" mat-sort-header> User </th>
      <td mat-cell *matCellDef="let appUserColumn" class="appUserColumnTD appUserName"> {{appUserColumn.txtFirstName}} {{appUserColumn.txtLastName}}</td>
    </ng-container>

    <ng-container matColumnDef="txtPageName">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnTH" mat-sort-header> Page Name </th>
      <td mat-cell *matCellDef="let appUserColumn" class="appUserColumnTD appPageName"> {{appUserColumn.txtPageName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtColumnName">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnTH" mat-sort-header> Page Column </th>
      <td mat-cell *matCellDef="let appUserColumn" class="appUserColumnTD appUserColumnName"> {{appUserColumn.txtColumnName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtColumnNameAlias">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnTH" mat-sort-header> Page Column Alias </th>
      <td mat-cell *matCellDef="let appUserColumn" class="appUserColumnTD appUserColumnNameAlias"> {{appUserColumn.txtColumnNameAlias}} </td>
    </ng-container>

    <ng-container matColumnDef="txtColumnSetName">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnTH" mat-sort-header> Column Set</th>
      <td mat-cell *matCellDef="let appUserColumn" class="appUserColumnTD appUserColumnSetName"> {{appUserColumn.txtColumnSetName}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnTH" mat-sort-header> Date Created </th>
      <td mat-cell *matCellDef="let appUserColumn" class="appUserColumnTD appPageDateCreated"> {{appUserColumn.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="blnVisible">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnTH" mat-sort-header> Column Visible </th>
      <td mat-cell *matCellDef="let appUserColumn" class="appUserColumnTD appUserColumnColumnVisible"> {{appUserColumn.blnVisible}} </td>
    </ng-container>

    <ng-container matColumnDef="intColumnOrder">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnTH" mat-sort-header> Column Order </th>
      <td mat-cell *matCellDef="let appUserColumn" class="appUserColumnTD appUserColumnColumnOrder"> {{appUserColumn.intColumnOrder}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditAppUserColumn">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnTH appUserColumnEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let appUserColumn" class="appUserColumnTD appUserColumnEdit">
        <button class="edit-app-user-column-button" type="button" mat-flat-button color="primary" (click)="editAppUserColumn(appUserColumn)">Edit</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveAppUserColumn">
      <th mat-header-cell *matHeaderCellDef class="appUserColumnTH appUserColumnRemoveTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let appUserColumn" class="appUserColumnTD appUserColumnRemove">
        <button class="edit-app-user-column-button" type="button" mat-flat-button color="primary" (click)="removeAppUserColumn(appUserColumn)">Remove</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedAppUserColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedAppUserColumns;">
    </tr>

  </table>
  <div class="no-stock-items" *ngIf="datasource == null || datasource.data.length == 0"><h7>There are no app user columns</h7></div>
</div>
