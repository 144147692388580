import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeliveryDocketListComponent } from '../../delivery-docket/delivery-docket-list/delivery-docket-list.component';
import { dtoDeliveryDocket } from '../../_shared/business-objects/dto-delivery-docket.bo';
import { LoadingDocket } from '../../_shared/business-objects/loading-docket.bo';

@Component({
  selector: 'app-loading-docket-create-dialog',
  templateUrl: './loading-docket-create-dialog.component.html',
  styleUrls: ['./loading-docket-create-dialog.component.css']
})
export class LoadingDocketCreateDialogComponent {

  constructor(private dialogRef: MatDialogRef<DeliveryDocketListComponent>) {

  }

  loadingDocketCreated(loadingDocketCreatedObj: [LoadingDocket, string]) {

    this.dialogRef.close({ status: "Saved", loadingDocket: loadingDocketCreatedObj[0] });

  }


  loadingDocketCancelled(cancelled: boolean) {

    this.dialogRef.close({ status: "Cancel", loadingDocket: null });

  }
}
