export interface OrderItemProcessOrderItem
{
	dteLastUpdated: Date;
	dteStatusDate: Date;
	guOrderItemId: string;
	guOrderItemProcessId: string;
	intOrder: number;
	intStatus: number;
	rowguid: string;
}

export class OrderItemProcessOrderItem
{
	dteLastUpdated: Date;
	dteStatusDate: Date;
	guOrderItemId: string;
	guOrderItemProcessId: string;
	intOrder: number;
	intStatus: number;
	rowguid: string;
}

