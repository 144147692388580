export interface ReckonInvoiceQuery {
  FileName: string
  Operation: string
  UserName: string
  Password: string
  CountryVersion: string
}

export class ReckonInvoiceQuery {
  FileName: string
  Operation: string
  UserName: string
  Password: string
  CountryVersion: string
}
