import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { MobilePlantEquipment } from '../business-objects/mobile-plant-equipment.bo';

@Injectable()
export class MobilePlantEquipmentService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getMobilePlantEquipment(mobilePlantEquipmentId: string) {
		return this.http.get<MobilePlantEquipment>(this._baseUrl + 'api/MobilePlantEquipment/' + mobilePlantEquipmentId);
	}

	getMobilePlantEquipmentParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<MobilePlantEquipment[]>(this._baseUrl + 'api/MobilePlantEquipment/GetMobilePlantEquipmentsParamArray/', aParam);
	}

	getAllMobilePlantEquipments() {
		return this.http.get<MobilePlantEquipment[]>(this._baseUrl + 'api/MobilePlantEquipment/GetAllMobilePlantEquipments/');
	}

	//getAlldtoMobilePlantEquipments() {
	//	return this.http.get<dtoMobilePlantEquipment[]>(this._baseUrl + 'api/MobilePlantEquipment/GetAllMobilePlantEquipments/');
	//}

	createMobilePlantEquipment(mobilePlantEquipment: MobilePlantEquipment) {
		return this.http.post<MobilePlantEquipment>(this._baseUrl + 'api/MobilePlantEquipment/CreateMobilePlantEquipment/', mobilePlantEquipment);
	}

	updateMobilePlantEquipment(mobilePlantEquipment: MobilePlantEquipment) {
		return this.http.put<MobilePlantEquipment>(this._baseUrl + 'api/MobilePlantEquipment/UpdateMobilePlantEquipment/', mobilePlantEquipment);
	}

	async getMobilePlantEquipmentPromise(mobilePlantEquipmentId: string) {
		return new Promise<MobilePlantEquipment>(resolve => {
			this.getMobilePlantEquipment(mobilePlantEquipmentId).subscribe(mobilePlantEquipment => {
				resolve(mobilePlantEquipment);
			}, err => {
				this.alertService.openSnackBarError("Error getting MobilePlantEquipment records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getMobilePlantEquipmentParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<MobilePlantEquipment[]>(resolve => {
			this.getMobilePlantEquipmentParamArray(aParam).subscribe(mobilePlantEquipments => {
				resolve(mobilePlantEquipments);
			}, err => {
				this.alertService.openSnackBarError("Error getting MobilePlantEquipment records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateMobilePlantEquipmentPromise(mobilePlantEquipment: MobilePlantEquipment) {
		return new Promise<MobilePlantEquipment>(resolve => {
			this.updateMobilePlantEquipment(mobilePlantEquipment).subscribe(mobilePlantEquipment => {
				resolve(mobilePlantEquipment);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating MobilePlantEquipment record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createMobilePlantEquipmentPromise(mobilePlantEquipment: MobilePlantEquipment) {
		return new Promise<MobilePlantEquipment>(resolve => {
			this.createMobilePlantEquipment(mobilePlantEquipment).subscribe(mobilePlantEquipment => {
				resolve(mobilePlantEquipment);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating MobilePlantEquipment record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}