import { DatePipe } from '@angular/common';
import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { MatSelect } from '@angular/material/select';
import { AppPageColumn } from '../_shared/business-objects/app-page-column.bo';
import { AppPage } from '../_shared/business-objects/app-page.bo';
import { CodeBuilderColumn } from '../_shared/business-objects/code-builder-column.bo';
import { CodeBuilderTable } from '../_shared/business-objects/code-builder-table.bo';
import { dtoAppPageColumn } from '../_shared/business-objects/dto-app-page-column.bo';
import { dtoCodeBuilderColumn } from '../_shared/business-objects/dto-code-builder-column.bo';
import { dtoIntKeyValue } from '../_shared/business-objects/dto-int-key-value.bo';
import { SQLParamArray } from '../_shared/business-objects/sql-param-array';
import { AlertService } from '../_shared/services/alert.service';
import { AppPageService } from '../_shared/services/app-page.service';
import { AppService } from '../_shared/services/app-services/app.service';
import { ColumnService } from '../_shared/services/column.service';
import { dtoAppPageColumnService } from '../_shared/services/dto-app-page-column.service';
import { dtoCodeBuilderColumnService } from '../_shared/services/dto-code-builder-column.service';
import { SharedService } from '../_shared/services/shared.service';
import { TableService } from '../_shared/services/table.service';
import { Orders } from '../_shared/business-objects/orders.bo';
import { MatOption } from '@angular/material/core';
import { extend } from 'hammerjs';
import { FormService } from '../_shared/services/form.service';

@Component({
  selector: 'app-app-page-development',
  templateUrl: './app-page-development.component.html',
  styleUrl: './app-page-development.component.scss'
})
export class AppPageDevelopmentComponent {

  @ViewChild('htmlPage') htmlPage: ElementRef;
  @ViewChild('javascriptPage') javascriptPage: ElementRef;
  @ViewChild('guAppPageId') guAppPageId: MatSelectionList;
  @ViewChildren('appPageColumnFieldType') appPageColumnFieldType: QueryList<MatSelect>;
  @ViewChildren('appPageColumnFieldWidth') appPageColumnFieldWidth: QueryList<MatSelect>;
  @ViewChildren('appPageColumnSelectDisplayField') appPageColumnSelectDisplayField: QueryList<MatSelect>;
  @ViewChildren('appPageColumnSelectValueField') appPageColumnSelectValueField: QueryList<MatSelect>;

  appPages: AppPage[];
  appPagesAll: AppPage[];
  appPageColumns: dtoAppPageColumnExt[];
  appPageColumnsAll: dtoAppPageColumnExt[];
  selectedAppPageColumns: dtoAppPageColumnExt[] = [];
  selectedAppPage: AppPage;
  selectedCodeBuilderTable: CodeBuilderTable;

  codeBuilderColumns: dtoCodeBuilderColumn[];
  codeBuilderColumnsAll: dtoCodeBuilderColumn[];
  codeBuilderTables: CodeBuilderTable[];
  codeBuilderTablesAll: CodeBuilderTable[];
  selectedCodeBuilderColumns: dtoCodeBuilderColumn[] = [];
  columnHoverId: string;

  appPageColumnFieldTypes: dtoIntKeyValue[];
  appPageColumnFieldWidths: dtoIntKeyValue[];
  appPageColumnSelectDisplayFields: dtoIntKeyValue[];
  appPageColumnSelectValueFields: dtoIntKeyValue[];

  clickedAppColumnField: boolean = false;

  form: FormGroup;

  constructor(private fb: FormBuilder, private appService: AppService, private sharedService: SharedService, private alertService: AlertService
    , private columnService: ColumnService, private dtoCodeBuilderColumnServcie: dtoCodeBuilderColumnService, private tableService: TableService
    , private appPageService: AppPageService, private dtoAppPageColumnService: dtoAppPageColumnService, private formService: FormService ) {

    const datepipe: DatePipe = new DatePipe('en-AU');

    this.form = this.fb.group({
      dteDateCreated: [''],
      guCopyFromAppPageId: [''],
      guAppPageId: [''],
      txtColumnName: [''],
      txtColumnNameAlias: [''],
      intDefaultColumnOrder: [''],
      guCodeBuilderColumnId: [''],
      guTableId: [''],
      intAppPageColumnFieldType: [''],
      intAppPageColumnFieldWidth: [''],
      intAppPageColumnSelectDisplayField: [''],
      intAppPageColumnSelectValueField: ['']
    });

    this.appPageColumnFieldTypes = this.sharedService.getEnumObjectKeyValue(AppPageColumnFieldType.enAppPageColumnFieldType, true);
    this.appPageColumnFieldWidths = this.sharedService.getEnumObjectKeyValue(AppPageColumnFieldType.enAppPageColumnFieldWidth, true);

  }

  ngOnInit(): void {

    document.addEventListener("click", this.openCloseColumnFieldType.bind(this));

    this.loadData();

  }

  async loadData() {

    this.appPagesAll = await this.appPageService.getAppPageAllPromise();
    this.appPages = this.appPagesAll;

    this.codeBuilderTablesAll = await this.tableService.getAllTablesPromise();
    this.codeBuilderTables = this.codeBuilderTablesAll;

    for (let i = 0; i <= this.appPages.length - 1; i++) {
      console.log(this.appPages[i].txtAppPageObjectAliasName);
      if (this.appPages[i].txtAppPageObjectAliasName == "Quote") {
        this.form.controls['guAppPageId'].setValue([this.appPages[i]]);
        await this.getAppPageColumns(this.appPages[i]);
        this.createCode();
        break;
      }
    }
  }

  openCloseColumnFieldType(e) {

    //console.log("e.target.dataset: ", e.target.dataset);

   
    //
    // If you click on the add customer contact button exit function
    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.codeBuilderColumnSelect != null) {
      //console.log("clicked on expand button");

      return;

    }

  }


  async getAppPageColumns(appPage: AppPage) {

    this.selectedAppPage = appPage;

    console.log(appPage.rowguid);
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guAppPageId", appPage.rowguid));
    aParamList.push(aParam);

    this.appPageColumns = await this.dtoAppPageColumnService.getdtoAppPageColumnExtParamArrayPromise(aParamList);
    this.appPageColumns.sort((a, b) => { return a.intDefaultColumnOrder > b.intDefaultColumnOrder ? 1 : -1 || a.txtColumnName > b.txtColumnName ? 1 : -1 });

    this.appPageColumnsAll = this.appPageColumns;

    for (let i = 0; i <= this.appPageColumns.length - 1; i++) {
      if (this.appPageColumns[i].txtColumnName.toLocaleLowerCase() != "rowguid" && this.appPageColumns[i].txtColumnName.toLocaleLowerCase() != "dtelastupdated") {
        this.appPageColumns[i].guSelectedColumnId = "tempvalue";
        this.selectedAppPageColumns.push(this.appPageColumns[i]);
      }
    }

    aParamList = [];
    aParam = [];
    aParam.push(new SQLParamArray("txtTableName", appPage.txtAppPageObjectName));
    aParamList.push(aParam);

    if (appPage.txtAppPageObjectName != null && appPage.txtAppPageObjectName != "") {

      this.selectedCodeBuilderTable = await this.tableService.getTablePromise(appPage.txtAppPageObjectName);

      if (this.selectedCodeBuilderTable != null) {

        aParamList = [];
        aParam = [];
        aParam.push(new SQLParamArray("guCodeBuilderTableId", this.selectedCodeBuilderTable.rowguid));
        aParamList.push(aParam);
        this.codeBuilderColumns = await this.dtoCodeBuilderColumnServcie.getdtoCodeBuilderColumnParamArrayPromise(aParamList);
        this.codeBuilderColumns.sort((a, b) => { return a.txtColumnName > b.txtColumnName ? 1 : -1 });

      }

    }


    this.setDefaultFieldType();


  }

  filterAppPages(pageFilter: string) {
    this.appPages = this.appPagesAll;

    this.appPages = this.appPages.filter(a => a.txtPageName.toLowerCase().startsWith(pageFilter.toLowerCase()));
    //console.log("tableFilter: " + this.codeBuilderTables);

  }

  filterAppPageColumns(columnFilter: string) {
    this.appPageColumns = this.appPageColumnsAll;

    this.appPageColumns = this.appPageColumns.filter(a => a.txtColumnName.toLowerCase().indexOf(columnFilter.toLowerCase()) > -1);
    //console.log("tableFilter: " + this.codeBuilderTables);

  }

  async getTableColumns(tableId: string) {

    console.log(tableId);
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guCodeBuilderTableId", tableId));
    aParamList.push(aParam);

    this.codeBuilderColumns = await this.dtoCodeBuilderColumnServcie.getdtoCodeBuilderColumnParamArrayPromise(aParamList);
    this.codeBuilderColumns.sort((a, b) => { return a.txtColumnName > b.txtColumnName ? 1 : -1 });

    this.codeBuilderColumnsAll = this.codeBuilderColumns;

    for (let i = 0; i <= this.codeBuilderColumns.length - 1; i++) {
      if (this.codeBuilderColumns[i].txtColumnName.toLocaleLowerCase() != "rowguid" && this.codeBuilderColumns[i].txtColumnName.toLocaleLowerCase() != "dtelastupdated") {
        this.codeBuilderColumns[i].guSelectedColumnId = "tempvalue";
        this.selectedCodeBuilderColumns.push(this.codeBuilderColumns[i]);
      }
    }
  }

  filterTables(tableFilter: string) {
    this.codeBuilderTables = this.codeBuilderTablesAll;

    this.codeBuilderTables = this.codeBuilderTables.filter(a => a.txtTableName.toLowerCase().startsWith(tableFilter.toLowerCase()));
    //console.log("tableFilter: " + this.codeBuilderTables);

  }

  filterColumns(columnFilter: string) {
    this.codeBuilderColumns = this.codeBuilderColumnsAll;

    this.codeBuilderColumns = this.codeBuilderColumns.filter(a => a.txtColumnName.toLowerCase().indexOf(columnFilter.toLowerCase()) > -1);
    //console.log("tableFilter: " + this.codeBuilderTables);

  }

  setColumnFromCodeBuilder(codeBuilderColumn: CodeBuilderColumn) {
    console.log(codeBuilderColumn)

    //this.form.controls['txtColumnName'].setValue(codeBuilderColumn.txtColumnName);

    let columnName: string = codeBuilderColumn.txtColumnName.substr(3, codeBuilderColumn.txtColumnName.length - 3);
    columnName = this.sharedService.spaceBeforeCapital(columnName);

    //this.form.controls['txtColumnNameAlias'].setValue(columnName);

  }

  async create(mode: string) {




  }

  selectCodeBuilder(codeBuilderColumn: dtoCodeBuilderColumn, selected: boolean) {
    if (codeBuilderColumn == null) {
      this.alertService.openSnackBarDefault("There was a problem selecting the code Builder Column - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    //console.log("selected: ", selected);

    // Remove All ProductType Contact
    //if (this.multipleProductTypes != null && this.multipleProductTypes.checked == false) {

    //  let codeBuilderColumnSelected: dtoCodeBuilderColumn[] = this.codeBuilderColumns.filter((a) => { return a.guSelectedColumnId != null && a.guSelectedColumnId != "" });
    //  for (let i = 0; i <= codeBuilderColumnSelected.length - 1; i++) {
    //    codeBuilderColumnSelected[i].guSelectedColumnId = "";
    //  }

    //  for (let i = this.codeBuilderColumns.length - 1; i >= 0; i--) {
    //    this.codeBuilderColumns.splice(i, 1);
    //  }

    //  for (let i = this.codeBuilderColumns.length - 1; i >= 0; i--) {
    //    this.codeBuilderColumns[i].guSelectedColumnId = "";
    //  }

    //}

    // Remove ProductType Contact
    if (selected == false) {
      for (let i = this.selectedCodeBuilderColumns.length - 1; i >= 0; i--) {
        if (this.selectedCodeBuilderColumns[i].rowguid == codeBuilderColumn.rowguid) {
          this.selectedCodeBuilderColumns.splice(i, 1);
          codeBuilderColumn.guSelectedColumnId = "";
          //console.log("unselected productType");
          
          break;
        }
      }
    }

    // Add ProductType Contact
    if (selected == true) {

      codeBuilderColumn.guSelectedColumnId = "tempvalue";

      this.selectedCodeBuilderColumns.push(codeBuilderColumn);

       //console.log("added productType");

    }

  }

  selectAppPageColumns(appPageColumn: dtoAppPageColumnExt, selected: boolean) {

    if (this.clickedAppColumnField == true) {
      this.clickedAppColumnField = false;
      return;
    }


    if (appPageColumn == null) {
      this.alertService.openSnackBarDefault("There was a problem selecting the app page Column - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    //console.log("selected: ", selected);

    // Remove All ProductType Contact
    //if (this.multipleProductTypes != null && this.multipleProductTypes.checked == false) {

    //  let codeBuilderColumnSelected: dtoCodeBuilderColumn[] = this.codeBuilderColumns.filter((a) => { return a.guSelectedColumnId != null && a.guSelectedColumnId != "" });
    //  for (let i = 0; i <= codeBuilderColumnSelected.length - 1; i++) {
    //    codeBuilderColumnSelected[i].guSelectedColumnId = "";
    //  }

    //  for (let i = this.codeBuilderColumns.length - 1; i >= 0; i--) {
    //    this.codeBuilderColumns.splice(i, 1);
    //  }

    //  for (let i = this.codeBuilderColumns.length - 1; i >= 0; i--) {
    //    this.codeBuilderColumns[i].guSelectedColumnId = "";
    //  }

    //}

    // Remove ProductType Contact
    if (selected == false) {
      for (let i = this.selectedAppPageColumns.length - 1; i >= 0; i--) {
        if (this.selectedAppPageColumns[i].rowguid == appPageColumn.rowguid) {
          this.selectedAppPageColumns.splice(i, 1);
          appPageColumn.guSelectedColumnId = "";
          //console.log("unselected productType");

          break;
        }
      }
    }

    // Add ProductType Contact
    if (selected == true) {

      appPageColumn.guSelectedColumnId = "tempvalue";

      this.selectedAppPageColumns.push(appPageColumn);

      //console.log("added productType");

    }

  }

  fieldWidthChange(appPageColumn: dtoAppPageColumn, fieldWidth: number) {
    for (let i = 0; i <= this.selectedAppPageColumns.length - 1; i++) {
      if (appPageColumn.rowguid == this.selectedAppPageColumns[i].rowguid) {
        this.selectedAppPageColumns[i].intFieldWidth = fieldWidth;
        break;
      }
    }
  }

  fieldTypeChange(appPageColumn: dtoAppPageColumn, fieldType: number) {
    for (let i = 0; i <= this.selectedAppPageColumns.length - 1; i++) {
      if (appPageColumn.rowguid == this.selectedAppPageColumns[i].rowguid) {
        this.selectedAppPageColumns[i].intFieldType = fieldType;
        break;
      }
    }

  }

  selectDisplayFieldChange(appPageColumn: dtoAppPageColumn, selectDisplayField: string) {
    for (let i = 0; i <= this.selectedAppPageColumns.length - 1; i++) {
      if (appPageColumn.rowguid == this.selectedAppPageColumns[i].rowguid) {
        this.selectedAppPageColumns[i].txtSelectDisplayField = selectDisplayField;
        break;
      }
    }
  }

  selectValueFieldChange(appPageColumn: dtoAppPageColumn, selectValueField: string) {
    for (let i = 0; i <= this.selectedAppPageColumns.length - 1; i++) {
      if (appPageColumn.rowguid == this.selectedAppPageColumns[i].rowguid) {
        this.selectedAppPageColumns[i].txtSelectValueField = selectValueField;
        break;
      }
    }
  }

  rowEnter(columnId: string) {
    this.columnHoverId = columnId;
    //console.log("row enter");
  }

  rowLeave() {
    this.columnHoverId = "";
    //console.log("row leave");
  }

  cancel() {
  }

  resetFields(mode: string) {


  }

  clickedColumnFieldType() {

    this.clickedAppColumnField = true;
  }

  createCode() {

    console.log("in create code");


    this.createHTMLPage();
    this.createJavascriptPage();

  }

  createHTMLPage() {

    if (this.selectedAppPage == null) {
      return;
    }


    console.log(this.selectedAppPage);

    let sHTMLStart: string = "<div class=\"" + this.selectedAppPage.txtPageURL.toLowerCase() + "-container\">\n";
    sHTMLStart += "\t<div class=\"" + this.selectedAppPage.txtPageURL + "-form\" [formGroup]=\"form\">\n";

    let sHTMLFields: string = "";
    let sHTMLEnd: string = "";
    let sColumnName: string = "";
    let sColumnId: string = "";
    let pageName: string = this.selectedAppPage.txtPageName.replace(/ /g, "");
    let pageObjectName: string = this.selectedAppPage.txtAppPageObjectName;
    let pageObjectAliasName: string = this.selectedAppPage.txtAppPageObjectAliasName;
    let maxLength: number = 0;
    let codeBuilderColumn: dtoCodeBuilderColumn;
    let dataType: AppPageColumnFieldType.enDataType = AppPageColumnFieldType.enDataType.string;

    for (let i = 0; i <= this.selectedAppPageColumns.length - 1; i++) {
      //codeBuilderColumn = this.getCodeBuilderColumn(this.selectedAppPageColumns[i].txtColumnName);
      //if (codeBuilderColumn != null) {
      //}
      maxLength = Number(this.selectedAppPageColumns[i].intMaxCharacterLength);
      dataType = this.getJavascriptType(String(this.selectedAppPageColumns[i].txtDataType));

      sColumnName = this.selectedAppPageColumns[i].txtColumnNameAlias.toLowerCase().replace(/ /g, "-");
      sColumnId = this.selectedAppPageColumns[i].txtColumnName;
      sHTMLFields += "\t\t<div class=\"" + this.selectedAppPage.txtPageURL + "-" + sColumnName + "\">\n";

      if (this.selectedAppPageColumns[i].intFieldType == AppPageColumnFieldType.enAppPageColumnFieldType.TextboxString) {
        if (Number(this.selectedAppPageColumns[i].intMaxCharacterLength) >= 1024) {
          sHTMLFields += this.createTextAreaFieldHTML(this.selectedAppPageColumns[i].intFieldWidth, this.selectedAppPageColumns[i].txtColumnNameAlias, sColumnName, sColumnId, dataType, maxLength);
        } else {
          sHTMLFields += this.createTextboxFieldHTML(this.selectedAppPageColumns[i].intFieldWidth, this.selectedAppPageColumns[i].txtColumnNameAlias, sColumnName, sColumnId, dataType, maxLength);
        }
      }

      if (this.selectedAppPageColumns[i].intFieldType == AppPageColumnFieldType.enAppPageColumnFieldType.ListboxSingle || this.selectedAppPageColumns[i].intFieldType == AppPageColumnFieldType.enAppPageColumnFieldType.ListboxMultiple) {
        sHTMLFields += this.createListboxHTML(this.selectedAppPageColumns[i].intFieldWidth, this.selectedAppPageColumns[i].txtColumnNameAlias, sColumnName, sColumnId, this.selectedAppPageColumns[i].txtSelectDisplayField, this.selectedAppPageColumns[i].txtSelectValueField);
      }

      sHTMLFields += "\t\t</div>\n\n";
    }

    sHTMLEnd = "\t</div>\n\n";

    sHTMLEnd += "\t<div class=\"action-button-container\">\n";
    sHTMLEnd += "\t\t<button class=\"action-button\" mat-flat-button color=\"primary\" (click)=\"create" + pageObjectAliasName + "('')\">Create " + pageObjectAliasName + "</button>\n";
    sHTMLEnd += "\t\t<button class=\"action-button\" mat-flat-button color=\"primary\" (click)=\"create" + pageObjectAliasName + "('Create" + pageObjectAliasName + "AddNew')\">Create " + pageObjectAliasName + " And Add New</button>\n";
    sHTMLEnd += "\t\t<button class=\"action-button\" mat-flat-button color=\"primary\" (click)=\"cancel" + pageObjectAliasName + "()\">Cancel</button>\n";
    sHTMLEnd += "\t</div>\n";


    sHTMLEnd += "</div>\n\n";

    sHTMLEnd += this.createSpinnerHTML(pageObjectAliasName);

    this.htmlPage.nativeElement.value = sHTMLStart + sHTMLFields + sHTMLEnd;


  }

  getCodeBuilderColumn(columnName: string) {
    let codeBuilderColumn: dtoCodeBuilderColumn;
    if (this.codeBuilderColumns != null && this.codeBuilderColumns.length > 0) {
      codeBuilderColumn = null;
      for (let i = 0; i <= this.codeBuilderColumns.length - 1; i++) {
        if (this.codeBuilderColumns[i].txtColumnName == columnName) {
          return this.codeBuilderColumns[i];
        }
      }
    }
  }

  createTextboxStringFieldHTML(width: number, label: string, className: string, columnId: string, maxLength: number) {
    return this.createTextboxFieldHTML(width, label, className, columnId, AppPageColumnFieldType.enDataType.string, maxLength);
  }

  createTextboxNumberFieldHTML(width: number, label: string, className: string, columnId: string, maxLength: number) {
    return this.createTextboxFieldHTML(width, label, className, columnId, AppPageColumnFieldType.enDataType.number, maxLength);
  }


  createTextboxFieldHTML(width: number, label: string, className: string, columnId: string, dataType: AppPageColumnFieldType.enDataType, maxLength: number) {
    let widthText: string = "";
    let textboxType: string = "";
    let maxLengthText: string = "";

    if (maxLength > 0) {
      maxLengthText = " maxlength=\"" + maxLength.toString() + "\"";
    }


    for (let i = 0; i <= this.appPageColumnFieldWidths.length - 1; i++) {
      if (this.appPageColumnFieldWidths[i].intValue == width) {
        widthText = String(this.columnFieldWidthText(this.appPageColumnFieldWidths[i].intValue));
        break;
      }
    }
    console.log("datatype: " + dataType);

    switch (dataType) {
      case AppPageColumnFieldType.enDataType.number:
        textboxType = "number";
      default:
        textboxType = "text"
    }


    let textboxStr: string = "\t\t\t<mat-form-field class=\"chh-form-field chh-textbox-" + widthText.toLowerCase() + " " + label.toLowerCase().replace(/ /g, "-") + "-form-field\" appearance=\"fill\">\n";
    textboxStr += "\t\t\t<mat-label>" + label + "</mat-label>\n"
    textboxStr += "\t\t\t\t<input class=\"textbox-input\" matInput value=\"\" type=\"" + textboxType + "\" id=\"" + columnId + "\" formControlName=\"" + columnId + "\"" + maxLengthText + ">\n";
    textboxStr += "\t\t\t</mat-form-field>\n";
    return textboxStr;
  }

  createTextAreaFieldHTML(width: number, label: string, className: string, columnId: string, dataType: AppPageColumnFieldType.enDataType, maxLength: number) {
    let textareaStr: string = "\t\t\t<mat-form-field class=\"chh-form-field example-full-width " + label.toLowerCase().replace(/ /g, "-") + "-form-field\" appearance=\"fill\">\n"
    textareaStr += "\t\t\t<mat-label>Comments</mat-label>\n"
    textareaStr += "\t\t\t\t<textarea class=\"comments\" matInput value=\"\" id=\"" + columnId + "\" formControlName=\"" + columnId + "\"></textarea>\n"
    textareaStr += "\t\t\t</mat-form-field>\n"
    return textareaStr;
  }
  
  createSpinnerHTML(label: string) {
    let spinnerStr: string = "<app-spinner * ngIf=\"showSpinner\" [showSpinner]=\"showSpinner\" [spinnerId]=\"'" + this.sharedService.camelize(label.replace(/ /g, "")) + "Spinner'\" class=\"" + label.toLowerCase().replace(/ /g, "-") + "-spinner\"></app-spinner>\n"
    return spinnerStr;
  }

  createListboxHTML(width: number, label: string, className: string, columnId: string, displayField: string, valueField: string) {
    let listboxStr: string = "\t\t\t<div class=\"" + label.toLowerCase().replace(/ /g, "-") + "-label container-label\">" + label + "</div>\n";
    listboxStr += "\t\t\t<mat-selection-list class=\"chh-mat-selection-list " + label.toLowerCase().replace(/ /g, "-") + "-select\" #" + columnId + " [multiple]=\"false\" id=\"" + columnId + "\" formControlName=\"" + columnId + "\">\n";
    listboxStr += "\t\t\t\t<mat-list-option *ngFor=\"let " + this.sharedService.camelize(label.replace(/ /g, "")) + " of " + this.sharedService.camelize(label.replace(/ /g, "")) + "s\" [value]=\"" + this.sharedService.camelize(label.replace(/ /g, "")) + "." + valueField + "\" (click)=\"" + this.sharedService.camelize(label.replace(/ /g, "")) + "Click(" + this.sharedService.camelize(label.replace(/ /g, "")) + ")\">\n";
    listboxStr += "\t\t\t\t{{" + this.sharedService.camelize(label.replace(/ /g, "")) + "." + displayField + "}}\n";
    listboxStr += "\t\t\t\t</mat-list-option>\n";
    listboxStr += "\t\t\t</mat-selection-list>\n";
    return listboxStr;
  }

  createJavascriptPage() {

    let sJavascript: string = this.buildJavascriptPage();
    this.javascriptPage.nativeElement.value = sJavascript;

  }


  buildJavascriptPage() {

    if (this.selectedAppPage == null) {
      return;
    }


    let jsStr: string = "import { Component, EventEmitter, Output } from '@angular/core';\n"
    jsStr += "import { FormBuilder, FormGroup, Validators } from '@angular/forms';\n"
    jsStr += "import { SQLParamArray } from '../../../_shared/business-objects/sql-param-array';\n"
    jsStr += "import { AlertService } from '../../../_shared/services/alert.service';\n"
    jsStr += "import { FormService } from '../../../_shared/services/form.service';\n"
    jsStr += "import { SharedService } from '../../../_shared/services/shared.service';\n"
    jsStr += "import { " + this.selectedAppPage.txtAppPageObjectName + " } from '../../../_shared/business-objects/" + this.selectedAppPage.txtAppPageObjectName + ".bo';\n";

    let fkTableName: string[] = [];
    let fkSelectDisplayField: string[] = [];
    let fkDataType: string[] = [];
    let fkFieldType: number[] = [];
    let intListBoxes: string[] = [];
    let dataType: AppPageColumnFieldType.enDataType = AppPageColumnFieldType.enDataType.string;

    let exists: boolean = false;
    for (let i = 0; i <= this.selectedAppPageColumns.length - 1; i++) {

      exists = false;

      for (let f = 0; f <= fkTableName.length - 1; f++) {
        if (this.selectedAppPageColumns[i].txtFKTableName == fkTableName[f]) {
          exists = true;
          break;
        }
      }

      if (exists == false && this.selectedAppPageColumns[i].txtFKTableName != null) {
        fkTableName.push(this.selectedAppPageColumns[i].txtFKTableName);
        fkSelectDisplayField.push(this.selectedAppPageColumns[i].txtSelectDisplayField);
        fkDataType.push(this.selectedAppPageColumns[i].txtDataType);
        fkFieldType.push(this.selectedAppPageColumns[i].intFieldType);
      }

      dataType = this.getJavascriptType(String(this.selectedAppPageColumns[i].txtDataType));
      
      if (dataType == AppPageColumnFieldType.enDataType.number && this.selectedAppPageColumns[i].intFieldType == AppPageColumnFieldType.enAppPageColumnFieldType.ListboxSingle) {
        intListBoxes.push(this.selectedAppPageColumns[i].txtColumnName);
        //console.log("txtColumnName", this.selectedAppPageColumns[i].txtColumnName)
      }

    }

    for (let i = 0; i <= fkTableName.length - 1; i++) {

      if (fkTableName[i] != null) {
        jsStr += "import { dto" + fkTableName[i] + " } from '../../../_shared/business-objects/dto" + fkTableName[i].toLowerCase() + ".bo';\n";
      }

    }

    jsStr += "\n\n";

    jsStr += "@Component({\n";
    jsStr += "\t\tselector: 'app-" + this.selectedAppPage.txtAppPageObjectAliasName.toLowerCase() + "-create',\n";
    jsStr += "\t\ttemplateUrl: '.\/" + this.selectedAppPage.txtAppPageObjectAliasName.toLowerCase() + "-create.component.html',\n";
    jsStr += "\t\tstyleUrl: '.\/" + this.selectedAppPage.txtAppPageObjectAliasName.toLowerCase() + "-create.component.scss'\"\n";
    jsStr += "})\n";
    jsStr += "export class " + this.selectedAppPage.txtAppPageObjectAliasName + "CreateComponent {\n";

    jsStr += "\t\t@Output() " + this.selectedAppPage.txtAppPageObjectAliasName.toLowerCase() + "Created = new EventEmitter<{ " + this.selectedAppPage.txtAppPageObjectAliasName.toLowerCase() + ": dto" + this.selectedAppPage.txtAppPageObjectAliasName + ", type: string }>();\n";
    jsStr += "\t\t@Output() " + this.selectedAppPage.txtAppPageObjectAliasName.toLowerCase() + "Cancelled = new EventEmitter<boolean>();\n";

    jsStr += "\n\n";

    jsStr += "\t\tform: FormGroup;\n";

    jsStr += "\n\n";

    for (let i = 0; i <= fkTableName.length - 1; i++) {

      if (fkTableName[i] != null) {
        jsStr += "\t\t" + this.sharedService.camelize(fkTableName[i]) + "s: dto" + fkTableName[i] + "[];\n";
      }
    }

    
    for (let i = 0; i <= intListBoxes.length - 1; i++) {

      if (intListBoxes[i] != null) {
        jsStr += "\t\t" + this.sharedService.camelize(intListBoxes[i].substring(3, intListBoxes[i].length)) + "s: dtoIntKeyValue[];\n";
      }

    }

    jsStr += "\n\n";

    jsStr += "\t\tshowSpinner: boolean\n\n";

    jsStr += "\t\tconstructor(private formBuilder: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private formService: FormService\n";

    for (let i = 0; i <= fkTableName.length - 1; i++) {

      if (fkTableName[i] != null) {
        if (i == 0) {
          jsStr += "\t\t\t";
        }
        jsStr += ", private dto" + this.sharedService.camelize(fkTableName[i]) + "Service: dto" + fkTableName[i] + "Service";
      }
    }

    jsStr += ") {\n";

    jsStr += "\t\t}\n\n";

    jsStr += "\t\tngOnInit(): void {\n";

    jsStr += "\t\t\tthis.form = this.formBuilder.group({\n";
    for (let i = 0; i <= this.selectedAppPageColumns.length - 1; i++) {

      dataType = this.getJavascriptType(String(this.selectedAppPageColumns[i].txtDataType));
      
      //console.log("nullable: ", this.selectedAppPageColumns[i].txtIsNullable);

      jsStr += "\t\t\t\t" + this.selectedAppPageColumns[i].txtColumnName + ": [''";
      if (this.selectedAppPageColumns[i].txtIsNullable == "NO") {
        jsStr += ", Validators.required";
      }
      jsStr += "]";
      if (i < this.selectedAppPageColumns.length - 1) {
        jsStr += ",";
      }

      jsStr += "\n";

      if (dataType == AppPageColumnFieldType.enDataType.number && this.selectedAppPageColumns[i].intFieldType == AppPageColumnFieldType.enAppPageColumnFieldType.ListboxSingle) {
      }

    }

    jsStr += "\t\t\t});\n\n";
    
    jsStr += "\t\t\tthis.showSpinner = false;\n\n";

    for (let i = 0; i <= intListBoxes.length - 1; i++) {

      jsStr += "\t\t\tthis." + this.sharedService.camelize(intListBoxes[i]) + "s = this.sharedService.getEnumObjectKeyValue(" + this.sharedService.camelize(intListBoxes[i]) + ".en" + this.sharedService.camelize(intListBoxes[i]) + ", true);\n";
      jsStr += "\t\t\tthis." + this.sharedService.camelize(intListBoxes[i]) + "s.sort((a, b) => { return a.txtValue > b.txtValue ? 1 : -1 });\n";
      jsStr += "\t\t\tthis." + this.sharedService.camelize(intListBoxes[i]) + "s.splice(0, 0, new dtoIntKeyValue(-1, \"None\"));\n";

    }

    jsStr += "\n\n";

    jsStr += "\t\t\tthis.loadData();\n\n";

    jsStr += "\t\t}\n\n";

    //jsStr += "\t\tasync loadTestData() {\n";

    //jsStr += "\t\t\t\/\/let aParamList: SQLParamArray[][] = [];\n";
    //jsStr += "\t\t\t\/\/let aParam: SQLParamArray[] = [];\n";
    //jsStr += "\t\t\t\/\/aParam.push(new SQLParamArray(\"txtName\", \"Martin McCarthy\"));\n";
    //jsStr += "\t\t\t\/\/aParamList.push(aParam);\n";

    //jsStr += "\t\t\tlet testCustomer: dtoCustomer = await this.dtoCustomerService.getdtoCustomerPromise(\"F392E3E8-0083-4C0C-9B31-7438F473A203\");\n";


    //jsStr += "\t\t\tthis.form.controls['txtCustomerName'].setValue(testCustomer.txtName);\n";
    //jsStr += "\t\t\tthis.form.controls['intSectorType'].setValue([testCustomer.intSectorType]);\n";
    //jsStr += "\t\t\tthis.form.controls['guPricingCategoryId'].setValue([testCustomer.guPricingCategoryId]);\n";
    //jsStr += "\t\t\tthis.form.controls['blnActive'].setValue(testCustomer.blnActive);\n";
    //jsStr += "\t\t\tthis.form.controls['txtLegalBusinessName'].setValue(testCustomer.txtBusinessName);\n";
    //jsStr += "\t\t\tthis.form.controls['txtOldTradingName'].setValue(testCustomer.txtOldTradingName);\n";
    //jsStr += "\t\t\tthis.form.controls['blnCombineOldTradingName'].setValue(testCustomer.blnCombineOldTradingName);\n";
    //jsStr += "\t\t\tthis.form.controls['blnIsABusiness'].setValue(testCustomer.blnIsABusiness);\n";

    //jsStr += "\t\t}\n";


    jsStr += "\t\tasync loadData() {\n\n";

    for (let i = 0; i <= fkTableName.length - 1; i++) {

      if (fkTableName[i] != null) {

        console.log("fkTableName[i]", fkTableName[i]);

        dataType = this.getJavascriptType(String(fkDataType[i]));
        if (dataType != AppPageColumnFieldType.enDataType.number && fkFieldType[i] == AppPageColumnFieldType.enAppPageColumnFieldType.ListboxSingle) {
          jsStr += "\t\t\tthis." + this.sharedService.camelize(fkTableName[i]) + "s = await this.dto" + this.sharedService.camelize(fkTableName[i]) + "Service.getAll" + fkTableName[i] + "Promise();\n";
          jsStr += "\t\t\tthis." + this.sharedService.camelize(fkTableName[i]) + "s.sort((a, b) => { return a." + fkSelectDisplayField[i] + " > b." + fkSelectDisplayField[i] + " ? 1 : -1 }); \n";
        }
      }
    }

    jsStr += "\t\t}\n\n";

    jsStr += "\n\n";

    jsStr += "\t\tasync create" + this.selectedAppPage.txtAppPageObjectAliasName + "(type: string) {\n";

    for (let i = 0; i <= this.selectedAppPageColumns.length - 1; i++) {

      dataType = this.getJavascriptType(String(this.selectedAppPageColumns[i].txtDataType));
      let jsDataType: string = "string";
      if (dataType == AppPageColumnFieldType.enDataType.number) {
        jsDataType = "number";
      }

      if (dataType == AppPageColumnFieldType.enDataType.date) {
        jsDataType = "Date";
      }

      if (dataType == AppPageColumnFieldType.enDataType.boolean) {
        jsDataType = "boolean";
      }

      //console.log("nullable: ", this.selectedAppPageColumns[i].txtIsNullable);

      jsStr += "\t\t\tlet " + this.selectedAppPageColumns[i].txtColumnName + ": " + jsDataType + " = this.form.controls['" + this.selectedAppPageColumns[i].txtColumnName + "'].value;\n";

    }


    for (let i = 0; i <= this.selectedAppPageColumns.length - 1; i++) {

      if (this.selectedAppPageColumns[i].txtIsNullable == "NO") {

        jsStr += "\t\t\tif (" + this.selectedAppPageColumns[i].txtColumnName + " == null || " + this.selectedAppPageColumns[i].txtColumnName + " == \"\") {\n";
        jsStr += "\t\t\t\tthis.alertService.openSnackBar(\"Please enter a " + this.selectedAppPageColumns[i].txtColumnName + ".\", \"Close\", \"center\", \"bottom\", \"\", 3000);\n";
        jsStr += "\t\t\t\treturn;\n";
        jsStr += "\t\t\t}\n\n";

      }
    }

    let tableAlaisObject: string = this.sharedService.camelize(this.selectedAppPage.txtAppPageObjectAliasName);
    jsStr += "\t\t\tlet " + tableAlaisObject + ": " + this.selectedAppPage.txtAppPageObjectName + " = new " + this.selectedAppPage.txtAppPageObjectName + "();\n";
    for (let i = 0; i <= this.selectedAppPageColumns.length - 1; i++) {

      dataType = this.getJavascriptType(String(this.selectedAppPageColumns[i].txtDataType));
      let jsDataType: string = "string";
      if (dataType == AppPageColumnFieldType.enDataType.number) {
        jsDataType = "number";
      }

      if (dataType == AppPageColumnFieldType.enDataType.date) {
        jsDataType = "Date";
      }

      if (dataType == AppPageColumnFieldType.enDataType.boolean) {
        jsDataType = "boolean";
      }

      //console.log("nullable: ", this.selectedAppPageColumns[i].txtIsNullable);
      if (this.selectedAppPageColumns[i].txtColumnDefault != null && this.selectedAppPageColumns[i].txtColumnDefault.indexOf("getdate") > - 1 && jsDataType == "Date") {
        jsStr += "\t\t\t" + tableAlaisObject + "." + this.selectedAppPageColumns[i].txtColumnName + " = this.sharedService.currentDatePlusTZOffset();\n";
      } else {
        jsStr += "\t\t\t" + tableAlaisObject + "." + this.selectedAppPageColumns[i].txtColumnName + " = " + this.selectedAppPageColumns[i].txtColumnName + ";\n";

      }

   }


    jsStr += "\t\t\tcustomer.intSectorType = intSectorType;\n";
    jsStr += "\t\t\tcustomer.guPricingCategoryId = guPricingCategoryId;\n";
    jsStr += "\t\t\tcustomer.blnActive = blnActive;\n";
    jsStr += "\t\t\tcustomer.txtBusinessName = txtLegalBusinessName;\n";
    jsStr += "\t\t\tcustomer.txtOldTradingName = txtOldTradingName;\n";
    jsStr += "\t\t\tcustomer.blnCombineOldTradingName = blnCombineOldTradingName;\n";

    jsStr += "\t\t\tthis.showSpinner = true;\n";

    jsStr += "\t\t\tconsole.log(customer);\n";

    jsStr += "\t\t\tlet existingCustomer: dtoCustomer = await this.dtoCustomerService.getdtoCustomerByName(customer.txtName);\n";
    jsStr += "\t\t\tif (existingCustomer != null) {\n";

    jsStr += "\t\t\t\tthis.showSpinner = false;\n";

    jsStr += "\t\t\t\tlet text: string = \"A customer already exists with that name and the following details\n\";\n";
    jsStr += "\t\t\t\ttext += existingCustomer.txtName + \"\n\";\n";

    jsStr += "\t\t\t\tlet msg: string = await this.alertService.openSnackBarCustomDefaultPromise(\"Existing Customer\", text);\n";

    jsStr += "\t\t\t\treturn;\n";
    jsStr += "\t\t\t}\n";

    jsStr += "\t\t\tcustomer = await this.customerService.createClientPromise(customer);\n";

    jsStr += "\t\t\tif (customer != null) {\n";

    jsStr += "\t\t\t\t\/\/let dtoCustomer: dtoCustomer = await this.dtoCustomerService.getdtoCustomerPromise(customer.rowguid);\n";


    jsStr += "\t\t\t\tthis.alertService.openSnackBar(\"The customer has been created\", \"Close\", \"center\", \"bottom\", \"\", 3000);\n";
    jsStr += "\t\t\t\tthis.showSpinner = false;\n";

    jsStr += "\t\t\t\tthis.customerCreated.emit({ customer: dtoCustomer, type: type }); \n";
    jsStr += "\t\t\t\t\/\/this.customerCreated.emit({ customer: null, type: type }); \n";

    jsStr += "\t\t\t}\n";


    jsStr += "\t\t\tif (customer == null) {\n";
    jsStr += "\t\t\t\tthis.showSpinner = false;\n";
    jsStr += "\t\t\t\tthis.alertService.openSnackBarError(\"There was a problem creating the customer\", \"Close\", \"center\", \"bottom\", 4000, true, \"There was a problem creating the customer contact\");\n";
    jsStr += "\t\t\t}\n";

    jsStr += "\t\t}\n";

    jsStr += "\t\tcancelCustomer() {\n";
    jsStr += "\t\t\tthis.customerCancelled.emit(true);\n";

    jsStr += "\t\t}\n";

    jsStr += "}\n";

    return jsStr;

  }


  public columnFieldWidthText(width: number) {
    switch(width) {
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH50:
        return "50";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH100:
        return "100";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH125:
        return "125";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH150:
        return "150";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH175:
        return "175";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH200:
        return "200";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH225:
        return "225";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH250:
        return "250";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH275:
        return "275";        
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH300:
        return "300";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH325:
        return "325";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH350:
        return "350";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH375:
        return "375";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH400:
        return "400";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH425:
        return "425";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH450:
        return "450";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH475:
        return "475";
      case AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH500:
        return "500";
    }
  }

  getDefaultFieldType(fieldDataType: AppPageColumnFieldType.enDataType): AppPageColumnFieldType.enAppPageColumnFieldType {
    if (fieldDataType == null) {
      return AppPageColumnFieldType.enAppPageColumnFieldType.TextboxString;
    }

    switch (fieldDataType) {
      case AppPageColumnFieldType.enDataType.string:
        return AppPageColumnFieldType.enAppPageColumnFieldType.TextboxString
      case AppPageColumnFieldType.enDataType.number:
        return AppPageColumnFieldType.enAppPageColumnFieldType.TextboxNumeric
      case AppPageColumnFieldType.enDataType.uniqueidentifier:
        return AppPageColumnFieldType.enAppPageColumnFieldType.ListboxSingle
      default:
        return AppPageColumnFieldType.enAppPageColumnFieldType.TextboxString

    }



  }

  getJavascriptType(sqlDataType: string): AppPageColumnFieldType.enDataType {
    if (sqlDataType == null || sqlDataType == "") {
      return 0;
    }

    switch (sqlDataType) {
      case "bigint":
      case "decimal":
      case "money":
      case "numeric":
      case "float":
      case "smallint":
      case "real":
      case "smallint":
      case "tinyint":
      case "int":
        return AppPageColumnFieldType.enDataType.number;
      case "bit":
        return AppPageColumnFieldType.enDataType.boolean;
      case "char":
      case "nchar":
      case "nvarchar":
      case "varchar":
      case "text":
      case "xml":
        return AppPageColumnFieldType.enDataType.string;
      case "date":
      case "datetime":
      case "smalldatetime":
        return AppPageColumnFieldType.enDataType.date;
      case "uniqueidentifier":
        return AppPageColumnFieldType.enDataType.uniqueidentifier;
      default:
        return 0;
    }

  }

  getDefaultColumWidth(maxColumnLength: number): AppPageColumnFieldType.enAppPageColumnFieldWidth {
    if (maxColumnLength == null || maxColumnLength == 0) {
      return AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH100;
    }

    if (maxColumnLength <= 100) {
      return AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH100;
    }

    if (maxColumnLength > 100 && maxColumnLength <= 255) {
      return AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH400;
    }

    if (maxColumnLength > 255 && maxColumnLength <= 1024) {
      return AppPageColumnFieldType.enAppPageColumnFieldWidth.CHH500;
    }

  }

  setDefaultFieldType() {

    //console.log("this.appPageColumnFieldType ", this.appPageColumnFieldType.forEach);

    if (this.appPageColumnFieldType == null) {
      return;
    }

    for (let i = 0; i <= this.appPageColumns.length - 1; i++) {
      //console.log("getJavascriptType: ", this.getJavascriptType(this.appPageColumns[i].txtDataType));
      //console.log("this.appPageColumnFieldType[i] :", this.appPageColumnFieldType[i]);

      let c: number = 0;
      this.appPageColumnFieldType.forEach((s) => {

        if (i == c) {
          let iValue = this.getDefaultFieldType(this.getJavascriptType(this.appPageColumns[i].txtDataType));
          //console.log("txtColumnName:  " + this.appPageColumns[i].txtColumnName + ",   this.appPageColumns[i].intMaxCharacterLength: " + this.appPageColumns[i].intMaxCharacterLength + ",  iValue: " + iValue);
          s.value = iValue;
          this.appPageColumns[i].intFieldType = iValue;
          //s.options.forEach((o) => {
          //  console.log("o._text",o._text);
          //});

        }

        c++;
      });

      let w: number = 0;
      this.appPageColumnFieldWidth.forEach((s) => {

        if (i == w) {
          let iValue = this.getDefaultColumWidth(this.appPageColumns[i].intMaxCharacterLength);
          //console.log("txtColumnName:  " + this.appPageColumns[i].txtColumnName + ",   this.appPageColumns[i].intMaxCharacterLength: " + this.appPageColumns[i].intMaxCharacterLength + ",  iValue: " + iValue);
          s.value = iValue;
          this.appPageColumns[i].intFieldWidth = iValue;
          //s.options.forEach((o) => {
          //  console.log("o._text",o._text);
          //});

        }

        w++;
      });

      
     let f: number = 0;
     this.appPageColumnSelectDisplayField.forEach((fkc) => {
       if (i == f) {
          if (this.appPageColumns[i].intFieldType == AppPageColumnFieldType.enAppPageColumnFieldType.ListboxSingle) {
            //console.log(this.appPageColumns[i].txtColumnName);

            if (this.appPageColumns[i].txtFKColumns != null) {
              this.appPageColumns[i].arFKColumns = this.sharedService.getArrayKeyValue(this.appPageColumns[i].txtFKColumns.split(','), false);
              this.appPageColumns[i].arFKColumns.sort((a, b) => { return a.txtValue > b.txtValue ? 1 : -1 });
              //console.log("getArrayKeyValue: ", this.appPageColumns[i].arFKColumns);

              let isName: boolean = false;
              for (let c = 0; c <= this.appPageColumns[i].arFKColumns.length - 1; c++) {
                //console.log("arFKColumns: ", this.appPageColumns[i].arFKColumns[c].txtValue.toLocaleLowerCase() + ",  " + (this.appPageColumns[i].arFKColumns[c].txtValue.toLocaleLowerCase() == "txtname"));
               if (this.appPageColumns[i].arFKColumns[c].txtValue == "txtName") {
                  isName = true;
                  break;
                }
              }

              if (isName == true) {
                //console.log("this.appPageColumns[i].arFKColumns: ", this.appPageColumns[i].arFKColumns);
                this.appPageColumns[i].txtSelectDisplayField = "txtName";
                fkc.value = "txtName";
              }
            }
          }
       }

         f++;
     });

      let v: number = 0;
      this.appPageColumnSelectValueField.forEach((fkc) => {
        if (i == v) {
          if (this.appPageColumns[i].intFieldType == AppPageColumnFieldType.enAppPageColumnFieldType.ListboxSingle) {
            console.log(this.appPageColumns[i].txtColumnName);

            //We should already have arFKColumns from above loop.
            if (this.appPageColumns[i].arFKColumns != null) {

              let isRowguid: boolean = false;
              for (let c = 0; c <= this.appPageColumns[i].arFKColumns.length - 1; c++) {
                console.log("arFKColumns: ", this.appPageColumns[i].arFKColumns[c].txtValue.toLocaleLowerCase() + ",  " + (this.appPageColumns[i].arFKColumns[c].txtValue.toLocaleLowerCase() == "rowguid"));
                if (this.appPageColumns[i].arFKColumns[c].txtValue == "rowguid") {
                  isRowguid = true;
                  break;
                }
              }

              if (isRowguid == true) {
                console.log("this.appPageColumns[i].arFKColumns: ", this.appPageColumns[i].arFKColumns);
                this.appPageColumns[i].txtSelectValueField = "rowguid";
             fkc.value = "rowguid";
              }
            }
          }
        }

        v++;
      });

    }

  }

  setDefaultSelectFields() {

    //console.log("this.appPageColumnFieldType ", this.appPageColumnFieldType.forEach);

    if (this.appPageColumnFieldType == null) {
      return;
    }

    for (let i = 0; i <= this.appPageColumns.length - 1; i++) {
      //console.log("getJavascriptType: ", this.getJavascriptType(this.appPageColumns[i].txtDataType));
      //console.log("this.appPageColumnFieldType[i] :", this.appPageColumnFieldType[i]);



      let c: number = 0;
      this.appPageColumnFieldType.forEach((s) => {

        if (i == c) {
          let iValue = this.getJavascriptType(this.appPageColumns[i].txtDataType);
          console.log("txtColumnName:  " + this.appPageColumns[i].txtColumnName + ",   this.appPageColumns[i].intMaxCharacterLength: " + this.appPageColumns[i].intMaxCharacterLength + ",  iValue: " + iValue);
          s.value = iValue;
          //s.options.forEach((o) => {
          //  console.log("o._text",o._text);
          //});

        }

        c++;
      });

      let w: number = 0;
      this.appPageColumnFieldWidth.forEach((s) => {

        if (i == w) {
          let iValue = this.getDefaultColumWidth(this.appPageColumns[i].intMaxCharacterLength);
          //console.log("txtColumnName:  " + this.appPageColumns[i].txtColumnName + ",   this.appPageColumns[i].intMaxCharacterLength: " + this.appPageColumns[i].intMaxCharacterLength + ",  iValue: " + iValue);
          s.value = iValue;
          this.appPageColumns[i].intFieldWidth = iValue;
          //s.options.forEach((o) => {
          //  console.log("o._text",o._text);
          //});

        }

        w++;
      });


    }

  }

  copyToClipboard(value: string) {
    this.formService.copyToClipboard(value);
    this.alertService.openSnackBar("The value has been copied to the clipboard", "Close", "center", "bottom", "", 3000);
  }


}

export namespace AppPageColumnFieldType{

  export enum enAppPageColumnFieldType {
    TextboxString, TextboxNumeric, ComboboxSingle, ComboboxMultiple, ListboxSingle, ListboxMultiple, SearchListboxSingle, SearchListboxMultiple, Checkbox, Slider, Radio
  }
  export enum enAppPageColumnFieldWidth {
    CHH50, CHH75, CHH100, CHH125, CHH150, CHH175, CHH200, CHH225, CHH250, CHH275, CHH300, CHH325, CHH350, CHH375, CHH400, CHH425, CHH450, CHH475, CHH500
  }

  export enum enDataType {
    string, number, date, uniqueidentifier, boolean
  }

}

  export class dtoAppPageColumnExt extends dtoAppPageColumn{

    arFKColumns: dtoIntKeyValue[];

}
