import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'camelCase'
})
export class CamelCasePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: any): any {
    let sanitizedContent = DOMPurify.sanitize(value);

    if (value != null && value.length > length) {
      let camelized: string = this.camelize(sanitizedContent);
      sanitizedContent = camelized;
    }

    value = value.toString().replaceAll('\n', '</br>')

    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);

  }

  camelize(str: string) {
    let words: string[] = str.split(' ');
    let newWords: string = "";
    for (let i = 0; i <= words.length - 1; i++) {
      newWords += words[i].substring(0, 1).toUpperCase() + words[i].substring(1, words[i].length).toLowerCase() + " ";
    }
    return newWords;
  }


}
