<div class="final-inspection-item-print-screen-container" id="finalInspectionItemPrintContainer">
  <div class="final-inspection-item-print-container">
    <div class="header-wrapper">
      <div class="header-container">
        <div class="header-logo">
          <img src="../../../../assets/img/BlackLogo.bmp" width="40" height="40" />
        </div>
        <div class="divider-wrapper">
          <div class="header-column-2"></div>
        </div>
        <div class="header-column-3A">
          <div class="header-row-1A">
            <div><h3>Coffs Harbour Hardwoods</h3></div>
          </div>
        </div>
      </div>
    </div>
    <div class="final-inspection-item-heading">Final&nbsp;&nbsp;Inspection</div>
    <div class="print-buton">
      <button class="print-button" type="button" mat-flat-button color="primary" (click)="print()" *ngIf="this.finalInspection != null && printPDFMode == false">Print Final Inspection</button>
    </div>
    <div class="final-inspection-item-info" *ngIf="finalInspection != null">
      <div class="final-heading-label final-inspection-item-no-label">Final No</div>
      <div class="final-heading-cell final-inspection-item-no">{{finalInspection.intFinalNo}}</div>
      <div class="final-heading-label final-inspection-item-date-inspected-label">Date Inspected</div>
      <div class="final-heading-cell final-inspection-item-date-inspected">{{finalInspection.dteDateInspected | date: 'dd/MM/yyyy'}}</div>
      <div class="final-heading-label final-inspection-item-inspected-by-label">Inspected By</div>
      <div class="final-heading-cell final-inspection-item-inspected-by">{{finalInspection.txtInspectedBy}}</div>

      <div class="final-heading-label final-inspection-item-chh-brand-applied-label">CHH Brand Applied</div>
      <div class="final-heading-cell final-inspection-item-chh-brand-applied">{{finalInspection.blnCHHBranded ? 'Yes' : 'No'}}</div>
      <div class="final-heading-label final-inspection-item-inspectors-stamp-applied-label">Inspectors Stamp Applied</div>
      <div class="final-heading-cell final-inspection-item-inspectors-stamp-applied">{{finalInspection.blnInspectedStamp ? 'Yes' : 'No'}}</div>
      <div class="final-heading-label final-inspection-item-cca-hazard-level-stamp-label">CCA &amp; Hazard Level Branded</div>
      <div class="final-heading-cell final-inspection-item-cca-hazard-level-stamp">{{finalInspection.blnCCAHazardStamp ? 'Yes' : 'No'}}</div>
      <div class="final-heading-label final-inspection-item-cca-hazard-level-label">Hazard Level</div>
      <div class="final-heading-cell final-inspection-item-cca-hazard-level">{{finalInspection.txtCCAHazardLevel}}</div>

    </div>
    <br />
    <div class="final-inspection-items-container">
      <div class="delivery-items-container">
        <app-final-inspection-item-list #appfinalinspectionitemlist [datasource]="finalInspectionItems" [finalInspection]="finalInspection" [columnListId]="'columnList'" [mode]="'print'"></app-final-inspection-item-list>
      </div>
    </div>

  </div>


</div>
<div class="pagebreak" [ngClass]="printPDFMode ? 'pagebreak-print' : 'pagebreak'"> </div>
<div class="final-inspection-summary-container" id="finalInspectionSummaryId">
  <div class="final-inspection-summary-heading">Final&nbsp;&nbsp;Inspection&nbsp;&nbsp;Summary</div>
  <br />
  <div class="final-inspection-summary-info" *ngIf="finalInspection != null">
    <div class="final-summary-heading-label final-inspection-summary-no-label">Final No</div>
    <div class="final-summary-heading-cell final-inspection-summary-no">{{finalInspection.intFinalNo}}</div>
    <div class="final-summary-heading-label final-inspection-summary-date-inspected-label">Date Inspected</div>
    <div class="final-summary-heading-cell final-inspection-summary-date-inspected">{{finalInspection.dteDateInspected | date: 'dd/MM/yyyy'}}</div>
  </div>
  <br />
  <app-final-inspection-item-summary class="final-inspection-item-summary" #appFinalInspectionSummary [datasource]="finalInspectionItemSummary" [finalInspectionItemSummaryQtyTotal]="finalInspectionItemSummaryQtyTotal" [finalInspectionItemSummaryS1QtyTotal]="finalInspectionItemSummaryS1QtyTotal" [finalInspectionItemSummaryS2QtyTotal]="finalInspectionItemSummaryS2QtyTotal" [finalInspectionItemSummaryS3QtyTotal]="finalInspectionItemSummaryS3QtyTotal" [finalInspectionItemSummaryBBTQtyTotal]="finalInspectionItemSummaryBBTQtyTotal" [finalInspectionItemSummaryM3Total]="finalInspectionItemSummaryM3Total" [finalInspectionItemSummaryTonneTotal]="finalInspectionItemSummaryTonneTotal" [mode]="'print'"></app-final-inspection-item-summary>
</div>
