import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductProfile } from '../../_shared/business-objects/product-profile.bo';

@Injectable()
export class ProductProfileService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }


  getProductCodeProfiles(gProductTypeCategoryId: string, gProductTypeId: string) {
    return this.http.get<ProductProfile[]>(this._baseUrl + 'api/ProductProfile/GetProductCodeProfiles/' + gProductTypeCategoryId + '/' + gProductTypeId);
  }

  getProductCodeProfile(gProfileId: string) {
    return this.http.get<ProductProfile>(this._baseUrl + 'api/ProductProfile/' + gProfileId);
  }

  getAllProductProfiles() {
    return this.http.get<ProductProfile[]>(this._baseUrl + 'api/ProductProfile/GetAllProductProfiles/');
  }


}  
