import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { BoardTallyRecordOrderItem } from '../business-objects/board-tally-record-order-item.bo';

@Injectable()
export class BoardTallyRecordOrderItemService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getBoardTallyRecordOrderItem(boardTallyRecordOrderItemId: string) {
		return this.http.get<BoardTallyRecordOrderItem>(this._baseUrl + 'api/BoardTallyRecordOrderItem/' + boardTallyRecordOrderItemId);
	}

	getBoardTallyRecordOrderItemParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<BoardTallyRecordOrderItem[]>(this._baseUrl + 'api/BoardTallyRecordOrderItem/GetBoardTallyRecordOrderItemsParamArray/', aParam);
	}

	getAllBoardTallyRecordOrderItems() {
		return this.http.get<BoardTallyRecordOrderItem[]>(this._baseUrl + 'api/BoardTallyRecordOrderItem/GetAllBoardTallyRecordOrderItems/');
	}

	//getAlldtoBoardTallyRecordOrderItems() {
	//	return this.http.get<dtoBoardTallyRecordOrderItem[]>(this._baseUrl + 'api/BoardTallyRecordOrderItem/GetAllBoardTallyRecordOrderItems/');
	//}

	createBoardTallyRecordOrderItem(boardTallyRecordOrderItem: BoardTallyRecordOrderItem) {
		return this.http.post<BoardTallyRecordOrderItem>(this._baseUrl + 'api/BoardTallyRecordOrderItem/CreateBoardTallyRecordOrderItem/', boardTallyRecordOrderItem);
	}

	updateBoardTallyRecordOrderItem(boardTallyRecordOrderItem: BoardTallyRecordOrderItem) {
		return this.http.put<BoardTallyRecordOrderItem>(this._baseUrl + 'api/BoardTallyRecordOrderItem/UpdateBoardTallyRecordOrderItem/', boardTallyRecordOrderItem);
	}

	async getBoardTallyRecordOrderItemPromise(boardTallyRecordOrderItemId: string) {
		return new Promise<BoardTallyRecordOrderItem>(resolve => {
			this.getBoardTallyRecordOrderItem(boardTallyRecordOrderItemId).subscribe(boardTallyRecordOrderItem => {
				resolve(boardTallyRecordOrderItem);
			}, err => {
				this.alertService.openSnackBarError("Error getting BoardTallyRecordOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getBoardTallyRecordOrderItemParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<BoardTallyRecordOrderItem[]>(resolve => {
			this.getBoardTallyRecordOrderItemParamArray(aParam).subscribe(boardTallyRecordOrderItems => {
				resolve(boardTallyRecordOrderItems);
			}, err => {
				this.alertService.openSnackBarError("Error getting BoardTallyRecordOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateBoardTallyRecordOrderItemPromise(boardTallyRecordOrderItem: BoardTallyRecordOrderItem) {
		return new Promise<BoardTallyRecordOrderItem>(resolve => {
			this.updateBoardTallyRecordOrderItem(boardTallyRecordOrderItem).subscribe(boardTallyRecordOrderItem => {
				resolve(boardTallyRecordOrderItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating BoardTallyRecordOrderItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createBoardTallyRecordOrderItemPromise(boardTallyRecordOrderItem: BoardTallyRecordOrderItem) {
		return new Promise<BoardTallyRecordOrderItem>(resolve => {
			this.createBoardTallyRecordOrderItem(boardTallyRecordOrderItem).subscribe(boardTallyRecordOrderItem => {
				resolve(boardTallyRecordOrderItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating BoardTallyRecordOrderItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}