<div class="delivered-sales-summary-print-screen-container" id="salesSummaryPrintContainer">
  <div class="delivered-sales-summary-print-container">
    <div class="header-wrapper">
      <div class="header-container">
        <div class="header-logo">
          <img src="../../../../assets/img/BlackLogo.bmp" width="40" height="40" />
        </div>
        <div class="divider-wrapper">
          <div class="header-column-2"></div>
        </div>
        <div class="header-column-3A">
          <div class="header-row-1A">
            <div><h3>Coffs Harbour Hardwoods</h3></div>
          </div>
        </div>
      </div>
    </div>
    <div class="sales-summary-heading-container">
      <div class="print-buton">
        <button class="print-button" type="button" mat-flat-button color="primary" (click)="createPDF();" *ngIf="deliveredSalesSummary != null && printMode">Section Sales Summary</button>
      </div>
    </div>
    <div class="sales-summary-table-container">
      <div class="delivered-sales-summary-table" *ngIf="deliveredSalesSummary != null">
        <div class="delivered-sales-summary-heading">Section&nbsp;&nbsp;Sales&nbsp;&nbsp;Summary</div>
        <div class="summary-heading-label delivered-sales-summary-quantity-label">Month</div>
        <div class="summary-heading-label delivered-sales-summary-quantity-label">Year</div>
        <div class="summary-heading-label delivered-sales-summary-quantity-uom-label">Amount</div>
        <div class="summary-heading-label delivered-sales-summary-quantity-uom-label">M3</div>
        @for(deliveredSales of deliveredSalesSummary; track salesSum; let idx = $index; let first = $first; let last = $last){
        <div class="sales-summary-row sales-summary-section" *ngIf="first || deliveredSalesSummary[idx - 1].txtOrderItemProcesses != deliveredSales.txtOrderItemProcesses">{{deliveredSales.txtOrderItemProcesses}}</div>
        <div class="sales-summary-row sales-summary-quantity">{{currentYear == deliveredSales.intYear && currentMonth == deliveredSales.intMonth ? currentDay : ''}} {{deliveredSales.intYear +'-' + deliveredSales.intMonth + '-01' | date:'MMMM'}}</div>
        <div class="sales-summary-row sales-summary-quantity">{{deliveredSales.intYear}}</div>
        <div class="sales-summary-row sales-sumamry-price chh-align-right">${{(deliveredSales.fltTotalPrice | number:'1.2-2':'en-AU')}}</div>
        <div class="sales-summary-row sales-summary-quantity-uom">{{deliveredSales.fltTotalM3 | number:'1.2-2'}}</div>
        <div class="sales-summary-total-row sales-sumamry-total-price-label chh-align-right" *ngIf="idx == deliveredSalesSummary.length - 1">Total Ex GST:</div>
        <div class="sales-summary-total-row sales-sumamry-total-price chh-align-right" *ngIf="idx == deliveredSalesSummary.length - 1">${{salesSummaryTotal | number:'1.2-2':'en-AU'}}</div>
        }
      </div>
    </div>

  </div>


</div>
