import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { AppUserColumn } from '../business-objects/app-user-column.bo';

@Injectable()
export class AppUserColumnService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getAppUserColumn(appUserColumnId: string) {
		return this.http.get<AppUserColumn>(this._baseUrl + 'api/AppUserColumn/' + appUserColumnId);
	}

	getAppUserColumnParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<AppUserColumn[]>(this._baseUrl + 'api/AppUserColumn/GetAppUserColumnsParamArray/', aParam);
	}

	getAllAppUserColumns() {
		return this.http.get<AppUserColumn[]>(this._baseUrl + 'api/AppUserColumn/GetAllAppUserColumns/');
	}

	//getAlldtoAppUserColumns() {
	//	return this.http.get<dtoAppUserColumn[]>(this._baseUrl + 'api/AppUserColumn/GetAllAppUserColumns/');
	//}

	createAppUserColumn(appUserColumn: AppUserColumn) {
		return this.http.post<AppUserColumn>(this._baseUrl + 'api/AppUserColumn/CreateAppUserColumn/', appUserColumn);
	}

	updateAppUserColumn(appUserColumn: AppUserColumn) {
		return this.http.put<AppUserColumn>(this._baseUrl + 'api/AppUserColumn/UpdateAppUserColumn/', appUserColumn);
	}

    removeAppUserColumn(appUserColumnId: string) {
      return this.http.delete(this._baseUrl + 'api/AppUserColumn/DeleteAppUserColumn/' + appUserColumnId);
    }

	async getAppUserColumnPromise(appUserColumnId: string) {
		return new Promise<AppUserColumn>(resolve => {
      this.getAppUserColumn(appUserColumnId).subscribe(appUserColumn => {
				resolve(appUserColumn);
			}, err => {
        this.alertService.openSnackBarError("Error getting AppUserColumn records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getAppUserColumnParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<AppUserColumn[]>(resolve => {
			this.getAppUserColumnParamArray(aParam).subscribe(appUserColumns => {
				resolve(appUserColumns);
			}, err => {
        this.alertService.openSnackBarError("Error getting AppUserColumn records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateAppUserColumnPromise(appUserColumn: AppUserColumn) {
		return new Promise<AppUserColumn>(resolve => {
			this.updateAppUserColumn(appUserColumn).subscribe(appUserColumn => {
				resolve(appUserColumn);
			}, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating AppUserColumn record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createAppUserColumnPromise(appUserColumn) {
		return new Promise<AppUserColumn>(resolve => {
			this.createAppUserColumn(appUserColumn).subscribe(appUserColumn => {
				resolve(appUserColumn);
			}, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating AppUserColumn record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async removeAppUserColumnPromise(appUserColumnId: string) {
    return new Promise<boolean>(resolve => {
      this.removeAppUserColumn(appUserColumnId).subscribe(appUserColumn => {
        resolve(true);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error removing AppUserColumn record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(false);
      });
    });
  }
}
