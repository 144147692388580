import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export interface CHHChart {
  //constructor(chartOptions: ChartOptions, chartLabels: string[], chartType: ChartType, chartLegend: boolean, chartData: ChartDataset[])
  chartOptions: ChartOptions
  chartLabels: string[];
  chartType: ChartType;
  chartLegend: boolean;
  chartData: ChartDataset[];
}

export class CHHChart {
  //constructor(chartOptions: ChartOptions, chartLabels: string[], chartType: ChartType, chartLegend: boolean, chartData: ChartDataset[]) {
  //  this.chartOptions = chartOptions;
  //  this.chartLabels = chartLabels;
  //  this.chartType = chartType;
  //  this.chartLegend = chartLegend;
  //  this.chartData = chartData;
  //}

  chartOptions: ChartOptions;
  chartLabels: string[];
  chartType: ChartType;
  chartLegend: boolean;
  chartData: ChartDataset[];

  setChartOption(chartTitle: string, dataLabelPrefix: string, ticksYPrefix: string, ticksYFontSize: number = 15, chartTitleFontSize: number = 40, chartTitleFontWeight: string = 'bold', padding: number = 50, chartLegend: boolean = true, dataLabelOffset: number = 1, dataLabelFontSize: number = 15, dataLabelFontWeight: 'normal' | 'bold' | 'lighter' | 'bolder' = 'bold') {
    this.chartOptions = {
      responsive: true,
      layout: {
        padding: padding  //50
      },

      plugins: {
        legend: {
          display: chartLegend  //true
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(context.parsed.y);
              }
              return label;            }
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 1,
          font: {
            size: dataLabelFontSize,  //15
            weight: dataLabelFontWeight  //'bold'
          },
          formatter: function (value, context) {
            return dataLabelPrefix + Number(value).toLocaleString();  //'$'
          }
        },
        title: {
          display: true,
          text: chartTitle,
          font: {
            size: chartTitleFontSize,  //40
            weight: chartTitleFontWeight  //'bold'
          }
        }
      },
      scales: {
        y: {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return ticksYPrefix + Number(value).toLocaleString();  //'$'
            },
            font: {
              size: ticksYFontSize   //15
            }
          }
        }
      }
    }
  }

  addChartData(data: number[], label: string, tension: number) {
    if (this.chartData == null) {
      this.chartData = [];
    }

    this.chartData.push({data: data, label: label, tension: tension});
  }
}
