import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoAppUserColumn } from '../business-objects/dto-app-user-column.bo';

@Injectable()
export class dtoAppUserColumnService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoAppUserColumn(dtoAppUserColumnId: string) {
		return this.http.get<dtoAppUserColumn>(this._baseUrl + 'api/dtoAppUserColumn/' + dtoAppUserColumnId);
	}

	getdtoAppUserColumnParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoAppUserColumn[]>(this._baseUrl + 'api/dtoAppUserColumn/GetdtoAppUserColumnsParamArray/', aParam);
	}

	getAlldtoAppUserColumns() {
		return this.http.get<dtoAppUserColumn[]>(this._baseUrl + 'api/dtoAppUserColumn/GetAlldtoAppUserColumns/');
	}

	//getAlldtodtoAppUserColumns() {
	//	return this.http.get<dtodtoAppUserColumn[]>(this._baseUrl + 'api/dtoAppUserColumn/GetAlldtoAppUserColumns/');
	//}

	createdtoAppUserColumn(dtoAppUserColumn: dtoAppUserColumn) {
		return this.http.post<dtoAppUserColumn>(this._baseUrl + 'api/dtoAppUserColumn/CreatedtoAppUserColumn/', dtoAppUserColumn);
	}

	updatedtoAppUserColumn(dtoAppUserColumn: dtoAppUserColumn) {
		return this.http.put<dtoAppUserColumn>(this._baseUrl + 'api/dtoAppUserColumn/UpdatedtoAppUserColumn/', dtoAppUserColumn);
	}

	async getdtoAppUserColumnPromise(dtoAppUserColumnId: string) {
		return new Promise<dtoAppUserColumn>(resolve => {
			this.getdtoAppUserColumn(dtoAppUserColumnId).subscribe(dtoAppUserColumn => {
				resolve(dtoAppUserColumn);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppUserColumn records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getdtoAppUserColumnParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoAppUserColumn[]>(resolve => {
			this.getdtoAppUserColumnParamArray(aParam).subscribe(dtoAppUserColumns => {
				resolve(dtoAppUserColumns);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppUserColumn records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async updatedtoAppUserColumnPromise(dtoAppUserColumn: dtoAppUserColumn) {
		return new Promise<dtoAppUserColumn>(resolve => {
			this.updatedtoAppUserColumn(dtoAppUserColumn).subscribe(dtoAppUserColumn => {
				resolve(dtoAppUserColumn);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoAppUserColumn record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createdtoAppUserColumnPromise(dtoAppUserColumn: dtoAppUserColumn) {
		return new Promise<dtoAppUserColumn>(resolve => {
			this.createdtoAppUserColumn(dtoAppUserColumn).subscribe(dtoAppUserColumn => {
				resolve(dtoAppUserColumn);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoAppUserColumn record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

}