import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {

  constructor() { }

  public transform(value: any): any {

    return Boolean(value) ? 'Yes' : 'No';

  }
}
