<div class="delivery-docket-order-item-update-container">
  <div class="delivery-docket-order-item-update-form-container">
    <div class="delivery-docket-order-item-update-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="field-row-docket-no">
            <mat-form-field class="chh-form-field example-full-width header-button delivery-docket-data" style="width: 120px !important" appearance="fill">
              <mat-label>Docket No.</mat-label>
              <input class="textbox-input disabled-input" matInput placeholder="0" value="" type="text" id="txtDeliveryDocketNo" formControlName="txtDeliveryDocketNo">
            </mat-form-field>
          </div>


          <div class="field-row-customer">
            <mat-form-field class="chh-form-field example-full-width header-button delivery-docket-data" style="width: 500px !important" appearance="fill">
              <mat-label>Customer</mat-label>
              <input class="textbox-input disabled-input" matInput placeholder="0" value="" type="text" id="txtCustomer" formControlName="txtCustomer">
            </mat-form-field>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="chh-form-field example-full-width delivery-address-input-form-field">
                <mat-label>Search Delivery Address</mat-label>
                <input matInput class="delivery-address-input" placeholder="Filter by address or town" placeholderLabel="i.e. dubbo...." value="" type="text" #deliveryAddressFilter (keyup)="filterDeliveryAddress(deliveryAddressFilter.value, chkGlobalAddress.checked)" (focus)="deliveryAddressFilter.select()">
              </mat-form-field>
              <div class="chh-vehicle-checkbox"><mat-checkbox class="example-margin" #chkGlobalAddress id="chkGlobalAddress" [value]="true" (change)="filterDeliveryAddress(deliveryAddressFilter.value, chkGlobalAddress.checked)">Global Addresses</mat-checkbox></div>
              <mat-selection-list class="delivery-address-select" #guCustomerDeliveryAddressId [multiple]="false" id="guCustomerDeliveryAddressId" formControlName="guCustomerDeliveryAddressId">
                <mat-list-option class="chh-list-option" *ngFor="let deliveryAddress of deliveryAddresses" [value]="deliveryAddress.rowguid">
                  <div class="delivery-address-container">
                    <div class="delivery-address" [innerHTML]="deliveryAddress.txtAddress | transformText"></div><div class="delivery-town">{{deliveryAddress.txtTown}}</div><div class="delivery-state">{{deliveryAddress.txtState}}</div><div class="delivery-postcode">{{deliveryAddress.txtPostcode}}</div>
                    <div class="delivery-address-comments" *ngIf="deliveryAddress.txtComments != null && deliveryAddress.txtComments != ''">{{deliveryAddress.txtComments}}</div>
                  </div>
                </mat-list-option>
              </mat-selection-list>
              <br />
            </div>
          </div>

          <div class="field-row-hauler">
            <mat-form-field class="chh-form-field example-full-width header-button delivery-docket-data" style="width: 500px !important" appearance="fill">
              <mat-label>Hauler</mat-label>
              <input class="textbox-input disabled-input" matInput placeholder="0" value="" type="text" id="txtHauler" formControlName="txtHauler">
            </mat-form-field>
          </div>

          <div class="field-row-order-no">
            <mat-form-field class="chh-form-field example-full-width header-button delivery-docket-data" style="width: 500px !important" appearance="fill">
              <mat-label>Order No.</mat-label>
              <input class="textbox-input disabled-input" matInput placeholder="0" value="" type="text" id="txtOrderNo" formControlName="txtOrderNo">
            </mat-form-field>
          </div>

          <div class="field-row-product-description">
            <mat-form-field class="chh-form-field example-full-width header-button delivery-docket-data" style="width: 500px !important" appearance="fill">
              <mat-label>Product Description</mat-label>
              <textarea class="textbox-input disabled-input" matInput placeholder="0" id="txtProductDescription" formControlName="txtProductDescription"></textarea>
            </mat-form-field>
          </div>

          <div class="field-row-quantity">
            <mat-form-field class="chh-form-field example-full-width header-button delivery-docket-data" style="width: 120px !important" appearance="fill">
              <mat-label>Quantity</mat-label>
              <input class="textbox-input disabled-input" matInput placeholder="0" value="" type="number" id="txtQuantity" formControlName="txtQuantity">
            </mat-form-field>
            <mat-form-field class="chh-form-field example-full-width header-button delivery-docket-data" style="width: 120px !important" appearance="fill">
              <input class="textbox-input disabled-input" matInput value="" type="text" id="txtQuantityUoM" formControlName="txtQuantityUoM">
            </mat-form-field>
          </div>

          <div class="comments-container">
            <textarea class="comments" autosize minRows="2" [(ngModel)]="deliveryDocketOrderItem.txtComments" id="txtComments" formControlName="txtComments"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="action-button-container">
  <button class="action-button" mat-flat-button color="primary" (click)="updateDeliveryDocketOrderItem()">Update Delivery Docket Order Item</button>
  <button class="action-button" mat-flat-button color="primary" (click)="cancelDeliveryDocketOrderItem()">Cancel</button>
</div>

<div class="spinner-container" *ngIf="showSpinner">
  <mat-card [@displaySpinner]="transitionProducts ? 'open' : 'closed'" class="products-spinner-card">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
