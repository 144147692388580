<form class="example-form printer-location-container-form" [formGroup]="form">
  <div class="printer-location-container" id="printerLocationContainer">
    <div class="printer-locations-heading"><h1>Printers</h1></div>

    <mat-tab-group color="accent" class="chh-mat-tab-group mat-mdc-tab-group" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabPrinters>
      <mat-tab label="Printer Locations" class="tab-label printer-location-search-tab">
        <div class="search-fields-container">
          <div class="search-printer-location-no">
            <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
              <mat-label>Printer Location Name.</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Joinery" value="" type="text" id="txtSearchPrinterLocationName" formControlName="txtSearchPrinterLocationName" (keydown.enter)="enterKeySearch('', $event)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple names by a comma i.e. Joinery, Board Plant..." class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-buttons">
            <div class="printer-locations-button-container">
              <button class="search-printer-location-button search-button" type="button" mat-flat-button color="primary" (click)="searchPrinterLocations('', 0, $event)">Search</button>
            </div>
          </div>

        </div>

        <mat-divider></mat-divider>

        <div class="printer-location-list-app-container">
          <app-printer-location-list #appPrinterLocationList [datasource]="printerLocations" (editPrinterEvent)="editPrinterLocation($event)" (updatedPrinterLocationEvent)="updatedPrinterLocation($event)" (removePrinterLocationEvent)="removePrinterLocation($event)" [columnBarPage]="'Printer Location'"></app-printer-location-list>
        </div>

      </mat-tab>

      <mat-tab label="Search Options" class="tab-label search-option-tab">
        <div class="search-options-container">
          <div class="printer-location-search-options">
            <h3>Printer Search Parameters</h3>

            <div class="search-option-row-3">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
                <mat-label>Printer Location created date from</mat-label>
                <input id="dteDateCreatedFrom" class="date-input printer-location-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickerfrom" formControlName="dteDateCreatedFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerfrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerfrom></mat-datepicker>
              </mat-form-field>
            </div>


            <div class="search-option-row-4">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
                <mat-label>Printer Location created date to</mat-label>
                <input id="dteDateCreatedTo" class="date-input printer-location-date-search" #searchDateCreatedTo matInput [matDatepicker]="pickerto" formControlName="dteDateCreatedTo">
                <mat-datepicker-toggle matSuffix [for]="pickerto"></mat-datepicker-toggle>
                <mat-datepicker #pickerto></mat-datepicker>
              </mat-form-field>
            </div>


          </div>
        </div>

      </mat-tab>

      <mat-tab label="Create Printer" class="tab-label">
        <div class="create-printer-location-container">
          <app-printer-location-create class="create-printer-location-app" [inDialog]="false" (printerLocationCreated)="printerLocationCreated($event)" (printerLocationCancelled)="printerLocationCancelled($event)" *ngIf="selectedTab == ePrinterLocationTabs.CreatePrinterLocation"></app-printer-location-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Printer" class="tab-label">
        <div class="update-printer-location-container">
          <app-printer-location-update [printer]="printer" (printerLocationUpdated)="printerLocationUpdated($event)" (printerLocationCancelled)="printerLocationCancelled($event)" *ngIf="selectedTab == ePrinterLocationTabs.UpdatePrinterLocation"></app-printer-location-update>
        </div>
      </mat-tab>

      <mat-tab label="Add Printer Items" class="tab-label">
        <div class="add-printer-location-items-container">
          <!--<app-printer-location-add-items #printerAddItems [printer]="printerAddItem" (printerItemsAdded)="printerItemsAdded($event)" (printerItemsCancelled)="printerItemsCancelled($event)" *ngIf="selectedTab == ePrinterLocationTabs.DocketAddItems"></app-printer-location-add-items>-->
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
