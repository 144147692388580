import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoTPCharge } from '../business-objects/dto-tpcharge.bo';

@Injectable()
export class dtoTPChargeService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoTPCharge(dtoTPChargeId: string) {
		return this.http.get<dtoTPCharge>(this._baseUrl + 'api/dtoTPCharge/' + dtoTPChargeId);
	}

	getdtoTPChargeParamArray(aParam: SQLParamArray[][], top: number = 0) {
    console.log("top post  -  " + top);
		return this.http.post<dtoTPCharge[]>(this._baseUrl + 'api/dtoTPCharge/GetdtoTPChargesParamArray/' + top, aParam);
	}

	getAlldtoTPCharges() {
		return this.http.get<dtoTPCharge[]>(this._baseUrl + 'api/dtoTPCharge/GetAlldtoTPCharges/');
	}

	//getAlldtodtoTPCharges() {
	//	return this.http.get<dtodtoTPCharge[]>(this._baseUrl + 'api/dtoTPCharge/GetAlldtoTPCharges/');
	//}

	createdtoTPCharge(dtoTPCharge: dtoTPCharge) {
		return this.http.post<dtoTPCharge>(this._baseUrl + 'api/dtoTPCharge/CreatedtoTPCharge/', dtoTPCharge);
	}

	updatedtoTPCharge(dtoTPCharge: dtoTPCharge) {
		return this.http.put<dtoTPCharge>(this._baseUrl + 'api/dtoTPCharge/UpdatedtoTPCharge/', dtoTPCharge);
	}

	deletedtoTPCharge(dtoTPChargeId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoTPCharge/DeletedtoTPCharge/' + dtoTPChargeId);
	}

	async getdtoTPChargePromise(dtoTPChargeId: string) {
		return new Promise<dtoTPCharge>(resolve => {
			this.getdtoTPCharge(dtoTPChargeId).subscribe(dtoTPCharge => {
				resolve(dtoTPCharge);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTPCharge records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoTPChargeParamArrayPromise(aParam: SQLParamArray[][], top: number = 0) {
    return new Promise<dtoTPCharge[]>(resolve => {
      console.log("top  -  " + top);
			this.getdtoTPChargeParamArray(aParam, top).subscribe(dtoTPCharges => {
				resolve(dtoTPCharges);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTPCharge records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoTPChargePromise(dtoTPCharge: dtoTPCharge) {
		return new Promise<dtoTPCharge>(resolve => {
			this.updatedtoTPCharge(dtoTPCharge).subscribe(dtoTPCharge => {
				resolve(dtoTPCharge);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoTPCharge record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoTPChargePromise(dtoTPCharge: dtoTPCharge) {
		return new Promise<dtoTPCharge>(resolve => {
			this.createdtoTPCharge(dtoTPCharge).subscribe(dtoTPCharge => {
				resolve(dtoTPCharge);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoTPCharge record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoTPChargePromise(dtoTPChargeId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoTPCharge(dtoTPChargeId).subscribe(dtoTPCharge => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoTPCharge record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
