import { Component, EventEmitter, Output } from '@angular/core';
import SignaturePad from 'signature_pad'


@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.css']
})
export class SignaturePadComponent {

  @Output() signedEvent = new EventEmitter<string>();
  @Output() noSignedEvent = new EventEmitter<true>();
  @Output() signedCancelledEvent = new EventEmitter<boolean>();


  signaturePad: SignaturePad;
  signImage: any = null;

  constructor() {

  }

  ngOnInit(): void {


    const canvas = document.querySelector("canvas");
    //const cxt = canvas.getContext("2d");
    //cxt.globalCompositeOperation
    this.signaturePad = new SignaturePad(canvas, {
      // It's Necessary to use an opaque color when saving image as JPEG;
      // this option can be omitted if only saving as PNG or SVG
      backgroundColor: 'rgb(255, 255, 255)'
    });


  }

  cancelSignature() {
    this.signedCancelledEvent.emit(true);
  }


  clearSignature() {
    this.signaturePad.clear();
  }

  saveSignature() {

    let dataURL = this.signaturePad.toDataURL("image/jpeg"); // save image as JPEG
    this.signedEvent.emit(dataURL);

  }

  saveNoSignature() {
    this.noSignedEvent.emit(true);
  }

  saveJPGButton() {
    if (this.signaturePad.isEmpty()) {
      alert("Please provide a signature first.");
    } else {
      var dataURL = this.signaturePad.toDataURL("image/jpeg");
      //this.downloadSignature(dataURL, "signature.jpg");
    }
  }

  downloadSignature(dataURL, filename) {
    var blob = this.dataURLToBlob(dataURL);
    var url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  dataURLToBlob(dataURL) {
    // Code taken from https://github.com/ebidel/filer.js
    var parts = dataURL.split(';base64,');
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }


}
