<div class="cca-charge-list-container">
  <app-app-user-column-set-bar [componentColumns]="displayedColumns" [pageName]="columnBarPage" [container]="'chargeContainer'" [columnListId]="'columnListId'" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)"></app-app-user-column-set-bar>

  <table mat-table #tblCharges [dataSource]="datasource" multiTemplateDataRows class="mat-elevation-z8 cca-charge-list-table">

    <ng-container matColumnDef="dteChargeDate">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Charge Date </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD chargeDate chh-align-right"> {{tpCharge.dteChargeDate | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditDueDate">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"> </th>
      <td mat-cell *matCellDef="let tpCharge; let idx = dataIndex;" class="chargeTD editChargeDate editIcon">
        <mat-icon matTooltip="Edit Charge Date" class="edit-note-icon mouse-pointer" (click)="openChargeDateSelector(tpCharge, idx)">edit_note</mat-icon>
        <div id="charge-date-selector-location{{idx}}"></div>
        <div id="chargeDateSelector{{idx}}" [ngClass]="tpCharge.rowguid == selectedChargeDate ? 'date-selector-transition' : 'date-selector'">
          <app-date-selector *ngIf="tpCharge.rowguid == selectedChargeDate" [inputDate]="tpCharge.dteChargeDate" (outputDateEvent)="setChargeDate(tpCharge, $event)" (cancelEvent)="closeChargeDate()"></app-date-selector>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="intChargeNo">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Charge No </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeNo chargeTD chh-align-right alignRight"> {{tpCharge.intChargeNo}} </td>
    </ng-container>

    <ng-container matColumnDef="txtTreatmentHazardLevel">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"> Hazard Level </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD treatmentHazardLevel"> {{tpCharge.txtTreatmentHazardLevel}} </td>
    </ng-container>

    <ng-container matColumnDef="blnBranded">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"> Branded </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD branded"> {{(tpCharge.blnBranded | yesNo)}} </td>
    </ng-container>

    <ng-container matColumnDef="intInitVacuumStart">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Init Vacuum Start </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD initVacuumStart chh-align-right"> {{tpCharge.intInitVacuumStart}} </td>
    </ng-container>

    <ng-container matColumnDef="intPressureStart">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Pressure Start </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD pressureStart chh-align-right"> {{tpCharge.intPressureStart}} </td>
    </ng-container>

    <ng-container matColumnDef="intFinalVacuumStart">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Final Vacuum Start </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD finalVacuumStart chh-align-right"> {{tpCharge.intFinalVacuumStart}} </td>
    </ng-container>

    <ng-container matColumnDef="intInitVacuumKpa">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Init Vacuum Kpa </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD initVacuumKpa chh-align-right"> {{tpCharge.intInitVacuumKpa}} </td>
    </ng-container>

    <ng-container matColumnDef="intPressureKpa">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Pressure Kpa </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD pressureKpa chh-align-right"> {{tpCharge.intPressureKpa}} </td>
    </ng-container>

    <ng-container matColumnDef="intInitVacuumMaxHeld">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Init Vacuum Max Held </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD initVacuumMaxHeld chh-align-right"> {{tpCharge.intInitVacuumMaxHeld}} </td>
    </ng-container>

    <ng-container matColumnDef="intPressureMaxHeld">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Pressure Max Held </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD pressureMaxHeld chh-align-right"> {{tpCharge.intPressureMaxHeld}} </td>
    </ng-container>

    <ng-container matColumnDef="intTankStart">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Tank Start </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD tankStart chh-align-right"> {{tpCharge.intTankStart}} </td>
    </ng-container>

    <ng-container matColumnDef="intTankFlooded">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Tank Flooded </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD tankFlooded chh-align-right"> {{tpCharge.intTankFlooded}} </td>
    </ng-container>

    <ng-container matColumnDef="intTankRefusal">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Tank Refusal </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD tankRefusal chh-align-right"> {{tpCharge.intTankRefusal}} </td>
    </ng-container>

    <ng-container matColumnDef="intTankKickback">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Tank Kickback </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD tankKickback chh-align-right"> {{tpCharge.intTankKickback}} </td>
    </ng-container>

    <ng-container matColumnDef="intTankEmpty">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Tank Empty </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD tankEmpty chh-align-right"> {{tpCharge.intTankEmpty}} </td>
    </ng-container>

    <ng-container matColumnDef="intTankFinish">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Tank Finish </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD tankFinish chh-align-right"> {{tpCharge.intTankFinish}} </td>
    </ng-container>

    <ng-container matColumnDef="intRateFlow">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Rate Flow </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD rateFlow chh-align-right"> {{tpCharge.intRateFlow}} </td>
    </ng-container>

    <ng-container matColumnDef="intSolutionStrength">
      <th mat-header-cell *matHeaderCellDef class="chargeTH chh-align-right"> Solution Strength </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD solutionStrength chh-align-right"> {{tpCharge.intSolutionStrength}} </td>
    </ng-container>

    <ng-container matColumnDef="txtComments">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"> Comments </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD comments"> {{tpCharge.txtComments}} </td>
    </ng-container>

    <ng-container matColumnDef="blnRecharge">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"> Recharge </th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD recharge"> {{tpCharge.blnRecharge | yesNo}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditCharge">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"></th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD"><button class="edit-cca-charge-button" type="button" mat-flat-button color="primary" (click)="editCharge(tpCharge)">Edit</button></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveCharge">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"></th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD"><button class="remove-cca-charge-button" type="button" mat-flat-button color="primary" (click)="removeCharge(tpCharge)">Remove</button></td>
    </ng-container>

    <ng-container matColumnDef="btnDropbox">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"></th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD"><button class="remove-cca-charge-button" type="button" mat-flat-button color="primary" (click)="openDropbox(tpCharge)">Dropbox</button></td>
    </ng-container>

    <ng-container matColumnDef="btnPrintCharge">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"></th>
      <td mat-cell *matCellDef="let tpCharge; let rowIdx = index;" class="chargeTD"><button class="print-cca-charge-button" type="button" mat-flat-button color="primary" (click)="printCharge(tpCharge, '')">Print Charge</button></td>
    </ng-container>

    <ng-container matColumnDef="btnPrintChargeSamples">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"></th>
      <td mat-cell *matCellDef="let tpCharge; let rowIdx = index;" class="chargeTD"><button class="print-cca-charge-button" type="button" mat-flat-button color="primary" (click)="printChargeSamples(tpCharge)">Print Charge Samples</button></td>
    </ng-container>

    <ng-container matColumnDef="btnEmailCharge">
      <th mat-header-cell *matHeaderCellDef class="chargeTH"></th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD" #emailButtonTD><button #emailButton class="email-cca-charge-button" type="button" mat-flat-button color="primary" (click)="emailCharge(tpCharge, emailButtonTD)">Email</button></td>
    </ng-container>

    <ng-container matColumnDef="btnViewChargeItems">
      <th mat-header-cell *matHeaderCellDef class="chargeTH" aria-label="row actions"></th>
      <td mat-cell *matCellDef="let tpCharge" class="chargeTD">
        <button mat-icon-button aria-label="expand row" (click)="expandElement(tpCharge,$event);$event.stopPropagation()">
          <mat-icon *ngIf="chargeExpanded !== tpCharge">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="chargeExpanded === tpCharge">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>


    <ng-container matColumnDef="expandedDetail">
      <th mat-header-cell *matHeaderCellDef class="chargeTH" [attr.colspan]="displayedColumns.length"></th>
      <td mat-cell *matCellDef="let tpCharge; let rowIdx = dataIndex" class="chargeTD chargeExpandTD" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="chargeExpanded == tpCharge ? 'expanded' : 'collapsed'">
          <div class="detail-view-container">
            <div class="detail-view">
              <div class="view-type-label">View Type</div>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group app-user-column-set-radio" fxLayout="column">
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="0" name="radView" (click)="changeView(tpCharge, 0)" [checked]="viewType == 0">Charge View</mat-radio-button>
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="1" name="radView" (click)="changeView(tpCharge, 1)" [checked]="viewType == 1">Table View</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="cca-charge-item-view">
              <app-cca-charge-item-view class="cca-charge-view" #appChargeItemView [chargeIdInput]="chargeExpanded.rowguid" *ngIf="chargeExpanded === tpCharge && viewType == 0"></app-cca-charge-item-view>
              <app-cca-charge-item-list #appChargeItemList (removeChargeItemEvent)="removeChargeItem($event, tpCharge)" [datasource]="chargeItems" [columnBarPage]="'Charge Items'" [columnBarContainer]="'chargeItemContainer'" [columnListId]="'columnList2'+rowIdx" *ngIf="chargeExpanded === tpCharge && viewType == 1"></app-cca-charge-item-list>
            </div>
          </div>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="cca-charge-row-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let tpCharge; let idx = dataIndex;" id="locationrow{{idx}}" (click)="expandElementAndSetColor(tpCharge,idx)" [ngClass]="tpCharge.rowguid == selectedRow ? 'itemRow activeTR' : 'itemRow rowHover'"></tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" id="expandedRow" class="example-detail-row"></tr>

  </table>

</div>

<div class="cca-charge-print-wrapper">
  <div id="chargePDF" class="cca-charge-print-container">
    <app-cca-charge-print #chargePrintApp [chargeIdInput]="chargePDFId"></app-cca-charge-print>
  </div>
</div>

<app-spinner-inline-tick #spinnerInlineTick [showSpinner]="showInlineSpinner" [showTick]="showTick" [diameter]="20" [inlineSpinnerId]="'inlineSpinnerId'" class="inline-spinner"></app-spinner-inline-tick>
