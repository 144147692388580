<h1>Add Pole Trimming Item</h1>

<form class="pole-trimming-add-form" [formGroup]="form">

  <section class="date-pole-number">
    <h6>Date</h6>
    <mat-form-field appearance="fill">
      <mat-label>Trimming Date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="dteDateCreated">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <br />

    <h6>Pole Number</h6>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Pole Number</mat-label>
      <input matInput placeholder="" value="" formControlName="txtPoleNumber">
    </mat-form-field>
    <br />
  </section>

  <section class="original-section">
    <h6>Original Item</h6>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="guOriginalProductTypeId" color="accent">
      <mat-button-toggle *ngFor="let ProductType of productTypes" [value]="ProductType.rowguid" (change)="onOriginalProductSelect(ProductType)">{{ProductType.txtName}}</mat-button-toggle>
    </mat-button-toggle-group>
    <br />
    <br />


    <mat-form-field class="example-full-width example-container" appearance="fill">
      <mat-label>Original Length</mat-label>
      <input matInput placeholder="" value="" formControlName="txtOriginalLength">
      <mat-hint align="end">{{originalLengthHint}}</mat-hint>
      <mat-error *ngIf="txtOriginalLength.invalid">
        {{
        validateLength(txtOriginalLength)
        }}
      </mat-error>
    </mat-form-field>
    &nbsp;
    <mat-form-field class="example-full-width example-container" appearance="fill">
      <mat-label>Original KN</mat-label>
      <input matInput placeholder="" value="" formControlName="txtOriginalKN">
      <mat-hint align="end">{{originalKNHint}}</mat-hint>
      <mat-error *ngIf="txtOriginalKN.invalid">
        {{
        validateKN(txtOriginalKN)
        }}
      </mat-error>
    </mat-form-field>&nbsp;

    <mat-checkbox class="example-margin" formControlName="chkOriginalQV" (change)="onOriginalQVClick()">QV</mat-checkbox>
    <br />

    <mat-form-field class="example-full-width example-container" appearance="fill">
      <mat-label>{{originalDiaPlaceHolder}}</mat-label>
      <input matInput placeholder="" value="" formControlName="fltOriginalDiameter">
      <mat-hint align="end">{{originalDiamHint}}</mat-hint>
      <mat-error *ngIf="fltOriginalDiameter.invalid">
        {{
        validateDiameter(fltOriginalDiameter, chkOriginalQV.value)
        }}
      </mat-error>
    </mat-form-field>
    &nbsp;
    <mat-form-field appearance="fill">
      <mat-label>Diameter Type</mat-label>
      <mat-select formControlName="intOriginalDiameterType">
        <mat-option [value]="0">None</mat-option>
        <mat-option [value]="1">Center</mat-option>
        <mat-option [value]="2">SED</mat-option>
        <mat-option [value]="3">BED</mat-option>
        <mat-option [value]="4">GL</mat-option>
      </mat-select>
    </mat-form-field>
    <br />
  </section>

  <section class="finished-section">
    <h6>Finished Item</h6>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="guFinishedProductTypeId" color="accent">
      <mat-button-toggle *ngFor="let ProductType of productTypes" [value]="ProductType.rowguid" (click)="onFinishedProductSelect(ProductType)">{{ProductType.txtName}}</mat-button-toggle>
    </mat-button-toggle-group>
    <br />
    <br />

    <mat-form-field class="example-full-width example-container" appearance="fill">
      <mat-label>Finished Lenth</mat-label>
      <input matInput placeholder="" value="" formControlName="txtFinishedLength">
      <mat-hint align="end">{{finishedLengthHint}}</mat-hint>
      <mat-error *ngIf="txtFinishedLength.invalid">
        {{
        validateLength(txtFinishedLength)
        }}
      </mat-error>
    </mat-form-field>
    &nbsp;
    <mat-form-field class="example-full-width example-container" appearance="fill">
      <mat-label>Finished KN</mat-label>
      <input matInput placeholder="" value="" formControlName="txtFinishedKN">
      <mat-hint align="end">{{finishedKNHint}}</mat-hint>
      <mat-error *ngIf="txtFinishedKN.invalid">
        {{
        validateKN(txtFinishedKN)
        }}
      </mat-error>
    </mat-form-field>&nbsp;
    <mat-checkbox class="example-margin" formControlName="chkFinishedQV" (change)="onFinishedQVClick()">QV</mat-checkbox>
    <br />

    <mat-form-field class="example-full-width example-container" appearance="fill">
      <mat-label>{{finishedDiaPlaceHolder}}</mat-label>
      <input matInput placeholder="" value="" formControlName="fltFinishedDiameter">
      <mat-hint align="end">{{finishedDiamHint}}</mat-hint>
      <mat-error *ngIf="fltFinishedDiameter.invalid">
        {{
        validateDiameter(fltFinishedDiameter, chkFinishedQV.value)
        }}
      </mat-error>
    </mat-form-field>
    &nbsp;
    <mat-form-field appearance="fill">
      <mat-label>Diameter Type</mat-label>
      <mat-select formControlName="intFinishedDiameterType">
        <mat-option [value]="0">None</mat-option>
        <mat-option [value]="1">Center</mat-option>
        <mat-option [value]="2">SED</mat-option>
        <mat-option [value]="3">BED</mat-option>
        <mat-option [value]="4">GL</mat-option>
      </mat-select>
    </mat-form-field>
    <br />
  </section>

  <section class="species">
    <h6>Species</h6>
    <mat-form-field>
      <mat-select class="speices" formControlName="guSpeciesId" placeholder="Species" placeholderLabel="Blackbutt" noEntriesFoundLabel="There are no species" #singleSelect>
        <mat-option class="speices">
          <ngx-mat-select-search placeholderLabel="i.e. Blackbutt ....." [formControl]="speciesFilterCtrl" ngDefaultControl></ngx-mat-select-search>
        </mat-option>
        <mat-option class="speices" *ngFor="let Species of filteredSpecies | async" [value]="Species.rowguid">
          {{Species.txtCode}} - {{Species.txtName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
  </section>

  <section class="reason-for-change">
    <mat-label><h6>Reason for change</h6></mat-label>
    <mat-selection-list (selectionChange)="selChange($event)" formControlName="guPoleTrimmingItemReasonForChange" multiple>
      <mat-list-option checkboxPosition="before" [value]="TrimingItemReasonForChange.rowguid" *ngFor="let TrimingItemReasonForChange of trimmingItemReasonForChangeData;">
        {{ TrimingItemReasonForChange.txtReasonForChange }}
      </mat-list-option>
    </mat-selection-list>
    <br />
  </section>

  <section class="comments">
    <h6>Comments</h6>
    <mat-form-field class="example-full-width txtComments" appearance="fill">
      <mat-label>Comments</mat-label>
      <textarea matInput formControlName="txtComments" placeholder="Ex. A reason not in the list above"></textarea>
    </mat-form-field>
    <br />
  </section>


  <div *ngIf="showErrorMessage" class="mat-error" [innerHTML]="errorMessage.message | safeHtml"></div>

  <br />

</form>

<section class="buttons">
  <button class="stocktakeButton" mat-flat-button color="primary" (click)="addPoleTrimmingItem()">Add</button>&nbsp;
  <button class="stocktakeButton" mat-flat-button color="primary">Cancel</button>
</section>
