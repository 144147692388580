import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from '../account/auth.service';
import { GraphService } from '../_shared/services/ms-graph-services/graph.service';

@Component({
  selector: 'app-login-msgraph',
  templateUrl: './login-msgraph.component.html',
  styleUrl: './login-msgraph.component.scss'
})
export class LoginMsgraphComponent implements OnInit {

  public _baseUrl: string;

  constructor(private authService: AuthService, private msalService: MsalService, private graphService: GraphService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;

  }


  ngOnInit(): void {

    //this.getMSToken();
    this.sendEmail();

  }

  async sendEmail() {

    return new Promise<any>(resolve => {
      this.sendMSGraphMail().subscribe(dtoTaxInvoiceProductSummary => {
        resolve(dtoTaxInvoiceProductSummary);
      }, err => {
        
        resolve(null);
      });
    });


  }

sendMSGraphMail() {
  return this.http.post<any>(this._baseUrl + 'api/dtoTaxInvoiceProductSummary/GetdtoTaxInvoiceProductSummaryParamArray/', "");
}

  async getMSToken() {

    //let result = await this.authService.loginMSGraph("Gm48Q~-C1tK7QGiN3sniWB572vCgRSnHRyVM5cpO");

    let result = await this.getMSTokenWithXMLReq("Gm48Q~-C1tK7QGiN3sniWB572vCgRSnHRyVM5cpO")

    if (result == null) {
      //let result2 = await this.authService.msLoginRedirect("https://localhost:44356");
      //console.log("result2", result2);

    }

    console.log("result", result);

  }


  async getMSTokenWithXMLReq(secret: string) {
    var xmlReq = new XMLHttpRequest();
    // add callback handlers
    xmlReq.addEventListener("load", this.onLoad);

    let body = 'grant_type=client_credentials&scope=https://graph.microsoft.com/.default&client_secret=' + secret + '&client_id=7f254664-037c-4e43-b74d-d799b185d124'

 
    console.log(JSON.stringify(body));

    xmlReq.open("POST", "https://login.microsoftonline.com:443/12058d2a-07ce-4a67-9d08-0371bc2884eb/oauth2/v2.0/token");
    xmlReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xmlReq.setRequestHeader("Access-Control-Allow-Origin", "*");

    xmlReq.onload = function (e) {
      if (xmlReq.status != 200) {
        console.log('Error', e)
        return
      }
      var data = JSON.parse(xmlReq.response)

      console.log(data);

      //data.forEach(function (dt) {
      //  html += '<li style="font-size:22px;"><a href="' + dt.path + '">' + dt.title + '</a></li>'
      //})

    }


    xmlReq.onerror = function () {
      console.log('Request failed')
    }
    // send request to server
    xmlReq.send(JSON.stringify(body));

  }

  onLoad(e) {
    console.log("this.response", e);
  }


}
