<form class="example-form final-inspection-form" [formGroup]="form">

  <div class="final-inspections-container" id="finalInspectionsContainer" #finalInspectionsElement>
    <h1>Final Inspections</h1>

    <mat-tab-group class="chh-mat-tab-group final-tab-group" color="accent" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabFinalInspections>
      <mat-tab label="Final Inspections" class="tab-label">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Final Number</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="number" id="txtSearchFinalNumber" formControlName="txtSearchFinalNumber" (keyup.enter)="searchFinalInspections('', 0)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 55670,28631..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-3">
            <button class="search-final-inspections-button" type="button" mat-flat-button color="primary" id="btnSearchFinalInspectionsMain" (click)="searchFinalInspections('', 0)">Search</button>
          </div>
          <div class="search-column-4">
            <mat-form-field class="example-full-width header-button" style="width: 90px !important" appearance="fill">
              <mat-label>Last Finals</mat-label>
              <input class="textbox-input txtSearchLastFinalNumbers" matInput placeholder="" value="10" type="text" #txtSearchLastFinalNumbers id="txtSearchLastFinalNumbers">
            </mat-form-field>
            <button class="search-final-inspections-button btnSearchLastThreeFinalInspectionsMain" type="button" mat-flat-button color="primary" id="btnSearchLastThreeFinalInspectionsMain" (click)="searchFinalInspections('lastinspections', txtSearchLastFinalNumbers.value)">Show Last {{txtSearchLastFinalNumbers.value}} Finals</button>
          </div>
          <div class="search-column-5">
            <button class="search-final-inspections-button btnSearchReadyForLoading" *ngIf="isSupervisor" type="button" mat-flat-button color="primary" id="btnSearchReadyForLoading" (click)="searchFinalInspections('readyforloading', 0)">Show Ready For Loading Finals</button>
          </div>
          <div class="search-column-6">
            <button class="search-final-inspections-button btnSearchParkedPoles" type="button" mat-flat-button color="primary" id="btnSearchParkedPoles" (click)="searchFinalInspections('parkedpoles', 0)">Show Parked Poles</button>
          </div>
          <div class="search-column-7">
            <div class="auto-refresh-checkbox" *ngIf="isSupervisor"><mat-checkbox class="example-margin" #autoRefresh id="chkRefreshReadyForLoading" formControlName="chkRefreshReadyForLoading" [value]="true" (change)="refreshReadyForLoading(autoRefresh.checked)">Refresh Ready For Loading</mat-checkbox></div>
            <input #timeLeft class="textbox-input time-left" matInput type="text" value="test" formControlName="txtTimeLeft" id="txtTimeLeft">
          </div>
        </div>

        <div class="search-totals-container">
          <div class="search-total-rows">Total Rows: {{totalRows}}</div>
          <div class="search-total-pieces">Total Qty: {{totalPoleQuantity}}</div>
          <div class="search-total-lm">Total M3: {{totalM3 | number:'1.0':'en-AU'}}</div>
          <div class="search-total-lm">Total Ton: {{totalTon | number:'1.0':'en-AU'}}</div>
          <div class="search-total-pieces">Results Time: {{resultTime}} s</div>
        </div>
        <mat-divider></mat-divider>

        <div class="stock-item-list-container">
          <app-final-inspection-list #appFinalInspectionList [datasource]="tpPoleInspections" [isSupervisor]="isSupervisor" [finalInspectionAdd]="finalInspectionAdd" (editFinalInspectionEvent)="editFinalInspection($event)" (removeFinalInspectionEvent)="removeFinalInspection($event)" *ngIf="tpPoleInspections != null"></app-final-inspection-list>
        </div>

      </mat-tab>
      <mat-tab label="Search Options" class="tab-label">
        <div class="search-options-fields-container" id="searchFieldsContainer">

          <div class="search-option-row-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Customer</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Essentail Energy" value="" type="text" id="txtCustomerName" formControlName="txtSearchCustomerName" (keyup.enter)="searchFinalInspections('', 0)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full product name i.e. Essent* Energy" class="help-icon">help_outline</mat-icon>
            <span class="search-options-search-button">
              <button class="search-final-inspections-button" type="button" mat-flat-button color="primary" id="btnSearchFinalInspectionsCustomer" (click)="searchFinalInspections('', 0)">Search</button>
            </span>
          </div>

          <div class="search-option-row-2">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Delivery Location</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Broken Hill" value="" type="text" id="txtLocation" formControlName="txtSearchLocation" (keyup.enter)="searchFinalInspections('', 0)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full location name i.e. Brok* Hill" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-3">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created From</mat-label>
              <input id="dteSearchDateCreatedFrom" class="date-input created-from-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteSearchDateCreatedFrom">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created To</mat-label>
              <input id="dteSearchDateCreatedTo" class="date-input created-from-date-search" #searchReceiptDateTo matInput [matDatepicker]="pickercreatedto" formControlName="dteSearchDateCreatedTo">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedto"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedto></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-5">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Order Number</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 474125" value="" type="text" id="txtOrderNumber" formControlName="txtOrderNumber" (keyup.enter)="searchFinalInspections('', 0)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full location name i.e. 474*25" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-6">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Pole Number</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 474125" value="" type="text" id="txtPoleNumber" formControlName="txtPoleNumber" (keyup.enter)="searchFinalInspections('', 0)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full location name i.e. 474*25" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-7">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Consecutive Number</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 081122A" value="" type="text" id="txtConsecutiveNumber" formControlName="txtConsecutiveNumber" (keyup.enter)="searchFinalInspections('', 0)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full location name i.e. 0811*2" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-7">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Product</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Pole" value="" type="text" id="txtProductType" formControlName="txtProductType" (keyup.enter)="searchFinalInspections('', 0)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full location name i.e. 0811*2" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-7">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Length</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 12.5" value="" type="number" id="txtLength" formControlName="txtLength" (keyup.enter)="searchFinalInspections('', 0)">
            </mat-form-field>&nbsp;
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>KN</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 8" value="" type="number" id="txtKN" formControlName="txtKN" (keyup.enter)="searchFinalInspections('', 0)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full location name i.e. 0811*2" class="help-icon">help_outline</mat-icon>
          </div>


          <div class="search-field-wrapper">
            <div class="search-option-row-final-status">
              <label id="example-radio-group-label">Final Status</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" #finalStatus formControlName="radFinalStatus">
                <mat-radio-button class="example-radio-button" [value]="-1" [checked]="true">All</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="0">In Progress</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">Pending</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="2">Ready For Loading</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="3">Complete</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="4">Parked Poles</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="copy-to-clip-board-container">
              <mat-icon matTooltip="Copy URL parameter" class="help-icon" (click)="copyToClipboard('finalstatus=' + getFinalStatusValue())">content_copy</mat-icon>
            </div>
          </div>

          <div class="search-field-wrapper">
            <div class="search-option-row-on-delivery">
              <label id="example-radio-group-label">On Delivery Docket</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radOnDeliveryDocket">
                <mat-radio-button class="example-radio-button" [value]="0">No</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">Yes</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="2" [checked]="true">Either</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="copy-to-clip-board-container">
              <mat-icon matTooltip="Copy URL parameter" class="help-icon" (click)="copyToClipboard('ondeliverydocket=' + getOnDeliveryStatus())">content_copy</mat-icon>
            </div>
          </div>

          <div class="search-field-wrapper">
            <div class="search-option-row-delivery-status">
              <label id="example-radio-group-label">Delivery Status</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radSearchDeliveryStatus">
                <mat-radio-button class="example-radio-button" *ngFor="let deliveryStatus of deliveryStatuses" [value]="deliveryStatus.intValue">
                  {{deliveryStatus.txtValue}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="copy-to-clip-board-container">
              <mat-icon matTooltip="Copy URL parameter" class="help-icon" (click)="copyToClipboard('deliverydocketstatus=' + getDeliveryStatus())">content_copy</mat-icon>
            </div>
          </div>

          <div class="search-option-row-7">
            <div class="use-auto-number-checkbox"><mat-checkbox class="example-margin" #parkedFinal id="blnParkedFinal" formControlName="blnParkedFinal">Parked Final</mat-checkbox></div>
          </div>

          <div class="search-option-row-7">
            <button class="search-option-delivery-docket-button" type="button" mat-flat-button color="primary" id="btnSearchFinalInspections" (click)="searchFinalInspections('', 0)">Search</button>
          </div>

        </div>
</mat-tab>

      <mat-tab label="Create Final Inspection" class="tab-label">
        <div class="create-final-inspection-container">
          <app-final-inspection-create (finalInspectionCreated)="finalInspectionCreated($event)" *ngIf="selectedTab == eFinalInspectionTabs.CreateFinalInspection"></app-final-inspection-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Final Inspection" class="tab-label">
        <div class="update-final-inspection-container">
          <app-final-inspection-update [tpPoleInspection]="tpPoleInspection" (finalInspectionUpdated)="finalInspectionUpdated($event)" (finalInspectionCancelled)="finalInspectionCancelled($event)" *ngIf="selectedTab == eFinalInspectionTabs.UpdateFinalInspection"></app-final-inspection-update>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
