import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';
import { dtoDeliveryDocket } from '../../_shared/business-objects/dto-delivery-docket.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoDeliveryDocketService } from '../../_shared/services/dto-delivery-docket.service';
import SignaturePad from 'signature_pad'
import { DeliveryDocketService } from '../../_shared/services/delivery-docket.service';
import { SignaturePadComponent } from '../../utilities/signature-pad/signature-pad.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '../../_shared/services/shared.service';
import { DeliveryDocketViewComponent } from '../delivery-docket-view/delivery-docket-view.component';
import { dtoDeliveryDocketCustomerDeliveryContact } from '../../_shared/business-objects/dto-delivery-docket-customer-delivery-contact.bo';
import { dtoDeliveryDocketCustomerDeliveryContactService } from '../../_shared/services/dto-delivery-docket-customer-delivery-contact.service';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';

@Component({
  selector: 'app-delivery-docket-print',
  templateUrl: './delivery-docket-print.component.html',
  styleUrls: ['./delivery-docket-print.component.css']
})
export class DeliveryDocketPrintComponent implements OnInit {

  @Input() deliveryDocketIdInput: string;
  @Input() showTOCs: boolean;
  @Input() hideSignaturePad: boolean;

  @ViewChild('appLoadingDocketView') appLoadingDocketView: DeliveryDocketViewComponent

  deliveryDocketId: string = "";
  deliveryDocket: dtoDeliveryDocket;
  customerDeliveryContacts: dtoDeliveryDocketCustomerDeliveryContact[];
  openPrintDialog: string = "";
  signaturePad: SignaturePad;
  signImage: any = null;
  timeStamp: number;

  signaturePadOpen: boolean = false;

  signaturePadDialogRef: MatDialogRef<SignaturePadComponent>;

  pdfMode: boolean = false;

  constructor(private dtoDeliveryDocketService: dtoDeliveryDocketService, private deliveryDocketService: DeliveryDocketService, private route: ActivatedRoute, private alertService: AlertService
    , private dialog: MatDialog, private sharedService: SharedService, private dtoDeliveryDocketCustomerDeliveryContactService: dtoDeliveryDocketCustomerDeliveryContactService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(async params => {

      if (params != null || true) {
        // The loading docket can be supplied from the @Input so it will override the param value.
        if (this.deliveryDocketIdInput == null || this.deliveryDocketIdInput == "") {
          this.deliveryDocketId = params["deliverydocketid"];

          this.openPrintDialog = params["openprintdialog"];

        }
      }
    });


    this.loadData();


    //const canvas = document.querySelector("canvas");
    ////const cxt = canvas.getContext("2d");
    ////cxt.globalCompositeOperation
    //this.signaturePad = new SignaturePad(canvas, {
    //  // It's Necessary to use an opaque color when saving image as JPEG;
    //  // this option can be omitted if only saving as PNG or SVG
    //  backgroundColor: 'rgb(255, 255, 255)'
    //});


  }

  loadSignature() {
    if (this.deliveryDocket == null || this.deliveryDocket.binSignature == null) {
      return;
    }

    this.signImage = this.deliveryDocket.binSignature;

  }

  async saveNoSignature() {
    this.deliveryDocket.binSignature = null;

    await this.deliveryDocketService.updateDeliveryDocketPromise(this.deliveryDocket);

    this.signImage = null;
    this.closeSignaturePad();
  }

  async signDeliveryDocket() {

    await this.openSignaturePad();

    let signaturePad: HTMLDivElement = document.getElementById("signaturePad") as HTMLDivElement;
    signaturePad.classList.remove('signature-pad-app-show');
    signaturePad.classList.remove('signature-pad-app-close');

    //this.positionSignature();

    setTimeout(this.positionSignature, 1);

  }

  async openSignaturePad() {
    this.signaturePadOpen = true;
  }

  positionSignature() {
    let signatureDiv: HTMLDivElement = document.getElementById("signatureDiv") as HTMLDivElement;
    let signaturePad: HTMLDivElement = document.getElementById("signaturePad") as HTMLDivElement;

    var offsets = signatureDiv.getBoundingClientRect();
    //var top = offsets.top + window.scrollY - 210;
    //var left = offsets.left + window.scrollX;
    var top = offsets.top - 50;
    var left = offsets.left - 50;


    signaturePad.style.top = top.toString() + "px";
    signaturePad.style.left = left.toString() + "px";

    signaturePad.classList.add('signature-pad-app-show');

  }

  closeSignaturePad() {
    this.signaturePadOpen = false;
    let signaturePad: HTMLDivElement = document.getElementById("signaturePad") as HTMLDivElement;
    signaturePad.classList.add('signature-pad-app-close');

  }

  async saveSignature(dataURL: string) {

    //let dataURL = this.signaturePad.toDataURL("image/jpeg"); // save image as JPEG

    //console.log(dataURL);

    //var blob = this.sharedService.dataURLToBlob(dataURL);

    await this.deliveryDocketService.saveSignaturePromise(this.deliveryDocketId, dataURL);

    this.deliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(this.deliveryDocketId);

    this.loadSignature();

    this.closeSignaturePad();

  }

//  clearSignature() {
//    this.signaturePad.clear();
//  }

//  saveJPGButton() {
//    if (this.signaturePad.isEmpty()) {
//      alert("Please provide a signature first.");
//    } else {
//      var dataURL = this.signaturePad.toDataURL("image/jpeg");
//      this.downloadSignature(dataURL, "signature.jpg");
//    }
//  }

//  downloadSignature(dataURL, filename) {
//    var blob = this.dataURLToBlob(dataURL);
//    var url = window.URL.createObjectURL(blob);

//    var a = document.createElement("a");
//    a.style.display = "none";
//    a.href = url;
//    a.download = filename;

//    document.body.appendChild(a);
//    a.click();

//    window.URL.revokeObjectURL(url);
//  }

//  dataURLToBlob(dataURL) {
    
//    var parts = dataURL.split(';base64,');
//    var contentType = parts[0].split(":")[1];
//    var raw = window.atob(parts[1]);
//    var rawLength = raw.length;
//    var uInt8Array = new Uint8Array(rawLength);

//    for (var i = 0; i < rawLength; ++i) {
//      uInt8Array[i] = raw.charCodeAt(i);
//    }

//    return new Blob([uInt8Array], { type: contentType });
//}


  async loadData() {


    if (this.deliveryDocketId == null || this.deliveryDocketId == "") {
      return;
    }


    this.deliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(this.deliveryDocketId);

    if (this.deliveryDocket == null) {
      this.alertService.openSnackBarCustom("No Delivery Docket", "There is no delivery docket", "Ok", "", "center", "bottom", "", 0, false);
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocketId));
    aParamList.push(aParam);

    this.customerDeliveryContacts = await this.dtoDeliveryDocketCustomerDeliveryContactService.getdtoDeliveryDocketCustomerDeliveryContactParamArrayPromise(aParamList);

    console.log("this.customerDeliveryContacts", this.customerDeliveryContacts);


    if (this.openPrintDialog == "yes") {
      setTimeout(() => { this.print() }, 500);
    }

    this.loadSignature()

  }

  async print() {

    console.log("delivery docket", this.deliveryDocket);

    if (this.deliveryDocket == null) {
      this.alertService.openSnackBarDefault("There is no delivery docket to print.");
      return;
    }

    window.print();

  }


  testPDF() {
    var pdf = new jsPDF('p', 'pt', 'A4');
    let htmlElement: HTMLElement = document.getElementById("deliveryDocketPrintContainer");

    if (htmlElement == null) {
      console.log("null element");
      return;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/octet-stream'
    });

    pdf.html(htmlElement,
      {
        callback: (pdf) => {
          //let arrBuffer: ArrayBuffer = pdf.output('arraybuffer');
          //console.log(arrBuffer);

          //this.http.post<Blob>("https://localhost:44358/api/Print/SaveFile/", arrBuffer, { headers: headers, responseType: 'blob' as 'json' }).subscribe(data => {
          //  //console.log("data: " + data);
          //});
          pdf.autoPrint({ variant: 'non-conform' });
          pdf.save('Test.pdf');
        }
      });


  }

}
