import { Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { dtoCustomerDeliveryAddress } from '../../_shared/business-objects/dto-customer-delivery-address.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoCustomerDeliveryAddressService } from '../../_shared/services/dto-customer-delivery-address.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-customer-delivery-address-selection',
  templateUrl: './customer-delivery-address-selection.component.html',
  styleUrl: './customer-delivery-address-selection.component.css',
  encapsulation: ViewEncapsulation.None
})
export class CustomerDeliveryAddressSelectionComponent {

  @Input() customerId: string;
  @Input() customerName: string;
  @Input() selectedCustomerDeliveryAddress: dtoCustomerDeliveryAddress;
  @Output() selectedCustomerDeliveryAddressEvent = new EventEmitter<dtoCustomerDeliveryAddress>();
  @Output() customerDeliveryAddressCancelledEvent = new EventEmitter<boolean>();
  @Output() addCustomerDeliveryAddressEvent = new EventEmitter();
  @Output() updateCustomerDeliveryAddressEvent = new EventEmitter<dtoCustomerDeliveryAddress>();

  
  @ViewChild('chkGlobalAddress') chkGlobalAddress: MatCheckbox;
  @ViewChildren('selectedAddress') selectedAddresses: QueryList<MatCheckbox>;

  customerDeliveryAddresses: dtoCustomerDeliveryAddress[];
  customerDeliveryAddressesAll: dtoCustomerDeliveryAddress[];
  globalCustomerDeliveryAddresses: dtoCustomerDeliveryAddress[];
  globalCustomerDeliveryAddressesAll: dtoCustomerDeliveryAddress[];

  isGlobalAddress: boolean = false;

  form: FormGroup;

  showSpinner: boolean = false;
  addressHoverId: string = "";
  showUpdateButton: boolean = false;

  constructor(private formBuilder: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private dtoCustomerDeliveryAddressService: dtoCustomerDeliveryAddressService, private formService: FormService) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      txtCustomerName: [this.customerName]
    });


    this.showSpinner = false;

    this.formService.setAutoFocusTimeout("deliveryAddressFilter", 300);

    this.loadData();
  }

  async loadData() {

    if (this.selectedCustomerDeliveryAddress == null) {
      this.getCustomerDeliveryAddresses(false, false);
    }

    if (this.selectedCustomerDeliveryAddress != null) {
      this.isGlobalAddress = (this.selectedCustomerDeliveryAddress.guClientId == this.sharedService.GlobalCustomerGuid);


      await this.getCustomerDeliveryAddresses(this.isGlobalAddress, false);

      setTimeout(() => { this.scrollToSelectedAddress() }, 500);

    }

  }

  scrollToSelectedAddress() {

    if (this.selectedCustomerDeliveryAddress != null) {

      var gotAddress = this.customerDeliveryAddresses.find(obj => {
        return obj.rowguid === this.selectedCustomerDeliveryAddress.rowguid;
      });
      if (gotAddress != null) {
        let customerDeliveryAddress: HTMLCollectionOf<Element> = document.getElementsByClassName("customer-delivery-address");

        let arr = this.customerDeliveryAddresses.map(a => a.rowguid);
        this.formService.scrollToListElement("customerDeliveryAddressSelect", this.selectedCustomerDeliveryAddress.rowguid, customerDeliveryAddress, arr);
      }
    }
  }


  async getCustomerDeliveryAddresses(showGlobal: boolean, includeInactive: boolean) {

    this.customerDeliveryAddresses = await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressByCustomerId(this.customerId, includeInactive);
    this.customerDeliveryAddressesAll = this.customerDeliveryAddresses;

    this.globalCustomerDeliveryAddresses = await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressByCustomerId(this.sharedService.GlobalCustomerGuid);
    this.globalCustomerDeliveryAddressesAll = this.globalCustomerDeliveryAddresses;

    if (showGlobal == true) {
      this.customerDeliveryAddresses = this.globalCustomerDeliveryAddresses;
    }

    this.customerDeliveryAddresses.sort((a, b) => {
      return (a.txtTown.toLowerCase().trim() > b.txtTown.toLowerCase().trim() ? 1 : -1) || (a.txtAddress.toLowerCase().trim() > b.txtAddress.toLowerCase().trim() ? 1 : -1)
    });


  }

  async filterCustomerDeliveryAddress(deliveryAddress: string, globalAddresses: boolean) {

    let addresses: dtoCustomerDeliveryAddress[];

    if (globalAddresses == true) {
      addresses = this.globalCustomerDeliveryAddresses;
    }

    if (globalAddresses == false) {
      addresses = this.customerDeliveryAddressesAll;
    }

    //console.log("addresses", addresses);

    if (deliveryAddress != null && deliveryAddress != "") {
      addresses = addresses.filter(a => (a.txtTown.toLowerCase().indexOf(deliveryAddress.toLowerCase()) > -1) || (a.txtAddress.toLowerCase().indexOf(deliveryAddress) > -1));
    }

    this.customerDeliveryAddresses = addresses;

    this.customerDeliveryAddresses.sort((a, b) => {
      return (a.txtTown.toLowerCase().trim() > b.txtTown.toLowerCase().trim() ? 1 : -1) || (a.txtAddress.toLowerCase().trim() > b.txtAddress.toLowerCase().trim() ? 1 : -1)
    });





    setTimeout(() => { this.scrollToSelectedAddress() }, 500);

  }


  setSelectedAddress(customerDeliveryAddress: dtoCustomerDeliveryAddress) {  //, selectedAddress: MatCheckbox

    this.selectedAddresses.forEach(c => {
      
      c.checked = (c.value == customerDeliveryAddress.rowguid);
    
    });

    this.selectedCustomerDeliveryAddress = customerDeliveryAddress;

  }

  async selectCustomerDeliveryAddress() {

    if (this.selectedCustomerDeliveryAddress == null) {
      let msg: string = await this.alertService.openSnackBarCustomPromise("Select Address", "Please select an address", "Ok", "", "center", "bottom", "", 0, false);
      return;
    }

    this.selectedCustomerDeliveryAddressEvent.emit(this.selectedCustomerDeliveryAddress);

  }

  cancel() {

    this.selectedAddresses.forEach(c => {
      c.checked = false;
    });

    this.selectedCustomerDeliveryAddress = null;
    this.customerDeliveryAddressCancelledEvent.emit(true);
  }

  rowEnter(customerDeliveryAddressId: string) {
    this.addressHoverId = customerDeliveryAddressId;
   //console.log("row enter");

  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");

  }

  addCustomerDeliveryAddress() {
    this.addCustomerDeliveryAddressEvent.emit();
  }

  updateCustomerDeliveryAddress(customerDeliveryAddress: dtoCustomerDeliveryAddress) {

    this.updateCustomerDeliveryAddressEvent.emit(customerDeliveryAddress);

  }

  showEditButton() {
    this.showUpdateButton = !this.showUpdateButton;
  }

}
