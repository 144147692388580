<div class="final-inspection-item-summary-container" [ngClass]="mode == 'print' ? 'final-inspection-item-summary-container-print' : 'final-inspection-item-summary-container'">

  <table mat-table #tblfinalInspectionItemSummarys [dataSource]="datasource" class="mat-elevation-z8 final-inspection-summary" [ngClass]="mode == 'print' ? 'final-inspection-summary-print' : 'final-inspection-summary'">

    <ng-container matColumnDef="txtOrderNo">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH"> Order No </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalInspectionItemSummarytxtOrderNo"> {{finalInspectionItemSummary.txtOrderNo}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemSummaryTD footerTD"> Total </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerName">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH"> Customer </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalInspectionItemSummarytxtCustomerName" [ngClass]="mode == 'print' ? 'finalInspectionItemSummarytxtCustomerName-print' : 'finalInspectionItemSummarytxtCustomerName'"> {{finalInspectionItemSummary.txtCustomerName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtDestination">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH"> Destination </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalInspectionItemSummarytxtDestination"> {{finalInspectionItemSummary.txtDestination}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="txtProductName">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH"> Product </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalInspectionItemSummarytxtProductName"> {{finalInspectionItemSummary.txtProductName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtFinalPole">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH"> Final Pole </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalFinalPole"> {{finalInspectionItemSummary.txtFinalPole}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="intS1">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH alignRight"> S1 </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalS1 alignRight"> {{finalInspectionItemSummary.intS1 == 0 ? '' : finalInspectionItemSummary.intS1}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemSummaryTD alignRight footerTD"> {{finalInspectionItemSummaryS1QtyTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="intS2">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH alignRight"> S2 </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalS2 alignRight"> {{finalInspectionItemSummary.intS2 == 0 ? '' : finalInspectionItemSummary.intS2}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemSummaryTD alignRight footerTD"> {{finalInspectionItemSummaryS2QtyTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="intS3">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH alignRight"> S3 </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalS3 alignRight"> {{finalInspectionItemSummary.intS3 == 0 ? '' : finalInspectionItemSummary.intS3}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemSummaryTD alignRight footerTD"> {{finalInspectionItemSummaryS3QtyTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="intBBT">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH alignRight"> BBT </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalBBT alignRight"> {{finalInspectionItemSummary.intBBT == 0 ? '' : finalInspectionItemSummary.intBBT}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemSummaryTD alignRight footerTD"> {{finalInspectionItemSummaryBBTQtyTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="intTotalQuantity">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH alignRight"> Total Qty </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalQuantity alignRight"> {{finalInspectionItemSummary.intTotalQuantity}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemSummaryTD alignRight footerTD"> {{finalInspectionItemSummaryQtyTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualM3">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH alignRight"> Actual M3 </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalActualM3 alignRight"> {{finalInspectionItemSummary.fltActualM3}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemSummaryTD alignRight footerTD"> {{finalInspectionItemSummaryM3Total}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualTonne">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemSummaryTH alignRight"> Actual Tonne </th>
      <td mat-cell *matCellDef="let finalInspectionItemSummary" class="finalInspectionItemSummaryTD finalActualTonne alignRight"> {{finalInspectionItemSummary.fltActualTonne}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemSummaryTD alignRight footerTD footerActualTonne"> {{finalInspectionItemSummaryTonneTotal}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedfinalInspectionItemSummary"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedfinalInspectionItemSummary;let finalInspectionItemSummary;"></tr>

    <tr mat-footer-row *matFooterRowDef="displayedfinalInspectionItemSummary"></tr>
  </table>
  <div class="no-stock-items" *ngIf="datasource == null"><h7>There are no final inspection items</h7></div>
</div>

