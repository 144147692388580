export interface CustomerContact
{
	blnActive: boolean;
	dteLastUpdated: Date;
	guCustomerId: string;
	intOrder: number;
	rowguid: string;
	txtAddress1: string;
	txtAddress2: string;
	txtComments: string;
	txtDeliveryEmail: string;
	txtEmail: string;
	txtFax: string;
	txtLastName: string;
	txtMobile: string;
	txtName: string;
	txtPhone1: string;
	txtPhone2: string;
	txtPostCode: string;
	txtState: string;
	txtTown: string;
}

export class CustomerContact
{
	blnActive: boolean;
	dteLastUpdated: Date;
	guCustomerId: string;
	intOrder: number;
	rowguid: string;
	txtAddress1: string;
	txtAddress2: string;
	txtComments: string;
	txtDeliveryEmail: string;
	txtEmail: string;
	txtFax: string;
	txtLastName: string;
	txtMobile: string;
	txtName: string;
	txtPhone1: string;
	txtPhone2: string;
	txtPostCode: string;
	txtState: string;
	txtTown: string;
}

