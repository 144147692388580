import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TPPoleInspection } from '../business-objects/tppole-inspection.bo';

import { SQLParamArray } from '../business-objects/sql-param-array';
@Injectable()
export class TPPoleInspectionService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		this._baseUrl = baseUrl;
	}

	getTPPoleInspection(tPPoleInspectionId: string) {
		return this.http.get<TPPoleInspection>(this._baseUrl + 'api/TPPoleInspection/' + tPPoleInspectionId);
	}

    getTPPoleInspectionByFinalNo(finalNo: number) {
      return this.http.get<TPPoleInspection>(this._baseUrl + 'api/TPPoleInspection/GetTPPoleInspectionByFinalNo/' + finalNo);
    }

	getTPPoleInspectionParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<TPPoleInspection[]>(this._baseUrl + 'api/TPPoleInspection/GetTPPoleInspectionParamArray/', aParam);
	}

	getAllTPPoleInspections() {
		return this.http.get<TPPoleInspection[]>(this._baseUrl + 'api/TPPoleInspection/GetAllTPPoleInspections/');
	}

	//getAlldtoTPPoleInspections() {
	//	return this.http.get<dtoTPPoleInspection[]>(this._baseUrl + 'api/TPPoleInspection/GetAllTPPoleInspections/');
	//}

	createTPPoleInspection(tPPoleInspection: TPPoleInspection) {
		return this.http.post<TPPoleInspection>(this._baseUrl + 'api/TPPoleInspection/CreateTPPoleInspection/', tPPoleInspection);
	}

	updateTPPoleInspection(tPPoleInspection: TPPoleInspection) {
		return this.http.put<TPPoleInspection>(this._baseUrl + 'api/TPPoleInspection/', tPPoleInspection);
  }


  async geTPPoleInspectionParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<TPPoleInspection[]>(resolve => {
      this.getTPPoleInspectionParamArray(aParam).subscribe(dtoTPPoleInspections => {
        resolve(dtoTPPoleInspections);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async getTPPoleInspectionPromise(tpPoleInspectionId: string) {
    return new Promise<TPPoleInspection>(resolve => {
      this.getTPPoleInspection(tpPoleInspectionId).subscribe(dtoTPPoleInspection => {
        resolve(dtoTPPoleInspection);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async getTPPoleInspectionByFinalNoPromise(finalNo: number) {
    return new Promise<TPPoleInspection>(resolve => {
      this.getTPPoleInspectionByFinalNo(finalNo).subscribe(dtoTPPoleInspection => {
        resolve(dtoTPPoleInspection);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async createTPPoleInspectionPromise(tPPoleInspection: TPPoleInspection) {
    return new Promise<TPPoleInspection>(resolve => {
      this.createTPPoleInspection(tPPoleInspection).subscribe(tpPoleInspection => {
        resolve(tpPoleInspection);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async updateTPPoleInspectionPromise(tPPoleInspection: TPPoleInspection) {
    return new Promise<TPPoleInspection>(resolve => {
      this.updateTPPoleInspection(tPPoleInspection).subscribe(tpPoleInspection => {
        resolve(tpPoleInspection);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  statusText(status: TPPoleInspection.enStatus) {
    if (status == TPPoleInspection.enStatus.InProgress) {
      return "In Progress";
    }

    if (status == TPPoleInspection.enStatus.Pending) {
      return "Pending";
    }


    if (status == TPPoleInspection.enStatus.ReadyForLoading) {
      return "Ready For Loading";
    }

    if (status == TPPoleInspection.enStatus.Complete) {
      return "Complete";
    }

  }

}
