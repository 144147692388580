<div class="loading-docket-print-screen-container">
  <div class="loading-docket-print-container">
    <div class="header-wrapper">
      <div class="header-container">
        <div class="header-logo">
          <img src="../../../../assets/img/BlackLogo.bmp" width="40" height="40" />
        </div>
        <div class="divider-wrapper">
          <div class="header-column-2"></div>
        </div>
        <div class="header-column-3" *ngIf="false">
          <div class="header-row-1">
            <div>C</div>
            <div>o</div>
            <div>f</div>
            <div>f</div>
            <div>s</div>
            <div></div>
            <div>H</div>
            <div>a</div>
            <div>r</div>
            <div>b</div>
            <div>o</div>
            <div>u</div>
            <div>r</div>
          </div>
          <div class="header-row-2">
            <div>H</div>
            <div>A</div>
            <div>R</div>
            <div>D</div>
            <div>W</div>
            <div>O</div>
            <div>O</div>
            <div>D</div>
            <div>S</div>
          </div>
          <div class="header-website" *ngIf="false">www.coffshardwoods.com.au</div>
        </div>

        <div class="header-column-3A">
          <div class="header-row-1A">
            <div><h3>Coffs Harbour Hardwoods</h3></div>
          </div>
        </div>

      </div>
    </div>
    <div class="loading-docket-heading">Loading Docket</div>
    <div class="print-buton">
      <button class="print-button" type="button" mat-flat-button color="primary" (click)="print()" *ngIf="this.loadingDocket != null && pdfMode == false">Print Loading Docket</button>
    </div>
    <div class="loading-docket-info" *ngIf="loadingDocket != null">
      <div class="docket-heading-label loading-docket-no-label">Docket No:</div>
      <div class="docket-heading-cell loading-docket-no">{{loadingDocket.intDocketNo}}</div>
      <div class="docket-heading-label loading-docket-date-loaded-label">Delivery Date:</div>
      <div class="docket-heading-cell loading-docket-date-loaded">{{loadingDocket.dteExpectedDeliveryDate | date: 'dd/MM/yyyy'}}</div>
      <div class="docket-heading-label loading-docket-date-loaded-label">Driver:</div>
      <div class="docket-heading-cell loading-docket-date-loaded">{{loadingDocket.txtHaulingCompanyDriverFirstName}} {{loadingDocket.txtHaulingCompanyDriverLastName}}</div>
      <div class="docket-heading-label loading-docket-hauler-label">Hauler:</div>
      <div class="docket-heading-cell loading-docket-hauler">{{loadingDocket.txtHaulingCompanyName}}</div>
      <div class="docket-heading-label loading-docket-trailer-label">Trailer:</div>
      <div class="docket-heading-cell loading-docket-trailer">{{loadingDocket.txtTrailerAbbreviation != "" ? loadingDocket.txtTrailerAbbreviation : loadingDocket.txtTrailer}}</div>
      <div class="docket-heading-label loading-docket-comments-label">Comments:</div>
      <div class="docket-heading-cell loading-docket-comments"><div class="load-comments-container"><div class="load-comments">{{loadingDocket.txtComments}}</div><div class="load-loadsof" *ngIf="loadingDocket.intMaxLoads > 1">{{'Load ' + loadingDocket.intLoadNo + ' of ' + loadingDocket.intMaxLoads}}</div></div></div>
    </div>
    <div class="items-container">
      <div class="loading-docket-items-container">
        <div class="delivery-items-label">Delivery Items</div>
        <div class="delivery-items-container">
          <app-loading-docket-view class="loading-docket-view" #appLoadingDocketView [loadingDocketIdInput]="loadingDocket.rowguid" *ngIf="loadingDocket != null"></app-loading-docket-view>
        </div>
      </div>
    </div>

  </div>

  <div class="loading-docket-bottom" id="loadingDocketBottom">
    <div class="signature-container">
      <div class="no-of-items-in-load-row-signature bold-label">Checked against Order:<br />by:</div>
      <div class="no-of-items-in-load-row-items bold-label">No of Items in load<br />Date</div>
      <div class="no-of-items-in-load-row-signature-label align-bottom signature-label">Person compliling order for delivery (name &amp; signature)</div>
      <div class="no-of-items-in-load-row-items-label align-bottom signature-label">Date</div>

      <div class="no-of-items-in-loaded-row-signature bold-label">Loaded and checked:<br />by:</div>
      <div class="no-of-items-in-loaded-row-items bold-label">No of Items loaded<br />Date</div>
      <div class="no-of-items-in-loaded-row-signature-label align-bottom signature-label">Person loading truck (name &amp; signature)</div>
      <div class="no-of-items-in-loaded-row-items-label align-bottom signature-label">Date</div>


      <div class="no-of-items-on-truck-row-signature bold-label">Load Received:<br />by:</div>
      <div class="no-of-items-on-truck-row-items bold-label">No of Items on truck<br />Date</div>
      <div class="no-of-items-on-truck-row-signature-label align-bottom signature-label">Driver (name &amp; signature)</div>
      <div class="no-of-items-on-truck-row-items-label align-bottom signature-label">Date</div>

    </div>
  </div>
</div>
<div class="pagebreak"> </div>
<app-loading-check-list class="loading-check-list-app" *ngIf="showCheckSheet"></app-loading-check-list>
