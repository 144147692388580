import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeliveryDocketOrderItem } from '../../_shared/business-objects/delivery-docket-order-item.bo';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { dtoCustomerDeliveryAddress } from '../../_shared/business-objects/dto-customer-delivery-address.bo';
import { dtoDeliveryDocketOrderItem } from '../../_shared/business-objects/dto-delivery-docket-order-item.bo';
import { dtoDeliveryDocket } from '../../_shared/business-objects/dto-delivery-docket.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { ClientDeliveryAddressService } from '../../_shared/services/client-delivery-address.service';
import { CustomerDeliveryAddressService } from '../../_shared/services/customer-delivery-address';
import { DeliveryDocketOrderItemService } from '../../_shared/services/delivery-docket-order-item.service';
import { dtoCustomerDeliveryAddressService } from '../../_shared/services/dto-customer-delivery-address.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-delivery-docket-order-item-update',
  templateUrl: './delivery-docket-order-item-update.component.html',
  styleUrls: ['./delivery-docket-order-item-update.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ], encapsulation: ViewEncapsulation.None
})
export class DeliveryDocketOrderItemUpdateComponent implements OnInit {

  form: FormGroup;

  @Output() deliveryDocketOrderItemUpdated = new EventEmitter<DeliveryDocketOrderItem>();
  @Output() deliveryDocketOrderItemCancelled = new EventEmitter<boolean>();

  @Input() deliveryDocketOrderItem: dtoDeliveryDocketOrderItem;
  @Input() deliveryDocket: dtoDeliveryDocket;

  @ViewChild("guCustomerDeliveryAddressId") guCustomerDeliveryAddressId: MatSelectionList;

  private _onDestroy = new Subject<void>();

  showSpinner: boolean


  public deliveryAddressFilterCtrl: FormControl = new FormControl();
  public filteredDeliveryAddresses: ReplaySubject<dtoCustomerDeliveryAddress[]> = new ReplaySubject<dtoCustomerDeliveryAddress[]>(1);
  public deliveryAddresses: dtoCustomerDeliveryAddress[];
  public deliveryAddressesAll: dtoCustomerDeliveryAddress[];

  constructor(private formBuilder: FormBuilder, private formService: FormService, private sharedService: SharedService, private alertService: AlertService
    , private dtoCustomerDeliveryAddressService: dtoCustomerDeliveryAddressService, private clientDeliveryAddressService: ClientDeliveryAddressService
    , private deliveryDocketOrderItemService: DeliveryDocketOrderItemService  ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      txtDeliveryDocketNo: [{ value: '', disabled: true }],
      txtCustomer: [{ value: '', disabled: true }],
      guCustomerDeliveryAddressId: [''],
      txtHauler: [{ value: '', disabled: true }],
      txtOrderNo: [{ value: '', disabled: true }],
      txtProductDescription: [{ value: '', disabled: true }],
      txtQuantity: [''],
      txtQuantityUoM: [{ value: '', disabled: true }],
      txtComments: ['']
    });


    if (this.deliveryDocketOrderItem == null) {
      this.alertService.openSnackBar("There is no delivery docket order item to update", "Close", "center", "bottom", "", 4000);
      return;
    }

    this.showSpinner = false;

    this.loadData();

    this.form.controls['txtDeliveryDocketNo'].setValue(this.deliveryDocket.intDocketNo.toString());
    this.form.controls['txtCustomer'].setValue(this.deliveryDocket.txtCustomerName);
    this.form.controls['guCustomerDeliveryAddressId'].setValue([this.deliveryDocketOrderItem.guCustomerDeliveryAddressId]);
    this.form.controls['txtHauler'].setValue(this.deliveryDocket.txtHaulingCompanyName);
    this.form.controls['txtOrderNo'].setValue(this.deliveryDocketOrderItem.txtOrderNo);
    this.form.controls['txtProductDescription'].setValue(this.deliveryDocketOrderItem.txtDescription);
    this.form.controls['txtQuantity'].setValue(this.deliveryDocketOrderItem.fltQuantity);
    this.form.controls['txtQuantityUoM'].setValue(this.deliveryDocketOrderItem.txtOrderItemQuantityUom);
    //this.form.controls['txtComments'].setValue(this.deliveryDocketOrderItem.txtComments);

    //this.form.controls['txtDeliveryDocketNo'].disable();
    //this.form.controls['txtCustomer'].disable();
    //this.form.controls['txtHauler'].disable();
    //this.form.controls['txtOrderNo'].disable();
    //this.form.controls['txtProductDescription'].disable();
    //this.form.controls['txtQuantityUoM'].disable();

    this.showSpinner = false;

    console.log(this.deliveryDocketOrderItem);

  }


  async loadData() {

    await this.getDeliveryAddresses();

  }

  async getDeliveryAddresses() {

    //let blankDelAddress: dtoCustomerDeliveryAddress = new dtoCustomerDeliveryAddress();
    //blankDelAddress.txtAddressWithNewLine = 'None';

    let dividerDelAddress: dtoCustomerDeliveryAddress = new dtoCustomerDeliveryAddress();
    dividerDelAddress.txtAddressWithNewLine = '=================GLOBAL ADDRESSES========================';
    dividerDelAddress.txtTown = "";   // need to put in because the filter function uses town field and can't be null

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guClientId", this.deliveryDocket.guCustomerId));
    aParamList.push(aParam);

    let deliveryAddresses = await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressParamArrayPromise(aParamList);
    if (deliveryAddresses == null) {
      deliveryAddresses = [];
    }

    deliveryAddresses.sort((a, b) => { return (a.txtTown > b.txtTown ? 1 : -1) || (a.txtAddress > b.txtAddress ? 1 : -1) });


    aParamList = [];
    aParam = [];

    aParam.push(new SQLParamArray("guClientId", this.sharedService.GlobalCustomerGuid));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("blnActive", "1"));
    aParamList.push(aParam);

    let deliveryAddressesGlobal = await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressParamArrayPromise(aParamList);


    if (deliveryAddressesGlobal != null) {
      //console.log("global addresses: ", deliveryAddressesGlobal);
      deliveryAddressesGlobal.sort((a, b) => { return (a.txtTown > b.txtTown ? 1 : -1) || (a.txtAddress > b.txtAddress ? 1 : -1) });
      deliveryAddresses.push(dividerDelAddress);
      deliveryAddresses = deliveryAddresses.concat(deliveryAddressesGlobal);

    }

    this.deliveryAddressesAll = deliveryAddresses;
    this.deliveryAddresses = deliveryAddresses;

    setTimeout(() => {
      this.formService.scrollToMatListOption("guCustomerDeliveryAddressId", this.deliveryDocketOrderItem.guCustomerDeliveryAddressId, this.guCustomerDeliveryAddressId.options.toArray());
    }, 500);

  }

 
  filterDeliveryAddress(address: string, globalAddress: boolean) {

    if (!this.deliveryAddresses) {
      return;
    }

    let addresses: dtoCustomerDeliveryAddress[] = this.deliveryAddressesAll;

    if (globalAddress == false) {
      addresses = addresses.filter(a => ((a.txtAddress != null && a.txtAddress.toLowerCase().indexOf(address.toLowerCase()) > -1) || (a.txtTown.toLowerCase().indexOf(address.toLowerCase()) > -1)) && a.guClientId != this.sharedService.GlobalCustomerGuid);
    }

    if (globalAddress == true) {
      addresses = addresses.filter(a => ((a.txtAddress != null && a.txtAddress.toLowerCase().indexOf(address.toLowerCase()) > -1) || (a.txtTown.toLowerCase().indexOf(address.toLowerCase()) > -1)) && a.guClientId == this.sharedService.GlobalCustomerGuid);
    }

    //console.log("customers filter: ", addresses);

    //addresses = addresses.slice(0, 50);

    this.deliveryAddresses = addresses;

    if (address == "" && globalAddress == false) {
      // We've backspaced back to no characters in the search so revert back to original selection.
      this.form.controls['guCustomerDeliveryAddressId'].setValue([this.deliveryDocketOrderItem.guCustomerDeliveryAddressId]);
      setTimeout(() => {
        this.formService.scrollToMatListOption("guCustomerDeliveryAddressId", this.deliveryDocketOrderItem.guCustomerDeliveryAddressId, this.guCustomerDeliveryAddressId.options.toArray());
      }, 500);
    }

  }

  async updateDeliveryDocketOrderItem() {

    let quantity: number = parseFloat(this.form.controls['txtQuantity'].value);
    let deliveryAddress: string = this.form.controls['guCustomerDeliveryAddressId'].value[0];
    let comments: string = this.form.controls['txtComments'].value;
    let msg: string = "";

    if (quantity > this.deliveryDocketOrderItem.fltQuantity) {
      if ((this.deliveryDocketOrderItem.fltQtyBal - (quantity - this.deliveryDocketOrderItem.fltQuantity)) < 0) {
        msg = await this.alertService.openSnackBarCustomPromise("Over Supply", "You are trying to delivery more than what is on order. Is this OK?", "Yes", "No", "center", "top", "snack-bar-custom");
        if (msg != "Yes") {
          return;
        }
      }
    }

    //console.log("updated deliveryDocketOrderItem: ", this.deliveryDocketOrderItem);

    let delDocketOrderItem: DeliveryDocketOrderItem;
    delDocketOrderItem = await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemPromise(this.deliveryDocketOrderItem.rowguid);
    delDocketOrderItem.fltQuantity = quantity;
    delDocketOrderItem.txtComments = comments;

    // NEED TO GET RID OF THE NONE OPTION
    delDocketOrderItem.guCustomerDeliveryAddressId = delDocketOrderItem.guCustomerDeliveryAddressId;

    if (deliveryAddress != null && deliveryAddress != '') {
      delDocketOrderItem.guCustomerDeliveryAddressId = deliveryAddress;
    }

    this.showSpinner = true;


    delDocketOrderItem = await this.deliveryDocketOrderItemService.updateDeliveryDocektOrderItemPromise(delDocketOrderItem);

    console.log("updated del docket order item: ", delDocketOrderItem);

    this.showSpinner = false;

    this.deliveryDocketOrderItemUpdated.emit(delDocketOrderItem);

  }

  cancelDeliveryDocketOrderItem() {
    this.deliveryDocketOrderItemCancelled.emit(true);
  }


}
