import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { UserPosition } from '../business-objects/user-position.bo';
import { AuthService } from '../../account/auth.service';

@Injectable()
export class UserPositionService {

  public _baseUrl: string;
  treatmentPlantAdmin: boolean = false;
  crossarmAdmin: boolean = false;
  treatmentPlantYardSection: boolean = false;
  boardPlantYardSection: boolean = false;
  crossarmYardSection: boolean = false;
  millYardSection: boolean = false;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private authService: AuthService) {
    this._baseUrl = baseUrl;
  }

  getUserPosition(userPositionId: string) {
    return this.http.get<UserPosition>(this._baseUrl + 'api/UserPosition/' + userPositionId);
  }

  getUserPositionParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<UserPosition[]>(this._baseUrl + 'api/UserPosition/GetUserPositionsParamArray/', aParam);
  }

  getAllUserPositions() {
    return this.http.get<UserPosition[]>(this._baseUrl + 'api/UserPosition/GetAllUserPositions/');
  }

  //getAlldtoUserPositions() {
  //	return this.http.get<dtoUserPosition[]>(this._baseUrl + 'api/UserPosition/GetAllUserPositions/');
  //}

  createUserPosition(userPosition: UserPosition) {
    return this.http.post<UserPosition>(this._baseUrl + 'api/UserPosition/CreateUserPosition/', userPosition);
  }

  updateUserPosition(userPosition: UserPosition) {
    return this.http.put<UserPosition>(this._baseUrl + 'api/UserPosition/UpdateUserPosition/', userPosition);
  }

  async getUserPositionPromise(userPositionId: string) {
    return new Promise<UserPosition>(resolve => {
      this.getUserPosition(userPositionId).subscribe(userPosition => {
        resolve(userPosition);
      }, err => {
        this.alertService.openSnackBarError("Error getting UserPosition records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async getUserPositionParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<UserPosition[]>(resolve => {
      this.getUserPositionParamArray(aParam).subscribe(userPositions => {
        resolve(userPositions);
      }, err => {
        this.alertService.openSnackBarError("Error getting UserPosition records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async updateUserPositionPromise(userPosition: UserPosition) {
    return new Promise<UserPosition>(resolve => {
      this.updateUserPosition(userPosition).subscribe(userPosition => {
        resolve(userPosition);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating UserPosition record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async createUserPositionPromise(userPosition: UserPosition) {
    return new Promise<UserPosition>(resolve => {
      this.createUserPosition(userPosition).subscribe(userPosition => {
        resolve(userPosition);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating UserPosition record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async isInPosition(position: string, employeeId: string): Promise<boolean> {

    return new Promise<boolean>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guUserId", employeeId));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("txtName", position, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "Position"));
      aParamList.push(aParam);

      this.getUserPositionParamArray(aParamList).subscribe(userPos => {
        if (userPos != null && userPos.length > 0) {
          resolve(true);
        }
        resolve(false);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating UserPosition record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(false);
      });
    });
  }

  async setDefaultYardSection() {

    // Only one of these should exist other than office staff.
    this.crossarmAdmin = await this.isInPosition("crossarm admin", this.authService.getAppUserEmployeeId());
    this.treatmentPlantAdmin = await this.isInPosition("tp admin", this.authService.getAppUserEmployeeId());
    this.treatmentPlantYardSection = await this.isInPosition("tp supervisor", this.authService.getAppUserEmployeeId());
    this.boardPlantYardSection = await this.isInPosition("board plant sales staff", this.authService.getAppUserEmployeeId());
    this.crossarmYardSection = await this.isInPosition("crossarms", this.authService.getAppUserEmployeeId());
    this.millYardSection = await this.isInPosition("mill", this.authService.getAppUserEmployeeId());


  }


}
