<div class="quote-item-view-container">
  <div class="quote-item-view-table">
    <div class="quote-item-item-no-header item-row-header"></div>
    <div class="quote-item-description-header item-row-header">Description</div>
    <div class="quote-item-qty-header item-row-header alignRight">Qty</div>
    <div class="quote-item-qty-uom-header item-row-header">Qty UoM</div>
    <div class="quote-item-total-m3-header item-row-header alignRight">Total M3</div>
    <div class="quote-item-weight-header item-row-header alignRight">Weight (T)</div>

    <ng-container *ngFor="let quoteItem of quoteItems; let idx = index">

      <div class="quote-item-item-no item-row alignRight">{{idx + 1}}</div>
      <div class="quote-item-description item-row">
        <div [innerHTML]="quoteItem.txtDescriptionWeb | safeHtml"></div>
        <div class="quote-item-comments" *ngIf="quoteItem.txtComments != null" [innerHTML]="quoteItem.txtComments | safeHtml"></div>
      </div>
      <div class="item-row alignRight quote-item-qty">{{quoteItem.fltQuantityTotal}}</div>
      <div class="quote-item-qty-uom item-row">{{quoteItem.txtQuantityUoM}}</div>
      <div class="quote-item-total-m3 item-row alignRight">{{quoteItem.fltM3 | number : '1.2-2' }}</div>
      <div class="quote-item-weight item-row alignRight">{{quoteItem.fltTonne | number : '1.2-2'}}</div>

      <div class="quote-item-item-no-footer item-footer alignRight" *ngIf="(idx == quoteItems.length - 1)">Totals</div>
      <div class="quote-item-description-footer item-footer alignRight" *ngIf="(idx == quoteItems.length - 1)">
        <span *ngIf="totalQtyBoxes > 0" [ngClass]="totalQtyLM > 0 || totalQtyEach > 0 || totalQtyM2 > 0 ? 'item-footer-boxes' : ''">{{totalQtyBoxes + (uomMultiples == true ? ' Boxes' : '')}}</span>
        <span *ngIf="totalQtyM2 > 0" [ngClass]="totalQtyLM > 0 || totalQtyEach > 0 ? 'item-footer-m2' : ''">{{totalQtyM2 + (uomMultiples == true ? ' M2' : '')}}</span>
        <span *ngIf="totalQtyLM > 0" [ngClass]="totalQtyEach > 0 ? 'item-footer-lm' : ''">{{totalQtyLM + (uomMultiples == true ? ' LM' : '')}}</span>
        <span *ngIf="totalQtyEach > 0">{{totalQtyEach}}</span>
      </div>

      <div class="quote-item-qty-uom-footer item-footer" *ngIf="(idx == quoteItems.length - 1)"></div>
      <div class="quote-item-total-m3-footer item-footer alignRight" *ngIf="(idx == quoteItems.length - 1)">
        {{totalM3}}
      </div>
      <div class="quote-item-weight-footer item-footer item-footer-weight alignRight" *ngIf="(idx == quoteItems.length - 1)">
        {{totalTonne}}
      </div>

    </ng-container>
  </div>

</div>
