import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { dtoProductType } from '../../_shared/business-objects/dto-product-type.bo';
import { ProductType } from '../../_shared/business-objects/product-type.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoProductTypeService } from '../../_shared/services/dto-product-type.service';
import { ProductTypeService } from '../../_shared/services/product-type.service';
import { ProductTypeUpdateDialogComponent } from '../product-type-update-dialog/product-type-update-dialog.component';

@Component({
  selector: 'app-product-type-select',
  templateUrl: './product-type-select.component.html',
  styleUrl: './product-type-select.component.scss'
})
export class ProductTypeSelectComponent {

  @ViewChild('productTypeFilter') productTypeFilter: ElementRef;
  @ViewChild('productTypeListContainer') productTypeListContainer: ElementRef;
  @ViewChild('multipleProductTypes') multipleProductTypes: MatSlideToggle;


  @Input() productTypes: dtoProductType[];
  @Output() selectedProductTypesEvent = new EventEmitter<dtoProductType[]>();

  showSpinner: boolean
  addressHoverId: string;

  productTypeUpdateDialogRef: MatDialogRef<ProductTypeUpdateDialogComponent>;
  selectedProductTypes: dtoProductType[];
  productTypesFiltered: dtoProductType[];
  productTypeExpanded: dtoProductType;

  productTypeFilteredItems: HTMLCollectionOf<HTMLDivElement>;

  showProductTypeList: boolean = false;
  showProductTypeListTransition: boolean = false;

  productTypeSelectionExpanded: boolean = false;
  productTypeListPinned: boolean = false;

  toolTipText: string = "pin";

  constructor(private productTypeService: ProductTypeService, private dtoProductTypeService: dtoProductTypeService
    , private alertService: AlertService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.selectedProductTypes = [];

    document.addEventListener("click", this.openCloseProductTypeList.bind(this));

  }

  openCloseProductTypeList(e) {

    if (this.productTypeListPinned == true) {
      return;
    }

    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.pushPin != null) {
      return;
    }


    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.productTypeInput != null && this.productTypeFilter.nativeElement.value != null && this.productTypeFilter.nativeElement.value.toString() != "") {
      this.showProductTypeList = true;
      //console.log("this.showCustomerList = true", this.showCustomerList);
      //console.log("customerListContainer", this.customerFilter.nativeElement.value);

      return;
    }

    //if (e == null || e.target == null || e.target.dataset == null || e.target.dataset.productTypeSelect == null) {

    // ALWAYS CLOSE THE SEARCH LIST UNLESS I'M PINNED
    if (this.productTypeListPinned == false) {
      this.showProductTypeList = false;

      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      //console.log("this.showCustomerList = false", this.showCustomerList);
      return;
    }

    //let domStringMap: DOMStringMap = e.target.dataset;

    //console.log(domStringMap.customerSelect.toString() == "customerSelect");
    //console.log(domStringMap.customerSelect.toString());
  }

  checkProductTypeListIsOpen(inputValue: string) {

    //console.log("inputValue", inputValue);

    if (inputValue == null || inputValue == "") {
      this.showProductTypeList = false;
      //console.log("inputValue empty", inputValue);
      return;
    }

    this.showProductTypeList = true;


  }


  rowEnter(productTypeId: string) {
    this.addressHoverId = productTypeId;
    //console.log("row enter");
  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");
  }

  async selectProductType(productType: dtoProductType, selected: boolean) {

    if (productType == null) {
      this.alertService.openSnackBarDefault("There was a problem selecting the productType - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    // Remove All ProductType Contact
    if (this.multipleProductTypes != null && this.multipleProductTypes.checked == false) {

      let productTypeSelected: dtoProductType[] = this.productTypes.filter((a) => { return a.guSelectedProductTypeId != null && a.guSelectedProductTypeId != "" });
      for (let i = 0; i <= productTypeSelected.length - 1; i++) {
        productTypeSelected[i].guSelectedProductTypeId = "";
      }

      for (let i = this.selectedProductTypes.length - 1; i >= 0; i--) {
        this.selectedProductTypes.splice(i, 1);
      }

      for (let i = this.productTypesFiltered.length - 1; i >= 0; i--) {
        this.productTypesFiltered[i].guSelectedProductTypeId = "";
      }

    }

    // Remove ProductType Contact
    if (selected == false) {
      for (let i = this.selectedProductTypes.length - 1; i >= 0; i--) {
        if (this.selectedProductTypes[i].rowguid == productType.rowguid) {
          this.selectedProductTypes.splice(i, 1);
          productType.guSelectedProductTypeId = "";
          //console.log("unselected productType");
          this.selectedProductTypesEvent.emit(this.selectedProductTypes);
          break;
        }
      }
    }

    // Add ProductType Contact
    if (selected == true) {

      productType.guSelectedProductTypeId = "tempvalue";

      this.selectedProductTypes.push(productType);
      this.selectedProductTypesEvent.emit(this.selectedProductTypes);

      if (this.multipleProductTypes.checked == false && this.productTypeListPinned == false) {
        this.showProductTypeList = false;
      }

      //console.log("added productType");

    }


  }

  async updateProductType(productType: dtoProductType) {

    console.log(productType);
    if (productType == null) {
      this.alertService.openSnackBarDefault("There is no productType to edit");
      return;
    }

    if (this.productTypeUpdateDialogRef != null) {
      this.productTypeUpdateDialogRef.close();
    }

    this.productTypeUpdateDialogRef = this.dialog.open(ProductTypeUpdateDialogComponent, {
      hasBackdrop: false,
      height: '95vh',
      maxWidth: '90vw',
      data: { selectedProductType: productType }
    });

    this.productTypeUpdateDialogRef.backdropClick().subscribe(() => {
      this.productTypeUpdateDialogRef.close();
    });


    this.productTypeUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, productType: ProductType }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          let cust: dtoProductType = await this.dtoProductTypeService.getdtoProductTypePromise(data.productType.rowguid);
          this.selectedProductTypes.push(cust);

          for (let i = 0; i <= this.productTypes.length - 1; i++) {
            if (this.productTypes[i].rowguid == data.productType.rowguid) {
              this.productTypes[i] = cust;
              this.productTypes[i].guSelectedProductTypeId = "tempvalue";
              break;
            }
          }

          this.productTypes.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

          this.alertService.openSnackBarDefault("The ProductType has been updated");

        }
        else {
          // We have cancellled

        }

      });

  }

  addProductType() {

  }

  nextProductType(e, productType: dtoProductType) {

    //console.log("e ", e);

    if (e.code.toLowerCase() == "escape") {
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      this.productTypeFilter.nativeElement.focus();
      this.productTypeFilter.nativeElement.setSelectionRange(this.productTypeFilter.nativeElement.value.length, this.productTypeFilter.nativeElement.value.length);

      this.showProductTypeList = false;
      return;
    }

    if ((e.code.toLowerCase() == "arrowdown" || e.code.toLowerCase() == "arrowup") && this.showProductTypeList == true) {
      //console.log("e ", e);

      if (this.productTypeFilteredItems != null) {

        let tabIndex: number = e.target.tabIndex;

        let productTypeUpdateDiv = document.getElementById("productTypeUpdateDiv" + tabIndex);

        if (tabIndex < this.productTypeFilteredItems.length - 1 && e.code.toLowerCase() == "arrowdown") {

          let productTypeUpdateDivPlus = document.getElementById("productTypeUpdateDiv" + (tabIndex + 1));

          //console.log("e down ", tabIndex);
          //TODO
          // Set this.productTypeContainer.style overflow-y to hidden here
          let docBody = document.getElementById("rootBody");
          docBody.style.overflowY = "hidden";

          this.productTypeListContainer.nativeElement.classList.add("productType-container-no-scroll");

          this.productTypeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          productTypeUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.productTypeFilteredItems[tabIndex + 1].focus();
          this.productTypeFilteredItems[tabIndex + 1].classList.add("chh-list-item-row-selected");
          productTypeUpdateDivPlus.classList.add("chh-list-item-row-selected");

          // Set this.productTypeContainer.style overflow-y to scroll here
          this.productTypeListContainer.nativeElement.classList.remove("productType-container-no-scroll");

        }

        if (tabIndex >= 0 && e.code.toLowerCase() == "arrowup") {

          if (tabIndex == 0) {
            //console.log("up tabIndex ", tabIndex);

            this.productTypeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
            productTypeUpdateDiv.classList.remove("chh-list-item-row-selected");

            let docBody = document.getElementById("rootBody");
            docBody.style.overflowY = "scroll";

            this.productTypeFilter.nativeElement.focus();
            this.productTypeFilter.nativeElement.setSelectionRange(this.productTypeFilter.nativeElement.value.length, this.productTypeFilter.nativeElement.value.length);
            return;
          }


          let productTypeUpdateDivMinus = document.getElementById("productTypeUpdateDiv" + (tabIndex - 1));

          //console.log("e up ", tabIndex);

          this.productTypeListContainer.nativeElement.classList.add("productType-container-no-scroll");

          this.productTypeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          productTypeUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.productTypeFilteredItems[tabIndex - 1].focus();
          this.productTypeFilteredItems[tabIndex - 1].classList.add("chh-list-item-row-selected");
          productTypeUpdateDivMinus.classList.add("chh-list-item-row-selected");

          this.productTypeListContainer.nativeElement.classList.remove("productType-container-no-scroll");


        }

      }
      //console.log("nextProductType");

    }

    if (e.code.toLowerCase() == "enter") {
      this.selectProductType(productType, !(productType.guSelectedProductTypeId != ''));
    }

  }

  filterProductTypes(productTypeFilter: string, e) {


    if (e.code.toLowerCase() == "arrowdown" && this.showProductTypeList == true) {
      //console.log("e ", e);
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "hidden";

      this.productTypeFilteredItems = document.getElementsByClassName("product-type-name") as HTMLCollectionOf<HTMLDivElement>;
      if (this.productTypeFilteredItems != null && this.productTypeFilteredItems.length > 0) {


        this.productTypeFilteredItems[0].classList.add("chh-list-item-row-selected");
        let productTypeUpdateDiv = document.getElementById("productTypeUpdateDiv0");

        productTypeUpdateDiv.classList.add("chh-list-item-row-selected");

        this.productTypeFilteredItems[0].focus();
        console.log("filterProductType TabIndex: ", this.productTypeFilteredItems[0].tabIndex);

      }

      //console.log("productTypeElements", this.productTypeFilteredItems);

    }

    this.getProductTypes(productTypeFilter);



  }

  getProductTypes(productTypeFilter: string) {

    this.productTypesFiltered = this.productTypes;

    if (productTypeFilter == null || productTypeFilter == "") {
      this.productTypesFiltered = [];
      this.showProductTypeList = false;
      return;
    }



    let productTypes: dtoProductType[] = this.productTypes;

    productTypes = productTypes.filter(a => ((a.txtName.toLowerCase().indexOf(productTypeFilter.toLowerCase()) > -1)));

    productTypes = productTypes.slice(0, 50);

    productTypes.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

    this.productTypesFiltered = productTypes;

    this.showProductTypeList = true;

    this.setProductTypeListTranition(1000);

  }

  setProductTypeListTranition(delay: number) {

    setTimeout(() => {
      this.showProductTypeListTransition = true;
    }, delay);

  }

  checkMultipleProductTypes(multipleProductTypes: boolean) {

    if (multipleProductTypes == false) {
      //Remove multiple selected productTypes so only one remains.
      let multipleCust: dtoProductType[] = this.productTypes.filter((a) => { return a.guSelectedProductTypeId != null && a.guSelectedProductTypeId != "" });
      if (multipleCust != null && multipleCust.length > 0) {

        multipleCust.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

        for (let i = multipleCust.length - 1; i >= 1; i--) {
          multipleCust.pop();
        }

        for (let i = 0; i <= this.productTypes.length - 1; i++) {
          if (multipleCust[0].rowguid != this.productTypes[i].rowguid) {
            this.productTypes[i].guSelectedProductTypeId = "";
          }
        }

        this.selectedProductTypes.length = 0;
        this.selectedProductTypes.push(multipleCust[0]);
        this.selectedProductTypesEvent.emit(this.selectedProductTypes);

      }

    }
  }

  removeProductType(productType: dtoProductType) {

    if (productType == null) {
      return;
    }

    for (let i = this.selectedProductTypes.length - 1; i >= 0; i--) {
      if (this.selectedProductTypes[i].rowguid == productType.rowguid) {
        this.selectedProductTypes.splice(i, 1);
        productType.guSelectedProductTypeId = "";
        //console.log("unselected productType");
        this.selectedProductTypesEvent.emit(this.selectedProductTypes);
        break;
      }
    }


  }

  expandSelectedProductTypes(e: PointerEvent) {

    if (e.pointerType == null || e.pointerType == "") {
      return;
    }

    this.productTypeSelectionExpanded = !this.productTypeSelectionExpanded;

    let selectedProductTypes: HTMLDivElement = document.getElementById("selectedProductTypes") as HTMLDivElement;
    selectedProductTypes.classList.toggle("selected-product-types-expanded");

    if (this.productTypeSelectionExpanded == true) {

    }

  }

  clickPin(e) {

    this.productTypeListPinned = !this.productTypeListPinned;

    if (this.productTypeListPinned == false) {
      this.showProductTypeList = false;
    }

    this.toolTipText = this.productTypeListPinned == true ? 'unpin' : 'pin';


  }

}
