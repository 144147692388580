import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../account/auth.service';
import { Client } from '../../_shared/business-objects/client.bo';
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';
import { LoadingDocket } from '../../_shared/business-objects/loading-docket.bo';
import { dtoIntKeyValue, dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { HaulingCompany } from '../../_shared/business-objects/hauling-company.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { ClientService } from '../../_shared/services/client.service';
import { CustomerContactService } from '../../_shared/services/customer-contact.service';
import { LoadingDocketService } from '../../_shared/services/loading-docket.service';
import { FormService } from '../../_shared/services/form.service';
import { HaulingCompanyService } from '../../_shared/services/hauling-company.service';
import { SharedService } from '../../_shared/services/shared.service';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { dtoHaulingCompany } from '../../_shared/business-objects/dto-hauling-company.bo';
import { dtoHaulingCompanyDriver } from '../../_shared/business-objects/dto-hauling-company-driver.bo';
import { MobilePlantEquipment } from '../../_shared/business-objects/mobile-plant-equipment.bo';
import { HaulingCompanyDriverSelectionComponent } from '../../hauling/hauling-company-driver-selection/hauling-company-driver-selection.component';
import { MobilePlantEquipmentSelectionComponent } from '../../mobile-plant/mobile-plant-equipment-selection/mobile-plant-equipment-selection.component';
import { MobilePlantCategory } from '../../_shared/business-objects/mobile-plant-category.bo';
import { MobilePlantCategoryService } from '../../_shared/services/mobile-plant-category.service';
import { UserPositionService } from '../../_shared/services/user-position.service';

@Component({
  selector: 'app-loading-docket-create',
  templateUrl: './loading-docket-create.component.html',
  styleUrls: ['./loading-docket-create.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class LoadingDocketCreateComponent implements OnInit {

  form: FormGroup;

  @Input() inDialog: boolean = false;
  @Output() loadingDocketCreated = new EventEmitter<[LoadingDocket, string]>();
  @Output() loadingDocketCancelled = new EventEmitter<boolean>();

  @ViewChild('appHaulingCompanyDriverSelection') appHaulingCompanyDriverSelection: HaulingCompanyDriverSelectionComponent
  @ViewChild('appMobilePlantEquipmentSelection') appMobilePlantEquipmentSelection: MobilePlantEquipmentSelectionComponent

  public customersFilterCtrl: FormControl = new FormControl();
  public customerContactsFilterCtrl: FormControl = new FormControl();
  public haulersFilterCtrl: FormControl = new FormControl();

  public customers: Client[];
  public customerContacts: CustomerContact[];
  public haulers: HaulingCompany[];
  public yardSections: dtoKeyValue[] = [];

  public filteredCustomers: ReplaySubject<Client[]> = new ReplaySubject<Client[]>(1);
  public filteredCustomerContacts: ReplaySubject<CustomerContact[]> = new ReplaySubject<CustomerContact[]>(1);
  public filteredHaulers: ReplaySubject<HaulingCompany[]> = new ReplaySubject<HaulingCompany[]>(1);

  loadingDocketComments: string;

  private _onDestroy = new Subject<void>();

  selectedHaulingCompany: dtoHaulingCompany;
  selectedHaulingCompanyDriver: dtoHaulingCompanyDriver;
  haulingCompanyId: string;
  trailerEquipment: MobilePlantEquipment;
  equipmentCategoryId: string;
  chhVehicle: boolean;

  showSpinner: boolean

  constructor(private formBuilder: FormBuilder, private formService: FormService, private customerService: ClientService, private haulerService: HaulingCompanyService, private sharedService: SharedService
    , private customerContactService: CustomerContactService, private alertService: AlertService, private authService: AuthService
    , private loadingDocketService: LoadingDocketService, private mobilePlantCategoryService: MobilePlantCategoryService, private userPositionService: UserPositionService) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      guHaulerId: [''],
      guDriverId: [''],
      guTrailerId: [''],
      dteExpectedLoadingDate: [''],
      dteExpectedDeliveryDate: [''],
      dteDateLoaded: [''],
      intLoadNo: [''],
      intMaxLoads: [''],
      txtComments: [''],
      intYardSection: ['']
    });

    this.showSpinner = false;

    this.loadData();

    console.log("inDilog" + this.inDialog);

  }

  async loadData() {

    const datepipe: DatePipe = new DatePipe('en-AU');

    //this.form.controls['dteDateLoaded'].setValue(datepipe.transform(new Date(Date.now()), 'yyyy-MM-dd'));
    this.form.controls['intLoadNo'].setValue(1);
    this.form.controls['intMaxLoads'].setValue(1);

    this.yardSections = this.sharedService.getEnumObject2(SharedService.enYardSection);

    //*******************************************************************************************
    //*******************************************************************************************
    // NEED TO MAKE ANOTHER USER PREFERENCES GROUP FOR DEFAULTING TO CROSSARMS LOADING LOCATION
    // WHEN DALE OR SCOTT CREATE A MANUAL LOADING DOCKET ITS ALWAYS FOR CROSSARMS.
    // SO NEED TO DEFAULT BASED ON USER PREFERENCES.
    //*******************************************************************************************

    //this.yardSections = this.sharedService.getEnumObjectKeyValue(SharedService.enYardSection, true).sort((a, b) => { return a.txtValue > b.txtValue ? 0 : -1 });
    this.getHaulers();
    this.setTrailerCategoryId();
    await this.userPositionService.setDefaultYardSection();

    if (this.userPositionService.boardPlantYardSection == true) {
      this.form.controls['intYardSection'].setValue(SharedService.enYardSection.BoardPlant);
      //console.log("intYardSection: " + this.form.controls['intYardSection'].value);
    }

    if (this.userPositionService.crossarmYardSection == true || this.userPositionService.crossarmAdmin == true) {
      this.form.controls['intYardSection'].setValue(SharedService.enYardSection.XArms);
      //console.log("intYardSection: " + this.form.controls['intYardSection'].value);
    }

    if (this.userPositionService.millYardSection == true) {
      this.form.controls['intYardSection'].setValue(SharedService.enYardSection.Mill);
      //console.log("intYardSection: " + this.form.controls['intYardSection'].value);
    }

    if (this.userPositionService.treatmentPlantYardSection == true || this.userPositionService.treatmentPlantAdmin == true) {

      // MOST OF THE TIME IF TREATMENT PLANT OR ADMIN ARE MANULLY CREATING A LOADING DOCKET THEY
      // WILL BE CREATING IT FOR CROSSARMS, SO JUST DEFAULT TO XMARMS FOR NOW.

      this.form.controls['intYardSection'].setValue(SharedService.enYardSection.XArms);
      //this.form.controls['intYardSection'].setValue(SharedService.enYardSection.TreatmentPlant);
      //console.log("intYardSection: " + this.form.controls['intYardSection'].value);
   }

  }

  async setTrailerCategoryId() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("txtName", "Trailer"));
    aParamList.push(aParam);

    let mobilePlantCategories: MobilePlantCategory[] = await this.mobilePlantCategoryService.getMobilePlantCategoryParamArrayPromise(aParamList);

    if (mobilePlantCategories != null && mobilePlantCategories.length > 0) {
      this.equipmentCategoryId = mobilePlantCategories[0].rowguid;
    }

  }


  async getHaulers() {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];
    let haulers = await this.haulerService.getHaulingCompanyParamArrayPromise(paramArrayList);
    if (haulers == null) {
      return;
    }

    this.haulers = haulers;
    this.filteredHaulers.next(this.haulers);

    this.haulersFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterHaulers();
      });
  }


  private filterHaulers() {

    if (!this.haulers) {
      return;
    }

    // get the search keyword
    let search = this.haulersFilterCtrl.value;
    if (!search) {
      this.filteredHaulers.next(this.haulers);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the ProductTypes
    this.filteredHaulers.next(
      this.haulers.filter(c => c.txtName.toLowerCase().indexOf(search) > -1)
    );
  }

  chhVehicleSelection(val: boolean) {
    if (val == true) {
      this.appMobilePlantEquipmentSelection.chhVehicles = true;
      this.appMobilePlantEquipmentSelection.loadData();
    }

    if (val == false) {
      this.appMobilePlantEquipmentSelection.chhVehicles = false;
      this.appMobilePlantEquipmentSelection.loadData();
    }

  }


  haulingCompanySelection(haulingCompany: dtoHaulingCompany[]) {

    if (haulingCompany == null) {
      return;
    }

    if (haulingCompany != null && haulingCompany.length > 0) {
      //this.haulingCompany = haulingCompany[0];
      this.haulingCompanyId = haulingCompany[0].rowguid;
      this.appHaulingCompanyDriverSelection.haulingCompanyId = this.haulingCompanyId;
      this.appHaulingCompanyDriverSelection.selectedHaulingCompanyDriver = null;
      this.appHaulingCompanyDriverSelection.loadData();
    }

    //console.log("haulingCompany.rowguid", haulingCompany[0].rowguid);

    this.form.controls['guHaulerId'].setValue(haulingCompany[0].rowguid);
    //console.log("guHaulerId", this.form.controls['guHaulerId'].value);

  }

  haulingCompanyDriverSelection(haulingCompanyDriver: dtoHaulingCompanyDriver[]) {

    if (haulingCompanyDriver == null) {
      return;
    }

    //console.log("haulingCompanyDriver.rowguid", haulingCompanyDriver[0].rowguid);

    this.form.controls['guDriverId'].setValue(haulingCompanyDriver[0].rowguid);
    //console.log("guDriverId", this.form.controls['guDriverId'].value);


  }

  equipmentSelection(equipment: MobilePlantEquipment[]) {

    if (equipment == null) {
      return;
    }

    console.log("equipment.rowguid", equipment[0].rowguid);

    this.form.controls['guTrailerId'].setValue(equipment[0].rowguid);
    console.log("guTrailerId", this.form.controls['guTrailerId'].value);


  }

  async createLoadingDocket(mode: string) {

    let haulerId: string = this.form.controls['guHaulerId'].value;
    let driverId: string = this.form.controls['guDriverId'].value;
    let trailerId: string = this.form.controls['guTrailerId'].value;
    let expectedLoadingDate: Date = this.form.controls['dteExpectedLoadingDate'].value;
    let expectedDeliveryDate: Date = this.form.controls['dteExpectedDeliveryDate'].value;
    //let dateLoaded: Date = this.form.controls['dteDateLoaded'].value;
    let loadNo: number = Number(this.form.controls['intLoadNo'].value);
    let maxLoads: number = Number(this.form.controls['intMaxLoads'].value);
    let yardSection: number = Number(this.form.controls['intYardSection'].value);
    let comments: string = this.form.controls['txtComments'].value;


    //if (dateLoaded == null || dateLoaded.toString() == "") {
    //  this.alertService.openSnackBar("Please select a date loaded.", "Close", "center", "bottom", "", 3000);
    //  return;
    //}


    if (loadNo == null || loadNo < 0) {
      this.alertService.openSnackBar("Please enter a load number.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (maxLoads == null || maxLoads < 0) {
      this.alertService.openSnackBar("Please enter number of loads.", "Close", "center", "bottom", "", 3000);
      return;
    }

    //console.log("expectedLoadingDate", expectedLoadingDate);
    //console.log("expectedDeliveryDate", expectedDeliveryDate);
    //console.log("dateLoaded", dateLoaded);


    let loadingDocket: LoadingDocket = new LoadingDocket;
    loadingDocket.dteDateCreated = this.sharedService.currentDatePlusTZOffset();

    loadingDocket.dteExpectedLoadingDate = null;
    if (expectedLoadingDate != null) {
      loadingDocket.dteExpectedLoadingDate = this.sharedService.convertDateAEST(expectedLoadingDate);
    }

    loadingDocket.dteExpectedDeliveryDate = null;
    if (expectedDeliveryDate != null) {
      loadingDocket.dteExpectedDeliveryDate = this.sharedService.convertDateAEST(expectedDeliveryDate);
    }

    //loadingDocket.dteDateLoaded = null;
    //if (dateLoaded != null) {
    //  loadingDocket.dteDateLoaded = this.sharedService.convertDateAEST(dateLoaded);
    //}

    //console.log("haulerId", haulerId);

    loadingDocket.guHaulerId = null;
    if (haulerId != "") {
      loadingDocket.guHaulerId = haulerId;
    }

    //console.log("driverId", driverId);

    loadingDocket.guDriverId = null;
    if (driverId != "") {
      loadingDocket.guDriverId = driverId;
    }

    //console.log("trailerId", trailerId);

    loadingDocket.guTrailerId = null;
    if (trailerId != "") {
      loadingDocket.guTrailerId = trailerId;
    }

    loadingDocket.intLoadNo = loadNo;
    loadingDocket.intMaxLoads = maxLoads;
    loadingDocket.txtComments = comments;
    //loadingDocket.guLoadingLocationId =
    loadingDocket.intYardSection = yardSection;

    this.showSpinner = true;

    //console.log("loadingDocket", loadingDocket);

    loadingDocket = await this.loadingDocketService.createLoadingDocketPromise(loadingDocket);

    if (loadingDocket != null) {
      //console.log(loadingDocket.rowguid);
      loadingDocket = await this.loadingDocketService.getLoadingDocketPromise(loadingDocket.rowguid);

      this.alertService.openSnackBar("The loading docket has been created", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;
      this.loadingDocketCreated.emit([loadingDocket, mode]);
    }


    if (loadingDocket == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem creating the loading docket", "Close", "center", "bottom", 3000, true, "There was a problem creating the loading docket");
    }



  }



  cancelLoadingDocket() {
    this.loadingDocketCancelled.emit(true);
  }
}
