<div class="hauling-company-driver-create-container">
  <div class="hauling-company-driver-create-form-container">
    <form class="hauling-company-driver-create-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="header-row-4">
            <div class="hauling-company">
              <mat-form-field>
                <div class="hauling-company-input-container">
                  <input matInput class="hauling-company-input" placeholder="Filter by name" placeholderLabel="i.e. XN54....." value="" type="text" #haulingCompanyFilter (keyup)="filterHaulingCompany(haulingCompanyFilter.value, chkCCHVehicles.checked)" (focus)="haulingCompanyFilter.select()">
                  <br /><br />
                </div>
              </mat-form-field>
              <div class="chh-vehicle-checkbox"><mat-checkbox class="example-margin" #chkCCHVehicles id="chkCCHVehicles" [value]="true" [checked]="true" (change)="filterHaulingCompany(haulingCompanyFilter.value, chkCCHVehicles.checked)">CHH Vehicles</mat-checkbox></div>
              <mat-selection-list class="hauling-company-select" #guHaulingCompanyId [multiple]="false" formControlName="guHaulingCompanyId">
                <mat-list-option *ngFor="let hauler of haulers" [value]="hauler.rowguid">
                  {{hauler.txtName}}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>

          <div class="header-row-4">
            <div class="employees-container">
              <mat-form-field>
                <div class="employee-input-container">
                  <input matInput class="employee-input" placeholder="Filter by name" placeholderLabel="i.e. John....." value="" type="text" #employeeFilter (keyup)="filterEmployee(employeeFilter.value)" (focus)="employeeFilter.select()">
                  <br /><br />
                </div>
              </mat-form-field>
              <mat-selection-list class="hauling-company-select" #guEmployeeId [multiple]="false" formControlName="guEmployeeId" (selectionChange)="setFromEmployee($event)">
                <mat-list-option *ngFor="let employee of employees" [value]="employee">
                  {{employee.txtFirstName}} {{employee.txtLastName}}
                </mat-list-option>
              </mat-selection-list>

            </div>
          </div>

          <div class="first-name-container">
            <div class="first-name-form-field-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>First Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. John" value="" type="text" #txtFirstName id="txtFirstName" formControlName="txtFirstName">
              </mat-form-field>
            </div>
          </div>

          <div class="last-name-container">
            <div class="last-name-form-field-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Last Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Smith" value="" type="text" #txtLastName id="txtLastName" formControlName="txtLastName">
              </mat-form-field>
            </div>
          </div>

          <div class="nick-name-container">
            <div class="nick-name-form-field-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Nick Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Johno" value="" type="text" id="txtNickName" formControlName="txtNickName">
              </mat-form-field>
            </div>
          </div>

          <div class="comments-container">
            <div class="comments-form-field-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Comments</mat-label>
                <input class="textbox-input" matInput value="" type="text" id="txtComments" formControlName="txtComments">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <span><mat-slide-toggle formControlName="blnActive">Active</mat-slide-toggle></span>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <span><mat-slide-toggle formControlName="blnDefaultDriver">Default Driver</mat-slide-toggle></span>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
            </div>
          </div>

          <div class="action-button-container">
            <button class="action-button" mat-flat-button color="primary" (click)="createHaulingCompanyDriver('create')">Create Driver</button>
            <button class="action-button" mat-flat-button color="primary" (click)="cancelHaulingCompanyDriver()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="spinner-container">
  <mat-card [@displaySpinner]="transitionProducts ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showSpinner">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
