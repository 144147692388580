import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoHaulingCompanyDriver } from '../business-objects/dto-hauling-company-driver.bo';

@Injectable()
export class dtoHaulingCompanyDriverService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoHaulingCompanyDriver(dtoHaulingCompanyDriverId: string) {
		return this.http.get<dtoHaulingCompanyDriver>(this._baseUrl + 'api/dtoHaulingCompanyDriver/' + dtoHaulingCompanyDriverId);
	}

	getdtoHaulingCompanyDriverParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoHaulingCompanyDriver[]>(this._baseUrl + 'api/dtoHaulingCompanyDriver/GetdtoHaulingCompanyDriversParamArray/', aParam);
	}

	getAlldtoHaulingCompanyDrivers() {
		return this.http.get<dtoHaulingCompanyDriver[]>(this._baseUrl + 'api/dtoHaulingCompanyDriver/GetAlldtoHaulingCompanyDrivers/');
	}

	//getAlldtodtoHaulingCompanyDrivers() {
	//	return this.http.get<dtodtoHaulingCompanyDriver[]>(this._baseUrl + 'api/dtoHaulingCompanyDriver/GetAlldtoHaulingCompanyDrivers/');
	//}

	createdtoHaulingCompanyDriver(dtoHaulingCompanyDriver: dtoHaulingCompanyDriver) {
		return this.http.post<dtoHaulingCompanyDriver>(this._baseUrl + 'api/dtoHaulingCompanyDriver/CreatedtoHaulingCompanyDriver/', dtoHaulingCompanyDriver);
	}

	updatedtoHaulingCompanyDriver(dtoHaulingCompanyDriver: dtoHaulingCompanyDriver) {
		return this.http.put<dtoHaulingCompanyDriver>(this._baseUrl + 'api/dtoHaulingCompanyDriver/UpdatedtoHaulingCompanyDriver/', dtoHaulingCompanyDriver);
	}

	async getdtoHaulingCompanyDriverPromise(dtoHaulingCompanyDriverId: string) {
		return new Promise<dtoHaulingCompanyDriver>(resolve => {
			this.getdtoHaulingCompanyDriver(dtoHaulingCompanyDriverId).subscribe(dtoHaulingCompanyDriver => {
				resolve(dtoHaulingCompanyDriver);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoHaulingCompanyDriver records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoHaulingCompanyDriverParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoHaulingCompanyDriver[]>(resolve => {
			this.getdtoHaulingCompanyDriverParamArray(aParam).subscribe(dtoHaulingCompanyDrivers => {
				resolve(dtoHaulingCompanyDrivers);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoHaulingCompanyDriver records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoHaulingCompanyDriverPromise(dtoHaulingCompanyDriver: dtoHaulingCompanyDriver) {
		return new Promise<dtoHaulingCompanyDriver>(resolve => {
			this.updatedtoHaulingCompanyDriver(dtoHaulingCompanyDriver).subscribe(dtoHaulingCompanyDriver => {
				resolve(dtoHaulingCompanyDriver);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoHaulingCompanyDriver record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoHaulingCompanyDriverPromise(dtoHaulingCompanyDriver: dtoHaulingCompanyDriver) {
		return new Promise<dtoHaulingCompanyDriver>(resolve => {
			this.createdtoHaulingCompanyDriver(dtoHaulingCompanyDriver).subscribe(dtoHaulingCompanyDriver => {
				resolve(dtoHaulingCompanyDriver);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoHaulingCompanyDriver record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}