<div class="quote-item-list-container">
  <app-app-user-column-set-bar [componentColumns]="displayedColumns" [pageName]="columnBarPage" [container]="'quoteContainer'" [columnListId]="'columnListId'" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)"></app-app-user-column-set-bar>

  <table mat-table #tblQuote [dataSource]="datasource" multiTemplateDataRows class="mat-elevation-z8 quote-item-list-table">

    <ng-container matColumnDef="intItemNo">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"> Item No </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD itemNo"> {{quoteItem.intItemNo}} </td>
    </ng-container>

    <ng-container matColumnDef="txtStockKeepingUnit">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"> Product Code </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD productCode"> {{quoteItem.txtStockKeepingUnit}} </td>
    </ng-container>

    <ng-container matColumnDef="txtProductName">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"> Product Type </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD customerContactPhone"> {{quoteItem.txtProductName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtProfileName">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"> Profile </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD profileName"> {{quoteItem.txtProfileName}} </td>
    </ng-container>

    <ng-container matColumnDef="fltWidth">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"> Width </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD width"> {{quoteItem.fltWidth | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="fltThickness">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Thickness </th>
      <td mat-cell *matCellDef="let quoteItem" class="thickness quoteItemTD alignRight"> {{quoteItem.fltThickness | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="txtSizeType">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Size Type </th>
      <td mat-cell *matCellDef="let quoteItem" class="sizeType quoteItemTD alignRight"> {{quoteItem.txtSizeType == 'None' ? '' : quoteItem.txtSizeType}} </td>
    </ng-container>

    <ng-container matColumnDef="fltNominalWidth">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"> Nominal Width </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD nominalWidth"> {{quoteItem.fltNominalWidth | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="fltNominalThickness">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Nominal Thickness </th>
      <td mat-cell *matCellDef="let quoteItem" class="nominalThickness quoteItemTD alignRight"> {{quoteItem.fltNominalThickness | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="fltExWidth">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"> Ex Width </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD exWidth"> {{quoteItem.fltExWidth | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="fltExThickness">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Ex Thickness </th>
      <td mat-cell *matCellDef="let quoteItem" class="exThickness quoteItemTD alignRight"> {{quoteItem.fltExThickness | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="blnNonStandardNominal">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Non Standard Nominal </th>
      <td mat-cell *matCellDef="let quoteItem" class="nonStandardNominal quoteItemTD alignRight"> {{quoteItem.blnNonStandardNominal | yesNo}} </td>
    </ng-container>

    <ng-container matColumnDef="intRippedBoards">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> No. Of Ripped Boards </th>
      <td mat-cell *matCellDef="let quoteItem" class="rippedBoards quoteItemTD alignRight"> {{quoteItem.intRippedBoards | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="intLaminatedBoards">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> No. Of Boards </th>
      <td mat-cell *matCellDef="let quoteItem" class="laminatedBoards quoteItemTD alignRight"> {{quoteItem.intLaminatedBoards | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="txtPoleLengthKN">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Length / KN </th>
      <td mat-cell *matCellDef="let quoteItem" class="poleLengthKN quoteItemTD alignRight"> {{quoteItem.txtPoleLengthKN}} </td>
    </ng-container>

    <ng-container matColumnDef="blnDrilled">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Drilled </th>
      <td mat-cell *matCellDef="let quoteItem" class="drilled quoteItemTD alignRight"> {{quoteItem.blnDrilled | yesNo}} </td>
    </ng-container>

    <ng-container matColumnDef="fltLength">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Length </th>
      <td mat-cell *matCellDef="let quoteItem" class="length quoteItemTD alignRight"> {{quoteItem.fltLength | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="blnSetLength">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Set Length </th>
      <td mat-cell *matCellDef="let quoteItem" class="setLength quoteItemTD alignRight"> {{quoteItem.blnSetLength | yesNo}} </td>
    </ng-container>

    <ng-container matColumnDef="blnPrecoated">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Precoated </th>
      <td mat-cell *matCellDef="let quoteItem" class="precoated quoteItemTD alignRight"> {{quoteItem.blnPrecoated | yesNo}} </td>
    </ng-container>

    <ng-container matColumnDef="fltDiameter">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Diameter </th>
      <td mat-cell *matCellDef="let quoteItem" class="diameter quoteItemTD alignRight"> {{quoteItem.fltDiameter | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="txtDiameterType">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Diameter Type </th>
      <td mat-cell *matCellDef="let quoteItem" class="diameterType quoteItemTD alignRight"> {{quoteItem.txtDiameterType == 'None' ? '' : quoteItem.txtDiameterType}} </td>
    </ng-container>

    <ng-container matColumnDef="fltWeight">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Weight </th>
      <td mat-cell *matCellDef="let quoteItem" class="weight quoteItemTD alignRight"> {{quoteItem.fltWeight}} </td>
    </ng-container>

    <ng-container matColumnDef="txtSpeciesName">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Species </th>
      <td mat-cell *matCellDef="let quoteItem" class="species quoteItemTD alignRight"> {{quoteItem.txtSpeciesName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtInGroundDurabilityClass">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Durability I.G. </th>
      <td mat-cell *matCellDef="let quoteItem" class="inGroundDurabilityClass quoteItemTD alignRight"> {{quoteItem.txtInGroundDurabilityClass}} </td>
    </ng-container>

    <ng-container matColumnDef="txtAboveGroundDurabilityClass">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Durability A.G. </th>
      <td mat-cell *matCellDef="let quoteItem" class="aboveGroundDurabilityClass quoteItemTD alignRight"> {{quoteItem.txtAboveGroundDurabilityClass}} </td>
    </ng-container>

    <ng-container matColumnDef="txtStrengthGroup">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Strength Group </th>
      <td mat-cell *matCellDef="let quoteItem" class="strengthGroup quoteItemTD alignRight"> {{quoteItem.txtStrengthGroup == 'None' ? '' : quoteItem.txtStrengthGroup}} </td>
    </ng-container>

    <ng-container matColumnDef="txtGradeName">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Visual Grade </th>
      <td mat-cell *matCellDef="let quoteItem" class="gradeName quoteItemTD alignRight"> {{quoteItem.txtGradeName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtStructureGrade">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Structure Grade </th>
      <td mat-cell *matCellDef="let quoteItem" class="structureGrade quoteItemTD alignRight"> {{quoteItem.txtStructureGrade}} </td>
    </ng-container>

    <ng-container matColumnDef="txtAuthorityName">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Authority Name </th>
      <td mat-cell *matCellDef="let quoteItem" class="authorityName quoteItemTD alignRight"> {{quoteItem.txtAuthorityName}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Quote Item Date </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD quoteDate alignRight"> {{quoteItem.dteCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>


    <ng-container matColumnDef="dteDueDate">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Due Date </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD dueDate alignRight"> {{quoteItem.dteDueDate | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditDueDate">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"> </th>
      <td mat-cell *matCellDef="let quoteItem; let idx = dataIndex;" class="quoteItemTD editDueDate editIcon">
        <mat-icon matTooltip="Edit Due Date" class="edit-note-icon mouse-pointer" (click)="openDueDateSelector(quoteItem, idx)">edit_note</mat-icon>
        <div id="due-date-selector-location{{idx}}"></div>
        <div id="dueDateSelector{{idx}}" [ngClass]="quoteItem.rowguid == selectedDueDate ? 'date-selector-transition' : 'date-selector'">
          <app-date-selector *ngIf="quoteItem.rowguid == selectedDueDate" [inputDate]="quoteItem.dteDueDate" (outputDateEvent)="setDueDate(quoteItem, $event)" (cancelEvent)="closeDueDate()"></app-date-selector>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="txtAdminNotes">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"> Admin Notes </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD adminComments"> {{quoteItem.txtAdminNotes}} </td>
    </ng-container>

    <ng-container matColumnDef="fltM3">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> M3 </th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD actualM3 alignRight"> {{quoteItem.fltM3 | number : '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="fltTonne">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH alignRight"> Tonne <span class="total-tonne"> {{totalTonne}}</span></th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD actualTonne alignRight"> {{quoteItem.fltTonne | number : '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsComplete">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"></th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD"><button class="set-as-scheduled-button" type="button" mat-flat-button color="primary" (click)="setAsComplete(quoteItem)">Set As Complete</button></td>
    </ng-container>

    <ng-container matColumnDef="btnEditQuoteItem">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"></th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD"><button class="edit-quote-button" type="button" mat-flat-button color="primary" (click)="editQuote(quoteItem)">Edit</button></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveQuoteItem">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"></th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD"><button class="remove-quote-button" type="button" mat-flat-button color="primary" (click)="removeQuote(quoteItem)">Remove</button></td>
    </ng-container>

    <ng-container matColumnDef="btnDropbox">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH"></th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD"><button class="remove-quote-button" type="button" mat-flat-button color="primary" (click)="openDropbox(quoteItem)">Dropbox</button></td>
    </ng-container>

    <ng-container matColumnDef="btnViewLinkedQuoteItems">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH" aria-label="row actions"></th>
      <td mat-cell *matCellDef="let quoteItem" class="quoteItemTD">
        <button mat-icon-button aria-label="expand row" (click)="expandElement(quoteItem,$event);$event.stopPropagation()">
          <mat-icon *ngIf="quoteExpanded !== quote">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="quoteExpanded === quote">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>


    <ng-container matColumnDef="expandedDetail">
      <th mat-header-cell *matHeaderCellDef class="quoteItemTH" [attr.colspan]="displayedColumns.length"></th>
      <td mat-cell *matCellDef="let quoteItem; let rowIdx = dataIndex" class="quoteItemTD quoteExpandTD" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="quoteItemExpanded == quoteItem ? 'expanded' : 'collapsed'" *ngIf="false">
          <div class="detail-view-container">
            <div class="detail-view">
              <div class="view-type-label">View Type</div>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group app-user-column-set-radio" fxLayout="column">
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="0" name="radView" (click)="changeView(quoteItem, 0)" [checked]="viewType == 0">Quote View</mat-radio-button>
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="1" name="radView" (click)="changeView(quoteItem, 1)" [checked]="viewType == 1">Table View</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="link-items-view">
              <!-- Tabs of linked stock items, delivery dockets, -->
            </div>
          </div>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="quote-item-row-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let quoteItem; let idx = dataIndex;" id="locationrow{{idx}}" (click)="expandElementAndSetColor(quoteItem,idx)" [ngClass]="quoteItem.rowguid == selectedRow ? 'activeTR' : ''"></tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" id="expandedRow" class="example-detail-row"></tr>

  </table>

</div>

<app-spinner-inline-tick #spinnerInlineTick [showSpinner]="showInlineSpinner" [showTick]="showTick" [diameter]="20" [inlineSpinnerId]="'inlineSpinnerId'" class="inline-spinner"></app-spinner-inline-tick>
