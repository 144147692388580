import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { BoardTallyRecordDeliveryTransferred } from '../business-objects/board-tally-record-delivery-transferred.bo';
import { AlertService } from './alert.service';

@Injectable()
export class BoardTallyRecordDeliveryTransferredService
{

	public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getBoardTallyRecordDeliveryTransferred(boardTallyRecordDeliveryTransferredId: string) {
		return this.http.get<BoardTallyRecordDeliveryTransferred>(this._baseUrl + 'api/BoardTallyRecordDeliveryTransferred/' + boardTallyRecordDeliveryTransferredId);
	}

  getAllBoardTallyRecordDeliveryTransferredByDelDocket(deliveryDocketId: string) {
    return this.http.get<BoardTallyRecordDeliveryTransferred[]>(this._baseUrl + 'api/BoardTallyRecordDeliveryTransferred/GetAllBoardTallyRecordDeliveryTransferredByDelDocket/' + deliveryDocketId);
  }

	getBoardTallyRecordDeliveryTransferredParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<BoardTallyRecordDeliveryTransferred[]>(this._baseUrl + 'api/BoardTallyRecordDeliveryTransferred/GetBoardTallyRecordDeliveryTransferredsParamArray/', aParam);
	}

	getAllBoardTallyRecordDeliveryTransferreds() {
		return this.http.get<BoardTallyRecordDeliveryTransferred[]>(this._baseUrl + 'api/BoardTallyRecordDeliveryTransferred/GetAllBoardTallyRecordDeliveryTransferreds/');
	}

	//getAlldtoBoardTallyRecordDeliveryTransferreds() {
	//	return this.http.get<dtoBoardTallyRecordDeliveryTransferred[]>(this._baseUrl + 'api/BoardTallyRecordDeliveryTransferred/GetAllBoardTallyRecordDeliveryTransferreds/');
	//}

	createBoardTallyRecordDeliveryTransferred(boardTallyRecordDeliveryTransferred: BoardTallyRecordDeliveryTransferred) {
		return this.http.post<BoardTallyRecordDeliveryTransferred>(this._baseUrl + 'api/BoardTallyRecordDeliveryTransferred/CreateBoardTallyRecordDeliveryTransferred/', boardTallyRecordDeliveryTransferred);
	}

	updateBoardTallyRecordDeliveryTransferred(boardTallyRecordDeliveryTransferred: BoardTallyRecordDeliveryTransferred) {
		return this.http.post<BoardTallyRecordDeliveryTransferred>(this._baseUrl + 'api/BoardTallyRecordDeliveryTransferred/UpdateBoardTallyRecordDeliveryTransferred/', boardTallyRecordDeliveryTransferred);
	}

	async getBoardTallyRecordDeliveryTransferredParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<BoardTallyRecordDeliveryTransferred[]>(resolve => {
			this.getBoardTallyRecordDeliveryTransferredParamArray(aParam).subscribe(boardTallyRecordDeliveryTransferreds => {
				resolve(boardTallyRecordDeliveryTransferreds);
			}, err => {
				console.log(err);
				resolve(null);
			});
		});
	}

  async getAllBoardTallyRecordDeliveryTransferredByDelDocketPromise(deliveryDocketId: string) {
    return new Promise<BoardTallyRecordDeliveryTransferred[]>(resolve => {
      this.getAllBoardTallyRecordDeliveryTransferredByDelDocket(deliveryDocketId).subscribe(boardTallyRecordDeliveryTransferreds => {
        resolve(boardTallyRecordDeliveryTransferreds);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async createBoardTallyRecordDeliveryTransferredParamArrayPromise(boardTallyRecordDeliveryTransferred: BoardTallyRecordDeliveryTransferred) {
    return new Promise<BoardTallyRecordDeliveryTransferred>(resolve => {
      this.createBoardTallyRecordDeliveryTransferred(boardTallyRecordDeliveryTransferred).subscribe(boardTallyRecordDeliveryTransferred => {
        resolve(boardTallyRecordDeliveryTransferred);
      }, err => {
        this.alertService.openSnackBarError("Error creating Board Tally Record Delivery Transferred record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

}
