import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AlertService } from './alert.service';
import { dtoReckonSettings } from '../business-objects/dtoReckonSettings';
import { AuthService } from '../../account/auth.service';
import { ReckonCompanyFile, ReckonCompanyFiles } from '../reckon-objects/reckon-company-files.ro';
import { ReckonInvoiceQuery } from '../reckon-objects/reckon-invoice-query';
import { ReckonResponseObject } from '../reckon-objects/reckon-response.ro';

@Injectable()
export class ReckonSettingsService {

  public _baseUrl: string;
  public _reckonSettings: dtoReckonSettings;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getLocalhostReckonBasicToken() {
    return this.http.get<string>(this._baseUrl + 'api/ReckonSettings/GetLocalhostReckonBasicToken/');
  }

  getTestingReckonBasicToken() {
    return this.http.get<string>(this._baseUrl + 'api/ReckonSettings/GetTestingReckonBasicToken/');
  }

  getProductionReckonBasicToken() {
    return this.http.get<string>(this._baseUrl + 'api/ReckonSettings/GetProductionReckonBasicToken/');
  }

  getReckonSettings() {
    return this.http.get<dtoReckonSettings>(this._baseUrl + 'api/ReckonSettings/GetReckonSettings/');
  }

  async getLocalhostReckonBasicTokenPromise() {
    return new Promise<string>(resolve => {
      this.getLocalhostReckonBasicToken().subscribe(basicToken => {
        resolve(basicToken);
      }, err => {
        this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getTestingReckonBasicTokenPromise() {
    return new Promise<string>(resolve => {
      this.getTestingReckonBasicToken().subscribe(basicToken => {
        resolve(basicToken);
      }, err => {
        this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getProductionReckonBasicTokenPromise() {
    return new Promise<string>(resolve => {
      this.getProductionReckonBasicToken().subscribe(basicToken => {
        resolve(basicToken);
      }, err => {
        this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }


  async getReckonBasicTokenPromise() {
    return new Promise<string>(async resolve => {

      if (this._reckonSettings == null) {
        this._reckonSettings = await this.getReckonSettingsPromise();
      }

      if (this._reckonSettings == null) {
        this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, "Error getting Settings records");
        resolve(null);
      }

      if (this._baseUrl.indexOf("localhost") > -1) {
        resolve(this._reckonSettings.localhostReckonBasicToken);
      }

      if (this._baseUrl.indexOf("mtech.testing.coffshardwoods") > -1) {
        resolve(this._reckonSettings.testingReckonBasicToken);
      }

      if (this._baseUrl.indexOf("mtech.coffshardwoods") > -1) {
        resolve(this._reckonSettings.productionReckonBasicToken);
      }

      resolve(null);

    });
  }

  async getReckonClientIdPromise() {
    return new Promise<string>(async resolve => {

      if (this._reckonSettings == null) {
        this._reckonSettings = await this.getReckonSettingsPromise();
      }

      if (this._reckonSettings == null) {
        this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, "Error getting Settings records");
        resolve(null);
      }


      if (this._baseUrl.indexOf("localhost") > -1) {
        resolve(this._reckonSettings.localhostReckonClientId);
      }

      if (this._baseUrl.indexOf("mtech.testing.coffshardwoods") > -1) {
        resolve(this._reckonSettings.testingReckonClientId);
      }

      if (this._baseUrl.indexOf("mtech.coffshardwoods") > -1) {
        resolve(this._reckonSettings.productionReckonClientId);
      }

      resolve(null);

    });
  }

  async getReckonRedirectURIPromise() {
    return new Promise<string>(async resolve => {

      if (this._reckonSettings == null) {
        this._reckonSettings = await this.getReckonSettingsPromise();
      }

      if (this._reckonSettings == null) {
        this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, "Error getting Settings records");
        resolve(null);
      }


      if (this._baseUrl.indexOf("localhost") > -1) {
        resolve(this._reckonSettings.localhostReckonRedirect);
      }

      if (this._baseUrl.indexOf("mtech.testing.coffshardwoods") > -1) {
        resolve(this._reckonSettings.testingReckonRedirect);
      }

      if (this._baseUrl.indexOf("mtech.coffshardwoods") > -1) {
        resolve(this._reckonSettings.productionReckonRedirect);
      }

      resolve(null);

    });
  }

  async getReckonPrimarySubscriptionKeyPromise() {
    return new Promise<string>(async resolve => {

      if (this._reckonSettings == null) {
        this._reckonSettings = await this.getReckonSettingsPromise();
      }

      if (this._reckonSettings == null) {
        this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, "Error getting Settings records");
        resolve(null);
      }

      resolve(this._reckonSettings.primaryReckonSubscriptionKey);

    });
  }

  async getReckonSecondarySubscriptionKeyPromise() {
    return new Promise<string>(async resolve => {

      if (this._reckonSettings == null) {
        this._reckonSettings = await this.getReckonSettingsPromise();
      }

      if (this._reckonSettings == null) {
        this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, "Error getting Settings records");
        resolve(null);
      }

      resolve(this._reckonSettings.secondaryReckonSubscriptionKey);

    });
  }

  async getReckonSettingsPromise() {
    return new Promise<dtoReckonSettings>(resolve => {
      this.getReckonSettings().subscribe(reckonSettings => {
        resolve(reckonSettings);
      }, err => {
        this.alertService.openSnackBarError("Error getting Settings records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }



  getUserFiles() {


    // + this.accessToken
    return new Promise<ReckonCompanyFiles>(resolve => {
      //let header = new HttpHeaders({ 'Authorization': 'Bearer ' + this.accessToken, 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.subKey });

      this.http.get<ReckonCompanyFiles>('https://api.reckon.com/RAH/v2/userfiles', {
         responseType: 'json'
      }
      ).subscribe(res => {
        //console.log("res", res);
        resolve(res);
      }, err => {
        console.log("err", err);
        resolve(null);
      });
    });
  }

  async getReckonCompanyFile(name: string) {
    let companyFiles: ReckonCompanyFiles = await this.getUserFiles();
    let companyFile: ReckonCompanyFile = null;

    if (companyFiles == null || companyFiles.availableFiles == null) {
      this.alertService.openSnackBarError("Error getting Reckon Company Files", "Close", "center", "bottom", 5000, true, "Error getting Reckon Company Files - null getUserFiles()");
    }

    if (companyFiles != null && companyFiles.availableFiles != null && companyFiles.availableFiles.length > 0) {
      for (let i = 0; i <= companyFiles.availableFiles.length - 1; i++) {
        if (companyFiles.availableFiles[i].fileName.toLowerCase().indexOf(name.toLowerCase()) > -1) {
          companyFile = companyFiles.availableFiles[i];
          break;
        }
      }
    }

    return companyFile;
  }

  async getCougarEnterprisesCompanyFile() {
    return this.getReckonCompanyFile("Cougar Enterprises.QBW");
  }

  async getCoffsHardwoodsTradingCompanyFile() {
    return this.getReckonCompanyFile("CHH Trading.QBW");
  }

  async getCoffsHardwoodsSalesCompanyFile() {
    return this.getReckonCompanyFile("CHH Sales.QBW");
  }

  getReckonInvoiceQuery(fileName: string, invoiceNumber: number) {
    return new Promise<ReckonResponseObject>(resolve => {
      ////let header = new HttpHeaders({ 'Authorization': 'Bearer ' + this.accessToken, 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.subKey });
      let body: ReckonInvoiceQuery = new ReckonInvoiceQuery();
      body.FileName = fileName;
      body.Operation = "<?xml version=\"1.0\"?><?qbxml version=\"6.1\"?><QBXML><QBXMLMsgsRq onError=\"continueOnError\"><InvoiceQueryRq><RefNumber>" + invoiceNumber + "</RefNumber><IncludeLineItems>1</IncludeLineItems></InvoiceQueryRq></QBXMLMsgsRq></QBXML>"

      //body.Operation = "<InvoiceQueryRq request ID=\"73\">"
      //body.Operation += "<ModifiedDateRangeFilter><FromModifiedDate>2003-01-02"
      //body.Operation += "</FromModifiedDate> <ToModifiedDate>2003-01-05"
      //body.Operation += "</ToModifiedDate>"
      //body.Operation += "</ModifiedDateRangeFilter> <EntityFilter>"
      //body.Operation += "<FullName> Jones: Kitchen </FullName> </EntityFilter >"
      //body.Operation += "</InvoiceQueryRq>"

      ////body.Operation = "<?xml version =\"1.0\"?>"
      ////body.Operation += "<?qbxml version =\"6.1\"?>"
      ////body.Operation += "<QBXML>"
      ////body.Operation += "<QBXMLMsgsRq onError=\"continueOnError\">"
      ////body.Operation += "<InvoiceQueryRq></InvoiceQueryRq>"
      ////body.Operation += "</QBXMLMsgsRq>"
      ////body.Operation += "</QBXML>";
      body.UserName = "martinWebApp";
      body.Password = "C0ug4r";
      body.CountryVersion = "/SupportedVersions";
      ////2019.R2.AU

      //<InvoiceQueryRq />
      //let body = {
      //  "FileName": "Q:\\Test Company File\\Cougar Enterprises.QBW",
      //  "Operation": "<?xml version=\"1.0\"?><?qbxml version=\"6.1\"?><QBXML><QBXMLMsgsRq onError=\"continueOnError\"><InvoiceQueryRq requestID=\"2083\" /></QBXMLMsgsRq></QBXML>",
      //  "UserName": "martinWebApp",
      //  "Password": "C0ug4r",
      //  "CountryVersion": "/SupportedVersions"
      //}

      this.http.post<ReckonResponseObject>('https://api.reckon.com/RAH/v2/', body, {
        responseType: 'json'
      }
      ).subscribe(res => {
        console.log("res", res);
        resolve(res);
      }, err => {
        console.log("err", err);
        resolve(null);
      });
    });
  }


  async getInvoicesWithXMLReq(fileName: string) {
    var xmlReq = new XMLHttpRequest();
    // add callback handlers
    xmlReq.addEventListener("load", this.onLoad);

    let payLoad: string = "<?xml version=\"1.0\"?>";
    payLoad += "<?qbxml version=\"6.1\"?>";
    payLoad += "<QBXM>";
    payLoad += "<QBXMLMsgsRq onError=\"continueOnError\">";
    payLoad += "<InvoiceQueryRq />";
    //payLoad += "</InvoiceQueryRq>";
    payLoad += "</QBXMLMsgsRq>";
    payLoad += "</QBXML>";

    let body = {
      "FileName": "Q:\\Test Company File\\Cougar Enterprises.QBW",
      "Operation": "<?xml version=\"1.0\"?><? qbxml version =\"6.1\"?><QBXML><QBXMLMsgsRq onError=\"continueOnError\"><InvoiceQueryRq /></QBXMLMsgsRq></QBXML>",
      "UserName": "martinWebApp",
      "Password": "C0ug4r",
      "CountryVersion": "/SupportedVersions"
    }

    //let body: ReckonInvoiceQuery = new ReckonInvoiceQuery();
    //body.FileName = fileName;
    //body.Operation = payLoad;
    //body.Operation = "<?xml version=\"1.0\"?>";
    //body.Operation += "<?qbxml version=\"6.1\"?>";
    //body.Operation += "<QBXM>";
    //body.Operation += "<QBXMLMsgsRq onError=\"continueOnError\">";
    //body.Operation += "<InvoiceQueryRq />";
    //  //body.Operation += "</InvoiceQueryRq>";
    //body.Operation += "</QBXMLMsgsRq>";
    ////body.Operation += "</QBXML>";
    //body.UserName = "martinWebApp";
    //body.Password = "C0ug4r";
    //body.CountryVersion = "/SupportedVersions";
    //let body = 'FileName=' + fileName + '&operation=' + payLoad + '&UserName=martinWebApp&Password=C0ug4r&CountryVersion=2019.R2.AU';
    //body = encodeURIComponent(body);

    console.log(JSON.stringify(body));
      
    xmlReq.open("POST", "https://api.reckon.com/RAH/v2/");
    xmlReq.setRequestHeader("Content-Type", "application/json");
    xmlReq.setRequestHeader("Authorization", "Bearer c72e3a86e8232dcf0ebf36c52aabab08");
    xmlReq.setRequestHeader("ocp-apim-subscription-key", "1fedd1fdaef247efbd7db9574778b723");

    xmlReq.onload = function (e) {
      if (xmlReq.status != 200) {
        console.log('Error', e)
        return
      }
      var data = JSON.parse(xmlReq.response)

      console.log(data);

      //data.forEach(function (dt) {
      //  html += '<li style="font-size:22px;"><a href="' + dt.path + '">' + dt.title + '</a></li>'
      //})

    }


    xmlReq.onerror = function () {
      console.log('Request failed')
    }
    // send request to server
    xmlReq.send(JSON.stringify(body));

  }

  onLoad(e) {
    console.log("this.response", e);
  }

}
