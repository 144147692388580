<div class="board-tally-record-history-list-container">
  <table mat-table #tblBoardTallyRecordHistory [dataSource]="datasource" class="mat-elevation-z8 stock-item-table" matSort>

    <ng-container matColumnDef="dteCreated">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH" mat-sort-header> Created </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryCreated"> {{boardTallyRecordHistory.dteCreated | date: 'dd/MM/yyyy hh:mm:ss'}} </td>
    </ng-container>

    <ng-container matColumnDef="fltQuantity">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH" mat-sort-header> Quantity </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryQuantity"> {{boardTallyRecordHistory.fltQuantity}} </td>
    </ng-container>

    <ng-container matColumnDef="intQuantityUoM">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH alignRightTH" mat-sort-header> Quantity UoM </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryQuantityUoM alignRightTD"> {{boardTallyRecordHistory.txtQuantityUoM}} </td>
    </ng-container>

    <ng-container matColumnDef="fltTotalLM">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH" mat-sort-header> Length </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryLength"> {{boardTallyRecordHistory.txtQuantityUoM.trim() === "Each" ? boardTallyRecordHistory.fltTotalLM : ""}} </td>
    </ng-container>

    <ng-container matColumnDef="intPieces">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH" mat-sort-header> Pieces </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryPieces"> {{boardTallyRecordHistory.intPieces}} </td>
    </ng-container>

    <ng-container matColumnDef="intNoOfLayers">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH" mat-sort-header> No of Layers </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryNoOfLayers"> {{boardTallyRecordHistory.intNoOfLayers}} </td>
    </ng-container>

    <ng-container matColumnDef="txtLocation">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH alignRightTH" mat-sort-header> Location </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryLocation alignRightTD"> {{boardTallyRecordHistory.txtLocation}} </td>
    </ng-container>

    <ng-container matColumnDef="blnArchived">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH" mat-sort-header> Archived </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryArchived"> {{boardTallyRecordHistory.blnArchived ? 'Yes' : 'No'}} </td>
    </ng-container>

    <ng-container matColumnDef="dteArchivedDate">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH alignRightTH" mat-sort-header> Archived Date </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryArchiveDate alignRightTD"> {{boardTallyRecordHistory.dteArchivedDate}} </td>
    </ng-container>

    <ng-container matColumnDef="txtArchiveComments">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH alignRightTH" mat-sort-header> Archive Comments</th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryArchiveComments alignRightTD"> {{boardTallyRecordHistory.txtArchiveComments}} </td>
    </ng-container>

    <ng-container matColumnDef="txtDescription">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH" mat-sort-header> Order Item </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryDescription"> {{boardTallyRecordHistory.txtDescription}} </td>
    </ng-container>

    <ng-container matColumnDef="txtEmployeeName">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH alignRightTH" mat-sort-header> Employee Name </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryIdentifier alignRightTD"> {{boardTallyRecordHistory.txtFirstName + ' ' + boardTallyRecordHistory.txtLastName}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH alignRightTH" mat-sort-header> Date Changed </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryCreated alignRightTD"> {{boardTallyRecordHistory.dteDateComputerChanged | date: 'dd/MM/yyyy hh:mm:ss'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtChangedData">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH alignRightTH" mat-sort-header> Changed Data </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryChangedData alignRightTD"> {{boardTallyRecordHistory.txtChangedData}} </td>
    </ng-container>

    <ng-container matColumnDef="txtBoardTallyRecordComments">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH alignRightTH" mat-sort-header> Stock Detail Comments </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryBoardTallyRecordComments alignRightTD"> {{boardTallyRecordHistory.txtBoardTallyRecordComments}} </td>
    </ng-container>

    <ng-container matColumnDef="txtStockContainerComments">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH alignRightTH" mat-sort-header> Stock Container Comments </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryStockContainerComments alignRightTD"> {{boardTallyRecordHistory.txtStockContainerComments}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateReturned">
      <th mat-header-cell *matHeaderCellDef class="boardTallyRecordHistoryTH alignRightTH" mat-sort-header> Date Returned </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="boardTallyRecordHistoryTD boardTallyRecordHistoryDateReturned alignRightTD"> {{boardTallyRecordHistory.dteDateReturned | date: 'dd/MM/yyyy'}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="stock-item-row-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <div class="board-tally-history-paginator-container">
    <mat-paginator #paginator
                   (page)="pageChangeEvent($event)"
                   [length]="totalRecords"
                   [pageSize]="pageSize"
                   [pageIndex]="pageIndex"
                   [pageSizeOptions]="[5, 10, 20, 50, 100]"
                   showFirstLastButtons>
    </mat-paginator>
  </div>

  <div class="no-stock-items" *ngIf="datasource == null || datasource.data == null"><h7>There is no stock item history</h7></div>
</div>
<app-spinner #spinner [showSpinner]="showSpinner" class="spinner"></app-spinner>
