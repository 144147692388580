import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResponseType } from '@microsoft/microsoft-graph-client';
import { Local } from 'protractor/built/driverProviders';
import { AuthService } from '../../account/auth.service';
import { ReckonBearerResponse } from '../../_shared/reckon-objects/ReckonBearerResponse';
import { AlertService } from '../../_shared/services/alert.service';
import { ReckonSettingsService } from '../../_shared/services/reckon.service';

@Component({
  selector: 'app-reckon-identity',
  templateUrl: './reckon-identity.component.html',
  styleUrl: './reckon-identity.component.scss'
})
export class ReckonIdentityComponent implements OnInit {

  reckonBearerRes: ReckonBearerResponse = null;
  authCode: string = "";
  subKey: string = "";
  refreshToken: string = "";
  basicToken: string = "";
  clientId: string = "";
  redirectURI: string = "";
  accessToken: string = "";
  tokenExpiry: number = 0;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private reckonSettingsService: ReckonSettingsService
    , private route: ActivatedRoute, private authService: AuthService) {

  }

  ngOnInit(): void {

    this.loadData();

  }

  async loadData() {

    this.accessToken = localStorage.getItem("rkAccessToken");
    this.refreshToken = localStorage.getItem("rkRefreshToken");
    this.tokenExpiry = Number(localStorage.getItem("rkTokenExpiry"));

    await this.getAuthCodeFromQueryString();

    this.basicToken = await this.reckonSettingsService.getReckonBasicTokenPromise();
    this.clientId = await this.reckonSettingsService.getReckonClientIdPromise();
    this.redirectURI = await this.reckonSettingsService.getReckonRedirectURIPromise();
    this.subKey = await this.reckonSettingsService.getReckonPrimarySubscriptionKeyPromise();

    if (this.authCode == "") {
      console.log("in loadData authcode is empty so calling this.getAuthCode: ");

      //redirect to reckon identity server to get auth code.
      this.getAuthCode();
      let msg: string = await this.alertService.openSnackBarCustomDefaultShowCancel("Reckon Login Problem - in loadData", "There was a problem accessing reckon to want to get a new token?", "Yes", "No");
      if (msg == "Yes") {
        this.getAuthCode();
      }
   }


    this.testCurrentToken();

    console.log("this.basicToken:", this.basicToken);

  }

  async getAuthCodeFromQueryString() {

    if (localStorage.getItem("authCode") != null && localStorage.getItem("authCode") != "") {
      console.log("getting auth code from local storage in ngOnInit");
      this.authCode = localStorage.getItem("authCode");
    }

    if (localStorage.getItem("authCode") == null || localStorage.getItem("authCode") == "") {
      this.route.queryParams.subscribe(async params => {
        if (params != null) {
          let code: string = params["code"];

          console.log("got code from query string: ", code);

          if (code != null && code != "") {
            this.authCode = code;
            localStorage.setItem("authCode", code);
          }
        }
      });

    }
  }


  async testCurrentToken() {

   

    console.log("this.accessToken:", this.accessToken);
    if (this.accessToken == null || this.accessToken == "") {
      await this.getToken();
    }

    if (this.accessToken != null && this.accessToken != "") {
      //Try and get the company file to test if access token works.
      let gotFiles: boolean = await this.getUserFiles();
      //this.alertService.openSnackBarDefault("got user files: " + gotFiles);

      window.opener.postMessage(true, '*'); //or false
      window.close();
    }
  }

  async getToken(){

    if (this.refreshToken != null && this.refreshToken != "") {
      this.reckonBearerRes = await this.getReckonRefreshTokenPromise();
    }

    console.log("this.reckonBearerRes from Refresh method", this.reckonBearerRes);


    if (this.reckonBearerRes == null) {
      //Refresh Token did not work so try get token method.
      // If fails then it will redirect to get a new authCode and come back and try again.
      this.reckonBearerRes = await this.getReckonTokenPromise();

      console.log("this.reckonBearerRes from Token method", this.reckonBearerRes);


    }

  }

  async getRefreshToken() {


    this.reckonBearerRes = await this.getReckonRefreshTokenPromise();

    console.log("this.reckonBearerRes", this.reckonBearerRes);
  }

  async getImplicitToken() {


    await this.getReckonImplicitTokenPromise();

  }

  async getReckonImplicitTokenPromise() {
    return new Promise(resolve => {
      this.getReckonImplicitToken().subscribe(res => {
        console.log(res);
        resolve(res);
      }, err => {

        console.log("err: ", err);

        //this.alertService.openSnackBarError("Error getting reckon token", "Close", "center", "bottom", 4000, false, "");
        resolve(null);
      });
    });
  }

  async getReckonTokenPromise() {
    return new Promise<ReckonBearerResponse>(resolve => {


      this.getReckonToken().subscribe(res => {
        console.log(res);

        localStorage.setItem('rkAccessToken', res.access_token);
        localStorage.setItem('rkRefreshToken', res.refresh_token);
        localStorage.setItem("rkTokenExpiry", res.expires_in.toString());
        localStorage.setItem('rkTokenExpiryDate', new Date(Date.now() + (res.expires_in * 1000)).toString())

        this.accessToken = res.access_token;
        this.refreshToken = res.refresh_token;
        this.authService.reckonAuth.emit(true);

        resolve(res);

        //    localStorage.setItem('txtReckonToken', user.txtToken);

      }, async (err: HttpErrorResponse) => {

        console.log("getReckonToken err: ", err.error.error);
        if (err.error.error == "invalid_grant" || err.error.error == "invalid_request") {
          //Go get new auth code.
          console.log("invalid Grant");
          //let msg: string = await this.alertService.openSnackBarCustomDefaultShowCancel("Reckon Login Problem - in get token", "There was a problem accessing reckon do you want to get a new token?", "Yes", "No");
          //if (msg == "Yes") {
          //  this.getAuthCode();
          //}

          this.getAuthCode();

        }

        //this.alertService.openSnackBarError("Error getting reckon token", "Close", "center", "bottom", 4000, false, "");
        resolve(null);
      });
    });
  }

  getReckonToken() {
    console.log("getReckonToken", new Date);

    let body = 'grant_type=authorization_code&code=' + this.authCode + '&redirect_uri=' + this.redirectURI;

    return this.http.post<ReckonBearerResponse>('https://identity.reckon.com/connect/token', body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + this.basicToken
      },
      responseType: 'json'
    });
  }

  async getReckonRefreshTokenPromise() {
    return new Promise<ReckonBearerResponse>(resolve => {
      this.getReckonRefreshToken().subscribe(res => {
        console.log(res);

        localStorage.setItem('rkAccessToken', res.access_token);
        localStorage.setItem('rkRefreshToken', res.refresh_token);
        localStorage.setItem("rkTokenExpiry", res.expires_in.toString());
        localStorage.setItem('rkTokenExpiryDate', new Date(Date.now() + (res.expires_in * 1000)).toString())

        this.accessToken = res.access_token;
        this.refreshToken = res.refresh_token;
        this.authService.reckonAuth.emit(true);

        resolve(res);

      }, async (err: HttpErrorResponse) => {

        if (err.error.error == "invalid_grant" || err.error.error == "invalid_request") {
          //Go get new auth code.
          console.log("getReckonRefreshToken err: ", err.error);
          //let msg: string = await this.alertService.openSnackBarCustomDefaultShowCancel("Reckon Login Problem - in get token refresh", "There was a problem accessing reckon do you want to get a new token?", "Yes", "No");
          //if (msg == "Yes") {
          //  this.getAuthCode();
          //}
          this.getAuthCode();

        }

        //this.alertService.openSnackBarError("Error getting reckon refresh token", "Close", "center", "bottom", 4000, false, "");

        resolve(null);
      });
    });
  }

  getReckonRefreshToken() {
    console.log("getReckonRefreshToken", new Date);

    let body = 'grant_type=refresh_token&refresh_token=' + this.refreshToken + '&redirect_uri=' + this.redirectURI;

    return this.http.post<ReckonBearerResponse>('https://identity.reckon.com/connect/token', body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + this.basicToken
      },
      responseType: 'json'
    });
  }

  getUserFiles() {


    // + this.accessToken
    return new Promise<boolean>(resolve => {
      //let header = new HttpHeaders({ 'Authorization': 'Bearer ' + this.accessToken, 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': this.subKey });

      

      this.http.get('https://api.reckon.com/RAH/v2/userfiles', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken,
          'Ocp-Apim-Subscription-Key': this.subKey
        }, responseType: 'json'
      }
      ).subscribe(res => {
        console.log("res", res);
        resolve(true);
      }, err => {
        console.log("err", err);
        resolve(false);
      });
    });
  }


  getAuthCode() {

    localStorage.setItem("authCode", "");

    let codeURL: string = "https://identity.reckon.com/connect/authorize?client_id=" + this.clientId + "&response_type=code&scope=openid+read+write+offline_access&redirect_uri=" + this.redirectURI + "&state=random_state&nonce=random_nonce&hidenav=yes"
    location.href = codeURL;

  }


  getTokenWithxmlReq() {
    var xmlReq = new XMLHttpRequest();
    // add callback handlers
    xmlReq.addEventListener("load", this.onLoad);

    let body = 'grant_type=authorization_code&code=5af00808ba2a95b7e37b1452686c71c7&redirect_uri=http://localhost';

    xmlReq.open("POST", "https://identity.reckon.com/connect/token");
    xmlReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xmlReq.setRequestHeader("Authorization", "Basic YWI5YjBhYTgtNWRhMS00NTM0LTk2Y2EtMzMwZjY5MmVlZDk4OmU1NDM2M2I5LTJjZDktNGY0Ni1hMjk0LTNiZTI4OTg2N2I5YQ==");

    xmlReq.onload = function (e) {
      if (xmlReq.status != 200) {
        console.log('Error', e)
        return
      }
      var data = JSON.parse(xmlReq.response)
      var html = '<ol>'

      console.log(data);

      //data.forEach(function (dt) {
      //  html += '<li style="font-size:22px;"><a href="' + dt.path + '">' + dt.title + '</a></li>'
      //})

      html += '</ol>'
      document.getElementById('resDiv').innerHTML = html
    }


    xmlReq.onerror = function () {
      console.log('Request failed')
    }
    // send request to server
    xmlReq.send(body);

  }

  onLoad(e) {
  console.log("this.response", e);
}



  getUserFilesWithxmlReq() {
    var xmlReq = new XMLHttpRequest();
    // add callback handlers
    xmlReq.addEventListener("load", this.onLoad);

    xmlReq.open("GET", "https://api.reckon.com/RAH/v2/userfiles");
    xmlReq.setRequestHeader("Content-Type", "application/json");
    xmlReq.setRequestHeader("Authorization", "Bearer " + this.accessToken);
    xmlReq.setRequestHeader("Ocp-Apim-Subscription-Key", this.subKey);
    xmlReq.responseType = "json";

    xmlReq.onload = function (e) {
      if (xmlReq.status != 200) {
        console.log('Error', e)
        return
      }
      console.log("xmlReq.response", xmlReq.response)
      var data = JSON.parse(xmlReq.response)
      var html = '<ol>'

      console.log(data);

      //data.forEach(function (dt) {
      //  html += '<li style="font-size:22px;"><a href="' + dt.path + '">' + dt.title + '</a></li>'
      //})

      html += '</ol>'
      document.getElementById('resDiv').innerHTML = html
    }


    xmlReq.onerror = function () {
      console.log('Request failed')
    }
    // send request to server
    console.log("xmlReq: ", xmlReq);

    xmlReq.send();

  }









  getReckonImplicitToken() {
    console.log("getReckonImplicitToken", new Date);
    //https://identity.reckon.com/connect/authorize?client_id={clientId}&response_type=token+token&scope=read+write&redirect_uri={redirectURI}

    return this.http.get('https://identity.reckon.com/connect/authorize?client_id=ab9b0aa8-5da1-4534-96ca-330f692eed98&response_type=token+token&scope=read+write&redirect_uri=http://localhost');
  }

//  {
//  "id_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6InE1UTVPcFRZVVM0eHA4cXVUbVVac1c0WkVqUSIsImtpZCI6InE1UTVPcFRZVVM0eHA4cXVUbVVac1c0WkVqUSJ9.eyJub25jZSI6InJhbmRvbV9ub25jZSIsImlhdCI6MTcxNTE0MTc5OSwic3ViIjoiMTU4NzU3OS0yIiwibmFtZSI6IjE1ODc1NzktMiIsImFtciI6InBhc3N3b3JkIiwiYXV0aF90aW1lIjoiMTcxNTE0MTU3OSIsImlkcCI6Imlkc3J2IiwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eS5yZWNrb24uY29tLmF1IiwiYXVkIjoiYWI5YjBhYTgtNWRhMS00NTM0LTk2Y2EtMzMwZjY5MmVlZDk4IiwiZXhwIjoxNzE1MTQ1Mzk5LCJuYmYiOjE3MTUxNDE3OTl9.meKVhSMjZxqGGCBfLYZJ2mZ7vF1_X_2lVBXM67ccnpqpB7cdV8jzt8YnoDoKGQQIrHLS7LrptvzVQQWoiVkrUAi-9nyVqqysGZgb1x91C3oucnX88nV6sZYLYZ5Qpg44Zmf4bWKgIEig7_88Lt7jZVemG8HtWjLG3_wVPJbTAMacGyzi-HOyMbssK1dYQ0gHxF9nW8pqVnqnc1AhsN-vQawnO6eEIKBixYidOKYPVOhAchJPNuVhTp39xNluZxy2UKWzq_5q77CD9eCnbBwpqp7yoejx8jastG_Y9cxM3HabaPp0OR9tvb4vLwITX9d7MUNZNFy2dEjY7Hj00YEK9Q",
//    "access_token": "b0277a1f9253137bae346757154089c1",
//      "expires_in": 10800,
//        "token_type": "Bearer",
//          "refresh_token": "904f775bf72f01e6ff8527a3b62f78d8"
//}

}


