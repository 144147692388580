import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DurabilityClass } from '../business-objects/durability-class.bo';

@Injectable()
export class DurabilityClassService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getGetProductCodeDurabilityClass(gProductTypeCategoryId: string, gProductTypeId: string, gProductProfileId: string, iLogSection: number, iWidth: number, iThickness: number, gSpeciesId: string) {
    return this.http.get<DurabilityClass[]>(this._baseUrl + 'api/DurabilityClass/GetProductCodeDurabilityClasses/' + gProductTypeCategoryId + '/' + gProductTypeId + '/' + iLogSection + '/' + iWidth + '/' + iThickness + '/' + gSpeciesId + '/' + gProductProfileId);
  }

  getDurabilityClass(gDurabilityClassId: string) {
    return this.http.get<DurabilityClass>(this._baseUrl + 'api/DurabilityClass/GetDurabilityClass/' + gDurabilityClassId);
  }


}
