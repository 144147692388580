export interface Moulder
{
	blnActive: boolean;
	dteLastUpdated: Date;
	fltSetupRate: number;
	intSection: number;
	rowguid: string;
	txtDescription: string;
	txtName: string;
}

export class Moulder
{
	blnActive: boolean;
	dteLastUpdated: Date;
	fltSetupRate: number;
	intSection: number;
	rowguid: string;
	txtDescription: string;
	txtName: string;
}

