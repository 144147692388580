<h1>Product Code Barcodes</h1>
<form class="stock-transfer-create-form" [formGroup]="form">
  <div class="header-row-4">
    <mat-form-field class="example-full-width example-container" appearance="fill">
      <mat-label>Identifier</mat-label>
      <input class="stock-identifier-input" matInput placeholder="" value="" formControlName="txtIdentifier">
      <mat-error *ngIf="false">
      </mat-error>
    </mat-form-field>
    <button class="stocktakeButton" mat-flat-button color="primary" (click)="printProductCodeBarcode()">Print Product Code Barcode</button>&nbsp;
  </div>
</form>
