import { Component, Input, OnInit } from '@angular/core';
import { dtoStockItem } from '../../_shared/business-objects/dto-stock-item.bo';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-bp-floor-order-item-stock-item-list',
  templateUrl: './bp-floor-order-item-stock-item-list.component.html',
  styleUrls: ['./bp-floor-order-item-stock-item-list.component.css']
})
export class BpFloorOrderItemStockItemListComponent implements OnInit {

  @Input() data: dtoStockItem[];

  displayedStockItems: string[] = ['intIdentifier', 'txtProductName', 'txtProfileName', 'intWidth', 'intThickness', 'txtSpeciesName', 'txtGradeName', 'fltQuantity', 'txtQuantityUoM', 'fltLength', 'fltDia', 'txtLocation', 'dteDateCreated', 'txtStockKeepingUnit', 'chkPrintSticker'];

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
  }

  printStocker(boardTallyRecordHistory: dtoStockItem, index: number) {

    let path: string = this.sharedService.LocalServerURI;
    if (location.hostname.indexOf('localhost') == -1) {
      path = 'https://' + location.hostname
    }

    var windowUrl = path + '/order-item-barcode-sticker?stockcontainerid=' + boardTallyRecordHistory.guStockContainerId + '&hidenav=yes';
    var uniqueName = new Date();
    var windowName = "_blank";
    var printWindow = window.open(windowUrl, windowName, 'left=400,top=200,width=1000,height=650');

  }

}
