<div class="grade-select-container" #gradeContainer>
  <mat-form-field class="chh-form-field example-full-width">
    <mat-label>Search Grades</mat-label>
    <input matInput data-grade-input="gradeInput" class="grade-input" placeholder="Filter by name" placeholderLabel="i.e. Essential Energy....." value="" type="text" #gradeFilter (keyup)="filterGrades(gradeFilter.value, $event)" (focus)="gradeFilter.select()" (click)="checkGradeListIsOpen(gradeFilter.value)">
  </mat-form-field>

  <mat-icon matTooltip="Click to add a Grade" class="clickable-icon person-add" (click)="addGrade()">person_add</mat-icon>
  <span><mat-slide-toggle #multipleGrades (click)="checkMultipleGrades(multipleGrades.checked)">Multiple Grades</mat-slide-toggle></span>
  <div class="selected-grades-container">
    <div class="selected-grades" id="selectedGrades" *ngIf="selectedGrades != null && selectedGrades.length > 0">
      <div class="chip-column">
        <mat-chip-listbox #chipGrid aria-label="Grade selection">
          @for (selectProduct of selectedGrades; track selectProduct; let selProdIdx = $index; let lastItem = $last) {
          <mat-chip-option (removed)="removeGrade(selectProduct)">
            {{selectProduct.txtName}}
            <button matChipRemove [attr.aria-label]="'remove ' + selectProduct.txtName">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
          }
        </mat-chip-listbox>
      </div>
      <div class="see-more-chips">
        <button mat-icon-button aria-label="expand row" (click)="expandSelectedGrades($event)">
          <mat-icon class="expand-icons grade-selection-expand-icon material-icons-outlined" *ngIf="gradeSelectionExpanded == false ">keyboard_arrow_down</mat-icon>
          <mat-icon class="expand-icons grade-selection-expand-icon material-icons-outlined" *ngIf="gradeSelectionExpanded == true">keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div data-grade-select="gradeSelect" class="grade-container" *ngIf="showGradeList" #gradeListContainer>
    <div data-grade-select="gradeSelect" id="gradeSelect" class="grade-select-list">
      <div class="grade-pin-row">
        <div class="button" data-push-pin="pushPin" id="btnPushPin" (click)="clickPin($event)" [ngClass]="gradeListPinned == true ? 'button-pressed' : 'button'" matTooltip="{{toolTipText}}">
          <mat-icon data-push-pin="pushPin" class="pin-icon">push_pin</mat-icon>
        </div>
      </div>
      <div class="grade-heading-row grade-name-heading">Name</div>
      <div class="grade-heading-row grade-abbreviation-heading">Abbreviation</div>
      <div class="grade-heading-row grade-update-heading"></div>
      @for(grade of gradesFiltered; track grade; let idx = $index){
      <div data-grade-select="gradeSelect" tabindex="{{idx}}" id="gradeName{{idx}}" (keyup)="nextGrade($event, grade)" class="chh-list-item-row chh-list-item-first-column grade-name" [ngClass]="grade.guSelectedGradeId != '' ? 'chh-list-item-row chh-list-item-row-selected grade-first-name' : addressHoverId == grade.rowguid ? 'chh-list-item-row chh-list-item-row-hover grade-first-name' : 'chh-list-item-row grade-first-name'" (mouseenter)="rowEnter(grade.rowguid)" (mouseleave)="rowLeave()" (click)="selectGrade(grade, !(grade.guSelectedGradeId != ''))"><div data-grade-select="gradeSelect" class="grade-text">{{grade.txtName}}</div></div>
      <div data-grade-select="gradeSelect" id="gradeName{{idx}}" class="chh-list-item-row grade-name" [ngClass]="grade.guSelectedGradeId != '' ? 'chh-list-item-row chh-list-item-row-selected grade-first-name' : addressHoverId == grade.rowguid ? 'chh-list-item-row chh-list-item-row-hover grade-abbreviation' : 'chh-list-item-row grade-abbreviation'" (mouseenter)="rowEnter(grade.rowguid)" (mouseleave)="rowLeave()" (click)="selectGrade(grade, !(grade.guSelectedGradeId != ''))"><div data-grade-select="gradeSelect" class="grade-abbreviation">{{grade.txtAbbreviation}}</div></div>
      <div data-grade-select="gradeSelect" id="gradeUpdateDiv{{idx}}" class="chh-list-item-row grade-radio grade-update-div" [ngClass]="grade.guSelectedGradeId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == grade.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(grade.rowguid)" (mouseleave)="rowLeave()" (click)="selectGrade(grade, !(grade.guSelectedGradeId != ''))">
        <div data-grade-select="gradeSelect" id="gradeUpdateButtonDiv{{idx}}" class="update-grade-button">
          <button mat-button (click)="updateGrade(grade)" matTooltip="Click to edit Grade">
            <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
          </button>
        </div>
      </div>

      }
    </div>

  </div>

</div>
