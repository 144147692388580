export interface PoleTrimmingItem
{
	blnFinishedPoleStockUpdated: boolean;
	blnOriginalPoleStockUpdated: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	fltFinishedDiameter: number;
	fltFinishedLength: number;
	fltM3: number;
	fltOriginalDiameter: number;
	fltOriginalLength: number;
	guEmployeeEnteredId: string;
	guEmployeeInspectedId: string;
	guFinalPoleId: string;
	guFinishedProductTypeId: string;
	guOriginalPoleId: string;
	guOriginalProductTypeId: string;
	guPoleInspectionItemId: string;
	guSpeciesId: string;
	intConsecutiveTrimminglNo: number;
	intFinishedDiameterType: number;
	intOriginalDiameterType: number;
	rowguid: string;
	txtComments: string;
	txtFinishedLengthKNSpec: string;
	txtFinishedProductType: string;
	txtOriginalLengthKNSpec: string;
	txtOriginalProductType: string;
	txtPoleNumber: string;
	txtSpeciesCode: string;
}

export class PoleTrimmingItem
{
	blnFinishedPoleStockUpdated: boolean;
	blnOriginalPoleStockUpdated: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	fltFinishedDiameter: number;
	fltFinishedLength: number;
	fltM3: number;
	fltOriginalDiameter: number;
	fltOriginalLength: number;
	guEmployeeEnteredId: string;
	guEmployeeInspectedId: string;
	guFinalPoleId: string;
	guFinishedProductTypeId: string;
	guOriginalPoleId: string;
	guOriginalProductTypeId: string;
	guPoleInspectionItemId: string;
	guSpeciesId: string;
	intConsecutiveTrimminglNo: number;
	intFinishedDiameterType: number;
	intOriginalDiameterType: number;
	rowguid: string;
	txtComments: string;
	txtFinishedLengthKNSpec: string;
	txtFinishedProductType: string;
	txtOriginalLengthKNSpec: string;
	txtOriginalProductType: string;
	txtPoleNumber: string;
	txtSpeciesCode: string;
}

