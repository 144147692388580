<div class="app-user-column-menu">
  <div class="app-user-column-sets">
    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group app-user-column-set-radio" fxLayout="column" [(ngModel)]="chosenItem">
      <mat-radio-button class="example-radio-button view-radio-buttons" *ngFor="let appUserColumnSet of appUserColumnSets" [value]="appUserColumnSet.rowguid" name="guUserColumnSetId" (click)="saveColumnSet(appUserColumnSet.rowguid)">
        {{appUserColumnSet.txtName}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="user-columns-reorder-container" id="{{columnListId}}">
    <div class="user-column-list-icon-container">
      <mat-icon class="user-column-list-icon" (click)="openColumnTable(table)">list</mat-icon>
      <mat-icon class="user-column-list-add-icon" (click)="openSaveView()">library_add</mat-icon>
      <mat-icon class="user-column-list-delete-icon" (click)="removeColumnSet()">delete</mat-icon>
      <div class="save-view-container" *ngIf="showSaveView" id="saveView">
        <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
          <mat-label>View</mat-label>
          <input class="textbox-input" id="myView" matInput placeholder="i.e. My View" value="" type="text" (keyup.enter)="saveView(myview.value)" #myview>
        </mat-form-field>
        <mat-icon class="user-column-list-add-icon" (click)="saveView(myview.value)">save</mat-icon>
        <mat-icon (click)="showSaveView=false">close</mat-icon>
      </div>
    </div>
    <div class="user-columns-container" cdkDropList
         [cdkDropListData]="appUserColumns"
         (cdkDropListDropped)="dropTable($event)" [ngClass]="showColumnTable ? 'reorder-table' : 'reorder-table-visible'" *ngIf="showColumnTable">

      @for(appUserColumn of appUserColumns; track appUserColumn){
        <div class="user-columns-row" cdkDrag [cdkDragData]="appUserColumn">
          <div class="reorder-icon">
            <mat-icon style="pointer-events: all;" cdkDragHandle>reorder</mat-icon>
          </div>
          <div class="column-name">
            {{appUserColumn.txtColumnNameAlias}}
          </div>
          <div class="visible-column">
            <div style="pointer-events: all;">
              <mat-checkbox class="example-margin" [checked]="appUserColumn.blnVisible == true" #visibleCheckbox (change)="saveColumn2(appUserColumn, visibleCheckbox.checked)"></mat-checkbox>
            </div>
          </div>
        </div>
      }

    </div>
    <table mat-table #table [dataSource]="appUserColumns" id="user-column-order-table" style="table-layout: fixed;width:500px;" class="mat-elevation-z8 reorder-table chh-app-user-colums"
           cdkDropList
           [cdkDropListData]="appUserColumns"
           (cdkDropListDropped)="dropTable($event)" *ngIf="false" [ngClass]="showColumnTable ? 'reorder-table' : 'reorder-table-visible'">

      <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
      <!-- Position Column -->
      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef class="reorder-icon-th" style="width:75px;"> No. </th>
        <td mat-cell *matCellDef="let appUserColumn" class="reorder-icon-td" style="width:75px;">
          <mat-icon style="pointer-events: all;"
                    cdkDragHandle>reorder</mat-icon>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="column-name-th" style="width:150px;"> Name </th>
        <td mat-cell *matCellDef="let appUserColumn" class="column-name-td" style="width:150px;"> {{appUserColumn.txtColumnNameAlias}}</td>
      </ng-container>

      <!-- Visible Column -->
      <ng-container matColumnDef="visible">
        <th mat-header-cell *matHeaderCellDef class="visible-th" style="width:50px;"> Visible </th>
        <td mat-cell *matCellDef="let appUserColumn" class="visible-td" style="width:50px;">
          <div style="pointer-events: all;">
            <mat-checkbox class="example-margin" [checked]="appUserColumn.blnVisible == true" #visibleCheckbox (change)="saveColumn2(appUserColumn, visibleCheckbox.checked)"></mat-checkbox>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          style="pointer-events: none;"
          cdkDrag [cdkDragData]="row"></tr>
    </table>
  </div>
</div>
