import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AlertService } from '../../../_shared/services/alert.service';
import { FormService } from '../../../_shared/services/form.service';
import { RoyaltyRateService } from '../../../_shared/services/royalty-rate.service';
import { dtoRoyaltyRateService } from '../../../_shared/services/dto-royalty-rate.service';
import { SharedService } from '../../../_shared/services/shared.service';
import { RoyaltyRate } from '../../../_shared/business-objects/royalty-rate.bo';
import { SQLParamArray } from '../../../_shared/business-objects/sql-param-array';

@Component({
  selector: 'app-royalty-rate-utility',
  templateUrl: './royalty-rate-utility.component.html',
  styleUrls: ['./royalty-rate-utility.component.css']
})
export class RoyaltyRateUtilityComponent {

  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private formService: FormService
    , private dtoRoyaltyRateService: dtoRoyaltyRateService, private royaltyRateService: RoyaltyRateService) {


  }


  async duplicateRoyaltyRates() {

    let msg: string = await this.alertService.openSnackBarCustomPromise("Duplicate Royalty Rates", "Are you sure you want to duplicate and inactivate the currenty royalty rates?", "Yes", "No", "center", "bottom");

    if (msg != "Yes") {
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("blnCurrent", "1"));
    aParamList.push(aParam);

    let royaltyRates: RoyaltyRate[] = await this.royaltyRateService.getRoyaltyRateParamArrayPromise(aParamList);

    if (royaltyRates == null || royaltyRates.length == 0) {
      this.alertService.openSnackBarDefault("There are no royalty rates to duplicate");
      return;
    }

    let royaltyRate: RoyaltyRate;
    for (let i = 0; i <= royaltyRates.length - 1; i++) {


      royaltyRate = new RoyaltyRate();
      royaltyRate.blnCurrent = true;
      royaltyRate.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
      royaltyRate.dteDateExpires = new Date((new Date().getFullYear() + 1).toString() + "-10-01");
      royaltyRate.fltCHHRoyaltyRate = royaltyRates[i].fltCHHRoyaltyRate;
      royaltyRate.fltForestryRoyaltyRate = royaltyRates[i].fltForestryRoyaltyRate;
      royaltyRate.guPoleId = royaltyRates[i].guPoleId;
      royaltyRate.intDurabilityClass = royaltyRates[i].intDurabilityClass;

      royaltyRates[i].blnCurrent = false;
      royaltyRates[i].txtComments = "Rate deactivated on " + new Date().getFullYear() + "-" + (new Date().getMonth()+1) + "-" + new Date().getDate()
      await this.royaltyRateService.updateRoyaltyRatePromise(royaltyRates[i]);

      await this.royaltyRateService.createRoyaltyRatePromise(royaltyRate);

      console.log("royalty rate created");

    }

    this.alertService.openSnackBarDefault("The current royalty rates have been duplicated and made inactive.");

  }


}
