import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatSelect } from '@angular/material/select';
import { dtoMachineRun } from '../../_shared/business-objects/dto-machine-run.bo';
import { MachineRun } from '../../_shared/business-objects/machine-run.bo';
import { Moulder } from '../../_shared/business-objects/moulder.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { dtoMachineRunService } from '../../_shared/services/dto-machine-run.service';
import { MachineRunService } from '../../_shared/services/machine-run.service';
import { MoulderService } from '../../_shared/services/moulder.service';
import { SharedService } from '../../_shared/services/shared.service';
import { BpFloorOrderItemListComponent } from '../bp-floor-order-item-list/bp-floor-order-item-list.component';

@Component({
  selector: 'app-bp-floor-machine-run-dialog',
  templateUrl: './bp-floor-machine-run-dialog.component.html',
  styleUrls: ['./bp-floor-machine-run-dialog.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class BpFloorMachineRunDialogComponent implements OnInit {

  @ViewChild('guMachineRunId') machineRunSelect: MatSelectionList;
  moulder: Moulder;
  machineRuns: dtoMachineRun[];
  machineRunsSelected: dtoMachineRun[] = [];
  moulders: Moulder[];
  selectedMoulders: Moulder[] = [];
  showSpinner: boolean;

  constructor(private dialogRef: MatDialogRef<BpFloorOrderItemListComponent>, private dtoMachineRunService: dtoMachineRunService
    , private moulderService: MoulderService, private sharedService: SharedService, @Inject(MAT_DIALOG_DATA) private data: { selectedMachineRuns: dtoMachineRun[], selectedMoulders: Moulder[] }) {

    if (data.selectedMachineRuns != null) {
      this.machineRunsSelected = data.selectedMachineRuns;
    }

    if (data.selectedMoulders != null) {
      this.selectedMoulders = data.selectedMoulders;
    }

    //this.moulder = data.moulder;

    //console.log("data.moulder", data.moulder);

  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close({ status: "Cancel", finalInspectionItem: null });
  }

  ngOnInit(): void {

    this.getMoulders();
    //this.getMachineRuns("");

  }


  async getMachineRuns(moulderId: string) {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (moulderId != null && moulderId != "") {
      aParam.push(new SQLParamArray("guMoulderId", moulderId));
      aParamList.push(aParam);
    }

    console.log("about to get machine run");

    this.machineRuns = await this.dtoMachineRunService.getdtoMachineRunParamArrayTopPromise(aParamList, 300);
    this.machineRuns.sort((a, b) => { return b.intRunNo - a.intRunNo });

    if (this.machineRunSelect != null) {
      console.log("machineRunSelect.length", this.machineRunSelect.options.length);
      setTimeout(function (oThis) {
        oThis.machineRunSelect.options.forEach(o => {
          console.log("option" + o.value);
          for (let i = 0; i <= oThis.machineRunsSelected.length - 1; i++) {
            if (o.value.rowguid == oThis.machineRunsSelected[i].rowguid) {
             console.log("option" + o.value.rowguid + ",  arr value: " + oThis.machineRunsSelected[i].rowguid);
              o.selected = true;
              break;
            }
          }
        });
      }, 1000, this);
    }

  }

  async getMoulders() {
    this.moulders = await this.moulderService.getAllMouldersPromise();
    this.moulders.sort((a, b) => { return a.txtName >= b.txtName ? 1 : -1 });
    let moulder: Moulder = new Moulder();
    moulder.txtName = "";
    moulder.rowguid = this.sharedService.EmptyGuid;
    this.moulders.splice(0, 0, moulder);
  }

  machineRunSelected(machineRun: dtoMachineRun, moulderId: string) {

    for (let i = this.machineRunsSelected.length - 1; i >= 0; i--) {
      if (this.machineRunsSelected[i].guMoulderId == moulderId) {
        this.machineRunsSelected.splice(i, 1);
      }
    }

    this.machineRunSelect.selectedOptions.selected.forEach(a => {

      let hasMachineRun: dtoMachineRun = this.machineRunsSelected.find(mr => { return mr.rowguid == a.value.rowguid });
      if (hasMachineRun == null) {

        this.machineRunsSelected.push(a.value);
      }

    });

    let selectedMoulder: Moulder = this.selectedMoulders.find(m => { return m.rowguid == moulderId });
    let moulder: Moulder = this.moulders.find(m => { return m.rowguid == moulderId });
    if (selectedMoulder == null) {
      this.selectedMoulders.push(moulder);
    }

    let gotMoulder: boolean = false;
    for (let i = 0; i <= this.machineRunsSelected.length - 1; i++) {
      if (this.machineRunsSelected[i].guMoulderId == moulderId) {
        gotMoulder = true;
        break;
      }
    }

    if (gotMoulder == false) {
      // No more machine runs selected for this moulder so remove this moulder from the selected moulders array.
      for (let i = this.selectedMoulders.length - 1; i >= 0; i--) {
        if (this.selectedMoulders[i].rowguid == moulderId) {
          console.log("removing moulder: ", this.selectedMoulders[i]);
          this.selectedMoulders.splice(i, 1);
        }
      }
    }

    console.log("selectedMoulders: ", this.selectedMoulders);

  }


  selectMachineRuns() {

    this.dialogRef.close({ status: "Selected", machineRuns: this.machineRunsSelected, moulders: this.selectedMoulders });


    //this.dialogRef.close({ status: "Selected", machineRun: machineRun });


  }

  machineRunCancelled(cancelled: boolean) {

    this.dialogRef.close({ status: "Cancel", machineRun: null });


  }

  cancelMachineRun(machineRun: dtoMachineRun, index: number) {

    this.machineRunsSelected.splice(index, 1);

  }

}
