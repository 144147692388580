<div class="final-inspection-item-list-container">
  <app-app-user-column-set-bar [componentColumns]="displayedfinalInspectionItems" [pageName]="'Final Inspection Items'" [container]="'finalInspectionsContainer'" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)" *ngIf="mode != 'print'"></app-app-user-column-set-bar>

  <table mat-table #tblfinalInspectionItems [dataSource]="datasource" class="mat-elevation-z8 final-inspection-table" [ngClass]="mode == 'print' ? 'final-inspection-table-print' : 'final-inspection-table'" matSort>

    <ng-container matColumnDef="intItemNo">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalItemNumber"> {{finalInspectionItem.intItemNo}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemTD footerTD"></td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerName">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Customer </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalInspectionItemtxtCustomerName"> {{finalInspectionItem.txtCustomerName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtOrderNo">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Order No </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalInspectionItemtxtOrderNo"> {{finalInspectionItem.txtOrderNo}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtPoleNumber">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Pole<br />Number </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD poleNumber"> {{finalInspectionItem.txtPoleNumber}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtConsecutiveNumber">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Consec<br />Number </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalConsecutiveNumber"> {{finalInspectionItem.txtConsecutiveNumber}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtChargeId">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Charge<br />Number </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalChargeNo"> {{finalInspectionItem.txtChargeId}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtChargeDate">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Treat<br />Month / Year</th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalTreatmentMonthYear"> {{finalInspectionItem.intTreatmentMonth}} {{finalInspectionItem.intTreatmentYear != null ? '/' : ''}} {{finalInspectionItem.intTreatmentYear}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtOriginalPole">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Original Pole </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalOriginalPole"> {{finalInspectionItem.txtOriginalPole}} </td>      <td mat-footer-cell *matFooterCellDef></td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtFinalPole">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Final Pole </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalFinalPole"> {{finalInspectionItem.txtFinalPole}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtDrilled">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Drilled </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalDrilled"> {{finalInspectionItem.txtDrilled}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtSpec">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Spec </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalSpec"> {{finalInspectionItem.txtElectricalAuthoritySpec}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltLength">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH finalLengthTH" mat-sort-header> Length </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalLength"> {{finalInspectionItem.fltLength}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltGLDia">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Dia </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalGLDia"> {{finalInspectionItem.fltDiameter}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtProductName">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Product<br />Name </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalProductName"> {{finalInspectionItem.txtProductName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtSpeciesName">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH finalSpeciesNameTH" mat-sort-header> Species </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalSpeciesName"> {{finalInspectionItem.txtSpeciesCodeAlias != null && finalInspectionItem.txtSpeciesCodeAlias != "" ? finalInspectionItem.txtSpeciesCodeAlias : finalInspectionItem.txtSpeciesCode}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltGirth">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Girth </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalGirth"> {{finalInspectionItem.fltGirth}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtNotes">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Notes </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalNotes"> {{finalInspectionItem.txtComments}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltActualM3">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight finalActualM3TH" [ngClass]="mode == 'print' ? 'finalInspectionItemTH finalActualM3TH-print alignRight-print' : 'finalInspectionItemTH finalActualM3TH alignRight'" mat-sort-header> Actual<br />M3 </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalActualM3 alignRight" [ngClass]="mode == 'print' ? 'finalInspectionItemTD finalActualM3-print alignRight-print' : 'finalInspectionItemTD finalActualM3 alignRight'"> {{finalInspectionItem.fltActualM3}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemTD totalM3Footer alignRight" [ngClass]="mode == 'print' ? 'finalInspectionItemTD totalM3Footer-print alignRight-print' : 'finalInspectionItemTD totalM3Footer alignRight'">{{totalM3}}</td>
    </ng-container>

    <ng-container matColumnDef="fltActualTonne">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight finalActualTonneTH" [ngClass]="mode == 'print' ? 'finalInspectionItemTH finalActualTonneTH-print alignRight-print' : 'finalInspectionItemTH finalActualTonneTH alignRight'" mat-sort-header> Actual<br />Tonne </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalActualTonne alignRight" [ngClass]="mode == 'print' ? 'finalInspectionItemTD finalActualTonne-print alignRight-print' : 'finalInspectionItemTD finalActualTonne alignRight'"> {{finalInspectionItem.fltActualTonne}} </td>
      <td mat-footer-cell *matFooterCellDef class="finalInspectionItemTD totalTonneFooter alignRight">{{totalTonne}}</td>
    </ng-container>

    <ng-container matColumnDef="intDeliveryDocketNo">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH alignRight" mat-sort-header> Delivery Docket No </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD intDeliveryDocketNo alignRight"> {{finalInspectionItem.intDocketNo}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="blnRejected">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Rejected </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD rejected"> {{finalInspectionItem.blnRejected ? 'Yes' : 'No'}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtRejectedComments">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH" mat-sort-header> Rejected Comments </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD rejectedComments"> {{finalInspectionItem.txtRejectedComments}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="btnEditfinalInspectionItem">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH finalInspectionItemEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalInspectionItemEdit">
        <button class="edit-final-inspection-item-button" type="button" mat-flat-button color="primary" (click)="editFinalInspectionItem(finalInspectionItem)" [disabled]="finalInspectionItem.guOrderItemId == null">Edit</button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="btnRemovefinalInspectionItem">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH finalInspectionItemEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalInspectionItemRemove">
        <button class="remove-final-inspection-item-button" type="button" mat-flat-button color="primary" (click)="removeFinalInspectionItem(finalInspectionItem)" [disabled]="finalInspectionItem.guOrderItemId == null">Remove</button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="btnTransferfinalInspectionItem">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH finalInspectionItemTransferTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalInspectionItemTransfer">
        <div matTooltip="To transfer this final item it must be first unlinked from the delivery docket {{finalInspectionItem.intDocketNo}}" [matTooltipDisabled]="finalInspectionItem.guDeliveryDocketOrderItemId == null">
          <button class="transfer-final-inspection-item-button" type="button" mat-flat-button color="primary" (click)="transferFinalInspectionItem(finalInspectionItem)" [disabled]="finalInspectionItem.guDeliveryDocketOrderItemId != null">Transfer</button>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="btnPrintPoleBarcode">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH finalInspectionItemPrintBarcodeTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD printPoleBarcode">
        <button class="print-barcode-final-inspection-item-button" type="button" mat-flat-button color="primary" (click)="printPoleTag(finalInspectionItem)">Print Barcode</button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="btnCreateDiscCSV">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH finalInspectionCreateDiscCSVTH">  </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalInspectionItemCreateDiscCSV">
        <button class="create-disc-csv-button" type="button" mat-flat-button color="primary" (click)="createDiscCSV(finalInspectionItem)">Create Disc CSV</button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="blnSelectCSVDisc">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionItemTH finalInspectionSelectDiscCSVTH">  </th>
      <td mat-cell *matCellDef="let finalInspectionItem" class="finalInspectionItemTD finalInspectionItemSelectDiscCSV">
        <mat-checkbox #blnSelectedCSVDisc class="example-margin" [value]="finalInspectionItem.blnSelectCSVDisc" (click)="toggleCreateCSVTickbox(blnSelectedCSVDisc, finalInspectionItem)"></mat-checkbox>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedfinalInspectionItems"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedfinalInspectionItems;let finalInspectionItem;" (click)="setRowColor(finalInspectionItem.rowguid)" [ngClass]="{'onDeliveryDocket' : finalInspectionItem.guDeliveryDocketOrderItemId != null, 'rejectedItem' : finalInspectionItem.blnRejected, 'activeTR' : finalInspectionItem.rowguid == selectedRow }">
    </tr>
    <tr mat-footer-row class="final-inspection-footer-row" *matFooterRowDef="displayedfinalInspectionItems; sticky: false"></tr>
  </table>
  <div class="no-stock-items" *ngIf="datasource == null"><h7>There are no final inspections</h7></div>
</div>


