import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { AppPageColumn } from '../business-objects/app-page-column.bo';

@Injectable()
export class AppPageColumnService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getAppPageColumn(appPageColumnId: string) {
		return this.http.get<AppPageColumn>(this._baseUrl + 'api/AppPageColumn/' + appPageColumnId);
	}

	getAppPageColumnParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<AppPageColumn[]>(this._baseUrl + 'api/AppPageColumn/GetAppPageColumnsParamArray/', aParam);
	}

	getAllAppPageColumns() {
		return this.http.get<AppPageColumn[]>(this._baseUrl + 'api/AppPageColumn/GetAllAppPageColumns/');
	}

	//getAlldtoAppPageColumns() {
	//	return this.http.get<dtoAppPageColumn[]>(this._baseUrl + 'api/AppPageColumn/GetAllAppPageColumns/');
	//}

	createAppPageColumn(appPageColumn: AppPageColumn) {
		return this.http.post<AppPageColumn>(this._baseUrl + 'api/AppPageColumn/CreateAppPageColumn/', appPageColumn);
	}

    removeAppPageColumn(appPageColumn: AppPageColumn) {
      return this.http.delete<AppPageColumn>(this._baseUrl + 'api/AppPageColumn/DeleteAppPageColumn/' + appPageColumn.rowguid);
    }

  updateAppPageColumn(appPageColumn: AppPageColumn) {
		return this.http.put<AppPageColumn>(this._baseUrl + 'api/AppPageColumn/UpdateAppPageColumn/', appPageColumn);
	}

	async getAppPageColumnPromise(appPageColumn) {
		return new Promise<AppPageColumn>(resolve => {
			this.getAppPageColumn(appPageColumn).subscribe(appPageColumn => {
				resolve(appPageColumn);
			}, err => {
				this.alertService.openSnackBarError("Error getting AppPageColumn records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getAppPageColumnParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<AppPageColumn[]>(resolve => {
			this.getAppPageColumnParamArray(aParam).subscribe(appPageColumns => {
				resolve(appPageColumns);
			}, err => {
				this.alertService.openSnackBarError("Error getting AppPageColumn records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async updateAppPageColumnPromise(appPageColumn: AppPageColumn) {
		return new Promise<AppPageColumn>(resolve => {
			this.updateAppPageColumn(appPageColumn).subscribe(appPageColumn => {
				resolve(appPageColumn);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating AppPageColumn record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createAppPageColumnPromise(appPageColumn) {
		return new Promise<AppPageColumn>(resolve => {
			this.createAppPageColumn(appPageColumn).subscribe(appPageColumn => {
				resolve(appPageColumn);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating AppPageColumn record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

  async removeAppPageColumnPromise(appPageColumn) {
    return new Promise<AppPageColumn>(resolve => {
      this.removeAppPageColumn(appPageColumn).subscribe(appPageColumn => {
        resolve(appPageColumn);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error removing AppPageColumn record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }
}
