export interface DeliveryDocket
{
	binSignature: string;
	blnEmailedInvoice: boolean;
	blnNextDelivery: boolean;
	blnUploadedInvoiceToPortal: boolean;
	dteCreatedDate: Date;
	dteDeliveryDate: Date;
	dteExpectedDeliveryDate: Date;
	dteExpectedLoadingDate: Date;
	dteLastUpdated: Date;
	dteLoaded: Date;
	dteStatusDate: Date;
	fltAdditionalKM: number;
	fltDeliveryCharge: number;
	fltEscort: number;
	guCustomerContactId: string;
	guCustomerId: string;
	guDriverId: string;
	guEmployeeCreatedId: string;
	guGeoStockLocationId: string;
	guHaulingId: string;
	guStockTransferDelAddressId: string;
	guTPPoleInspectionId: string;
	intDeliveryType: number;
	intDocketNo: number;
	intInvoicePortalUploaded: number;
	intInvoiceStatus: number;
	intLoadNo: number;
	intMaxLoads: number;
	intPaymentType: number;
	intStatus: number;
	intSystem: number;
	rowguid: string;
	txtComments: string;
	txtInvoiceNo: string;
}

export class DeliveryDocket
{
	binSignature: string;
	blnEmailedInvoice: boolean;
	blnNextDelivery: boolean;
	blnUploadedInvoiceToPortal: boolean;
	dteCreatedDate: Date;
	dteDeliveryDate: Date;
	dteExpectedDeliveryDate: Date;
	dteExpectedLoadingDate: Date;
	dteLastUpdated: Date;
	dteLoaded: Date;
	dteStatusDate: Date;
	fltAdditionalKM: number;
	fltDeliveryCharge: number;
	fltEscort: number;
	guCustomerContactId: string;
	guCustomerId: string;
	guDriverId: string;
	guEmployeeCreatedId: string;
	guGeoStockLocationId: string;
	guHaulingId: string;
	guStockTransferDelAddressId: string;
	guTPPoleInspectionId: string;
	intDeliveryType: number;
	intDocketNo: number;
	intInvoicePortalUploaded: number;
	intInvoiceStatus: number;
	intLoadNo: number;
	intMaxLoads: number;
	intPaymentType: number;
	intStatus: number;
	intSystem: number;
	rowguid: string;
	txtComments: string;
	txtInvoiceNo: string;
}

export namespace DeliveryDocket
{
	export enum enDeliveryStatus
	{
		ReadyForDispatch,
		Dispatched,
		Delivered,
		PickedUp
	}
}

export namespace DeliveryDocket
{
	export enum enDeliveryType
	{
		Delivery,
		StockTransfer
	}
}

export namespace DeliveryDocket
{
	export enum enInvoicePortalUploaded
	{
		NA,
		ToBeUploaded,
		Uploaded
	}
}

export namespace DeliveryDocket
{
	export enum enInvoiceStatus
	{
		NA,
		ToBeEmailed,
		Emailed
	}
}

