import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductCodePriceChangeReason } from '../../_shared/business-objects/product-code-price-change-reason.bo';

@Injectable()
export class PriceChangeReasonService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getAllPriceChangeReasons() {
    return this.http.get<ProductCodePriceChangeReason>(this._baseUrl + 'api/ProductCodePriceChangeReason');
  }

  getPriceChangeByReasonType(type: ProductCodePriceChangeReason.enReasonType, transactionType: ProductCodePriceChangeReason.enTransactionType) {
    return this.http.get<ProductCodePriceChangeReason[]>(this._baseUrl + 'api/ProductCodePriceChangeReason/GetReasonsByType/' + parseInt(type.toString()) + '/' + parseInt(transactionType.toString()));
  }

  getPriceChangeReason(gPriceChangeReasonId: string) {
    return this.http.get<ProductCodePriceChangeReason>(this._baseUrl + 'api/ProductCodePriceChangeReason/' + gPriceChangeReasonId);
  }
}
