import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dtoOrderItem } from '../../../_shared/business-objects/dto-order-item.bo';
import { SQLParamArray } from '../../../_shared/business-objects/sql-param-array';
import { dtoOrderItemService } from '../../../_shared/services/dto-order-item.service';

@Component({
  selector: 'app-quote-item-view',
  templateUrl: './quote-item-view.component.html',
  styleUrl: './quote-item-view.component.scss'
})
export class QuoteItemViewComponent {

  @Input() quoteIdInput: string;

  quoteId: string = "";
  quoteItems: dtoOrderItem[];
  quoteTotals: { deliveryDocketId: string, totalItems: number, totalQtyEach: number, totalQtyLM: number, totalQtyM2: number, totalQtyBoxes: number, totalM3: number, totalTonne: number, multipleUoM: boolean }[] = [];
  showStrengthGroups: boolean = false;
  showStrengthGroup3: boolean = false;
  screenView: boolean = false;

  totalM3: number;
  totalTonne: number;
  totalQtyBoxes: number;
  totalQtyM2: number;
  totalQtyLM: number;
  totalQtyEach: number;

  uomMultiples: boolean;

  constructor(private dtoOrderItemService: dtoOrderItemService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    if (this.quoteIdInput != null && this.quoteIdInput != "") {
      this.quoteId = this.quoteIdInput;
    }

    this.loadData();
  }

  setQuoteId(id: string) {
    this.quoteId = id;
  }

  async loadData() {

    this.uomMultiples = false;

    //console.log("app view - before: " + this.quoteId);

    this.route.queryParams.subscribe(async params => {

      if (params != null) {

        // ==========================================================================================
        // The delivery docket can be supplied from the @Input so it will override the param value.
        // ==========================================================================================
        if (this.quoteIdInput == null || this.quoteIdInput == "") {
          this.quoteId = params["quoteid"];
        }

        let screenView: string = String(params["screenview"]);
        this.screenView = screenView.toLocaleLowerCase() == "yes";

      }
    });

    //console.log("app view - after: " + this.quoteId);

    if (this.quoteId == null) {
      return;
    }

    console.log("quoteId: - ", this.quoteId);


    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guOrderId", this.quoteId));
    aParamList.push(aParam);

    this.quoteItems = await this.dtoOrderItemService.getdtoOrderItemParamArrayPromise(aParamList);
    this.quoteItems.sort((a, b) => { return (a.intItemNo - b.intItemNo) });

    console.log("quote items: - ", this.quoteItems);

    this.totalM3 = 0;
    this.totalTonne = 0;

    for (let i = 0; i <= this.quoteItems.length - 1; i++) {
      this.totalM3 += this.quoteItems[i].fltM3;
      this.totalTonne += this.quoteItems[i].fltTonne;
      
      this.totalM3 = Number(this.totalM3.toFixed(2));
      this.totalTonne = Number(this.totalTonne.toFixed(2));
    }

    this.totalQtyBoxes = this.sumQuantity("M2", true);
    this.totalQtyM2 = this.sumQuantity("M2", false);
    this.totalQtyLM = this.sumQuantity("LM", false);
    this.totalQtyEach = this.sumQuantity("Each", false);

    this.uomMultiples = (this.totalQtyBoxes > 0 && (this.totalQtyM2 > 0 || this.totalQtyLM > 0 || this.totalQtyEach > 0));

    if (this.uomMultiples == false) {
      this.uomMultiples = (this.totalQtyM2 > 0 && (this.totalQtyBoxes > 0 || this.totalQtyLM > 0 || this.totalQtyEach > 0));
    }

    if (this.uomMultiples == false) {
      this.uomMultiples = (this.totalQtyLM > 0 && (this.totalQtyBoxes > 0 || this.totalQtyM2 > 0 || this.totalQtyEach > 0));
    }

    if (this.uomMultiples == false) {
      this.uomMultiples = (this.totalQtyEach > 0 && (this.totalQtyBoxes > 0 || this.totalQtyM2 > 0 || this.totalQtyLM > 0));
    }


    //let arr = this.quoteItems.filter((d, index) => { return this.quoteItems.indexOf(d) === index });
    //const arrUniqDelDockets = [...new Map(this.quoteItems.map(d => [d.guDeliveryDocketId, d])).values()];

    //let deliveryDocketIds: string[] = arrUniqDelDockets.map(d => d.guDeliveryDocketId);

    //console.log("deliveryDocketIds", deliveryDocketIds);

    //this.calcDeliveryDocketTotals(deliveryDocketIds);

    //let grandTotalQtyEach: number = 0;
    //let grandTotalQtyLM: number = 0;
    //let grandTotalQtyM2: number = 0;
    //let grandTotalQtyBoxes: number = 0;
    //let grandTtoalM3: number = 0;
    //let grandTtoalTonne: number = 0;

    //this.showStrengthGroups = false;
    //this.showStrengthGroup3 = false;

    //let deliveryDocketId: string = "";

    //for (let i = 0; i <= this.deliveryDocketOrderItems.length - 1; i++) {

    //  if (this.deliveryDocketOrderItems[i].intS1 != null || this.deliveryDocketOrderItems[i].intS2 != null || this.deliveryDocketOrderItems[i].intBBT != null) {
    //    this.showStrengthGroups = true;
    //  }

    //  if (this.deliveryDocketOrderItems[i].intS3 != null) {
    //    this.showStrengthGroup3 = true;
    //  }

    //  this.deliveryDocketOrderItems[i]['totalQtyEach'] = this.getDeliveryDocketQtyEachTotal(this.deliveryDocketOrderItems[i].guDeliveryDocketId);
    //  this.deliveryDocketOrderItems[i]['totalQtyLM'] = this.getDeliveryDocketQtyLMTotal(this.deliveryDocketOrderItems[i].guDeliveryDocketId);
    //  this.deliveryDocketOrderItems[i]['totalQtyM2'] = this.getDeliveryDocketQtyM2Total(this.deliveryDocketOrderItems[i].guDeliveryDocketId);
    //  this.deliveryDocketOrderItems[i]['totalQtyBoxes'] = this.getDeliveryDocketQtyBoxesTotal(this.deliveryDocketOrderItems[i].guDeliveryDocketId);
    //  this.deliveryDocketOrderItems[i]['totalM3'] = this.getDeliveryDocketM3Total(this.deliveryDocketOrderItems[i].guDeliveryDocketId);
    //  this.deliveryDocketOrderItems[i]['totalTonne'] = this.getDeliveryDocketTonneTotal(this.deliveryDocketOrderItems[i].guDeliveryDocketId);
    //  this.deliveryDocketOrderItems[i]['uomMultiples'] = this.getDeliveryDocketUoMMultiples(this.deliveryDocketOrderItems[i].guDeliveryDocketId);


      //grandTtoalM3 += parseFloat(this.deliveryDocketOrderItems[i].fltActualM3.toFixed(2));
      //grandTtoalTonne += parseFloat(this.deliveryDocketOrderItems[i].fltActualTonne.toFixed(2));

   //};

    //deliveryDocketIds.forEach(d => {
    //  grandTotalQtyEach += parseFloat(this.getDeliveryDocketQtyEachTotal(d).toFixed(2));
    //  grandTotalQtyLM += parseFloat(this.getDeliveryDocketQtyLMTotal(d).toFixed(2));
    //  grandTotalQtyM2 += parseFloat(this.getDeliveryDocketQtyM2Total(d).toFixed(2));
    //  grandTotalQtyBoxes += parseFloat(this.getDeliveryDocketQtyBoxesTotal(d).toFixed(2));

    //  console.log("grandTotalQtyEach: " + grandTotalQtyEach);
    //  console.log("grandTotalQtyLM: " + grandTotalQtyLM);
    //  console.log("grandTotalQtyM2: " + grandTotalQtyM2);
    //  console.log("grandTotalQtyBoxes: " + grandTotalQtyBoxes);
    //});

    //for (let i = 0; i <= this.deliveryDocketOrderItems.length - 1; i++) {
    //  this.deliveryDocketOrderItems[i]['grandTotalQtyEach'] = parseFloat(grandTotalQtyEach.toFixed(2));
    //  this.deliveryDocketOrderItems[i]['grandTotalQtyLM'] = parseFloat(grandTotalQtyLM.toFixed(2));
    //  this.deliveryDocketOrderItems[i]['grandTotalQtyM2'] = parseFloat(grandTotalQtyM2.toFixed(2));
    //  this.deliveryDocketOrderItems[i]['grandTotalQtyBoxes'] = parseFloat(grandTotalQtyBoxes.toFixed(2));
    //  this.deliveryDocketOrderItems[i]['grandTotalM3'] = parseFloat(grandTtoalM3.toFixed(2));
    //  this.deliveryDocketOrderItems[i]['grandTotalTonne'] = parseFloat(grandTtoalTonne.toFixed(2));
    //};


    //console.log(this.deliveryDocketOrderItems);


  }

  sumQuantity(type: string, isParquetry: boolean) {

    let quoteItems: dtoOrderItem[]

    if (isParquetry == false) {
      quoteItems = this.quoteItems.filter((a) => a.txtQuantityUoM == type);
    }

    if (isParquetry == true) {
      quoteItems = this.quoteItems.filter((a) => a.txtQuantityUoM == type && a.intNoOfParquetryBoxes > 0);
    }

    let fltQty: number = 0;
    for (let i = 0; i <= quoteItems.length - 1; i++) {

      if (isParquetry == false) {
          fltQty += parseFloat(quoteItems[i].fltQuantityTotal.toFixed(2));
      } else {
        fltQty += parseFloat(quoteItems[i].intNoOfParquetryBoxes.toFixed(2))
      }
    }

    return parseFloat(fltQty.toFixed(2));
    //return d.fltBoardTallyRecordQuantity != null && d.fltBoardTallyRecordQuantity > 0 ? d.fltBoardTallyRecordQuantity : d.fltQuantity

  }


  //calcDeliveryDocketTotals(deliveryDocketIds: string[]) {

  //  //console.log(this.deliveryDocketOrderItems.reduce((n, { fltActualM3 }) => n + fltActualM3, 0));

  //  deliveryDocketIds.forEach(d => {

  //    this.deliveryDocketTotals.push({
  //      deliveryDocketId: d,
  //      totalItems: 0,
  //      totalQtyEach: this.sumQuantity(d, 'Each', false), // this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == d && a.txtOrderItemQuantityUom == 'Each').reduce((n, { fltQuantity }) => parseFloat((n + fltQuantity).toFixed(2)), 0),
  //      totalQtyLM: this.sumQuantity(d, 'LM', false),  // this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == d && a.txtOrderItemQuantityUom == 'LM').reduce((n, { fltQuantity }) => parseFloat((n + fltQuantity).toFixed(2)), 0)
  //      totalQtyM2: this.sumQuantity(d, 'M2', false),  // this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == d && a.txtOrderItemQuantityUom == 'M2').reduce((n, { fltQuantity }) => parseFloat((n + fltQuantity).toFixed(2)), 0),
  //      totalQtyBoxes: this.sumQuantity(d, 'M2', true),  //this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == d && a.txtOrderItemQuantityUom == 'M2' && a.intNoOfParquetryBoxes > 0).reduce((n, { intNoOfParquetryBoxes }) => parseInt((n + intNoOfParquetryBoxes).toString()), 0),
  //      totalM3: this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == d).reduce((n, { fltActualM3 }) => parseFloat((n + fltActualM3).toFixed(2)), 0),
  //      totalTonne: this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == d).reduce((n, { fltActualTonne }) => parseFloat((n + fltActualTonne).toFixed(2)), 0),
  //      multipleUoM: false
  //    })
  //  });

  //  this.deliveryDocketTotals.forEach(dt => {
  //    let multipleUoM: number = 0;
  //    if (dt.totalQtyEach > 0) {
  //      multipleUoM += 1
  //    }

  //    if (dt.totalQtyLM > 0) {
  //      multipleUoM += 1
  //    }

  //    if (dt.totalQtyM2 > 0) {
  //      multipleUoM += 1
  //    }

  //    if (dt.totalQtyBoxes > 0) {
  //      multipleUoM += 1
  //    }

  //    if (multipleUoM > 1) {
  //      dt.multipleUoM = true;
  //    }

  //  });

  //  //let sum: number = this.deliveryDocketOrderItems.filter((a) => a.guDeliveryDocketId == deliveryDocketId).reduce((n, { fltActualM3 }) => n + fltActualM3, 0);
  //  //console.log(sum);

  //  //console.log("this.deliveryDocketTotals:", this.deliveryDocketTotals);


  //}

  //getDeliveryDocketM3Total(deliveryDocketId: string) {
  //  for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
  //    if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
  //      //console.log("this.deliveryDocketTotals[i].totalM3: " + this.deliveryDocketTotals[i].totalM3);
  //      return this.deliveryDocketTotals[i].totalM3;
  //    }
  //  }
  //}

  //getDeliveryDocketTonneTotal(deliveryDocketId: string) {
  //  for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
  //    if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
  //      //console.log("this.deliveryDocketTotals[i].totalM3: " + this.deliveryDocketTotals[i].totalM3);
  //      return this.deliveryDocketTotals[i].totalTonne;
  //    }
  //  }
  //}

  //getDeliveryDocketQtyEachTotal(deliveryDocketId: string) {
  //  for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
  //    if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
  //      //console.log("this.deliveryDocketTotals[i].totalQtyEach: " + this.deliveryDocketTotals[i].totalQtyEach);
  //      return this.deliveryDocketTotals[i].totalQtyEach;
  //    }
  //  }
  //}

  //getDeliveryDocketQtyLMTotal(deliveryDocketId: string) {
  //  for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
  //    if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
  //      //console.log("this.deliveryDocketTotals[i].totalQtyLM: " + this.deliveryDocketTotals[i].totalQtyLM);
  //      return this.deliveryDocketTotals[i].totalQtyLM;
  //    }
  //  }
  //}

  //getDeliveryDocketQtyM2Total(deliveryDocketId: string) {
  //  for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
  //    if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
  //      //console.log("this.deliveryDocketTotals[i].totalQtyM2: " + this.deliveryDocketTotals[i].totalQtyM2);
  //      return this.deliveryDocketTotals[i].totalQtyM2;
  //    }
  //  }
  //}

  //getDeliveryDocketQtyBoxesTotal(deliveryDocketId: string) {
  //  for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
  //    if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
  //      //console.log("this.deliveryDocketTotals[i].totalQtyBoxes: " + this.deliveryDocketTotals[i].totalQtyBoxes);
  //      return this.deliveryDocketTotals[i].totalQtyBoxes;
  //    }
  //  }
  //}

  //getDeliveryDocketUoMMultiples(deliveryDocketId: string) {
  //  for (let i = 0; i <= this.deliveryDocketTotals.length - 1; i++) {
  //    if (this.deliveryDocketTotals[i].deliveryDocketId == deliveryDocketId) {
  //      //console.log("this.deliveryDocketTotals[i].totalQtyBoxes: " + this.deliveryDocketTotals[i].totalQtyBoxes);
  //      return this.deliveryDocketTotals[i].multipleUoM;
  //    }
  //  }
  //}
}
