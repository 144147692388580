<div class="customer-contact-create-container">
  <div class="customer-contact-create-form" [formGroup]="form">

    <div class="customer-contact-customer-container">
      <mat-form-field class="chh-form-field example-full-width customer-name-form-field" appearance="fill">
        <mat-label>Customer</mat-label>
        <input class="textbox-input" readonly matInput value="{{customerName}}" type="text" formControlName="txtCustomerName">
      </mat-form-field>
    </div>

    <div class="customer-contact-name-container">
      <mat-form-field class="chh-form-field example-full-width" appearance="fill">
        <mat-label>First Name</mat-label>
        <input class="textbox-input" matInput placeholder="i.e. John..." value="" type="text" id="txtFirstName" formControlName="txtFirstName">
      </mat-form-field>
      &nbsp;
      <mat-form-field class="chh-form-field example-full-width" appearance="fill">
        <mat-label>Last Name</mat-label>
        <input class="textbox-input" matInput placeholder="i.e. Smith..." value="" type="text" formControlName="txtLastName">
      </mat-form-field>
    </div>

    <div class="customer-contact-address1-container">
      <mat-form-field class="chh-form-field example-full-width address1-form-field" appearance="fill">
        <mat-label>Address 1</mat-label>
        <input class="textbox-input" matInput value="" type="text" formControlName="txtAddress1">
      </mat-form-field>
    </div>

    <div class="customer-contact-address2-container">
      <mat-form-field class="chh-form-field example-full-width address2-form-field" appearance="fill">
        <mat-label>Address 2</mat-label>
        <input class="textbox-input" matInput value="" type="text" formControlName="txtAddress2">
      </mat-form-field>
    </div>

    <div class="customer-contact-town-container">
      <mat-form-field class="chh-form-field example-full-width town-form-field" appearance="fill">
        <mat-label>Town</mat-label>
        <input class="textbox-input" matInput value="" type="text" #txtTown formControlName="txtTown">
      </mat-form-field>
    </div>

    <div class="customer-contact-state-container">
      <mat-selection-list class="chh-mat-selection-list state-select" #txtState [multiple]="false" id="txtState" formControlName="txtState">
        <mat-list-option *ngFor="let state of states" [value]="state.txtValue">
          {{state.txtValue}}
        </mat-list-option>
      </mat-selection-list>
    </div>


    <div class="customer-contact-post-code-container">
      <mat-form-field class="chh-form-field example-full-width post-code-form-field" appearance="fill">
        <mat-label>Postcode</mat-label>
        <input class="textbox-input" matInput value="" type="text" #txtPostcode formControlName="txtPostcode">
      </mat-form-field>
      <div class="lookup-post-code-button-column">
        <button class="post-code-button" type="button" mat-flat-button color="primary" (click)="lookupPostcode(txtTown.value, txtState._value[0])">Get Postcode</button>
      </div>

      <div class="multipostcode-container" *ngIf="postcodes != null">
        <mat-selection-list class="chh-mat-selection-list multipostcode-select" #txtMultiPostcode [multiple]="false" id="txtMultiPostcode" (selectionChange)="selectPostcode(txtMultiPostcode._value[0])">
          <mat-list-option *ngFor="let postcode of postcodes" [value]="postcode.postcode">
            {{postcode.name}} {{postcode.postcode}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>

    <div class="customer-contact-phone1-container">
      <mat-form-field class="chh-form-field example-full-width phone1-form-field" appearance="fill">
        <mat-label>Phone 1</mat-label>
        <input class="textbox-input" matInput value="" type="tel" formControlName="txtPhone1">
      </mat-form-field>
    </div>

    <div class="customer-contact-phone2-container">
      <mat-form-field class="chh-form-field example-full-width phone2-form-field" appearance="fill">
        <mat-label>Phone 2</mat-label>
        <input class="textbox-input" matInput value="" type="tel" formControlName="txtPhone2">
      </mat-form-field>
    </div>

    <div class="customer-contact-mobile-container">
      <mat-form-field class="chh-form-field example-full-width mobile-form-field" appearance="fill">
        <mat-label>Mobile</mat-label>
        <input class="textbox-input" matInput value="" type="tel" formControlName="txtMobile">
      </mat-form-field>
    </div>

    <div class="customer-contact-fax-container">
      <mat-form-field class="chh-form-field example-full-width fax-form-field" appearance="fill">
        <mat-label>Fax</mat-label>
        <input class="textbox-input" matInput value="" type="tel" formControlName="txtFax">
      </mat-form-field>
    </div>

    <div class="customer-contact-email-container">
      <mat-form-field class="chh-form-field example-full-width email-form-field" appearance="fill">
        <mat-label>Email</mat-label>
        <input class="textbox-input" matInput value="" type="email" formControlName="txtEmail">
      </mat-form-field>
    </div>

    <div class="customer-contact-active-container">
      <mat-checkbox class="example-margin" #blnActive id="blnActive" formControlName="blnActive">Active</mat-checkbox>
    </div>



  </div>

  <div class="action-button-container">
    <button class="action-button" mat-flat-button color="primary" (click)="createCustomerContact('create')">Create Customer Contact</button>
    <button class="action-button" mat-flat-button color="primary" (click)="createCustomerContact('createaddnew')">Create Customer Contact And Add New</button>
    <button class="action-button" mat-flat-button color="primary" (click)="cancelCustomerContact()">Cancel</button>
  </div>

</div>

<app-spinner *ngIf="showSpinner" [showSpinner]="showSpinner" class="customer-spinner"></app-spinner>
