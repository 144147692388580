import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dtoTPPoleInspectionItem } from '../../_shared/business-objects/dto-tppole-inspection-item.bo';
import { dtoTPPoleInspection } from '../../_shared/business-objects/dto-tppole-inspection.bo';
import { FinalInspectionItemListComponent } from '../final-inspection-item-list/final-inspection-item-list.component';

@Component({
  selector: 'app-final-inspection-item-transfer-dialog',
  templateUrl: './final-inspection-item-transfer-dialog.component.html',
  styleUrls: ['./final-inspection-item-transfer-dialog.component.css']
})
export class FinalInspectionItemTransferDialogComponent implements OnInit {

  finalInspectionItems: dtoTPPoleInspectionItem[];
  finalInspection: dtoTPPoleInspection;

  constructor(private dialogRef: MatDialogRef<FinalInspectionItemListComponent>, @Inject(MAT_DIALOG_DATA) private data: { finalInspectionItems: dtoTPPoleInspectionItem[], finalInspection: dtoTPPoleInspection }) {

    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close();
    });

    dialogRef.disableClose = true;

    this.finalInspectionItems = data.finalInspectionItems;
    this.finalInspection = data.finalInspection;

  }


  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close({ status: "Cancel" });
  }

  ngOnInit(): void {


  }

  finalInspectionItemTransferred(val: boolean) {
    this.dialogRef.close({ status: "Updated" });
  }

  finalInspectionItemTransferCancelled(val: boolean) {
    this.dialogRef.close({ status: "Cancel" });
  }

}
