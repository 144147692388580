import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { dtoSlobStockSummary } from "../business-objects/dto-slob-stock-summary.bo";
import { dtoStockItem } from "../business-objects/dto-stock-item.bo";
import { SQLParamArray } from "../business-objects/sql-param-array";
import { SharedService } from "./shared.service";


@Injectable()
export class GuestService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService) {
    this._baseUrl = baseUrl;
  }


  getSlobStockParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoStockItem[]>(this._baseUrl + 'api/guest/GetSlobStock/', aParam);
  }

  getSlobStockSummary() {
    return this.http.get<dtoSlobStockSummary[]>(this._baseUrl + 'api/guest/GetSlobStockSummary/');
  }

  async getSlobStockParamArrayPromise() {
    return new Promise<dtoStockItem[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("blnSlobStock", "1"));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("blnStockContainerArchived", "0"));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("blnArchive", "0"));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("txtCustomerName", "", SQLParamArray.enSQLOperator.IsNull));
      aParamList.push(aParam);

      this.getSlobStockParamArray(aParamList).subscribe(boardTallyRecords => {
        resolve(boardTallyRecords);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  async getSlobStockSummaryPromise() {
    return new Promise<dtoSlobStockSummary[]>(resolve => {

      this.getSlobStockSummary().subscribe(slobStockSummary => {
        resolve(slobStockSummary);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

}  
