import { Component } from '@angular/core';

@Component({
  selector: 'app-cca-charge-update',
  templateUrl: './cca-charge-update.component.html',
  styleUrl: './cca-charge-update.component.scss'
})
export class CcaChargeUpdateComponent {

}
