import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PoleTrimmingItem } from '../_shared/business-objects/pole-trimming-item.bo';
import { Router } from '@angular/router';

import { ErrorMessage } from '../_shared/ErrorMessage.class';
import { PoleTrimmingItemService } from '../_shared/services/pole-trimming-item.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
//import { SafeHtmlPipe } from '../../pipes/safeHTML.pipe'
//import { SafeHtml } from '@angular/platform-browser/safehtml';

@Component({
  selector: 'app-pole-trimming-item',
  templateUrl: './pole-trimming-item.component.html',
  styleUrls: ['./pole-trimming-item.component.css']
})
export class PoleTrimmingItemComponent implements OnInit {
  public poleTrimmingItems: any;
  form: FormGroup;
  formMode: string;
  showErrorMessage: boolean;
  poleStockTotal: PoleTrimmingItemTotal;
  errorMessage: ErrorMessage
  errorMessageString: string;
  displayedColumns: string[] = ['dteDateCreated', 'dteTimeCreated', 'txtPoleNumber', 'txtOriginalProductType', 'fltOriginalLength', 'origspace', 'intOriginalKN', 'fltOriginalDiameter', 'txtOriginalDiameterType', 'txtOriginalSpec', 'txtFinishedProductType', 'fltFinishedLength', 'finspace', 'intFinishedKN', 'fltFinishedDiameter', 'txtFinishedDiameterType', 'txtFinishedSpec', 'txtSpecies', 'btnShowDetail'];


  constructor(private fb: FormBuilder, private poleTrimmingItemService: PoleTrimmingItemService, private router: Router) {
    this.form = this.fb.group({
      dteDateCreatedFrom: [''],
      dteDateCreatedTo: [''],
      txtPoleNumber: [''],
      radMode: [1]
    });

    this.formMode = 'search';

    this.poleStockTotal = new PoleTrimmingItemTotal;
    this.poleStockTotal.intTotalItems = 0;
    this.showErrorMessage = false;
    this.errorMessageString = '';

    this.getTodaysList();
  }

  ngOnInit(): void {
  }

  onModeSelect() {
    //console.log("onModeSelect: " + this.form.value.radMode);
    if (this.form.value.radMode == 'enter') {
      this.formMode = 'enter';
      //console.log("onModeSelect-stocktake: " + this.form.value.radMode);
    }
    if (this.form.value.radMode == 'search') {
      this.formMode = 'search';
      //console.log("onModeSelect-search: " + this.form.value.radMode);
    }
  }

  search() {
    this.showErrorMessage = false;

    if (this.formMode != 'search') {
      window.alert('Please select search mode.');
      return false;
    }

    if (this.form.value.txtPoleNumber == '' && this.form.value.dteDateCreatedFrom == '' && this.form.value.dteDateCreatedTo == '') {
      window.alert('Please enter a pole number or dates to search on.');
      return false;
    }

    let poleTrim: PoleTrimmingItem;
    poleTrim = new PoleTrimmingItem();
    poleTrim.txtPoleNumber = this.form.value.txtPoleNumber;
    if (this.form.value.txtPoleNumber != '') {
      this.poleTrimmingItemService.getPoleTrimmingItemByPoleNumber(poleTrim.txtPoleNumber).subscribe(data => {
        //console.log(JSON.stringify(data));
        this.poleTrimmingItems = [];
        if (data != null) {
          let aPolTrimmingItems: PoleTrimmingItem[] = [];
          aPolTrimmingItems.push(data);
          this.poleTrimmingItems = aPolTrimmingItems;
        }
      }, err => {
        console.error("err: " + err.message);
      });
    }
    else {
      if (this.form.value.dteDateCreatedFrom != '' && this.form.value.dteDateCreatedTo != '') {

        const datepipe: DatePipe = new DatePipe('en-AU');
        let dateFrom: string = datepipe.transform(Date.now(), 'yyyy-MM-dd');
        let dateTo: string = datepipe.transform(Date.now(), 'yyyy-MM-dd');

        if (this.form.value.dteDateCreatedFrom != '') {
          dateFrom = datepipe.transform(new Date(this.form.value.dteDateCreatedFrom), 'yyyy-MM-dd');
        }
        if (this.form.value.dteDateCreatedTo != '') {
          dateTo = datepipe.transform(new Date(this.form.value.dteDateCreatedTo), 'yyyy-MM-dd');
        }
        this.poleTrimmingItemService.getPoleTrimmingItemsListByDates(dateFrom, dateTo).subscribe(data => {
          this.poleTrimmingItems = data;
          //console.log("data: " + data);
        }, err => {
          console.error("err: " + err.message);
        });
      } else {
        alert("Please select a from date and a to date");
      }
    }
  }


  addTrimmingItem() {
    this.showErrorMessage = false;
    if (this.formMode != 'enter') {
      window.alert('Please select enter mode.');
      return false;
    }

    if (this.form.value.txtPoleNumber == '') {
      window.alert('Please enter a pole number to search.');
      return false;
    }

    let poleTrim: PoleTrimmingItem;
    poleTrim = new PoleTrimmingItem();
    poleTrim.txtPoleNumber = this.form.value.txtPoleNumber;
    if (this.formMode == 'enter') {
        this.poleTrimmingItemService.setPoleTrimmingItem(poleTrim).subscribe(data => {
        this.showErrorMessage = false;
        this.getTodaysList();

          //console.log("data: " + data);

      }, (errResponse: HttpErrorResponse) => {
          this.errorMessage = new ErrorMessage();
          this.errorMessage.message = errResponse.error.message;
          
          //this.errorMessageString = this.safePipe.transform(errResponse.error.message, null);
          
          this.showErrorMessage = true;

          //console.error("message: " + errResponse.error.message);
          //console.error("err: " + JSON.stringify(errResponse));
      });
    }
    else {
      //this.searchTo50Stocktake()
    }
  }

  addNewTrimmingItem() {

  }


  showTrimmingItemDetail(gPoleTrimmingGuid: string) {
    this.router.navigate(["pole-trimming-item-update", gPoleTrimmingGuid])
  }

 
  getTodaysList() {
    this.form.controls['dteDateCreatedFrom'].setValue('');
    this.form.controls['dteDateCreatedTo'].setValue('');
    this.form.controls['txtPoleNumber'].setValue('');

    this.poleTrimmingItemService.getPoleTrimmingItemsTodaysList().subscribe(data => {
      this.showErrorMessage = false;
      //console.log("data: " + data);
      //console.log("data stringify: " + JSON.stringify(data));

      this.poleTrimmingItems = data;

    }, (errResponse: HttpErrorResponse) => {
        console.log(errResponse);

      this.errorMessage = new ErrorMessage();
      this.errorMessage.message = errResponse.error.message;

      this.showErrorMessage = true;

        //console.error("message: " + errResponse.error.message);
        //console.error("err: " + JSON.stringify(errResponse));
    });
  }


  getLast3Days() {
    this.form.controls['dteDateCreatedFrom'].setValue('');
    this.form.controls['dteDateCreatedTo'].setValue('');
    this.form.controls['txtPoleNumber'].setValue('');

    this.poleTrimmingItemService.getPoleTrimmingItemsLast3DaysList().subscribe(data => {
      this.showErrorMessage = false;
      //console.log("data: " + data);
      //console.log("data stringify: " + JSON.stringify(data));

      this.poleTrimmingItems = data;

    }, (errResponse: HttpErrorResponse) => {
      console.log(errResponse);

      this.errorMessage = new ErrorMessage();
      this.errorMessage.message = errResponse.error.message;

      this.showErrorMessage = true;

      //console.error("message: " + errResponse.error.message);
      //console.error("err: " + JSON.stringify(errResponse));
    });
  }

  getKNDivider(gPoleId) {
    if (gPoleId == "" || gPoleId == undefined) {
      return "";
    }
    return "/";
  }

}



export class PoleTrimmingItemTotal {
  intTotalItems: number;
}
