import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DiscTemplateConfig } from '../../_shared/business-objects/disc-template-config.bo';
import { dtoDiscTemplateConfig } from '../../_shared/business-objects/dto-disc-template-config.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { ElectricalAuthoritySpecService } from '../../_shared/services/electrical-authority-spec.service';
import { dtoDiscTemplateConfigService } from '../../_shared/services/dto-disc-template-config.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { DiscTemplateConfigService } from '../../_shared/services/disc-template-config.service';

@Component({
  selector: 'app-disc-template-config-list',
  templateUrl: './disc-template-config-list.component.html',
  styleUrls: ['./disc-template-config-list.component.css']
})
export class DiscTemplateConfigListComponent {

  @ViewChild('tblDiscTemplateConfig', { static: false }) tblDiscTemplateConfig: MatTable<dtoDiscTemplateConfig>;

  @Input() datasource: MatTableDataSource<dtoDiscTemplateConfig>;
  @Output() editDiscTemplateConfigEvent = new EventEmitter<dtoDiscTemplateConfig>();
  @Output() addDiscTemplateConfigEvent = new EventEmitter<DiscTemplateConfig>();
  @Output() addDiscTemplateConfigItemsEvent = new EventEmitter<dtoDiscTemplateConfig>();
  @Output() updatedDiscTemplateConfigEvent = new EventEmitter<dtoDiscTemplateConfig>();

  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() columnListId: string;

  form: FormGroup;

  loadedComponent: boolean = false;

  discTemplateConfig: DiscTemplateConfig;
  discTemplateConfigExpanded: dtoDiscTemplateConfig;

  selectedRow: string = "";


  displayedColumns: string[] = ['dteDateCreated', 'txtAuthorityName', 'intCSVColumnNumber', 'txtInputType', 'txtUserInputValue', 'txtComputerInputField', 'txtComments', 'blnActive', 'btnEditDiscTemplateConfig', 'btnRemoveDiscTemplateConfig'];


  constructor(private dtoDiscTemplateConfigService: dtoDiscTemplateConfigService, private discTemplateConfigService: DiscTemplateConfigService, private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private route: ActivatedRoute, private formService: FormService
    , private dialog: MatDialog, private electricalAuthoritySpecService: ElectricalAuthoritySpecService
  ) {

    if (this.columnBarPage == null || this.columnBarPage == "") {
      this.columnBarPage = "Disc Template Config";
    }

    if (this.columnBarContainer == null || this.columnBarContainer == "") {
      this.columnBarContainer = "discTemplateConfigContainer";
    }


  }

  ngOnInit(): void {

    if (this.columnListId == null) {
      this.columnListId = "columnListId";
    }

    this.loadedComponent = true;

    console.log("columnListId: ", document.getElementById(this.columnListId));

    //this.updatedComponentColumnsEvent
  }


  updatedComponentColumnsEvent(columns: string[]) {

    this.displayedColumns = columns;

  }

  onTabChange(e) {

  }


  async editDiscTemplateConfig(discTemplateConfig: dtoDiscTemplateConfig) {
    this.editDiscTemplateConfigEvent.emit(discTemplateConfig);

    this.rowColorPropagation(discTemplateConfig.rowguid);

  }

  async removeDiscTemplateConfig(discTemplateConfig: dtoDiscTemplateConfig, index: number) {
    let removed: boolean = await this.discTemplateConfigService.deleteDiscTemplateConfigPromise(discTemplateConfig.rowguid);
    if (removed == true) {
      this.datasource.data.splice(index, 1);
      this.tblDiscTemplateConfig.renderRows();

      this.alertService.openSnackBarDefault("Disc Template Config record has been removed");
    }

  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      window.event.stopPropagation();
    }
  }

  setRowColor(discTemplateConfigId: string) {

    //console.log("in set row color: " + discTemplateConfigId);

    if (this.selectedRow == "" || discTemplateConfigId != this.selectedRow) {
      this.selectedRow = discTemplateConfigId;
      return;
    }

    if (discTemplateConfigId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }

  }

}
