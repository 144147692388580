import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductTypeCategory } from '../../_shared/business-objects/product-type-category.bo';


@Injectable()
export class ProductTypeCategoryService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getProductTypeCategoryList(parentId) {
    return this.http.get<ProductTypeCategory[]>(this._baseUrl + 'api/producttypecategory/GetProductTypeCategoryList/' + parentId);
  }

  getProductTypeCategoryListWithParents(productTypeCategoryId) {
    return this.http.get<ProductTypeCategory[]>(this._baseUrl + 'api/producttypecategory/GetProductTypeCategoryListWithParents/' + productTypeCategoryId);
  }

  getProductTypeCategory(productTypeCategoryId) {
    return this.http.get<ProductTypeCategory>(this._baseUrl + 'api/producttypecategory/' + productTypeCategoryId);
  }
}  
