import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { dtoTPPoleInspectionItem } from '../../_shared/business-objects/dto-tppole-inspection-item.bo';

@Component({
  selector: 'app-delivery-docket-final-items-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './delivery-docket-final-items-list.component.html',
  styleUrls: ['./delivery-docket-final-items-list.component.css']
})
export class DeliveryDocketFinalItemsListComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoTPPoleInspectionItem>;
  @Input() columnListId: string = "ColumnList2";
  @Input() columnBarContainer: string;
  @Input() appIndex: number;
  @Output() unlinkFinalInspectionItemEvent = new EventEmitter<dtoTPPoleInspectionItem>();
  @Input() finalInspectionItemActualM3TotalInput: number;
  @Input() finalInspectionItemActualTonneTotalInput: number;


  @ViewChild('tblfinalInspectionItems') tblfinalInspectionItems: MatTable<dtoTPPoleInspectionItem>;
  @ViewChild(MatSort) sort: MatSort;

  form: FormGroup;

  //displayedfinalInspectionItems: string[] = ['intItemNo', 'intFinalNo', 'txtCustomerName', 'txtOrderNo', 'txtPoleNumber', 'txtConsecutiveNumber', 'txtChargeId', 'txtChargeDate', 'txtOriginalPole', 'txtFinalPole', 'txtDrilled', 'txtSpec', 'fltLength', 'fltGLDia', 'txtProductName', 'txtSpeciesName', 'fltGirth', 'fltActualM3', 'fltActualTonne', 'btnUnlink'];
  displayedfinalInspectionItems: string[] = ['intItemNo', 'intFinalNo', 'txtCustomerName', 'txtOrderNo', 'txtPoleNumber', 'txtConsecutiveNumber', 'txtChargeId', 'txtChargeDate', 'txtOriginalPole', 'txtFinalPole', 'txtDrilled', 'txtSpec', 'fltLength', 'fltGLDia', 'txtProductName', 'txtSpeciesName', 'fltGirth', 'fltActualM3', 'fltActualTonne'];

  deliveryDocketFinalItems: dtoTPPoleInspectionItem[];

  finalInspectionItemM3TotalInput: number;
  finalInspectionItemWeightTotalInput: number;

  loadedComponent: boolean = false;

  constructor(private fb: FormBuilder, private cdref: ChangeDetectorRef) {

    this.form = this.fb.group({
      //chkAddItem:[true]
    });

    if (this.columnBarContainer == null || this.columnBarContainer == "") {
      this.columnBarContainer = "deliveryDocketContainer";
    }


  }

  ngOnInit(): void {

    this.finalInspectionItemM3TotalInput = 10;
    this.finalInspectionItemWeightTotalInput = 11;
    this.finalInspectionItemActualM3TotalInput = 10;
    this.finalInspectionItemActualTonneTotalInput = 11;

    console.log("In ngOnInit, DeliveryDocketFinalItemsListComponent");

    if (this.columnListId == null) {
      this.columnListId = "columnListId";
    }

  }


  ngAfterViewInit() {

    //this.datasource.sort = this.sort;
  }

  updatedComponentColumnsEvent(columns: string[]) {
    this.displayedfinalInspectionItems = columns;

  }

  setDeliveryDocketFinalItems(finalItems: dtoTPPoleInspectionItem[]) {

    this.deliveryDocketFinalItems = finalItems;

  }

  setDeliveryDocketOrderItems(finalItems: dtoTPPoleInspectionItem[]) {



  }

  unlinkFinalItem(finalItem: dtoTPPoleInspectionItem) {
    this.unlinkFinalInspectionItemEvent.emit(finalItem);
  }

  setTotals(index: number) {

    //console.log("in set totals");

    this.finalInspectionItemM3TotalInput = 0;
    this.finalInspectionItemWeightTotalInput = 0;
    this.finalInspectionItemActualM3TotalInput = 0;
    this.finalInspectionItemActualTonneTotalInput = 0;

    let totalM3: number = 0;
    let totalTonne: number = 0;
    for (let i = 0; i <= this.datasource.data.length - 1; i++) {

      totalM3 += this.datasource.data[i].fltActualM3;
      totalTonne += this.datasource.data[i].fltActualTonne;

      //console.log("totalM3 " + totalM3);

    }

    this.finalInspectionItemM3TotalInput = totalM3;
    this.finalInspectionItemWeightTotalInput = totalTonne;

    this.finalInspectionItemActualM3TotalInput = this.finalInspectionItemM3TotalInput;
    this.finalInspectionItemActualTonneTotalInput = this.finalInspectionItemWeightTotalInput;

    if (document.getElementById("actualM3" + index) != null) {
      document.getElementById("actualM3" + index).innerHTML = this.finalInspectionItemM3TotalInput.toFixed(2).toString();
      document.getElementById("actualWeight" + index).innerHTML = this.finalInspectionItemWeightTotalInput.toFixed(2).toString();
    }

  }


}
