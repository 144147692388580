<div class="disc-template-config-list-container">
  <app-app-user-column-set-bar [componentColumns]="displayedColumns" [pageName]="columnBarPage" [container]="columnBarContainer" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)" *ngIf="loadedComponent"></app-app-user-column-set-bar>

  <table mat-table #tblDiscTemplateConfig [dataSource]="datasource" class="mat-elevation-z8 disc-template-config-list-table">

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="discTemplateConfigTH alignRight"> Date Created </th>
      <td mat-cell *matCellDef="let discTemplateConfig" class="discTemplateConfigTD dateCreated alignRight"> {{discTemplateConfig.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtAuthorityName">
      <th mat-header-cell *matHeaderCellDef class="discTemplateConfigTH"> Authority Name </th>
      <td mat-cell *matCellDef="let discTemplateConfig" class="discTemplateConfigTD authorityName">{{discTemplateConfig.txtAuthorityName}}</td>
    </ng-container>

    <ng-container matColumnDef="intCSVColumnNumber">
      <th mat-header-cell *matHeaderCellDef class="discTemplateConfigTH"> CSV Column Number </th>
      <td mat-cell *matCellDef="let discTemplateConfig" class="discTemplateConfigTD CSVColumnNumber">{{discTemplateConfig.intCSVColumnNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="txtInputType">
      <th mat-header-cell *matHeaderCellDef class="discTemplateConfigTH"> Input Type </th>
      <td mat-cell *matCellDef="let discTemplateConfig" class="discTemplateConfigTD inputType">{{discTemplateConfig.txtInputType}}</td>
    </ng-container>

    <ng-container matColumnDef="txtUserInputValue">
      <th mat-header-cell *matHeaderCellDef class="discTemplateConfigTH"> User Input Value </th>
      <td mat-cell *matCellDef="let discTemplateConfig" class="discTemplateConfigTD userInputValue">{{discTemplateConfig.txtUserInputValue}}</td>
    </ng-container>

    <ng-container matColumnDef="txtComputerInputField">
      <th mat-header-cell *matHeaderCellDef class="discTemplateConfigTH"> Computer Input Field </th>
      <td mat-cell *matCellDef="let discTemplateConfig" class="discTemplateConfigTD computerInputField">{{discTemplateConfig.txtComputerInputField}}</td>
    </ng-container>

    <ng-container matColumnDef="txtComments">
      <th mat-header-cell *matHeaderCellDef class="discTemplateConfigTH"> Comments </th>
      <td mat-cell *matCellDef="let discTemplateConfig" class="discTemplateConfigTD comments">{{discTemplateConfig.txtComments}}</td>
    </ng-container>

    <ng-container matColumnDef="blnActive">
      <th mat-header-cell *matHeaderCellDef class="discTemplateConfigTH"> Active </th>
      <td mat-cell *matCellDef="let discTemplateConfig" class="discTemplateConfigTD active">{{discTemplateConfig.blnActive ? 'Yes' : 'No'}}</td>
    </ng-container>

    <ng-container matColumnDef="btnEditDiscTemplateConfig">
      <th mat-header-cell *matHeaderCellDef class="discTemplateConfigTH"></th>
      <td mat-cell *matCellDef="let discTemplateConfig" class="discTemplateConfigTD"><button class="edit-disc-template-config-button" type="button" mat-flat-button color="primary" (click)="editDiscTemplateConfig(discTemplateConfig)">Edit</button></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveDiscTemplateConfig">
      <th mat-header-cell *matHeaderCellDef class="discTemplateConfigTH"></th>
      <td mat-cell *matCellDef="let discTemplateConfig; let idx = index;" class="discTemplateConfigTD"><button class="remove-disc-template-config-button" type="button" mat-flat-button color="primary" (click)="removeDiscTemplateConfig(discTemplateConfig, idx)">Remove</button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="disc-template-config-row-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let discTemplateConfig" (click)="setRowColor(discTemplateConfig.rowguid)" [ngClass]="discTemplateConfig.rowguid == selectedRow ? 'activeTR' : ''"></tr>

  </table>

</div>
