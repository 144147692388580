<div class="pos-sales-container" [formGroup]="form" *ngIf="screenMode == true">
  <div class="pos-sales-grid">

    <div class="pos-filter-options" *ngIf="screenMode == true">
      <div class="pos-filter-options-grid">
        <div class="report-type-selection-container">
          <mat-selection-list #chartReportTypeList  formControlName="chartReportTypeList" [multiple]="false" (selectionChange)="reportTypeChange(chartReportTypeList._value[0])">
            @for (chartReportType of chartReportTypes; track chartReportType) {
            <mat-list-option [value]="chartReportType.intValue" [selected]="chartReportType.intValue == enChartType.WeeklySales">{{chartReportType.txtValue}}</mat-list-option>
            }
          </mat-selection-list>

        </div>

        <div class="product-summary-report-type-selection-container">
          <mat-selection-list #productTypeSummaryReportTypeList  formControlName="productSummaryReportType" [multiple]="false">
            @for (productSummaryReportType of productSummaryReportTypes; track productSummaryReportType) {
            <mat-list-option [value]="productSummaryReportType.intValue" [selected]="productSummaryReportType.intValue == enSummaryType.LastWeek">{{productSummaryReportType.txtValue}}</mat-list-option>
            }
          </mat-selection-list>

        </div>

        <div class="date-selection-container">
          <mat-form-field appearance="fill">
            <mat-label>Search on Dispatched Date</mat-label>
            <mat-select class="chh-mat-selection date-select" #txtDateSelection [multiple]="false" id="txtDateSelection" formControlName="txtDateSelection" (selectionChange)="setSalesDate(txtDateSelection.value)">
              @for (dr of dateRange; track dr; let  i = $index) {
              <mat-option [value]="i">{{dr.text}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

        </div>

        <div class="date-from">
          <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
            <mat-label>Date From</mat-label>
            <input id="dateTo" class="date-input" #dateFrom matInput [matDatepicker]="datepickerfrom" formControlName="dteDateFrom">
            <mat-datepicker-toggle matSuffix [for]="datepickerfrom"></mat-datepicker-toggle>
            <mat-datepicker #datepickerfrom></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="date-to">
          <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
            <mat-label>Date To</mat-label>
            <input id="dateFrom" class="date-input" #dateTo matInput [matDatepicker]="datepickerto" formControlName="dteDateTo">
            <mat-datepicker-toggle matSuffix [for]="datepickerto"></mat-datepicker-toggle>
            <mat-datepicker #datepickerto></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="sale-location">
          <mat-form-field appearance="fill">
            <mat-label>Sale Location</mat-label>
            <mat-select formControlName="guSaleLocationId" (selectionChange)="setChartTitle()">
              <mat-option *ngFor="let saleLocation of saleLocations" [value]="saleLocation.rowguid">{{saleLocation.txtLocationName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="chart-width">
          Chart Width
          <mat-slider #chartWidthSlider min="100" max="2000" step="10" showTickMarks discrete (change)="setChartWidth($event)" [displayWith]="formatLabel">
            <input matSliderThumb value="1600">
          </mat-slider>
        </div>
        <div class="update-chart-button-container">
          <button class="update-chart-button" type="button" mat-flat-button color="primary" (click)="updateChart()">Update Chart</button>
          <button class="update-chart-button" type="button" mat-flat-button color="primary" (click)="downloadChart()">Download Chart</button>
          <button class="email-chart-button" type="button" mat-flat-button color="primary" (click)="testTasks()">Email Chart</button>
          <button class="email-chart-button" type="button" mat-flat-button color="primary" (click)="runSummary()">Run Summary</button>
          <button class="email-chart-button" type="button" mat-flat-button color="primary" (click)="viewSummary()">View Summary</button>
        </div>

      </div>
    </div>

    <div class="pos-chart">
      <app-chh-chart #posSalesChart *ngIf="screenMode == true && (taxInvoiceDailyTotals != null || taxInvoiceWeeklyTotals != null || taxInvoiceMonthlyTotals != null)" [chartDataSet]="chartDataSet" [chartLabel]="chartLabel" [chartType]="chartType" [chartLegend]="chartLegend" [chartOptions]="chartOptions" [canvasWidth]="chartWidth" [canvasHeight]="800" [chartContainerId]="'chartContainerId'"></app-chh-chart>
    </div>
  </div>
</div>

<app-chh-chart #posSalesChart *ngIf="screenMode == false" [chartDataSet]="chartDataSet" [chartLabel]="chartLabel" [chartType]="chartType" [chartLegend]="chartLegend" [chartOptions]="chartOptions" [canvasWidth]="chartWidth" [canvasHeight]="800" [chartContainerId]="'chartContainerId'" [hideChart]="true"></app-chh-chart>
<div style="visibility:hidden" id="chartBase64"></div>

<app-pos-product-summary class="pos-product-summary-app" *ngIf="productSummary != null" #productSummaryApp id="productSummaryApp" [taxInvoiceProductSummary]="productSummary" [dateRange]="posProductSummaryDateRange" [saleLocationName]="saleLocationName" [printMode]="false" (componentLoaded)="posProductSummaryLoaded()"></app-pos-product-summary>
<div class="pos-product-summary-canvas" id="productSummaryCanvas"></div>
