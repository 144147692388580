import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { Position } from '../business-objects/position.bo';

@Injectable()
export class PositionService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getPosition(positionId: string) {
    return this.http.get<Position>(this._baseUrl + 'api/Position/' + positionId);
  }

  getPositionParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<Position[]>(this._baseUrl + 'api/Position/GetPositionsParamArray/', aParam);
  }

  getAllPositions() {
    return this.http.get<Position[]>(this._baseUrl + 'api/Position/GetAllPositions/');
  }

  //getAlldtoPositions() {
  //	return this.http.get<dtoPosition[]>(this._baseUrl + 'api/Position/GetAllPositions/');
  //}

  createPosition(position: Position) {
    return this.http.post<Position>(this._baseUrl + 'api/Position/CreatePosition/', position);
  }

  updatePosition(position: Position) {
    return this.http.put<Position>(this._baseUrl + 'api/Position/UpdatePosition/', position);
  }

  async getPositionPromise(positionId: string) {
    return new Promise<Position>(resolve => {
      this.getPosition(positionId).subscribe(position => {
        resolve(position);
      }, err => {
        this.alertService.openSnackBarError("Error getting Position records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async getPositionParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<Position[]>(resolve => {
      this.getPositionParamArray(aParam).subscribe(positions => {
        resolve(positions);
      }, err => {
        this.alertService.openSnackBarError("Error getting Position records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async updatePositionPromise(position: Position) {
    return new Promise<Position>(resolve => {
      this.updatePosition(position).subscribe(position => {
        resolve(position);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating Position record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async createPositionPromise(position: Position) {
    return new Promise<Position>(resolve => {
      this.createPosition(position).subscribe(position => {
        resolve(position);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating Position record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

}
