import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoLoadingDocketItem } from '../business-objects/dto-loading-docket-item.bo';
import { SharedService } from './shared.service';

@Injectable()
export class dtoLoadingDocketItemService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoLoadingDocketItem(dtoLoadingDocketItemId: string) {
		return this.http.get<dtoLoadingDocketItem>(this._baseUrl + 'api/dtoLoadingDocketItem/' + dtoLoadingDocketItemId);
	}

	getdtoLoadingDocketItemParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoLoadingDocketItem[]>(this._baseUrl + 'api/dtoLoadingDocketItem/GetdtoLoadingDocketItemsParamArray/', aParam);
	}

	getAlldtoLoadingDocketItems() {
		return this.http.get<dtoLoadingDocketItem[]>(this._baseUrl + 'api/dtoLoadingDocketItem/GetAlldtoLoadingDocketItems/');
	}

	//getAlldtodtoLoadingDocketItems() {
	//	return this.http.get<dtodtoLoadingDocketItem[]>(this._baseUrl + 'api/dtoLoadingDocketItem/GetAlldtoLoadingDocketItems/');
	//}

	createdtoLoadingDocketItem(dtoLoadingDocketItem: dtoLoadingDocketItem) {
		return this.http.post<dtoLoadingDocketItem>(this._baseUrl + 'api/dtoLoadingDocketItem/CreatedtoLoadingDocketItem/', dtoLoadingDocketItem);
	}

	updatedtoLoadingDocketItem(dtoLoadingDocketItem: dtoLoadingDocketItem) {
		return this.http.put<dtoLoadingDocketItem>(this._baseUrl + 'api/dtoLoadingDocketItem/UpdatedtoLoadingDocketItem/', dtoLoadingDocketItem);
	}

	async getdtoLoadingDocketItemPromise(dtoLoadingDocketItemId: string) {
		return new Promise<dtoLoadingDocketItem>(resolve => {
			this.getdtoLoadingDocketItem(dtoLoadingDocketItemId).subscribe(dtoLoadingDocketItem => {
				resolve(dtoLoadingDocketItem);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoLoadingDocketItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoLoadingDocketItemParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoLoadingDocketItem[]>(resolve => {
			this.getdtoLoadingDocketItemParamArray(aParam).subscribe(dtoLoadingDocketItems => {
				resolve(dtoLoadingDocketItems);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoLoadingDocketItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoLoadingDocketItemPromise(dtoLoadingDocketItem: dtoLoadingDocketItem) {
		return new Promise<dtoLoadingDocketItem>(resolve => {
			this.updatedtoLoadingDocketItem(dtoLoadingDocketItem).subscribe(dtoLoadingDocketItem => {
				resolve(dtoLoadingDocketItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoLoadingDocketItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoLoadingDocketItemPromise(dtoLoadingDocketItem: dtoLoadingDocketItem) {
		return new Promise<dtoLoadingDocketItem>(resolve => {
			this.createdtoLoadingDocketItem(dtoLoadingDocketItem).subscribe(dtoLoadingDocketItem => {
				resolve(dtoLoadingDocketItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoLoadingDocketItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
  }



}
