import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { dtoLoadingDocket } from '../../_shared/business-objects/dto-loading-docket.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoLoadingDocketService } from '../../_shared/services/dto-loading-docket.service';
import { LoadingDocketViewComponent } from '../loading-docket-view/loading-docket-view.component';

@Component({
  selector: 'app-loading-docket-print',
  templateUrl: './loading-docket-print.component.html',
  styleUrls: ['./loading-docket-print.component.css']
})
export class LoadingDocketPrintComponent implements OnInit {

  @Input() loadingDocketIdInput: string;
  @Input() showCheckSheet: boolean;
  @ViewChild('appLoadingDocketView') appLoadingDocketView: LoadingDocketViewComponent

  loadingDocketId: string = "";
  loadingDocket: dtoLoadingDocket;
  openPrintDialog: string = "";
  //showCheckSheet: boolean = true;

  pdfMode: boolean = false;

  constructor(private dtoLoadingDocketService: dtoLoadingDocketService, private route: ActivatedRoute, private alertService: AlertService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(async params => {

      if (params != null || true) {
        // The loading docket can be supplied from the @Input so it will override the param value.
        if (this.loadingDocketIdInput == null || this.loadingDocketIdInput == "") {
          this.loadingDocketId = params["loadingdocketid"];

          this.openPrintDialog = params["openprintdialog"];

        }
      }
    });

    if (this.showCheckSheet == null) {
      this.showCheckSheet = true;
    }
    console.log("showCheckSheet" + this.showCheckSheet);

    this.loadData();

  }

  async loadData() {

    if (this.loadingDocketId == null || this.loadingDocketId == "") {
      return;
    }

    this.loadingDocket = await this.dtoLoadingDocketService.getdtoLoadingDocketPromiseById(this.loadingDocketId);

    if (this.openPrintDialog == "yes") {
      setTimeout(() => { this.print() }, 500);
    }

  }

  async print() {

    console.log("loading docket", this.loadingDocket);

    if (this.loadingDocket == null) {
      this.alertService.openSnackBarDefault("There is no loading docket to print.");
      return;
    }

    window.print();

  }

}
