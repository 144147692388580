<div class="species-select-container" #speciesContainer>
  <mat-form-field class="chh-form-field example-full-width">
    <mat-label>Search Species</mat-label>
    <input matInput data-species-input="speciesInput" class="species-input" placeholder="Filter by name" placeholderLabel="i.e. Essential Energy....." value="" type="text" #speciesFilter (keyup)="filterSpecies(speciesFilter.value, $event)" (focus)="speciesFilter.select()" (click)="checkSpeciesListIsOpen(speciesFilter.value)">
  </mat-form-field>

  <mat-icon matTooltip="Click to add a Species" class="clickable-icon person-add" (click)="addSpecies()">person_add</mat-icon>
  <span><mat-slide-toggle #multipleSpecies (click)="checkMultipleSpecies(multipleSpecies.checked)">Multiple Species</mat-slide-toggle></span>
  <div class="selected-species-container">
    <div class="selected-species" id="selectedSpecies" *ngIf="selectedSpecies != null && selectedSpecies.length > 0">
      <div class="chip-column">
        <mat-chip-listbox #chipGrid aria-label="Species selection">
          @for (selectSpecies of selectedSpecies; track selectSpecies; let selSpecIdx = $index; let lastItem = $last) {
          <mat-chip-option (removed)="removeSpecies(selectSpecies)">
            {{selectSpecies.txtName}}
            <button matChipRemove [attr.aria-label]="'remove ' + selectSpecies.txtName">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
          }
        </mat-chip-listbox>
      </div>
      <div class="see-more-chips">
        <button mat-icon-button aria-label="expand row" (click)="expandSelectedSpecies($event)">
          <mat-icon class="expand-icons species-selection-expand-icon material-icons-outlined" *ngIf="speciesSelectionExpanded == false ">keyboard_arrow_down</mat-icon>
          <mat-icon class="expand-icons species-selection-expand-icon material-icons-outlined" *ngIf="speciesSelectionExpanded == true">keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div data-species-select="speciesSelect" class="species-container" *ngIf="showSpeciesList" #speciesListContainer>
    <div data-species-select="speciesSelect" id="speciesSelect" class="species-select-list" [ngClass]="showSpeciesASCode ? 'species-select-list-all' : 'species-select-list'">
      <div class="species-pin-row" [ngClass]="showSpeciesASCode ? 'species-pin-row-all' : 'species-pin-row'">
        <div class="button" data-push-pin="pushPin" id="btnPushPin" (click)="clickPin($event)" [ngClass]="speciesListPinned == true ? 'button-pressed' : 'button'" matTooltip="{{toolTipText}}">
          <mat-icon data-push-pin="pushPin" class="pin-icon">push_pin</mat-icon>
        </div>
      </div>
      <div class="species-heading-row species-name-heading">Name</div>
      <div class="species-heading-row species-code-heading">Code</div>
      <div class="species-heading-row species-code-heading" *ngIf="showSpeciesASCode == true">A.S. Code</div>
      <div class="species-heading-row species-update-heading"></div>
      @for(species of speciesFiltered; track species; let idx = $index){
      <div data-species-select="speciesSelect" tabindex="{{idx}}" id="speciesName{{idx}}" (keyup)="nextSpecies($event, species)" class="chh-list-item-row chh-list-item-first-column species-name" [ngClass]="species.guSelectedSpeciesId != '' ? 'chh-list-item-row chh-list-item-row-selected species-name' : addressHoverId == species.rowguid ? 'chh-list-item-row chh-list-item-row-hover species-name' : 'chh-list-item-row species-name'" (mouseenter)="rowEnter(species.rowguid)" (mouseleave)="rowLeave()" (click)="selectSpecies(species, !(species.guSelectedSpeciesId != ''))"><div data-species-select="speciesSelect" class="species-text">{{species.txtName}}</div></div>
      <div data-species-select="speciesSelect" id="speciesCode{{idx}}" class="chh-list-item-row species-code" [ngClass]="species.guSelectedSpeciesId != '' ? 'chh-list-item-row chh-list-item-row-selected species-code' : addressHoverId == species.rowguid ? 'chh-list-item-row chh-list-item-row-hover species-code' : 'chh-list-item-row species-code'" (mouseenter)="rowEnter(species.rowguid)" (mouseleave)="rowLeave()" (click)="selectSpecies(species, !(species.guSelectedSpeciesId != ''))"><div data-species-select="speciesSelect" class="species-text">{{species.txtCode}}</div></div>
      <div data-species-select="speciesSelect" id="speciesASCode{{idx}}" class="chh-list-item-row species-as-code" [ngClass]="species.guSelectedSpeciesId != '' ? 'chh-list-item-row chh-list-item-row-selected species-as-code' : addressHoverId == species.rowguid ? 'chh-list-item-row chh-list-item-row-hover species-as-code' : 'chh-list-item-row species-as-code'" (mouseenter)="rowEnter(species.rowguid)" (mouseleave)="rowLeave()" (click)="selectSpecies(species, !(species.guSelectedSpeciesId != ''))" *ngIf="showSpeciesASCode == true"><div data-species-select="speciesSelect" class="species-text">{{species.txtCodeAlias}}</div></div>
      <div data-species-select="speciesSelect" id="speciesUpdateDiv{{idx}}" class="chh-list-item-row species-radio species-update-div" [ngClass]="species.guSelectedSpeciesId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == species.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(species.rowguid)" (mouseleave)="rowLeave()" (click)="selectSpecies(species, !(species.guSelectedSpeciesId != ''))">
        <div data-species-select="speciesSelect" id="speciesUpdateButtonDiv{{idx}}" class="update-species-button">
          <button mat-button (click)="updateSpecies(species)" matTooltip="Click to edit Species">
            <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
          </button>
        </div>
      </div>

      }
    </div>

  </div>

</div>
