import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { Moulder } from '../business-objects/moulder.bo';

@Injectable()
export class MoulderService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getMoulder(moulderId: string) {
		return this.http.get<Moulder>(this._baseUrl + 'api/Moulder/' + moulderId);
	}

	getMoulderParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<Moulder[]>(this._baseUrl + 'api/Moulder/GetMouldersParamArray/', aParam);
	}

	getAllMoulders() {
		return this.http.get<Moulder[]>(this._baseUrl + 'api/Moulder/GetAllMoulders/');
	}

	//getAlldtoMoulders() {
	//	return this.http.get<dtoMoulder[]>(this._baseUrl + 'api/Moulder/GetAllMoulders/');
	//}

	createMoulder(moulder: Moulder) {
		return this.http.post<Moulder>(this._baseUrl + 'api/Moulder/CreateMoulder/', moulder);
	}

	updateMoulder(moulder: Moulder) {
		return this.http.put<Moulder>(this._baseUrl + 'api/Moulder/UpdateMoulder/', moulder);
	}

  async getAllMouldersPromise() {
    return new Promise<Moulder[]>(resolve => {
      this.getAllMoulders().subscribe(moulder => {
        moulder.sort((a, b) => { return a.txtName > b.txtName ? 1 : 0 });
        resolve(moulder);
      }, err => {
        this.alertService.openSnackBarError("Error getting All Moulder records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getMoulderPromise(moulderId: string) {
		return new Promise<Moulder>(resolve => {
			this.getMoulder(moulderId).subscribe(moulder => {
				resolve(moulder);
			}, err => {
				this.alertService.openSnackBarError("Error getting Moulder records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getMoulderParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<Moulder[]>(resolve => {
			this.getMoulderParamArray(aParam).subscribe(moulders => {
				resolve(moulders);
			}, err => {
				this.alertService.openSnackBarError("Error getting Moulder records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateMoulderPromise(moulder: Moulder) {
		return new Promise<Moulder>(resolve => {
			this.updateMoulder(moulder).subscribe(moulder => {
				resolve(moulder);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating Moulder record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createMoulderPromise(moulder: Moulder) {
		return new Promise<Moulder>(resolve => {
			this.createMoulder(moulder).subscribe(moulder => {
				resolve(moulder);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating Moulder record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}
