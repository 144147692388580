import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppPrinter } from '../../_shared/business-objects/app-printer.bo';
import { Printer } from '../../_shared/business-objects/printer.bo';
import { AlertService } from './alert.service';

@Injectable()
export class AppPrinterService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getAllAppPrinters() {
    return this.http.get<AppPrinter[]>(this._baseUrl + 'api/AppPrinter/AppPrinter');
  }

  getPrintersByAppNameAndType(appName: string, type: Printer.enType) {
    return this.http.get<Printer[]>(this._baseUrl + 'api/AppPrinter/GetPrintersByAppNameAndType/' + appName + '/' + parseInt(type.toString()));
  }

  getAppPrinter(gAppPrinterId: string) {
    return this.http.get<AppPrinter>(this._baseUrl + 'api/AppPrinter/' + gAppPrinterId);
  }

  getPrintersByAppNameAndTypePromise(appName: string, type: Printer.enType) {

    return new Promise<Printer[]>(resolve => {
      this.http.get<Printer[]>(this._baseUrl + 'api/AppPrinter/GetPrintersByAppNameAndType/' + appName + '/' + parseInt(type.toString())).subscribe(appPrinters => {
        resolve(appPrinters);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error getting app printer record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
    
  }

  getAllPrintersPromise() {
    return new Promise<AppPrinter[]>(resolve => {
      this.getAllAppPrinters().subscribe(appPrinters => {
        resolve(appPrinters);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error getting app printer record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });

  }

}
