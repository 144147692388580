import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AppService } from '../../_shared/services/app-services/app.service';


@Component({
  selector: 'app-app-page-list',
  templateUrl: './app-page-list.component.html',
  styleUrls: ['./app-page-list.component.css']
})
export class AppPageListComponent implements OnInit {

  @Input() datasource: MatTableDataSource<AppPage>;
  @Output() editAppPageEvent = new EventEmitter<AppPage>();
  @Output() addAppPageEvent = new EventEmitter<AppPage>();

  @ViewChild(MatSort) sort: MatSort;

  displayedAppPages: string[] = ['intApp', 'txtPageName', 'txtPageURL', 'dteDateCreated', 'btnEditappPage'];

  constructor(private appService: AppService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
  }

  editAppPage(appPage: AppPage) {

    this.editAppPageEvent.emit(appPage);

  }

  appName(app: number) {
    return this.appService.appText(app);
  }

}
