<div class="pos-product-summary-print-screen-container" id="productSummaryPrintContainer">
  <div class="pos-product-summary-print-container">
    <div class="header-wrapper">
      <div class="header-container">
        <div class="header-logo">
          <img src="../../../../assets/img/BlackLogo.bmp" width="40" height="40" />
        </div>
        <div class="divider-wrapper">
          <div class="header-column-2"></div>
        </div>
        <div class="header-column-3A">
          <div class="header-row-1A">
            <div><h3>Coffs Harbour Hardwoods</h3></div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-summary-heading-container">
      <div class="print-buton">
        <button class="print-button" type="button" mat-flat-button color="primary" (click)="createPDF();" *ngIf="taxInvoiceProductSummary != null && printMode">Print POS Product Summary</button>
      </div>
    </div>
    <div class="product-summary-table-container">
      <div class="pos-product-summary-table" *ngIf="taxInvoiceProductSummary != null">
        <div class="pos-product-summary-heading">POS&nbsp;&nbsp;Product Summary - {{saleLocationName}}</div>
        <div class="pos-product-summary-heading-date-range">{{dateRange}}</div>
        <div class="summary-heading-label pos-product-summary-description-label">Description</div>
        <div class="summary-heading-label pos-product-summary-quantity-label chh-align-right alignRight">Quantity</div>
        <div class="summary-heading-label pos-product-summary-quantity-uom-label">Quantity<br />UOM</div>
        <div class="summary-heading-label pos-product-summary-price-label chh-align-right">Total Amount<br />Ex GST</div>
        @for(productSummary of taxInvoiceProductSummary; track productSum; let idx = $index){
        <div class="product-summary-row product-summary-receipt-description" [innerHTML]="productSummary.txtReceiptDescription | truncateString:50"></div>
        <div class="product-summary-row product-summary-quantity chh-align-right">{{productSummary.fltQuantity | number:'':'en-AU'}}</div>
        <div class="product-summary-row product-summary-quantity-uom">{{productSummary.txtQuantityUoM}}</div>
        <div class="product-summary-row product-sumamry-price chh-align-right">${{(productSummary.fltTotalItemPriceExGST | number:'1.2-2':'en-AU')}}</div>
        <div class="product-summary-total-row product-sumamry-total-price-label chh-align-right" *ngIf="idx == taxInvoiceProductSummary.length - 1">Total Ex GST:</div>
        <div class="product-summary-total-row product-sumamry-total-price chh-align-right" *ngIf="idx == taxInvoiceProductSummary.length - 1">${{productSummaryTotal | number:'1.2-2':'en-AU'}}</div>
        }
      </div>
    </div>

  </div>


</div>
