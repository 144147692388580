<form class="example-form" [formGroup]="form">
  <div class="app-user-column-set-container">
    <h1>App User Column Set</h1>

    <mat-tab-group color="accent" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabAppUserColumnSets>
      <mat-tab label="App User Column Set" class="tab-label">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Column Set Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="text" id="txtSearchAppUserColumnSetName" formControlName="txtSearchAppUserColumnSetName" (keyup.enter)="searchAppUserColumnSets()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple names by a comma i.e. Pole View, Sawn View..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-3">
            <button class="search-user-column-set-button" type="button" mat-flat-button color="primary" id="btnSearchAppPageColumnsMain" (click)="searchAppUserColumnSets()">Search</button>
          </div>
        </div>

        <div class="search-totals-container">
          <div class="search-total-rows">Total Rows: {{totalRows}}</div>
          <div class="search-total-pieces">Results Time: {{resultTime}} s</div>
        </div>
        <mat-divider></mat-divider>

        <div class="stock-item-list-container">
          <app-app-user-column-set-list [datasource]="appUserColumnSets" (editAppUserColumnSetEvent)="editAppUserColumnSet($event)" *ngIf="appUserColumnSets != null"></app-app-user-column-set-list>
        </div>

      </mat-tab>
      <mat-tab label="Search Options" class="tab-label">
        <div class="search-options-fields-container" id="searchFieldsContainer">

          <div class="search-option-row-1">
            <label id="example-radio-group-label">App</label>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radSearchApps">
              <mat-radio-button class="example-radio-button" *ngFor="let app of apps" [value]="app.intValue">
                {{app.txtValue}}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="search-option-row-2">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>App User First Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. stock-items" value="" type="text" id="txtSearchAppUserFirstName" formControlName="txtSearchAppUserFirstName" (keyup.enter)="searchAppUserColumnSets()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full user name" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-2">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>App User Last Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. stock-items" value="" type="text" id="txtSearchAppUserLastName" formControlName="txtSearchAppUserLastName" (keyup.enter)="searchAppUserColumnSets()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full user name" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>App Page</mat-label>
              <mat-select formControlName="guAppPageId">
                <mat-option *ngFor="let appPage of appPages" [value]="appPage.rowguid">{{appPage.txtPageName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="search-option-row-3">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created From</mat-label>
              <input id="dteSearchDateCreatedFrom" class="date-input created-from-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteSearchDateCreatedFrom">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created To</mat-label>
              <input id="dteSearchDateCreatedTo" class="date-input created-from-date-search" #searchReceiptDateTo matInput [matDatepicker]="pickercreatedto" formControlName="dteSearchDateCreatedTo">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedto"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedto></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-7">
            <button class="search-option-delivery-docket-button" type="button" mat-flat-button color="primary" id="btnSearchAppUserColumnSet" (click)="searchAppUserColumnSets()">Search</button>
          </div>

        </div>
      </mat-tab>

      <mat-tab label="Create App User Column Set" class="tab-label">
        <div class="create-app-page-column-container">
          <app-app-user-column-set-create (appUserColumnSetCreated)="appUserColumnSetCreated($event)" (appUserColumnSetCancelled)="appUserColumnSetCancelled()" *ngIf="selectedTab == enAppUserColumnSetTabs.CreateAppUserColumnSet"></app-app-user-column-set-create>
        </div>
      </mat-tab>

      <mat-tab label="Update App User Column Set" class="tab-label">
        <div class="update-app-user-column-set-container">
          <app-app-user-column-set-update [appUserColumnSet]="appUserColumnSet" (appUserColumnSetUpdated)="appUserColumnSetUpdated($event)" (appUserColumnSetCancelled)="appUserColumnSetCancelled()" *ngIf="selectedTab == enAppUserColumnSetTabs.UpdateAppUserColumnSet"></app-app-user-column-set-update>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
