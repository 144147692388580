<div id="orderItemBarcodeSticker" [ngClass]="multiItem == true ? 'sticker-container-landscape' : 'sticker-container'">
  <div class="header-wrapper">
    <div [ngClass]="multiItem == true ? 'header-container-landscape' : 'header-container'">
      <div class="header-logo">
        <img src="../../../../assets/img/BlackLogo.bmp" width="90" height="90" />
      </div>
      <div class="divider-wrapper">
        <div class="header-column-2"></div>
      </div>
      <div [ngClass]="multiItem == true ? 'header-column-3-landscape' : 'header-column-3'">
        <div class="header-row-1">
          <div>C</div>
          <div>o</div>
          <div>f</div>
          <div>f</div>
          <div>s</div>
          <div></div>
          <div>H</div>
          <div>a</div>
          <div>r</div>
          <div>b</div>
          <div>o</div>
          <div>u</div>
          <div>r</div>
        </div>
        <div class="header-row-2">
          <div>H</div>
          <div>A</div>
          <div>R</div>
          <div>D</div>
          <div>W</div>
          <div>O</div>
          <div>O</div>
          <div>D</div>
          <div>S</div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-website" *ngIf="false">www.coffshardwoods.com.au</div>

  <div class="sticker-order-item-container" *ngIf="multiItem == false">

    <div class="row-pack-number sticker-row">
      <div class="pack-number-label sticker-label">Pack No:</div><div class="pack-number-value sticker-value">{{stockContainerIdentifier}}</div>
    </div>
    <div class="row-product-name sticker-row">
      <div class="product-name-label sticker-label">Product:</div><div class="product-name-value sticker-value">{{orderItem == null ? '' : boardTallyRecordHistory.txtProductShortName != null && boardTallyRecordHistory.txtProductShortName != "" ? boardTallyRecordHistory.txtProductShortName : boardTallyRecordHistory.txtProductName}}</div>
    </div>
    <div class="row-profile-name sticker-row">
      <div class="profile-name-label sticker-label">Profile:</div><div class="profile-name-value sticker-value">{{orderItem == null ? '' : boardTallyRecordHistory.txtProfileAbbrievation != null && boardTallyRecordHistory.txtProfileAbbrievation != "" ? boardTallyRecordHistory.txtProfileAbbrievation : boardTallyRecordHistory.txtProfileName}}</div>
    </div>
    <div class="row-size sticker-row">
      <div class="size-label sticker-label">Size:</div><div class="size-value sticker-value">{{orderItem == null ? '' : boardTallyRecordHistory.fltWidth}} x {{orderItem == null ? '' : boardTallyRecordHistory.fltThickness}}</div>
    </div>
    <div class="row-length sticker-row" *ngIf="boardTallyRecordHistory != null && boardTallyRecordHistory.txtQuantityUoM != null && boardTallyRecordHistory.txtQuantityUoM == 'Each'">
      <div class="length-label sticker-label">Length:</div><div class="length-value sticker-value">{{orderItem == null ? '' : boardTallyRecordHistory.fltLength}}</div>
    </div>
    <div class="row-grade-name sticker-row">
      <div class="grade-name-label sticker-label">Grade:</div><div class="grade-name-value sticker-value">{{orderItem == null ? '' : boardTallyRecordHistory.txtGradeName}}</div>
    </div>
    <div class="row-species-name sticker-row">
      <div class="species-name-label sticker-label">Species:</div><div class="species-name-value sticker-value">{{orderItem == null ? '' : boardTallyRecordHistory.txtSpeciesName}}</div>
    </div>
    <div class="row-quantity sticker-row">
      <div class="quantity-label sticker-label">Quantity:</div><div class="quantity-value sticker-value">{{orderItem == null ? '' : boardTallyRecordHistory.fltQuantityTotal}} {{orderItem == null ? '' : boardTallyRecordHistory.txtQuantityUoM != 'Each' ? boardTallyRecordHistory.txtQuantityUoM : ''}}</div>
    </div>
  </div>

  <div class="sticker-order-item-container-landscape" *ngIf="multiItem == true">


    <div class="stock-container-row">
      <div class="pack-number-label-landscape sticker-label-landscape">Pack No:</div><div class="pack-number-value-landscape sticker-value-landscape" *ngIf="stockContainer != null">{{stockContainer.intIdentifier}}</div><div class="pack-number-value-landscape sticker-value-landscape" *ngIf="stockContainer == null">Auto&nbsp;Generated</div><div class="pack-number-barcode-value sticker-value-landscape"><app-code128auto-barcode *ngIf="stockContainer != null" [barcodeText]="stockContainer.intIdentifier"></app-code128auto-barcode></div>
    </div>
    <div class="customer-container-row">
      <div class="customer-name-label-landscape sticker-label-landscape">Customer:</div><div class="customer-name-value-landscape sticker-value-landscape" *ngIf="stockContainer != null">{{customerName}}</div>
    </div>
    <div class="order-no-container-row">
      <div class="order-no-label-landscape sticker-label-landscape">Order No:</div><div class="order-no-value-landscape sticker-value-landscape" *ngIf="stockContainer != null">{{orderNo}}</div>
    </div>

    <div class="boardtally-record-multi-item-container">
      <table mat-table #tblStockItems [dataSource]="datasource" class="mat-elevation-z8 stock-item-table" matSort>
        <ng-container matColumnDef="intItemNo">
          <th mat-header-cell *matHeaderCellDef class="item-no-column-header sticker-column-header" mat-sort-header> </th>
          <td mat-cell *matCellDef="let boardTallyRecordHistory" class="item-no-value sticker-value-landscape"> {{boardTallyRecordHistory.intItemNo}} </td>
        </ng-container>

        <ng-container matColumnDef="txtProductName">
          <th mat-header-cell *matHeaderCellDef class="product-name-column-header sticker-column-header" mat-sort-header> Product </th>
          <td mat-cell *matCellDef="let boardTallyRecordHistory" class="product-name-value sticker-value-landscape">{{boardTallyRecordHistory == null ? '' : boardTallyRecordHistory.txtProductShortName != null && boardTallyRecordHistory.txtProductShortName != "" ? boardTallyRecordHistory.txtProductShortName : boardTallyRecordHistory.txtProductName}} </td>
        </ng-container>


        <ng-container matColumnDef="txtProfileName">
          <th mat-header-cell *matHeaderCellDef class="profile-name-label sticker-column-header" mat-sort-header> Profile </th>
          <td mat-cell *matCellDef="let boardTallyRecordHistory" class="profile-name-value sticker-value-landscape"> {{boardTallyRecordHistory == null ? '' : boardTallyRecordHistory.txtProfileAbbrievation != null && boardTallyRecordHistory.txtProfileAbbrievation != "" ? boardTallyRecordHistory.txtProfileAbbrievation : boardTallyRecordHistory.txtProfileName}} </td>
        </ng-container>


        <ng-container matColumnDef="txtGradeName">
          <th mat-header-cell *matHeaderCellDef class="grade-name-label sticker-column-header" mat-sort-header> Grade </th>
          <td mat-cell *matCellDef="let boardTallyRecordHistory" class="grade-name-value sticker-value-landscape"> {{boardTallyRecordHistory == null ? '' : boardTallyRecordHistory.txtGradeName}} </td>
        </ng-container>


        <ng-container matColumnDef="txtSpeciesName">
          <th mat-header-cell *matHeaderCellDef class="species-name-label sticker-column-header" mat-sort-header> Species </th>
          <td mat-cell *matCellDef="let boardTallyRecordHistory" class="species-name-value sticker-value-landscape"> {{boardTallyRecordHistory == null ? '' : boardTallyRecordHistory.txtSpeciesName}} </td>
        </ng-container>


        <ng-container matColumnDef="intSize">
          <th mat-header-cell *matHeaderCellDef class="size-label sticker-column-header" mat-sort-header> Size </th>
          <td mat-cell *matCellDef="let boardTallyRecordHistory" class="size-value sticker-value-landscape"> {{boardTallyRecordHistory == null ? '' : boardTallyRecordHistory.intWidth}} x {{boardTallyRecordHistory == null ? '' : boardTallyRecordHistory.intThickness}} </td>
        </ng-container>


        <ng-container matColumnDef="fltQuantityLength">
          <th mat-header-cell *matHeaderCellDef class="length-label sticker-column-header" mat-sort-header> Qty / Length </th>
          <td mat-cell *matCellDef="let boardTallyRecordHistory" class="length-value sticker-value-landscape">
            {{boardTallyRecordHistory == null ? '' : boardTallyRecordHistory.fltQuantity}} {{boardTallyRecordHistory == null ? '' : boardTallyRecordHistory.txtQuantityUoM.trim() != 'Each' ? boardTallyRecordHistory.txtQuantityUoM : ''}}  {{boardTallyRecordHistory == null ? '' : boardTallyRecordHistory.txtQuantityUoM.trim() == 'Each' ? ' / ' + boardTallyRecordHistory.intTotalLM : ''}}
            <span class="parquetry-boxes" *ngIf="boardTallyRecordHistory.intParquetryBoxes > 0"><br />{{boardTallyRecordHistory.intParquetryBoxes > 0 ? '(' + boardTallyRecordHistory.txtParquetryBoxes + ')' : ''}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="fltOrderLength">
          <th mat-header-cell *matHeaderCellDef class="length-label sticker-column-header" mat-sort-header> Order Length </th>
          <td mat-cell *matCellDef="let boardTallyRecordHistory" class="length-value sticker-value-landscape">
            <span class="multiple-lengths">{{boardTallyRecordHistory.txtMultipleLength}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="btnRemoveItem">
          <th mat-header-cell *matHeaderCellDef class="length-label sticker-column-header" mat-sort-header> </th>
          <td mat-cell *matCellDef="let boardTallyRecordHistory" class="length-value sticker-value-landscape">
            <span class="remove-icon"><mat-icon class="cancel-icon" matTooltip="Remove Item" (click)="removeItem(boardTallyRecordHistory)">cancel</mat-icon></span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedStockItems; sticky: true" class="stock-item-row-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedStockItems;"></tr>
      </table>
    </div>
  </div>

</div>
<button class="print-button" type="button" mat-flat-button color="primary" (click)="print()" *ngIf="this.stockContainerId != null && this.stockContainerId != ''">Print Sticker</button>
<app-spinner *ngIf="showSpinner" [showSpinner]="showSpinner" class="spinner"></app-spinner>

