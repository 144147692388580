import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dtoTPCharge } from '../../_shared/business-objects/dto-tpcharge.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoTPChargeService } from '../../_shared/services/dto-tpcharge.service';

@Component({
  selector: 'app-cca-charge-print',
  templateUrl: './cca-charge-print.component.html',
  styleUrl: './cca-charge-print.component.scss'
})
export class CcaChargePrintComponent implements OnInit {

  @Input() chargeIdInput: string;

  tpCharge: dtoTPCharge;
  tpChargeId: string = "";
  openPrintDialog: string = "";
  samples: boolean = false;

  pdfMode: boolean = false;

  constructor(private dtoTPChargeService: dtoTPChargeService, private route: ActivatedRoute, private alertService: AlertService) {

  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(async params => {

      if (params != null || true) {
        // The loading docket can be supplied from the @Input so it will override the param value.
        if (this.chargeIdInput == null || this.chargeIdInput == "") {
          this.chargeIdInput = params["chargeid"];
          this.samples = (params["samples"] == "yes");
          this.openPrintDialog = params["openprintdialog"];
          //console.log("samples: ", this.samples);
        }
      }
    });


    this.loadData();

  }

  async loadData() {

    if (this.chargeIdInput == null) {
      return null;
    }

    this.tpCharge = await this.dtoTPChargeService.getdtoTPChargePromise(this.chargeIdInput);

    if (this.openPrintDialog == "yes") {
      setTimeout(() => {
        this.print();

      }, 500);
    }

  }

  async print() {

    console.log("delivery docket", this.tpCharge);

    if (this.tpCharge == null) {
      this.alertService.openSnackBarDefault("There is no charge to print.");
      return;
    }

    window.print();

  }

}
