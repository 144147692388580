import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformText'
})
export class TransformTextPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value == null) {
      return "";
    }
    return value.replace(/\n/g, '<br>'); //replace all '\n' with <br>;
  }

}
