import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as jsbarcode from 'jsbarcode';

@Component({
  selector: 'app-code128auto-barcode',
  templateUrl: './code128auto-barcode.component.html',
  styleUrls: ['./code128auto-barcode.component.css']
})
export class Code128autoBarcodeComponent implements OnInit, AfterViewInit {

  @Input() barcodeText: string;
  @Input() barcodeId: string;
  @Input() height: number;
  @Input() lineWidth: number;
  @Input() fontSize: number;
  @Input() displayValue: boolean;

  constructor() {

  }

  ngOnInit(): void {


    if (this.barcodeId == null || this.barcodeId == "") {
      this.barcodeId = "barcodeId";
    }

    if (this.height == null || this.height == 0) {
      this.height = 40;
    }

    if (this.lineWidth == null || this.lineWidth == 0) {
      this.lineWidth = 2;
    }

    if (this.displayValue == null) {
      this.displayValue = false;
    }




  }

  ngAfterViewInit(): void {

    //console.log("barcodeId: ", document.getElementById(this.barcodeId));
    if (this.barcodeText != null && this.barcodeText != "") {

      jsbarcode("#" + this.barcodeId, this.barcodeText, {
        format: "CODE128",
        lineColor: "#000",
        width: this.lineWidth,
        height: this.height,
        fontSize: this.fontSize,
        displayValue: this.displayValue
      });
    }
  }

  setBarcodeText(data: string) {
    this.barcodeText = data;
    if (data != null && data != "") {
      jsbarcode("#" + this.barcodeId, this.barcodeText, {
        format: "CODE128",
        lineColor: "#000",
        width: this.lineWidth,
        height: this.height,
        fontSize: this.fontSize,
        displayValue: this.displayValue
      });
    }

  }
}
