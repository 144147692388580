export interface AppUserColumn
{
	blnVisible: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guAppPageColumnId: string;
	guAppUserColumnSetId: string;
	guAppUserId: string;
	intColumnOrder: number;
	intPageState: number;
	rowguid: string;
}

export class AppUserColumn
{
	blnVisible: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guAppPageColumnId: string;
	guAppUserColumnSetId: string;
	guAppUserId: string;
	intColumnOrder: number;
	intPageState: number;
	rowguid: string;
}

