export interface AppUserPreference
{
	blnActive: boolean;
	blnValue: boolean;
	decValue: number;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	dteValue: Date;
	guAppPreferenceId: string;
	guAppUserId: string;
	guValueId: string;
	intValue: number;
	rowguid: string;
	txtValue: string;
}

export class AppUserPreference
{
	blnActive: boolean;
	blnValue: boolean;
	decValue: number;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	dteValue: Date;
	guAppPreferenceId: string;
	guAppUserId: string;
	guValueId: string;
	intValue: number;
	rowguid: string;
	txtValue: string;
}

