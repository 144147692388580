import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { AuthService } from '../../account/auth.service';
import { AppUserColumnSet } from '../../_shared/business-objects/app-user-column-set.bo';
import { dtoAppUserColumnSet } from '../../_shared/business-objects/dto-app-user-column-set.bo';
import { dtoAppUserColumn } from '../../_shared/business-objects/dto-app-user-column.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { AppUserColumnSetService } from '../../_shared/services/app-user-column-set.service';
import { AppUserColumnService } from '../../_shared/services/app-user-column.service';
import { FormService } from '../../_shared/services/form.service';
import { dtoAppUserColumnSetService } from '../../_shared/services/dto-app-user-column-set.service';
import { dtoAppUserColumnService } from '../../_shared/services/dto-app-user-column.service';
import { AppUserColumn } from '../../_shared/business-objects/app-user-column.bo';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';

@Component({
  selector: 'app-app-user-column-set-bar',
  templateUrl: './app-user-column-set-bar.component.html',
  styleUrls: ['./app-user-column-set-bar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppUserColumnSetBarComponent implements OnInit, AfterContentInit {
  @ViewChild('table') table: MatTable<dtoAppUserColumn>;

  @Input() pageName: string;
  @Input() componentColumns: string[];
  @Input() container: string;
  @Input() columnListId: string;
  @Output() updatedComponentColumnsEvent = new EventEmitter<string[]>();

  displayedColumns: string[] = ['order', 'name', 'visible'];

  appUserColumnSets: dtoAppUserColumnSet[];
  appUserColumns: dtoAppUserColumn[];

  chosenItem: string = "";

  showColumnTable: boolean = false;
  showSaveView: boolean = false;

  selectedColumnSet: AppUserColumnSet;

  columnList: HTMLTableElement;

  constructor(private dtoAppUserColumnService: dtoAppUserColumnService, private appUserColumnService: AppUserColumnService, private authService: AuthService, private appUserColumnSetService: AppUserColumnSetService
    , private dtoAppUserColumnSetService: dtoAppUserColumnSetService, private ref: ChangeDetectorRef, private alertService: AlertService, private formService: FormService) { }

  ngOnInit(): void {

    setTimeout(() => { this.loadUserColumns() }, 800);
    this.loadUserColumnSets();

  }

  ngAfterContentInit(): void {

    setTimeout(() => {

      //console.log("this.container: " + document.body.innerHTML);
      let containerElement = document.getElementById(this.container);

      //console.log("columnListId: " + this.columnListId);
      //console.log("container", this.container);
      //console.log("containerElement", containerElement);

      containerElement.addEventListener('click', (e) => { this.closeColumnList("container"); });

      //document.getElementById(this.container).addEventListener('click', (e) => { this.closeColumnList("container"); });
      this.columnList = document.getElementById(this.columnListId) as HTMLTableElement;


      this.columnList.addEventListener('click', (e) => { e.stopPropagation(); });
    }, 1000);

  }


  closeColumnList(element: string) {

    if (element == "container") {
      if (this.showColumnTable == true) {
        this.showColumnTable = false;
        this.ref.detectChanges();
      }

      if (this.showSaveView == true) {
        this.showSaveView = false;
        this.ref.detectChanges();
      }
    }
  }


  async loadUserColumns() {


    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guAppUserId", this.authService.getAppUserId()));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("txtPageName", this.pageName));  //"Delivery Docket Order Items"
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("blnCurrentView", "1"));
    aParamList.push(aParam);

    this.appUserColumns = await this.dtoAppUserColumnService.getdtoAppUserColumnParamArrayPromise(aParamList);

    console.log("in loadUserColumns", this.appUserColumns);

      //console.log(this.pageName, this.appUserColumns);
   if (this.appUserColumns != null && this.appUserColumns.length > 0) {
      //console.log(this.pageName, this.appUserColumns);
      this.componentColumns.length = 0;
      let allColumns: string[] = [];
      for (let i = 0; i <= this.appUserColumns.length - 1; i++) {
        if (this.appUserColumns[i].blnVisible) {
          this.componentColumns.push(this.appUserColumns[i].txtColumnName);
          allColumns.push(this.appUserColumns[i].rowguid);
        }
      }

      this.updatedComponentColumnsEvent.emit(this.componentColumns);

      //this.ref.detectChanges();

    }

  }


  async loadUserColumnSets() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guAppUserId", this.authService.getAppUserId()));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("txtPageName", this.pageName));
    aParamList.push(aParam);

    this.appUserColumnSets = await this.dtoAppUserColumnSetService.getdtoAppUserColumnSetParamArrayPromise(aParamList);

    this.appUserColumnSets.sort(function (a, b) {

      var textA = a.txtName.toUpperCase();
      var textB = b.txtName.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;

    });

    for (let i = 0; i <= this.appUserColumnSets.length - 1; i++) {
      if (this.appUserColumnSets[i].blnCurrentView == true) {
        this.chosenItem = this.appUserColumnSets[i].rowguid;
        this.selectedColumnSet = this.appUserColumnSets[i];
        break;
      }
    }

    this.ref.detectChanges();

  }

  async saveColumnSet(columnSetId: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guAppUserId", this.authService.getAppUserId()));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("txtPageName", this.pageName));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("guAppUserColumnSetId", columnSetId));
    aParamList.push(aParam);

    this.appUserColumns = await this.dtoAppUserColumnService.getdtoAppUserColumnParamArrayPromise(aParamList);

    if (this.appUserColumns != null && this.appUserColumns.length > 0) {
      //console.log(this.appUserColumns);
      this.componentColumns.length = 0;

      for (let i = 0; i <= this.appUserColumns.length - 1; i++) {
        if (this.appUserColumns[i].blnVisible) {
          this.componentColumns.push(this.appUserColumns[i].txtColumnName);
        }
      }
    }

    for (let i = 0; i <= this.appUserColumnSets.length - 1; i++) {
      let appUserColumnSet: AppUserColumnSet = await this.appUserColumnSetService.getAppUserColumnSetPromise(this.appUserColumnSets[i].rowguid);

      appUserColumnSet.blnCurrentView = false;

      if (this.appUserColumnSets[i].rowguid == columnSetId) {
        appUserColumnSet.blnCurrentView = true;
        this.selectedColumnSet = this.appUserColumnSets[i];
      }

      //console.log(appUserColumnSet);
      await this.appUserColumnSetService.updateAppUserColumnSetPromise(appUserColumnSet);

    }

    this.updatedComponentColumnsEvent.emit(this.componentColumns);
    
    this.ref.detectChanges();

  }


  async saveColumn2(appUserColumn: dtoAppUserColumn, visible: boolean) {


    let appUserCol: AppUserColumn = await this.appUserColumnService.getAppUserColumnPromise(appUserColumn.rowguid);

    if (appUserCol != null) {
      appUserCol.blnVisible = visible;

      this.appUserColumnService.updateAppUserColumn(appUserCol).subscribe(userCol => {

        //console.log(userCol);

      }, err => {
        console.log(err);
      });
    }

    // Update local array
    this.componentColumns.length = 0;

    for (let i = 0; i <= this.appUserColumns.length - 1; i++) {

      if (this.appUserColumns[i].rowguid == appUserColumn.rowguid) {

        this.appUserColumns[i].blnVisible = visible;
      }


      if (this.appUserColumns[i].blnVisible == true) {
        this.componentColumns.push(this.appUserColumns[i].txtColumnName);
      }


    }

    this.updatedComponentColumnsEvent.emit(this.componentColumns);

    this.ref.detectChanges();


  }

  openColumnTable(table: MatTable<dtoAppUserColumn>) {

    this.showColumnTable = !this.showColumnTable;
    //console.log("this.showColumnTable", this.showColumnTable);

    //if (this.showColumnTable == true) {
    //  let reorderTable = document.getElementById("user-column-order-table");

    //  console.log(reorderTable);
    //  //reorderTable.style.display = 'fixed';
    //}

  }


  async dropTable(event: CdkDragDrop<dtoAppUserColumn[]>) {


    let appUserCol: dtoAppUserColumn = null;
    let index: number = 0;
    //for (let i = 0; i <= this.appUserColumns.length - 1; i++) {

    //  if (this.appUserColumns[i].rowguid == event.item.data.rowguid) {
    //    console.log("this.appUserColumns[i].rowguid == event.item.data.rowguid", event.item.data.rowguid);

    //    this.appUserColumns[i].intColumnOrder = event.currentIndex + 1;  // zero based index so add 1;
        
    //    appUserCol = this.appUserColumns[i];
    //    index = i;
    //    break;
    //  }
    //}

    //if (appUserCol != null) {

    //  this.appUserColumns.splice(index, 1);
    //  this.appUserColumns.splice(appUserCol.intColumnOrder - 1, 0, appUserCol);

      
    //}



    this.componentColumns.length = 0;

    const prevIndex = this.appUserColumns.findIndex((d) => d === event.item.data);

    //console.log("save this rowguid: " + event.item.data.rowguid + "  to Order Number: " + event.currentIndex + ",  Previous Index: " + prevIndex);
    //console.log("event.previousIndex: " + event.previousIndex);

    moveItemInArray(this.appUserColumns, prevIndex, event.currentIndex);

    //console.log("this.appUserColumns ", this.appUserColumns);

    for (let i = 0; i <= this.appUserColumns.length - 1; i++) {
      
      // save new ordered list.
      let appUserColumn: AppUserColumn = await this.appUserColumnService.getAppUserColumnPromise(this.appUserColumns[i].rowguid)

      if (this.appUserColumns[i].rowguid == event.item.data.rowguid) {
        appUserColumn.intColumnOrder = event.currentIndex + 1;
      }

      if (this.appUserColumns[i].rowguid != event.item.data.rowguid) {
        appUserColumn.intColumnOrder = i + 1;
      }

      //console.log(appUserColumn.intColumnOrder + "  -  " + this.appUserColumns[i].txtColumnName + " : current index: " + event.currentIndex);

        if (appUserColumn != null) {

          await this.appUserColumnService.updateAppUserColumnPromise(appUserColumn);
        }


      this.appUserColumns[i].intColumnOrder = i + 1
      if (this.appUserColumns[i].blnVisible == true) {
        this.componentColumns.push(this.appUserColumns[i].txtColumnName);
      }
    }



    //this.table.renderRows();

    this.updatedComponentColumnsEvent.emit(this.componentColumns);


    this.ref.detectChanges();

  }

  openSaveView() {
    this.showSaveView = !this.showSaveView;
    this.formService.setAutoFocusTimeout("myView", 300);
  }

  async saveView(view: string) {

    if (this.selectedColumnSet != null) {
      let columnSet: AppUserColumnSet = new AppUserColumnSet;
      columnSet.blnCurrentView = false;
      columnSet.dteDateCreated = new Date(Date.now());
      columnSet.guAppPageId = this.selectedColumnSet.guAppPageId;
      columnSet.guAppUserId = this.selectedColumnSet.guAppUserId;
      columnSet.txtName = view;

      columnSet = await this.appUserColumnSetService.createAppUserColumnSetPromise(columnSet);

      for (let i = 0; i <= this.appUserColumns.length - 1; i++) {
        let appUserColumn: AppUserColumn = new AppUserColumn;
        appUserColumn.blnVisible = this.appUserColumns[i].blnVisible;
        appUserColumn.dteDateCreated = new Date(Date.now());
        appUserColumn.guAppPageColumnId = this.appUserColumns[i].guAppPageColumnId;
        appUserColumn.guAppUserColumnSetId = columnSet.rowguid;
        appUserColumn.guAppUserId = this.appUserColumns[i].guAppUserId;
        appUserColumn.intColumnOrder = this.appUserColumns[i].intColumnOrder;
        appUserColumn.intPageState = this.appUserColumns[i].intPageState;
        appUserColumn = await this.appUserColumnService.createAppUserColumnPromise(appUserColumn);
      }
    }

    this.loadUserColumnSets();

    this.showSaveView = false;
    //console.log(view);
  }

  async removeColumnSet() {

    if (this.selectedColumnSet.txtName.toLowerCase() == "default") {
      this.alertService.openSnackBar("You cannot remove the default view", "Close", "center", "bottom", "", 4000);
      return;
    }

    let msg: string = await this.alertService.openSnackBarCustomPromise("Remove View", "Are you sure you want to remove the view " + this.selectedColumnSet.txtName + "?", "Yes", "No", "center", "bottom", "", 0);

    if (msg != "Yes") {
      return;
    }


    for (let i = 0; i <= this.appUserColumns.length - 1; i++) {
      await this.appUserColumnService.removeAppUserColumnPromise(this.appUserColumns[i].rowguid);
    }

    await this.appUserColumnSetService.removeAppUserColumnSetPromise(this.selectedColumnSet.rowguid);

    this.loadUserColumnSets();

    this.chosenItem = this.appUserColumnSets[0].rowguid;

    this.saveColumnSet(this.appUserColumnSets[0].rowguid);


  }
}
