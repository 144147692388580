<div class="link-existing-loading-docket-container">
  <div class="link-existing-loading-docket-selection-form" [formGroup]="form">

    <div class="search-options">
      <div class="yard-section-load">
        <div class="search-option-row-1">
          <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
            <mat-label>Loading Docket No</mat-label>
            <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="text" id="txtSearchLoadingDocketNo" formControlName="txtSearchLoadingDocketNo" (keydown.enter)="searchLoadingDocket()">
          </mat-form-field>
        </div>

      </div>

      <div class="loading-docket-number-container">
        <div class="search-option-row-1">
          <mat-form-field appearance="fill" class="chh-form-field">
            <mat-label>Yard Loading Location</mat-label>
            <mat-select formControlName="intYardSection">
              <mat-option *ngFor="let yrdSection of yardSections" [value]="yrdSection.intValue" [selected]="yrdSection.intValue == yardSection">{{yrdSection.txtValue}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div>

    <div class="search-buttons">
      <div class="button-container">
        <button class="search-loadig-docket-number-button search-button" type="button" mat-flat-button color="primary" (click)="searchLoadingDocket()">Search Docket Number</button>
        <button class="search-last-10-loadig-dockets search-button" type="button" mat-flat-button color="primary" (click)="searchLast10LoadingDockets()">Search Last 10</button>
        <button class="search-ready-for-loading-button search-button" type="button" mat-flat-button color="primary" (click)="searchReadyForLoading()">Ready For Loading</button>
        <button class="search-scheduled-button search-button" type="button" mat-flat-button color="primary" (click)="searchScheduled()">Scheduled</button>
      </div>
    </div>


    <div class="link-existing-loading-docket">
      <mat-selection-list class="link-existing-loading-docket-select" #guLoadingDocketId [multiple]="false" formControlName="guLoadingDocketId" (selectionChange)="loadingDocketSelect(guLoadingDocketId._value)">
        <mat-list-option *ngFor="let loadingDocket of loadingDockets" [value]="loadingDocket" [selected]="selectedLoadingDocket != null ? loadingDocket.rowguid == selectedLoadingDocket.rowguid : false">
          <div class="select-grid"><div class="select-docket-no">{{loadingDocket.intDocketNo}}</div><div class="select-customer-location" [innerHtml]="loadingDocket.txtCustomerLocation | safeHtml"></div></div>
        </mat-list-option>
      </mat-selection-list>
      <br />
    </div>
  </div>
</div>

<div class="save-buttons">
  <div class="save-button-container">
    <button class="save-button" type="button" mat-flat-button color="primary" (click)="selectLoadingDocket()">Save</button>
    <button class="cancel-button" type="button" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
  </div>
</div>

