import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Orders } from '../business-objects/orders.bo';
import { dtoOrders } from '../business-objects/dto-orders.bo';
import { CustomerService } from './customer.service';
import { CustomerDeliveryAddressService } from './customer-delivery-address';
import { CustomerContactService } from './customer-contact.service';

import { AuthService } from '../../account/auth.service';

import { DatePipe } from '@angular/common';
import { SQLParamArray } from '../business-objects/sql-param-array';

@Injectable()
export class OrderService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private customerService: CustomerService,
    private customerDeliveryAddressService: CustomerDeliveryAddressService, private customerContactService: CustomerContactService,
    private authService: AuthService) {
    this._baseUrl = baseUrl;
  }

  getOrder(orderno: number) {
    return this.http.get<Orders>(this._baseUrl + 'api/order/' + orderno);
  }

  getOrdersByDate(dateFrom: Date, dateTo: Date) {
    return this.http.get<Orders>(this._baseUrl + 'api/order/' + dateFrom + '/' + dateTo);
  }

  getDTOOrderByRowguid(orderId: string) {
    console.log(this._baseUrl + 'api/order/GetDTOOrderByRowguid/' + orderId);
    return this.http.get<dtoOrders>(this._baseUrl + 'api/order/GetDTOOrderByRowguid/' + orderId);
  }

  getOrderByRowguid(orderId: string) {
    console.log(this._baseUrl + 'api/order/GetOrderByRowguid/' + orderId);
    return this.http.get<Orders>(this._baseUrl + 'api/order/GetOrderByRowguid/' + orderId);
  }

  createOrder(order: Orders) {
    console.log("dteCreated: " + order.dteCreated);

    return this.http.post<Orders>(this._baseUrl + 'api/order/CreateOrder/', order);
  }

  async getDTOOrderPromise(orderId: string) {
    return new Promise<dtoOrders>(resolve => {
      this.getDTOOrderByRowguid(orderId).subscribe(order => {
        resolve(order);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  createCashSaleOrderObject(): Orders {

    //THIS ORDER IS SET AS COMPLETE AND DELIVERED SINCE IT WILL ONCE TRANSACTION
    //IS COMPLETE. ONLY IN MEMBORY AND NOT SAVED SO ALL GOOD.
    const datepipe: DatePipe = new DatePipe('en-AU');
    let order: Orders = new Orders();
    let loc: string = "COFFS";

    order.guCustomerId = this.customerService.CoffsHarbourStoreCustomerGuid;
    order.guClientDelAddressId = this.customerDeliveryAddressService.CoffsHarbourStoreDeliveryAddressGuid;

    console.log("loc: " + localStorage.getItem('loc'));
    if (localStorage.getItem('loc') == "HQ") {
      loc = "GLENREAGH";
      order.guCustomerId = this.customerService.GlenreaghStoreCustomerGuid;
      order.guClientDelAddressId = this.customerDeliveryAddressService.GlenreaghStoreDeliveryAddressGuid;
    }
    order.txtOrderNo = loc + "-SALE: " + datepipe.transform(Date.now(), 'yyyy-MM-ddTHH:mm:ss').replace("T"," ");
    order.intType = Orders.enType.Order;
    order.dteCreated = new Date(datepipe.transform(Date.now(), 'yyyy-MM-dd'));
    order.dteDueDate = new Date(datepipe.transform(Date.now(), 'yyyy-MM-dd'));  //new Date(Date.now());
    order.blnDelivered = true;
    order.intStatus = Orders.enStatus.Complete;
    order.guEmployeeId = this.authService.getAppUserEmployeeId();
    order.dteEntered = new Date(datepipe.transform(Date.now(), 'yyyy-MM-dd'));  //new Date(Date.now());
    order.blnAddGST = false;
    order.fltDeliveryRate = 0;
    order.guQuoteId = null;
    order.txtContact = "";
    order.guQuotedById = null;
    order.txtAvailablity = "";
    order.txtEscort = 0;
    order.intDeliveryRateUnit = 0;
    order.intEscortRateUnit = 0;
    order.blnPaid = false;
    order.blnCalled = false;
    order.blnSheltered = false;
    order.intLoadOrder = 0;
    order.guClientDelAddressId2 = null;
    order.guClientDelAddressId3 = null;
    order.guClientDelAddressId4 = null;
    //order.blnJoinery = false;   // need to work out if we want to do this... probably not. set relative OrderItem from ProductCode info.
    order.blnMillToCut = false;
    order.blnBPToProcess = false;
    order.guOrderTakenById = this.authService.getAppUserEmployeeId();
    order.intBPReferenceNo = 0;
    order.guCustomerContactId = this.customerContactService.CoffsHarbourStoreCustomerContactGuid;
    order.dteLastEmailed = null;
    order.fltAdditionalKM = 0;
    order.txtOverDueComments = "";
    order.blnRemittance = false;
    order.intPaymentType = Orders.enPaymentType.PaymentOnPickup;
    order.intInvoiceTransmission = Orders.enInvoiceTransmission.PickUp;
    order.intAvailabilityNumber = 0;
    order.intAvailabilityUnit = Orders.enAvailabilityUnit.Days;
    order.txtInternalComments = "";
    order.blnAskForSale = false;
    order.dteAskForSaleReminder = null;
    order.intAskForSaleReason = 0;
    order.txtAskForReasonComments = "";
    order.fltAskForReasonPercentage = 0;
    order.guDuplicatedQuoteId = null;
    order.blnOptionalQuote = false;
    order.blnContactedSubContractor = false;
    order.fltSubContractedFreightQuote = 0;
    order.txtFreightSubContractor = "";
    order.txtAdminComments = "";
    order.blnStock = false;
    order.blnMinimumDepositPaid = false;
    order.blnConsignment = false;
    return order;
  }

}  
