export interface DeliveryDocketCustomerDeliveryContact
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guCustomerContactId: string;
	guDeliveryDocketId: string;
	intContactOrder: number;
	rowguid: string;
	txtComments: string;
}

export class DeliveryDocketCustomerDeliveryContact
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guCustomerContactId: string;
	guDeliveryDocketId: string;
	intContactOrder: number;
	rowguid: string;
	txtComments: string;
}

