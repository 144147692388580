export interface Client
{
	blnActive: boolean;
	blnAddDeliveryChargeToDocket: boolean;
	blnAuthorityCustomer: boolean;
	blnBoardPlant: boolean;
	blnCombineOldTradingName: boolean;
	blnContractCustomer: boolean;
	blnCut: boolean;
	blnDesap: boolean;
	blnDuplicate: boolean;
	blnForestry: boolean;
	blnHaul: boolean;
	blnInstallationQuote: boolean;
	blnInternalCustomer: boolean;
	blnIsABusiness: boolean;
	blnKeyCustomer: boolean;
	blnLocal: boolean;
	blnMill: boolean;
	blnOrders: boolean;
	blnParquetry: boolean;
	blnPhonebook: boolean;
	blnPoleCosting: boolean;
	blnPrivateProperty: boolean;
	blnRoyalty: boolean;
	blnSnig: boolean;
	blnSystemCustomer: boolean;
	blnToolHandles: boolean;
	blnTreatmentPlant: boolean;
	dteDateCreated: Date;
	dteDateInactivated: Date;
	dteLastUpdated: Date;
	fltAuctionFee: number;
	fltPercentageMargin: number;
	guElectricalAuthoritySpecId: string;
	guPricingCategoryId: string;
	intCustomerType: number;
	intInvoiceTransmission: number;
	intLocation: number;
	intPaymentType: number;
	intSectorType: number;
	intTerms: number;
	intUsualLoadingLocation: number;
	intWebAppCustomActions: number;
	memComments: string;
	OriginalRowguid: string;
	rowguid: string;
	txtABN: string;
	txtAddress1: string;
	txtAddress2: string;
	txtBusinessName: string;
	txtContact1: string;
	txtContact2: string;
	txtCountry: string;
	txtDeliveryAddress: string;
	txtDeliveryCountry: string;
	txtDeliveryPostcode: string;
	txtDeliveryState: string;
	txtDeliveryTown: string;
	txtEmail: string;
	txtEmail2: string;
	txtFax: string;
	txtMobile: string;
	txtName: string;
	txtOldTradingName: string;
	txtOrderNo: string;
	txtPhone1: string;
	txtPhone2: string;
	txtPostcode: string;
	txtState: string;
	txtTown: string;
	txtUpdateDataComments: string;
}

export class Client
{
	blnActive: boolean;
	blnAddDeliveryChargeToDocket: boolean;
	blnAuthorityCustomer: boolean;
	blnBoardPlant: boolean;
	blnCombineOldTradingName: boolean;
	blnContractCustomer: boolean;
	blnCut: boolean;
	blnDesap: boolean;
	blnDuplicate: boolean;
	blnForestry: boolean;
	blnHaul: boolean;
	blnInstallationQuote: boolean;
	blnInternalCustomer: boolean;
	blnIsABusiness: boolean;
	blnKeyCustomer: boolean;
	blnLocal: boolean;
	blnMill: boolean;
	blnOrders: boolean;
	blnParquetry: boolean;
	blnPhonebook: boolean;
	blnPoleCosting: boolean;
	blnPrivateProperty: boolean;
	blnRoyalty: boolean;
	blnSnig: boolean;
	blnSystemCustomer: boolean;
	blnToolHandles: boolean;
	blnTreatmentPlant: boolean;
	dteDateCreated: Date;
	dteDateInactivated: Date;
	dteLastUpdated: Date;
	fltAuctionFee: number;
	fltPercentageMargin: number;
	guElectricalAuthoritySpecId: string;
	guPricingCategoryId: string;
	intCustomerType: number;
	intInvoiceTransmission: number;
	intLocation: number;
	intPaymentType: number;
	intSectorType: number;
	intTerms: number;
	intUsualLoadingLocation: number;
	intWebAppCustomActions: number;
	memComments: string;
	OriginalRowguid: string;
	rowguid: string;
	txtABN: string;
	txtAddress1: string;
	txtAddress2: string;
	txtBusinessName: string;
	txtContact1: string;
	txtContact2: string;
	txtCountry: string;
	txtDeliveryAddress: string;
	txtDeliveryCountry: string;
	txtDeliveryPostcode: string;
	txtDeliveryState: string;
	txtDeliveryTown: string;
	txtEmail: string;
	txtEmail2: string;
	txtFax: string;
	txtMobile: string;
	txtName: string;
	txtOldTradingName: string;
	txtOrderNo: string;
	txtPhone1: string;
	txtPhone2: string;
	txtPostcode: string;
	txtState: string;
	txtTown: string;
	txtUpdateDataComments: string;
}

export namespace Client
{
	export enum enSectorType
	{
		None,
		Wholesale,
		Trade,
		Retail,
		Merchant
	}
}

export namespace Client
{
	export enum enTerms
	{
		None,
		COD,
		ThirtyDays,
		SixtyDays
	}
}

export namespace Client
{
	export enum enWebAppCustomActions
	{
		None,
		AlertQuantityChange
	}
}

