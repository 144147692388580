export interface OrderItem
{
	blnAmendment: boolean;
	blnBattens: boolean;
	blnBPFloorOrder: boolean;
	blnComplaint: boolean;
	blnComplaintResolved: boolean;
	blnConsignment: boolean;
	blnConsignmentDelivered: boolean;
	blnDelivered: boolean;
	blnDontShowCube: boolean;
	blnDrilled: boolean;
	blnFinalDelivery: boolean;
	blnInvoiced: boolean;
	blnJoinery: boolean;
	blnMinimumDeposit: boolean;
	blnNextBrisbaneDelivery: boolean;
	blnNextDelivery: boolean;
	blnNextDelivery2: boolean;
	blnNextLocalDelivery: boolean;
	blnNonStandardNominal: boolean;
	blnNonStandardProduct: boolean;
	blnOptional: boolean;
	blnPaidInFull: boolean;
	blnPercisionDocked: boolean;
	blnPrecoated: boolean;
	blnPriceAmendmentRequested: boolean;
	blnPriceAmendmentUpdated: boolean;
	blnPricesAreCorrect: boolean;
	blnProductChangeRequested: boolean;
	blnProductChangeUpdated: boolean;
	blnSetLength: boolean;
	blnShowAboveGroundDurability: boolean;
	blnShowInGroundDurability: boolean;
	blnShowStrengthGroup: boolean;
	dteComplaint: Date;
	dteComplaintResolved: Date;
	dteDataUpdatedDate: Date;
	dteDateCreated: Date;
	dteDeliveryDate: Date;
	dteInvoiceDate: Date;
	dteLastUpdated: Date;
	dteProcessed: Date;
	dteStatusDate: Date;
	fltDiameter: number;
	fltDiscountPercentage: number;
	fltExThickness: number;
	fltExWidth: number;
	fltLength: number;
	fltLengthOLD: number;
	fltMiscPrice: number;
	fltNominalThickness: number;
	fltNominalWidth: number;
	fltOriginalPrice: number;
	fltParquetryBoxes: number;
	fltPrecisionDockingRate: number;
	fltPrice: number;
	fltPriceIncGST: number;
	fltQuantityDeliveried: number;
	fltQuantityOLD: number;
	fltQuantityPaid: number;
	fltQuantityTotal: number;
	fltSetupLMRate: number;
	fltThickness: number;
	fltTotalItemPrice: number;
	fltTotalItemPriceIncGST: number;
	fltWasteFactor: number;
	fltWidth: number;
	guAboveGroundDurabilityClassId: string;
	guCutbackPoleId: string;
	guDurabilityId: string;
	guElectricalAuthoritySpecId: string;
	guEmployeeChangedPriceId: string;
	guGradeId: string;
	guOrderId: string;
	guOriginalPoleId: string;
	guPoleId: string;
	guProductApplicationId: string;
	guProductCodeId: string;
	guProductCodePriceChangeReasonId: string;
	guProductCodePriceId: string;
	guProductProfileId: string;
	guProductTypeId: string;
	guProfileId: string;
	guSpeciesId: string;
	guStockId: string;
	intBPFloorOrderItem: number;
	intComplaintStatus: number;
	intDiameterType: number;
	intDurabilityClassType: number;
	intHazardLevel: number;
	intItemNo: number;
	intLaminatedBoards: number;
	intLengthType: number;
	intLogSection: number;
	intMiscPriceType: number;
	intMoistureLevel: number;
	intPriceType: number;
	intQuantityUoM: number;
	intRippedBoards: number;
	intSap: number;
	intSizeType: number;
	intSpec: number;
	intStatus: number;
	intStrengthGroup: number;
	intStructureGrade: number;
	intStuctureGrade: number;
	intTreatmentType: number;
	intWasteFactorType: number;
	rowguid: string;
	txtAdminComments: string;
	txtComments: string;
	txtComplaintComments: string;
	txtInternalComments: string;
	txtInvoiceNo: string;
	txtMinimumDepositValue: number;
	txtMiscPriceComments: string;
	txtPackingInstruction: string;
	txtProductCodePriceChangeReason: string;
	txtStockCode: string;
}

export class OrderItem
{
	blnAmendment: boolean;
	blnBattens: boolean;
	blnBPFloorOrder: boolean;
	blnComplaint: boolean;
	blnComplaintResolved: boolean;
	blnConsignment: boolean;
	blnConsignmentDelivered: boolean;
	blnDelivered: boolean;
	blnDontShowCube: boolean;
	blnDrilled: boolean;
	blnFinalDelivery: boolean;
	blnInvoiced: boolean;
	blnJoinery: boolean;
	blnMinimumDeposit: boolean;
	blnNextBrisbaneDelivery: boolean;
	blnNextDelivery: boolean;
	blnNextDelivery2: boolean;
	blnNextLocalDelivery: boolean;
	blnNonStandardNominal: boolean;
	blnNonStandardProduct: boolean;
	blnOptional: boolean;
	blnPaidInFull: boolean;
	blnPercisionDocked: boolean;
	blnPrecoated: boolean;
	blnPriceAmendmentRequested: boolean;
	blnPriceAmendmentUpdated: boolean;
	blnPricesAreCorrect: boolean;
	blnProductChangeRequested: boolean;
	blnProductChangeUpdated: boolean;
	blnSetLength: boolean;
	blnShowAboveGroundDurability: boolean;
	blnShowInGroundDurability: boolean;
	blnShowStrengthGroup: boolean;
	dteComplaint: Date;
	dteComplaintResolved: Date;
	dteDataUpdatedDate: Date;
	dteDateCreated: Date;
	dteDeliveryDate: Date;
	dteInvoiceDate: Date;
	dteLastUpdated: Date;
	dteProcessed: Date;
	dteStatusDate: Date;
	fltDiameter: number;
	fltDiscountPercentage: number;
	fltExThickness: number;
	fltExWidth: number;
	fltLength: number;
	fltLengthOLD: number;
	fltMiscPrice: number;
	fltNominalThickness: number;
	fltNominalWidth: number;
	fltOriginalPrice: number;
	fltParquetryBoxes: number;
	fltPrecisionDockingRate: number;
	fltPrice: number;
	fltPriceIncGST: number;
	fltQuantityDeliveried: number;
	fltQuantityOLD: number;
	fltQuantityPaid: number;
	fltQuantityTotal: number;
	fltSetupLMRate: number;
	fltThickness: number;
	fltTotalItemPrice: number;
	fltTotalItemPriceIncGST: number;
	fltWasteFactor: number;
	fltWidth: number;
	guAboveGroundDurabilityClassId: string;
	guCutbackPoleId: string;
	guDurabilityId: string;
	guElectricalAuthoritySpecId: string;
	guEmployeeChangedPriceId: string;
	guGradeId: string;
	guOrderId: string;
	guOriginalPoleId: string;
	guPoleId: string;
	guProductApplicationId: string;
	guProductCodeId: string;
	guProductCodePriceChangeReasonId: string;
	guProductCodePriceId: string;
	guProductProfileId: string;
	guProductTypeId: string;
	guProfileId: string;
	guSpeciesId: string;
	guStockId: string;
	intBPFloorOrderItem: number;
	intComplaintStatus: number;
	intDiameterType: number;
	intDurabilityClassType: number;
	intHazardLevel: number;
	intItemNo: number;
	intLaminatedBoards: number;
	intLengthType: number;
	intLogSection: number;
	intMiscPriceType: number;
	intMoistureLevel: number;
	intPriceType: number;
	intQuantityUoM: number;
	intRippedBoards: number;
	intSap: number;
	intSizeType: number;
	intSpec: number;
	intStatus: number;
	intStrengthGroup: number;
	intStructureGrade: number;
	intStuctureGrade: number;
	intTreatmentType: number;
	intWasteFactorType: number;
	rowguid: string;
	txtAdminComments: string;
	txtComments: string;
	txtComplaintComments: string;
	txtInternalComments: string;
	txtInvoiceNo: string;
	txtMinimumDepositValue: number;
	txtMiscPriceComments: string;
	txtPackingInstruction: string;
	txtProductCodePriceChangeReason: string;
	txtStockCode: string;
}

export namespace OrderItem
{
	export enum enDurabilityClassType
	{
		None,
		InGround,
		AboveGround
	}
}

export namespace OrderItem
{
	export enum enHazardTreatment
	{
		None,
		H1,
		H2,
		H3,
		H4,
		H5,
		H6
	}
}

export namespace OrderItem
{
	export enum enLogSection
	{
		None,
		BH,
		FOH
	}
}

export namespace OrderItem
{
	export enum enMoistureLevel
	{
		None,
		KD,
		AD,
		GOS
	}
}

export namespace OrderItem
{
	export enum enQuantityUoM
	{
		Each,
		M3,
		LM,
		M2,
		KG,
		TON
	}
}

export namespace OrderItem
{
	export enum enSizeType
	{
		None,
		FS,
		CS
	}
}

export namespace OrderItem
{
	export enum enStrengthGroup
	{
		None,
		S1,
		S2,
		S3,
		SD1,
		SD2,
		SD3
	}
}

export namespace OrderItem
{
	export enum enStructureGrade
	{
		None,
		F14,
		F17,
		F22,
		F27,
		F34,
		Landscape
	}
}

export namespace OrderItem
{
	export enum enTreatmentType
	{
		None,
		CCA,
		Boron,
		ACQ
	}
}

