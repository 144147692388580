export interface HaulingCompanyDriver
{
	blnActive: boolean;
	blnDefaultDriver: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guEmployeeId: string;
	guHaulingCompanyId: string;
	rowguid: string;
	txtComments: string;
	txtFirstName: string;
	txtLastName: string;
	txtNickName: string;
}

export class HaulingCompanyDriver
{
	blnActive: boolean;
	blnDefaultDriver: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guEmployeeId: string;
	guHaulingCompanyId: string;
	rowguid: string;
	txtComments: string;
	txtFirstName: string;
	txtLastName: string;
	txtNickName: string;
}

