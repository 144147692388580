import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoPrinterLocation } from '../business-objects/dto-printer-location.bo';

@Injectable()
export class dtoPrinterLocationService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoPrinterLocation(dtoPrinterLocationId: string) {
		return this.http.get<dtoPrinterLocation>(this._baseUrl + 'api/dtoPrinterLocation/' + dtoPrinterLocationId);
	}

	getdtoPrinterLocationParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoPrinterLocation[]>(this._baseUrl + 'api/dtoPrinterLocation/GetdtoPrinterLocationsParamArray/', aParam);
	}

	getAlldtoPrinterLocations() {
		return this.http.get<dtoPrinterLocation[]>(this._baseUrl + 'api/dtoPrinterLocation/GetAlldtoPrinterLocations/');
	}

	//getAlldtodtoPrinterLocations() {
	//	return this.http.get<dtodtoPrinterLocation[]>(this._baseUrl + 'api/dtoPrinterLocation/GetAlldtoPrinterLocations/');
	//}

	createdtoPrinterLocation(dtoPrinterLocation: dtoPrinterLocation) {
		return this.http.post<dtoPrinterLocation>(this._baseUrl + 'api/dtoPrinterLocation/CreatedtoPrinterLocation/', dtoPrinterLocation);
	}

	updatedtoPrinterLocation(dtoPrinterLocation: dtoPrinterLocation) {
		return this.http.put<dtoPrinterLocation>(this._baseUrl + 'api/dtoPrinterLocation/UpdatedtoPrinterLocation/', dtoPrinterLocation);
	}

	deletedtoPrinterLocation(dtoPrinterLocationId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoPrinterLocation/DeletedtoPrinterLocation/' + dtoPrinterLocationId);
	}

	async getdtoPrinterLocationPromise(dtoPrinterLocationId: string) {
		return new Promise<dtoPrinterLocation>(resolve => {
			this.getdtoPrinterLocation(dtoPrinterLocationId).subscribe(dtoPrinterLocation => {
				resolve(dtoPrinterLocation);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoPrinterLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoPrinterLocationParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoPrinterLocation[]>(resolve => {
			this.getdtoPrinterLocationParamArray(aParam).subscribe(dtoPrinterLocations => {
				resolve(dtoPrinterLocations);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoPrinterLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoPrinterLocationPromise(dtoPrinterLocation: dtoPrinterLocation) {
		return new Promise<dtoPrinterLocation>(resolve => {
			this.updatedtoPrinterLocation(dtoPrinterLocation).subscribe(dtoPrinterLocation => {
				resolve(dtoPrinterLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoPrinterLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoPrinterLocationPromise(dtoPrinterLocation: dtoPrinterLocation) {
		return new Promise<dtoPrinterLocation>(resolve => {
			this.createdtoPrinterLocation(dtoPrinterLocation).subscribe(dtoPrinterLocation => {
				resolve(dtoPrinterLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoPrinterLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoPrinterLocationPromise(dtoPrinterLocationId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoPrinterLocation(dtoPrinterLocationId).subscribe(dtoPrinterLocation => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoPrinterLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}