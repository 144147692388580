<div class="stock-for-sale-container">
  <div class="header">
    <div class="header-grid">
      <div class="header-left">
        <a class="navbar-brand" href="https://coffshardwoods.com.au/" title="Coffs Harbour Hardwoods" rel="home">
          <img id="logomain" class="logo" src="https://coffshardwoods.com.au/wp-content/themes/Coffs%20Harbour%20Hardwoods/images/logowhite.png" title="" alt="Coffs Harbour Hardwoods">
        </a>
      </div>
      <div class="header-right">
          <h1>Stock For Sale</h1>
      </div>
    </div>
  </div>

  <div class="stock-item-list-container">
    <div class="stock-item-grid">
      @for(stockItem of stockItems; track stockItem; let idx = $index){
      <div class="product-tile-column">
          <app-product-tile [stockItem]="stockItem"></app-product-tile>
      </div>
      }
    </div>

  </div>
</div>
