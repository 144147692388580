import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatListOption, MatSelectionListChange } from '@angular/material/list';
import { AuthService } from '../../account/auth.service';
import { DiscTemplateConfig } from '../../_shared/business-objects/disc-template-config.bo';
import { dtoIntKeyValue, dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { ElectricalAuthoritySpec } from '../../_shared/business-objects/electrical-authority-spec.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { DiscTemplateConfigService } from '../../_shared/services/disc-template-config.service';
import { ElectricalAuthoritySpecService } from '../../_shared/services/electrical-authority-spec.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-disc-template-config-create',
  templateUrl: './disc-template-config-create.component.html',
  styleUrls: ['./disc-template-config-create.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class DiscTemplateConfigCreateComponent {
  @ViewChild('txtFirstName') txtFirstName: ElementRef;
  @ViewChild('txtLastName') txtLastName: ElementRef;

  form: FormGroup;

  @Output() discTemplateConfigCreated = new EventEmitter<[DiscTemplateConfig, string]>();
  @Output() discTemplateConfigCancelled = new EventEmitter<boolean>();


  public customersFilterCtrl: FormControl = new FormControl();
  public customerContactsFilterCtrl: FormControl = new FormControl();
  public haulersFilterCtrl: FormControl = new FormControl();

  public electricalAuthoritys: ElectricalAuthoritySpec[];
  public electricalAuthoritysAll: ElectricalAuthoritySpec[];

  inputTypes: dtoKeyValue[] = [];
  computerInputFields: dtoIntKeyValue[] = [];

  showSpinner: boolean

  constructor(private formBuilder: FormBuilder, private formService: FormService, private electricalAuthoritySpecService: ElectricalAuthoritySpecService, private sharedService: SharedService
    , private alertService: AlertService, private authService: AuthService
    , private discTemplateConfigService: DiscTemplateConfigService) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      guElectricalAuthoritySpecId: [''],
      txtCSVColumnNumber: [''],
      intInputType: [''],
      txtUserInput: [''],
      intComputerInputField: [''],
      blnActive: [''],
      txtComments: ['']
    });

    this.showSpinner = false;

    this.loadData();

    this.inputTypes = this.sharedService.getEnumObject2(DiscTemplateConfig.enInputType);
    this.computerInputFields = this.sharedService.getEnumObjectKeyValue(DiscTemplateConfig.enComputerInputField);

    this.computerInputFields.splice(0, 0 ,new dtoIntKeyValue(-1, "None"));

    this.form.controls['intInputType'].setValue([0]);
    this.form.controls['intComputerInputField'].setValue('');
    this.form.controls['intComputerInputField'].disable();

    this.form.controls['blnActive'].setValue(1);


  }

  async loadData() {

    await this.getElectricalAuthoritys();
  }


  async getElectricalAuthoritys() {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];
    let electricalAuthoritys = await this.electricalAuthoritySpecService.getElectricalAuthoritySpecParamArrayPromise(paramArrayList);
    if (electricalAuthoritys == null) {
      return;
    }

    electricalAuthoritys.sort((a, b) => { return (a.txtAuthorityName > b.txtAuthorityName) ? 1 : -1 });

    this.electricalAuthoritys = electricalAuthoritys;
    this.electricalAuthoritysAll = this.electricalAuthoritys;
  }

  async filterElectricalAuthoritySpec(name: string) {
    this.electricalAuthoritys = this.electricalAuthoritysAll;

    this.electricalAuthoritys = this.electricalAuthoritys.filter(a => ((a.txtAuthorityName.toLowerCase().indexOf(name.toLowerCase()) > -1)) || a.txtAuthorityName == "None");

 }

  async setNextColumnNumber(electricalAuthoritySpecId: string) {
    //console.log("electricalAuthoritySpecId ", electricalAuthoritySpecId)

    //Get last column number and increase by 1.
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guElectricalAuthoritySpecId", electricalAuthoritySpecId));
    aParamList.push(aParam);

    let discTemplates: DiscTemplateConfig[] = await this.discTemplateConfigService.getDiscTemplateConfigParamArrayPromise(aParamList);
    let nextCSVColumnNumber: number = 0;
    if (discTemplates != null) {
      discTemplates = discTemplates.sort((a, b) => { return a.intCSVColumnNumber - b.intCSVColumnNumber });

      discTemplates.forEach(dc => {
        nextCSVColumnNumber = dc.intCSVColumnNumber + 1;
      });
    }

    this.form.controls['txtCSVColumnNumber'].setValue('');

    if (nextCSVColumnNumber > 0) {
      this.form.controls['txtCSVColumnNumber'].setValue(nextCSVColumnNumber);

    }
  }

  disableInputField(val: MatSelectionListChange) {

    if (val == null) {
      return null;
    }

    let option: MatListOption = val.options[0];

    if (option == null) {
      return null;
    }

    if (option.value == DiscTemplateConfig.enInputType.User) {
      this.form.controls['txtUserInput'].enable();
      this.form.controls['intComputerInputField'].setValue('');
      this.form.controls['intComputerInputField'].disable();
    }

    if (option.value == DiscTemplateConfig.enInputType.Computer) {
      this.form.controls['intComputerInputField'].enable();
      this.form.controls['txtUserInput'].setValue('');
      this.form.controls['txtUserInput'].disable();
    }

    console.log(option.value);

  }


  async createDiscTemplateConfig(mode: string) {

    let electricalAuthoritySpecId: string = this.form.controls['guElectricalAuthoritySpecId'].value[0];
    let csvColumnNumber: number = this.form.controls['txtCSVColumnNumber'].value;
    let inputType: number = this.form.controls['intInputType'].value[0];
    let userInput: string = this.form.controls['txtUserInput'].value;
    let computerInputField: number = this.form.controls['intComputerInputField'].value[0];
    let active: boolean = this.form.controls['blnActive'].value == true;
    let comments: string = this.form.controls['txtComments'].value;

    if (electricalAuthoritySpecId == null || electricalAuthoritySpecId == "") {
      this.alertService.openSnackBar("Please select an Electrical Authority Spec.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (csvColumnNumber == null || csvColumnNumber == 0) {
      this.alertService.openSnackBar("Please enter a CSV column number.", "Close", "center", "bottom", "", 3000);
      return;
    }

    console.log("input type: ", inputType);

    if (inputType == null) {
      this.alertService.openSnackBar("Please select an input type.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if ((computerInputField == null || computerInputField == -1) && inputType == DiscTemplateConfig.enInputType.Computer) {
      this.alertService.openSnackBar("Please select a computer input field.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if ((userInput == null || userInput == "") && inputType == DiscTemplateConfig.enInputType.User) {
      this.alertService.openSnackBar("Please select an Electrical Authority Spec.", "Close", "center", "bottom", "", 3000);
      return;
    }


    let discTemplateConfig: DiscTemplateConfig = new DiscTemplateConfig;
    discTemplateConfig.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
    discTemplateConfig.intCSVColumnNumber = csvColumnNumber;
    discTemplateConfig.intInputType = inputType;
    discTemplateConfig.txtUserInputValue = userInput;

    if (computerInputField == null) {
      computerInputField = -1
    }

    discTemplateConfig.intComputerInputField = computerInputField;
    discTemplateConfig.txtComments = comments;
    discTemplateConfig.blnActive = active;

    discTemplateConfig.guElectricalAuthoritySpecId = electricalAuthoritySpecId;
    if (Array.isArray(electricalAuthoritySpecId) == true) {
      discTemplateConfig.guElectricalAuthoritySpecId = electricalAuthoritySpecId[0];
    }

    this.showSpinner = true;

    console.log("discTemplateConfig: ", discTemplateConfig);
    discTemplateConfig = await this.discTemplateConfigService.createDiscTemplateConfigPromise(discTemplateConfig);

    if (discTemplateConfig != null) {
      console.log(discTemplateConfig.rowguid);
      discTemplateConfig = await this.discTemplateConfigService.getDiscTemplateConfigPromise(discTemplateConfig.rowguid);

      this.alertService.openSnackBar("The disc template has been created", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;
      this.discTemplateConfigCreated.emit([discTemplateConfig, mode]);
    }


    if (discTemplateConfig == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem creating the disc template", "Close", "center", "bottom", 3000, true, "There was a problem creating the driver");
    }



  }

  cancelDiscTemplateConfig() {
    this.discTemplateConfigCancelled.emit(true);
  }

}
