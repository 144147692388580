import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { dtoTPPoleInspectionItem } from '../business-objects/dto-tppole-inspection-item.bo';
import { SharedService } from './shared.service';

@Injectable()
export class dtoTPPoleInspectionItemService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService) {
    this._baseUrl = baseUrl;
  }

  getdtoTPPoleInspectionItem(dtoTPPoleInspectionItemId: string) {
    return this.http.get<dtoTPPoleInspectionItem>(this._baseUrl + 'api/dtoTPPoleInspectionItem/' + dtoTPPoleInspectionItemId);
  }

  getdtoTPPoleInspectionItemParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoTPPoleInspectionItem[]>(this._baseUrl + 'api/dtoTPPoleInspectionItem/GetdtoTPPoleInspectionItemsParamArray/', aParam);
  }

  getAlldtoTPPoleInspectionItems() {
    return this.http.get<dtoTPPoleInspectionItem[]>(this._baseUrl + 'api/dtoTPPoleInspectionItem/GetAlldtoTPPoleInspectionItems/');
  }

  //getAlldtodtoTPPoleInspectionItems() {
  //	return this.http.get<dtodtoTPPoleInspectionItem[]>(this._baseUrl + 'api/dtoTPPoleInspectionItem/GetAlldtoTPPoleInspectionItems/');
  //}

  createdtoTPPoleInspectionItem(dtoTPPoleInspectionItem: dtoTPPoleInspectionItem) {
    return this.http.post<dtoTPPoleInspectionItem>(this._baseUrl + 'api/dtoTPPoleInspectionItem/CreatedtoTPPoleInspectionItem/', dtoTPPoleInspectionItem);
  }

  updatedtoTPPoleInspectionItem(dtoTPPoleInspectionItem: dtoTPPoleInspectionItem) {
    return this.http.post<dtoTPPoleInspectionItem>(this._baseUrl + 'api/dtoTPPoleInspectionItem/UpdatedtoTPPoleInspectionItem/', dtoTPPoleInspectionItem);
  }

  async getdtoTPPoleInspectionItemPromise(finalInspectionItemId: string) {
    return new Promise<dtoTPPoleInspectionItem>(resolve => {

      this.getdtoTPPoleInspectionItem(finalInspectionItemId).subscribe(dtoTPPoleInspectionItem => {
        resolve(dtoTPPoleInspectionItem);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async getdtoTPPoleInspectionItemParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<dtoTPPoleInspectionItem[]>(resolve => {
      this.getdtoTPPoleInspectionItemParamArray(aParam).subscribe(dtoTPPoleInspectionItems => {
        resolve(dtoTPPoleInspectionItems);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async getdtoTPPoleInspectionItemByInspectionIdPromise(finalInspectionId: string) {
    return new Promise<dtoTPPoleInspectionItem[]>(resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guTPPoleInspectionId", finalInspectionId));
      aParamList.push(aParam);

      this.getdtoTPPoleInspectionItemParamArray(aParamList).subscribe(dtoTPPoleInspectionItems => {
        resolve(dtoTPPoleInspectionItems);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async getdtoTPPoleInspectionItemByDeliveryIdPromise(deliveryDocketId: string) {
    return new Promise<dtoTPPoleInspectionItem[]>(resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocketId));
      aParamList.push(aParam);

      this.getdtoTPPoleInspectionItemParamArray(aParamList).subscribe(dtoTPPoleInspectionItems => {
        resolve(dtoTPPoleInspectionItems);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async downloadDeliveryDockect(finalItems: dtoTPPoleInspectionItem[]) {

    //return new Promise(resolve => {
    //  let path: string = this.sharedService.PrintServerURI;
    //  //path = "https://localhost:44358/";
    //  //console.log("in downloadLoadingDockect", deliveryDocketItems);
    //  this.http.post(path + 'api/PrintDeliveryDocket/DownloadDeliveryDocket/', deliveryDocketItems, { responseType: 'blob' }).subscribe(pdf => {
    //    let mimeType: string = 'application/pdf';
    //    let blob: any = new Blob([pdf], { type: mimeType });
    //    const url = window.URL.createObjectURL(blob);
    //    //console.log("opening widow");
    //    window.open(url);
    //    resolve(pdf);
    //  }, err => {
    //    console.log(err);
    //    resolve(null);
    //  });
    //});
  }

}
