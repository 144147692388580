import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';
import { dtoCustomer } from '../../_shared/business-objects/dto-customer.bo';
import { dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { AddressService } from '../../_shared/services/address';
import { AlertService } from '../../_shared/services/alert.service';
import { CustomerContactService } from '../../_shared/services/customer-contact.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-customer-contact-create',
  templateUrl: './customer-contact-create.component.html',
  styleUrl: './customer-contact-create.component.css'
})
export class CustomerContactCreateComponent implements OnInit {

  form: FormGroup;

  @Input() customerId: string;
  @Input() customerName: string;
  @Output() customerContactCreated = new EventEmitter<[CustomerContact, string]>();
  @Output() customerContactCancelled = new EventEmitter<boolean>();

  states: dtoKeyValue[] = [];
  postcodes: Object[];

  showSpinner: boolean

  constructor(private formBuilder: FormBuilder, private sharedService: SharedService, private alertService: AlertService
              , private customerContactService: CustomerContactService, private formService: FormService, private addressService: AddressService) {

  }

  ngOnInit(): void {

    this.states = this.sharedService.getEnumObject2(SharedService.enStates);

    this.form = this.formBuilder.group({
      txtCustomerName: [this.customerName],
      txtFirstName: ['', Validators.required],
      txtLastName: [''],
      txtAddress1: [''],
      txtAddress2: [''],
      txtTown: [''],
      txtState: [['NSW']],
      txtPostcode: [''],
      txtPhone1: [''],
      txtPhone2: [''],
      txtMobile: [''],
      txtFax: [''],
      txtEmail: [''],
      blnActive: [1]
    });

    
    this.showSpinner = false;

    this.formService.setAutoFocusTimeout("txtFirstName", 300);
    
  }

  async lookupPostcode(town: string, state: string) {
   
    let postCodes: Object[];
    let postCodePromise = this.addressService.getPostCodePromise(town, state);

    await postCodePromise.then(res => postCodes = res);

    if (postCodes != null && Array.isArray(postCodes) && postCodes.length > 0) {

      if (postCodes.length > 1) {
        this.postcodes = postCodes;
        return;
      }

      //console.log("postcode: ", postCodes);

      if (this.form.controls['txtPostcode'].value != null && this.form.controls['txtPostcode'].value != "") {
        let msg: string = await this.alertService.openSnackBarCustomPromise("Existing Postcode", "There is an existing Postcode in the postcode field.\nDo you want to overwrite it?", "Yes", "No", "center", "bottom", "", 0, true);

        if (msg != "Yes") {
          return;
        }
      }

      this.form.controls['txtPostcode'].setValue(postCodes[0]['postcode']);

    }

  }

  selectPostcode(postcode: string) {

    if (postcode == "") {
      return;
    }

    this.form.controls['txtPostcode'].setValue(postcode);

    this.postcodes = null;

  }


  async createCustomerContact(mode: string) {

    let firstName: string = this.form.controls['txtFirstName'].value;
    let lastName: string = this.form.controls['txtLastName'].value;
    let address1: string = this.form.controls['txtAddress1'].value;
    let address2: string = this.form.controls['txtAddress2'].value;
    let town: string = this.form.controls['txtTown'].value;
    let state: string = this.form.controls['txtState'].value[0];
    let postcode: string = this.form.controls['txtPostcode'].value;
    let phone1: string = this.form.controls['txtPhone1'].value;
    let phone2: string = this.form.controls['txtPhone2'].value;
    let mobile: string = this.form.controls['txtMobile'].value;
    let fax: string = this.form.controls['txtFax'].value;
    let email: string = this.form.controls['txtEmail'].value;
    let active: boolean = (this.form.controls['blnActive'].value == 1);


    if (firstName == null || firstName == "") {
      this.alertService.openSnackBar("Please enter a contact first name.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (lastName == null || lastName == "") {
      await this.alertService.openSnackBarCustomPromise("Last Name", "Please contacts last name is empty.\nPlease remember to the get contacts last name when possible.", "Ok", "", "center", "bottom", "", 0, false);
    }

    if ((phone1 == null && mobile == null && email == null) || (phone1 == "" && mobile == "" && email == "")) {
      this.alertService.openSnackBarCustomPromise("Contact Info", "Please enter either a phone number, mobile number or email so the contact can be contacted.", "Ok", "", "center", "bottom", "", 0, false);
      return;
    }


    let customerContact: CustomerContact = new CustomerContact;
    customerContact.guCustomerId = this.customerId;
    customerContact.txtName = firstName;
    customerContact.txtLastName = lastName;
    customerContact.txtAddress1 = address1;
    customerContact.txtAddress2 = address2;
    customerContact.txtTown = town;
    customerContact.txtState = state;
    customerContact.txtPostCode = String(postcode);
    customerContact.txtPhone1 = phone1;
    customerContact.txtPhone2 = phone2;
    customerContact.txtMobile = mobile;
    customerContact.txtFax = fax;
    customerContact.txtEmail = email;
    customerContact.blnActive = active;


    this.showSpinner = true;

    console.log(customerContact);

    customerContact = await this.customerContactService.createCustomerContactPromise(customerContact);

    if (customerContact != null) {
      console.log(customerContact.rowguid);
      customerContact = await this.customerContactService.getCustomerContactPromise(customerContact.rowguid);


      this.alertService.openSnackBar("The customer contact has been created", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;
      this.customerContactCreated.emit([customerContact, mode]);
    }


    if (customerContact == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem creating the customer contact", "Close", "center", "bottom", 4000, true, "There was a problem creating the customer contact");
    }

  }

  cancelCustomerContact() {
    this.customerContactCancelled.emit(true);

  }



}
