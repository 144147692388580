import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dtoDeliveryDocket } from '../business-objects/dto-delivery-docket.bo';
import { DeliveryDocket } from '../business-objects/delivery-docket.bo';

import { SharedService } from './shared.service';
import { dtoKeyValue } from '../business-objects/dto-int-key-value.bo';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { dtoDeliveryDocketItem } from '../business-objects/dto-delivery-docket-item.bo';
import { FileService } from './app-services/file.service';

@Injectable()
export class dtoDeliveryDocketService
{

	public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService, private fileService: FileService) {
		this._baseUrl = baseUrl;
	}

	getdtoDeliveryDocket(dtoDeliveryDocketId: string) {
		return this.http.get<dtoDeliveryDocket>(this._baseUrl + 'api/dtoDeliveryDocket/' + dtoDeliveryDocketId);
	}

	getAlldtoDeliveryDockets() {
		return this.http.get<dtoDeliveryDocket[]>(this._baseUrl + 'api/dtoDeliveryDocket/GetAlldtoDeliveryDockets/');
  }

  getReadyForDispatchdtoDeliveryDockets() {
    return this.http.get<dtoDeliveryDocket[]>(this._baseUrl + 'api/dtoDeliveryDocket/GetdtoDeliveryDockets?status=' + DeliveryDocket.enDeliveryStatus.ReadyForDispatch);
  }

  getdtoDeliveryDocketsParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoDeliveryDocket[]>(this._baseUrl + 'api/dtoDeliveryDocket/GetdtoDeliveryDocketsParamArray', aParam);
  }

  //getdtoDeliveryDocketsWithParam(aParam: SQLParamArray[][]) {
  //  return this.http.post<dtoDeliveryDocket[]>(this._baseUrl + 'api/dtoDeliveryDocket/GetdtoDeliveryDocketsWithParamArray', aParam);
  //}


  getdtoDeliveryDockets(emailedInvoice: boolean = null, uploadedInvoiceToPortal: boolean = null, customerId: string = null, deliveryType: number[] = null, docketNo: number[] = null, createdDate: Date[] = null, deliveryDate: Date[] = null, loaded: Date[] = null, loadingDocketDateLoaded: Date[] = null, statusDate: Date[] = null, additionalKM: number[] = null, deliveryCharge: number[] = null, escort: number[] = null, customerContactId: string = null, haulingId: string[] = null, tpPoleInspectionId: string[] = null, loadingDocketNo: number[] = null, paymentType: number[] = null, status: number[] = null, system: number[] = null, comments: string[] = null, invoiceNo: string[] = null) {
    let params: string = "";
    if (docketNo != null && docketNo.length > 0) {
      params = "docketNo"
    }
    return this.http.get<dtoDeliveryDocket[]>(this._baseUrl + 'api/dtoDeliveryDocket/GetdtoDeliveryDockets?status=' + DeliveryDocket.enDeliveryStatus.ReadyForDispatch);
  }

	//getAlldtodtoDeliveryDockets() {
	//	return this.http.get<dtodtoDeliveryDocket[]>(this._baseUrl + 'api/dtoDeliveryDocket/GetAlldtoDeliveryDockets/');
	//}

  async getDeliveryDocketPromise(deliveryDocketId: string) {
    return new Promise<dtoDeliveryDocket>(resolve => {
      this.getdtoDeliveryDocket(deliveryDocketId).subscribe(deliveryDocket => {
        resolve(deliveryDocket);
      }, err => {
        console.log(err);
      });
    })
  }

  async getDeliveryDocketPromiseParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<dtoDeliveryDocket[]>(resolve => {
      this.getdtoDeliveryDocketsParamArray(aParam).subscribe(deliveryDocket => {
        resolve(deliveryDocket);
      }, err => {
        console.log(err);
      });
    })
  }

  async getDeliveryDocketPromiseByDocketNo(deliveryDocketNo: number) {
    return new Promise<dtoDeliveryDocket>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("intDocketNo", deliveryDocketNo.toString()));
      aParamList.push(aParam);

      this.getdtoDeliveryDocketsParamArray(aParamList).subscribe(deliveryDockets => {
        if (deliveryDockets != null) {
          resolve(deliveryDockets[0]);
        }
      }, err => {
        console.log(err);
      });
    })
  }

	createdtoDeliveryDocket(dtoDeliveryDocket: dtoDeliveryDocket) {
		return this.http.post<dtoDeliveryDocket>(this._baseUrl + 'api/dtoDeliveryDocket/CreatedtoDeliveryDocket/', dtoDeliveryDocket);
	}

	updatedtoDeliveryDocket(dtoDeliveryDocket: dtoDeliveryDocket) {
		return this.http.post<dtoDeliveryDocket>(this._baseUrl + 'api/dtoDeliveryDocket/UpdatedtoDeliveryDocket/', dtoDeliveryDocket);
  }

  async downloadDeliveryDockect(deliveryDocketItems: dtoDeliveryDocketItem[]) {

    return new Promise(resolve => {
      let path: string = this.sharedService.PrintServerURI;
      //path = "https://localhost:44358/";
      //console.log("in downloadLoadingDockect", deliveryDocketItems);
      this.http.post(path + 'api/PrintDeliveryDocket/DownloadDeliveryDocket/', deliveryDocketItems, { responseType: 'blob' }).subscribe(pdf => {
        let mimeType: string = 'application/pdf';
        let blob: any = new Blob([pdf], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        //console.log("opening widow");
        window.open(url);
        resolve(pdf);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async downloadAusgridCSV(deliveryDocket: dtoDeliveryDocket) {

    return new Promise<Blob>(resolve => {
      this.http.post(this._baseUrl + 'api/dtoDeliveryDocket/CreatedAusgridCSV/', deliveryDocket, { responseType: 'blob' }).subscribe(async csv => {
        resolve(csv);

      //  let mimeType: string = 'application/pdf';
      //  let blob: any = new Blob([pdf], { type: mimeType });
      //  const url = window.URL.createObjectURL(blob);
      //  //console.log("opening widow");
      //  window.open(url);
      //  resolve(pdf);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async printDeliveryDocket(arrBuff: ArrayBuffer) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/octet-stream'
    });
    this.http.post<Blob>("https://localhost:44358/api/Print/SaveFile/", arrBuff, { headers: headers, responseType: 'blob' as 'json' }).subscribe(data => {
      //console.log("data: " + data);
    });

  }

}
