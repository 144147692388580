export interface dtoHaulingCompany
{
	blnActive: boolean;
	blnBoardPlant: boolean;
	blnCHHVehicle: boolean;
	blnDeliversContractCutLogs: boolean;
	blnForestry: boolean;
	blnOrders: boolean;
	blnPrivateProperty: boolean;
	blnRecipientInvoice: boolean;
	dteLastUpdated: Date;
	fltTonneCapacity: number;
	guMobilePlantEquipmentId: string;
	memComments: string;
	rowguid: string;
	txtAbbreviation: string;
	txtAddress1: string;
	txtAddress2: string;
	txtAdminEmail: string;
	txtCorrespondenceEmail: string;
	txtCountry: string;
	txtFax: string;
	txtMobile: string;
	txtMobilePlantAbbreviation: string;
	txtMobilePlantCategoryName: string;
	txtMobilePlantEquipmentName: string;
	txtName: string;
	txtPhone1: string;
	txtPhone2: string;
	txtPreviousRego: string;
	txtState: string;
	txtTown: string;
}

export class dtoHaulingCompany
{
	blnActive: boolean;
	blnBoardPlant: boolean;
	blnCHHVehicle: boolean;
	blnDeliversContractCutLogs: boolean;
	blnForestry: boolean;
	blnOrders: boolean;
	blnPrivateProperty: boolean;
	blnRecipientInvoice: boolean;
	dteLastUpdated: Date;
	fltTonneCapacity: number;
	guMobilePlantEquipmentId: string;
	memComments: string;
	rowguid: string;
	txtAbbreviation: string;
	txtAddress1: string;
	txtAddress2: string;
	txtAdminEmail: string;
	txtCorrespondenceEmail: string;
	txtCountry: string;
	txtFax: string;
	txtMobile: string;
	txtMobilePlantAbbreviation: string;
	txtMobilePlantCategoryName: string;
	txtMobilePlantEquipmentName: string;
	txtName: string;
	txtPhone1: string;
	txtPhone2: string;
	txtPreviousRego: string;
	txtState: string;
	txtTown: string;
}

