import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { DiscTemplateConfig } from '../business-objects/disc-template-config.bo';

@Injectable()
export class DiscTemplateConfigService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getDiscTemplateConfig(discTemplateConfigId: string) {
		return this.http.get<DiscTemplateConfig>(this._baseUrl + 'api/DiscTemplateConfig/' + discTemplateConfigId);
	}

	getDiscTemplateConfigParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<DiscTemplateConfig[]>(this._baseUrl + 'api/DiscTemplateConfig/GetDiscTemplateConfigsParamArray/', aParam);
	}

	getAllDiscTemplateConfigs() {
		return this.http.get<DiscTemplateConfig[]>(this._baseUrl + 'api/DiscTemplateConfig/GetAllDiscTemplateConfigs/');
	}

	//getAlldtoDiscTemplateConfigs() {
	//	return this.http.get<dtoDiscTemplateConfig[]>(this._baseUrl + 'api/DiscTemplateConfig/GetAllDiscTemplateConfigs/');
	//}

	createDiscTemplateConfig(discTemplateConfig: DiscTemplateConfig) {
		return this.http.post<DiscTemplateConfig>(this._baseUrl + 'api/DiscTemplateConfig/CreateDiscTemplateConfig/', discTemplateConfig);
	}

	updateDiscTemplateConfig(discTemplateConfig: DiscTemplateConfig) {
		return this.http.put<DiscTemplateConfig>(this._baseUrl + 'api/DiscTemplateConfig/UpdateDiscTemplateConfig/', discTemplateConfig);
	}

  deleteDiscTemplateConfig(discTemplateConfigId: string) {
    return this.http.delete(this._baseUrl + 'api/DiscTemplateConfig/DeleteDiscTemplateConfig/' + discTemplateConfigId);
  }

	async getDiscTemplateConfigPromise(discTemplateConfigId: string) {
		return new Promise<DiscTemplateConfig>(resolve => {
			this.getDiscTemplateConfig(discTemplateConfigId).subscribe(discTemplateConfig => {
				resolve(discTemplateConfig);
			}, err => {
				this.alertService.openSnackBarError("Error getting DiscTemplateConfig records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getDiscTemplateConfigParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<DiscTemplateConfig[]>(resolve => {
			this.getDiscTemplateConfigParamArray(aParam).subscribe(discTemplateConfigs => {
				resolve(discTemplateConfigs);
			}, err => {
				this.alertService.openSnackBarError("Error getting DiscTemplateConfig records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateDiscTemplateConfigPromise(discTemplateConfig: DiscTemplateConfig) {
		return new Promise<DiscTemplateConfig>(resolve => {
			this.updateDiscTemplateConfig(discTemplateConfig).subscribe(discTemplateConfig => {
				resolve(discTemplateConfig);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating DiscTemplateConfig record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createDiscTemplateConfigPromise(discTemplateConfig: DiscTemplateConfig) {
		return new Promise<DiscTemplateConfig>(resolve => {
			this.createDiscTemplateConfig(discTemplateConfig).subscribe(discTemplateConfig => {
				resolve(discTemplateConfig);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating DiscTemplateConfig record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async deleteDiscTemplateConfigPromise(discTemplateConfigId: string) {
    return new Promise<boolean>(resolve => {
      this.deleteDiscTemplateConfig(discTemplateConfigId).subscribe(discTemplateConfig => {
        resolve(true);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error removing DiscTemplateConfig record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(false);
      });
    });
  }

}
