export interface DeliveryDocketStockTransfer
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guDeliveryDocketId: string;
	guStockContainerId: string;
	intItemNo: number;
	rowguid: string;
}

export class DeliveryDocketStockTransfer
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guDeliveryDocketId: string;
	guStockContainerId: string;
	intItemNo: number;
	rowguid: string;
}

