<div class="machine-run-dialog-container">
  <div class="machine-run-dialog-form-container">
    <div class="machine-run-dialog-form">
      <h1>Machine Runs</h1>
      <div class="header-container">
        <div class="fields-container">
          <div class="fields-row-1">
            <div class="moulder-select-container">
              <mat-form-field appearance="fill">
                <mat-label>Moulder</mat-label>
                <mat-select id="guMoulderId" #guMoulderId (selectionChange)="getMachineRuns(guMoulderId._value)">
                  <mat-option *ngFor="let moulder of moulders" [value]="moulder.rowguid">{{moulder.txtName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="selected-machine-run-column">
            <div class="selected-macheine-runs" *ngFor="let selectedMachineRun of machineRunsSelected; let i = index">
              <div class="selected-machine-run-moulder">{{selectedMachineRun.txtMoulderName}}</div>
              <div class="selected-machine-run-number">{{selectedMachineRun.intRunNo}}</div>
              <div class="selected-machine-run-charge-no">{{selectedMachineRun.txtChargeNo}}</div>
              <div class="selected-machine-run-width">{{selectedMachineRun.fltWidth}}</div>
              <div class="selected-machine-run-thickness">{{selectedMachineRun.fltThickness}}</div>
              <div class="selected-machine-run-species">{{selectedMachineRun.txtSpeciesName}}</div>
            </div>

          </div>
          <div class="fields-row-2">
            <div class="header-row-4">
              <div class="machine-runs">
                <mat-selection-list class="machine-run-select" #guMachineRunId [multiple]="true">
                  <mat-list-option *ngFor="let machineRun of machineRuns" [value]="machineRun" (click)="machineRunSelected(machineRun, guMoulderId._value)">
                    <div class="options-container"><div class="option-run-no">{{machineRun.intRunNo}}</div><div class="option-charge-no">{{machineRun.txtChargeNo != null ? machineRun.txtChargeNo : ''}}</div><div class="option-size">{{machineRun.fltWidth}} x {{machineRun.fltThickness}}</div><div class="option-species-1">{{machineRun.txtSpeciesName}}</div><div class="option-species-2">{{machineRun.txtSpecies2Name != null ? machineRun.txtSpecies2Name : ''}}</div><div class="option-species-3">{{machineRun.txtSpecies3Name != null ? machineRun.txtSpecies3Name : ''}}</div></div>
                  </mat-list-option>
                </mat-selection-list>
                <br />
              </div>
            </div>
          </div>
        </div>
          <div class="action-button-container">
            <button class="action-button" mat-flat-button color="primary" (click)="selectMachineRuns()" [disabled]="this.machineRunsSelected == null || this.machineRunsSelected.length == 0">Select</button>
            <button class="action-button" mat-flat-button color="primary" (click)="machineRunCancelled(true)">Cancel</button>
          </div>
        </div>
    </div>
  </div>
</div>

<div class="spinner-container">
  <mat-card [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showSpinner">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
