import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DBFieldFunction, SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoTaxInvoiceProductSummary } from '../business-objects/dto-tax-invoice-product-summary.bo';

@Injectable()
export class dtoTaxInvoiceProductSummaryService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoTaxInvoiceProductSummary(dtoTaxInvoiceProductSummaryId: string) {
		return this.http.get<dtoTaxInvoiceProductSummary>(this._baseUrl + 'api/dtoTaxInvoiceProductSummary/' + dtoTaxInvoiceProductSummaryId);
	}

	getdtoTaxInvoiceProductSummaryParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoTaxInvoiceProductSummary[]>(this._baseUrl + 'api/dtoTaxInvoiceProductSummary/GetdtoTaxInvoiceProductSummaryParamArray/', aParam);
	}

  getdtoTaxInvoiceWeeklyProductSummaryParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoTaxInvoiceProductSummary[]>(this._baseUrl + 'api/dtoTaxInvoiceProductSummary/GetdtoTaxInvoiceWeeklyProductSummaryParamArray/', aParam);
  }

  getdtoTaxInvoiceMonthlyProductSummaryParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoTaxInvoiceProductSummary[]>(this._baseUrl + 'api/dtoTaxInvoiceProductSummary/GetdtoTaxInvoiceMonthlyProductSummaryParamArray/', aParam);
  }

	getAlldtoTaxInvoiceProductSummarys() {
		return this.http.get<dtoTaxInvoiceProductSummary[]>(this._baseUrl + 'api/dtoTaxInvoiceProductSummary/GetAlldtoTaxInvoiceProductSummary/');
	}

	//getAlldtodtoTaxInvoiceProductSummarys() {
	//	return this.http.get<dtodtoTaxInvoiceProductSummary[]>(this._baseUrl + 'api/dtoTaxInvoiceProductSummary/GetAlldtoTaxInvoiceProductSummary/');
	//}

	createdtoTaxInvoiceProductSummary(dtoTaxInvoiceProductSummary: dtoTaxInvoiceProductSummary) {
		return this.http.post<dtoTaxInvoiceProductSummary>(this._baseUrl + 'api/dtoTaxInvoiceProductSummary/CreatedtoTaxInvoiceProductSummary/', dtoTaxInvoiceProductSummary);
	}

	updatedtoTaxInvoiceProductSummary(dtoTaxInvoiceProductSummary: dtoTaxInvoiceProductSummary) {
		return this.http.put<dtoTaxInvoiceProductSummary>(this._baseUrl + 'api/dtoTaxInvoiceProductSummary/UpdatedtoTaxInvoiceProductSummary/', dtoTaxInvoiceProductSummary);
	}

	deletedtoTaxInvoiceProductSummary(dtoTaxInvoiceProductSummaryId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoTaxInvoiceProductSummary/DeletedtoTaxInvoiceProductSummary/' + dtoTaxInvoiceProductSummaryId);
	}

	async getdtoTaxInvoiceProductSummaryPromise(dtoTaxInvoiceProductSummaryId: string) {
		return new Promise<dtoTaxInvoiceProductSummary>(resolve => {
			this.getdtoTaxInvoiceProductSummary(dtoTaxInvoiceProductSummaryId).subscribe(dtoTaxInvoiceProductSummary => {
				resolve(dtoTaxInvoiceProductSummary);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTaxInvoiceProductSummary records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoTaxInvoiceProductSummaryByDate(dateFrom: string, dateTo: string, saleLocationId: string) {

    return new Promise<dtoTaxInvoiceProductSummary[]>(resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guSaleLocationId", saleLocationId));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("dteTaxInvoiceDateCreated", dateFrom, SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND, new DBFieldFunction(SQLParamArray.enDBFieldFunction.CastDate, "", "")));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("dteTaxInvoiceDateCreated", dateTo, SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND, new DBFieldFunction(SQLParamArray.enDBFieldFunction.CastDate, "", "")));
      aParamList.push(aParam);

      this.getdtoTaxInvoiceProductSummaryParamArray(aParamList).subscribe(dtoTaxInvoiceProductSummary => {
        resolve(dtoTaxInvoiceProductSummary);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoTaxInvoiceProductSummary records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getdtoTaxInvoiceProductSummaryByWeekNumber(year: number, week: number, saleLocationId: string) {

    return new Promise<dtoTaxInvoiceProductSummary[]>(resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guSaleLocationId", saleLocationId));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("intYear", year.toString()));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("intWeek", week.toString()));
      aParamList.push(aParam);

      this.getdtoTaxInvoiceWeeklyProductSummaryParamArray(aParamList).subscribe(dtoTaxInvoiceProductSummary => {
        resolve(dtoTaxInvoiceProductSummary);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoTaxInvoiceProductSummary records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getdtoTaxInvoiceProductSummaryByMonthNumber(year: number, month: number, saleLocationId: string) {

    return new Promise<dtoTaxInvoiceProductSummary[]>(resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guSaleLocationId", saleLocationId));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("intYear", year.toString()));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("intMonth", month.toString()));
      aParamList.push(aParam);

      this.getdtoTaxInvoiceMonthlyProductSummaryParamArray(aParamList).subscribe(dtoTaxInvoiceProductSummary => {
        resolve(dtoTaxInvoiceProductSummary);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoTaxInvoiceProductSummary records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getdtoTaxInvoiceProductSummaryByQuarter(quarter: number, year: number, saleLocationId: string) {

    return new Promise<dtoTaxInvoiceProductSummary[]>(resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guSaleLocationId", saleLocationId));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("intYear", year.toString()));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("intFYQuarter", quarter.toString()));
      aParamList.push(aParam);

      this.getdtoTaxInvoiceProductSummaryParamArray(aParamList).subscribe(dtoTaxInvoiceProductSummary => {
        resolve(dtoTaxInvoiceProductSummary);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoTaxInvoiceProductSummary records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getdtoTaxInvoiceProductSummaryParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoTaxInvoiceProductSummary[]>(resolve => {
			this.getdtoTaxInvoiceProductSummaryParamArray(aParam).subscribe(dtoTaxInvoiceProductSummary => {
				resolve(dtoTaxInvoiceProductSummary);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTaxInvoiceProductSummary records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoTaxInvoiceProductSummaryPromise(dtoTaxInvoiceProductSummary: dtoTaxInvoiceProductSummary) {
		return new Promise<dtoTaxInvoiceProductSummary>(resolve => {
			this.updatedtoTaxInvoiceProductSummary(dtoTaxInvoiceProductSummary).subscribe(dtoTaxInvoiceProductSummary => {
				resolve(dtoTaxInvoiceProductSummary);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoTaxInvoiceProductSummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoTaxInvoiceProductSummaryPromise(dtoTaxInvoiceProductSummary: dtoTaxInvoiceProductSummary) {
		return new Promise<dtoTaxInvoiceProductSummary>(resolve => {
			this.createdtoTaxInvoiceProductSummary(dtoTaxInvoiceProductSummary).subscribe(dtoTaxInvoiceProductSummary => {
				resolve(dtoTaxInvoiceProductSummary);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoTaxInvoiceProductSummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoTaxInvoiceProductSummaryPromise(dtoTaxInvoiceProductSummaryId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoTaxInvoiceProductSummary(dtoTaxInvoiceProductSummaryId).subscribe(dtoTaxInvoiceProductSummary => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoTaxInvoiceProductSummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
