export interface BoardTallyRecordProductProfile
{
	dteLastUpdated: Date;
	guBoardTallyRecordId: string;
	guProductProfileId: string;
	rowguid: string;
}

export class BoardTallyRecordProductProfile
{
	dteLastUpdated: Date;
	guBoardTallyRecordId: string;
	guProductProfileId: string;
	rowguid: string;
}

