export interface TPPoleInspection
{
	blnButtsMarkedCorrectly: boolean;
	blnCCAHazardStamp: boolean;
	blnCHHBranded: boolean;
	blnInspectedStamp: boolean;
	blnParkedFinal: boolean;
	blnSurfaceClean: boolean;
	dteDateInspected: Date;
	dteLastUpdated: Date;
	guElectricalAuthoritySpecId: string;
	guInspectedById: string;
	intCCAHazardLevel: number;
	intFinalNo: number;
	intLoadNo: number;
	intMaxLoads: number;
	intParkedSpec: number;
	intStatus: number;
	rowguid: string;
	txtComments: string;
	txtParkedSpec: string;
}

export class TPPoleInspection
{
	blnButtsMarkedCorrectly: boolean;
	blnCCAHazardStamp: boolean;
	blnCHHBranded: boolean;
	blnInspectedStamp: boolean;
	blnParkedFinal: boolean;
	blnSurfaceClean: boolean;
	dteDateInspected: Date;
	dteLastUpdated: Date;
	guElectricalAuthoritySpecId: string;
	guInspectedById: string;
	intCCAHazardLevel: number;
	intFinalNo: number;
	intLoadNo: number;
	intMaxLoads: number;
	intParkedSpec: number;
	intStatus: number;
	rowguid: string;
	txtComments: string;
	txtParkedSpec: string;
}

export namespace TPPoleInspection
{
	export enum enHazardLevel
	{
		None,
		H1,
		H2,
		H3,
		H4,
		H5,
		H6
	}
}

export namespace TPPoleInspection
{
	export enum enOnDeliveryDocket
	{
		No,
		Yes,
		Either
	}
}

export namespace TPPoleInspection
{
	export enum enStatus
	{
		InProgress,
		Pending,
		ReadyForLoading,
		Complete,
		PolesParked
	}
}

