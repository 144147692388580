import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { PrinterLocation } from '../business-objects/printer-location.bo';

@Injectable()
export class PrinterLocationService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getPrinterLocation(printerLocationId: string) {
		return this.http.get<PrinterLocation>(this._baseUrl + 'api/PrinterLocation/' + printerLocationId);
	}

	getPrinterLocationParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<PrinterLocation[]>(this._baseUrl + 'api/PrinterLocation/GetPrinterLocationsParamArray/', aParam);
	}

	getAllPrinterLocations() {
		return this.http.get<PrinterLocation[]>(this._baseUrl + 'api/PrinterLocation/GetAllPrinterLocations/');
	}

	//getAlldtoPrinterLocations() {
	//	return this.http.get<dtoPrinterLocation[]>(this._baseUrl + 'api/PrinterLocation/GetAllPrinterLocations/');
	//}

	createPrinterLocation(printerLocation: PrinterLocation) {
		return this.http.post<PrinterLocation>(this._baseUrl + 'api/PrinterLocation/CreatePrinterLocation/', printerLocation);
	}

	updatePrinterLocation(printerLocation: PrinterLocation) {
		return this.http.put<PrinterLocation>(this._baseUrl + 'api/PrinterLocation/UpdatePrinterLocation/', printerLocation);
	}

	deletePrinterLocation(printerLocationId: string) {
		return this.http.delete(this._baseUrl + 'api/PrinterLocation/DeletePrinterLocation/' + printerLocationId);
	}

	async getPrinterLocationPromise(printerLocationId: string) {
		return new Promise<PrinterLocation>(resolve => {
			this.getPrinterLocation(printerLocationId).subscribe(printerLocation => {
				resolve(printerLocation);
			}, err => {
				this.alertService.openSnackBarError("Error getting PrinterLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getPrinterLocationParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<PrinterLocation[]>(resolve => {
			this.getPrinterLocationParamArray(aParam).subscribe(printerLocations => {
				resolve(printerLocations);
			}, err => {
				this.alertService.openSnackBarError("Error getting PrinterLocation records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatePrinterLocationPromise(printerLocation: PrinterLocation) {
		return new Promise<PrinterLocation>(resolve => {
			this.updatePrinterLocation(printerLocation).subscribe(printerLocation => {
				resolve(printerLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating PrinterLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createPrinterLocationPromise(printerLocation: PrinterLocation) {
		return new Promise<PrinterLocation>(resolve => {
			this.createPrinterLocation(printerLocation).subscribe(printerLocation => {
				resolve(printerLocation);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating PrinterLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletePrinterLocationPromise(printerLocationId: string) {
		return new Promise<boolean>(resolve => {
			this.deletePrinterLocation(printerLocationId).subscribe(printerLocation => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing PrinterLocation record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}