import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SQLParamArray } from '../../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../../_shared/services/alert.service';
import { FormService } from '../../../_shared/services/form.service';
import { SharedService } from '../../../_shared/services/shared.service';
import { Orders } from '../../../_shared/business-objects/orders.bo';
import { dtoCustomer } from '../../../_shared/business-objects/dto-customer.bo';
import { dtoCustomerContact } from '../../../_shared/business-objects/dto-customer-contact.bo';
import { dtoCustomerDeliveryAddress } from '../../../_shared/business-objects/dto-customer-delivery-address.bo';
import { dtoOrders } from '../../../_shared/business-objects/dto-orders.bo';
import { dtoCustomerService } from '../../../_shared/services/dto-customer.service';
import { dtoCustomerContactService } from '../../../_shared/services/dto-customer-contact.service';
import { dtoCustomerDeliveryAddressService } from '../../../_shared/services/dto-customer-delivery-address.service';
import { OrdersService } from '../../../_shared/services/orders.service';
import { dtoOrdersService } from '../../../_shared/services/dto-orders.service';
import { Employee } from '../../../_shared/business-objects/employee.bo';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { CustomerContactSelectComponent } from '../../../customer-contact/customer-contact-select/customer-contact-select.component';


@Component({
		selector: 'app-quote-create',
		templateUrl: './quote-create.component.html',
  styleUrl: './quote-create.component.scss',
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ],
  encapsulation: ViewEncapsulation.None
})

export class QuoteCreateComponent {
		@Output() quoteCreated = new EventEmitter<{ quote: dtoOrders, type: string }>();
		@Output() quoteCancelled = new EventEmitter<boolean>();

  @ViewChild("appCustomerContact") appCustomerContact: CustomerContactSelectComponent;


		form: FormGroup;


  customers: dtoCustomer[];
	customerContacts: dtoCustomerContact[];
  customerDeliveryAddresses: dtoCustomerDeliveryAddress[];
  customerDeliveryAddress2s: dtoCustomerDeliveryAddress[];
  customerDeliveryAddress3s: dtoCustomerDeliveryAddress[];
  customerDeliveryAddress4s: dtoCustomerDeliveryAddress[];
  customerDeliveryContacts: dtoCustomerContact[];
  quotedByEmployees: Employee[];

		showSpinner: boolean

		constructor(private formBuilder: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private formService: FormService
      , private dtoCustomerService: dtoCustomerService, private dtoCustomerContactService: dtoCustomerContactService, private dtoCustomerDeliveryAddressService: dtoCustomerDeliveryAddressService
      , private ordersService: OrdersService, private dtoOrdersService: dtoOrdersService) {
		}

		ngOnInit(): void {
			this.form = this.formBuilder.group({
				txtOrderNo: [''],
				guCustomerId: ['', Validators.required],
				guCustomerContactId: [''],
				guCustomerDeliveryContactId: [''],
				guClientDelAddressId: [''],
				guClientDelAddressId2: [''],
				guClientDelAddressId3: [''],
				guClientDelAddressId4: [''],
				intPaymentType: [''],
				intInvoiceTransmission: [''],
				fltDeliveryRate: [''],
				fltEscort: [''],
				fltUnloadingCharge: ['', Validators.required],
				guEmployeeId: [''],
				txtComments: [''],
				txtInternalComments: [''],
				txtFreightSubContractor: [''],
				fltSubContractedFreightQuote: [''],
				txtFreightSubContractorComments: [''],
				blnContactedSubContractor: [''],
				txtAdminComments: [''],
				intAvailabilityNumber: [''],
				intAvailabilityUnit: [''],
				txtAvailablity: [''],
				blnOptionalQuote: [''],
				blnAskForSale: [''],
				dteAskForSaleReminder: [''],
				dteCreated: ['', Validators.required]
			});

			this.showSpinner = false;



			this.loadData();

		}

		async loadData() {

      this.dtoCustomerService.getAlldtoCustomer().then(customers => {
        this.customers = customers;
        this.customers.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 }); 
      });

      //this.customers = await this.dtoCustomerService.getAlldtoCustomer();
      //this.customers.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 }); 
		}

  async getCustomerContatcts(customerId: string) {

    this.customerContacts = await this.dtoCustomerContactService.getdtoCustomerContactByCustomerId(customerId);
    this.customerContacts.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 }); 

    console.log("this.customerContacts", this.customerContacts);

  }

  async getClientDeliveryAddresses(customerId: string) {

    this.customerDeliveryAddresses = await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressByCustomerId(customerId);
    this.customerDeliveryAddresses.sort((a, b) => { return a.txtTown > b.txtTown ? 1 : -1 }); 

  }



		async createQuote(type: string) {
			let txtOrderNo: string = this.form.controls['txtOrderNo'].value;
			let guCustomerId: string = this.form.controls['guCustomerId'].value;
			let guCustomerContactId: string = this.form.controls['guCustomerContactId'].value;
			let guCustomerDeliveryContactId: string = this.form.controls['guCustomerDeliveryContactId'].value;
			let guClientDelAddressId: string = this.form.controls['guClientDelAddressId'].value;
			let guClientDelAddressId2: string = this.form.controls['guClientDelAddressId2'].value;
			let guClientDelAddressId3: string = this.form.controls['guClientDelAddressId3'].value;
			let guClientDelAddressId4: string = this.form.controls['guClientDelAddressId4'].value;
			let intPaymentType: number = this.form.controls['intPaymentType'].value;
			let intInvoiceTransmission: number = this.form.controls['intInvoiceTransmission'].value;
			let fltDeliveryRate: number = this.form.controls['fltDeliveryRate'].value;
			let fltEscort: number = this.form.controls['fltEscort'].value;
			let fltUnloadingCharge: number = this.form.controls['fltUnloadingCharge'].value;
			let guEmployeeId: string = this.form.controls['guEmployeeId'].value;
			let txtComments: string = this.form.controls['txtComments'].value;
			let txtInternalComments: string = this.form.controls['txtInternalComments'].value;
			let txtFreightSubContractor: string = this.form.controls['txtFreightSubContractor'].value;
			let fltSubContractedFreightQuote: number = this.form.controls['fltSubContractedFreightQuote'].value;
			let txtFreightSubContractorComments: string = this.form.controls['txtFreightSubContractorComments'].value;
			let blnContactedSubContractor: boolean = this.form.controls['blnContactedSubContractor'].value;
			let txtAdminComments: string = this.form.controls['txtAdminComments'].value;
			let intAvailabilityNumber: number = this.form.controls['intAvailabilityNumber'].value;
			let intAvailabilityUnit: number = this.form.controls['intAvailabilityUnit'].value;
			let txtAvailablity: string = this.form.controls['txtAvailablity'].value;
			let blnOptionalQuote: boolean = this.form.controls['blnOptionalQuote'].value;
			let blnAskForSale: boolean = this.form.controls['blnAskForSale'].value;
			let dteAskForSaleReminder: Date = this.form.controls['dteAskForSaleReminder'].value;
      let dteCreated: Date = this.form.controls['dteCreated'].value;

			if (guCustomerId == null || guCustomerId == "") {
				this.alertService.openSnackBar("Please enter a guCustomerId.", "Close", "center", "bottom", "", 3000);
				return;
			}


      if (fltUnloadingCharge == null || fltUnloadingCharge.toString() == "") {
				this.alertService.openSnackBar("Please enter a fltUnloadingCharge.", "Close", "center", "bottom", "", 3000);
				return;
			}


			if (dteCreated == null || dteCreated.toString() == "") {
				this.alertService.openSnackBar("Please enter a dteCreated.", "Close", "center", "bottom", "", 3000);
				return;
			}

			let quote: Orders = new Orders();
			quote.txtOrderNo = txtOrderNo;
			quote.guCustomerId = guCustomerId;
			quote.guCustomerContactId = guCustomerContactId;
			quote.guCustomerDeliveryContactId = guCustomerDeliveryContactId;
			quote.guClientDelAddressId = guClientDelAddressId;
			quote.guClientDelAddressId2 = guClientDelAddressId2;
			quote.guClientDelAddressId3 = guClientDelAddressId3;
			quote.guClientDelAddressId4 = guClientDelAddressId4;
			quote.intPaymentType = intPaymentType;
			quote.intInvoiceTransmission = intInvoiceTransmission;
			quote.fltDeliveryRate = fltDeliveryRate;
			quote.txtEscort = fltEscort;
			quote.fltUnloadingCharge = fltUnloadingCharge;
			quote.guEmployeeId = guEmployeeId;
			quote.txtComments = txtComments;
			quote.txtInternalComments = txtInternalComments;
			quote.txtFreightSubContractor = txtFreightSubContractor;
			quote.fltSubContractedFreightQuote = fltSubContractedFreightQuote;
			quote.txtFreightSubContractorComments = txtFreightSubContractorComments;
			quote.blnContactedSubContractor = blnContactedSubContractor;
			quote.txtAdminComments = txtAdminComments;
			quote.intAvailabilityNumber = intAvailabilityNumber;
			quote.intAvailabilityUnit = intAvailabilityUnit;
			quote.txtAvailablity = txtAvailablity;
			quote.blnOptionalQuote = blnOptionalQuote;
			quote.blnAskForSale = blnAskForSale;
			quote.dteAskForSaleReminder = dteAskForSaleReminder;
      quote.dteCreated = dteCreated;


			this.showSpinner = true;

      let existingCustomer: dtoCustomer = await this.dtoCustomerService.getdtoCustomerByName(quote.txtOrderNo);
      if (existingCustomer != null) {
				this.showSpinner = false;
				let text: string = "A quote already exists with that name and the following details";
				text += existingCustomer.txtName + " ";
				let msg: string = await this.alertService.openSnackBarCustomDefaultPromise("Existing Customer", text);
				return;
			}
      quote = await this.ordersService.createOrdersPromise(quote);

      if(quote != null) {

        let dtoQuote: dtoOrders = await this.dtoOrdersService.getdtoOrdersPromise(quote.rowguid);

			  this.alertService.openSnackBar("The quote has been created", "Close", "center", "bottom", "", 3000);
			  this.showSpinner = false;
        this.quoteCreated.emit({ quote: dtoQuote, type: type }); 
				
      }

      if (quote == null) {
				this.showSpinner = false;
        this.alertService.openSnackBarError("There was a problem creating the quote", "Close", "center", "bottom", 4000, true, "There was a problem creating the quote");
      }

    }

		cancelQuote() {
			this.quoteCancelled.emit(true);
    }


  selectedCustomers(customers: dtoCustomer[]) {

    if (customers == null || customers.length == 0) {
      return;
    }

    console.log("customers: ", customers);

    this.getCustomerContatcts(customers[0].rowguid);

  }

  selectedCustomerContacts(customerContacts: dtoCustomerContact[]) {

    console.log("customerContacts: ", customerContacts);

  }

  async selectedCustomerAndContacts(customerAndContacts: { customerContacts: dtoCustomerContact[], customers: dtoCustomer[] }) {

    //let customerAndContact: { customerContact: dtoCustomerContact[], customer: dtoCustomer[] } = { customerContact: [], customer: [] };

    if (customerAndContacts.customers == null || customerAndContacts.customers.length == 0) {
      return;
    }

    //await this.getCustomerContatcts(customerAndContacts.customers[0].rowguid);

    //this.appCustomerContact.setCustomerContacts(this.customerContacts);

    console.log("customerContacts: ", customerAndContacts.customerContacts);
    console.log("customers: ", customerAndContacts.customers);

  }

}
