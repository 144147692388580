export interface TPPoleInspectionItem
{
	blnDrilled: boolean;
	blnFinishedPoleStockUpdated: boolean;
	blnOldFinalItem: boolean;
	blnOriginalPoleStockUpdated: boolean;
	blnRegalPole: boolean;
	blnRejected: boolean;
	blnStockDecreased: boolean;
	blnStockUpdated: boolean;
	dteDateCreated: Date;
	dteDateRejected: Date;
	dteLastUpdated: Date;
	fltActualM3: number;
	fltActualTonne: number;
	fltDiameter: number;
	fltGirth: number;
	fltLength: number;
	fltM3: number;
	fltOriginalLength: number;
	guChargeId: string;
	guCHHStockId: string;
	guCustomerId: string;
	guDeliveryDocketOrderItemId: string;
	guElectricalAuthoritySpecId: string;
	guEmployeeEnteredId: string;
	guFinalPoleId: string;
	guOrderId: string;
	guOrderItemId: string;
	guOriginalPoleId: string;
	guOriginalProductTypeId: string;
	guProductCodePriceId: string;
	guProductTypeId: string;
	guSpeciesId: string;
	guTPPoleInspectionId: string;
	guTPPoleTrimmingItemId: string;
	guTransferredFromOrderItemId: string;
	intDiameterType: number;
	intItemNo: number;
	intSpecification: number;
	intTreatmentMonth: number;
	intTreatmentYear: number;
	rowguid: string;
	txtChargeDate: string;
	txtChargeId: string;
	txtComments: string;
	txtConsecutiveNumber: string;
	txtPoleNumber: string;
	txtRejectedComments: string;
}

export class TPPoleInspectionItem
{
	blnDrilled: boolean;
	blnFinishedPoleStockUpdated: boolean;
	blnOldFinalItem: boolean;
	blnOriginalPoleStockUpdated: boolean;
	blnRegalPole: boolean;
	blnRejected: boolean;
	blnStockDecreased: boolean;
	blnStockUpdated: boolean;
	dteDateCreated: Date;
	dteDateRejected: Date;
	dteLastUpdated: Date;
	fltActualM3: number;
	fltActualTonne: number;
	fltDiameter: number;
	fltGirth: number;
	fltLength: number;
	fltM3: number;
	fltOriginalLength: number;
	guChargeId: string;
	guCHHStockId: string;
	guCustomerId: string;
	guDeliveryDocketOrderItemId: string;
	guElectricalAuthoritySpecId: string;
	guEmployeeEnteredId: string;
	guFinalPoleId: string;
	guOrderId: string;
	guOrderItemId: string;
	guOriginalPoleId: string;
	guOriginalProductTypeId: string;
	guProductCodePriceId: string;
	guProductTypeId: string;
	guSpeciesId: string;
	guTPPoleInspectionId: string;
	guTPPoleTrimmingItemId: string;
	guTransferredFromOrderItemId: string;
	intDiameterType: number;
	intItemNo: number;
	intSpecification: number;
	intTreatmentMonth: number;
	intTreatmentYear: number;
	rowguid: string;
	txtChargeDate: string;
	txtChargeId: string;
	txtComments: string;
	txtConsecutiveNumber: string;
	txtPoleNumber: string;
	txtRejectedComments: string;
}

export namespace TPPoleInspectionItem
{
	export enum enOnDeliveryDocket
	{
		No,
		Yes,
		Either
	}
}

