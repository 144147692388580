import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { MachineRun } from '../business-objects/machine-run.bo';

@Injectable()
export class MachineRunService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getMachineRun(machineRunId: string) {
		return this.http.get<MachineRun>(this._baseUrl + 'api/MachineRun/' + machineRunId);
  }

  getMachineRunByRunNo(machineRunNo: number, moulderId: string) {
    return this.http.get<MachineRun>(this._baseUrl + 'api/MachineRun/GetMachineRunByRunNo/' + machineRunNo + '/' + moulderId);
  }

	getMachineRunParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<MachineRun[]>(this._baseUrl + 'api/MachineRun/GetMachineRunsParamArray/', aParam);
	}

	getAllMachineRuns() {
		return this.http.get<MachineRun[]>(this._baseUrl + 'api/MachineRun/GetAllMachineRuns/');
	}

	//getAlldtoMachineRuns() {
	//	return this.http.get<dtoMachineRun[]>(this._baseUrl + 'api/MachineRun/GetAllMachineRuns/');
	//}

	createMachineRun(machineRun: MachineRun) {
		return this.http.post<MachineRun>(this._baseUrl + 'api/MachineRun/CreateMachineRun/', machineRun);
	}

	updateMachineRun(machineRun: MachineRun) {
		return this.http.put<MachineRun>(this._baseUrl + 'api/MachineRun/UpdateMachineRun/', machineRun);
	}

	async getMachineRunPromise(machineRunId: string) {
		return new Promise<MachineRun>(resolve => {
			this.getMachineRun(machineRunId).subscribe(machineRun => {
				resolve(machineRun);
			}, err => {
				this.alertService.openSnackBarError("Error getting MachineRun records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getMachineRunByRunNoPromise(machineRunNo: number, moulderId: string) {
    return new Promise<MachineRun>(resolve => {
      this.getMachineRunByRunNo(machineRunNo, moulderId).subscribe(machineRun => {
        resolve(machineRun);
      }, err => {
        this.alertService.openSnackBarError("Error getting MachineRunByRunNo records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }


	async getMachineRunParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<MachineRun[]>(resolve => {
			this.getMachineRunParamArray(aParam).subscribe(machineRuns => {
				resolve(machineRuns);
			}, err => {
				this.alertService.openSnackBarError("Error getting MachineRun records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateMachineRunPromise(machineRun: MachineRun) {
		return new Promise<MachineRun>(resolve => {
			this.updateMachineRun(machineRun).subscribe(machineRun => {
				resolve(machineRun);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating MachineRun record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createMachineRunPromise(machineRun: MachineRun) {
		return new Promise<MachineRun>(resolve => {
			this.createMachineRun(machineRun).subscribe(machineRun => {
				resolve(machineRun);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating MachineRun record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}
