import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoDiscTemplateConfig } from '../business-objects/dto-disc-template-config.bo';

@Injectable()
export class dtoDiscTemplateConfigService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoDiscTemplateConfig(dtoDiscTemplateConfigId: string) {
		return this.http.get<dtoDiscTemplateConfig>(this._baseUrl + 'api/dtoDiscTemplateConfig/' + dtoDiscTemplateConfigId);
	}

	getdtoDiscTemplateConfigParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoDiscTemplateConfig[]>(this._baseUrl + 'api/dtoDiscTemplateConfig/GetdtoDiscTemplateConfigsParamArray/', aParam);
	}

	getAlldtoDiscTemplateConfigs() {
		return this.http.get<dtoDiscTemplateConfig[]>(this._baseUrl + 'api/dtoDiscTemplateConfig/GetAlldtoDiscTemplateConfigs/');
	}

	//getAlldtodtoDiscTemplateConfigs() {
	//	return this.http.get<dtodtoDiscTemplateConfig[]>(this._baseUrl + 'api/dtoDiscTemplateConfig/GetAlldtoDiscTemplateConfigs/');
	//}

	createdtoDiscTemplateConfig(dtoDiscTemplateConfig: dtoDiscTemplateConfig) {
		return this.http.post<dtoDiscTemplateConfig>(this._baseUrl + 'api/dtoDiscTemplateConfig/CreatedtoDiscTemplateConfig/', dtoDiscTemplateConfig);
	}

	updatedtoDiscTemplateConfig(dtoDiscTemplateConfig: dtoDiscTemplateConfig) {
		return this.http.put<dtoDiscTemplateConfig>(this._baseUrl + 'api/dtoDiscTemplateConfig/UpdatedtoDiscTemplateConfig/', dtoDiscTemplateConfig);
	}

	async getdtoDiscTemplateConfigPromise(dtoDiscTemplateConfigId: string) {
		return new Promise<dtoDiscTemplateConfig>(resolve => {
			this.getdtoDiscTemplateConfig(dtoDiscTemplateConfigId).subscribe(dtoDiscTemplateConfig => {
				resolve(dtoDiscTemplateConfig);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDiscTemplateConfig records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoDiscTemplateConfigParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoDiscTemplateConfig[]>(resolve => {
			this.getdtoDiscTemplateConfigParamArray(aParam).subscribe(dtoDiscTemplateConfigs => {
				resolve(dtoDiscTemplateConfigs);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDiscTemplateConfig records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoDiscTemplateConfigPromise(dtoDiscTemplateConfig: dtoDiscTemplateConfig) {
		return new Promise<dtoDiscTemplateConfig>(resolve => {
			this.updatedtoDiscTemplateConfig(dtoDiscTemplateConfig).subscribe(dtoDiscTemplateConfig => {
				resolve(dtoDiscTemplateConfig);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoDiscTemplateConfig record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoDiscTemplateConfigPromise(dtoDiscTemplateConfig: dtoDiscTemplateConfig) {
		return new Promise<dtoDiscTemplateConfig>(resolve => {
			this.createdtoDiscTemplateConfig(dtoDiscTemplateConfig).subscribe(dtoDiscTemplateConfig => {
				resolve(dtoDiscTemplateConfig);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoDiscTemplateConfig record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}