import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { PoleInspectionItemIdentifier } from '../business-objects/pole-inspection-item-identifier.bo';

@Injectable()
export class PoleInspectionItemIdentifierService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getPoleInspectionItemIdentifier(poleInspectionItemIdentifierId: string) {
		return this.http.get<PoleInspectionItemIdentifier>(this._baseUrl + 'api/PoleInspectionItemIdentifier/' + poleInspectionItemIdentifierId);
	}

	getPoleInspectionItemIdentifierParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<PoleInspectionItemIdentifier[]>(this._baseUrl + 'api/PoleInspectionItemIdentifier/GetPoleInspectionItemIdentifiersParamArray/', aParam);
  }

  getLastNumberOfIdentifiers(numberOfIdentifiers: number) {
    return this.http.get<PoleInspectionItemIdentifier[]>(this._baseUrl + 'api/PoleInspectionItemIdentifier/GetLastNumberOfIdentifiers/' + numberOfIdentifiers.toString());
  }


	getAllPoleInspectionItemIdentifiers() {
		return this.http.get<PoleInspectionItemIdentifier[]>(this._baseUrl + 'api/PoleInspectionItemIdentifier/GetAllPoleInspectionItemIdentifiers/');
	}

	//getAlldtoPoleInspectionItemIdentifiers() {
	//	return this.http.get<dtoPoleInspectionItemIdentifier[]>(this._baseUrl + 'api/PoleInspectionItemIdentifier/GetAllPoleInspectionItemIdentifiers/');
	//}

	createPoleInspectionItemIdentifier(poleInspectionItemIdentifier: PoleInspectionItemIdentifier) {
		return this.http.post<PoleInspectionItemIdentifier>(this._baseUrl + 'api/PoleInspectionItemIdentifier/CreatePoleInspectionItemIdentifier/', poleInspectionItemIdentifier);
	}

  createPoleInspectionItemIdentifierByNumber(numberToCreate: Number) {
    return this.http.post<PoleInspectionItemIdentifier[]>(this._baseUrl + 'api/PoleInspectionItemIdentifier/CreatePoleInspectionItemIdentifiersByNumber/' + numberToCreate.toString(), null);
  }

	updatePoleInspectionItemIdentifier(poleInspectionItemIdentifier: PoleInspectionItemIdentifier) {
		return this.http.put<PoleInspectionItemIdentifier>(this._baseUrl + 'api/PoleInspectionItemIdentifier/UpdatePoleInspectionItemIdentifier/', poleInspectionItemIdentifier);
	}

	deletePoleInspectionItemIdentifier(poleInspectionItemIdentifierId: string) {
		return this.http.delete(this._baseUrl + 'api/PoleInspectionItemIdentifier/DeletePoleInspectionItemIdentifier/' + poleInspectionItemIdentifierId);
	}

	async getPoleInspectionItemIdentifierPromise(poleInspectionItemIdentifierId: string) {
		return new Promise<PoleInspectionItemIdentifier>(resolve => {
			this.getPoleInspectionItemIdentifier(poleInspectionItemIdentifierId).subscribe(poleInspectionItemIdentifier => {
				resolve(poleInspectionItemIdentifier);
			}, err => {
				this.alertService.openSnackBarError("Error getting PoleInspectionItemIdentifier records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getPoleInspectionItemIdentifierParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<PoleInspectionItemIdentifier[]>(resolve => {
			this.getPoleInspectionItemIdentifierParamArray(aParam).subscribe(poleInspectionItemIdentifiers => {
				resolve(poleInspectionItemIdentifiers);
			}, err => {
				this.alertService.openSnackBarError("Error getting PoleInspectionItemIdentifier records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getLastNumberOfIdentifiersPromise(numberOfIdentifiers: number) {
    return new Promise<PoleInspectionItemIdentifier[]>(resolve => {
      this.getLastNumberOfIdentifiers(numberOfIdentifiers).subscribe(poleInspectionItemIdentifiers => {
        resolve(poleInspectionItemIdentifiers);
      }, err => {
        this.alertService.openSnackBarError("Error getting PoleInspectionItemIdentifier records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getIdentifiersBySearchNumbersPromise(numberFrom: number, numberTo: number) {
    return new Promise<PoleInspectionItemIdentifier[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("intIdentifier", numberFrom.toString(), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo));
      aParamList.push(aParam);
      aParam = [];
      aParam.push(new SQLParamArray("intIdentifier", numberTo.toString(), SQLParamArray.enSQLOperator.LessThanOrEqualTo));
      aParamList.push(aParam);

      this.getPoleInspectionItemIdentifierParamArray(aParamList).subscribe(poleInspectionItemIdentifiers => {
        resolve(poleInspectionItemIdentifiers);
      }, err => {
        this.alertService.openSnackBarError("Error getting PoleInspectionItemIdentifier records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async updatePoleInspectionItemIdentifierPromise(poleInspectionItemIdentifier: PoleInspectionItemIdentifier) {
		return new Promise<PoleInspectionItemIdentifier>(resolve => {
			this.updatePoleInspectionItemIdentifier(poleInspectionItemIdentifier).subscribe(poleInspectionItemIdentifier => {
				resolve(poleInspectionItemIdentifier);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating PoleInspectionItemIdentifier record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async createPoleInspectionItemIdentifierByNumberPromise(numberToCreate: number) {
    return new Promise<PoleInspectionItemIdentifier[]>(resolve => {
      this.createPoleInspectionItemIdentifierByNumber(numberToCreate).subscribe(poleInspectionItemIdentifiers => {
        resolve(poleInspectionItemIdentifiers);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating PoleInspectionItemIdentifiers", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async createPoleInspectionItemIdentifierPromise(poleInspectionItemIdentifier: PoleInspectionItemIdentifier) {
		return new Promise<PoleInspectionItemIdentifier>(resolve => {
			this.createPoleInspectionItemIdentifier(poleInspectionItemIdentifier).subscribe(poleInspectionItemIdentifier => {
				resolve(poleInspectionItemIdentifier);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating PoleInspectionItemIdentifier record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletePoleInspectionItemIdentifierPromise(poleInspectionItemIdentifierId: string) {
		return new Promise<boolean>(resolve => {
			this.deletePoleInspectionItemIdentifier(poleInspectionItemIdentifierId).subscribe(poleInspectionItemIdentifier => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing PoleInspectionItemIdentifier record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
