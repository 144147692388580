import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Client } from '../../_shared/business-objects/client.bo';
import { dtoCustomer } from '../../_shared/business-objects/dto-customer.bo';
import { SharedService } from './shared.service';

@Injectable()
export class CustomerService {

  public _baseUrl: string;

  public readonly CoffsHarbourStoreCustomerGuid: string = "22222222-2222-2222-2222-222222222222";
  public readonly GlenreaghStoreCustomerGuid: string = "33333333-3333-3333-3333-333333333333";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService) {
    this._baseUrl = baseUrl;
  }

  getAllCustomers() {
    return this.http.get<Client>(this._baseUrl + 'api/Customer/GetAllCustomers');
  }

  getDTOCustomer(customerId: string, customerName: string, contactFirstName: string, contactLastName: string, contactAddress1: string, contactState: string, contactPostcode: string, contactMobile: string, contactPhone: string, contactEmail: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomers/' + this.sharedService.convertGuid(customerId) + '/' + customerName + '/' + contactFirstName + '/' + contactLastName + '/' + contactAddress1 + '/' + contactState + '/' + contactPostcode + '/' + contactMobile + '/' + contactPhone + '/' + contactEmail);
  }

  getDTOCustomerByRowguid(customerId: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomersByRowguid/' + this.sharedService.convertGuid(customerId));
  }

  getDTOCustomerContactByName(customerName: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomerByName/' + customerName);
  }

  getDTOCustomerContactByFirstName(firstName: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomerByFirstName/' + firstName);
  }

  getDTOCustomerContactByLastName(lastName: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomerByLastName/' + lastName);
  }

  getDTOCustomerContactByAddress1(address1: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomerByAddress1/' + address1);
  }

  getDTOCustomerContactByState(state: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomerByState/' + state);
  }

  getDTOCustomerContactByPostcode(postcode: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomerByPostcode/' + postcode);
  }

  getDTOCustomerContactByMobile(mobile: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomerByMobile/' + mobile);
  }

  getDTOCustomerContactByPhone(phone: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomerByPhone/' + phone);
  }

  getDTOCustomerContactByEmail(email: string) {
    return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/Client/GetCustomerByEmail/' + email);
  }


}  
