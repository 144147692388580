import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../account/auth.service';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',

})

export class LogoutComponent {
  form: FormGroup;
  public show: boolean = false;

  constructor(private authService: AuthService, private router: Router) {

    authService.setLogoutSession();

    //authService.logout();

  }
  
}
