  <form class="example-form loading-docket-container-form" [formGroup]="form">
  <div class="loading-docket-container" id="loadingDocketContainer">
    <div class="loading-docket-heading">
      <h1>Loading Dockets</h1>
    </div>

    <mat-tab-group color="accent" class="chh-mat-tab-group loading-docket-mat-tab-group" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabLoadingDockets>
      <mat-tab label="Loading Dockets" class="tab-label loading-docket-search-tab">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Docket No</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="number" id="txtSearchLoadingDocketNo" formControlName="txtSearchLoadingDocketNo" (keydown.enter)="searchLoadingDockets('')">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 55670,28631..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-3">
            <div class="button-container">
              <button class="search-loadig-docket-button search-button" type="button" mat-flat-button color="primary" (click)="searchLoadingDockets('')">Search</button>
              <button class="search-ready-for-loading-button search-button" type="button" mat-flat-button color="primary" (click)="searchReadyForLoading()">Ready For Loading</button>
              <button class="search-scheduled-button search-button" type="button" mat-flat-button color="primary" (click)="searchScheduled()">Scheduled</button>
              <button class="search-scheduled-button search-button" type="button" mat-flat-button color="primary" (click)="searchLoaded()">Loaded</button>
              <button class="search-scheduled-button search-button" type="button" mat-flat-button color="primary" (click)="searchDispatched()">Dispatched</button>
            </div>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>Status</mat-label>
              <mat-select formControlName="intStatus" multiple>
                <mat-option *ngFor="let loadingStatus of loadingStatuses" [value]="loadingStatus.intValue">{{loadingStatus.txtValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="yard-section-load">
            <div class="search-option-row-1">
              <mat-form-field appearance="fill">
                <mat-label>Yard Loading Location</mat-label>
                <mat-select formControlName="intYardSection" multiple>
                  <mat-option *ngFor="let yardSection of yardSections" [value]="yardSection.intValue">{{yardSection.txtValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="loading-docket-list-app-container">
          <app-loading-docket-list #appLoadingDocketList [datasource]="loadingDockets" [totalTonne]="totalTonne" (editLoadingDocketEvent)="editLoadingDocket($event)" (addLoadingDocketItemsEvent)="addLoadingDocketItems($event)" (updatedLoadingDocketEvent)="updatedLoadingDocket($event)" (removeLoadingDocketEvent)="removeLoadingDocket($event)" (searchLoadingDockets)="searchLoadingDockets('')" [columnBarPage]="'Loading Dockets'" *ngIf="loadingDockets != null"></app-loading-docket-list>
        </div>

      </mat-tab>



      <mat-tab label="Search Options" class="tab-label search-option-tab">


        <div class="search-options">

          <div class="search-options-column-1">


            <div class="search-option-row-1">
              <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
                <mat-label>Customer</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Essential Energy" value="" type="text" id="txtSearchCustomer" formControlName="txtSearchCustomer" (keydown.enter)="searchLoadingDockets('')">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-1">
              <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
                <mat-label>Location</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Dubbo" value="" type="text" id="txtSearchLocation" formControlName="txtSearchLocation" (keydown.enter)="searchLoadingDockets('')">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>


            <div class="search-option-row-1">
              <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
                <mat-label>Order Number</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. EE410034556" value="" type="text" formControlName="txtSearchOrderNumber" (keydown.enter)="searchLoadingDockets('')">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-1">
              <div class="search-product-type-container">
                <app-product-type-select [productTypes]="productTypes" (selectedProductTypesEvent)="selectedProductTypes($event)"></app-product-type-select>
              </div>
            </div>

            <div class="search-option-row-5">
              <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
                <mat-label>Hauling Company Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Blanchards" value="" type="text" formControlName="txtSearchHaulingCompanyName" (keydown.enter)="searchLoadingDockets('')">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-1">
              <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
                <mat-label>Final Number</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 10870" value="" type="text" formControlName="txtSearchFinalNumber" (keydown.enter)="searchLoadingDockets('')">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-2">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Delivery Docket No</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 55670" value="" type="text" id="txtSearchDeliveryDocketNo" formControlName="txtSearchDeliveryDocketNo" (keydown.enter)="searchLoadingDockets('')">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple numbers by a comma i.e. 55670,28631..." class="help-icon">help_outline</mat-icon>
            </div>
            <div class="search-option-row-3">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
                <mat-label>Expected Loading Date From</mat-label>
                <input id="dteSearchLoadingDateFrom" class="date-input loading-date-search" #searchLoadingDateFrom matInput [matDatepicker]="statuspickerfrom" formControlName="dteSearchLoadingDateFrom">
                <mat-datepicker-toggle matSuffix [for]="statuspickerfrom"></mat-datepicker-toggle>
                <mat-datepicker #statuspickerfrom></mat-datepicker>
              </mat-form-field>
            </div>


            <div class="search-option-row-4">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
                <mat-label>Expected Loading Date To</mat-label>
                <input id="dteSearchLoadingDateTo" class="date-input loading-date-search" #searchLoadingDateTo matInput [matDatepicker]="statuspickerto" formControlName="dteSearchLoadingDateTo">
                <mat-datepicker-toggle matSuffix [for]="statuspickerto"></mat-datepicker-toggle>
                <mat-datepicker #statuspickerto></mat-datepicker>
              </mat-form-field>
            </div>



            <div class="search-option-row-6">
              <span><mat-slide-toggle formControlName="blnSearchUploadInvoice">Invoice Uploaded</mat-slide-toggle></span>
            </div>

            <div class="search-option-row-7">
              <button class="search-option-loading-docket-button" type="button" mat-flat-button color="primary" (click)="searchLoadingDockets('')">Search</button>
            </div>

            <mat-divider></mat-divider>
          </div>

          <div class="search-options-column-2">
            <div class="dispatched-selection-criteria">

              <div class="date-selection-container">
                <div class="date-selection-label container-label">Search on Dispatched Date</div>
                <mat-selection-list class="chh-mat-selection-list date-select" #txtDateSelection [multiple]="false" id="txtDateSelection" formControlName="txtDateSelection">
                  <mat-list-option [value]="0" (click)="setDispatchedDate(1, 'w')">1 Week</mat-list-option>
                  <mat-list-option [value]="0" (click)="setDispatchedDate(2, 'w')">2 Weeks</mat-list-option>
                  <mat-list-option [value]="0" (click)="setDispatchedDate(3, 'w')">3 Weeks</mat-list-option>
                  <mat-list-option [value]="0" (click)="setDispatchedDate(4, 'w')">4 Weeks</mat-list-option>
                  <mat-list-option [value]="0" (click)="setDispatchedDate(3, 'm')">3 Months</mat-list-option>
                  <mat-list-option [value]="0" (click)="setDispatchedDate(6, 'm')">6 Months</mat-list-option>
                  <mat-list-option [value]="0" (click)="setDispatchedDate(12, 'm')">12 Months</mat-list-option>
                </mat-selection-list>
              </div>

              <div class="search-option-row-3">
                <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
                  <mat-label>Dispatched Date From</mat-label>
                  <input id="dteSearchDispatchedDateFrom" class="date-input dispatched-date-search" #searchDispatchedDateFrom matInput [matDatepicker]="dispatchedpickerfrom" formControlName="dteSearchDispatchedDateFrom">
                  <mat-datepicker-toggle matSuffix [for]="dispatchedpickerfrom"></mat-datepicker-toggle>
                  <mat-datepicker #dispatchedpickerfrom></mat-datepicker>
                </mat-form-field>
              </div>


              <div class="search-option-row-4">
                <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
                  <mat-label>Dispatched Date To</mat-label>
                  <input id="dteSearchDispatchedDateTo" class="date-input dispatched-date-search" #searchDispatchedDateTo matInput [matDatepicker]="dispatchedpickerto" formControlName="dteSearchDispatchedDateTo">
                  <mat-datepicker-toggle matSuffix [for]="dispatchedpickerto"></mat-datepicker-toggle>
                  <mat-datepicker #dispatchedpickerto></mat-datepicker>
                </mat-form-field>
              </div>

            </div>


            <div class="search-dispatched-button-container">
              <button class="search-dispatched-button" type="button" mat-flat-button color="primary" (click)="searchDispatchedLoadingDockets()">Search Despatched</button>

              <div class="clear-dispatched-selection-button-container">
                <button class="clear-dispatched-selection-button" type="button" mat-flat-button color="primary" (click)="clearDispatchDateSelection()">Clear Despatched Dates</button>
              </div>
            </div>

            <div class="selected-truck-trailer-driver-container">
              <div class="truck-trailer-selection-button-container">
                <button class="truck-trailer-selection-button" type="button" mat-flat-button color="primary" (click)="openEquipmentDialog()">Open Truck/Trailer/Driver Selection</button>
                <button class="clear-truck-trailer-selection-button" type="button" mat-flat-button color="primary" (click)="clearTruckTrailerDriver()">Clear</button>
              </div>
              <div class="selected-truck-trailer-driver-grid">
                <div class="truck-label truck-trailer-driver-label" *ngIf="searchHaulingCompany != null">Truck:</div>
                <div class="selected-truck truck-trailer-driver-value" *ngIf="searchHaulingCompany != null">{{searchHaulingCompany.txtName}}</div>
                <div class="trailer-label truck-trailer-driver-label" *ngIf="searchTrailer != null">Trailer:</div>
                <div class="selected-trailer truck-trailer-driver-value" *ngIf="searchTrailer != null">{{searchTrailer.txtName}}</div>
                <div class="driver-label truck-trailer-driver-label" *ngIf="searchDriver != null">Driver:</div>
                <div class="selected-driver truck-trailer-driver-value" *ngIf="searchDriver != null">{{searchDriver.txtFirstName + ' ' + searchDriver.txtLastName}}</div>
              </div>
            </div>

            <div class="">

            </div>



          </div>
        </div>
      </mat-tab>

      <mat-tab label="Create Loading Docket" class="tab-label">
        <div class="create-loading-docket-container">
          <app-loading-docket-create [inDialog]="false" (loadingDocketCreated)="loadingDocketCreated($event)" (loadingDocketCancelled)="loadingDocketCancelled($event)" *ngIf="selectedTab == eLoadingDocketTabs.CreateLoadingDocket"></app-loading-docket-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Loading Docket" class="tab-label">
        <div class="update-loading-docket-container">
          <app-loading-docket-update [loadingDocket]="loadingDocket" (loadingDocketUpdated)="loadingDocketUpdated($event)" (loadingDocketCancelled)="loadingDocketCancelled($event)" *ngIf="selectedTab == eLoadingDocketTabs.UpdateLoadingDocket"></app-loading-docket-update>
        </div>
      </mat-tab>

      <mat-tab label="Add Docket Items" class="tab-label">
        <div class="add-docket-items-container">
          <app-loading-docket-add-items #loadingDocketAddItems [loadingDocket]="loadingDocketAddItem" (loadingDocketItemsAdded)="loadingDocketItemsAdded($event)" (loadingDocketItemsCancelled)="loadingDocketItemsCancelled($event)" *ngIf="selectedTab == eLoadingDocketTabs.DocketAddItems"></app-loading-docket-add-items>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
