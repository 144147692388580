import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CodeBuilderColumn } from '../business-objects/code-builder-column.bo';

@Injectable()
export class ColumnService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getTable(columnName: string) {
    return this.http.get<CodeBuilderColumn>(this._baseUrl + 'api/CodeBuilderColumn/' + columnName);
  }

  getAllColumns(tableId: string) {
    return this.http.get<CodeBuilderColumn[]>(this._baseUrl + 'api/CodeBuilderColumn/GetCodeBuilderColumns/' + tableId);
  }

  getColumn(columnId: string) {
    return this.http.get<CodeBuilderColumn>(this._baseUrl + 'api/CodeBuilderColumn/GetCodeBuilderColumn/' + columnId);
  }

  updateColumn(column: CodeBuilderColumn) {
    return this.http.put<CodeBuilderColumn>(this._baseUrl + 'api/CodeBuilderColumn/UpdateCodeBuilderColumn/', column);
  }

  async getAllColumnsPromise(tableId: string) {
    return new Promise<CodeBuilderColumn[]>(resolve => {
      this.getAllColumns(tableId).subscribe(table => {
        resolve(table);
      }, err => {
        console.log(err);
      });
    })
  }

}
