<div class="quote-list-container">
  <app-app-user-column-set-bar [componentColumns]="displayedColumns" [pageName]="columnBarPage" [container]="'quoteContainer'" [columnListId]="'columnListId'" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)"></app-app-user-column-set-bar>

  <table mat-table #tblQuotes [dataSource]="datasource" multiTemplateDataRows class="mat-elevation-z8 quote-list-table">

    <ng-container matColumnDef="txtCustomerName">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Customer </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD customerName"> {{quote.txtCustomerName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerContactName">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Customer Contact Name </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD customerContactName"> {{quote.txtCustomerContactFirstName + ' ' + quote.txtCustomerContactLastName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerContactPhone">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Customer Contact Phone </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD customerContactPhone"> {{quote.txtCustomerContactPhone}} </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerContactMobile">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Customer Contact Mobile </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD customerContactMobile"> {{quote.txtCustomerContactMobile}} </td>
    </ng-container>

    <ng-container matColumnDef="txtDeliveryAddress">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Delivery Address </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD deliveryAddress"> {{quote.txtDeliveryAddress}} </td>
    </ng-container>

    <ng-container matColumnDef="txtDeliveryTown">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Delivery Town </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD deliveryTown"> {{quote.txtDeliveryTown}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditDeliveryAddress">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD editDeliveryAddress">
        <mat-icon matTooltip="Edit Delivery Address" class="edit-note-icon mouse-pointer" (click)="openDeliveryAddressDialog(quote)">edit_note</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="txtQuoteNo">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Quote No </th>
      <td mat-cell *matCellDef="let quote" class="orderNo quoteTD alignRight"> {{quote.txtOrderNo}} </td>
    </ng-container>

    <ng-container matColumnDef="txtOrderNo">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Order No </th>
      <td mat-cell *matCellDef="let quote" class="orderNo quoteTD alignRight"> {{quote.txtQuotedOrderNo}} </td>
    </ng-container>

    <ng-container matColumnDef="dteCreated">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Quote Date </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD quoteDate alignRight"> {{quote.dteCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>


    <ng-container matColumnDef="dteDueDate">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Due Date </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD dueDate alignRight"> {{quote.dteDueDate | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditDueDate">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> </th>
      <td mat-cell *matCellDef="let quote; let idx = dataIndex;" class="quoteTD editDueDate editIcon">
        <mat-icon matTooltip="Edit Due Date" class="edit-note-icon mouse-pointer" (click)="openDueDateSelector(quote, idx)">edit_note</mat-icon>
        <div id="due-date-selector-location{{idx}}"></div>
        <div id="dueDateSelector{{idx}}" [ngClass]="quote.rowguid == selectedDueDate ? 'date-selector-transition' : 'date-selector'">
          <app-date-selector *ngIf="quote.rowguid == selectedDueDate" [inputDate]="quote.dteDueDate" (outputDateEvent)="setDueDate(quote, $event)" (cancelEvent)="closeDueDate()"></app-date-selector>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="txtAvailabilityNo">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Availability </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD availabilityNo"> {{quote.intAvailabilityNumber > 0 ? quote.intAvailabilityNumber : ''}} </td>
    </ng-container>

    <ng-container matColumnDef="txtAvailabilityUnit">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Availability Unit </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD availabilityUnit"> {{quote.txtAvailabilityUnit != 'None' ? quote.txtAvailabilityUnit : ''}} </td>
    </ng-container>

    <ng-container matColumnDef="txtAvailability">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Availability Comments </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD availabilityComments">
        <app-truncate-string [truncateString]="quote.txtAvailablity | isNull" [truncateLength]="50"></app-truncate-string>
      </td>
    </ng-container>

    <ng-container matColumnDef="txtPaymentType">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Payment Type </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD paymentType alignRight"> {{quote.txtPaymentType}} </td>
    </ng-container>

    <ng-container matColumnDef="txtInvoiceReply">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Invoice Reply </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD invoiceReply alignRight"> {{quote.txtInvoiceReply}} </td>
    </ng-container>

    <ng-container matColumnDef="txtComments">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Comments </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD comments">
        <app-truncate-string [truncateString]="quote.txtComments | isNull" [truncateLength]="50"></app-truncate-string>
      </td>
    </ng-container>

    <ng-container matColumnDef="txtStatus">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Status </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD status"> {{quote.txtStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="txtQuotedBy">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Quoted By </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD quotedBy"> {{quote.txtQuotedByFirstName + ' ' + quote.txtQuotedByLastName}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateEntered">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Date Entered </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD dateEntered alignRight"> {{quote.dteEntered | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtEnteredBy">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Entered By </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD enteredBy"> {{quote.txtTakenByFirstName != null ? quote.txtTakenByFirstName + ' ' + quote.txtTakenByLastName : ''}} </td>
    </ng-container>

    <ng-container matColumnDef="dteCompleted">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Completed </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD dateCompleted alignRight"> {{quote.dteCompleted | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dteLastEmailed">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Last Emailed </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD lastEmailed alignRight"> {{quote.dteLastEmailed | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtInternalComments">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Internal Comments </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD internalComments">
        <app-truncate-string [truncateString]="quote.txtInternalComments | isNull" [truncateLength]="50"></app-truncate-string>
      </td>
    </ng-container>

    <ng-container matColumnDef="blnAskForSale">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Ask For Sale </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD internalComments"> {{quote.blnAskForSale | yesNo}} </td>
    </ng-container>

    <ng-container matColumnDef="dteAskForSaleReminder">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Ask For Sale Reminder </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD askForSaleReminder alignRight"> {{quote.dteAskForSaleReminder | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditAskForSaleReminder">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> </th>
      <td mat-cell *matCellDef="let quote; let idx = dataIndex;" class="quoteTD editAskForSaleReminder editIcon">
        <mat-icon matTooltip="Edit Ask For Sale Reminder" class="edit-note-icon mouse-pointer" (click)="openAskForSaleReminderSelector(quote, idx)">edit_note</mat-icon>
        <div id="ask-for-sale-reminder-selector-location{{idx}}"></div>
        <div id="askForSaleReminderSelector{{idx}}" [ngClass]="quote.rowguid == selectedAskForSaleReminderDate ? 'date-selector-transition' : 'date-selector'">
          <app-date-selector *ngIf="quote.rowguid == selectedAskForSaleReminderDate" [inputDate]="quote.dteAskForSaleReminder" (outputDateEvent)="setAskForSaleReminder(quote, $event)" (cancelEvent)="close  ()"></app-date-selector>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="txtAskForReason">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Non Conversion Reason </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD internalComments"> {{quote.txtAskForReason}} </td>
    </ng-container>

    <ng-container matColumnDef="txtAskForReasonComments">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Non Conversion Comments </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD internalComments"> {{quote.txtAskForReasonComments}} </td>
    </ng-container>

    <ng-container matColumnDef="fltAskForReasonPercentage">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Ask For Reason Percentage </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD askForReasonPercentage"> {{quote.fltAskForReasonPercentage | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="blnOptional">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Optional </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD optional"> {{quote.blnOptional | yesNo}} </td>
    </ng-container>

    <ng-container matColumnDef="txtFreightSubContractor">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Freight Sub Contractor </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD freightSubContractor"> {{quote.txtFreightSubContractor}} </td>
    </ng-container>

    <ng-container matColumnDef="fltSubContractedFreightQuote">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Sub Contracted Freight Quote </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD subContractedFreightQuote"> {{quote.fltSubContractedFreightQuote | isZero}} </td>
    </ng-container>

    <ng-container matColumnDef="txtFreightSubContractorComments">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Freight Sub Contractor Comments </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD freightSubContractorComments">
        <app-truncate-string [truncateString]="quote.txtFreightSubContractorComments | isNull" [truncateLength]="50"></app-truncate-string>
      </td>
    </ng-container>

    <ng-container matColumnDef="txtAdminNotes">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"> Admin Notes </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD adminComments">
        <app-truncate-string [truncateString]="quote.txtAdminNotes | isNull" [truncateLength]="50"></app-truncate-string>
      </td>
    </ng-container>

    <ng-container matColumnDef="fltM3">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> M3 </th>
      <td mat-cell *matCellDef="let quote" class="quoteTD M3 alignRight"> {{quote.fltM3 | number : '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="fltTonne">
      <th mat-header-cell *matHeaderCellDef class="quoteTH alignRight"> Tonne <span class="total-tonne"> {{totalTonne}}</span></th>
      <td mat-cell *matCellDef="let quote" class="quoteTD tonne alignRight"> {{quote.fltTonne | number : '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsComplete">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"></th>
      <td mat-cell *matCellDef="let quote" class="quoteTD"><button class="set-as-complete-button" type="button" mat-flat-button color="primary" (click)="setAsComplete(quote)" [disabled]="quote.intStatus == orderStatusComplete">Set As Complete</button></td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsActive">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"></th>
      <td mat-cell *matCellDef="let quote" class="quoteTD"><button class="set-as-active-button" type="button" mat-flat-button color="primary" (click)="setAsActive(quote)" [disabled]="quote.intStatus == orderStatusActive">Set As Active</button></td>
    </ng-container>

    <ng-container matColumnDef="btnConvertToOrder">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"></th>
      <td mat-cell *matCellDef="let quote" class="quoteTD"><button class="set-as-scheduled-button" type="button" mat-flat-button color="primary" (click)="convertToOrder(quote)">Convert To Order</button></td>
    </ng-container>

    <ng-container matColumnDef="btnEditQuote">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"></th>
      <td mat-cell *matCellDef="let quote" class="quoteTD"><button class="edit-quote-button" type="button" mat-flat-button color="primary" (click)="editQuote(quote)">Edit</button></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveQuote">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"></th>
      <td mat-cell *matCellDef="let quote" class="quoteTD"><button class="remove-quote-button" type="button" mat-flat-button color="primary" (click)="removeQuote(quote)">Remove</button></td>
    </ng-container>

    <ng-container matColumnDef="btnDropbox">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"></th>
      <td mat-cell *matCellDef="let quote" class="quoteTD"><button class="remove-quote-button" type="button" mat-flat-button color="primary" (click)="openDropbox(quote)">Dropbox</button></td>
    </ng-container>

    <ng-container matColumnDef="btnPrintQuote">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"></th>
      <td mat-cell *matCellDef="let quote; let rowIdx = index;" class="quoteTD"><button class="print-quote-button" type="button" mat-flat-button color="primary" (click)="printQuote(quote)">Print Quote</button></td>
    </ng-container>

    <ng-container matColumnDef="btnEmailQuote">
      <th mat-header-cell *matHeaderCellDef class="quoteTH"></th>
      <td mat-cell *matCellDef="let quote" class="quoteTD" #emailButtonTD><button #emailButton class="email-quote-button" type="button" mat-flat-button color="primary" (click)="emailQuote(quote, emailButtonTD)">Email</button></td>
    </ng-container>

    <ng-container matColumnDef="btnViewQuoteItems">
      <th mat-header-cell *matHeaderCellDef class="quoteTH" aria-label="row actions"></th>
      <td mat-cell *matCellDef="let quote" class="quoteTD">
        <button mat-icon-button aria-label="expand row" (click)="expandElement(quote,$event);$event.stopPropagation()">
          <mat-icon *ngIf="quoteExpanded !== quote">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="quoteExpanded === quote">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>


    <ng-container matColumnDef="expandedDetail">
      <th mat-header-cell *matHeaderCellDef class="quoteTH" [attr.colspan]="displayedColumns.length"></th>
      <td mat-cell *matCellDef="let quote; let rowIdx = dataIndex" class="quoteTD quoteExpandTD" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="quoteExpanded == quote ? 'expanded' : 'collapsed'">
          <div class="detail-view-container">
            <div class="detail-view">
              <div class="view-type-label">View Type</div>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group app-user-column-set-radio" fxLayout="column">
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="0" name="radView" (click)="changeView(quote, 0)" [checked]="viewType == 0">Quote View</mat-radio-button>
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="1" name="radView" (click)="changeView(quote, 1)" [checked]="viewType == 1">Table View</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="quote-item-view">
              <app-quote-item-view class="quote-view" #appQuoteItemView [quoteIdInput]="quote.rowguid" *ngIf="quoteExpanded === quote && viewType == 0"></app-quote-item-view>
              <app-quote-item-list #appQuoteItemList (removeQuoteItemEvent)="removeQuoteItem($event, quote)" [datasource]="quoteItems" [columnBarPage]="'Quote Items'" [columnBarContainer]="'quoteItemContainer'" [columnListId]="'columnList2'+rowIdx" *ngIf="quoteExpanded === quote && viewType == 1"></app-quote-item-list>
            </div>
          </div>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="quote-row-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let quote; let idx = dataIndex;" id="locationrow{{idx}}" (click)="expandElementAndSetColor(quote,idx)" [ngClass]="quote.rowguid == selectedRow ? 'itemRow activeTR' : 'itemRow rowHover'"></tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" id="expandedRow" class="example-detail-row"></tr>

  </table>

</div>

<div class="quote-print-wrapper">
  <div id="quotePDF" class="quote-print-container">
    <app-quote-print #quotePrintApp [quoteIdInput]="quotePDFId" [showTOCs]="showTOCs"></app-quote-print>
  </div>
</div>

<app-spinner-inline-tick #spinnerInlineTick [showSpinner]="showInlineSpinner" [showTick]="showTick" [diameter]="20" [inlineSpinnerId]="'inlineSpinnerId'" class="inline-spinner"></app-spinner-inline-tick>
