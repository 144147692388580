
<div class="app-user-column-update-container">
  <div class="app-user-column-update-form-container">
    <form class="app-user-column-update-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created</mat-label>
              <input id="dteDateCreated" class="date-input created-from-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteDateCreated">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>App User</mat-label>
              <mat-select formControlName="guAppUserId">
                <mat-option *ngFor="let appUser of appUsers" [value]="appUser.rowguid">{{appUser.txtFirstName}} {{appUser.txtLastName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>App Pages</mat-label>
              <mat-select formControlName="guAppPageId" #appPageId (selectionChange)="onPageChange(appPageId.value)">
                <mat-option *ngFor="let appPage of appPages" [value]="appPage.rowguid">{{appPage.txtPageName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>Page Column</mat-label>
              <mat-select formControlName="guAppPageColumnId">
                <mat-option *ngFor="let appPageColumn of appPageColumns" [value]="appPageColumn.rowguid">{{appPageColumn.txtColumnName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>App User Column Set</mat-label>
              <mat-select formControlName="guAppUserColumnSetId">
                <mat-option *ngFor="let appUserColumnSet of appUserColumnSets" [value]="appUserColumnSet.rowguid">{{appUserColumnSet.txtName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="main-row-3">
            <mat-slide-toggle formControlName="blnVisible">Visible</mat-slide-toggle>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Column Order</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 1" value="" type="number" id="intColumnOrder" formControlName="intColumnOrder">
              </mat-form-field>
            </div>
          </div>

          <button class="stocktakeButton" mat-flat-button color="primary" (click)="update()">Update</button>
          <button class="stocktakeButton" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
        </div>

      </div>

    </form>
  </div>
</div>


