import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { AppUserColumnSet } from '../business-objects/app-user-column-set.bo';
import { AppPage } from '../business-objects/app-page.bo';

@Injectable()
export class AppUserColumnSetService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getAppUserColumnSet(appUserColumnSetId: string) {
		return this.http.get<AppUserColumnSet>(this._baseUrl + 'api/AppUserColumnSet/' + appUserColumnSetId);
	}

	getAppUserColumnSetParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<AppUserColumnSet[]>(this._baseUrl + 'api/AppUserColumnSet/GetAppUserColumnSetsParamArray/', aParam);
	}

	getAllAppUserColumnSets() {
		return this.http.get<AppUserColumnSet[]>(this._baseUrl + 'api/AppUserColumnSet/GetAllAppUserColumnSets/');
	}

	//getAlldtoAppUserColumnSets() {
	//	return this.http.get<dtoAppUserColumnSet[]>(this._baseUrl + 'api/AppUserColumnSet/GetAllAppUserColumnSets/');
	//}

	createAppUserColumnSet(appUserColumnSet: AppUserColumnSet) {
		return this.http.post<AppUserColumnSet>(this._baseUrl + 'api/AppUserColumnSet/CreateAppUserColumnSet/', appUserColumnSet);
	}

	updateAppUserColumnSet(appUserColumnSet: AppUserColumnSet) {
		return this.http.put<AppUserColumnSet>(this._baseUrl + 'api/AppUserColumnSet/UpdateAppUserColumnSet/', appUserColumnSet);
	}

    removeAppUserColumnSet(appUserColumnSetId: string) {
      return this.http.delete(this._baseUrl + 'api/AppUserColumnSet/DeleteAppUserColumnSet/' + appUserColumnSetId);
    }

	async getAppUserColumnSetPromise(appUserColumnSet) {
		return new Promise<AppUserColumnSet>(resolve => {
			this.getAppUserColumnSet(appUserColumnSet).subscribe(appUserColumnSet => {
				resolve(appUserColumnSet);
			}, err => {
        this.alertService.openSnackBarError("Error getting AppUserColumnSet records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getAppUserColumnSetByName(appUserColumnSetName: string, appUserId: string, appPageId: string) {
    return new Promise<AppUserColumnSet>(resolve => {
      this.http.get<AppUserColumnSet>(this._baseUrl + 'api/AppUserColumnSet/GetAppUserColumnSetByName/' + appUserColumnSetName + '/' + appUserId + '/' + appPageId).subscribe(appUserColumnSet => {
        resolve(appUserColumnSet);
      }, err => {
        this.alertService.openSnackBarError("Error getting AppUserColumnSet records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getAppUserColumnSetParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<AppUserColumnSet[]>(resolve => {
			this.getAppUserColumnSetParamArray(aParam).subscribe(appUserColumnSets => {
				resolve(appUserColumnSets);
			}, err => {
        this.alertService.openSnackBarError("Error getting AppUserColumnSet records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateAppUserColumnSetPromise(appUserColumnSet: AppUserColumnSet) {
		return new Promise<AppUserColumnSet>(resolve => {
			this.updateAppUserColumnSet(appUserColumnSet).subscribe(appUserColumnSet => {
				resolve(appUserColumnSet);
			}, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating AppUserColumnSet record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createAppUserColumnSetPromise(appUserColumnSet) {
		return new Promise<AppUserColumnSet>(resolve => {
			this.createAppUserColumnSet(appUserColumnSet).subscribe(appUserColumnSet => {
				resolve(appUserColumnSet);
			}, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating AppUserColumnSet record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}


  async removeAppUserColumnSetPromise(appUserColumnSetId: string) {
    return new Promise<boolean>(resolve => {
      this.removeAppUserColumnSet(appUserColumnSetId).subscribe(appUserColumnSet => {
        resolve(true);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error removing AppUserColumnSet record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(false);
      });
    });
  }
}
