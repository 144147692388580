import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppUserGeoStockLocation } from '../business-objects/app-user-geo-stock-location.bo';
import { dtoAppUserGeoStockLocation } from '../business-objects/dto-app-user-geo-stock-location.bo';

@Injectable()
export class AppUserGeoStockLocationService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getAppUserGeoStockLocation(appUserGeoStockLocationId: string) {
    return this.http.get<AppUserGeoStockLocation>(this._baseUrl + 'api/AppUserGeoStockLocation/' + appUserGeoStockLocationId);
  }

  getAppUserGeoStockLocationDefault(appUserId: string) {
    return this.http.get<AppUserGeoStockLocation>(this._baseUrl + 'api/AppUserGeoStockLocation/GetAppUserGeoStockLocationDefault/' + appUserId);
  }

  getAllAppUserGeoStockLocations() {
    return this.http.get<AppUserGeoStockLocation[]>(this._baseUrl + 'api/AppUserGeoStockLocation/GetAllGeoStockLocations/');
  }

  getAlldtoAppUserGeoStockLocations() {
    return this.http.get<dtoAppUserGeoStockLocation[]>(this._baseUrl + 'api/AppUserGeoStockLocation/GetAllDTOGeoStockLocations/');
  }

  createAppUserGeoStockLocation(appUserGeoStockLocation: AppUserGeoStockLocation) {
    return this.http.post<AppUserGeoStockLocation>(this._baseUrl + 'api/AppUserGeoStockLocation/CreateAppUserGeoStockLocation/', appUserGeoStockLocation);
  }

  updateAppUserGeoStockLocation(appUserGeoStockLocation: AppUserGeoStockLocation) {
    return this.http.put<AppUserGeoStockLocation>(this._baseUrl + 'api/AppUserGeoStockLocation/UpdateAppUserGeoStockLocation/', appUserGeoStockLocation);
  }

  removeAppUserGeoStockLocation(appUserGeoStockLocationId: string) {
    return this.http.delete(this._baseUrl + 'api/AppUserGeoStockLocation/' + appUserGeoStockLocationId);
  }


}
