<form class="example-form quote-container-form" [formGroup]="form">
  <div class="quote-container" id="quoteContainer">
    <div class="quotes-heading"><h1>Quotes</h1></div>

    <mat-tab-group color="accent" class="chh-mat-tab-group mat-mdc-tab-group" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabQuotes>
      <mat-tab label="Quotes" class="tab-label quote-search-tab">
        <div class="search-fields-container">
          <div class="search-quote-no">
            <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
              <mat-label>Quote No.</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="text" id="txtSearchQuoteNo" formControlName="txtSearchQuoteNo" (keydown.enter)="enterKeySearch('', $event)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 45680,59680..." class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-customer">
            <app-customer-select [customers]="customers" [showMultiple]="true" (selectedCustomersEvent)="selectedCustomers($event)"></app-customer-select>
          </div>

          <div class="search-status">
            <mat-form-field appearance="fill" class="chh-form-field">
              <mat-label>Status</mat-label>
              <mat-select formControlName="intStatus" multiple>
                <mat-option *ngFor="let quoteStatus of quoteStatuses" [value]="quoteStatus.intValue">{{quoteStatus.txtValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="search-last-number">
            <mat-form-field class="example-full-width header-button" style="width: 90px !important" appearance="fill">
              <mat-label>Last Quotes</mat-label>
              <input class="textbox-input txtSearchLastQuotes" matInput placeholder="" value="20" type="text" #txtSearchLastQuotes id="txtSearchLastQuotes">
            </mat-form-field>
          </div>

          <div class="search-buttons">
            <div class="quotes-button-container">
              <button class="search-quote-button search-button" type="button" mat-flat-button color="primary" (click)="searchQuotes('', 0, $event)">Search</button>
              <button class="search-quote-button search-button" type="button" mat-flat-button color="primary" (click)="searchQuotes('', txtSearchLastQuotes.value, $event)">Search Last {{txtSearchLastQuotes.value}}</button>
              <button class="search-active-quotes-button search-button" type="button" mat-flat-button color="primary" (click)="searchQuotes('active', 0, $event)" *ngIf="false">Search Active Quotes</button>
              <button class="search-completed-quotes-button search-button" type="button" mat-flat-button color="primary" (click)="searchQuotes('completed', 0, $event)" *ngIf="false">Search Completed Quotes</button>
            </div>
          </div>

        </div>

        <mat-divider></mat-divider>

        <div class="quote-list-app-container">
          <app-quote-list #appQuoteList [datasource]="quotes" (editQuoteEvent)="editQuote($event)" (addQuoteItemsEvent)="addQuoteItems($event)" (updatedQuoteEvent)="updatedQuote($event)" (removeQuoteEvent)="removeQuote($event)" [columnBarPage]="'Quotes'"></app-quote-list>
        </div>

      </mat-tab>



      <mat-tab label="Search Options" class="tab-label search-option-tab">
        <div class="search-options-container">
          <div class="quote-search-options">
            <h3>Quote Search Parameters</h3>

            <div class="search-option-row-3">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
                <mat-label>Quoted date from</mat-label>
                <input id="dteSearchQuotedDateFrom" class="date-input loading-date-search" #searchLoadingDateFrom matInput [matDatepicker]="statuspickerfrom" formControlName="dteSearchQuotedDateFrom">
                <mat-datepicker-toggle matSuffix [for]="statuspickerfrom"></mat-datepicker-toggle>
                <mat-datepicker #statuspickerfrom></mat-datepicker>
              </mat-form-field>
            </div>


            <div class="search-option-row-4">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
                <mat-label>Quoted date to</mat-label>
                <input id="dteSearchQuotedDateTo" class="date-input loading-date-search" #searchLoadingDateTo matInput [matDatepicker]="statuspickerto" formControlName="dteSearchQuotedDateTo">
                <mat-datepicker-toggle matSuffix [for]="statuspickerto"></mat-datepicker-toggle>
                <mat-datepicker #statuspickerto></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="search-option-row-1">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Delivery Address</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Dubbo" value="" type="text" id="txtSearchDeliveryAddress" formControlName="txtSearchDeliveryAddress" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>

            </div>


            <div class="search-option-row-1">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Order Number</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 10568,10885" value="" type="text" formControlName="txtSearchOrderNumber" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>



            <div class="header-row-4">
              <div class="search-option-row-order-status">
                <label id="example-radio-group-label">Order Status</label>
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radOnOrder">
                  <mat-radio-button class="example-radio-button" [value]="0">Non Order</mat-radio-button>
                  <mat-radio-button class="example-radio-button" [value]="1">Order</mat-radio-button>
                  <mat-radio-button class="example-radio-button" [value]="2">Both</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div class="search-option-row-5">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-150" appearance="fill">
                <mat-label>Quoted By First Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. John, Brian..." value="" type="text" formControlName="txtSearchQuotedByFirstName" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
              <mat-form-field class="chh-form-field example-full-width chh-textbox-150" appearance="fill">
                <mat-label>Quoted By Last Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Hearne, Krix..." value="" type="text" formControlName="txtSearchQuotedByLastName" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-1">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Quote Comments</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Can supply in multiples" value="" type="text" formControlName="txtSearchQuoteComments" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="Use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-1">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Freight Sub Contractor</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Blanchards" value="" type="text" formControlName="txtSearchFreightSubContractor" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-1">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-175" appearance="fill">
                <mat-label>Subby Freight Value From</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 300" value="" type="number" formControlName="txtSearchSubContractorFreightValueFrom" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              &nbsp;
              <mat-form-field class="chh-form-field example-full-width chh-textbox-175" appearance="fill">
                <mat-label>Subby Freight Value To</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 6800" value="" type="number" formControlName="txtSearchSubContractorFreightValueTo" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
            </div>

            <div class="search-option-row-1">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Sub Contractor Freight Comments</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Tuesday deliveries only." value="" type="text" formControlName="txtSearchSubContractorFreightComments" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="Use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-6">
              <span><mat-slide-toggle formControlName="blnShowSearchItemsOnly">Show Search Items Only</mat-slide-toggle></span>
            </div>



            <mat-divider></mat-divider>
          </div>

          <div class="quote-item-search-options">
            <h3>Quote Item Search Parameters</h3>

            <div class="search-product-type-container">
              <app-product-code-select [multipleCodes]="true" (selectedProductCodesEvent)="selectedProductCodes($event)"></app-product-code-select>
            </div>

            <div class="search-option-row-1">
              <div class="search-product-type-container">
                <app-product-type-select [productTypes]="productTypes" (selectedProductTypesEvent)="selectedProductTypes($event)"></app-product-type-select>
              </div>
            </div>

            <div class="search-option-row-1">
              <div class="search-product-profile-container">
                <app-product-profile-select [productProfiles]="productProfiles" (selectedProductProfilesEvent)="selectedProductProfiles($event)"></app-product-profile-select>
              </div>
            </div>

            <div class="search-option-row-5">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-150 search-width" appearance="fill">
                <mat-label>Width</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 80,130" value="" type="text" formControlName="txtSearchWidth" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>

              <mat-form-field class="chh-form-field example-full-width chh-textbox-150 search-thickness" appearance="fill">
                <mat-label>Thickness</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 19,25,30,42" value="" type="text" formControlName="txtSearchThickness" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>

            </div>

            <div class="search-option-row-5">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-150 search-nominal-width" appearance="fill">
                <mat-label>Nominal Width</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 80,130" value="" type="text" formControlName="txtSearchNominalWidth" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>

              <mat-form-field class="chh-form-field example-full-width chh-textbox-150 search-nominal-thickness" appearance="fill">
                <mat-label>Nominal Thickness</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 19,25,30,42" value="" type="text" formControlName="txtSearchNominalThickness" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-5">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-150 search-ex-width" appearance="fill">
                <mat-label>Ex Width</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 80,130" value="" type="text" formControlName="txtSearchExWidth" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>

              <mat-form-field class="chh-form-field example-full-width chh-textbox-150 search-ex-thickness" appearance="fill">
                <mat-label>Ex Thickness</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 19,25,30,42" value="" type="text" formControlName="txtSearchExThickness" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-5">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-150 search-length" appearance="fill">
                <mat-label>Length</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 2.4,4.2,4.8,6" value="" type="text" formControlName="txtSearchLength" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
              <mat-form-field class="chh-form-field example-full-width chh-textbox-100" appearance="fill">
                <mat-label>Pole KN</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 4,6,8,12" value="" type="text" formControlName="txtSearchPoleKN" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-5">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-150" appearance="fill">
                <mat-label>Diameter</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 250,300,350" value="" type="text" formControlName="txtSearchDiameter" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-5">
              <div class="search-species-container">
                <app-species-select [species]="species" [showSpeciesASCode]="true" (selectedSpeciesEvent)="selectedSpecies($event)"></app-species-select>
              </div>
            </div>

            <div class="search-option-row-5">
              <div class="search-grade-container">
                <app-grade-select [grades]="grades" (selectedGradesEvent)="selectedGrades($event)"></app-grade-select>
              </div>
            </div>

            <div class="search-option-row-5">
              <mat-form-field appearance="fill" class="chh-form-field chh-textbox-200">
                <mat-label>Treatment</mat-label>
                <mat-select formControlName="intSearchTreatment" multiple>
                  <mat-option *ngFor="let treatmentType of treatmentTypes" [value]="treatmentType.intValue">{{treatmentType.txtValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="search-option-row-5">
              <mat-form-field appearance="fill" class="chh-form-field chh-textbox-200">
                <mat-label>Treatment Hazard Level</mat-label>
                <mat-select formControlName="intSearchTreatmentHazardLevel" multiple>
                  <mat-option *ngFor="let treatmentHazardLevel of treatmentHazardLevels" [value]="treatmentHazardLevel.intValue">{{treatmentHazardLevel.txtValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="search-option-row-1">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Quote Item Comments</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Can supply in multiples" value="" type="text" formControlName="txtSearchQuoteItemComments" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="Use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

          </div>

          <div class="quote-search-button">
            <div class="quote-search-button-container">
              <button class="search-option-quote-button" type="button" mat-flat-button color="primary" id="btnSearchOptionsButton" (click)="searchQuotes('', 0, $event)">Search</button>
            </div>
          </div>

          <div class="customer-contact-search-options">
            <h3>Customer Contact Search<br />Parameters</h3>

            <div class="search-option-row-2">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-175" appearance="fill">
                <mat-label>Contact First Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. John" value="" type="text" formControlName="txtSearchContactFirstName" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>

              <mat-form-field class="chh-form-field example-full-width chh-textbox-175" appearance="fill">
                <mat-label>Contact Last Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Smith" value="" type="text" formControlName="txtSearchContactLastName" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-2">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Contact Email</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. john@essentialenergy.com.au" value="" type="email" formControlName="txtSearchContactEmail" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-2">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Contact Mobile</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 0429 492 006" value="" type="text" formControlName="txtSearchContactMobile" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-2">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Contact Phone</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 02 6649 2006" value="" type="text" formControlName="txtSearchContactPhone" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

          </div>

          <div class="search-column-spare" *ngIf="false"></div>

          <div class="search-column-search-button">
            <button class="search-option-quote-button" type="button" mat-flat-button color="primary" id="btnSearchOptionsButton2" (click)="searchQuotes('', 0, $event)">Search</button>
          </div>

        </div>

      </mat-tab>

      <mat-tab label="Create Quote" class="tab-label">
        <div class="create-quote-container">
          <app-quote-create class="create-quote-app" [inDialog]="false" (quoteCreated)="quoteCreated($event)" (quoteCancelled)="quoteCancelled($event)" *ngIf="selectedTab == eQuoteTabs.CreateQuote"></app-quote-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Quote" class="tab-label">
        <div class="update-quote-container">
          <app-quote-update [quote]="quote" (quoteUpdated)="quoteUpdated($event)" (quoteCancelled)="quoteCancelled($event)" *ngIf="selectedTab == eQuoteTabs.UpdateQuote"></app-quote-update>
        </div>
      </mat-tab>

      <mat-tab label="Add Quote Items" class="tab-label">
        <div class="add-quote-items-container">
          <!--<app-quote-add-items #quoteAddItems [quote]="quoteAddItem" (quoteItemsAdded)="quoteItemsAdded($event)" (quoteItemsCancelled)="quoteItemsCancelled($event)" *ngIf="selectedTab == eQuoteTabs.DocketAddItems"></app-quote-add-items>-->
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
