<div class="appuser-list-list-container" id="appUserListContainer">

  <app-app-user-column-set-bar [componentColumns]="displayedColumns" [pageName]="columnBarPage" [container]="columnBarContainer" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)" *ngIf="loadedComponent"></app-app-user-column-set-bar>

  <table mat-table #tblAppUser [dataSource]="datasource" multiTemplateDataRows class="mat-elevation-z8 appuser-list-table" *ngIf="datasource != null">

    <ng-container matColumnDef="txtFirstName">
      <th mat-header-cell *matHeaderCellDef class="appUserTH alignRight"> First Name </th>
      <td mat-cell *matCellDef="let appUser" class="firstname appUserTD alignRight"> {{appUser.txtFirstName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtLastName">
      <th mat-header-cell *matHeaderCellDef class="appUserTH alignRight"> Last Name </th>
      <td mat-cell *matCellDef="let appUser" class="appUserTD lastname alignRight"> {{appUser.txtLastName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtUserName">
      <th mat-header-cell *matHeaderCellDef class="appUserTH alignRight"> User Name </th>
      <td mat-cell *matCellDef="let appUser" class="appUserTD username alignRight"> {{appUser.txtUserName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtFromAddresses">
      <th mat-header-cell *matHeaderCellDef class="appUserTH alignRight"> From Addresses </th>
      <td mat-cell *matCellDef="let appUser" class="appUserTD from-addresses alignRight"> {{appUser.txtFromAddresses}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditAppUser">
      <th mat-header-cell *matHeaderCellDef class="appUserTH"></th>
      <td mat-cell *matCellDef="let appUser" class="appUserTD"><button class="edit-appuser-list-button" type="button" mat-flat-button color="primary" (click)="editAppUser(appUser)">Edit</button></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveAppUser">
      <th mat-header-cell *matHeaderCellDef class="appUserTH"></th>
      <td mat-cell *matCellDef="let appUser" class="appUserTD"><button class="remove-appuser-list-button" type="button" mat-flat-button color="primary" (click)="removeAppUser(appUser.rowguid)">Remove</button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let appUser; let idx = dataIndex;" id="locationrow{{idx}}"></tr>

  </table>

</div>
