<h1>Pole Stock Take</h1>
<form class="pole-stock-take-form" [formGroup]="form">
  <div class="header-row-4">
    <mat-form-field class="example-full-width example-container" appearance="fill">
      <mat-label>Pole Number</mat-label>
      <input class="pole-stock-take-input" matInput placeholder="" value="" formControlName="txtIdentifier">
      <mat-error *ngIf="false">
      </mat-error>
    </mat-form-field>
    <button class="stocktakeButton" mat-flat-button color="primary" (click)="addToStocktake()">Add to Stocktake</button>&nbsp;
  </div>
</form>

