import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../account/auth.service';
import { Client } from '../../_shared/business-objects/client.bo';
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';
import { dtoHaulingCompanyDriver } from '../../_shared/business-objects/dto-hauling-company-driver.bo';
import { dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { HaulingCompany } from '../../_shared/business-objects/hauling-company.bo';
import { LoadingDocket } from '../../_shared/business-objects/loading-docket.bo';
import { MobilePlantEquipment } from '../../_shared/business-objects/mobile-plant-equipment.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { ClientService } from '../../_shared/services/client.service';
import { CustomerContactService } from '../../_shared/services/customer-contact.service';
import { FormService } from '../../_shared/services/form.service';
import { dtoHaulingCompanyDriverService } from '../../_shared/services/dto-hauling-company-driver.service';
import { HaulingCompanyService } from '../../_shared/services/hauling-company.service';
import { LoadingDocketService } from '../../_shared/services/loading-docket.service';
import { MobilePlantEquipmentService } from '../../_shared/services/mobile-plant-equipment.service';
import { SharedService } from '../../_shared/services/shared.service';
import { MobilePlantCategory } from '../../_shared/business-objects/mobile-plant-category.bo';
import { MobilePlantCategoryService } from '../../_shared/services/mobile-plant-category.service';
import { ViewChild } from '@angular/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';
import { dtoHaulingCompany } from '../../_shared/business-objects/dto-hauling-company.bo';
import { MobilePlantEquipmentSelectionComponent } from '../../mobile-plant/mobile-plant-equipment-selection/mobile-plant-equipment-selection.component';
import { HaulingCompanyDriverSelectionComponent } from '../../hauling/hauling-company-driver-selection/hauling-company-driver-selection.component';
import { dtoHaulingCompanyService } from '../../_shared/services/dto-hauling-company.service';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { DeliveryDocketOrderItem } from '../../_shared/business-objects/delivery-docket-order-item.bo';
import { DeliveryDocketService } from '../../_shared/services/delivery-docket.service';
import { DeliveryDocketOrderItemService } from '../../_shared/services/delivery-docket-order-item.service';

@Component({
  selector: 'app-loading-docket-update',
  templateUrl: './loading-docket-update.component.html',
  styleUrls: ['./loading-docket-update.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class LoadingDocketUpdateComponent implements OnInit {

  form: FormGroup;

  @ViewChild('guHaulerId') guHaulerId: MatSelectionList;
  @ViewChild('guDriverId') guDriverId: MatSelectionList;
  @ViewChild('guTrailerId') guTrailerId: MatSelectionList;
  @ViewChild('appHaulingCompanyDriverSelection') appHaulingCompanyDriverSelection: HaulingCompanyDriverSelectionComponent
  @ViewChild('appMobilePlantEquipmentSelection') appMobilePlantEquipmentSelection: MobilePlantEquipmentSelectionComponent


  @Output() loadingDocketUpdated = new EventEmitter<LoadingDocket>();
  @Output() loadingDocketCancelled = new EventEmitter<boolean>();
  @Input() loadingDocket: LoadingDocket;
  @Input() inDialog: boolean = false;


  public customersFilterCtrl: FormControl = new FormControl();
  public customerContactsFilterCtrl: FormControl = new FormControl();
  public haulersFilterCtrl: FormControl = new FormControl();

  public customers: Client[];
  public customerContacts: CustomerContact[];
  public haulers: HaulingCompany[] = [];
  haulersAll: HaulingCompany[] = [];
  drivers: dtoHaulingCompanyDriver[] = [];
  trailers: MobilePlantEquipment[] = [];
  driversAll: dtoHaulingCompanyDriver[] = [];
  trailersAll: MobilePlantEquipment[] = [];

  //selectedHaulingCompany: HaulingCompany;
  //selectedHaulingCompanyDriver: dtoHaulingCompanyDriver;
  selectedTrailer: MobilePlantEquipment;

  selectedHaulingCompany: dtoHaulingCompany;
  selectedHaulingCompanyDriver: dtoHaulingCompanyDriver;
  haulingCompanyId: string;
  trailerEquipment: MobilePlantEquipment;
  equipmentCategoryId: string;
  chhVehicle: boolean;

  public filteredCustomers: ReplaySubject<Client[]> = new ReplaySubject<Client[]>(1);
  public filteredCustomerContacts: ReplaySubject<CustomerContact[]> = new ReplaySubject<CustomerContact[]>(1);
  public filteredHaulers: ReplaySubject<HaulingCompany[]> = new ReplaySubject<HaulingCompany[]>(1);
  yardSections: dtoKeyValue[] = [];

  private _onDestroy = new Subject<void>();

  showSpinner: boolean

  constructor(private formBuilder: FormBuilder, private formService: FormService, private customerService: ClientService, private dtoHaulerService: dtoHaulingCompanyService, private sharedService: SharedService
    , private customerContactService: CustomerContactService, private alertService: AlertService, private authService: AuthService
    , private loadingDocketService: LoadingDocketService, private dtoHaulingCompanyDriverService: dtoHaulingCompanyDriverService, private mobilePlantEquipmentService: MobilePlantEquipmentService
    , private mobilePlantCategoryService: MobilePlantCategoryService, private deliveryDocketService: DeliveryDocketService, private deliveryDocketOrderItemService: DeliveryDocketOrderItemService  ) {

    this.yardSections = this.sharedService.getEnumObject2(SharedService.enYardSection);

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      guHaulerId: [''],
      guDriverId: [''],
      guTrailerId: [''],
      dteExpectedLoadingDate: [''],
      dteExpectedDeliveryDate: [''],
      dteDateLoaded: [''],
      intLoadNo: [''],
      intMaxLoads: [''],
      txtComments: [''],
      intYardSection: ['']
    });

    if (this.loadingDocket == null) {
      this.alertService.openSnackBar("There is no loading docket to update", "Close", "center", "bottom", "", 4000);
      return;
    }

    this.showSpinner = false;

    this.loadData();



  }

  async loadData() {

    const datepipe: DatePipe = new DatePipe('en-AU');

    this.form.controls['dteDateLoaded'].setValue(datepipe.transform(new Date(Date.now()), 'yyyy-MM-dd'));
    this.form.controls['intLoadNo'].setValue(1);
    this.form.controls['intMaxLoads'].setValue(1);

    //await this.getHaulers();
    //await this.getDrivers("");
    //await this.getTrailers();

    this.form.controls['guHaulerId'].setValue(this.loadingDocket.guHaulerId);
    this.form.controls['guDriverId'].setValue(this.loadingDocket.guDriverId);
    this.form.controls['guTrailerId'].setValue(this.loadingDocket.guTrailerId);
    this.form.controls['dteExpectedLoadingDate'].setValue(this.loadingDocket.dteExpectedLoadingDate);
    this.form.controls['dteExpectedDeliveryDate'].setValue(this.loadingDocket.dteExpectedDeliveryDate);
    this.form.controls['dteDateLoaded'].setValue(this.loadingDocket.dteDateLoaded);
    this.form.controls['intLoadNo'].setValue(this.loadingDocket.intLoadNo);
    this.form.controls['intMaxLoads'].setValue(this.loadingDocket.intMaxLoads);
    this.form.controls['intYardSection'].setValue(this.loadingDocket.intYardSection);
    this.form.controls['txtComments'].setValue(this.loadingDocket.txtComments);

    if (this.loadingDocket.guHaulerId != null && this.loadingDocket.guHaulerId != "") {
      this.selectedHaulingCompany = await this.dtoHaulerService.getdtoHaulingCompanyPromise(this.loadingDocket.guHaulerId);
      //if (this.guHaulerId != null) {

      //  let aHaulers = this.guHaulerId.options.toArray();
      //  let selectedHaulerHeight: number = 0;
      //  let haulerList = document.getElementById("guHaulerId");

      //  for (let i = 0; i <= aHaulers.length - 1; i++) {
      //    let el = aHaulers[i]._hostElement;
      //    selectedHaulerHeight += el.clientHeight;
      //    if (aHaulers[i].value.rowguid == this.loadingDocket.guHaulerId) {
      //      break;
      //    } 
      //  };

      //  if (selectedHaulerHeight > 500) {
      //    haulerList.scrollTo({ top: selectedHaulerHeight - 75, left: 0, behavior: 'smooth' });
      //  }

      //}

    }

    if (this.loadingDocket.guDriverId != null && this.loadingDocket.guDriverId != "") {
      this.selectedHaulingCompanyDriver = await this.dtoHaulingCompanyDriverService.getdtoHaulingCompanyDriverPromise(this.loadingDocket.guDriverId);
      this.appHaulingCompanyDriverSelection.haulingCompanyId = this.loadingDocket.guHaulerId;
      this.appHaulingCompanyDriverSelection.loadData();

      //if (this.guDriverId != null) {

      //  let selectedDriverHeight: number = 0;
      //  let driverList = document.getElementById("guDriverId");
      //  let aDrivers = this.guDriverId.options.toArray();
      //  for (let i = 0; i <= aDrivers.length - 1; i++) {

      //    let el = aDrivers[i]._hostElement;

      //    selectedDriverHeight += el.clientHeight;

      //    if (aDrivers[i].value.rowguid == this.loadingDocket.guDriverId) {
      //      break;
      //    }
      //  };


      //  if (selectedDriverHeight > 500) {
      //    driverList.scrollTo({ top: selectedDriverHeight - 75, left: 0, behavior: 'smooth' });
      //  }

      //}



    }

    this.equipmentCategoryId = await this.mobilePlantCategoryService.getMobilePlantCategoryId("Trailer");
    
    this.appMobilePlantEquipmentSelection.EquipmentCategory = this.equipmentCategoryId;
    this.appMobilePlantEquipmentSelection.loadData();

    if (this.loadingDocket.guTrailerId != null && this.loadingDocket.guTrailerId != "") {

      this.trailerEquipment = await this.mobilePlantEquipmentService.getMobilePlantEquipmentPromise(this.loadingDocket.guTrailerId);
      
      this.appMobilePlantEquipmentSelection.setSelectedMobilePlantEquipment(this.trailerEquipment);

    }


    //window.scrollTo(0, 0);

  }


  async getHaulers() {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];
    let haulers = await this.dtoHaulerService.getdtoHaulingCompanyParamArrayPromise(paramArrayList);
    if (haulers == null) {
      return;
    }

    this.haulersAll = haulers;
    this.haulers = haulers;

  }


  filterHaulers(name: string) {

    if (!this.haulersAll) {
      return;
    }

    this.haulers = this.haulersAll;

    this.haulers = this.haulers.filter(h => h.txtName.toLowerCase().indexOf(name) > -1);

  }


  async getDrivers(haulerId: string) {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];

    if (haulerId == "" && this.loadingDocket != null && (this.loadingDocket.guHaulerId != null || this.loadingDocket.guHaulerId != "")) {
      paramArray.push(new SQLParamArray("guHaulingCompanyId", this.loadingDocket.guHaulerId));
      paramArrayList.push(paramArray);
    }

    if (haulerId != "") {
      paramArray.push(new SQLParamArray("guHaulingCompanyId", haulerId));
      paramArrayList.push(paramArray);
    }

    let drivers = await this.dtoHaulingCompanyDriverService.getdtoHaulingCompanyDriverParamArrayPromise(paramArrayList);
    if (drivers == null) {
      return;
    }

    let blankHaulingCompany: dtoHaulingCompanyDriver = new dtoHaulingCompanyDriver();
    blankHaulingCompany.rowguid = null;
    blankHaulingCompany.txtFirstName = "None";

    //console.log("this.drivers - before: ", drivers);

    drivers.splice(0, 0, blankHaulingCompany);

    //console.log("this.drivers - after: ", drivers);

    this.drivers.sort((a, b) => { return (a.txtFirstName > b.txtFirstName ? 1 : -1) });

    this.driversAll = drivers;
    this.drivers = drivers;



  }


  filterDrivers(name: string) {

    if (this.driversAll == null) {
      return;
    }

    this.drivers = this.driversAll;

    // get the search keyword
    //this.drivers = this.drivers.filter(a => (a.blnCHHVehicle == true && a.guMobilePlantEquipmentId != null && a.txtMobilePlantCategoryName.toLowerCase() == "truck") || a.txtFirstName == "None");


    this.drivers = this.drivers.filter(d => d.txtFirstName.toLowerCase().indexOf(name) > -1 || d.txtLastName.toLowerCase().indexOf(name) > -1);

    //console.log("this.drivers: ", this.drivers);

  }


  async getTrailers() {
    let paramArrayList: SQLParamArray[][] = [];
    let paramArray: SQLParamArray[] = [];

    paramArray.push(new SQLParamArray("txtName", "trailer"));
    paramArrayList.push(paramArray);

    let mobilePlantCategory:MobilePlantCategory[] = await this.mobilePlantCategoryService.getMobilePlantCategoryParamArrayPromise(paramArrayList);
    let categoryId: string = "";

    paramArrayList = [];
    paramArray = [];

    if (mobilePlantCategory != null && mobilePlantCategory.length > 0) {
      categoryId = mobilePlantCategory[0].rowguid;
      paramArray.push(new SQLParamArray("guMobilePlantCategoryId", categoryId));
      paramArrayList.push(paramArray);
    }

    console.log("mobilePlantCategory: ", mobilePlantCategory);


    let trailers = await this.mobilePlantEquipmentService.getMobilePlantEquipmentParamArrayPromise(paramArrayList);
    if (trailers == null) {
      return;
    }

    trailers.sort((a, b) => { return (a.intIdentifier - b.intIdentifier) });

    this.trailersAll = trailers;
    this.trailers = trailers;


  }


  filterTrailers(name: string) {

    if (this.driversAll == null) {
      return;
    }

    this.trailers = this.trailersAll;

    // get the search keyword
    //this.drivers = this.drivers.filter(a => (a.blnCHHVehicle == true && a.guMobilePlantEquipmentId != null && a.txtMobilePlantCategoryName.toLowerCase() == "truck") || a.txtFirstName == "None");

    this.trailers = this.trailers.filter(t => t.txtName.toLowerCase().indexOf(name) > -1);

  }

  chhVehicleSelection(val: boolean) {
    if (val == true) {
      this.appMobilePlantEquipmentSelection.chhVehicles = true;
      this.appMobilePlantEquipmentSelection.loadData();
    }

    if (val == false) {
      this.appMobilePlantEquipmentSelection.chhVehicles = false;
      this.appMobilePlantEquipmentSelection.loadData();
    }

  }


  haulingCompanySelection(haulingCompany: dtoHaulingCompany[]) {

    if (haulingCompany == null) {
      return;
    }

    if (haulingCompany != null && haulingCompany.length > 0) {
      //this.haulingCompany = haulingCompany[0];
      this.haulingCompanyId = haulingCompany[0].rowguid;
      this.appHaulingCompanyDriverSelection.haulingCompanyId = this.haulingCompanyId;
      this.appHaulingCompanyDriverSelection.selectedHaulingCompanyDriver = null;
      this.appHaulingCompanyDriverSelection.loadData();
    }

    console.log("haulingCompanySelection", haulingCompany[0].rowguid);

    this.form.controls['guHaulerId'].setValue(haulingCompany[0].rowguid);
    console.log("guHaulerId", this.form.controls['guHaulerId'].value);

  }

  haulingCompanyDriverSelection(haulingCompanyDriver: dtoHaulingCompanyDriver[]) {

    if (haulingCompanyDriver == null) {
      return;
    }

    console.log("haulingCompanyDriverSelection", haulingCompanyDriver[0].rowguid);

    this.form.controls['guDriverId'].setValue(haulingCompanyDriver[0].rowguid);
    console.log("guDriverId", this.form.controls['guDriverId'].value);


  }

  equipmentSelection(equipment: MobilePlantEquipment[]) {

    if (equipment == null) {
      return;
    }

    console.log("equipmentSelection", equipment[0].rowguid);

    this.form.controls['guTrailerId'].setValue(equipment[0].rowguid);
    console.log("guTrailerId", this.form.controls['guTrailerId'].value);


  }

  async updateLoadingDocket() {

    let haulerId: string = this.form.controls['guHaulerId'].value;
    let driverId: string = this.form.controls['guDriverId'].value;
    let trailerId: string = this.form.controls['guTrailerId'].value;
    let expectedLoadingDate: Date = this.form.controls['dteExpectedLoadingDate'].value;
    let expectedDeliveryDate: Date = this.form.controls['dteExpectedDeliveryDate'].value;
    let dateLoaded: Date = this.form.controls['dteDateLoaded'].value;
    let loadNo: number = Number(this.form.controls['intLoadNo'].value);
    let maxLoads: number = Number(this.form.controls['intMaxLoads'].value);
    let yardSection: number = Number(this.form.controls['intYardSection'].value);
    let comments: string = this.form.controls['txtComments'].value;
    let updateExpectedLoadingDate: boolean = false;
    let updateExpectedDeliveryDate: boolean = false;

    //console.log("guHaulerId: ", haulerId);
    //console.log("guDriverId: ", driverId);
    //console.log("guTrailerId: ", trailerId);


    //if (dateLoaded == null || dateLoaded.toString() == "") {
    //  this.alertService.openSnackBar("Please select a date loaded.", "Close", "center", "bottom", "", 3000);
    //  return;
    //}


    if (loadNo == null || loadNo < 0) {
      this.alertService.openSnackBar("Please enter a load number.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (maxLoads == null || maxLoads < 0) {
      this.alertService.openSnackBar("Please enter number of loads.", "Close", "center", "bottom", "", 3000);
      return;
    }

    //console.log("dteExpectedLoadingDate ==  expectedLoadingDate ", this.loadingDocket.dteExpectedLoadingDate + "  == " + expectedLoadingDate);
    //console.log("dteExpectedDeliveryDate ==  expectedDeliveryDate ", this.loadingDocket.dteExpectedDeliveryDate + "  == " + expectedDeliveryDate);

    if (this.sharedService.convertDateAEST(this.loadingDocket.dteExpectedLoadingDate) != this.sharedService.convertDateAEST(expectedLoadingDate)) {
      updateExpectedLoadingDate = true;
      //console.log("updateExpectedLoadingDat: " + updateExpectedLoadingDate);
    }

    if (this.sharedService.convertDateAEST(this.loadingDocket.dteExpectedDeliveryDate) != this.sharedService.convertDateAEST(expectedDeliveryDate)) {
      updateExpectedDeliveryDate = true;
      //console.log("updateExpectedDeliveryDate: " + updateExpectedDeliveryDate);
   }

    this.loadingDocket.dteExpectedLoadingDate = this.sharedService.convertDateAEST(expectedLoadingDate);
    this.loadingDocket.dteExpectedDeliveryDate = this.sharedService.convertDateAEST(expectedDeliveryDate);


    //this.loadingDocket.dteDateLoaded = null;
    //if (dateLoaded != null) {
    //  this.loadingDocket.dteDateLoaded = this.sharedService.convertDateAEST(dateLoaded);
    //}
    
    this.loadingDocket.guHaulerId = null;
    if (haulerId != null && haulerId != "") {
      this.loadingDocket.guHaulerId = haulerId;
    }

    this.loadingDocket.guDriverId = null;
    if (driverId != null && driverId != "") {
      this.loadingDocket.guDriverId = driverId;
    }

    this.loadingDocket.guTrailerId = null;
    if (trailerId != null && trailerId != "") {
      this.loadingDocket.guTrailerId = trailerId;
    }

    this.loadingDocket.intLoadNo = loadNo;
    this.loadingDocket.intMaxLoads = maxLoads;
    this.loadingDocket.txtComments = comments;
   //this.loadingDocket.guLoadingLocationId =
    this.loadingDocket.intYardSection = yardSection;

    this.showSpinner = true;

    //console.log(this.loadingDocket);
    this.loadingDocket = await this.loadingDocketService.updateLoadingDocketPromise(this.loadingDocket);

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("guLoadingDocketId", this.loadingDocket.rowguid, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1));
    aParamList.push(aParam);

    if (updateExpectedLoadingDate == true || updateExpectedDeliveryDate == true) {
      let deliveryDockets: DeliveryDocket[] = await this.deliveryDocketService.getDeliveryDocketParamArrayPromise(aParamList);
      if (deliveryDockets != null) {
        for (let d = 0; d <= deliveryDockets.length - 1; d++) {

          //console.log("DeliveryDocket - before: ", deliveryDockets[d]);

          this.loadingDocket.dteExpectedDeliveryDate = this.sharedService.convertDateAEST(expectedDeliveryDate);
          if (updateExpectedLoadingDate == true) {
            deliveryDockets[d].dteExpectedLoadingDate = this.sharedService.convertDateAEST(expectedLoadingDate);
          }

          if (updateExpectedDeliveryDate == true) {
            deliveryDockets[d].dteExpectedDeliveryDate = this.sharedService.convertDateAEST(expectedDeliveryDate);
          }

          //console.log("DeliveryDocket - after: ", deliveryDockets[d]);

          await this.deliveryDocketService.updateDeliveryDocketPromise(deliveryDockets[d]);

          //console.log("DeliveryDocket updated: ");

          //console.log("delivery docket:", d);

        }
      }
    }

    if (this.loadingDocket != null) {
      this.loadingDocket = await this.loadingDocketService.getLoadingDocketPromise(this.loadingDocket.rowguid);

      this.alertService.openSnackBar("The loading docket has been updated", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;
      this.loadingDocketUpdated.emit(this.loadingDocket);
    }


    if (this.loadingDocket == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem updaing the loading docket", "Close", "center", "bottom", 3000, true, "There was a problem creating the loading docket");
    }



  }

  cancelLoadingDocket() {
    this.loadingDocketCancelled.emit(true);
  }

}
