export interface LoadingDocket
{
	dteDateCreated: Date;
	dteDateDispatched: Date;
	dteDateLoaded: Date;
	dteExpectedDeliveryDate: Date;
	dteExpectedLoadingDate: Date;
	dteLastUpdated: Date;
	guDriverId: string;
	guHaulerId: string;
	guLoadingLocationId: string;
	guMobilePlantEquipmentId: string;
	guTrailerId: string;
	intDocketNo: number;
	intLoadNo: number;
	intMaxLoads: number;
	intStatus: number;
	intYardSection: number;
	rowguid: string;
	txtComments: string;
}

export class LoadingDocket
{
	dteDateCreated: Date;
	dteDateDispatched: Date;
	dteDateLoaded: Date;
	dteExpectedDeliveryDate: Date;
	dteExpectedLoadingDate: Date;
	dteLastUpdated: Date;
	guDriverId: string;
	guHaulerId: string;
	guLoadingLocationId: string;
	guMobilePlantEquipmentId: string;
	guTrailerId: string;
	intDocketNo: number;
	intLoadNo: number;
	intMaxLoads: number;
	intStatus: number;
	intYardSection: number;
	rowguid: string;
	txtComments: string;
}

export namespace LoadingDocket
{
	export enum enLoadingDocketStatus
	{
		ReadyForLoading,
		Scheduled,
		Loaded,
		Dispatched,
		Complete
	}
}

