<form class="example-form machine-run-container-form" [formGroup]="form">

  <div class="machine-run-container" id="machineRunContainer">
    <h1>Machine Runs</h1>

    <mat-tab-group color="accent" class="chh-mat-tab-group mat-mdc-tab-group" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabMachineRuns>
      <mat-tab label="Machine Runs" class="tab-label">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Machine Run No.</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="text" formControlName="txtSearchMachineRunNo" (keydown.enter)="searchMachineRuns('')">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 55670,28631..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-3">
            <button class="search-machine-run-button" type="button" mat-flat-button color="primary" (click)="searchMachineRuns('')">Search</button>
          </div>

        </div>

        <mat-divider></mat-divider>

        <div class="machine-run-list-container">
          <app-machine-run-list #appMachineRunList [datasource]="machineRuns" [openMachineRun]="openMachineRun" [columnBarPage]="'Machine Runs'" (editMachineRunEvent)="editMachineRun($event)" (addMachineRunItemsEvent)="addMachineRunItems($event)" *ngIf="machineRuns != null"></app-machine-run-list>
        </div>

      </mat-tab>

      <mat-tab label="Search Options" class="tab-label">
        <div class="search-options-fields-container">
          <div class="search-option-row-1">
            <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>Moulder</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Weinig" value="" type="text" id="txtSearchMoulder" formControlName="txtSearchMoulder">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>Species</mat-label>
              <mat-select formControlName="guSpeciesId" multiple>
                <mat-option *ngFor="let specie of species" [value]="specie.rowguid">{{specie.txtName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

 
          <div class="search-option-row-3">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Machining date from</mat-label>
              <input id="dteSearchDateFrom" class="date-input receipt-date-search" #searchMachineDateFrom matInput [matDatepicker]="machinepickerfrom" formControlName="dteSearchDateFrom">
              <mat-datepicker-toggle matSuffix [for]="machinepickerfrom"></mat-datepicker-toggle>
              <mat-datepicker #machinepickerfrom></mat-datepicker>
            </mat-form-field>
          </div>


          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Machining date to</mat-label>
              <input id="dteSearchDateTo" class="date-input receipt-date-search" #searchMachineDateTo matInput [matDatepicker]="machinepickerto" formControlName="dteSearchDateTo">
              <mat-datepicker-toggle matSuffix [for]="machinepickerto"></mat-datepicker-toggle>
              <mat-datepicker #machinepickerto></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-7">
            <button class="search-option-machine-run-button" type="button" mat-flat-button color="primary" (click)="searchMachineRuns('')">Search</button>
          </div>

          <mat-divider></mat-divider>

        </div>
      </mat-tab>

      <mat-tab label="Create Machine Run" class="tab-label">
        <div class="create-machine-run-container">
          <app-machine-run-create (machineRunCreated)="machineRunCreated($event)" (machineRunCancelled)="machineRunCancelled($event)" *ngIf="selectedTab == eMachineRunTabs.CreateMachineRun"></app-machine-run-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Machine Run" class="tab-label">
        <div class="update-machine-run-container">
          <app-machine-run-update [machineRun]="machineRun" [orderId]="orderId" (machineRunUpdated)="machineRunUpdated($event)" (machineRunCancelled)="machineRunCancelled($event)" *ngIf="selectedTab == eMachineRunTabs.UpdateMachineRun"></app-machine-run-update>
        </div>
      </mat-tab>

      <mat-tab label="Add Raw Material Packs" class="tab-label">
        <div class="add-docket-items-container">
          <app-machine-run-add-items #machineRunAddItems [machineRun]="machineRunAddItem" [columnListId]="'columnListId'" (machineRunItemsAdded)="machineRunItemsAdded($event)" (machineRunItemsCancelled)="machineRunItemsCancelled($event)" *ngIf="selectedTab == eMachineRunTabs.AddMachineRecords"></app-machine-run-add-items>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
