import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-quote-print',
  templateUrl: './quote-print.component.html',
  styleUrl: './quote-print.component.scss'
})
export class QuotePrintComponent {

  @Input() showTOCs: boolean;
  @Input() quoteIdInput: boolean;


}
