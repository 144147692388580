<div class="number-of-photos" *ngIf="loadFileAttachments != null">Loading Photos {{loadNumber}} of {{totalPhotos}}</div>
<div class="no-photos" *ngIf="fileAttachments == null || fileAttachments.length == 0">There are no photos</div>
<div class="photo-viewer-contaier" *ngIf="fileAttachments != null && fileAttachments.length > 0">
  @for(fileAttachment of fileAttachments; track fileAttachment){
  <div class="photo-column">
    <div>
      <img [src]="fileAttachment.dataURI()" width="500" alt="Image" (click)="openPhoto(fileAttachment)" />
    </div>
    <div class="file-name">{{fileAttachment.fileName}}</div>
    <div class="file-date">{{fileAttachment.fileDate | date: 'dd/MM/yyyy hh:mm a'}}</div>
  </div>

  <div class="photo-action-column">
    <mat-checkbox class="example-margin" #parkedFinal id="chkPhotoSelected" #chkPhotoSelected (change)="selectedPhoto(chkPhotoSelected, fileAttachment)"></mat-checkbox>
  </div>
  }

</div>
<div class="button-grid-container">
  <div class="photo-viewer-button-container">
    <div class="email-button-container">
      <button class="email-button" type="button" mat-flat-button color="primary" (click)="emailPhotos()">Email Photos</button>
    </div>
    <div class="delete-button-container">
      <button class="email-button" type="button" mat-flat-button color="primary" (click)="deletePhotos()">Delete Selected Photo</button>
    </div>
    <div class="take-photo-button-container">
      <button type="button" mat-flat-button color="primary" (click)="fileInput.click()">Take Photo</button>
      <input hidden (change)="savePhoto($event)" #fileInput type="file" id="file" accept="image/*" capture="environment">
    </div>
    <div class="close-button-container">
      <button class="email-button" type="button" mat-flat-button color="primary" (click)="closePhotos()">Close</button>
    </div>
  </div>
</div>
<div class="photo-spinner-container" *ngIf="showSpinner">
  <app-spinner class="app-photo-spinner" [showSpinner]="showSpinner" [spinnerId]="'photoViewerSpinner'"></app-spinner>
</div>
