<div class="delivery-docket-order-item-list-container" id="orderItemContainer">

  <app-app-user-column-set-bar [componentColumns]="displayedDeliveryDocketOrderItems" [pageName]="columnBarPage" [container]="columnBarContainer" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)"></app-app-user-column-set-bar>

  <table mat-table #tblDeliveryDocketOrderItems id="tblDeliveryDocketOrderItems{{appIndex}}" [dataSource]="datasource" class="mat-elevation-z8 delivery-docket-order-items-table" matSort *ngIf="datasource != null">

    <ng-container matColumnDef="intItemNo">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalItemNumber"> {{deliveryDocketOrderItem.intItemNo}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD footerTD">  </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerName">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Customer </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD customerName"> {{deliveryDocketOrderItem.txtCustomerName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtOrderNo">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Order No </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD orderNo"> {{deliveryDocketOrderItem.txtOrderNo}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtProductTypeName">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Product Name </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD productName"> {{deliveryDocketOrderItem.txtProductTypeName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtProfileName">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" mat-sort-header> Profile </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD profileName"> {{deliveryDocketOrderItem.txtProfileName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltWidth">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" mat-sort-header> Width </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD width"> {{deliveryDocketOrderItem.fltWidth}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltThickness">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" mat-sort-header> Thickness </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD thickness"> {{deliveryDocketOrderItem.fltThickness}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtLengthKN">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" mat-sort-header> Length / KN </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD lengthKN"> {{deliveryDocketOrderItem.intPoleLength}} {{deliveryDocketOrderItem.intPoleLength != null ? '/' : '' }} {{deliveryDocketOrderItem.intPoleKN}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltLength">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" mat-sort-header> Length </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD length"> {{deliveryDocketOrderItem.fltLength}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltDiameter">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" mat-sort-header> Diameter </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD diameter"> {{deliveryDocketOrderItem.fltDiameter == 0 ? '' : deliveryDocketOrderItem.fltDiameter}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtSpecies">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Species </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD species"> {{deliveryDocketOrderItem.txtSpecies}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtGradeName">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Grade </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD grade"> {{deliveryDocketOrderItem.txtGradeName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtTreatmentType">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Treatment </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD treatment"> {{deliveryDocketOrderItem.txtTreatmentType}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtTreatmentHazardLevel">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Hazard Level </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD hazardLevel"> {{deliveryDocketOrderItem.txtTreatmentHazardLevel}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtStockCode">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Stock Code </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD stockCode"> {{deliveryDocketOrderItem.txtStockCode}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltQtyBal">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Quantity Bal </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalQuantityOnOrder alignRightTD"> {{deliveryDocketOrderItem.fltQtyBal}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRight footerTD"> </td>
    </ng-container>

    <ng-container matColumnDef="intFinalQuantity">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Final Quantity </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalFinalQuantity alignRightTD"> {{deliveryDocketOrderItem.intFinalQuantity}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRight footerTD"> </td>
    </ng-container>

    <ng-container matColumnDef="fltQuantity">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Quantity </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem; let rowIndex = index" class="deliveryDocketOrderItemTD alignRightTD quantity">
        <div class="quantity-cell-grid">
          <div class="quantity-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 100px !important;height:50px; padding-right: 0px !important; display: inline-block;" appearance="fill">
              <input [ngClass]="deliveryDocketOrderItem.fltQtyBal < 0 ? 'textbox-input-white' : 'textbox-input inline-textbox'" matInput type="number" value="{{deliveryDocketOrderItem.fltQuantity}}" id="txtQuantity{{rowIndex}}" #txtQuantity [disabled]="true" (keyup)="validateQuantity(txtQuantity.value, deliveryDocketOrderItem)">
            </mat-form-field>
          </div>
          <div class="quantity-column-2" style="padding-left: 10px; border: 0px solid green; visibility: hidden; width: 0px; vertical-align: bottom; " id="spanSpinnerContainer{{rowIndex}}" [@displaySpinner]="showSpinner ? 'open' : 'closed'">
            <mat-progress-spinner class="example-margin products-spinner inline-spinner" color="primary" mode="indeterminate" [diameter]="30" value="50"></mat-progress-spinner>
          </div>
          <div class="quantity-column-3" style="padding-left: 10px; border: 0px solid green; visibility: hidden; width: 0px; vertical-align:bottom;" id="iconTickContainer{{rowIndex}}" [@displayTick]="showTick ? 'open' : 'closed'">
            <mat-icon class="done-icon">done</mat-icon>
          </div>
          <div class="quantity-column-4" style="padding-left: 10px; border: 0px solid green; visibility: hidden; width: 0px; vertical-align:bottom;" id="iconCancelContainer{{rowIndex}}" [@displayCancel]="showCancel ? 'open' : 'closed'">
            <mat-icon class="cancel-icon" matTooltip="Cancel update" (click)="cancelQuantity(deliveryDocketOrderItem, rowIndex)">cancel</mat-icon>
          </div>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD footerQuantity"> <div class="quantityFooterTD">{{deliveryDocketOrderItemQtyTotalInput}}</div></td>
    </ng-container>

    <ng-container matColumnDef="txtQuantityUOM">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Qty UOM </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD quantityUOM"> {{deliveryDocketOrderItem.txtOrderItemQuantityUom}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtAddress">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH addressTH" mat-sort-header> Address </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD address"> {{(deliveryDocketOrderItem.txtAlternateAddress != null && deliveryDocketOrderItem.txtAlternateAddress != '') ? deliveryDocketOrderItem.txtAlternateAddress + ' ' + deliveryDocketOrderItem.txtAlternateTown : deliveryDocketOrderItem.txtAddress + ' ' + deliveryDocketOrderItem.txtTown}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtComments">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Comments </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD comments" [innerHTML]="deliveryDocketOrderItem.txtComments | transformText"> </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="fltM3">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" align="right" arrowPosition="before" mat-sort-header> M3 </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD M3 alignRightTD"> {{deliveryDocketOrderItem.fltM3}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD footerM3"> {{deliveryDocketOrderItemM3TotalInput}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualM3">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Actual M3 </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD actualM3 alignRightTD"> {{RoundNumber(deliveryDocketOrderItem.fltActualM3,3)}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD footerActualM3"> {{deliveryDocketOrderItemActualM3TotalInput}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualTonne">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Actual Tonne </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD actualTonne alignRightTD"> {{RoundNumber(deliveryDocketOrderItem.fltActualTonne,3)}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD footerActualTonne"> {{deliveryDocketOrderItemActualTonneTotalInput}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditQuantity">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH editQuantityTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem; let rowIndex = index" class="deliveryDocketOrderItemTD editQuantity">
        <div matTooltip="To change the quantity please either add final items or remove final items." [matTooltipDisabled]="!(deliveryDocketOrderItem.fltFinalM3 != null && deliveryDocketOrderItem.fltFinalM3 > 0)">
          <button class="edit-delivery-docket-order-item-button" type="button" mat-flat-button color="primary" id="btnEditQuantity{{rowIndex}}" #btnEditQuantity (click)="editQuantity(deliveryDocketOrderItem, rowIndex, btnEditQuantity)" [disabled]="deliveryDocketOrderItem.fltFinalM3 != null && deliveryDocketOrderItem.fltFinalM3 > 0">Edit Quantity</button>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="btnEditDeliveryDocketOrderItem">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH deliveryDocketOrderItemEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD deliveryDocketOrderItemEdit">
        <button class="edit-delivery-docket-order-item-button" type="button" mat-flat-button color="primary" (click)="editDeliveryDocketOrderItem(deliveryDocketOrderItem)">Edit</button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveDeliveryDocketOrderItem">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH deliveryDocketOrderItemEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD deliveryDocketOrderItemRemove">
        <button class="remove-delivery-docket-order-item-button" type="button" mat-flat-button color="primary" (click)="removeDeliveryDocketOrderItem(deliveryDocketOrderItem)">Remove</button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedDeliveryDocketOrderItems"></tr>
    <tr style="height:50px;" class="chh-mat-row" mat-row *matRowDef="let row; columns: displayedDeliveryDocketOrderItems;let deliveryDocketOrderItem" [ngClass]="deliveryDocketOrderItem.fltQtyBal < 0 ? 'delivery-docket-order-item-row-red' : ''">
    </tr>
    <tr class="chh-mat-footer-row" mat-footer-row *matFooterRowDef="displayedDeliveryDocketOrderItems"></tr>
  </table>
  <div class="no-delivery-docket-orderitems-items" *ngIf="datasource == null"><h7>There are no delivery docket order items</h7></div>
</div>

<div class="spinner-container" id="divSpinner" #spinner *ngIf="showSpinner" style="top:50px;left:200px;">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" [diameter]="30" value="50"></mat-progress-spinner>
  </div>
</div>
