import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoProductCode } from '../business-objects/dto-product-code.bo';

@Injectable()
export class dtoProductCodeService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoProductCode(dtoProductCodeId: string) {
		return this.http.get<dtoProductCode>(this._baseUrl + 'api/dtoProductCode/' + dtoProductCodeId);
	}

	getdtoProductCodeParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoProductCode[]>(this._baseUrl + 'api/dtoProductCode/GetdtoProductCodesParamArray/', aParam);
	}

	getAlldtoProductCodes() {
		return this.http.get<dtoProductCode[]>(this._baseUrl + 'api/dtoProductCode/GetAlldtoProductCodes/');
	}

	//getAlldtodtoProductCodes() {
	//	return this.http.get<dtodtoProductCode[]>(this._baseUrl + 'api/dtoProductCode/GetAlldtoProductCodes/');
	//}

	createdtoProductCode(dtoProductCode: dtoProductCode) {
		return this.http.post<dtoProductCode>(this._baseUrl + 'api/dtoProductCode/CreatedtoProductCode/', dtoProductCode);
	}

	updatedtoProductCode(dtoProductCode: dtoProductCode) {
		return this.http.put<dtoProductCode>(this._baseUrl + 'api/dtoProductCode/UpdatedtoProductCode/', dtoProductCode);
	}

	deletedtoProductCode(dtoProductCodeId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoProductCode/DeletedtoProductCode/' + dtoProductCodeId);
	}

	async getdtoProductCodePromise(dtoProductCodeId: string) {
		return new Promise<dtoProductCode>(resolve => {
			this.getdtoProductCode(dtoProductCodeId).subscribe(dtoProductCode => {
				resolve(dtoProductCode);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoProductCode records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoProductCodeParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoProductCode[]>(resolve => {
			this.getdtoProductCodeParamArray(aParam).subscribe(dtoProductCodes => {
				resolve(dtoProductCodes);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoProductCode records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoProductCodePromise(dtoProductCode: dtoProductCode) {
		return new Promise<dtoProductCode>(resolve => {
			this.updatedtoProductCode(dtoProductCode).subscribe(dtoProductCode => {
				resolve(dtoProductCode);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoProductCode record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoProductCodePromise(dtoProductCode: dtoProductCode) {
		return new Promise<dtoProductCode>(resolve => {
			this.createdtoProductCode(dtoProductCode).subscribe(dtoProductCode => {
				resolve(dtoProductCode);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoProductCode record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoProductCodePromise(dtoProductCodeId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoProductCode(dtoProductCodeId).subscribe(dtoProductCode => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoProductCode record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}