import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from '../account/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  athService: AuthService;

  constructor(authService: AuthService, private route: ActivatedRoute) {
    this.athService = authService;
  }


  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {


    let idToken = localStorage.getItem("txtToken");
    const rkToken = this.athService.getReckonAccessToken();

    //console.log("hasTotkenExpired: " + this.athService.isTotkenValid())

    //console.log("req.url: " + req.url)

    if (idToken == null || idToken == "") {
      //Lets try and retreive from querystring.

      this.route.queryParams.subscribe(async params => {

        //console.log("search: ", params);
        if (params != null) {
          let txtToken: string = params["txtToken"];

          if (txtToken != null) {
            //console.log("txtToken", txtToken);
            idToken = txtToken;

          }
        }


      });
    }

    if (!this.athService.isTokenValid(false)) {
      this.athService.setLogoutSession();

      //this.athService.logout();
    }

    if (req.url.indexOf("https://api.reckon.com") > -1) {
      //We are calling reckon API so use reckon Bearer token.

      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + rkToken).set("Content-Type", "application/json").set("Ocp-Apim-Subscription-Key", this.athService.subKey)
      });

      //console.log("Reckon Token request: ", cloned);
      return next.handle(cloned);

    }

    //console.log("req - top: ", req);

    if (idToken) {
 

      if (req.url.indexOf("login.microsoftonline.com") == -1 && req.url.indexOf("mtech.utilities.coffshardwoods.com.au") == -1 && req.url.indexOf("https://identity.reckon.com") == -1 && req.url.indexOf("https://api.reckon.com") == -1) {
        //console.log("req.url", req.url);
        const cloned = req.clone({
          headers: req.headers.set("Authorization",
            "Bearer " + idToken)
        });

        //console.log("cloned: ", cloned);

        return next.handle(cloned);
      }

      //if (req.url.indexOf("mtech.utilities.coffshardwoods.com.au") > -1) {
      //  req.headers.set("Access-Control-Allow-Origin", "*");
      //  req.headers.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
      //  console.log(req.headers);
      //}
      //console.log("going to mtech.utilities.");

      //console.log("req got token: ", req);


      return next.handle(req);

    }
    else {
      //console.log("req: ", req);
      return next.handle(req);
    }
  }
}
