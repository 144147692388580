import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeliveryDocketListComponent } from '../../delivery-docket/delivery-docket-list/delivery-docket-list.component';
import { LoadingDocketListComponent } from '../../loading-docket/loading-docket-list/loading-docket-list.component';
import { ClientDeliveryAddress } from '../../_shared/business-objects/client-delivery-address.bo';
import { dtoCustomerDeliveryAddress } from '../../_shared/business-objects/dto-customer-delivery-address.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoCustomerDeliveryAddressService } from '../../_shared/services/dto-customer-delivery-address.service';

@Component({
  selector: 'app-customer-delivery-address-dialog',
  templateUrl: './customer-delivery-address-dialog.component.html',
  styleUrl: './customer-delivery-address-dialog.component.css',
  animations: [
    trigger('displayCreateForm', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('700ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]), // End Trigger
    trigger('displayUpdateForm', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('700ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]), // End Trigger
    trigger(
      'inOutAnimation',
      [state('createform', style({
        width: '35vw'
      })),
        state('selectform', style({
        width: '62vw'
        })),
        state('updateform', style({
          width: '36vw'
        })),
        transition('* => createform', [
        animate('500ms')
      ]),
        transition('* => selectform', [
        animate('500ms')
        ]),
        transition('* => updateform', [
          animate('500ms')
        ]),
      ]
    )
  ]
})
export class CustomerDeliveryAddressDialogComponent {

  customerId: string;
  customerName: string;
  selectedCustomerDeliveryAddress: dtoCustomerDeliveryAddress;

  showCreateDeliveryAddress: boolean = false;
  showUpdateDeliveryAddress: boolean = false;

  hide: boolean = false;

  customerDeliveryAddress: dtoCustomerDeliveryAddress;

  headingName: string = "Customer Delivery Address Selection";

  constructor(private loadingDocketListRef: MatDialogRef<LoadingDocketListComponent>, private deliveryDocketListRef: MatDialogRef<DeliveryDocketListComponent>, @Inject(MAT_DIALOG_DATA) private data: { customerId: string, customerName: string, selectedCustomerDeliveryAddress: dtoCustomerDeliveryAddress }
              , private dtoCustomerDeliveryAddressService: dtoCustomerDeliveryAddressService, private alertService: AlertService) {

    this.customerId = data.customerId;
    this.customerName = data.customerName;
    this.selectedCustomerDeliveryAddress = data.selectedCustomerDeliveryAddress;

    console.log("customerId: ", data.customerId);
    console.log("customerName: ", data.customerName);
    console.log("selectedCustomerDeliveryAddress: ", data.selectedCustomerDeliveryAddress);

  }

  selectCustomerDeliveryAddress(customerDeliveryAddress: dtoCustomerDeliveryAddress) {

    if (this.loadingDocketListRef != null) {
      this.loadingDocketListRef.close({ status: "Saved", customerDeliveryAddress: customerDeliveryAddress });
    }

    if (this.deliveryDocketListRef != null) {
      this.deliveryDocketListRef.close({ status: "Saved", customerDeliveryAddress: customerDeliveryAddress });
    }

  }


  customerDeliveryAddressCancelled(cancelled: boolean) {

    if (this.loadingDocketListRef != null) {
      this.loadingDocketListRef.close({ status: "Cancel", customerContact: null });
    }

    if (this.deliveryDocketListRef != null) {
      this.deliveryDocketListRef.close({ status: "Cancel", customerContact: null });
    }

  }

  addCustomerDeliveryAddress() {
    console.log("in addCustomerDeliveryAddress");
    this.showCreateDeliveryAddress = true;
  }

  updateCustomerDeliveryAddress(customerDeliveryAddress: dtoCustomerDeliveryAddress) {

    this.showUpdateDeliveryAddress = true;

    this.customerDeliveryAddress = customerDeliveryAddress;

  }

  async customerDeliveryAddressCreated(customerDeliveryAddress: ClientDeliveryAddress) {
    let dtoDelAddress: dtoCustomerDeliveryAddress = await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressPromise(customerDeliveryAddress.rowguid);

    if (dtoDelAddress == null) {
      this.alertService.openSnackBarDefault("The dtoCustomerDeliveryAddress cannot be found");
      return;
    }

    this.selectedCustomerDeliveryAddress = dtoDelAddress;

    this.showCreateDeliveryAddress = false;

  }

  customerDeliveryAddressCreateCancelled(val: boolean) {
    this.showCreateDeliveryAddress = false;

  }

  async customerDeliveryAddressUpdated(customerDeliveryAddress: ClientDeliveryAddress) {
    let dtoDelAddress: dtoCustomerDeliveryAddress = await this.dtoCustomerDeliveryAddressService.getdtoCustomerDeliveryAddressPromise(customerDeliveryAddress.rowguid);

    if (dtoDelAddress == null) {
      this.alertService.openSnackBarDefault("The dtoCustomerDeliveryAddress cannot be found");
      return;
    }

    this.selectedCustomerDeliveryAddress = dtoDelAddress;

    this.showUpdateDeliveryAddress = false;

  }

  customerDeliveryAddressUpdateCancelled(val: boolean) {
    this.showUpdateDeliveryAddress = false;

  }

}
