export interface GeoStockLocation
{
	blnActive: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guCustomerDeliveryAddressId: string;
	guCustomerId: string;
	rowguid: string;
	txtLocationName: string;
	txtShortName: string;
}

export class GeoStockLocation
{
	blnActive: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guCustomerDeliveryAddressId: string;
	guCustomerId: string;
	rowguid: string;
	txtLocationName: string;
	txtShortName: string;
}

