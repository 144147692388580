import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { DeliveryDocketAddItemsComponent } from '../delivery-docket-add-items/delivery-docket-add-items.component';

@Component({
  selector: 'app-delivery-docket-create-dialog',
  templateUrl: './delivery-docket-create-dialog.component.html',
  styleUrls: ['./delivery-docket-create-dialog.component.css']
})
export class DeliveryDocketCreateDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DeliveryDocketAddItemsComponent>) {  }

  ngOnInit(): void {
  }

  deliveryDocketCreated(deliveryDocketCreatedObj: [DeliveryDocket, string]) {
    
    this.dialogRef.close({ status: "Saved", deliveryDocket: deliveryDocketCreatedObj[0] });


  }

  deliveryDocketCancelled(cancelled: boolean) {

    this.dialogRef.close({ status: "Cancel", finalInspectionItem: null });


  }

}
