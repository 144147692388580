

<form class="pole-trimming-form" [formGroup]="form">

  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="radMode" color="accent">
    <mat-button-toggle value="enter" (click)="onModeSelect()">Enter Mode</mat-button-toggle>
    <mat-button-toggle value="search" (click)="onModeSelect()" [checked]="true">Search Mode</mat-button-toggle>
  </mat-button-toggle-group>
  <br />
  <br />


  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Pole Number</mat-label>
    <input matInput placeholder="" value="" formControlName="txtPoleNumber" (keyup.enter)="addTrimmingItem()">
  </mat-form-field>&nbsp;

  <mat-form-field appearance="fill">
    <mat-label>Trimming Date From</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="dteDateCreatedFrom">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>&nbsp;

  <mat-form-field appearance="fill">
    <mat-label>Trimming Date To</mat-label>
    <input matInput [matDatepicker]="picker2" formControlName="dteDateCreatedTo">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>

  <div *ngIf="showErrorMessage" class="mat-error" [innerHTML]="errorMessage.message | safeHtml"></div>


  <br />

</form>

<button class="stocktakeButton" mat-flat-button color="primary" (click)="search()">Search Trimming Items</button>&nbsp;&nbsp;
<button class="stocktakeButton" mat-flat-button color="primary" (click)="getTodaysList()">List Todays Items</button>&nbsp;&nbsp;
<button class="stocktakeButton" mat-flat-button color="primary" (click)="getLast3Days()">List 3 Days</button>&nbsp;&nbsp;
<button class="stocktakeButton" mat-flat-button color="primary" [routerLink]="['/pole-trimming-item-add']">Add Trimming Item</button>


<mat-card class="stocktakeTotalsCard">
  Total Trimming Items: {{poleTrimmingItems != null ? poleTrimmingItems.length : ""}}
</mat-card>

<table mat-table [dataSource]="poleTrimmingItems" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
  <ng-container matColumnDef="dteDateCreated">
    <th mat-header-cell *matHeaderCellDef class="date-created cell-align-right"> Date Created </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="date-created cell-align-right"> {{PoleTrimmingItem.dteDateCreated | date:'dd/MM/yy'}} </td>
  </ng-container>

  <ng-container matColumnDef="dteTimeCreated">
    <th mat-header-cell *matHeaderCellDef class="date-created cell-align-right"> Time </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="date-created cell-align-right"> {{PoleTrimmingItem.dteDateCreated | date:'h:mm a'}} </td>
  </ng-container>

  <ng-container matColumnDef="txtPoleNumber">
    <th mat-header-cell *matHeaderCellDef class="pole-number-header-cell cell-align-right"> Pole Number </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="pole-number-cell cell-align-right"> {{PoleTrimmingItem.txtPoleNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="txtOriginalProductType">
    <th mat-header-cell *matHeaderCellDef class="product"> Product </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem"> {{PoleTrimmingItem.txtOriginalProductType}} </td>
  </ng-container>

  <ng-container matColumnDef="fltOriginalLength">
    <th mat-header-cell *matHeaderCellDef class="original-length"> Orig. Len </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="original-length"> {{PoleTrimmingItem.fltOriginalLength}} </td>
  </ng-container>

  <ng-container matColumnDef="origspace">
    <th mat-header-cell *matHeaderCellDef>  </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem"> {{getKNDivider(PoleTrimmingItem.guOriginalPoleId)}} </td>
  </ng-container>

  <ng-container matColumnDef="intOriginalKN">
    <th mat-header-cell *matHeaderCellDef class="original-kn"> Orig. KN </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="original-kn"> {{PoleTrimmingItem.intOriginalKN == 0 ? "" : PoleTrimmingItem.intOriginalKN}} </td>
  </ng-container>

  <ng-container matColumnDef="txtOriginalSpec">
    <th mat-header-cell *matHeaderCellDef class="original-spec"> Orig. Spec </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="original-spec"> {{PoleTrimmingItem.txtOriginalSpec}} </td>
  </ng-container>


  <ng-container matColumnDef="fltOriginalDiameter">
    <th mat-header-cell *matHeaderCellDef class="diameter"> Orig. Dia </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="diameter"> {{PoleTrimmingItem.fltOriginalDiameter}} </td>
  </ng-container>

  <ng-container matColumnDef="txtOriginalDiameterType">
    <th mat-header-cell *matHeaderCellDef class="diameter-type"> Orig. Dia Type </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="diameter-type"> {{PoleTrimmingItem.txtOriginalDiameterType}} </td>
  </ng-container>

  <ng-container matColumnDef="txtFinishedProductType">
    <th mat-header-cell *matHeaderCellDef class="product"> Product </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem"> {{PoleTrimmingItem.txtFinishedProductType}} </td>
  </ng-container>

  <ng-container matColumnDef="fltFinishedLength">
    <th mat-header-cell *matHeaderCellDef class="finished-length"> Fin. Len </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="finished-length"> {{PoleTrimmingItem.fltFinishedLength}} </td>
  </ng-container>

  <ng-container matColumnDef="finspace">
    <th mat-header-cell *matHeaderCellDef>  </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem"> {{getKNDivider(PoleTrimmingItem.guFinishedPoleId)}} </td>
  </ng-container>

  <ng-container matColumnDef="intFinishedKN">
    <th mat-header-cell *matHeaderCellDef class="finished-kn"> Fin. KN </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="finished-kn"> {{PoleTrimmingItem.intFinishedKN == 0 ? "" : PoleTrimmingItem.intFinishedKN}} </td>
  </ng-container>

  <ng-container matColumnDef="txtFinishedSpec">
    <th mat-header-cell *matHeaderCellDef class="finished-spec"> Fin. Spec </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="finished-spec"> {{PoleTrimmingItem.txtFinishedSpec}} </td>
  </ng-container>

  <ng-container matColumnDef="fltFinishedDiameter">
    <th mat-header-cell *matHeaderCellDef class="diameter"> Fin. Dia </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="diameter"> {{PoleTrimmingItem.fltFinishedDiameter}} </td>
  </ng-container>

  <ng-container matColumnDef="txtFinishedDiameterType">
    <th mat-header-cell *matHeaderCellDef class="diameter-type"> Fin. Dia Type </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="diameter-type"> {{PoleTrimmingItem.txtFinishedDiameterType}} </td>
  </ng-container>

  <ng-container matColumnDef="txtSpecies">
    <th mat-header-cell *matHeaderCellDef class="reason-for-change"> Species </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem" class="reason-for-change"> {{PoleTrimmingItem.txtSpecies}} </td>
  </ng-container>

  <ng-container matColumnDef="btnShowDetail">
    <th mat-header-cell *matHeaderCellDef class="change-pole-button"> Change Pole </th>
    <td mat-cell *matCellDef="let PoleTrimmingItem">
      <button class="change-pole-button" mat-flat-button color="primary" (click)="showTrimmingItemDetail(PoleTrimmingItem.rowguid)">Change Pole</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
