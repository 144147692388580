<div class="create-appuser-geostocklocation-container">
  <div class="header-container">
    <div class="header-row-1">
      <h1>App User Geo Stock Locations</h1>
      <br />
    </div>
  </div>
  <div class="main-container">
    <div class="main-row-1">
      <table mat-table #tblAppUserGeoStockLocation [dataSource]="dtoAppUserGeoStockLocations" class="mat-elevation-z8">
        <ng-container matColumnDef="txtFullName">
          <th mat-header-cell *matHeaderCellDef class="header-cell-TH"> User Name </th>
          <td mat-cell *matCellDef="let dtoAppUserGeoStockLocation" class="cell-TD"> {{dtoAppUserGeoStockLocation.txtFullName}} </td>
        </ng-container>

        <ng-container matColumnDef="txtLocationName">
          <th mat-header-cell *matHeaderCellDef class="header-cell-TH"> Location </th>
          <td mat-cell *matCellDef="let dtoAppUserGeoStockLocation" class="cell-TD"> {{dtoAppUserGeoStockLocation.txtLocationName}} </td>
        </ng-container>

        <ng-container matColumnDef="blnDefaultLocation">
          <th mat-header-cell *matHeaderCellDef class="header-cell-TH"> Default Location </th>
          <td mat-cell *matCellDef="let dtoAppUserGeoStockLocation" class="cell-TD"> {{dtoAppUserGeoStockLocation.blnDefaultLocation ? 'Yes' : 'No'}} </td>
        </ng-container>

        <ng-container matColumnDef="btnEdit">
          <th mat-header-cell *matHeaderCellDef class="header-cell-TH"> </th>
          <td mat-cell *matCellDef="let dtoAppUserGeoStockLocation; let rowIdx = index;" class="cell-TD"> <button id="btnEdit{{rowIdx}}" class="stocktakeButton" mat-flat-button color="primary" (click)="editAppUserLocation()">Edit</button> </td>
        </ng-container>

        <ng-container matColumnDef="btnRemove">
          <th mat-header-cell *matHeaderCellDef class="header-cell-TH"> </th>
          <td mat-cell *matCellDef="let dtoAppUserGeoStockLocation; let rowIdx = index;" class="cell-TD"> <button id="btnRemove{{rowIdx}}" class="stocktakeButton" mat-flat-button color="primary" (click)="removeAppUserLocation(dtoAppUserGeoStockLocation.rowguid)">Remove</button> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="appUserGeoStockLocationsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: appUserGeoStockLocationsColumns;"></tr>
      </table>
    </div>
  </div>

  <div class="footer-container">
    <br />
    <button class="stocktakeButton" mat-flat-button color="primary" [routerLink]="['/appuser-geostocklocation-create']">Add App User Location</button>&nbsp;
  </div>

</div>


