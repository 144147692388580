import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CodeBuilderColumn } from '../business-objects/code-builder-column.bo';


@Injectable()
export class IOService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  writeFile(content) {
    console.log(JSON.stringify(content));

    return this.http.post(this._baseUrl + 'api/writefile/', content);
  }

  downloadFile(fileName) {

    return this.http.get(this._baseUrl + 'api/writefile/downloadfile/' + fileName, { responseType: 'blob' });
    //return this.http.get(this._baseUrl + 'api/writefile/downloadfile/powersupply.jpg', { responseType: 'blob' });
    //return this.http.get(this._baseUrl + 'api/writefile/getfile/', { responseType: 'blob' });
  }


  createClasses(content) {
    return this.http.post(this._baseUrl + 'api/writefile/createclasses/', content);
  }

  createDTOClasses(content) {
    return this.http.post(this._baseUrl + 'api/writefile/CreateViewClasses/', content);
  }

  createDTOTSClasses(content) {
    return this.http.post(this._baseUrl + 'api/writefile/CreateViewTypeScriptClasses/', content);
  }


  createDTORepoClasses(content) {
    return this.http.post(this._baseUrl + 'api/writefile/CreateViewRepositoryClasses/', content);
  }


  createDTOServiceClasses(content) {
    return this.http.post(this._baseUrl + 'api/writefile/CreateViewServiceClasses/', content);
  }

  createTypeScriptServices(content) {
    return this.http.post(this._baseUrl + 'api/writefile/CreateTypeScriptServices/', content);
  }

  createWebAPIControllers(content) {
    return this.http.post(this._baseUrl + 'api/writefile/CreateWebApiControllers/', content);
  }

  getWebAPIController(tableName: string, saveFile: boolean) {
    return this.http.get(this._baseUrl + 'api/writefile/GetController/' + tableName + '?saveFile=' + saveFile, {responseType: 'text'});
  }

  getSearchComponentHTML(tableName: string, parentPath: string, saveFile: boolean) {
    let query = "";
    if (parentPath != "") {
      query = "&parentPath=" + parentPath;
    }

    query += "&saveFile=" + saveFile;

    return this.http.get(this._baseUrl + 'api/writefile/GetSearchComponentHTML/?tableName=' + tableName + query, { responseType: 'text' });
  }

  getWebAPIControllerWithColumns(tableName: string, columns: CodeBuilderColumn[], saveFile: boolean) {
    return this.http.post(this._baseUrl + 'api/writefile/GetControllerWithColumns/' + tableName + '?saveFile=' + saveFile, columns, { responseType: 'text' });
  }

  getCSServiceClass(tableName: string, columns: CodeBuilderColumn[], saveFile: boolean) {
    return this.http.post(this._baseUrl + 'api/writefile/GetCSServiceClass/' + tableName + '?saveFile=' + saveFile, columns, { responseType: 'text' });
  }

  getCSServiceInterface(tableName: string, columns: CodeBuilderColumn[], saveFile: boolean) {
    return this.http.post(this._baseUrl + 'api/writefile/GetCSServiceInterface/' + tableName + '?saveFile=' + saveFile, columns, { responseType: 'text' });
  }

  getCSRepositoryClass(tableName: string, columns: CodeBuilderColumn[], saveFile: boolean) {
    return this.http.post(this._baseUrl + 'api/writefile/GetCSRepositoryClass/' + tableName + '?saveFile=' + saveFile, columns, { responseType: 'text' });
  }

  getCSRepositoryInterface(tableName: string, columns: CodeBuilderColumn[], saveFile: boolean) {
    return this.http.post(this._baseUrl + 'api/writefile/GetCSRepositoryInterface/' + tableName + '?saveFile=' + saveFile, columns, { responseType: 'text' });
  }

  getTypeScriptService(tableName: string, columns: CodeBuilderColumn[], saveFile: boolean) {
    return this.http.post(this._baseUrl + 'api/writefile/GetTypeScritpService/' + tableName + '?saveFile=' + saveFile, columns, { responseType: 'text' });
  }

  getNullableParameter() {
    return this.http.get(this._baseUrl + 'api/writefile/TestNullableField/test/?hellodate=2022-07-05', { responseType: 'text' });
  }

}  
