<div class="product-tile-container" *ngIf="webPublishStockSummaryItem != null" (click)="viewDetail(webPublishStockSummaryItem)">
  <div class="product-tile-grid">
    <div class="photo-container">
      <div class="photo-thumb-nail-image">
        <mat-icon matTooltip="" class="product-image material-symbols-outlined" *ngIf="dataURL == null">image</mat-icon>
        <img *ngIf="dataURL != null" [src]="dataURL" width="200" alt="Image" class="thumbnail-image" />
      </div>
    </div>

    <div class="description-container">
      <div class="product-tile-description product-name">{{webPublishStockSummaryItem.txtProductName}}</div>
      <div class="product-tile-description profile-name">{{webPublishStockSummaryItem.txtProfileName}}</div>
      <div class="product-tile-description width">{{webPublishStockSummaryItem.intWidth}}</div>
      <div class="product-tile-description width-thickness-divider" *ngIf="webPublishStockSummaryItem.intWidth != null && webPublishStockSummaryItem.intThickness != null">x</div>
      <div class="product-tile-description thickness">{{webPublishStockSummaryItem.intThickness}}mm</div>
      <div class="product-tile-description species" [innerHTML]="webPublishStockSummaryItem.txtSpeciesName | camelCase"></div>
      <div class="product-tile-description grade" [innerHTML]="webPublishStockSummaryItem.txtGradeName.toLowerCase() == 'none' ? '' : (webPublishStockSummaryItem.txtGradeName + ' Grade' | camelCase)"></div>
    </div>
    <div class="more-options">more options</div>
    <div class="price-qty-container-grid">
      <div class="pricing-container">
        <div class="price-dollars">
          {{formatDollars(webPublishStockSummaryItem.fltApprovedPrice)}}
        </div>
        <div class="price-cents">
          {{formatCents(webPublishStockSummaryItem.fltApprovedPrice)}}
        </div>
        <div class="price-type">
          {{formatPriceType(webPublishStockSummaryItem.txtApprovedPriceText, webPublishStockSummaryItem.fltApprovedPrice)}}

        </div>
      </div>
      <div class="quantity" *ngIf="false">{{webPublishStockSummaryItem.fltQuantity + (webPublishStockSummaryItem.txtQuantityUoM != null && webPublishStockSummaryItem.txtQuantityUoM.trim() == 'Each' ? ' @ ' + webPublishStockSummaryItem.intTotalLM : '')}}</div>
      <div class="quantity-uom" *ngIf="false">{{webPublishStockSummaryItem.txtQuantityUoM != null && webPublishStockSummaryItem.txtQuantityUoM.trim() != 'Each' ? webPublishStockSummaryItem.txtQuantityUoM : ''}}</div>
    </div>
  </div>
</div>
