import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { SharedService } from '../../_shared/services/shared.service';
import { AppPageService } from '../../_shared/services/app-page.service';
import { AppService } from '../../_shared/services/app-services/app.service';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AppPageColumn } from '../../_shared/business-objects/app-page-column.bo';
import { AppPageColumnService } from '../../_shared/services/app-page-column.service';
import { dtoAppPageColumn } from '../../_shared/business-objects/dto-app-page-column.bo';

@Component({
  selector: 'app-app-page-column-update',
  templateUrl: './app-page-column-update.component.html',
  styleUrls: ['./app-page-column-update.component.css']
})
export class AppPageColumnUpdateComponent implements OnInit {

  appPages: AppPage[];

  form: FormGroup;

  @Input() appPageColumn: dtoAppPageColumn;
  @Output() appPageColumnUpdated = new EventEmitter<AppPageColumn>();
  @Output() appPageColumnCancelled = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private appService: AppService, private sharedService: SharedService, private alertService: AlertService
    , private appPageService: AppPageService, private appPageColumnService: AppPageColumnService) {

    const datepipe: DatePipe = new DatePipe('en-AU');

    this.form = this.fb.group({
      dteDateCreated: [''],
      guAppPageId: [''],
      txtColumnName: [''],
      txtColumnNameAlias: [''],
      intDefaultColumnOrder: ['']
    });


    this.form.controls['dteDateCreated'].enable();
    this.form.controls['dteDateCreated'].setValue(this.sharedService.currentDatePlusTZOffset());
    this.form.controls['dteDateCreated'].disable();


  }

  ngOnInit(): void {

    if (this.appPageColumn == null) {
      this.alertService.openSnackBar("There is no app page column to update", "Close", "center", "bottom", "", 3000);
      return;
    }

    this.loadData();

  }

  async loadData() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParamList.push(aParam);

    this.appPages = await this.appPageService.getAppPageParamArrayPromise(aParamList);

    this.form.controls['dteDateCreated'].setValue(this.appPageColumn.dteDateCreated);
    this.form.controls['guAppPageId'].setValue(this.appPageColumn.guAppPageId);
    this.form.controls['txtColumnName'].setValue(this.appPageColumn.txtColumnName);
    this.form.controls['txtColumnNameAlias'].setValue(this.appPageColumn.txtColumnNameAlias);
    this.form.controls['intDefaultColumnOrder'].setValue(this.appPageColumn.intDefaultColumnOrder);

  }


  async update() {

    let saveAppPageColumn: boolean = true;
    let dateCreated: Date = new Date(this.form.controls['dteDateCreated'].value.toString());
    let columnName: string = this.form.controls['txtColumnName'].value;
    let columnNameAlias: string = this.form.controls['txtColumnNameAlias'].value;
   let appPageId: string = this.form.controls['guAppPageId'].value;
    let defaultColumnOrder: number = this.form.controls['intDefaultColumnOrder'].value;
    const datepipe: DatePipe = new DatePipe('en-AU');

    if (dateCreated == null || dateCreated.toString() == "") {
      this.alertService.openSnackBar("Please select an created date.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (columnName == null || columnName.toString() == "") {
      this.alertService.openSnackBar("Please enter a column name.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (columnNameAlias == null || columnNameAlias.toString() == "") {
      this.alertService.openSnackBar("Please enter a column name alias.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (appPageId == null || appPageId.toString() == "") {
      this.alertService.openSnackBar("Please select an app page.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (defaultColumnOrder == null || defaultColumnOrder == 0) {
      this.alertService.openSnackBar("Please enter a default column order.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (saveAppPageColumn) {
      let appPageColumn: AppPageColumn;
      appPageColumn = await this.appPageColumnService.getAppPageColumnPromise(this.appPageColumn.rowguid);


      //delete appPageColumn.rowguid;  //remove rowguid property so DB will create one.

      appPageColumn.dteDateCreated = new Date(datepipe.transform(dateCreated, 'yyyy-MM-dd'));
      appPageColumn.txtColumnName = columnName;
      appPageColumn.txtColumnNameAlias = columnNameAlias;
      appPageColumn.guAppPageId = appPageId;
      appPageColumn.intDefaultColumnOrder = defaultColumnOrder;

      appPageColumn = await this.appPageColumnService.updateAppPageColumnPromise(appPageColumn);

      appPageColumn = await this.appPageColumnService.getAppPageColumnPromise(appPageColumn.rowguid);
      this.appPageColumnUpdated.emit(appPageColumn);

    }


  }

  cancel() {
    this.resetFields();
    this.appPageColumnCancelled.emit(true);
  }

  resetFields() {

    this.form.controls['dteDateCreated'].setValue(this.sharedService.currentDatePlusTZOffset.toString());
    this.form.controls['txtColumnName'].setValue('');
    this.form.controls['txtColumnNameAlias'].setValue('');
    this.form.controls['guAppPageId'].setValue('');
    this.form.controls['intDefaultColumnOrder'].setValue('');

  }
}
