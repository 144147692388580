import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { dtoDeliveryDocket } from '../../_shared/business-objects/dto-delivery-docket.bo';
import { dtoTPPoleInspectionItem } from '../../_shared/business-objects/dto-tppole-inspection-item.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { DeliveryDocketService } from '../../_shared/services/delivery-docket.service';
import { dtoDeliveryDocketService } from '../../_shared/services/dto-delivery-docket.service';
import { dtoDeliveryDocketOrderItemService } from '../../_shared/services/dto-delivery-docket-order-item.service';
import { DeliveryDocketCreateDialogComponent } from '../delivery-docket-create-dialog/delivery-docket-create-dialog.component';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { dtoDeliveryDocketOrderItem } from '../../_shared/business-objects/dto-delivery-docket-order-item.bo';
import { FormService } from '../../_shared/services/form.service';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { dtoAppUserColumn } from '../../_shared/business-objects/dto-app-user-column.bo';
import { AppUserColumnService } from '../../_shared/services/app-user-column.service';
import { TPPoleInspection } from '../../_shared/business-objects/tppole-inspection.bo';
import { TPPoleInspectionService } from '../../_shared/services/tppole-inspection.service';
import { dtoTPPoleInspectionItemService } from '../../_shared/services/dto-tppole-inspection-item.service';
import { DeliveryDocketFinalItemsAddComponent } from '../delivery-docket-final-items-add/delivery-docket-final-items-add.component';
import { DeliveryDocketFinalItemsPreviewComponent } from '../delivery-docket-final-items-preview/delivery-docket-final-items-preview.component';
import { DeliveryDocketOrderItemListComponent } from '../delivery-docket-order-item-list/delivery-docket-order-item-list.component';
import { MatTableDataSource } from '@angular/material/table';
import { OrderItemService } from '../../_shared/services/order-item.service';
import { dtoOrderItem } from '../../_shared/business-objects/dto-order-item.bo';
import { DeliveryDocketOrderItem } from '../../_shared/business-objects/delivery-docket-order-item.bo';
import { OrderItem } from '../../_shared/business-objects/order-item.bo';
import { SharedService } from '../../_shared/services/shared.service';
import { TPPoleInspectionItem } from '../../_shared/business-objects/tppole-inspection-item.bo';
import { TPPoleInspectionItemService } from '../../_shared/services/tppole-inspection-item.service';
import { DeliveryDocketOrderItemService } from '../../_shared/services/delivery-docket-order-item.service';
import { DeliveryDocketFinalItemsListComponent } from '../delivery-docket-final-items-list/delivery-docket-final-items-list.component';
import { DeliveryDocketUpdateDialogComponent } from '../delivery-docket-update-dialog/delivery-docket-update-dialog.component';
import { DeliveryDocketOrderItemsAddComponent } from '../delivery-docket-order-items-add/delivery-docket-order-items-add.component';
import { LoadingDocketDelivery } from '../../_shared/business-objects/loading-docket-delivery.bo';
import { LoadingDocketDeliveryService } from '../../_shared/services/loading-docket-delivery.service';
import { LoadingDocket } from '../../_shared/business-objects/loading-docket.bo';
import { LoadingDocketService } from '../../_shared/services/loading-docket.service';
import { DeliveryDocketOrderItemUpdateDialogComponent } from '../delivery-docket-order-item-update-dialog/delivery-docket-order-item-update-dialog.component';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { dtoOrderItemService } from '../../_shared/services/dto-order-item.service';

export enum enDeliveryDocketAddItemsTabs { OrderItems = 0, LinkedFinalItems = 1, LinkedStockItems = 2, AddFinalItems = 3, AddStockItems = 4, AddOrderItems = 5 }

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-delivery-docket-add-items',
  templateUrl: './delivery-docket-add-items.component.html',
  styleUrls: ['./delivery-docket-add-items.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class DeliveryDocketAddItemsComponent implements OnInit {

  form: FormGroup;

  @Input() deliveryDocket: dtoDeliveryDocket;
  @Output() deliveryDocketItemsAdded = new EventEmitter<true>();

  @ViewChild('appFinalInspectionList') finalItemListComponent: DeliveryDocketFinalItemsAddComponent;
  @ViewChild('appDeliveryDocketPreview') deliveryDocketPreviewComponent: DeliveryDocketFinalItemsPreviewComponent;
  @ViewChild('appDeliveryDocketOrderItemList') deliveryDocketOrderItemsComponent: DeliveryDocketOrderItemListComponent;
  @ViewChild('appLinkedInspecionList') finalItemsLinkedComponent: DeliveryDocketFinalItemsListComponent;
  @ViewChild('tabDeliveryDocketsAddItems', { static: false }) tabDeliveryDocketsAddItems: MatTabGroup;

  @ViewChild('appOrderItemsList') orderItemsComponent: DeliveryDocketOrderItemsAddComponent;
  //
  
  deliveryDocketCreateDialogRef: MatDialogRef<DeliveryDocketCreateDialogComponent>
  deliveryDocketUpdateDialogRef: MatDialogRef<DeliveryDocketUpdateDialogComponent>
  deliveryDocketOrderItemUpdateDialogRef: MatDialogRef<DeliveryDocketOrderItemUpdateDialogComponent>

  deliveryDocketOrderItems: MatTableDataSource<dtoDeliveryDocketOrderItem>;
  deliveryDocketOrderItemsList: MatTableDataSource<dtoDeliveryDocketOrderItem>;
  linkedFinalInspectionItems: MatTableDataSource<dtoTPPoleInspectionItem>;

  finalInspectionItems: dtoTPPoleInspectionItem[];
  orderItems: dtoOrderItem[];
  deliveryDocketOrderItemsToAdd: DeliveryDocketOrderItem[];

  showSpinner: boolean;
  selectedTab: number;

  deliveryDocketOrderItemFinalItemsToAddM3Total: number;
  deliveryDocketOrderItemFinalItemsToAddTonneTotal: number;

  deliveryDocketOrderItemsToAddM3Total: number;
  deliveryDocketOrderItemsToAddTonneTotal: number;

  viewType: number = 0;
  finalItemsView: number = 0;
  gotNonDelivered: boolean = false;
  gotOrderItemsNonDelivered: boolean = false;
  haveDeliveryDocket: boolean = false;

  public eDeliveryDocketAddItemsTabs = enDeliveryDocketAddItemsTabs;

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private dialog: MatDialog, private deliveryDocketService: DeliveryDocketService
    , private alertService: AlertService, private dtoDeliveryDocketService: dtoDeliveryDocketService, private dtoDeliveryDocketOrderItemService: dtoDeliveryDocketOrderItemService
    , private formService: FormService, private tpPoleInspectionService: TPPoleInspectionService, private dtoTPPoleInspectionItemService: dtoTPPoleInspectionItemService
    , private orderItemService: OrderItemService, private dtoOrderItemService: dtoOrderItemService, private sharedService: SharedService, private tpPoleInspectionItemService: TPPoleInspectionItemService
    , private deliveryDocketOrderItemService: DeliveryDocketOrderItemService, private loadingDocketDeliveryService: LoadingDocketDeliveryService, private loadingDocketService: LoadingDocketService ) {

    this.form = this.fb.group({
      txtSearchFinalNo: [''],
      txtSearchOrderNo: [''],
      txtDeliveryDocketNo: [''],
      txtCustomer: [''],
      txtHauler: [''],
      dteStatusDate: [''],
      txtStatus: [''],
      radFinalItemsView:[0]
    });

  }

  ngOnInit(): void {
    this.gotNonDelivered = false;
    this.haveDeliveryDocket = false;
    this.showSpinner = false;
    this.selectedTab = this.eDeliveryDocketAddItemsTabs.OrderItems;
    //this.selectedTab = this.eDeliveryDocketAddItemsTabs.LinikedFinalItems;

    this.loadDeliveryDocketData();
    this.loadDeliveryDocketOrderItems();

    this.form.controls['txtDeliveryDocketNo'].disable();
    this.form.controls['txtCustomer'].disable();
    this.form.controls['txtHauler'].disable();
    this.form.controls['dteStatusDate'].disable();
    this.form.controls['txtStatus'].disable();

    setTimeout(() => {
      this.loadLinkedFinalItems()
      this.haveDeliveryDocket = this.deliveryDocket != null;
    },200);

    this.route.queryParams.subscribe(params => {


      this.formService.setFieldsFromQueryParams(this.filterToQueryParamMap, params, this.form);

      if (params.viewtype == "1") {
        setTimeout(() => {
          this.changeView(1);
        }, 1000);
      }

    });

    //this.form.controls['txtSearchFinalNo'].setValue("9874");


  }

  loadDeliveryDocketData() {

    
    //if (this.deliveryDocket == null) {
    //  this.form.controls['btnCreateDeliveryDocket'].enable();
    //}

    if (this.deliveryDocket != null) {
      //this.form.controls['btnCreateDeliveryDocket'].disable();
      this.form.controls['txtDeliveryDocketNo'].setValue(this.deliveryDocket.intDocketNo.toString());
      this.form.controls['txtCustomer'].setValue(this.deliveryDocket.txtCustomerName);
      this.form.controls['txtHauler'].setValue(this.deliveryDocket.txtHaulingCompanyName);
      this.form.controls['dteStatusDate'].setValue(this.deliveryDocket.dteStatusDate);
      this.form.controls['txtStatus'].setValue(this.deliveryDocket.txtStatus);

    }
  }


  async loadDeliveryDocketOrderItems() {

    console.log("order items delivery docket: " + this.deliveryDocket);

    if (this.deliveryDocket != null) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
      aParamList.push(aParam);
      this.deliveryDocketOrderItems = new MatTableDataSource();
      this.deliveryDocketOrderItemsList = new MatTableDataSource();
      this.deliveryDocketOrderItems.data = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemParamArrayPromise(aParamList);
      this.deliveryDocketOrderItemsList.data = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemParamArrayPromise(aParamList);

      this.deliveryDocketPreviewComponent.setTotals()
      this.deliveryDocketOrderItemsComponent.setTotals()

    }
  }

  async loadLinkedFinalItems() {

    console.log("delivery docket: " + this.deliveryDocket);

    if (this.deliveryDocket != null) {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", this.deliveryDocket.rowguid));
      aParamList.push(aParam);
      this.linkedFinalInspectionItems = new MatTableDataSource();
      this.linkedFinalInspectionItems.data = await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemParamArrayPromise(aParamList);

      //this.finalItemsLinkedComponent
      

    }
  }

  onTabChange(e: MatTabChangeEvent) {


    if (e.index == this.eDeliveryDocketAddItemsTabs.LinkedFinalItems) {
      this.finalItemsLinkedComponent.loadedComponent = true;
    }

    if (e.index == this.eDeliveryDocketAddItemsTabs.AddOrderItems) {
      this.orderItemsComponent.loadedComponent = true;
      this.formService.setAutoFocusTimeout("txtSearchOrderNo");
    }

    this.selectedTab = e.index;

  }

  searchFinalInspections() {
    this.searchFinalInspection(this.form.controls['txtSearchFinalNo'].value);
  }

  async searchFinalInspection(finalNo: number) {
    if (finalNo == null || finalNo == 0) {
      this.alertService.openSnackBar("The final number does not exist", "Close", "center", "bottom", "", 4000);
      return;
    }

    let finalInspection: TPPoleInspection = new TPPoleInspection;
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("intFinalNo", finalNo.toString()));
    aParamList.push(aParam);


    finalInspection = await this.tpPoleInspectionService.getTPPoleInspectionByFinalNoPromise(finalNo);
    //console.log("final Inspection", finalInspection);
    if (finalInspection != null) {

      await this.searchFinalInspectionItems(finalInspection.rowguid, this.form.controls['radFinalItemsView'].value);

      this.finalItemListComponent.setDeliveryDocketFinalItems(this.finalInspectionItems);

      //run initial preview code.
      this.previewDeliveryDocket(this.finalInspectionItems);

    }


  }


  async searchFinalInspectionItems(finalInspectionId: string, deliveryStatus: number) {

    this.showSpinner = true;

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guTPPoleInspectionId", finalInspectionId));
    aParamList.push(aParam);

    //console.log("deliveryStatus: " + deliveryStatus);

    if (deliveryStatus == 0 || deliveryStatus == 1) {
      aParam = [];
      if (deliveryStatus == 0) {
        aParam.push(new SQLParamArray("guDeliveryDocketOrderItemId", "", SQLParamArray.enSQLOperator.IsNull));
      }
      if (deliveryStatus == 1) {
        aParam.push(new SQLParamArray("guDeliveryDocketOrderItemId", "", SQLParamArray.enSQLOperator.IsNotNull));
      }
      aParamList.push(aParam);
    }

    this.finalInspectionItems = await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemParamArrayPromise(aParamList);

    this.gotNonDelivered = false;
    
    this.finalInspectionItems.forEach((f) => {
      f.blnAddToDelivery = true;
      if (f.guDeliveryDocketOrderItemId == null || f.guDeliveryDocketOrderItemId == "") {
        console.log("got non delivered");
        this.gotNonDelivered = true;
      }
    }); //By default we want to add to delivery docket.

    this.showSpinner = false;


  }

  async createDeliveryDocket() {

    if (this.deliveryDocketCreateDialogRef != null) {
      this.deliveryDocketCreateDialogRef.close();
    }


    this.deliveryDocketCreateDialogRef = this.dialog.open(DeliveryDocketCreateDialogComponent, {
      hasBackdrop: false,
      height: 'auto',
      maxWidth: '90vw'
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketCreateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.deliveryDocketCreateDialogRef.backdropClick().subscribe(() => {
      this.deliveryDocketCreateDialogRef.close();
    });


    this.deliveryDocketCreateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, deliveryDocket: DeliveryDocket }) => {
        if (data == null || data == undefined) {
          this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        console.log(data);
        if (data.status != "Cancel") {
          console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

          let deliveryDocket: dtoDeliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(data.deliveryDocket.rowguid);

          this.deliveryDocket = deliveryDocket;
          this.loadDeliveryDocketData();
          this.loadDeliveryDocketOrderItems();

        }
        else {
          // We have cancellled

        }

      });

  }


  async updateDeliveryDocket() {

    if (this.deliveryDocketUpdateDialogRef != null) {
      this.deliveryDocketUpdateDialogRef.close();
    }

    let deliveryDocket: DeliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(this.deliveryDocket.rowguid);
    let orderId: string = "";
    let deliveryDocketOrderItems: dtoDeliveryDocketOrderItem[] = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemByDocketIdPromise(deliveryDocket.rowguid);
    let loadingDocket: LoadingDocket;

    //console.log("deliveryDocketOrderItems", deliveryDocketOrderItems);
    if (deliveryDocketOrderItems != null && deliveryDocketOrderItems.length > 0) {
      orderId = deliveryDocketOrderItems[0].guOrderId;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocket.rowguid));
    aParamList.push(aParam);

    let loadingDocketDelivery: LoadingDocketDelivery[] = await this.loadingDocketDeliveryService.getLoadingDocketDeliveryParamArrayPromise(aParamList);

    //console.log("loadingDocketDelivery", loadingDocketDelivery);
    if (loadingDocketDelivery != null && loadingDocketDelivery.length > 0) {
      loadingDocket = await this.loadingDocketService.getLoadingDocketPromise(loadingDocketDelivery[0].guLoadingDocketId);
    }

    this.deliveryDocketUpdateDialogRef = this.dialog.open(DeliveryDocketUpdateDialogComponent, {
      hasBackdrop: false,
      height: 'auto',
      maxWidth: '90vw',
      data: { deliveryDocket: deliveryDocket, loadingDocket: loadingDocket, orderId: orderId }
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.deliveryDocketUpdateDialogRef.backdropClick().subscribe(() => {
      this.deliveryDocketUpdateDialogRef.close();
    });


    this.deliveryDocketUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, deliveryDocket: DeliveryDocket }) => {
        if (data == null || data == undefined) {
          this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        console.log(data);
        if (data.status != "Cancel") {
          console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);

          let deliveryDocket: dtoDeliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromise(data.deliveryDocket.rowguid);

          this.deliveryDocket = deliveryDocket;
          this.loadDeliveryDocketData();

        }
        else {
          // We have cancellled

        }

      });

  }

  async editDeliveryDocketOrderItem(deliveryDocketOrderItem: dtoDeliveryDocketOrderItem) {
    console.log("editDeliveryDocketOrderItem", deliveryDocketOrderItem);

    if (this.deliveryDocketOrderItemUpdateDialogRef != null) {
      this.deliveryDocketOrderItemUpdateDialogRef.close();
    }

    
    this.deliveryDocketOrderItemUpdateDialogRef = this.dialog.open(DeliveryDocketOrderItemUpdateDialogComponent, {
      hasBackdrop: false,
      height: 'auto',
      maxWidth: '90vw',
      data: { deliveryDocketOrderItem: deliveryDocketOrderItem, deliveryDocket: this.deliveryDocket }
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketOrderItemUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.deliveryDocketOrderItemUpdateDialogRef.backdropClick().subscribe(() => {
      this.deliveryDocketOrderItemUpdateDialogRef.close();
    });


    this.deliveryDocketOrderItemUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, deliveryDocketOrderItem: DeliveryDocketOrderItem }) => {
        if (data == null || data == undefined) {
          this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        console.log(data);
        if (data.status != "Cancel") {
          console.log("Delivery Docket Order Item Updated: " + data.deliveryDocketOrderItem.rowguid);

          this.loadDeliveryDocketOrderItems();

        }
        else {
          // We have cancellled

        }

      });


  }


  async removeDeliveryDocketOrderItem(deliveryDocketOrderItem: dtoDeliveryDocketOrderItem) {
    console.log("removeDeliveryDocketOrderItem");

    if (deliveryDocketOrderItem == null) {
      this.alertService.openSnackBar("There is no delivery docket order item to remove", "Close", "center", "bottom", "", 4000);
      return;
    }

    let alertMsg: string = "Are you sure you want to remove this deliery docket order item and unlink all linked items?";
    let msg: string = await this.alertService.openSnackBarCustomPromise("Remove Delivery Docket Order Item", alertMsg, "Yes", "No");

    if (msg != "Yes") {
      return;
    }

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];


    aParam.push(new SQLParamArray("guDeliveryDocketOrderItemId", deliveryDocketOrderItem.rowguid));
    aParamList.push(aParam);
    let finalItems: TPPoleInspectionItem[] = await this.tpPoleInspectionItemService.getTPPoleInspectionItemParamArrayPromise(aParamList);
    for (let i = 0; i <= finalItems.length - 1; i++) {
      finalItems[i].guDeliveryDocketOrderItemId = null;
    }

    await this.tpPoleInspectionItemService.updateTPPoleInspectionItemsPromise(finalItems);

    console.log("updating delivery docket order item.");
    let delDocketOrderItem: DeliveryDocketOrderItem = await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemPromise(deliveryDocketOrderItem.rowguid);
    await this.deliveryDocketOrderItemService.removeDeliveryDocektOrderItemPromise(deliveryDocketOrderItem.rowguid);

    await this.deliveryDocketOrderItemService.reSetItemNumbers(deliveryDocketOrderItem.guDeliveryDocketId);

    this.loadDeliveryDocketOrderItems();

    setTimeout(() => { this.loadLinkedFinalItems(); }, 1000);


  }



  changeView(val: number) {
    
    if (val == 1) {
      this.previewDeliveryDocket(this.finalItemListComponent.deliveryDocketFinalItems)
    }

    this.viewType = val;


  }

  changeFinalItmsView(val: number) {

    this.finalItemsView = val;
    this.form.controls['radFinalItemsView'].setValue(val);
    this.searchFinalInspections();

  }



  async createMissingDeliveryDocketOrderItems(deliveryDocketFinalItems: dtoTPPoleInspectionItem[]): Promise<dtoDeliveryDocketOrderItem[]>  {
    return new Promise(async resolve => {

      //=======================================================================================================================
      // CREATE ANY MISSING DELIVERY DOCKET ORDER ITEMS FIRST
      //=======================================================================================================================
      //let deliveryDocketOrderItemsToAdd: dtoDeliveryDocketOrderItem[] = [];
      let gotOrderItem: boolean;
      let blankDeliveryDocketOrderItem: dtoDeliveryDocketOrderItem = await this.dtoDeliveryDocketOrderItemService.getBlankdtoDeliveryDocketOrderItemPromise();

      if (this.deliveryDocketOrderItems == null) {
        this.deliveryDocketOrderItems = new MatTableDataSource<dtoDeliveryDocketOrderItem>();
      }

      deliveryDocketFinalItems.forEach(async (f) => {
        if (f.blnAddToDelivery == true) {

          gotOrderItem = false;

          //console.log(f.guOrderItemId + " = " + this.deliveryDocketOrderItems.data.length);
          for (let i = 0; i <= this.deliveryDocketOrderItems.data.length - 1; i++) {
            if (f.guDeliveryDocketOrderItemId == null && f.guOrderItemId == this.deliveryDocketOrderItems.data[i].guOrderItemId) {
              gotOrderItem = true;
              break;
            }
          }

        

          if (f.guDeliveryDocketOrderItemId == null && gotOrderItem == false) {
            let deliveryDocketOrderItem: dtoDeliveryDocketOrderItem = { ...blankDeliveryDocketOrderItem };
            deliveryDocketOrderItem.txtCustomerName = f.txtCustomerName;
            deliveryDocketOrderItem.txtOrderNo = f.txtOrderNo;
            deliveryDocketOrderItem.txtProductTypeName = f.txtProductName;
            deliveryDocketOrderItem.fltQuantityTotal = f.fltQuantityTotal;
            deliveryDocketOrderItem.fltQtyBal = Number(f.fltQtyBal);
            deliveryDocketOrderItem.intFinalQuantity = 0;
            deliveryDocketOrderItem.txtPoleLengthKN = f.txtFinalPole;
            deliveryDocketOrderItem.guOrderItemId = f.guOrderItemId;
            deliveryDocketOrderItem.guDeliveryDocketId = this.deliveryDocket.rowguid;
            deliveryDocketOrderItem.fltActualM3 = 0;
            deliveryDocketOrderItem.fltActualTonne = 0;
            deliveryDocketOrderItem.intItemNo = this.deliveryDocketOrderItems.data.length + 1;
            //let orderItem: dtoOrderItem = await this.orderItemService.getdtoOrderItemPromise(f.guOrderItemId);
            deliveryDocketOrderItem.guCustomerDeliveryAddressId = f.guClientDelAddressId;
            deliveryDocketOrderItem.intQuantityUoM = OrderItem.enQuantityUoM.Each;
            deliveryDocketOrderItem.fltQuantity = 0;
            this.deliveryDocketOrderItems.data.push(deliveryDocketOrderItem);
            //console.log("deliveryDocketOrderItemsToAdd: ", this.deliveryDocketOrderItems.data);
            //console.log("adding del order item", f.guOrderItemId);
          }
        
        }

      });
      resolve(this.deliveryDocketOrderItems.data);
    });

  }


  async previewDeliveryDocket(deliveryDocketFinalItems: dtoTPPoleInspectionItem[]) {
    //

    if (deliveryDocketFinalItems == null) {
      return;
    }


    if (this.deliveryDocket == null) {
      this.alertService.openSnackBar("Please create a delivery docket first", "Close", "center", "bottom", "", 4000);
      return;
    }

    if (this.deliveryDocketOrderItems == null || this.deliveryDocketOrderItems.data.length == 0) {
      // WE HAVE NO EXISTING DELIVERY DOCKET ORDER ITEMS SO WE NEED TO CREATE IN MEMORY DELIVERY DOCKET ORDER ITEMS.
      // ITERATE OVER FINAL ITEMS AND CREATE DELIVERY DOCKET ORDER ITEMS.

    }

    //Reset array to nothing then build.
    //this.deliveryDocketPreviewComponent.deliveryOrderItemsDatasource.data.length = 0;
    //this.deliveryDocketOrderItems.data.length = 0;
    //console.log("deliveryDocketOrderItems", this.deliveryDocketOrderItems);

    await this.createMissingDeliveryDocketOrderItems(deliveryDocketFinalItems);


    // Remove any order items that should no longer appear. ie removing last final item for an order item.
    // Therefore delivery docket order item should be removed.
    let orderItemExists: boolean = false;
    let iCount: number = 0;
    this.deliveryDocketOrderItems.data.forEach((ddoi) => {
      if (ddoi.rowguid == this.sharedService.EmptyGuid) {
        //console.log(ddoi.txtPoleLengthKN + ", " + ddoi.rowguid + ",  final length: " + deliveryDocketFinalItems.length);
        for (let i = 0; i <= deliveryDocketFinalItems.length - 1; i++) {
          if (deliveryDocketFinalItems[i].blnAddToDelivery == true && ddoi.guOrderItemId == deliveryDocketFinalItems[i].guOrderItemId) {
            //console.log(ddoi.guOrderItemId + " = " + deliveryDocketFinalItems[i].guOrderItemId);
            orderItemExists = true;
            break;
          }
        };
        //console.log("orderItemExists= " + orderItemExists);
        if (orderItemExists == false) {
          this.deliveryDocketOrderItems.data.splice(iCount, 1);
        }
      }
      iCount++;
    });

    //=======================================================================================================================
    // ADD TO EXISTING DELIVERY DOCKET ORDER ITEMS FIRST
    //=======================================================================================================================
    for (let i = 0; i <= this.deliveryDocketOrderItems.data.length - 1; i++) {
      this.deliveryDocketOrderItems.data[i].intFinalItemsToAdd = 0;
      this.deliveryDocketOrderItems.data[i].intFinalItemsToAddTotal = 0;
      this.deliveryDocketOrderItems.data[i].fltFinalItemsToAddTotalActualM3 = 0;
      this.deliveryDocketOrderItems.data[i].fltFinalItemsToAddTotalActualTonne = 0;
    }

    deliveryDocketFinalItems.forEach((f) => {
      if (f.blnAddToDelivery == true) {
      for (let i = 0; i <= this.deliveryDocketOrderItems.data.length - 1; i++) {
        if (f.guDeliveryDocketOrderItemId == null && f.guOrderItemId == this.deliveryDocketOrderItems.data[i].guOrderItemId) {
          this.deliveryDocketOrderItems.data[i].intFinalItemsToAdd = Number(this.deliveryDocketOrderItems.data[i].intFinalItemsToAdd.toString()) + 1;
          this.deliveryDocketOrderItems.data[i].fltFinalItemsToAddTotalActualM3 += Number(f.fltActualM3);
          this.deliveryDocketOrderItems.data[i].fltFinalItemsToAddTotalActualTonne += Number(f.fltActualTonne);
          break;
        }
      }

    }
    });


    //=======================================================================================================================
    // ADD UP TOTAL FINAL ITEMS TO ADD
    //=======================================================================================================================
    for (let i = 0; i <= this.deliveryDocketOrderItems.data.length - 1; i++) {
        //Filter out new items.
      if (this.deliveryDocketOrderItems.data[i].intFinalItemsToAdd > 0) {
      }
      this.deliveryDocketOrderItems.data[i].intFinalItemsToAddTotal = this.deliveryDocketOrderItems.data[i].fltQuantity + this.deliveryDocketOrderItems.data[i].intFinalItemsToAdd;
      if (this.deliveryDocketOrderItems.data[i].rowguid != this.sharedService.EmptyGuid) {
      }
      this.deliveryDocketOrderItems.data[i].fltFinalItemsToAddTotalActualM3 = this.deliveryDocketOrderItems.data[i].fltActualM3 + this.deliveryDocketOrderItems.data[i].fltFinalItemsToAddTotalActualM3;
      this.deliveryDocketOrderItems.data[i].fltFinalItemsToAddTotalActualTonne = this.deliveryDocketOrderItems.data[i].fltActualTonne + this.deliveryDocketOrderItems.data[i].fltFinalItemsToAddTotalActualTonne;
      //console.log("fltFinalItemsToAddTotalActualM3: " + this.deliveryDocketOrderItems.data[i].fltFinalItemsToAddTotalActualM3);

    }


    //console.log("this.deliveryDocketOrderItems.data", this.deliveryDocketOrderItems.data);
    if (this.deliveryDocketPreviewComponent.deliveryOrderItemsDatasource == null) {
      this.deliveryDocketPreviewComponent.deliveryOrderItemsDatasource = new MatTableDataSource<dtoDeliveryDocketOrderItem>();
    }
    this.deliveryDocketPreviewComponent.deliveryOrderItemsDatasource.data = this.deliveryDocketOrderItems.data;
    this.deliveryDocketPreviewComponent.setTotals();
    this.deliveryDocketOrderItemFinalItemsToAddM3Total = this.deliveryDocketPreviewComponent.deliveryDocketOrderItemFinalItemsToAddM3Total;
    this.deliveryDocketOrderItemFinalItemsToAddTonneTotal = this.deliveryDocketPreviewComponent.deliveryDocketOrderItemFinalItemsToAddTonneTotal;

  }

  updatePreview(deliveryDocketFinalItems: dtoTPPoleInspectionItem[]) {
    
    this.previewDeliveryDocket(deliveryDocketFinalItems);

  }


  async addFinalItems() {
    if (this.finalItemListComponent.deliveryDocketFinalItems == null) {
      this.alertService.openSnackBar("There are no final items to add", "Close", "center", "bottom", "", 4000);
      return;
    }

    this.showSpinner = true;


    let delItems: DeliveryDocketOrderItem[] = [];
    let orderItemsSetAsDelivered: string[] = [];


    for (let i = 0; i <= this.deliveryDocketOrderItems.data.length - 1; i++) {
      if (this.deliveryDocketOrderItems.data[i].intFinalItemsToAddTotal > 0) {

        if (this.deliveryDocketOrderItems.data[i].rowguid != this.sharedService.EmptyGuid) {
          //Update Existing item.
          //console.log("DeliveryDocketOrderItem: " + this.deliveryDocketOrderItems.data[i].rowguid);
          let delItem: DeliveryDocketOrderItem = await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemPromise(this.deliveryDocketOrderItems.data[i].rowguid);
          delItem.fltQuantity = this.deliveryDocketOrderItems.data[i].intFinalItemsToAddTotal;

          //console.log("setting update del item: " + delItem.rowguid + ",  " + delItem.guOrderItemId);
          delItems.push(delItem);

          if (delItem.fltQuantity >= this.deliveryDocketOrderItems.data[i].fltQtyBal) {
            orderItemsSetAsDelivered.push(this.deliveryDocketOrderItems.data[i].guOrderItemId);
            console.log("add to order item set delivered array: " + this.deliveryDocketOrderItems.data[i].guOrderItemId);
          }
        }

        if (this.deliveryDocketOrderItems.data[i].rowguid == this.sharedService.EmptyGuid) {
          //Create new item.
          let delItem: DeliveryDocketOrderItem = await this.deliveryDocketOrderItemService.getBlankDeliveryDocketOrderItemPromise();
          delete delItem.rowguid
          delItem.dteDateCreated = new Date(Date.now());
          delItem.fltQuantity = this.deliveryDocketOrderItems.data[i].intFinalItemsToAddTotal;
          delItem.intQuantityUoM = OrderItem.enQuantityUoM.Each;
          delItem.intItemNo = this.deliveryDocketOrderItems.data[i].intItemNo;
          delItem.guDeliveryDocketId = this.deliveryDocketOrderItems.data[i].guDeliveryDocketId;
          delItem.guOrderItemId = this.deliveryDocketOrderItems.data[i].guOrderItemId;
          delItem.guCustomerDeliveryAddressId = this.deliveryDocketOrderItems.data[i].guCustomerDeliveryAddressId;

          //console.log("setting new del item: " + delItem.rowguid + ",  " + delItem.guOrderItemId);
          delItems.push(delItem);

          if (delItem.fltQuantity >= this.deliveryDocketOrderItems.data[i].fltQtyBal) {
            orderItemsSetAsDelivered.push(this.deliveryDocketOrderItems.data[i].guOrderItemId);
            console.log("add to order item set delivered array: " + this.deliveryDocketOrderItems.data[i].guOrderItemId);
          }

        }
        
      }

    }




    //Go and update all final items.
    let sDeliveryDocketItems: string;
    for (let d = 0; d <= delItems.length - 1; d++) {
      sDeliveryDocketItems += "'" + delItems[d].rowguid + "',";
      if (delItems[d].rowguid == null) {

        delItems[d] = await this.deliveryDocketOrderItemService.createDeliveryDocektOrderItemPromise(delItems[d]);
        //console.log("going to create update delivery docket order item: " + delItems[d].rowguid + ", " + delItems[d].guOrderItemId);

      }

      if (delItems[d].rowguid != null) {

        await this.deliveryDocketOrderItemService.updateDeliveryDocektOrderItemPromise(delItems[d]);

        
        //console.log("going to update delivery docket order item: " + delItems[d].rowguid + ", " + delItems[d].guOrderItemId);

      }

    };


    let finalItems: TPPoleInspectionItem[] = [];
    // Go through each final item and see what delivery docket order item i'm lined up with.
    for (let f = 0; f <= this.finalItemListComponent.deliveryDocketFinalItems.length - 1; f++) {
      let dtoFinalItem: dtoTPPoleInspectionItem = this.finalItemListComponent.deliveryDocketFinalItems[f];
      if (dtoFinalItem.blnAddToDelivery == true) {

        for (let i = 0; i <= delItems.length - 1; i++) {
          if (dtoFinalItem.guDeliveryDocketOrderItemId == null && dtoFinalItem.guOrderItemId == delItems[i].guOrderItemId && delItems[i].rowguid != this.sharedService.EmptyGuid) {
            let finalItem: TPPoleInspectionItem = await this.tpPoleInspectionItemService.getTPPoleInspectionItemPromise(dtoFinalItem.rowguid);
            finalItem.guDeliveryDocketOrderItemId = delItems[i].rowguid;
            //console.log("setting final item: " + finalItem.rowguid + ",  " + finalItem.guDeliveryDocketOrderItemId);
            finalItems.push(finalItem);
            break;
          }

        }
      }
    };

    //Go and update all final items.
    let sFinalItems: string;
    for (let f = 0; f <= finalItems.length - 1; f++) {
      sFinalItems += "'" + finalItems[f].rowguid + "',";
      //console.log("going to update final item: " + finalItems[f].rowguid + ", " + finalItems[f].guFinalPoleId + ",  " + finalItems[f].guDeliveryDocketOrderItemId);

      await this.tpPoleInspectionItemService.updateTPPoleInspectionItemPromise(finalItems[f]);
      
    };


    for (let oi = 0; oi <= orderItemsSetAsDelivered.length - 1; oi++) {

      let orderItem: OrderItem = await this.orderItemService.getdtoOrderItemPromise(orderItemsSetAsDelivered[oi].toString());
      orderItem.blnDelivered = true;

      await this.orderItemService.updateOrderItemPromise(orderItem);
      console.log("updating order item set delivered: ", orderItem);

    };


    this.alertService.openSnackBar("The final inspection items have been linked and delivery docket items created", "Close", "center", "bottom", "", 3000);

    this.loadDeliveryDocketOrderItems();

    this.deliveryDocketItemsAdded.emit(true);
    setTimeout(() => { this.searchFinalInspections(); this.loadLinkedFinalItems(); }, 1000);

    //console.log("sFinalItems", sFinalItems);
    //console.log("sDeliveryDocketItems", sDeliveryDocketItems);

    this.showSpinner = false;

  }


  async unlinkFinalItem(finalItem: TPPoleInspectionItem) {

    if (finalItem.guDeliveryDocketOrderItemId == null || finalItem.guDeliveryDocketOrderItemId == "") {
      this.alertService.openSnackBar("The final item is not linked to a delivery docket", "Close", "center", "bottom", "", 4000);
      return;
    }

    let deliveryDocketOrderItem: dtoDeliveryDocketOrderItem = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemPromise(finalItem.guDeliveryDocketOrderItemId);
    let alertMsg: string = "Are you sure you want to remove this final item?";
    let removeDelOrderItem: boolean = false;
    console.log("fltQuantity: " + deliveryDocketOrderItem.fltQuantity);
   if (deliveryDocketOrderItem.fltQuantity == 1) {
      alertMsg += "\nThis is also the last final item linked to the delivery docket item " + deliveryDocketOrderItem.txtPoleLengthKN + " so it will also be removed.";
      removeDelOrderItem = true;
    }


    let msg: string = await this.alertService.openSnackBarCustomPromise("Remove Final Item", alertMsg, "Yes", "No");

    if (msg != "Yes") {
      return;
    }

    this.showSpinner = true;

    finalItem.guDeliveryDocketOrderItemId = null;
    await this.tpPoleInspectionItemService.updateTPPoleInspectionItemPromise(finalItem);
    console.log("updating inspection item");

    if (removeDelOrderItem == true) {
      console.log("removing delivery docket order item.");
      await this.deliveryDocketOrderItemService.removeDeliveryDocektOrderItemPromise(deliveryDocketOrderItem.rowguid);

    }

    if (removeDelOrderItem == false) {
      console.log("updating delivery docket order item.");
      let delDocketOrderItem: DeliveryDocketOrderItem = await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemPromise(deliveryDocketOrderItem.rowguid);
      delDocketOrderItem.fltQuantity -= 1;

      await this.deliveryDocketOrderItemService.updateDeliveryDocektOrderItemPromise(delDocketOrderItem);

      // Check if total order items have now not been delivered since we are removing final item.
      // We need to unset the delivered tick box on the order item.
      let dtoOrderItem: dtoOrderItem = await this.dtoOrderItemService.getdtoOrderItemPromise(delDocketOrderItem.guOrderItemId);
      //console.log("dtoOrderItem: ", dtoOrderItem);
      //console.log("dtoOrderItem.blnDelivered: ", dtoOrderItem.blnDelivered);
      //console.log("dtoOrderItem.fltQtyBal: ", dtoOrderItem.fltQtyBal);

      if (dtoOrderItem.blnDelivered == true && dtoOrderItem.fltQtyBal > 0) {
        let orderItem: OrderItem = await this.orderItemService.getOrderItemsPromise(delDocketOrderItem.guOrderItemId);
        orderItem.blnDelivered = false;
        await this.orderItemService.updateOrderItemPromise(orderItem);
        //console.log("orderItem: ", orderItem);

      }

    }


    this.loadDeliveryDocketOrderItems();

    setTimeout(() => { this.loadLinkedFinalItems(); }, 1000);

    this.showSpinner = false;

  } 

  async addOrderItems() {

    if (this.deliveryDocketOrderItemsToAdd == null || this.deliveryDocketOrderItemsToAdd.length == 0) {
      this.alertService.openSnackBarDefault("There are no order items to add");
      return;
    }


    //let existingDeliveryDocketOrderItems: DeliveryDocketOrderItem[] = await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemByDocketIdPromise(this.deliveryDocket.rowguid);
    //let delOrderItemsToAdd: DeliveryDocketOrderItem[];
    //let delOrderItemsToUpdate: DeliveryDocketOrderItem[];

    
    //if (existingDeliveryDocketOrderItems == null || existingDeliveryDocketOrderItems.length == 0) {
    //  delOrderItemsToUpdate = this.deliveryDocketOrderItemsToAdd;
    //}

    //// Are there any new items to add that are the same order item in the existing items on the delivery docket.
    //// If so then this means we probably have not finished with the delivery docket yet.
    //// E.g we've created the delivery docket and realised that the quantity is incorrect or we find out that
    //// the quantiy has changed before loading or the truck is too heavy and we need to take some off.
    //// In this case we just want to set the Quantity of the delivery docket order item to be over written with
    //// the Quanity that the user as specified.
    //// There should also be a manual way to edit the Delivery Docket Order Item as well to change the Quantity.
    //if (existingDeliveryDocketOrderItems != null && existingDeliveryDocketOrderItems.length > 0) {
      
    //  for (let i = 0; i <= this.deliveryDocketOrderItemsToAdd.length - 1; i++) {
    //    let exists: boolean = false;
    //    for (let e = 0; i <= delOrderItemsToAdd.length - 1; e++) {
    //      if (delOrderItemsToAdd[e].guOrderItemId == this.deliveryDocketOrderItemsToAdd[i].guOrderItemId) {
    //        delOrderItemsToAdd[e].fltQuantity = this.deliveryDocketOrderItemsToAdd[i].fltQuantity;
    //        exists = true;
    //        break;
    //      }
    //    }

    //    // Add as new item if it does not exist.
    //    if (exists == false) {
    //      delOrderItemsToAdd.push(this.deliveryDocketOrderItemsToAdd[i]);
    //    }
    //  }
    //}

    this.showSpinner = true;

    for (let i = 0; i <= this.deliveryDocketOrderItemsToAdd.length - 1; i++) {

      await this.deliveryDocketOrderItemService.createDeliveryDocektOrderItemPromise(this.deliveryDocketOrderItemsToAdd[i]);

      // Check if total order items have been delivered.
      // If so we need to set the delivered tick box on the order item.
      let dtoOrderItem: dtoOrderItem = await this.dtoOrderItemService.getdtoOrderItemPromise(this.deliveryDocketOrderItemsToAdd[i].guOrderItemId);
      //console.log("dtoOrderItem: ", dtoOrderItem);
      //console.log("dtoOrderItem.blnDelivered: ", dtoOrderItem.blnDelivered);
      //console.log("dtoOrderItem.fltQtyBal: ", dtoOrderItem.fltQtyBal);

      if (dtoOrderItem.blnDelivered == false && dtoOrderItem.fltQtyBal == 0) {
        let orderItem: OrderItem = await this.orderItemService.getOrderItemsPromise(this.deliveryDocketOrderItemsToAdd[i].guOrderItemId);
        orderItem.blnDelivered = true;
        await this.orderItemService.updateOrderItemPromise(orderItem);
        //console.log("orderItem: ", orderItem);

      }



    }


    this.orderItemsComponent.deliveryDocketOrderItemsToAdd = [];
    this.orderItemsComponent.datasource = new MatTableDataSource<dtoOrderItem>();
    this.orderItemsComponent.datasource.data = [];

    this.tabDeliveryDocketsAddItems.selectedIndex = this.eDeliveryDocketAddItemsTabs.OrderItems;
    this.loadDeliveryDocketOrderItems();

    this.showSpinner = false;

  }

  updateOrderItemsToAdd(deliveryDocketOrderItemsUpdate:DeliveryDocketOrderItem[] ) {

    this.deliveryDocketOrderItemsToAdd = deliveryDocketOrderItemsUpdate;

    for (let i = 0; i <= this.deliveryDocketOrderItemsToAdd.length - 1; i++) {

      console.log("Item: " + this.deliveryDocketOrderItemsToAdd[i].intItemNo + ",  fltQuantity: " + this.deliveryDocketOrderItemsToAdd[i].fltQuantity);

    }


  }


  unlinkOrderItem(orderItem: dtoOrderItem) {

  }

  async searchOrderItems(orderNo: string) {

    if (orderNo == null || orderNo == "") {
      this.alertService.openSnackBarDefault("Please enter an order number");
      return;
    }

    if (this.deliveryDocket == null) {
      this.alertService.openSnackBarDefault("The there no Delivery Docket selected");
      return;
    }

    this.showSpinner = true;

    let orderItems: dtoOrderItem[] = [];
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("txtOrderNo", orderNo.toString(), SQLParamArray.enSQLOperator.LikeAny));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("guCustomerId", this.deliveryDocket.guCustomerId));
    aParamList.push(aParam);


    orderItems = await this.orderItemService.getdtoOrderItemParamArrayPromise(aParamList);

    console.log("orderItems", orderItems);
    if (orderItems != null) {

      // Set which items already exist
      for (let i = 0; i <= orderItems.length - 1; i++) {
        if (this.deliveryDocketOrderItems.data != null && this.deliveryDocketOrderItems.data.length > 0) {
          for (let e = 0; e <= this.deliveryDocketOrderItems.data.length - 1; e++) {
            if (this.deliveryDocketOrderItems.data[e].guOrderItemId == orderItems[i].rowguid) {
              orderItems[i].blnExistsOnDocket = true;
              break;
            }
          }
        }
      }


      this.orderItemsComponent.datasource = new MatTableDataSource<dtoOrderItem>();
      this.orderItemsComponent.datasource.data = orderItems;
      this.orderItemsComponent.deliveryDocketOrderItemsToAdd = [];
      setTimeout(() => {
        this.orderItemsComponent.addAllItem.checked = true;
        //console.log("addAllItem", this.orderItemsComponent.addAllItem)
      }, 500);


      let delItemBlank: DeliveryDocketOrderItem = await this.deliveryDocketOrderItemService.getBlankDeliveryDocketOrderItemPromise();

      for (let i = 0; i <= orderItems.length - 1; i++) {

        if (orderItems[i].blnExistsOnDocket == false && (orderItems[i].fltQtyBal > 0 && orderItems[i].fltQtyBal <= orderItems[i].fltQuantityTotal)) {
          this.gotOrderItemsNonDelivered = true;
          this.orderItemsComponent.addNewDeliveryDocketOrderItem(this.deliveryDocket, orderItems[i], delItemBlank, orderItems[i].fltQtyBal, i);
        }

      }

      this.deliveryDocketOrderItemsToAdd = this.orderItemsComponent.deliveryDocketOrderItemsToAdd;

      this.showSpinner = false;

    }

  }


  openLoadingDocket() {
    let path: string = this.sharedService.LocalServerURI;
    if (location.hostname.indexOf('localhost') == -1) {
      path = 'https://' + location.hostname
    }

    var windowUrl = path + '/loading-docket-search?docketno=' + this.deliveryDocket.intLoadingDocketNo + '&search=true';
    var uniqueName = new Date();
    var windowName = "_blank";
    var printWindow = window.open(windowUrl, windowName).focus();  }

}
