import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { AppService } from '../../_shared/services/app-services/app.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { AppUserColumn } from '../../_shared/business-objects/app-user-column.bo';
import { AppUserColumnService } from '../../_shared/services/app-user-column.service';
import { dtoAppUserColumnService } from '../../_shared/services/dto-app-user-column.service';
import { AppPageService } from '../../_shared/services/app-page.service';
import { AppPageColumnService } from '../../_shared/services/app-page-column.service';
import { AppPageColumn } from '../../_shared/business-objects/app-page-column.bo';
import { dtoAppUserColumn } from '../../_shared/business-objects/dto-app-user-column.bo';


export enum enAppUserColumnTabs { AppUserColumns = 0, SearchOptions = 1, CreateAppUserColumn = 2, UpdateAppUserColumn = 3 }


@Component({
  selector: 'app-app-user-column-search',
  templateUrl: './app-user-column-search.component.html',
  styleUrls: ['./app-user-column-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class AppUserColumnSearchComponent implements OnInit {

  @ViewChild('tabAppUserColumns', { static: false }) tabAppPages: MatTabGroup;


  form: FormGroup;
  selectedTab: number;
  public enAppUserColumnTabs = enAppUserColumnTabs;

  appUserColumns: MatTableDataSource<dtoAppUserColumn>;
  appUserColumn: AppUserColumn;

  apps: dtoIntKeyValue[] = [];

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  totalRows: number = 0;

  resultTime: number;
  searchTime: number;

  showSpinner: boolean = false;

  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private route: ActivatedRoute, private formService: FormService
    , private appUserColumnService: AppUserColumnService, private appService: AppService, private appPageService: AppPageService, private appPageColumService: AppPageColumnService
    , private dtoAppUserColumnService: dtoAppUserColumnService) {

    //

    this.form = this.fb.group({
      txtSearchAppUserColumnName: [''],
      txtSearchAppUserPageName: [''],
      txtSearchColumnSetName: [''],
      radSearchApps: [''],
      txtSearchAppUserColumnURL: [''],
      dteSearchDateCreatedFrom: [''],
      dteSearchDateCreatedTo: [''],
      txtSearchFirstName: [''],
      txtSearchLastName: ['']
    });

    this.appUserColumns = new MatTableDataSource();


    this.apps = this.sharedService.getEnumObjectKeyValue(appService.enApp);
    this.apps.splice(0, 0, new dtoIntKeyValue(-1, "None"));

    this.form.controls['radSearchApps'].setValue(-1);

  }

  ngOnInit(): void {

    this.selectedTab = this.enAppUserColumnTabs.AppUserColumns;

    this.route.queryParams.subscribe(params => {

      this.formService.setFieldsFromQueryParams(this.filterToQueryParamMap, params, this.form);

    });

    this.loadData();

  }

  async loadData() {

    //let aParamList: SQLParamArray[][] = [];
    //let aParam: SQLParamArray[] = [];

    //aParam.push(new SQLParamArray("dteDateCreated", '2022-01-01', SQLParamArray.enSQLOperator.GreaterThanOrEqualTo));
    //aParamList.push(aParam);

    //this.appUserColumn.data = await this.appPageService.getAppPageParamArrayPromise(aParamList);

  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    if (e.index == this.enAppUserColumnTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchAppUserColumnName");
    }

  }


  searchAppUserColumns() {

    let btnSearchAppPages = document.getElementById("btnSearchPageURL");
    if (btnSearchAppPages != null) {
      btnSearchAppPages.focus();
    }

    this.tabAppPages.selectedIndex = this.enAppUserColumnTabs.AppUserColumns;
    this.showSpinner = true;

    this.resultTime = 0;
    this.searchTime = Date.now();

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let appColumnName: string = this.form.controls["txtSearchAppUserColumnName"].value.toString();
    let appPageName: string = this.form.controls["txtSearchAppUserPageName"].value.toString();
    let columnSetName: string = this.form.controls["txtSearchColumnSetName"].value.toString();
    let firstName: string = this.form.controls["txtSearchFirstName"].value.toString();
    let lastName: string = this.form.controls["txtSearchLastName"].value.toString();
    let app: string = this.form.controls["radSearchApps"].value.toString();
    let appPageURL: string = this.form.controls["txtSearchAppUserColumnURL"].value;
    let createdDateFrom: string = this.form.controls["dteSearchDateCreatedFrom"].value;
    let createdDateTo: string = this.form.controls["dteSearchDateCreatedTo"].value;
    const datepipe: DatePipe = new DatePipe('en-AU');


    this.totalRows = 0;

    this.appUserColumns.data = [];

    if (appColumnName != "" && appColumnName != null) {
      aParam = [];
      if (appColumnName.indexOf(",")) {
        let aAppColumnNames: string[] = appColumnName.split(",");
        for (let i = 0; i <= aAppColumnNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtColumnName", aAppColumnNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtColumnName", appColumnName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (appPageName != "" && appPageName != null) {
      aParam = [];
      if (appPageName.indexOf(",")) {
        let aAppPageNames: string[] = appPageName.split(",");
        for (let i = 0; i <= aAppPageNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtPageName", aAppPageNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtPageName", appPageName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (columnSetName != "" && columnSetName != null) {
      aParam = [];
      if (appPageName.indexOf(",")) {
        let aColumnSetNames: string[] = columnSetName.split(",");
        for (let i = 0; i <= aColumnSetNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtColumnSetName", aColumnSetNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtColumnSetName", columnSetName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (firstName != "" && firstName != null) {
      aParam = [];
      if (firstName.indexOf(",")) {
        let aFirstNames: string[] = firstName.split(",");
        for (let i = 0; i <= aFirstNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtFirstName", aFirstNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtFirstName", firstName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (lastName != "" && lastName != null) {
      aParam = [];
      if (lastName.indexOf(",")) {
        let aLastNames: string[] = lastName.split(",");
        for (let i = 0; i <= aLastNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtLastName", aLastNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtLastName", lastName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (app != "" && app != null && parseInt(app) > -1) {
      aParam = [];
      aParam.push(new SQLParamArray("intApp", app))
      aParamList.push(aParam);
    }


    if (appPageURL != "" && appPageURL != null) {
      aParam = [];
      if (appPageURL.indexOf(",")) {
        let aAppPageURLs: string[] = appPageURL.split(",");
        for (let i = 0; i <= aAppPageURLs.length - 1; i++) {
          aParam.push(new SQLParamArray("txtPageURL", aAppPageURLs[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtPageURL", appPageURL, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }


    if (createdDateFrom != "" && createdDateFrom != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdDateFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }

    if (createdDateTo != "" && createdDateTo != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdDateTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }


    if (aParamList.length == 0) {
      //this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "", 4000);
    }


    this.dtoAppUserColumnService.getdtoAppUserColumnParamArray(aParamList).subscribe(appUserColumns => {

      appUserColumns = appUserColumns.sort((a, b) => { return ((a.txtPageName > b.txtPageName ? 1 : -1 || a.txtLastName > b.txtLastName ? 1 : -1) && a.txtFirstName > b.txtFirstName ? 1 : -1 && a.intColumnOrder - b.intColumnOrder )});
      //appUserColumns = appUserColumns.sort((a, b) => { return a.txtLastName > b.txtLastName ? 1 : -1 || a.txtFirstName > b.txtFirstName ? 1 : -1 }).sort((a, b) => {
      //  return a.txtPageName > b.txtPageName ? 1 : -1
      //});

      this.appUserColumns.data = appUserColumns;

      this.totalRows = appUserColumns.length;

      this.resultTime = ((Date.now() - this.searchTime) / 1000);

      this.showSpinner = false;

    }, err => {
      console.log(err);
    });


  }


  async editAppUserColumn(appUserColumn: dtoAppUserColumn) {

    this.appUserColumn = await this.appUserColumnService.getAppUserColumnPromise(appUserColumn.rowguid);

    this.tabAppPages.selectedIndex = this.enAppUserColumnTabs.UpdateAppUserColumn;

  }

  async appUserColumnCreated(appUserColumn: AppUserColumn) {
    if (appUserColumn == null) {
      return;
    }

    this.tabAppPages.selectedIndex = this.enAppUserColumnTabs.AppUserColumns;

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("rowguid", appUserColumn.guAppPageColumnId));
    let appPageColumn: AppPageColumn = await this.appPageColumService.getAppPageColumnPromise(appUserColumn.guAppPageColumnId);

    this.form.controls['txtSearchAppUserColumnName'].setValue(appPageColumn.txtColumnName);

    this.searchAppUserColumns();

  }

  async appUserColumnUpdated(appUserColumn: AppUserColumn) {

    if (appUserColumn == null) {
      return;
    }

    this.tabAppPages.selectedIndex = this.enAppUserColumnTabs.AppUserColumns;


    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("rowguid", appUserColumn.guAppPageColumnId));
    let appPageColumn: AppPageColumn = await this.appPageColumService.getAppPageColumnPromise(appUserColumn.guAppPageColumnId);


    aParamList = [];
    aParam = [];

    aParam.push(new SQLParamArray("rowguid", appPageColumn.guAppPageId));
    let appPage: AppPage = await this.appPageService.getAppPagePromise(appPageColumn.guAppPageId);


    this.form.controls['txtSearchAppUserPageName'].setValue(appPage.txtPageName);


    //this.form.controls['txtSearchAppUserColumnName'].setValue(appPageColumn.guAppPageId);

    this.searchAppUserColumns();

  }

  appUserColumnCreatedAllColumns(pageId: string) {

    this.tabAppPages.selectedIndex = this.enAppUserColumnTabs.AppUserColumns;

  }

  appUserColumnCreatedCopyUser(val: string) {

    this.tabAppPages.selectedIndex = this.enAppUserColumnTabs.AppUserColumns;

  }

  appUserColumnCancelled() {

    this.tabAppPages.selectedIndex = this.enAppUserColumnTabs.AppUserColumns;

  }

  async appUserColumnRemove(appUserColumn: AppUserColumn) {

    if (appUserColumn == null) {
      return;
    }

    let msg = await this.alertService.openSnackBarCustomPromise("Remove App User Column", "Are you sure you want to remove this app user column", "Yes", "No", "center", "bottom", "", 0);

    if (msg == "Yes") {
      this.appUserColumnService.removeAppUserColumnPromise(appUserColumn.rowguid);
    }

    this.searchAppUserColumns();

  }
}
