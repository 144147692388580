<div class="stock-item-multiple-dialog">

  <div class="stock-item-multiple-heading">
    <h1>Stock Item Multiples</h1>
  </div>
  <div class="stock-item-multiples-container">
    <div class="stock-item-container-row">
      <h2>Stock Item</h2>
      <div class="stock-item-container" *ngIf="stockItem != null">
        <div class="stock-item-product">{{stockItem.txtProductName}}</div>
        <div class="stock-item-profile">{{stockItem.txtProfileName}}</div>
        <div class="stock-item-width">{{stockItem.intWidth}}</div>
        <div class="stock-item-separator">x</div>
        <div class="stock-item-thickness">{{stockItem.intThickness}}</div>
        <div class="stock-item-species">{{stockItem.txtSpeciesName}}</div>
        <div class="stock-item-grade">{{stockItem.txtGradeName}}</div>
        <div class="stock-item-quantity">1</div>
        <div class="stock-item-at">&#64;</div>
        <div class="stock-item-length">
          <input class="stock-length-textbox" type="number" step="0.1" min="0" value="" id="txtLength" #txtLength (keyup)="validateStockItemLength();" (change)="validateStockItemLength();">
        </div>
        <div class="stock-item-order-item-total-lm" [ngClass]="overLength == true ? 'stock-item-order-item-total-lm-red blink_me' : 'stock-item-order-item-total-lm'">
          {{orderItemLM}}
        </div>
        <div class="stock-item-waste" [ngClass]="overLength == true ? 'stock-item-waste-red blink_me' : 'stock-item-waste'">{{stockItemWaste != null ? stockItemWaste + '%' : ''}}</div>

        <div class="selected-machine-runs-container">
          <div class="selected-machine-runs" id="selectedMmachineRuns">
            {{selectedMachineRunNos}}
          </div>
        </div>
        <div class="selected-moulders-container">
          <div class="selected-machine-runs" id="selectedMmachineRuns">
            <span *ngIf="selectedMachineMoulderObjs != null && selectedMachineMoulderObjs.length > 0">Moulders: </span>
            <span class="selected-moulders" *ngFor="let moulder of selectedMachineMoulderObjs; let i = index">{{i < selectedMachineMoulderObjs.length - 1 ? moulder.txtName + ', ' : moulder.txtName}}</span>
          </div>
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div class="order-length-label">order length</div>
        <div class="waste-label">waste</div>

        <div class="selected-machine-runs-container">
          <button class="get-machine-run-button" type="button" mat-flat-button color="primary" (click)="getMachineRun()">Get Machine Run</button>
          <button class="get-moulder-button" type="button" mat-flat-button color="primary" (click)="getMoulder()">Get Moulder</button>
          <button class="no-moulder-button" type="button" mat-flat-button color="primary" (click)="setNoMoulder()">No Moulder</button>
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>

      </div>
    </div>

    <div class="order-item-container-row">
      <div class="order-item-container">
        <h2>Order Items</h2>
        <table mat-table #tblOrderItems id="tblOrderItems{{appIndex}}" multiTemplateDataRows [dataSource]="datasource" class="mat-elevation-z8" matSort *ngIf="datasource != null">

          <ng-container matColumnDef="intItemNo">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD finalItemNumber"> {{orderItem.intItemNo}} </td>
            <td mat-footer-cell *matFooterCellDef class="orderItemTD footerTD">  </td>
          </ng-container>

          <ng-container matColumnDef="txtProductTypeName">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Product Name </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD productName"> {{orderItem.txtProductShortName != null && orderItem.txtProductShortName != "" ? orderItem.txtProductShortName : orderItem.txtProductName}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="txtProfileName">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" mat-sort-header> Profile </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD profileName"> {{orderItem.txtProfileAbbrievation != null && orderItem.txtProfileAbbrievation != "" ? orderItem.txtProfileAbbrievation : orderItem.txtProfileName}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="fltWidth">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Width </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD width alignRightTD"> {{orderItem.fltWidth}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="fltThickness">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Thickness </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD thickness alignRightTD"> {{orderItem.fltThickness}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="fltLength">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Length </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD length alignRightTD"> {{orderItem.fltLength}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="fltDiameter">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Diameter </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD diameter alignRightTD"> {{orderItem.fltDiameter == 0 ? '' : orderItem.fltDiameter}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="txtSpecies">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Species </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD species"> {{orderItem.txtSpeciesName}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="txtGradeName">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Grade </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD grade"> {{orderItem.txtGradeName}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="fltQtyTotal">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Quantity Total </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD finalQuantityOnOrder alignRightTD"> {{orderItem.fltQuantityTotal}} </td>
            <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRight footerTD"> </td>
          </ng-container>

          <ng-container matColumnDef="fltQtyBal">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Quantity Bal </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD finalQuantityOnOrder alignRightTD"> {{orderItem.fltQtyBal}} </td>
            <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRight footerTD"> </td>
          </ng-container>

          <ng-container matColumnDef="fltQuantity">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Quantity To Add </th>
            <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD alignRightTD quantity">
              <span style="display: inline-block; border: 0px solid green;">
                <mat-form-field class="example-full-width header-button" style="width: 100px !important; padding-right: 0px !important;" appearance="fill">
                  <input class="textbox-input inline-textbox" matInput type="number" min="0" value="" id="txtQuantity2{{rowIndex}}" #txtQuantity (keyup)="validateOrderItemQuantity(txtQuantity.value, orderItem, rowIndex);$event.stopPropagation();" (change)="validateOrderItemQuantity(txtQuantity.value, orderItem, rowIndex);$event.stopPropagation();" [disabled]="orderItem.fltQtyBal > 0 ? false : true">
                </mat-form-field>
              </span>
            </td>
            <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerQuantity"> <div class="quantityFooterTD">{{orderItemQtyTotalInput}}</div></td>
          </ng-container>

          <ng-container matColumnDef="txtQuantityUOM">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Qty UOM </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD quantityUOM"> {{orderItem.txtQuantityUoM}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="txtAddress">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH addressTH" mat-sort-header> Address </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD address"> {{(orderItem.txtAlternateAddress != null || orderItem.txtAlternateAddress != '') ? orderItem.txtAlternateAddress : orderItem.txtAddress}} {{orderItem.txtTown}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="txtComments">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Comments </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD comments"> {{orderItem.txtComments}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>


          <ng-container matColumnDef="fltM3">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" align="right" arrowPosition="before" mat-sort-header> M3 </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD M3 alignRightTD"> {{orderItem.fltM3}} </td>
            <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerM3"> {{orderItemM3TotalInput}} </td>
          </ng-container>

          <ng-container matColumnDef="fltActualM3">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Actual M3 </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD actualM3 alignRightTD"> {{RoundNumber(orderItem.fltActualM3,3)}} </td>
            <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerActualM3"> {{orderItemActualM3TotalInput}} </td>
          </ng-container>

          <ng-container matColumnDef="fltActualTonne">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Actual Tonne </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD actualTonne alignRightTD"> {{RoundNumber(orderItem.fltActualTonne,3)}} </td>
            <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerActualTonne"> {{orderItemActualTonneTotalInput}} </td>
          </ng-container>

          <ng-container matColumnDef="btnEditQuantity">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH editQuantityTH" mat-sort-header>  </th>
            <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD editQuantity">
              <div matTooltip="To change the quantity please either add final items or remove final items." [matTooltipDisabled]="!(orderItem.fltFinalM3 != null && orderItem.fltFinalM3 > 0)">
                <button class="edit-order-item-button" type="button" mat-flat-button color="primary" id="btnEditQuantity{{rowIndex}}" #btnEditQuantity (click)="editQuantity(orderItem, rowIndex, btnEditQuantity)" [disabled]="orderItem.fltFinalM3 != null && orderItem.fltFinalM3 > 0">Edit Quantity</button>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="btnEditOrderItem">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH orderItemEditTH" mat-sort-header>  </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD orderItemEdit">
              <button class="edit-order-item-button" type="button" mat-flat-button color="primary" (click)="editOrderItem(orderItem)">Edit</button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="btnRemoveOrderItem">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH orderItemEditTH" mat-sort-header>  </th>
            <td mat-cell *matCellDef="let orderItem" class="orderItemTD orderItemRemove">
              <button class="remove-delivery-docket-order-item-button" type="button" mat-flat-button color="primary" (click)="removeOrderItem(orderItem)">Remove</button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="btnViewStockItems">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH" aria-label="row actions"></th>
            <td mat-cell *matCellDef="let orderItem; let rowIdx = dataIndex" class="orderItemTD">
              <button mat-icon-button aria-label="expand row" type="button" (click)="expandElement(orderItem, rowIdx);$event.stopPropagation()">
                <mat-icon *ngIf="orderItemExpanded !== orderItem">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="orderItemExpanded === orderItem">keyboard_arrow_up</mat-icon>
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <th mat-header-cell *matHeaderCellDef class="orderItemTH" [attr.colspan]="displayedOrderItems.length"></th>
            <td mat-cell *matCellDef="let orderItem; let rowIdx = dataIndex" class="orderItemTD" [attr.colspan]="displayedOrderItems.length">
              <div class="example-element-detail" [@detailExpand]="orderItemExpanded == orderItem ? 'expanded' : 'collapsed'">
                <div class="stock-items-container">
                  <app-bp-floor-order-item-stock-item-list [data]="orderItemStockItems"></app-bp-floor-order-item-stock-item-list>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedOrderItems"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedOrderItems;">
          </tr>
          <tr mat-footer-row *matFooterRowDef="displayedOrderItems"></tr>

          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" id="expandedRow" class="example-detail-row"></tr>

        </table>
      </div>

    </div>

    <div class="stock-item-order-item-container-row">
      <h2>Order Items To Link</h2>
      <div class="boardtallyrecord-order-item-row-container" *ngFor="let boardTallyRecordOrderItem of boardTallyRecordOrderItems">

        <div class="boardtallyrecord-order-item-product">{{boardTallyRecordOrderItem.txtOrderItemProductName}}</div>
        <div class="boardtallyrecord-order-item-profile">{{boardTallyRecordOrderItem.txtOrderItemProfileName}}</div>
        <div class="boardtallyrecord-order-item-width">{{boardTallyRecordOrderItem.fltOrderItemWidth}}</div>
        <div class="boardtallyrecord-order-item-separator">x</div>
        <div class="boardtallyrecord-order-item-thickness">{{boardTallyRecordOrderItem.fltOrderItemThickness}}</div>
        <div class="boardtallyrecord-order-item-species">{{boardTallyRecordOrderItem.txtOrderItemSpeciesName}}</div>
        <div class="boardtallyrecord-order-item-grade">{{boardTallyRecordOrderItem.txtOrderItemGradeName}}</div>
        <div class="boardtallyrecord-order-item-quantity">{{boardTallyRecordOrderItem.fltBTOrderItemQuantity}}</div>
        <div class="boardtallyrecord-order-item-at">&#64;</div>
        <div class="boardtallyrecord-order-item-length">{{boardTallyRecordOrderItem.fltOrderItemLength}}</div>
      </div>
    </div>

  </div>

  <div class="button-container">
    <button class="cancel-button" type="button" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
    <div matTooltip="Please select a moulder" [matTooltipDisabled]="selectedMachineMoulderObjs.length > 0">
      <button class="save-button" type="button" mat-flat-button color="primary" (click)="save()" [disabled]="selectedMachineMoulderObjs.length == 0">Save</button>
    </div>
  </div>
</div>
