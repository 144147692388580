import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoDeliveryDocketOrderStockItem } from '../business-objects/dto-delivery-docket-order-stock-item.bo';

@Injectable()
export class dtoDeliveryDocketOrderStockItemService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoDeliveryDocketOrderStockItem(dtoDeliveryDocketOrderStockItemId: string) {
		return this.http.get<dtoDeliveryDocketOrderStockItem>(this._baseUrl + 'api/dtoDeliveryDocketOrderStockItem/' + dtoDeliveryDocketOrderStockItemId);
	}

	getdtoDeliveryDocketOrderStockItemParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoDeliveryDocketOrderStockItem[]>(this._baseUrl + 'api/dtoDeliveryDocketOrderStockItem/GetdtoDeliveryDocketOrderStockItemsParamArray/', aParam);
	}

	getAlldtoDeliveryDocketOrderStockItems() {
		return this.http.get<dtoDeliveryDocketOrderStockItem[]>(this._baseUrl + 'api/dtoDeliveryDocketOrderStockItem/GetAlldtoDeliveryDocketOrderStockItems/');
	}

	//getAlldtodtoDeliveryDocketOrderStockItems() {
	//	return this.http.get<dtodtoDeliveryDocketOrderStockItem[]>(this._baseUrl + 'api/dtoDeliveryDocketOrderStockItem/GetAlldtoDeliveryDocketOrderStockItems/');
	//}

	createdtoDeliveryDocketOrderStockItem(dtoDeliveryDocketOrderStockItem: dtoDeliveryDocketOrderStockItem) {
		return this.http.post<dtoDeliveryDocketOrderStockItem>(this._baseUrl + 'api/dtoDeliveryDocketOrderStockItem/CreatedtoDeliveryDocketOrderStockItem/', dtoDeliveryDocketOrderStockItem);
	}

	updatedtoDeliveryDocketOrderStockItem(dtoDeliveryDocketOrderStockItem: dtoDeliveryDocketOrderStockItem) {
		return this.http.put<dtoDeliveryDocketOrderStockItem>(this._baseUrl + 'api/dtoDeliveryDocketOrderStockItem/UpdatedtoDeliveryDocketOrderStockItem/', dtoDeliveryDocketOrderStockItem);
	}

	deletedtoDeliveryDocketOrderStockItem(dtoDeliveryDocketOrderStockItemId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoDeliveryDocketOrderStockItem/DeletedtoDeliveryDocketOrderStockItem/' + dtoDeliveryDocketOrderStockItemId);
	}

	async getdtoDeliveryDocketOrderStockItemPromise(dtoDeliveryDocketOrderStockItemId: string) {
		return new Promise<dtoDeliveryDocketOrderStockItem>(resolve => {
			this.getdtoDeliveryDocketOrderStockItem(dtoDeliveryDocketOrderStockItemId).subscribe(dtoDeliveryDocketOrderStockItem => {
				resolve(dtoDeliveryDocketOrderStockItem);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDeliveryDocketOrderStockItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoDeliveryDocketOrderStockItemParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoDeliveryDocketOrderStockItem[]>(resolve => {
			this.getdtoDeliveryDocketOrderStockItemParamArray(aParam).subscribe(dtoDeliveryDocketOrderStockItems => {
				resolve(dtoDeliveryDocketOrderStockItems);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDeliveryDocketOrderStockItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoDeliveryDocketOrderStockItemPromise(dtoDeliveryDocketOrderStockItem: dtoDeliveryDocketOrderStockItem) {
		return new Promise<dtoDeliveryDocketOrderStockItem>(resolve => {
			this.updatedtoDeliveryDocketOrderStockItem(dtoDeliveryDocketOrderStockItem).subscribe(dtoDeliveryDocketOrderStockItem => {
				resolve(dtoDeliveryDocketOrderStockItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoDeliveryDocketOrderStockItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoDeliveryDocketOrderStockItemPromise(dtoDeliveryDocketOrderStockItem: dtoDeliveryDocketOrderStockItem) {
		return new Promise<dtoDeliveryDocketOrderStockItem>(resolve => {
			this.createdtoDeliveryDocketOrderStockItem(dtoDeliveryDocketOrderStockItem).subscribe(dtoDeliveryDocketOrderStockItem => {
				resolve(dtoDeliveryDocketOrderStockItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoDeliveryDocketOrderStockItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoDeliveryDocketOrderStockItemPromise(dtoDeliveryDocketOrderStockItemId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoDeliveryDocketOrderStockItem(dtoDeliveryDocketOrderStockItemId).subscribe(dtoDeliveryDocketOrderStockItem => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoDeliveryDocketOrderStockItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}