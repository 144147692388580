import { trigger, state, style, transition, animate, query, animateChild } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { dtoTPChargeItem } from '../../_shared/business-objects/dto-tpcharge-item.bo';
import { dtoTPCharge } from '../../_shared/business-objects/dto-tpcharge.bo';

@Component({
  selector: 'app-cca-charge-item-list',
  templateUrl: './cca-charge-item-list.component.html',
  styleUrl: './cca-charge-item-list.component.scss',
  //animations: [
  //  trigger('detailExpand', [
  //    state('collapsed', style({ height: '0px', minHeight: '0' })),
  //    state('expanded', style({ height: '*' })),
  //    transition('expanded <=> collapsed', [
  //      animate('425ms cubic-bezier(0.4, 0.0, 0.2, 1)'),  //225
  //      query('@*', animateChild(), { optional: true })
  //    ]),
  //  ]),
  //]
})
export class CcaChargeItemListComponent {

  @Input() datasource: MatTableDataSource<dtoTPChargeItem>;
  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() columnListId: string;

  selectedRow: string = "";

  chargeExpanded: dtoTPChargeItem;

  expandElementAndSetColor(tpChargeItem: dtoTPChargeItem, idx: number) {

    let div: HTMLDivElement = document.getElementById("locationrow" + idx.toString()) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(() => {
        if (div.getAttribute("data-dblclick") == "1") {
          this.setRowColor(tpChargeItem.rowguid);
          //window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 200);
    } else {
      div.removeAttribute("data-dblclick");
      this.expandElement(tpChargeItem, idx);
      this.selectedRow = tpChargeItem.rowguid;  //Always highlight row when double clicking.

      //window.alert('ondouble');
    }

  }


  async expandElement(tpCharge: dtoTPChargeItem, index: number) {

    this.chargeExpanded = this.chargeExpanded === tpCharge ? null : tpCharge;

    //await this.loadDeliveryDocketOrderItems(deliveryDocket);

    //await this.loadLinkedFinalItems(index)



  }


  setRowColor(deliveryDocketId: string) {

    if (this.selectedRow == "" || deliveryDocketId != this.selectedRow) {
      this.selectedRow = deliveryDocketId;
      return;
    }

    if (deliveryDocketId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }

  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      window.event.stopPropagation();
    }
  }

  doubleclick(idx: number) {
    let div: HTMLDivElement = document.getElementById("locationrow" + idx.toString()) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(function () {
        if (div.getAttribute("data-dblclick") == "1") {
          window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 300);
    } else {
      div.removeAttribute("data-dblclick");
      window.alert('ondouble');
    }
  }



}
