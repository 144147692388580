<div class="loading-docket-update-container">
  <div class="loading-docket-update-form-container">
    <form class="loading-docket-update-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="header-row-4">
            <div class="search-option-row-4">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
                <mat-label>Expected Loading Date</mat-label>
                <input id="dteExpectedLoadingDate" class="date-input expected-loading-date" #expectedLoadingDate matInput [matDatepicker]="pickerexpectedloading" formControlName="dteExpectedLoadingDate">
                <mat-datepicker-toggle matSuffix [for]="pickerexpectedloading"></mat-datepicker-toggle>
                <mat-datepicker #pickerexpectedloading></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="search-option-row-4">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
                <mat-label>Expected Delivery Date</mat-label>
                <input id="dteExpectedDeliveryDate" class="date-input expected-delivery-date" #expectedLoadingDate matInput [matDatepicker]="pickerexpecteddelivery" formControlName="dteExpectedDeliveryDate">
                <mat-datepicker-toggle matSuffix [for]="pickerexpecteddelivery"></mat-datepicker-toggle>
                <mat-datepicker #pickerexpecteddelivery></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4" *ngIf="false">
            <div class="search-option-row-4">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
                <mat-label>Loading Date</mat-label>
                <input id="dteDateLoaded" class="date-input date-loaded" #dateLoaded matInput [matDatepicker]="pickerloaded" formControlName="dteDateLoaded">
                <mat-datepicker-toggle matSuffix [for]="pickerloaded"></mat-datepicker-toggle>
                <mat-datepicker #pickerloaded></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <div class="truck-trailer-grid-container">
                <div class="hauling-company-container">
                  <app-hauling-company-selection [selectedHaulingCompany]="selectedHaulingCompany" (haulingCompanySelectionEvent)="haulingCompanySelection($event)" (chhVehicleSelectionEvent)="chhVehicleSelection($event)"></app-hauling-company-selection>
                </div>
                <div class="driver-selection-container" [ngClass]="inDialog == true ? 'driver-selection-container-dialog' : 'driver-selection-container'">
                  <app-hauling-company-driver-selection #appHaulingCompanyDriverSelection [selectedHaulingCompanyDriver]="selectedHaulingCompanyDriver" [haulingCompanyId]="haulingCompanyId" (haulingCompanyDriverSelectionEvent)="haulingCompanyDriverSelection($event)"></app-hauling-company-driver-selection>
                </div>
                <div class="equipment-selection-container" [ngClass]="inDialog == true ? 'equipment-selection-container-dialog' : 'equipment-selection-container'">
                  <app-mobile-plant-equipment-selection #appMobilePlantEquipmentSelection [mobilePlantEquipmentObj]="trailerEquipment" [EquipmentCategory]="equipmentCategoryId" [chhVehicles]="chhVehicle" (equipmentSelectionEvent)="equipmentSelection($event)"></app-mobile-plant-equipment-selection>
                </div>
              </div>
              <input value="" type="hidden" formControlName="guHaulerId">
              <input value="" type="hidden" formControlName="guDriverId">
              <input value="" type="hidden" formControlName="guTrailerId">
            </div>
          </div>

 

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 100px !important" appearance="fill">
                <mat-label>Load</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 1" value="" type="number" formControlName="intLoadNo">
              </mat-form-field>
              Of
              <mat-form-field class="example-full-width header-button" style="width: 100px !important" appearance="fill">
                <mat-label>Loads</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 3" value="" type="number" formControlName="intMaxLoads">
              </mat-form-field>
            </div>
          </div>


          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>Yard Loading Location</mat-label>
              <mat-select formControlName="intYardSection">
                <mat-option *ngFor="let yardSection of yardSections" [value]="yardSection.intValue">{{yardSection.txtValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="loading-docket-comments">
            <mat-form-field class="example-full-width header-button delivery-docket-data" style="width: 500px !important" appearance="fill">
              <mat-label>Comments</mat-label>
              <textarea class="textarea-input disabled-input comments" matInput id="txtComments" formControlName="txtComments"></textarea>
            </mat-form-field>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
            </div>
          </div>

          <div class="action-button-container">
            <button class="action-button" mat-flat-button color="primary" (click)="updateLoadingDocket()">Update Loading Docket</button>
            <button class="action-button" mat-flat-button color="primary" (click)="cancelLoadingDocket()">Cancel</button>
          </div>
        </div>
        </div>
    </form>
  </div>
</div>

<div class="spinner-container">
  <mat-card [@displaySpinner]="transitionProducts ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showSpinner">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
