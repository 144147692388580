import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoDeliveryDocketOrderItem } from '../business-objects/dto-delivery-docket-order-item.bo';

@Injectable()
export class dtoDeliveryDocketOrderItemService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoDeliveryDocketOrderItem(dtoDeliveryDocketOrderItemId: string) {
		return this.http.get<dtoDeliveryDocketOrderItem>(this._baseUrl + 'api/dtoDeliveryDocketOrderItem/' + dtoDeliveryDocketOrderItemId);
	}

    getdtoDeliveryDocketOrderItemByDocketId(docketId: string) {
      return this.http.get<dtoDeliveryDocketOrderItem[]>(this._baseUrl + 'api/dtoDeliverydocketorderitem/GetdtoDeliveryDocketOrderItemByDocketId/' + docketId);
    }

    getBlankdtoDeliveryDocketOrderItem() {
      return this.http.get<dtoDeliveryDocketOrderItem>(this._baseUrl + 'api/dtoDeliverydocketorderitem/GetBlankdtoDeliveryDocketOrderItem/');
    }

	getdtoDeliveryDocketOrderItemParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoDeliveryDocketOrderItem[]>(this._baseUrl + 'api/dtoDeliveryDocketOrderItem/GetdtoDeliveryDocketOrderItemsParamArray/', aParam);
	}

	getAlldtoDeliveryDocketOrderItems() {
		return this.http.get<dtoDeliveryDocketOrderItem[]>(this._baseUrl + 'api/dtoDeliveryDocketOrderItem/GetAlldtoDeliveryDocketOrderItems/');
	}

	//getAlldtodtoDeliveryDocketOrderItems() {
	//	return this.http.get<dtodtoDeliveryDocketOrderItem[]>(this._baseUrl + 'api/dtoDeliveryDocketOrderItem/GetAlldtoDeliveryDocketOrderItems/');
	//}

	createdtoDeliveryDocketOrderItem(dtoDeliveryDocketOrderItem: dtoDeliveryDocketOrderItem) {
		return this.http.post<dtoDeliveryDocketOrderItem>(this._baseUrl + 'api/dtoDeliveryDocketOrderItem/CreatedtoDeliveryDocketOrderItem/', dtoDeliveryDocketOrderItem);
	}

	updatedtoDeliveryDocketOrderItem(dtoDeliveryDocketOrderItem: dtoDeliveryDocketOrderItem) {
		return this.http.put<dtoDeliveryDocketOrderItem>(this._baseUrl + 'api/dtoDeliveryDocketOrderItem/UpdatedtoDeliveryDocketOrderItem/', dtoDeliveryDocketOrderItem);
	}

	async getdtoDeliveryDocketOrderItemPromise(dtoDeliveryDocketOrderItemId: string) {
		return new Promise<dtoDeliveryDocketOrderItem>(resolve => {
			  this.getdtoDeliveryDocketOrderItem(dtoDeliveryDocketOrderItemId).subscribe(dtoDeliveryDocketOrderItem => {
				resolve(dtoDeliveryDocketOrderItem);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDeliveryDocketOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoDeliveryDocketOrderItemByDocketIdPromise(docketId: string) {
      return new Promise<dtoDeliveryDocketOrderItem[]>(resolve => {
        this.getdtoDeliveryDocketOrderItemByDocketId(docketId).subscribe(dtoDeliveryDocketOrderItems => {
          resolve(dtoDeliveryDocketOrderItems);
        }, err => {
          this.alertService.openSnackBarError("Error getting dtoDeliveryDocketOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
          resolve(null);
        });
      });
    }
  
  async getBlankdtoDeliveryDocketOrderItemPromise() {
    return new Promise<dtoDeliveryDocketOrderItem>(resolve => {
      this.getBlankdtoDeliveryDocketOrderItem().subscribe(dtoDeliveryDocketOrderItems => {
        resolve(dtoDeliveryDocketOrderItems);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoDeliveryDocketOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getdtoDeliveryDocketOrderItemParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoDeliveryDocketOrderItem[]>(resolve => {
			this.getdtoDeliveryDocketOrderItemParamArray(aParam).subscribe(dtoDeliveryDocketOrderItems => {
				resolve(dtoDeliveryDocketOrderItems);
			}, err => {
        this.alertService.openSnackBarError("Error getting dtoDeliveryDocketOrderItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

    
	async updatedtoDeliveryDocketOrderItemPromise(dtoDeliveryDocketOrderItem: dtoDeliveryDocketOrderItem) {
		return new Promise<dtoDeliveryDocketOrderItem>(resolve => {
			this.updatedtoDeliveryDocketOrderItem(dtoDeliveryDocketOrderItem).subscribe(dtoDeliveryDocketOrderItem => {
				resolve(dtoDeliveryDocketOrderItem);
			}, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating dtoDeliveryDocketOrderItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoDeliveryDocketOrderItemPromise(dtoDeliveryDocketOrderItem: dtoDeliveryDocketOrderItem) {
		return new Promise<dtoDeliveryDocketOrderItem>(resolve => {
			this.createdtoDeliveryDocketOrderItem(dtoDeliveryDocketOrderItem).subscribe(dtoDeliveryDocketOrderItem => {
				resolve(dtoDeliveryDocketOrderItem);
			}, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating dtoDeliveryDocketOrderItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
  }



}
