import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoOrdersDeliveryRate } from '../business-objects/dto-orders-delivery-rate.bo';

@Injectable()
export class dtoOrdersDeliveryRateService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoOrdersDeliveryRate(dtoOrdersDeliveryRateId: string) {
		return this.http.get<dtoOrdersDeliveryRate>(this._baseUrl + 'api/dtoOrdersDeliveryRate/' + dtoOrdersDeliveryRateId);
	}

	getdtoOrdersDeliveryRateParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoOrdersDeliveryRate[]>(this._baseUrl + 'api/dtoOrdersDeliveryRate/GetdtoOrdersDeliveryRatesParamArray/', aParam);
	}

	getAlldtoOrdersDeliveryRates() {
		return this.http.get<dtoOrdersDeliveryRate[]>(this._baseUrl + 'api/dtoOrdersDeliveryRate/GetAlldtoOrdersDeliveryRates/');
	}

	//getAlldtodtoOrdersDeliveryRates() {
	//	return this.http.get<dtodtoOrdersDeliveryRate[]>(this._baseUrl + 'api/dtoOrdersDeliveryRate/GetAlldtoOrdersDeliveryRates/');
	//}

	createdtoOrdersDeliveryRate(dtoOrdersDeliveryRate: dtoOrdersDeliveryRate) {
		return this.http.post<dtoOrdersDeliveryRate>(this._baseUrl + 'api/dtoOrdersDeliveryRate/CreatedtoOrdersDeliveryRate/', dtoOrdersDeliveryRate);
	}

	updatedtoOrdersDeliveryRate(dtoOrdersDeliveryRate: dtoOrdersDeliveryRate) {
		return this.http.put<dtoOrdersDeliveryRate>(this._baseUrl + 'api/dtoOrdersDeliveryRate/UpdatedtoOrdersDeliveryRate/', dtoOrdersDeliveryRate);
	}

	async getdtoOrdersDeliveryRatePromise(dtoOrdersDeliveryRateId: string) {
		return new Promise<dtoOrdersDeliveryRate>(resolve => {
			this.getdtoOrdersDeliveryRate(dtoOrdersDeliveryRateId).subscribe(dtoOrdersDeliveryRate => {
				resolve(dtoOrdersDeliveryRate);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoOrdersDeliveryRate records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoOrdersDeliveryRateParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoOrdersDeliveryRate[]>(resolve => {
			this.getdtoOrdersDeliveryRateParamArray(aParam).subscribe(dtoOrdersDeliveryRates => {
				resolve(dtoOrdersDeliveryRates);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoOrdersDeliveryRate records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoOrdersDeliveryRatePromise(dtoOrdersDeliveryRate: dtoOrdersDeliveryRate) {
		return new Promise<dtoOrdersDeliveryRate>(resolve => {
			this.updatedtoOrdersDeliveryRate(dtoOrdersDeliveryRate).subscribe(dtoOrdersDeliveryRate => {
				resolve(dtoOrdersDeliveryRate);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoOrdersDeliveryRate record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoOrdersDeliveryRatePromise(dtoOrdersDeliveryRate: dtoOrdersDeliveryRate) {
		return new Promise<dtoOrdersDeliveryRate>(resolve => {
			this.createdtoOrdersDeliveryRate(dtoOrdersDeliveryRate).subscribe(dtoOrdersDeliveryRate => {
				resolve(dtoOrdersDeliveryRate);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoOrdersDeliveryRate record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}