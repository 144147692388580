import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { dtoOrders } from '../../_shared/business-objects/dto-orders.bo';
import { dtoProductType } from '../../_shared/business-objects/dto-product-type.bo';
import { dtoSpecies } from '../../_shared/business-objects/dto-species.bo';
import { dtoTPChargeItem } from '../../_shared/business-objects/dto-tpcharge-item.bo';
import { dtoTPCharge } from '../../_shared/business-objects/dto-tpcharge.bo';
import { Orders } from '../../_shared/business-objects/orders.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { SQLParamService } from '../../_shared/services/app-services/sql-param.service';
import { dtoTPChargeItemService } from '../../_shared/services/dto-tpcharge-item.service';
import { dtoTPChargeService } from '../../_shared/services/dto-tpcharge.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

export enum enChargeTabs { Charges = 0, SearchOptions = 1, CreateCharge = 2, UpdateCharge = 3 }

@Component({
  selector: 'app-cca-charge-search',
  templateUrl: './cca-charge-search.component.html',
  styleUrl: './cca-charge-search.component.scss',
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class CcaChargeSearchComponent {

  @ViewChild('tabCharges', { static: false }) tabCharges: MatTabGroup;

  form: FormGroup;
  tpCharges: MatTableDataSource<dtoTPCharge>;
  productTypes: dtoProductType[];
  selectedProd: dtoProductType[];
  species: dtoSpecies[];
  selectedSpec: dtoSpecies[];
  treatmentHazardLevels: dtoIntKeyValue[];

  years: dtoIntKeyValue[] = [];

  public eChargeTabs = enChargeTabs;
  selectedTab: number;

  showSpinner: boolean = false;

  aChargeParamList: SQLParamArray[][];


  constructor(private formService: FormService, private fb: FormBuilder, private sharedService: SharedService, private dtoTPChargeService: dtoTPChargeService
    , private dtoTPChargeItemService: dtoTPChargeItemService, private alertService: AlertService
    , private sqlParamService: SQLParamService, private dialog: MatDialog) {

    this.form = this.fb.group({
      txtSearchChargeNo: [''],
      txtSearchChargeYear: [''],
      dteSearchChargedDateFrom: [''],
      dteSearchChargedDateTo: [''],
      txtSearchPoleNumber: [''],
      txtSearchConsecutiveNumber: [''],
      txtSearchDiameter: [''],
      intSearchTreatmentHazardLevel: [''],
      txtSearchChargeComments: ['']
    });

  }


  ngOnInit() {

    document.title = "CCA Charge";

    this.tpCharges = new MatTableDataSource<dtoTPCharge>();
    let currentYear: number = new Date().getFullYear();
    let years: number[];
    for (let i = currentYear; i >= 2004; i--) {
      this.years.push(new dtoIntKeyValue(i, i.toString()));
    }

    this.years.splice(0, 0, new dtoIntKeyValue(0, ""));

    this.loadData();

    this.treatmentHazardLevels = this.sharedService.getEnumObjectKeyValue(SharedService.enHazardTreatment, true).filter((a) => { return a.txtValue != "None" });;
    this.treatmentHazardLevels.sort((a, b) => { return a.txtValue > b.txtValue ? 1 : -1 });

  }

  async loadData() {

  }


  async searchCharges(type: string, top: number) {

    this.aChargeParamList = [];
    let txtSearchChargeNo: string = this.form.controls['txtSearchChargeNo'].value;
    let txtSearchChargeYear: string = this.form.controls['txtSearchChargeYear'].value;
    let dteSearchChargedDateFrom: string = this.form.controls['dteSearchChargedDateFrom'].value;
    let dteSearchChargedDateTo: string = this.form.controls['dteSearchChargedDateTo'].value;
    let txtSearchPoleNumber: string = this.form.controls['txtSearchPoleNumber'].value;
    let txtSearchConsecutiveNumber: string = this.form.controls['txtSearchConsecutiveNumber'].value;
    let txtSearchDiameter: string = this.form.controls['txtSearchDiameter'].value;
    let intSearchTreatmentHazardLevel: string = this.form.controls['intSearchTreatmentHazardLevel'].value;
    let txtSearchChargeComments: string = this.form.controls['txtSearchChargeComments'].value

    const datepipe: DatePipe = new DatePipe('en-AU');

    console.log("this.aChargeParamList", this.aChargeParamList);
    console.log("txtSearchChargeNo", txtSearchChargeNo);
    this.sqlParamService.addParameter(this.aChargeParamList, txtSearchChargeNo, "intChargeNo");
    this.sqlParamService.addParameter(this.aChargeParamList, txtSearchChargeYear, "intChargeYear");
    this.sqlParamService.addParameter(this.aChargeParamList, datepipe.transform(dteSearchChargedDateFrom, 'yyyy-MM-dd'), "dteChargeDate", false, SQLParamArray.enSQLOperator.GreaterThanOrEqualTo);
    this.sqlParamService.addParameter(this.aChargeParamList, datepipe.transform(dteSearchChargedDateTo, 'yyyy-MM-dd'), "dteChargeDate", false, SQLParamArray.enSQLOperator.LessThanOrEqualTo);
    this.sqlParamService.addParameter(this.aChargeParamList, txtSearchPoleNumber, "txtPoleNumber");
    this.sqlParamService.addParameter(this.aChargeParamList, txtSearchConsecutiveNumber, "intConsecutiveNumber");
    this.sqlParamService.addParameter(this.aChargeParamList, txtSearchDiameter, "fltDiameter");
    this.sqlParamService.addParameter(this.aChargeParamList, intSearchTreatmentHazardLevel, "intHazardLevel");
    this.sqlParamService.addParameter(this.aChargeParamList, txtSearchChargeComments, "txtComments");

    let productTypeIds: string[] = this.selectedProd != null ? this.selectedProd.map((a) => { return a.rowguid }) : [];
    this.sqlParamService.addParameter(this.aChargeParamList, productTypeIds, "guProductTypeId", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");

    let speciesIds: string[] = this.selectedSpec != null ? this.selectedSpec.map((a) => { return a.rowguid }) : [];
    this.sqlParamService.addParameter(this.aChargeParamList, speciesIds, "guSpeciesId", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");

    console.log("top: ", top);

    this.tpCharges.data = await this.dtoTPChargeService.getdtoTPChargeParamArrayPromise(this.aChargeParamList, top);
    
    if (this.tabCharges.selectedIndex != 0) {
      this.formService.setAutoFocusTimeout("btnSearchOptionsButton");

      this.tabCharges.selectedIndex = 0;

    }
    
  }

  enterKeySearch(type: string, val) {

  }

  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    console.log(e.index == this.eChargeTabs.SearchOptions);

    if (e.index == this.eChargeTabs.SearchOptions) {
      //this.formService.setAutoFocusTimeout("txtSearchChargeNo");
    }

    if (e.index == this.eChargeTabs.Charges) {
      this.formService.setAutoFocusTimeout("txtSearchChargeNo");

    }

  }

  editCharge(tpCharge: dtoTPCharge) {

  }

  addChargeItems(tpChargeItems: dtoTPChargeItem[]) {

  }

  updatedCharge(tpCharge: dtoTPCharge) {

  }

  removeCharge(val: boolean) {

  }


  selectedProductTypes(productTypes: dtoProductType[]) {

  }

  selectedSpecies(species: dtoSpecies[]) {

  }

}
