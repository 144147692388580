import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { dtoCustomer } from '../../_shared/business-objects/dto-customer.bo';
import { Client } from '../../_shared/business-objects/client.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { ClientService } from '../../_shared/services/client.service';
import { dtoCustomerService } from '../../_shared/services/dto-customer.service';
import { CustomerUpdateDialogComponent } from '../customer-update-dialog/customer-update-dialog.component';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { dtoCustomerContactService } from '../../_shared/services/dto-customer-contact.service';
import { dtoCustomerContact } from '../../_shared/business-objects/dto-customer-contact.bo';
import { CustomerContactCreateDialogComponent } from '../../customer-contact/customer-contact-create-dialog/customer-contact-create-dialog.component';
import { CustomerContactUpdateDialogComponent } from '../../customer-contact/customer-contact-update-dialog/customer-contact-update-dialog.component';
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';
import { CustomerContactService } from '../../_shared/services/customer-contact.service';
import { CustomerCreateDialogComponent } from '../customer-create-dialog/customer-create-dialog.component';
import { FormService } from '../../_shared/services/form.service';
import { MatIcon } from '@angular/material/icon';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';
import { AuthService } from '../../account/auth.service';
import { SendMailDialogComponent } from '../../email/send-mail-dialog/send-mail-dialog.component';
import { EmailService } from '../../_shared/services/email.service';

@Component({
  selector: 'app-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrl: './customer-select.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CustomerSelectComponent implements OnInit {

  @ViewChild('customerFilter') customerFilter: ElementRef;
  @ViewChild('customerListContainer') customerListContainer: ElementRef;
  @ViewChild('multipleCustomers') multipleCustomers: MatSlideToggle;

  
  @Input() customers: dtoCustomer[];
  @Input() showMultiple: boolean;
  @Output() selectedCustomersEvent = new EventEmitter<dtoCustomer[]>();
  @Output() selectedCustomerContactsEvent = new EventEmitter <{ customerContacts: dtoCustomerContact[], customers: dtoCustomer[]}>();

  showSpinner: boolean
  addressHoverId: string;
  contactHoverId: string;

  customerUpdateDialogRef: MatDialogRef<CustomerUpdateDialogComponent>;
  selectedCustomers: dtoCustomer[];
  customersFiltered: dtoCustomer[];
  customerExpanded: dtoCustomer;
  customerContacts: dtoCustomerContact[];

  customerFilteredItems: HTMLCollectionOf<HTMLDivElement>;

  sendMailDialogRef: MatDialogRef<SendMailDialogComponent>

  showCustomerList: boolean = false;
  showCustomerListTransition: boolean = false;
  customerChipSelected: boolean = false;

  customerContactCreateDialogRef: MatDialogRef<CustomerContactCreateDialogComponent>
  customerContactUpdateDialogRef: MatDialogRef<CustomerContactUpdateDialogComponent>
  customerCreateDialogRef: MatDialogRef<CustomerCreateDialogComponent>

  customerSelectionExpanded: boolean = false;

  customerListPinned: boolean = false;
  toolTipText: string = "pin";

  constructor(private customerService: ClientService, private dtoCustomerService: dtoCustomerService
    , private alertService: AlertService, private dialog: MatDialog, private dtocustomerContactService: dtoCustomerContactService
    , private customerContactService: CustomerContactService, private formService: FormService, private authService: AuthService
    , private emailService: EmailService) {

  }

  ngOnInit() {
    this.selectedCustomers = [];

    document.addEventListener("click", this.openCloseCustomerList.bind(this));

  }

  checkCustomerListIsOpen(inputValue: string) {

    //console.log("inputValue", inputValue);

    if (inputValue == null || inputValue == "") {
      this.showCustomerList = false;
      //console.log("inputValue empty", inputValue);
      return;
    }

    this.showCustomerList = true;

  }

  openCloseCustomerList(e) {
    console.log("customer open close customer", e);

    if (this.customerListPinned == true) {
      return;
    }

    if (this.customerChipSelected == true) {
      this.customerChipSelected = false;
      return;
    }

    //
    // If you click on the add customer contact button exit function
    if (e != null && e.target != null && e.target.dataset != null && (e.target.dataset.customerContactAdd != null || e.target.dataset.customerContactUpdate != null)) {   // || e.target.dataset.customerContactList != null
      //console.log("clicked on expand button");

      return;

    }


    // If you click on the expanded button exit function
    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.customerExpand != null) {
      //console.log("clicked on expand button");

      return;

    }

    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.customerInput != null && e.target.dataset.customerInput == "customerInput" && this.customerFilter.nativeElement.value != null && this.customerFilter.nativeElement.value.toString() != "") {
      this.showCustomerList = true;
      //console.log("this.showCustomerList = true", this.showCustomerList);
      //console.log("customerListContainer", this.customerFilter.nativeElement.value);

      return;
    }

    //if (e == null || e.target == null || e.target.dataset == null || e.target.dataset.customerSelect == null) {
    // ALWAYS CLOSE THE SEARCH LIST UNLESS I'M PINNED
    if (this.customerListPinned == false) {

      this.showCustomerList = false;

      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      //console.log("this.showCustomerList = false", this.showCustomerList);
      return;
    }

    //let domStringMap: DOMStringMap = e.target.dataset;

    //console.log(domStringMap.customerSelect.toString() == "customerSelect");
    //console.log(domStringMap.customerSelect.toString());
  }


  rowEnter(customerId: string) {
    this.addressHoverId = customerId;
    //console.log("row enter");
  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");
  }

  contactRowEnter(contactId: string) {
    this.contactHoverId = contactId;
  }

  contactRowLeave() {
    this.contactHoverId = "";
  }

  async selectCustomer(customer: dtoCustomer, selected: boolean) {

    if (customer == null) {
      this.alertService.openSnackBarDefault("There was a problem selecting the customer - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    // Remove All Customer Contact
    if (this.multipleCustomers == null || (this.multipleCustomers != null && this.multipleCustomers.checked == false)) {

      let customerSelected: dtoCustomer[] = this.customers.filter((a) => { return a.guSelectedCustomerId != null && a.guSelectedCustomerId != "" });
      for (let i = 0; i <= customerSelected.length - 1; i++) {
        customerSelected[i].guSelectedCustomerId = "";
      }

      for (let i = this.selectedCustomers.length - 1; i >= 0; i--) {
        this.selectedCustomers.splice(i, 1);
      }

      for (let i = this.customersFiltered.length - 1; i >= 0; i--) {
        this.customersFiltered[i].guSelectedCustomerId = "";
      }

    }

    // Remove Customer Contact
    if (selected == false) {
      for (let i = this.selectedCustomers.length - 1; i >= 0; i--) {
        if (this.selectedCustomers[i].rowguid == customer.rowguid) {
          this.selectedCustomers.splice(i, 1);
          customer.guSelectedCustomerId = "";
          //console.log("unselected customer");
          this.selectedCustomersEvent.emit(this.selectedCustomers);
          break;
        }
      }
    }

    // Add Customer Contact
    if (selected == true) {

      customer.guSelectedCustomerId = "tempvalue";

      this.selectedCustomers.push(customer);
      this.selectedCustomersEvent.emit(this.selectedCustomers);

      if (this.multipleCustomers != null && this.multipleCustomers.checked == false && this.customerListPinned == false) {
        this.showCustomerList = false;
      }

      //console.log("added customer");

    }

    //this.getCustomerContacts(this.deliveryDocket.guCustomerId);

  }

  async updateCustomer(customer: dtoCustomer) {

    
    if (customer == null) {
      this.alertService.openSnackBarDefault("There is no customer to edit");
      return;
    }

    if (this.customerUpdateDialogRef != null) {
      this.customerUpdateDialogRef.close();
    }

    this.customerUpdateDialogRef = this.dialog.open(CustomerUpdateDialogComponent, {
      hasBackdrop: false,
      height: '96vh',
      width: 'max-content',
      minWidth: '720px',
      maxWidth: '90vw',
      data: { selectedCustomer: customer }
    });

    this.customerUpdateDialogRef.backdropClick().subscribe(() => {
      this.customerUpdateDialogRef.close();
    });


    this.customerUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, customer: dtoCustomer }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        if (data.status != "Cancel") {
          //let cust: dtoCustomer = await this.dtoCustomerService.getdtoCustomerPromise(data.customer.rowguid);
          //this.selectedCustomers.push(cust);


          for (let i = 0; i <= this.customers.length - 1; i++) {
            if (this.customers[i].rowguid == data.customer.rowguid) {
              console.log("data.customer", data.customer);
              this.customers[i] = data.customer;
              //this.customers[i].guSelectedCustomerId = "tempvalue";
              break;
            }
          }

          for (let i = 0; i <= this.customersFiltered.length - 1; i++) {
            if (this.customersFiltered[i].rowguid == data.customer.rowguid) {
              console.log("data.customer", data.customer);
              this.customersFiltered[i] = data.customer;
              //this.customers[i].guSelectedCustomerId = "tempvalue";
              break;
            }
          }

          this.customers.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

          //this.alertService.openSnackBarDefault("The Customer has been updated");

          this.showCustomerList = true;

        }
        else {
          // We have cancellled

        }

      });

  }

  async addCustomer() {
    if (this.customerCreateDialogRef != null) {
      this.customerCreateDialogRef.close();
    }

    this.customerCreateDialogRef = this.dialog.open(CustomerCreateDialogComponent, {
      hasBackdrop: false,
      height: '96vh',
      width: 'max-content',
      minWidth: '720px',
      maxWidth: '90vw'
    });

    this.customerCreateDialogRef.backdropClick().subscribe(() => {
      this.customerCreateDialogRef.close();
    });


    this.customerCreateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, customer: dtoCustomer }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {

          if (data.status = "") {

          }
          this.showCustomerList = true;
          this.customers.push(data.customer);
          this.getCustomers(data.customer.txtName);

          //this.alertService.openSnackBarDefault("The Customer has been created");

        }
        else {
          // We have cancellled but still show customer list since it was open.
          //this.showCustomerList = true;

        }

      });
  }

  nextCustomer(e, customer: dtoCustomer) {

    //console.log("e ", e);

    if (e.code.toLowerCase() == "escape") {
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      this.customerFilter.nativeElement.focus();
      this.customerFilter.nativeElement.setSelectionRange(this.customerFilter.nativeElement.value.length, this.customerFilter.nativeElement.value.length);

      this.showCustomerList = false;
      return;
    }

    if ((e.code.toLowerCase() == "arrowdown" || e.code.toLowerCase() == "arrowup") && this.showCustomerList == true) {
      //console.log("e ", e);

      if (this.customerFilteredItems != null) {

        let tabIndex: number = e.target.tabIndex;

        let customerTown = document.getElementById("customerTown" + tabIndex);
        let customerUpdateDiv = document.getElementById("customerUpdateDiv" + tabIndex);
        let customerContactList = document.getElementById("customerContactList" + tabIndex);

        if (tabIndex < this.customerFilteredItems.length - 1 && e.code.toLowerCase() == "arrowdown") {

          let customerTownPlus = document.getElementById("customerTown" + (tabIndex + 1));
          let customerUpdateDivPlus = document.getElementById("customerUpdateDiv" + (tabIndex + 1));
          let customerContactListPlus = document.getElementById("customerContactList" + (tabIndex + 1));

          //console.log("e down ", tabIndex);
          //TODO
          // Set this.customerContainer.style overflow-y to hidden here
          let docBody = document.getElementById("rootBody");
          docBody.style.overflowY = "hidden";

          this.customerListContainer.nativeElement.classList.add("customer-container-no-scroll");

          this.customerFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          customerTown.classList.remove("chh-list-item-row-selected");
          customerUpdateDiv.classList.remove("chh-list-item-row-selected");
          customerContactList.classList.remove("chh-list-item-row-selected");

          this.customerFilteredItems[tabIndex + 1].focus();
          this.customerFilteredItems[tabIndex + 1].classList.add("chh-list-item-row-selected");
          customerTownPlus.classList.add("chh-list-item-row-selected");
          customerUpdateDivPlus.classList.add("chh-list-item-row-selected");
          customerContactListPlus.classList.add("chh-list-item-row-selected");

          // Set this.customerContainer.style overflow-y to scroll here
          this.customerListContainer.nativeElement.classList.remove("customer-container-no-scroll");

        }

        if (tabIndex >= 0 && e.code.toLowerCase() == "arrowup") {

          if (tabIndex == 0) {
            //console.log("up tabIndex ", tabIndex);

            this.customerFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
            customerTown.classList.remove("chh-list-item-row-selected");
            customerUpdateDiv.classList.remove("chh-list-item-row-selected");
            customerContactList.classList.remove("chh-list-item-row-selected");

            let docBody = document.getElementById("rootBody");
            docBody.style.overflowY = "scroll";

            this.customerFilter.nativeElement.focus();
            this.customerFilter.nativeElement.setSelectionRange(this.customerFilter.nativeElement.value.length, this.customerFilter.nativeElement.value.length);
            return;
          }


          let customerTownMinus = document.getElementById("customerTown" + (tabIndex - 1));
          let customerUpdateDivMinus = document.getElementById("customerUpdateDiv" + (tabIndex - 1));
          let customerContactListMinus = document.getElementById("customerContactList" + (tabIndex - 1));

          //console.log("e up ", tabIndex);

          this.customerListContainer.nativeElement.classList.add("customer-container-no-scroll");

          this.customerFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          customerTown.classList.remove("chh-list-item-row-selected");
          customerUpdateDiv.classList.remove("chh-list-item-row-selected");
          customerContactList.classList.remove("chh-list-item-row-selected");

          this.customerFilteredItems[tabIndex - 1].focus();
          this.customerFilteredItems[tabIndex - 1].classList.add("chh-list-item-row-selected");
          customerTownMinus.classList.add("chh-list-item-row-selected");
          customerUpdateDivMinus.classList.add("chh-list-item-row-selected");
          customerContactListMinus.classList.add("chh-list-item-row-selected");

          this.customerListContainer.nativeElement.classList.remove("customer-container-no-scroll");


        }

      }
      //console.log("nextCustomer");

    }

    if (e.code.toLowerCase() == "enter") {
      this.selectCustomer(customer, !(customer.guSelectedCustomerId != ''));
    }

  }

  filterCustomers(customerFilter: string, e) {


    if (e.code.toLowerCase() == "arrowdown" && this.showCustomerList == true) {
      //console.log("e ", e);
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "hidden";

      this.customerFilteredItems = document.getElementsByClassName("customer-name") as HTMLCollectionOf<HTMLDivElement>;
      if (this.customerFilteredItems != null && this.customerFilteredItems.length > 0) {
        

        this.customerFilteredItems[0].classList.add("chh-list-item-row-selected");
        let customerTown = document.getElementById("customerTown0");
        let customerUpdateDiv = document.getElementById("customerUpdateDiv0");
        let customerContactList = document.getElementById("customerContactList0");

        customerTown.classList.add("chh-list-item-row-selected");
        customerUpdateDiv.classList.add("chh-list-item-row-selected");
        customerContactList.classList.add("chh-list-item-row-selected");

        this.customerFilteredItems[0].focus();
        //console.log("filterCustomer TabIndex: ", this.customerFilteredItems[0].tabIndex);

      }

      //console.log("customerElements", this.customerFilteredItems);

    }

    this.getCustomers(customerFilter);



  }

  getCustomers(customerFilter: string) {

    this.customersFiltered = this.customers;

    if (customerFilter == null || customerFilter == "") {
      this.customersFiltered = [];
      this.showCustomerList = false;
      return;
    }



    let customers: dtoCustomer[] = this.customers;

    customers = customers.filter(a => ((a.txtName.toLowerCase().indexOf(customerFilter.toLowerCase()) > -1)));

    customers = customers.slice(0, 50);

    customers.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

    this.customersFiltered = customers;

    this.showCustomerList = true;

    this.setCustomerListTranition(1000);

  }

  setCustomerListTranition(delay: number) {

    setTimeout(() => {
      this.showCustomerListTransition = true;
    }, delay);

  }

  checkMultipleCustomers(multipleCustomers: boolean) {

    if (multipleCustomers == false) {
      //Remove multiple selected customers so only one remains.
      let multipleCust: dtoCustomer[] = this.customers.filter((a) => { return a.guSelectedCustomerId != null && a.guSelectedCustomerId != "" });
      if (multipleCust != null && multipleCust.length > 0) {

        multipleCust.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

        for (let i = multipleCust.length - 1; i >= 1; i--) {
          multipleCust.pop();
        }

        for (let i = 0; i <= this.customers.length - 1; i++) {
          if (multipleCust[0].rowguid != this.customers[i].rowguid) {
            this.customers[i].guSelectedCustomerId = "";
          }
        }

        this.selectedCustomers.length = 0;
        this.selectedCustomers.push(multipleCust[0]);
        this.selectedCustomersEvent.emit(this.selectedCustomers);

      }

    }
  }

  async expandElement(customer: dtoCustomer, e, selected: boolean) {

    if (this.customerExpanded != null && this.customerExpanded.rowguid != customer.rowguid) {
      // We have clicked on a different row.
      this.customerExpanded = null;
    }

    if (selected == false) {
      //let contacts: dtoCustomerContact[] = await this.customerContactService.getdtoCustomerContactByCustomerId(customer.rowguid);
      //this.customerContacts = contacts.sort((a, b) => { return (a.txtLastName + ' ' + a.txtName > b.txtLastName + ' ' + b.txtName ? 1 : -1) });
      this.getCustomerContacts(customer.rowguid);

      this.customerExpanded = customer;
    }

    if (selected == true) {
      this.customerExpanded = null;
      this.customerContacts = null;
    }

  }

  async getCustomerContacts(customerId: string) {

    let contacts: dtoCustomerContact[] = await this.dtocustomerContactService.getdtoCustomerContactByCustomerId(customerId);
    this.customerContacts = contacts.sort((a, b) => { return (a.txtLastName + ' ' + a.txtName > b.txtLastName + ' ' + b.txtName ? 1 : -1) });

  }


  addCustomerContact(customer: dtoCustomer) {
    if (this.customerContactCreateDialogRef != null) {
      this.customerContactCreateDialogRef.close();
    }


    this.customerContactCreateDialogRef = this.dialog.open(CustomerContactCreateDialogComponent, {
      hasBackdrop: false,
      height: '95vh',
      maxWidth: '90vw',
      data: { customerId: customer.rowguid, customerName: customer.txtName }
    });

    this.customerContactCreateDialogRef.backdropClick().subscribe(() => {
      this.customerContactCreateDialogRef.close();
    });


    this.customerContactCreateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, customerContact: CustomerContact }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          this.showCustomerList = true;
          this.getCustomerContacts(data.customerContact.guCustomerId);

          this.alertService.openSnackBarDefault("The Customer Contact has been added");

        }
        else {
          // We have cancellled but still show customer list since it was open.
          this.showCustomerList = true;

        }

      });

  }

  async updateCustomerContact(customerContact: dtoCustomerContact) {
    if (this.customerContactUpdateDialogRef != null) {
      this.customerContactUpdateDialogRef.close();
    }

    let custContact: CustomerContact = await this.customerContactService.getCustomerContactPromise(customerContact.rowguid);

    this.customerContactUpdateDialogRef = this.dialog.open(CustomerContactUpdateDialogComponent, {
      hasBackdrop: false,
      height: '95vh',
      maxWidth: '90vw',
      data: { customerId: custContact.guCustomerId, customerName: customerContact.txtCustomerName, selectedCustomerContact: custContact }
    });

    this.customerContactUpdateDialogRef.backdropClick().subscribe(() => {
      this.customerContactUpdateDialogRef.close();
    });


    this.customerContactUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, customerContact: CustomerContact }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          this.showCustomerList = true;
          this.getCustomerContacts(data.customerContact.guCustomerId);

          this.alertService.openSnackBarDefault("The Customer Contact has been updated");

        }
        else {
          // We have cancellled but still show customer list since it was open.
          this.showCustomerList = true;

        }

      });

  }

  removeCustomer(customer: dtoCustomer) {

    if (customer == null) {
      return;
    }

    for (let i = this.selectedCustomers.length - 1; i >= 0; i--) {
      if (this.selectedCustomers[i].rowguid == customer.rowguid) {
        this.selectedCustomers.splice(i, 1);
        customer.guSelectedCustomerId = "";
        //console.log("unselected customer");
        this.selectedCustomersEvent.emit(this.selectedCustomers);
        break;
      }
    }


  }

  selectChipCustomer(e, customer: dtoCustomer) {

    this.getCustomers(customer.txtName);

    this.customerFilter.nativeElement.value = customer.txtName;
    this.customerChipSelected = true;
    this.showCustomerList = true;

  }

  expandSelectedCustomers(e: PointerEvent) {


    if (e.pointerType == null || e.pointerType == "") {
      return;
    }

    this.customerSelectionExpanded = !this.customerSelectionExpanded;

    let selectedCustomers: HTMLDivElement = document.getElementById("selectedCustomers") as HTMLDivElement;
    selectedCustomers.classList.toggle("selected-customers-expanded");

    if (this.customerSelectionExpanded == true) {

    }

  }

  clickPin(e) {

    this.customerListPinned = !this.customerListPinned;

    if (this.customerListPinned == false) {
      this.showCustomerList = false;
    }

    this.toolTipText = this.customerListPinned == true ? 'unpin' : 'pin';


  }

  copyToClipboard(val: string) {
    this.formService.copyToClipboard(val);
    this.alertService.openSnackBar("The value " + val + " has been copied to the clipboard", "Close", "center", "bottom", "", 3000);
  }

  selectContact(customerContact: dtoCustomerContact, customer: dtoCustomer) {

    this.selectCustomer(customer, true);

    console.log("customerContact", customerContact);
    console.log("customer", customer);

    let customerAndContact: { customerContacts: dtoCustomerContact[], customers: dtoCustomer[] } = { customerContacts: [], customers: [] };
    customerAndContact.customerContacts = [];
    customerAndContact.customers = [];
    customerAndContact.customerContacts.push(customerContact);
    customerAndContact.customers.push(customer);

    console.log("customerAndContact", customerAndContact);

    this.selectedCustomerContactsEvent.emit(customerAndContact);

  }

  async sendEmail(email: string, emailDiv: HTMLDivElement) {

    this.emailService.openEmailDialog(email, "", "", "", emailDiv, null);

    //var offsets = emailDiv.getBoundingClientRect();
    //var top = offsets.top + window.scrollY - 175;
    //var left = offsets.left + window.scrollX - 25;

    ////console.log("top" + top + " - left: " + left);

    //if (this.sendMailDialogRef != null) {
    //  this.sendMailDialogRef.close();
    //}


    //let fileAttachments: FileAttachment[] = [];
    ////let fileAttachment: FileAttachment = await this.getPDFBase64(deliveryDocket);
    ////if (fileAttachment != null) {
    ////  fileAttachments.push(fileAttachment);
    ////}

    ////console.log(fileAttachments);
    //let username: string = (await this.authService.getUser()).displayName;
    //let toAddresses: string = email;
    //let ccAddresses: string = "";
    //let subject: string = "";
    //let message: string = "";

    //this.sendMailDialogRef = this.dialog.open(SendMailDialogComponent, {
    //  hasBackdrop: false,
    //  height: 'auto',
    //  maxWidth: '90vw',
    //  data: { toAddresses: toAddresses, ccAddresses: ccAddresses, bccAddresses: "", emailSubject: subject, emailMessage: message, fileAttachements: fileAttachments }
    //});

    //// -------------------------------------------------------------------------------
    //// COULD USE componentInstance FOR SOMTHING.
    ////this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    //// -------------------------------------------------------------------------------

    //this.sendMailDialogRef.backdropClick().subscribe(() => {
    //  this.sendMailDialogRef.close();
    //});


    //this.sendMailDialogRef
    //  .afterClosed()
    //  .subscribe(async (
    //    data: { status: string }) => {
    //    if (data == null || data == undefined) {
    //      //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
    //      return;
    //    }

    //    //console.log(data);
    //    if (data.status != "Cancel") {

    //    }
    //    else {
    //      // We have cancellled

    //    }

    //  });

  }


}
