<div class="stock-item-create-by-product-code-container">
  <div class="stock-item-create-by-product-code-form-container">
    <form class="stock-item-create-by-product-code-form" (keydown.enter)="$event.preventDefault()" [formGroup]="form">
      <div class="header-container">
        <div class="create-stock-fields-container">

          <div class="geo-location-header-row">
            <h6>Geo Location</h6>
            <mat-button-toggle-group #locationToggle name="fontStyle" aria-label="Font Style" formControlName="guGeoStockLocationId" color="accent">
              <mat-button-toggle *ngFor="let geoStockLocation of geoStockLocations" [value]="geoStockLocation.rowguid" [disabled]="defaultGeoLocation.rowguid != geoStockLocation.rowguid" [checked]="defaultGeoLocation.rowguid == geoStockLocation.rowguid">{{geoStockLocation.txtShortName}}</mat-button-toggle>
            </mat-button-toggle-group>
            <br />
            <br />
          </div>

          <div class="header-row-3">
            <div class="header-row-3-container">
              <div class="use-auto-number-checkbox"><mat-checkbox class="example-margin" #chkUseAutoNumbers id="chkUseAutoNumbers" [checked]="disablingIdentifier(chkUseAutoNumbers,txtIdentifier)" [disabled]="mode == 'additem'">Use Auto Numbers</mat-checkbox></div>
              <mat-form-field class="chh-form-field example-full-width example-container" appearance="fill">
                <mat-label>Identifier</mat-label>
                <input class="stock-identifier-input" matInput type="number" placeholder="" value="" #txtIdentifier id="txtIdentifier" formControlName="txtIdentifier" [attr.disabled]="(chkUseAutoNumbers.checked || mode == 'additem') ? true : null"  onKeyPress="if(this.value.length == 6) return false;">
                <mat-error *ngIf="false"></mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-3">
            <div class="header-row-3-container">
              <mat-form-field class="chh-form-field example-full-width example-container" appearance="fill">
                <mat-label>Location</mat-label>
                <input class="stock-location-input" mask="S-0-00" #txtLocation matInput placeholder="" value="" id="txtLocation" formControlName="txtLocation" (input)="txtLocation.value = txtLocation.value.toUpperCase()">
                <mat-error *ngIf="false"></mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field appearance="fill" class="chh-form-field">
                <mat-label>Moulder</mat-label>
                <mat-select formControlName="guMoulderId" [(ngModel)]="selectedMoulderValue">
                  <mat-option *ngFor="let moulder of moulders" [value]="moulder.rowguid">{{moulder.txtName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-5">
            <div class="header-row-5-container">
              <mat-form-field class="chh-form-field example-full-width example-container" appearance="fill">
                <mat-label>Quantity</mat-label>
                <input class="stock-location-input" matInput placeholder="" value="" id="fltQuantity" formControlName="fltQuantity">
                <mat-error *ngIf="false"></mat-error>
              </mat-form-field>
              <span class="quantity-uom" #quantityUOM></span>
            </div>
          </div>

          <div class="header-row-6">
            <div class="header-row-6-container">
              <div class="header-row-6-column-1">
                <mat-form-field class="chh-form-field example-full-width example-container sku-form-field" appearance="fill">
                  <mat-label>SKU</mat-label>
                  <input class="stock-sku-input" matInput #txtSKU placeholder="" value="" id="txtSKU" formControlName="txtSKU">
                  <mat-error *ngIf="false"></mat-error>
                </mat-form-field>
              </div>
              <button class="stocktakeButton" mat-flat-button color="primary" (click)="createStock(chkUseAutoNumbers.checked == true)">Create Stock Item</button>
            </div>
          </div>
        </div>
        <div class="search-sku-container">
          <div class="search-sku" *ngIf="false">
            <div class="header-row-6">
              <div class="header-row-6-container">
                <div class="header-row-6-column-1">
                  <mat-form-field class="chh-form-field example-full-width example-container" appearance="fill">
                    <mat-label>Search SKU</mat-label>
                    <input class="stock-sku-input" matInput placeholder="" value="" #txtProductCodeSearch id="txtSearchSKU" formControlName="txtSearchSKU" (input)="getProductCodes(txtProductCodeSearch.value)">
                    <mat-error *ngIf="false"></mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="search-sku-list">
            <div class="search-product-type-container">
              <app-product-code-select [multipleCodes]="false" (selectedProductCodesEvent)="selectedProductCodes($event)"></app-product-code-select>
            </div>

            <mat-selection-list #productCodeList [multiple]="false" *ngIf="false">
              <mat-list-option class="sku-list-option" *ngFor="let productCode of productCodes" [value]="productCode.rowguid" (click)="txtSKU.value = productCode.txtStockKeepingUnit;quantityUOM.innerText = productCode.txtQuantityUoM;selectedProductCode = productCode;">
                {{productCode.txtStockKeepingUnit}}
              </mat-list-option>
            </mat-selection-list>
            <app-spinner *ngIf="showSpinner" [showSpinner]="showSpinner" [spinnerId]="'productCodeSpinner'" class="product-code-spinner"></app-spinner>

          </div>

        </div>
      </div>
    </form>
  </div>
</div>
