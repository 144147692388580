<div class="customer-delivery-address-selection-container">
  <div class="customer-delivery-address-selection-form" [formGroup]="form">

    <div class="customer-name-container">
      <div class="customer-name-label">Customer:</div><div class="customer-name">{{customerName}}</div>
    </div>

    <div class="customer-delivery-address-select-container">
      <div class="search-customer-delivery-address-row">
        <div class="search-input">
          <mat-form-field class="chh-form-field">
            <div class="customer-address-filter-input-container">
              <input matInput class="customer-address-filter-input" placeholder="Filter delivery address" placeholderLabel="i.e. Dubbo....." value="" type="text" #deliveryAddressFilter id="deliveryAddressFilter" (keyup)="filterCustomerDeliveryAddress(deliveryAddressFilter.value, chkGlobalAddress.checked)" (focus)="deliveryAddressFilter.select()">
            </div>
          </mat-form-field>
        </div>

        <div class="global-address-checkbox"><mat-checkbox class="example-margin" #chkGlobalAddress id="chkGlobalAddress" [value]="true" [checked]="isGlobalAddress" (change)="filterCustomerDeliveryAddress(deliveryAddressFilter.value, chkGlobalAddress.checked)">Show Global<br />Addresses</mat-checkbox></div>
        <div class="inactive-address-checkbox"><mat-checkbox class="example-margin" #chkInactiveAddress id="chkInactiveAddress" [value]="true" (change)="getCustomerDeliveryAddresses(chkGlobalAddress.checked, chkInactiveAddress.checked)">Include Inactive<br />Address</mat-checkbox></div>

        <div class="add-customer-delivery-address">
          <mat-icon matTooltip="Click to add a Customer Delivery Address" class="clickable-icon chh-icon" (click)="addCustomerDeliveryAddress()">format_list_bulleted_add</mat-icon>
        </div>

        <div class="edit-customer-delivery-address">
          <mat-icon matTooltip="Click to edit a Customer Delivery Address" class="clickable-icon chh-icon" (click)="showEditButton()">edit_note</mat-icon>
        </div>
      </div>

      <div class="customer-delivery-address-select-container">
        <div id="customerDeliveryAddressSelect" class="customer-delivery-address-select">
          @for(customerDeliveryAddress of customerDeliveryAddresses; track customerDeliveryAddress){
          <div class="chh-list-item-row chh-list-item-first-column customer-delivery-address" [ngClass]="selectedCustomerDeliveryAddress != null && selectedCustomerDeliveryAddress.rowguid == customerDeliveryAddress.rowguid ? 'chh-list-item-row chh-list-item-row-selected customer-delivery-address' : addressHoverId == customerDeliveryAddress.rowguid ? 'chh-list-item-row chh-list-item-row-hover customer-delivery-address' : 'chh-list-item-row customer-delivery-address'" (mouseenter)="rowEnter(customerDeliveryAddress.rowguid)" (mouseleave)="rowLeave()" (click)="setSelectedAddress(customerDeliveryAddress)"><div class="address-text">{{customerDeliveryAddress.txtAddress}}</div></div>
          <div class="chh-list-item-row customer-delivery-address-town" [ngClass]="selectedCustomerDeliveryAddress != null && selectedCustomerDeliveryAddress.rowguid == customerDeliveryAddress.rowguid ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customerDeliveryAddress.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customerDeliveryAddress.rowguid)" (mouseleave)="rowLeave()" (click)="setSelectedAddress(customerDeliveryAddress)"><div class="address-text">{{customerDeliveryAddress.txtTown}}</div></div>
          <div class="chh-list-item-row customer-delivery-address-state" [ngClass]="selectedCustomerDeliveryAddress != null && selectedCustomerDeliveryAddress.rowguid == customerDeliveryAddress.rowguid ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customerDeliveryAddress.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customerDeliveryAddress.rowguid)" (mouseleave)="rowLeave()" (click)="setSelectedAddress(customerDeliveryAddress)"><div class="address-text">{{customerDeliveryAddress.txtState}}</div></div>
          <div class="chh-list-item-row customer-delivery-address-postcode" [ngClass]="selectedCustomerDeliveryAddress != null && selectedCustomerDeliveryAddress.rowguid == customerDeliveryAddress.rowguid ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customerDeliveryAddress.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customerDeliveryAddress.rowguid)" (mouseleave)="rowLeave()" (click)="setSelectedAddress(customerDeliveryAddress)"><div class="address-text">{{customerDeliveryAddress.txtPostcode}}</div></div>
          <div class="chh-list-item-row chh-list-item-last-column customer-delivery-address-radio" [ngClass]="selectedCustomerDeliveryAddress != null && selectedCustomerDeliveryAddress.rowguid == customerDeliveryAddress.rowguid ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == customerDeliveryAddress.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(customerDeliveryAddress.rowguid)" (mouseleave)="rowLeave()" (click)="setSelectedAddress(customerDeliveryAddress)">
            <div class="address-text" *ngIf="showUpdateButton == false">
              <mat-radio-button class="chh-list-radio example-margin" [value]="customerDeliveryAddress.rowguid" [checked]="selectedCustomerDeliveryAddress != null ? customerDeliveryAddress.rowguid == selectedCustomerDeliveryAddress.rowguid : false" id="selectedAddress" #selectedAddress (change)="setSelectedAddress(customerDeliveryAddress)"></mat-radio-button>
            </div>
            <div class="update-address-button" *ngIf="showUpdateButton">
              <button mat-button (click)="updateCustomerDeliveryAddress(customerDeliveryAddress)" matTooltip="Click to edit Customer Delivery Address" >
                <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
              </button>
            </div>
          </div>
          }
        </div>

      </div>

    </div>
  </div>
</div>

<div class="save-buttons">
  <div class="save-button-container">
    <button class="save-button" type="button" mat-flat-button color="primary" (click)="selectCustomerDeliveryAddress()">Save</button>
    <button class="cancel-button" type="button" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
  </div>
</div>

<app-spinner *ngIf="showSpinner" [showSpinner]="showSpinner" class="customer-spinner"></app-spinner>

