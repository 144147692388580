import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { ElectricalAuthoritySpec } from '../business-objects/electrical-authority-spec.bo';

@Injectable()
export class ElectricalAuthoritySpecService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getElectricalAuthoritySpec(electricalAuthoritySpecId: string) {
		return this.http.get<ElectricalAuthoritySpec>(this._baseUrl + 'api/ElectricalAuthoritySpec/' + electricalAuthoritySpecId);
	}

	getElectricalAuthoritySpecParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<ElectricalAuthoritySpec[]>(this._baseUrl + 'api/ElectricalAuthoritySpec/GetElectricalAuthoritySpecsParamArray/', aParam);
	}

	getAllElectricalAuthoritySpecs() {
		return this.http.get<ElectricalAuthoritySpec[]>(this._baseUrl + 'api/ElectricalAuthoritySpec/GetAllElectricalAuthoritySpecs/');
	}

	//getAlldtoElectricalAuthoritySpecs() {
	//	return this.http.get<dtoElectricalAuthoritySpec[]>(this._baseUrl + 'api/ElectricalAuthoritySpec/GetAllElectricalAuthoritySpecs/');
	//}

	createElectricalAuthoritySpec(electricalAuthoritySpec: ElectricalAuthoritySpec) {
		return this.http.post<ElectricalAuthoritySpec>(this._baseUrl + 'api/ElectricalAuthoritySpec/CreateElectricalAuthoritySpec/', electricalAuthoritySpec);
	}

	updateElectricalAuthoritySpec(electricalAuthoritySpec: ElectricalAuthoritySpec) {
		return this.http.put<ElectricalAuthoritySpec>(this._baseUrl + 'api/ElectricalAuthoritySpec/UpdateElectricalAuthoritySpec/', electricalAuthoritySpec);
	}

  async getAllElectricalAuthoritySpecPromise() {
    return new Promise<ElectricalAuthoritySpec[]>(resolve => {
      this.getAllElectricalAuthoritySpecs().subscribe(electricalAuthoritySpec => {
        resolve(electricalAuthoritySpec);
      }, err => {
        this.alertService.openSnackBarError("Error getting ElectricalAuthoritySpec records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

	async getElectricalAuthoritySpecPromise(electricalAuthoritySpecId: string) {
		return new Promise<ElectricalAuthoritySpec>(resolve => {
      this.getElectricalAuthoritySpec(electricalAuthoritySpecId).subscribe(electricalAuthoritySpec => {
				resolve(electricalAuthoritySpec);
			}, err => {
				this.alertService.openSnackBarError("Error getting ElectricalAuthoritySpec records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getElectricalAuthoritySpecParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<ElectricalAuthoritySpec[]>(resolve => {
			this.getElectricalAuthoritySpecParamArray(aParam).subscribe(electricalAuthoritySpecs => {
				resolve(electricalAuthoritySpecs);
			}, err => {
				this.alertService.openSnackBarError("Error getting ElectricalAuthoritySpec records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async updateElectricalAuthoritySpecPromise(electricalAuthoritySpec: ElectricalAuthoritySpec) {
		return new Promise<ElectricalAuthoritySpec>(resolve => {
			this.updateElectricalAuthoritySpec(electricalAuthoritySpec).subscribe(electricalAuthoritySpec => {
				resolve(electricalAuthoritySpec);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating ElectricalAuthoritySpec record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createElectricalAuthoritySpecPromise(electricalAuthoritySpec) {
		return new Promise<ElectricalAuthoritySpec>(resolve => {
			this.createElectricalAuthoritySpec(electricalAuthoritySpec).subscribe(electricalAuthoritySpec => {
				resolve(electricalAuthoritySpec);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating ElectricalAuthoritySpec record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

}
