import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { LoadingDocketDelivery } from '../business-objects/loading-docket-delivery.bo';
import { of } from 'rxjs';

@Injectable()
export class LoadingDocketDeliveryService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getLoadingDocketDelivery(loadingDocketDeliveryId: string) {
		return this.http.get<LoadingDocketDelivery>(this._baseUrl + 'api/LoadingDocketDelivery/' + loadingDocketDeliveryId);
	}

	getLoadingDocketDeliveryParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<LoadingDocketDelivery[]>(this._baseUrl + 'api/LoadingDocketDelivery/GetLoadingDocketDeliverysParamArray/', aParam);
	}

	getAllLoadingDocketDeliverys() {
		return this.http.get<LoadingDocketDelivery[]>(this._baseUrl + 'api/LoadingDocketDelivery/GetAllLoadingDocketDeliverys/');
	}

	//getAlldtoLoadingDocketDeliverys() {
	//	return this.http.get<dtoLoadingDocketDelivery[]>(this._baseUrl + 'api/LoadingDocketDelivery/GetAllLoadingDocketDeliverys/');
	//}

	createLoadingDocketDelivery(loadingDocketDelivery: LoadingDocketDelivery) {
		return this.http.post<LoadingDocketDelivery>(this._baseUrl + 'api/LoadingDocketDelivery/CreateLoadingDocketDelivery/', loadingDocketDelivery);
	}

  deleteLoadingDocketDelivery(loadingDocketDeliveryId: string) {
    return this.http.delete(this._baseUrl + 'api/LoadingDocketDelivery/DeleteLoadingDocketDelivery/' + loadingDocketDeliveryId);
  }

	updateLoadingDocketDelivery(loadingDocketDelivery: LoadingDocketDelivery) {
		return this.http.put<LoadingDocketDelivery>(this._baseUrl + 'api/LoadingDocketDelivery/UpdateLoadingDocketDelivery/', loadingDocketDelivery);
	}

	async getLoadingDocketDeliveryPromise(loadingDocketDeliveryId: string) {
		return new Promise<LoadingDocketDelivery>(resolve => {
			this.getLoadingDocketDelivery(loadingDocketDeliveryId).subscribe(loadingDocketDelivery => {
				resolve(loadingDocketDelivery);
			}, err => {
				this.alertService.openSnackBarError("Error getting LoadingDocketDelivery records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getLoadingDocketDeliveryParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<LoadingDocketDelivery[]>(resolve => {
			this.getLoadingDocketDeliveryParamArray(aParam).subscribe(loadingDocketDeliverys => {
				resolve(loadingDocketDeliverys);
			}, err => {
				this.alertService.openSnackBarError("Error getting LoadingDocketDelivery records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

  async getLoadingDocketDeliveryByDeliveryIdPromise(deliveryDocketId: string) {
    return new Promise<LoadingDocketDelivery[]>(resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guDeliveryDocketId", deliveryDocketId));
      aParamList.push(aParam);

      this.getLoadingDocketDeliveryParamArray(aParamList).subscribe({
        next: dtoTPPoleInspectionItems =>
          resolve(dtoTPPoleInspectionItems),
        error: err => {
          console.log(err);
          this.alertService.openSnackBarError("Error getting LoadingDocketDelivery records", "Close", "center", "bottom", 4000, true, err.error);
          resolve(null);
        }
      });
    });

  }


	async updateLoadingDocketDeliveryPromise(loadingDocketDelivery: LoadingDocketDelivery) {
		return new Promise<LoadingDocketDelivery>(resolve => {
			this.updateLoadingDocketDelivery(loadingDocketDelivery).subscribe(loadingDocketDelivery => {
				resolve(loadingDocketDelivery);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating LoadingDocketDelivery record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createLoadingDocketDeliveryPromise(loadingDocketDelivery: LoadingDocketDelivery) {
		return new Promise<LoadingDocketDelivery>(resolve => {
			this.createLoadingDocketDelivery(loadingDocketDelivery).subscribe(loadingDocketDelivery => {
				resolve(loadingDocketDelivery);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating LoadingDocketDelivery record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
  }

  async deleteLoadingDocketDeliveryPromise(loadingDocketDeliveryId: string) {
    return new Promise<boolean>(resolve => {
      this.deleteLoadingDocketDelivery(loadingDocketDeliveryId).subscribe(loadingDocketDelivery => {
        resolve(true);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error removing LoadingDocketDelivery record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(false);
      });
    });
  }

}
