<h1>Code Builder</h1>
<form class="pole-trimming-add-form" [formGroup]="form">

  <div class="code-builder-container">

    <div class="header-row-1">
      Header Row 1
      <span><button type="button" class="stocktakeButton" mat-flat-button mat-button color="primary" (click)="testNullableParameter()" id="btnTestNullableParameter">Test Nullable Parameter</button> </span>

    </div>


    <div class="header-row-2">
      Header Row 2

    </div>

    <div class="main-container">
      <div class="main-left-panel">
        <h3>Tables</h3>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Table name</mat-label>
          <input matInput id="txtCustomer" placeholder="Customer" #tableFilter value="" (input)="filterTables(tableFilter.value)">
        </mat-form-field>
        <mat-list>
          <mat-list-item *ngFor="let table of tables; let rowIdx = index;">
            <h5 matLine> {{table.txtTableName}} </h5>
            <p matLine>
              <span><button type="button" class="stocktakeButton" mat-flat-button mat-button color="primary" (click)="viewColumns(table)" id="tbl{{table.txtTableName}}">View Code</button> </span>
            </p>
          </mat-list-item>
        </mat-list>
      </div>

      <div class="main-right-panel">
        main right panel to view code.. will need tabs here
        <mat-tab-group color="accent" backgroundColor="primary" [selectedIndex]="selectedOrdersTab" (selectedTabChange)="onTabChange($event)" #tabCodeBuilder>
          <mat-tab label="Fields" class="tab-label">
            <h3>Columns</h3>

            <span><button type="button" class="stocktakeButton" mat-flat-button mat-button color="primary" (click)="viewCode()" id="btnViewCode">View Code</button> </span>
            <span><mat-slide-toggle (change)="onSaveChange($event.checked)">Save File</mat-slide-toggle></span>

            <table mat-table #tblOrderItems [dataSource]="tableFields" class="mat-elevation-z8">

              <ng-container matColumnDef="column_name">
                <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Column Name </th>
                <td mat-cell *matCellDef="let field" class="identifierCell stockitemTD"> {{field.txtColumnName}} </td>
              </ng-container>

              <ng-container matColumnDef="fk_column">
                <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Foriegn Key Column </th>
                <td mat-cell *matCellDef="let field" class="stockitemTD stockItemProduct"> {{field.txtFKColumnName}} </td>
              </ng-container>

              <ng-container matColumnDef="fk_referenced_table">
                <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Referenced Table </th>
                <td mat-cell *matCellDef="let field" class="stockitemTD stockItemProduct"> {{field.txtFKTableName}} </td>
              </ng-container>

              <ng-container matColumnDef="fk_referenced_column">
                <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Referenced Column </th>
                <td mat-cell *matCellDef="let field" class="stockitemTD stockItemProduct"> {{field.txtFKReferencedColumnName}} </td>
              </ng-container>

              <ng-container matColumnDef="chkFilter">
                <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Filter </th>
                <td mat-cell *matCellDef="let field" class="stockitemTD stockItemProfile">

                  <mat-form-field appearance="fill" *ngIf="field.txtColumnName != 'rowguid' && field.txtColumnName != 'dteLastUpdated'">
                    <mat-label>Filter Type</mat-label>
                    <mat-select [value]="field.intColumnFilterType" (selectionChange)="onColumFilterSelect($event.value, field)">
                      <mat-option *ngFor="let dbFilter of getDBFilters(field)" [value]="dbFilter.intValue">{{dbFilter.txtValue}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="chkSQLFilterOperator">
                <th mat-header-cell *matHeaderCellDef class="stockitemTH"> SQL Filter Operator </th>
                <td mat-cell *matCellDef="let field" class="stockitemTD stockItemProfile">

                  <mat-form-field appearance="fill" *ngIf="field.txtColumnName != 'rowguid' && field.txtColumnName != 'dteLastUpdated'">
                    <mat-label>Filter Type</mat-label>
                    <mat-select [value]="field.intSQLFilterOperator" (selectionChange)="onColumSQLFilterOperatorSelect($event.value, field)">
                      <mat-option *ngFor="let dbSQLFilterOperator of dbSQLFilterOperators" [value]="dbSQLFilterOperator.intValue">{{dbSQLFilterOperator.txtValue}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="is_nullable_filter">
                <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Is Nullable Filter </th>
                <td mat-cell *matCellDef="let field" class="stockitemTD stockItemProduct"><mat-checkbox class="example-margin" [checked]="field.blnNullableFilter" (change)="onIsNullableFilterChange($event.checked,field)"></mat-checkbox> </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
            </table>
            <span><button type="button" class="stocktakeButton" mat-flat-button mat-button color="primary" (click)="saveColumn()" id="btnSaveColumn">Save</button> </span>

          </mat-tab>
          <mat-tab label="HTML" class="tab-label">
            <mat-form-field class="example-full-width example-full-height txtComments" appearance="fill">
              <mat-label>HTML</mat-label>
              <textarea class="txtCode" matInput formControlName="txtHTMLCode" placeholder="HTML Code"></textarea>
            </mat-form-field>
          </mat-tab>
          <mat-tab label="CSS" class="tab-label">
            <mat-form-field class="example-full-width txtComments" appearance="fill">
              <mat-label>CSS</mat-label>
              <textarea class="txtCode" matInput formControlName="txtCSSCode" placeholder="CSS Code"></textarea>
            </mat-form-field>
          </mat-tab>
          <mat-tab label="Service" class="tab-label">
            <mat-form-field class="example-full-width example-full-height txtComments" appearance="fill">
              <mat-label>Service</mat-label>
              <textarea class="txtCode" matInput formControlName="txtServiceCode" placeholder="Service Code"></textarea>
            </mat-form-field>
          </mat-tab>
          <mat-tab label="Controller" class="tab-label">
            <mat-form-field class="example-full-width txtComments" appearance="fill">
              <mat-label>Controller</mat-label>
              <textarea class="txtCode" matInput formControlName="txtControllerCode" placeholder="Controller Code"></textarea>
            </mat-form-field>
          </mat-tab>
          <mat-tab label="Service Class" class="tab-label">
            <mat-form-field class="example-full-width txtComments" appearance="fill">
              <mat-label>Service</mat-label>
              <textarea class="txtCode" matInput formControlName="txtServiceClassCode" placeholder="Service Class Code"></textarea>
            </mat-form-field>
          </mat-tab>
          <mat-tab label="Service Interface" class="tab-label">
            <mat-form-field class="example-full-width txtComments" appearance="fill">
              <mat-label>Service</mat-label>
              <textarea class="txtCode" matInput formControlName="txtServiceInterfaceCode" placeholder="Service Interface Code"></textarea>
            </mat-form-field>
          </mat-tab>
          <mat-tab label="Repository Class" class="tab-label">
            <mat-form-field class="example-full-width txtComments" appearance="fill">
              <mat-label>Repository Class</mat-label>
              <textarea class="txtCode" matInput formControlName="txtRepositoryClassCode" placeholder="Repository Class Code"></textarea>
            </mat-form-field>
          </mat-tab>
          <mat-tab label="Repository Interface" class="tab-label">
            <mat-form-field class="example-full-width txtComments" appearance="fill">
              <mat-label>Repository Interface</mat-label>
              <textarea class="txtCode" matInput formControlName="txtRepositoryInterfaceCode" placeholder="Repository Interface Code"></textarea>
            </mat-form-field>
          </mat-tab>
        </mat-tab-group>

      </div>


    </div>


    <div class="footer-row-1">
      footer row 1

    </div>


  </div>

</form>

