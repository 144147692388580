import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AppPageColumn } from '../../_shared/business-objects/app-page-column.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { AppService } from '../../_shared/services/app-services/app.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { AppPageColumnService } from '../../_shared/services/app-page-column.service';
import { dtoAppPageColumnService } from '../../_shared/services/dto-app-page-column.service';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AppPageService } from '../../_shared/services/app-page.service';
import { dtoAppPageColumn } from '../../_shared/business-objects/dto-app-page-column.bo';

export enum enAppPageColumnTabs { AppPageColumns = 0, SearchOptions = 1, CreateAppPageColumn = 2, UpdateAppPageColumn = 3 }


@Component({
  selector: 'app-app-page-column-search',
  templateUrl: './app-page-column-search.component.html',
  styleUrls: ['./app-page-column-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class AppPageColumnSearchComponent implements OnInit {

  @ViewChild('tabAppPageColumns', { static: false }) tabAppPageColumns: MatTabGroup;


  form: FormGroup;
  selectedTab: number;
  public enAppPageColumnTabs = enAppPageColumnTabs;

  appPageColumns: MatTableDataSource<dtoAppPageColumn>;
  appPageColumn: AppPageColumn;

  appPages: AppPage[];
  selectedAppPages: string;


  apps: dtoIntKeyValue[] = [];

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  totalRows: number = 0;

  resultTime: number;
  searchTime: number;

  showSpinner: boolean = false;

  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private route: ActivatedRoute, private formService: FormService
    , private dtoAppPageColumnService: dtoAppPageColumnService, private appPageColumnService: AppPageColumnService, private appPageService: AppPageService, private appService: AppService) {

    this.form = this.fb.group({
      txtSearchAppPageColumnName: [''],
      txtSearchAppPageName: [''],
      guAppPageId: [''],
      radSearchApps: [''],
      txtSearchAppPageColumnURL: [''],
      dteSearchDateCreatedFrom: [''],
      dteSearchDateCreatedTo: ['']
    });

    this.appPageColumns = new MatTableDataSource();


    this.apps = this.sharedService.getEnumObjectKeyValue(appService.enApp);
    this.apps.splice(0, 0, new dtoIntKeyValue(-1, "None"));

    this.form.controls['radSearchApps'].setValue(-1);

  }

  ngOnInit(): void {

    this.selectedTab = this.enAppPageColumnTabs.AppPageColumns;

    this.route.queryParams.subscribe(params => {

      this.formService.setFieldsFromQueryParams(this.filterToQueryParamMap, params, this.form);

    });

    this.loadData();

  }

  async loadData() {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    //aParam.push(new SQLParamArray("dteDateCreated", '2022-01-01', SQLParamArray.enSQLOperator.GreaterThanOrEqualTo));
    aParamList.push(aParam);

    this.appPages = await this.appPageService.getAppPageParamArrayPromise(aParamList);
    let appPage: AppPage = new AppPage;
    appPage.txtPageName = "None";
    appPage.rowguid = this.sharedService.EmptyGuid;

    this.appPages.splice(0, 0, appPage);


  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    if (e.index == this.enAppPageColumnTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchAppName");
    }

  }


  searchAppPageColumns() {

    let btnSearchAppPageColumns = document.getElementById("btnSearchPageURL");
    if (btnSearchAppPageColumns != null) {
      btnSearchAppPageColumns.focus();
    }

    this.tabAppPageColumns.selectedIndex = this.enAppPageColumnTabs.AppPageColumns;
    this.showSpinner = true;

    this.resultTime = 0;
    this.searchTime = Date.now();

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let appPageColumnName: string = this.form.controls["txtSearchAppPageColumnName"].value.toString();
    let appPageName: string = this.form.controls["txtSearchAppPageName"].value.toString();
    let app: string = this.form.controls["radSearchApps"].value.toString();
    let appPageId: string = this.form.controls["guAppPageId"].value;
    let createdDateFrom: string = this.form.controls["dteSearchDateCreatedFrom"].value;
    let createdDateTo: string = this.form.controls["dteSearchDateCreatedTo"].value;
    const datepipe: DatePipe = new DatePipe('en-AU');


    this.totalRows = 0;

    this.appPageColumns.data = [];

    if (appPageColumnName != "" && appPageColumnName != null) {
      aParam = [];
      if (appPageColumnName.indexOf(",")) {
        let aAppPageColumnNames: string[] = appPageColumnName.split(",");
        for (let i = 0; i <= aAppPageColumnNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtColumnName", aAppPageColumnNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtColumnName", appPageColumnName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (appPageName != "" && appPageName != null) {
      aParam = [];
      if (appPageName.indexOf(",")) {
        let aAppPageNames: string[] = appPageName.split(",");
        for (let i = 0; i <= aAppPageNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtPageName", aAppPageNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtPageName", appPageName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (app != "" && app != null && parseInt(app) > -1) {
      aParam = [];
      aParam.push(new SQLParamArray("intApp", app, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "AppPage"))
      aParamList.push(aParam);
    }

    if (appPageId != "" && appPageId != null && appPageId != this.sharedService.EmptyGuid) {
      aParam = [];
      aParam.push(new SQLParamArray("guAppPageId", appPageId))
      aParamList.push(aParam);
    }


    if (createdDateFrom != "" && createdDateFrom != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdDateFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }

    if (createdDateTo != "" && createdDateTo != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdDateTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }


    if (aParamList.length == 0) {
      //this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "", 4000);
    }


    this.dtoAppPageColumnService.getdtoAppPageColumnParamArray(aParamList).subscribe(appPages => {

      this.appPageColumns.data = appPages;

      this.totalRows = appPages.length;

      this.resultTime = ((Date.now() - this.searchTime) / 1000);

      this.showSpinner = false;

    }, err => {
      console.log(err);
    });


  }


  async editAppPageColumn(appPageColumn: dtoAppPageColumn) {

    console.log(this.appPageColumn);

    this.appPageColumn = await this.appPageColumnService.getAppPageColumnPromise(appPageColumn.rowguid);


    this.tabAppPageColumns.selectedIndex = this.enAppPageColumnTabs.UpdateAppPageColumn;

  }

  async removeAppPageColumn(appPageColumn: dtoAppPageColumn) {

    let msg: string = await this.alertService.openSnackBarCustomPromise("Remove App Page Column", "Are you sure you want to remove this app page column " + appPageColumn.txtColumnName + "?", "Yes", "No", "center", "bottom", "", 0);
    if (msg != "Yes") {
      return;
    }

    await this.appPageColumnService.removeAppPageColumnPromise(appPageColumn);

    this.searchAppPageColumns();


  }

  async appPageColumnCreated(appPage: AppPage) {
    if (appPage == null) {
      return;
    }

    this.tabAppPageColumns.selectedIndex = this.enAppPageColumnTabs.AppPageColumns;

    this.form.controls['txtSearchAppPageName'].setValue(appPage.txtPageName);

    this.searchAppPageColumns();

  }

  async appPageColumnUpdated(appPageColumn: AppPageColumn) {

    if (appPageColumn == null) {
      return;
    }

    this.tabAppPageColumns.selectedIndex = this.enAppPageColumnTabs.AppPageColumns;

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("rowguid", appPageColumn.guAppPageId));
    let appPage: AppPage = await this.appPageService.getAppPagePromise(appPageColumn.guAppPageId);


    this.form.controls['txtSearchAppPageName'].setValue(appPage.txtPageName);

    this.searchAppPageColumns();

  }

  appPageColumnCancelled() {

    this.tabAppPageColumns.selectedIndex = this.enAppPageColumnTabs.AppPageColumns;

  }

}
