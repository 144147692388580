<h1>Downloads</h1>
<br />
<br />
<br />

<button class="" type="button" mat-flat-button color="primary" (click)="download()" *ngIf="botanicalIsVisible">Download MTECH Botanical</button>
<div #downloadProgress id="downloadProgress" *ngIf="botanicalIsVisible">Progress %</div>

<button class="" type="button" mat-flat-button color="primary" (click)="testPDF()">Test PDF</button>

