import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppPageColumn } from '../../_shared/business-objects/app-page-column.bo';
import { dtoAppPageColumn } from '../../_shared/business-objects/dto-app-page-column.bo';
import { AppService } from '../../_shared/services/app-services/app.service';

@Component({
  selector: 'app-app-page-column-list',
  templateUrl: './app-page-column-list.component.html',
  styleUrls: ['./app-page-column-list.component.css']
})
export class AppPageColumnListComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoAppPageColumn>;
  @Output() editAppPageColumnEvent = new EventEmitter<dtoAppPageColumn>();
  @Output() removeAppPageColumnEvent = new EventEmitter<dtoAppPageColumn>();

  @ViewChild(MatSort) sort: MatSort;

  displayedAppPageColumns: string[] = ['txtPageName', 'txtColumnName', 'txtColumnNameAlias', 'dteDateCreated', 'intDefaultColumnOrder', 'btnEditAppPageColumn', 'btnRemoveAppPageColumn'];

  constructor(private appService: AppService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
  }

  editAppPageColumn(appPageColumn: dtoAppPageColumn) {

    this.editAppPageColumnEvent.emit(appPageColumn);

  }

  removeAppPageColumn(appPageColumn: dtoAppPageColumn) {

    this.removeAppPageColumnEvent.emit(appPageColumn);

  }
}
