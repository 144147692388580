<form class="example-form" [formGroup]="form">

  <div class="delivery-docket-add-items-container">
    <h3>Delivery Docket Add Items</h3>

    <div class="delivery-docket">
      <mat-form-field class="example-full-width header-button delivery-docket-data" style="width: 120px !important" appearance="fill">
        <mat-label>Docket No.</mat-label>
        <input class="textbox-input disabled-input" matInput placeholder="0" value="" type="text" id="txtDeliveryDocketNo" formControlName="txtDeliveryDocketNo" disabled="disabled">
      </mat-form-field>
      <mat-form-field class="example-full-width header-button delivery-docket-data" style="width: 350px !important" appearance="fill">
        <mat-label>Customer</mat-label>
        <input class="textbox-input" matInput placeholder="" value="" type="text" id="txtCustomer" formControlName="txtCustomer" readonly>
      </mat-form-field>
      <mat-form-field class="example-full-width header-button delivery-docket-data" style="width: 350px !important" appearance="fill">
        <mat-label>Hauler</mat-label>
        <input class="textbox-input" matInput placeholder="" value="" type="text" id="txtHauler" formControlName="txtHauler" readonly>
      </mat-form-field>
      <mat-form-field class="example-full-width header-button delivery-docket-data" style="width: 180px !important;" appearance="fill">
        <mat-label>Status Date</mat-label>
        <input id="dteDateCreated" class="date-input created-from-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteStatusDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
        <mat-datepicker #pickercreatedfrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="example-full-width header-button delivery-docket-data" style="width: 200px !important" appearance="fill">
        <mat-label>Status</mat-label>
        <input class="textbox-input" matInput placeholder="" value="" type="text" id="txtStatus" formControlName="txtStatus" readonly>
      </mat-form-field>

      <button class="create-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="createDeliveryDocket()" [disabled]="deliveryDocket != null" *ngIf="haveDeliveryDocket == false">Create Delivery Docket</button>
      <button class="update-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="updateDeliveryDocket()" [disabled]="deliveryDocket == null" *ngIf="haveDeliveryDocket == true">Update Delivery Docket</button>
      <button class="open-loading-docket-button" type="button" mat-flat-button color="primary" (click)="openLoadingDocket()" [disabled]="deliveryDocket == null || deliveryDocket.intLoadingDocketNo == null || deliveryDocket.intLoadingDocketNo == 0" *ngIf="haveDeliveryDocket == true">Open Loading Docket {{deliveryDocket.intLoadingDocketNo}}</button>
    </div>

    <div class="spinner-container" *ngIf="showSpinner">
      <mat-card [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showSpinner">
        <mat-card-content class="spinner-content">
          <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
        </mat-card-content>
      </mat-card>
    </div>

    <mat-tab-group class="chh-mat-tab-group delivery-docket-order-items-add-tab-group" color="accent" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabDeliveryDocketsAddItems>
      <mat-tab label="Order Items" class="tab-label order-item-list-tab">
        <div class="delivery-docket-order-items-container">
          <app-delivery-docket-order-item-list #appDeliveryDocketOrderItemList [datasource]="deliveryDocketOrderItemsList" (editDeliveryDocketOrderItemEvent)="editDeliveryDocketOrderItem($event)" (removeDeliveryDocketOrderItemEvent)="removeDeliveryDocketOrderItem($event)" *ngIf="deliveryDocketOrderItems != null"></app-delivery-docket-order-item-list>
        </div>
      </mat-tab>

      <mat-tab label="Linked Final Items" class="tab-label">
        <div id="finalInspectionsContainer">
          <app-delivery-docket-final-items-list #appLinkedInspecionList [datasource]="linkedFinalInspectionItems" (unlinkFinalInspectionItemEvent)="unlinkFinalItem($event)"></app-delivery-docket-final-items-list>
        </div>
      </mat-tab>

      <mat-tab label="Linked Stock Items" class="tab-label">
      </mat-tab>

      <mat-tab label="Add Final Items" class="tab-label" id="final-sheet-tab">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Final No</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" #searchFinalNo value="" type="text" #finalNo formControlName="txtSearchFinalNo" (keyup.enter)="searchFinalInspection(searchFinalNo.value)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 55670,28631..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-buttons">
            <button class="search-final-inspection-items-button" type="button" mat-flat-button color="primary" (click)="searchFinalInspection(finalNo.value)">Search Finals</button>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="delivery-docket-final-items-add-container">
          <div class="delivery-docket-final-items-top-container">
            <div class="detail-view">
              <div class="view-type-label">View Type</div>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group view-type-radio" fxLayout="column">
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="0" name="radView" (click)="changeView(0)" [checked]="viewType == 0">Final Items</mat-radio-button>
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="1" name="radView" (click)="changeView(1)" [checked]="viewType == 1">Preview Delivery Docket</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="add-to-docket-column-radios">
              <div class="final-item-delivery-status-label">Final Item Delivery Status</div>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group view-type-radio" fxLayout="column" formControlName="radFinalItemsView">
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="0" (click)="changeFinalItmsView(0)" [checked]="finalItemsView == 0">Non Delivered</mat-radio-button>
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="1" (click)="changeFinalItmsView(1)" [checked]="finalItemsView == 1">Delivered</mat-radio-button>
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="2" (click)="changeFinalItmsView(2)" [checked]="finalItemsView == 2">Either</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="delivery-docket-order-item-totals">
              <div class="total-actual-m3-label">Total Actual M3</div><div class="total-actual-m3">{{deliveryDocketOrderItemFinalItemsToAddM3Total}}</div><div class="total-actual-tonne-label">Total Actual Tonne</div><div class="total-actual-tonne">{{deliveryDocketOrderItemFinalItemsToAddTonneTotal}}</div>
            </div>
            <div class="add-to-docket-column-buttons">
              <button class="add-final-items-button" type="button" mat-flat-button color="primary" (click)="addFinalItems()" [disabled]="gotNonDelivered == false ">Add Final Items</button>
            </div>
          </div>
          <div class="delivery-final-view">
            <app-delivery-docket-final-items-add #appFinalInspectionList [datasource]="finalInspectionItems" (updateFinalInspectionItemEvent)="updatePreview($event)" (unlinkFinalInspectionItemEvent)="unlinkFinalItem($event)" [ngClass]="viewType == 0 ? 'show-view' : 'hide-view'"></app-delivery-docket-final-items-add>
            <app-delivery-docket-final-items-preview #appDeliveryDocketPreview [finalItemsDatasource]="finalInspectionItems" [deliveryOrderItemsDatasource]="deliveryDocketOrderItems" [deliveryDocket]="deliveryDocket" [ngClass]="viewType == 1 ? 'show-view' : 'hide-view'"></app-delivery-docket-final-items-preview>
          </div>
        </div>

      </mat-tab>
      <mat-tab label="Add Stock Items" class="tab-label">
        <div>Add Stock Items</div>
      </mat-tab>

      <mat-tab label="Add Order Items" class="tab-label">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Order No</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. EE41002553" #searchOrderNo value="" type="text" id="txtSearchOrderNo" formControlName="txtSearchOrderNo" (keyup.enter)="searchOrderItems(searchOrderNo.value)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. EE41002553,EE41002555..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-buttons">
            <button class="search-order-items-button" type="button" mat-flat-button color="primary" (click)="searchOrderItems(searchOrderNo.value)">Search Order Items</button>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="delivery-docket-order-items-add-container">
          <div class="delivery-docket-order-items-top-container">
            <div class="delivery-docket-order-item-totals">
              <div class="total-m3-label">Total M3</div><div class="total-m3">{{deliveryDocketOrderItemsToAddM3Total}}</div><div class="total-tonne-label">Total Tonne</div><div class="total-tonne">{{deliveryDocketOrderItemsToAddTonneTotal}}</div>
            </div>
            <div class="add-to-docket-column-buttons">
              <button class="add-order-items-button" type="button" mat-flat-button color="primary" (click)="addOrderItems()" [disabled]="gotOrderItemsNonDelivered == false ">Add Order Items</button>
            </div>
          </div>
          <div class="delivery-order-view">
            <app-delivery-docket-order-items-add #appOrderItemsList [columnListId]="'columnListId3'" [datasource]="orderItems" [deliveryDocket]="deliveryDocket" [existingDeliveryDocketOrderItems]="deliveryDocketOrderItemsList" (updateOrderItemsToAdd)="updateOrderItemsToAdd($event)" (unlinkOrderItemEvent)="unlinkOrderItem($event)"></app-delivery-docket-order-items-add>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</form>




