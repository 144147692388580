import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { dtoTPPoleInspection } from '../../_shared/business-objects/dto-tppole-inspection.bo';
import { TPCharge } from '../../_shared/business-objects/tpcharge.bo';
import { TPPoleInspection } from '../../_shared/business-objects/tppole-inspection.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { SharedService } from '../../_shared/services/shared.service';
import { TPPoleInspectionService } from '../../_shared/services/tppole-inspection.service';
import { EmployeeService } from '../../_shared/services/employee.service';
import { Employee } from '../../_shared/business-objects/employee.bo';
import { ElectricalAuthoritySpec } from '../../_shared/business-objects/electrical-authority-spec.bo';
import { ElectricalAuthoritySpecService } from '../../_shared/services/electrical-authority-spec.service';

@Component({
  selector: 'app-final-inspection-create',
  templateUrl: './final-inspection-create.component.html',
  styleUrls: ['./final-inspection-create.component.css']
})
export class FinalInspectionCreateComponent implements OnInit {

  form: FormGroup;
  hazardLevels: dtoIntKeyValue[] = [];
  employees: Employee[] = [];
  public elecAuthSpecs: ElectricalAuthoritySpec[];

  @Output() finalInspectionCreated = new EventEmitter<[TPPoleInspection, string]>();

  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private tpPoleInspectionService: TPPoleInspectionService
    , private employeeService: EmployeeService, private electricalAuthoritySpec: ElectricalAuthoritySpecService  ) {

    this.form = this.fb.group({
      dteDateInspected: [''],
      guInspectedById: [''],
      chkCHHBrandApplied: [''],
      chkInspectorsStampApplied: [''],
      chkCCAAndHazardLevelApplied: [''],
      chkSurfaceClean: [''],
      chkButtsMarkedCorrectly: [''],
      radHazardLevel: [''],
      intLoadNo:[''],
      intMaxLoads: [''],
      txtComments: [''],
      blnParkedFinal: [''],
      txtParkedFinalComments: [''],
      guElectricalAuthoritySpecId: ['']
    });

    const datepipe: DatePipe = new DatePipe('en-AU');

    this.hazardLevels = this.sharedService.getEnumObjectKeyValue(SharedService.enHazardTreatment);

    this.form.controls['radHazardLevel'].setValue(5);
    this.form.controls['intLoadNo'].setValue(1);
    this.form.controls['intMaxLoads'].setValue(1);
    this.form.controls['dteDateInspected'].setValue(new Date(datepipe.transform(Date.now(), 'yyyy-MM-dd')));


  }

  ngOnInit(): void {

    this.getElectricalAuthoritySpecs();
    this.form.controls['guElectricalAuthoritySpecId'].setValue(this.sharedService.EmptyGuid);


    this.employeeService.getEmployeesInPosition("Pole Final Inspector").subscribe(employees => {
      console.log(employees);

      this.employees = employees
    }, err => {
      console.log(err);
    });
  }


  async getElectricalAuthoritySpecs() {
    await this.electricalAuthoritySpec.getAllElectricalAuthoritySpecs().subscribe(data => {
      this.elecAuthSpecs = data;
      let elecAuthSpec: ElectricalAuthoritySpec = new ElectricalAuthoritySpec;
      elecAuthSpec.rowguid = this.sharedService.EmptyGuid;
      elecAuthSpec.txtAuthorityName = "None";
      this.elecAuthSpecs.splice(0, 0, elecAuthSpec);
    });
  }


  async createFinalInspection(mode: string) {

    let saveFinalInspection: boolean = true;
    let dateInspected: string = this.form.controls['dteDateInspected'].value;
    let inspectedById: string = this.form.controls['guInspectedById'].value;
    let chhBrandApplied: boolean = this.form.controls['chkCHHBrandApplied'].value == true;
    let inspectorsStamp: boolean = this.form.controls['chkInspectorsStampApplied'].value == true;
    let ccaHazardLevel: boolean = this.form.controls['chkCCAAndHazardLevelApplied'].value == true;
    let surfaceClean: boolean = this.form.controls['chkSurfaceClean'].value == true;
    let buttsMarkedCorrectly: boolean = this.form.controls['chkButtsMarkedCorrectly'].value == true;
    let hazardLevel: number = this.form.controls['radHazardLevel'].value;
    let loadNo: number = this.form.controls['intLoadNo'].value;
    let maxLoads: number = this.form.controls['intMaxLoads'].value;
    let comments: string = this.form.controls['txtComments'].value;
    let parkedFinal: boolean = this.form.controls['blnParkedFinal'].value == true;
    let parkedFinalComments: string = this.form.controls['txtParkedFinalComments'].value;
    let electricalAuthoritySpec: string = this.form.controls['guElectricalAuthoritySpecId'].value;
    const datepipe: DatePipe = new DatePipe('en-AU');

    console.log("dateInspected: " + dateInspected);
    console.log("ccaHazardLevel: " + ccaHazardLevel);
    console.log("surfaceClean: " + surfaceClean);
    console.log("buttsMarkedCorrectly: " + buttsMarkedCorrectly);
    console.log("hazardLevel: " + hazardLevel);

    if (dateInspected == null || dateInspected.toString() == "") {
      this.alertService.openSnackBar("Please select an inspection date.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (inspectedById == null || inspectedById.toString() == "") {
      this.alertService.openSnackBar("Please select an inspector.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (chhBrandApplied == null || chhBrandApplied == false) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("CHH Brand", "Is the CHH Brand applied?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = (alertResponse == "Yes");
      chhBrandApplied = (alertResponse == "Yes");
      this.form.controls['chkCHHBrandApplied'].setValue((alertResponse == "Yes"));
    }

    if (saveFinalInspection && (inspectorsStamp == null || inspectorsStamp == false)) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("Inspectors Stamp", "Is the inspectors stamp suppose to be applied?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = true;  // We are going to save whether they say yes or no, just set checkbox.
      inspectorsStamp = (alertResponse == "Yes");
      this.form.controls['chkInspectorsStampApplied'].setValue((alertResponse == "Yes"));
   }

    if (saveFinalInspection && (ccaHazardLevel == null || ccaHazardLevel == false)) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("CCA and Hazard Level Stamp", "Is the CCA and Hazard Level stamp suppose to be applied?", "Yes", "No", "center", "bottom", "", 3000);
      //saveFinalInspection = (alertResponse == "Yes");
      ccaHazardLevel = (alertResponse == "Yes");
      this.form.controls['chkCCAAndHazardLevelApplied'].setValue((alertResponse == "Yes"));
    }

    if (saveFinalInspection && (surfaceClean == null || surfaceClean == false)) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("Surface Clean No Residure", "Is the surface clean with no residue?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = (alertResponse == "Yes");
      surfaceClean = (alertResponse == "Yes");
      this.form.controls['chkSurfaceClean'].setValue((alertResponse == "Yes"));
    }

    if (saveFinalInspection && (buttsMarkedCorrectly == null || buttsMarkedCorrectly == false)) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("Butts Marked Correctly", "Are the butts marked correctly?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = (alertResponse == "Yes");
      buttsMarkedCorrectly = (alertResponse == "Yes");
      this.form.controls['chkButtsMarkedCorrectly'].setValue((alertResponse == "Yes"));
    }

    if (saveFinalInspection && (hazardLevel == null || hazardLevel == 0)) {
      let alertResponse = await this.alertService.openSnackBarCustomPromise("Hazard Level", "Is there a Hazard Level?", "Yes", "No", "center", "bottom", "", 3000);
      saveFinalInspection = (alertResponse != "Yes");  // If we've canceled the snack bar it means we want to stop and select a Hazard Level
    }



    if (saveFinalInspection) {
      let tpPoleInspection: TPPoleInspection = new TPPoleInspection;


      delete tpPoleInspection.rowguid;  //remove rowguid property so DB will create one.

      tpPoleInspection.dteDateInspected = new Date(datepipe.transform(dateInspected, 'yyyy-MM-dd'));
      tpPoleInspection.guInspectedById = inspectedById;
      tpPoleInspection.blnCHHBranded = chhBrandApplied;
      tpPoleInspection.blnInspectedStamp = inspectorsStamp;
      tpPoleInspection.blnCCAHazardStamp = ccaHazardLevel;
      tpPoleInspection.blnSurfaceClean = surfaceClean;
      tpPoleInspection.blnButtsMarkedCorrectly = buttsMarkedCorrectly;
      tpPoleInspection.intCCAHazardLevel = hazardLevel;
      tpPoleInspection.intLoadNo = loadNo;
      tpPoleInspection.intMaxLoads = maxLoads;
      tpPoleInspection.txtComments = comments;
      tpPoleInspection.blnParkedFinal = parkedFinal;
      tpPoleInspection.txtParkedSpec = parkedFinalComments;
      tpPoleInspection.guElectricalAuthoritySpecId = null;

      if (electricalAuthoritySpec != null && electricalAuthoritySpec != this.sharedService.EmptyGuid) {
        tpPoleInspection.guElectricalAuthoritySpecId = this.form.controls['guElectricalAuthoritySpecId'].value;
      }

      tpPoleInspection = await this.tpPoleInspectionService.createTPPoleInspectionPromise(tpPoleInspection);

      if (mode == "create") {
        tpPoleInspection = await this.tpPoleInspectionService.getTPPoleInspectionPromise(tpPoleInspection.rowguid);
        this.finalInspectionCreated.emit([tpPoleInspection, mode]);
      }

      if (mode == "createanditem") {
        tpPoleInspection = await this.tpPoleInspectionService.getTPPoleInspectionPromise(tpPoleInspection.rowguid);
        this.finalInspectionCreated.emit([tpPoleInspection, mode]);
      }
    }

  }

}
