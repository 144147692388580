import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../account/auth.service';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { DiscTemplateConfig } from '../../_shared/business-objects/disc-template-config.bo';
import { dtoDiscTemplateConfig } from '../../_shared/business-objects/dto-disc-template-config.bo';
import { ElectricalAuthoritySpec } from '../../_shared/business-objects/electrical-authority-spec.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { DiscTemplateConfigService } from '../../_shared/services/disc-template-config.service';
import { dtoDiscTemplateConfigService } from '../../_shared/services/dto-disc-template-config.service';
import { ElectricalAuthoritySpecService } from '../../_shared/services/electrical-authority-spec.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

export enum enDiscTemplateConfigTabs { DiscTemplateConfig = 0, SearchOptions = 1, CreateDiscTemplateConfig = 2, UpdateDiscTemplateConfig = 3 }


@Component({
  selector: 'app-disc-template-config-search',
  templateUrl: './disc-template-config-search.component.html',
  styleUrls: ['./disc-template-config-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ],
})
export class DiscTemplateConfigSearchComponent {

  @ViewChild('tabDiscTemplateConfig', { static: false }) tabDiscTemplateConfigs: MatTabGroup;

  form: FormGroup;

  public eDiscTemplateConfigTabs = enDiscTemplateConfigTabs;

  selectedTab: number;
  showSpinner: boolean = false;
  discTemplateConfigs: MatTableDataSource<dtoDiscTemplateConfig> = new MatTableDataSource();
  discTemplateConfig: DiscTemplateConfig;
  electricalAuthority: ElectricalAuthoritySpec;

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  constructor(private dtoDiscTemplateConfigService: dtoDiscTemplateConfigService, private discTemplateConfigService: DiscTemplateConfigService, private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private route: ActivatedRoute, private formService: FormService
    , private authService: AuthService, private electricalAuthoritySpecService: ElectricalAuthoritySpecService) {

    this.form = this.fb.group({
      txtSearchElectricalAuthority: [''],
      blnSearchActive: ['']
    });

    this.filterToQueryParamMap.push({ txtField: "txtSearchElectricalAuthority", txtQueryParam: "electricalauthority", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    this.filterToQueryParamMap.push({ txtField: "blnSearchActive", txtQueryParam: "location", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
  }

  ngOnInit(): void {

    this.selectedTab = this.eDiscTemplateConfigTabs.DiscTemplateConfig;

    this.loadData()


  }

  async loadData() {


    this.formService.setAutoFocusTimeout("txtSearchElectricalAuthority");

  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    console.log(e.index == this.eDiscTemplateConfigTabs.SearchOptions);

    if (e.index == this.eDiscTemplateConfigTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("blnSearchActive");
    }

    if (e.index == this.eDiscTemplateConfigTabs.DiscTemplateConfig) {
      this.formService.setAutoFocusTimeout("txtAuthorityName");
    }

  }

  async searchDiscTemplateConfig(searchType: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let electricalAuthority: string = this.form.controls["txtSearchElectricalAuthority"].value;
    const datepipe: DatePipe = new DatePipe('en-AU');

    if (electricalAuthority != "" && electricalAuthority != null) {
      aParam = [];
      if (electricalAuthority.indexOf(",")) {
        let aElectricalAuthority: string[] = electricalAuthority.split(",");
        for (let i = 0; i <= aElectricalAuthority.length - 1; i++) {
          aParam.push(new SQLParamArray("txtAuthorityName", aElectricalAuthority[i], SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtAuthorityName", electricalAuthority, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }


    this.showSpinner = true;

    //if (aParamList.length == 0) {
    //  this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "centerSnackbar", 4000);
    //  this.showSpinner = false;
    //  return;
    //}

    console.log("params: ", aParamList);

    let discTemplateConfigs: dtoDiscTemplateConfig[] = await this.dtoDiscTemplateConfigService.getdtoDiscTemplateConfigParamArrayPromise(aParamList);
    discTemplateConfigs.sort((a, b) => { return (a.txtAuthorityName > b.txtAuthorityName ? 1 : -1 && a.intCSVColumnNumber - b.intCSVColumnNumber) });

    this.discTemplateConfigs.data = discTemplateConfigs;


    console.log("this.discTemplateConfigs", this.discTemplateConfigs.data);

    this.tabDiscTemplateConfigs.selectedIndex = this.eDiscTemplateConfigTabs.DiscTemplateConfig;
    this.showSpinner = false;

  }


  async editDiscTemplateConfig(discTemplateConfig: dtoDiscTemplateConfig) {

    this.discTemplateConfig = await this.discTemplateConfigService.getDiscTemplateConfigPromise(discTemplateConfig.rowguid);
    this.electricalAuthority = await this.electricalAuthoritySpecService.getElectricalAuthoritySpecPromise(discTemplateConfig.guElectricalAuthoritySpecId);

    this.tabDiscTemplateConfigs.selectedIndex = this.eDiscTemplateConfigTabs.UpdateDiscTemplateConfig;

  }


  updatedLoadingDocket(discTemplateConfig: dtoDiscTemplateConfig) {


  }

  
  async discTemplateConfigCreated(discTemplateConfigCreatedObj: [DiscTemplateConfig, string]) {
    if (discTemplateConfigCreatedObj == null) {
      return;
    }

    console.log("discTemplateConfigCreatedObj: ", discTemplateConfigCreatedObj[1]);

    if (discTemplateConfigCreatedObj[1] == "create") {
      this.tabDiscTemplateConfigs.selectedIndex = this.eDiscTemplateConfigTabs.DiscTemplateConfig;
      this.discTemplateConfigs.data = [];

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("rowguid", discTemplateConfigCreatedObj[0].rowguid));
      aParamList.push(aParam);

      this.discTemplateConfigs.data = await this.dtoDiscTemplateConfigService.getdtoDiscTemplateConfigParamArrayPromise(aParamList);

      console.log("discTemplateConfigs: ", this.discTemplateConfigs.data);

    }

  }

  async discTemplateConfigUpdated(discTemplateConfig: DiscTemplateConfig) {

    if (discTemplateConfig == null) {
      return;
    }

    this.tabDiscTemplateConfigs.selectedIndex = this.eDiscTemplateConfigTabs.DiscTemplateConfig;
    this.discTemplateConfigs.data = [];
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("rowguid", discTemplateConfig.rowguid));
    aParamList.push(aParam);

    console.log("rowguid: ", discTemplateConfig.rowguid);

    this.discTemplateConfigs.data = await this.dtoDiscTemplateConfigService.getdtoDiscTemplateConfigParamArrayPromise(aParamList);

    console.log("discTemplateConfigUpdated: ", this.discTemplateConfigs.data);

  }

  discTemplateConfigCancelled(val: boolean) {
    this.selectedTab = this.eDiscTemplateConfigTabs.DiscTemplateConfig;
  }

}
