import { PoleInspectionItemIdentifier } from "../business-objects/pole-inspection-item-identifier.bo";
import { Printer } from "../business-objects/printer.bo";

export interface PoleInspectionTags {
  poleInspectionItemIdentifiers: PoleInspectionItemIdentifier[];
  Printer: Printer;
}

export class PoleInspectionTags {
  poleInspectionItemIdentifiers: PoleInspectionItemIdentifier[];
  Printer: Printer;
}
