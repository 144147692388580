<div class="order-item-list-container" id="orderItemContainer">

  <app-app-user-column-set-bar [componentColumns]="displayedOrderItems" [pageName]="'Floor Order Items'" [container]="columnBarContainer" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)"></app-app-user-column-set-bar>

  <table mat-table #tblOrderItems id="tblOrderItems{{appIndex}}" multiTemplateDataRows [dataSource]="datasource" class="mat-elevation-z8" matSort *ngIf="datasource != null">

    <ng-container matColumnDef="intItemNo">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD finalItemNumber"> {{orderItem.intItemNo}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD footerTD">  </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerName">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Customer </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD customerName"> {{orderItem.txtCustomerName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtOrderNo">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Order No </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD orderNo"> {{orderItem.txtOrderNo}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtProductTypeName">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Product Name </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD productName"> {{orderItem.txtProductShortName != null && orderItem.txtProductShortName != "" ? orderItem.txtProductShortName : orderItem.txtProductName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtProfileName">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRight" mat-sort-header> Profile </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD profileName"> {{orderItem.txtProfileAbbrievation != null && orderItem.txtProfileAbbrievation != "" ? orderItem.txtProfileAbbrievation : orderItem.txtProfileName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltWidth">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Width </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD width alignRightTD"> {{orderItem.fltWidth}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltThickness">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Thickness </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD thickness alignRightTD"> {{orderItem.fltThickness}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtLengthKN">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Length / KN </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD lengthKN"> {{orderItem.intPoleLength}} {{orderItem.intPoleLength != null ? '/' : '' }} {{orderItem.intPoleKN}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltLength">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Length </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD length alignRightTD"> {{orderItem.fltLength}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltStockItemLength">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Stock Item Length </th>
      <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD alignRightTD stockItemLength">
        <span style="display: inline-block; border: 0px solid green;">
          <mat-form-field class="example-full-width header-button" style="width: 100px !important; padding-right: 0px !important;" appearance="fill">
            <input class="textbox-input inline-textbox" matInput type="number" min="0" value="{{orderItem.fltLength}}" id="txtStockItemLength{{rowIndex}}" #txtStockItemLength>
          </mat-form-field>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerStockItemLength"></td>
    </ng-container>

    <ng-container matColumnDef="fltDiameter">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Diameter </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD diameter alignRightTD"> {{orderItem.fltDiameter == 0 ? '' : orderItem.fltDiameter}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtSpecies">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Species </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD species"> {{orderItem.txtSpeciesName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtGradeName">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Grade </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD grade"> {{orderItem.txtGradeName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtTreatmentType">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Treatment </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD treatment"> {{orderItem.txtTreatmentType}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtTreatmentHazardLevel">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Hazard Level </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD hazardLevel"> {{orderItem.txtTreatmentHazardLevel}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtStockKeepingUnit">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> SKU </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD stockCode"> {{orderItem.txtStockKeepingUnit}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltQtyTotal">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Quantity Total </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD finalQuantityOnOrder alignRightTD"> {{orderItem.fltQuantityTotal}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRight footerTD"> </td>
    </ng-container>

    <ng-container matColumnDef="fltQtyBal">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Quantity Bal </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD finalQuantityOnOrder alignRightTD"> {{orderItem.fltQtyBal}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRight footerTD"> </td>
    </ng-container>

    <ng-container matColumnDef="intFinalQuantity">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Final Quantity </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD finalFinalQuantity alignRightTD"> {{orderItem.intFinalQuantity}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRight footerTD"> </td>
    </ng-container>

    <ng-container matColumnDef="fltQuantity">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Quantity To Add </th>
      <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD alignRightTD quantity">
        <span style="display: inline-block; border: 0px solid green;">
          <mat-form-field class="example-full-width header-button" style="width: 100px !important; padding-right: 0px !important;" appearance="fill">
            <input class="textbox-input inline-textbox" matInput type="number" min="0" value="{{orderItem.fltQtyBal}}" id="txtQuantity{{rowIndex}}" #txtQuantity (keyup)="validateQuantity(txtQuantity.value, orderItem, rowIndex);$event.stopPropagation();" (change)="validateQuantity(txtQuantity.value, orderItem, rowIndex);$event.stopPropagation();" [disabled]="orderItem.fltQtyBal > 0 ? false : true">
          </mat-form-field>
        </span>
        <span style="display: inline-block; padding-left: 10px; border: 0px solid green; visibility: hidden; width: 0px; vertical-align: bottom; padding-bottom: 25px;" id="spanSpinnerContainer{{rowIndex}}" [@displaySpinner]="showSpinner ? 'open' : 'closed'">
          <mat-progress-spinner class="example-margin products-spinner inline-spinner" color="primary" mode="indeterminate" [diameter]="30" value="50"></mat-progress-spinner>
        </span>
        <span style="display: inline-block; padding-left: 10px; border: 0px solid green; visibility: hidden; width: 0px; vertical-align:bottom; padding-bottom:25px;" id="iconTickContainer{{rowIndex}}" [@displayTick]="showTick ? 'open' : 'closed'">
          <mat-icon class="done-icon">done</mat-icon>
        </span>
        <span style="display: inline-block; padding-left: 10px; border: 0px solid green; visibility: hidden; width: 0px; vertical-align:bottom; padding-bottom:25px;" id="iconCancelContainer{{rowIndex}}" [@displayCancel]="showCancel ? 'open' : 'closed'">
          <mat-icon class="cancel-icon" matTooltip="Cancel update" (click)="cancelQuantity(orderItem, rowIndex)">cancel</mat-icon>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerQuantity"> <div class="quantityFooterTD">{{orderItemQtyTotalInput}}</div></td>
    </ng-container>

    <ng-container matColumnDef="fltQtyAdded">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Quantity Added </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD qtyAdded alignRightTD"> {{orderItem.fltQtyAdded}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRight footerTD"> </td>
    </ng-container>

    <ng-container matColumnDef="txtQuantityUOM">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Qty UOM </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD quantityUOM"> {{orderItem.txtQuantityUoM}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtAddress">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH addressTH" mat-sort-header> Address </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD address"> {{(orderItem.txtAlternateAddress != null || orderItem.txtAlternateAddress != '') ? orderItem.txtAlternateAddress : orderItem.txtAddress}} {{orderItem.txtTown}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtComments">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" mat-sort-header> Comments </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD comments"> {{orderItem.txtComments}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="fltM3">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" align="right" arrowPosition="before" mat-sort-header> M3 </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD M3 alignRightTD"> {{orderItem.fltM3}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerM3"> {{orderItemM3TotalInput}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualM3">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Actual M3 </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD actualM3 alignRightTD"> {{RoundNumber(orderItem.fltActualM3,3)}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerActualM3"> {{orderItemActualM3TotalInput}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualTonne">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Actual Tonne </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD actualTonne alignRightTD"> {{RoundNumber(orderItem.fltActualTonne,3)}} </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerActualTonne"> {{orderItemActualTonneTotalInput}} </td>
    </ng-container>

    <ng-container matColumnDef="guMoulderId">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header> Moulder </th>
      <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD alignRightTD machineRun">
        <div class="moulder-container">
          <div class="moulder-list" #moulderList></div>
          <div #moulderClearContainer [ngClass]="moulderList.innerHTML != '' && moulderList.innerHTML != null ? 'moulder-clear-container-visible' : 'moulder-clear-container-invisible'">
            <mat-icon class="cancel-icon" matTooltip="Clear Moulders" (click)="cancelMoulders(orderItem, rowIndex)">cancel</mat-icon>
          </div>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerQuantity"> </td>
    </ng-container>

    <ng-container matColumnDef="btnGetMoulder">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD alignRightTD btnGetMoulder">
        <button class="get-moulder-button" type="button" mat-flat-button color="primary" (click)="getMoulder(rowIndex)">Get Moulder</button>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerQuantity"> </td>
    </ng-container>

    <ng-container matColumnDef="btnNoMoulder">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD alignRightTD btnNoMoulder">
        <button class="get-moulder-button" type="button" mat-flat-button color="primary" (click)="setNoMoulder(rowIndex)">No Moulder</button>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerQuantity"> </td>
    </ng-container>

    <ng-container matColumnDef="intMachineRun">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH machineRunTH" arrowPosition="before" mat-sort-header> Machine Run </th>
      <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD alignRightTD machineRun">
        <div class="moulder-container">
          <div class="machine-run-list" #machineRunList></div>
          <div #machineRunClearContainer [ngClass]="machineRunList.innerHTML != '' && machineRunList.innerHTML != null ? 'machine-run-clear-container-visible' : 'machine-run-clear-container-invisible'">
            <mat-icon class="cancel-icon" matTooltip="Clear Machine Runs" (click)="cancelMachineRun(orderItem, rowIndex)">cancel</mat-icon>
          </div>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerQuantity"> <div class="quantityFooterTD">{{orderItemQtyTotalInput}}</div></td>
    </ng-container>

    <ng-container matColumnDef="btnCopyAbove">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD alignRightTD machineRun">
        <mat-icon class="tab-duplicate" matTooltip="Copy Last Machine Run(s) and Moulder(s)" (click)="copyAbove(rowIndex)">tab_duplicate</mat-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerQuantity"> </td>
    </ng-container>

    <ng-container matColumnDef="btnGetMachineRun">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD alignRightTD machineRun">
        <button class="get-machine-run-button" type="button" mat-flat-button color="primary" (click)="getMachineRun(rowIndex)">Get Machine Run</button>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerQuantity"> </td>
    </ng-container>

    <ng-container matColumnDef="btnAddMultipleLength">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH alignRightTH" arrowPosition="before" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD alignRightTD multipleLength">
        <button class="add-multiple-length-button" type="button" mat-flat-button color="primary" (click)="addMultipleLength(orderItem, rowIndex)">Add Multiple Length</button>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD alignRightTD footerTD footerQuantity"> </td>
    </ng-container>

    <ng-container matColumnDef="chkAddToPack">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" arrowPosition="before" mat-sort-header> Add To Pack </th>
      <td mat-cell *matCellDef="let orderItem; let rowIdx = dataIndex;" class="orderItemTD addToPack">
        <div #tooltip="matTooltip" matTooltip="" [matTooltipDisabled]="false" (mouseenter)="addToPackMouseEnter(tooltip, orderItem, rowIdx)" (mouseleave)="addToPackMouseLeave(tooltip)">
          <button class="add-to-pack-button" type="button" #btnAddToPack mat-flat-button color="primary" (click)="addItemChange(orderItem, rowIdx)" [disabled]="true">Add To Pack</button>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef class="orderItemTD footerTD footerAddToPack">  </td>
    </ng-container>

    <ng-container matColumnDef="btnEditQuantity">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH editQuantityTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let orderItem; let rowIndex = dataIndex" class="orderItemTD editQuantity">
        <div matTooltip="To change the quantity please either add final items or remove final items." [matTooltipDisabled]="!(orderItem.fltFinalM3 != null && orderItem.fltFinalM3 > 0)">
          <button class="edit-order-item-button" type="button" mat-flat-button color="primary" id="btnEditQuantity{{rowIndex}}" #btnEditQuantity (click)="editQuantity(orderItem, rowIndex, btnEditQuantity)" [disabled]="orderItem.fltFinalM3 != null && orderItem.fltFinalM3 > 0">Edit Quantity</button>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="btnEditOrderItem">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH orderItemEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD orderItemEdit">
        <button class="edit-order-item-button" type="button" mat-flat-button color="primary" (click)="editOrderItem(orderItem)">Edit</button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveOrderItem">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH orderItemEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let orderItem" class="orderItemTD orderItemRemove">
        <button class="remove-delivery-docket-order-item-button" mat-flat-button type="button" color="primary" (click)="removeOrderItem(orderItem)">Remove</button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="btnViewStockItems">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" aria-label="row actions"></th>
      <td mat-cell *matCellDef="let orderItem; let rowIdx = dataIndex" class="orderItemTD">
        <button mat-icon-button aria-label="expand row" type="button" (click)="expandElement(orderItem, rowIdx);$event.stopPropagation()">
          <mat-icon *ngIf="orderItemExpanded !== orderItem">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="orderItemExpanded === orderItem">keyboard_arrow_up</mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <th mat-header-cell *matHeaderCellDef class="orderItemTH" [attr.colspan]="displayedOrderItems.length"></th>
      <td mat-cell *matCellDef="let orderItem; let rowIdx = dataIndex" class="orderItemTD" [attr.colspan]="displayedOrderItems.length">
        <div class="example-element-detail" [@detailExpand]="orderItemExpanded == orderItem ? 'expanded' : 'collapsed'">
          <div class="stock-items-container">
            <app-bp-floor-order-item-stock-item-list [data]="orderItemStockItems"></app-bp-floor-order-item-stock-item-list>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedOrderItems"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedOrderItems;">
    </tr>
    <tr mat-footer-row *matFooterRowDef="displayedOrderItems"></tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" id="expandedRow" class="example-detail-row"></tr>

  </table>
  <div class="no-delivery-docket-orderitems-items" *ngIf="datasource == null"><h7>There are no order items</h7></div>
</div>

<div class="spinner-container" id="divSpinner" #spinner *ngIf="showSpinner" style="top:50px;left:200px;">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" [diameter]="30" value="50"></mat-progress-spinner>
  </div>
</div>

