import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class WOWService {

constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
  //this._baseUrl = baseUrl;
}


  WOWData(Article) {   //, Attribute

    return new Promise<any>(resolve => {
      //https://www.woolworths.com.au/apis/ui/Search/products?searchTerm=coffee
      //         https://www.woolworths.com.au/api/v3/ui/schemaorg/product/176411
      var url = "https://www.woolworths.com.au/apis/ui/products/" + Article;
      console.log(url);
      var result = this.http.get<JSON>(url).subscribe(
        data => {
          //var JSONResponse = JSON.parse(data);
          //var attValue = this.getProductProperty(JSONResponse, Attribute);
          //var attValue = this.getProductProperty(data, Attribute);
          console.log(data);
          resolve(data);
        });
    });
  }

  getProductProperty(JSONResponse, Attribute) {
  //var result = UrlFetchApp.fetch(url).getContentText();
  if (JSONResponse.pageCount === 0) {
    return "";
  }
  else {
    switch (Attribute) {
      case "Barcode":
        return JSONResponse[0].barcode;
        
      case "Price":
        return JSONResponse[0].Price;
        
      case "InstorePrice:":
        return JSONResponse[0].InstorePrice;
        
      case "Name":
        return JSONResponse[0].Name;
        
      case "IsNew":
        return JSONResponse[0].IsNew;
        
      case "IsOnSpecial":
        return JSONResponse[0].IsOnSpecial;
        
      case "InstoreIsOnSpecial":
        return JSONResponse[0].InstoreIsOnSpecial;
        
      case "IsEdrSpecial":
        return JSONResponse[0].IsEdrSpecial;
        
      case "SavingsAmount":
        return JSONResponse[0].SavingsAmount;
        
      case "WasPrice":
        return JSONResponse[0].WasPrice;
        
      case "IsInStock":
        return JSONResponse[0].IsInStock;
        
      case "Brand":
        return JSONResponse[0].Brand;
        
      case "IsInFamily":
        return JSONResponse[0].IsInFamily;
        
      case "tgawarnings":
        return JSONResponse[0].AdditionalAttributes.tgawarnings;
        
      case "tgawarning":
        return JSONResponse[0].AdditionalAttributes.tgawarning;
        
      case "vendorarticleid":
        return JSONResponse[0].AdditionalAttributes.vendorarticleid;
        
      case "vendorcostprice":
        return JSONResponse[0].AdditionalAttributes.vendorcostprice;
        
      case "Promotion":
        return JSONResponse[0].HeaderTag.Content;
        
    }
  }
}

}
