import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'truncateString'
})
export class TruncateStringPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: any, length: number = 20, suffix: string = '...'): any {
    let sanitizedContent = DOMPurify.sanitize(value);

    if (value != null && value.length > length) {
      let truncated: string = sanitizedContent.substring(0, length).trim() + suffix;
      sanitizedContent = truncated;
    }

    value = value.toString().replaceAll('\n', '</br>')

    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);

  }
}
