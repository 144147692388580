import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AppUserColumnSet } from '../../_shared/business-objects/app-user-column-set.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { AppService } from '../../_shared/services/app-services/app.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { AppUserColumnSetService } from '../../_shared/services/app-user-column-set.service';
import { dtoAppUserColumnSetService } from '../../_shared/services/dto-app-user-column-set.service';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AppPageService } from '../../_shared/services/app-page.service';
import { dtoAppUserColumnSet } from '../../_shared/business-objects/dto-app-user-column-set.bo';

export enum enAppUserColumnSetTabs { AppUserColumnSets = 0, SearchOptions = 1, CreateAppUserColumnSet = 2, UpdateAppUserColumnSet = 3 }

@Component({
  selector: 'app-app-user-column-set-search',
  templateUrl: './app-user-column-set-search.component.html',
  styleUrls: ['./app-user-column-set-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class AppUserColumnSetSearchComponent implements OnInit {

  @ViewChild('tabAppUserColumnSets', { static: false }) tabAppUserColumnSets: MatTabGroup;


  form: FormGroup;
  selectedTab: number;
  public enAppUserColumnSetTabs = enAppUserColumnSetTabs;

  appUserColumnSets: MatTableDataSource<dtoAppUserColumnSet>;
  appUserColumnSet: dtoAppUserColumnSet;

  appPages: AppPage[];
  selectedAppPages: string;


  apps: dtoIntKeyValue[] = [];

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  totalRows: number = 0;

  resultTime: number;
  searchTime: number;

  showSpinner: boolean = false;

  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private route: ActivatedRoute, private formService: FormService
    , private dtoAppUserColumnSetService: dtoAppUserColumnSetService, private appUserColumnSetService: AppUserColumnSetService, private appPageService: AppPageService, private appService: AppService
    , private ref: ChangeDetectorRef  ) {

    this.form = this.fb.group({
      txtSearchAppUserColumnSetName: [''],
      txtSearchAppUserFirstName: [''],
      txtSearchAppUserLastName: [''],
      txtSearchAppUser: [''],
      guAppPageId: [''],
      radSearchApps: [''],
      dteSearchDateCreatedFrom: [''],
      dteSearchDateCreatedTo: ['']
    });

    this.appUserColumnSets = new MatTableDataSource();


    this.apps = this.sharedService.getEnumObjectKeyValue(appService.enApp);
    this.apps.splice(0, 0, new dtoIntKeyValue(-1, "None"));

    this.form.controls['radSearchApps'].setValue(-1);

  }

  ngOnInit(): void {

    this.selectedTab = this.enAppUserColumnSetTabs.AppUserColumnSets;

    this.route.queryParams.subscribe(params => {

      this.formService.setFieldsFromQueryParams(this.filterToQueryParamMap, params, this.form);

    });

    this.loadData();

  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  async loadData() {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    //aParam.push(new SQLParamArray("dteDateCreated", '2022-01-01', SQLParamArray.enSQLOperator.GreaterThanOrEqualTo));
    aParamList.push(aParam);

    this.appPages = await this.appPageService.getAppPageParamArrayPromise(aParamList);
    let appPage: AppPage = new AppPage;
    appPage.txtPageName = "None";
    appPage.rowguid = this.sharedService.EmptyGuid;

    this.appPages.splice(0, 0, appPage);


  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    if (e.index == this.enAppUserColumnSetTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchAppUserFirstName");
    }

  }


  searchAppUserColumnSets() {

    let btnSeach = document.getElementById("btnSearchAppUserColumnSet");
    if (btnSeach != null) {
      btnSeach.focus();
    }


    this.showSpinner = true;

    this.resultTime = 0;
    this.searchTime = Date.now();

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let appUserFirstName: string = this.form.controls["txtSearchAppUserFirstName"].value;
    let appUserLastName: string = this.form.controls["txtSearchAppUserLastName"].value;
    let appUserColumnSetName: string = this.form.controls["txtSearchAppUserColumnSetName"].value;
    let app: string = this.form.controls["radSearchApps"].value.toString();
    let appPageId: string = this.form.controls["guAppPageId"].value;
    let createdDateFrom: string = this.form.controls["dteSearchDateCreatedFrom"].value;
    let createdDateTo: string = this.form.controls["dteSearchDateCreatedTo"].value;
    const datepipe: DatePipe = new DatePipe('en-AU');


    this.totalRows = 0;

    this.appUserColumnSets.data = [];

    if (appUserFirstName != null && appUserFirstName.toString() != "") {
      aParam = [];
      if (appUserFirstName.indexOf(",")) {
        let aAppUserFirstNames: string[] = appUserFirstName.split(",");
        for (let i = 0; i <= aAppUserFirstNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtFirstName", aAppUserFirstNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtFirstName", appUserFirstName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (appUserLastName != null && appUserLastName.toString() != "") {
      aParam = [];
      if (appUserLastName.indexOf(",")) {
        let aAppUserLastNames: string[] = appUserLastName.split(",");
        for (let i = 0; i <= aAppUserLastNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtLastName", aAppUserLastNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtLastName", appUserFirstName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (appUserColumnSetName != null && appUserColumnSetName.toString() != "") {
      aParam = [];
      if (appUserColumnSetName.indexOf(",")) {
        let aAppUserColumnSetNames: string[] = appUserColumnSetName.split(",");
        for (let i = 0; i <= aAppUserColumnSetNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtName", aAppUserColumnSetNames[i].trim(), SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtName", appUserColumnSetName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (app != "" && app != null && parseInt(app) > -1) {
      aParam = [];
      aParam.push(new SQLParamArray("intApp", app, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "AppPage"))
      aParamList.push(aParam);
    }

    if (appPageId != "" && appPageId != null && appPageId != this.sharedService.EmptyGuid) {
      aParam = [];
      aParam.push(new SQLParamArray("guAppPageId", appPageId))
      aParamList.push(aParam);
    }


    if (createdDateFrom != "" && createdDateFrom != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdDateFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }

    if (createdDateTo != "" && createdDateTo != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdDateTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }


    if (aParamList.length == 0) {
      //this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "", 4000);
    }


    this.dtoAppUserColumnSetService.getdtoAppUserColumnSetParamArray(aParamList).subscribe(appUserColumnSets => {

      this.appUserColumnSets.data = appUserColumnSets;

      this.totalRows = appUserColumnSets.length;

      this.resultTime = ((Date.now() - this.searchTime) / 1000);

      this.showSpinner = false;

      //this.tabAppUserColumnSets.selectedIndex = this.enAppUserColumnSetTabs.AppUserColumnSets;
      //setTimeout(() => { this.selectedTab = this.enAppUserColumnSetTabs.AppUserColumnSets }, 3000);

      this.selectedTab = this.enAppUserColumnSetTabs.AppUserColumnSets;
      this.formService.setAutoFocusTimeout("txtSearchAppUserColumnSetName");


    }, err => {
      console.log(err);
    });


  }


  async editAppUserColumnSet(appUserColumnSet: dtoAppUserColumnSet) {


    this.appUserColumnSet = appUserColumnSet;
    console.log("editAppUserColumnSet", this.appUserColumnSet);


    this.tabAppUserColumnSets.selectedIndex = this.enAppUserColumnSetTabs.UpdateAppUserColumnSet;

  }

  async appUserColumnSetCreated(appUserColumnSet: AppUserColumnSet) {
    if (appUserColumnSet == null) {
      return;
    }

    this.tabAppUserColumnSets.selectedIndex = this.enAppUserColumnSetTabs.AppUserColumnSets;

    this.form.controls['txtSearchAppUserColumnSetName'].setValue(appUserColumnSet.txtName);

    this.searchAppUserColumnSets();

  }

  async appUserColumnSetUpdated(appUserColumnSet: AppUserColumnSet) {

    if (appUserColumnSet == null) {
      return;
    }

    this.tabAppUserColumnSets.selectedIndex = this.enAppUserColumnSetTabs.AppUserColumnSets;

    this.form.controls['txtSearchAppUserColumnSetName'].setValue(appUserColumnSet.txtName);

    this.searchAppUserColumnSets();

  }

  appUserColumnSetCancelled() {

    this.tabAppUserColumnSets.selectedIndex = this.enAppUserColumnSetTabs.AppUserColumnSets;

  }

}
