import { Component } from '@angular/core';

@Component({
  selector: 'app-product-profile-update-dialog',
  templateUrl: './product-profile-update-dialog.component.html',
  styleUrl: './product-profile-update-dialog.component.scss'
})
export class ProductProfileUpdateDialogComponent {

}
