import { Injectable } from "@angular/core";
import { DBFieldFunction, SQLParamArray } from "../../business-objects/sql-param-array";

@Injectable()
export class SQLParamService {

  addParameter(aParamList: SQLParamArray[][], value: (string | Array<string>), field: string, emptyValue: boolean = false, sqlOperator: SQLParamArray.enSQLOperator = SQLParamArray.enSQLOperator.Equals, logicalOperator: SQLParamArray.enSQLLogicalOperator = SQLParamArray.enSQLLogicalOperator.AND, dbFieldFunctionArray: DBFieldFunction = null, tablename: string = "", sqlStatmentType: SQLParamArray.enSQLStatementType = SQLParamArray.enSQLStatementType.SelectSQL, queryNo: number = 1, subQueryClass: string = "") {
    let aParam: SQLParamArray[] = this.returnParameter(value, field, emptyValue, sqlOperator, logicalOperator, dbFieldFunctionArray, tablename, sqlStatmentType, queryNo, subQueryClass);

    if (aParam.length > 0) {
      aParamList.push(aParam)
    }

  }

  addMultiFieldParameter(aParamList: SQLParamArray[][], value: (string | Array<string>), fieldArray: string[], emptyValue: boolean = false, sqlOperator: SQLParamArray.enSQLOperator = SQLParamArray.enSQLOperator.Equals, logicalOperator: SQLParamArray.enSQLLogicalOperator = SQLParamArray.enSQLLogicalOperator.AND, dbFieldFunctionArray: DBFieldFunction = null, tablename: string = "", sqlStatmentType: SQLParamArray.enSQLStatementType = SQLParamArray.enSQLStatementType.SelectSQL, queryNo: number = 1, subQueryClass: string = "") {
    let aParam: SQLParamArray[] = [];

    let aFieldParam: SQLParamArray[] = [];
    for (let i = 0; i <= fieldArray.length - 1; i++) {
      aFieldParam = this.returnParameter(value, fieldArray[i], emptyValue, sqlOperator, logicalOperator, dbFieldFunctionArray, tablename, sqlStatmentType, queryNo, subQueryClass);
      for (let p = 0; p <= aFieldParam.length - 1; p++) {
        aParam.push(aFieldParam[p]);
      }
    }

    if (aParam.length > 0) {
      aParamList.push(aParam)
    }

  }

  returnParameter(value: (string | Array<string>), field: string, emptyValue: boolean = false, sqlOperator: SQLParamArray.enSQLOperator = SQLParamArray.enSQLOperator.Equals, logicalOperator: SQLParamArray.enSQLLogicalOperator = SQLParamArray.enSQLLogicalOperator.AND, dbFieldFunctionArray: DBFieldFunction = null, tablename: string = "", sqlStatmentType: SQLParamArray.enSQLStatementType = SQLParamArray.enSQLStatementType.SelectSQL, queryNo: number = 1, subQueryClass: string = "") {

    let aParam: SQLParamArray[] = [];

    if (emptyValue == false && (value == null || value == "null" || value == "" || field == null || field == "")) {
      return aParam;
    }

    console.log("value: ", value);
    if (Array.isArray(value) == true || value.toString().indexOf(",") > -1) {

      if (Array.isArray(value) == true) {
        for (let i = 0; i <= value.length - 1; i++) {
          aParam.push(new SQLParamArray(field, value[i].toString().trimStart(), sqlOperator, logicalOperator, dbFieldFunctionArray, tablename, sqlStatmentType, queryNo, subQueryClass));
        }
      }

      if (Array.isArray(value) == false) {
        let aArray: string[] = value.toString().split(",");
        for (let i = 0; i <= aArray.length - 1; i++) {
          aParam.push(new SQLParamArray(field, aArray[i].trimStart(), sqlOperator, logicalOperator, dbFieldFunctionArray, tablename, sqlStatmentType, queryNo, subQueryClass));
        }
      }

    }
    else {
      aParam.push(new SQLParamArray(field, value.toString(), sqlOperator, logicalOperator, dbFieldFunctionArray, tablename, sqlStatmentType, queryNo, subQueryClass));
    }

    return aParam;

  }

}
