import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-printer-location-create',
  templateUrl: './printer-location-create.component.html',
  styleUrls: ['./printer-location-create.component.css']
})
export class PrinterLocationCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
