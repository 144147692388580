export interface dtoBoardTallyRecordOrderItem
{
	dteLastUpdated: Date;
	fltBTOrderItemQuantity: number;
	fltOrderItemDiameter: number;
	fltOrderItemLength: number;
	fltOrderItemQtyBal: number;
	fltOrderItemQuantity: number;
	fltOrderItemQuantityTotal: number;
	fltOrderItemThickness: number;
	fltOrderItemWidth: number;
	fltStockItemDia: number;
	fltStockItemLength: number;
	fltStockItemQuantity: number;
	fltStockItemThickness: number;
	fltStockItemWidth: number;
	guBoardTallyRecordId: string;
	guOrderItemId: string;
	intBTOrderItemQuantityUoM: number;
	intOrderItemDiameterType: number;
	intOrderItemQuantityUoM: number;
	rowguid: string;
	txtBTOrderItemQuantityUoM: string;
	txtDescription: string;
	txtOrderItemGradeName: string;
	txtOrderItemProductName: string;
	txtOrderItemProductShortName: string;
	txtOrderItemProfileAbbrievation: string;
	txtOrderItemProfileName: string;
	txtOrderItemSpeciesName: string;
}

export class dtoBoardTallyRecordOrderItem
{
	dteLastUpdated: Date;
	fltBTOrderItemQuantity: number;
	fltOrderItemDiameter: number;
	fltOrderItemLength: number;
	fltOrderItemQtyBal: number;
	fltOrderItemQuantity: number;
	fltOrderItemQuantityTotal: number;
	fltOrderItemThickness: number;
	fltOrderItemWidth: number;
	fltStockItemDia: number;
	fltStockItemLength: number;
	fltStockItemQuantity: number;
	fltStockItemThickness: number;
	fltStockItemWidth: number;
	guBoardTallyRecordId: string;
	guOrderItemId: string;
	intBTOrderItemQuantityUoM: number;
	intOrderItemDiameterType: number;
	intOrderItemQuantityUoM: number;
	rowguid: string;
	txtBTOrderItemQuantityUoM: string;
	txtDescription: string;
	txtOrderItemGradeName: string;
	txtOrderItemProductName: string;
	txtOrderItemProductShortName: string;
	txtOrderItemProfileAbbrievation: string;
	txtOrderItemProfileName: string;
	txtOrderItemSpeciesName: string;
}

