import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as saveAs from 'file-saver';
import jsPDF from 'jspdf';
import { Observable } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import { SharedService } from '../_shared/services/shared.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {

  @ViewChild('downloadProgress') progressDiv: HTMLElement
  botanicalIsVisible: boolean = false;
  download$: Observable<Download>;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    //setTimeout(() => { this.progressDiv.innerHTML = "Progress test %"; console.log(this.progressDiv.innerHTML) }, 200);

    //this.progressDiv.innerHTML = "Progress test %";
    //this.fileUrl = this.sharedService.PrintServerURI + 'MTECHBotanical.zip';

  }

  fileUrl

  testPDF() {
    var pdf = new jsPDF('p', 'px', 'A4');
    let htmlElement: HTMLElement = document.createElement('div');
    htmlElement.innerHTML = "<div style='width:500px;height500px;background-color: #92a8d1;'>Test Div</div>";

    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/octet-stream'
    });

    pdf.html(htmlElement,
      {
        callback: (pdf) => {
          let arrBuffer: ArrayBuffer = pdf.output('arraybuffer');
          console.log(arrBuffer);

          this.http.post<Blob>("https://localhost:44358/api/Print/SaveFile/", arrBuffer, { headers: headers, responseType: 'blob' as 'json' }).subscribe(data => {
            //console.log("data: " + data);
          });

          pdf.save('Test.pdf');
        }
      });


  }


  download() {
    //return this.http.get('https://localhost:44358/api/Print/DownloadFinalLoadingDocket/' + finalInspectionNo, { responseType: 'blob' });  //
    //return this.http.get(this.sharedService.PrintServerURI + 'api/Print/DownloadFinalLoadingDocket/' + finalInspectionNo, { responseType: 'blob' });
    //let path: string = this._baseUrl + 'api/Print/DownloadLoadingDocket';

    let path: string = this.sharedService.PrintServerURI + 'api/Print/DownloadFile/MTECHBotanical.zip';
    console.log("path octet-stream", path);
    //path = 'https://localhost:44358/api/Print/DownloadFile/MTECHBotanical.zip';


    //this.downloadLocal(path, "MTECHBotanical.zip").subscribe(data => {
    //  //let mimeType: string = 'application/zip';
    //  //blob = new Blob([data.content], { type: mimeType });
    //  if (data.state == 'DONE') {
    //    console.log("Done");
    //  }
    //  if (data.state == 'IN_PROGRESS') {

    //    document.getElementById('downloadProgress').innerHTML = "Progress " + data.progress.toString() + " %";

    //    //console.log("Progress " + data.progress.toString() + " %");
    //  }

    //});

    this.downloadFile2(path).subscribe(
      data => {

        var fileName = "MTECHBotanical.zip";
        const contentDisposition = data.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        saveAs(data.body, fileName);
      },
      err => {
        console.error(err);
        //this.blockUI.stop();
        alert("Problem while downloading the file.\n" +
          "[" + err.status + "] " + err.statusText);
      });



    //this.http.get<Blob>(path, {
    //  responseType: 'json',
    //}).subscribe(file => {

    //  //const data = file;
    //  //const blob = new Blob([data], { type: 'application/octet-stream' });

    //  //this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));


    //  let mimeType: string = 'application/octet-stream';
    //  let blob: Blob = new Blob([file], { type: mimeType });

    //  //this.saveFileOnUserDevice({ content: blob, name: 'MTECHBotanical.zip' });

    //  const url = window.URL.createObjectURL(blob);
    //  window.open(url);



    //}, err => {
    //  window.alert('There was an error getting the file');

    //  console.log(err);
    //});;
  }


  downloadFile2(path: string): Observable<HttpResponse<Blob>> {

    var url = path;

    return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' });
  }


  //async downloadLargeFile(url: string, body: any): Promise<ArrayBuffer> {
  //  let fileBuffer = await this.http.post(url, body, {
  //    responseType: "arraybuffer",
  //  }).pipe(map((file: ArrayBuffer) => {
  //    return file;
  //  })).toPromise

  //  return fileBuffer;
  //}




  saveFileOnUserDevice = function (file) { // content: blob, name: string
    //if (navigator.msSaveBlob) { // For ie and Edge
    //  return navigator.msSaveBlob(file.content, file.name);
    //}
    //else {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(file.content);
      link.download = file.name;
      document.body.appendChild(link);
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      link.remove();
      window.URL.revokeObjectURL(link.href);
    //}
  }

  downloadLocal(url: string, filename?: string): Observable<Download> {
    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).pipe(this.downloadFile(blob => saveAs(blob, filename)))
  }


  downloadFile(
    saver?: (b: Blob) => void
  ): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
  return (source: Observable<HttpEvent<Blob>>) =>
    source.pipe(
      scan((previous: Download, event: HttpEvent<Blob>): Download => {
        if (this.isHttpProgressEvent(event)) {
          return {
            progress: event.total
              ? Math.round((100 * event.loaded) / event.total)
              : previous.progress,
            state: 'IN_PROGRESS',
            content: null
          }
        }
        if (this.isHttpResponse(event)) {
          if (saver && event.body) {
            saver(event.body)
          }
          return {
            progress: 100,
            state: 'DONE',
            content: event.body
          }
        }
        return previous
      },
        { state: 'PENDING', progress: 0, content: null }
      )
    )
  }

 isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response
}

 isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
  return event.type === HttpEventType.DownloadProgress
    || event.type === HttpEventType.UploadProgress
}


}


export interface Download {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
  progress: number
  content: Blob | null
}

