import { Component, Input, OnInit } from '@angular/core';
import { dtoLoadingDocket } from '../../_shared/business-objects/dto-loading-docket.bo';

@Component({
  selector: 'app-loading-check-list',
  templateUrl: './loading-check-list.component.html',
  styleUrls: ['./loading-check-list.component.css']
})
export class LoadingCheckListComponent implements OnInit {

  @Input() loadingDocket: dtoLoadingDocket;

  constructor() { }

  ngOnInit(): void {
  }

}
