export interface AppUserColumnSet
{
	blnCurrentView: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guAppPageId: string;
	guAppUserId: string;
	rowguid: string;
	txtName: string;
}

export class AppUserColumnSet
{
	blnCurrentView: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guAppPageId: string;
	guAppUserId: string;
	rowguid: string;
	txtName: string;
}

