import { BoardTallyRecordMachineRun } from "./board-tally-record-machine-run.bo";
import { BoardTallyRecordMoulder } from "./board-tally-record-moulder.bo";
import { dtoBoardTallyRecordOrderItem } from "./dto-board-tally-record-order-item.bo";
import { dtoStockItem } from "./dto-stock-item.bo";

export interface StockItemContainer {
  guStockContainerId: string
  stockItem: dtoStockItem
  boardTallyRecordMachineRunArray: BoardTallyRecordMachineRun[]
  boardTallyRecordMoulderArray: BoardTallyRecordMoulder[]
  boardTallyRecordOrderItemArray: dtoBoardTallyRecordOrderItem[]
}

export class StockItemContainer {
  guStockContainerId: string
  stockItem: dtoStockItem
  boardTallyRecordMachineRunArray: BoardTallyRecordMachineRun[]
  boardTallyRecordMoulderArray: BoardTallyRecordMoulder[]
  boardTallyRecordOrderItemArray: dtoBoardTallyRecordOrderItem[]
}
