import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { PoleInspectionTags } from '../../_shared/app-objects/PoleInspectionTags';
import { PoleTag } from '../../_shared/app-objects/PoleTag';
import { dtoPoleInspectionItemIdentifier } from '../../_shared/business-objects/dto-pole-inspection-item-identifier.bo';
import { PoleInspectionItemIdentifier } from '../../_shared/business-objects/pole-inspection-item-identifier.bo';
import { Printer } from '../../_shared/business-objects/printer.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { PrintService } from '../../_shared/services/app-services/print.service';
import { dtoPoleInspectionItemIdentifierService } from '../../_shared/services/dto-pole-inspection-item-identifier.service';
import { PoleInspectionItemIdentifierService } from '../../_shared/services/pole-inspection-item-identifier.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-inspection-pole-barcode',
  templateUrl: './inspection-pole-barcode.component.html',
  styleUrl: './inspection-pole-barcode.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class InspectionPoleBarcodeComponent {

  @ViewChild('txtNextNumberFrom') txtNextNumberFrom: MatInput;
  @ViewChild('txtNextNumberTo') txtNextNumberTo: MatInput;
  @ViewChild('numbersList') numbersList: MatInput;

  
  treatmentPlantPrinter: Printer;
  form: FormGroup;

  numberFromError: boolean = false;
  numberToError: boolean = false;
  numberFromErrorText: string = 'The number must be six digits or less';
  numberToErrorText: string = 'The number must be six digits or less';

  lastNumber: string;
  nextNumber: number;
  toNumber: number;
  tagsToCreate: number = 0;
  selectedTagsToPrint: PoleInspectionItemIdentifier[];

  showLastNumberError: boolean = false;
  searchNumbersExpanded: boolean = false;

  poleInspectionItemIdentifiers: PoleInspectionItemIdentifier[];

  constructor(private fb: FormBuilder, private sharedService: SharedService,
    @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private printService: PrintService, private dtoPoleInspectionItemIdentifierService: dtoPoleInspectionItemIdentifierService
    , private poleInspectionItemIdentifierService: PoleInspectionItemIdentifierService  ) {

    this.form = this.fb.group({
      txtIdentifier: [''],
      txtNextNumberFrom: [''],
      txtNextNumberTo: ['']
    });

  }

  ngOnInit(): void {

    this.setPrinter();
    this.getNextNumber();
    this.getLastNumberOfIdentifiers(20);

  }

  async getNextNumber() {

    let poleInspectionItemIdentifer: dtoPoleInspectionItemIdentifier = await this.dtoPoleInspectionItemIdentifierService.getdtoPoleInspectionItemIdentifierLastNumberPromise();

    if (poleInspectionItemIdentifer == null) {
      this.showLastNumberError = true;
      this.lastNumber = "509999";
    }
    else {
      this.lastNumber = poleInspectionItemIdentifer.intIdentifier.toString();
    }

    
    this.nextNumber = Number(this.lastNumber) + 1;

    this.showLastNumberError = false;

  }


  async setPrinter() {
    this.treatmentPlantPrinter = await this.printService.getPrinterByNamePromise("WEB-APP-TP", Printer.enType.Mobile);
    console.log("printer:", this.treatmentPlantPrinter);
  }

  async createNumbers(numberOfNumbers: number) {

    this.toNumber = Number(this.nextNumber) + Number(numberOfNumbers - 1);
    this.tagsToCreate = numberOfNumbers;
  }


  async createNextNumbers(numberOfNumbers: number) {

    if (numberOfNumbers == null || isNaN(numberOfNumbers)){

      await this.alertService.openSnackBarCustomDefaultPromise("No Number", "Please specifiy a number of numbers to create", "Ok");
      return;
    }

    let msg: string = await this.alertService.openSnackBarCustomDefaultShowCancel("Create Tags", "Are you sure you want to create and print " + numberOfNumbers.toString() + " pole tags?", "Yes", "No");
    if (msg != "Yes") {
      return;
    }

    this.poleInspectionItemIdentifiers = [];

    this.poleInspectionItemIdentifiers = await this.poleInspectionItemIdentifierService.createPoleInspectionItemIdentifierByNumberPromise(numberOfNumbers);

    console.log("identifiers: ", this.poleInspectionItemIdentifiers);

    let poleInspectionTags: PoleInspectionTags = new PoleInspectionTags();
    poleInspectionTags.poleInspectionItemIdentifiers = this.poleInspectionItemIdentifiers;
    poleInspectionTags.Printer = this.treatmentPlantPrinter;

    console.log("poleInspectionTags", poleInspectionTags);

    let printed: boolean = await this.printService.printInspectionItemIdentifiers(poleInspectionTags);

    if (printed == true) {
      this.alertService.openSnackBarDefault("The pole tags have been created and printed", "Close", "center", "bottom", "", 5000);
    }

    this.toNumber = null;
    this.tagsToCreate = 0;

    await this.getNextNumber();

    //this.alertService.openSnackBar("Pole tags have been created", "Close", "center", "bottom", "", 5000);

  }

  async clearSelectedNumbers() {

    this.selectedTagsToPrint = [];

    let tempIds: PoleInspectionItemIdentifier[] = this.poleInspectionItemIdentifiers;

    this.poleInspectionItemIdentifiers = [];

    setTimeout(() => { this.poleInspectionItemIdentifiers = tempIds }, 500);

    
  }


  async printNumbers() {

    if (this.selectedTagsToPrint == null || this.selectedTagsToPrint.length == 0) {
      await this.alertService.openSnackBarCustomDefaultPromise("No Tag Selection", "Please select poles tags to print.", "Ok");
      return;
    }

    let msg: string = await this.alertService.openSnackBarCustomDefaultShowCancel("Print Tags", "Are you sure you want to print the selected pole tags?", "Yes", "No");
    if (msg != "Yes") {
      return;
    }

    let poleInspectionTags: PoleInspectionTags = new PoleInspectionTags();
    poleInspectionTags.poleInspectionItemIdentifiers = this.selectedTagsToPrint;
    poleInspectionTags.Printer = this.treatmentPlantPrinter;

    console.log("poleInspectionTags", poleInspectionTags);

    let printed: boolean = await this.printService.printInspectionItemIdentifiers(poleInspectionTags);
    if (printed == true) {
      this.alertService.openSnackBarDefault("The pole tags have been printed", "Close", "center", "bottom", "", 5000);
    }
    
  }

  setSelectedIdentifier(pii: PoleInspectionItemIdentifier, selected: boolean) {

    //console.log("pii", pii);

    if (this.selectedTagsToPrint == null) {
      this.selectedTagsToPrint = [];
    }

    if (selected == true) {
      this.selectedTagsToPrint.push(pii);
    }

    if (selected == false) {
      for (let i = 0; i <= this.selectedTagsToPrint.length - 1; i++) {
        if (this.selectedTagsToPrint[i].rowguid == pii.rowguid) {
          this.selectedTagsToPrint.splice(i, 1);
          //console.log("this.selectedTagsToPrint: ", this.selectedTagsToPrint[i].rowguid + ",  pii.rowguid: " + pii.rowguid)
          break;
        }
      }
    }

    //console.log("this.selectedTagsToPrint", this.selectedTagsToPrint);

  }



  async getLastNumberOfIdentifiers(numberOfIdentifiers: number) {

    if (numberOfIdentifiers == null || isNaN(numberOfIdentifiers)) {

      await this.alertService.openSnackBarCustomDefaultPromise("No Number", "Please specifiy a number of numbers to get", "Ok");
      return;
    }

    this.poleInspectionItemIdentifiers = [];

    this.poleInspectionItemIdentifiers = await this.poleInspectionItemIdentifierService.getLastNumberOfIdentifiersPromise(numberOfIdentifiers);

  }


  printPoleBarcode() {
    //console.log(this.form.controls["txtIdentifier"].value);
    if (this.form.controls["txtIdentifier"].value == "") {
      this.alertService.openSnackBarError("You must enter a pole number", "Close", "center", "bottom", 2000, false, "");
      return false;
    }

    let poleNumber: number = this.form.controls["txtIdentifier"].value;

    let path: string = this.sharedService.PrintServerURI;
    //path = "https://localhost:44358/";

    let poleTag: PoleTag = new PoleTag();
    poleTag.Printer = this.treatmentPlantPrinter;
    poleTag.txtPoleNumber = poleNumber.toString();
    poleTag.txtSize = ""

    //console.log("printPoleTagByNumber", dataURL);
    this.printService.printInspectionPoleTag(poleTag);

  }

  setFromError() {
    //FOR SOME REASON WONT SET DIRECTLY IN THE validateFromNumber function
    //SO THRWOING IN A PROMISE... SEEMS TO WORK.
    return new Promise<true>(resolve => {
      this.numberFromError = true;
    });

  }

  setToError() {
    //FOR SOME REASON WONT SET DIRECTLY IN THE validateToNumber function
    //SO THRWOING IN A PROMISE... SEEMS TO WORK.
    return new Promise<true>(resolve => {
      this.numberToError = true;
    });

  }

  async validateFromNumber(input) {
    

    if (this.validateNumber(input) == false) {
      this.numberFromErrorText = 'The number must be six digits\nor less\nYou typed ' + input.value;
      input.value = input.value.toString().substring(0, 6)
      //await this.setFromError();
    }

    this.numberFromError = false;

  }

  async validateToNumber(input) {
    if (this.validateNumber(input) == false) {
      this.numberToErrorText = 'The number must be six digits\nor less\nYou typed ' + input.value;
      input.value = input.value.toString().substring(0, 6)
      //await this.setToError();
    }

    this.numberToError = false;
  }


  validateNumber(input): boolean {

    if (input.value > 999999) {
      return false;
    } 


    return true;
  }

  setError() {

  }

  async searchLastNumbers(lastNumber: number) {

    await this.getLastNumberOfIdentifiers(lastNumber);

  }


  async searchNumbers() {

    console.log("from: ", this.form.controls['txtNextNumberFrom'].value);

    let fromNumber: string = this.form.controls['txtNextNumberFrom'].value;
    let toNumber: string = this.form.controls['txtNextNumberTo'].value;

    if ((toNumber == null || toNumber == "") && (fromNumber != null && fromNumber != "")) {
      toNumber = fromNumber;
    }

    if (fromNumber == null || fromNumber == null || isNaN(Number(fromNumber))) {

      await this.alertService.openSnackBarCustomDefaultPromise("No Number", "Please specifiy a from number", "Ok");
      return;
    }

    if (toNumber == null || toNumber == null || isNaN(Number(toNumber))) {

      await this.alertService.openSnackBarCustomDefaultPromise("No Number", "Please specifiy a to number", "Ok");
      return;
    }

    if (Number(toNumber) < Number(fromNumber)) {

      await this.alertService.openSnackBarCustomDefaultPromise("Number Conflict", "The from number must be less than the to number", "Ok");
      return;
    }
    
    this.poleInspectionItemIdentifiers = [];

    this.poleInspectionItemIdentifiers = await this.poleInspectionItemIdentifierService.getIdentifiersBySearchNumbersPromise(Number(fromNumber), Number(toNumber))

    this.poleInspectionItemIdentifiers.sort((a, b) => { return a.intIdentifier > b.intIdentifier ? 1 : -1 });


  }

  showSearchNumbers() {

    this.searchNumbersExpanded = !this.searchNumbersExpanded;

  }
}
