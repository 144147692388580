
import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { dtoTPCharge } from '../../_shared/business-objects/dto-tpcharge.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { SharedService } from '../../_shared/services/shared.service';
import { CcaChargeItemViewComponent } from '../cca-charge-item-view/cca-charge-item-view.component';

@Component({
  selector: 'app-cca-charge-list',
  templateUrl: './cca-charge-list.component.html',
  styleUrl: './cca-charge-list.component.scss',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', [
        animate('425ms cubic-bezier(0.4, 0.0, 0.2, 1)'),  //225
        query('@*', animateChild(), { optional: true })
      ]),
    ]),
  ]
})
export class CcaChargeListComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoTPCharge>;
  @Input() columnBarPage: string;
  @Input() columnBarContainer: string;
  @Input() columnListId: string;

  @ViewChild('appChargeItemView') appChargeItemView: CcaChargeItemViewComponent

  showTick: boolean;
  showTOCs: boolean;
  showInlineSpinner: boolean;

  chargePDFId: string;

  chargeExpanded: dtoTPCharge = null;
  selectedRow: string = "";
  viewType: number = 0;

  displayedColumns: string[] = ['dteChargeDate', 'intChargeNo', 'txtTreatmentHazardLevel', 'blnBranded', 'intInitVacuumStart', 'intPressureStart', 'intFinalVacuumStart', 'intInitVacuumKpa', 'intPressureKpa', 'intInitVacuumMaxHeld', 'intPressureMaxHeld', 'intTankStart', 'intTankFlooded', 'intTankRefusal', 'intTankKickback', 'intTankEmpty', 'intTankFinish', 'intRateFlow', 'intSolutionStrength', 'txtComments', 'blnRecharge', 'btnEditCharge', 'btnRemoveCharge', 'btnDropbox', 'btnPrintCharge', 'btnPrintChargeSamples', 'btnEmailCharge', 'btnViewChargeItems'];

  constructor(private sharedService: SharedService) {

  }

  ngOnInit(): void {

    this.viewType = 0;

  }


  updatedComponentColumnsEvent(columns: string[]) {
    console.log("columns........:", columns);

    this.displayedColumns = columns;

  }

  printChargeSamples(tpCharge: dtoTPCharge) {
    this.printCharge(tpCharge, 'samples');
  }

  printCharge(tpCharge: dtoTPCharge, type: string) {

    console.log("tpCharge: ", tpCharge);

    let path: string = this.sharedService.LocalServerURI;
    if (location.hostname.indexOf('localhost') == -1) {
      path = 'https://' + location.hostname
    }

    var windowUrl = path + '/cca-charge-print?chargeid=' + tpCharge.rowguid + '&hidenav=yes&openprintdialog=yes';
    if (type == "samples") {
      windowUrl += "&samples=yes"
    }
    var uniqueName = new Date();
    var windowName = "_blank";
    var printWindow = window.open(windowUrl, windowName).focus();

    this.rowColorPropagation(tpCharge.rowguid);

  }


  expandElementAndSetColor(tpCharge: dtoTPCharge, idx: number) {

    let div: HTMLDivElement = document.getElementById("locationrow" + idx.toString()) as HTMLDivElement;
    console.log("div.getAttribute('data - dblclick')", div.getAttribute("data-dblclick"));

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(() => {
        if (div.getAttribute("data-dblclick") == "1") {
          this.setRowColor(tpCharge.rowguid);
          //window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 200);
    } else {
      div.removeAttribute("data-dblclick");
      this.expandElement(tpCharge, idx);
      this.selectedRow = tpCharge.rowguid;  //Always highlight row when double clicking.

      //window.alert('ondouble');
    }

    //this.chargeExpanded = tpCharge;

  }


  async expandElement(tpCharge: dtoTPCharge, index: number) {

    this.chargeExpanded = this.chargeExpanded === tpCharge ? null : tpCharge;

    if (this.chargeExpanded != null) {
      await this.loadChargeItems(tpCharge);
    }

    //await this.loadDeliveryDocketOrderItems(deliveryDocket);

    //await this.loadLinkedFinalItems(index)



  }

  async loadChargeItems(tpCharge: dtoTPCharge) {

    setTimeout(() => {
      this.appChargeItemView.tpChargId = tpCharge.rowguid;
      this.appChargeItemView.loadData();
    }, 500);

    //if (this.chargeExpanded == tpCharge) {
    //  let aParamList: SQLParamArray[][] = [];
    //  let aParam: SQLParamArray[] = [];

    //  aParam.push(new SQLParamArray("guLoadingDocketId", loadingDocket.rowguid, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL));
    //  aParamList.push(aParam);

    //  this.deliveryDockets = await this.dtoDeliveryDocketService.getDeliveryDocketPromiseParamArrayPromise(aParamList);
    //  if (this.deliveryDocketlist != null) {
    //    this.deliveryDocketlist.loadedComponent = true;
    //  }

    //}

  }

  async changeView(tpCharge: dtoTPCharge, type: number) {

    this.viewType = type;

    if (type == 0) {
      await this.loadChargeItems(tpCharge);
    }

  }

  setRowColor(deliveryDocketId: string) {

    if (this.selectedRow == "" || deliveryDocketId != this.selectedRow) {
      this.selectedRow = deliveryDocketId;
      return;
    }

    if (deliveryDocketId == this.selectedRow) {
      this.selectedRow = "";
      return;
    }

  }

  rowColorPropagation(rowId: string) {
    if (this.selectedRow == rowId) {
      window.event.stopPropagation();
    }
  }

  doubleclick(idx: number) {
    let div: HTMLDivElement = document.getElementById("locationrow" + idx.toString()) as HTMLDivElement;

    if (div.getAttribute("data-dblclick") == null) {
      div.setAttribute("data-dblclick", "1");
      setTimeout(function () {
        if (div.getAttribute("data-dblclick") == "1") {
          window.alert('single');
        }
        div.removeAttribute("data-dblclick");
      }, 300);
    } else {
      div.removeAttribute("data-dblclick");
      window.alert('ondouble');
    }
  }



}
