import { Component } from '@angular/core';

@Component({
  selector: 'app-grade-update-dialog',
  templateUrl: './grade-update-dialog.component.html',
  styleUrl: './grade-update-dialog.component.scss'
})
export class GradeUpdateDialogComponent {

}
