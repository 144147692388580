<form class="example-form printer-container-form" [formGroup]="form">
  <div class="printer-container" id="printerContainer">
    <div class="printers-heading"><h1>Printers</h1></div>

    <mat-tab-group color="accent" class="chh-mat-tab-group mat-mdc-tab-group" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabPrinters>
      <mat-tab label="Printers" class="tab-label printer-search-tab">
        <div class="search-fields-container">
          <div class="search-printer-no">
            <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
              <mat-label>Printer Name.</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Joinery Printer" value="" type="text" id="txtSearchPrinterName" formControlName="txtSearchPrinterName" (keydown.enter)="enterKeySearch('', $event)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple names by a comma i.e. Joinery Printer, Board Plant..." class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-buttons">
            <div class="printers-button-container">
              <button class="search-printer-button search-button" type="button" mat-flat-button color="primary" (click)="searchPrinters('', 0, $event)">Search</button>
            </div>
          </div>

        </div>

        <mat-divider></mat-divider>

        <div class="printer-list-app-container">
          <app-printer-list #appPrinterList [datasource]="printers" (editPrinterEvent)="editPrinter($event)" (addPrinterItemsEvent)="addPrinterItems($event)" (updatedPrinterEvent)="updatedPrinter($event)" (removePrinterEvent)="removePrinter($event)" [columnBarPage]="'Printers'"></app-printer-list>
        </div>

      </mat-tab>

      <mat-tab label="Search Options" class="tab-label search-option-tab">
        <div class="search-options-container">
          <div class="printer-search-options">
            <h3>Printer Search Parameters</h3>

            <div class="search-option-row-3">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
                <mat-label>Printer created date from</mat-label>
                <input id="dteDateCreatedFrom" class="date-input printer-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickerfrom" formControlName="dteDateCreatedFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerfrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerfrom></mat-datepicker>
              </mat-form-field>
            </div>


            <div class="search-option-row-4">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
                <mat-label>Printer created date to</mat-label>
                <input id="dteDateCreatedTo" class="date-input printer-date-search" #searchDateCreatedTo matInput [matDatepicker]="pickerto" formControlName="dteDateCreatedTo">
                <mat-datepicker-toggle matSuffix [for]="pickerto"></mat-datepicker-toggle>
                <mat-datepicker #pickerto></mat-datepicker>
              </mat-form-field>
            </div>


          </div>
        </div>

      </mat-tab>

      <mat-tab label="Create Printer" class="tab-label">
        <div class="create-printer-container">
          <app-printer-create class="create-printer-app" [inDialog]="false" (printerCreated)="printerCreated($event)" (printerCancelled)="printerCancelled($event)" *ngIf="selectedTab == ePrinterTabs.CreatePrinter"></app-printer-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Printer" class="tab-label">
        <div class="update-printer-container">
          <app-printer-update [printer]="printer" (printerUpdated)="printerUpdated($event)" (printerCancelled)="printerCancelled($event)" *ngIf="selectedTab == ePrinterTabs.UpdatePrinter"></app-printer-update>
        </div>
      </mat-tab>

      <mat-tab label="Add Printer Items" class="tab-label">
        <div class="add-printer-items-container">
          <!--<app-printer-add-items #printerAddItems [printer]="printerAddItem" (printerItemsAdded)="printerItemsAdded($event)" (printerItemsCancelled)="printerItemsCancelled($event)" *ngIf="selectedTab == ePrinterTabs.DocketAddItems"></app-printer-add-items>-->
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
