import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Orders } from '../business-objects/orders.bo';
import { OrderItemProcessOrderItem } from '../business-objects/order-item-process-order-item.bo';
import { SharedService } from './shared.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class OrderItemProcessOrderItemService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService) {
    this._baseUrl = baseUrl;
  }

  getOrderItemOrderItem(orderItemProcessOrderItemId: string) {
    return this.http.get<OrderItemProcessOrderItem>(this._baseUrl + 'api/OrderItemProcessOrderItem/' + orderItemProcessOrderItemId);
  }

  getOrderItemProcessOrderItemsByOrderItem(gOrderItemId: string) {
    return this.http.get<OrderItemProcessOrderItem[]>(this._baseUrl + 'api/OrderItemProcessOrderItem/GetOrderItemProcessOrderItemsByOrderItem/' + gOrderItemId);
  }

  createOrderItemProcessOrderItem(orderItemProcessOrderItem: OrderItemProcessOrderItem) {
    return this.http.post<OrderItemProcessOrderItem>(this._baseUrl + 'api/OrderItemProcessOrderItem/CreateOrderItemProcessOrderItem/', orderItemProcessOrderItem);
  }

  createOrderItemProcessOrderItemObject(gOrderItemId: string, gOrderItemProcessId: string, iOrder: number): OrderItemProcessOrderItem {
    const datepipe: DatePipe = new DatePipe('en-AU');
    let orderItemProcessOrderItem: OrderItemProcessOrderItem = new OrderItemProcessOrderItem();

    orderItemProcessOrderItem.guOrderItemId = gOrderItemId;
    orderItemProcessOrderItem.guOrderItemProcessId = gOrderItemProcessId;
    orderItemProcessOrderItem.intOrder = iOrder;
    orderItemProcessOrderItem.intStatus = 0;
    orderItemProcessOrderItem.dteStatusDate = new Date(datepipe.transform(Date.now(), 'yyyy-MM-dd'));

    return orderItemProcessOrderItem;
  }

}
