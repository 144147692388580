<div class="equipment-selection-container">
  <div class="equipment-selection-form" [formGroup]="form">
    <div class="equipment">
      <mat-form-field class="chh-form-field">
        <div class="equipment-input-container">
          <input matInput class="equipment-input" placeholder="Filter by name" placeholderLabel="i.e. XN54....." value="" type="text" #equipmentFilter (keyup)="filterEquipment(equipmentFilter.value)" (focus)="equipmentFilter.select()">
          <br /><br />
        </div>
      </mat-form-field>
      <mat-selection-list class="equipment-select" #guEquipmentId [multiple]="false" formControlName="guEquipmentId" (selectionChange)="mobilePlantEquipmentSelect(guEquipmentId._value)">
        <mat-list-option *ngFor="let equipment of mobilePlantEquipment" [value]="equipment" [selected]="equipment.rowguid == selectedMobilePlantEquipmentId">
          {{equipment.txtIdentifierCode}}{{equipment.intIdentifier > 0 ? equipment.intIdentifier : ''}}{{equipment.txtName != 'None' ? ' - ' : ''}}{{equipment.txtAbbreviation != '' && equipment.txtAbbreviation != null ? equipment.txtAbbreviation : equipment.txtName}}
        </mat-list-option>
      </mat-selection-list>
      <br />
    </div>
  </div>
</div>
