import { Injectable } from '@angular/core';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { AuthService } from '../../../account/auth.service';
import { User } from '../../../account/user';
import { AlertsService } from '../../../alerts.service';
import { FileAttachment } from '../../app-objects/file-attachment';
import { MicrosoftGraphFileAttachment } from '../../app-objects/microsoft-graph-file-attachment';
import { AlertService } from '../alert.service';



@Injectable({
  providedIn: 'root'
})

export class GraphService {

  constructor(
    private authService: AuthService,
    private alertsService: AlertsService,
    private alertService: AlertService) { }

  async getCalendarView(start: string, end: string, timeZone: string): Promise<MicrosoftGraph.Event[] | undefined> {
    if (!this.authService.graphClient) {
      this.alertsService.addError('Graph client is not initialized.');
      return undefined;
    }

    try {
      // GET /me/calendarview?startDateTime=''&endDateTime=''
      // &$select=subject,organizer,start,end
      // &$orderby=start/dateTime
      // &$top=50
      const result = await this.authService.graphClient
        .api('/me/calendarview')
        .header('Prefer', `outlook.timezone="${timeZone}"`)
        .query({
          startDateTime: start,
          endDateTime: end
        })
        .select('subject,organizer,start,end')
        .orderby('start/dateTime')
        .top(50)
        .get();

      return result.value;
    } catch (error) {
      this.alertsService.addError('Could not get events', JSON.stringify(error, null, 2));
    }
    return undefined;
  }

  async addEventToCalendar(newEvent: MicrosoftGraph.Event): Promise<void> {
    if (!this.authService.graphClient) {
      this.alertsService.addError('Graph client is not initialized.');
      return undefined;
    }

    try {
      // POST /me/events
      await this.authService.graphClient
        .api('/me/events')
        .post(newEvent);
    } catch (error) {
      throw Error(JSON.stringify(error, null, 2));
    }
  }

  async sendMail(from: string, to: string, subject: string, message: string, cc: string = "", bcc: string = "", attachments: MicrosoftGraphFileAttachment[] = []): Promise<void> {
    //newMessage: MicrosoftGraph.Message



    if (!this.authService.graphClient) {
      this.alertsService.addError('Graph client is not initialized.');
      await this.alertService.openSnackBarCustomPromise("Graph client is not initialized", "Graph client is not initialized", "Ok", "", "center", "bottom", "", 0, false);
      return undefined;
    }

    console.log("In sendMail");

    if (to == null || to == "") {
      await this.alertService.openSnackBarCustomPromise("Send Email Error", "There is no To: address supplied", "Ok", "", "center", "bottom", "", 0, false);
      return;
    }

    if (from == null || from == "") {
      await this.alertService.openSnackBarCustomPromise("Send Email Error", "There is no From: address supplied", "Ok", "", "center", "bottom", "", 0, false);
      return;
    }

    //{
    //  '@odata.type': '#microsoft.graph.fileAttachment',
    //    name: 'attachment.txt',
    //      contentType: 'text/plain',
    //        contentBytes: 'SGVsbG8gV29ybGQh'
    //}


    let toAddresses = [];
    let ccAddresses = [];
    let bccAddresses = [];
    let attachmentsArr = [];

    if (to != "") {
      to = to.replace(" ", "");
      let aTo: string[] = to.split(";");
      aTo.forEach(s => {
        toAddresses.push({ emailAddress: { address: s.replace("\n","") } })
      });
    }

    if (cc != "") {
      cc = cc.replace(" ", "");
      let aCC: string[] = cc.split(";");
      aCC.forEach(s => {
        ccAddresses.push({ emailAddress: { address: s.replace("\n", "") } })
      });
    }

    if (bcc != "") {
      bcc = bcc.replace(" ", "");
      let bCC: string[] = bcc.split(";");
      bCC.forEach(s => {
        bccAddresses.push({ emailAddress: { address: s.replace("\n", "") } })
      });
    }

    if (attachments != null && attachments.length > 0) {
      attachments.forEach(f => {
        let contentBytes: string = f.contentBytes;
        let base64Index: number = contentBytes.indexOf("base64,");
        if (base64Index > -1) {
          contentBytes = contentBytes.substring(base64Index + 7, contentBytes.length);
        }
        //console.log("base64Index: ", base64Index);
        //console.log("name: ", f.name);
        //console.log("contentType: ", f.contentType);

        attachmentsArr.push({ '@odata.type': '#microsoft.graph.fileAttachment', name: f.name, contentType: f.contentType, contentBytes: contentBytes })
        //console.log("contentBytes: ", contentBytes);
      });
    }

    // Replace javascript newline with HTML <br>
    message = message.replace(/\n/g, '<br />');

    const sendMail = {
      message: {
        subject: subject,
        body: {
          contentType: 'HTML',
          content: message
        },
        toRecipients: toAddresses
        ,
        from:  
            {
              emailAddress: 
              {
                address: from
              }
        },
        ccRecipients: ccAddresses
        ,
        bccRecipients: bccAddresses,
        attachments: attachmentsArr
      },
      saveToSentItems: 'true'
    };

    console.log("sendMail", sendMail);

    try {
      // POST /me/events
      await this.authService.graphClient
        .api('/me/sendMail')
        .post(sendMail);
    } catch (error) {
      throw Error(JSON.stringify(error, null, 2));
    }
  }


  async getGetOneNotePage(pageId: string, contentFormat: string): Promise<MicrosoftGraph.OnenotePage | undefined> {
    if (!this.authService.graphClient) {
      this.alertsService.addError('Graph client is not initialized.');
      return undefined;
    }

    try {
      // GET /me/calendarview?startDateTime=''&endDateTime=''
      // &$select=subject,organizer,start,end
      // &$orderby=start/dateTime
      // &$top=50
      const result = await this.authService.graphClient
        //.api('/me/onenote/notebooks').version('v1.0')
        .api('/me/onenote/pages').version('v1.0')
        //.api('/me/onenote/pages/' + pageId).version('v1.0')
        //.api('/me/onenote/pages/1-b10c06c94a23486e986ff15bd85a51f5!62-a4be0929-de43-4c9e-bef9-80e2f685c706').version('v1.0')
        //.header('Prefer', `outlook.timezone="${timeZone}"`)

        //.query({
        //  format: contentFormat
        //})

        //.select('subject,organizer,start,end')
        //.orderby('start/dateTime')
        //.top(50)
        .get();
      
      return result.value;
    } catch (error) {
      this.alertsService.addError('Could not get events', JSON.stringify(error, null, 2));
    }
    return undefined;
  }


}
