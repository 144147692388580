import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { dtoProductProfile } from '../../_shared/business-objects/dto-product-profile.bo';
import { ProductProfile } from '../../_shared/business-objects/product-profile.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoProductProfileService } from '../../_shared/services/dto-product-profile.service';
import { ProductProfileService } from '../../_shared/services/product-profile.service';
import { ProductProfileUpdateDialogComponent } from '../product-profile-update-dialog/product-profile-update-dialog.component';

@Component({
  selector: 'app-product-profile-select',
  templateUrl: './product-profile-select.component.html',
  styleUrl: './product-profile-select.component.scss'
})
export class ProductProfileSelectComponent {
  @ViewChild('productProfileFilter') productProfileFilter: ElementRef;
  @ViewChild('productProfileListContainer') productProfileListContainer: ElementRef;
  @ViewChild('multipleProductProfiles') multipleProductProfiles: MatSlideToggle;


  @Input() productProfiles: dtoProductProfile[];
  @Output() selectedProductProfilesEvent = new EventEmitter<dtoProductProfile[]>();

  showSpinner: boolean
  addressHoverId: string;

  productProfileUpdateDialogRef: MatDialogRef<ProductProfileUpdateDialogComponent>;
  selectedProductProfiles: dtoProductProfile[];
  productProfilesFiltered: dtoProductProfile[];
  productProfileExpanded: dtoProductProfile;

  productProfileFilteredItems: HTMLCollectionOf<HTMLDivElement>;

  showProductProfileList: boolean = false;
  showProductProfileListTransition: boolean = false;

  productProfileSelectionExpanded: boolean = false;
  productProfileListPinned: boolean = false;

  toolTipText: string = "pin";

  constructor(private productProfileService: ProductProfileService, private dtoProductProfileService: dtoProductProfileService
    , private alertService: AlertService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.selectedProductProfiles = [];

    document.addEventListener("click", this.openCloseProductProfileList.bind(this));

  }

  openCloseProductProfileList(e) {

    if (this.productProfileListPinned == true) {
      return;
    }

    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.pushPin != null) {
      return;
    }


    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.productProfileInput != null && this.productProfileFilter.nativeElement.value != null && this.productProfileFilter.nativeElement.value.toString() != "") {
      this.showProductProfileList = true;
      //console.log("this.showCustomerList = true", this.showCustomerList);
      //console.log("customerListContainer", this.customerFilter.nativeElement.value);

      return;
    }

    //if (e == null || e.target == null || e.target.dataset == null || e.target.dataset.productProfileSelect == null) {

    // ALWAYS CLOSE THE SEARCH LIST UNLESS I'M PINNED
    if (this.productProfileListPinned == false) {
      this.showProductProfileList = false;

      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      //console.log("this.showCustomerList = false", this.showCustomerList);
      return;
    }

    //let domStringMap: DOMStringMap = e.target.dataset;

    //console.log(domStringMap.customerSelect.toString() == "customerSelect");
    //console.log(domStringMap.customerSelect.toString());
  }

  checkProductProfileListIsOpen(inputValue: string) {

    //console.log("inputValue", inputValue);

    if (inputValue == null || inputValue == "") {
      this.showProductProfileList = false;
      //console.log("inputValue empty", inputValue);
      return;
    }

    this.showProductProfileList = true;


  }


  rowEnter(productProfileId: string) {
    this.addressHoverId = productProfileId;
    //console.log("row enter");
  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");
  }

  async selectProductProfile(productProfile: dtoProductProfile, selected: boolean) {

    if (productProfile == null) {
      this.alertService.openSnackBarDefault("There was a problem selecting the productProfile - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    // Remove All ProductProfile Contact
    if (this.multipleProductProfiles != null && this.multipleProductProfiles.checked == false) {

      let productProfileSelected: dtoProductProfile[] = this.productProfiles.filter((a) => { return a.guSelectedProductProfileId != null && a.guSelectedProductProfileId != "" });
      for (let i = 0; i <= productProfileSelected.length - 1; i++) {
        productProfileSelected[i].guSelectedProductProfileId = "";
      }

      for (let i = this.selectedProductProfiles.length - 1; i >= 0; i--) {
        this.selectedProductProfiles.splice(i, 1);
      }

      for (let i = this.productProfilesFiltered.length - 1; i >= 0; i--) {
        this.productProfilesFiltered[i].guSelectedProductProfileId = "";
      }

    }

    // Remove ProductProfile Contact
    if (selected == false) {
      for (let i = this.selectedProductProfiles.length - 1; i >= 0; i--) {
        if (this.selectedProductProfiles[i].rowguid == productProfile.rowguid) {
          this.selectedProductProfiles.splice(i, 1);
          productProfile.guSelectedProductProfileId = "";
          //console.log("unselected productProfile");
          this.selectedProductProfilesEvent.emit(this.selectedProductProfiles);
          break;
        }
      }
    }

    // Add ProductProfile Contact
    if (selected == true) {

      productProfile.guSelectedProductProfileId = "tempvalue";

      this.selectedProductProfiles.push(productProfile);
      this.selectedProductProfilesEvent.emit(this.selectedProductProfiles);

      if (this.multipleProductProfiles.checked == false && this.productProfileListPinned == false) {
        this.showProductProfileList = false;
      }

      //console.log("added productProfile");

    }


  }

  async updateProductProfile(productProfile: dtoProductProfile) {

    console.log(productProfile);
    if (productProfile == null) {
      this.alertService.openSnackBarDefault("There is no productProfile to edit");
      return;
    }

    if (this.productProfileUpdateDialogRef != null) {
      this.productProfileUpdateDialogRef.close();
    }

    this.productProfileUpdateDialogRef = this.dialog.open(ProductProfileUpdateDialogComponent, {
      hasBackdrop: false,
      height: '95vh',
      maxWidth: '90vw',
      data: { selectedProductProfile: productProfile }
    });

    this.productProfileUpdateDialogRef.backdropClick().subscribe(() => {
      this.productProfileUpdateDialogRef.close();
    });


    this.productProfileUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, productProfile: ProductProfile }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          let cust: dtoProductProfile = await this.dtoProductProfileService.getdtoProductProfilePromise(data.productProfile.rowguid);
          this.selectedProductProfiles.push(cust);

          for (let i = 0; i <= this.productProfiles.length - 1; i++) {
            if (this.productProfiles[i].rowguid == data.productProfile.rowguid) {
              this.productProfiles[i] = cust;
              this.productProfiles[i].guSelectedProductProfileId = "tempvalue";
              break;
            }
          }

          this.productProfiles.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

          this.alertService.openSnackBarDefault("The ProductProfile has been updated");

        }
        else {
          // We have cancellled

        }

      });

  }

  addProductProfile() {

  }

  nextProductProfile(e, productProfile: dtoProductProfile) {

    //console.log("e ", e);

    if (e.code.toLowerCase() == "escape") {
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      this.productProfileFilter.nativeElement.focus();
      this.productProfileFilter.nativeElement.setSelectionRange(this.productProfileFilter.nativeElement.value.length, this.productProfileFilter.nativeElement.value.length);

      this.showProductProfileList = false;
      return;
    }

    if ((e.code.toLowerCase() == "arrowdown" || e.code.toLowerCase() == "arrowup") && this.showProductProfileList == true) {
      //console.log("e ", e);

      if (this.productProfileFilteredItems != null) {

        let tabIndex: number = e.target.tabIndex;

        let productProfileUpdateDiv = document.getElementById("productProfileUpdateDiv" + tabIndex);

        if (tabIndex < this.productProfileFilteredItems.length - 1 && e.code.toLowerCase() == "arrowdown") {

          let productProfileUpdateDivPlus = document.getElementById("productProfileUpdateDiv" + (tabIndex + 1));

          //console.log("e down ", tabIndex);
          //TODO
          // Set this.productProfileContainer.style overflow-y to hidden here
          let docBody = document.getElementById("rootBody");
          docBody.style.overflowY = "hidden";

          this.productProfileListContainer.nativeElement.classList.add("productProfile-container-no-scroll");

          this.productProfileFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          productProfileUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.productProfileFilteredItems[tabIndex + 1].focus();
          this.productProfileFilteredItems[tabIndex + 1].classList.add("chh-list-item-row-selected");
          productProfileUpdateDivPlus.classList.add("chh-list-item-row-selected");

          // Set this.productProfileContainer.style overflow-y to scroll here
          this.productProfileListContainer.nativeElement.classList.remove("productProfile-container-no-scroll");

        }

        if (tabIndex >= 0 && e.code.toLowerCase() == "arrowup") {

          if (tabIndex == 0) {
            //console.log("up tabIndex ", tabIndex);

            this.productProfileFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
            productProfileUpdateDiv.classList.remove("chh-list-item-row-selected");

            let docBody = document.getElementById("rootBody");
            docBody.style.overflowY = "scroll";

            this.productProfileFilter.nativeElement.focus();
            this.productProfileFilter.nativeElement.setSelectionRange(this.productProfileFilter.nativeElement.value.length, this.productProfileFilter.nativeElement.value.length);
            return;
          }


          let productProfileUpdateDivMinus = document.getElementById("productProfileUpdateDiv" + (tabIndex - 1));

          //console.log("e up ", tabIndex);

          this.productProfileListContainer.nativeElement.classList.add("productProfile-container-no-scroll");

          this.productProfileFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          productProfileUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.productProfileFilteredItems[tabIndex - 1].focus();
          this.productProfileFilteredItems[tabIndex - 1].classList.add("chh-list-item-row-selected");
          productProfileUpdateDivMinus.classList.add("chh-list-item-row-selected");

          this.productProfileListContainer.nativeElement.classList.remove("productProfile-container-no-scroll");


        }

      }
      //console.log("nextProductProfile");

    }

    if (e.code.toLowerCase() == "enter") {
      this.selectProductProfile(productProfile, !(productProfile.guSelectedProductProfileId != ''));
    }

  }

  filterProductProfiles(productProfileFilter: string, e) {


    if (e.code.toLowerCase() == "arrowdown" && this.showProductProfileList == true) {
      //console.log("e ", e);
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "hidden";

      this.productProfileFilteredItems = document.getElementsByClassName("product-type-name") as HTMLCollectionOf<HTMLDivElement>;
      if (this.productProfileFilteredItems != null && this.productProfileFilteredItems.length > 0) {


        this.productProfileFilteredItems[0].classList.add("chh-list-item-row-selected");
        let productProfileUpdateDiv = document.getElementById("productProfileUpdateDiv0");

        productProfileUpdateDiv.classList.add("chh-list-item-row-selected");

        this.productProfileFilteredItems[0].focus();
        console.log("filterProductProfile TabIndex: ", this.productProfileFilteredItems[0].tabIndex);

      }

      //console.log("productProfileElements", this.productProfileFilteredItems);

    }

    this.getProductProfiles(productProfileFilter);



  }

  getProductProfiles(productProfileFilter: string) {

    this.productProfilesFiltered = this.productProfiles;

    if (productProfileFilter == null || productProfileFilter == "") {
      this.productProfilesFiltered = [];
      this.showProductProfileList = false;
      return;
    }



    let productProfiles: dtoProductProfile[] = this.productProfiles;

    productProfiles = productProfiles.filter(a => ((a.txtName.toLowerCase().indexOf(productProfileFilter.toLowerCase()) > -1)));

    productProfiles = productProfiles.slice(0, 50);

    productProfiles.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

    this.productProfilesFiltered = productProfiles;

    this.showProductProfileList = true;

    this.setProductProfileListTranition(1000);

  }

  setProductProfileListTranition(delay: number) {

    setTimeout(() => {
      this.showProductProfileListTransition = true;
    }, delay);

  }

  checkMultipleProductProfiles(multipleProductProfiles: boolean) {

    if (multipleProductProfiles == false) {
      //Remove multiple selected productProfiles so only one remains.
      let multipleCust: dtoProductProfile[] = this.productProfiles.filter((a) => { return a.guSelectedProductProfileId != null && a.guSelectedProductProfileId != "" });
      if (multipleCust != null && multipleCust.length > 0) {

        multipleCust.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

        for (let i = multipleCust.length - 1; i >= 1; i--) {
          multipleCust.pop();
        }

        for (let i = 0; i <= this.productProfiles.length - 1; i++) {
          if (multipleCust[0].rowguid != this.productProfiles[i].rowguid) {
            this.productProfiles[i].guSelectedProductProfileId = "";
          }
        }

        this.selectedProductProfiles.length = 0;
        this.selectedProductProfiles.push(multipleCust[0]);
        this.selectedProductProfilesEvent.emit(this.selectedProductProfiles);

      }

    }
  }

  removeProductProfile(productProfile: dtoProductProfile) {

    if (productProfile == null) {
      return;
    }

    for (let i = this.selectedProductProfiles.length - 1; i >= 0; i--) {
      if (this.selectedProductProfiles[i].rowguid == productProfile.rowguid) {
        this.selectedProductProfiles.splice(i, 1);
        productProfile.guSelectedProductProfileId = "";
        //console.log("unselected productProfile");
        this.selectedProductProfilesEvent.emit(this.selectedProductProfiles);
        break;
      }
    }


  }

  expandSelectedProductProfiles(e: PointerEvent) {

    if (e.pointerType == null || e.pointerType == "") {
      return;
    }

    this.productProfileSelectionExpanded = !this.productProfileSelectionExpanded;

    let selectedProductProfiles: HTMLDivElement = document.getElementById("selectedProductProfiles") as HTMLDivElement;
    selectedProductProfiles.classList.toggle("selected-product-profiles-expanded");

    if (this.productProfileSelectionExpanded == true) {

    }

  }

  clickPin(e) {

    this.productProfileListPinned = !this.productProfileListPinned;

    if (this.productProfileListPinned == false) {
      this.showProductProfileList = false;
    }

    this.toolTipText = this.productProfileListPinned == true ? 'unpin' : 'pin';


  }
}
