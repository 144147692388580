import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Orders } from '../business-objects/orders.bo';
import { OrderItem } from '../business-objects/order-item.bo';
import { dtoOrderItem } from '../business-objects/dto-order-item.bo';
import { CustomerService } from './customer.service';
import { CustomerDeliveryAddressService } from './customer-delivery-address';
import { CustomerContactService } from './customer-contact.service';
import { SharedService } from './shared.service';
import { ProductCode } from '../business-objects/product-code.bo';
import { dtoProductCodes } from '../business-objects/dto-product-codes.bo';

import { AuthService } from '../../account/auth.service';

import { DatePipe } from '@angular/common';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { AlertService } from './alert.service';
import { SQLParamArray } from '../business-objects/sql-param-array';

@Injectable()
export class OrderItemService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private customerService: CustomerService,
    private customerDeliveryAddressService: CustomerDeliveryAddressService, private customerContactService: CustomerContactService,
    private authService: AuthService, private sharedService: SharedService, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getOrderItem(orderItemId: string) {
    return this.http.get<OrderItem>(this._baseUrl + 'api/orderitem/' + orderItemId);
  }

  getdtoOrderItem(orderItemId: string) {
    return this.http.get<dtoOrderItem>(this._baseUrl + 'api/orderitem/GetdtoOrderItem/' + orderItemId);
  }

  getOrderItemParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<OrderItem[]>(this._baseUrl + 'api/OrderItem/GetOrderItemsParamArray/', aParam);
  }

  getdtoOrderItemParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoOrderItem[]>(this._baseUrl + 'api/OrderItem/GetdtoOrderItemsParamArray/', aParam);
  }

  getOrderItemsByOrder(orderId: string) {
    return this.http.get<OrderItem>(this._baseUrl + 'api/orderitem/GetOrderItemsByOrder/' + orderId);
  }

  getDTOOrderItemsByOrder(orderId: string) {
    return this.http.get<dtoOrderItem[]>(this._baseUrl + 'api/orderitem/GetDTOOrderItemsByOrder/' + orderId);
  }

  createOrderItem(orderItem: OrderItem) {
    console.log(orderItem);
    return this.http.post<OrderItem>(this._baseUrl + 'api/orderitem/CreateOrderItem/', orderItem);
  }

  updateOrderItem(orderItem: OrderItem) {
    return this.http.put<OrderItem>(this._baseUrl + 'api/orderitem/UpdateOrderItem/', orderItem);
  }

  async getOrderItemsPromise(orderItemId: string) {
    return new Promise<OrderItem>(resolve => {
      this.getOrderItem(orderItemId).subscribe(orderItem => {
        resolve(orderItem);
      }, err => {
        this.alertService.openSnackBarError("Error getting Order Items records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async getdtoOrderItemPromise(orderItemId: string) {
    return new Promise<dtoOrderItem>(resolve => {
      this.getdtoOrderItem(orderItemId).subscribe(orderItem => {
        resolve(orderItem);
      }, err => {
        this.alertService.openSnackBarError("Error getting Order Items records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async getdtoOrderItemsByOrderPromise(orderId: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guOrderId", orderId))
    aParamList.push(aParam);

    return new Promise<dtoOrderItem[]>(resolve => {
      this.getdtoOrderItemParamArray(aParamList).subscribe(orderItems => {
        resolve(orderItems);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error getting Orders records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async getOrderItemsByOrderPromise(orderId: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guOrderId", orderId))
    aParamList.push(aParam);

    return new Promise<OrderItem[]>(resolve => {
      this.getOrderItemParamArray(aParamList).subscribe(orderItems => {
        resolve(orderItems);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error getting Orders records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async getdtoOrderItemParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<dtoOrderItem[]>(resolve => {
      this.getdtoOrderItemParamArray(aParam).subscribe(orderItems => {
        resolve(orderItems);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error getting Orders records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async getOrderItemParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<OrderItem[]>(resolve => {
      this.getOrderItemParamArray(aParam).subscribe(orderItems => {
        resolve(orderItems);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error getting Orders records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async updateOrderItemPromise(orderItem: OrderItem) {
    return new Promise<OrderItem>(resolve => {
      this.updateOrderItem(orderItem).subscribe(orderItem => {
        resolve(orderItem);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating Orders record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async createOrdersPromise(orderItem: OrderItem) {
    return new Promise<OrderItem>(resolve => {
      this.createOrderItem(orderItem).subscribe(orderItem => {
        resolve(orderItem);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating Orders record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }


  mapOrderItemFromDTO(dtoOrderItem: dtoOrderItem, excludeProperties: Array<string>): OrderItem {
    var orderItem: OrderItem = new OrderItem();

    for (let [key, value] of Object.entries(dtoOrderItem)) {
      if (excludeProperties.indexOf(key) == -1) {
        if (value == this.sharedService.EmptyGuid) {
          orderItem[key] = null;
        } else {
          orderItem[key] = value;
        }
      }
    }
    return orderItem;
  }



  createCashSaleDTOOrderItemObject(order: Orders, productCode: dtoProductCodes, itemNo: number): dtoOrderItem {
    const datepipe: DatePipe = new DatePipe('en-AU');
    let orderItem: dtoOrderItem = new dtoOrderItem();

    //================================================================================
    // NEED TO SETUP PRODUCT CODE PROPERTIES TABLE TO STORE THINGS LIKE
    // NOMINAL SIZE, NOMINAL DIAMETER, TREATEMENT TYPE, STRUCTURAL GRADE, SET LENGTH
    //================================================================================
    orderItem.txtProductName = productCode.txtProductName;
    orderItem.txtProfileName = productCode.txtProfileName;
    orderItem.txtSpeciesName = productCode.txtSpeciesName;
    orderItem.txtGradeName = productCode.txtGradeName;
    orderItem.guProductCodeId = productCode.rowguid;
    orderItem.guProductCodePriceId = productCode.guProductCodePriceId;
    orderItem.guProductCodePriceChangeReasonId = null;
    orderItem.dteDateCreated = new Date(datepipe.transform(Date.now(), 'yyyy-MM-dd'));
    orderItem.intItemNo = itemNo;
    orderItem.guOrderId = order.rowguid;
    orderItem.guProductTypeId = productCode.guProductTypeId;
    orderItem.guProfileId = null;
    orderItem.guProductProfileId = productCode.guProfileId;
    orderItem.fltWidth = productCode.fltWidth;
    orderItem.fltThickness = productCode.fltThickness;
    orderItem.fltNominalWidth = productCode.fltStartWidth;
    orderItem.fltNominalThickness = productCode.fltStartThickness;
    orderItem.fltExWidth = productCode.fltStartWidth;
    orderItem.fltExThickness = productCode.fltStartThickness;
    orderItem.guPoleId = productCode.guPoleId;
    orderItem.fltDiameter = productCode.fltDia;
    orderItem.intDiameterType = productCode.intDiaType;
    orderItem.fltLength = 1;
    if (productCode.fltLength > 1) {
      orderItem.fltLength = productCode.fltLength;
    }
    orderItem.fltQuantityTotal = 1;  //Update quantity in order items and or check out / cart
    orderItem.fltPrice = productCode.fltPrice;
    orderItem.intPriceType = productCode.intPricingUnit;
    orderItem.txtPriceType = productCode.txtPricingUnit;
    orderItem.fltPriceIncGST = productCode.fltPriceIncGST;    //Number.parseFloat((productCode.fltPrice * this.sharedService.GSTPlusOne).toFixed(2));  // this will make sure we round to 2 decimal palces.
    orderItem.fltTotalItemPriceIncGST = 0;
    orderItem.intQuantityUoM = productCode.intDefaultQtyUoM;
    orderItem.txtQuantityUoM = productCode.txtQuantityUoM;
    orderItem.fltQuantityDeliveried = 0;
    orderItem.guSpeciesId = productCode.guSpeciesId;
    orderItem.guGradeId = productCode.guGradeId;
    orderItem.intTreatmentType = productCode.intTreatmentType;   
    orderItem.intHazardLevel = productCode.intTreatmentHazardLevel;
    orderItem.guElectricalAuthoritySpecId = productCode.guElectricalAuthoritySpecId;
    orderItem.guDurabilityId = productCode.guDurabilityClassId;  
    orderItem.txtStockCode = productCode.txtCustomerStockCode;  //Customer Stock code such as Essential Energy pole stock code
    orderItem.intSizeType = productCode.intSizeType;
    orderItem.txtComments = "";
    orderItem.intStatus = Orders.enStatus.Complete;
    orderItem.dteStatusDate = new Date(datepipe.transform(Date.now(), 'yyyy-MM-dd'));
    orderItem.dteDeliveryDate = null;
    orderItem.dteInvoiceDate = null;
    orderItem.txtInvoiceNo = "";
    orderItem.blnDelivered = true;  // SETING AS DELIVERED AS WILL AFTER TRANSACTION IS COMPLETE
    orderItem.blnOptional = false;
    orderItem.blnInvoiced = false;
    orderItem.blnNextDelivery = false;
    orderItem.blnNextDelivery2 = false;
    orderItem.blnFinalDelivery = false;
    orderItem.blnNextLocalDelivery = false;
    orderItem.blnNextBrisbaneDelivery = false;
    orderItem.blnJoinery = false;
    orderItem.intStuctureGrade = 0;
    orderItem.intLogSection = 0;
    orderItem.intMoistureLevel = 0;
    orderItem.intStructureGrade = 0;
    orderItem.blnBPFloorOrder = false;
    orderItem.dteProcessed = null;
    orderItem.intBPFloorOrderItem = 0;
    orderItem.txtPackingInstruction = "";
    orderItem.blnDontShowCube = false;
    orderItem.blnDrilled = false;
    orderItem.blnSetLength = false;
    orderItem.intStrengthGroup = 0;
    orderItem.intDurabilityClassType = 0;
    orderItem.blnAmendment = false;
    orderItem.blnPercisionDocked = false;
    orderItem.fltPrecisionDockingRate = 0;
    orderItem.fltSetupLMRate = 0;
    orderItem.fltWasteFactor = 0;
    orderItem.intWasteFactorType = 0;
    orderItem.fltMiscPrice = 0;
    orderItem.intMiscPriceType = 0;
    orderItem.txtMiscPriceComments = "";
    orderItem.txtInternalComments = "";
    orderItem.blnComplaint = false;
    orderItem.txtComplaintComments = "";
    orderItem.dteComplaint = null;
    orderItem.blnComplaintResolved = false;
    orderItem.dteComplaintResolved = null;
    orderItem.intComplaintStatus = 0;
    orderItem.fltDiscountPercentage = 0;
    orderItem.txtAdminComments = "";
    orderItem.blnPrecoated = false;
    orderItem.guOriginalPoleId = null;
    orderItem.fltOriginalPrice = 0;
    orderItem.guCutbackPoleId = null;
    orderItem.blnMinimumDeposit = false;
    orderItem.txtMinimumDepositValue = 0;
    orderItem.intLengthType = 0;
    orderItem.blnNonStandardNominal = false;
    orderItem.intRippedBoards = 0;
    orderItem.intLaminatedBoards = 0;
    orderItem.blnConsignment = false;
    orderItem.blnConsignmentDelivered = false;
    orderItem.fltParquetryBoxes = 0;
    orderItem.dteDataUpdatedDate = null;
    orderItem.fltQuantityOLD = 0;
    orderItem.fltLengthOLD = 0;
    orderItem.blnNonStockItem = productCode.blnNonStockItem;
    return orderItem;
  }

}  
