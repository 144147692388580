<div class="hauling-company-driver-list-container">
  <app-app-user-column-set-bar [componentColumns]="displayedColumns" [pageName]="columnBarPage" [container]="columnBarContainer" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)" *ngIf="loadedComponent"></app-app-user-column-set-bar>

  <table mat-table #tblHaulingCompanyDriver [dataSource]="datasource" class="mat-elevation-z8 hauling-company-driver-list-table">

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH alignRight"> Date Created </th>
      <td mat-cell *matCellDef="let haulingCompanyDriver" class="haulingCompanyDriverTD dateCreated alignRight"> {{haulingCompanyDriver.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtHaulingCompanyName">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH"> Hauling Company </th>
      <td mat-cell *matCellDef="let haulingCompanyDriver" class="haulingCompanyDriverTD haulingCompanyName">{{haulingCompanyDriver.txtHaulingCompanyName}}</td>
    </ng-container>

    <ng-container matColumnDef="txtFirstName">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH"> First Name </th>
      <td mat-cell *matCellDef="let haulingCompanyDriver" class="haulingCompanyDriverTD firstName">{{haulingCompanyDriver.txtFirstName}}</td>
    </ng-container>

    <ng-container matColumnDef="txtLastName">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH"> Last Name </th>
      <td mat-cell *matCellDef="let haulingCompanyDriver" class="haulingCompanyDriverTD lastName">{{haulingCompanyDriver.txtLastName}}</td>
    </ng-container>

    <ng-container matColumnDef="txtNickName">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH"> Nick Name </th>
      <td mat-cell *matCellDef="let haulingCompanyDriver" class="haulingCompanyDriverTD nickName">{{haulingCompanyDriver.txtNickName}}</td>
    </ng-container>

    <ng-container matColumnDef="txtComments">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH"> Comments </th>
      <td mat-cell *matCellDef="let haulingCompanyDriver" class="haulingCompanyDriverTD comments">{{haulingCompanyDriver.txtComments}}</td>
    </ng-container>

    <ng-container matColumnDef="blnActive">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH"> Active </th>
      <td mat-cell *matCellDef="let haulingCompanyDriver" class="haulingCompanyDriverTD active">{{haulingCompanyDriver.blnActive ? 'Yes' : 'No'}}</td>
    </ng-container>

    <ng-container matColumnDef="blnDefaultDriver">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH"> Default Driver </th>
      <td mat-cell *matCellDef="let haulingCompanyDriver" class="haulingCompanyDriverTD defaultDriver">{{haulingCompanyDriver.blnDefaultDriver ? 'Yes' : 'No'}}</td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsDefaultDriver">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH"></th>
      <td mat-cell *matCellDef="let haulingCompanyDriver; let idx = index;" class="haulingCompanyDriverTD"><button class="set-as-default-driver-button" type="button" mat-flat-button color="primary" (click)="setAsDefaultDriver(haulingCompanyDriver, idx)">Set As Default Driver</button></td>
    </ng-container>

    <ng-container matColumnDef="btnEditHaulingCompanyDriver">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH"></th>
      <td mat-cell *matCellDef="let haulingCompanyDriver" class="haulingCompanyDriverTD"><button class="edit-hauling-company-driver-button" type="button" mat-flat-button color="primary" (click)="editHaulingCompanyDriver(haulingCompanyDriver)">Edit</button></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveHaulingCompanyDriver">
      <th mat-header-cell *matHeaderCellDef class="haulingCompanyDriverTH"></th>
      <td mat-cell *matCellDef="let haulingCompanyDriver; let idx = index;" class="haulingCompanyDriverTD"><button class="remove-hauling-company-driver-button" type="button" mat-flat-button color="primary" (click)="removeHaulingCompanyDriver(haulingCompanyDriver, idx)">Remove</button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="hauling-company-driver-row-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let haulingCompanyDriver" (click)="setRowColor(haulingCompanyDriver.rowguid)" [ngClass]="haulingCompanyDriver.rowguid == selectedRow ? 'activeTR' : ''"></tr>

  </table>

</div>
