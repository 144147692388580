export interface ElectricalAuthoritySpec
{
	blnActive: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	intGroundLineMeasurementType: number;
	intState: number;
	rowguid: string;
	txtAuthorityName: string;
	txtComments: string;
	txtCrossarmSpecName: string;
	txtDiscCode: string;
	txtPoleSpecName: string;
	txtProductCode: string;
	txtStateName: string;
}

export class ElectricalAuthoritySpec
{
	blnActive: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	intGroundLineMeasurementType: number;
	intState: number;
	rowguid: string;
	txtAuthorityName: string;
	txtComments: string;
	txtCrossarmSpecName: string;
	txtDiscCode: string;
	txtPoleSpecName: string;
	txtProductCode: string;
	txtStateName: string;
}

export namespace ElectricalAuthoritySpec
{
	export enum enGroundLineMeasurementType
	{
		Diameter,
		Circumference
	}
}

