<div class="cca-charge-print-screen-container" id="tpChargePrintScreenContainer">
  <div class="cca-charge-print-container" id="tpChargePrintContainer">
    <div class="header-wrapper">
      <div class="header-container">
        <div class="header-logo">
          <img src="../../../../assets/img/BlackLogo.bmp" width="40" height="40" />
        </div>
        <div class="divider-wrapper">
          <div class="header-column-2"></div>
        </div>
        <div class="header-column-3" *ngIf="false">
          <div class="header-row-1">
            <div>C</div>
            <div>o</div>
            <div>f</div>
            <div>f</div>
            <div>s</div>
            <div></div>
            <div>H</div>
            <div>a</div>
            <div>r</div>
            <div>b</div>
            <div>o</div>
            <div>u</div>
            <div>r</div>
          </div>
          <div class="header-row-2">
            <div>H</div>
            <div>A</div>
            <div>R</div>
            <div>D</div>
            <div>W</div>
            <div>O</div>
            <div>O</div>
            <div>D</div>
            <div>S</div>
          </div>
          <div class="header-website" *ngIf="false">www.coffshardwoods.com.au</div>
        </div>

        <div class="header-column-3A">
          <div class="header-row-1A">
            <div><h3>Coffs Harbour Hardwoods</h3></div>
          </div>
        </div>

      </div>
    </div>
    <div class="cca-charge-heading">CCA Charge</div>
    <div class="print-buton">
      <button class="print-button" type="button" mat-flat-button color="primary" (click)="print()" *ngIf="this.tpCharge != null && pdfMode == false">Print Charge Docket</button>
    </div>
    <div class="cca-charge-info" *ngIf="tpCharge != null">
      <div class="docket-heading-label cca-charge-no-label">Charge No:</div>
      <div class="docket-heading-cell cca-charge-no">{{tpCharge.intChargeNo}}</div>
      <div class="docket-heading-label cca-charge-charge-year-label">Charge Year</div>
      <div class="docket-heading-cell cca-charge-year">{{tpCharge.dteChargeDate | date: 'yy'}}</div>
      <div class="docket-heading-label cca-charge-date-label">Charge Date:</div>
      <div class="docket-heading-cell cca-charge-date">{{tpCharge.dteChargeDate | date: 'dd/MM/yyyy'}}</div>
      <div class="docket-heading-label cca-charge-vacuum-start-label">Vacuum Start:</div>
      <div class="docket-heading-cell cca-charge-vacuum-start">{{tpCharge.intInitVacuumStart}}</div>
      <div class="docket-heading-label cca-charge-vacuum-max-held-label">Init Vacuum Max Held:</div>
      <div class="docket-heading-cell cca-charge-vacuum-max-held">{{tpCharge.intInitVacuumMaxHeld}}</div>
      <div class="docket-heading-label cca-charge-pressure-start-label">Pressure Start</div>
      <div class="docket-heading-cell cca-charge-pressure-start">{{tpCharge.intPressureStart}}</div>
      <div class="docket-heading-label cca-charge-pressure-max-held-label">Pressure Max Held</div>
      <div class="docket-heading-cell cca-charge-pressure-max-held">{{tpCharge.intPressureMaxHeld}}</div>
      <div class="docket-heading-label cca-charge-tank-start-label">Tank Start</div>
      <div class="docket-heading-cell cca-charge-tank-start">{{tpCharge.intTankStart}}</div>
      <div class="docket-heading-label cca-charge-tank-finish-label">Tank Finish</div>
      <div class="docket-heading-cell cca-charge-tank-finish">{{tpCharge.intTankFinish}}</div>
      <div class="docket-heading-label cca-charge-solution-strength-label">Solution Strength</div>
      <div class="docket-heading-cell cca-charge-solution-strength">{{tpCharge.intSolutionStrength}}</div>
      <div class="docket-heading-label cca-charge-retention-label">Retention</div>
      <div class="docket-heading-cell cca-charge-retention"></div>
      <div class="docket-heading-label cca-charge-blank-label"></div>
      <div class="docket-heading-cell cca-charge-blank"></div>
      <div class="docket-heading-label cca-charge-comments-label">Comments</div>
      <div class="docket-heading-cell cca-charge-comments">{{tpCharge.txtComments}}</div>
    </div>

    <br />
    <div class="items-container">
      <div class="cca-charge-items-container">
        <div class="cca-charge-items-label">Charge Items<span *ngIf="samples == true" class="sample-taken-header-dash">-</span><span *ngIf="samples == true" class="sample-taken-header">Samples Only</span></div>
        <div class="cca-charge-items-container">
          <app-cca-charge-item-view class="cca-charge-item-view" #appCCAChargeItemView [tpChargeIdInput]="tpCharge.rowguid" *ngIf="tpCharge != null"></app-cca-charge-item-view>
        </div>
      </div>
    </div>
    <span class="sample-taken-footer">* </span>Sample Taken

  </div>
