import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { DeliveryRate } from '../business-objects/delivery-rate.bo';

@Injectable()
export class DeliveryRateService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getDeliveryRate(deliveryRateId: string) {
		return this.http.get<DeliveryRate>(this._baseUrl + 'api/DeliveryRate/' + deliveryRateId);
	}

	getDeliveryRateParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<DeliveryRate[]>(this._baseUrl + 'api/DeliveryRate/GetDeliveryRatesParamArray/', aParam);
	}

	getAllDeliveryRates() {
		return this.http.get<DeliveryRate[]>(this._baseUrl + 'api/DeliveryRate/GetAllDeliveryRates/');
	}

	//getAlldtoDeliveryRates() {
	//	return this.http.get<dtoDeliveryRate[]>(this._baseUrl + 'api/DeliveryRate/GetAllDeliveryRates/');
	//}

	createDeliveryRate(deliveryRate: DeliveryRate) {
		return this.http.post<DeliveryRate>(this._baseUrl + 'api/DeliveryRate/CreateDeliveryRate/', deliveryRate);
	}

	updateDeliveryRate(deliveryRate: DeliveryRate) {
		return this.http.put<DeliveryRate>(this._baseUrl + 'api/DeliveryRate/UpdateDeliveryRate/', deliveryRate);
	}

	async getDeliveryRatePromise(deliveryRateId: string) {
		return new Promise<DeliveryRate>(resolve => {
			this.getDeliveryRate(deliveryRateId).subscribe(deliveryRate => {
				resolve(deliveryRate);
			}, err => {
				this.alertService.openSnackBarError("Error getting DeliveryRate records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getDeliveryRateParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<DeliveryRate[]>(resolve => {
			this.getDeliveryRateParamArray(aParam).subscribe(deliveryRates => {
				resolve(deliveryRates);
			}, err => {
				this.alertService.openSnackBarError("Error getting DeliveryRate records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateDeliveryRatePromise(deliveryRate: DeliveryRate) {
		return new Promise<DeliveryRate>(resolve => {
			this.updateDeliveryRate(deliveryRate).subscribe(deliveryRate => {
				resolve(deliveryRate);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating DeliveryRate record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createDeliveryRatePromise(deliveryRate: DeliveryRate) {
		return new Promise<DeliveryRate>(resolve => {
			this.createDeliveryRate(deliveryRate).subscribe(deliveryRate => {
				resolve(deliveryRate);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating DeliveryRate record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}