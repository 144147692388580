import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { TPPoleInspectionItem } from '../business-objects/tppole-inspection-item.bo';
import { dtoKeyValue } from '../business-objects/dto-int-key-value.bo';
import { TPPoleInspection } from '../business-objects/tppole-inspection.bo';

@Injectable()
export class TPPoleInspectionItemService
{
 
  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
	  this._baseUrl = baseUrl;
  }

  getTPPoleInspectionItem(tPPoleInspectionItemId: string) {
	  return this.http.get<TPPoleInspectionItem>(this._baseUrl + 'api/TPPoleInspectionItem/' + tPPoleInspectionItemId);
  }

  getTPPoleInspectionItemParamArray(aParam: SQLParamArray[][]) {
	  return this.http.post<TPPoleInspectionItem[]>(this._baseUrl + 'api/TPPoleInspectionItem/GetTPPoleInspectionItemsParamArray/', aParam);
  }

  getAllTPPoleInspectionItems() {
	  return this.http.get<TPPoleInspectionItem[]>(this._baseUrl + 'api/TPPoleInspectionItem/GetAllTPPoleInspectionItems/');
  }

    getLastItemNo(poleInspectionId: string) {
      return this.http.get<dtoKeyValue>(this._baseUrl + 'api/TPPoleInspectionItem/GetLastItemNo/' + poleInspectionId);
    }

  //getAlldtoTPPoleInspectionItems() {
  //	return this.http.get<dtoTPPoleInspectionItem[]>(this._baseUrl + 'api/TPPoleInspectionItem/GetAllTPPoleInspectionItems/');
  //}

  createTPPoleInspectionItem(tPPoleInspectionItem: TPPoleInspectionItem) {
    console.log(tPPoleInspectionItem);
	  return this.http.post<TPPoleInspectionItem>(this._baseUrl + 'api/TPPoleInspectionItem/CreateTPPoleInspectionItem/', tPPoleInspectionItem);
  }

  updateTPPoleInspectionItem(tPPoleInspectionItem: TPPoleInspectionItem) {
	  return this.http.put<TPPoleInspectionItem>(this._baseUrl + 'api/TPPoleInspectionItem/UpdateTPPoleInspectionItem/', tPPoleInspectionItem);
  }

  removeTPPoleInspectionItem(poleInspectionItemId: string) {
    return this.http.delete<boolean>(this._baseUrl + 'api/TPPoleInspectionItem/' + poleInspectionItemId);
  }

  async getTPPoleInspectionItemPromise(tPPoleInspectionItemId: string) {
	  return new Promise<TPPoleInspectionItem>(resolve => {
      this.getTPPoleInspectionItem(tPPoleInspectionItemId).subscribe(tPPoleInspectionItem => {
			  resolve(tPPoleInspectionItem);
		  }, err => {
        this.alertService.openSnackBarError("Error getting TPPoleInspectionItem records", "Close", "center", "bottom", 4000, true, err.message);
			  resolve(null);
		  });
	  });
  }

  async reSetItemNumbers(poleInspectionId: string) {
    return new Promise<TPPoleInspectionItem[]>(resolve => {
      this.http.get<TPPoleInspectionItem[]>(this._baseUrl + 'api/TPPoleInspectionItem/ReSetItemNumbers/' + poleInspectionId).subscribe(poleInspectionItems => {
        resolve(poleInspectionItems);
      }, err => {
        this.alertService.openSnackBarError("Error resetting TPPoleInspectionItem item numbers", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getTPPoleInspectionItemParamArrayPromise(aParam: SQLParamArray[][]) {
	  return new Promise<TPPoleInspectionItem[]>(resolve => {
		  this.getTPPoleInspectionItemParamArray(aParam).subscribe(tPPoleInspectionItems => {
			  resolve(tPPoleInspectionItems);
		  }, err => {
        this.alertService.openSnackBarError("Error getting TPPoleInspectionItem records", "Close", "center", "bottom", 4000, true, err.message);
			  resolve(null);
		  });
	  });
  }

  async updateTPPoleInspectionItemPromise(tPPoleInspectionItem: TPPoleInspectionItem) {
	  return new Promise<TPPoleInspectionItem>(resolve => {
		  this.updateTPPoleInspectionItem(tPPoleInspectionItem).subscribe(tPPoleInspectionItem => {
			  resolve(tPPoleInspectionItem);
		  }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating TPPoleInspectionItem record", "Close", "center", "bottom", 4000, true, err.message);
			  resolve(null);
		  });
	  });
  }

  async createTPPoleInspectionItemPromise(tPPoleInspectionItem) {
	  return new Promise<TPPoleInspectionItem>(resolve => {
		  this.createTPPoleInspectionItem(tPPoleInspectionItem).subscribe(tPPoleInspectionItem => {
			  resolve(tPPoleInspectionItem);
      }, (err: HttpErrorResponse) => {
        console.log(err);
                this.alertService.openSnackBarError("Error creating TPPoleInspectionItem record", "Close", "center", "bottom", 4000, true, err.message);
			  resolve(null);
		  });
	  });
  }

  async getLastItemNoPromise(poleInspectionId: string) {
    return new Promise<dtoKeyValue>(resolve => {
      this.getLastItemNo(poleInspectionId).subscribe(keyValue => {
        resolve(keyValue);
    }, err => {
        this.alertService.openSnackBarError("Error getting TPPoleInspection record", "Close", "center", "bottom", 4000, true, err.message);
      resolve(null);
    });
    });
  }

  async removeTPPoleInspectionItemPromise(poleInspectionItemId: string) {
    return new Promise<boolean>(resolve => {
      this.removeTPPoleInspectionItem(poleInspectionItemId).subscribe(val => {
        //console.log("removed in promise: " + val);
        resolve(true);
      }, err => {
        this.alertService.openSnackBarError("Error removing TPPoleInspection record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(false);
      });
    });
  }

  async removeTPPoleInspectionItemsPromise(poleInspectionItems: TPPoleInspectionItem[]) {
    if (poleInspectionItems == null) {
      this.alertService.openSnackBar("There are no inspection items to remove", "Close", "center", "bottom", "", 4000);
      return;
    }

    return new Promise<true>(async resolve => {
      for (let i = 0; i <= poleInspectionItems.length - 1; i++) {
        //console.log("removing final item: " + poleInspectionItems[i].txtPoleNumber);
        await this.removeTPPoleInspectionItemPromise(poleInspectionItems[i].rowguid);
        //console.log("removed final item: " + poleInspectionItems[i].txtPoleNumber);
      }
      //console.log("finished removing ");
      resolve(true);
    });
  }

  async updateTPPoleInspectionItemsPromise(poleInspectionItems: TPPoleInspectionItem[]) {
    if (poleInspectionItems == null) {
      this.alertService.openSnackBar("There are no inspection items to remove", "Close", "center", "bottom", "", 4000);
      return;
    }

    return new Promise<true>(async resolve => {
      for (let i = 0; i <= poleInspectionItems.length - 1; i++) {
        //console.log("updating final item: " + poleInspectionItems[i].txtPoleNumber);
        await this.updateTPPoleInspectionItemPromise(poleInspectionItems[i]);
        //console.log("updated final item: " + poleInspectionItems[i].txtPoleNumber);
      }
      //console.log("finished updating");
      resolve(true);
    });
  }
}
