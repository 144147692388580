import { Injectable } from "@angular/core";
import { AuthService } from "../../account/auth.service";
import { AppUser } from "../../app-users/app-user-update/appuser.module";


@Injectable()
export class AppUserInfoService {

  public _baseUrl: string;

  constructor(private authService: AuthService) {
    
  }


  getAppUserUserName() {

    let appUser: AppUser = this.authService.getAppUser();
    return appUser.txtUserName;

  }

}
