import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { Printer } from '../../_shared/business-objects/printer.bo';
import { dtoPrinter } from '../../_shared/business-objects/dto-printer.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { SQLParamService } from '../../_shared/services/app-services/sql-param.service';
import { FormService } from '../../_shared/services/form.service';
import { PrinterService } from '../../_shared/services/printer.service';
import { SharedService } from '../../_shared/services/shared.service';
import { PrinterListComponent } from '../printer-list/printer-list.component';
import { dtoPrinterService } from '../../_shared/services/dto-printer.service';

export enum enPrinterTabs { Printers = 0, SearchOptions = 1, CreatePrinter = 2, UpdatePrinter = 3 }

@Component({
  selector: 'app-printer-search',
  templateUrl: './printer-search.component.html',
  styleUrls: ['./printer-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class PrinterSearchComponent implements OnInit {
  @ViewChild("appPrinterList") appPrinterList: PrinterListComponent;

  showSpinner: boolean;
  printers: MatTableDataSource<dtoPrinter>;
  public ePrinterTabs = enPrinterTabs;
  printerStatuses: dtoKeyValue[] = [];
  treatmentTypes: dtoKeyValue[] = [];
  treatmentHazardLevels: dtoKeyValue[] = [];

  aPrinterParamList: SQLParamArray[][];

  selectedTab: number;

  form: FormGroup;


  constructor(private formService: FormService, private fb: FormBuilder, private sharedService: SharedService, private printerService: PrinterService, private dtoPrinterService: dtoPrinterService
    ,private alertService: AlertService, private cdref: ChangeDetectorRef, private sqlParamService: SQLParamService
    ) {

    this.form = this.fb.group({
      txtSearchPrinterName: [''],
      dteDateCreatedFrom: [''],
      dteDateCreatedTo: ['']
    });

  }


  ngOnInit() {

    document.title = "Printers";

    this.printers = new MatTableDataSource<dtoPrinter>();

    this.loadData();

    this.cdref.detectChanges();

  }

  ngAfterContentInit() {

    this.cdref.detectChanges();

  }

  ngAfterViewInit() {

    this.cdref.detectChanges();

  }

  async loadData() {

  }

  enterKeySearch(type: string, e: KeyboardEvent) {

    if (this.selectedTab == enPrinterTabs.SearchOptions) {

      //WE NEED BLUR THE CURRENT TEXTBOX BECAUSE WE GET AN REF DETECTION CHANGE ERROR
      //WHEN GOING BACK TO THE PRINTERS TAB WHICH IS DONE PROGRAMATICALLY IN this.searchPrinters.

      if (e.target != null) {
        let input: HTMLInputElement = e.target as HTMLInputElement;
        if (input != null) {
          input.blur()
        }
      }
    }

    this.searchPrinters(type, 0, e);

  }

  async searchPrinters(type: string, lastPrinters: number, e) {

    this.aPrinterParamList = [];
    let criteriaSelected: boolean = false;
    let aMandatoryParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let printerName: string = this.form.controls["txtSearchPrinterName"].value;
    let printerCreatedFrom: string = this.form.controls["dteDateCreatedFrom"].value;
    let printerCreatedTo: string = this.form.controls["dteDateCreatedTo"].value;


    const datepipe: DatePipe = new DatePipe('en-AU');

    if (type == "searchoptions") {

    }

    this.sqlParamService.addParameter(this.aPrinterParamList, printerName, "txtName");
    this.sqlParamService.addParameter(this.aPrinterParamList, datepipe.transform(printerCreatedFrom, 'yyyy-MM-dd'), "dteDateCreated", false, SQLParamArray.enSQLOperator.GreaterThanOrEqualTo);
    this.sqlParamService.addParameter(this.aPrinterParamList, datepipe.transform(printerCreatedTo, 'yyyy-MM-dd'), "dteDateCreated", false, SQLParamArray.enSQLOperator.LessThanOrEqualTo);

    //if (this.aPrinterParamList.length == 0) {
    //  let msg: string = await this.alertService.openSnackBarCustomPromise("No Search Criteria", "Please select some search criteria before searching", "Ok", "", "center", "bottom", "", 0, false);
    //  return;
    //}

    //this.sqlParamService.addParameter(this.aPrinterParamList, "1", "intType");


    console.log("aParamList", this.aPrinterParamList);

    this.printers = new MatTableDataSource<dtoPrinter>();
    this.showSpinner = true;


    if (this.selectedTab != enPrinterTabs.Printers) {
      this.selectedTab = enPrinterTabs.Printers;

    }


    let printers: dtoPrinter[] = await this.dtoPrinterService.getdtoPrinterParamArrayPromise(this.aPrinterParamList);



    if (printers != null && printers.length > 0) {

      //printers.sort((a, b) => { return a.txtOrderNo > b.txtOrderNo ? 1 : -1 });

      printers.sort((a, b) => { return a.txtAliasName > b.txtAliasName || a.txtName > b.txtName ? 1 : -1 });

      this.printers.data = printers;
      console.log("printers: ", printers);
    }



    this.showSpinner = false;



  }

 
  printerCancelled(val: boolean) {

  }


  updatedPrinter(printer: Printer) {

  }

  removePrinter(printer: Printer) {

  }

  editPrinter($event) {

  }

  addPrinterItems(printer: Printer) {

  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    console.log(e.index == this.ePrinterTabs.SearchOptions);

    if (e.index == this.ePrinterTabs.SearchOptions) {
      //this.formService.setAutoFocusTimeout("txtSearchPrinter");
    }

    if (e.index == this.ePrinterTabs.Printers) {
      this.formService.setAutoFocusTimeout("txtSearchPrinter");

    }

  }


}
