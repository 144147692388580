<div class="login-container">
  <div class="row h-100 justify-content-center pt-5">
    <form [formGroup]="form" autocomplete="on" (ngSubmit)="login()">
      <fieldset>
        <legend>Login</legend>
        <label *ngIf="show" class="authFail">Incorrect username or password</label><br />
        <mat-form-field class="email-field" appearance="fill">
          <mat-label>email</mat-label>
          <input matInput placeholder="john@coffshardwoods.com.au" formControlName="email" type="email">
        </mat-form-field>
        <br />
        <mat-form-field class="password-field" appearance="fill">
          <mat-label>password</mat-label>
          <input matInput placeholder="" value="" type="password" formControlName="password">
        </mat-form-field>
        <br />
        <button mat-flat-button color="primary">Login</button>

      </fieldset>

    </form>
  </div>
</div>
