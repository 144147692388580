import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppPageColumn } from '../../_shared/business-objects/app-page-column.bo';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AppUserColumnSet } from '../../_shared/business-objects/app-user-column-set.bo';
import { AppUserColumn } from '../../_shared/business-objects/app-user-column.bo';
import { AppUser } from '../../_shared/business-objects/app-user.bo';
import { dtoAppUserColumn } from '../../_shared/business-objects/dto-app-user-column.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { AppPageColumnService } from '../../_shared/services/app-page-column.service';
import { AppPageService } from '../../_shared/services/app-page.service';
import { AppService } from '../../_shared/services/app-services/app.service';
import { AppUserColumnSetService } from '../../_shared/services/app-user-column-set.service';
import { AppUserColumnService } from '../../_shared/services/app-user-column.service';
import { AppUserService } from '../../_shared/services/app-user.service';
import { dtoAppUserColumnSetService } from '../../_shared/services/dto-app-user-column-set.service';
import { dtoAppUserColumnService } from '../../_shared/services/dto-app-user-column.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-app-user-column-create',
  templateUrl: './app-user-column-create.component.html',
  styleUrls: ['./app-user-column-create.component.css']
})
export class AppUserColumnCreateComponent implements OnInit {

  appUsers: AppUser[];
  fromAppUsers: AppUser[];
  appUserColumnSets: AppUserColumnSet[];
  appUserColumns: AppUserColumn[];
  appPageColumns: AppPageColumn[];
  appPages: AppPage[];

  selectedAppPageColumns: AppPageColumn[];

  form: FormGroup;

  @Output() appUserColumnCreated = new EventEmitter<AppUserColumn>();
  @Output() appUserColumnCreatedAllColumns = new EventEmitter<string>();
  @Output() appUserColumnCreatedCopyUser = new EventEmitter<string>();
  @Output() appUserColumnCancelled = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private appService: AppService, private sharedService: SharedService, private alertService: AlertService
    , private appPageService: AppPageService, private appPageColumnService: AppPageColumnService, private appUserColumnService: AppUserColumnService, private dtoAppUserColumnService: dtoAppUserColumnService
    , private appUserColumnSetService: AppUserColumnSetService, private dtoAppUserColumnSetService: dtoAppUserColumnSetService, private appUserService: AppUserService ) {


    this.form = this.fb.group({
      dteDateCreated: [''],
      guFromAppUserId: [''],
      guAppUserId: [''],
      guAppPageId: [''],
      guAppUserColumnSetId: [''],
      guAppPageColumnId: [''],
      blnVisible: [''],
      txtColumnName: [''],
      intColumnOrder: [''],
      blnAddAllColumns: ['']
    });

    this.form.controls['dteDateCreated'].enable();
    this.form.controls['dteDateCreated'].setValue(this.sharedService.currentDatePlusTZOffset());
    this.form.controls['dteDateCreated'].disable();

  }

  ngOnInit(): void {

    this.loadData();

  }

  async loadData() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];


    aParamList.push(aParam);
    this.appUsers = await this.appUserService.getAppUserParamArrayPromise(aParamList);


    aParamList = [];
    aParamList.push(aParam);
    this.fromAppUsers = await this.appUserService.getAppUserParamArrayPromise(aParamList);


    aParamList = [];
    aParamList.push(aParam);
    //this.appUserColumnSets = await this.appUserColumnSetService.getAppUserColumnSetParamArrayPromise(aParamList);
    this.appUserColumnSets = await this.dtoAppUserColumnSetService.getdtoAppUserColumnSetParamArrayPromise(aParamList);

    aParamList = [];
    aParamList.push(aParam);
    this.appPageColumns = await this.appPageColumnService.getAppPageColumnParamArrayPromise(aParamList);

    aParamList = [];
    aParamList.push(aParam);
    this.appPages = await this.appPageService.getAppPageParamArrayPromise(aParamList);

  }


  async create(mode: string) {

    let saveAppUserColumn: boolean = true;
    let dateCreated: Date = new Date(this.form.controls['dteDateCreated'].value.toString());
    let appPageId: string = this.form.controls['guAppPageId'].value;
    let appUserIds: [] = this.form.controls['guAppUserId'].value;
    let copyFromAppUserId: string = this.form.controls['guFromAppUserId'].value;
    let userId: string;
    let appUserColumnSetIds: [] = this.form.controls['guAppUserColumnSetId'].value;
    let appPageColumnId: AppPageColumn[] = this.form.controls['guAppPageColumnId'].value;
    //if (this.form.controls['guAppPageColumnId'].value != null) {
    // console.log("dateCreated: " + dateCreated);
    //  appPageColumnId = this.form.controls['guAppPageColumnId'].value;
    //}
    let visible: boolean = (this.form.controls['blnVisible'].value == true);
    let defaultColumnOrder: number = this.form.controls['intColumnOrder'].value;
    let addAllColumns: boolean = this.form.controls['blnAddAllColumns'].value;
    const datepipe: DatePipe = new DatePipe('en-AU');

    console.log("appUserId: " + appUserIds);
    console.log("copyFromAppUserId: " + copyFromAppUserId);
    console.log("appUserColumnSetId: " + appUserColumnSetIds);
    console.log("appPageColumnId: ", appPageColumnId);
    console.log("visible: " + visible);
    console.log("defaultColumnOrder: " + defaultColumnOrder);
    console.log("addAllColumns: " + addAllColumns);


    if (dateCreated == null || dateCreated.toString() == "") {
      this.alertService.openSnackBar("Please select an created date.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (appUserIds == null || appUserIds.length == 0) {
      this.alertService.openSnackBar("Please select an app user.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if ((appUserColumnSetIds == null || appUserColumnSetIds.length == 0) && (copyFromAppUserId == null || copyFromAppUserId == "")) {
      this.alertService.openSnackBar("Please select an app column set.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (addAllColumns == false && (appPageColumnId == null || appPageColumnId.length == 0) && (copyFromAppUserId == null || copyFromAppUserId == "")) {
      this.alertService.openSnackBar("Please select an app page column.", "Close", "center", "bottom", "", 3000);
      return;
    }


    //if (addAllColumns == false && (defaultColumnOrder == null || defaultColumnOrder == 0)) {
    //  this.alertService.openSnackBar("Please enter a default column order.", "Close", "center", "bottom", "", 3000);
    //  return;
    //}

    console.log("copyFromAppUserId", copyFromAppUserId);
    if (copyFromAppUserId != null && copyFromAppUserId != "") {

      console.log("about to copy user");
      let appUserColumn: AppUserColumn;

      // Get all columns from copy app user
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guAppUserId", copyFromAppUserId));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("txtColumnSetName", "Default"));
      aParamList.push(aParam);

      let copyUserPageColumns: dtoAppUserColumn[] = await this.dtoAppUserColumnService.getdtoAppUserColumnParamArrayPromise(aParamList);
      let gAppPageId: string = "";

      // Run through all of the copy user columns
      for (let i = 0; i <= copyUserPageColumns.length - 1; i++) {

        console.log("copyUserPageColumns[i].guAppPageColumnId", copyUserPageColumns[i].guAppPageColumnId);

        // Run through each user to copy to.
        for (let u = 0; u <= appUserIds.length - 1; u++) {

          console.log("appUserIds", appUserIds);

          let appUserColumnExists: AppUserColumn[] = [];
          let aParamList: SQLParamArray[][] = [];
          let aParam: SQLParamArray[] = [];

          let appUserColumnSet: AppUserColumnSet = await this.appUserColumnSetService.getAppUserColumnSetByName("Default", appUserIds[u], copyUserPageColumns[i].guAppPageId)
          if (appUserColumnSet == null) {
            //No default set so create on for this user.
            appUserColumnSet = new AppUserColumnSet();
            appUserColumnSet.blnCurrentView = true
            appUserColumnSet.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
            appUserColumnSet.txtName = "Default";
            appUserColumnSet.guAppPageId = copyUserPageColumns[i].guAppPageId;
            appUserColumnSet.guAppUserId = appUserIds[u];
            await this.appUserColumnSetService.createAppUserColumnSetPromise(appUserColumnSet);
            console.log("createAppUserColumnSetPromise", appUserColumnSet);

            appUserColumnSet = await this.appUserColumnSetService.getAppUserColumnSetByName("Default", appUserIds[u], copyUserPageColumns[i].guAppPageId)

          }

          aParam.push(new SQLParamArray("guAppUserId", userId));
          aParamList.push(aParam);

          aParam = [];
          aParam.push(new SQLParamArray("guAppUserColumnSetId", appUserColumnSet.rowguid));
          aParamList.push(aParam);

          aParam = [];
          aParam.push(new SQLParamArray("guAppPageColumnId", copyUserPageColumns[i].guAppPageColumnId));
          aParamList.push(aParam);

          appUserColumnExists = await this.appUserColumnService.getAppUserColumnParamArrayPromise(aParamList);

          if (appUserColumnExists != null && appUserColumnExists.length > 0) {
            console.log("breaking out of for loop");
            continue;
          }

          appUserColumn = new AppUserColumn;

          delete appUserColumn.rowguid;  //remove rowguid property so DB will create one.
          appUserColumn.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
          appUserColumn.guAppUserId = appUserIds[u];
          appUserColumn.guAppUserColumnSetId = appUserColumnSet.rowguid;
          appUserColumn.guAppPageColumnId = copyUserPageColumns[i].guAppPageColumnId;
          appUserColumn.intColumnOrder = copyUserPageColumns[i].intColumnOrder;
          appUserColumn.blnVisible = true;
          appUserColumn.intPageState = 0;
          appUserColumn = await this.appUserColumnService.createAppUserColumnPromise(appUserColumn);
          
          console.log("createAppUserColumnPromise", appUserColumn);

        }
      }

      console.log("appUserColumnCreatedCopyUser - finish");
      this.appUserColumnCreatedCopyUser.emit("");

      return;

    }


    for (let s = 0; s <= appUserColumnSetIds.length - 1; s++) {
      for (let iUserSet = 0; iUserSet <= this.appUserColumnSets.length - 1; iUserSet++) {
        if (this.appUserColumnSets[iUserSet].rowguid == appUserColumnSetIds[s]) {
          userId = this.appUserColumnSets[iUserSet].guAppUserId;
          break;
        }
      }

      if (userId == null || userId == "") {
        this.alertService.openSnackBar("The user is empty", "Close", "center", "bottom", "", 3000);
        return;
      }


      if (saveAppUserColumn) {

        let appUserColumn: AppUserColumn;




        if (addAllColumns == true) {
          for (let i = 0; i <= this.appPageColumns.length - 1; i++) {


            let appUserColumnExists: AppUserColumn[] = [];
            let aParamList: SQLParamArray[][] = [];
            let aParam: SQLParamArray[] = [];


            aParam.push(new SQLParamArray("guAppUserId", userId));
            aParamList.push(aParam);

            aParam = [];
            aParam.push(new SQLParamArray("guAppUserColumnSetId", appUserColumnSetIds[s]));
            aParamList.push(aParam);

            aParam = [];
            aParam.push(new SQLParamArray("guAppPageColumnId", this.appPageColumns[i].rowguid));
            aParamList.push(aParam);

            appUserColumnExists = await this.appUserColumnService.getAppUserColumnParamArrayPromise(aParamList);

            if (appUserColumnExists != null && appUserColumnExists.length > 0) {
              //console.log("breaking out of for loop");
              continue;
            }

            appUserColumn = new AppUserColumn;


              delete appUserColumn.rowguid;  //remove rowguid property so DB will create one.
              appUserColumn.dteDateCreated = new Date(datepipe.transform(dateCreated, 'yyyy-MM-dd'));
              appUserColumn.guAppUserId = userId;
              appUserColumn.guAppUserColumnSetId = appUserColumnSetIds[s];
              appUserColumn.guAppPageColumnId = this.appPageColumns[i].rowguid;
              appUserColumn.intColumnOrder = this.appPageColumns[i].intDefaultColumnOrder;
              appUserColumn.blnVisible = visible;
              appUserColumn.intPageState = 0;
              appUserColumn = await this.appUserColumnService.createAppUserColumnPromise(appUserColumn);

          }

            this.appUserColumnCreatedAllColumns.emit(appPageId);
        }



        if (addAllColumns == false) {
          for (let i = 0; i <= appPageColumnId.length - 1; i++) {

            console.log("appPageColumnId[i]: ", appPageColumnId[i]);

            let appUserColumnExists: AppUserColumn[] = [];
            let aParamList: SQLParamArray[][] = [];
            let aParam: SQLParamArray[] = [];

            aParam.push(new SQLParamArray("guAppUserId", userId));
            aParamList.push(aParam);

            aParam = [];
            aParam.push(new SQLParamArray("guAppUserColumnSetId", appUserColumnSetIds[s]));
            aParamList.push(aParam);

            aParam = [];
            aParam.push(new SQLParamArray("guAppPageColumnId", appPageColumnId[i].rowguid));
            aParamList.push(aParam);

            //console.log("guAppUserColumnSetId", appUserColumnSetIds[s]);
            //console.log("guAppUserColumnSetId array", appUserColumnSetIds);


            appUserColumnExists = await this.appUserColumnService.getAppUserColumnParamArrayPromise(aParamList);

            //console.log("appUserColumnExists", appUserColumnExists);
           if (appUserColumnExists != null && appUserColumnExists.length > 0) {
              //console.log("breaking out of for loop");
              this.alertService.openSnackBar("This column already exists for this user, page and column set", "Close", "center", "bottom", "", 3000);
              continue;
            }

            appUserColumn = new AppUserColumn;

            delete appUserColumn.rowguid;  //remove rowguid property so DB will create one.

            appUserColumn.dteDateCreated = new Date(datepipe.transform(dateCreated, 'yyyy-MM-dd'));
            appUserColumn.guAppUserId = userId;
            appUserColumn.guAppUserColumnSetId = appUserColumnSetIds[s];
            appUserColumn.guAppPageColumnId = appPageColumnId[i].rowguid;
            appUserColumn.intColumnOrder = appPageColumnId[i].intDefaultColumnOrder;
            appUserColumn.blnVisible = visible;

            appUserColumn = await this.appUserColumnService.createAppUserColumnPromise(appUserColumn);
          }

          if (mode == "Create") {
            appUserColumn = await this.appUserColumnService.getAppUserColumnPromise(appUserColumn.rowguid);
            this.appUserColumnCreated.emit(appUserColumn);
          }

          if (mode == "CreateAndAdd") {
            this.resetFields(mode);
          }
        }

        if (mode == "Create") {
          this.appUserColumnCreated.emit(appUserColumn);
        }

        if (mode == "CreateAndAdd") {
          this.resetFields(mode);
        }

      }

    }

  }

  cancel() {
    this.resetFields('');
    this.appUserColumnCancelled.emit(true);

  }

  resetFields(mode: string) {

    if (mode != "CreateAndAdd") {
      //this.form.controls['dteDateCreated'].setValue('');
      this.form.controls['guAppUserId'].setValue('');
      this.form.controls['guAppUserColumnSetId'].setValue('');
      //this.form.controls['blnVisible'].setValue('1');
    }

    //this.form.controls['guAppPageColumnId'].setValue('');
    this.form.controls['intColumnOrder'].setValue('');
    //this.form.controls['blnAddAllColumns'].setValue('0');

  }

  onUserChange(userId: string) {


  }

  async onPageChange(pageId: string) {

    //console.log("pageId: " + pageId);
    let users: [] = this.form.controls['guAppUserId'].value;
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (users == null || users.length == 0) {
      this.alertService.openSnackBar("Please select a user", "Close", "center", "bottom", "", 3000);
      return;
    }

    aParam.push(new SQLParamArray("guAppPageId", pageId));
    aParamList.push(aParam);
    this.appPageColumns = await this.appPageColumnService.getAppPageColumnParamArrayPromise(aParamList);

    aParamList = [];
    aParam = [];
    aParam.push(new SQLParamArray("guAppPageId", pageId));
    aParamList.push(aParam);

    aParam = [];
    for (let i = 0; i <= users.length; i++) {
      aParam.push(new SQLParamArray("guAppUserId", users[i]));
    }
    aParamList.push(aParam);

    //console.log("user", users);
    this.appUserColumnSets = await this.dtoAppUserColumnSetService.getdtoAppUserColumnSetParamArrayPromise(aParamList);


  }

  onAddAllColumnsChange(val: boolean) {

    //console.log(val);

    if (val == true) {
      this.form.controls['blnVisible'].setValue(1);
      this.form.controls['blnVisible'].disable();
      this.form.controls['intColumnOrder'].disable();
    }

    if (val == false) {
      this.form.controls['blnVisible'].setValue(0);
      this.form.controls['blnVisible'].enable();
      this.form.controls['intColumnOrder'].enable();
    }
  }


}
