import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../_shared/services/alert.service';

import { AppUser } from '../_shared/business-objects/app-user.bo';
import { GeoStockLocation } from '../_shared/business-objects/geo-stock-location.bo';

import { GeoStockLocationService } from '../_shared/services/geo-stock-location.service';
import { AppUserService } from '../_shared/services/app-user.service';
import { AppUserGeoStockLocationService } from '../_shared/services/appuser-geostocklocation.service';
import { AppUserGeoStockLocation } from '../_shared/business-objects/app-user-geo-stock-location.bo';
import { NavigationService } from '../_shared/services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appuser-geostocklocation-create',
  templateUrl: './appuser-geostocklocation-create.component.html',
  styleUrls: ['./appuser-geostocklocation-create.component.css']
})
export class AppuserGeostocklocationCreateComponent implements OnInit {

  form: FormGroup;
  appUsers: AppUser[];
  geoStockLocations: GeoStockLocation[];

  guAppUserId = new FormControl('');
  guGeoStockLocationId = new FormControl('');
  blnDefaultLocation = new FormControl('');

  selectedAppUsers: string;
  selectedGeoStockLocations: [];


  constructor(private fb: FormBuilder, private geoStockLocationService: GeoStockLocationService, private appUserService: AppUserService, private alertService: AlertService
    , private appUserGeoStockLocationService: AppUserGeoStockLocationService, private navigationService: NavigationService,
    private router: Router) {
    this.form = this.fb.group({
      guAppUserId: ['', Validators.required],
      guGeoStockLocationId: ['', Validators.required],
      blnDefaultLocation: [false]
    });

  }

  ngOnInit(): void {

    this.geoStockLocationService.getAllGeoStockLocations().subscribe(geoLocations => {
      this.geoStockLocations = geoLocations;

    }, err => {
      console.log(err);
    });

    this.appUserService.getAllAppUsers().subscribe(appUsers => {
      this.appUsers = appUsers;

    }, err => {
      console.log(err);
    });


  }


  async addAppUserToLoctions() {
    for (let l = 0; l <= this.selectedGeoStockLocations.length - 1; l++) {
      console.log("adding user to locaton");
      let defaultLocation: boolean = false;
      if (this.blnDefaultLocation.value) {
        defaultLocation = true;
      }
      await this.addAppUserLocation(this.selectedAppUsers, this.selectedGeoStockLocations[l], defaultLocation);
      }

    //this.router.navigate(['appuser-geolocation-list'], { queryParams: { returnUrl: this.navigationService.getReturnURL() } });
    this.router.navigate(['appuser-geostocklocation-list']);

  }


  async addAppUserLocation(appUserId: string, geoStockLocationId: string, defaultLocation: boolean) {
    if (appUserId == "") {
      this.alertService.openSnackBarError("There is no app user id", "Close", "center", "bottom", 3000, false, "");
      return;
    }

    if (geoStockLocationId == "") {
      this.alertService.openSnackBarError("There is no geo stock location id", "Close", "center", "bottom", 3000, false, "");
      return;
    }

    return new Promise(resolve => {
      let appUserLocation = new AppUserGeoStockLocation();
      appUserLocation.blnActive = true;
      appUserLocation.guAppUserId = appUserId;
      appUserLocation.guGeoStockLocationId = geoStockLocationId;
      appUserLocation.blnDefaultLocation = defaultLocation;


      this.appUserGeoStockLocationService.createAppUserGeoStockLocation(appUserLocation).subscribe(appUserLoc => {
        resolve(appUserLoc);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  getRemainingLocations(appUserId) {
    
    this.geoStockLocationService.getRemainingAppUserGeoStockLocations(appUserId).subscribe(geoLocations => {
      this.geoStockLocations = geoLocations;

    }, err => {
      console.log(err);
    });
  }



}
