import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoCustomerContact } from '../business-objects/dto-customer-contact.bo';

@Injectable()
export class dtoCustomerContactService
{

  public _baseUrl: string;
  customerContactsNotOnDocket: dtoCustomerContact[];

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoCustomerContact(dtoCustomerContactId: string) {
		return this.http.get<dtoCustomerContact>(this._baseUrl + 'api/dtoCustomerContact/' + dtoCustomerContactId);
	}

	getdtoCustomerContactParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoCustomerContact[]>(this._baseUrl + 'api/dtoCustomerContact/GetdtoCustomerContactsParamArray/', aParam);
  }

	getAlldtoCustomerContacts() {
		return this.http.get<dtoCustomerContact[]>(this._baseUrl + 'api/dtoCustomerContact/GetAlldtoCustomerContacts/');
	}

	//getAlldtodtoCustomerContacts() {
	//	return this.http.get<dtodtoCustomerContact[]>(this._baseUrl + 'api/dtoCustomerContact/GetAlldtoCustomerContacts/');
	//}

	createdtoCustomerContact(dtoCustomerContact: dtoCustomerContact) {
		return this.http.post<dtoCustomerContact>(this._baseUrl + 'api/dtoCustomerContact/CreatedtoCustomerContact/', dtoCustomerContact);
	}

	updatedtoCustomerContact(dtoCustomerContact: dtoCustomerContact) {
		return this.http.put<dtoCustomerContact>(this._baseUrl + 'api/dtoCustomerContact/UpdatedtoCustomerContact/', dtoCustomerContact);
	}

	deletedtoCustomerContact(dtoCustomerContactId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoCustomerContact/DeletedtoCustomerContact/' + dtoCustomerContactId);
	}

	async getdtoCustomerContactPromise(dtoCustomerContactId: string) {
		return new Promise<dtoCustomerContact>(resolve => {
			this.getdtoCustomerContact(dtoCustomerContactId).subscribe(dtoCustomerContact => {
				resolve(dtoCustomerContact);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoCustomerContact records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoCustomerContactParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoCustomerContact[]>(resolve => {
			this.getdtoCustomerContactParamArray(aParam).subscribe(dtoCustomerContacts => {
				resolve(dtoCustomerContacts);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoCustomerContact records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoCustomerContactByCustomerId(customerId: string) {
    return new Promise<dtoCustomerContact[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guCustomerId", customerId));
      aParamList.push(aParam);

      this.getdtoCustomerContactParamArray(aParamList).subscribe({
        next: (dtoCustomerContacts) => {
          resolve(dtoCustomerContacts);
        }, error: (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error getting dtoCustomerContact records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      }});
    });
  }

  async getdtoCustomerContactsOnDeliveryDocket(customerId: string, deliveryDocketId: string) {
    return new Promise<dtoCustomerContact[]>(resolve => {
      this.http.get<dtoCustomerContact[]>(this._baseUrl + 'api/dtoCustomerContact/GetdtoCustomerContactsOnDeliveryDocket/' + customerId + '/' + deliveryDocketId).subscribe(dtoCustomerContacts => {
        resolve(dtoCustomerContacts);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoCustomerContact records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getdtoCustomerContactsNotOnDeliveryDocket(customerId: string, deliveryDocketId: string, reset: boolean) {
    return new Promise<dtoCustomerContact[]>(resolve => {

      if (this.customerContactsNotOnDocket != null && reset == false) {
        resolve(this.customerContactsNotOnDocket);
        return;
      }

      this.http.get<dtoCustomerContact[]>(this._baseUrl + 'api/dtoCustomerContact/GetdtoCustomerContactsNotOnDeliveryDocket/' + customerId + '/' + deliveryDocketId).subscribe(dtoCustomerContacts => {
        this.customerContactsNotOnDocket = dtoCustomerContacts;
        resolve(dtoCustomerContacts);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoCustomerContact records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async updatedtoCustomerContactPromise(dtoCustomerContact: dtoCustomerContact) {
		return new Promise<dtoCustomerContact>(resolve => {
			this.updatedtoCustomerContact(dtoCustomerContact).subscribe(dtoCustomerContact => {
				resolve(dtoCustomerContact);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoCustomerContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoCustomerContactPromise(dtoCustomerContact: dtoCustomerContact) {
		return new Promise<dtoCustomerContact>(resolve => {
			this.createdtoCustomerContact(dtoCustomerContact).subscribe(dtoCustomerContact => {
				resolve(dtoCustomerContact);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoCustomerContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoCustomerContactPromise(dtoCustomerContactId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoCustomerContact(dtoCustomerContactId).subscribe(dtoCustomerContact => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoCustomerContact record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
