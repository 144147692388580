import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { PoleCube } from '../business-objects/pole-cube.bo';
import { SharedService } from './shared.service';

@Injectable()
export class PoleCubeService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getPoleCube(poleCubeId: string) {
		return this.http.get<PoleCube>(this._baseUrl + 'api/PoleCube/' + poleCubeId);
	}

    getPoleCubeBySpec(poleId: string, productTypeId: string, poleSpec: SharedService.enPoleSpec) {
      return this.http.get<PoleCube>(this._baseUrl + 'api/PoleCube/GetPoleCubeBySpec/' + poleId + '/' + productTypeId + '/' + poleSpec);
    }

	getPoleCubeParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<PoleCube[]>(this._baseUrl + 'api/PoleCube/GetPoleCubesParamArray/', aParam);
	}

	getAllPoleCubes() {
		return this.http.get<PoleCube[]>(this._baseUrl + 'api/PoleCube/GetAllPoleCubes/');
	}

	//getAlldtoPoleCubes() {
	//	return this.http.get<dtoPoleCube[]>(this._baseUrl + 'api/PoleCube/GetAllPoleCubes/');
	//}

	createPoleCube(poleCube: PoleCube) {
		return this.http.post<PoleCube>(this._baseUrl + 'api/PoleCube/CreatePoleCube/', poleCube);
	}

	updatePoleCube(poleCube: PoleCube) {
		return this.http.put<PoleCube>(this._baseUrl + 'api/PoleCube/UpdatePoleCube/', poleCube);
	}

	async getPoleCubePromise(poleCubeId: string) {
		return new Promise<PoleCube>(resolve => {
			this.getPoleCube(poleCubeId).subscribe(poleCube => {
				resolve(poleCube);
			}, err => {
				this.alertService.openSnackBarError("Error getting PoleCube records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getPoleCubeParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<PoleCube[]>(resolve => {
			this.getPoleCubeParamArray(aParam).subscribe(poleCubes => {
				resolve(poleCubes);
			}, err => {
				this.alertService.openSnackBarError("Error getting PoleCube records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

    async getPoleCubeBySpecPromise(poleId: string, productTypeId: string, poleSpec: SharedService.enPoleSpec) {
      return new Promise<PoleCube>(resolve => {
        this.getPoleCubeBySpec(poleId, productTypeId, poleSpec).subscribe(poleCube => {
          resolve(poleCube);
        }, err => {
          this.alertService.openSnackBarError("Error getting PoleCube record", "Close", "center", "bottom", 4000, true, err.error);
          resolve(null);
        });
      });
    }

	async updatePoleCubePromise(poleCube: PoleCube) {
		return new Promise<PoleCube>(resolve => {
			this.updatePoleCube(poleCube).subscribe(poleCube => {
				resolve(poleCube);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating PoleCube record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createPoleCubePromise(poleCube) {
		return new Promise<PoleCube>(resolve => {
			this.createPoleCube(poleCube).subscribe(poleCube => {
				resolve(poleCube);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating PoleCube record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

}
