import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-machine-run-create',
  templateUrl: './machine-run-create.component.html',
  styleUrls: ['./machine-run-create.component.css']
})
export class MachineRunCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
