import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppUser } from '../business-objects/app-user.bo';
import { AlertService } from './alert.service';
import { SQLParamArray } from '../business-objects/sql-param-array';

@Injectable()
export class AppUserService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getAppUser(appUserId: string) {
		return this.http.get<AppUser>(this._baseUrl + 'api/AppUser/' + appUserId);
	}

    getAppUserParamArray(aParam: SQLParamArray[][]) {
      return this.http.post<AppUser[]>(this._baseUrl + 'api/AppUser/GetAppUsersParamArray/', aParam);
    }

	getAllAppUsers() {
		return this.http.get<AppUser[]>(this._baseUrl + 'api/AppUser/GetAllAppUsers/');
	}

	//getAlldtoAppUsers() {
	//	return this.http.get<dtoAppUser[]>(this._baseUrl + 'api/AppUser/GetAllAppUsers/');
	//}

	createAppUser(appUser: AppUser) {
		return this.http.post<AppUser>(this._baseUrl + 'api/AppUser/CreateAppUser/', appUser);
	}

	updateAppUser(appUser: AppUser) {
		return this.http.put<AppUser>(this._baseUrl + 'api/AppUser/UpdateAppUser/', appUser);
  }

  async getAppUserPromise(appUserId: string) {
    return new Promise<AppUser>(resolve => {
      this.getAppUser(appUserId).subscribe(appUser => {
        resolve(appUser);
      }, err => {
        this.alertService.openSnackBarError("Error getting AppUser records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getAppUserParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<AppUser[]>(resolve => {
      this.getAppUserParamArray(aParam).subscribe(appUsers => {
        resolve(appUsers);
      }, err => {
        this.alertService.openSnackBarError("Error getting AppUser records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async updateAppUserPromise(appUser: AppUser) {
    return new Promise<AppUser>(resolve => {
      this.updateAppUser(appUser).subscribe(appUser => {
        resolve(appUser);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating AppUser record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async createAppUserPromise(appUser) {
    return new Promise<AppUser>(resolve => {
      this.createAppUser(appUser).subscribe(appUser => {
        resolve(appUser);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating AppUser record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

}
