import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { AppUserService } from '../../_shared/services/app-user.service';
import { FormService } from '../../_shared/services/form.service';
import { AppUser } from '../../_shared/business-objects/app-user.bo'
import { AppUserListComponent } from '../app-user-list/app-user-list.component';

export enum enAppUserTabs { AppUsers = 0, SearchOptions = 1, CreateAppUser = 2, UpdateAppUser = 3 }

@Component({
  selector: 'app-app-user-search',
  templateUrl: './app-user-search.component.html',
  styleUrl: './app-user-search.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AppUserSearchComponent implements OnInit {

  @ViewChild('tabAppUsers', { static: false }) tabAppUsers: MatTabGroup;
  @ViewChild('appAppUserList', { static: false }) appAppUserList: AppUserListComponent;

  
  form: FormGroup;
  public eAppUserTabs = enAppUserTabs;

  selectedTab: number;
  showTable: boolean = false;
  showSpinner: boolean = false;
  appUsers: MatTableDataSource<AppUser> = new MatTableDataSource();
  appUser: AppUser;


  constructor(private appUserService: AppUserService, private alertService: AlertService, private fb: FormBuilder
            , private formService: FormService) {

    this.form = this.fb.group({
      txtSearchUserName: [''],
      txtSearchFirstName: [''],
      txtSearchLastName: [''],
      dteSearchCreatedDateFrom: [''],
      dteSearchCreatedDateTo: ['']
    });

  }


  ngOnInit() {

    this.searchAppUsers();

  }

  async searchAppUsers() {

    let userName: string = this.form.controls["txtSearchUserName"].value;
    let firstName: string = this.form.controls["txtSearchFirstName"].value;
    let lastName: string = this.form.controls["txtSearchLastName"].value;
    let dateFrom: string = this.form.controls["dteSearchCreatedDateFrom"].value;
    let dateTo: string = this.form.controls["dteSearchCreatedDateTo"].value;
    const datepipe: DatePipe = new DatePipe('en-AU');


    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    if (userName != "" && userName != null) {
      aParam = [];
      if (userName.indexOf(",")) {
        let aUserNames: string[] = userName.split(",");
        for (let i = 0; i <= aUserNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtUserName", aUserNames[i], SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtUserName", userName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (firstName != "" && firstName != null) {
      aParam = [];
      if (firstName.indexOf(",")) {
        let aFirstNames: string[] = firstName.split(",");
        for (let i = 0; i <= aFirstNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtFirstName", aFirstNames[i], SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtFirstName", firstName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (lastName != "" && lastName != null) {
      aParam = [];
      if (lastName.indexOf(",")) {
        let aLastNames: string[] = firstName.split(",");
        for (let i = 0; i <= aLastNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtLastName", aLastNames[i], SQLParamArray.enSQLOperator.Like))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtLastName", lastName, SQLParamArray.enSQLOperator.Like))
      }
      aParamList.push(aParam);
    }

    if (dateFrom != null && dateFrom != "") {
      aParam = [];
      aParam.push(new SQLParamArray("dteCreatedDate", datepipe.transform(dateFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }

    if (dateTo != null && dateTo != "") {
      aParam = [];
      aParam.push(new SQLParamArray("dteCreatedDate", datepipe.transform(dateTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }


    this.appUsers.data = await this.appUserService.getAppUserParamArrayPromise(aParamList);

    this.appUsers.data = this.appUsers.data.sort((a, b) => { return a.txtLastName > b.txtLastName && a.txtFirstName > b.txtFirstName ? 1 : -1 });

  }

  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    console.log(e.index == this.eAppUserTabs.SearchOptions);

    if (e.index == this.eAppUserTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchCustomer");
    }

    if (e.index == this.eAppUserTabs.AppUsers) {
      this.formService.setAutoFocusTimeout("txtSearchLoadingDocketNo");
    }



  }


  editAppUser(appUser: AppUser) {

    console.log("appuser: ", appUser);

    this.appUser = appUser;

    this.tabAppUsers.selectedIndex = this.eAppUserTabs.UpdateAppUser;

  }

  appUserUpdated(appUser: AppUser) {

    this.tabAppUsers.selectedIndex = this.eAppUserTabs.AppUsers;

    this.appUsers.data.length = 0;
    this.appUsers.data.push(appUser);

    this.appAppUserList.tblAppUser.renderRows();

  }

  appUserCancelled(val: boolean) {

    this.tabAppUsers.selectedIndex = this.eAppUserTabs.AppUsers;

  }

}
