<form class="example-form" [formGroup]="form">
  <div class="app-user-columns-container">
    <h1>App User Columns</h1>

    <mat-tab-group color="accent" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabAppUserColumns>
      <mat-tab label="App User Columns" class="tab-label">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="text" id="txtSearchAppUserColumnName" formControlName="txtSearchAppUserColumnName" (keyup.enter)="searchAppUserColumns()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple names by a comma i.e. Stock Items, Delivery Dockets..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Page Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. FinalInspection" value="" type="text" id="txtSearchAppUserPageName" formControlName="txtSearchAppUserPageName" (keyup.enter)="searchAppUserColumns()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple names by a comma i.e. Stock Items, Delivery Dockets..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Column Set Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Pole View" value="" type="text" id="txtSearchColumnSetName" formControlName="txtSearchColumnSetName" (keyup.enter)="searchAppUserColumns()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple names by a comma i.e. Stock Items, Delivery Dockets..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>First Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. John" value="" type="text" id="txtSearchFirstName" formControlName="txtSearchFirstName" (keyup.enter)="searchAppUserColumns()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple names by a comma i.e. Stock Items, Delivery Dockets..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Last Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. John" value="" type="text" id="txtSearchLastName" formControlName="txtSearchLastName" (keyup.enter)="searchAppUserColumns()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple names by a comma i.e. Stock Items, Delivery Dockets..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-column-3">
            <button class="search-app-user-columns-button" type="button" mat-flat-button color="primary" id="btnSearchAppUserColumnsMain" (click)="searchAppUserColumns()">Search</button>
          </div>
        </div>

        <div class="search-totals-container">
          <div class="search-total-rows">Total Rows: {{totalRows}}</div>
          <div class="search-total-pieces">Results Time: {{resultTime}} s</div>
        </div>
        <mat-divider></mat-divider>

        <div class="stock-item-list-container">
          <app-app-user-column-list [datasource]="appUserColumns" (editAppUserColumnEvent)="editAppUserColumn($event)" (removeAppUserColumnEvent)="appUserColumnRemove($event)" *ngIf="appUserColumns != null"></app-app-user-column-list>
        </div>

      </mat-tab>
      <mat-tab label="Search Options" class="tab-label">
        <div class="search-options-fields-container" id="searchFieldsContainer">

          <div class="search-option-row-1">
            <label id="example-radio-group-label">App</label>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radSearchApps">
              <mat-radio-button class="example-radio-button" *ngFor="let app of apps" [value]="app.intValue">
                {{app.txtValue}}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="search-option-row-2">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Page URL</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. stock-items" value="" type="text" id="txtSearchAppUserColumnURL" formControlName="txtSearchAppUserColumnURL" (keyup.enter)="searchAppUserColumns()">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use and astrix (*) anywhere if you don't know the full location name i.e. stock-* report" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-3">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created From</mat-label>
              <input id="dteSearchDateCreatedFrom" class="date-input created-from-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteSearchDateCreatedFrom">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created To</mat-label>
              <input id="dteSearchDateCreatedTo" class="date-input created-from-date-search" #searchReceiptDateTo matInput [matDatepicker]="pickercreatedto" formControlName="dteSearchDateCreatedTo">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedto"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedto></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-7">
            <button class="search-option-delivery-docket-button" type="button" mat-flat-button color="primary" id="btnSearchAppUserColumns" (click)="searchAppUserColumns()">Search</button>
          </div>

        </div>
      </mat-tab>

      <mat-tab label="Create App User Column" class="tab-label">
        <div class="create-app-user-column-container">
          <app-app-user-column-create (appPageCreated)="appUserColumnCreated($event)" (appUserColumnCreatedAllColumns)="appUserColumnCreatedAllColumns($event)" (appUserColumnCreatedCopyUser)="appUserColumnCreatedCopyUser($event)" (appPageCancelled)="appUserColumnCancelled()" *ngIf="selectedTab == enAppUserColumnTabs.CreateAppUserColumn"></app-app-user-column-create>
        </div>
      </mat-tab>

      <mat-tab label="Update App User Column" class="tab-label">
        <div class="update-stock-item-container">
          <app-app-user-column-update [appUserColumn]="appUserColumn" (appUserColumnUpdated)="appUserColumnUpdated($event)" (appUserColumnCancelled)="appUserColumnCancelled()" *ngIf="selectedTab == enAppUserColumnTabs.UpdateAppUserColumn"></app-app-user-column-update>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
