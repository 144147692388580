<div class="customer-delivery-address-create-container">
  <div class="customer-delivery-address-create-form" [formGroup]="form">
    <div class="customer-delivery-address-field-container">

      <div class="customer-name-container">
        <div class="customer-name-label">Customer:</div><div class="customer-name">{{customerName}}</div>
      </div>

      <div class="customer-delivery-address-street-container">
        <mat-form-field class="chh-form-field example-full-width street-address-form-field" appearance="fill">
          <mat-label>Street Address</mat-label>
          <input class="textbox-input" matInput placeholder="i.e. 161 Tallawudjah Creek Rd..." value="" type="text" id="txtStreetAddress" formControlName="txtStreetAddress">
        </mat-form-field>
      </div>

      <div class="customer-delivery-address-town-container">
        <mat-form-field class="chh-form-field example-full-width town-form-field" appearance="fill">
          <mat-label>Town</mat-label>
          <input class="textbox-input" matInput value="" type="text" #txtTown formControlName="txtTown">
        </mat-form-field>
      </div>

      <div class="customer-delivery-address-state-container">
        <mat-selection-list class="chh-mat-selection-list state-select" #txtState [multiple]="false" id="txtState" formControlName="txtState">
          <mat-list-option *ngFor="let state of states" [value]="state.txtValue">
            {{state.txtValue}}
          </mat-list-option>
        </mat-selection-list>
      </div>


      <div class="customer-delivery-address-post-code-container">
        <div class="post-code-form-field-container">
          <mat-form-field class="chh-form-field example-full-width post-code-form-field" appearance="fill">
            <mat-label>Postcode</mat-label>
            <input class="textbox-input" matInput value="" type="text" #txtPostcode formControlName="txtPostcode">
          </mat-form-field>
        </div>
        <div class="lookup-post-code-button-column">
          <div class="post-code-grid-container">
            <div class="post-code-button-container">
              <button class="post-code-button" type="button" mat-flat-button color="primary" (click)="lookupPostcode(txtTown.value, txtState._value[0])">Get Postcode</button>
            </div>
            <div class="inline-spinner-container">
              <app-spinner-inline-tick #spinnerInlineTick [showSpinner]="showPostcodeSpinner" [showTick]="showTick" [diameter]="30" class="postcode-spinner"></app-spinner-inline-tick>
            </div>
          </div>
        </div>

        <div [ngClass]="postcodes != null ? 'multipostcode-container-transition' : 'multipostcode-container'">
          <mat-selection-list *ngIf="postcodes != null" class="chh-mat-selection-list multipostcode-select" #txtMultiPostcode [multiple]="false" id="txtMultiPostcode" (selectionChange)="selectPostcode(txtMultiPostcode._value[0])">
            <mat-list-option *ngFor="let postcode of postcodes" [value]="postcode.postcode">
              {{postcode.name}} {{postcode.postcode}}
            </mat-list-option>
          </mat-selection-list>
        </div>
        <div class="no-post-code-found" *ngIf="noPostcodeFound">No postcode found</div>
      </div>

      <div class="customer-delivery-address-country-container">
        <mat-form-field class="chh-form-field example-full-width country-form-field" appearance="fill">
          <mat-label>Country</mat-label>
          <input class="textbox-input" matInput value="" type="text" formControlName="txtCountry">
        </mat-form-field>
      </div>

      <div class="customer-delivery-address-site-region-name-container">
        <mat-form-field class="chh-form-field example-full-width site-region-name-form-field" appearance="fill">
          <mat-label>Site / Region Name</mat-label>
          <input class="textbox-input" matInput value="" type="text" formControlName="txtSiteRegionName">
        </mat-form-field>
      </div>

      <div class="customer-delivery-address-contact-first-name-container">
        <mat-form-field class="chh-form-field example-full-width contact-first-name-form-field" appearance="fill">
          <mat-label>Contact First Name</mat-label>
          <input class="textbox-input" matInput value="" type="text" formControlName="txtContactFirstName">
        </mat-form-field>
      </div>

      <div class="customer-delivery-address-contact-last-name-container">
        <mat-form-field class="chh-form-field example-full-width contact-last-name-form-field" appearance="fill">
          <mat-label>Contact Last Name</mat-label>
          <input class="textbox-input" matInput value="" type="text" formControlName="txtContactLastName">
        </mat-form-field>
      </div>

      <div class="customer-delivery-address-email-to-container">
        <mat-form-field [ngClass]="form.controls['txtDeliveryEmailAddressTo']?.hasError('email') ? 'chh-form-field-with-error example-full-width email-to-form-field' : 'chh-form-field example-full-width email-to-form-field'" appearance="fill">
          <mat-label>Delivery Email To</mat-label>
          <input class="textbox-input" matInput value="" type="email" [email]="true" formControlName="txtDeliveryEmailAddressTo">
          <mat-error *ngIf="form.controls['txtDeliveryEmailAddressTo']?.hasError('email')">
            Invalid email address.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="customer-delivery-address-email-cc-container">
        <mat-form-field [ngClass]="form.controls['txtDeliveryEmailAddressCC']?.hasError('email') ? 'chh-form-field-with-error example-full-width email-cc-form-field' : 'chh-form-field example-full-width email-cc-form-field'" appearance="fill">
          <mat-label>Delivery Email CC</mat-label>
          <input class="textbox-input" matInput value="" type="email" [email]="true" formControlName="txtDeliveryEmailAddressCC">
          <mat-error *ngIf="form.controls['txtDeliveryEmailAddressCC']?.hasError('email')">
            Invalid email address.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="customer-delivery-address-active-container">
        <mat-checkbox class="example-margin" #blnActive id="blnActive" formControlName="blnActive">Active</mat-checkbox>
      </div>

      <div class="customer-delivery-address-comments-container">
        <textarea class="comments" placeholder="Comments" autosize minRows="2" id="txtComments" [(ngModel)]="addressComments" formControlName="txtComments"></textarea>
      </div>


    </div>

  </div>

  <div class="action-button-container">
    <button class="action-button" mat-flat-button color="primary" (click)="createCustomerDeliveryAddress()">Create Delivery Address</button>
    <button class="action-button" mat-flat-button color="primary" (click)="cancelCustomerDeliveryAddress()">Cancel</button>
  </div>

</div>

<app-spinner *ngIf="showSpinner" [showSpinner]="showSpinner" class="customer-spinner"></app-spinner>
