import { Printer } from "../business-objects/printer.bo";

export interface StockItemBarcode {
  txtIdentifier: string;
  txtProductName: string;
  txtProductShortName: string;
  txtDescription: string;
  txtDateCreated: string;
  txtWidth: string;
  txtThickness: string;
  txtLength: string;
  txtQuantity: string;
  txtUOM: string;
  txtSpecies: string;
  txtVisualGrade: string;
  txtStructuralGrade: string;
  txtRunNos: string;
  txtLogoBase64: string;
  Printer: Printer;
}

export class StockItemBarcode {
  txtIdentifier: string;
  txtProductName: string;
  txtProductShortName: string;
  txtDescription: string;
  txtDateCreated: string;
  txtWidth: string;
  txtThickness: string;
  txtLength: string;
  txtQuantity: string;
  txtUOM: string;
  txtSpecies: string;
  txtVisualGrade: string;
  txtStructuralGrade: string;
  txtRunNos: string;
  txtLogoBase64: string;
  Printer: Printer;
}
