import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoRoyaltyRate } from '../business-objects/dto-royalty-rate.bo';

@Injectable()
export class dtoRoyaltyRateService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoRoyaltyRate(dtoRoyaltyRateId: string) {
		return this.http.get<dtoRoyaltyRate>(this._baseUrl + 'api/dtoRoyaltyRate/' + dtoRoyaltyRateId);
	}

	getdtoRoyaltyRateParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoRoyaltyRate[]>(this._baseUrl + 'api/dtoRoyaltyRate/GetdtoRoyaltyRatesParamArray/', aParam);
	}

	getAlldtoRoyaltyRates() {
		return this.http.get<dtoRoyaltyRate[]>(this._baseUrl + 'api/dtoRoyaltyRate/GetAlldtoRoyaltyRates/');
	}

	//getAlldtodtoRoyaltyRates() {
	//	return this.http.get<dtodtoRoyaltyRate[]>(this._baseUrl + 'api/dtoRoyaltyRate/GetAlldtoRoyaltyRates/');
	//}

	createdtoRoyaltyRate(dtoRoyaltyRate: dtoRoyaltyRate) {
		return this.http.post<dtoRoyaltyRate>(this._baseUrl + 'api/dtoRoyaltyRate/CreatedtoRoyaltyRate/', dtoRoyaltyRate);
	}

	updatedtoRoyaltyRate(dtoRoyaltyRate: dtoRoyaltyRate) {
		return this.http.put<dtoRoyaltyRate>(this._baseUrl + 'api/dtoRoyaltyRate/UpdatedtoRoyaltyRate/', dtoRoyaltyRate);
	}

	async getdtoRoyaltyRatePromise(dtoRoyaltyRateId: string) {
		return new Promise<dtoRoyaltyRate>(resolve => {
			this.getdtoRoyaltyRate(dtoRoyaltyRateId).subscribe(dtoRoyaltyRate => {
				resolve(dtoRoyaltyRate);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoRoyaltyRate records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoRoyaltyRateParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoRoyaltyRate[]>(resolve => {
			this.getdtoRoyaltyRateParamArray(aParam).subscribe(dtoRoyaltyRates => {
				resolve(dtoRoyaltyRates);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoRoyaltyRate records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoRoyaltyRatePromise(dtoRoyaltyRate: dtoRoyaltyRate) {
		return new Promise<dtoRoyaltyRate>(resolve => {
			this.updatedtoRoyaltyRate(dtoRoyaltyRate).subscribe(dtoRoyaltyRate => {
				resolve(dtoRoyaltyRate);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoRoyaltyRate record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoRoyaltyRatePromise(dtoRoyaltyRate: dtoRoyaltyRate) {
		return new Promise<dtoRoyaltyRate>(resolve => {
			this.createdtoRoyaltyRate(dtoRoyaltyRate).subscribe(dtoRoyaltyRate => {
				resolve(dtoRoyaltyRate);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoRoyaltyRate record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}