
export interface FieldQueryParamMap {
  txtField: string;
  txtQueryParam: string;
  intFieldType: FieldQueryParamMap.enFieldType;
  intValueType: FieldQueryParamMap.enValueType;
}

export class FieldQueryParamMap {
  txtField: string;
  txtQueryParam: string;
  intFieldType: FieldQueryParamMap.enFieldType;
  intValueType: FieldQueryParamMap.enValueType;
}

export namespace FieldQueryParamMap {
  export enum enFieldType {
    Textbox,
    SelectboxSingle,
    SelectboxMultiple,
    Checkbox,
    SlideToogle,
    Radiobox
  }

  export enum enValueType {
    String,
    Int,
    Float,
    Boolean,
    Date
  }
}
