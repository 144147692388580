import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoSpecies } from '../business-objects/dto-species.bo';

@Injectable()
export class dtoSpeciesService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoSpecies(dtoSpeciesId: string) {
		return this.http.get<dtoSpecies>(this._baseUrl + 'api/dtoSpecies/' + dtoSpeciesId);
	}

	getdtoSpeciesParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoSpecies[]>(this._baseUrl + 'api/dtoSpecies/GetdtoSpeciesParamArray/', aParam);
	}

	getAlldtoSpecies() {
		return this.http.get<dtoSpecies[]>(this._baseUrl + 'api/dtoSpecies/GetAlldtoSpecies/');
	}

	//getAlldtodtoSpeciess() {
	//	return this.http.get<dtodtoSpecies[]>(this._baseUrl + 'api/dtoSpecies/GetAlldtoSpecies/');
	//}

	createdtoSpecies(dtoSpecies: dtoSpecies) {
		return this.http.post<dtoSpecies>(this._baseUrl + 'api/dtoSpecies/CreatedtoSpecies/', dtoSpecies);
	}

	updatedtoSpecies(dtoSpecies: dtoSpecies) {
		return this.http.put<dtoSpecies>(this._baseUrl + 'api/dtoSpecies/UpdatedtoSpecies/', dtoSpecies);
	}

	deletedtoSpecies(dtoSpeciesId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoSpecies/DeletedtoSpecies/' + dtoSpeciesId);
	}

  async getdtoAllSpecies() {
    return new Promise<dtoSpecies[]>(resolve => {
      this.getAlldtoSpecies().subscribe(dtoSpecies => {
        resolve(dtoSpecies);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoSpecies records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getdtoSpeciesPromise(dtoSpeciesId: string) {
		return new Promise<dtoSpecies>(resolve => {
			this.getdtoSpecies(dtoSpeciesId).subscribe(dtoSpecies => {
				resolve(dtoSpecies);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoSpecies records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoSpeciesParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoSpecies[]>(resolve => {
			this.getdtoSpeciesParamArray(aParam).subscribe(dtoSpecies => {
				resolve(dtoSpecies);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoSpecies records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoSpeciesPromise(dtoSpecies: dtoSpecies) {
		return new Promise<dtoSpecies>(resolve => {
			this.updatedtoSpecies(dtoSpecies).subscribe(dtoSpecies => {
				resolve(dtoSpecies);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoSpecies record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoSpeciesPromise(dtoSpecies: dtoSpecies) {
		return new Promise<dtoSpecies>(resolve => {
			this.createdtoSpecies(dtoSpecies).subscribe(dtoSpecies => {
				resolve(dtoSpecies);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoSpecies record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoSpeciesPromise(dtoSpeciesId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoSpecies(dtoSpeciesId).subscribe(dtoSpecies => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoSpecies record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
