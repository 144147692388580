import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../account/auth.service';
import { FieldQueryParamMap } from '../_shared/app-objects/field-query-param-map.ao';
import { BoardTallyRecord } from '../_shared/business-objects/board-tally-record.bo';
import { DeliveryDocket } from '../_shared/business-objects/delivery-docket.bo';
import { dtoIntKeyValue, dtoKeyValue } from '../_shared/business-objects/dto-int-key-value.bo';
import { dtoStockItemDisplay } from '../_shared/business-objects/dto-stock-item-display.bo';
import { dtoStockItem } from '../_shared/business-objects/dto-stock-item.bo';
import { GeoStockLocation } from '../_shared/business-objects/geo-stock-location.bo';
import { SQLParamArray } from '../_shared/business-objects/sql-param-array';
import { AlertService } from '../_shared/services/alert.service';
import { BoardTallyRecordService } from '../_shared/services/board-tally-record.service';
import { dtoStockItemDisplayService } from '../_shared/services/dto-stock-item-display.service';
import { dtoStockItemService } from '../_shared/services/dto-stock-item.service';
import { FormService } from '../_shared/services/form.service';
import { GeoStockLocationService } from '../_shared/services/geo-stock-location.service';
import { SharedService } from '../_shared/services/shared.service';


export enum enStockItemsTabs { StockItems = 0, SearchOptions = 1, CreateStockItem = 2, UpdateStockItem = 3 }


@Component({
  selector: 'app-stock-items',
  templateUrl: './stock-items.component.html',
  styleUrls: ['./stock-items.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class StockItemsComponent implements OnInit {

  @ViewChild('tabStockItems', { static: false }) tabStockItems: MatTabGroup;

  form: FormGroup;
  selectedTab: number;
  public eStockItemsTabs = enStockItemsTabs;

  geoStockLocations: GeoStockLocation[];
  myGeoStockLocations: GeoStockLocation[];
  defaultGeoLocation: GeoStockLocation;

  deliveryStatuses: dtoIntKeyValue[] = [];

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  boardTallyRecordHistory: dtoStockItem;
  //stockItems: dtoStockItem[] = [];
  //stockItems: MatTableDataSource<dtoStockItem>;
  stockItems: MatTableDataSource<dtoStockItemDisplay>;

  addToStockItem: dtoStockItem;
  createStockItemMode: string;
  updateStockItemSelected: boolean;

  boardTallyRecords: dtoStockItemDisplay[] = [];

  totalLM: number = 0;
  totalM2: number = 0;
  totalM3: number = 0;
  totalPieces: number = 0;
  totalRows: number = 0;

  resultTime: number;
  searchTime: number;

  showProductsSpinner: boolean = false;

  constructor(private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService, private route: ActivatedRoute, private formService: FormService
    , private boardTallyRecordService: BoardTallyRecordService, private geoStockLocationService: GeoStockLocationService, private authService: AuthService,
    private dtoStockItemDisplayService: dtoStockItemDisplayService, private dtoStockItemService: dtoStockItemService, private cdref: ChangeDetectorRef  ) {

    this.form = this.fb.group({
      guGeoStockLocationId: [''],
      txtSearchIdentifier: [''],
      txtProductName: [''],
      txtProfileName: [''],
      txtWidth: [''],
      txtThickness: [''],
      txtLength: [''],
      txtSpeciesName: [''],
      txtGradeName: [''],
      dteSearchDateCreatedFrom: [''],
      dteSearchDateCreatedTo: [''],
      radDeliveryStatus: [''],
      txtSearchSKU: [''],
      radContainerArchived: [0],
      radItemArchived: [0],
      txtRetailValue: [''],
      txtTradeValue: [''],
      radApprovedPricing: [-1],
      radOnOrder: [0],
      radSlobStock: [-1],
      radClearanceStock: [-1]
   });

    this.stockItems = new MatTableDataSource();

    this.deliveryStatuses = this.sharedService.getEnumObjectKeyValue(DeliveryDocket.enDeliveryStatus);
    this.deliveryStatuses.splice(0, 0, new dtoIntKeyValue(-1, "None"));

    this.form.controls['radDeliveryStatus'].setValue(-1);



    //this.form.controls["txtProductName"].setValue("Solid Flooring");
    
    //this.form.controls["txtWidth"].setValue("80");
    //this.form.controls["txtThickness"].setValue("19");
    //this.form.controls["txtSpeciesName"].setValue("Blackbutt");
    //this.form.controls["txtGradeName"].setValue("Select");


  }


  ngOnInit(): void {

    this.selectedTab = this.eStockItemsTabs.StockItems;

    this.route.queryParams.subscribe(params => {

      this.formService.setFieldsFromQueryParams(this.filterToQueryParamMap, params, this.form);

    });

    this.loadData();
    this.createStockItemMode = "add";
    this.updateStockItemSelected = false;

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  async loadData() {

    this.defaultGeoLocation = await this.geoStockLocationService.getMyDefaultGeoLocation(this.authService.getAppUserId());
    this.form.controls['guGeoStockLocationId'].setValue(this.defaultGeoLocation.rowguid);

    this.geoStockLocations = await this.geoStockLocationService.getMyGeoStockLocationsPromise(this.authService.getAppUserId());

  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    if (e.index == this.eStockItemsTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtProductName");
    }

    if (e.index == this.eStockItemsTabs.CreateStockItem) {

      if (this.updateStockItemSelected == false) {
        this.createStockItemMode = "add";
      }

      if (this.updateStockItemSelected == true) {
        this.createStockItemMode = "additem";
        this.updateStockItemSelected = false;  // reset
      }
    }


  }

  createStockItem() {
    this.createStockItemMode = "add";
    console.log(this.createStockItemMode);

  }

  searchStockItems() {
 
    let btnSeachStockItems = document.getElementById("btnSearchStockItemsProduct");
    if (btnSeachStockItems != null) {
      btnSeachStockItems.focus();
    }

    this.tabStockItems.selectedIndex = this.eStockItemsTabs.StockItems
    this.showProductsSpinner = true;

    this.resultTime = 0;
    this.searchTime = Date.now();

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let geoLocation: string = this.form.controls["guGeoStockLocationId"].value;
    let identifier: string = this.form.controls["txtSearchIdentifier"].value.toString();
    let sku: string = this.form.controls["txtSearchSKU"].value.toString();
    let productName: string = this.form.controls["txtProductName"].value;
    let profileName: string = this.form.controls["txtProfileName"].value;
    let width: string = this.form.controls["txtWidth"].value;
    let thickness: string = this.form.controls["txtThickness"].value;
    let length: string = this.form.controls["txtLength"].value;
    let speciesName: string = this.form.controls["txtSpeciesName"].value;
    let gradeName: string = this.form.controls["txtGradeName"].value;
    let createdDateFrom: string = this.form.controls["dteSearchDateCreatedFrom"].value;
    let createdDateTo: string = this.form.controls["dteSearchDateCreatedTo"].value;
    let deliveryStatus: string = this.form.controls["radDeliveryStatus"].value;
    let containerArchived: number = this.form.controls["radContainerArchived"].value;
    let itemArchived: number = this.form.controls["radItemArchived"].value;
    let retailValue: number = this.form.controls["txtRetailValue"].value;
    let tradeValue: number = this.form.controls["txtTradeValue"].value;
    let approvedPricing: number = this.form.controls["radApprovedPricing"].value;
    let onOrder: number = this.form.controls["radOnOrder"].value;
    let slobStock: number = this.form.controls["radSlobStock"].value;
    let clearanceStock: number = this.form.controls["radClearanceStock"].value;

    const datepipe: DatePipe = new DatePipe('en-AU');

    this.totalRows = 0;
    this.totalLM = 0;
    this.totalM2 = 0;
    this.totalM3 = 0;
    this.totalPieces = 0;

    //this.stockItems = new MatTableDataSource();
    this.stockItems.data = [];

    if (geoLocation != "" && geoLocation != null) {
      aParam = [];
      aParam.push(new SQLParamArray("guGeoStockLocationId", geoLocation))
      aParamList.push(aParam);
    }

    //console.log(this.form.controls["txtSearchIdentifier"]);
    //console.log("identifier: " + identifier);

    if (identifier != "" && identifier != null) {
      aParam = [];
      if (identifier.indexOf(",")) {
        let aIdentifiers: string[] = identifier.split(",");
        for (let i = 0; i <= aIdentifiers.length - 1; i++) {
          aParam.push(new SQLParamArray("intIdentifier", aIdentifiers[i]))
        }
      }
      else {
        aParam.push(new SQLParamArray("intIdentifier", identifier))
      }
      aParamList.push(aParam);
    }

    if (sku != "" && sku != null) {
      aParam = [];
      if (sku.indexOf(",")) {
        let aSKUs: string[] = sku.split(",");
        for (let i = 0; i <= aSKUs.length - 1; i++) {
          aParam.push(new SQLParamArray("txtStockKeepingUnit", aSKUs[i], SQLParamArray.enSQLOperator.LikeAny))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtStockKeepingUnit", sku, SQLParamArray.enSQLOperator.LikeAny))
      }
      aParamList.push(aParam);

    }


    if (productName != "" && productName != null) {
      aParam = [];
      if (productName.indexOf(",")) {
        let aProductNames: string[] = productName.split(",");
        for (let i = 0; i <= aProductNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtProductName", aProductNames[i].trim(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtProductName", productName, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        //aParam.push(new SQLParamArray("guProfileId", '9C797500-88E8-44FB-BAEA-9A3D6178D067', SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }

    if (profileName != "" && profileName != null) {
      aParam = [];
      if (profileName.indexOf(",")) {
        let aProfileNames: string[] = profileName.split(",");
        for (let i = 0; i <= aProfileNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtProfileName", aProfileNames[i].trim(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtProfileName", profileName, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }

    if (width != "" && width != null) {
      aParam = [];
      if (width.indexOf(",")) {
        let aWidths: string[] = width.split(",");
        for (let i = 0; i <= aWidths.length - 1; i++) {
          aParam.push(new SQLParamArray("intWidth", aWidths[i].trim(), SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("intWidth", width, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
        //aParam.push(new SQLParamArray("intWidth", '80', SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }


    if (thickness != "" && thickness != null) {
      aParam = [];
      if (thickness.indexOf(",")) {
        let aThicknesses: string[] = thickness.split(",");
        for (let i = 0; i <= aThicknesses.length - 1; i++) {
          aParam.push(new SQLParamArray("intThickness", aThicknesses[i].trim(), SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("intThickness", thickness, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
        //aParam.push(new SQLParamArray("intThickness", '19', SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }

    if (length != "" && length != null) {
      aParam = [];
      if (length.indexOf(",")) {
        let aLengths: string[] = length.split(",");
        for (let i = 0; i <= aLengths.length - 1; i++) {
          aParam.push(new SQLParamArray("intTotalLM", aLengths[i].trim(), SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("intTotalLM", length, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }


    if (speciesName != "" && speciesName != null) {
      aParam = [];
      if (speciesName.indexOf(",")) {
        let aSpeciesNames: string[] = speciesName.split(",");
        for (let i = 0; i <= aSpeciesNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtSpeciesName", aSpeciesNames[i].trim(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtSpeciesName", speciesName, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        //aParam.push(new SQLParamArray("guSpeciesId", '597B46CE-61AC-44D0-87D7-8B690E650A74', SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }


    if (gradeName != "" && gradeName != null) {
      aParam = [];
      if (gradeName.indexOf(",")) {
        let aGradeNames: string[] = gradeName.split(",");
        for (let i = 0; i <= aGradeNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtGradeName", aGradeNames[i].trim(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtGradeName", gradeName, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        //aParam.push(new SQLParamArray("guBoardGradeId", '8D6BE813-46B6-4845-967F-C62C80378ADC', SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }


    if (createdDateFrom != "" && createdDateFrom != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdDateFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }

    if (createdDateTo != "" && createdDateTo != null) {
      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", datepipe.transform(createdDateTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND))
      aParamList.push(aParam);
    }


    if (deliveryStatus != "" && deliveryStatus != null && parseInt(deliveryStatus) > -1) {
      aParam = [];
      aParam.push(new SQLParamArray("intDeliveryStatus", deliveryStatus))
      aParamList.push(aParam);
    }

    
    if (containerArchived != null && containerArchived > -1) {
      aParam = [];
      aParam.push(new SQLParamArray("blnStockContainerArchived", containerArchived.toString()))

      if (containerArchived == 0) { // Active, add null as well
        aParam.push(new SQLParamArray("blnStockContainerArchived", "", SQLParamArray.enSQLOperator.IsNull))
      }
      aParamList.push(aParam);
    }

    if (itemArchived != null && itemArchived > -1) {
      aParam = [];
      aParam.push(new SQLParamArray("blnBTArchive", itemArchived.toString()))

      if (itemArchived == 0) { // Active, add null as well
        aParam.push(new SQLParamArray("blnBTArchive", "", SQLParamArray.enSQLOperator.IsNull))
      }
      aParamList.push(aParam);
    }

    if (approvedPricing != null && approvedPricing > -1) {

      if (approvedPricing == 0) {
        aParam = [];
        aParam.push(new SQLParamArray("fltApprovedPrice", "0", SQLParamArray.enSQLOperator.GreaterThan))
        aParamList.push(aParam);

        aParam = [];
        aParam.push(new SQLParamArray("fltApprovedPrice", "", SQLParamArray.enSQLOperator.IsNotNull))
        aParamList.push(aParam);
      }

      if (approvedPricing == 1) { // Active, add null as well
        aParam = [];
        aParam.push(new SQLParamArray("fltApprovedPrice", "", SQLParamArray.enSQLOperator.IsNull))
        aParam.push(new SQLParamArray("fltApprovedPrice", "0"))
        aParamList.push(aParam);
      }
    }

    //console.log("slobstock: ", slobStock);

    if (slobStock != null && slobStock > -1) {

      if (slobStock == 0) {
        aParam = [];
        aParam.push(new SQLParamArray("blnSLOBStock", "0"))
        aParamList.push(aParam);
      }

      if (slobStock == 1) { 
        aParam = [];
        aParam.push(new SQLParamArray("blnSLOBStock", "1"))
        aParamList.push(aParam);
      }
    }

    if (clearanceStock != null && clearanceStock > -1) {

      if (clearanceStock == 0) {
        aParam = [];
        aParam.push(new SQLParamArray("blnClearance", "0"))
        aParamList.push(aParam);
      }

      if (clearanceStock == 1) {
        aParam = [];
        aParam.push(new SQLParamArray("blnClearance", "1"))
        aParamList.push(aParam);
      }
    }

    if (onOrder != null && onOrder > -1) {

      if (onOrder == 0) {
        aParam = [];
        aParam.push(new SQLParamArray("guOrderItemId", "", SQLParamArray.enSQLOperator.IsNull))
        aParamList.push(aParam);
      }

      if (onOrder == 1) { // Linked to Order
        aParam = [];
        aParam.push(new SQLParamArray("guOrderItemId", "", SQLParamArray.enSQLOperator.IsNotNull))
        aParamList.push(aParam);
      }
    }


    if (retailValue != null && retailValue.toString() != "" && retailValue > -1) {
      aParam = [];
      aParam.push(new SQLParamArray("fltRetailPrice", retailValue.toString(), SQLParamArray.enSQLOperator.Equals))
      aParamList.push(aParam);
    }

    if (tradeValue != null && tradeValue.toString() != "" && tradeValue > -1) {
      aParam = [];
      aParam.push(new SQLParamArray("fltTradePrice", tradeValue.toString(), SQLParamArray.enSQLOperator.Equals))
      aParamList.push(aParam);
    }

    if (aParamList.length == 0) {
      this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "", 4000);
    }


    //if (deliveryStatus == "" || deliveryStatus == null || parseInt(deliveryStatus) == -1) {
    //  this.boardTallyRecordService.getNonDeliveredStockItemParamArray(aParamList).subscribe(stockItems => {

    //    //this.stockItems.sort = this.sort;
    //    this.stockItems.data = stockItems;
    //    this.totalRows = stockItems.length;
    //    stockItems.forEach(boardTallyRecordHistory => {

    //      if (boardTallyRecordHistory.txtQuantityUoM.trim() == "LM") {
    //        this.totalLM += boardTallyRecordHistory.fltQuantity;
    //      }

    //      if (boardTallyRecordHistory.txtQuantityUoM.trim() == "M2") {
    //        this.totalM2 += boardTallyRecordHistory.fltQuantity;
    //      }

    //      if (boardTallyRecordHistory.txtQuantityUoM.trim() == "Each") {
    //        this.totalPieces += boardTallyRecordHistory.fltQuantity;
    //      }
    //    });

    //    this.tabStockItems.selectedIndex = this.eStockItemsTabs.StockItems;

    //    this.resultTime = ((Date.now() - this.searchTime) / 1000);

    //  }, err => {
    //    console.log(err);
    //  });
    //}

    //console.log("aParamList",aParamList);

    if (deliveryStatus == "" || deliveryStatus == null || parseInt(deliveryStatus) == -1) {
      this.dtoStockItemDisplayService.getAlldtoStockItemDisplayParamArray(aParamList).subscribe(stockItems => {

        //console.log(stockItems);

        //this.stockItems.sort = this.sort;
        this.stockItems.data = stockItems;

       this.totalRows = stockItems.length;
        stockItems.forEach(boardTallyRecordHistory => {

          if (boardTallyRecordHistory.txtQuantityUoM.trim() == "LM") {
            this.totalLM += boardTallyRecordHistory.fltQuantity;
          }

          if (boardTallyRecordHistory.txtQuantityUoM.trim() == "M2") {
            this.totalM2 += boardTallyRecordHistory.fltQuantity;
          }

          if (boardTallyRecordHistory.txtQuantityUoM.trim() == "Each") {
            this.totalPieces += boardTallyRecordHistory.fltQuantity;
          }

          this.totalM3 += boardTallyRecordHistory.fltM3;

        });

        this.totalLM = parseFloat(this.totalLM.toFixed(2));
        this.totalM2 = parseFloat(this.totalM2.toFixed(2));
        this.totalM3 = parseFloat(this.totalM3.toFixed(2));


        this.resultTime = ((Date.now() - this.searchTime) / 1000);

        this.showProductsSpinner = false;

      }, err => {
        console.log(err);
      });
    }



    //if (deliveryStatus != "" && deliveryStatus != null && parseInt(deliveryStatus) > -1) {
    //  this.dtoStockItemDisplayService.getAlldtoStockItemDisplayParamArray(aParamList).subscribe(stockItems => {

    //    //this.stockItems.sort = this.sort;
    //    this.stockItems.data = stockItems;

    //    this.totalRows = stockItems.length;
    //    stockItems.forEach(boardTallyRecordHistory => {

    //      if (boardTallyRecordHistory.txtQuantityUoM.trim() == "LM") {
    //        this.totalLM += boardTallyRecordHistory.fltQuantity;
    //      }

    //      if (boardTallyRecordHistory.txtQuantityUoM.trim() == "M2") {
    //        this.totalM2 += boardTallyRecordHistory.fltQuantity;
    //      }

    //      if (boardTallyRecordHistory.txtQuantityUoM.trim() == "Each") {
    //        this.totalPieces += boardTallyRecordHistory.fltQuantity;
    //      }
    //    });


    //    this.tabStockItems.selectedIndex = this.eStockItemsTabs.StockItems;

    //  }, err => {
    //    console.log(err);
    //  });
    //}




  }


  async addStockItem(boardTallyRecordHistory: dtoStockItemDisplay) {

    this.addToStockItem =  await this.boardTallyRecordService.getdtoStockItemPromise(boardTallyRecordHistory.guBoardTallyRecordId);


    this.updateStockItemSelected = true;
    this.tabStockItems.selectedIndex = this.eStockItemsTabs.CreateStockItem;


  }


  async editStockItem(boardTallyRecordHistory: dtoStockItemDisplay) {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guBoardTallyRecordId", boardTallyRecordHistory.guBoardTallyRecordId));
    aParamList.push(aParam);


    this.boardTallyRecordHistory = await this.boardTallyRecordService.getdtoStockItemPromise(boardTallyRecordHistory.guBoardTallyRecordId);
    //console.log("this.boardTallyRecordHistory",this.boardTallyRecordHistory);
   
    
    this.tabStockItems.selectedIndex = this.eStockItemsTabs.UpdateStockItem;

  }

  async stockCreated(boardTallyRecordHistory: dtoStockItem) {
    if (boardTallyRecordHistory == null) {
      return;
    }
    this.tabStockItems.selectedIndex = this.eStockItemsTabs.StockItems;
    
    this.form.controls['txtSearchIdentifier'].setValue(boardTallyRecordHistory.intIdentifier);

    this.searchStockItems();

  }


  stockUpdated(e) {
    this.tabStockItems.selectedIndex = this.eStockItemsTabs.StockItems;
    this.searchStockItems();
  }

  async pasteFromClipboard() {
    this.form.controls['txtSearchSKU'].setValue(await this.formService.pasteFromClipboard());
  }

  async clearProductCode() {
    this.form.controls['txtSearchSKU'].setValue("");
  }

}



