import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoAppPrinter } from '../business-objects/dto-app-printer.bo';

@Injectable()
export class dtoAppPrinterService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoAppPrinter(dtoAppPrinterId: string) {
		return this.http.get<dtoAppPrinter>(this._baseUrl + 'api/dtoAppPrinter/' + dtoAppPrinterId);
	}

	getdtoAppPrinterParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoAppPrinter[]>(this._baseUrl + 'api/dtoAppPrinter/GetdtoAppPrintersParamArray/', aParam);
	}

	getAlldtoAppPrinters() {
		return this.http.get<dtoAppPrinter[]>(this._baseUrl + 'api/dtoAppPrinter/GetAlldtoAppPrinters/');
	}

	//getAlldtodtoAppPrinters() {
	//	return this.http.get<dtodtoAppPrinter[]>(this._baseUrl + 'api/dtoAppPrinter/GetAlldtoAppPrinters/');
	//}

	createdtoAppPrinter(dtoAppPrinter: dtoAppPrinter) {
		return this.http.post<dtoAppPrinter>(this._baseUrl + 'api/dtoAppPrinter/CreatedtoAppPrinter/', dtoAppPrinter);
	}

	updatedtoAppPrinter(dtoAppPrinter: dtoAppPrinter) {
		return this.http.put<dtoAppPrinter>(this._baseUrl + 'api/dtoAppPrinter/UpdatedtoAppPrinter/', dtoAppPrinter);
	}

	async getdtoAppPrinterPromise(dtoAppPrinterId: string) {
		return new Promise<dtoAppPrinter>(resolve => {
			this.getdtoAppPrinter(dtoAppPrinterId).subscribe(dtoAppPrinter => {
				resolve(dtoAppPrinter);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppPrinter records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoAppPrinterParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoAppPrinter[]>(resolve => {
			this.getdtoAppPrinterParamArray(aParam).subscribe(dtoAppPrinters => {
				resolve(dtoAppPrinters);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppPrinter records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoAppPrinterByAppNamePromise(appName: string) {
    return new Promise<dtoAppPrinter[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("txtName", appName));
      aParamList.push(aParam);

      this.getdtoAppPrinterParamArray(aParamList).subscribe(dtoAppPrinters => {
        resolve(dtoAppPrinters);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoAppPrinter records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async updatedtoAppPrinterPromise(dtoAppPrinter: dtoAppPrinter) {
		return new Promise<dtoAppPrinter>(resolve => {
			this.updatedtoAppPrinter(dtoAppPrinter).subscribe(dtoAppPrinter => {
				resolve(dtoAppPrinter);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoAppPrinter record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoAppPrinterPromise(dtoAppPrinter: dtoAppPrinter) {
		return new Promise<dtoAppPrinter>(resolve => {
			this.createdtoAppPrinter(dtoAppPrinter).subscribe(dtoAppPrinter => {
				resolve(dtoAppPrinter);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoAppPrinter record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}
