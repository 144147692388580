import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DeliveryDocketStockTransfer } from '../business-objects/delivery-docket-stock-transfer.bo';
import { dtoDeliveryDocketStockTransfer } from '../business-objects/dto-delivery-docket-stock-transfer.bo';
import { dtoStockItem } from '../business-objects/dto-stock-item.bo';
import { SQLParamArray } from '../business-objects/sql-param-array';

@Injectable()
export class DeliveryDocketStockTransferService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		this._baseUrl = baseUrl;
	}

	getDeliveryDocketStockTransfer(deliveryDocketStockTransferId: string) {
		return this.http.get<DeliveryDocketStockTransfer>(this._baseUrl + 'api/DeliveryDocketStockTransfer/' + deliveryDocketStockTransferId);
	}

	getAllDeliveryDocketStockTransfers() {
		return this.http.get<DeliveryDocketStockTransfer[]>(this._baseUrl + 'api/DeliveryDocketStockTransfer/GetAllDeliveryDocketStockTransfers/');
	}

  getdtoDeliveryDocketStockTransfersParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoDeliveryDocketStockTransfer[]>(this._baseUrl + 'api/DeliveryDocketStockTransfer/GetdtoDeliveryDocketStockTransfersParamArray/', aParam);
  }

	//getAlldtoDeliveryDocketStockTransfers() {
	//	return this.http.get<dtoDeliveryDocketStockTransfer[]>(this._baseUrl + 'api/DeliveryDocketStockTransfer/GetAllDeliveryDocketStockTransfers/');
	//}

	createDeliveryDocketStockTransfer(deliveryDocketStockTransfer: DeliveryDocketStockTransfer) {
		return this.http.post<DeliveryDocketStockTransfer>(this._baseUrl + 'api/DeliveryDocketStockTransfer/CreateDeliveryDocketStockTransfer/', deliveryDocketStockTransfer);
	}

	updateDeliveryDocketStockTransfer(deliveryDocketStockTransfer: DeliveryDocketStockTransfer) {
		return this.http.post<DeliveryDocketStockTransfer>(this._baseUrl + 'api/DeliveryDocketStockTransfer/UpdateDeliveryDocketStockTransfer/', deliveryDocketStockTransfer);
	}

}
