export interface AppUserGeoStockLocation
{
	blnActive: boolean;
	blnDefaultLocation: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guAppUserId: string;
	guGeoStockLocationId: string;
	rowguid: string;
}

export class AppUserGeoStockLocation
{
	blnActive: boolean;
	blnDefaultLocation: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guAppUserId: string;
	guGeoStockLocationId: string;
	rowguid: string;
}

