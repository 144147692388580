<div class="stock-item-container">
  <table mat-table #tblCurrentStockItems [dataSource]="data" class="mat-elevation-z8">

    <ng-container matColumnDef="txtProductName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Product </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemProduct"> {{boardTallyRecordHistory.txtProductName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtProfileName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Profile </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemProfile"> {{boardTallyRecordHistory.txtProfileAbbrievation}} </td>
    </ng-container>

    <ng-container matColumnDef="intWidth">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Width </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemProfile"> {{boardTallyRecordHistory.intWidth}} </td>
    </ng-container>

    <ng-container matColumnDef="intThickness">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemThicknessTH"> Thick </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemThickness"> {{boardTallyRecordHistory.intThickness}} </td>
    </ng-container>

    <ng-container matColumnDef="txtSpeciesName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemSpeciesTH"> Species </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemSpecies"> {{boardTallyRecordHistory.txtSpeciesName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtGradeName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemGradeTH"> Grade </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemGrade"> {{boardTallyRecordHistory.txtGradeName}} </td>
    </ng-container>

    <ng-container matColumnDef="fltQuantity">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemQuantityTH"> Quantity </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemQuantity"> {{boardTallyRecordHistory.fltQuantity}} </td>
    </ng-container>

    <ng-container matColumnDef="txtQuantityUoM">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemQuantityUoMTH"> Quantity UoM </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemQuantityUoM"> {{boardTallyRecordHistory.txtQuantityUoM}} </td>
    </ng-container>

    <ng-container matColumnDef="fltLength">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemLengthTH"> Length </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemLength"> {{boardTallyRecordHistory.intTotalLM}} </td>
    </ng-container>

    <ng-container matColumnDef="fltDia">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemDiaTH"> Dia </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemDia"> {{boardTallyRecordHistory.fltDia}} </td>
    </ng-container>

    <ng-container matColumnDef="txtLocation">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemLocationTH"> Location </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemLocation"> {{boardTallyRecordHistory.txtContainerLocation}} </td>
    </ng-container>

    <ng-container matColumnDef="intIdentifier">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemIdentifierTH"> Identifier </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemIdentifier"> {{boardTallyRecordHistory.intIdentifier}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemCreatedTH"> Created </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemCreated"> {{boardTallyRecordHistory.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtStockKeepingUnit">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemSKUTH"> SKU </th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemSKU"> {{boardTallyRecordHistory.txtStockKeepingUnit}} </td>
    </ng-container>
    <ng-container matColumnDef="chkReceiveStock">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH" aria-label="row actions"></th>
      <td mat-cell *matCellDef="let boardTallyRecordHistory; let rowIdx = dataIndex;" class="stockitemTD">
        <button class="search-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="transferToStockItem(boardTallyRecordHistory, rowIdx)">Transfer Into Stock</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedStockItems"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedStockItems;">
    </tr>
  </table>
  <div class="no-stock-items" *ngIf="data == null || data.length === 0"><h7>There are no stock items to transfer</h7></div>
</div>
