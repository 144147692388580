export interface dtoOrdersDeliveryRate
{
	dteLastUpdated: Date;
	fltAdditionalKM: number;
	fltEscort: number;
	fltPrice: number;
	guDeliveryDocketId: string;
	guDeliveryRateId: string;
	guOrderId: string;
	intDocketNo: number;
	rowguid: string;
	txtName: string;
	txtOrderNo: string;
	txtRateType: string;
}

export class dtoOrdersDeliveryRate
{
	dteLastUpdated: Date;
	fltAdditionalKM: number;
	fltEscort: number;
	fltPrice: number;
	guDeliveryDocketId: string;
	guDeliveryRateId: string;
	guOrderId: string;
	intDocketNo: number;
	rowguid: string;
	txtName: string;
	txtOrderNo: string;
	txtRateType: string;
}

