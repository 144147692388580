<p>Board Tally Records Not In Current Stock</p>
<div class="button-container">
  <button type="button" class="stocktakeButton" mat-flat-button color="primary" (click)="showPacksNotInStock()">Show Packs Not In Stock</button>
</div>
<div class="board-tally-record-no-in-current-stock-container" *ngIf="showTable">
  <table mat-table #tblBoardTallyRecordNotInStock [dataSource]="dtoBoardTallyRecordNotInStock" class="mat-elevation-z8">

    <ng-container matColumnDef="txtSection">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Section </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="identifierCell stockitemTD"> {{boardtallyRecord.txtSection}} </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Customer </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemProduct"> {{boardtallyRecord.txtCustomerName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtProductName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Customer </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemProduct"> {{boardtallyRecord.txtProductName}} </td>
    </ng-container>


    <ng-container matColumnDef="intIdentifier">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Identifier </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemProfile"> {{boardtallyRecord.intIdentifier}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Date Created </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemWidth"> {{boardtallyRecord.dteDateCreated}} </td>
    </ng-container>

    <ng-container matColumnDef="dteLastestDate">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Lastest Date </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemThickness"> {{boardtallyRecord.dteLastestDate}} </td>
    </ng-container>

    <ng-container matColumnDef="intDeliveryDocketNo">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Delivery Docket No </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemSpecies"> {{boardtallyRecord.intDeliveryDocketNo}} </td>
    </ng-container>

    <ng-container matColumnDef="txtDeliveryStatus">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Delivery Status </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemGrade"> {{boardtallyRecord.txtDeliveryStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="dteStatusDate">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Status Date </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemThickness"> {{boardtallyRecord.dteStatusDate}} </td>
    </ng-container>

    <ng-container matColumnDef="intWidth">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Width </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemThickness"> {{boardtallyRecord.intWidth}} </td>
    </ng-container>

    <ng-container matColumnDef="sizeSeparator">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"></th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemSizeSeparator">x</td>
    </ng-container>

    <ng-container matColumnDef="intThickness">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Thickness </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemThickness"> {{boardtallyRecord.intThickness}} </td>
    </ng-container>

    <ng-container matColumnDef="fltQuantity">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Qty </th>
      <td mat-cell *matCellDef="let boardtallyRecord; let rowIdx = index;" class="stockitemTD stockItemQuantity">{{ boardtallyRecord.fltQuantity | number : '1.2-2' }}</td>
    </ng-container>

    <ng-container matColumnDef="intQuantityUoM">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> UoM </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemQuantityUOM"> {{boardtallyRecord.intQuantityUoM}} </td>
    </ng-container>

    <ng-container matColumnDef="fltTotalLM">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Length </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemLength"> {{boardtallyRecord.fltTotalLM}} </td>
    </ng-container>


    <ng-container matColumnDef="txtSpeciesName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Species Name </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemLength"> {{boardtallyRecord.txtSpeciesName }} </td>
    </ng-container>

    <ng-container matColumnDef="txtGradeName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Grade Name </th>
      <td mat-cell *matCellDef="let boardtallyRecord" class="stockitemTD stockItemLength">{{boardtallyRecord.txtGradeName }}</td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsDelivered">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH"> </th>
      <td mat-cell *matCellDef="let boardtallyRecord; let rowIdx = index;" class="stockitemTD stockItemAddToOrder">
        <button type="button" class="stocktakeButton" mat-flat-button color="primary" id="btnSetAsDelivered{{rowIdx}}" (click)="setAsDelivered(boardtallyRecord, rowIdx)" *ngIf="boardtallyRecord.intDeliveryDocketNo != 0" [disabled]="(boardtallyRecord.intStatus == 0)">Set as Delivered</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div class="spinner-container">
  <mat-card [@displaySpinner]="transitionProducts ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showProductsSpinner">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
