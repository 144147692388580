<div class="app-page-column-create-container">
  <div class="app-page-column-create-form-container">
    <form class="app-page-column-create-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created</mat-label>
              <input id="dteDateCreated" class="date-input created-from-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteDateCreated">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>Copy From App Page</mat-label>
              <mat-select formControlName="guCopyFromAppPageId" #appPageCopy>
                <mat-option *ngFor="let appPageCopy of appPagesCopy" [value]="appPageCopy.rowguid">{{appPageCopy.txtPageName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button class="stocktakeButton" mat-flat-button color="primary" (click)="copyFromAppPage(appPageCopy,  appPage)">Copy</button>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>App Page</mat-label>
              <mat-select formControlName="guAppPageId" #appPage (selectionChange)="setNextColumnNumber(appPage.value)">
                <mat-option *ngFor="let appPage of appPages" [value]="appPage.rowguid">{{appPage.txtPageName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Column Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. txtName" value="" type="text" id="txtColumnName" formControlName="txtColumnName">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Column Name Alias</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. txtName" value="" type="text" id="txtColumnNameAlias" formControlName="txtColumnNameAlias">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Column Order</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 1" value="" type="number" id="intDefaultColumnOrder" formControlName="intDefaultColumnOrder">
              </mat-form-field>
            </div>
          </div>

          <button class="stocktakeButton" mat-flat-button color="primary" (click)="create('Create')">Create</button>
          <button class="stocktakeButton" mat-flat-button color="primary" (click)="create('CreateAndAdd')">Create and Add New</button>
          <button class="stocktakeButton" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
        </div>
        <div class="code-builder-container">
          <h5>Add By Code Builder Columns</h5>
          <div class="code-builder-columns">
            <div class="code-builder-table-container">
              <mat-form-field>
                <div class="table-filter">
                  <input matInput class="table-filter-input" placeholder="Filter Tables" placeholderLabel="i.e. Delivery Dockets ....." value="" type="text" #tableFilter (keyup)="filterTables(tableFilter.value)">
                  <br /><br />
                </div>
              </mat-form-field>
              <mat-label>Table</mat-label>
              <mat-selection-list class="table-select" formControlName="guTableId" #codeBuilderTable [multiple]="false" (selectionChange)="getTableColumns(codeBuilderTable._value[0])">
                <mat-list-option *ngFor="let codeBuilderTable of codeBuilderTables" [value]="codeBuilderTable.rowguid">{{codeBuilderTable.txtTableName}}</mat-list-option>
              </mat-selection-list>
              <br /><br />
            </div>
            <mat-form-field>
              <div class="table-filter">
                <input matInput class="table-filter-input" placeholder="Filter Tables" placeholderLabel="i.e. txtUserName ....." value="" type="text" #columnFilter (keyup)="filterColumns(columnFilter.value)">
                <br /><br />
              </div>
            </mat-form-field>
            <mat-selection-list class="columns-select" #codeBuilderColumn [multiple]="false" formControlName="guCodeBuilderColumnId" (selectionChange)="setColumnFromCodeBuilder(codeBuilderColumn._value[0])">
              <mat-list-option *ngFor="let CodeBuilderColumn of codeBuilderColumns" [value]="CodeBuilderColumn">
                {{CodeBuilderColumn.txtColumnName}}
              </mat-list-option>
            </mat-selection-list>
            <br />
          </div>

        </div>
      </div>

    </form>
  </div>
</div>
