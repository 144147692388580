import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CHHPoleStocktake } from '../_shared/business-objects/chhpole-stocktake.bo';
import { PoleInspectionItem } from '../_shared/business-objects/pole-inspection-item.bo';
import { CHHStock } from '../_shared/business-objects/chhstock.bo';

import { SharedService } from '../_shared/services/shared.service';
import { AlertService } from '../_shared/services/alert.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pole-stock-take',
  templateUrl: './pole-stock-take.component.html',
  styleUrls: ['./pole-stock-take.component.css']
})
export class PoleStockTakeComponent implements OnInit {

  form: FormGroup;
  public _baseUrl: string;


  constructor(private fb: FormBuilder, private http: HttpClient, private sharedService: SharedService,
    @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {

    this._baseUrl = baseUrl;

    this.form = this.fb.group({
      txtIdentifier: ['']
    });

  }

  ngOnInit(): void {


  }



  addToStocktake() {



  }

}
