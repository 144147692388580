import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../account/auth.service';
import { AppUserColumnSet } from '../../_shared/business-objects/app-user-column-set.bo';
import { AppUserColumn } from '../../_shared/business-objects/app-user-column.bo';
import { DeliveryDocketOrderItem } from '../../_shared/business-objects/delivery-docket-order-item.bo';
import { dtoAppUserColumnSet } from '../../_shared/business-objects/dto-app-user-column-set.bo';
import { dtoAppUserColumn } from '../../_shared/business-objects/dto-app-user-column.bo';
import { dtoDeliveryDocketOrderItem } from '../../_shared/business-objects/dto-delivery-docket-order-item.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AppUserColumnSetService } from '../../_shared/services/app-user-column-set.service';
import { AppUserColumnService } from '../../_shared/services/app-user-column.service';
import { dtoAppUserColumnSetService } from '../../_shared/services/dto-app-user-column-set.service';
import { dtoAppUserColumnService } from '../../_shared/services/dto-app-user-column.service';

import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatTab } from '@angular/material/tabs';
import { AlertService } from '../../_shared/services/alert.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { MatButton } from '@angular/material/button';
import { dtoDeliveryDocketOrderItemService } from '../../_shared/services/dto-delivery-docket-order-item.service';
import { DeliveryDocketOrderItemService } from '../../_shared/services/delivery-docket-order-item.service';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
  { position: 11, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 12, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 13, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];


@Component({
  selector: 'app-delivery-docket-order-item-list',
  templateUrl: './delivery-docket-order-item-list.component.html',
  styleUrls: ['./delivery-docket-order-item-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.3s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.3s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ]),
    trigger('displayTick', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.1s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.9s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ]),
    trigger('displayCancel', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.1s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.2s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class DeliveryDocketOrderItemListComponent implements OnInit, AfterViewInit {
  @ViewChild('tblDeliveryDocketOrderItems') tblDeliveryDocketOrderItems: MatTable<DeliveryDocketOrderItem>;
  @ViewChild('spinner') spinner: HTMLDivElement;

  @Input() datasource: MatTableDataSource<dtoDeliveryDocketOrderItem>;
  @Input() columnListId: string;
  @Input() columnBarContainer: string;
  @Input() columnBarPage: string
  @Input() appIndex: number;
  @Input() deliveryDocketOrderItemQtyTotalInput: number;
  @Input() deliveryDocketOrderItemM3TotalInput: number;
  @Input() deliveryDocketOrderItemActualM3TotalInput: number;
  @Input() deliveryDocketOrderItemActualTonneTotalInput: number;
  @Output() editDeliveryDocketOrderItemEvent = new EventEmitter<dtoDeliveryDocketOrderItem>();
  @Output() addDeliveryDocketOrderItemEvent = new EventEmitter<dtoDeliveryDocketOrderItem>();
  @Output() removeDeliveryDocketOrderItemEvent = new EventEmitter<dtoDeliveryDocketOrderItem>();

  @ViewChild(MatSort) sort: MatSort;

  selectedColumns = new FormControl('');

  deliveryDocketOrderItemQtyTotal: number = 0;
  deliveryDocketOrderItemM3Total: number = 0;
  deliveryDocketOrderItemActualM3Total: number = 0;
  deliveryDocketOrderItemActualTonneTotal: number = 0;
  deliveryDocketOrderItemFinalQtyTotal: number = 0;

  showSpinner: boolean = false;
  showTick: boolean = false;
  showCancel: boolean = false;

  //loadedComponent: boolean = false;

  displayedDeliveryDocketOrderItems: string[] = ['intItemNo', 'txtCustomerName', 'txtOrderNo', 'txtProductTypeName', 'txtProfileName', 'fltWidth', 'fltThickness', 'txtLengthKN', 'fltLength', 'fltDiameter', 'txtSpecies', 'txtGradeName', 'txtTreatmentType', 'txtTreatmentHazardLevel', 'txtStockCode', 'fltQuantity', 'fltQtyBal', 'intFinalQuantity', 'txtQuantityUOM', 'txtAddress', 'txtComments', 'fltActualM3', 'fltActualTonne', 'btnEditQuantity', 'btnEditDeliveryDocketOrderItem', 'btnRemoveDeliveryDocketOrderItem'];
  //displayedDeliveryDocketOrderItems: string[] = ['intItemNo', 'txtCustomerName', 'txtOrderNo', 'txtProductTypeName', 'txtProfileName', 'fltWidth', 'fltThickness', 'txtLengthKN', 'fltLength', 'fltDiameter', 'txtSpecies', 'txtGradeName', 'txtTreatmentType', 'txtTreatmentHazardLevel', 'txtStockCode', 'fltQtyBal', 'intFinalQuantity', 'fltQuantity'];  //


  windowMouseX: number;
  windowMouseY: number;
  listMouseX: number;
  listMouseY: number;

  constructor(private dtoAppUserColumnService: dtoAppUserColumnService, private appUserColumnService: AppUserColumnService, private authService: AuthService, private appUserColumnSetService: AppUserColumnSetService
    , private dtoAppUserColumnSetService: dtoAppUserColumnSetService, private ref: ChangeDetectorRef, private alertService: AlertService, private formService: FormService
    , private sharedService: SharedService, private dtoDeliveryDocketOrderItemService: dtoDeliveryDocketOrderItemService, private deliveryDocketOrderItemService: DeliveryDocketOrderItemService
    , private cdref: ChangeDetectorRef  ) {

    if (this.columnBarContainer == null || this.columnBarContainer == "") {
      this.columnBarContainer = "deliveryDocketContainer";
    }
  }

  ngOnInit(): void {

    this.deliveryDocketOrderItemQtyTotal = 0;
    this.deliveryDocketOrderItemM3Total = 0;
    this.deliveryDocketOrderItemActualM3Total = 0;
    this.deliveryDocketOrderItemActualTonneTotal = 0;

    if (this.columnListId == null) {
      this.columnListId = "columnListId";
    }

    //console.log("columnBarPage", this.columnBarPage);

    if (this.columnBarPage == null) {
      this.columnBarPage = "Delivery Docket Order Items";
    }

  }


  updatedComponentColumnsEvent(columns: string[]) {

    //console.log("columns", columns);

    this.displayedDeliveryDocketOrderItems = columns;

  }


  setTotals() {


    this.deliveryDocketOrderItemQtyTotal = 0;
    this.deliveryDocketOrderItemM3Total = 0;
    this.deliveryDocketOrderItemActualM3Total = 0;
    this.deliveryDocketOrderItemActualTonneTotal = 0;

    if (this.datasource.data != null) {
      this.datasource.data.forEach((a) => {
        this.deliveryDocketOrderItemQtyTotal += a.fltQuantity;
        console.log("deliveryDocketOrderItemQtyTotal: " + this.deliveryDocketOrderItemQtyTotal);
        this.deliveryDocketOrderItemM3Total += Number(Number(a.fltM3).toFixed(3));
        this.deliveryDocketOrderItemActualM3Total += Number(Number(a.fltActualM3).toFixed(3));
        this.deliveryDocketOrderItemActualTonneTotal += Number(Number(a.fltActualTonne).toFixed(3));
        this.deliveryDocketOrderItemFinalQtyTotal += a.intFinalQuantity;

      });
      
      this.deliveryDocketOrderItemM3Total = Number(this.deliveryDocketOrderItemM3Total.toFixed(3));
      this.deliveryDocketOrderItemActualM3Total = Number(this.deliveryDocketOrderItemActualM3Total.toFixed(3));
      this.deliveryDocketOrderItemActualTonneTotal = Number(this.deliveryDocketOrderItemActualTonneTotal.toFixed(3));
      console.log("deliveryDocketOrderItemActualTonneTotal: " + this.deliveryDocketOrderItemActualTonneTotal);



      this.deliveryDocketOrderItemM3TotalInput = this.deliveryDocketOrderItemM3Total;
      this.deliveryDocketOrderItemActualM3TotalInput = this.deliveryDocketOrderItemActualM3Total;
      this.deliveryDocketOrderItemActualTonneTotalInput = this.deliveryDocketOrderItemActualTonneTotal;
      this.deliveryDocketOrderItemQtyTotalInput = this.deliveryDocketOrderItemQtyTotal;

      console.log("deliveryDocketOrderItemM3TotalInput: " + this.deliveryDocketOrderItemM3TotalInput);
      console.log("deliveryDocketOrderItemActualM3TotalInput: " + this.deliveryDocketOrderItemActualM3TotalInput);
      console.log("deliveryDocketOrderItemActualTonneTotalInput: " + this.deliveryDocketOrderItemActualTonneTotalInput);
      console.log("deliveryDocketOrderItemQtyTotalInput: " + this.deliveryDocketOrderItemQtyTotalInput);

    }
  }


  ngAfterViewInit() {
    if (this.datasource != null) {
      this.datasource.sort = this.sort;
    }
  }

  async validateQuantity(quantity: number, deliveryDocketOrderItem: dtoDeliveryDocketOrderItem) {

    console.log("quantity: " + quantity);

    console.log("quantity fltQtyBal: " + deliveryDocketOrderItem.fltQtyBal);

    console.log("quantity calc fltQtyBal: " + (deliveryDocketOrderItem.fltQtyBal - quantity));

    console.log("quantity calc fltQuantity: " + (deliveryDocketOrderItem.fltQuantity + quantity));

    if (quantity > deliveryDocketOrderItem.fltQuantity && (quantity - deliveryDocketOrderItem.fltQuantity) > deliveryDocketOrderItem.fltQtyBal) {
      await this.alertService.openSnackBarCustom("Over Supply", "You cannot over supply an order.\nThe quantity balance is " + deliveryDocketOrderItem.fltQtyBal.toString() + " and you trying to delivery " + ((quantity - deliveryDocketOrderItem.fltQuantity) - deliveryDocketOrderItem.fltQtyBal).toString() + " more than the balance.", "Ok", "", "center", "bottom", "", 0, false);
      return false;
    }

    return true;
  }

  cancelQuantity(deliveryDocketOrderItem: dtoDeliveryDocketOrderItem, rowIndex: number) {
    let btnEditQty: HTMLButtonElement = document.getElementById('btnEditQuantity' + rowIndex.toString()) as HTMLButtonElement;
    let txtQuantity: HTMLInputElement = document.getElementById('txtQuantity' + rowIndex.toString()) as HTMLInputElement;
    let iconCancelContainer: HTMLSpanElement = document.getElementById('iconCancelContainer' + rowIndex.toString()) as HTMLSpanElement;

    console.log(btnEditQty);

    btnEditQty.textContent = "Edit Quantity";
    txtQuantity.value = deliveryDocketOrderItem.fltQuantity.toString();
    txtQuantity.disabled = true;

    this.showCancel = false;

    setTimeout(() => {
      iconCancelContainer.style.visibility = "hidden";
      iconCancelContainer.style.width = "0px;"

    }, 1200);
  }


  async editQuantity(deliveryDocketOrderItem: dtoDeliveryDocketOrderItem, quantityInput, btnEditQty: MatButton) {

    let txtQuantity: HTMLInputElement = document.getElementById('txtQuantity' + quantityInput.toString()) as HTMLInputElement;
    let buttonText: string = btnEditQty._elementRef.nativeElement.innerText
    let divSpinner: HTMLDivElement = document.getElementById('divSpinner') as HTMLDivElement;
    let spanSpinnerContainer: HTMLSpanElement = document.getElementById('spanSpinnerContainer' + quantityInput.toString()) as HTMLSpanElement;
    let iconTickContainer: HTMLSpanElement = document.getElementById('iconTickContainer' + quantityInput.toString()) as HTMLSpanElement;
    let iconCancelContainer: HTMLSpanElement = document.getElementById('iconCancelContainer' + quantityInput.toString()) as HTMLSpanElement;



    if (buttonText == "Edit Quantity") {
      txtQuantity.disabled = false;
      btnEditQty._elementRef.nativeElement.innerText = "Update Quantity";
      txtQuantity.focus();
      txtQuantity.select();
      iconCancelContainer.style.visibility = "visible";
      iconCancelContainer.style.width = "40px;"
      this.showCancel = true;
    }

    if (buttonText == "Update Quantity") {

      if (await this.validateQuantity(parseFloat(txtQuantity.value.toString()), deliveryDocketOrderItem) == false) {
        return;
      }

      // Update Quantity
      this.showSpinner = true;
      this.cdref.detectChanges();
      spanSpinnerContainer.style.visibility = "visible";
      spanSpinnerContainer.style.width = "40px;"

      iconCancelContainer.style.visibility = "hidden";
      iconCancelContainer.style.width = "0px;"

      let delDocketOrderItem: DeliveryDocketOrderItem = await this.deliveryDocketOrderItemService.getDeliveryDocketOrderItemPromise(deliveryDocketOrderItem.rowguid);
      deliveryDocketOrderItem.fltQuantity = parseFloat(txtQuantity.value.toString());  // Update client side version too and call setTotals();
      delDocketOrderItem.fltQuantity = parseFloat(txtQuantity.value.toString());

      let updatedDeliveryDocketOrderItem = await this.deliveryDocketOrderItemService.updateDeliveryDocektOrderItemPromise(delDocketOrderItem);
      let dtoDelDocketOrderItem: dtoDeliveryDocketOrderItem = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemPromise(deliveryDocketOrderItem.rowguid);
      deliveryDocketOrderItem.fltQtyBal = dtoDelDocketOrderItem.fltQtyBal;  // Update client side version of QtyBal;
      deliveryDocketOrderItem.fltM3 = dtoDelDocketOrderItem.fltM3;
      deliveryDocketOrderItem.fltActualM3 = dtoDelDocketOrderItem.fltActualM3;
      deliveryDocketOrderItem.fltActualTonne = dtoDelDocketOrderItem.fltActualTonne;


      this.setTotals();

      btnEditQty._elementRef.nativeElement.innerText = "Edit Quantity";
      txtQuantity.disabled = true;

      this.showSpinner = false;
      this.cdref.detectChanges();



      spanSpinnerContainer.style.visibility = "hidden";
      spanSpinnerContainer.style.width = "0px;"

      if (updatedDeliveryDocketOrderItem == null) {
        this.alertService.openSnackBarError("There was a problem updating the delivery docket order them. An email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "There was a problem updating the delivery docket order them.\n" + JSON.stringify(deliveryDocketOrderItem));
      }


      iconTickContainer.style.visibility = "visible";
      iconTickContainer.style.width = "40px;"
      this.showTick = true;

      this.cdref.detectChanges();

      setTimeout(() => {

        this.showTick = false;
        this.cdref.detectChanges();

      }, 1000);

      setTimeout(() => {
        this.showTick = false;
        iconTickContainer.style.visibility = "hidden";
        iconTickContainer.style.width = "0px;"

      }, 1400);

    }

  
  }

  editDeliveryDocketOrderItem(deliveryDocketOrderItem: dtoDeliveryDocketOrderItem) {

    this.editDeliveryDocketOrderItemEvent.emit(deliveryDocketOrderItem);

  }

  removeDeliveryDocketOrderItem(deliveryDocketOrderItem: dtoDeliveryDocketOrderItem) {
    //console.log("in del docket order item list removeDeliveryDocketOrderItem  : ", deliveryDocketOrderItem);
    this.removeDeliveryDocketOrderItemEvent.emit(deliveryDocketOrderItem);

  }

  RoundNumber(val, decimal) {
    return this.sharedService.RoundNumber(val, decimal);
  }

}

class totalsObj {
  deliveryDocketOrderItemQtyTotal: number;
  deliveryDocketOrderItemM3Total: number;
  deliveryDocketOrderItemActualM3Total: number;
  deliveryDocketOrderItemActualTonneTotal: number;
  deliveryDocketOrderItemFinalQtyTotal: number;

}
