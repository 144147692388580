import { MicrosoftGraphFileAttachment } from "./microsoft-graph-file-attachment"

export class MSGraphEmailMessage {

  constructor(from: string, to: string, cc: string, bcc: string, subject: string, body: string, attachments: MicrosoftGraphFileAttachment[]) {

    this.message = new Message();

    this.message.from = new From(from);

    let toArr: ToRecipient[] = [];
    let toSplit: string[] = to.split(";");
    for (let i = 0; i <= toSplit.length - 1; i++) {
      toArr.push(new ToRecipient(toSplit[i]));
    }

    let ccArr: ToRecipient[] = [];
    let ccSplit: string[] = cc.split(";");
    for (let i = 0; i <= ccSplit.length - 1; i++) {
      ccArr.push(new CcRecipient(ccSplit[i]));
    }

    let bccArr: BccRecipient[] = [];
    let bccSplit: string[] = bcc.split(";");
    for (let i = 0; i <= bccSplit.length - 1; i++) {
      bccArr.push(new BccRecipient(bccSplit[i]));
    }

    this.message.toRecipients = toArr;
    this.message.ccRecipients = ccArr;
    this.message.bccRecipients = bccArr;
    this.message.subject = subject;
    this.message.body = new Body(body);
    this.message.attachments = attachments;
  }

  message: Message
}

export class BccRecipient {
  constructor(email: string) {
    this.emailAddress = new EmailAddress(email);
  }
  emailAddress: EmailAddress
}

export class Body {
  constructor(body: string, contentType = "html") {
    this.contentType = contentType;
    this.content = body;
  }

  contentType: string
  content: string
}

export class CcRecipient {
  constructor(email: string) {
    this.emailAddress = new EmailAddress(email);
  }

  emailAddress: EmailAddress
}

export class From {
  constructor(email: string) {
    this.emailAddress = new EmailAddress(email);
  }

  emailAddress: EmailAddress
}

export class EmailAddress {
  constructor(email: string) {
    this.address = email;
  }
  address: string
}

export class Message {
  from: From;
  subject: string;
  body: Body;
  toRecipients: ToRecipient[];
  ccRecipients: CcRecipient[];
  bccRecipients: BccRecipient[];
  attachments: MicrosoftGraphFileAttachment[];
}

export class ToRecipient {
  constructor(email: string) {
    this.emailAddress = new EmailAddress(email);
  }
  emailAddress: EmailAddress;
}

