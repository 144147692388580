import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { dtoTPPoleInspection } from '../business-objects/dto-tppole-inspection.bo';
import { TPPoleInspection } from '../business-objects/tppole-inspection.bo';
import { dtoTPPoleInspectionItem } from '../business-objects/dto-tppole-inspection-item.bo';

@Injectable()
export class dtoTPPoleInspectionService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getdtoTPPoleInspection(dtoTPPoleInspectionId: string) {
    return this.http.get<dtoTPPoleInspection>(this._baseUrl + 'api/dtoTPPoleInspection/' + dtoTPPoleInspectionId);
  }

  getdtoTPPoleInspectionParamArray(aParam: SQLParamArray[][], eOnDeliveryDocket: TPPoleInspection.enOnDeliveryDocket) {
    return this.http.post<dtoTPPoleInspection[]>(this._baseUrl + 'api/dtoTPPoleInspection/GetdtoTPPoleInspectionParamArray/' + eOnDeliveryDocket, aParam);
  }

  getAlldtoTPPoleInspections() {
    return this.http.get<dtoTPPoleInspection[]>(this._baseUrl + 'api/dtoTPPoleInspection/GetAlldtoTPPoleInspections/');
  }

  getLastTPPoleInspections(lastNoOfInspections: number, eOnDeliveryDocket: TPPoleInspection.enOnDeliveryDocket, aParam: SQLParamArray[][]) {
    return this.http.post<dtoTPPoleInspection[]>(this._baseUrl + 'api/dtoTPPoleInspection/GetLastdtoTPPoleInspections/' + lastNoOfInspections + '/' + eOnDeliveryDocket, aParam);
  }

  //getAlldtodtoTPPoleInspections() {
  //	return this.http.get<dtodtoTPPoleInspection[]>(this._baseUrl + 'api/dtoTPPoleInspection/GetAlldtoTPPoleInspections/');
  //}

  createdtoTPPoleInspection(dtoTPPoleInspection: dtoTPPoleInspection) {
    return this.http.post<dtoTPPoleInspection>(this._baseUrl + 'api/dtoTPPoleInspection/CreatedtoTPPoleInspection/', dtoTPPoleInspection);
  }

  updatedtoTPPoleInspection(dtoTPPoleInspection: dtoTPPoleInspection) {
    return this.http.post<dtoTPPoleInspection>(this._baseUrl + 'api/dtoTPPoleInspection/UpdatedtoTPPoleInspection/', dtoTPPoleInspection);
  }

  removeTPPoleInspection(tpPoleInspectionId: string) {
    return this.http.delete(this._baseUrl + 'api/TPPoleInspection/' + tpPoleInspectionId);
  }

  createDiscCSV(finalInspectionItems: dtoTPPoleInspectionItem[]) {
    return this.http.post(this._baseUrl + 'api/dtoTPPoleInspection/CreateTPPoleInspectionCSV/', finalInspectionItems);
  }

  removeTPPoleInspectionPromise(tpPoleInspectionId: string) {
    return new Promise<boolean>(resolve => {
      this.removeTPPoleInspection(tpPoleInspectionId).subscribe(data => {
        resolve(true);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async getdtoTPPoleInspectionParamArrayPromise(aParam: SQLParamArray[][], eOnDeliveryDocket: TPPoleInspection.enOnDeliveryDocket) {
    return new Promise<dtoTPPoleInspection[]>(resolve => {
      this.getdtoTPPoleInspectionParamArray(aParam, eOnDeliveryDocket).subscribe(dtoTPPoleInspections => {
        resolve(dtoTPPoleInspections);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async getdtoTPPoleInspectionPromise(poleInspectionId: string) {
    return new Promise<dtoTPPoleInspection>(resolve => {
      this.getdtoTPPoleInspection(poleInspectionId).subscribe(dtoTPPoleInspection => {
        resolve(dtoTPPoleInspection);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async getdtoTPPoleInspectionByFinalNoPromise(finalNo: string, eOnDeliveryDocket: TPPoleInspection.enOnDeliveryDocket) {
    return new Promise<dtoTPPoleInspection>(async resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("intFinalNo", finalNo.toString()));
      aParamList.push(aParam);

      let finalToo: dtoTPPoleInspection[] = await this.getdtoTPPoleInspectionParamArrayPromise(aParamList, eOnDeliveryDocket);

      if (finalToo == null || finalToo.length == 0) {
        resolve(null);
      }

      resolve(finalToo[0]);  //Should only be one final

    });
  }

  async getParkedFinalsPromise(eOnDeliveryDocket: TPPoleInspection.enOnDeliveryDocket) {
    return new Promise<dtoTPPoleInspection[]>(async resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("blnParkedFinal", "1"));
      aParamList.push(aParam);

      let finalToo: dtoTPPoleInspection[] = await this.getdtoTPPoleInspectionParamArrayPromise(aParamList, eOnDeliveryDocket);

      if (finalToo == null) {
        resolve(null);
      }

      resolve(finalToo);  //Should only be one final

    });
  }

  async getInProgressFinalsPromise(eOnDeliveryDocket: TPPoleInspection.enOnDeliveryDocket) {
    return new Promise<dtoTPPoleInspection[]>(async resolve => {

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("intStatus", TPPoleInspection.enStatus.InProgress.toString()));
      aParamList.push(aParam);

      let finalToo: dtoTPPoleInspection[] = await this.getdtoTPPoleInspectionParamArrayPromise(aParamList, eOnDeliveryDocket);

      if (finalToo == null) {
        resolve(null);
      }

      resolve(finalToo);  //Should only be one final

    });
  }

  async getLastdtoTPPoleInspections(lastNoOfInspections: number, eOnDeliveryDocket: TPPoleInspection.enOnDeliveryDocket, aParam: SQLParamArray[][]) {
    return new Promise<dtoTPPoleInspection[]>(resolve => {
      this.getLastTPPoleInspections(lastNoOfInspections, eOnDeliveryDocket, aParam).subscribe(dtoTPPoleInspections => {
        resolve(dtoTPPoleInspections);
      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async createDiscCSVPromise(finalInspections: dtoTPPoleInspectionItem[]) {
    return new Promise<boolean>(resolve => {
      this.createDiscCSV(finalInspections).subscribe(res => {
        resolve(true);
      }, err => {
        console.log(err);
        resolve(false);
      });
    });
  }

}
