import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';
import { dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { AddressService } from '../../_shared/services/address';
import { AlertService } from '../../_shared/services/alert.service';
import { CustomerContactService } from '../../_shared/services/customer-contact.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-customer-contact-update',
  templateUrl: './customer-contact-update.component.html',
  styleUrl: './customer-contact-update.component.css'
})
export class CustomerContactUpdateComponent {
  form: FormGroup;

  @Input() selectedCustomerContact: CustomerContact
  @Input() customerId: string;
  @Input() customerName: string;
  @Output() customerContactUpdated = new EventEmitter<CustomerContact>();
  @Output() customerContactCancelled = new EventEmitter<boolean>();

  states: dtoKeyValue[] = [];
  postcodes: Object[];

  showSpinner: boolean

  constructor(private formBuilder: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private customerContactService: CustomerContactService, private formService: FormService, private addressService: AddressService) {

  }

  ngOnInit(): void {

    if (this.selectedCustomerContact == null) {
      this.showNoCustomerContact();
    }

    this.states = this.sharedService.getEnumObject2(SharedService.enStates);

    this.form = this.formBuilder.group({
      txtCustomerName: [this.customerName],
      txtFirstName: [this.selectedCustomerContact.txtName, Validators.required],
      txtLastName: [this.selectedCustomerContact.txtLastName],
      txtAddress1: [this.selectedCustomerContact.txtAddress1],
      txtAddress2: [this.selectedCustomerContact.txtAddress2],
      txtTown: [this.selectedCustomerContact.txtTown],
      txtState: [[this.selectedCustomerContact.txtState]],
      txtPostcode: [this.selectedCustomerContact.txtPostCode],
      txtPhone1: [this.selectedCustomerContact.txtPhone1],
      txtPhone2: [this.selectedCustomerContact.txtPhone2],
      txtMobile: [this.selectedCustomerContact.txtMobile],
      txtFax: [this.selectedCustomerContact.txtFax],
      txtEmail: [this.selectedCustomerContact.txtEmail],
      blnActive: [this.selectedCustomerContact.blnActive]
    });


    this.showSpinner = false;

    this.formService.setAutoFocusTimeout("txtFirstName", 300);

  }

  async showNoCustomerContact() {
    await this.alertService.openSnackBarCustomPromise("No Customer Contact", "There is no Customer Contact to update", "Ok", "", "center", "bottom", "", 0, false);
    this.customerContactCancelled.emit(true);
    return;
  }


  async lookupPostcode(town: string, state: string) {

    let postCodes: Object[];
    let postCodePromise = this.addressService.getPostCodePromise(town, state);

    await postCodePromise.then(res => postCodes = res);

    if (postCodes != null && Array.isArray(postCodes) && postCodes.length > 0) {

      if (postCodes.length > 1) {
        this.postcodes = postCodes;
        return;
      }

      //console.log("postcode: ", postCodes);

      if (this.form.controls['txtPostcode'].value != null && this.form.controls['txtPostcode'].value != "") {
        let msg: string = await this.alertService.openSnackBarCustomPromise("Existing Postcode", "There is an existing Postcode in the postcode field.\nDo you want to overwrite it?", "Yes", "No", "center", "bottom", "", 0, true);

        if (msg != "Yes") {
          return;
        }
      }

      this.form.controls['txtPostcode'].setValue(postCodes[0]['postcode']);

    }

  }

  selectPostcode(postcode: string) {

    if (postcode == "") {
      return;
    }

    this.form.controls['txtPostcode'].setValue(postcode);

    this.postcodes = null;

  }


  async updateCustomerContact() {

    let firstName: string = this.form.controls['txtFirstName'].value;
    let lastName: string = this.form.controls['txtLastName'].value;
    let address1: string = this.form.controls['txtAddress1'].value;
    let address2: string = this.form.controls['txtAddress2'].value;
    let town: string = this.form.controls['txtTown'].value;
    let state: string = this.form.controls['txtState'].value[0];
    let postcode: string = this.form.controls['txtPostcode'].value;
    let phone1: string = this.form.controls['txtPhone1'].value;
    let phone2: string = this.form.controls['txtPhone2'].value;
    let mobile: string = this.form.controls['txtMobile'].value;
    let fax: string = this.form.controls['txtFax'].value;
    let email: string = this.form.controls['txtEmail'].value;
    let active: boolean = (this.form.controls['blnActive'].value == 1);


    if (firstName == null || firstName == "") {
      this.alertService.openSnackBar("Please enter a contact first name.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (lastName == null || lastName == "") {
      await this.alertService.openSnackBarCustomPromise("Last Name", "Please contacts last name is empty.\nPlease remember to the get contacts last name when possible.", "Ok", "", "center", "bottom", "", 0, false);
    }

    if ((phone1 == null && mobile == null && email == null) || (phone1 == "" && mobile == "" && email == "")) {
      this.alertService.openSnackBarCustomPromise("Contact Info", "Please enter either a phone number, mobile number or email so the contact can be contacted.", "Ok", "", "center", "bottom", "", 0, false);
      return;
    }


    //let customerContact: CustomerContact = new CustomerContact;
    this.selectedCustomerContact.guCustomerId = this.customerId;
    this.selectedCustomerContact.txtName = firstName;
    this.selectedCustomerContact.txtLastName = lastName;
    this.selectedCustomerContact.txtAddress1 = address1;
    this.selectedCustomerContact.txtAddress2 = address2;
    this.selectedCustomerContact.txtTown = town;
    this.selectedCustomerContact.txtState = state;
    this.selectedCustomerContact.txtPostCode = postcode;
    this.selectedCustomerContact.txtPhone1 = phone1;
    this.selectedCustomerContact.txtPhone2 = phone2;
    this.selectedCustomerContact.txtMobile = mobile;
    this.selectedCustomerContact.txtFax = fax;
    this.selectedCustomerContact.txtEmail = email;
    this.selectedCustomerContact.blnActive = active;


    this.showSpinner = true;

    console.log(this.selectedCustomerContact);

    this.selectedCustomerContact = await this.customerContactService.updateCustomerContactPromise(this.selectedCustomerContact);

    if (this.selectedCustomerContact != null) {
      //console.log(this.selectedCustomerContact.rowguid);
      this.selectedCustomerContact = await this.customerContactService.getCustomerContactPromise(this.selectedCustomerContact.rowguid);


      this.alertService.openSnackBar("The customer contact has been updated", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;
      this.customerContactUpdated.emit(this.selectedCustomerContact);
    }


    if (this.selectedCustomerContact == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem updating the customer contact", "Close", "center", "bottom", 4000, true, "There was a problem updating the customer contact");
    }

  }

  cancelCustomerContact() {
    this.customerContactCancelled.emit(true);

  }

}
