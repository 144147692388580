export interface StockContainer
{
	blnArchived: boolean;
	blnConsignment: boolean;
	blnConsignmentDelivered: boolean;
	blnDamaged: boolean;
	blnDelivered: boolean;
	blnFloorCreated: boolean;
	blnFollowUp: boolean;
	blnLawsonCres: boolean;
	blnMelbourne: boolean;
	blnMelbourneStockDelivered: boolean;
	blnOnHold: boolean;
	blnParentPack: boolean;
	blnQuarantined: boolean;
	blnSLOBStock: boolean;
	blnStockTake: boolean;
	blnUncommitted: boolean;
	blnWorkInProcess: boolean;
	dteArchivedDate: Date;
	dteCreated: Date;
	dteDeliveredDate: Date;
	dteDispatched: Date;
	dteFollowUp: Date;
	dteLastModified: Date;
	dteLastUpdated: Date;
	dteOnHold: Date;
	dteOriginalDateCreated: Date;
	dteQuarantinedDate: Date;
	dteReadyForDispatch: Date;
	dteReturned: Date;
	dteStockTake: Date;
	guEmployeeOnHoldId: string;
	guGeoStockLocationId: string;
	guParentId: string;
	guStockContainerTypeId: string;
	intIdentifier: number;
	intStatus: number;
	rowguid: string;
	txtArchiveComments: string;
	txtComments: string;
	txtHoldComments: string;
	txtLocation: string;
	txtQuarantinedComments: string;
	txtStickerComments: string;
}

export class StockContainer
{
	blnArchived: boolean;
	blnConsignment: boolean;
	blnConsignmentDelivered: boolean;
	blnDamaged: boolean;
	blnDelivered: boolean;
	blnFloorCreated: boolean;
	blnFollowUp: boolean;
	blnLawsonCres: boolean;
	blnMelbourne: boolean;
	blnMelbourneStockDelivered: boolean;
	blnOnHold: boolean;
	blnParentPack: boolean;
	blnQuarantined: boolean;
	blnSLOBStock: boolean;
	blnStockTake: boolean;
	blnUncommitted: boolean;
	blnWorkInProcess: boolean;
	dteArchivedDate: Date;
	dteCreated: Date;
	dteDeliveredDate: Date;
	dteDispatched: Date;
	dteFollowUp: Date;
	dteLastModified: Date;
	dteLastUpdated: Date;
	dteOnHold: Date;
	dteOriginalDateCreated: Date;
	dteQuarantinedDate: Date;
	dteReadyForDispatch: Date;
	dteReturned: Date;
	dteStockTake: Date;
	guEmployeeOnHoldId: string;
	guGeoStockLocationId: string;
	guParentId: string;
	guStockContainerTypeId: string;
	intIdentifier: number;
	intStatus: number;
	rowguid: string;
	txtArchiveComments: string;
	txtComments: string;
	txtHoldComments: string;
	txtLocation: string;
	txtQuarantinedComments: string;
	txtStickerComments: string;
}

export namespace StockContainer
{
	export enum enStatus
	{
		Active,
		Processed,
		OnHold
	}
}

