import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../account/auth.service';
import { Router } from '@angular/router';
import { AlertsService } from '../../alerts.service';
import { GraphService } from '../../_shared/services/ms-graph-services/graph.service';
import { ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { EditorChangeContent, EditorChangeSelection, QuillEditorComponent } from 'ngx-quill';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';
import { MicrosoftGraphFileAttachment } from '../../_shared/app-objects/microsoft-graph-file-attachment';
import { AppUser } from '../../_shared/business-objects/app-user.bo';
import { User } from '../../account/user';
import { MatSelect } from '@angular/material/select';
//import { FileAttachment } from '@microsoft/microsoft-graph-types';
//import { QuillModule } from 'ngx-quill'

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SendEmailComponent implements OnInit {

  @ViewChild('selFromAddress') selFromAddress: MatSelect;
  @ViewChild('toAddress') toAddress: ElementRef;
  @ViewChild('ccAddress') ccAddress: ElementRef;
  @ViewChild('bccAddress') bccAddress: ElementRef;
  @ViewChild('txtEmailSubject') txtEmailSubject: ElementRef;
  @ViewChild('emailContent') emailContent: QuillEditorComponent;
  @ViewChild('myInput') myInput: ElementRef;
  @ViewChild('testImage') testImage: HTMLImageElement;

  @Input() toAddresses: string;
  @Input() ccAddresses: string;
  @Input() bccAddresses: string;
  @Input() emailSubject: string;
  @Input() emailMessage: string;
  @Input() fileAttachmentsInput: FileAttachment[];
  @Output() sendMailCancellEvent = new EventEmitter<boolean>();
  @Output() emailSentEvent = new EventEmitter<boolean>();

  quillContent: string;
  fileAttachments: FileAttachment[] = [];
  reader: FileReader;
  readerData: any;
  onDropZone: boolean = false;
  attachmentHoverName: string = "";
  emailToAddresses: string = "";
  emailCCAddresses: string = "";
  emailBCCAddresses: string = "";
  fromAddresses: string[] = [];
  toAddressMinRows: number = 1;

  constructor(
    private authService: AuthService,
    private graphService: GraphService,
    private alertsService: AlertsService,
    private router: Router
  ) {


  }

  ngOnInit(): void {

    setTimeout(() => { this.loadData() }, 500);

  }


  async loadData() {

    this.fromAddresses = String(this.authService.getAppUser().txtFromAddresses).split(",");

    this.fromAddresses.splice(0, 0, this.authService.user.txtUserName);

    this.selFromAddress.value = this.fromAddresses[0];

    if (this.toAddresses != null && String(this.toAddresses) != "") {
      this.toAddress.nativeElement.value = this.toAddresses;
      let toAddressTextArea: HTMLTextAreaElement = document.getElementById("txtToAddresses") as HTMLTextAreaElement;

      let minRowsTo = this.toAddresses.split(";").length - 1;
      if (minRowsTo > 0) {
        toAddressTextArea.rows = minRowsTo + 1;
      }

    }

    if (this.ccAddresses != null && String(this.ccAddresses) != "") {
      this.ccAddress.nativeElement.value = this.ccAddresses;
      let ccAddressTextArea: HTMLTextAreaElement = document.getElementById("txtCCAddresses") as HTMLTextAreaElement;

      let minRowsCC = this.ccAddresses.split(";").length - 1;
      if (minRowsCC > 0) {
        ccAddressTextArea.rows = minRowsCC + 1;
      }

    }

    if (this.bccAddresses != null && String(this.bccAddresses) != "") {
      this.bccAddress.nativeElement.value = this.bccAddresses;
      let bccAddressTextArea: HTMLTextAreaElement = document.getElementById("txtBCCAddresses") as HTMLTextAreaElement;

      let minRowsBCC = this.bccAddresses.split(";").length - 1;
      if (minRowsBCC > 0) {
        bccAddressTextArea.rows = minRowsBCC + 1;
      }
    }

    if (this.emailSubject != null && String(this.emailSubject) != "") {
      this.txtEmailSubject.nativeElement.value = this.emailSubject;
    }

    if (this.emailMessage != null && String(this.emailMessage) != "") {
      let quillEditor = document.getElementsByClassName("ql-editor");
      if (quillEditor != null && quillEditor[0] != null) {
        quillEditor[0].innerHTML = this.emailMessage;
      }
    }

    if (this.fileAttachmentsInput != null) {
      this.fileAttachments = this.fileAttachmentsInput;
    }
  }

  //async uploadFile(e) {
  //  var $data;

  //  const reader = new FileReader();

  //  let file = e.target.files[0];
  //  this.reader = new FileReader();
  //  //this.reader.addEventListener("load", this.handleEvent);

  //  //reader.onloadend = function () {
  //  //  const base64 = reader.result;
  //  //  this.fileByteArray = base64;
  //  //};

  //  //this.reader.onload = (function (file) { // here we save variable 'file' in closure
  //  //  return function (e) { // return handler function for 'onload' event
  //  //    data = this.result; // do some thing with data
        
  //  //  }
  //  //})(file);


  //  //reader.readAsDataURL(file);

  //  if (file) {
  //    this.addListeners(this.reader);
  //    this.reader.readAsDataURL(file);
  //  }

  //  //await this.renderReadAsDataURL(this.reader, file);


  //  //this.reader.removeEventListener("load", this.handleEvent);

  //  //this.uploadFile1();
  //}

  async uploadFile(e) {
    let fileByteArray: String | ArrayBuffer;
    let file = e.target.files[0];
    fileByteArray = await this.readImage(file);

    if (fileByteArray != null) {
      this.fileAttachments.push(new FileAttachment(file.name.toString(), "", file.type, fileByteArray, null, file.size, new Date(Date.now())));
    }

    //let testImage: HTMLImageElement = document.getElementById("testImage") as HTMLImageElement;
    //testImage.src = this.fileByteArray[0].toString();
    //testImage.height = 100;
    //console.log("image", this.fileByteArray);
  }

   logFile = async (e) => {
     let image: string | ArrayBuffer = await this.readImage(e.target.files[0])
     let testImage: HTMLImageElement = document.getElementById("testImage") as HTMLImageElement;
     testImage.src = image.toString();
     testImage.width = 300;
     testImage.height = 300;
     console.log("image", image);
  }

  readImage = (
    file
  ) => {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.readerData = e.target?.result;
        resolve(e.target?.result);
      };

      reader.onerror = (e) => {
        reject(`couldn't read image`);
      };

      reader.readAsDataURL(file);
    });
  };


  async dropHandler(ev) {

    console.log("this.onDropZone: " + this.onDropZone);

    if (this.onDropZone == false) {
      ev.preventDefault();
      ev.stopPropagation();
      console.log("outside of drop zone");
      return
    }

    console.log("File(s) dropped");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    let fileByteArray: String | ArrayBuffer;

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach(async (item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file = item.getAsFile();
          fileByteArray = await this.readImage(file)
          //console.log("file.name.toString(): ", file.name.toString());
          //console.log("file.type.toString(): ", file.type.toString());
        
          this.fileAttachments.push(new FileAttachment(file.name.toString(), "", file.type, fileByteArray, null, file.size, new Date(Date.now())));

          //console.log("size: ", file.size);

          //console.log("this.fileByteArray", fileByteArray);
       //   var img = document.createElement("img");
       //   img.src = fileByteArray.toString();
       //   img.height = 100;
       //   img.src = fileByteArray.toString();

       //   if (file.type == "application/pdf") {
       //     img.src = "./assets/img/pdf_icon.png";
       //   }


       //   let imageList = document.getElementById("imagelist");
       //   imageList.appendChild(img);

       //   console.log(`… file[${i}].name = ${file.name}`);
       //   console.log(`… file[${i}].type = ${file.type}`);
       }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach(async (file, i) => {

        fileByteArray = await this.readImage(file)

        this.fileAttachments.push(new FileAttachment(file.name.toString(), "", file.type, fileByteArray, null, file.size, new Date(Date.now())));

      //  console.log("this.fileByteArray", this.fileByteArray);
      //  let img = document.createElement('img');
      //  img.src = this.fileByteArray.toString();
      //  img.height = 200;

      //  let imageList = document.getElementById("imagelist");
      //  imageList.appendChild(img);
      //  console.log(`… file[${i}].name = ${file.name}`);
      });
    }
  }

  dragOverHandler(ev) {

    this.onDropZone = true;

    console.log("File(s) in drop zone");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  dragOutHandler(ev) {

    this.onDropZone = false;

    console.log("File(s) NOT in drop zone");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    ev.stopPropagation();
  }

  async readFile(evt) {
    const [file] = evt.target.files
    if (!file) return
    const data = await file.text()
    //return this.processFileContent(data)
  }

  addListeners(reader) {
    reader.addEventListener("loadstart", this.handleEvent);
    reader.addEventListener("load", this.handleEvent);
    reader.addEventListener("loadend", this.handleEvent);
    reader.addEventListener("progress", this.handleEvent);
    reader.addEventListener("error", this.handleEvent);
    reader.addEventListener("abort", this.handleEvent);
  }

  renderReadAsDataURL(reader: FileReader, file) {
    return new Promise<any>(resolve => {
      reader.readAsDataURL(file);
      console.log("resolve renderReadAsDataURL: ");
      resolve(true);
    });
  }

  handleEvent(event) {

    if (event.type === "load") {

      console.log("in handle event");
      console.log("reader", this.reader);

      //setTimeout(() => {
      //  this.fileByteArray = this.reader.result;

      //  console.log("fileByteArray", this.fileByteArray);
      //}, 1000);
    }
  }

  renderOnload(reader: FileReader, data, file) {
    console.log("data - before: ", data);

    return new Promise<any>(resolve => {
      reader.onload = (function (file) { // here we save variable 'file' in closure
        return function (e) { // return handler function for 'onload' event
          data = this.result; // do some thing with data
          console.log("resolve data: ", data);
          resolve(data)
        }
      })(file);

    });
  }

  uploadFile1() {
    var files = this.myInput.nativeElement.files[0];
    var reader = new FileReader();
    reader.onload = this.processFile2();
    reader.readAsText(files);
  }

  uploadFile2() {
    var files = document.querySelector('input').files[0];
    var reader = new FileReader();
    reader.onload = this.processFile(files);
    reader.readAsDataURL(files);
  }

  uploadFile3() {
    var files = this.myInput.nativeElement.files[0];
    var reader = new FileReader();
    reader.onload = this.processFile(files);
    reader.readAsArrayBuffer(files);
  }

  processFile(theFile) {
    var fileByteArray = [];
    return function (e) {
      var theBytes = e.target.result; //.split('base64,')[1]; // use with uploadFile2
      //console.log(theBytes);
      fileByteArray.push(theBytes);
      document.getElementById('file').innerText = '';
    //  for (var i = 0; i < fileByteArray.length; i++) {
    //    document.getElementById('file').innerText += fileByteArray[i];
    //  }
    }
  }

  processFile2(): any {
    var fileByteArray = [];
    return async (e) => {
      var theBytes = e.target.result; //.split('base64,')[1]; // use with uploadFile2
      console.log(theBytes);
      fileByteArray.push(theBytes);
      //document.getElementById('file').innerText = '';
  //    for(var i = 0; i<fileByteArray.length; i++) {
  //      document.getElementById('file').innerText += fileByteArray[i];
  //    }
    }

    console.log(fileByteArray);

  }

  removeAttachement(index: number) {

    console.log("index: " + index);

    if (this.fileAttachments.length > 0) {
      this.fileAttachments.splice(index, 1);
      this.attachmentHoverName = "";
    }
  }


  onEditorChanged(content: EditorChangeContent | EditorChangeSelection) {

    //console.log("content", content['editor']['root']['innerHTML']);

    this.quillContent = content['editor']['root']['innerHTML'];

  }


  async sendEmail() {
    const timeZone = this.authService.ms_user?.timeZone ?? 'UTC';
    //const graphEvent = this.model.getGraphEvent(timeZone);

    //console.log("this.toAddress.value", this.toAddress.nativeElement.value);

    if (this.toAddress.nativeElement.value == null || this.toAddress.nativeElement.value == "") {
      return;
    }

    try {

      let fileAttachments: MicrosoftGraphFileAttachment[] = null;

      if (this.fileAttachments != null && this.fileAttachments.length > 0) {

        fileAttachments = [];

        for (let i = 0; i <= this.fileAttachments.length - 1; i++) {
          //console.log("name: ", this.fileAttachments[i].FileName);

          fileAttachments.push(new MicrosoftGraphFileAttachment(this.fileAttachments[i].fileName, this.fileAttachments[i].contentType, this.fileAttachments[i].bytes.toString()));
        }


      }


      await this.graphService.sendMail(this.selFromAddress.value, this.toAddress.nativeElement.value, this.txtEmailSubject.nativeElement.value, this.quillContent, this.ccAddress.nativeElement.value, this.bccAddress.nativeElement.value, fileAttachments);
      //this.alertsService.addSuccess('Email Sent');
      this.clearForm();
      console.log("email sent");
      this.emailSentEvent.emit(true);

      //this.router.navigate(['/calendar']);
    } catch (error) {
      this.alertsService.addError('Error creating event.', error.message);
      console.log("email Error", error.message);
    }

  }

  clearForm() {
    this.toAddress.nativeElement.value = "";
    this.ccAddress.nativeElement.value = "";
    this.bccAddress.nativeElement.value = "";
    this.txtEmailSubject.nativeElement.value = "";
    this.quillContent = "";
    this.fileAttachments.length = 0;
    let quillEditor = document.getElementsByClassName("ql-editor");
    if (quillEditor != null && quillEditor[0] != null) {
      quillEditor[0].innerHTML = "";
    }
  }

  cancel() {
    this.sendMailCancellEvent.emit(true);
  }

  downloadAttachment(fileAttachment: FileAttachment) {

    const base64toBlob = (data: string) => {
      // Cut the prefix `data:application/pdf;base64` from the raw base 64
      //let contentBytes: string = fileAttachment.Bytes.toString();
      //let base64Index: number = contentBytes.indexOf("base64,");
      let base64Index: number = data.indexOf("base64,");
      let base64WithoutPrefix: string;
      if (base64Index > -1) {
        base64WithoutPrefix = data.substring(base64Index + 7, data.length);
      }
      if (base64Index == -1) {
        base64WithoutPrefix = data;
      }

      //console.log("base64Index", base64Index)
      //console.log("base64WithoutPrefix", base64WithoutPrefix)

      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      //return new Blob([out], { type: 'application/pdf' });
      return new Blob([out], { type: fileAttachment.contentType });
    };

    // Have to work out how to view email messages.
    if (fileAttachment.fileName.indexOf('.msg') == -1 && fileAttachment != null) {

      const blob = base64toBlob(fileAttachment.bytes.toString());

      //let mimeType: string = 'application/pdf';
      //let blob: any = new Blob([pdf], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      //console.log("opening widow");
      window.open(url);



    }

  }


}
