import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { Printer } from '../business-objects/printer.bo';

@Injectable()
export class PrinterService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getPrinter(printerId: string) {
		return this.http.get<Printer>(this._baseUrl + 'api/Printer/' + printerId);
	}

	getPrinterParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<Printer[]>(this._baseUrl + 'api/Printer/GetPrintersParamArray/', aParam);
	}

	getAllPrinters() {
		return this.http.get<Printer[]>(this._baseUrl + 'api/Printer/GetAllPrinters/');
	}

	//getAlldtoPrinters() {
	//	return this.http.get<dtoPrinter[]>(this._baseUrl + 'api/Printer/GetAllPrinters/');
	//}

	createPrinter(printer: Printer) {
		return this.http.post<Printer>(this._baseUrl + 'api/Printer/CreatePrinter/', printer);
	}

	updatePrinter(printer: Printer) {
		return this.http.put<Printer>(this._baseUrl + 'api/Printer/UpdatePrinter/', printer);
	}

	async getPrinterPromise(printerId: string) {
		return new Promise<Printer>(resolve => {
			this.getPrinter(printerId).subscribe(printer => {
				resolve(printer);
			}, err => {
				this.alertService.openSnackBarError("Error getting Printer records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getPrinterParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<Printer[]>(resolve => {
			this.getPrinterParamArray(aParam).subscribe(printers => {
				resolve(printers);
			}, err => {
				this.alertService.openSnackBarError("Error getting Printer records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatePrinterPromise(printer: Printer) {
		return new Promise<Printer>(resolve => {
			this.updatePrinter(printer).subscribe(printer => {
				resolve(printer);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating Printer record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createPrinterPromise(printer: Printer) {
		return new Promise<Printer>(resolve => {
			this.createPrinter(printer).subscribe(printer => {
				resolve(printer);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating Printer record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
  }


  async getPrintersByAppNameAndTypePromise(appName: string, type: Printer.enType) {
    return new Promise<Printer>(resolve => {
      this.http.get<Printer>(this._baseUrl + 'api/AppPrinter/GetPrintersByAppNameAndType/' + appName + '/' + type.toString()).subscribe(printer => {
        //console.log("treatment plant printer");
        //console.log(printer[0]);

        resolve(printer[0]);
        console.log(JSON.stringify(printer[0]));

      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

}
