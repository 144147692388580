<div class="product-profile-select-container" #productProfileContainer>
  <mat-form-field class="chh-form-field example-full-width">
    <mat-label>Search Product Profile</mat-label>
    <input matInput data-product-profile-input="productProfileInput" class="product-profile-input" placeholder="Filter by name" placeholderLabel="i.e. Essential Energy....." value="" type="text" #productProfileFilter (keyup)="filterProductProfiles(productProfileFilter.value, $event)" (focus)="productProfileFilter.select()" (click)="checkProductProfileListIsOpen(productProfileFilter.value)">
  </mat-form-field>

  <mat-icon matTooltip="Click to add a Product Profile" class="clickable-icon person-add" (click)="addProductProfile()">person_add</mat-icon>
  <span><mat-slide-toggle #multipleProductProfiles (click)="checkMultipleProductProfiles(multipleProductProfiles.checked)">Multiple Product Profiles</mat-slide-toggle></span>
  <div class="selected-product-profiles-container">
    <div class="selected-product-profiles" id="selectedProductProfiles" *ngIf="selectedProductProfiles != null && selectedProductProfiles.length > 0">
      <div class="chip-column">
        <mat-chip-listbox #chipGrid aria-label="ProductProfile selection">
          @for (selectProduct of selectedProductProfiles; track selectProduct; let selProdIdx = $index; let lastItem = $last) {
          <mat-chip-option (removed)="removeProductProfile(selectProduct)">
            {{selectProduct.txtName}}
            <button matChipRemove [attr.aria-label]="'remove ' + selectProduct.txtName">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
          }
        </mat-chip-listbox>
      </div>
      <div class="see-more-chips">
        <button mat-icon-button aria-label="expand row" (click)="expandSelectedProductProfiles($event)">
          <mat-icon class="expand-icons product-profile-selection-expand-icon material-icons-outlined" *ngIf="productProfileSelectionExpanded == false ">keyboard_arrow_down</mat-icon>
          <mat-icon class="expand-icons product-profile-selection-expand-icon material-icons-outlined" *ngIf="productProfileSelectionExpanded == true">keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div data-product-profile-select="productProfileSelect" class="product-profile-container" *ngIf="showProductProfileList" #productProfileListContainer>
    <div data-product-profile-select="productProfileSelect" id="productProfileSelect" class="product-profile-select-list">
      <div class="product-profile-pin-row">
        <div class="button" data-push-pin="pushPin" id="btnPushPin" (click)="clickPin($event)" [ngClass]="productProfileListPinned == true ? 'button-pressed' : 'button'" matTooltip="{{toolTipText}}">
          <mat-icon data-push-pin="pushPin" class="pin-icon">push_pin</mat-icon>
        </div>
      </div>
      <div class="product-profile-heading-row product-profile-name-heading">Name</div>
      <div class="product-profile-heading-row product-profile-update-heading"></div>
      @for(productProfile of productProfilesFiltered; track productProfile; let idx = $index){
      <div data-product-profile-select="productProfileSelect" tabindex="{{idx}}" id="productProfileName{{idx}}" (keyup)="nextProductProfile($event, productProfile)" class="chh-list-item-row chh-list-item-first-column product-profile-name" [ngClass]="productProfile.guSelectedProductProfileId != '' ? 'chh-list-item-row chh-list-item-row-selected product-profile-first-name' : addressHoverId == productProfile.rowguid ? 'chh-list-item-row chh-list-item-row-hover product-profile-first-name' : 'chh-list-item-row product-profile-first-name'" (mouseenter)="rowEnter(productProfile.rowguid)" (mouseleave)="rowLeave()" (click)="selectProductProfile(productProfile, !(productProfile.guSelectedProductProfileId != ''))"><div data-product-profile-select="productProfileSelect" class="product-profile-text">{{productProfile.txtName}}</div></div>
      <div data-product-profile-select="productProfileSelect" id="productProfileUpdateDiv{{idx}}" class="chh-list-item-row product-profile-radio product-profile-update-div" [ngClass]="productProfile.guSelectedProductProfileId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == productProfile.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(productProfile.rowguid)" (mouseleave)="rowLeave()" (click)="selectProductProfile(productProfile, !(productProfile.guSelectedProductProfileId != ''))">
        <div data-product-profile-select="productProfileSelect" id="productProfileUpdateButtonDiv{{idx}}" class="update-product-profile-button">
          <button mat-button (click)="updateProductProfile(productProfile)" matTooltip="Click to edit ProductProfile">
            <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
          </button>
        </div>
      </div>

      }
    </div>

  </div>

</div>
