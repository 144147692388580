export interface AppPreference
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	intApp: number;
	rowguid: string;
	txtName: string;
}

export class AppPreference
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	intApp: number;
	rowguid: string;
	txtName: string;
}

