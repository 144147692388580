import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { dtoProductCode } from '../../_shared/business-objects/dto-product-code.bo';
import { ProductCode } from '../../_shared/business-objects/product-code.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { SQLParamService } from '../../_shared/services/app-services/sql-param.service';
import { dtoProductCodeService } from '../../_shared/services/dto-product-code.service';
import { ProductCodeService } from '../../_shared/services/product-code.service';
import { ProductCodeBuilderDialogComponent } from '../product-code-builder-dialog/product-code-builder-dialog.component';

@Component({
  selector: 'app-product-code-select',
  templateUrl: './product-code-select.component.html',
  styleUrl: './product-code-select.component.scss'
})
export class ProductCodeSelectComponent {
  @ViewChild('productCodeFilter') productCodeFilter: ElementRef;
  @ViewChild('productCodeListContainer') productCodeListContainer: ElementRef;
  @ViewChild('multipleProductCodes') multipleProductCodes: MatSlideToggle;


  productCodes: dtoProductCode[] = [];
  @Input() multipleCodes: boolean;
  @Output() selectedProductCodesEvent = new EventEmitter<dtoProductCode[]>();

  showSpinner: boolean
  addressHoverId: string;

  productCodeSelectDialogRef: MatDialogRef<ProductCodeBuilderDialogComponent>

  //productCodeUpdateDialogRef: MatDialogRef<ProductCodeUpdateDialogComponent>;
  selectedProductCodes: dtoProductCode[];
  productCodesFiltered: dtoProductCode[];
  productCodeExpanded: dtoProductCode;

  productCodeFilteredItems: HTMLCollectionOf<HTMLDivElement>;

  showProductCodeList: boolean = false;
  showProductCodeListTransition: boolean = false;

  productCodeSelectionExpanded: boolean = false;
  productCodeListPinned: boolean = false;

  toolTipText: string = "pin";

  aParamList: SQLParamArray[][] = [];

  constructor(private productCodeService: ProductCodeService, private dtoProductCodeService: dtoProductCodeService
    , private alertService: AlertService, private dialog: MatDialog, private sqlParamService: SQLParamService) {

  }

  ngOnInit() {
    this.selectedProductCodes = [];

    document.addEventListener("click", this.openCloseProductCodeList.bind(this));

  }

  async searchProdctCodes(value: string) {

    

    if (value == null || value == "") {
      return;
    }

    this.aParamList = [];

    this.sqlParamService.addParameter(this.aParamList, value, "txtStockKeepingUnit", false, SQLParamArray.enSQLOperator.Like);

    this.showSpinner = true;

    this.productCodes = await this.dtoProductCodeService.getdtoProductCodeParamArrayPromise(this.aParamList);
    this.productCodesFiltered = this.productCodes;
    console.log("value", value);

    this.showSpinner = false;

    if (this.selectedProductCodes != null && this.selectedProductCodes.length > 0) {
      for (let i = 0; i <= this.productCodes.length - 1; i++) {
        for (let s = 0; s <= this.selectedProductCodes.length - 1; s++) {
          if (this.productCodes[i].rowguid == this.selectedProductCodes[s].rowguid) {
            this.productCodes[i].guSelectedProductCodeId = "tempvalue";
          }
        }

      }

    }
    console.log("this.productCodes", this.productCodes);

    this.showProductCodeList = true;

    this.setProductCodeListTranition(1000);

    //this.filterProductCodes(value, null);

  }


  openCloseProductCodeList(e) {

    if (this.productCodeListPinned == true) {
      return;
    }

    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.pushPin != null) {
      return;
    }


    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.productCodeInput != null && this.productCodeFilter.nativeElement.value != null && this.productCodeFilter.nativeElement.value.toString() != "") {
      this.showProductCodeList = true;
      //console.log("this.showCustomerList = true", this.showCustomerList);
      //console.log("customerListContainer", this.customerFilter.nativeElement.value);

      return;
    }

    //if (e == null || e.target == null || e.target.dataset == null || e.target.dataset.productCodeSelect == null) {

    // ALWAYS CLOSE THE SEARCH LIST UNLESS I'M PINNED
    if (this.productCodeListPinned == false) {
      this.showProductCodeList = false;

      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      //console.log("this.showCustomerList = false", this.showCustomerList);
      return;
    }

    //let domStringMap: DOMStringMap = e.target.dataset;

    //console.log(domStringMap.customerSelect.toString() == "customerSelect");
    //console.log(domStringMap.customerSelect.toString());
  }

  checkProductCodeListIsOpen(inputValue: string) {

    //console.log("inputValue", inputValue);

    if (inputValue == null || inputValue == "") {
      this.showProductCodeList = false;
      //console.log("inputValue empty", inputValue);
      return;
    }

    this.showProductCodeList = true;


  }


  rowEnter(productCodeId: string) {
    this.addressHoverId = productCodeId;
    //console.log("row enter");
  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");
  }

  async selectProductCode(productCode: dtoProductCode, selected: boolean) {

    if (productCode == null) {
      this.alertService.openSnackBarDefault("There was a problem selecting the productCode - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    // Remove All ProductCode Contact
    if (this.multipleProductCodes == null || this.multipleProductCodes.checked == false) {

      let productCodeSelected: dtoProductCode[] = this.productCodes.filter((a) => { return a.guSelectedProductCodeId != null && a.guSelectedProductCodeId != "" });
      for (let i = 0; i <= productCodeSelected.length - 1; i++) {
        productCodeSelected[i].guSelectedProductCodeId = "";
      }

      for (let i = this.selectedProductCodes.length - 1; i >= 0; i--) {
        this.selectedProductCodes.splice(i, 1);
      }

      for (let i = this.productCodesFiltered.length - 1; i >= 0; i--) {
        this.productCodesFiltered[i].guSelectedProductCodeId = "";
      }

    }

    // Remove ProductCode Contact
    if (selected == false) {
      for (let i = this.selectedProductCodes.length - 1; i >= 0; i--) {
        if (this.selectedProductCodes[i].rowguid == productCode.rowguid) {
          this.selectedProductCodes.splice(i, 1);
          productCode.guSelectedProductCodeId = "";
          //console.log("unselected productCode");
          this.selectedProductCodesEvent.emit(this.selectedProductCodes);
          break;
        }
      }
    }

    // Add ProductCode Contact
    if (selected == true) {

      productCode.guSelectedProductCodeId = "tempvalue";

      this.selectedProductCodes.push(productCode);
      this.selectedProductCodesEvent.emit(this.selectedProductCodes);

      if ((this.multipleProductCodes == null || this.multipleProductCodes.checked == false) && this.productCodeListPinned == false) {
        this.showProductCodeList = false;
      }

      //console.log("added productCode");

    }


  }

  //async updateProductCode(productCode: dtoProductCode) {

  //  console.log(productCode);
  //  if (productCode == null) {
  //    this.alertService.openSnackBarDefault("There is no productCode to edit");
  //    return;
  //  }

  //  if (this.productCodeUpdateDialogRef != null) {
  //    this.productCodeUpdateDialogRef.close();
  //  }

  //  this.productCodeUpdateDialogRef = this.dialog.open(ProductCodeUpdateDialogComponent, {
  //    hasBackdrop: false,
  //    height: '95vh',
  //    maxWidth: '90vw',
  //    data: { selectedProductCode: productCode }
  //  });

  //  this.productCodeUpdateDialogRef.backdropClick().subscribe(() => {
  //    this.productCodeUpdateDialogRef.close();
  //  });


  //  this.productCodeUpdateDialogRef
  //    .afterClosed()
  //    .subscribe(async (
  //      data: { status: string, productCode: ProductCode }) => {
  //      if (data == null || data == undefined) {
  //        //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
  //        return;
  //      }

  //      //console.log(data);
  //      if (data.status != "Cancel") {
  //        let cust: dtoProductCode = await this.dtoProductCodeService.getdtoProductCodePromise(data.productCode.rowguid);
  //        this.selectedProductCodes.push(cust);

  //        for (let i = 0; i <= this.productCodes.length - 1; i++) {
  //          if (this.productCodes[i].rowguid == data.productCode.rowguid) {
  //            this.productCodes[i] = cust;
  //            this.productCodes[i].guSelectedProductCodeId = "tempvalue";
  //            break;
  //          }
  //        }

  //        this.productCodes.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

  //        this.alertService.openSnackBarDefault("The ProductCode has been updated");

  //      }
  //      else {
  //        // We have cancellled

  //      }

  //    });

  //}

  addProductCode() {

  }

  nextProductCode(e, productCode: dtoProductCode) {

    //console.log("e ", e);

    if (e.code.toLowerCase() == "escape") {
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      this.productCodeFilter.nativeElement.focus();
      this.productCodeFilter.nativeElement.setSelectionRange(this.productCodeFilter.nativeElement.value.length, this.productCodeFilter.nativeElement.value.length);

      this.showProductCodeList = false;
      return;
    }

    if ((e.code.toLowerCase() == "arrowdown" || e.code.toLowerCase() == "arrowup") && this.showProductCodeList == true) {
      //console.log("e ", e);

      if (this.productCodeFilteredItems != null) {

        let tabIndex: number = e.target.tabIndex;

        if (tabIndex < this.productCodeFilteredItems.length - 1 && e.code.toLowerCase() == "arrowdown") {

          //console.log("e down ", tabIndex);
          //TODO
          // Set this.productCodeContainer.style overflow-y to hidden here
          let docBody = document.getElementById("rootBody");
          docBody.style.overflowY = "hidden";

          this.productCodeListContainer.nativeElement.classList.add("product-code-container-no-scroll");

          this.productCodeFilteredItems[tabIndex].classList.remove("chh-list-item-row-hover");
          //this.productCodeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");

          this.productCodeFilteredItems[tabIndex + 1].focus();
          this.productCodeFilteredItems[tabIndex + 1].classList.add("chh-list-item-row-hover");
          //this.productCodeFilteredItems[tabIndex + 1].classList.add("chh-list-item-row-selected");

          // Set this.productCodeContainer.style overflow-y to scroll here
          this.productCodeListContainer.nativeElement.classList.remove("product-code-container-no-scroll");

        }

        if (tabIndex >= 0 && e.code.toLowerCase() == "arrowup") {

          if (tabIndex == 0) {
            //console.log("up tabIndex ", tabIndex);

            this.productCodeFilteredItems[tabIndex].classList.remove("chh-list-item-row-hover");
            //this.productCodeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");

            let docBody = document.getElementById("rootBody");
            docBody.style.overflowY = "scroll";

            this.productCodeFilter.nativeElement.focus();
            this.productCodeFilter.nativeElement.setSelectionRange(this.productCodeFilter.nativeElement.value.length, this.productCodeFilter.nativeElement.value.length);
            return;
          }


          //console.log("e up ", tabIndex);

          this.productCodeListContainer.nativeElement.classList.add("product-code-container-no-scroll");

          this.productCodeFilteredItems[tabIndex].classList.remove("chh-list-item-row-hover");
          //this.productCodeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");

          this.productCodeFilteredItems[tabIndex - 1].focus();
          this.productCodeFilteredItems[tabIndex - 1].classList.add("chh-list-item-row-hover");
          //this.productCodeFilteredItems[tabIndex - 1].classList.add("chh-list-item-row-selected");

          this.productCodeListContainer.nativeElement.classList.remove("product-code-container-no-scroll");


        }

      }
      //console.log("nextProductCode");

    }

    if (e.code.toLowerCase() == "enter") {
      this.selectProductCode(productCode, !(productCode.guSelectedProductCodeId != ''));
      if (this.productCodeListPinned == false) {
        this.showProductCodeList = false;
      }
    }

  }

  filterProductCodes(productCodeFilter: string, e) {


    if (e != null && e.code.toLowerCase() == "arrowdown" && this.showProductCodeList == true) {
      //console.log("e ", e);
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "hidden";

      this.productCodeFilteredItems = document.getElementsByClassName("product-code-name") as HTMLCollectionOf<HTMLDivElement>;
      if (this.productCodeFilteredItems != null && this.productCodeFilteredItems.length > 0) {


        this.productCodeFilteredItems[0].classList.add("chh-list-item-row-hover");
        //this.productCodeFilteredItems[0].classList.add("chh-list-item-row-selected");

        this.productCodeFilteredItems[0].focus();
        console.log("filterProductCode TabIndex: ", this.productCodeFilteredItems[0].tabIndex);

      }

      //console.log("productCodeElements", this.productCodeFilteredItems);

    }

    //this.getProductCodes(productCodeFilter);



  }

  getProductCodes(productCodeFilter: string) {

    this.productCodesFiltered = this.productCodes;

    if (productCodeFilter == null || productCodeFilter == "") {
      this.productCodesFiltered = [];
      this.showProductCodeList = false;
      return;
    }



    let productCodes: dtoProductCode[] = this.productCodes;

    productCodes = productCodes.filter(a => ((a.txtStockKeepingUnit.toLowerCase().indexOf(productCodeFilter.toLowerCase()) > -1)));

    productCodes = productCodes.slice(0, 50);

    productCodes.sort((a, b) => { return a.txtStockKeepingUnit > b.txtStockKeepingUnit ? 1 : -1 });

    this.productCodesFiltered = productCodes;

    this.showProductCodeList = true;

    this.setProductCodeListTranition(1000);

  }

  setProductCodeListTranition(delay: number) {

    setTimeout(() => {
      this.showProductCodeListTransition = true;
    }, delay);

  }

  checkMultipleProductCodes(multipleProductCodes: boolean) {

    if (multipleProductCodes == false) {
      //Remove multiple selected productCodes so only one remains.
      let multipleCust: dtoProductCode[] = this.productCodes.filter((a) => { return a.guSelectedProductCodeId != null && a.guSelectedProductCodeId != "" });
      if (multipleCust != null && multipleCust.length > 0) {

        multipleCust.sort((a, b) => { return a.txtStockKeepingUnit > b.txtStockKeepingUnit ? 1 : -1 });

        for (let i = multipleCust.length - 1; i >= 1; i--) {
          multipleCust.pop();
        }

        for (let i = 0; i <= this.productCodes.length - 1; i++) {
          if (multipleCust[0].rowguid != this.productCodes[i].rowguid) {
            this.productCodes[i].guSelectedProductCodeId = "";
          }
        }

        this.selectedProductCodes.length = 0;
        this.selectedProductCodes.push(multipleCust[0]);
        this.selectedProductCodesEvent.emit(this.selectedProductCodes);

      }

    }
  }

  removeProductCode(productCode: dtoProductCode) {

    if (productCode == null) {
      return;
    }

    for (let i = this.selectedProductCodes.length - 1; i >= 0; i--) {
      if (this.selectedProductCodes[i].rowguid == productCode.rowguid) {
        this.selectedProductCodes.splice(i, 1);
        productCode.guSelectedProductCodeId = "";
        //console.log("unselected productCode");
        this.selectedProductCodesEvent.emit(this.selectedProductCodes);
        break;
      }
    }


  }

  expandSelectedProductCodes(e: PointerEvent) {

    if (e.pointerType == null || e.pointerType == "") {
      return;
    }

    this.productCodeSelectionExpanded = !this.productCodeSelectionExpanded;

    let selectedProductCodes: HTMLDivElement = document.getElementById("selectedProductCodes") as HTMLDivElement;
    selectedProductCodes.classList.toggle("selected-product-codes-expanded");

    if (this.productCodeSelectionExpanded == true) {

    }

  }

  clickPin(e) {

    this.productCodeListPinned = !this.productCodeListPinned;

    if (this.productCodeListPinned == false) {
      this.showProductCodeList = false;
    }

    this.toolTipText = this.productCodeListPinned == true ? 'unpin' : 'pin';


  }

  openProductCodeSelect() {

    this.productCodeSelectDialogRef = this.dialog.open(ProductCodeBuilderDialogComponent, {
      hasBackdrop: false,
      height: '95vh',
      maxWidth: '90vw',
      data: { productCode: this.productCodeFilter.nativeElement.value }
    });

    // -------------------------------------------------------------------------------
    // COULD USE componentInstance FOR SOMTHING.
    //this.deliveryDocketUpdateDialogRef.componentInstance.calcTotals = this.calculateOrderTotals;
    // -------------------------------------------------------------------------------

    this.productCodeSelectDialogRef.backdropClick().subscribe(() => {
      this.productCodeSelectDialogRef.close();
    });


    this.productCodeSelectDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, productCode: string }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        if (data.status != "Cancel") {
          console.log("data.productCode", data.productCode);
          //this.form.controls['txtSearchProductCode'].setValue(data.productCode);
          this.productCodeFilter.nativeElement.value = data.productCode;
          this.searchProdctCodes(this.productCodeFilter.nativeElement.value);
          //console.log("data.deliveryDocket.rowguid: " + data.deliveryDocket.rowguid);


          //if (data.customerDeliveryAddress == null) {
          //  this.alertService.openSnackBar("There is no delivery address selected.", "Close", "center", "bottom", "", 5000);
          //  return;
          //}

          //if (data.customerDeliveryAddress != null) {

          //  this.alertService.openSnackBarDefault("Delivery address has been updated.");

          //}

        }
        else {
          // We have cancellled

        }

      });

  }

}
