import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNull'
})
export class IsNullPipe implements PipeTransform {

  constructor() { }

  public transform(value: any, returnValue: any = ""): any {

    return value == null ? returnValue : value;

  }
}
