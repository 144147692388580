import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoProductType } from '../business-objects/dto-product-type.bo';

@Injectable()
export class dtoProductTypeService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoProductType(dtoProductTypeId: string) {
		return this.http.get<dtoProductType>(this._baseUrl + 'api/dtoProductType/' + dtoProductTypeId);
	}

	getdtoProductTypeParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoProductType[]>(this._baseUrl + 'api/dtoProductType/GetdtoProductTypesParamArray/', aParam);
	}

	getAlldtoProductTypes() {
		return this.http.get<dtoProductType[]>(this._baseUrl + 'api/dtoProductType/GetAlldtoProductTypes/');
	}

	//getAlldtodtoProductTypes() {
	//	return this.http.get<dtodtoProductType[]>(this._baseUrl + 'api/dtoProductType/GetAlldtoProductTypes/');
	//}

	createdtoProductType(dtoProductType: dtoProductType) {
		return this.http.post<dtoProductType>(this._baseUrl + 'api/dtoProductType/CreatedtoProductType/', dtoProductType);
	}

	updatedtoProductType(dtoProductType: dtoProductType) {
		return this.http.put<dtoProductType>(this._baseUrl + 'api/dtoProductType/UpdatedtoProductType/', dtoProductType);
	}

	deletedtoProductType(dtoProductTypeId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoProductType/DeletedtoProductType/' + dtoProductTypeId);
	}

  async getdtoProductTypes() {
    return new Promise<dtoProductType[]>(resolve => {
      this.getAlldtoProductTypes().subscribe(dtoProductType => {
        resolve(dtoProductType);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoProductType records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getdtoProductTypePromise(dtoProductTypeId: string) {
		return new Promise<dtoProductType>(resolve => {
			this.getdtoProductType(dtoProductTypeId).subscribe(dtoProductType => {
				resolve(dtoProductType);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoProductType records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoProductTypeParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoProductType[]>(resolve => {
			this.getdtoProductTypeParamArray(aParam).subscribe(dtoProductTypes => {
				resolve(dtoProductTypes);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoProductType records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoProductTypePromise(dtoProductType: dtoProductType) {
		return new Promise<dtoProductType>(resolve => {
			this.updatedtoProductType(dtoProductType).subscribe(dtoProductType => {
				resolve(dtoProductType);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoProductType record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoProductTypePromise(dtoProductType: dtoProductType) {
		return new Promise<dtoProductType>(resolve => {
			this.createdtoProductType(dtoProductType).subscribe(dtoProductType => {
				resolve(dtoProductType);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoProductType record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoProductTypePromise(dtoProductTypeId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoProductType(dtoProductTypeId).subscribe(dtoProductType => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoProductType record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
