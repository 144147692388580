import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../account/auth.service';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { dtoHaulingCompanyDriver } from '../../_shared/business-objects/dto-hauling-company-driver.bo';
import { dtoHaulingCompany } from '../../_shared/business-objects/dto-hauling-company.bo';
import { Employee } from '../../_shared/business-objects/employee.bo';
import { HaulingCompanyDriver } from '../../_shared/business-objects/hauling-company-driver.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoHaulingCompanyDriverService } from '../../_shared/services/dto-hauling-company-driver.service';
import { dtoHaulingCompanyService } from '../../_shared/services/dto-hauling-company.service';
import { EmployeeService } from '../../_shared/services/employee.service';
import { FormService } from '../../_shared/services/form.service';
import { HaulingCompanyDriverService } from '../../_shared/services/hauling-company-driver.service';
import { SharedService } from '../../_shared/services/shared.service';

export enum enHaulingCompanyDriverTabs { HaulingCompanyDriver = 0, SearchOptions = 1, CreateHaulingCompanyDriver = 2, UpdateHaulingCompanyDriver = 3 }


@Component({
  selector: 'app-hauling-company-driver-search',
  templateUrl: './hauling-company-driver-search.component.html',
  styleUrls: ['./hauling-company-driver-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class HaulingCompanyDriverSearchComponent implements OnInit {

  @ViewChild('tabHaulingCompanyDrivers', { static: false }) tabHaulingCompanyDrivers: MatTabGroup;

  form: FormGroup;

  public eHaulingCompanyDriverTabs = enHaulingCompanyDriverTabs;

  selectedTab: number;
  showSpinner: boolean = false;
  haulingCompanyDrivers: MatTableDataSource<dtoHaulingCompanyDriver> = new MatTableDataSource();
  haulingCompanyDriver: HaulingCompanyDriver;
  haulingCompany: dtoHaulingCompany;
  haulingCompanyEmployee: Employee;

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  constructor(private dtoHaulingCompanyDriverService: dtoHaulingCompanyDriverService, private haulingCompanyDriverService: HaulingCompanyDriverService, private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private route: ActivatedRoute, private formService: FormService
    , private authService: AuthService, private employeeService: EmployeeService, private dtoHaulingCompanyService: dtoHaulingCompanyService) {

    this.form = this.fb.group({
      txtSearchHaulingCompany: [''],
      txtSearchDriverName: [''],
      blnSearchActive: ['']
    });

    this.filterToQueryParamMap.push({ txtField: "txtSearchHaulingCompany", txtQueryParam: "hauler", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    this.filterToQueryParamMap.push({ txtField: "txtSearchDriverName", txtQueryParam: "customer", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    this.filterToQueryParamMap.push({ txtField: "blnSearchActive", txtQueryParam: "location", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
  }

  ngOnInit(): void {

    this.selectedTab = this.eHaulingCompanyDriverTabs.HaulingCompanyDriver;

    this.loadData()


  }

  async loadData() {


    this.formService.setAutoFocusTimeout("txtSearchHaulingCompany");

  }


  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    console.log(e.index == this.eHaulingCompanyDriverTabs.SearchOptions);

    if (e.index == this.eHaulingCompanyDriverTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchDriverName");
    }

    if (e.index == this.eHaulingCompanyDriverTabs.HaulingCompanyDriver) {
      this.formService.setAutoFocusTimeout("txtSearchHaulingCompany");
    }

  }

  async searchHaulingCompanyDrivers(searchType: string) {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let haulingCompanyName: string = this.form.controls["txtSearchHaulingCompany"].value;
    let driverName: string = this.form.controls["txtSearchDriverName"].value;
    const datepipe: DatePipe = new DatePipe('en-AU');

    if (haulingCompanyName != "" && haulingCompanyName != null) {
      aParam = [];
      if (haulingCompanyName.indexOf(",")) {
        let aHaulingCompanyNames: string[] = haulingCompanyName.split(",");
        for (let i = 0; i <= aHaulingCompanyNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtHaulingCompanyName", aHaulingCompanyNames[i], SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtHaulingCompanyName", haulingCompanyName, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }

    if (driverName != "" && driverName != null) {
      aParam = [];
      if (driverName.indexOf(",")) {
        let aHaulingCompanyDriverNames: string[] = driverName.split(",");
        for (let i = 0; i <= aHaulingCompanyDriverNames.length - 1; i++) {
          aParam.push(new SQLParamArray("txtHaulingCompanyName", aHaulingCompanyDriverNames[i], SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
        }
      }
      else {
        aParam.push(new SQLParamArray("txtName", driverName, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND))
      }
      aParamList.push(aParam);
    }


    this.showSpinner = true;

    //if (aParamList.length == 0) {
    //  this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "centerSnackbar", 4000);
    //  this.showSpinner = false;
    //  return;
    //}

    console.log("params: ", aParamList);

    let haulingCompanyDrivers: dtoHaulingCompanyDriver[] = await this.dtoHaulingCompanyDriverService.getdtoHaulingCompanyDriverParamArrayPromise(aParamList);
    haulingCompanyDrivers.sort((a, b) => { return a.txtHaulingCompanyName > b.txtHaulingCompanyName ? 1 : -1 });
    this.haulingCompanyDrivers.data = haulingCompanyDrivers;


    console.log("this.haulingCompanyDrivers", this.haulingCompanyDrivers.data);

      this.tabHaulingCompanyDrivers.selectedIndex = this.eHaulingCompanyDriverTabs.HaulingCompanyDriver;
      this.showSpinner = false;

  }


  async editHaulingCompanyDriver(haulingCompanyDriver: dtoHaulingCompanyDriver) {

    this.haulingCompanyDriver = await this.haulingCompanyDriverService.getHaulingCompanyDriverPromise(haulingCompanyDriver.rowguid);
    this.haulingCompany = await this.dtoHaulingCompanyService.getdtoHaulingCompanyPromise(haulingCompanyDriver.guHaulingCompanyId);

    if (haulingCompanyDriver.guEmployeeId != null) {
      this.haulingCompanyEmployee = await this.employeeService.getEmployeePromise(haulingCompanyDriver.guEmployeeId);
    }

    this.tabHaulingCompanyDrivers.selectedIndex = this.eHaulingCompanyDriverTabs.UpdateHaulingCompanyDriver;

  }


  updatedLoadingDocket(haulingCompanyDriver: dtoHaulingCompanyDriver) {


  }


  async haulingCompanyDriverCreated(haulingCompanyDriverCreatedObj: [HaulingCompanyDriver, string]) {
    if (haulingCompanyDriverCreatedObj == null) {
      return;
    }

    if (haulingCompanyDriverCreatedObj[1] == "create") {
      this.tabHaulingCompanyDrivers.selectedIndex = this.eHaulingCompanyDriverTabs.HaulingCompanyDriver;
      this.haulingCompanyDrivers.data = [];

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("rowguid", haulingCompanyDriverCreatedObj[0].rowguid));
      aParamList.push(aParam);

      this.haulingCompanyDrivers.data = await this.dtoHaulingCompanyDriverService.getdtoHaulingCompanyDriverParamArrayPromise(aParamList);
    }

  }

  async haulingCompanyDriverUpdated(haulingCompanyDriver: HaulingCompanyDriver) {

    if (haulingCompanyDriver == null) {
      return;
    }

    this.tabHaulingCompanyDrivers.selectedIndex = this.eHaulingCompanyDriverTabs.HaulingCompanyDriver;
    this.haulingCompanyDrivers.data = [];
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("rowguid", haulingCompanyDriver.rowguid));
    aParamList.push(aParam);

    this.haulingCompanyDrivers.data = await this.dtoHaulingCompanyDriverService.getdtoHaulingCompanyDriverParamArrayPromise(aParamList);

  }

  haulingCompanyDriverCancelled(val: boolean) {
    this.selectedTab = this.eHaulingCompanyDriverTabs.HaulingCompanyDriver;
  }

}
