import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { dtoBoardTallyRecordSumNotInStock } from '../business-objects/dto-board-tally-record-sum-not-in-stock.bo';
import { DatePipe } from '@angular/common';

@Injectable()
export class dtoBoardTallyRecordNotInStockService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getdtoBoardTallyRecordNotInStock(dateFrom: Date, dateTo: Date, geoStockLocationIds: string[], statusDate: Date, stocktakeDate: Date) {
    const datepipe: DatePipe = new DatePipe('en-AU');
    let url: string = "api/dtoBoardTallyRecordSumNotInStock/GetdtoBoardTallyRecordSumNotInStock/?dateFrom=2022-06-30&dateTo=2022-06-30";
    for (let i = 0; i <= geoStockLocationIds.length - 1; i++) {
      url += "&geoStockLocationIds=" + geoStockLocationIds[i];
    }
    //"Http failure response for https://localhost:44356/api/dtoBoardTallyRecordSumNotInStock/GetdtoBoardTallyRecordSumNotInStock/?dateFrom=2022-06-30&dateTo=2022-06-30&geoStockLocationIds=11111111-1111-1111-1111-111111111111&geoStockLocationIds=33333333-3333-3333-3333-333333333333: 415 OK"
    //return this.http.get<dtoBoardTallyRecordSumNotInStock[]>(this._baseUrl + 'api/dtoBoardTallyRecordSumNotInStock/GetdtoBoardTallyRecordSumNotInStock/?dateFrom=' + datepipe.transform(dateFrom, 'yyyy-MM-dd') + '&dateTo=' + datepipe.transform(dateTo, 'yyyy-MM-dd') + '&geoStockLocationIds=' + geoStockLocationIds + '&statusDate=' + datepipe.transform(statusDate, 'yyyy-MM-dd') + '&stocktakeDate=' + datepipe.transform(stocktakeDate, 'yyyy-MM-dd'));
    return this.http.get<dtoBoardTallyRecordSumNotInStock[]>(this._baseUrl + url);
  }

  getTestArray() {
    const datepipe: DatePipe = new DatePipe('en-AU');
    let url: string = "api/dtoBoardTallyRecordSumNotInStock/GetArrayTest?Ids=test1&Ids=test2";
    return this.http.get(this._baseUrl + url);
  }

}
