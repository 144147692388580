import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BoardTallyRecordMoulder } from '../business-objects/board-tally-record-moulder.bo';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { ExecFileOptionsWithStringEncoding } from 'child_process';

@Injectable()
export class BoardTallyRecordMoulderService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		this._baseUrl = baseUrl;
	}

	getBoardTallyRecordMoulder(boardTallyRecordMoulderId: string) {
		return this.http.get<BoardTallyRecordMoulder>(this._baseUrl + 'api/BoardTallyRecordMoulder/' + boardTallyRecordMoulderId);
	}

	getAllBoardTallyRecordMoulders() {
		return this.http.get<BoardTallyRecordMoulder[]>(this._baseUrl + 'api/BoardTallyRecordMoulder/GetAllBoardTallyRecordMoulders/');
	}

  getAllBoardTallyRecordMouldersParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<BoardTallyRecordMoulder[]>(this._baseUrl + 'api/BoardTallyRecordMoulder/GetAllBoardTallyRecordMouldersParamArray/', aParam);
  }

	//getAlldtoBoardTallyRecordMoulders() {
	//	return this.http.get<dtoBoardTallyRecordMoulder[]>(this._baseUrl + 'api/BoardTallyRecordMoulder/GetAllBoardTallyRecordMoulders/');
	//}

	createBoardTallyRecordMoulder(boardTallyRecordMoulder: BoardTallyRecordMoulder) {
		return this.http.post<BoardTallyRecordMoulder>(this._baseUrl + 'api/BoardTallyRecordMoulder/CreateBoardTallyRecordMoulder/', boardTallyRecordMoulder);
	}

	updateBoardTallyRecordMoulder(boardTallyRecordMoulder: BoardTallyRecordMoulder) {
		return this.http.post<BoardTallyRecordMoulder>(this._baseUrl + 'api/BoardTallyRecordMoulder/UpdateBoardTallyRecordMoulder/', boardTallyRecordMoulder);
  }

  deleteBoardTallyRecordMoulder(boardTallyRecordMoulderId: string) {
    return this.http.delete(this._baseUrl + 'api/BoardTallyRecordMoulder/' + boardTallyRecordMoulderId);
  }

  getAllBoardTallyRecordMouldersParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<BoardTallyRecordMoulder[]>(resolve => {
      this.getAllBoardTallyRecordMouldersParamArray(aParam).subscribe(boardTallyRecordMoulders => {
        resolve(boardTallyRecordMoulders);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  async createBoardTallyRecordMoulderPromise(boardTallyRecordMoulder: BoardTallyRecordMoulder) {
    return new Promise<BoardTallyRecordMoulder>(resolve => {
      this.createBoardTallyRecordMoulder(boardTallyRecordMoulder).subscribe(boardTallyRecordMoulder => {
        resolve(boardTallyRecordMoulder);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  deleteBoardTallyRecordMoulderPromise(boardTallyRecordMoulderId: string) {
    return new Promise<Boolean>(resolve => {
      this.deleteBoardTallyRecordMoulder(boardTallyRecordMoulderId).subscribe(data => {
        resolve(true);
      }, err => {
        console.log(err);
        resolve(false);
      });

    });

  }

}
