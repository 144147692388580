import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { DeliveryDocket } from '../business-objects/delivery-docket.bo';
import { SharedService } from './shared.service';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';


@Injectable()
export class DeliveryDocketService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getDeliveryDockets() {
    return this.http.get<DeliveryDocket[]>(this._baseUrl + 'api/deliverydocket');
  }

  getDeliveryDocket(docketno: number) {
    return this.http.get<DeliveryDocket>(this._baseUrl + 'api/deliverydocket/' + docketno);
  }

  getDeliveryDocketById(deliveryDocketId: string) {
    return this.http.get<DeliveryDocket>(this._baseUrl + 'api/deliverydocket/GetDeliveryDocketById/' + deliveryDocketId);
  }

  getDeliveryDocketParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<DeliveryDocket[]>(this._baseUrl + 'api/DeliveryDocket/GetDeliveryDocketsParamArray/', aParam);
  }

  createDeliveryDocket(deliveryDocket: DeliveryDocket) {

    //console.log("createDeliveryDocket: ", deliveryDocket);

    return this.http.post<DeliveryDocket>(this._baseUrl + 'api/deliverydocket/', deliveryDocket);
  }

  updateDeliveryDocket(deliveryDocket: DeliveryDocket) {
    return this.http.put<DeliveryDocket>(this._baseUrl + 'api/deliverydocket/', deliveryDocket);
  }

  saveSignature(deliveryDocketId: string, signature: ArrayBuffer) {
    //console.log("signature: ", signature);
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/octet-stream'
    });

    //let path: string = this._baseUrl;
    //path = "https://localhost:44358/";

    //return this.http.post<Blob>(path + 'api/DeliveryDocket/SaveSignature/', signature, { headers: headers, responseType: 'blob' as 'json' });

    let arrBuffer: ArrayBuffer = signature;
    //console.log(arrBuffer);

    //return this.http.post<Blob>("https://localhost:44358/api/Print/SaveFile/", arrBuffer, { headers: headers, responseType: 'blob' as 'json' });
    return this.http.post<Blob>(this._baseUrl + "api/DeliveryDocket/SaveSignature/" + deliveryDocketId, arrBuffer, { headers: headers, responseType: 'blob' as 'json' });

  }

  async getDeliveryDocketPromise(deliveryDocketId: string) {
    return new Promise<DeliveryDocket>(resolve => {
      this.getDeliveryDocketById(deliveryDocketId).subscribe(deliveryDocket => {
        resolve(deliveryDocket);
      }, err => {
        console.log(err);
      });
    })
  }

  async getDeliveryDocketParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<DeliveryDocket[]>(resolve => {
      this.getDeliveryDocketParamArray(aParam).subscribe(deliveryDockets => {
        resolve(deliveryDockets);
      }, err => {
        this.alertService.openSnackBarError("Error getting DeliveryDocket records", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }


  async updateDeliveryDocketPromise(deliveryDocket: DeliveryDocket) {
    return new Promise<DeliveryDocket>(resolve => {
      this.updateDeliveryDocket(deliveryDocket).subscribe(deliveryDocket => {
        resolve(deliveryDocket);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating DeliveryDocket record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async createDeliveryDocketPromise(deliveryDocket: DeliveryDocket) {
    return new Promise<DeliveryDocket>(resolve => {
      this.createDeliveryDocket(deliveryDocket).subscribe(deliveryDocket => {
        resolve(deliveryDocket);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating DeliveryDocket record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async removeDeliveryDocketPromise(deliveryDocketId: string) {
    return new Promise<boolean>(resolve => {
      this.http.delete(this._baseUrl + 'api/deliverydocket/RemoveDeliveryDocket/' + deliveryDocketId).subscribe(deliveryDocket => {
        resolve(true);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error removing DeliveryDocket record", "Close", "center", "bottom", 4000, true, err.error);
        resolve(null);
      });
    });
  }

  async saveSignaturePromise(deliveryDocketId: string, signatureDataURL: string) {

    var signature = this.sharedService.dataURLToBlob(signatureDataURL);

    let arrBuffer: ArrayBuffer;

    await signature.arrayBuffer().then(arrB => {
      arrBuffer = arrB;
      //console.log("arrBuffer: ", arrBuffer);
    });


    return new Promise<boolean>(resolve => {
      this.saveSignature(deliveryDocketId, arrBuffer).subscribe(deliveryDocket => {
        resolve(true);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error saving signature for DeliveryDocket record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(false);
      });
    })
  }

  //mapDeliveryDocketFromDTO(boardTallyRecordHistory: dtoStockItem, excludeProperties: Array<string>): DeliveryDocket {
  //  var deliveryDocket: DeliveryDocket = new DeliveryDocket();

  //  for (let [key, value] of Object.entries(boardTallyRecordHistory)) {
  //    if (excludeProperties.indexOf(key) == -1) {
  //      console.log("Property " + key + ",  val: " + value);

  //      deliveryDocket[key] = value;

  //      if (value == this.sharedService.EmptyGuid) {
  //        deliveryDocket[key] = null;
  //      }

  //      if (value == this.sharedService.MinDate) {
  //        console.log("date min value");
  //        deliveryDocket[key] = null;
  //      }

  //    }
  //  }
  //  return deliveryDocket;
  //}


}  
