import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoCustomerDeliveryAddress } from '../business-objects/dto-customer-delivery-address.bo';

@Injectable()
export class dtoCustomerDeliveryAddressService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoCustomerDeliveryAddress(dtoCustomerDeliveryAddressId: string) {
		return this.http.get<dtoCustomerDeliveryAddress>(this._baseUrl + 'api/dtoCustomerDeliveryAddress/' + dtoCustomerDeliveryAddressId);
	}

	getdtoCustomerDeliveryAddressParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoCustomerDeliveryAddress[]>(this._baseUrl + 'api/dtoCustomerDeliveryAddress/GetdtoCustomerDeliveryAddressParamArray/', aParam);
	}

	getAlldtoCustomerDeliveryAddresss() {
		return this.http.get<dtoCustomerDeliveryAddress[]>(this._baseUrl + 'api/dtoCustomerDeliveryAddress/GetAlldtoCustomerDeliveryAddress/');
	}

	//getAlldtodtoCustomerDeliveryAddresss() {
	//	return this.http.get<dtodtoCustomerDeliveryAddress[]>(this._baseUrl + 'api/dtoCustomerDeliveryAddress/GetAlldtoCustomerDeliveryAddress/');
	//}

	createdtoCustomerDeliveryAddress(dtoCustomerDeliveryAddress: dtoCustomerDeliveryAddress) {
		return this.http.post<dtoCustomerDeliveryAddress>(this._baseUrl + 'api/dtoCustomerDeliveryAddress/CreatedtoCustomerDeliveryAddress/', dtoCustomerDeliveryAddress);
	}

	updatedtoCustomerDeliveryAddress(dtoCustomerDeliveryAddress: dtoCustomerDeliveryAddress) {
		return this.http.put<dtoCustomerDeliveryAddress>(this._baseUrl + 'api/dtoCustomerDeliveryAddress/UpdatedtoCustomerDeliveryAddress/', dtoCustomerDeliveryAddress);
	}

	async getdtoCustomerDeliveryAddressPromise(dtoCustomerDeliveryAddressId: string) {
		return new Promise<dtoCustomerDeliveryAddress>(resolve => {
			this.getdtoCustomerDeliveryAddress(dtoCustomerDeliveryAddressId).subscribe(dtoCustomerDeliveryAddress => {
				resolve(dtoCustomerDeliveryAddress);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoCustomerDeliveryAddress records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoDeliveryDocketOrderItemAddresses(deliveryDocketId: string) {
    return new Promise<dtoCustomerDeliveryAddress[]>(resolve => {
      this.http.get<dtoCustomerDeliveryAddress[]>(this._baseUrl + 'api/dtoCustomerDeliveryAddress/GetDeliveryDocketOrderItemAddresses/' + deliveryDocketId).subscribe(dtoCustomerDeliveryAddress => {
        resolve(dtoCustomerDeliveryAddress);
      }, err => {
        this.alertService.openSnackBarError("Error getting DeliveryDocketOrderItemAddresses dtoCustomerDeliveryAddress records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getdtoCustomerDeliveryAddressParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoCustomerDeliveryAddress[]>(resolve => {
			this.getdtoCustomerDeliveryAddressParamArray(aParam).subscribe(dtoCustomerDeliveryAddress => {
				resolve(dtoCustomerDeliveryAddress);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoCustomerDeliveryAddress records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoCustomerDeliveryAddressByCustomerId(customerId: string, includeInactive: boolean = false) {

    if (customerId == null || customerId == "") {
      this.alertService.openSnackBarDefault("There is no customer ID supplied");
      return;
    }


    return new Promise<dtoCustomerDeliveryAddress[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("guClientId", customerId));
      aParamList.push(aParam);

      if (includeInactive == false) {
        aParam = [];
        aParam.push(new SQLParamArray("blnActive", "1"));
        aParamList.push(aParam);
      }

      this.getdtoCustomerDeliveryAddressParamArray(aParamList).subscribe(
        {
          next: dtoCustomerDeliveryAddress => {
            resolve(dtoCustomerDeliveryAddress);
          },
          error: (err: HttpErrorResponse) => {
            this.alertService.openSnackBarError("Error getting dtoCustomerDeliveryAddress records", "Close", "center", "bottom", 4000, true, err.message);
            resolve(null);
          },
        });
    });
  }

	async updatedtoCustomerDeliveryAddressPromise(dtoCustomerDeliveryAddress: dtoCustomerDeliveryAddress) {
		return new Promise<dtoCustomerDeliveryAddress>(resolve => {
			this.updatedtoCustomerDeliveryAddress(dtoCustomerDeliveryAddress).subscribe(dtoCustomerDeliveryAddress => {
				resolve(dtoCustomerDeliveryAddress);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoCustomerDeliveryAddress record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoCustomerDeliveryAddressPromise(dtoCustomerDeliveryAddress: dtoCustomerDeliveryAddress) {
		return new Promise<dtoCustomerDeliveryAddress>(resolve => {
			this.createdtoCustomerDeliveryAddress(dtoCustomerDeliveryAddress).subscribe(dtoCustomerDeliveryAddress => {
				resolve(dtoCustomerDeliveryAddress);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoCustomerDeliveryAddress record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}
