import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Client } from '../../_shared/business-objects/client.bo';
import { dtoCustomer } from '../../_shared/business-objects/dto-customer.bo';
import { dtoIntKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { ElectricalAuthoritySpec } from '../../_shared/business-objects/electrical-authority-spec.bo';
import { Orders } from '../../_shared/business-objects/orders.bo';
import { PricingCategory } from '../../_shared/business-objects/pricing-category.bo';
import { AddressService } from '../../_shared/services/address';
import { AlertService } from '../../_shared/services/alert.service';
import { ClientService } from '../../_shared/services/client.service';
import { ElectricalAuthoritySpecService } from '../../_shared/services/electrical-authority-spec.service';
import { FormService } from '../../_shared/services/form.service';
import { PricingCategoryService } from '../../_shared/services/pricing-category.service';
import { SharedService } from '../../_shared/services/shared.service';
import { dtoCustomerService } from '../../_shared/services/dto-customer.service';

@Component({
  selector: 'app-customer-update',
  templateUrl: './customer-update.component.html',
  styleUrl: './customer-update.component.scss'
})
export class CustomerUpdateComponent {

  @Input() customer: dtoCustomer;
  @Output() customerUpdated = new EventEmitter<{ customer: dtoCustomer, type: string }>();
  @Output() customerCancelled = new EventEmitter<boolean>();

  form: FormGroup;

  states: dtoIntKeyValue[] = [];
  sectorTypes: dtoIntKeyValue[] = [];
  postcodes: Object[];
  pricingCategorys: PricingCategory[];
  usualLoadingLocations: dtoIntKeyValue[] = [];
  terms: dtoIntKeyValue[] = [];
  paymentTypes: dtoIntKeyValue[] = [];
  invoiceTransmissions: dtoIntKeyValue[] = [];
  electricalAuthoritySpecs: ElectricalAuthoritySpec[] = [];
  webAppCustomActions: dtoIntKeyValue[] = [];



  customerComments: string = "";

  showSpinner: boolean

  constructor(private formBuilder: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private customerService: ClientService, private dtoCustomerService: dtoCustomerService, private formService: FormService, private addressService: AddressService
    , private electricalAuthoritySpecService: ElectricalAuthoritySpecService, private pricingCategoryService: PricingCategoryService) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      txtCustomerName: ['', Validators.required],
      intSectorType: [0],
      guPricingCategoryId: [''],
      blnActive: [1],
      txtLegalBusinessName: [''],
      txtOldTradingName: [''],
      blnCombineOldTradingName: [''],
      blnIsABusiness: [''],
      blnKeyCustomer: [''],
      txtComments: [''],
      blnOrders: [1],
      blnBoardPlant: [1],
      blnJoinery: [1],
      blnToolHandles: [1],
      blnParquetry: [1],
      blnMill: [1],
      blnTreatment: [1],
      blnDesap: [1],
      blnPrivateProperty: [''],
      blnForestry: [''],
      txtABN: [''],
      txtAddress: [''],
      txtTown: [''],
      txtState: [['NSW']],
      txtPostcode: [''],
      txtPhone1: [''],
      txtPhone2: [''],
      txtMobile: [''],
      txtFax: [''],
      txtEmail: [''],
      intUsualLoadingLocation: [[-1]],
      fltAuctionFee: [''],
      intTerms: [[1]],
      intPaymentType: [[2]],
      intInvoiceTransmission: [[1]],
      guElectricalAuthoritySpecId: [[this.sharedService.EmptyGuid]],
      blnInternalCustomer: [''],
      blnSystemCustomer: [''],
      blnAddDeliveryChargeToDocket: [''],
      intWebAppCustomActions: [[0]]
    });


    this.showSpinner = false;

    this.sectorTypes = this.sharedService.getEnumObjectKeyValue(Client.enSectorType, true);
    this.states = this.sharedService.getEnumObjectKeyValue(SharedService.enStates);
    this.usualLoadingLocations = this.sharedService.getEnumObjectKeyValue(SharedService.enYardSection, true);
    this.usualLoadingLocations.sort((a, b) => { return a.txtValue > b.txtValue ? 1 : -1 });
    this.usualLoadingLocations.splice(0, 0, new dtoIntKeyValue(-1, "None"));

    this.terms = this.sharedService.getEnumObjectKeyValue(Client.enTerms, true);
    this.paymentTypes = this.sharedService.getEnumObjectKeyValue(Orders.enPaymentType, true);
    this.invoiceTransmissions = this.sharedService.getEnumObjectKeyValue(Orders.enInvoiceTransmission, true);
    this.webAppCustomActions = this.sharedService.getEnumObjectKeyValue(Client.enWebAppCustomActions, true);

    this.loadData();

    this.form.controls['intSectorType'].setValue([0]);

    this.formService.setAutoFocusTimeout("txtCustomerName", 300);



    this.loadCustomerData();


  }

  async loadCustomerData() {

    //let aParamList: SQLParamArray[][] = [];
    //let aParam: SQLParamArray[] = [];
    //aParam.push(new SQLParamArray("txtName", "Martin McCarthy"));
    //aParamList.push(aParam);


  }


  async loadData() {

    this.pricingCategorys = await this.pricingCategoryService.getAllPricingCategoryPromise();

    this.electricalAuthoritySpecs = await this.electricalAuthoritySpecService.getAllElectricalAuthoritySpecPromise();
    this.electricalAuthoritySpecs.sort((a, b) => { return a.txtAuthorityName > b.txtAuthorityName ? 1 : -1 });

    let elecAuthSpec: ElectricalAuthoritySpec = new ElectricalAuthoritySpec();
    elecAuthSpec.rowguid = this.sharedService.EmptyGuid;
    elecAuthSpec.txtAuthorityName = "None";
    this.electricalAuthoritySpecs.splice(0, 0, elecAuthSpec);


    this.form.controls['txtCustomerName'].setValue(this.customer.txtName);
    this.form.controls['intSectorType'].setValue([this.customer.intSectorType]);
    this.form.controls['guPricingCategoryId'].setValue([this.customer.guPricingCategoryId]);
    this.form.controls['blnActive'].setValue(this.customer.blnActive);
    this.form.controls['txtLegalBusinessName'].setValue(this.customer.txtBusinessName);
    this.form.controls['txtOldTradingName'].setValue(this.customer.txtOldTradingName);
    this.form.controls['blnCombineOldTradingName'].setValue(this.customer.blnCombineOldTradingName);
    this.form.controls['blnIsABusiness'].setValue(this.customer.blnIsABusiness);
    this.form.controls['blnKeyCustomer'].setValue(this.customer.blnKeyCustomer);
    this.form.controls['txtComments'].setValue(this.customer.memComments);
    this.form.controls['blnOrders'].setValue(this.customer.blnOrders);
    this.form.controls['blnBoardPlant'].setValue(this.customer.blnBoardPlant);
    //this.form.controls['blnJoinery'].setValue(this.customer.blnJoinery);
    this.form.controls['blnToolHandles'].setValue(this.customer.blnToolHandles);
    this.form.controls['blnParquetry'].setValue(this.customer.blnParquetry);
    this.form.controls['blnMill'].setValue(this.customer.blnMill);
    this.form.controls['blnTreatment'].setValue(this.customer.blnTreatmentPlant);
    this.form.controls['blnDesap'].setValue(this.customer.blnDesap);
    this.form.controls['blnPrivateProperty'].setValue(this.customer.blnPrivateProperty);
    this.form.controls['blnForestry'].setValue(this.customer.blnForestry);
    this.form.controls['txtABN'].setValue(this.customer.txtABN);
    this.form.controls['txtAddress'].setValue(this.customer.txtAddress1);
    this.form.controls['txtTown'].setValue(this.customer.txtTown);
    this.form.controls['txtState'].setValue([this.customer.txtState]);
    this.form.controls['txtPostcode'].setValue(this.customer.txtPostcode);
    this.form.controls['txtPhone1'].setValue(this.customer.txtPhone1);
    this.form.controls['txtPhone2'].setValue(this.customer.txtPhone2);
    this.form.controls['txtMobile'].setValue(this.customer.txtMobile);
    this.form.controls['txtFax'].setValue(this.customer.txtFax);
    this.form.controls['txtEmail'].setValue(this.customer.txtEmail);
    this.form.controls['intUsualLoadingLocation'].setValue([this.customer.intUsualLoadingLocation]);
    this.form.controls['fltAuctionFee'].setValue(this.customer.fltAuctionFee);
    this.form.controls['intTerms'].setValue([this.customer.intTerms]);
    this.form.controls['intPaymentType'].setValue([this.customer.intPaymentType]);
    this.form.controls['intInvoiceTransmission'].setValue([this.customer.intInvoiceTransmission]);
    this.form.controls['guElectricalAuthoritySpecId'].setValue([this.sharedService.cEmptyGuid(this.customer.guElectricalAuthoritySpecId)]);
    this.form.controls['blnInternalCustomer'].setValue(this.customer.blnInternalCustomer);
    this.form.controls['blnSystemCustomer'].setValue(this.customer.blnSystemCustomer);
    this.form.controls['blnAddDeliveryChargeToDocket'].setValue(this.customer.blnAddDeliveryChargeToDocket);
    this.form.controls['intWebAppCustomActions'].setValue([this.customer.intWebAppCustomActions]);


  }


  async lookupPostcode(town: string, state: string) {

    let postCodes: Object[];
    let postCodePromise = this.addressService.getPostCodePromise(town, state);

    await postCodePromise.then(res => postCodes = res);

    if (postCodes != null && Array.isArray(postCodes) && postCodes.length > 0) {

      if (postCodes.length > 1) {
        this.postcodes = postCodes;
        return;
      }

      //console.log("postcode: ", postCodes);

      if (this.form.controls['txtPostcode'].value != null && this.form.controls['txtPostcode'].value != "") {
        let msg: string = await this.alertService.openSnackBarCustomPromise("Existing Postcode", "There is an existing Postcode in the postcode field.\nDo you want to overwrite it?", "Yes", "No", "center", "bottom", "", 0, true);

        if (msg != "Yes") {
          return;
        }
      }

      this.form.controls['txtPostcode'].setValue(postCodes[0]['postcode']);

    }

  }

  selectPostcode(postcode: string) {

    if (postcode == "") {
      return;
    }

    this.form.controls['txtPostcode'].setValue(postcode);

    this.postcodes = null;

  }


  async updateCustomer(type: string) {

    let txtCustomerName: string = this.form.controls['txtCustomerName'].value;
    let intSectorType: number = Number(this.form.controls['intSectorType'].value);
    let guPricingCategoryId: string = String(this.form.controls['guPricingCategoryId'].value[0]);
    let blnActive: boolean = this.form.controls['blnActive'].value == true;
    let txtLegalBusinessName: string = this.form.controls['txtLegalBusinessName'].value;
    let txtOldTradingName: string = this.form.controls['txtOldTradingName'].value;
    let blnCombineOldTradingName: boolean = this.form.controls['blnCombineOldTradingName'].value == true;
    let blnIsABusiness: boolean = this.form.controls['blnIsABusiness'].value == true;
    let blnKeyCustomer: boolean = this.form.controls['blnKeyCustomer'].value == true;
    let txtComments: string = this.form.controls['txtComments'].value;
    let blnOrders: boolean = this.form.controls['blnOrders'].value == true;
    let blnBoardPlant: boolean = this.form.controls['blnBoardPlant'].value == true;
    let blnJoinery: boolean = this.form.controls['blnJoinery'].value == true;
    let blnToolHandles: boolean = this.form.controls['blnToolHandles'].value == true;
    let blnParquetry: boolean = this.form.controls['blnParquetry'].value == true;
    let blnMill: boolean = this.form.controls['blnMill'].value == true;
    let blnTreatment: boolean = this.form.controls['blnTreatment'].value == true;
    let blnDesap: boolean = this.form.controls['blnDesap'].value == true;
    let blnPrivateProperty: boolean = this.form.controls['blnPrivateProperty'].value == true;
    let blnForestry: boolean = this.form.controls['blnForestry'].value == true;
    let txtABN: string = this.form.controls['txtABN'].value;
    let txtAddress: string = this.form.controls['txtAddress'].value;
    let txtTown: string = this.form.controls['txtTown'].value;
    let txtState: string = this.form.controls['txtState'].value[0];
    let txtPostcode: string = this.form.controls['txtPostcode'].value;
    let txtPhone1: string = this.form.controls['txtPhone1'].value;
    let txtPhone2: string = this.form.controls['txtPhone2'].value;
    let txtMobile: string = this.form.controls['txtMobile'].value;
    let txtFax: string = this.form.controls['txtFax'].value;
    let txtEmail: string = this.form.controls['txtEmail'].value;
    let intUsualLoadingLocation: number = Number(this.form.controls['intUsualLoadingLocation'].value);
    let fltAuctionFee: number = Number(this.form.controls['fltAuctionFee'].value);
    let intTerms: number = Number(this.form.controls['intTerms'].value);
    let intPaymentType: number = Number(this.form.controls['intPaymentType'].value);
    let intInvoiceTransmission: number = Number(this.form.controls['intInvoiceTransmission'].value);
    let guElectricalAuthoritySpecId: string = this.sharedService.cNullGuid(this.form.controls['guElectricalAuthoritySpecId'].value[0]);
    let blnInternalCustomer: boolean = this.form.controls['blnInternalCustomer'].value == true;
    let blnSystemCustomer: boolean = this.form.controls['blnSystemCustomer'].value == true;
    let blnAddDeliveryChargeToDocket: boolean = this.form.controls['blnAddDeliveryChargeToDocket'].value == true;
    let intWebAppCustomActions: number = Number(this.form.controls['intWebAppCustomActions'].value[0]);

    if (txtCustomerName == null || txtCustomerName == "") {
      this.alertService.openSnackBar("Please enter a customer name.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (intSectorType == null || intSectorType == 0) {
      await this.alertService.openSnackBarCustomDefaultPromise("Sector Type", "Please select a Sector Type.");
      return;
    }

    if (guPricingCategoryId == null || guPricingCategoryId == "") {
      await this.alertService.openSnackBarCustomDefaultPromise("Pricing Category", "Please select a Pricing Category.");
      return;
    }


    console.log("intSectorType", intSectorType);
    console.log("guPricingCategoryId", guPricingCategoryId);

    // Testing only --  let customer: Client = await this.customerService.getClientPromise("F392E3E8-0083-4C0C-9B31-7438F473A203");

    
    let customer: Client = await this.customerService.getClientPromise(this.customer.rowguid);

    customer.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
    customer.txtName = txtCustomerName;
    customer.intSectorType = intSectorType;
    customer.guPricingCategoryId = guPricingCategoryId;
    customer.blnActive = blnActive;
    customer.txtBusinessName = txtLegalBusinessName;
    customer.txtOldTradingName = txtOldTradingName;
    customer.blnCombineOldTradingName = blnCombineOldTradingName;
    customer.blnIsABusiness = blnIsABusiness;
    customer.blnKeyCustomer = blnKeyCustomer;
    customer.memComments = txtComments;
    customer.blnOrders = blnOrders;
    customer.blnBoardPlant = blnBoardPlant;
    //customer.blnJoinery = blnJoinery;
    customer.blnToolHandles = blnToolHandles;
    customer.blnParquetry = blnParquetry;
    customer.blnMill = blnMill;
    customer.blnTreatmentPlant = blnTreatment;
    customer.blnDesap = blnDesap;
    customer.blnPrivateProperty = blnPrivateProperty;
    customer.blnForestry = blnForestry;
    customer.txtABN = txtABN;
    customer.txtAddress1 = txtAddress;
    customer.txtTown = txtTown;
    customer.txtState = txtState;
    customer.txtPostcode = String(txtPostcode);
    customer.txtPhone1 = txtPhone1;
    customer.txtPhone2 = txtPhone2;
    customer.txtMobile = txtMobile;
    customer.txtFax = txtFax;
    customer.txtEmail = txtEmail;
    customer.intUsualLoadingLocation = intUsualLoadingLocation;
    customer.fltAuctionFee = fltAuctionFee;
    customer.intTerms = intTerms;
    customer.intPaymentType = intPaymentType;
    customer.intInvoiceTransmission = intInvoiceTransmission;
    customer.guElectricalAuthoritySpecId = guElectricalAuthoritySpecId == "" ? null : guElectricalAuthoritySpecId;
    customer.blnInternalCustomer = blnInternalCustomer;
    customer.blnSystemCustomer = blnSystemCustomer;
    customer.blnAddDeliveryChargeToDocket = blnAddDeliveryChargeToDocket;
    customer.intWebAppCustomActions = intWebAppCustomActions

    //Surplus properties
    customer.blnAuthorityCustomer = (customer.blnAuthorityCustomer == true);
    customer['originalRowguid'] = customer.OriginalRowguid;
    customer.OriginalRowguid = customer.OriginalRowguid;
    customer.blnContractCustomer = (customer.blnContractCustomer == true);
    customer.blnDuplicate = (customer.blnDuplicate == true);
    if (customer.txtOrderNo == null) {
      customer.txtOrderNo = "";
    } else {
      customer.txtOrderNo = String(customer.txtOrderNo);
    }
    customer.txtUpdateDataComments = "";


    this.showSpinner = true;

    console.log(customer);



    //let existingCustomer: dtoCustomer = await this.dtoCustomerService.getdtoCustomerByName(customer.txtName);
    //if (existingCustomer != null) {

    //  this.showSpinner = false;

    //  let text: string = "A customer already exists with that name and the following details\n";
    //  text += existingCustomer.txtName + "\n";
    //  text += existingCustomer.txtAddress1 + (String(existingCustomer.txtAddress1) != "" ? "\n" : "");
    //  text += existingCustomer.txtTown + " " + existingCustomer.txtState + " " + existingCustomer.txtPostcode;
    //  text += (String(existingCustomer.txtPhone1) != "" ? "\n" : "") + existingCustomer.txtPhone1;
    //  text += (String(existingCustomer.txtMobile) != "" ? "\n" : "") + existingCustomer.txtMobile;

    //  let msg: string = await this.alertService.openSnackBarCustomDefaultPromise("Existing Customer", text);


    //  return;
    //}

    customer = await this.customerService.updateClientPromise(customer);
    // TEsting only  --   customer = await this.customerService.updateClientPromise(customer);

    if (customer != null) {
      console.log(customer.rowguid);
      //customer = await this.customerService.getClientPromise(customer.rowguid);

      let dtoCustomer: dtoCustomer = await this.dtoCustomerService.getdtoCustomerPromise(customer.rowguid);


      this.alertService.openSnackBar("The customer has been updated", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;

      this.customerUpdated.emit({ customer: dtoCustomer, type: type });
      //this.customerCreated.emit({ customer: null, type: type });

    }


    if (customer == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem creating the customer", "Close", "center", "bottom", 4000, true, "There was a problem creating the customer contact");
    }

  }

  cancelCustomer() {
    this.customerCancelled.emit(true);

  }


  setPricingCategory(sectorType: number) {

    console.log("sectorType: ", sectorType);

    if (sectorType == Client.enSectorType.None) {
      return;
    }

    let pricingCatName = "";
    if (sectorType == Client.enSectorType.Wholesale) {
      pricingCatName = "Wholesale - Ex Yard";
    }

    if (sectorType == Client.enSectorType.Merchant) {
      pricingCatName = "Merchant";
    }

    if (sectorType == Client.enSectorType.Trade) {
      pricingCatName = "Trade";
    }

    if (sectorType == Client.enSectorType.Retail) {
      pricingCatName = "Retail";
    }

    if (pricingCatName != "") {
      for (let i = 0; i <= this.pricingCategorys.length - 1; i++) {
        console.log("this.pricingCategorys[i].txtName: ", this.pricingCategorys[i].txtName.toLocaleLowerCase());
        if (this.pricingCategorys[i].txtName.toLocaleLowerCase().indexOf(pricingCatName.toLocaleLowerCase()) > -1) {
          this.form.controls['guPricingCategoryId'].setValue([this.pricingCategorys[i].rowguid]);
          break;
        }
      }
    }

  }


}
