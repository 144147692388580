import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeoStockLocation } from '../business-objects/geo-stock-location.bo';
import { SQLParamArray } from '../business-objects/sql-param-array';

@Injectable()
export class GeoStockLocationService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		this._baseUrl = baseUrl;
	}

	getGeoStockLocation(geoStockLocationId: string) {
		return this.http.get<GeoStockLocation>(this._baseUrl + 'api/GeoStockLocation/' + geoStockLocationId);
	}

	getAllGeoStockLocations() {
		return this.http.get<GeoStockLocation[]>(this._baseUrl + 'api/GeoStockLocation/GetAllGeoStockLocations/');
	}

  getGeoStockLocationParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<GeoStockLocation[]>(this._baseUrl + 'api/GeoStockLocation/GetGeoStockLocationParamArray/', aParam);
  }

	//getAlldtoGeoStockLocations() {
	//	return this.http.get<dtoGeoStockLocation[]>(this._baseUrl + 'api/GeoStockLocation/GetAllGeoStockLocation/');
	//}

	createGeoStockLocation(geoStockLocation: GeoStockLocation) {
		return this.http.post<GeoStockLocation>(this._baseUrl + 'api/GeoStockLocation/CreateGeoStockLocation/', geoStockLocation);
	}

	updateGeoStockLocation(geoStockLocation: GeoStockLocation) {
		return this.http.post<GeoStockLocation>(this._baseUrl + 'api/GeoStockLocation/UpdateGeoStockLocation/', geoStockLocation);
  }

  getRemainingAppUserGeoStockLocations(appUserId: string) {
    return this.http.get<GeoStockLocation[]>(this._baseUrl + 'api/GeoStockLocation/GetRemainingAppUserGeoStockLocations/' + appUserId);
  }

  getMyGeoStockLocations(appUserId: string) {
    return this.http.get<GeoStockLocation[]>(this._baseUrl + 'api/GeoStockLocation/GetMyGeoStockLocations/' + appUserId);
  }

  getMyDefaultGeoStockLocation(appUserId: string) {
    return this.http.get<GeoStockLocation>(this._baseUrl + 'api/GeoStockLocation/GetMyDefaultGeoStockLocation/' + appUserId);
  }


  async getGeoStockLocationsPromise(aParam: SQLParamArray[][]) {

    return new Promise<GeoStockLocation[]>(resolve => {
      this.getGeoStockLocationParamArray(aParam).subscribe(stockLocations => {

        resolve(stockLocations);

      }, err => {
        console.log(err);
      });
    });


  }

  async getMyDefaultGeoLocation(appUserId: string) {

    return new Promise<GeoStockLocation>(resolve => {
      this.getMyDefaultGeoStockLocation(appUserId).subscribe(stockLocation => {

        resolve(stockLocation);

      }, err => {
        console.log(err);
      });
    });


  }


  async getMyGeoStockLocationsPromise(appUserId: string) {
    return new Promise<GeoStockLocation[]>(resolve => {
      this.getMyGeoStockLocations(appUserId).subscribe(myLocations => {

        resolve(myLocations);

      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

}
