<div class="barcode-textbox"><input matInput id="barcodeTextbox" #barcodeTextbox (input)="setData(barcodeTextbox)" /></div>
<div class="barcode-container">
  <app-code128auto-barcode class="barcode-app" [barcodeId]="'barcodeId1'" #barcodeId1 [barcodeText]="data" [height]="50" [lineWidth]="lineSize" [fontSize]="fontSize" [displayValue]="true" *ngIf="barcode1"></app-code128auto-barcode>
  <app-code128auto-barcode class="barcode-app" [barcodeId]="'barcodeId2'" #barcodeId2 [barcodeText]="data" [height]="50" [lineWidth]="lineSize" [fontSize]="fontSize" [displayValue]="true" *ngIf="barcode2"></app-code128auto-barcode>
  <app-code128auto-barcode class="barcode-app" [barcodeId]="'barcodeId3'" #barcodeId3 [barcodeText]="data" [height]="50" [lineWidth]="lineSize" [fontSize]="fontSize" [displayValue]="true" *ngIf="barcode3"></app-code128auto-barcode>
  <app-code128auto-barcode class="barcode-app" [barcodeId]="'barcodeId4'" #barcodeId4 [barcodeText]="data" [height]="50" [lineWidth]="lineSize" [fontSize]="fontSize" [displayValue]="true" *ngIf="barcode4"></app-code128auto-barcode>
  <div class="button-container">
    <button class="print-button" type="button" mat-flat-button color="primary" (click)="print()" *ngIf="showButton">Print</button>
  </div>
</div>
