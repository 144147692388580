import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { Code128autoBarcodeComponent } from '../../../utilities/barcode/code128auto-barcode/code128auto-barcode.component';
import { AlertService } from '../../../_shared/services/alert.service';

@Component({
  selector: 'app-barcode-sticker',
  templateUrl: './barcode-sticker.component.html',
  styleUrls: ['./barcode-sticker.component.css']
})
export class BarcodeStickerComponent implements OnInit {

  @Input() data: string;
  @ViewChild('barcodeId1') barcodeId1: Code128autoBarcodeComponent;
  @ViewChild('barcodeId2') barcodeId2: Code128autoBarcodeComponent;
  @ViewChild('barcodeId3') barcodeId3: Code128autoBarcodeComponent;
  @ViewChild('barcodeId4') barcodeId4: Code128autoBarcodeComponent;

  lineSize: number = 2;
  fontSize: number = 24;

  barcode1: boolean;
  barcode2: boolean;
  barcode3: boolean;
  barcode4: boolean;
  showButton: boolean;

  constructor(private route: ActivatedRoute, private alertService: AlertService) { }

  ngOnInit(): void {

    this.barcode1 = true;
    this.barcode2 = true;
    this.barcode3 = true;
    this.barcode4 = true;
    this.showButton = true;

    this.route.queryParams.subscribe(async params => {
      
      if (params != null) {
        this.data = params["data"] == null ? "" : params["data"];
        this.barcode1 = params["barcode1"] == null ? true : params["barcode1"] == "true";
        this.barcode2 = params["barcode2"] == null ? true : params["barcode2"] == "true";
        this.barcode3 = params["barcode3"] == null ? true : params["barcode3"] == "true";
        this.barcode4 = params["barcode4"] == null ? true : params["barcode4"] == "true";
        console.log("data.length: ", this.data.length);
        if (this.data.length > 22) {
          this.lineSize = 2;
        }
        if (this.data != null && this.data != "" && params["print"] != "No") {
          
          this.print();
        }

      }

    });

  }

  async print() {

    console.log("barcode", this.data);

    if (this.data == null) {
      this.alertService.openSnackBarDefault("There is no data for the barcode");
      return;
    }



    let promptuser = window.print
    window.print();
    return;


  }

  setData(val: MatInput) {
    this.barcode1 = true;
    this.barcode2 = false;
    this.barcode3 = false;
    this.barcode4 = false;

    if (val.value != null && val.value != "") {
      this.data = val.value;
      this.barcodeId1.setBarcodeText(val.value);
      this.showButton = true;
      console.log(val.value);
    }

    if (val.value == null || val.value == "") {
      this.barcode1 = false;
      this.showButton = false;
      console.log("setting barcode 1 to false");
    }

  }

}
