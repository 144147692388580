import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductCode } from '../business-objects/product-code.bo';
import { ProductType } from '../business-objects/product-type.bo';
import { ProductProfile } from '../business-objects/product-profile.bo';
import { dtoKeyValue } from '../business-objects/dto-int-key-value.bo';
import { Species } from '../business-objects/species.bo';
import { BoardGrade } from '../business-objects/board-grade.bo';
import { dtoProductCodes } from '../business-objects/dto-product-codes.bo';
import { OrderItem } from '../business-objects/order-item.bo';
import { DatePipe } from '@angular/common';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { dtoProductCode } from '../business-objects/dto-product-code.bo';

@Injectable()
export class ProductCodeService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getProductCodeByRowguid(gProductCodeId: string) {
    console.log("getProductCodeByRowguid: " + gProductCodeId);
    return this.http.get<dtoProductCodes>(this._baseUrl + 'api/ProductCode/' + gProductCodeId);
  }

  getProductCodesParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoProductCodes[]>(this._baseUrl + 'api/ProductCode/GetProductCodesParamArray/', aParam);
  }

  getGetProductCodesByCategory(gProductTypeCategoryId: string) {
    return this.http.get<dtoProductCodes[]>(this._baseUrl + 'api/ProductCode/GetProductCodesByCategory/' + gProductTypeCategoryId);
  }

  getGetProductCodes(gProductTypeCategoryId: string, gProductTypeId: string, gProductProfileId: string, iLogSection: number, iWidth: number, iThickness: number, gSpeciesId: string, gDurabilityClassId: string, gBoardGradeId: string, iStructuralGrade: number, iUnitOfMeasure: number, iLength: number, iTreatmentType: number, iTreatmentHazardLevel: number, gPricingCategoryId: string) {
    return this.http.get<dtoProductCodes[]>(this._baseUrl + 'api/ProductCode/GetProductCodes/' + gProductTypeCategoryId + '/' + gProductTypeId + '/' + iLogSection + '/' + iWidth + '/' + iThickness + '/' + gSpeciesId + '/' + gDurabilityClassId + '/' + gBoardGradeId + '/' + iStructuralGrade + '/' + iUnitOfMeasure + '/' + encodeURIComponent(iLength) + '/' + iTreatmentType + '/' + iTreatmentHazardLevel + '/' + gPricingCategoryId + '/' + gProductProfileId);
  }

  getProductCodeBySKU(sku: string, gPricingCategoryId: string) {
    return this.http.get<dtoProductCodes[]>(this._baseUrl + 'api/ProductCode/GetProductCodeBySKU/' + sku + '/' + gPricingCategoryId);
  }

  getGetProductCodeLogSections(gProductTypeCategoryId: string, gProductTypeId: string, gProductProfileId: string) {
    return this.http.get<dtoKeyValue[]>(this._baseUrl + 'api/ProductCode/GetProductCodeLogSections/' + gProductTypeCategoryId + '/' + gProductTypeId + '/' + gProductProfileId);
  }

  getGetProductCodeSizes(gProductTypeCategoryId: string, gProductTypeId: string, gProductProfileId: string, iLogSection: number) {
    return this.http.get<dtoKeyValue[]>(this._baseUrl + 'api/ProductCode/GetProductCodeSizes/' + gProductTypeCategoryId + '/' + gProductTypeId + '/' + iLogSection + '/' + gProductProfileId);
  }



  getGetProductCodeStructuralGrade(gProductTypeCategoryId: string, gProductTypeId: string, gProductProfileId: string, iLogSection: number, iWidth: number, iThickness: number, gSpeciesId: string, gDurabilityClassId: string, gBoardGradeId: string) {
    return this.http.get<dtoKeyValue[]>(this._baseUrl + 'api/ProductCode/GetProductCodeStructuralGrade/' + gProductTypeCategoryId + '/' + gProductTypeId + '/' + iLogSection + '/' + iWidth + '/' + iThickness + '/' + gSpeciesId + '/' + gDurabilityClassId + '/' + gBoardGradeId + '/' + gProductProfileId);
  }

  getGetProductCodeDefaultUOM(gProductTypeCategoryId: string, gProductTypeId: string, gProductProfileId: string, iLogSection: number, iWidth: number, iThickness: number, gSpeciesId: string, gDurabilityClassId: string, gBoardGradeId: string, iStructuralGrade: number) {
    return this.http.get<dtoKeyValue[]>(this._baseUrl + 'api/ProductCode/GetProductCodeDefaultUOM/' + gProductTypeCategoryId + '/' + gProductTypeId + '/' + iLogSection + '/' + iWidth + '/' + iThickness + '/' + gSpeciesId + '/' + gDurabilityClassId + '/' + gBoardGradeId + '/' + iStructuralGrade + '/' + gProductProfileId);
  }

  getGetProductCodeLengths(gProductTypeCategoryId: string, gProductTypeId: string, gProductProfileId: string, iLogSection: number, iWidth: number, iThickness: number, gSpeciesId: string, gDurabilityClassId: string, gBoardGradeId: string, iStructuralGrade: number) {
    return this.http.get<dtoKeyValue[]>(this._baseUrl + 'api/ProductCode/GetProductCodeLengths/' + gProductTypeCategoryId + '/' + gProductTypeId + '/' + iLogSection + '/' + iWidth + '/' + iThickness + '/' + gSpeciesId + '/' + gDurabilityClassId + '/' + gBoardGradeId + '/' + iStructuralGrade + '/' + gProductProfileId);
  }

  getGetProductCodeTreatmentTypes(gProductTypeCategoryId: string, gProductTypeId: string, gProductProfileId: string, iLogSection: number, iWidth: number, iThickness: number, gSpeciesId: string, gDurabilityClassId: string, gBoardGradeId: string, iStructuralGrade: number, iUnitOfMeasure: number, iLength: number) {
    return this.http.get<dtoKeyValue[]>(this._baseUrl + 'api/ProductCode/GetProductCodeTreatmentTypes/' + gProductTypeCategoryId + '/' + gProductTypeId + '/' + iLogSection + '/' + iWidth + '/' + iThickness + '/' + gSpeciesId + '/' + gDurabilityClassId + '/' + gBoardGradeId + '/' + iStructuralGrade + '/' + iUnitOfMeasure + '/' + iLength + '/' + gProductProfileId);
  }

  getGetProductCodeTreatmentHazardLevels(gProductTypeCategoryId: string, gProductTypeId: string, gProductProfileId: string, iLogSection: number, iWidth: number, iThickness: number, gSpeciesId: string, gDurabilityClassId: string, gBoardGradeId: string, iStructuralGrade: number, iUnitOfMeasure: number, iLength: number, iTreatmentType: number) {
    return this.http.get<dtoKeyValue[]>(this._baseUrl + 'api/ProductCode/GetProductCodeTreatmentHazardLevels/' + gProductTypeCategoryId + '/' + gProductTypeId + '/' + iLogSection + '/' + iWidth + '/' + iThickness + '/' + gSpeciesId + '/' + gDurabilityClassId + '/' + gBoardGradeId + '/' + iStructuralGrade + '/' + iUnitOfMeasure + '/' + iLength + '/' + iTreatmentType + '/' + gProductProfileId);
  }

  getProductCodeByStockItem(gBoardTallyRecordId: string, gPricingCategoryId: string) {
    return this.http.get<ProductCode>(this._baseUrl + 'api/ProductCode/GetProductCodeByStockItem/' + gBoardTallyRecordId + '/' + gPricingCategoryId);
  }

  getUpdatedProductCodePrice(aProductCodes: dtoProductCodes[], gPricingCategoryId: string) {
    return this.http.post<dtoProductCodes[]>(this._baseUrl + 'api/ProductCode/GetUpdatedProductCodePrice/' + gPricingCategoryId, aProductCodes);
  }

  getProductCodeByRowguidPromise(productCodeId: string) {
    return new Promise<dtoProductCodes>(resolve => {
      this.getProductCodeByRowguid(productCodeId).subscribe(productCode => {

        resolve(productCode);

      }, err => {
        console.log(err);
        resolve(null);
      })
    });

  }

}  
