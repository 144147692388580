import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BoardTallyRecordProductProfile } from '../business-objects/board-tally-record-product-profile.bo';
import { SQLParamArray } from '../business-objects/sql-param-array';

@Injectable()
export class BoardTallyRecordProductProfileService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		this._baseUrl = baseUrl;
	}

	getBoardTallyRecordProductProfile(boardTallyRecordProductProfileId: string) {
		return this.http.get<BoardTallyRecordProductProfile>(this._baseUrl + 'api/BoardTallyRecordProductProfile/' + boardTallyRecordProductProfileId);
	}

  getBoardTallyRecordProductProfilesParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<BoardTallyRecordProductProfile[]>(this._baseUrl + 'api/BoardTallyRecordProductProfile/GetBoardTallyRecordProductProfileParamArray/', aParam);
  }

	getAllBoardTallyRecordProductProfiles() {
		return this.http.get<BoardTallyRecordProductProfile[]>(this._baseUrl + 'api/BoardTallyRecordProductProfile/GetAllBoardTallyRecordProductProfiles/');
	}

	//getAlldtoBoardTallyRecordProductProfiles() {
	//	return this.http.get<dtoBoardTallyRecordProductProfile[]>(this._baseUrl + 'api/BoardTallyRecordProductProfile/GetAllBoardTallyRecordProductProfiles/');
	//}

	createBoardTallyRecordProductProfile(boardTallyRecordProductProfile: BoardTallyRecordProductProfile) {
		return this.http.post<BoardTallyRecordProductProfile>(this._baseUrl + 'api/BoardTallyRecordProductProfile/CreateBoardTallyRecordProductProfile/', boardTallyRecordProductProfile);
	}

  deleteBoardTallyRecordProductProfile(boardTallyRecordProductProfileId: string) {
    return this.http.delete(this._baseUrl + 'api/BoardTallyRecordProductProfile/' + boardTallyRecordProductProfileId);
  }

	updateBoardTallyRecordProductProfile(boardTallyRecordProductProfile: BoardTallyRecordProductProfile) {
		return this.http.post<BoardTallyRecordProductProfile>(this._baseUrl + 'api/BoardTallyRecordProductProfile/UpdateBoardTallyRecordProductProfile/', boardTallyRecordProductProfile);
  }


  getAllBoardTallyRecordProductProfilesParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<BoardTallyRecordProductProfile[]>(resolve => {
      this.getBoardTallyRecordProductProfilesParamArray(aParam).subscribe(boardTallyRecordProfiles => {
        resolve(boardTallyRecordProfiles);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  createBoardTallyRecordProductProfilePromise(boardTallyRecordProductProfile: BoardTallyRecordProductProfile) {
    return new Promise<Boolean>(resolve => {
      this.createBoardTallyRecordProductProfile(boardTallyRecordProductProfile).subscribe(data => {
        resolve(true);
      }, err => {
        console.log(err);
        resolve(false);
      });

    });

  }

  deleteBoardTallyRecordProductProfilePromise(boardTallyRecordProductProfileId: string) {
    return new Promise<Boolean>(resolve => {
      this.deleteBoardTallyRecordProductProfile(boardTallyRecordProductProfileId).subscribe(data => {
        resolve(true);
      }, err => {
        console.log(err);
        resolve(false);
      });

    });

  }

}
