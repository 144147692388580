import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoPrinter } from '../business-objects/dto-printer.bo';

@Injectable()
export class dtoPrinterService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoPrinter(dtoPrinterId: string) {
		return this.http.get<dtoPrinter>(this._baseUrl + 'api/dtoPrinter/' + dtoPrinterId);
	}

	getdtoPrinterParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoPrinter[]>(this._baseUrl + 'api/dtoPrinter/GetdtoPrintersParamArray/', aParam);
	}

	getAlldtoPrinters() {
		return this.http.get<dtoPrinter[]>(this._baseUrl + 'api/dtoPrinter/GetAlldtoPrinters/');
	}

	//getAlldtodtoPrinters() {
	//	return this.http.get<dtodtoPrinter[]>(this._baseUrl + 'api/dtoPrinter/GetAlldtoPrinters/');
	//}

	createdtoPrinter(dtoPrinter: dtoPrinter) {
		return this.http.post<dtoPrinter>(this._baseUrl + 'api/dtoPrinter/CreatedtoPrinter/', dtoPrinter);
	}

	updatedtoPrinter(dtoPrinter: dtoPrinter) {
		return this.http.put<dtoPrinter>(this._baseUrl + 'api/dtoPrinter/UpdatedtoPrinter/', dtoPrinter);
	}

	deletedtoPrinter(dtoPrinterId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoPrinter/DeletedtoPrinter/' + dtoPrinterId);
	}

	async getdtoPrinterPromise(dtoPrinterId: string) {
		return new Promise<dtoPrinter>(resolve => {
			this.getdtoPrinter(dtoPrinterId).subscribe(dtoPrinter => {
				resolve(dtoPrinter);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoPrinter records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoPrinterParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoPrinter[]>(resolve => {
			this.getdtoPrinterParamArray(aParam).subscribe(dtoPrinters => {
				resolve(dtoPrinters);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoPrinter records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoPrinterPromise(dtoPrinter: dtoPrinter) {
		return new Promise<dtoPrinter>(resolve => {
			this.updatedtoPrinter(dtoPrinter).subscribe(dtoPrinter => {
				resolve(dtoPrinter);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoPrinter record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoPrinterPromise(dtoPrinter: dtoPrinter) {
		return new Promise<dtoPrinter>(resolve => {
			this.createdtoPrinter(dtoPrinter).subscribe(dtoPrinter => {
				resolve(dtoPrinter);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoPrinter record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoPrinterPromise(dtoPrinterId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoPrinter(dtoPrinterId).subscribe(dtoPrinter => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoPrinter record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}