import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { dtoDeliveredSalesBySectionDetailAll } from '../business-objects/dto-delivered-sales-by-section-detail-all.bo';

@Injectable()
export class dtoDeliveredSalesBySectionDetailAllService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
		this._baseUrl = baseUrl;
	}

	getdtoDeliveredSalesBySectionDetailAll(dtoDeliveredSalesBySectionDetailAllId: string) {
		return this.http.get<dtoDeliveredSalesBySectionDetailAll>(this._baseUrl + 'api/dtoDeliveredSalesBySectionDetailAll/' + dtoDeliveredSalesBySectionDetailAllId);
	}

  getAlldtoDeliveredSalesBySectionDetailAlls(sectionName: string[]) {
    let secName: string = "";
    for (let i: number = 0; i <= sectionName.length - 1; i++) {
      secName += "sectionName=" + sectionName[i]
      if (i < sectionName.length - 1) {
        secName += "&"
      }
    }
    console.log("secName:", secName);
   console.log("sectionName:", sectionName);

    return this.http.get<dtoDeliveredSalesBySectionDetailAll[]>(this._baseUrl + 'api/dtoDeliveredSalesBySectionDetailAll/GetAlldtoDeliveredSalesBySectionDetailAlls?' + secName);
	}

	//getAlldtodtoDeliveredSalesBySectionDetailAlls() {
	//	return this.http.get<dtodtoDeliveredSalesBySectionDetailAll[]>(this._baseUrl + 'api/dtoDeliveredSalesBySectionDetailAll/GetAlldtoDeliveredSalesBySectionDetailAlls/');
	//}

	createdtoDeliveredSalesBySectionDetailAll(dtoDeliveredSalesBySectionDetailAll: dtoDeliveredSalesBySectionDetailAll) {
		return this.http.post<dtoDeliveredSalesBySectionDetailAll>(this._baseUrl + 'api/dtoDeliveredSalesBySectionDetailAll/CreatedtoDeliveredSalesBySectionDetailAll/', dtoDeliveredSalesBySectionDetailAll);
	}

	updatedtoDeliveredSalesBySectionDetailAll(dtoDeliveredSalesBySectionDetailAll: dtoDeliveredSalesBySectionDetailAll) {
		return this.http.post<dtoDeliveredSalesBySectionDetailAll>(this._baseUrl + 'api/dtoDeliveredSalesBySectionDetailAll/UpdatedtoDeliveredSalesBySectionDetailAll/', dtoDeliveredSalesBySectionDetailAll);
	}

}
