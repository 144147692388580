import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import html2canvas from 'html2canvas';
import { dtoTaxInvoiceProductSummary } from '../../../_shared/business-objects/dto-tax-invoice-product-summary.bo';
import { FileService } from '../../../_shared/services/app-services/file.service';

@Component({
  selector: 'app-pos-product-summary',
  templateUrl: './pos-product-summary.component.html',
  styleUrl: './pos-product-summary.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PosProductSummaryComponent implements OnInit {

  @Input() taxInvoiceProductSummary: dtoTaxInvoiceProductSummary[];
  @Input() dateRange: string;
  @Input() saleLocationName: string;
  @Input() printMode: boolean;

  @Output() componentLoaded = new EventEmitter<boolean>();

  productSummaryTotal: number = 0;

  constructor(private fileService: FileService) {

  }


  ngOnInit():void {


    //setTimeout(() => { this.calcTotal() }, 500);
    //this.calcTotal();

  }

  ngAfterViewInit() {
    // Code to execute when the div is loaded
    //this.componentLoaded.emit(true);

  }

  calcTotal() {

    this.productSummaryTotal = 0;
    if (this.taxInvoiceProductSummary != null) {
      for (let i = 0; i <= this.taxInvoiceProductSummary.length - 1; i++) {
        this.productSummaryTotal += this.taxInvoiceProductSummary[i].fltTotalItemPriceExGST;
        //console.log("this.productSummaryTotal", this.productSummaryTotal);
      }
    }

  }

  async createPDF() {

    this.printMode = false;

    setTimeout(() => {
      this.getBase64();

      this.printMode = true;

    }, 500);

  }

  async createHTMLCanvas() {
    return new Promise<string>(async resolve => {
      resolve(await this.renderHTMLToCanvas());
    });
  }

  renderHTMLToCanvas() {
    return new Promise<string>(resolve => {
      html2canvas(document.querySelector("#productSummaryPrintContainer"), { scale: 1 }).then(async canvas => {
        console.log("renderHTMLToCanvas");
        resolve(canvas.toDataURL('image/png', 1));
      });
    });
  }

  async getBase64() {

    let ele = document.getElementById("productSummaryPrintContainer");

    let arrBuffer = await this.fileService.HTMLToPdfDataURL("productSummaryPrintContainer", "p");

    console.log(arrBuffer);


  }


}
