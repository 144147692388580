import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TaxInvoice } from '../business-objects/tax-invoice.bo';
import { Printer } from '../business-objects/printer.bo';
import { SharedService } from '../services/shared.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class TaxInvoiceService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService) {
    this._baseUrl = baseUrl;
  }

  getTaxInvoice(invoiceNo: number, saleLocationId: string) {
    return this.http.get<TaxInvoice>(this._baseUrl + 'api/TaxInvoice/' + invoiceNo + '/' + saleLocationId);
  }

  getTaxInvoicesByDates(dateFrom: Date, dateTo: Date, saleLocationId: string) {
    const datepipe: DatePipe = new DatePipe('en-AU');
    return this.http.get<TaxInvoice[]>(this._baseUrl + 'api/TaxInvoice/GetTaxInvoiceByDates/' + encodeURIComponent(datepipe.transform(dateFrom, 'yyyy-MM-dd')) + '/' + encodeURIComponent(datepipe.transform(dateTo, 'yyyy-MM-dd')) + '/' + saleLocationId);
  }

  getTaxInvoicesTop50(saleLocationId: string) {
    return this.http.get<TaxInvoice[]>(this._baseUrl + 'api/TaxInvoice/GetTaxInvoicesTop50/' + saleLocationId);
  }

  createTaxInvoice(taxInvoice: TaxInvoice) {
    return this.http.post<TaxInvoice>(this._baseUrl + 'api/TaxInvoice/CreateTaxInvoice/', taxInvoice);
  }

  updateTaxInvoice(taxInvoice: TaxInvoice) {
    return this.http.put<TaxInvoice>(this._baseUrl + 'api/TaxInvoice/UpdateTaxInvoice/', taxInvoice);
  }


  printTaxInvoice(taxInvoice: TaxInvoice, printer: Printer) {
    //console.log(printer);
    //return this.http.post('https://localhost:44358/api/Print/PrintReceipt/' + taxInvoice.rowguid, printer);  //, { responseType: 'blob' }
    return this.http.post(this.sharedService.PrintServerURI + 'api/Print/PrintReceipt/' + taxInvoice.rowguid, printer, {responseType: 'blob'});
  }

  downloadTaxInvoice(taxInvoice: TaxInvoice) {
    //return this.http.get('https://localhost:44358/api/Print/DownloadReceipt/' + taxInvoice.rowguid, { responseType: 'blob' });  //
    return this.http.get(this.sharedService.PrintServerURI + 'api/Print/DownloadReceipt/' + taxInvoice.rowguid, { responseType: 'blob' });
  }

  downloadTaxInvoiceSummaryReportByDates(dateFrom: Date, dateTo: Date, saleLocationId: string) {
   const datepipe: DatePipe = new DatePipe('en-AU');
    //return this.http.get('https://localhost:44358/api/Print/CreateCashSaleSummaryReport/' + encodeURIComponent(datepipe.transform(dateFrom, 'yyyy-MM-dd')) + '/' + encodeURIComponent(datepipe.transform(dateTo, 'yyyy-MM-dd')) + '/' + saleLocationId, { responseType: 'blob' })
    return this.http.get(this.sharedService.PrintServerURI + 'api/Print/CreateCashSaleSummaryReport/' + encodeURIComponent(datepipe.transform(dateFrom, 'yyyy-MM-dd')) + '/' + encodeURIComponent(datepipe.transform(dateTo, 'yyyy-MM-dd')) + '/' + saleLocationId, { responseType: 'blob' })
  }

  downloadTaxInvoiceSummaryByProductReportByDates(dateFrom: Date, dateTo: Date, saleLocationId: string) {
    const datepipe: DatePipe = new DatePipe('en-AU');
    return this.http.get('https://localhost:44358/api/Print/CreateCashSaleSummaryByProductReport/' + encodeURIComponent(datepipe.transform(dateFrom, 'yyyy-MM-dd')) + '/' + encodeURIComponent(datepipe.transform(dateTo, 'yyyy-MM-dd')) + '/' + saleLocationId, { responseType: 'blob' })
    //return this.http.get(this.sharedService.PrintServerURI + 'api/Print/CreateCashSaleSummaryByProductReport/' + encodeURIComponent(datepipe.transform(dateFrom, 'yyyy-MM-dd')) + '/' + encodeURIComponent(datepipe.transform(dateTo, 'yyyy-MM-dd')) + '/' + saleLocationId, { responseType: 'blob' })
  }

  downloadTaxInvoiceDetailReportByDates(dateFrom: Date, dateTo: Date, saleLocationId: string) {
    const datepipe: DatePipe = new DatePipe('en-AU');
    //return this.http.get('https://localhost:44358/api/Print/CreateCashSaleDetailReport/' + encodeURIComponent(datepipe.transform(dateFrom, 'yyyy-MM-dd')) + '/' + encodeURIComponent(datepipe.transform(dateTo, 'yyyy-MM-dd')) + '/' + saleLocationId, { responseType: 'blob' })
    return this.http.get(this.sharedService.PrintServerURI + 'api/Print/CreateCashSaleDetailReport/' + encodeURIComponent(datepipe.transform(dateFrom, 'yyyy-MM-dd')) + '/' + encodeURIComponent(datepipe.transform(dateTo, 'yyyy-MM-dd')) + '/' + saleLocationId, { responseType: 'blob' })
  }

}
