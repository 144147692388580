import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BoardGrade } from '../../_shared/business-objects/board-grade.bo';

@Injectable()
export class BoardGradeService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getAllBoardGrades() {
    return this.http.get<BoardGrade>(this._baseUrl + 'api/BoardGrade/GetAllBoardGrades');
  }

  getProductCodeBoardGrades(gProductCategoryId: string, gProductTypeId: string, iLogSection, iWidth: number, iThickness: number, gSpeciesId: string, gDurabilityClassId: string, gProductProfileId: string) {
    return this.http.get<BoardGrade[]>(this._baseUrl + 'api/BoardGrade/GetProductCodeGrades/' + gProductCategoryId + '/' + gProductTypeId + '/' + iLogSection + '/' + iWidth + '/' + iThickness + '/' + gSpeciesId + '/' + gDurabilityClassId + '/' + gProductProfileId);
  }

  getBoardGrade(gGradeId: string) {
    return this.http.get<BoardGrade>(this._baseUrl + 'api/BoardGrade/' + gGradeId);
  }
}  
