import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DBFieldFunction, SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from '../services/alert.service';
import { dtoTaxInvoiceDailyTotals } from '../business-objects/dto-tax-invoice-daily-totals.bo';

@Injectable()
export class dtoTaxInvoiceDailyTotalsService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoTaxInvoiceDailyTotals(dtoTaxInvoiceDailyTotalsId: string) {
		return this.http.get<dtoTaxInvoiceDailyTotals>(this._baseUrl + 'api/dtoTaxInvoiceDailyTotals/' + dtoTaxInvoiceDailyTotalsId);
	}

	getdtoTaxInvoiceDailyTotalsParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoTaxInvoiceDailyTotals[]>(this._baseUrl + 'api/dtoTaxInvoiceDailyTotals/GetdtoTaxInvoiceDailyTotalsParamArray/', aParam);
	}

	getAlldtoTaxInvoiceDailyTotalss() {
		return this.http.get<dtoTaxInvoiceDailyTotals[]>(this._baseUrl + 'api/dtoTaxInvoiceDailyTotals/GetAlldtoTaxInvoiceDailyTotals/');
	}

	//getAlldtodtoTaxInvoiceDailyTotalss() {
	//	return this.http.get<dtodtoTaxInvoiceDailyTotals[]>(this._baseUrl + 'api/dtoTaxInvoiceDailyTotals/GetAlldtoTaxInvoiceDailyTotals/');
	//}

	createdtoTaxInvoiceDailyTotals(dtoTaxInvoiceDailyTotals: dtoTaxInvoiceDailyTotals) {
		return this.http.post<dtoTaxInvoiceDailyTotals>(this._baseUrl + 'api/dtoTaxInvoiceDailyTotals/CreatedtoTaxInvoiceDailyTotals/', dtoTaxInvoiceDailyTotals);
	}

	updatedtoTaxInvoiceDailyTotals(dtoTaxInvoiceDailyTotals: dtoTaxInvoiceDailyTotals) {
		return this.http.put<dtoTaxInvoiceDailyTotals>(this._baseUrl + 'api/dtoTaxInvoiceDailyTotals/UpdatedtoTaxInvoiceDailyTotals/', dtoTaxInvoiceDailyTotals);
	}

	deletedtoTaxInvoiceDailyTotals(dtoTaxInvoiceDailyTotalsId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoTaxInvoiceDailyTotals/DeletedtoTaxInvoiceDailyTotals/' + dtoTaxInvoiceDailyTotalsId);
	}

	async getdtoTaxInvoiceDailyTotalsPromise(dtoTaxInvoiceDailyTotalsId: string) {
		return new Promise<dtoTaxInvoiceDailyTotals>(resolve => {
			this.getdtoTaxInvoiceDailyTotals(dtoTaxInvoiceDailyTotalsId).subscribe(dtoTaxInvoiceDailyTotals => {
				resolve(dtoTaxInvoiceDailyTotals);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTaxInvoiceDailyTotals records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoTaxInvoiceDailyTotalsParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoTaxInvoiceDailyTotals[]>(resolve => {
			this.getdtoTaxInvoiceDailyTotalsParamArray(aParam).subscribe(dtoTaxInvoiceDailyTotals => {
				resolve(dtoTaxInvoiceDailyTotals);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTaxInvoiceDailyTotals records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoTaxInvoiceDailyTotalsByDates(from: string, to: string, saleLocation: string) {
    return new Promise<dtoTaxInvoiceDailyTotals[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("dteDateCreated", from, SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND, new DBFieldFunction(SQLParamArray.enDBFieldFunction.CastDate, "", "")));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("dteDateCreated", to, SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND, new DBFieldFunction(SQLParamArray.enDBFieldFunction.CastDate, "", "")));
      aParamList.push(aParam);

      if (saleLocation != null && saleLocation != "") {
        aParam = [];
        aParam.push(new SQLParamArray("guSaleLocationId", saleLocation));
        aParamList.push(aParam);
      }

      this.getdtoTaxInvoiceDailyTotalsParamArray(aParamList).subscribe(dtoTaxInvoiceDailyTotals => {
        resolve(dtoTaxInvoiceDailyTotals);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoTaxInvoiceDailyTotals records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async updatedtoTaxInvoiceDailyTotalsPromise(dtoTaxInvoiceDailyTotals: dtoTaxInvoiceDailyTotals) {
		return new Promise<dtoTaxInvoiceDailyTotals>(resolve => {
			this.updatedtoTaxInvoiceDailyTotals(dtoTaxInvoiceDailyTotals).subscribe(dtoTaxInvoiceDailyTotals => {
				resolve(dtoTaxInvoiceDailyTotals);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoTaxInvoiceDailyTotals record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoTaxInvoiceDailyTotalsPromise(dtoTaxInvoiceDailyTotals: dtoTaxInvoiceDailyTotals) {
		return new Promise<dtoTaxInvoiceDailyTotals>(resolve => {
			this.createdtoTaxInvoiceDailyTotals(dtoTaxInvoiceDailyTotals).subscribe(dtoTaxInvoiceDailyTotals => {
				resolve(dtoTaxInvoiceDailyTotals);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoTaxInvoiceDailyTotals record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoTaxInvoiceDailyTotalsPromise(dtoTaxInvoiceDailyTotalsId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoTaxInvoiceDailyTotals(dtoTaxInvoiceDailyTotalsId).subscribe(dtoTaxInvoiceDailyTotals => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoTaxInvoiceDailyTotals record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
