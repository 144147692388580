import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppUserColumn } from '../../_shared/business-objects/app-user-column.bo';
import { dtoAppUserColumn } from '../../_shared/business-objects/dto-app-user-column.bo';
import { AppUserColumnService } from '../../_shared/services/app-user-column.service';

@Component({
  selector: 'app-app-user-column-list',
  templateUrl: './app-user-column-list.component.html',
  styleUrls: ['./app-user-column-list.component.css']
})
export class AppUserColumnListComponent implements OnInit {

  @Input() datasource: MatTableDataSource<dtoAppUserColumn>;
  @Output() editAppUserColumnEvent = new EventEmitter<dtoAppUserColumn>();
  @Output() removeAppUserColumnEvent = new EventEmitter<dtoAppUserColumn>();

  @ViewChild(MatSort) sort: MatSort;

  displayedAppUserColumns: string[] = ['txtUserName', 'txtPageName', 'txtColumnName', 'txtColumnNameAlias', 'txtColumnSetName', 'dteDateCreated', 'blnVisible', 'intColumnOrder', 'btnEditAppUserColumn', 'btnRemoveAppUserColumn'];


  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
  }

  async editAppUserColumn(dtoAppUserColumn: dtoAppUserColumn) {

    this.editAppUserColumnEvent.emit(dtoAppUserColumn);

  }

  async removeAppUserColumn(dtoAppUserColumn: dtoAppUserColumn) {

    this.removeAppUserColumnEvent.emit(dtoAppUserColumn);

  }

}
