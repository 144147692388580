<div class="customer-update-container">
  <div class="customer-update-form" [formGroup]="form">

    <div class="customer-name-container field-container">
      <mat-form-field class="chh-form-field chh-textbox-500 customer-name-form-field" appearance="fill">
        <mat-label>Customer Name / Business Name</mat-label>
        <input class="textbox-input" matInput value="" type="text" id="txtCustomerName" formControlName="txtCustomerName">
      </mat-form-field>
    </div>

    <div class="customer-active-container field-container">
      <mat-checkbox class="example-margin" #blnActive id="blnActive" formControlName="blnActive">Active</mat-checkbox>
    </div>

    <div class="customer-is-a-business-container field-container">
      <mat-checkbox class="example-margin" #blnIsABusiness id="blnIsABusiness" formControlName="blnIsABusiness">Is a Business</mat-checkbox>
    </div>

    <div class="business-detail" #businessDetail *ngIf="blnIsABusiness.checked == true">
      <div class="customer-legal-business-name-container field-container">
        <mat-form-field class="chh-form-field chh-textbox-500" appearance="fill">
          <mat-label>Legal Business Name</mat-label>
          <input class="textbox-input" matInput placeholder="i.e. My Buisness Name Pty Ltd" value="" type="text" id="txtLegalBusinessName" formControlName="txtLegalBusinessName">
        </mat-form-field>
      </div>

      <div class="customer-abn-container field-container">
        <mat-form-field class="chh-form-field chh-textbox-300 abn-form-field" appearance="fill">
          <mat-label>ABN</mat-label>
          <input class="textbox-input" matInput value="" type="text" formControlName="txtABN">
        </mat-form-field>
      </div>

      <div class="customer-old-trading-name-container field-container">
        <mat-form-field class="chh-form-field chh-textbox-500" appearance="fill">
          <mat-label>Old Trading Name</mat-label>
          <input class="textbox-input" matInput placeholder="i.e. My Old Buisness Name Pty Ltd" value="" type="text" id="txtOldTradingName" formControlName="txtOldTradingName">
        </mat-form-field>
      </div>


      <div class="customer-combine-old-trading-name-container field-container">
        <mat-checkbox class="example-margin" #blnCombineOldTradingName id="blnCombineOldTradingName" formControlName="blnCombineOldTradingName">Combine Old Trading Name</mat-checkbox>
        <mat-icon matTooltip="This will display the current business name with the old trading name in customer lists throught he application i.e  Smiths Timbers (John Smith Timber Merchant) " class="help-icon">help_outline</mat-icon>
      </div>

      <div class="customer-address-container field-container">
        <mat-form-field class="chh-form-field example-full-width address-form-field" appearance="fill">
          <mat-label>Business Address</mat-label>
          <input class="textbox-input" matInput value="" type="text" formControlName="txtAddress">
        </mat-form-field>
      </div>

      <div class="customer-town-container field-container">
        <mat-form-field class="chh-form-field example-full-width town-form-field" appearance="fill">
          <mat-label>Business Town</mat-label>
          <input class="textbox-input" matInput value="" type="text" #txtTown formControlName="txtTown">
        </mat-form-field>
      </div>

      <div class="customer-state-container field-container">
        <div class="state-label container-label">Business State</div>
        <mat-selection-list class="chh-mat-selection-list state-select" #txtState [multiple]="false" id="txtState" formControlName="txtState">
          <mat-list-option *ngFor="let state of states" [value]="state.txtValue">
            {{state.txtValue}}
          </mat-list-option>
        </mat-selection-list>
      </div>

      <div class="customer-post-code-container field-container">
        <mat-form-field class="chh-form-field example-full-width post-code-form-field" appearance="fill">
          <mat-label>Business Postcode</mat-label>
          <input class="textbox-input" matInput value="" type="text" #txtPostcode formControlName="txtPostcode">
        </mat-form-field>
        <div class="lookup-post-code-button-column">
          <button class="post-code-button" type="button" mat-flat-button color="primary" (click)="lookupPostcode(txtTown.value, txtState._value[0])">Get Postcode</button>
        </div>

        <div class="multipostcode-container field-container" *ngIf="postcodes != null">
          <mat-selection-list class="chh-mat-selection-list multipostcode-select" #txtMultiPostcode [multiple]="false" id="txtMultiPostcode" (selectionChange)="selectPostcode(txtMultiPostcode._value[0])">
            <mat-list-option *ngFor="let postcode of postcodes" [value]="postcode.postcode">
              {{postcode.name}} {{postcode.postcode}}
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>

      <div class="customer-phone1-container field-container">
        <mat-form-field class="chh-form-field example-full-width phone1-form-field" appearance="fill">
          <mat-label>Business Phone 1</mat-label>
          <input class="textbox-input" matInput value="" type="tel" formControlName="txtPhone1">
        </mat-form-field>
      </div>

      <div class="customer-phone2-container field-container">
        <mat-form-field class="chh-form-field example-full-width phone2-form-field" appearance="fill">
          <mat-label>Business Phone 2</mat-label>
          <input class="textbox-input" matInput value="" type="tel" formControlName="txtPhone2">
        </mat-form-field>
      </div>

      <div class="customer-mobile-container field-container">
        <mat-form-field class="chh-form-field example-full-width mobile-form-field" appearance="fill">
          <mat-label>Business Mobile</mat-label>
          <input class="textbox-input" matInput value="" type="tel" formControlName="txtMobile">
        </mat-form-field>
      </div>

      <div class="customer-fax-container field-container" *ngIf="false">
        <mat-form-field class="chh-form-field example-full-width fax-form-field" appearance="fill">
          <mat-label>Business Fax</mat-label>
          <input class="textbox-input" matInput value="" type="tel" formControlName="txtFax">
        </mat-form-field>
      </div>

      <div class="customer-email-container field-container">
        <mat-form-field class="chh-form-field example-full-width email-form-field" appearance="fill">
          <mat-label>Business Accounts Email</mat-label>
          <input class="textbox-input" matInput value="" type="email" formControlName="txtEmail">
        </mat-form-field>
      </div>

    </div>

    <div class="customer-sector-type-container field-container">
      <div class="sector-type-label container-label">Sector Type</div>
      <mat-selection-list class="chh-mat-selection-list sector-type-select" #intSectorType [multiple]="false" id="intSectorType" formControlName="intSectorType">
        <mat-list-option *ngFor="let sectorType of sectorTypes" [value]="sectorType.intValue" (click)="setPricingCategory(sectorType.intValue)">
          {{sectorType.txtValue}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="customer-pricing-category-container field-container">
      <div class="pricing-category-label container-label">Pricing Category</div>
      <mat-selection-list class="chh-mat-selection-list pricing-category-select" #guPricingCategoryId [multiple]="false" id="guPricingCategoryId" formControlName="guPricingCategoryId">
        <mat-list-option *ngFor="let pricingCategory of pricingCategorys" [value]="pricingCategory.rowguid">
          {{pricingCategory.txtName}}
        </mat-list-option>
      </mat-selection-list>
    </div>


    <div class="customer-terms-container field-container">
      <div class="terms-label container-label">Payment Terms</div>
      <mat-selection-list class="chh-mat-selection-list terms-select" #intTerms [multiple]="false" id="intTerms" formControlName="intTerms">
        <mat-list-option *ngFor="let term of terms" [value]="term.intValue">
          {{term.txtValue}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="customer-payment-type-container field-container">
      <div class="payment-type-label container-label">Default Payment Type</div>
      <mat-selection-list class="chh-mat-selection-list payment-type-select" #intPaymentType [multiple]="false" id="intPaymentType" formControlName="intPaymentType">
        <mat-list-option *ngFor="let paymentType of paymentTypes" [value]="paymentType.intValue">
          {{paymentType.txtValue}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="customer-invoice-transmission-container field-container">
      <div class="invoice-transmision-label container-label">Default Invoice Transmission</div>
      <mat-selection-list class="chh-mat-selection-list invoice-transmission-select" #intInvoiceTransmission [multiple]="false" id="intInvoiceTransmission" formControlName="intInvoiceTransmission">
        <mat-list-option *ngFor="let invoiceTransmission of invoiceTransmissions" [value]="invoiceTransmission.intValue">
          {{invoiceTransmission.txtValue}}
        </mat-list-option>
      </mat-selection-list>
    </div>


    <div class="customer-key-customer-container field-container">
      <mat-checkbox class="example-margin" #blnKeyCustomer id="blnKeyCustomer" formControlName="blnKeyCustomer">Key Customer</mat-checkbox>
    </div>

    <div class="customer-comments-container field-container">
      <div class="comments-label container-label">Comments</div>
      <textarea class="comments chh-textbox-500" autosize minRows="2" [(ngModel)]="customerComments" id="txtComments" formControlName="txtComments"></textarea>
    </div>

    <div class="mtech-list-appearance">MTECH Lists</div>
    <div class="mtech-list-appearance-container">
      <div class="customer-orders-container field-container">
        <mat-checkbox class="example-margin" #blnOrders id="blnOrders" formControlName="blnOrders">Orders</mat-checkbox>
      </div>

      <div class="customer-board-plant-container field-container">
        <mat-checkbox class="example-margin" #blnBoardPlant id="blnBoardPlant" formControlName="blnBoardPlant" [checked]="true">Board Plant</mat-checkbox>
      </div>

      <div class="customer-joinery-container field-container">
        <mat-checkbox class="example-margin" #blnBoardPlant id="blnJoinery" formControlName="blnJoinery" [checked]="true">Joinery</mat-checkbox>
      </div>

      <div class="customer-tool-handles-container field-container">
        <mat-checkbox class="example-margin" #blnToolHandles id="blnToolHandles" formControlName="blnToolHandles" [checked]="true">Tool Handles</mat-checkbox>
      </div>

      <div class="customer-parquetry-container field-container">
        <mat-checkbox class="example-margin" #blnParquetry id="blnParquetry" formControlName="blnParquetry" [checked]="true">Parquetry</mat-checkbox>
      </div>

      <div class="customer-mill-container field-container">
        <mat-checkbox class="example-margin" #blnMill id="blnMill" formControlName="blnMill" [checked]="true">Mill</mat-checkbox>
      </div>

      <div class="customer-treatment-container field-container">
        <mat-checkbox class="example-margin" #blnTreatment id="blnTreatment" formControlName="blnTreatment" [checked]="true">Treatment</mat-checkbox>
      </div>

      <div class="customer-desap-container field-container">
        <mat-checkbox class="example-margin" #blnDesap id="blnDesap" formControlName="blnDesap">Desap</mat-checkbox>
      </div>

      <div class="customer-private-property-container field-container">
        <mat-checkbox class="example-margin" #blnPrivateProperty id="blnPrivateProperty" formControlName="blnPrivateProperty">Private Property</mat-checkbox>
      </div>

      <div class="customer-forestry-container field-container">
        <mat-checkbox class="example-margin" #blnForestry id="blnForestry" formControlName="blnForestry">Forestry</mat-checkbox>
      </div>
    </div>

    <div class="customer-usual-loading-location-container field-container">
      <div class="usual-loading-location-label container-label">Usual Loading Location</div>
      <mat-selection-list class="chh-mat-selection-list usual-loading-location-select" #intUsualLoadingLocation [multiple]="false" id="intUsualLoadingLocation" formControlName="intUsualLoadingLocation">
        <mat-list-option *ngFor="let usualLoadingLocation of usualLoadingLocations" [value]="usualLoadingLocation.intValue">
          {{usualLoadingLocation.txtValue}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="customer-auction-commission-container field-container">
      <mat-form-field class="chh-form-field chh-textbox-200 auction-fee-form-field" appearance="fill">
        <mat-label>Auction Commission %</mat-label>
        <input class="textbox-input" matInput value="" placeholder="i.e. 19.75" type="number" formControlName="fltAuctionFee">
      </mat-form-field>&nbsp;%
    </div>

    <div class="customer-electrical-authority-spec-container field-container">
      <div class="electrical-authority-spec-label container-label">Electrical Authority Spec</div>
      <mat-selection-list class="chh-mat-selection-list electrical-authority-spec-select" #guElectricalAuthoritySpecId [multiple]="false" id="guElectricalAuthoritySpecId" formControlName="guElectricalAuthoritySpecId">
        <mat-list-option *ngFor="let electricalAuthoritySpec of electricalAuthoritySpecs" [value]="electricalAuthoritySpec.rowguid">
          {{electricalAuthoritySpec.txtAuthorityName}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="customer-internal-customer-container field-container">
      <mat-checkbox class="example-margin" #blnInternalCustomer id="blnInternalCustomer" formControlName="blnInternalCustomer">Internal Customer</mat-checkbox>
    </div>

    <div class="customer-system-customer-container field-container">
      <mat-checkbox class="example-margin" #blnSystemCustomer id="blnSystemCustomer" formControlName="blnSystemCustomer">System Customer</mat-checkbox>
    </div>

    <div class="customer-add-delivery-charge-to-docket-container field-container">
      <mat-checkbox class="example-margin" #blnAddDeliveryChargeToDocket id="blnAddDeliveryChargeToDocket" formControlName="blnAddDeliveryChargeToDocket">Add Delivery Charge To Docket</mat-checkbox>
    </div>

    <div class="customer-web-app-custom-actions-container field-container">
      <div class="web-app-custom-actions-label container-label">Web App Custom Actions</div>
      <mat-selection-list class="chh-mat-selection-list web-app-custom-actions-select" #intWebAppCustomActions [multiple]="false" id="intWebAppCustomActions" formControlName="intWebAppCustomActions">
        <mat-list-option *ngFor="let webAppCustomAction of webAppCustomActions" [value]="webAppCustomAction.intValue">
          {{webAppCustomAction.txtValue}}
        </mat-list-option>
      </mat-selection-list>
    </div>


  </div>

  <div class="action-button-container">
    <button class="action-button" mat-flat-button color="primary" (click)="updateCustomer('')">Update Customer</button>
    <button class="action-button" mat-flat-button color="primary" (click)="updateCustomer('UpdateCustomerContact')">Update Customer And Add Contact</button>
    <button class="action-button" mat-flat-button color="primary" (click)="cancelCustomer()">Cancel</button>
  </div>

</div>

<app-spinner *ngIf="showSpinner" [showSpinner]="showSpinner" class="customer-spinner"></app-spinner>
