import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { dtoGrade } from '../../_shared/business-objects/dto-grade.bo';
import { dtoProductProfile } from '../../_shared/business-objects/dto-product-profile.bo';
import { dtoProductType } from '../../_shared/business-objects/dto-product-type.bo';
import { dtoSpecies } from '../../_shared/business-objects/dto-species.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { SQLParamService } from '../../_shared/services/app-services/sql-param.service';
import { dtoGradeService } from '../../_shared/services/dto-grade.service';
import { dtoProductProfileService } from '../../_shared/services/dto-product-profile.service';
import { dtoProductTypeService } from '../../_shared/services/dto-product-type.service';
import { dtoSpeciesService } from '../../_shared/services/dto-species.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-product-code-builder',
  templateUrl: './product-code-builder.component.html',
  styleUrl: './product-code-builder.component.scss'
})
export class ProductCodeBuilderComponent implements OnInit {

  @Input() selectedProductCode: string;
  @Output() productCodeSelected = new EventEmitter<string>();
  @Output() productCodeCancelled = new EventEmitter<boolean>();

  @ViewChild('productTypeSKU') productTypeSKU: ElementRef;
  @ViewChild('productProfileSKU') productProfileSKU: ElementRef;
  @ViewChild('widthSKU') widthSKU: ElementRef;
  @ViewChild('thicknessSKU') thicknessSKU: ElementRef;
  @ViewChild('lengthSKU') lengthSKU: ElementRef;
  @ViewChild('speciesSKU') speciesSKU: ElementRef;
  @ViewChild('gradeSKU') gradeSKU: ElementRef;
  @ViewChild('skuFinishWildCard') skuFinishWildCard: MatCheckbox;

  productTypes: dtoProductType[];
  productTypesAll: dtoProductType[];
  selectedProd: dtoProductType;

  productProfiles: dtoProductProfile[];
  productProfilesAll: dtoProductProfile[];
  selectedProdProfile: dtoProductProfile;

  species: dtoSpecies[];
  speciesAll: dtoSpecies[];
  selectedSpec: dtoSpecies;

  grades: dtoGrade[];
  gradesAll: dtoGrade[];
  selectedGrade: dtoGrade;

  skuText: string = "";

  constructor(private formService: FormService, private sharedService: SharedService, private alertService: AlertService, private productTypeService: dtoProductTypeService
    , private sqlParamService: SQLParamService, private dtoSpeciesService: dtoSpeciesService, private dtoGradeService: dtoGradeService
    , private dtoProductProfileService: dtoProductProfileService) {

  }

  ngOnInit() {

    this.loadData();

  }

  async loadData() {


    this.productTypeService.getdtoProductTypes().then(productTypes => {
      this.productTypes = productTypes;
      this.productTypesAll = productTypes;
      this.productTypes.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });
      this.productTypesAll.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });
    });

    this.dtoSpeciesService.getdtoAllSpecies().then(species => {
      this.species = species;
      this.speciesAll = species;
      this.species.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });
      this.speciesAll.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });
      console.log("got species", this.species.length);
    });

    this.dtoGradeService.getdtoAllGrades().then(grades => {
      this.grades = grades;
      this.gradesAll = grades;
      this.grades.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });
      this.gradesAll.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });
    console.log("got grades", this.grades.length);
    });

    this.dtoProductProfileService.getdtoAllProductProfile().then(profiles => {
      this.productProfiles = profiles;
      this.productProfilesAll = profiles;
      this.productProfiles.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });
      this.productProfilesAll.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });
      console.log("got productProfiles", this.productProfiles.length);
    });

  }

  buildSKUText() {

    this.skuText = this.productTypeSKU.nativeElement.value != null && this.productTypeSKU.nativeElement.value != "" ? this.productTypeSKU.nativeElement.value + "-" : "";
    this.skuText += this.productProfileSKU.nativeElement.value != null && this.productProfileSKU.nativeElement.value != "" ? this.productProfileSKU.nativeElement.value + "-" : "";
    this.skuText += this.widthSKU.nativeElement.value != null && this.widthSKU.nativeElement.value != "" ? this.widthSKU.nativeElement.value + "-" : "";
    this.skuText += this.thicknessSKU.nativeElement.value != null && this.thicknessSKU.nativeElement.value != "" ? this.thicknessSKU.nativeElement.value + "-" : "";
    this.skuText += this.lengthSKU.nativeElement.value != null && this.lengthSKU.nativeElement.value != "" ? this.lengthSKU.nativeElement.value + "-" : "";
    this.skuText += this.speciesSKU.nativeElement.value != null && this.speciesSKU.nativeElement.value != "" ? this.speciesSKU.nativeElement.value + "-" : "";
    this.skuText += this.gradeSKU.nativeElement.value != null && this.gradeSKU.nativeElement.value != "" ? this.gradeSKU.nativeElement.value + "-" : "";

    if (this.skuText.length > 0 && this.skuText.substr(this.skuText.length - 1, 1) == "-") {
      this.skuText = this.skuText.substr(0, this.skuText.length - 1);
    }

    if (this.skuFinishWildCard.checked == true) {
      this.skuText += "*";
    }
  }


  selectProductType(productType: dtoProductType, textbox: HTMLInputElement) {

    if (this.selectedProd == productType) {
      this.selectedProd = null;
      textbox.value = "";
      this.buildSKUText();
      return;
    }

    if (this.selectedProd != productType) {
      this.selectedProd = productType;
      textbox.value = productType.txtProductCode;
      this.buildSKUText();
      return;
    }


  }

  selectProductProfile(productProfile: dtoProductProfile, textbox: HTMLInputElement) {
    if (this.selectedProdProfile == productProfile) {
      this.selectedProdProfile = null;
      textbox.value = "";
      this.buildSKUText();
      return;
    }

    if (this.selectedProdProfile != productProfile) {
      this.selectedProdProfile = productProfile;
      textbox.value = productProfile.txtProductCode;
      this.buildSKUText();
      return;
    }
  }

  selectSpecies(species: dtoSpecies, textbox: HTMLInputElement) {
    if (this.selectedSpec == species) {
      this.selectedSpec = null;
      textbox.value = "";
      this.buildSKUText();
      return;
    }

    if (this.selectedSpec != species) {
      this.selectedSpec = species;
      textbox.value = species.txtProductCode;
      this.buildSKUText();
      return;
    }
  }

  selectGrade(grade: dtoGrade, textbox: HTMLInputElement) {
    if (this.selectedGrade == grade) {
      this.selectedGrade = null;
      textbox.value = "";
      this.buildSKUText();
      return;
    }

    if (this.selectedGrade != grade) {
      this.selectedGrade = grade;
      textbox.value = grade.txtProductCode;
      this.buildSKUText();
      return;
    }
  }

  setWidthProductCode(value: string, textbox: HTMLInputElement) {
    textbox.value = value;
    this.buildSKUText();
  }

  setThicknessProductCode(value: string, textbox: HTMLInputElement) {
    textbox.value = value;
    this.buildSKUText();
  }

  setLengthProductCode(value: string, textbox: HTMLInputElement) {
    textbox.value = value;
    this.buildSKUText();
  }

  useWildCard(textbox: HTMLInputElement, checked: boolean) {

    if (checked == false) {
      textbox.value = "";
      this.buildSKUText();
      return;
    }

    if (checked == true) {
      textbox.value = "*";
      this.buildSKUText();
      return;
    }

  }

  filterProductType(name: string) {

    let productTypes: dtoProductType[] = this.productTypesAll;
    productTypes = productTypes.filter(a => { return (String(a.txtName).toLowerCase().indexOf(name.toLowerCase()) > -1) || (String(a.txtProductCode).toLowerCase().indexOf(name.toLowerCase()) > -1) });
    productTypes.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

    this.productTypes = productTypes;

  }

  filterProductProfile(name: string) {

    let productProfiles: dtoProductProfile[] = this.productProfilesAll;
    productProfiles = productProfiles.filter(a => { return (String(a.txtName).toLowerCase().indexOf(name.toLowerCase()) > -1) || (String(a.txtProductCode).toLowerCase().indexOf(name.toLowerCase()) > -1) });
    productProfiles.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

    this.productProfiles = productProfiles;

  }

  filterSpecies(name: string) {

    let species: dtoSpecies[] = this.speciesAll;
    species = species.filter(a => { return (String(a.txtName).toLowerCase().indexOf(name.toLowerCase()) > -1) || (String(a.txtProductCode).toLowerCase().indexOf(name.toLowerCase()) > -1) });
    species.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

    this.species = species;

  }

  filterGrade(name: string) {

    let grades: dtoGrade[] = this.gradesAll;
    grades = grades.filter(a => { return (String(a.txtName).toLowerCase().indexOf(name.toLowerCase()) > -1) || (String(a.txtProductCode).toLowerCase().indexOf(name.toLowerCase()) > -1) });
    grades.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

    this.grades = grades;

  }

  selectSKU() {
    this.productCodeSelected.emit(this.skuText);
  }

  cancelSKU() {
    this.productCodeCancelled.emit(true);
  }


}
