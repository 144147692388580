import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { HaulingCompany } from '../../_shared/business-objects/hauling-company.bo';
import { MobilePlantEquipment } from '../../_shared/business-objects/mobile-plant-equipment.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { MobilePlantEquipmentService } from '../../_shared/services/mobile-plant-equipment.service';

@Component({
  selector: 'app-mobile-plant-equipment-selection',
  templateUrl: './mobile-plant-equipment-selection.component.html',
  styleUrls: ['./mobile-plant-equipment-selection.component.css']
})
export class MobilePlantEquipmentSelectionComponent implements OnInit {

  @Input() EquipmentCategory: string;
  @Input() mobilePlantEquipmentObj: MobilePlantEquipment;
  @Input() chhVehicles: boolean;

  @Output() equipmentSelectionEvent = new EventEmitter<MobilePlantEquipment[]>();

  form: FormGroup;

  selectedMobilePlantEquipmentId: string = "";

  public mobilePlantEquipment: MobilePlantEquipment[] = [];
  public mobilePlantEquipmentAll: MobilePlantEquipment[] = [];


  constructor(private mobilePlantEquipmentService: MobilePlantEquipmentService, private alertService: AlertService, private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      guEquipmentId: [''],
    });

    this.loadData();

  }

  async loadData() {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    
    if (this.EquipmentCategory != "") {
      aParam.push(new SQLParamArray("guMobilePlantCategoryId", this.EquipmentCategory));
      aParamList.push(aParam);

    }

    let blankEquipment: MobilePlantEquipment = new MobilePlantEquipment();
    blankEquipment.rowguid = null;
    blankEquipment.txtName = "None";

    //console.log("EquipmentCategory: " + this.EquipmentCategory);

    this.mobilePlantEquipmentAll = [];
    this.mobilePlantEquipment = [];

      this.mobilePlantEquipmentAll = await this.mobilePlantEquipmentService.getMobilePlantEquipmentParamArrayPromise(aParamList);
      this.mobilePlantEquipmentAll.sort((a, b) => { return (a.intIdentifier - b.intIdentifier) });

      this.mobilePlantEquipment = this.mobilePlantEquipmentAll;
      //console.log("this.mobilePlantEquipment: ", this.mobilePlantEquipment);

      //this.mobilePlantEquipment = this.mobilePlantEquipment.sort((a, b) => { return (a.txtName < b.txtName ? 0 : 1) });
      //console.log("this.mobilePlantEquipment after sort: ", this.mobilePlantEquipment);
    //if (this.chhVehicles == true) {
    //}

    this.mobilePlantEquipment.splice(0, 0, blankEquipment);

    if (this.mobilePlantEquipmentObj != null) {
      this.selectedMobilePlantEquipmentId = this.mobilePlantEquipmentObj.rowguid;
    }

  }

  setSelectedMobilePlantEquipment(mobilePlantEquipment: MobilePlantEquipment) {

    this.mobilePlantEquipmentObj = mobilePlantEquipment;
    this.selectedMobilePlantEquipmentId = mobilePlantEquipment.rowguid;

  }


  filterEquipment(equipmentFilter: string) {
    this.mobilePlantEquipment = this.mobilePlantEquipmentAll;

    this.mobilePlantEquipment = this.mobilePlantEquipment.filter(a => a.txtName.toLowerCase().indexOf(equipmentFilter.toLowerCase()) > -1 || a.txtName == "None");

    //console.log("tableFilter: " + this.codeBuilderTables);

  }

  mobilePlantEquipmentSelect(mobilePlantEquipment: MobilePlantEquipment[]) {
    //console.log("selectedEquipment: ", mobilePlantEquipment);

    this.equipmentSelectionEvent.emit(mobilePlantEquipment);
  }

}
