export interface BoardTallyRecordDeliveryTransferred
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guBoardTallyRecordId: string;
	guDeliveryDocketStockTransferId: string;
	rowguid: string;
}

export class BoardTallyRecordDeliveryTransferred
{
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guBoardTallyRecordId: string;
	guDeliveryDocketStockTransferId: string;
	rowguid: string;
}

