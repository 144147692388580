import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { HaulingCompanyDriver } from '../business-objects/hauling-company-driver.bo';

@Injectable()
export class HaulingCompanyDriverService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getHaulingCompanyDriver(haulingCompanyDriverId: string) {
		return this.http.get<HaulingCompanyDriver>(this._baseUrl + 'api/HaulingCompanyDriver/' + haulingCompanyDriverId);
	}

	getHaulingCompanyDriverParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<HaulingCompanyDriver[]>(this._baseUrl + 'api/HaulingCompanyDriver/GetHaulingCompanyDriversParamArray/', aParam);
	}

	getAllHaulingCompanyDrivers() {
		return this.http.get<HaulingCompanyDriver[]>(this._baseUrl + 'api/HaulingCompanyDriver/GetAllHaulingCompanyDrivers/');
	}

	//getAlldtoHaulingCompanyDrivers() {
	//	return this.http.get<dtoHaulingCompanyDriver[]>(this._baseUrl + 'api/HaulingCompanyDriver/GetAllHaulingCompanyDrivers/');
	//}

  createHaulingCompanyDriver(haulingCompanyDriver: HaulingCompanyDriver) {
		return this.http.post<HaulingCompanyDriver>(this._baseUrl + 'api/HaulingCompanyDriver/CreateHaulingCompanyDriver/', haulingCompanyDriver);
	}

	updateHaulingCompanyDriver(haulingCompanyDriver: HaulingCompanyDriver) {
		return this.http.put<HaulingCompanyDriver>(this._baseUrl + 'api/HaulingCompanyDriver/UpdateHaulingCompanyDriver/', haulingCompanyDriver);
	}

  deleteHaulingCompanyDriver(haulingCompanyDriverId: string) {
    return this.http.delete(this._baseUrl + 'api/HaulingCompanyDriver/DeleteHaulingCompanyDriver/' + haulingCompanyDriverId);
  }

	async getHaulingCompanyDriverPromise(haulingCompanyDriverId: string) {
		return new Promise<HaulingCompanyDriver>(resolve => {
			this.getHaulingCompanyDriver(haulingCompanyDriverId).subscribe(haulingCompanyDriver => {
				resolve(haulingCompanyDriver);
			}, err => {
				this.alertService.openSnackBarError("Error getting HaulingCompanyDriver records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getHaulingCompanyDriverParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<HaulingCompanyDriver[]>(resolve => {
			this.getHaulingCompanyDriverParamArray(aParam).subscribe(haulingCompanyDrivers => {
				resolve(haulingCompanyDrivers);
			}, err => {
				this.alertService.openSnackBarError("Error getting HaulingCompanyDriver records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateHaulingCompanyDriverPromise(haulingCompanyDriver: HaulingCompanyDriver) {
		return new Promise<HaulingCompanyDriver>(resolve => {
			this.updateHaulingCompanyDriver(haulingCompanyDriver).subscribe(haulingCompanyDriver => {
				resolve(haulingCompanyDriver);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating HaulingCompanyDriver record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createHaulingCompanyDriverPromise(haulingCompanyDriver: HaulingCompanyDriver) {
		return new Promise<HaulingCompanyDriver>(resolve => {
			this.createHaulingCompanyDriver(haulingCompanyDriver).subscribe(haulingCompanyDriver => {
				resolve(haulingCompanyDriver);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating HaulingCompanyDriver record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async deleteHaulingCompanyDriverPromise(haulingCompanyDriverId: string) {
    return new Promise<boolean>(resolve => {
      this.deleteHaulingCompanyDriver(haulingCompanyDriverId).subscribe(haulingCompanyDriver => {
        resolve(true);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error deleting HaulingCompanyDriver record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

}
