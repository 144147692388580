import { Printer } from "../business-objects/printer.bo";

export interface PrintByteArray {
  byteArray: string;
  printer: Printer;
}

export class PrintByteArray {
  byteArray: string;
  printer: Printer;
}
