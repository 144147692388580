import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClientDeliveryAddress } from '../../_shared/business-objects/client-delivery-address.bo';

@Injectable()
export class CustomerDeliveryAddressService {

  public _baseUrl: string;

  public readonly CoffsHarbourStoreDeliveryAddressGuid: string = "22222222-2222-2222-2222-222222222222";
  public readonly GlenreaghStoreDeliveryAddressGuid: string = "33333333-3333-3333-3333-333333333333";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getAllDeliveryAddresses() {
    return this.http.get<ClientDeliveryAddress>(this._baseUrl + 'api/CustomerDeliveryAddress/GetAllAddresses');
  }

  getCustomerDeliveryAddresses(customerId: string) {
    return this.http.get<ClientDeliveryAddress[]>(this._baseUrl + 'api/CustomerDeliveryAddress/GetCustomersDeliveryAddressess/' + customerId);
  }

}  
