import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { CHHPoleStock } from '../business-objects/chhpole-stock.bo';

@Injectable()
export class CHHPoleStockService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getCHHPoleStock(cHHPoleStockId: string) {
		return this.http.get<CHHPoleStock>(this._baseUrl + 'api/CHHPoleStock/' + cHHPoleStockId);
	}

	getCHHPoleStockParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<CHHPoleStock[]>(this._baseUrl + 'api/CHHPoleStock/GetCHHPoleStocksParamArray/', aParam);
	}

	getAllCHHPoleStocks() {
		return this.http.get<CHHPoleStock[]>(this._baseUrl + 'api/CHHPoleStock/GetAllCHHPoleStocks/');
	}

	//getAlldtoCHHPoleStocks() {
	//	return this.http.get<dtoCHHPoleStock[]>(this._baseUrl + 'api/CHHPoleStock/GetAllCHHPoleStocks/');
	//}

	createCHHPoleStock(cHHPoleStock: CHHPoleStock) {
		return this.http.post<CHHPoleStock>(this._baseUrl + 'api/CHHPoleStock/CreateCHHPoleStock/', cHHPoleStock);
	}

	updateCHHPoleStock(cHHPoleStock: CHHPoleStock) {
		return this.http.put<CHHPoleStock>(this._baseUrl + 'api/CHHPoleStock/UpdateCHHPoleStock/', cHHPoleStock);
	}

	deleteCHHPoleStock(cHHPoleStockId: string) {
		return this.http.delete(this._baseUrl + 'api/CHHPoleStock/DeleteCHHPoleStock/' + cHHPoleStockId);
	}

	async getCHHPoleStockPromise(cHHPoleStockId: string) {
		return new Promise<CHHPoleStock>(resolve => {
			this.getCHHPoleStock(cHHPoleStockId).subscribe(cHHPoleStock => {
				resolve(cHHPoleStock);
			}, err => {
				this.alertService.openSnackBarError("Error getting CHHPoleStock records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getCHHPoleStockByPole(poleId: string) {
    return new Promise<CHHPoleStock>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guPoleId", poleId));
      aParamList.push(aParam);

      this.getCHHPoleStockParamArray(aParamList).subscribe(cHHPoleStocks => {
        if (cHHPoleStocks != null && cHHPoleStocks.length > 0) {
          resolve(cHHPoleStocks[0]);
        }
        resolve(null);

      }, err => {
        this.alertService.openSnackBarError("Error getting CHHPoleStock records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getCHHPoleStockParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<CHHPoleStock[]>(resolve => {
			this.getCHHPoleStockParamArray(aParam).subscribe(cHHPoleStocks => {
				resolve(cHHPoleStocks);
			}, err => {
				this.alertService.openSnackBarError("Error getting CHHPoleStock records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateCHHPoleStockPromise(cHHPoleStock: CHHPoleStock) {
		return new Promise<CHHPoleStock>(resolve => {
			this.updateCHHPoleStock(cHHPoleStock).subscribe(cHHPoleStock => {
				resolve(cHHPoleStock);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating CHHPoleStock record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createCHHPoleStockPromise(cHHPoleStock: CHHPoleStock) {
		return new Promise<CHHPoleStock>(resolve => {
			this.createCHHPoleStock(cHHPoleStock).subscribe(cHHPoleStock => {
				resolve(cHHPoleStock);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating CHHPoleStock record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deleteCHHPoleStockPromise(cHHPoleStockId: string) {
		return new Promise<boolean>(resolve => {
			this.deleteCHHPoleStock(cHHPoleStockId).subscribe(cHHPoleStock => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing CHHPoleStock record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
