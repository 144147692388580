<div class="app-page-column-update-container">
  <div class="app-page-column-update-form-container">
    <form class="app-page-column-update-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created From</mat-label>
              <input id="dteDateCreated" class="date-input created-from-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteDateCreated">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>App Page</mat-label>
              <mat-select formControlName="guAppPageId">
                <mat-option *ngFor="let appPage of appPages" [value]="appPage.rowguid">{{appPage.txtPageName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Column Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. txtName" value="" type="text" id="txtColumnName" formControlName="txtColumnName">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Column Name Alias</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. txtName" value="" type="text" id="txtColumnNameAlias" formControlName="txtColumnNameAlias">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Column Order</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 1" value="" type="number" id="intDefaultColumnOrder" formControlName="intDefaultColumnOrder">
              </mat-form-field>
            </div>
          </div>

          <button class="stocktakeButton" mat-flat-button color="primary" (click)="update()">Update</button>
          <button class="stocktakeButton" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
        </div>
      </div>

    </form>
  </div>
</div>
