export interface PoleTrimmingItemReasonForChange
{
	dteLastUpdated: Date;
	guPoleTrimmingItemId: string;
	guTrimmingItemReasonForChangeId: string;
	rowguid: string;
}

export class PoleTrimmingItemReasonForChange
{
	dteLastUpdated: Date;
	guPoleTrimmingItemId: string;
	guTrimmingItemReasonForChangeId: string;
	rowguid: string;
}

