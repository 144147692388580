<div class="app-page-column-list-container">
  <table mat-table #tblAppPageColumns [dataSource]="datasource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="txtPageName">
      <th mat-header-cell *matHeaderCellDef class="appPageColumnTH" mat-sort-header> Page Name </th>
      <td mat-cell *matCellDef="let appPageColumn" class="appPageColumnTD appPageName"> {{appPageColumn.txtPageName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtColumnName">
      <th mat-header-cell *matHeaderCellDef class="appPageColumnTH" mat-sort-header> Page Column </th>
      <td mat-cell *matCellDef="let appPageColumn" class="appPageColumnTD appPageColumnName"> {{appPageColumn.txtColumnName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtColumnNameAlias">
      <th mat-header-cell *matHeaderCellDef class="appPageColumnTH" mat-sort-header> Page Column Alias </th>
      <td mat-cell *matCellDef="let appPageColumn" class="appPageColumnTD appPageColumnNameAlias"> {{appPageColumn.txtColumnNameAlias}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="appPageColumnTH" mat-sort-header> Date Created </th>
      <td mat-cell *matCellDef="let appPageColumn" class="appPageColumnTD appPageDateCreated"> {{appPageColumn.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="intDefaultColumnOrder">
      <th mat-header-cell *matHeaderCellDef class="appPageColumnTH" mat-sort-header> Default Column Order </th>
      <td mat-cell *matCellDef="let appPageColumn" class="appPageColumnTD appPageColumnDefaultColumnOrder"> {{appPageColumn.intDefaultColumnOrder}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditAppPageColumn">
      <th mat-header-cell *matHeaderCellDef class="appPageColumnTH appPageColumnEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let appPageColumn" class="appPageColumnTD appPageColumnEdit">
        <button class="edit-final-inspection-item-button" type="button" mat-flat-button color="primary" (click)="editAppPageColumn(appPageColumn)">Edit</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveAppPageColumn">
      <th mat-header-cell *matHeaderCellDef class="appPageColumnTH appPageColumnRemoveTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let appPageColumn" class="appPageColumnTD appPageColumnRemove">
        <button class="remove-final-inspection-item-button" type="button" mat-flat-button color="primary" (click)="removeAppPageColumn(appPageColumn)">Remove</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedAppPageColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedAppPageColumns;">
    </tr>

  </table>
  <div class="no-stock-items" *ngIf="datasource == null || datasource.data.length == 0"><h7>There are no app pages</h7></div>
</div>


