import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoDeliveryDocketItem } from '../business-objects/dto-delivery-docket-item.bo';

@Injectable()
export class dtoDeliveryDocketItemService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoDeliveryDocketItem(dtoDeliveryDocketItemId: string) {
		return this.http.get<dtoDeliveryDocketItem>(this._baseUrl + 'api/dtoDeliveryDocketItem/' + dtoDeliveryDocketItemId);
	}

	getdtoDeliveryDocketItemParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoDeliveryDocketItem[]>(this._baseUrl + 'api/dtoDeliveryDocketItem/GetdtoDeliveryDocketItemsParamArray/', aParam);
	}

	getAlldtoDeliveryDocketItems() {
		return this.http.get<dtoDeliveryDocketItem[]>(this._baseUrl + 'api/dtoDeliveryDocketItem/GetAlldtoDeliveryDocketItems/');
	}

	//getAlldtodtoDeliveryDocketItems() {
	//	return this.http.get<dtodtoDeliveryDocketItem[]>(this._baseUrl + 'api/dtoDeliveryDocketItem/GetAlldtoDeliveryDocketItems/');
	//}

	createdtoDeliveryDocketItem(dtoDeliveryDocketItem: dtoDeliveryDocketItem) {
		return this.http.post<dtoDeliveryDocketItem>(this._baseUrl + 'api/dtoDeliveryDocketItem/CreatedtoDeliveryDocketItem/', dtoDeliveryDocketItem);
	}

	updatedtoDeliveryDocketItem(dtoDeliveryDocketItem: dtoDeliveryDocketItem) {
		return this.http.put<dtoDeliveryDocketItem>(this._baseUrl + 'api/dtoDeliveryDocketItem/UpdatedtoDeliveryDocketItem/', dtoDeliveryDocketItem);
	}

	async getdtoDeliveryDocketItemPromise(dtoDeliveryDocketItemId: string) {
		return new Promise<dtoDeliveryDocketItem>(resolve => {
			this.getdtoDeliveryDocketItem(dtoDeliveryDocketItemId).subscribe(dtoDeliveryDocketItem => {
				resolve(dtoDeliveryDocketItem);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDeliveryDocketItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoDeliveryDocketItemParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoDeliveryDocketItem[]>(resolve => {
			this.getdtoDeliveryDocketItemParamArray(aParam).subscribe(dtoDeliveryDocketItems => {
				resolve(dtoDeliveryDocketItems);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoDeliveryDocketItem records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoDeliveryDocketItemPromise(dtoDeliveryDocketItem: dtoDeliveryDocketItem) {
		return new Promise<dtoDeliveryDocketItem>(resolve => {
			this.updatedtoDeliveryDocketItem(dtoDeliveryDocketItem).subscribe(dtoDeliveryDocketItem => {
				resolve(dtoDeliveryDocketItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoDeliveryDocketItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoDeliveryDocketItemPromise(dtoDeliveryDocketItem: dtoDeliveryDocketItem) {
		return new Promise<dtoDeliveryDocketItem>(resolve => {
			this.createdtoDeliveryDocketItem(dtoDeliveryDocketItem).subscribe(dtoDeliveryDocketItem => {
				resolve(dtoDeliveryDocketItem);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoDeliveryDocketItem record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}