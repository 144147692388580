import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeliveryDocketCreateComponent } from '../../delivery-docket/delivery-docket-create/delivery-docket-create.component';
import { DeliveryDocketUpdateComponent } from '../../delivery-docket/delivery-docket-update/delivery-docket-update.component';
import { CustomerContact } from '../../_shared/business-objects/customer-contact.bo';
import { CustomerContactCreateComponent } from '../customer-contact-create/customer-contact-create.component';

@Component({
  selector: 'app-customer-contact-create-dialog',
  templateUrl: './customer-contact-create-dialog.component.html',
  styleUrl: './customer-contact-create-dialog.component.css'
})
export class CustomerContactCreateDialogComponent {

  customerName: string;
  customerId: string;

  constructor(private deliveryUpdateRef: MatDialogRef<DeliveryDocketUpdateComponent>, private deliveryCreateRef: MatDialogRef<DeliveryDocketCreateComponent>, @Inject(MAT_DIALOG_DATA) private data: { customerId: string, customerName: string }) {

    this.customerId = data.customerId;
    this.customerName = data.customerName;

  }


  customerContactCreated(customerContactCreatedObj: [CustomerContact, string]) {

    if (this.deliveryUpdateRef != null) {
      this.deliveryUpdateRef.close({ status: "Saved", customerContact: customerContactCreatedObj[0] });
    }

    if (this.deliveryCreateRef != null) {
      this.deliveryCreateRef.close({ status: "Saved", customerContact: customerContactCreatedObj[0] });
    }

  }

  customerContactCancelled(cancelled: boolean) {

    if (this.deliveryUpdateRef != null) {
      this.deliveryUpdateRef.close({ status: "Cancel", customerContact: null });
    }

    if (this.deliveryCreateRef != null) {
      this.deliveryCreateRef.close({ status: "Cancel", customerContact: null });
    }


  }

}
