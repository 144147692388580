<div class="hauling-company-driver-selection-container">
  <div class="hauling-company-driver-selection-form" [formGroup]="form">
    <div class="hauling-company-driver">
      <mat-selection-list class="hauling-company-driver-select" #guHaulingCompanyDriverId [multiple]="false" formControlName="guHaulingCompanyDriverId" (selectionChange)="haulingCompanyDriverSelect(guHaulingCompanyDriverId._value)">
        <mat-list-option *ngFor="let haulingCompanyDriver of haulingCompanyDrivers" [value]="haulingCompanyDriver" [selected]="selectedHaulingCompanyDriver == null ? haulingCompanyDriver.rowguid == null : haulingCompanyDriver.rowguid == selectedHaulingCompanyDriver.rowguid">
          {{haulingCompanyDriver.txtFirstName}} {{haulingCompanyDriver.txtLastName}} {{haulingCompanyDriver.blnDefaultDriver == true ? ' - (Default Driver)' : ''}}
        </mat-list-option>
      </mat-selection-list>
      <br />
    </div>
  </div>
</div>
