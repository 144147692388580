import { Printer } from "../business-objects/printer.bo";

export interface PoleTag {
  txtPoleNumber: string;
  txtSize: string;
  txtLogoBase64: string;
  Printer: Printer;
}

export class PoleTag {
  txtPoleNumber: string;
  txtSize: string;
  txtLogoBase64: string;
  Printer: Printer;
}
