import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { MobilePlantCategory } from '../business-objects/mobile-plant-category.bo';

@Injectable()
export class MobilePlantCategoryService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getMobilePlantCategory(mobilePlantCategoryId: string) {
		return this.http.get<MobilePlantCategory>(this._baseUrl + 'api/MobilePlantCategory/' + mobilePlantCategoryId);
	}

	getMobilePlantCategoryParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<MobilePlantCategory[]>(this._baseUrl + 'api/MobilePlantCategory/GetMobilePlantCategoryParamArray/', aParam);
	}

	getAllMobilePlantCategorys() {
		return this.http.get<MobilePlantCategory[]>(this._baseUrl + 'api/MobilePlantCategory/GetAllMobilePlantCategory/');
	}

	//getAlldtoMobilePlantCategorys() {
	//	return this.http.get<dtoMobilePlantCategory[]>(this._baseUrl + 'api/MobilePlantCategory/GetAllMobilePlantCategory/');
	//}

	createMobilePlantCategory(mobilePlantCategory: MobilePlantCategory) {
		return this.http.post<MobilePlantCategory>(this._baseUrl + 'api/MobilePlantCategory/CreateMobilePlantCategory/', mobilePlantCategory);
	}

	updateMobilePlantCategory(mobilePlantCategory: MobilePlantCategory) {
		return this.http.put<MobilePlantCategory>(this._baseUrl + 'api/MobilePlantCategory/UpdateMobilePlantCategory/', mobilePlantCategory);
	}

	async getMobilePlantCategoryPromise(mobilePlantCategoryId: string) {
		return new Promise<MobilePlantCategory>(resolve => {
			this.getMobilePlantCategory(mobilePlantCategoryId).subscribe(mobilePlantCategory => {
				resolve(mobilePlantCategory);
			}, err => {
				this.alertService.openSnackBarError("Error getting MobilePlantCategory records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getMobilePlantCategoryParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<MobilePlantCategory[]>(resolve => {
			this.getMobilePlantCategoryParamArray(aParam).subscribe(mobilePlantCategory => {
				resolve(mobilePlantCategory);
			}, err => {
				this.alertService.openSnackBarError("Error getting MobilePlantCategory records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getMobilePlantCategoryId(category: string) {
    return new Promise<string>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("txtName", category));
      aParamList.push(aParam);

      this.getMobilePlantCategoryParamArray(aParamList).subscribe(mobilePlantCategory => {

        if (mobilePlantCategory != null && mobilePlantCategory.length > 0) {
          resolve(mobilePlantCategory[0].rowguid);
        }
        else {
          resolve("");
        }

      }, err => {
        this.alertService.openSnackBarError("Error getting MobilePlantCategory records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async updateMobilePlantCategoryPromise(mobilePlantCategory: MobilePlantCategory) {
		return new Promise<MobilePlantCategory>(resolve => {
			this.updateMobilePlantCategory(mobilePlantCategory).subscribe(mobilePlantCategory => {
				resolve(mobilePlantCategory);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating MobilePlantCategory record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createMobilePlantCategoryPromise(mobilePlantCategory: MobilePlantCategory) {
		return new Promise<MobilePlantCategory>(resolve => {
			this.createMobilePlantCategory(mobilePlantCategory).subscribe(mobilePlantCategory => {
				resolve(mobilePlantCategory);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating MobilePlantCategory record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}
