export interface MicrosoftGraphFileAttachment {
  constructor(name: string, contentType: string, contentBytes: string)
  '@odata.type': string;
  name: string;
  contentType: string;
  contentBytes: string;
}

export class MicrosoftGraphFileAttachment {
  constructor(name: string, contentType: string, contentBytes: string) {
    this["@odata.type"] = '#microsoft.graph.fileAttachment';
    this.name = name;
    this.contentType = contentType;
    this.contentBytes = contentBytes;
  }

  '@odata.type': string;
  name: string;
  contentType: string;
  contentBytes: string;
}
