import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoCustomer } from '../business-objects/dto-customer.bo';

@Injectable()
export class dtoCustomerService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoCustomer(dtoCustomerId: string) {
		return this.http.get<dtoCustomer>(this._baseUrl + 'api/dtoCustomer/' + dtoCustomerId);
	}

	getdtoCustomerParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoCustomer[]>(this._baseUrl + 'api/dtoCustomer/GetdtoCustomersParamArray/', aParam);
	}

	getAlldtoCustomers() {
		return this.http.get<dtoCustomer[]>(this._baseUrl + 'api/dtoCustomer/GetAlldtoCustomers/');
	}

	//getAlldtodtoCustomers() {
	//	return this.http.get<dtodtoCustomer[]>(this._baseUrl + 'api/dtoCustomer/GetAlldtoCustomers/');
	//}

	createdtoCustomer(dtoCustomer: dtoCustomer) {
		return this.http.post<dtoCustomer>(this._baseUrl + 'api/dtoCustomer/CreatedtoCustomer/', dtoCustomer);
	}

	updatedtoCustomer(dtoCustomer: dtoCustomer) {
		return this.http.put<dtoCustomer>(this._baseUrl + 'api/dtoCustomer/UpdatedtoCustomer/', dtoCustomer);
	}

	async getdtoCustomerPromise(dtoCustomerId: string) {
		return new Promise<dtoCustomer>(resolve => {
			this.getdtoCustomer(dtoCustomerId).subscribe(dtoCustomer => {
				resolve(dtoCustomer);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoCustomer records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoCustomerParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoCustomer[]>(resolve => {
			this.getdtoCustomerParamArray(aParam).subscribe(dtoCustomers => {
				resolve(dtoCustomers);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoCustomer records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoCustomerByName(name: string) {
    return new Promise<dtoCustomer>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("txtName", name));
      aParamList.push(aParam);


      this.getdtoCustomerParamArray(aParamList).subscribe(dtoCustomers => {
        if (dtoCustomers != null && dtoCustomers.length > 0) {
          resolve(dtoCustomers[0]);
        }
        resolve(null);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoCustomer records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getAlldtoCustomer() {
    return new Promise<dtoCustomer[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];

      aParam.push(new SQLParamArray("blnSystemCustomer", "0"));
      aParam.push(new SQLParamArray("blnInternalCustomer", "0"));

      this.getdtoCustomerParamArray(aParamList).subscribe({
        next: ((dtoCustomers) => {
          resolve(dtoCustomers);
        }),
        error: (err: HttpErrorResponse) => {
          this.alertService.openSnackBarError("Error getting dtoCustomer records", "Close", "center", "bottom", 4000, true, err.message);
          resolve(null);
        },
      });
    });
  }

	async updatedtoCustomerPromise(dtoCustomer: dtoCustomer) {
		return new Promise<dtoCustomer>(resolve => {
			this.updatedtoCustomer(dtoCustomer).subscribe(dtoCustomer => {
				resolve(dtoCustomer);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoCustomer record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoCustomerPromise(dtoCustomer: dtoCustomer) {
		return new Promise<dtoCustomer>(resolve => {
			this.createdtoCustomer(dtoCustomer).subscribe(dtoCustomer => {
				resolve(dtoCustomer);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoCustomer record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

}
