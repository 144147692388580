<div class="final-inspection-create-container">
  <div class="final-inspection-create-form-container">
    <form class="final-inspection-create-form" [formGroup]="form">
      <div class="header-container">
        <div class="fields-container">

          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Date Created From</mat-label>
              <input id="dteDateCreated" class="date-input created-from-date-search" #searchDateCreatedFrom matInput [matDatepicker]="pickercreatedfrom" formControlName="dteDateCreated">
              <mat-datepicker-toggle matSuffix [for]="pickercreatedfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickercreatedfrom></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Page Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Stock Items" value="" type="text" id="txtAppPageName" formControlName="txtAppPageName">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Page URL</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. stock-items" value="" type="text" id="txtAppPageURL" formControlName="txtAppPageURL">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Object Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. stock-items" value="" type="text" id="txtAppPageObjectName" formControlName="txtAppPageObjectName">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
                <mat-label>Object Alias Name</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. stock-items" value="" type="text" id="txtAppPageObjectAliasName" formControlName="txtAppPageObjectAliasName">
              </mat-form-field>
            </div>
          </div>

          <div class="header-row-4">
            <div class="header-row-3-container">
              <label id="example-radio-group-label">App</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="radApp">
                <mat-radio-button class="example-radio-button" *ngFor="let app of apps" [value]="app.intValue">
                  {{app.txtValue}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <button class="stocktakeButton" mat-flat-button color="primary" (click)="updateAppPage()">Update App Page</button>
          <button class="stocktakeButton" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div>
