import { Component, Input, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { Code128autoBarcodeComponent } from '../../../utilities/barcode/code128auto-barcode/code128auto-barcode.component';
import { Printer } from '../../../_shared/business-objects/printer.bo';
import { AlertService } from '../../../_shared/services/alert.service';
import { PrintService } from '../../../_shared/services/app-services/print.service';

@Component({
  selector: 'app-barcode-sticker-large',
  templateUrl: './barcode-sticker-large.component.html',
  styleUrl: './barcode-sticker-large.component.scss'
})
export class BarcodeStickerLargeComponent {

  @Input() data: string;
  @ViewChild('barcodeId1') barcodeId1: Code128autoBarcodeComponent;

  lineSize: number = 3;
  fontSize: number = 60;

  barcode: boolean;


  showButton: boolean;

  printer: Printer;


  constructor(private route: ActivatedRoute, private alertService: AlertService, private printService: PrintService) { }

  ngOnInit(): void {

    //this.barcode = true;
    this.showButton = true;

    this.route.queryParams.subscribe(async params => {

      if (params != null) {
        this.data = params["data"] == null ? "" : params["data"];
        this.barcode = params["barcode1"] == null ? false : params["barcode1"] == "true";
        console.log("params: ", params["barcode1"] == null ? true : params["barcode1"] == "true");
        console.log("params[barcode1]: ", params["barcode1"]);
      if (this.data.length > 22) {
          this.lineSize = 2;
        }
        if (this.data != null && this.data != "" && params["print"] != "No") {

          //this.print();
        }

      }

    });

    this.setPrinter();
  }

 
  async print() {

    console.log("barcode", this.data);

    if (this.data == null) {
      this.alertService.openSnackBarDefault("There is no data for the barcode");
      return;
    }



    let promptuser = window.print
    window.print();
    return;


  }

  setData(val: MatInput) {
    this.barcode = true;

    if (val.value != null && val.value != "") {
      this.data = val.value;
      this.barcodeId1.setBarcodeText(val.value);
      this.showButton = true;
      //console.log(val.value);
    }

    if (val.value == null || val.value == "") {
      this.barcode = false;
      this.showButton = false;
      //console.log("setting barcode 1 to false");
    }

  }

  async setPrinter() {
    this.printer = await this.printService.getPrinterByNamePromise("WEB-APP-BP", Printer.enType.Mobile);
  }


  printBarcode(val: string) {

    this.printService.printPoleBarcode(val, this.printer)

  }

}
