import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { SpinnerInlineTickComponent } from '../../utilities/spinner-inline-tick/spinner-inline-tick.component';
import { ClientDeliveryAddress } from '../../_shared/business-objects/client-delivery-address.bo';
import { dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { AddressService } from '../../_shared/services/address';
import { AlertService } from '../../_shared/services/alert.service';
import { ClientDeliveryAddressService } from '../../_shared/services/client-delivery-address.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-customer-delivery-address-create',
  templateUrl: './customer-delivery-address-create.component.html',
  styleUrl: './customer-delivery-address-create.component.scss',
  animations: [
    trigger(
      'postcodeAnimation',
      [state('showPostcodes', style({
        height: '300px'
      })),
        state('closePostcodes', style({
          height: '0px'
      })),
        transition('* => showPostcodes', [
        animate('500ms')
      ]),
        transition('* => closePostcodes', [
        animate('500ms')
      ]),]
    )
  ]
})
export class CustomerDeliveryAddressCreateComponent {
  form: FormGroup;

  @Input() customerId: string;
  @Input() customerName: string;
  @Output() customerDeliveryAddressCreatedEvent = new EventEmitter<ClientDeliveryAddress>();
  @Output() customerDeliveryAddressCancelledEvent = new EventEmitter<boolean>();

  @ViewChild('spinnerInlineTick') spinnerInlineTick: SpinnerInlineTickComponent;
  states: dtoKeyValue[] = [];
  postcodes: Object[];

  showSpinner: boolean;
  showPostcodeSpinner: boolean = false;
  showTick: boolean = false;
  addressComments: string = ""
  noPostcodeFound: boolean = false;

  constructor(private formBuilder: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private customerDeliveryAddressService: ClientDeliveryAddressService, private formService: FormService, private addressService: AddressService) {

  }


  ngOnInit(): void {

    this.states = this.sharedService.getEnumObject2(SharedService.enStates);

    this.form = this.formBuilder.group({
      txtCustomerName: [this.customerName],
      txtStreetAddress: [''],
      txtTown: ['', Validators.required],
      txtState: [['NSW']],
      txtPostcode: [''],
      txtCountry: ['Australia'],
      txtSiteRegionName: [''],
      txtContactFirstName: [''],
      txtContactLastName: [''],
      txtDeliveryEmailAddressTo: [''],
      txtDeliveryEmailAddressCC: [''],
      txtComments: [''],
      blnActive: [1]
    });

    this.showSpinner = false;

    this.formService.setAutoFocusTimeout("txtFirstName", 300);

  }

  async lookupPostcode(town: string, state: string) {

    this.noPostcodeFound = false;
    this.showPostcodeSpinner = true;
    this.postcodes = null;

    let postCodes: Object[];
    let postCodePromise = this.addressService.getPostCodePromise(town, state);

    await postCodePromise.then(res => postCodes = res);

    console.log("postcode: ", postCodes);

    this.showPostcodeSpinner = false;

    if (postCodes != null && Array.isArray(postCodes) && postCodes.length > 0) {

      setTimeout(() => { this.showTick = true }, 500);

      setTimeout(() => { this.showTick = false }, 600);

      if (postCodes.length > 1) {
        this.postcodes = postCodes;
        return;
      }


      if (this.form.controls['txtPostcode'].value != null && this.form.controls['txtPostcode'].value != "") {
        let msg: string = await this.alertService.openSnackBarCustomPromise("Existing Postcode", "There is an existing Postcode in the postcode field.\nDo you want to overwrite it?", "Yes", "No", "center", "bottom", "", 0, true);

        if (msg != "Yes") {
          return;
        }
      }

      this.form.controls['txtPostcode'].setValue(postCodes[0]['postcode']);

    }

    console.log("show tick false");

    this.showTick = false;
    this.noPostcodeFound = true;


  }

  async selectPostcode(postcode: string) {

    if (postcode == "") {
      return;
    }

    if (this.form.controls['txtPostcode'].value != null && this.form.controls['txtPostcode'].value != "") {
      let msg: string = await this.alertService.openSnackBarCustomPromise("Existing Postcode", "There is an existing Postcode in the postcode field.\nDo you want to overwrite it?", "Yes", "No", "center", "bottom", "", 0, true);

      if (msg != "Yes") {
        return;
      }
    }

    this.form.controls['txtPostcode'].setValue(postcode);

    this.postcodes = null;

  }


  async createCustomerDeliveryAddress() {

    let address: string = this.form.controls['txtStreetAddress'].value;
    let town: string = this.form.controls['txtTown'].value;
    let state: string = this.form.controls['txtState'].value[0];
    let postcode: string = this.form.controls['txtPostcode'].value;
    let country: string = this.form.controls['txtCountry'].value;
    let siteRegionName: string = this.form.controls['txtSiteRegionName'].value;
    let contactFirstName: string = this.form.controls['txtContactFirstName'].value;
    let contactLastName: string = this.form.controls['txtContactLastName'].value;
    let emailTo: string = this.form.controls['txtDeliveryEmailAddressTo'].value;
    let emailCC: string = this.form.controls['txtDeliveryEmailAddressCC'].value;
    let comments: string = this.form.controls['txtComments'].value;
    let active: boolean = (this.form.controls['blnActive'].value == 1);


    if (town == null || town == "") {
      this.alertService.openSnackBar("Please enter a town.", "Close", "center", "bottom", "", 3000);
      return;
    }


    let customerDeliveryAddress: ClientDeliveryAddress = new ClientDeliveryAddress;
    customerDeliveryAddress.guClientId = this.customerId;
    customerDeliveryAddress.txtAddress = address;
    customerDeliveryAddress.txtTown = town;
    customerDeliveryAddress.txtState = state;
    customerDeliveryAddress.txtPostcode = postcode.toString();
    customerDeliveryAddress.txtCountry = country;
    customerDeliveryAddress.txtSiteRegionName = siteRegionName;
    customerDeliveryAddress.txtContactFirstName = contactFirstName;
    customerDeliveryAddress.txtContactLastName = contactLastName;
    customerDeliveryAddress.txtDeliveryEmailAddressTo = emailTo;
    customerDeliveryAddress.txtDeliveryEmailAddressCC = emailCC;
    customerDeliveryAddress.txtComments = comments;
    customerDeliveryAddress.blnActive = active;


    this.showSpinner = true;

    console.log(customerDeliveryAddress);

    customerDeliveryAddress = await this.customerDeliveryAddressService.createClientDeliveryAddressPromise(customerDeliveryAddress);

    if (customerDeliveryAddress != null) {
      console.log(customerDeliveryAddress.rowguid);
      customerDeliveryAddress = await this.customerDeliveryAddressService.getClientDeliveryAddressPromise(customerDeliveryAddress.rowguid);


      this.alertService.openSnackBar("The customer delivery address has been created", "Close", "center", "bottom", "", 3000);
      this.showSpinner = false;
      this.customerDeliveryAddressCreatedEvent.emit(customerDeliveryAddress);
    }


    if (customerDeliveryAddress == null) {
      this.showSpinner = false;
      this.alertService.openSnackBarError("There was a problem creating the customer delivery address", "Close", "center", "bottom", 4000, true, "There was a problem creating the customer delivery address");
    }

  }

  cancelCustomerDeliveryAddress() {
    this.customerDeliveryAddressCancelledEvent.emit(true);

  }

}
