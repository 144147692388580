import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoLoadingDocketDelivery } from '../business-objects/dto-loading-docket-delivery.bo';

@Injectable()
export class dtoLoadingDocketDeliveryService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoLoadingDocketDelivery(dtoLoadingDocketDeliveryId: string) {
		return this.http.get<dtoLoadingDocketDelivery>(this._baseUrl + 'api/dtoLoadingDocketDelivery/' + dtoLoadingDocketDeliveryId);
	}

	getdtoLoadingDocketDeliveryParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoLoadingDocketDelivery[]>(this._baseUrl + 'api/dtoLoadingDocketDelivery/GetdtoLoadingDocketDeliverysParamArray/', aParam);
	}

	getAlldtoLoadingDocketDeliverys() {
		return this.http.get<dtoLoadingDocketDelivery[]>(this._baseUrl + 'api/dtoLoadingDocketDelivery/GetAlldtoLoadingDocketDeliverys/');
	}

	//getAlldtodtoLoadingDocketDeliverys() {
	//	return this.http.get<dtodtoLoadingDocketDelivery[]>(this._baseUrl + 'api/dtoLoadingDocketDelivery/GetAlldtoLoadingDocketDeliverys/');
	//}

	createdtoLoadingDocketDelivery(dtoLoadingDocketDelivery: dtoLoadingDocketDelivery) {
		return this.http.post<dtoLoadingDocketDelivery>(this._baseUrl + 'api/dtoLoadingDocketDelivery/CreatedtoLoadingDocketDelivery/', dtoLoadingDocketDelivery);
	}

	updatedtoLoadingDocketDelivery(dtoLoadingDocketDelivery: dtoLoadingDocketDelivery) {
		return this.http.put<dtoLoadingDocketDelivery>(this._baseUrl + 'api/dtoLoadingDocketDelivery/UpdatedtoLoadingDocketDelivery/', dtoLoadingDocketDelivery);
	}

	async getdtoLoadingDocketDeliveryPromise(dtoLoadingDocketDeliveryId: string) {
		return new Promise<dtoLoadingDocketDelivery>(resolve => {
			this.getdtoLoadingDocketDelivery(dtoLoadingDocketDeliveryId).subscribe(dtoLoadingDocketDelivery => {
				resolve(dtoLoadingDocketDelivery);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoLoadingDocketDelivery records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getdtoLoadingDocketDeliveryParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoLoadingDocketDelivery[]>(resolve => {
			this.getdtoLoadingDocketDeliveryParamArray(aParam).subscribe(dtoLoadingDocketDeliverys => {
				resolve(dtoLoadingDocketDeliverys);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoLoadingDocketDelivery records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async updatedtoLoadingDocketDeliveryPromise(dtoLoadingDocketDelivery: dtoLoadingDocketDelivery) {
		return new Promise<dtoLoadingDocketDelivery>(resolve => {
			this.updatedtoLoadingDocketDelivery(dtoLoadingDocketDelivery).subscribe(dtoLoadingDocketDelivery => {
				resolve(dtoLoadingDocketDelivery);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoLoadingDocketDelivery record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createdtoLoadingDocketDeliveryPromise(dtoLoadingDocketDelivery: dtoLoadingDocketDelivery) {
		return new Promise<dtoLoadingDocketDelivery>(resolve => {
			this.createdtoLoadingDocketDelivery(dtoLoadingDocketDelivery).subscribe(dtoLoadingDocketDelivery => {
				resolve(dtoLoadingDocketDelivery);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoLoadingDocketDelivery record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

}