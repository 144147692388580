<div class="loading-docket-view-container">
  <div [ngClass]="showStrengthGroup3 == true ? 'loading-docket-view-table3' : showStrengthGroups == true ? 'loading-docket-view-table' : 'loading-docket-view-table2'">
    <div class="loading-docket-item-no-header item-row-header"></div>
    <div class="loading-docket-description-header item-row-header">Description</div>
    <div class="loading-docket-s1-header item-row-header alignRight" *ngIf="showStrengthGroups == true">{{showStrengthGroups == true ? 'S1' : ''}}</div>
    <div class="loading-docket-s2-header item-row-header alignRight" *ngIf="showStrengthGroups == true">{{showStrengthGroups == true ? 'S2' : ''}}</div>
    <div class="loading-docket-s3-header item-row-header alignRight" *ngIf="showStrengthGroup3 == true">{{showStrengthGroup3 == true ? 'S3' : ''}}</div>
    <div class="loading-docket-bbt-header item-row-header alignRight" *ngIf="showStrengthGroups == true">{{showStrengthGroups == true ? 'BBT' : ''}}</div>
    <div class="loading-docket-qty-header item-row-header alignRight">Qty</div>
    <div class="loading-docket-qty-uom-header item-row-header">Qty UoM</div>
    <div class="loading-docket-total-m3-header item-row-header alignRight">Total M3</div>
    <div class="loading-docket-weight-header item-row-header alignRight">Weight (T)</div>
    <div class="loading-docket-destination-header item-row-header">Destination</div>

    <ng-container *ngFor="let deliveryDocketOrderItem of deliveryDocketOrderItems; let idx = index">

      <div *ngIf="(idx == 0 || idx > 0 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx-1].guDeliveryDocketId)" [ngClass]="(idx > 0 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx-1].guDeliveryDocketId) ? showStrengthGroup3 == true ? 'delivery-docket-heading-table3 delivery-docket-heading-table-padding' : showStrengthGroups == true ? 'delivery-docket-heading-table delivery-docket-heading-table-padding' : 'delivery-docket-heading-table2 delivery-docket-heading-table-padding' : showStrengthGroup3 == true ? 'delivery-docket-heading-table3' : showStrengthGroups == true ? 'delivery-docket-heading-table' : 'delivery-docket-heading-table2'">
        <div class="delivery-docket-number">
          {{deliveryDocketOrderItem.intDocketNo}}
        </div>
        <div class="customer-name">{{deliveryDocketOrderItem.txtCustomerName}} :</div>
        <div class="customer-order-number"> {{deliveryDocketOrderItem.txtOrderNumbers}}</div>
        <div class="edit-customer-delivery-address">
          <button mat-button (click)="openDeliveryAddressDialog(deliveryDocketOrderItem)" matTooltip="Click to edit Customer Delivery Address" [ngClass]="isPrintPage == false ? 'edit-customer-delivery-address-button' : 'edit-customer-delivery-address-button-hidden'">
            <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
          </button>
        </div>
      </div>
      <div class="loading-docket-item-no item-row alignRight">{{idx + 1}}</div>
      <div [ngClass]="deliveryDocketOrderItem.blnMultipleOrders == false ? 'loading-docket-description2 item-row' : showStrengthGroup3 ? 'loading-docket-strength-group-description3 item-row' : showStrengthGroups ? 'loading-docket-strength-group-description item-row' : deliveryDocketOrderItem.blnMultipleOrders == true ? 'loading-docket-description item-row' : 'loading-docket-description2 item-row'">
        <div [ngClass]="deliveryDocketOrderItem.blnMultipleOrders == true ? 'loading-docket-description-cell' : 'loading-docket-description-cell2'">
          {{deliveryDocketOrderItem.txtDescription}}
          <div *ngIf="deliveryDocketOrderItem.txtComments != null" [innerHTML]="deliveryDocketOrderItem.txtComments | safeHtml">{{deliveryDocketOrderItem.txtComments}}</div>
        </div>
        <div class="order-number" *ngIf="deliveryDocketOrderItem.blnMultipleOrders">{{deliveryDocketOrderItem.txtOrderNo}}</div>
      </div>
      <div class="item-row alignRight loading-docket-s1" *ngIf="showStrengthGroups == true || showStrengthGroup3 == true">{{deliveryDocketOrderItem.intS1}}</div>
      <div class="item-row alignRight loading-docket-s2" *ngIf="showStrengthGroups == true || showStrengthGroup3 == true">{{deliveryDocketOrderItem.intS2}}</div>
      <div class="item-row alignRight loading-docket-s3" *ngIf="showStrengthGroup3 == true">{{deliveryDocketOrderItem.intS3}}</div>
      <div class="item-row alignRight loading-docket-bbt" *ngIf="showStrengthGroups == true || showStrengthGroup3 == true">{{deliveryDocketOrderItem.intBBT}}</div>
      <div class="item-row alignRight loading-docket-qty">{{deliveryDocketOrderItem.fltBoardTallyRecordQuantity != null && deliveryDocketOrderItem.fltBoardTallyRecordQuantity > 0 ? deliveryDocketOrderItem.fltBoardTallyRecordQuantity : deliveryDocketOrderItem.fltQuantity}}</div>
      <div class="loading-docket-qty-uom item-row">{{deliveryDocketOrderItem.txtOrderItemQuantityUom}}</div>
      <div class="loading-docket-total-m3 item-row alignRight">{{deliveryDocketOrderItem.fltActualM3 | number : '1.2-2' }}</div>
      <div class="loading-docket-weight item-row alignRight">{{deliveryDocketOrderItem.fltActualTonne | number : '1.2-2'}}</div>
      <div class="item-row loading-docket-destination">{{deliveryDocketOrderItem.txtAlternateTown}}</div>

      <div class="loading-docket-item-no-footer item-footer alignRight" *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)"></div>
      <div *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)" [ngClass]="showStrengthGroup3 == true ? 'loading-docket-description-footer3 item-footer alignRight' : showStrengthGroups == true ? 'loading-docket-description-footer item-footer alignRight' : 'loading-docket-description-footer2 item-footer alignRight'">
        <span *ngIf="deliveryDocketOrderItem.totalQtyBoxes > 0" [ngClass]="deliveryDocketOrderItem.totalQtyLM > 0 || deliveryDocketOrderItem.totalQtyEach > 0 || deliveryDocketOrderItem.totalQtyM2 > 0 ? 'item-footer-boxes' : ''">{{deliveryDocketOrderItem.totalQtyBoxes + (deliveryDocketOrderItem.uomMultiples == true ? ' Boxes' : '')}}</span>
        <span *ngIf="deliveryDocketOrderItem.totalQtyM2 > 0" [ngClass]="deliveryDocketOrderItem.totalQtyLM > 0 || deliveryDocketOrderItem.totalQtyEach > 0 ? 'item-footer-m2' : ''">{{deliveryDocketOrderItem.totalQtyM2 + (deliveryDocketOrderItem.uomMultiples == true ? ' M2' : '')}}</span>
        <span *ngIf="deliveryDocketOrderItem.totalQtyLM > 0" [ngClass]="deliveryDocketOrderItem.totalQtyEach > 0 ? 'item-footer-lm' : ''">{{deliveryDocketOrderItem.totalQtyLM + (deliveryDocketOrderItem.uomMultiples == true ? ' LM' : '')}}</span>
        <span *ngIf="deliveryDocketOrderItem.totalQtyEach > 0">{{deliveryDocketOrderItem.totalQtyEach}}</span>
      </div>


      <div class="loading-docket-qty-uom-footer item-footer" *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)"></div>
      <div class="loading-docket-total-m3-footer item-footer alignRight" *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)">
        {{deliveryDocketOrderItem.totalM3}}
      </div>
      <div class="loading-docket-weight-footer item-footer item-footer-weight alignRight" *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)">
        {{deliveryDocketOrderItem.totalTonne}}
      </div>
      <div class="loading-docket-destination-footer item-footer" *ngIf="(idx == deliveryDocketOrderItems.length - 1 || idx < deliveryDocketOrderItems.length - 1 && deliveryDocketOrderItem.guDeliveryDocketId != deliveryDocketOrderItems[idx+1].guDeliveryDocketId)"></div>

      <div class="loading-docket-item-no-total-footer item-footer grand-total-footer alignRight" *ngIf="idx == deliveryDocketOrderItems.length - 1"></div>
      <div class="" *ngIf="idx == deliveryDocketOrderItems.length - 1" [ngClass]="showStrengthGroup3 == true ? 'loading-docket-description-total-footer3 item-footer grand-total-footer alignRight' : showStrengthGroups == true ? 'loading-docket-description-total-footer item-footer grand-total-footer alignRight' : 'loading-docket-description-total-footer2 item-footer grand-total-footer alignRight'">
        <div class="grand-total-label">Totals</div>
        <div *ngIf="deliveryDocketOrderItem.grandTotalQtyBoxes > 0" [ngClass]="deliveryDocketOrderItem.grandTotalQtyLM > 0 || deliveryDocketOrderItem.grandTotalQtyEach > 0 || deliveryDocketOrderItem.grandTotalQtyM2 > 0 ? 'grand-total-item-footer-boxes' : ''">{{deliveryDocketOrderItem.grandTotalQtyBoxes + (deliveryDocketOrderItem.uomMultiples == true ? ' Boxes' : '')}}</div>
        <div *ngIf="deliveryDocketOrderItem.grandTotalQtyM2 > 0" [ngClass]="deliveryDocketOrderItem.grandTotalQtyLM > 0 || deliveryDocketOrderItem.grandTotalQtyEach > 0 ? 'grand-total-item-footer-m2' : ''">{{deliveryDocketOrderItem.grandTotalQtyM2 + (deliveryDocketOrderItem.uomMultiples == true ? ' M2' : '')}}</div>
        <div *ngIf="deliveryDocketOrderItem.grandTotalQtyLM > 0" [ngClass]="deliveryDocketOrderItem.grandTotalQtyEach > 0 ? 'grand-total-item-footer-lm' : ''">{{deliveryDocketOrderItem.grandTotalQtyLM + (deliveryDocketOrderItem.uomMultiples == true ? ' LM' : '')}}</div>
        <div *ngIf="deliveryDocketOrderItem.grandTotalQtyEach > 0">{{deliveryDocketOrderItem.grandTotalQtyEach}}</div>
      </div>
      <div class="loading-docket-qty-uom-total-footer item-footer grand-total-footer" *ngIf="idx == deliveryDocketOrderItems.length - 1"></div>
      <div class="loading-docket-total-m3-total-footer item-footer grand-total-footer alignRight" *ngIf="idx == deliveryDocketOrderItems.length - 1">
        {{deliveryDocketOrderItem.grandTotalM3}}
      </div>
      <div class="loading-docket-weight-total-footer item-footer item-footer-weight grand-total-footer alignRight" *ngIf="idx == deliveryDocketOrderItems.length - 1">
        {{deliveryDocketOrderItem.grandTotalTonne}} T
      </div>
      <div class="loading-docket-destination-total-footer item-footer grand-total-footer" *ngIf="idx == deliveryDocketOrderItems.length - 1"></div>

    </ng-container>
  </div>

</div>
