import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-product-code-builder-dialog',
  templateUrl: './product-code-builder-dialog.component.html',
  styleUrl: './product-code-builder-dialog.component.scss'
})
export class ProductCodeBuilderDialogComponent {
  selectedProductCode: string;

  constructor(private productCodeDialogRef: MatDialogRef<ProductCodeBuilderDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { selectedProductCode: string }) {

    this.selectedProductCode = data.selectedProductCode;

  }


  productCodeSelected(productCode: string) {

    this.productCodeDialogRef.close({ status: "Saved", productCode: productCode });

  }

  productCodeCancelled(cancelled: boolean) {

    this.productCodeDialogRef.close({ status: "Cancel", productCode: "" });

  }
}
