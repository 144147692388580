import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoAppUserColumnSet } from '../business-objects/dto-app-user-column-set.bo';

@Injectable()
export class dtoAppUserColumnSetService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoAppUserColumnSet(dtoAppUserColumnSetId: string) {
		return this.http.get<dtoAppUserColumnSet>(this._baseUrl + 'api/dtoAppUserColumnSet/' + dtoAppUserColumnSetId);
	}

	getdtoAppUserColumnSetParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoAppUserColumnSet[]>(this._baseUrl + 'api/dtoAppUserColumnSet/GetdtoAppUserColumnSetsParamArray/', aParam);
	}

	getAlldtoAppUserColumnSets() {
		return this.http.get<dtoAppUserColumnSet[]>(this._baseUrl + 'api/dtoAppUserColumnSet/GetAlldtoAppUserColumnSets/');
	}

	//getAlldtodtoAppUserColumnSets() {
	//	return this.http.get<dtodtoAppUserColumnSet[]>(this._baseUrl + 'api/dtoAppUserColumnSet/GetAlldtoAppUserColumnSets/');
	//}

	createdtoAppUserColumnSet(dtoAppUserColumnSet: dtoAppUserColumnSet) {
		return this.http.post<dtoAppUserColumnSet>(this._baseUrl + 'api/dtoAppUserColumnSet/CreatedtoAppUserColumnSet/', dtoAppUserColumnSet);
	}

	updatedtoAppUserColumnSet(dtoAppUserColumnSet: dtoAppUserColumnSet) {
		return this.http.put<dtoAppUserColumnSet>(this._baseUrl + 'api/dtoAppUserColumnSet/UpdatedtoAppUserColumnSet/', dtoAppUserColumnSet);
	}

	async getdtoAppUserColumnSetPromise(dtoAppUserColumnSet) {
		return new Promise<dtoAppUserColumnSet>(resolve => {
			this.getdtoAppUserColumnSet(dtoAppUserColumnSet).subscribe(dtoAppUserColumnSet => {
				resolve(dtoAppUserColumnSet);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppUserColumnSet records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async getdtoAppUserColumnSetParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoAppUserColumnSet[]>(resolve => {
			this.getdtoAppUserColumnSetParamArray(aParam).subscribe(dtoAppUserColumnSets => {
				resolve(dtoAppUserColumnSets);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoAppUserColumnSet records", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async updatedtoAppUserColumnSetPromise(dtoAppUserColumnSet: dtoAppUserColumnSet) {
		return new Promise<dtoAppUserColumnSet>(resolve => {
			this.updatedtoAppUserColumnSet(dtoAppUserColumnSet).subscribe(dtoAppUserColumnSet => {
				resolve(dtoAppUserColumnSet);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoAppUserColumnSet record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

	async createdtoAppUserColumnSetPromise(dtoAppUserColumnSet) {
		return new Promise<dtoAppUserColumnSet>(resolve => {
			this.createdtoAppUserColumnSet(dtoAppUserColumnSet).subscribe(dtoAppUserColumnSet => {
				resolve(dtoAppUserColumnSet);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoAppUserColumnSet record", "Close", "center", "bottom", 4000, true, err.error);
				resolve(null);
			});
		});
	}

}