import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../account/auth.service';
import { Printer } from '../_shared/business-objects/printer.bo';
import { SharedService } from '../_shared/services/shared.service';
import { AlertService } from '../_shared/services/alert.service';

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { PrintService } from '../_shared/services/app-services/print.service';



@Component({
  selector: 'app-pole-barcode',
  templateUrl: './pole-barcode.component.html',
  styleUrls: ['./pole-barcode.component.css']
})
export class PoleBarcodeComponent implements OnInit {

  public _baseUrl: string;
  treatmentPlantPrinter: Printer;
  form: FormGroup;


  constructor(private fb: FormBuilder, private _snackBar: MatSnackBar, private http: HttpClient, private authService: AuthService, private sharedService: SharedService,
    @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private printService: PrintService) {

    this._baseUrl = baseUrl;

    this.form = this.fb.group({
      txtIdentifier: ['']
    });

}

  ngOnInit(): void {

    //// 2 is mobile printer.
    ////this.sharedService.PrintServerURI
    //this.http.get<Printer>(this._baseUrl + 'api/AppPrinter/GetPrintersByAppNameAndType/WEB-APP-TP/2').subscribe(printer => {
    //  //console.log("treatment plant printer");
    //  //console.log(printer[0]);

    //  this.treatmentPlantPrinter = printer[0];
    //  console.log(JSON.stringify(printer[0]));

    //}, err => {
    //  console.log(err);

    //});

    this.setPrinter();


  }

  async setPrinter() {
    this.treatmentPlantPrinter = await this.printService.getPrinterByNamePromise("WEB-APP-TP", Printer.enType.Mobile);
    console.log("printer:", this.treatmentPlantPrinter);
  }


  printPoleBarcode() {
    //console.log(this.form.controls["txtIdentifier"].value);
    if (this.form.controls["txtIdentifier"].value == "") {
      this.alertService.openSnackBarError("You must enter a pole number", "Close", "center", "bottom", 2000, false, "");
      return false;
    }

    let poleNumber: number = this.form.controls["txtIdentifier"].value;

    let path: string = this.sharedService.PrintServerURI;
    //path = "https://localhost:44358/";

    //https://localhost:44358
    //this.sharedService.PrintServerURI
    this.http.post(path + 'api/Print/PrintPoleBarcode/' + poleNumber.toString(), this.treatmentPlantPrinter, { responseType: 'blob' }).subscribe(pdf => {
      //let mimeType: string = 'application/pdf';
      //let blob: any = new Blob([pdf], { type: mimeType });
      //const url = window.URL.createObjectURL(blob);
      //console.log(blob);
      //window.open(url);


      //saveAs(blob, 'testFile.txt');
      //saveAs(blob, 'testFile.jpg');
      //saveAs(blob, 'testPDF.pdf');

      this.alertService.openSnackBarDefault("The barcode has printed " + poleNumber.toString());

    }, (err: HttpErrorResponse) => {
      let errConsole: HTMLElement = document.getElementById("errorConsole");
      let errConsoleMessage: HTMLElement = document.getElementById("errorConsoleMessage");
      errConsole.innerHTML = err.error;
      errConsoleMessage.innerHTML = err.message;

      console.log(err);
      this.alertService.openSnackBarDefault("error \n" + err.message);

      this.alertService.openSnackBarError("Error printing barcode\n" + err.error, "Close", "center", "bottom", 4000, true, err.message);
    });;

  }

}
