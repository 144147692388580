<div class="delivery-docket-final-items-preview-container">

  <table mat-table #tblDeliveryDocketOrderItem [dataSource]="deliveryOrderItemsDatasource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="txtOrderNo">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Order No </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD deliveryDocketOrderItemtxtOrderNo"> {{deliveryDocketOrderItem.txtOrderNo}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD footerTD"> Total </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerName">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Customer </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD deliveryDocketOrderItemtxtCustomerName"> {{deliveryDocketOrderItem.txtCustomerName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="txtProductTypeName">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Product </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD deliveryDocketOrderItemtxtProductName"> {{deliveryDocketOrderItem.txtProductTypeName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="txtPoleLengthKN">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH" mat-sort-header> Final Pole </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalFinalPole"> {{deliveryDocketOrderItem.txtPoleLengthKN}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fltQuantityOnOrder">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Quantity<br />On Order </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalQuantityOnOrder alignRightTD"> {{deliveryDocketOrderItem.fltQuantityTotal}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD"> </td>
    </ng-container>

    <ng-container matColumnDef="fltQtyBal">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Quantity<br />Bal </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalQuantityOnOrder alignRightTD"> {{deliveryDocketOrderItem.fltQtyBal}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD"> </td>
    </ng-container>

    <ng-container matColumnDef="intFinalQuantity">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Linked Final<br />Quantity </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalFinalQuantity alignRightTD"> {{deliveryDocketOrderItem.intFinalQuantity}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD"> {{deliveryDocketOrderItemFinalQtyTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="fltQuantity">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight headerQuantity" arrowPosition="before" mat-sort-header> Quantity<br />On Docket </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD quantity alignRightTD"> {{deliveryDocketOrderItem.fltQuantity}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD footerQuantity"> {{deliveryDocketOrderItemQtyTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="intFinalItemsToAdd">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight headerFinalItemsToAdd" arrowPosition="before" mat-sort-header> Final Items<br />To Add </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalQuantityToAdd alignRightTD"> {{deliveryDocketOrderItem.intFinalItemsToAdd}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD footerFinalItemsToAdd"> {{deliveryDocketOrderItemFinalItemsToAddQtyTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="intFinalItemsToAddTotal">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Qty<br />Total </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalQuantityToAddTotal alignRightTD"> {{deliveryDocketOrderItem.intFinalItemsToAddTotal}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD"> {{deliveryDocketOrderItemFinalItemsToAddQtyTotalTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualM3">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Linked Final<br />Actual M3 </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalActualM3 alignRightTD"> {{RoundNumber(deliveryDocketOrderItem.fltActualM3,3)}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD"> {{deliveryDocketOrderItemM3Total}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualTonne">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight headerActualTonne" arrowPosition="before" mat-sort-header> Linked Final<br />Actual Tonne </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD actualTonne alignRightTD"> {{RoundNumber(deliveryDocketOrderItem.fltActualTonne,3)}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD footerActualTonne"> {{deliveryDocketOrderItemTonneTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="fltFinalItemsToAddTotalActualM3">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Actual M3<br />Total </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalActualM3 alignRightTD"> {{RoundNumber(deliveryDocketOrderItem.fltFinalItemsToAddTotalActualM3,3)}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD"> {{deliveryDocketOrderItemFinalItemsToAddM3Total}} </td>
    </ng-container>

    <ng-container matColumnDef="fltFinalItemsToAddTotalActualTonne">
      <th mat-header-cell *matHeaderCellDef class="deliveryDocketOrderItemTH alignRight" arrowPosition="before" mat-sort-header> Actual Tonne<br />Total </th>
      <td mat-cell *matCellDef="let deliveryDocketOrderItem" class="deliveryDocketOrderItemTD finalActualTonne alignRightTD"> {{RoundNumber(deliveryDocketOrderItem.fltFinalItemsToAddTotalActualTonne,3)}} </td>
      <td mat-footer-cell *matFooterCellDef class="deliveryDocketOrderItemTD alignRightTD footerTD footerActualTonne"> {{deliveryDocketOrderItemFinalItemsToAddTonneTotal}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedDeliveryDocketPreview"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedDeliveryDocketPreview;let deliveryDocketOrderItem;"></tr>

    <tr mat-footer-row *matFooterRowDef="displayedDeliveryDocketPreview"></tr>
  </table>
  <div class="no-stock-items" *ngIf="deliveryOrderItemsDatasource == null"><h7>There are no preview items</h7></div>
</div>

