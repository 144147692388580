import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dtoTPPoleInspectionItemSummary } from '../../_shared/business-objects/dto-tppole-inspection-item-summary.bo';
import { dtoTPPoleInspectionItem } from '../../_shared/business-objects/dto-tppole-inspection-item.bo';
import { dtoTPPoleInspection } from '../../_shared/business-objects/dto-tppole-inspection.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { dtoTPPoleInspectionItemSummaryService } from '../../_shared/services/dto-tppole-inspection-item-summary.service';
import { dtoTPPoleInspectionItemService } from '../../_shared/services/dto-tppole-inspection-item.service';
import { dtoTPPoleInspectionService } from '../../_shared/services/dto-tppole-inspection.service';
import { FinalInspectionItemListComponent } from '../final-inspection-item-list/final-inspection-item-list.component';

@Component({
  selector: 'app-final-inspection-item-print',
  templateUrl: './final-inspection-item-print.component.html',
  styleUrls: ['./final-inspection-item-print.component.css']
})
export class FinalInspectionItemPrintComponent implements OnInit {

  @ViewChild('appfinalinspectionitemlist') appfinalinspectionitemlist: FinalInspectionItemListComponent;

  @Input() finalInspectionIdInput: string;
  @Input() printPDFMode: boolean;

  finalInspectionId: string = "";
  finalInspection: dtoTPPoleInspection;
  finalInspectionItems: dtoTPPoleInspectionItem[] = [];
  finalInspectionItemSummary: dtoTPPoleInspectionItemSummary[] = [];
  finalInspectionItemSummaryS1QtyTotal: number;
  finalInspectionItemSummaryS2QtyTotal: number;
  finalInspectionItemSummaryS3QtyTotal: number;
  finalInspectionItemSummaryBBTQtyTotal: number;
  finalInspectionItemSummaryQtyTotal: number;
  finalInspectionItemSummaryM3Total: number;
  finalInspectionItemSummaryTonneTotal: number;
  openPrintDialog: string = "";


  constructor(private dtoTPPoleInspectionItemService: dtoTPPoleInspectionItemService, private dtoTPPoleInspectionItemSummaryService: dtoTPPoleInspectionItemSummaryService
    , private dtoTPPoleInspectionService: dtoTPPoleInspectionService, private route: ActivatedRoute, private alertService: AlertService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(async params => {

      if (params != null || true) {
        // The loading docket can be supplied from the @Input so it will override the param value.
        this.finalInspectionId = params["finalinspectionid"];
        this.openPrintDialog = params["openprintdialog"];
      }
    });

    //console.log("printPDFMode", this.printPDFMode);

    this.loadData();
  }

  async loadData() {

    if (this.finalInspectionId == null || this.finalInspectionId == "") {
      if (this.printPDFMode == false) {
        this.alertService.openSnackBarDefault("There is no final inspection id");
      }
      return;
    }


    this.finalInspection = await this.dtoTPPoleInspectionService.getdtoTPPoleInspectionPromise(this.finalInspectionId);


    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guTPPoleInspectionId", this.finalInspection.rowguid));
    aParamList.push(aParam);

    this.finalInspectionItems = await this.dtoTPPoleInspectionItemService.getdtoTPPoleInspectionItemParamArrayPromise(aParamList);


    console.log(this.finalInspectionItems);

    await this.loadFinalInspectionItemSummary(this.finalInspection);

    this.appfinalinspectionitemlist.calcTotals();

    if (this.openPrintDialog == "yes") {
      setTimeout(() => { this.print() }, 500);
    }

  }

  async loadFinalInspectionItemSummary(finalInspection: dtoTPPoleInspection) {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("intFinalNo", finalInspection.intFinalNo.toString()));
    aParamList.push(aParam);

    this.finalInspectionItemSummary = await this.dtoTPPoleInspectionItemSummaryService.getdtoTPPoleInspectionItemSummaryParamArrayPromise(aParamList);

    this.finalInspectionItemSummaryS1QtyTotal = 0;
    this.finalInspectionItemSummaryS2QtyTotal = 0;
    this.finalInspectionItemSummaryS3QtyTotal = 0;
    this.finalInspectionItemSummaryBBTQtyTotal = 0;
    this.finalInspectionItemSummaryQtyTotal = 0;
    this.finalInspectionItemSummaryM3Total = 0;
    this.finalInspectionItemSummaryTonneTotal = 0;

    if (this.finalInspectionItemSummary != null) {
      this.finalInspectionItemSummary.forEach((finalSum, index, arr) => {
        this.finalInspectionItemSummaryS1QtyTotal += finalSum.intS1;
        this.finalInspectionItemSummaryS2QtyTotal += finalSum.intS2;
        this.finalInspectionItemSummaryS3QtyTotal += finalSum.intS3;
        this.finalInspectionItemSummaryBBTQtyTotal += finalSum.intBBT;
        this.finalInspectionItemSummaryQtyTotal += finalSum.intTotalQuantity;
        this.finalInspectionItemSummaryM3Total += finalSum.fltActualM3;
        this.finalInspectionItemSummaryTonneTotal += finalSum.fltActualTonne;
      });

      this.finalInspectionItemSummaryTonneTotal = Math.round((this.finalInspectionItemSummaryTonneTotal + Number.EPSILON) * 100) / 100;
      this.finalInspectionItemSummaryM3Total = Math.round((this.finalInspectionItemSummaryM3Total + Number.EPSILON) * 100) / 100;
    }
    //console.log("finalInspectionItemSummaryTotal: " + this.finalInspectionItemSummaryTotal);
  }

  async print() {

    console.log("loading docket", this.finalInspection);

    if (this.finalInspection == null) {
      this.alertService.openSnackBarDefault("There is no final inspection to print.");
      return;
    }

    window.print();

  }

}
