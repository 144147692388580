<form class="stock-transfer-create-form" [formGroup]="form">
  <div class="create-stock-transfer-container">
    <div class="header-container">
      <div class="header-row-1">
        <h1>Create Stock Transfer</h1>
      </div>

      <div class="header-row-2">
        <h6>Geo Location From</h6>
        <mat-button-toggle-group #locationToggle name="fontStyle" aria-label="Font Style" formControlName="guGeoStockLocationFromId" color="accent" *ngIf="showFromLocations">
          <mat-button-toggle *ngFor="let geoStockLocation of geoStockLocations" [value]="geoStockLocation.rowguid" (change)="onFromGeoStockLocationSelect(geoStockLocation)" [disabled]="showMyLocations(geoStockLocation.rowguid)">{{geoStockLocation.txtShortName}}</mat-button-toggle>
        </mat-button-toggle-group>
        <br />
        <br />
      </div>

      <div class="header-row-3">
        <h6>Geo Location Too</h6>
        <mat-button-toggle-group #locationToggle name="fontStyle" aria-label="Font Style" formControlName="guGeoStockLocationTooId" color="accent" *ngIf="showFromLocations">
          <mat-button-toggle *ngFor="let geoStockLocation of geoStockLocations" [value]="geoStockLocation.rowguid" (change)="onToGeoStockLocationSelect(geoStockLocation)" [disabled]="showMyLocationsTo(geoStockLocation.rowguid)">{{geoStockLocation.txtShortName}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="header-row-4">
        <div class="header-row-4-container">
          <div class="header-row-4-column-1">
            <mat-form-field class="example-full-width example-container" appearance="fill">
              <mat-label>Identifier</mat-label>
              <input class="stock-identifier-input" matInput placeholder="" value="" id="txtIdentifier" formControlName="txtIdentifier">
              <mat-error *ngIf="false"></mat-error>
            </mat-form-field>
            <button class="stocktakeButton" mat-flat-button color="primary" (click)="attachStock()">Attach Stock</button>
          </div>
          <div class="header-row-4-column-2">
            <button class="stocktakeButton" mat-flat-button color="primary" (click)="createStockTransfer()" [disabled]="this.stockItems.length == 0">Create Stock Transfer Delivery Docket</button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-container">
      <table mat-table #tblStockItems [dataSource]="stockItems" class="mat-elevation-z8">

        <ng-container matColumnDef="txtIdentifier">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Identifier </th>
          <td mat-cell *matCellDef="let StockItem" class="identifierCell stockitemTD"> {{StockItem.intIdentifier}} </td>
        </ng-container>

        <ng-container matColumnDef="txtProductName">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Product </th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemProduct"> {{StockItem.txtProductName}} </td>
        </ng-container>

        <ng-container matColumnDef="txtProfileName">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Profile </th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemProfile"> {{StockItem.txtProfileAbbrievation}} </td>
        </ng-container>

        <ng-container matColumnDef="intWidth">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemWidthTH"> W </th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemWidth"> {{StockItem.intWidth}} </td>
        </ng-container>

        <ng-container matColumnDef="sizeSeparator">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"></th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemSizeSeparator">x</td>
        </ng-container>

        <ng-container matColumnDef="intThickness">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"> T </th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemThickness"> {{StockItem.intThickness}} </td>
        </ng-container>

        <ng-container matColumnDef="txtSpeciesName">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Species </th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemSpecies"> {{StockItem.txtSpeciesName}} </td>
        </ng-container>

        <ng-container matColumnDef="txtGradeName">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Grade </th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemGrade"> {{StockItem.txtGradeName}} </td>
        </ng-container>

        <ng-container matColumnDef="fltQuantity">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemQuantityTH"> Qty </th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemQuantity"> {{StockItem.fltQuantity}} </td>
        </ng-container>

        <ng-container matColumnDef="txtQuantityUoM">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"> UoM </th>
          <td mat-cell *matCellDef="let StockItem" [ngClass]="{'stockItemQuantityUOMCenter' : StockItem.txtQuantityUoM.trim()=='Each'}" class="stockitemTD stockItemQuantityUOM"><div>{{(StockItem.txtQuantityUoM.trim()=='Each' && StockItem.intTotalLM > 0) ? '/' : StockItem.txtQuantityUoM}} </div></td>
        </ng-container>

        <ng-container matColumnDef="intTotalLM">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemLengthTH"> Length </th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemLength"><div *ngIf="(StockItem.txtQuantityUoM.trim()=='Each' && StockItem.intTotalLM > 0)"> {{StockItem.intTotalLM}} </div></td>
        </ng-container>

        <ng-container matColumnDef="txtContainerLocation">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Location </th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemLocation"> {{StockItem.txtContainerLocation}} </td>
        </ng-container>

        <ng-container matColumnDef="txtStockType">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Type </th>
          <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemLocation">{{StockItem.txtStockContainerTypeName}}</td>
        </ng-container>

        <ng-container matColumnDef="btnRemove">
          <th mat-header-cell *matHeaderCellDef class="stockitemTH"> </th>
          <td mat-cell *matCellDef="let StockItem; let rowIdx = index;" class="stockitemTD"><button type="button" class="stocktakeButton" mat-flat-button color="primary" id="btnRemove{{rowIdx}}" (click)="removeFromStockTransfer(StockItem, rowIdx)">Remove</button></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedStockItems"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedStockItems;"></tr>
      </table>
    </div>

    <div class="footer-container">
      
    </div>

  </div>
</form>
