<h1 class="boardtallyrecord-title">Board Plant Stocktake</h1>
<br>

<form class="stocktake-form" [formGroup]="form">
  <div class="search-toggle-buttons">
    <div class="stock-mode">
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="radMode" color="accent">
        <mat-button-toggle value="stocktake" (click)="onModeSelect()">Stocktake Mode</mat-button-toggle>
        <mat-button-toggle value="search" (click)="onModeSelect()" [checked]="true">Search Mode</mat-button-toggle>
      </mat-button-toggle-group>

    </div>

    <div class="geo-locations-container">
      <div class="search-column-3">
        <div class="header-row-2">
          <span class="geo-location-label">Geo Location</span>
          <mat-button-toggle-group #locationToggle name="fontStyle" aria-label="Font Style" formControlName="guGeoStockLocationId" color="accent">
            <mat-button-toggle *ngFor="let geoStockLocation of geoStockLocations" [value]="geoStockLocation.rowguid">{{geoStockLocation.txtShortName}}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </div>


  <br />
  <br />

  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Identifier</mat-label>
    <input matInput placeholder="" value="" id="txtStocktakeIdentifier" formControlName="txtStocktakeIdentifier" (keyup.enter)="onSubmit('')">
  </mat-form-field>&nbsp;

  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Location Area</mat-label>
    <input matInput mask="S-0-00" placeholder="i.e  P016" value="" formControlName="txtStocktakeLocation" #stocktakeLocation class="toUpperCase" (input)="stocktakeLocation.value = stocktakeLocation.value.toString().toUpperCase()" (keyup.enter)="onSubmit('')">
  </mat-form-field>

  <br />
  <mat-radio-group formControlName="radIncludeNonStocktake" aria-label="Select an option" class="radioIncludeNonStocktake">
    <mat-radio-button value="0" [checked]="true">Stocktake Only&nbsp;&nbsp;</mat-radio-button>
    <mat-radio-button value="1">Non Stocktake Only&nbsp;&nbsp;</mat-radio-button>
    <mat-radio-button value="2">Both&nbsp;&nbsp;</mat-radio-button>
  </mat-radio-group>

  <span class="archived-items">
      <mat-checkbox class="example-margin" #blnArchived id="blnArchived" formControlName="blnArchived">Include Archived Stock Items</mat-checkbox>
  </span>

  <br />
  <br />

</form>

<button class="stocktakeButton" mat-flat-button color="primary" (click)="onSubmit('stocktake')" [disabled]="formMode=='search'? true : null">Mark As Stock Checked</button>&nbsp;&nbsp;
<button class="stocktakeButton" mat-flat-button color="primary" (click)="onSubmit('search')" [disabled]="formMode=='stocktake'? true : null">Search Stock</button>
<div *ngIf="stockDoesntExist" class="stockDoesntExist">The stock does not exist.</div>
<mat-card *ngIf="stockDoesntExist">{{ errorMessage.message }}</mat-card>
<mat-card class="stocktakeTotalsCard">
  Total Stock Items: {{stockItemsCol.intTotalStockItems}}
</mat-card>

<table mat-table [dataSource]="bpStocktake" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
  <ng-container matColumnDef="txtIdentifier">
    <th mat-header-cell *matHeaderCellDef> Identifier </th>
    <td mat-cell *matCellDef="let dtoStockItem" class="identifierCell"> {{dtoStockItem.intIdentifier}} </td>
  </ng-container>

  <ng-container matColumnDef="productName">
    <th mat-header-cell *matHeaderCellDef> Product </th>
    <td mat-cell *matCellDef="let dtoStockItem"> {{dtoStockItem.txtProductName}} </td>
  </ng-container>

  <ng-container matColumnDef="intWidth">
    <th mat-header-cell *matHeaderCellDef> Width </th>
    <td mat-cell *matCellDef="let dtoStockItem"> {{dtoStockItem.intWidth}} </td>
  </ng-container>

  <ng-container matColumnDef="intThickness">
    <th mat-header-cell *matHeaderCellDef> Thickness </th>
    <td mat-cell *matCellDef="let dtoStockItem"> {{dtoStockItem.intThickness}} </td>
  </ng-container>

  <ng-container matColumnDef="speciesName">
    <th mat-header-cell *matHeaderCellDef> Species </th>
    <td mat-cell *matCellDef="let dtoStockItem"> {{dtoStockItem.txtSpeciesName}} </td>
  </ng-container>

  <ng-container matColumnDef="gradeName">
    <th mat-header-cell *matHeaderCellDef> Grade </th>
    <td mat-cell *matCellDef="let dtoStockItem"> {{dtoStockItem.txtGradeName}} </td>
  </ng-container>

  <ng-container matColumnDef="fltQuantity">
    <th mat-header-cell *matHeaderCellDef> Quantity </th>
    <td mat-cell *matCellDef="let dtoStockItem"> {{dtoStockItem.fltQuantity}} </td>
  </ng-container>

  <ng-container matColumnDef="txtQuantityUoM">
    <th mat-header-cell *matHeaderCellDef> Quantity UoM </th>
    <td mat-cell *matCellDef="let dtoStockItem"><div *ngIf="dtoStockItem.txtQuantityUoM!='Each'"> {{dtoStockItem.txtQuantityUoM}} </div></td>
  </ng-container>

  <ng-container matColumnDef="intTotalLM">
    <th mat-header-cell *matHeaderCellDef> Length </th>
    <td mat-cell *matCellDef="let dtoStockItem"><div *ngIf="dtoStockItem.txtQuantityUoM=='Each'"> {{dtoStockItem.intTotalLM}} </div></td>
  </ng-container>

  <ng-container matColumnDef="txtLocation">
    <th mat-header-cell *matHeaderCellDef> Location </th>
    <td mat-cell *matCellDef="let dtoStockItem"> {{dtoStockItem.txtContainerLocation}} </td>
  </ng-container>

  <ng-container matColumnDef="txtStockTake">
    <th mat-header-cell *matHeaderCellDef> Stocktake </th>
    <td mat-cell *matCellDef="let dtoStockItem"> {{dtoStockItem.txtStockTake}} </td>
  </ng-container>

  <ng-container matColumnDef="dteStockTake">
    <th mat-header-cell *matHeaderCellDef> Stocktake Date </th>
    <td mat-cell *matCellDef="let dtoStockItem"> {{dtoStockItem.dteContainerStockTake | date:'dd/MM/yyyy h:mm a'}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
