import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PoleTrimingItemReasonForChange } from '../business-objects/obsolete/pole-trimming-item-reason-for-change.class';


@Injectable()
export class TrimingItemReasonForChangeService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getAllTrimingItemReasonForChangeService() {
    return this.http.get<PoleTrimingItemReasonForChange[]>(this._baseUrl + 'api/TrimingItemReasonForChange/GetAll');
  }


}  
