import { Component } from '@angular/core';

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrl: './order-search.component.scss'
})
export class OrderSearchComponent {

}
