import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppPage } from '../../_shared/business-objects/app-page.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { SharedService } from '../../_shared/services/shared.service';
import { AppPageService } from '../../_shared/services/app-page.service';
import { AppService } from '../../_shared/services/app-services/app.service';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AppUserColumnSet } from '../../_shared/business-objects/app-user-column-set.bo';
import { AppUser } from '../../_shared/business-objects/app-user.bo';
import { AppUserColumnSetService } from '../../_shared/services/app-user-column-set.service';
import { AppUserService } from '../../_shared/services/app-user.service';
import { AppUserColumn } from '../../_shared/business-objects/app-user-column.bo';

@Component({
  selector: 'app-app-user-column-set-create',
  templateUrl: './app-user-column-set-create.component.html',
  styleUrls: ['./app-user-column-set-create.component.css']
})
export class AppUserColumnSetCreateComponent implements OnInit {

  appUsers: AppUser[];
  appPages: AppPage[];

  form: FormGroup;

  @Output() appUserColumnSetCreated = new EventEmitter<AppUserColumnSet>();
  @Output() appUserColumnSetCancelled = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private appService: AppService, private sharedService: SharedService, private alertService: AlertService
    , private appPageService: AppPageService, private appUserColumnSetService: AppUserColumnSetService, private appUserService: AppUserService) {

    const datepipe: DatePipe = new DatePipe('en-AU');

    this.form = this.fb.group({
      dteDateCreated: [''],
      guAppUserId: [''],
      guAppPageId: [''],
      txtColumnSetName: [''],
      blnCurrentView: ['']
    });


    this.form.controls['dteDateCreated'].enable();
    this.form.controls['dteDateCreated'].setValue(this.sharedService.currentDatePlusTZOffset());
    this.form.controls['dteDateCreated'].disable();


  }

  ngOnInit(): void {

    this.loadData();

  }

  async loadData() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParamList.push(aParam);

    this.appPages = await this.appPageService.getAppPageParamArrayPromise(aParamList);
    this.appUsers = await this.appUserService.getAppUserParamArrayPromise(aParamList);

  }


  async create(mode: string) {

    let saveAppPageColumn: boolean = true;
    let dateCreated: Date = new Date(this.form.controls['dteDateCreated'].value.toString());
    let columnSetName: string = this.form.controls['txtColumnSetName'].value;
    let appUserId: string = this.form.controls['guAppUserId'].value;
    let appPageId: string = this.form.controls['guAppPageId'].value;
    let currentView: boolean = this.form.controls['blnCurrentView'].value == true;
    const datepipe: DatePipe = new DatePipe('en-AU');

    if (dateCreated == null || dateCreated.toString() == "") {
      this.alertService.openSnackBar("Please select an created date.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (columnSetName == null || columnSetName.toString() == "") {
      this.alertService.openSnackBar("Please enter a column set name.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (appUserId == null || appUserId.toString() == "") {
      this.alertService.openSnackBar("Please select an app user.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (appPageId == null || appPageId.toString() == "") {
      this.alertService.openSnackBar("Please select an app page.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (saveAppPageColumn) {

      if (currentView == true) {
        let aParamList: SQLParamArray[][] = [];
        let aParam: SQLParamArray[] = [];

        let appUserColumnSetExist: AppUserColumnSet[] = [];
        aParam.push(new SQLParamArray("blnCurrentView", "1"));
        aParamList.push(aParam);

        aParam = [];
        aParam.push(new SQLParamArray("guAppUserId", appUserId));
        aParamList.push(aParam);

        aParam = [];
        aParam.push(new SQLParamArray("guAppPageId", appPageId));
        aParamList.push(aParam);

        appUserColumnSetExist = await this.appUserColumnSetService.getAppUserColumnSetParamArrayPromise(aParamList);

        if (appUserColumnSetExist != null && appUserColumnSetExist.length > 0) {
          this.alertService.openSnackBar("There is already a set that has current view set on this page", "Close", "center", "bottom", "", 3000);
          return;
        }
      }

      let appUserColumnSet: AppUserColumnSet = new AppUserColumnSet;

      delete appUserColumnSet.rowguid;  //remove rowguid property so DB will create one.

      appUserColumnSet.dteDateCreated = new Date(datepipe.transform(dateCreated, 'yyyy-MM-dd'));
      appUserColumnSet.txtName = columnSetName;
      appUserColumnSet.guAppUserId = appUserId;
      appUserColumnSet.guAppPageId = appPageId;
      appUserColumnSet.blnCurrentView = currentView;

      appUserColumnSet = await this.appUserColumnSetService.createAppUserColumnSetPromise(appUserColumnSet);

      if (mode == "Create") {
        appUserColumnSet = await this.appUserColumnSetService.getAppUserColumnSetPromise(appUserColumnSet.rowguid);
        this.appUserColumnSetCreated.emit(appUserColumnSet);
      }

      if (mode == "CreateAndAdd") {
        this.resetFields(true);
      }
    }


  }

  cancel() {
    this.resetFields(false);
    this.appUserColumnSetCancelled.emit(true);
  }

  resetFields(cache: boolean) {

    this.form.controls['dteDateCreated'].enable();
    this.form.controls['dteDateCreated'].setValue(this.sharedService.currentDatePlusTZOffset());
    this.form.controls['dteDateCreated'].disable();

    //this.form.controls['dteDateCreated'].setValue(this.sharedService.currentDatePlusTZOffset.toString());
    //this.form.controls['txtColumnSetName'].setValue('');

    if (cache == false) {
      this.form.controls['guAppUserId'].setValue('');
      this.form.controls['guAppPageId'].setValue('');
    }

  }

}
