<div class="cca-charge-item-view-container">
  <div class="cca-charge-item-view-table">
    <div class="cca-charge-item-item-no-header item-row-header"></div>
    <div class="cca-charge-item-po-number-header item-row-header">Consecutive No.</div>
    <div class="cca-charge-item-description-header item-row-header">Pole No.</div>
    <div class="cca-charge-item-qty-header item-row-header">Product</div>
    <div class="cca-charge-item-qty-uom-header item-row-header">Size</div>
    <div class="cca-charge-item-qty-uom-header item-row-header">Species</div>
    <div class="cca-charge-item-total-m3-header item-row-header alignRight">M3</div>

    <ng-container *ngFor="let tpChargeItem of tpChargeItems; let idx = index">

      <div class="cca-charge-item-item-no item-row alignRight">{{idx + 1}}</div>
      <div class="order-number item-row">{{tpChargeItem.intConsecutiveNo == 0 ? '' : tpChargeItem.intConsecutiveNo}}<span class="sample-taken" matTooltip="Sample Taken">{{tpChargeItem.blnSampleTaken == true ? ' *' : ''}}</span></div>
      <div class="cca-charge-item-description item-row">{{tpChargeItem.txtPoleNumber}}</div>
      <div class="item-row cca-charge-item-qty">{{tpChargeItem.txtProductTypeName}}</div>
      <div class="cca-charge-item-qty-uom item-row">{{tpChargeItem.txtSize}}</div>
      <div class="cca-charge-item-qty-uom item-row">{{tpChargeItem.txtSpeciesCode}}</div>
      <div class="cca-charge-item-total-m3 item-row alignRight">{{tpChargeItem.fltM3 | number : '1.2-2' }}</div>

      <div class="cca-charge-item-blank1-footer item-footer" *ngIf="(idx == tpChargeItems.length - 1 || idx < tpChargeItems.length - 1 && tpChargeItem.guTPChargeId != tpChargeItems[idx+1].guTPChargeId)"></div>
      <div class="cca-charge-item-blank2-footer item-footer" *ngIf="(idx == tpChargeItems.length - 1 || idx < tpChargeItems.length - 1 && tpChargeItem.guTPChargeId != tpChargeItems[idx+1].guTPChargeId)"></div>
      <div class="cca-charge-item-blank3-footer item-footer" *ngIf="(idx == tpChargeItems.length - 1 || idx < tpChargeItems.length - 1 && tpChargeItem.guTPChargeId != tpChargeItems[idx+1].guTPChargeId)"></div>
      <div class="cca-charge-item-blank4-footer item-footer" *ngIf="(idx == tpChargeItems.length - 1 || idx < tpChargeItems.length - 1 && tpChargeItem.guTPChargeId != tpChargeItems[idx+1].guTPChargeId)"></div>
      <div class="cca-charge-item-total-m3-label-footer item-footer" *ngIf="(idx == tpChargeItems.length - 1 || idx < tpChargeItems.length - 1 && tpChargeItem.guTPChargeId != tpChargeItems[idx+1].guTPChargeId)">Total M3:</div>
      <div class="cca-charge-item-blank6-footer item-footer" *ngIf="false && (idx == tpChargeItems.length - 1 || idx < tpChargeItems.length - 1 && tpChargeItem.guTPChargeId != tpChargeItems[idx+1].guTPChargeId)"></div>
      <div class="cca-charge-item-total-m3-footer item-footer" *ngIf="(idx == tpChargeItems.length - 1 || idx < tpChargeItems.length - 1 && tpChargeItem.guTPChargeId != tpChargeItems[idx+1].guTPChargeId)">
        <div class="cca-charge-item-total-m3-footer  alignRight" *ngIf="(idx == tpChargeItems.length - 1 || idx < tpChargeItems.length - 1 && tpChargeItem.guTPChargeId != tpChargeItems[idx+1].guTPChargeId)">
          {{totalM3}}
        </div>
        <div class="cca-charge-item-weight-footer item-footer item-footer-weight alignRight" *ngIf="false && (idx == tpChargeItems.length - 1 || idx < tpChargeItems.length - 1 && tpChargeItem.guTPChargeId != tpChargeItems[idx+1].guTPChargeId)">
          {{totalTonne}}
        </div>
      </div>
    </ng-container>
  </div>
</div>
