import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoTaxInvoiceWeeklyTotals } from '../business-objects/dto-tax-invoice-weekly-totals.bo';

@Injectable()
export class dtoTaxInvoiceWeeklyTotalsService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoTaxInvoiceWeeklyTotals(dtoTaxInvoiceWeeklyTotalsId: string) {
		return this.http.get<dtoTaxInvoiceWeeklyTotals>(this._baseUrl + 'api/dtoTaxInvoiceWeeklyTotals/' + dtoTaxInvoiceWeeklyTotalsId);
	}

	getdtoTaxInvoiceWeeklyTotalsParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoTaxInvoiceWeeklyTotals[]>(this._baseUrl + 'api/dtoTaxInvoiceWeeklyTotals/GetdtoTaxInvoiceWeeklyTotalsParamArray/', aParam);
	}

	getAlldtoTaxInvoiceWeeklyTotalss() {
		return this.http.get<dtoTaxInvoiceWeeklyTotals[]>(this._baseUrl + 'api/dtoTaxInvoiceWeeklyTotals/GetAlldtoTaxInvoiceWeeklyTotals/');
	}

	//getAlldtodtoTaxInvoiceWeeklyTotalss() {
	//	return this.http.get<dtodtoTaxInvoiceWeeklyTotals[]>(this._baseUrl + 'api/dtoTaxInvoiceWeeklyTotals/GetAlldtoTaxInvoiceWeeklyTotals/');
	//}

	createdtoTaxInvoiceWeeklyTotals(dtoTaxInvoiceWeeklyTotals: dtoTaxInvoiceWeeklyTotals) {
		return this.http.post<dtoTaxInvoiceWeeklyTotals>(this._baseUrl + 'api/dtoTaxInvoiceWeeklyTotals/CreatedtoTaxInvoiceWeeklyTotals/', dtoTaxInvoiceWeeklyTotals);
	}

	updatedtoTaxInvoiceWeeklyTotals(dtoTaxInvoiceWeeklyTotals: dtoTaxInvoiceWeeklyTotals) {
		return this.http.put<dtoTaxInvoiceWeeklyTotals>(this._baseUrl + 'api/dtoTaxInvoiceWeeklyTotals/UpdatedtoTaxInvoiceWeeklyTotals/', dtoTaxInvoiceWeeklyTotals);
	}

	deletedtoTaxInvoiceWeeklyTotals(dtoTaxInvoiceWeeklyTotalsId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoTaxInvoiceWeeklyTotals/DeletedtoTaxInvoiceWeeklyTotals/' + dtoTaxInvoiceWeeklyTotalsId);
	}

	async getdtoTaxInvoiceWeeklyTotalsPromise(dtoTaxInvoiceWeeklyTotalsId: string) {
		return new Promise<dtoTaxInvoiceWeeklyTotals>(resolve => {
			this.getdtoTaxInvoiceWeeklyTotals(dtoTaxInvoiceWeeklyTotalsId).subscribe(dtoTaxInvoiceWeeklyTotals => {
				resolve(dtoTaxInvoiceWeeklyTotals);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTaxInvoiceWeeklyTotals records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

  async getdtoTaxInvoiceWeeklyTotalsByDates(year: number, saleLocation: string) {
    return new Promise<dtoTaxInvoiceWeeklyTotals[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("intYear", year.toString()));
      aParamList.push(aParam);

      if (saleLocation != null && saleLocation != "") {
        aParam = [];
        aParam.push(new SQLParamArray("guSaleLocationId", saleLocation));
        aParamList.push(aParam);
      }

      this.getdtoTaxInvoiceWeeklyTotalsParamArray(aParamList).subscribe(dtoTaxInvoiceDailyTotals => {
        resolve(dtoTaxInvoiceDailyTotals);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoTaxInvoiceWeeklyTotals records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getdtoTaxInvoiceWeeklyTotalsParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoTaxInvoiceWeeklyTotals[]>(resolve => {
			this.getdtoTaxInvoiceWeeklyTotalsParamArray(aParam).subscribe(dtoTaxInvoiceWeeklyTotals => {
				resolve(dtoTaxInvoiceWeeklyTotals);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTaxInvoiceWeeklyTotals records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoTaxInvoiceWeeklyTotalsPromise(dtoTaxInvoiceWeeklyTotals: dtoTaxInvoiceWeeklyTotals) {
		return new Promise<dtoTaxInvoiceWeeklyTotals>(resolve => {
			this.updatedtoTaxInvoiceWeeklyTotals(dtoTaxInvoiceWeeklyTotals).subscribe(dtoTaxInvoiceWeeklyTotals => {
				resolve(dtoTaxInvoiceWeeklyTotals);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoTaxInvoiceWeeklyTotals record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoTaxInvoiceWeeklyTotalsPromise(dtoTaxInvoiceWeeklyTotals: dtoTaxInvoiceWeeklyTotals) {
		return new Promise<dtoTaxInvoiceWeeklyTotals>(resolve => {
			this.createdtoTaxInvoiceWeeklyTotals(dtoTaxInvoiceWeeklyTotals).subscribe(dtoTaxInvoiceWeeklyTotals => {
				resolve(dtoTaxInvoiceWeeklyTotals);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoTaxInvoiceWeeklyTotals record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoTaxInvoiceWeeklyTotalsPromise(dtoTaxInvoiceWeeklyTotalsId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoTaxInvoiceWeeklyTotals(dtoTaxInvoiceWeeklyTotalsId).subscribe(dtoTaxInvoiceWeeklyTotals => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoTaxInvoiceWeeklyTotals record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
