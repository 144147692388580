import { Component, Inject, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { WOWService } from '../testWOW';
import { dtoKeyValue } from '../_shared/business-objects/dto-int-key-value.bo';

import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FormGroup } from '@angular/forms';

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

const STOCKITEM_DATA: StockItem[] = [
  { Name: 'Hydrogen', Price: 1.0079, Special: true, WasPrice: 22, Discount: "20%"},
  { Name: 'Hydrogen', Price: 1.0079, Special: true, WasPrice: 22, Discount: "20%" },
  { Name: 'Hydrogen', Price: 1.0079, Special: true, WasPrice: 22, Discount: "20%" },
  { Name: 'Hydrogen', Price: 1.0079, Special: true, WasPrice: 22, Discount: "20%" },
  { Name: 'Hydrogen', Price: 1.0079, Special: true, WasPrice: 22, Discount: "20%" },
];

@Component({
  selector: 'app-fetch-data',
  templateUrl: './fetch-data.component.html',
  styleUrls: ['./fetch-data.css'],
})
export class FetchDataComponent {
  public forecasts: WeatherForecast[];
  form: FormGroup;
  @ViewChild('tblStockItems') tblStockItems: MatTable<StockItem>;

  public wowJSON: any;
  public wowData: any;
  public productName: string;
  public productPrice: number;
  productSpecial: boolean;
  productWasPrice: number;
  productDiscount: string;

  showTable: boolean = true;

  displayedColumns3: string[] = ['Name', 'Price', 'Special', 'WasPrice', 'Discount'];

  wowStockItems: StockItem[] = [];

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = ELEMENT_DATA;

  displayedColumns2: string[] = ['name', 'price', 'special', 'wasprice', 'discount'];
  dataSource2 = STOCKITEM_DATA;

  constructor(private http: HttpClient, private wowService: WOWService, @Inject('BASE_URL') private baseUrl: string) {

    //http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
    //  this.forecasts = result;
    //}, error => console.error(error));

    //this.wowStockItems = [];

  }


  async getWOWProducts() {
    //let req: HttpRequest<any>;
   
    //const cloned = req.clone();
    //let header;
    //cloned.headers.set("Access-Control-Allow-Origin", "*");
    //cloned.headers.set("Access-Control-Allow-Methods", "DELETE, POST, GET, OPTIONS");
    //cloned.headers.set("Access-Control-Allow-Headers", "Content-Type, Authorization, X-Requested-With");

    //var headers = new HttpHeaders(
    //  {
    //    "Access-Control-Allow-Origin": "https://localhost:44356" ,
    //   "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
    //    "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
    //    "Content-Type": "text/html; charset=UTF-8"
    //   }
    //);


    //let url: string = "https://www.woolworths.com.au/shop/productgroup/090222-wk33-half-price-carousel?icmpid=sm-hp-hero1:half-price-generic-misc%7Cwk33&pageNumber=2";
    //this.http.get(url, { headers: headers, responseType: "text" }).subscribe(data => {

    //  //console.warn(data);
    //  this.wowData = data;
    //}, error => {
    //    console.log("error");
    //    console.log(error);
    //});

    let productCode: HTMLInputElement;
    productCode = document.getElementById("productCode") as HTMLInputElement;

    let knownProductCodes: string = "256700,208112,829544,1079,692794,78638,37174,284455,32306"
    let productCodes: string = productCode.value;
    if (productCodes != "") {
      productCodes = productCode + ",";
    }
    productCodes += knownProductCodes;
    console.log("productCodes: " + productCodes);
    
    this.wowJSON = await this.wowService.WOWData(productCodes);

    //this.productName = this.wowService.getProductProperty(this.wowJSON, "Name")
    //this.productPrice = this.wowService.getProductProperty(this.wowJSON, "Price")
    //this.productSpecial = this.wowService.getProductProperty(this.wowJSON, "IsOnSpecial")
    //this.productWasPrice = this.wowService.getProductProperty(this.wowJSON, "WasPrice")


    for (let i = 0; i <= this.wowJSON.length - 1; i++) {
      let stockItem: StockItem = new StockItem();
      stockItem.Name = this.wowJSON[i].Name;
      stockItem.Price = this.wowJSON[i].Price;
      stockItem.Special = this.wowJSON[i].IsOnSpecial;
      stockItem.WasPrice = this.wowJSON[i].WasPrice;
      if (stockItem.WasPrice > 0 && stockItem.Price > 0 && stockItem.WasPrice > stockItem.Price) {
        stockItem.Discount = ((((stockItem.WasPrice - stockItem.Price) / stockItem.WasPrice)) * 100).toFixed(0) + "%";
      }
      console.log("wowJSON: ", stockItem);
      this.wowStockItems.push(stockItem);
    }

    this.tblStockItems.renderRows();
   
    console.log("wowStockItems",this.wowStockItems);

    //this.showTable = true;


    
  }

  sendEmail() {

    let stockItemsTable = document.getElementById("productsContainer").innerHTML;
    let keyValue: dtoKeyValue = new dtoKeyValue();
    keyValue.txtValue = stockItemsTable;

    this.http.post(this.baseUrl + 'api/email/SendEmail/', keyValue, {responseType: "text"}).subscribe(data => {

      console.log(data);

    }, error => {
        console.log("error");
        console.log(error);
    });
  }

  testMTECHUtilities() {

    const headers = new HttpHeaders();  //.set('Content-Type', 'text/html; charset=utf-8')
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, PATCH');
    //headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Accept');

    console.log('headers length: ' + headers.get('Access-Control-Allow-Headers'));
    console.log(headers.keys[0]);

    for (let i = 0; headers.getAll.length - 1; i++) {
      console.log(headers);
    }

    this.http.get('https://mtech.utilities.coffshardwoods.com.au:8172/api/WeatherForecast/', { headers }).subscribe(data => {
      console.log(data);

      //lblPrinted.innerHTML = "Printed";

      //this.download(data);

    }, err => {
      console.log(err);
      //lblPrinted.innerHTML = "Error Printing";
    });

  }

}

interface WeatherForecast {
  date: string;
  temperatureC: number;
  temperatureF: number;
  summary: string;
}

export interface StockItem {
  Name: string;
  Price: number;
  Special: boolean;
  WasPrice: number;
  Discount: string;
}

export class StockItem {
  Name: string;
  Price: number;
  Special: boolean;
  WasPrice: number;
  Discount: string;
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
