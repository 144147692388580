export interface BoardTallyRecordMoulder
{
	dteLastUpdated: Date;
	guBoardTallyRecordId: string;
	guMoulderId: string;
	intNoOfRuns: number;
	rowguid: string;
}

export class BoardTallyRecordMoulder
{
	dteLastUpdated: Date;
	guBoardTallyRecordId: string;
	guMoulderId: string;
	intNoOfRuns: number;
	rowguid: string;
}

