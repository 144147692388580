import { Component, OnInit, ViewChild } from '@angular/core';
import { dtoBoardTallyRecordNotInStockService } from '../_shared/services/dto-board-tally-record-not-in-stock.service';
import { DeliveryDocketService } from '../_shared/services/delivery-docket.service';

import { dtoBoardTallyRecordSumNotInStock } from '../_shared/business-objects/dto-board-tally-record-sum-not-in-stock.bo';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DeliveryDocket } from '../_shared/business-objects/delivery-docket.bo';


@Component({
  selector: 'app-board-tally-record-not-in-stock',
  templateUrl: './board-tally-record-not-in-stock.component.html',
  styleUrls: ['./board-tally-record-not-in-stock.component.css'],
    animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 1 //,
      })),
      transition('open <=> closed', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ])

    ]),
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class BoardTallyRecordNotInStockComponent implements OnInit {

  @ViewChild('tblBoardTallyRecordNotInStock') tblBoardTallyRecordNotInStock: MatTable<dtoBoardTallyRecordSumNotInStock>;

  dtoBoardTallyRecordNotInStock: dtoBoardTallyRecordSumNotInStock[] = [];

  displayedColumns: string[] = ['txtSection', 'txtCustomerName', 'txtProductName', 'intIdentifier', 'dteDateCreated', 'dteLastestDate', 'intDeliveryDocketNo', 'txtDeliveryStatus', 'dteStatusDate', 'intWidth', 'sizeSeparator', 'intThickness', 'fltQuantity', 'intQuantityUoM', 'fltTotalLM', 'txtSpeciesName', 'txtGradeName', 'btnSetAsDelivered'];
  displayedColumns2: string[] = ['txtSection', 'txtCustomerName', 'txtProductName'];

  showDeliveryButton: boolean = true;
  showTable: boolean = false;

  showProductsSpinner: boolean = false;
  transitionProducts: boolean = false;


  constructor(private boardTallyRecordNotInStockService: dtoBoardTallyRecordNotInStockService, private deliveryDocketService: DeliveryDocketService) {
    
  }

  ngOnInit(): void {


  }

  showPacksNotInStock() {

    let geoStockLocationId: string[] = [];
    geoStockLocationId.push("11111111-1111-1111-1111-111111111111");
    geoStockLocationId.push("33333333-3333-3333-3333-333333333333");
    this.showProductSpinner(true);
    this.showTable = false;

    this.boardTallyRecordNotInStockService.getdtoBoardTallyRecordNotInStock(new Date('2022-06-30'), new Date('2022-06-30'), geoStockLocationId, new Date('2022-06-30'), new Date('2022-06-30')).subscribe(boardTallyNotInStock => {

      this.showProductSpinner(false);

      this.dtoBoardTallyRecordNotInStock = boardTallyNotInStock;
      this.showTable = true;

      console.log(boardTallyNotInStock);

    }, err => {

      console.log(err);

    });


    //this.boardTallyRecordNotInStockService.getTestArray().subscribe(test => {

    //  console.log(test);

    //}, err => {

    //  console.log(err);

    //});
  }


  setAsDelivered(bt: dtoBoardTallyRecordSumNotInStock, rowIndex: number) {

    this.deliveryDocketService.getDeliveryDocket(bt.intDeliveryDocketNo).subscribe(delDocket => {

      console.log(delDocket);
      
      delDocket.intStatus = DeliveryDocket.enDeliveryStatus.Delivered;
      delDocket.dteStatusDate = delDocket.dteLoaded;
      
      this.deliveryDocketService.updateDeliveryDocket(delDocket).subscribe(updateDelDocket => {

        console.log(updateDelDocket);

        this.dtoBoardTallyRecordNotInStock.splice(rowIndex, 1);

        this.tblBoardTallyRecordNotInStock.renderRows();

      }, err => {

        console.log(err);

      });


    }, err => {

      console.log(err);

    });

    console.log(bt);

  }


  showProductSpinner(val: boolean) {
    this.showProductsSpinner = val;
    this.transitionProducts = val;
  }

}
