import { Component } from '@angular/core';

@Component({
  selector: 'app-quote-update',
  templateUrl: './quote-update.component.html',
  styleUrl: './quote-update.component.scss'
})
export class QuoteUpdateComponent {

}
