<form class="example-form cca-charge-container-form" [formGroup]="form">
  <div class="cca-charge-container" id="chargeContainer">
    <div class="cca-charge-heading"><h1>CCA Charge</h1></div>

    <mat-tab-group color="accent" class="chh-mat-tab-group charge-mat-tab-group" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabCharges>
      <mat-tab label="Charges" class="tab-label charge-search-tab">
        <div class="search-fields-container">
          <div class="search-charge-no">
            <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
              <mat-label>Charge No.</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 254" value="" type="text" id="txtSearchChargeNo" formControlName="txtSearchChargeNo" (keydown.enter)="enterKeySearch('', $event)">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 254,138..." class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-charge-year">
            <mat-form-field class="chh-form-field example-full-width chh-textbox-200">
              <mat-label>Treatment Year</mat-label>
              <mat-select formControlName="txtSearchChargeYear">
                @for (year of years; track year) {
                <mat-option [value]="year.intValue">{{year.txtValue}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <div class="search-last-number">
            <mat-form-field class="example-full-width header-button" style="width: 90px !important" appearance="fill">
              <mat-label>Last Charges</mat-label>
              <input class="textbox-input txtSearchLastCharges" matInput placeholder="" value="20" type="text" #txtSearchLastCharges id="txtSearchLastCharges">
            </mat-form-field>
          </div>

          <div class="search-buttons">
            <div class="cca-charge-button-container">
              <button class="search-charge-button search-button" type="button" mat-flat-button color="primary" (click)="searchCharges('', 0)">Search</button>
              <button class="search-charge-button search-button" type="button" mat-flat-button color="primary" (click)="searchCharges('', txtSearchLastCharges.value)">Search Last {{txtSearchLastCharges.value}}</button>
            </div>
          </div>

        </div>

        <mat-divider></mat-divider>

        <div class="charge-list-app-container">
          <app-cca-charge-list #appChargeList [datasource]="tpCharges" (editChargeEvent)="editCharge($event)" (addChargeItemsEvent)="addChargeItems($event)" (updatedChargeEvent)="updatedCharge($event)" (removeChargeEvent)="removeCharge($event)" [columnBarPage]="'CCA Charges'"></app-cca-charge-list>
        </div>

      </mat-tab>



      <mat-tab label="Search Options" class="tab-label search-option-tab">
        <div class="search-options-container">
          <div class="charge-search-options">
            <h3>Charge Search Parameters</h3>

            <div class="search-option-row-3">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
                <mat-label>Charged date from</mat-label>
                <input id="dteSearchChargedDateFrom" class="date-input loading-date-search" #searchLoadingDateFrom matInput [matDatepicker]="statuspickerfrom" formControlName="dteSearchChargedDateFrom">
                <mat-datepicker-toggle matSuffix [for]="statuspickerfrom"></mat-datepicker-toggle>
                <mat-datepicker #statuspickerfrom></mat-datepicker>
              </mat-form-field>
            </div>


            <div class="search-option-row-4">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-200" appearance="fill">
                <mat-label>Charged date to</mat-label>
                <input id="dteSearchChargedDateTo" class="date-input loading-date-search" #searchLoadingDateTo matInput [matDatepicker]="statuspickerto" formControlName="dteSearchChargedDateTo">
                <mat-datepicker-toggle matSuffix [for]="statuspickerto"></mat-datepicker-toggle>
                <mat-datepicker #statuspickerto></mat-datepicker>
              </mat-form-field>
            </div>



            <mat-divider></mat-divider>
          </div>

          <div class="charge-item-search-options">
            <h3>Charge Item Search Parameters</h3>

            <div class="search-option-row-1">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Pole Number</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 152458" value="" type="number" formControlName="txtSearchPoleNumber" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="Use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-1">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Consecutive Number</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 3714" value="" type="number" formControlName="txtSearchConsecutiveNumber" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="Use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-1">
              <div class="search-product-type-container">
                <app-product-type-select [productTypes]="productTypes" (selectedProductTypesEvent)="selectedProductTypes($event)"></app-product-type-select>
              </div>
            </div>

            <div class="search-option-row-5">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-150" appearance="fill">
                <mat-label>Diameter</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. 250,300,350" value="" type="text" formControlName="txtSearchDiameter" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

            <div class="search-option-row-5">
              <div class="search-species-container">
                <app-species-select [species]="species" [showSpeciesASCode]="true" (selectedSpeciesEvent)="selectedSpecies($event)"></app-species-select>
              </div>
            </div>

            <div class="search-option-row-5">
              <mat-form-field appearance="fill" class="chh-form-field chh-textbox-200">
                <mat-label>Treatment Hazard Level</mat-label>
                <mat-select formControlName="intSearchTreatmentHazardLevel" multiple>
                  <mat-option *ngFor="let treatmentHazardLevel of treatmentHazardLevels" [value]="treatmentHazardLevel.intValue">{{treatmentHazardLevel.txtValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="search-option-row-1">
              <mat-form-field class="chh-form-field example-full-width chh-textbox-400" appearance="fill">
                <mat-label>Charge Comments</mat-label>
                <input class="textbox-input" matInput placeholder="i.e. Charge went low, going to recharge" value="" type="text" formControlName="txtSearchChargeComments" (keydown.enter)="enterKeySearch('', $event)">
              </mat-form-field>
              <mat-icon matTooltip="Use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            </div>

          </div>

          <div class="charge-search-button">
            <div class="charge-search-button-container">
              <button class="search-option-charge-button" type="button" mat-flat-button color="primary" id="btnSearchOptionsButton" (click)="searchCharges('', 0 )">Search</button>
            </div>
          </div>

        </div>

      </mat-tab>

      <mat-tab label="Create Charge" class="tab-label">
        <div class="create-cca-charge-container">
          <app-cca-charge-create [inDialog]="false" (chargeCreated)="chargeCreated($event)" (chargeCancelled)="chargeCancelled($event)" *ngIf="selectedTab == eChargeTabs.CreateCharge"></app-cca-charge-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Charge" class="tab-label">
        <div class="update-cca-charge-container">
          <app-cca-charge-update [charge]="charge" (chargeUpdated)="chargeUpdated($event)" (chargeCancelled)="chargeCancelled($event)" *ngIf="selectedTab == eChargeTabs.UpdateCharge"></app-cca-charge-update>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</form>

<div class="spinner-container" *ngIf="showSpinner">
  <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
    <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
  </div>
</div>
