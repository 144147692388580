import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { BoardGrade } from '../../_shared/business-objects/board-grade.bo';
import { dtoGrade } from '../../_shared/business-objects/dto-grade.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { BoardGradeService } from '../../_shared/services/board-grade.service';
import { dtoGradeService } from '../../_shared/services/dto-grade.service';
import { GradeUpdateDialogComponent } from '../grade-update-dialog/grade-update-dialog.component';

@Component({
  selector: 'app-grade-select',
  templateUrl: './grade-select.component.html',
  styleUrl: './grade-select.component.scss'
})
export class GradeSelectComponent {
  @ViewChild('gradeFilter') gradeFilter: ElementRef;
  @ViewChild('gradeListContainer') gradeListContainer: ElementRef;
  @ViewChild('multipleGrades') multipleGrades: MatSlideToggle;


  @Input() grades: dtoGrade[];
  @Output() selectedGradeEvent = new EventEmitter<dtoGrade[]>();

  showSpinner: boolean
  addressHoverId: string;

  gradeUpdateDialogRef: MatDialogRef<GradeUpdateDialogComponent>;
  selectedGrades: dtoGrade[];
  gradesFiltered: dtoGrade[];
  gradeExpanded: dtoGrade;

  gradeFilteredItems: HTMLCollectionOf<HTMLDivElement>;

  showGradeList: boolean = false;
  showGradeListTransition: boolean = false;

  gradeSelectionExpanded: boolean = false;
  gradeListPinned: boolean = false;

  toolTipText: string = "pin";

  constructor(private gradeService: BoardGradeService, private dtoGradeService: dtoGradeService
    , private alertService: AlertService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.selectedGrades = [];

    document.addEventListener("click", this.openCloseGradeList.bind(this));

  }

  openCloseGradeList(e) {

    if (this.gradeListPinned == true) {
      return;
    }

    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.pushPin != null) {
      return;
    }


    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.gradeInput != null && this.gradeFilter.nativeElement.value != null && this.gradeFilter.nativeElement.value.toString() != "") {
      this.showGradeList = true;
      //console.log("this.showCustomerList = true", this.showCustomerList);
      //console.log("customerListContainer", this.customerFilter.nativeElement.value);

      return;
    }

    //if (e == null || e.target == null || e.target.dataset == null || e.target.dataset.gradeSelect == null) {

    // ALWAYS CLOSE THE SEARCH LIST UNLESS I'M PINNED
    if (this.gradeListPinned == false) {
      this.showGradeList = false;

      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      //console.log("this.showCustomerList = false", this.showCustomerList);
      return;
    }

    //let domStringMap: DOMStringMap = e.target.dataset;

    //console.log(domStringMap.customerSelect.toString() == "customerSelect");
    //console.log(domStringMap.customerSelect.toString());
  }

  checkGradeListIsOpen(inputValue: string) {

    //console.log("inputValue", inputValue);

    if (inputValue == null || inputValue == "") {
      this.showGradeList = false;
      //console.log("inputValue empty", inputValue);
      return;
    }

    this.showGradeList = true;


  }


  rowEnter(gradeId: string) {
    this.addressHoverId = gradeId;
    //console.log("row enter");
  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");
  }

  async selectGrade(grade: dtoGrade, selected: boolean) {

    if (grade == null) {
      this.alertService.openSnackBarDefault("There was a problem selecting the grade - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    // Remove All Grade 
    if (this.multipleGrades != null && this.multipleGrades.checked == false) {

      let gradeSelected: dtoGrade[] = this.grades.filter((a) => { return a.guSelectedGradeId != null && a.guSelectedGradeId != "" });
      for (let i = 0; i <= gradeSelected.length - 1; i++) {
        gradeSelected[i].guSelectedGradeId = "";
      }

      for (let i = this.selectedGrades.length - 1; i >= 0; i--) {
        this.selectedGrades.splice(i, 1);
      }

      for (let i = this.gradesFiltered.length - 1; i >= 0; i--) {
        this.gradesFiltered[i].guSelectedGradeId = "";
      }

    }

    // Remove Grade 
    if (selected == false) {
      for (let i = this.selectedGrades.length - 1; i >= 0; i--) {
        if (this.selectedGrades[i].rowguid == grade.rowguid) {
          this.selectedGrades.splice(i, 1);
          grade.guSelectedGradeId = "";
          //console.log("unselected grade");
          this.selectedGradeEvent.emit(this.selectedGrades);
          break;
        }
      }
    }

    // Add Grade 
    if (selected == true) {

      grade.guSelectedGradeId = "tempvalue";

      this.selectedGrades.push(grade);
      this.selectedGradeEvent.emit(this.selectedGrades);

      if (this.multipleGrades.checked == false && this.gradeListPinned == false) {
        this.showGradeList = false;
      }

      //console.log("added grade");

    }


  }

  async updateGrade(grade: dtoGrade) {

    console.log(grade);
    if (grade == null) {
      this.alertService.openSnackBarDefault("There is no grade to edit");
      return;
    }

    if (this.gradeUpdateDialogRef != null) {
      this.gradeUpdateDialogRef.close();
    }

    this.gradeUpdateDialogRef = this.dialog.open(GradeUpdateDialogComponent, {
      hasBackdrop: false,
      height: '95vh',
      maxWidth: '90vw',
      data: { selectedGrades: grade }
    });

    this.gradeUpdateDialogRef.backdropClick().subscribe(() => {
      this.gradeUpdateDialogRef.close();
    });


    this.gradeUpdateDialogRef
      .afterClosed()
      .subscribe(async (
        data: { status: string, grade: BoardGrade }) => {
        if (data == null || data == undefined) {
          //this.alertService.openSnackBarError("Something went wrong!\nAn email has been sent to the IT department.", "Close", "center", "bottom", 4000, true, "Something went wrong with closing the FinalInspectionCreate dialog, null data.");
          return;
        }

        //console.log(data);
        if (data.status != "Cancel") {
          let cust: dtoGrade = await this.dtoGradeService.getdtoGradePromise(data.grade.rowguid);
          this.selectedGrades.push(cust);

          for (let i = 0; i <= this.grades.length - 1; i++) {
            if (this.grades[i].rowguid == data.grade.rowguid) {
              this.grades[i] = cust;
              this.grades[i].guSelectedGradeId = "tempvalue";
              break;
            }
          }

          this.grades.sort((a, b) => { return (a.txtName > b.txtName ? 1 : -1) });

          this.alertService.openSnackBarDefault("The Grade has been updated");

        }
        else {
          // We have cancellled

        }

      });

  }

  addGrade() {

  }

  nextGrade(e, grade: dtoGrade) {

    //console.log("e ", e);

    if (e.code.toLowerCase() == "escape") {
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      this.gradeFilter.nativeElement.focus();
      this.gradeFilter.nativeElement.setSelectionRange(this.gradeFilter.nativeElement.value.length, this.gradeFilter.nativeElement.value.length);

      this.showGradeList = false;
      return;
    }

    if ((e.code.toLowerCase() == "arrowdown" || e.code.toLowerCase() == "arrowup") && this.showGradeList == true) {
      //console.log("e ", e);

      if (this.gradeFilteredItems != null) {

        let tabIndex: number = e.target.tabIndex;

        let gradeUpdateDiv = document.getElementById("gradeUpdateDiv" + tabIndex);

        if (tabIndex < this.gradeFilteredItems.length - 1 && e.code.toLowerCase() == "arrowdown") {

          let gradeUpdateDivPlus = document.getElementById("gradeUpdateDiv" + (tabIndex + 1));

          //console.log("e down ", tabIndex);
          //TODO
          // Set this.gradeContainer.style overflow-y to hidden here
          let docBody = document.getElementById("rootBody");
          docBody.style.overflowY = "hidden";

          this.gradeListContainer.nativeElement.classList.add("grade-container-no-scroll");

          this.gradeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          gradeUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.gradeFilteredItems[tabIndex + 1].focus();
          this.gradeFilteredItems[tabIndex + 1].classList.add("chh-list-item-row-selected");
          gradeUpdateDivPlus.classList.add("chh-list-item-row-selected");

          // Set this.gradeContainer.style overflow-y to scroll here
          this.gradeListContainer.nativeElement.classList.remove("grade-container-no-scroll");

        }

        if (tabIndex >= 0 && e.code.toLowerCase() == "arrowup") {

          if (tabIndex == 0) {
            //console.log("up tabIndex ", tabIndex);

            this.gradeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
            gradeUpdateDiv.classList.remove("chh-list-item-row-selected");

            let docBody = document.getElementById("rootBody");
            docBody.style.overflowY = "scroll";

            this.gradeFilter.nativeElement.focus();
            this.gradeFilter.nativeElement.setSelectionRange(this.gradeFilter.nativeElement.value.length, this.gradeFilter.nativeElement.value.length);
            return;
          }


          let gradeUpdateDivMinus = document.getElementById("gradeUpdateDiv" + (tabIndex - 1));

          //console.log("e up ", tabIndex);

          this.gradeListContainer.nativeElement.classList.add("grade-container-no-scroll");

          this.gradeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          gradeUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.gradeFilteredItems[tabIndex - 1].focus();
          this.gradeFilteredItems[tabIndex - 1].classList.add("chh-list-item-row-selected");
          gradeUpdateDivMinus.classList.add("chh-list-item-row-selected");

          this.gradeListContainer.nativeElement.classList.remove("grade-container-no-scroll");


        }

      }
      //console.log("nextGrade");

    }

    if (e.code.toLowerCase() == "enter") {
      this.selectGrade(grade, !(grade.guSelectedGradeId != ''));
    }

  }

  filterGrades(gradeFilter: string, e) {


    if (e.code.toLowerCase() == "arrowdown" && this.showGradeList == true) {
      //console.log("e ", e);
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "hidden";

      this.gradeFilteredItems = document.getElementsByClassName("product-type-name") as HTMLCollectionOf<HTMLDivElement>;
      if (this.gradeFilteredItems != null && this.gradeFilteredItems.length > 0) {


        this.gradeFilteredItems[0].classList.add("chh-list-item-row-selected");
        let gradeUpdateDiv = document.getElementById("gradeUpdateDiv0");

        gradeUpdateDiv.classList.add("chh-list-item-row-selected");

        this.gradeFilteredItems[0].focus();
        console.log("filterGrade TabIndex: ", this.gradeFilteredItems[0].tabIndex);

      }

      //console.log("gradeElements", this.gradeFilteredItems);

    }

    this.getGrades(gradeFilter);



  }

  getGrades(gradeFilter: string) {

    this.gradesFiltered = this.grades;

    if (gradeFilter == null || gradeFilter == "") {
      this.gradesFiltered = [];
      this.showGradeList = false;
      return;
    }



    let grades: dtoGrade[] = this.grades;

    grades = grades.filter(a => ((a.txtName.toLowerCase().indexOf(gradeFilter.toLowerCase()) > -1)));

    grades = grades.slice(0, 50);

    grades.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

    this.gradesFiltered = grades;

    this.showGradeList = true;

    this.setGradeListTranition(1000);

  }

  setGradeListTranition(delay: number) {

    setTimeout(() => {
      this.showGradeListTransition = true;
    }, delay);

  }

  checkMultipleGrades(multipleGrade: boolean) {

    if (multipleGrade == false) {
      //Remove multiple selected grades so only one remains.
      let multipleCust: dtoGrade[] = this.grades.filter((a) => { return a.guSelectedGradeId != null && a.guSelectedGradeId != "" });
      if (multipleCust != null && multipleCust.length > 0) {

        multipleCust.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

        for (let i = multipleCust.length - 1; i >= 1; i--) {
          multipleCust.pop();
        }

        for (let i = 0; i <= this.grades.length - 1; i++) {
          if (multipleCust[0].rowguid != this.grades[i].rowguid) {
            this.grades[i].guSelectedGradeId = "";
          }
        }

        this.selectedGrades.length = 0;
        this.selectedGrades.push(multipleCust[0]);
        this.selectedGradeEvent.emit(this.selectedGrades);

      }

    }
  }

  removeGrade(grade: dtoGrade) {

    if (grade == null) {
      return;
    }

    for (let i = this.selectedGrades.length - 1; i >= 0; i--) {
      if (this.selectedGrades[i].rowguid == grade.rowguid) {
        this.selectedGrades.splice(i, 1);
        grade.guSelectedGradeId = "";
        //console.log("unselected grade");
        this.selectedGradeEvent.emit(this.selectedGrades);
        break;
      }
    }


  }

  expandSelectedGrade(e: PointerEvent) {

    if (e.pointerType == null || e.pointerType == "") {
      return;
    }

    this.gradeSelectionExpanded = !this.gradeSelectionExpanded;

    let selectedGrades: HTMLDivElement = document.getElementById("selectedGrades") as HTMLDivElement;
    selectedGrades.classList.toggle("selected-product-types-expanded");

    if (this.gradeSelectionExpanded == true) {

    }

  }

  clickPin(e) {

    this.gradeListPinned = !this.gradeListPinned;

    if (this.gradeListPinned == false) {
      this.showGradeList = false;
    }

    this.toolTipText = this.gradeListPinned == true ? 'unpin' : 'pin';


  }
}
